import { FC } from "react";

import { ButtonComponent } from "@syncfusion/ej2-react-buttons";
import { ReactComponent as Delete } from "../../../icons/delete.svg";
import { ReactComponent as Copy } from "../../../icons/copy.svg";
import "./NotesCard.scss";

interface IProps {
  date: string;
  note: string;
  onTextAreaChange: (str: string) => void;
}

const NotesCard: FC<IProps> = ({ date, note, onTextAreaChange }) => {
  const handleTextAreaChange = (event: any) => {
    onTextAreaChange(event.target.value);
  };
  return (
    <div className="notesCard">
      <div className="notesCard-header">
        <span className="notesCard-header-date">{date}</span>
        <span>
          <ButtonComponent onClick={() => {}} style={{ padding: 0 }}>
            <Copy width={40} height={40} />
          </ButtonComponent>
          <ButtonComponent
            className="delete-btn"
            onClick={() => {}}
            style={{ padding: 0, marginRight: 20 }}
          >
            <Delete width={40} height={40} />
          </ButtonComponent>
        </span>
      </div>
      <div className="notesCard-body">
        <div className="notesCard-profile-section">
          <div className="notesCard-profile"></div>
          <p>Henry Wright</p>
        </div>
        <div className="notesCard-text-section">
          <textarea
            value={note}
            rows={8}
            cols={20}
            onChange={handleTextAreaChange}
          />
        </div>
      </div>
    </div>
  );
};

export default NotesCard;
