import { ButtonComponent } from "@syncfusion/ej2-react-buttons";
import axios from "axios";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import userIcon from "../../assets/userIcon.png";
import { RootState } from "../../redux/store";
import notify from "../../services/toasterService";
import { ContactType } from "./constants";
import { setCurrentContact } from "./contactReducer";
import { addMasterDataEndPoint, getEndPoint } from "./utils";

const CommodityTypeAddScreen = (props: any) => {
  const { setAddScreen } = props;
  const [errors, setErrors] = useState({
    codeError: "",
    pricingTypeError: "",
    priceError: "",
  });
  let codeError = "";
  let pricingTypeError = "";
  let priceError = "";
  const dispatch = useDispatch();
  const [selectedImage, setSelectedImage] = useState();
  const [commodityTypeCode, setCommodityTypeCode] = useState("");
  const [commodityTypeDesc, setcommodityTypeDesc] = useState("");
  const [commodityTypePricingType, setCommodityTypePricingType] = useState("");
  const [commodityTypePrice, setcommodityTypePrice] = useState("");
  const [showPriceDiv, setShowPriceDiv] = useState(true);
  const [showPercentDiv, setShowPercentDiv] = useState(false);

  const [myCode, setMycode] = useState("");
  const [myPrice, setMyPrice] = useState("");
  const [myPricingType, setMyPricingType] = useState("");

  const pricingTypeData = useSelector(
    (state: RootState) => state.contactReducer?.pricingTypeMasterData
  );

  const imageChange = (e: any) => {
    if (e.target.files && e.target.files.length > 0) {
      setSelectedImage(e.target.files[0]);
    }
  };

  const handleAddCTCode = (e: any) => {
    setCommodityTypeCode(e.target.value);
    validate();
  };
  const handleAddCTDesc = (e: any) => {
    setcommodityTypeDesc(e.target.value);
    validate();
  };

  const [price, setPrice] = useState("");
  const handleAddCTPricingType = (e: any) => {
    setCommodityTypePricingType(e.target.value);
    validate();
    if (
      e.target.value === "Fixed price" ||
      e.target.value === "User Set" ||
      e.target.value === "0"
    ) {
      setShowPriceDiv(true);
      setShowPercentDiv(false);
    } else {
      setShowPriceDiv(false);
      setShowPercentDiv(true);
    }
  };
  const handleAddCTPrice = (e: any) => {
    if (e.target.value === "") {
      priceError = "Required ";
    }
    var regexFax = /^[0-9]*(?:\.\d{1,2})?$/;
    if (!regexFax.test(e.target.value)) {
      priceError = "Invalid ";
    } else {
      if (e.target.value.includes(".") === true) {
        if (e.target.value.split(".")[1].length > 2) {
          setcommodityTypePrice(parseFloat(e.target.value).toFixed(2));

          setPrice(parseFloat(e.target.value).toFixed(2));
        } else {
          setcommodityTypePrice(e.target.value);

          setPrice(e.target.value);
        }
      } else {
        setcommodityTypePrice(e.target.value);

        setPrice(e.target.value);
      }
    }
    if (priceError) {
      setErrors({ codeError, pricingTypeError, priceError });
      return false;
    } else {
      if (e.target.value.includes(".") === true) {
        if (e.target.value.split(".")[1].length > 2) {
          setcommodityTypePrice(parseFloat(e.target.value).toFixed(2));

          setPrice(parseFloat(e.target.value).toFixed(2));
        } else {
          setcommodityTypePrice(e.target.value);

          setPrice(e.target.value);
        }
      } else {
        setcommodityTypePrice(e.target.value);

        setPrice(e.target.value);
      }

      return true;
    }
  };
  const handleAddCTPricePercent = (e: any) => {
    if (e.target.value === "") {
      priceError = "Required ";
    } else {
      var regexFax = /^[0-9]*(?:\.\d{1,2})?$/;
      if (!regexFax.test(e.target.value)) {
        priceError = "Invalid ";
      } else if (e.target.value > 100) {
        setcommodityTypePrice("");
        priceError = "Percent should not exceed 100%";
        e.target.value = "";
      } else {
        setcommodityTypePrice(e.target.value);
      }
      if (priceError) {
        setErrors({ codeError, pricingTypeError, priceError });
        return false;
      } else {
        setcommodityTypePrice(e.target.value);
        return true;
      }
    }
  };
  const body = {
    active: true,
    code: commodityTypeCode,
    deleted: false,
    description: commodityTypeDesc,
    price: commodityTypePrice,
    pricingType: commodityTypePricingType,
  };
  let tabName: any = ContactType.COMMODITY_TYPES;
  const handleAddCommodityType = () => {
    if (validate()) {
      const endpoint = addMasterDataEndPoint(tabName);
      const getEndpoint = getEndPoint(tabName);
      handleMasterDataChange(endpoint, getEndpoint);
    }
  };
  const handleMasterDataChange = async (
    endpoint: string,
    getEndpoint: string
  ) => {
    const result = await axios
      .post(endpoint, body)
      .then((results: any) => {
        if (results && results.data) {
          setAddScreen(false);
          notify("Commodity type created successfully", "success");
        } else {
          notify("Failed to create Commodity type", "error");
        }
      })
      .catch((error: any) => {
        if (error.response) {
          notify(error.response?.data?.message, "error");
        }
      });
    const getresult = await axios.get(getEndpoint);
    dispatch(setCurrentContact(getresult.data));
  };
  const handleCancleCTPopup = () => {
    setAddScreen(false);
  };
  const validate = (field?: any, value?: any) => {
    if (commodityTypeCode === "") {
      codeError = "Required Field";
      setMycode("1px solid red");
    } else {
      setMycode("");
    }
    if (commodityTypePricingType === "") {
      pricingTypeError = "Required Field";
      setMyPricingType("1px solid red");
    } else {
      setMyPricingType("");
    }
    var regexFax = /^[0-9]*(?:\.\d{1,2})?$/;
    if (!regexFax.test(commodityTypePrice)) {
      priceError = "Invalid ";
    }
    if (commodityTypePrice === "") {
      priceError = "Required Field";
      setMyPrice("1px solid red");
    } else {
      setMyPrice("");
    }

    if (codeError || pricingTypeError || priceError) {
      setErrors({ codeError, pricingTypeError, priceError });
      return false;
    }
    return true;
  };

  /* check input format function */
  const formatInput = (e: any) => {
    // Prevent characters that are not numbers ("e", ".", "+" & "-") ✨
    let checkIfNum;
    console.log(e.target.value);
    if (e.target.value.includes(".") === true) {
      checkIfNum = e.target.value.split(".");
      console.log(checkIfNum);
      if (checkIfNum[1].length > 2) {
        e.target.value = parseFloat(checkIfNum[1]).toFixed(2);
        return e;
      } else {
        return e;
      }
    }
    // var t = e.value;
    //  e.value = (t.indexOf(".") >= 0) ? (t.substr(0, t.indexOf(".")) + t.substr(t.indexOf("."), 3)) : t;
    // return  e.preventDefault();
  };
  return (
    <div className="commodity-popup mr-0">
      <div className="row">
        <div className="col-sm-12 textAlign-center">
          {/* <input accept="image/*" type="file" onChange={imageChange} /> */}
          <img src={userIcon} className="userAddIcon" />
          {selectedImage && (
            <div className="imagePreviewDiv">
              <img
                src={URL.createObjectURL(selectedImage)}
                className="imagePreview"
                height="100"
                width="100"
              />
            </div>
          )}
        </div>
        </div>

        <div className="col-sm-12 row m-0 mt-2">
            <div className="col-sm-6">
              <div className="col-sm-12 mt-3">
              <label className="label-title">Code<span className="requiredStar">*</span></label>
              {commodityTypeCode === "" ? (
                <span className="text-danger" style={{float: 'right'}}>{errors.codeError}</span>
              ) : (
                ""
              )}
              <input
                type="text"
                className="formcontrol"
                onChange={(e) => handleAddCTCode(e)}
                style={{ border: myCode }}
              />
            </div>
            <div className="col-sm-12 mt-3">
              <label className="label-title">Description</label>
              <input
                type="text"
                className="form-control"
                onChange={(e) => handleAddCTDesc(e)}
              />
            </div>
          </div>
        <div className="col-sm-6">
          <div className="col-sm-12 mt-3">
              <label className="label-title">Pricing Type<span className="requiredStar">*</span></label>
              {commodityTypePricingType === "" ? (
                <span className="text-danger" style={{float: 'right'}}>{errors.pricingTypeError}</span>
              ) : (
                ""
              )}
              <select
                className="formcontrol"
                onChange={(e) => handleAddCTPricingType(e)}
                style={{ border: myPricingType }}
              >
                <option value="0">Select</option>
                {pricingTypeData?.map((pt) => (
                  <option value={pt.pricingType}>{pt.pricingType}</option>
                ))}
                {/* <option value="Fixed price">Fixed Price</option>
                 <option value="User set">User set</option>
                 <option value="Percent">Percent</option> */}
              </select>
            </div>
          {showPriceDiv ? (
            <div className="">
              <div className="col-sm-12 mt-3">
                <label className="label-title">Price ($)<span className="requiredStar">*</span></label>
                {commodityTypePrice === "" ? (
                  <span className="text-danger" style={{float: 'right'}}>{errors.priceError}</span>
                ) : (
                  ""
                )}
                <input
                  type="number"
                  className="formcontrol"
                  onChange={(e) => handleAddCTPrice(e)}
                  value={price}
                  onBlur={(e) => handleAddCTPrice(e)}
                  onKeyUp={(e) => handleAddCTPrice(e)}
                  style={{ border: myPrice }}
                />
                {/* <input type="number"  title="Rate"  id="rate"  className="form-control" min="0.00"  step="0.001" max="1.00"/> */}
                {/* <NumericTextBoxComponent format='n3' decimals={3} validateDecimalOnType={true}  >
            </NumericTextBoxComponent> */}
                
              </div>
            </div>
          ) : (
            <div className="">
              <div className="col-sm-12">
                <label className="label-title">Rate (%)</label>
                {commodityTypePrice === "" ? (
                  <span className="text-danger" style={{float: 'right'}}>{errors.priceError}</span>
                ) : (
                  ""
                )}
                <input
                  type="text"
                  className="form-control"
                  onChange={(e) => handleAddCTPricePercent(e)}
                  onBlur={(e) => handleAddCTPricePercent(e)}
                  onKeyUp={(e) => handleAddCTPricePercent(e)}
                />
              </div>
            </div>
          )}
        </div>        
        </div>
      

      <div className="col-sm-12 px-0 mt-5">
        <ButtonComponent
          cssClass="eg-btn-primary float-right"
          onClick={() => handleAddCommodityType()}
        >
          ADD COMMODITY TYPE
        </ButtonComponent>
        <ButtonComponent
          cssClass="eg-btn-secondary float-right mr-4"
          onClick={handleCancleCTPopup}
        >
          CANCEL
        </ButtonComponent>
      </div>
    </div>
  );
};
export default CommodityTypeAddScreen;
