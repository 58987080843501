import { FC } from "react";
import InvoicesControls from "../controls";
import { ReactComponent as Delete } from "../../../icons/deleteNew.svg";
import { ReactComponent as Save } from "../../../icons/saveNew.svg";
import { ButtonComponent } from "@syncfusion/ej2-react-buttons";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
// import { useHistory } from "react-router-dom";
const exportMenu = [

  {
    text: "",
    exportType: "Empty",
    screenType: "InvoiceDetails",
  },
  // {
  //   text: "Invoice w/o pricing",
  //   exportType: "csv",
  // },
  // {
  //   text: "Invoice details",
  //   exportType: "csv",
  // },
  // {
  //   text: "Invoice details with client",
  //   exportType: "csv",
  // },
  // {
  //   text: "Invoice",
  //   exportType: "pdf",
  // },
  // {
  //   text: "Invoice w/o pricing",
  //   exportType: "pdf",
  // },
  // {
  //   text: "Invoice details",
  //   exportType: "pdf",
  // },
  // {
  //   text: "Invoice details with client",
  //   exportType: "pdf",
  // },
];
interface IProps {
  hideCancelButton?: boolean;
  handleAddInvoice: () => void;
  handleAddToBOL: () => void;
  handleSave: () => void;
  handleDeleteInvoice: () => void;
  disableControls?: boolean;
  hideDeleteBtn?: Boolean;
}
function paramsToObject(entries: any) {
  const result: any = {};
  for (const [key, value] of entries) {
    result[key] = value;
  }
  return result;
}
const InvoiceDetailsTabControl: FC<IProps> = ({
  hideCancelButton,
  handleAddInvoice,
  handleAddToBOL,
  handleSave,
  handleDeleteInvoice,
  disableControls,
  hideDeleteBtn = false,
}) => {
  // const history = useHistory();
  // const urlParams = new URLSearchParams(history.location.search);
  // const paramEntries = urlParams.entries();
  // const params = paramsToObject(paramEntries);
  const arInvoiceSavedata = useSelector(
    (store: RootState) => store.invoiceReducer.arInvoiceSaveData
  );
  const creditMemoInfo = useSelector(
    (state: RootState) => state.invoiceReducer.creditMemoSavedData
  );
  return (
    <div style={{ display: "flex" }}>
      <InvoicesControls
        cancelButtonText="Add to BOL"
        hideCancelButton={hideCancelButton}
        enableActionHistory
        handleAddIconClick={handleAddInvoice}
        handleCancelButtonClick={handleAddToBOL}
        exportTypes={exportMenu}
        hideAddNewButton={
          arInvoiceSavedata?.invoiceNumber?.charAt(0).includes("C")
            ? true
            : false
        }
      />
      <span style={{ padding: "5px 0" }}>
        {hideDeleteBtn && (
          <ButtonComponent
            className="iconCopy btnDeleteIcon"
            disabled={disableControls}
            onClick={handleDeleteInvoice}
          >
            <Delete />
          </ButtonComponent>
        )}
        {creditMemoInfo?.status?.status === "Draft" ||
        arInvoiceSavedata?.status?.status === "Draft" ? (
          <ButtonComponent
            disabled={disableControls}
            // disabled={params.ArInvoiceId}
            className="iconCopy"
            onClick={handleSave}
          >
            <Save color={"green"} />
          </ButtonComponent>
        ) : (
          ""
        )}
      </span>
    </div>
  );
};

export default InvoiceDetailsTabControl;
