import { ButtonComponent } from "@syncfusion/ej2-react-buttons";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../redux/store";

import saveIcon from "../../../icons/common/save.png";
import closeIcon from "../../../icons/common/close.png";
import activeIcon from "../../../icons/common/active.png";
import inactiveIcon from "../../../icons/common/inactive.png";
import {
  getAccessMgmtEndPoint,
  getStoreWsUpdate,
  updateAccessReqEndPoint,
} from "../utils";
import {
  setActiveDirectoryData,
  setPreviewImage,
  setUploadedImage,
  setUserAccessData,
} from "../accessmgmtReducer";
import axios from "axios";
import notify from "../../../services/toasterService";
import { setRowData } from "../../grid/gridReducer";
import { formatDate } from "../../settings/utils";
import { DatePickerComponent } from "@syncfusion/ej2-react-calendars";
import { DropDownListComponent } from "@syncfusion/ej2-react-dropdowns";
import AddAccessManagementScreen from "../addScreen/addScreen";
import Resetpopup from "./resetpopup";

const UserGeneralPreview = () => {
  const [addScreen, setAddScreen] = useState(false);
  const [addScreenTabName, setAddScreenTabName] = useState(null);

  const dispatch = useDispatch();
  const [userGeneralInfo, setUserGenInfo] = useState<any[]>([]);
  let [selectedEndDate, setSelectedEndDate] = useState<any>();
  const [showAdd, setShowAdd] = useState(true);

  const [showStore, setShowStore] = useState(false);
  /* Get selected data from Grid Reducer */
  const selRowData = useSelector(
    (state: RootState) => state.gridReducer.selectedRowData
  );
  
  const locationsDataList = useSelector(
    (state: RootState) => state.accessMgmtReducer.locatonsList
  );
  const rolesListData = useSelector(
    (state: RootState) => state.accessMgmtReducer.rolesList
  );

  let uploadedImageFile = useSelector(
    (state: RootState) => state.accessMgmtReducer.previewImage
  );
  const [selectFields, setSelectFields] = useState<any[]>([
    {
      roleId: "",
      reclaimCenterId: "",
    },
  ]);
  let dropDownListObject: any;
  const [storesList, setStoresList] = useState<any>([]);

  const [storeId, setStoreId] = useState<any>();

  const [updatedUserGeneralInfo, setUpdateduserGenInfo] = useState<any>([])

  useEffect(() => {
    setUserGenInfo([]);
    if (selRowData && selRowData?.length > 0) {
      
      setUpdateduserGenInfo(selRowData)
      setUserGenInfo([]);
      setUserGenInfo(updatedUserGeneralInfo);
    
      dispatch(setUploadedImage([]));
      // setShowStore(false)
      if (
        selRowData[0].endDate !== undefined &&
        selRowData[0].endDate !== null &&
        selRowData[0].endDate !== ""
      ) {
        selectedEndDate = formatDate(new Date(selRowData[0].endDate));
        formatInput(selRowData[0].endDate);
        //selRowData[0].endDate = formatDate(selRowData[0].endDate)
      }
        //  let storeObj : any;
        //  console.log(storesList)
        //  if(storesList.length > 0) {
        //   storeObj = getSelectedStorebyId(selRowData[0]?.storeId)
        //   setStoreId(storeObj?.storeNumber + ' - ' + storeObj.storeName);
        //   console.log(storeId)
        //  } else {
        //   setStoreId(selRowData[0]?.store?.storeNumber + ' - ' + selRowData[0]?.store?.storeName);
        //  }
        
         setStoreId(selRowData[0]?.store?.storeNumber + ' - ' + selRowData[0]?.store?.storeName);
      
      //  setRemoveSelModules([]);
      // setModules([]);
    }
     setShowStore(false)

    // Write for onload
  }, [selRowData, updatedUserGeneralInfo]);

  const autoCompleteFields = {
    value: "id",
    text: "storeName",
  };
  const getStoreNamewithMainFrameCustomer = (e: any) => {
    getStoreList(e.text);
  };

  const getStoreList = (storeName?: any) => {
    if (storeName.length > 2) {
      const endpoint: any = getStoreWsUpdate(storeName);
      const result = axios.get(endpoint);
      result
        .then((data: any) => {
          setStoresList(data.data.content);
        })
        .catch((error: any) => {
          notify(error.message, "error");
        });
    } else {
    }
  };

  /**
   * Changing status for Preview selected tabs
   * @param flag - true/false
   */
  const changeStatusForRowData = (flag: any) => {
    if (flag === false) {
      setUserGenInfo([{ ...userGeneralInfo[0], activeForGrid: "Inactive" }]);
    } else {
      setUserGenInfo([{ ...userGeneralInfo[0], activeForGrid: "Active" }]);
    }
  };


  const getSelectedStorebyId = (id: any) => {
    storesList.map((item: any) => {
      if(item.id === id)
       return item
    })
  }
  /* Update Users WS Call */
  const updateUserGeneralTabApi = async (data: any) => {
    //   const token = sessionStorage.getItem('token');
    //  const token = UserService.getToken()
    const endPointURL = updateAccessReqEndPoint("Users");
    let store = getSelectedStorebyId(userGeneralInfo[0]?.storeId)
  
    let body = {
      // userId: userGeneralInfo[0]?.company

      firstname: userGeneralInfo[0]?.firstname,
      lastname: userGeneralInfo[0]?.lastName,
      company: userGeneralInfo[0]?.company,
      jobTitle: userGeneralInfo[0]?.jobTitle,
      username: userGeneralInfo[0]?.username,
      roleId: userGeneralInfo[0]?.roleId,
      reclaimCenterId: userGeneralInfo[0]?.reclaimCenterId,
      endDate: userGeneralInfo[0]?.endDate,

      email: userGeneralInfo[0]?.email,
      phoneNumber: userGeneralInfo[0]?.phoneNumber,
      fax: userGeneralInfo[0]?.fax,
      language: userGeneralInfo[0]?.language,
      timezone:
        userGeneralInfo[0]?.timezone !== null &&
        userGeneralInfo[0]?.timezone !== undefined
          ? userGeneralInfo[0]?.timezone
          : "en-us",
      countryId: userGeneralInfo[0]?.country,
      stateId: userGeneralInfo[0]?.stateId,
      city: userGeneralInfo[0]?.city,
      street: userGeneralInfo[0]?.street,

      password: userGeneralInfo[0]?.password,
      storeId:
        userGeneralInfo[0]?.storeId !== null &&
        userGeneralInfo[0]?.storeId !== undefined
          ? userGeneralInfo[0]?.storeId
          : userGeneralInfo[0]?.storeId,
      userType: userGeneralInfo[0]?.userType,
      userId: userGeneralInfo[0]?.userId,
      id: userGeneralInfo[0]?.id,
      status: userGeneralInfo[0]?.activeForGrid,
      scanUser: userGeneralInfo[0]?.scanUser !== undefined &&  userGeneralInfo[0]?.scanUser !== null ?  userGeneralInfo[0]?.scanUser : {}
    };

    if (
      uploadedImageFile.length > 0 &&
      uploadedImageFile !== [] &&
      uploadedImageFile !== undefined
    ) {
      Object.assign(body, { image: uploadedImageFile[0].base64 });
    } else {
      Object.assign(body, { image: userGeneralInfo[0]?.image });
    }
    if (userGeneralInfo[0]?.userType === "External") {
      if (
        userGeneralInfo[0]?.scanUser !== undefined &&
        userGeneralInfo[0]?.scanUser !== null &&
        userGeneralInfo[0]?.scanUser !== ""
      ) {
        Object.assign(body, {
          scanUser: {
            binMaxScanQty: userGeneralInfo[0]?.scanUser?.binMaxScanQty,
            boxMaxScanQty: userGeneralInfo[0]?.scanUser?.boxMaxScanQty,
            scanMaxQty: userGeneralInfo[0]?.scanUser?.scanMaxQty,
            canCloseBin: userGeneralInfo[0]?.scanUser?.canCloseBin,
            hourlyScanGoal: userGeneralInfo[0]?.scanUser?.hourlyScanGoal,
            supervisorPassword:
              userGeneralInfo[0]?.scanUser?.supervisorPassword,
            id: userGeneralInfo[0]?.scanUser?.id,
          },
        });
      } else {
        Object.assign(body, { scanUser: {} });
      }
    }
    //  await axios.post(endPointURL, body, { headers: { 'Authorization': `Bearer ${token}` } }).then(
    await axios
      .post(endPointURL, body)
      .then(
        // (res) => {
        //     notify('User updated successfully', 'success');
        //     getUsers('Users');
        //    // clearPreview();
        // }
        (res: any) => {
          if (res.data.status !== 200) {
            notify(res.data.message, "error");
          } else {
            notify(res.data.message, "success");
            getUsers("Users");
          }
          setTimeout(() => {
           // dispatch(setRowData(res.data[0]));
            dispatch(setPreviewImage([]));
           
          }, 10);
          // clearPreview();
        }
      )
      .catch((err) => {
        console.log(err, "Users");
        notify("Failed to update user", "error");
      });
  };

  /* Get Users WS Call */
  const getUsers = async (fieldName: any) => {
    // const token = sessionStorage.getItem('token');
    // const token = UserService.getToken()
    const getEndPointUrl = getAccessMgmtEndPoint(fieldName);
    // await axios.get(getEndPointUrl, { headers: { 'Authorization': `Bearer ${token}` } }).then((res: { data: any; }) => {
    await axios
      .get(getEndPointUrl)
      .then((res: { data: any }) => {
        dispatch(setUserAccessData(res.data));
        const dataa = res.data[0];
        setUserGenInfo(dataa);
        setTimeout(() => {
          dispatch(setRowData(userGeneralInfo));
          setUpdateduserGenInfo(userGeneralInfo);
        }, 5);
      })
      .catch((err: any) => {
        console.log(err);
        notify(
          "Error! Unable to fetch data. Please try after sometime.",
          "error"
        );
      });
  };

  const clearPreview = () => {
    dispatch(setRowData([]));
    dispatch(setUploadedImage([]));
  };

  /* Update fields data  */
  const userInfoFieldFocusOut = (e: any, key: any) => {
  
    if (key === "endDate") {
      setUserGenInfo([{ ...userGeneralInfo[0], [key]: e.value }]);
    } else {
      setUserGenInfo([{ ...userGeneralInfo[0], [key]: e.value }]);
    }
  };

  /* General User Preview Tab */
  const userFieldsSelectFocusOut = (event: any, key: any) => {
    if (key === "storeId") {
   
      setUserGenInfo([{ ...userGeneralInfo[0], [key]: event.value }]);
      //setStoreId(0);
      //validateMobileUsers()
    } else {
      setUserGenInfo([{ ...userGeneralInfo[0], [key]: event.target.value }]);
    }
  };

  const formatInput = (e: any) => {
    selectedEndDate = formatDate(new Date(e));
  };

  const updateStoreId = () => {
    //setShowStore(true);
    setStoreId(0);
  };
  /* General User Preview Tab */
  
    const handleRenderModal = (type : any, userId: any) => {
      localStorage.setItem("userId",userId);

      setAddScreen(true);
      setAddScreenTabName(type);
    };

  const UserGeneralPreviewData = (data: any) => {
    return (
      <div>
      {addScreen && (
        <AddAccessManagementScreen
          addScreen={addScreen}
          setAddScreen={setAddScreen}
          tabName={addScreenTabName}
        />
      )}
      
        <div className="row">
          <div className="col-sm-12 px-0">
            <div className="col-sm-5 col-md-5 col-lg-5 pr-0 preview-Key" style={{paddingLeft: '8px'}}>
              User Type
            </div>
            <div className="col-sm-7 col-md-7 col-lg-7 p-0 preview-value ">
              <p className="accMgmtFields">{userGeneralInfo[0]?.userType}</p>
            </div>
          </div>
        </div>
        <hr className="previewScreenDevider mt-0 mb-1"></hr>
        <div className="row">
          <div className="col-sm-12 px-0 pt-0">
            <div
              className="col-sm-5 col-md-5 col-lg-5 preview-Key "
              style={{ padding: "7px" }}
            >
              First Name
            </div>

            <div className="col-sm-7 col-md-7 col-lg-7 p-0 preview-value ">
              <input
                type="text"
                className="form-control boxShadow-None border-none cursor-pointer txtEditFieldFocus accMgmtFields boxShadow-None pl-0"
                value={userGeneralInfo[0]?.firstname}
                onChange={(e: any) =>
                  userInfoFieldFocusOut(e.target, "firstname")
                }
                onBlur={(e: any) =>
                  userInfoFieldFocusOut(e.target, "firstname")
                }
                onKeyUp={(e: any) =>
                  userInfoFieldFocusOut(e.target, "firstname")
                }
              />
              {/* <InlineInputEdit inputClassName="txtEditClassNameInput  "  text={selRowData && selRowData[0]?.firstname} labelClassName="txtEditClassName"
                            onFocusOut={(e) => userInfoFieldFocusOut(e, 'firstname')} labelPlaceHolder='---'/>  */}
            </div>
          </div>
          <div className="col-sm-12 px-0 pt-4 ">
            <div
              className="col-sm-5 col-md-5 col-lg-5 preview-Key  "
              style={{ padding: "8px" }}
            >
              Last Name
            </div>
            <div className="col-sm-7 col-md-7 col-lg-7 p-0 preview-value ">
              <input
                type="text"
                className="form-control boxShadow-None border-none cursor-pointer txtEditFieldFocus accMgmtFields boxShadow-None pl-0 "
                onChange={(e: any) =>
                  userInfoFieldFocusOut(e.target, "lastName")
                }
                value={userGeneralInfo[0]?.lastName}
                onBlur={(e: any) => userInfoFieldFocusOut(e.target, "lastName")}
                onKeyUp={(e: any) =>
                  userInfoFieldFocusOut(e.target, "lastName")
                }
              />
              {/* <InlineInputEdit inputClassName="txtEditClassNameInput  "  text={selRowData && selRowData[0]?.lastName} labelClassName="txtEditClassName"
                        onFocusOut={(e) => userInfoFieldFocusOut(e, 'lastName')} labelPlaceHolder='---'/>  */}
            </div>
          </div>
          <div className="col-sm-12 px-0 pt-4 ">
            <div
              className="col-sm-5 col-md-5 col-lg-5 preview-Key  "
              style={{ padding: "8px" }}
            >
              Company
            </div>
            <div className="col-sm-7 col-md-7 col-lg-7 p-0 preview-value ">
              <input
                type="text"
                className="form-control boxShadow-None border-none cursor-pointer txtEditFieldFocus accMgmtFields boxShadow-None pl-0"
                value={userGeneralInfo[0]?.company}
                onChange={(e: any) =>
                  userInfoFieldFocusOut(e.target, "company")
                }
                onBlur={(e: any) => userInfoFieldFocusOut(e.target, "company")}
                onKeyUp={(e: any) => userInfoFieldFocusOut(e.target, "company")}
              />
              {/* <InlineInputEdit inputClassName="txtEditClassNameInput  "  text={userGeneralInfo[0]?.company} labelClassName="txtEditClassName"
                             onFocusOut={(e) => userInfoFieldFocusOut(e, 'company')} labelPlaceHolder='---'/>  */}
            </div>
          </div>
          <div className="col-sm-12 px-0 pt-4 ">
            <div
              className="col-sm-5 col-md-5 col-lg-5 preview-Key  "
              style={{ padding: "8px" }}
            >
              Job Title
            </div>
            <div className="col-sm-7 col-md-7 col-lg-7 p-0 preview-value ">
              <input
                type="text"
                className="form-control boxShadow-None border-none cursor-pointer txtEditFieldFocus accMgmtFields boxShadow-None pl-0 "
                value={userGeneralInfo[0]?.jobTitle}
                onChange={(e: any) =>
                  userInfoFieldFocusOut(e.target, "jobTitle")
                }
                onBlur={(e: any) => userInfoFieldFocusOut(e.target, "jobTitle")}
                onKeyUp={(e: any) =>
                  userInfoFieldFocusOut(e.target, "jobTitle")
                }
              />
              {/* <InlineInputEdit inputClassName="txtEditClassNameInput  "  text={userGeneralInfo[0]?.jobTitle} labelClassName="txtEditClassName"
                            onFocusOut={(e) => userInfoFieldFocusOut(e, 'jobTitle')} labelPlaceHolder='---'/>  */}
            </div>
          </div>
          <div className="col-sm-12 px-0 pt-4 ">
            <div
              className="col-sm-5 col-md-5 col-lg-5 preview-Key  "
              style={{ padding: "8px" }}
            >
              Username
            </div>
            <div className="col-sm-7 col-md-7 col-lg-7 p-0 preview-value ">
              <p className="accMgmtFields mb-0 mt-1" >{userGeneralInfo[0]?.username}</p>
            </div>
            {/* <div className="col-sm-6 col-md-6 col-lg-7 preview-value ">
              <input
                type="text"
                className="form-control boxShadow-None border-none cursor-pointer txtEditFieldFocus accMgmtFields boxShadow-None pl-0"
                value={userGeneralInfo[0]?.username}
                onChange={(e: any) =>
                  userInfoFieldFocusOut(e.target, "username")
                }
                onBlur={(e: any) => userInfoFieldFocusOut(e.target, "username")}
                onKeyUp={(e: any) =>
                  userInfoFieldFocusOut(e.target, "username")
                }
              />
           
            </div> */}
          </div>
          {userGeneralInfo[0].userType === "Mobile" ||
          userGeneralInfo[0].userType === "mobile" ? (
            <div className="col-sm-12 px-0 pt-4 ">
              <div
                className="col-sm-5 col-md-5 col-lg-5 preview-Key  "
                style={{ padding: "8px" }}
              >
                Store
              </div>
              <div className="col-sm-7 col-md-7 col-lg-7 p-0 preview-value ">
                {/* {storeId !== null && storeId !== undefined && storeId !== 0 ? <span className="col-sm-12 px-0 py-2">
                            <p className="moduleItem">
                            <span className="module-access-value">
                                {userGeneralInfo[0]?.storeId}
                            </span>
                        </p>
                      </span> : ''} */}
                {showStore ? (
                  <DropDownListComponent
                    allowFiltering={true}
                    ref={(scope) => {
                      dropDownListObject = scope;
                    }}
                    dataSource={storesList}
                    filtering={getStoreNamewithMainFrameCustomer.bind(this)}
                    fields={autoCompleteFields}
                    change={(e: any) => userFieldsSelectFocusOut(e, "storeId")}
                  />
                ) : (
                  <span
                    className="module-access-value pointer accMgmtFields"
                    onClick={() => setShowStore(true)}
                    style={{ fontSize: "12px", fontWeight: 100, padding: "8px 0px"}}
                  >
                   {storeId}
                  </span>
                )}
              </div>
            </div>
          ) : (
            <></>
          )}
          <div className="col-sm-12 px-0 pt-4 ">
            <div
              className="col-sm-5 col-md-5 col-lg-5 preview-Key pt-1 "
              style={{ padding: "8px" }}
            >
              Role
            </div>
            <div className="col-sm-7 col-md-7 col-lg-7 p-0 preview-value pl-0">
              <select
                className="form-control border-none storeNumber accMgmtFields p-0"
                value={userGeneralInfo[0].roleId}
                onChange={(e) => userFieldsSelectFocusOut(e, "roleId")}
              >
                {/* <option value={userGeneralInfo[0]?.roleId}>{userGeneralInfo[0]?.roleName}</option> */}
                {rolesListData?.map((role) => (
                  <option value={role?.id}>{role?.roleName}</option>
                ))}
              </select>
            </div>
          </div>
          <div className="col-sm-12 px-0 pt-4 ">
            <div
              className="col-sm-5 col-md-5 col-lg-5 preview-Key pt-1 "
              style={{ padding: "8px" }}
            >
              Location
            </div>
            <div className="col-sm-7 col-md-7 col-lg-7 p-0 preview-value pl-0">
              {userGeneralInfo[0]?.reclaimCenterId !== undefined &&
              userGeneralInfo[0]?.reclaimCenterId !== null &&
              userGeneralInfo[0]?.reclaimCenterId !== "" ? (
                <select
                  className="form-control border-none storeNumber accMgmtFields p-0"
                  value={userGeneralInfo[0]?.reclaimCenterId}
                  onChange={(e) =>
                    userFieldsSelectFocusOut(e, "reclaimCenterId")
                  }
                >
                  {/* <option value={userGeneralInfo[0]?.location}>{userGeneralInfo[0]?.locationName}</option> */}
                  {locationsDataList?.map((rc) => (
                    <option value={rc?.id}>{rc?.reclaimcenterName}</option>
                  ))}
                </select>
              ) : (
                <select
                  className="form-control border-none storeNumber p-0"
                  onChange={(e) =>
                    userFieldsSelectFocusOut(e, "reclaimCenterId")
                  }
                >
                  <option value="">Select Location</option>
                  {locationsDataList?.map((rc) => (
                    <option value={rc?.id}>{rc?.reclaimcenterName}</option>
                  ))}
                </select>
              )}
            </div>
          </div>
          <div className="col-sm-12 px-0 pt-4 ">
            <div
              className="col-sm-5 col-md-5 col-lg-5 preview-Key  pt-1"
              style={{ padding: "8px" }}
            >
              End Date
            </div>
            <div className="col-sm-7 col-md-7 col-lg-7 p-0">
              <DatePickerComponent
                value={userGeneralInfo[0]?.endDate}
                change={(e) => userInfoFieldFocusOut(e, "endDate")}
              ></DatePickerComponent>
              {/* {selectedEndDate ? <span>{selectedEndDate}</span> : <></> } */}
              {/* {selectedEndDate !== undefined && selectedEndDate !== '' ?
                         <input type="date" className="float-left ml-3" value="formatInput()" onChange={(e) => userInfoFieldFocusOut(e,'endDate')} />
                       : <input type="date" className="float-left ml-3" value="2022-08-15" onChange={(e) => userInfoFieldFocusOut(e,'endDate')} /> } */}
            </div>
          </div>
          {userGeneralInfo[0].userType === "Mobile"  || userGeneralInfo[0].userType === "External" ||
          userGeneralInfo[0].userType === "mobile" || userGeneralInfo[0].userType === "external" ? (
            <div className="col-sm-12 px-0 pt-4 ">
            {/* <div
              className="col-sm-7 preview-Key  pt-1"
              style={{ padding: "8px" }}>
              </div> */}
              <div
              className="col-sm-12 preview-Key  pt-1"
              style={{ padding: "8px" }}>
              <ButtonComponent
            cssClass="e-outline opt-btn-light float-right"
            onClick={(e) =>
              handleRenderModal('Reset' ,  userGeneralInfo[0].userId)
          }          >
            {/* <img src={saveIcon} alt="Reset Password" /> */}
             Reset Password
          </ButtonComponent>
          </div>
              </div>
          ) : (
            <></>
          )}
          
        </div>
      
      </div>
    );
  };

  /* Footer Preview  */
  const footerPreview = (data: any) => {
    return (
      <div className="col-sm-12 px-0 pt-4">
        <div className="col-sm-6 col-md-6 col-lg-5 preview-Key p-0">
          <ButtonComponent
            cssClass="e-outline opt-btn-light float-left"
            onClick={() => updateUserGeneralTabApi(data)}
          >
            <img src={saveIcon} alt="Save" />
          </ButtonComponent>

          <ButtonComponent
            cssClass="e-outline opt-btn-light float-left ml-3"
            onClick={() => clearPreview()}
          >
            <img src={closeIcon} alt="Close" />
          </ButtonComponent>
        </div>
        <div className="col-sm-6 col-md-6 col-lg-7 preview-value pt-3">
          <p className="preview-status">
            {data?.activeForGrid === "Active" ||
            data?.activeForGrid === "active" ? (
              <span>
                <img
                  src={activeIcon}
                  alt="Status"
                  onClick={() => changeStatusForRowData(false)}
                />
                &nbsp; &nbsp; Active
              </span>
            ) : (
              <span>
                <img
                  src={inactiveIcon}
                  alt="Status"
                  onClick={() => changeStatusForRowData(true)}
                />
                &nbsp; &nbsp; Inactive
              </span>
            )}
          </p>
        </div>
      </div>
    );
  };

  return (
    <div>
      {userGeneralInfo && userGeneralInfo.length > 0 ? (
        <div className="col-sm-12 user-preview-details px-4 py-3">
          <div className="col-sm-12 px-3 py-3">
            {UserGeneralPreviewData(userGeneralInfo[0])}
          </div>
          <div className="col-sm-12 px-0 pt-2 pb-4">
            {footerPreview(userGeneralInfo[0])}
          </div>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default UserGeneralPreview;
