import { environment } from './environment';

export const baseURL = environment.react_app_url; //process.env.REACT_APP_URL;
export const clientContext = environment.react_app_client_context; //process.env.REACT_APP_CLIENT_CONTEXT;
export const API_PATH = {
    ITEMS_CATALOG_FILTER_PATH: '/v1/masterdata/itemcatalogue/filter',
    ITEM_CATALOG_ITEM: '/v1/masterdata/itemcatalog/items/',
    SCANNED_REPORTS_PATH: '/v1/reports/scan/preview',
    SAVE_SCANNED_REPORT_CRITERIA_PATH: '/v1/reports/scan',
    SCANNED_REPORTS_LIST: '/v1/reports/scan/list',
    GET_SCANNED_REPORT_BASED_ON_ID: '/v1/reports/scan/',
    DOWNLOAD_SCANNED_REPORT_BASED_ON_ID: '/v1/reports/scan/',
    PRINTER_SETTINGS: '/v1/masterdata/printer-settings/',
    PRINTER_SETTINGS_JSPM: '/v1/masterdata/printer-settings/jspm',
    STORES_PATH: '/v1/masterdata/store/list/filter',
    VENDORS_PATH: '/v1/masterdata/vendor/list/filter',
    RULE_EXCEPTIONS_PATH: '/v1/masterdata/ruleexception/list/filter',
    RULE_EXCEPTIONS_RULE: '/v1/masterdata/ruleexception/items/',
    RULE_EXCEPTIONS_BY_ITEM_CATALOG: '/v1/masterdata/ruleexception/list',
    STORES_ITEM: '/v1/masterdata/store/item/',
    VENDORS_ITEM: '/v1/masterdata/vendor/items/',
    CLOSED_PALLETS: '/v1/scan/pallet/closed-list',
    CLOSED_OUTBOUND_BOXES: '/v1/scan/box/closed-list',
    CLOSED_BINS: '/v1/scan/bin/closed-list',
    AR_INVOICE_PATH: '/v1/invoice/arinvoices/list/filter',
    AR_INVOICE_DETAILS: '/v1/invoice/arinvoices/items/',
    CREDIT_MEMO_FILTER: '/v1/invoices/creditmemo/list/filter'
};

export const getApiBaseUrl = () => {
    let url = baseURL;
    if (clientContext) {
        url = url + clientContext;
    }
    return url;
};
