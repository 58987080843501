import {createSlice} from "@reduxjs/toolkit";

export interface SideBar {
    userData: any[];
    loggedInUserData: any[];
    accessToken: any[];
    loggdInUserName: any[];
    loggedInUserId: any[];
}

const initialState: SideBar = {
    userData: [],
    loggedInUserData: [],
    accessToken: [],
    loggdInUserName: [],
    loggedInUserId: []
};

export const SideBarSlice = createSlice({
    name: "SideBar",
    initialState,
    reducers: {
        setUserData: (state, {payload}) => {
            state.userData = payload;
        },
        setLoggedInUserInfo: (state, {payload}) => {
            state.loggedInUserData = payload;
        },
        setAccessToken: (state, {payload}) => {
            state.accessToken = payload;
        },
        setLoggedinUsername: (state, {payload}) => {
            state.loggdInUserName = payload;
        },
        setLoggedInUserId: (state, {payload}) => {
            state.loggedInUserId = payload;
        },
    },
});

// Action creators are generated for each case reducer function
export const {
    setUserData,
    setLoggedInUserInfo,
    setAccessToken,
    setLoggedinUsername,
    setLoggedInUserId
} = SideBarSlice.actions;

export default SideBarSlice.reducer;
