import { createSlice } from '@reduxjs/toolkit';

export interface contactsState {
    uploadedImage: any[];
    stores: any[];
    storesConfig: {};
    warehouses: any[];
    reclaimCenters: any[];
    carriers: any[];
    vendors: {};
    customers: any[];
    partnerSettings: any[];
    secondaryRetails: any[];
    currentContact: any[];
    companyCodes: any[];
    tabHeading: any;
    currencyData: any[];
    laungageData: any[];
    timezoneData: any[];
    clientsData: any[];
    customerTypeData: any[];
    storeTypeData: any[];
    superGroupData: any[];
    clientGroupData: any[];
    statusData: any[];
    reasonCodeData: any[];
    paymentTermsData: any[];
    billingModeData: any[];
    glAccountMasterData: any[];
    departmentsMasterData: any[];
    pricingTypeMasterData: any[];
    palletNameMasterData: any[];
    scanGroupMasterData: any[];
    BinData: any[];
    dispositionData: any[];
    selectedRCIdforBinException: any[];
    itemCatalogData: any[];
    debitReason: any[];
    fiscalYears: any[];
    tabNameForRowData: any;
    fiscalYearTemplate: any[];
    fiscalYearPeriods: any[];
    countryData: any[];
    countryCodePhoneData: any[];
    WhareHouseLocationData: any[];
    WHUPCDefaultData: any[];
    WHCustomData: any[];
    tabNameWHLoc: any;
    reclaimCentersList: any;
    statesData: any[];
    statesAddrDataOne: any[];
    statesAddrDataTwo: any[];
    palletNameForViewScreen: any[];
    storeDispositionList: any[];
    scanGroupListByRC: any[];
    descriptionForItemCatalogView: any[];
    RCNameForBinExceptionView: any[];
    selectedRecordsForExport: any[];
    RECodeForRuleExceptionView: any[];
    selectedMobileWithCountryFlag: any;
    selectedAltMobileWithCountryFlag: any;
    selectedFaxWithCountryFlag: any;
    selectedCountryCodeForMobile: any;
    selectedCountryCodeForAltMobile: any;
    selectedCountryCodeForFax: any;
    selectedMobileWithCountryFlagPreview: any;
    selectedAltMobileWithCountryFlagPreview: any;
    selectedFaxWithCountryFlagPreview: any;
    selectedCountryCodeForMobilePreview: any;
    selectedCountryCodeForAltMobilePreview: any;
    selectedCountryCodeForFaxPreview: any;
    showUpdateAndCancelIconsOnPreview: boolean;
    updateStoreOrVendorData: any[];
    inentoryNameForMasterData: any[];
    upcData: any[];
}

const initialState: contactsState = {
    uploadedImage: [],
    stores: [],
    storesConfig: {},
    warehouses: [],
    reclaimCenters: [],
    carriers: [],
    vendors: [],
    customers: [],
    partnerSettings: [],
    secondaryRetails: [],
    currentContact: [],
    companyCodes: [],
    tabHeading: [],
    currencyData: [],
    laungageData: [],
    timezoneData: [],
    clientsData: [],
    customerTypeData: [],
    storeTypeData: [],
    superGroupData: [],
    clientGroupData: [],
    statusData: [],
    reasonCodeData: [],
    paymentTermsData: [],
    billingModeData: [],
    glAccountMasterData: [],
    departmentsMasterData: [],
    pricingTypeMasterData: [],
    palletNameMasterData: [],
    scanGroupMasterData: [],
    BinData: [],
    dispositionData: [],
    selectedRCIdforBinException: [],
    itemCatalogData: [],
    debitReason: [],
    fiscalYears: [],
    tabNameForRowData: [],
    fiscalYearTemplate: [],
    fiscalYearPeriods: [],
    countryData: [],
    countryCodePhoneData: [],
    WhareHouseLocationData: [],
    WHUPCDefaultData: [],
    WHCustomData: [],
    tabNameWHLoc: [],
    reclaimCentersList: [],
    statesData: [],
    statesAddrDataOne: [],
    statesAddrDataTwo: [],
    palletNameForViewScreen: [],
    inentoryNameForMasterData: [],
    storeDispositionList: [],
    scanGroupListByRC: [],
    descriptionForItemCatalogView: [],
    RCNameForBinExceptionView: [],
    selectedRecordsForExport: [],
    RECodeForRuleExceptionView: [],
    selectedMobileWithCountryFlag: null,
    selectedAltMobileWithCountryFlag: null,
    selectedFaxWithCountryFlag: null,
    selectedCountryCodeForMobile: '',
    selectedCountryCodeForAltMobile: '',
    selectedCountryCodeForFax: '',
    selectedMobileWithCountryFlagPreview: null,
    selectedAltMobileWithCountryFlagPreview: null,
    selectedFaxWithCountryFlagPreview: null,
    selectedCountryCodeForMobilePreview: '',
    selectedCountryCodeForAltMobilePreview: '',
    selectedCountryCodeForFaxPreview: '',
    showUpdateAndCancelIconsOnPreview: false,
    updateStoreOrVendorData: [],
    upcData: [],
};

export const contactSlice = createSlice({
    name: 'contacts',
    initialState,
    reducers: {
        setMasterDataAddImage: (state, { payload }) => {
            state.uploadedImage = payload;
        },
        setStores: (state, { payload }) => {
            state.stores = payload;
        },
        setStoresConfig: (state, { payload }) => {
            state.storesConfig = payload;
        },
        setWarehouses: (state, { payload }) => {
            state.warehouses = payload;
        },
        setReclaimCenters: (state, { payload }) => {
            state.reclaimCenters = payload;
        },
        setCarriers: (state, { payload }) => {
            state.carriers = payload;
        },
        setVendors: (state, { payload }) => {
            state.vendors = payload;
        },
        setCustomers: (state, { payload }) => {
            state.customers = payload;
        },
        setPartnerSettings: (state, { payload }) => {
            state.partnerSettings = payload;
        },
        setSecondaryRetails: (state, { payload }) => {
            state.secondaryRetails = payload;
        },
        setCurrentContact: (state, { payload }) => {
            state.currentContact = payload;
        },
        setCompanyCodes: (state, { payload }) => {
            state.companyCodes = payload;
        },
        setTabHeading: (state, payload) => {
            state.tabHeading = payload;
        },
        setCurrencyData: (state, { payload }) => {
            state.currencyData = payload;
        },
        setLaungageData: (state, { payload }) => {
            state.laungageData = payload;
        },
        setTimezoneData: (state, { payload }) => {
            state.timezoneData = payload;
        },
        setClientsData: (state, { payload }) => {
            state.clientsData = payload;
        },
        setCustomerTypeData: (state, { payload }) => {
            state.customerTypeData = payload;
        },
        setStoreTypeData: (state, { payload }) => {
            state.storeTypeData = payload;
        },
        setSuperGroupData: (state, { payload }) => {
            state.superGroupData = payload;
        },
        setClientGroupData: (state, { payload }) => {
            state.clientGroupData = payload;
        },
        setStatusData: (state, { payload }) => {
            state.statusData = payload;
        },
        setReasonCodeData: (state, { payload }) => {
            state.reasonCodeData = payload;
        },
        setPaymentTermsData: (state, { payload }) => {
            state.paymentTermsData = payload;
        },
        setBillingModeData: (state, { payload }) => {
            state.billingModeData = payload;
        },
        setGLAccountMasterData: (state, { payload }) => {
            state.glAccountMasterData = payload;
        },
        setDepartmentMasterData: (state, { payload }) => {
            state.departmentsMasterData = payload;
        },
        setPricingTypeMasterData: (state, { payload }) => {
            state.pricingTypeMasterData = payload;
        },
        setPalletNameMasterData: (state, { payload }) => {
            state.palletNameMasterData = payload;
        },
        setInventoryNameMasterData: (state, { payload }) => {
            state.inentoryNameForMasterData = payload;
        },
        setScanGroupMasterData: (state, { payload }) => {
            state.scanGroupMasterData = payload;
        },
        setBinData: (state, { payload }) => {
            state.BinData = payload;
        },
        setDispositionData: (state, { payload }) => {
            state.dispositionData = payload;
        },
        setSelectedRCIdForBin: (state, { payload }) => {
            state.selectedRCIdforBinException = payload;
        },
        setItemCatalogData: (state, { payload }) => {
            state.itemCatalogData = payload;
        },
        setDebitReasonData: (state, { payload }) => {
            state.debitReason = payload;
        },
        setFiscalYearsData: (state, { payload }) => {
            state.fiscalYears = payload;
        },
        setTabNameForRowData: (state, { payload }) => {
            state.tabNameForRowData = payload;
        },
        setFiscalYearTemplateData: (state, { payload }) => {
            state.fiscalYearTemplate = payload;
        },
        setFiscalYearPeriodsData: (state, { payload }) => {
            state.fiscalYearPeriods = payload;
        },
        setCountryData: (state, { payload }) => {
            state.countryData = payload;
        },
        setCountryPhoneData: (state, { payload }) => {
            state.countryCodePhoneData = payload;
        },
        setWHLocationsData: (state, { payload }) => {
            state.WhareHouseLocationData = payload;
        },
        setWHLocUPCDefaultData: (state, { payload }) => {
            state.WHUPCDefaultData = payload;
        },
        setWHLocCustomData: (state, { payload }) => {
            state.WHCustomData = payload;
        },
        setTabNameWHLocation: (state, payload) => {
            state.tabNameWHLoc = payload;
        },
        setReclaimCentersList: (state, payload) => {
            state.reclaimCentersList = payload;
        },
        setStatesData: (state, { payload }) => {
            state.statesData = payload;
        },
        setStatesAddrDataOne: (state, { payload }) => {
            state.statesAddrDataOne = payload;
        },
        setStatesAddrDataTwo: (state, { payload }) => {
            state.statesAddrDataTwo = payload;
        },
        setPalletName: (state, { payload }) => {
            state.palletNameForViewScreen = payload;
        },
        setStoreDispositionList: (state, { payload }) => {
            state.storeDispositionList = payload;
        },
        setScanGroupByRC: (state, { payload }) => {
            state.scanGroupListByRC = payload;
        },
        setDescriptioForItemCatalog: (state, { payload }) => {
            state.descriptionForItemCatalogView = payload;
        },
        setRCNameForBinExceptionView: (state, { payload }) => {
            state.RCNameForBinExceptionView = payload;
        },
        setSelectedRecrdsExport: (state, { payload }) => {
            state.selectedRecordsForExport = payload;
        },
        setRECodeForRuleExceptionView: (state, { payload }) => {
            state.RECodeForRuleExceptionView = payload;
        },
        setMobileWithCountry: (state, { payload }) => {
            state.selectedMobileWithCountryFlag = payload;
        },
        setAltMobileWithCountry: (state, { payload }) => {
            state.selectedAltMobileWithCountryFlag = payload;
        },
        setFaxWithCountry: (state, { payload }) => {
            state.selectedFaxWithCountryFlag = payload;
        },
        setCountryCodeForMobile: (state, { payload }) => {
            state.selectedCountryCodeForMobile = payload;
        },
        setCountryCodeForAltMobile: (state, { payload }) => {
            state.selectedCountryCodeForAltMobile = payload;
        },
        setCountryCodeForFax: (state, { payload }) => {
            state.selectedCountryCodeForFax = payload;
        },
        setMobileWithCountryPreview: (state, { payload }) => {
            state.selectedMobileWithCountryFlagPreview = payload;
        },
        setAltMobileWithCountryPreview: (state, { payload }) => {
            state.selectedAltMobileWithCountryFlagPreview = payload;
        },
        setFaxWithCountryPreview: (state, { payload }) => {
            state.selectedFaxWithCountryFlagPreview = payload;
        },
        setCountryCodeForMobilePreview: (state, { payload }) => {
            state.selectedCountryCodeForMobilePreview = payload;
        },
        setCountryCodeForAltMobilePreview: (state, { payload }) => {
            state.selectedCountryCodeForAltMobilePreview = payload;
        },
        setCountryCodeForFaxPreview: (state, { payload }) => {
            state.selectedCountryCodeForFaxPreview = payload;
        },
        setShowUpdateAndCancelIconsOnPreview: (state, { payload }) => {
            state.showUpdateAndCancelIconsOnPreview = payload;
        },
        setUpdatedStoresORVendorData: (state, { payload }) => {
            state.updateStoreOrVendorData = payload;
        },
        setUpcList: (state, { payload }) => {
            state.upcData = payload;
        },
    },
});

// Action creators are generated for each case reducer function
export const {
    setStores,
    setStoresConfig,
    setReclaimCenters,
    setCarriers,
    setVendors,
    setCustomers,
    setPartnerSettings,
    setSecondaryRetails,
    setWarehouses,
    setCurrentContact,
    setCompanyCodes,
    setTabHeading,
    setCurrencyData,
    setLaungageData,
    setTimezoneData,
    setClientsData,
    setCustomerTypeData,
    setStoreTypeData,
    setSuperGroupData,
    setClientGroupData,
    setStatusData,
    setReasonCodeData,
    setPaymentTermsData,
    setBillingModeData,
    setGLAccountMasterData,
    setDepartmentMasterData,
    setPricingTypeMasterData,
    setPalletNameMasterData,
    setScanGroupMasterData,
    setBinData,
    setDispositionData,
    setSelectedRCIdForBin,
    setItemCatalogData,
    setDebitReasonData,
    setFiscalYearsData,
    setTabNameForRowData,
    setFiscalYearTemplateData,
    setFiscalYearPeriodsData,
    setCountryData,
    setCountryPhoneData,
    setWHLocationsData,
    setWHLocUPCDefaultData,
    setWHLocCustomData,
    setTabNameWHLocation,
    setReclaimCentersList,
    setStatesData,
    setPalletName,
    setStoreDispositionList,
    setScanGroupByRC,
    setStatesAddrDataOne,
    setStatesAddrDataTwo,
    setDescriptioForItemCatalog,
    setRCNameForBinExceptionView,
    setSelectedRecrdsExport,
    setRECodeForRuleExceptionView,
    setMobileWithCountry,
    setAltMobileWithCountry,
    setFaxWithCountry,
    setCountryCodeForMobile,
    setCountryCodeForAltMobile,
    setCountryCodeForFax,
    setShowUpdateAndCancelIconsOnPreview,
    setMobileWithCountryPreview,
    setAltMobileWithCountryPreview,
    setFaxWithCountryPreview,
    setCountryCodeForMobilePreview,
    setCountryCodeForAltMobilePreview,
    setCountryCodeForFaxPreview,
    setUpdatedStoresORVendorData,
    setMasterDataAddImage,
    setInventoryNameMasterData,
    setUpcList,
} = contactSlice.actions;

export default contactSlice.reducer;
