import { ButtonComponent } from '@syncfusion/ej2-react-buttons';
import {
    TabComponent,
    TabItemDirective,
    TabItemsDirective,
} from '@syncfusion/ej2-react-navigations';
import saveIcon from '../../../icons/common/save.png';
import closeIcon from '../../../icons/common/close.png';
import activeIcon from '../../../icons/common/active.png';
import inactiveIcon from '../../../icons/common/inactive.png';
import { FC, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Query } from '@syncfusion/ej2-data';
import {
    getDynamicValues,
    getReportsEndPoint,
    getScanDateWS,
    getStoreWsUpdate,
    getUPCWs,
    getVendorIDWs,
    updateReportsEndPoint,
} from '../utils';
import axios from 'axios';
import notify from '../../../services/toasterService';
import { setSavedReportData } from '../reportreducer';
import './previewreport.scss';
import { DatePickerComponent } from '@syncfusion/ej2-react-calendars';
import excelIcon from '../../../assets/excel.png';
import pdfIcon from '../../../assets/pdf.png';
import ExcelService from '../../../services/excel';
import * as FileSaver from 'file-saver';
import { AsyncPaginate } from 'react-select-async-paginate';
import {
    CheckBoxSelection,
    Inject,
    MultiSelectComponent,
    DropDownListComponent,
} from '@syncfusion/ej2-react-dropdowns';

const previewTab = [
    { text: 'Parameters', id: '0' },
    { text: 'Settings', id: '1' },
];

/* Declaring Interfaces for getting inputs other tabs */
interface IPreviewTab {
    previewData: any[];
}

const ReportPreviewTab: FC<IPreviewTab> = ({ previewData }) => {
    const dispatch = useDispatch();
    const [paramsInfo, setparamsInfo] = useState<any>(
        previewData[0].reportParameters
    );
    const [settingsInfo, setSettingsInfo] = useState<any>(previewData[0]);
    const [wsParamsList, setWsParamsList] = useState<any>([]);
    const [formValues, setFormValues] = useState<any>({});
    const [urlParams, setUrlParams] = useState<any>({});

    const [loaderStatus, setLoaderStatus] = useState(true);
    const [myFormControl, setMyFormControl] = useState('');
    const [auditDateClass, setAuditDateClass] = useState('');
    const [locationClass, setLocationClass] = useState('');
    const [clientClass, setClientClass] = useState('');
    const [periodClass, setPeriodClass] = useState('');
    const [soldStatusClass, setSoldStatusClass] = useState('');
    const [customerNumberClass, setCustomerNumberClass] = useState('');
    const [sortCodeClass, setSortCodeClass] = useState('');
    const [scannedByClass, setScannedByClass] = useState('');
    const [commodityCodeClass, setCommodityCodeClass] = useState('');
    const [wareHouseLocationClass, setWareHouseLocationClass] = useState('');
    const [scanModeClass, setScanModeClass] = useState('');
    const [dispositionClass, setDispositionClass] = useState('');
    const [customerGroupClass, setCustomerGroupClass] = useState('');
    const [scannedByInternalClass, setScannedByInternalClass] = useState('');
    const [storeIdClass, setStoreIdClass] = useState('');
    const [vendorIdClass, setVendorIdClass] = useState('');
    const [upcClass, setUpcClass] = useState('');
    const [scanDateClass, setScanDateClass] = useState('');
    const [finalDispositionClass, setFinalDispositionClass] = useState('');
    const [exportEnabled, setExportEnabled] = useState(false);
    const [storeValues, onChange] = useState<any>([]);
    const [noParamReports, setNoParamReports] = useState(false);
    const [fromDateClass, setFromDateClass] = useState('');
    const [toDateClass, setToDateClass] = useState('');
    const [isAllSelected, setIsAllSelected] = useState(false);

    let minDate: Date = new Date();
    // let maxDate:Date = new Date();
    let eventKeyCode: any;

    const defaultAdditional = {
        page: 1,
    };

    const loadPageOptions = async (q: any, prevOptions: any, { page }: any) => {
        let res: any;
        if ((q === '' || q.length > 3) && page === 1) {
            const endPointURL = getStoreWsUpdate(q);
            res = await axios.get(endPointURL).then((res: any) => {
                return {
                    options: res.data.content,
                    hasMore: !res.last,
                    additional: {
                        page: page + 1,
                    },
                };
            });
            var list = res.options.map((store: any) => ({
                value: store.id,
                label: store.mainframeCustomerNumber,
            }));
            res.options = list;
        } else {
            res.options = prevOptions;
        }
        return res;
    };

    const [errors, setErrors] = useState<any>({
        locationError: '',
        auditDateError: '',
        clientGroupError: '',
        periodError: '',
        soldStatusError: '',
        customerNumberError: '',
        sortCodeError: '',
        commodityCodeError: '',
        scannedByError: '',
        warehouseLocationError: '',
        scanModeError: '',
        dispositionError: '',
        customerGroupError: '',
        scannedByInternalError: '',
        storeIdError: '',
        inputFieldError: '',
        vendorIdError: '',
        upcError: '',
        scanDateError: '',
        fromDateError: '',
        toDateError: '',
    });
    const tabName = previewData[0]?.tabName;
    const reportName = previewData[0].name;

    useEffect(() => {
        // Write for onload
        if (previewData?.length > 0) {
            setWsParamsList([]);
            setSettingsInfo([]);
            setFormValues({});
            setUrlParams({});
            setSettingsInfo(previewData[0]);
            onChange('');

            // Clear validations
            setMyFormControl('');
            setAuditDateClass('');
            setLocationClass('');
            setClientClass('');
            setPeriodClass('');
            setSoldStatusClass('');
            setCustomerNumberClass('');
            setSortCodeClass('');
            setScannedByClass('');
            setCommodityCodeClass('');
            setWareHouseLocationClass('');
            setScanModeClass('');
            setDispositionClass('');
            setCustomerGroupClass('');
            setScannedByInternalClass('');
            setStoreIdClass('');
            setVendorIdClass('');
            setUpcClass('');
            setScanDateClass('');
            setFromDateClass('');
            setToDateClass('');
            setFinalDispositionClass('');

            setErrors({
                locationError: '',
                auditDateError: '',
                clientGroupError: '',
                periodError: '',
                soldStatusError: '',
                customerNumberError: '',
                sortCodeError: '',
                commodityCodeError: '',
                scannedByError: '',
                warehouseLocationError: '',
                scanModeError: '',
                dispositionError: '',
                customerGroupError: '',
                scannedByInternalError: '',
                storeIdError: '',
                inputFieldError: '',
                vendorIdError: '',
                upcError: '',
                scanDateError: '',
                fromDateError: '',
                toDateError: '',
            });
        }
        getChildTabEvent();
    }, [previewData]);

    const changeSettings = (value: any, key: any) => {
        setSettingsInfo({ ...settingsInfo, [key]: value });
    };

    const clearPreview = () => {
        setFormValues({});
        onChange('');
        setUrlParams({});
        notify('Changes discarded', 'error');
    };

    const clearSettingsPreview = () => {
        setSettingsInfo([]);
        setSettingsInfo(previewData[0]);
        notify('Changes discarded', 'error');
    };

    const getChildTabEvent = () => {
       
        if (previewData[0]?.reportParameters?.length > 0) {
            Promise.all(
                previewData[0]?.reportParameters.map((element: any) => {
                    return new Promise((resolve) => {
                        getDynamicReportAPI(element, resolve);
                    });
                })
            ).then((res) => {
                let paramsCopy = {};
                res.map((item: any) => {
                    if (item.mandatoryField === true) {
                        Object.assign(paramsCopy, { [item.parameter]: '' });
                    }
                });
                if (Object.keys(paramsCopy).length > 0) {
                    setFormValues(paramsCopy);
                    setExportEnabled(true);
                } else {
                    // console.log('No validation needed');
                    setExportEnabled(false);
                    setFormValues({});
                }
                setLoaderStatus(false);
                setWsParamsList(res);
                setNoParamReports(false);
                console.log(wsParamsList, 'Report Preview Columns');
            });
        } else {
            // console.log(previewData);
            // if (previewData[0].id === 18) {
            //     setNoParamReports(true);
            // } else {
            //     setNoParamReports(false);
            // }
            console.log(wsParamsList, 'No parameters found for this report');
            setNoParamReports(true);
            setExportEnabled(false);
            setFormValues({});
            setTimeout(() => {
                setLoaderStatus(false);
            }, 250);

            // Write some code if reportParams does not have data
        }
    };

    const getDynamicReportAPI = async (report: any, resolve: any) => {
        setLoaderStatus(true);
        if (report.type === 'dropdown') {
            if (
                report.parameter !== 'storeId' &&
                report.parameter !== 'upc' &&
                report.parameter !== 'vendorId' &&
                report.parameter !== 'scanDate' &&
                report.parameter !== 'storeNumber' &&
                report.parameter !== 'mainframeCustomerNumber' 

            ) {
                const endPointURL = getDynamicValues(report.parameter);
                await axios
                    .get(endPointURL)
                    .then((res: any) => {
                        if (res.data.length > 0) {
                            if (report.parameter === 'scannedBy') {
                                res.data.map((item: any) => {
                                    const scannedByComboInfo =
                                        item.username +
                                        ' - ' +
                                        item.id +
                                        '(' +
                                        item.mainframeCustomerNumber +
                                        ')';
                                    Object.assign(item, {
                                        scannedByCombo: scannedByComboInfo,
                                    });
                                });
                            }

                            if (report.parameter === 'scannedByInternal') {
                                res.data.map((item: any) => {
                                    const scannedByInternalComboInfo =
                                        item.id + ' - ' + item.username;
                                    Object.assign(item, {
                                        scannedByInternalCombo:
                                            scannedByInternalComboInfo,
                                    });
                                });
                            }

                            if (
                                report.parameter === 'client' ||
                                report.parameter === 'customerGroup'
                            ) {
                                res.data.map((item: any) => {
                                    const clientGroupComboInfo =
                                        item.clientGroupNumber +
                                        ' - ' +
                                        item.clientGroupName;
                                    Object.assign(item, {
                                        clientGroupCombo: clientGroupComboInfo,
                                    });
                                });
                            }

                            if (report.parameter === 'customerNumber' || report.parameter === 'salvageCustomerNumber') {
                                res.data.map((item: any) => {
                                    const customerComboInfo =
                                        item.customerNumber +
                                        ' - ' +
                                        item.customerName;
                                    Object.assign(item, {
                                        customerCombo: customerComboInfo,
                                    });
                                });
                            }

                            if (report.parameter === 'customerGroup') {
                                res.data.map((item: any) => {
                                    const customerGroupComboInfo =
                                        item.clientGroupNumber +
                                        ' - ' +
                                        item.clientGroupName;
                                    Object.assign(item, {
                                        customerGroupCombo:
                                            customerGroupComboInfo,
                                    });
                                });
                            }

                            if (report.parameter === 'period') {
                                res.data.map((item: any) => {
                                    const periodComoboInfo =
                                        item.id + '---' + item.period;
                                    Object.assign(item, {
                                        periodComobo: periodComoboInfo,
                                    });
                                });
                            }

                            resolve({ ...report, wsData: res.data });
                        } else {
                            resolve({ ...report, wsData: res.data });
                        }
                    })
                    .catch((err) => {
                        if (err) {
                            console.log(
                                err,
                                'Error While getting dropdown data with ' +
                                    report.parameter
                            );
                            notify(err.response?.data?.message, 'error');
                        }
                        resolve({ ...report });
                    });
            } else if (
                report.parameter === 'storeId' ||
                report.parameter === 'storeNumber' ||
                report.parameter === 'mainframeCustomerNumber' 
            ) {
                const endPointURL = getStoreWsUpdate('ABC');
                await axios
                    .get(endPointURL)
                    .then((res: any) => {
                        resolve({ ...report, wsData: res.data.content });
                    })
                    .catch((err) => {
                        if (err) {
                            console.log(
                                err,
                                'Error While getting dropdown data with ' +
                                    report.parameter
                            );
                            notify(err.response?.data?.message, 'error');
                        }
                        resolve({ ...report });
                    });
            }
           if (
                report.parameter === 'finalDisposition'
            ) {
                const endPointURL = getDynamicValues('finalDisposition');
                await axios
                    .get(endPointURL)
                    .then((res: any) => {
                        const reportObj = {...report};
                        Object.assign(reportObj,{
                            wsData: res.data
                          });
                   report = reportObj;
                    })
                    .catch((err) => {
                        if (err) {
                            console.log(
                                err,
                                'Error While getting dropdown data with ' +
                                    report.parameter
                            );
                            notify(err.response?.data?.message, 'error');
                        }
                        resolve({ ...report });
                    });
            }
             else if (report.parameter === 'upc') {
                const endPointURL = getUPCWs('12');
                await axios
                    .get(endPointURL)
                    .then((res: any) => {
                        resolve({ ...report, wsData: res.data.content });
                    })
                    .catch((err) => {
                        if (err) {
                            console.log(
                                err,
                                'Error While getting dropdown data with ' +
                                    report.parameter
                            );
                            notify(err.response?.data?.message, 'error');
                        }
                        resolve({ ...report });
                    });
            } else if (report.parameter === 'vendorId') {
                const endPointURL = getVendorIDWs();
                const body = {
                    filters: [],
                    sort: { key: 'vendorName', direction: 'ascending' },
                    page: {
                        pageNumber: 0,
                        pageSize: 50,
                    },
                };
                await axios
                    .post(endPointURL, body)
                    .then((res: any) => {
                        resolve({ ...report, wsData: res.data?.result });
                    })
                    .catch((err) => {
                        if (err) {
                            console.log(
                                err,
                                'Error While getting dropdown data with ' +
                                    report.parameter
                            );
                            notify(err.response?.data?.message, 'error');
                        }
                        resolve({ ...report });
                    });
            } else if (report.parameter === 'scanDate') {
                resolve({ ...report, wsData: [] });
            } else {
                // Write something you need
            }
        } else {
            // console.log('No data found for this parameter ' + report.parameter);
            resolve({ ...report });
        }
        setLoaderStatus(false);
    };

    const updateReportSettings = async () => {
        const apiURL = updateReportsEndPoint('Settings');
        const body = {
            id: settingsInfo?.id,
            name: settingsInfo?.name,
            type: settingsInfo?.type,
            permissions: settingsInfo?.permissions,
            status: settingsInfo?.status,
        };
        await axios
            .post(apiURL, body)
            .then((res) => {
                notify('Settings updated Successfully', 'success');
                getSavedReports();
            })
            .catch((err) => {
                notify('Failed to update data', 'error');
            });
    };

    const getSavedReports = async () => {
        const endPointURL = getReportsEndPoint('Saved Reports');
        await axios
            .get(endPointURL)
            .then((res) => {
                dispatch(setSavedReportData(res.data));
            })
            .catch((err) => {
                notify('Failed to fetch data', 'error');
            });
    };

    const changeStatusForRowData = (flag: any) => {
        if (flag === false) {
            setSettingsInfo({ ...settingsInfo, status: 'Inactive' });
        } else {
            setSettingsInfo({ ...settingsInfo, status: 'Active' });
        }
    };

    const getScannedByCombo = (e: any) => {
        let scaannedBy = wsParamsList.filter((obj: any) => {
            for (const param in obj) {
                if (obj[param].toString().includes('scannedBy')) {
                    return obj;
                }
            }
        });
        if (e.text === '') {
            e.updateData(scaannedBy[0].wsData);
        } else {
            const filterData = scaannedBy[0].wsData.filter((item: any) => {
                for (const key in item) {
                    if (item[key]) {
                        if (key === 'id' || key === 'username')
                            if (item[key].toString().includes(e.text)) {
                                return item;
                            }
                    }
                }
            });
            e.updateData(filterData);
        }
    };
    const generateDynamicLink = useCallback(
        (val: string | any, key: any, type: any) => {
            const valType = typeof val;
            const formValuesCopy = { ...formValues };
            if ((val === '' || val === null) && key === 'invoiceNumber') {
                delete formValues.invoiceNumber;
                return false;
            }
            if ((val === '' || val === null) && key === 'fromDate') {
                delete formValues.fromDate;
            }
            if ((val === '' || val === null) && key === 'toDate') {
                delete formValues.toDate;
            } else {
                if (val !== undefined) {
                    if (type === 'varchar') {
                        formValuesCopy[key] = val;
                    } else {
                        formValuesCopy[key] = val;
                        if (key === 'fromDate') {
                            minDate = val;
                        }
                    }
                    setFormValues(formValuesCopy);

                    validate(formValuesCopy);

                    let urlParamValues = { ...urlParams };
                    if (type === 'Date' || type === 'date') {
                        // if (val !== '') {
                        const formattedDate = formatDate(val);
                        urlParamValues[key] = formattedDate;
                        // }
                    } else if (type === 'varchar') {
                        // if (val?.target?.value !== '') {
                        urlParamValues[key] = val;
                        // }
                    } else if (key === 'storeNumber') {
                        onChange(val);
                        var selectedStoreList: string[] = [];
                        val.forEach(function (element: any) {
                            selectedStoreList.push(element.label);
                        });
                        urlParamValues[key] = selectedStoreList;
                        formValuesCopy['storeNumber'] = selectedStoreList;
                    } else if (key === 'storeId') {
                        onChange(val);
                        var selectedStoreList: string[] = [];
                        val.forEach(function (element: any) {
                            selectedStoreList.push(element.label);
                        });
                        urlParamValues[key] = selectedStoreList;
                        formValuesCopy['storeId'] = selectedStoreList;
                        urlParamValues[key] = val.label;
                    } else if (key === 'mainframeCustomerNumber') {
                        onChange(val);
                        var selectedStoreList: string[] = [];
                        urlParamValues[key] = selectedStoreList;
                        formValuesCopy['mainframeCustomerNumber'] = val.label;
                        urlParamValues[key] = val.label;
                    }
                    
                    else {
                        if (val !== '') {
                            urlParamValues[key] = val;
                        }
                    }
                    setUrlParams(urlParamValues);
                } else {
                    validate(formValuesCopy);
                }
                // validate(formValuesCopy);
            }
        },
        [formValues, urlParams]
    );

    const getScanDateList = (val: any) => {
        const endPointURL = getScanDateWS();
        const body = {
            ids: val,
        };
        axios
            .post(endPointURL, body)
            .then((res) => {
                const data = res?.data;
                const wsParamsListCopy = [...wsParamsList];
                wsParamsListCopy.map((item) => {
                    if (item.parameter === 'scanDate') {
                        item['wsData'] = [];
                        item['wsData'] = data;
                    }
                });
                setWsParamsList(wsParamsListCopy);
                // console.log(wsParamsList, 'After search wsParamsList');
            })
            .catch((err) => {
                if (err) {
                    console.log(
                        err,
                        'Error While getting dropdown data with store'
                    );
                    notify(err.response?.data?.message, 'error');
                }
            });
    };

    const getVendorList = async (e: any) => {
        if (e.text.length > 2) {
            const endPointURL = getVendorIDWs();
            const body = {
                filters: [
                    { key: 'vendorName', value: e.text, operation: 'contains' },
                ],
                sort: { key: 'vendorName', direction: 'ascending' },
                page: {
                    pageNumber: 0,
                    pageSize: 50,
                },
            };
            await axios
                .post(endPointURL, body)
                .then((res: any) => {
                    const data = res.data.result;
                    const wsParamsListCopy = [...wsParamsList];
                    wsParamsListCopy.map((item) => {
                        if (item.parameter === 'vendorId') {
                            item['wsData'] = [];
                            item['wsData'] = data;
                        }
                    });
                    setWsParamsList(wsParamsListCopy);
                    console.log(wsParamsList, 'After search wsParamsList');
                })
                .catch((err) => {
                    if (err) {
                        console.log(
                            err,
                            'Error While getting dropdown data with store'
                        );
                        notify(err.response?.data?.message, 'error');
                    }
                });
        }
    };

    const getUPClist = async (e: any) => {
        if (e.text.length > 2) {
            const endPointURL = getUPCWs(e.text);
            await axios
                .get(endPointURL)
                .then((res: any) => {
                    const data = res.data.content;
                    const wsParamsListCopy = [...wsParamsList];
                    // console.log(data);
                    wsParamsListCopy.map((item) => {
                        if (item.parameter === 'upc') {
                            item['wsData'] = [];
                            item['wsData'] = data;
                        }
                    });
                    setWsParamsList(wsParamsListCopy);
                    console.log(wsParamsList, 'After search wsParamsList');
                })
                .catch((err) => {
                    if (err) {
                        console.log(
                            err,
                            'Error While getting dropdown data with store'
                        );
                        notify(err.response?.data?.message, 'error');
                    }
                });
        }
    };

    const getStoreName = async (e: any) => {
        if (e.text.length > 2) {
            const endPointURL = getStoreWsUpdate(e.text);
            await axios
                .get(endPointURL)
                .then((res: any) => {
                    const data = res.data.content;
                    const wsParamsListCopy = [...wsParamsList];
                    console.log(data);
                    wsParamsListCopy.map((item) => {
                        if (
                            item.parameter === 'storeId' ||
                            item.parameter === 'storeNumber'
                        ) {
                            item['wsData'] = [];
                            item['wsData'] = data;
                        }
                    });
                    console.log(wsParamsListCopy);
                    // wsParamsListCopy[0]['wsData'] = res;
                    setWsParamsList(wsParamsListCopy);
                    console.log(wsParamsList);
                })
                .catch((err) => {
                    if (err) {
                        console.log(
                            err,
                            'Error While getting dropdown data with store'
                        );
                        notify(err.response?.data?.message, 'error');
                    }
                });
        }
    };

    const formatDate = (date: string | number | Date) => {
        const d = new Date(date);
        let month = '' + (d.getMonth() + 1);
        let day = '' + d.getDate();
        const year = d.getFullYear().toString();
        if (month.length < 2) {
            month = '0' + month;
        }
        if (day.length < 2) {
            day = '0' + day;
        }
        return [year, month, day].join('-');
    };

    let dowloadFileData: any;
    const downloadFile = async (val: number) => {
        if (urlParams.fromDate > urlParams.toDate) {
            notify('To Date undefined', 'error');
        } else {
            setLoaderStatus(true);
            const baseURL = updateReportsEndPoint('downloadFile');
            const endPointURL = previewData[0].apiUrl;
            const fullURL = baseURL + endPointURL;
            const paramKeys = Object.keys(urlParams);
            if (
                paramKeys !== undefined &&
                paramKeys !== null &&
                paramKeys.length > 0
            ) {
                exportFileWsCall(urlParams, paramKeys, fullURL, val);
            } else {
                if (noParamReports === true) {
                    exportFileWsCall(urlParams, paramKeys, fullURL, val);
                } else {
                    setLoaderStatus(false);
                    notify('Please enter atleast one parameter', 'error');
                }
            }
        }
    };

    const exportFileWsCall = (
        urlParams: any,
        paramKeys: any,
        fullURL: any,
        val: number
    ) => {
        let  urlParamsCopy :any;
        if (isAllSelected) {
            urlParamsCopy = {...formValues ,...{isAllUsersSelected:true}}
            delete formValues.scannedBy;
            delete urlParamsCopy.scannedBy;
            delete urlParams.scannedBy;
            setUrlParams(urlParamsCopy);
            //return false;
         } else {
            setIsAllSelected(false);
            urlParamsCopy = {...formValues ,...{isAllUsersSelected:false}}
         }
       // const urlParamsCopy = { ...urlParams };
        paramKeys.map((item: any) => {
            if (item === 'period') {
                let periodIdArr: any = [];
                const periodValues = urlParams[item];
                console.log(periodValues);
                periodValues.map((item: any) => {
                    const data = item.split('---');
                    periodIdArr.push(data[1]);
                });
                console.log(periodIdArr);
                urlParamsCopy[item] = periodIdArr;
            }
        });
        for (let key in urlParamsCopy) {
            if (urlParamsCopy[key] == '') {
                urlParamsCopy[key] = null;
            }
        }

        let body = {};
        body = urlParamsCopy;
        Object.assign(body, { flag: val });
        // console.log(body)

        if (val === 0) {
            axios
                .post(fullURL, body)
                .then((res: any) => {
                    console.log(res.data);
                    let data: any = res.data;
                    /* For Octet-stream */
                    // dowloadFileData = res.data;
                    // const file = new Blob([dowloadFileData], {
                    //     type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                    // });

                    /* For Base64 to Excel */
                    if (data.status === true) {
                        dowloadFileData = data.data;
                        const type =
                            'data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,';
                        const file = type + dowloadFileData;
                        FileSaver.saveAs(
                            file,
                            reportName +
                                ExcelService.formatDateToUS(new Date()) +
                                '.xlsx'
                        );
                    } else {
                        console.log('No data found with these parameters');
                        notify('No data found with these parameters', 'error');
                    }
                    setFormValues({});
                    setUrlParams({});
                    setLoaderStatus(false);
                    setIsAllSelected(false);
                })
                .catch((err) => {
                    console.log(err);
                    setLoaderStatus(false);
                    notify(
                        'Unable to complete request. Please try again later.',
                        'error'
                    );
                });
        } else {
            axios
                .post(fullURL, body)
                .then((res) => {
                    let data: any = res.data;
                    /* For Octet-stream */
                    // dowloadFileData = res.data;
                    // const file = new Blob([dowloadFileData], {
                    //     type: 'application/pdf',
                    // });
                    // const fileURL = URL.createObjectURL(file);
                    // window.open(fileURL);

                    if (data.status === true) {
                        var byteCharacters = atob(data.data);
                        var byteNumbers = new Array(byteCharacters.length);
                        for (var i = 0; i < byteCharacters.length; i++) {
                            byteNumbers[i] = byteCharacters.charCodeAt(i);
                        }
                        var byteArray = new Uint8Array(byteNumbers);
                        var file = new Blob([byteArray], {
                            type: 'application/pdf;base64',
                        });
                        var fileURL = URL.createObjectURL(file);
                        window.open(fileURL);
                    } else {
                        console.log('No data found for this request');
                        notify('No data found with these parameters', 'error');
                    }
                    setLoaderStatus(false);
                })
                .catch((err) => {
                    setLoaderStatus(false);
                    notify(
                        'Unable to complete request. Please try again later.',
                        'error'
                    );
                });
            setFormValues({});
            setUrlParams({});
        }
    };

    const validate = (formValuesCopy: any) => {
        let locationError = '';
        let auditDateError = '';
        let clientGroupError = '';
        let periodError = '';
        let soldStatusError = '';
        let customerNumberError = '';
        let sortCodeError = '';
        let commodityCodeError = '';
        let scannedByError = '';
        let warehouseLocationError = '';
        let scanModeError = '';
        let dispositionError = '';
        let customerGroupError = '';
        let scannedByInternalError = '';
        let storeIdError = '';
        let inputFieldError = '';
        let vendorIdError = '';
        let upcError = '';
        let scanDateError = '';
        let fromDateError = '';
        let toDateError = '';
        let finalDispositionError = '';

        setErrors({
            locationError: '',
            auditDateError: '',
            clientGroupError: '',
            periodError: '',
            soldStatusError: '',
            customerNumberError: '',
            sortCodeError: '',
            commodityCodeError: '',
            scannedByError: '',
            warehouseLocationError: '',
            scanModeError: '',
            dispositionError: '',
            customerGroupError: '',
            scannedByInternalError: '',
            storeIdError: '',
            inputFieldError: '',
            vendorIdError: '',
            upcError: '',
            scanDateError: '',
            fromDateError: '',
            toDateError: '',
            finalDispositionError:''
        });

        if (
            formValuesCopy !== undefined &&
            formValuesCopy !== '' &&
            Object.keys(formValuesCopy).length > 0
        ) {
            if (
                formValuesCopy?.auditDate === '' ||
                formValuesCopy?.auditDate === null
            ) {
                setAuditDateClass('1px solid red');
                auditDateError = 'Required Field';
            } else {
                setAuditDateClass('');
            }

            if (
                formValuesCopy?.fromDate === '' ||
                formValuesCopy?.fromDate?.length === 0
            ) {
                setFromDateClass('1px solid red');
                fromDateError = 'Required Field';
            } else {
                setFromDateClass('');
            }

            if (
                formValuesCopy?.toDate === '' ||
                formValuesCopy?.toDate?.length === 0
            ) {
                setToDateClass('1px solid red');
                toDateError = 'Required Field';
            } else {
                setToDateClass('');
            }

            if (
                formValuesCopy?.location === '' ||
                formValuesCopy?.center === '' ||
                formValuesCopy?.locationId === '' ||
                formValuesCopy?.location?.length === 0 ||
                formValuesCopy?.center?.length === 0 ||
                formValuesCopy?.locationId?.length === 0
            ) {
                locationError = 'Required Field';
                setLocationClass('1px solid red');
            } else {
                setLocationClass('');
            }

            if (
                formValuesCopy?.client === '' ||
                formValuesCopy?.client?.length === 0
            ) {
                setClientClass('1px solid red');
                clientGroupError = 'Required Field';
            } else {
                setClientClass('1px solid red');
            }

            if (
                formValuesCopy?.period === '' ||
                formValuesCopy?.period?.length === 0
            ) {
                setPeriodClass('1px solid red');
                periodError = 'Required Field';
            } else {
                setPeriodClass('');
            }

            if (
                formValuesCopy?.soldStatus === '' ||
                formValuesCopy?.soldStatus?.length === 0
            ) {
                setSoldStatusClass('1px solid red');
                soldStatusError = 'Required Field';
            } else {
                setSoldStatusClass('');
            }

            if (
                formValuesCopy?.customerNumber === '' ||
                formValuesCopy?.customerNumber?.length === 0
            ) {
                setCustomerNumberClass('1px solid red');
                customerNumberError = 'Required Field';
            } else {
                setCustomerNumberClass('');
            }

            if (
                formValuesCopy?.sortCode === '' ||
                formValuesCopy?.sortCode?.length === 0
            ) {
                setSortCodeClass('1px solid red');
                sortCodeError = 'Required Field';
            } else {
                setSortCodeClass('');
            }

            if( formValuesCopy?.isAllUsersSelected === false || formValuesCopy?.scannedBy?.length === 0) {
                
                setScannedByClass('1px solid red');
                scannedByError = 'Required Field';
            }
            if ( formValuesCopy?.isAllUsersSelected === true || formValuesCopy?.scannedBy?.length > 0){
                setScannedByClass('');
                scannedByError ='';
            }

            if (
                formValuesCopy?.commodityCode === '' ||
                formValuesCopy?.commodityCode?.length === 0
            ) {
                setCommodityCodeClass('1px solid red');
                commodityCodeError = 'Required Field';
            } else {
                setCommodityCodeClass('');
            }

            if (
                formValuesCopy?.warehouseLocation === '' ||
                formValuesCopy?.warehouseLocation?.length === 0
            ) {
                setWareHouseLocationClass('1px solid red');
                warehouseLocationError = 'Required Field';
            } else {
                setWareHouseLocationClass('');
            }

            if (
                formValuesCopy?.scanMode === '' ||
                formValuesCopy?.scanMode?.length === 0
            ) {
                setScanModeClass('1px solid red');
                scanModeError = 'Required Field';
            } else {
                setScanModeClass('');
            }

            if (
                formValuesCopy?.disposition === '' ||
                formValuesCopy?.disposition?.length === 0
            ) {
                setDispositionClass('1px solid red');
                dispositionError = 'Required Field';
            } else {
                setDispositionClass('');
            }

            if (
                formValuesCopy?.customerGroup === '' ||
                formValuesCopy?.customerGroup?.length === 0
            ) {
                setCustomerGroupClass('1px solid red');
                customerGroupError = 'Required Field';
            } else {
                setCustomerGroupClass('');
            }

            if (
                formValuesCopy?.scannedByInternal === '' ||
                formValuesCopy?.scannedByInternal?.length === 0
            ) {
                setScannedByInternalClass('1px solid red');
                scannedByInternalError = 'Required Field';
            } else {
                setScannedByInternalClass('');
            }

            if (formValuesCopy?.storeId === '') {
                setStoreIdClass('1px solid red');
                storeIdError = 'Required Field';
            } else {
                setStoreIdClass('');
            }

            if (
                formValuesCopy?.vendorId === '' ||
                formValuesCopy?.vendorId?.length === 0
            ) {
                setVendorIdClass('1px solid red');
                vendorIdError = 'Required Field';
            } else {
                setVendorIdClass('');
            }
            if (
                formValuesCopy?.upc === '' ||
                formValuesCopy?.upc?.length === 0
            ) {
                setUpcClass('1px solid red');
                upcError = 'Required Field';
            } else {
                setUpcClass('');
            }

            if (
                formValuesCopy?.scanDate === '' ||
                formValuesCopy?.scanDate?.length === 0
            ) {
                setScanDateClass('1px solid red');
                scanDateError = 'Required Field';
            } else {
                setScanDateClass('');
            }

            if (
                formValuesCopy?.finalDisposition === '' ||
                formValuesCopy?.finalDisposition?.length === 0
            ) {
                setFinalDispositionClass('1px solid red');
                finalDispositionError = 'Required Field';
            } else {
                setFinalDispositionClass('');
            }

            //     if (formValuesCopy?.invoiceNumber === '' || formValuesCopy?.invoiceNumber?.length === 0 ||
            //     formValuesCopy?.upc === '' || formValuesCopy?.upc?.length === 0 ) {
            //     //setMyFormControl('1px solid red');
            //     //inputFieldError = 'Required Field';
            // } else {
            //     setMyFormControl('');
            // }

            if (
                formValuesCopy?.upc === '' ||
                formValuesCopy?.upc?.length === 0
            ) {
                setMyFormControl('1px solid red');
                inputFieldError = 'Required Field';
            } else {
                setMyFormControl('');
            }
        } else {
            setExportEnabled(false);
        }
        if (
            locationError ||
            auditDateError ||
            clientGroupError ||
            periodError ||
            soldStatusError ||
            customerNumberError ||
            sortCodeError ||
            commodityCodeError ||
            scannedByError ||
            warehouseLocationError ||
            scanModeError ||
            dispositionError ||
            customerGroupError ||
            scannedByInternalError ||
            storeIdError ||
            inputFieldError ||
            vendorIdError ||
            upcError ||
            scanDateError ||
            fromDateError ||
            toDateError
        ) {
            setErrors({
                locationError,
                auditDateError,
                clientGroupError,
                periodError,
                soldStatusError,
                customerNumberError,
                sortCodeError,
                commodityCodeError,
                scannedByError,
                warehouseLocationError,
                scanModeError,
                dispositionError,
                customerGroupError,
                scannedByInternalError,
                storeIdError,
                inputFieldError,
                vendorIdError,
                upcError,
                scanDateError,
                fromDateError,
                toDateError,
            });
            setExportEnabled(true);
            // console.log(errors);

            // setTmeZoneDisabled(true);
            return false;
        } else {
            setExportEnabled(false);
            // setTmeZoneDisabled(false);
        }

        return true;
    };

    const handleSelectAll = (e:any) => {
        let  formValuesCopy:any
        if (e.currentTarget.checked) {
           setIsAllSelected(true);
           formValuesCopy = {...formValues ,...{isAllUsersSelected:true}};
        } else {
            setIsAllSelected(false);
            formValuesCopy = {...formValues ,...{isAllUsersSelected:false}};
        }
        setFormValues(formValuesCopy);
    }
    const parametersPreview = () => {
        return (
            <div className="container4">
                {loaderStatus === true ? (
                    <span className="loaderClassSaved" />
                ) : (
                    <div className="col-sm-12 px-0 reports-preview-details  ">
                        {wsParamsList && wsParamsList.length > 0 ? (
                            <div className="col-sm-12 px-4 pt-4 paramsList">
                                {wsParamsList &&
                                    wsParamsList.length > 0 &&
                                    wsParamsList?.map(
                                        (item: any, rIdx: any) => (
                                            <div className="col-sm-12 px-0 pt-4 ">
                                                <div className="col-sm-6 col-md-6 col-lg-4 px-0 py-2 reports-keys pt-2 ">
                                                    {item?.parameterAlias}{' '}
                                                    {item?.mandatoryField ===
                                                    true ? (
                                                        <span
                                                            style={{
                                                                color: 'red',
                                                            }}
                                                        >
                                                            *
                                                        </span>
                                                    ) : (
                                                        ''
                                                    )}
                                                </div>
                                                <div className="col-sm-6 col-md-6 col-lg-8 reports-values pb-4">
                                                    {item?.type ===
                                                    'dropdown' ? (
                                                        <div className="col-sm-12 p-0">
                                                            {item?.wsData && (
                                                                <div
                                                                    id="ll-autocomplete"
                                                                    className="control-pane "
                                                                >
                                                                    <div className="control-section col-sm-12 p-0">
                                                                        <div
                                                                            id="multigroup"
                                                                            className="control-styles"
                                                                        >
                                                                            {(item?.parameter ===
                                                                                'location' ||
                                                                                item?.parameter ===
                                                                                    'center' ||
                                                                                item?.parameter ===
                                                                                    'locationId' ||
                                                                                item?.parameter ===
                                                                                    'reclaimCenter') && (
                                                                                <>
                                                                                    {item?.multiSelect ===
                                                                                    false ? (
                                                                                        <DropDownListComponent
                                                                                            dataSource={
                                                                                                item?.wsData
                                                                                            }
                                                                                            value={
                                                                                                formValues[
                                                                                                    item
                                                                                                        .parameter
                                                                                                ]
                                                                                            }
                                                                                            fields={{
                                                                                                text: 'reclaimcenterName',
                                                                                                value: 'id',
                                                                                            }}
                                                                                            placeholder="Select"
                                                                                            popupHeight="220px"
                                                                                            sortOrder="Ascending"
                                                                                            allowFiltering={
                                                                                                true
                                                                                            }
                                                                                            change={(
                                                                                                e
                                                                                            ) => {
                                                                                                
                                                                                                if (
                                                                                                    e.value ===
                                                                                                        null ||
                                                                                                    e
                                                                                                        .value
                                                                                                        ?.length ===
                                                                                                        0
                                                                                                ) {
                                                                                                    if (
                                                                                                        item.mandatoryField ===
                                                                                                        true
                                                                                                    ) {
                                                                                                        generateDynamicLink(
                                                                                                            '',
                                                                                                            item?.parameter,
                                                                                                            item?.type
                                                                                                        );
                                                                                                    } else {
                                                                                                        generateDynamicLink(
                                                                                                            null,
                                                                                                            item?.parameter,
                                                                                                            item?.type
                                                                                                        );
                                                                                                    }
                                                                                                } else {
                                                                                                    generateDynamicLink(
                                                                                                        e.value,
                                                                                                        item?.parameter,
                                                                                                        item?.type
                                                                                                    );
                                                                                                }
                                                                                            }}
                                                                                            style={{
                                                                                                borderBottom:
                                                                                                    locationClass,
                                                                                            }}
                                                                                        />
                                                                                    ) : (
                                                                                        <MultiSelectComponent
                                                                                            className="form-control"
                                                                                            dataSource={
                                                                                                item?.wsData
                                                                                            }
                                                                                            fields={{
                                                                                                text: 'reclaimcenterName',
                                                                                                value: 'id',
                                                                                            }}
                                                                                            placeholder="Select"
                                                                                            mode="CheckBox"
                                                                                            showSelectAll={
                                                                                                true
                                                                                            }
                                                                                            value={
                                                                                                formValues[
                                                                                                    item
                                                                                                        .parameter
                                                                                                ]
                                                                                            }
                                                                                            showDropDownIcon={
                                                                                                true
                                                                                            }
                                                                                            filterBarPlaceholder="Search"
                                                                                            popupHeight="250px"
                                                                                            sortOrder="Ascending"
                                                                                            change={(
                                                                                                e
                                                                                            ) => {
                                                                                               
                                                                                                if (
                                                                                                    e.value ===
                                                                                                        null ||
                                                                                                    e
                                                                                                        .value
                                                                                                        ?.length ===
                                                                                                        0
                                                                                                ) {
                                                                                                    if (
                                                                                                        item.mandatoryField ===
                                                                                                        true
                                                                                                    ) {
                                                                                                        generateDynamicLink(
                                                                                                            '',
                                                                                                            item?.parameter,
                                                                                                            item?.type
                                                                                                        );
                                                                                                    } else {
                                                                                                        generateDynamicLink(
                                                                                                            null,
                                                                                                            item?.parameter,
                                                                                                            item?.type
                                                                                                        );
                                                                                                    }
                                                                                                } else {
                                                                                                    generateDynamicLink(
                                                                                                        e.value,
                                                                                                        item?.parameter,
                                                                                                        item?.type
                                                                                                    );
                                                                                                }
                                                                                            }}
                                                                                            style={{
                                                                                                borderBottom:
                                                                                                    locationClass,
                                                                                            }}
                                                                                        >
                                                                                            <Inject
                                                                                                services={[
                                                                                                    CheckBoxSelection,
                                                                                                ]}
                                                                                            />
                                                                                        </MultiSelectComponent>
                                                                                    )}
                                                                                    {item.mandatoryField ===
                                                                                        true &&
                                                                                    formValues[
                                                                                        item
                                                                                            .parameter
                                                                                    ] ===
                                                                                        '' ? (
                                                                                        <span
                                                                                            className="text-danger  error-text"
                                                                                            style={{
                                                                                                float: 'right',
                                                                                            }}
                                                                                        >
                                                                                            {
                                                                                                errors.locationError
                                                                                            }
                                                                                        </span>
                                                                                    ) : (
                                                                                        ''
                                                                                    )}
                                                                                </>
                                                                            )}

                                                                            {item?.parameter ===
                                                                                'client' && (
                                                                                <>
                                                                                    {item?.multiSelect ===
                                                                                    false ? (
                                                                                        <DropDownListComponent
                                                                                            dataSource={
                                                                                                item?.wsData
                                                                                            }
                                                                                            value={
                                                                                                formValues[
                                                                                                    item
                                                                                                        .parameter
                                                                                                ]
                                                                                            }
                                                                                            fields={{
                                                                                                text: 'clientGroupCombo',
                                                                                                value: 'clientGroupName',
                                                                                            }}
                                                                                            placeholder="Select client"
                                                                                            popupHeight="220px"
                                                                                            sortOrder="Ascending"
                                                                                            allowFiltering={
                                                                                                true
                                                                                            }
                                                                                            style={{
                                                                                                borderBottom:
                                                                                                    clientClass,
                                                                                            }}
                                                                                            change={(
                                                                                                e
                                                                                            ) => {
                                                                                               
                                                                                                if (
                                                                                                    e.value ===
                                                                                                        null ||
                                                                                                    e
                                                                                                        .value
                                                                                                        ?.length ===
                                                                                                        0
                                                                                                ) {
                                                                                                    if (
                                                                                                        item.mandatoryField ===
                                                                                                        true
                                                                                                    ) {
                                                                                                        generateDynamicLink(
                                                                                                            '',
                                                                                                            item?.parameter,
                                                                                                            item?.type
                                                                                                        );
                                                                                                    } else {
                                                                                                        generateDynamicLink(
                                                                                                            null,
                                                                                                            item?.parameter,
                                                                                                            item?.type
                                                                                                        );
                                                                                                    }
                                                                                                } else {
                                                                                                    generateDynamicLink(
                                                                                                        e.value,
                                                                                                        item?.parameter,
                                                                                                        item?.type
                                                                                                    );
                                                                                                }
                                                                                            }}
                                                                                        />
                                                                                    ) : (
                                                                                        <MultiSelectComponent
                                                                                            className="form-control"
                                                                                            dataSource={
                                                                                                item?.wsData
                                                                                            }
                                                                                            placeholder="Select client"
                                                                                            fields={{
                                                                                                text: 'clientGroupCombo',
                                                                                                value: 'clientGroupName',
                                                                                            }}
                                                                                            mode="CheckBox"
                                                                                            showSelectAll={
                                                                                                true
                                                                                            }
                                                                                            filterBarPlaceholder="Search client"
                                                                                            value={
                                                                                                formValues[
                                                                                                    item
                                                                                                        .parameter
                                                                                                ]
                                                                                            }
                                                                                            showDropDownIcon={
                                                                                                true
                                                                                            }
                                                                                            popupHeight="250px"
                                                                                            change={(
                                                                                                e
                                                                                            ) => {
                                                                                                
                                                                                                if (
                                                                                                    e.value ===
                                                                                                        null ||
                                                                                                    e
                                                                                                        .value
                                                                                                        ?.length ===
                                                                                                        0
                                                                                                ) {
                                                                                                    if (
                                                                                                        item.mandatoryField ===
                                                                                                        true
                                                                                                    ) {
                                                                                                        generateDynamicLink(
                                                                                                            '',
                                                                                                            item?.parameter,
                                                                                                            item?.type
                                                                                                        );
                                                                                                    } else {
                                                                                                        generateDynamicLink(
                                                                                                            null,
                                                                                                            item?.parameter,
                                                                                                            item?.type
                                                                                                        );
                                                                                                    }
                                                                                                } else {
                                                                                                    generateDynamicLink(
                                                                                                        e.value,
                                                                                                        item?.parameter,
                                                                                                        item?.type
                                                                                                    );
                                                                                                }
                                                                                            }}
                                                                                            sortOrder="Ascending"
                                                                                            style={{
                                                                                                borderBottom:
                                                                                                    clientClass,
                                                                                            }}
                                                                                        >
                                                                                            <Inject
                                                                                                services={[
                                                                                                    CheckBoxSelection,
                                                                                                ]}
                                                                                            />
                                                                                        </MultiSelectComponent>
                                                                                    )}
                                                                                    {item.mandatoryField ===
                                                                                        true &&
                                                                                    formValues[
                                                                                        item
                                                                                            .parameter
                                                                                    ] ===
                                                                                        '' ? (
                                                                                        <span
                                                                                            className="text-danger  error-text"
                                                                                            style={{
                                                                                                float: 'right',
                                                                                            }}
                                                                                        >
                                                                                            {
                                                                                                errors.clientGroupError
                                                                                            }
                                                                                        </span>
                                                                                    ) : (
                                                                                        ''
                                                                                    )}
                                                                                </>
                                                                            )}

                                                                            {item?.parameter ===
                                                                                'period' && (
                                                                                <>
                                                                                    {item?.multiSelect ===
                                                                                    false ? (
                                                                                        <DropDownListComponent
                                                                                            dataSource={
                                                                                                item?.wsData
                                                                                            }
                                                                                            value={
                                                                                                formValues[
                                                                                                    item
                                                                                                        .parameter
                                                                                                ]
                                                                                            }
                                                                                            fields={{
                                                                                                text: 'period',
                                                                                                value: 'periodComobo',
                                                                                            }}
                                                                                            placeholder="Select period"
                                                                                            popupHeight="220px"
                                                                                            allowFiltering={
                                                                                                true
                                                                                            }
                                                                                            change={(
                                                                                                e: any
                                                                                            ) => {
                                                                                              
                                                                                                if (
                                                                                                    e.value ===
                                                                                                        null ||
                                                                                                    e
                                                                                                        .value
                                                                                                        ?.length ===
                                                                                                        0
                                                                                                ) {
                                                                                                    if (
                                                                                                        item.mandatoryField ===
                                                                                                        true
                                                                                                    ) {
                                                                                                        generateDynamicLink(
                                                                                                            '',
                                                                                                            item?.parameter,
                                                                                                            item?.type
                                                                                                        );
                                                                                                    } else {
                                                                                                        generateDynamicLink(
                                                                                                            null,
                                                                                                            item?.parameter,
                                                                                                            item?.type
                                                                                                        );
                                                                                                    }
                                                                                                } else {
                                                                                                    generateDynamicLink(
                                                                                                        e.value,
                                                                                                        item?.parameter,
                                                                                                        item?.type
                                                                                                    );
                                                                                                }
                                                                                            }}
                                                                                            style={{
                                                                                                borderBottom:
                                                                                                    periodClass,
                                                                                            }}
                                                                                        />
                                                                                    ) : (
                                                                                        <MultiSelectComponent
                                                                                            className="form-control"
                                                                                            dataSource={
                                                                                                item?.wsData
                                                                                            }
                                                                                            fields={{
                                                                                                text: 'period',
                                                                                                value: 'periodComobo',
                                                                                            }}
                                                                                            placeholder="Select period"
                                                                                            mode="CheckBox"
                                                                                            showSelectAll={
                                                                                                true
                                                                                            }
                                                                                            value={
                                                                                                formValues[
                                                                                                    item
                                                                                                        .parameter
                                                                                                ]
                                                                                            }
                                                                                            showDropDownIcon={
                                                                                                true
                                                                                            }
                                                                                            filterBarPlaceholder="Search period"
                                                                                            popupHeight="250px"
                                                                                            change={(
                                                                                                e
                                                                                            ) => {
                                                                                                // generateDynamicLink(e.value, item?.parameter, item?.type);
                                                                                               
                                                                                                if (
                                                                                                    e.value ===
                                                                                                        null ||
                                                                                                    e
                                                                                                        .value
                                                                                                        ?.length ===
                                                                                                        0
                                                                                                ) {
                                                                                                    if (
                                                                                                        item.mandatoryField ===
                                                                                                        true
                                                                                                    ) {
                                                                                                        generateDynamicLink(
                                                                                                            '',
                                                                                                            item?.parameter,
                                                                                                            item?.type
                                                                                                        );
                                                                                                    } else {
                                                                                                        generateDynamicLink(
                                                                                                            null,
                                                                                                            item?.parameter,
                                                                                                            item?.type
                                                                                                        );
                                                                                                    }
                                                                                                } else {
                                                                                                    generateDynamicLink(
                                                                                                        e.value,
                                                                                                        item?.parameter,
                                                                                                        item?.type
                                                                                                    );
                                                                                                }
                                                                                                let paramsArr: any[] =
                                                                                                    [];
                                                                                                wsParamsList.map(
                                                                                                    (
                                                                                                        item: any
                                                                                                    ) => {
                                                                                                        paramsArr.push(
                                                                                                            item.parameter
                                                                                                        );
                                                                                                    }
                                                                                                );
                                                                                                const keyStatus =
                                                                                                    paramsArr.includes(
                                                                                                        'scanDate'
                                                                                                    );
                                                                                                // console.log(keyStatus);
                                                                                                if (
                                                                                                    e
                                                                                                        .value
                                                                                                        ?.length >
                                                                                                        0 &&
                                                                                                    keyStatus ===
                                                                                                        true
                                                                                                ) {
                                                                                                    let periodIdArr: any[] =
                                                                                                        [];
                                                                                                    const periodValues =
                                                                                                        e.value;
                                                                                                    periodValues.map(
                                                                                                        (
                                                                                                            item: any
                                                                                                        ) => {
                                                                                                            const data =
                                                                                                                item.split(
                                                                                                                    '---'
                                                                                                                );
                                                                                                            periodIdArr.push(
                                                                                                                data[0]
                                                                                                            );
                                                                                                        }
                                                                                                    );
                                                                                                    getScanDateList(
                                                                                                        periodIdArr
                                                                                                    );
                                                                                                }
                                                                                            }}
                                                                                            style={{
                                                                                                borderBottom:
                                                                                                    periodClass,
                                                                                            }}
                                                                                        >
                                                                                            <Inject
                                                                                                services={[
                                                                                                    CheckBoxSelection,
                                                                                                ]}
                                                                                            />
                                                                                        </MultiSelectComponent>
                                                                                    )}
                                                                                    {item.mandatoryField ===
                                                                                        true &&
                                                                                    formValues[
                                                                                        item
                                                                                            .parameter
                                                                                    ] ===
                                                                                        '' ? (
                                                                                        <span
                                                                                            className="text-danger  error-text"
                                                                                            style={{
                                                                                                float: 'right',
                                                                                            }}
                                                                                        >
                                                                                            {
                                                                                                errors.periodError
                                                                                            }
                                                                                        </span>
                                                                                    ) : (
                                                                                        ''
                                                                                    )}
                                                                                </>
                                                                            )}

                                                                            {(item?.parameter ===
                                                                                'soldStatus' || item?.parameter ===
                                                                                'closedInvoiceStatus') && (
                                                                                <>
                                                                                    {item?.multiSelect ===
                                                                                    false ? (
                                                                                        <DropDownListComponent
                                                                                            dataSource={
                                                                                                item?.wsData
                                                                                            }
                                                                                            value={
                                                                                                formValues[
                                                                                                    item
                                                                                                        .parameter
                                                                                                ]
                                                                                            }
                                                                                            fields={{
                                                                                                text: 'status',
                                                                                                value: 'status',
                                                                                            }}
                                                                                            sortOrder="Ascending"
                                                                                            placeholder="Select sold status"
                                                                                            popupHeight="220px"
                                                                                            allowFiltering={
                                                                                                true
                                                                                            }
                                                                                            change={(
                                                                                                e
                                                                                            ) => {
                                                                                               
                                                                                                if (
                                                                                                    e.value ===
                                                                                                        null ||
                                                                                                    e
                                                                                                        .value
                                                                                                        ?.length ===
                                                                                                        0
                                                                                                ) {
                                                                                                    if (
                                                                                                        item.mandatoryField ===
                                                                                                        true
                                                                                                    ) {
                                                                                                        generateDynamicLink(
                                                                                                            '',
                                                                                                            item?.parameter,
                                                                                                            item?.type
                                                                                                        );
                                                                                                    } else {
                                                                                                        generateDynamicLink(
                                                                                                            null,
                                                                                                            item?.parameter,
                                                                                                            item?.type
                                                                                                        );
                                                                                                    }
                                                                                                } else {
                                                                                                    generateDynamicLink(
                                                                                                        e.value,
                                                                                                        item?.parameter,
                                                                                                        item?.type
                                                                                                    );
                                                                                                }
                                                                                            }}
                                                                                            style={{
                                                                                                borderBottom:
                                                                                                    soldStatusClass,
                                                                                            }}
                                                                                        />
                                                                                    ) : (
                                                                                        <MultiSelectComponent
                                                                                            className="form-control"
                                                                                            dataSource={
                                                                                                item?.wsData
                                                                                            }
                                                                                            fields={{
                                                                                                text: 'status',
                                                                                                value: 'status',
                                                                                            }}
                                                                                            placeholder="Select sold status"
                                                                                            mode="CheckBox"
                                                                                            showSelectAll={
                                                                                                true
                                                                                            }
                                                                                            value={
                                                                                                formValues[
                                                                                                    item
                                                                                                        .parameter
                                                                                                ]
                                                                                            }
                                                                                            showDropDownIcon={
                                                                                                true
                                                                                            }
                                                                                            filterBarPlaceholder="Search sold status"
                                                                                            popupHeight="250px"
                                                                                            change={(
                                                                                                e
                                                                                            ) => {
                                                                                              
                                                                                                if (
                                                                                                    e.value ===
                                                                                                        null ||
                                                                                                    e
                                                                                                        .value
                                                                                                        ?.length ===
                                                                                                        0
                                                                                                ) {
                                                                                                    if (
                                                                                                        item.mandatoryField ===
                                                                                                        true
                                                                                                    ) {
                                                                                                        generateDynamicLink(
                                                                                                            '',
                                                                                                            item?.parameter,
                                                                                                            item?.type
                                                                                                        );
                                                                                                    } else {
                                                                                                        generateDynamicLink(
                                                                                                            null,
                                                                                                            item?.parameter,
                                                                                                            item?.type
                                                                                                        );
                                                                                                    }
                                                                                                } else {
                                                                                                    generateDynamicLink(
                                                                                                        e.value,
                                                                                                        item?.parameter,
                                                                                                        item?.type
                                                                                                    );
                                                                                                }
                                                                                            }}
                                                                                            sortOrder="Ascending"
                                                                                            style={{
                                                                                                borderBottom:
                                                                                                    soldStatusClass,
                                                                                            }}
                                                                                        >
                                                                                            <Inject
                                                                                                services={[
                                                                                                    CheckBoxSelection,
                                                                                                ]}
                                                                                            />
                                                                                        </MultiSelectComponent>
                                                                                    )}
                                                                                    {item.mandatoryField ===
                                                                                        true &&
                                                                                    formValues[
                                                                                        item
                                                                                            .parameter
                                                                                    ] ===
                                                                                        '' ? (
                                                                                        <span
                                                                                            className="text-danger  error-text"
                                                                                            style={{
                                                                                                float: 'right',
                                                                                            }}
                                                                                        >
                                                                                            {
                                                                                                errors.soldStatusError
                                                                                            }
                                                                                        </span>
                                                                                    ) : (
                                                                                        ''
                                                                                    )}
                                                                                </>
                                                                            )}

                                                                            {(item?.parameter ===
                                                                                'customerNumber' || item?.parameter ==='salvageCustomerNumber') && (
                                                                                <>
                                                                                    {item?.multiSelect ===
                                                                                    false ? (
                                                                                        <DropDownListComponent
                                                                                            dataSource={
                                                                                                item?.wsData
                                                                                            }
                                                                                            value={
                                                                                                formValues[
                                                                                                    item
                                                                                                        .parameter
                                                                                                ]
                                                                                            }
                                                                                            fields={{
                                                                                                text: 'customerCombo',
                                                                                                value: 'customerNumber',
                                                                                            }}
                                                                                            allowFiltering={
                                                                                                true
                                                                                            }
                                                                                            placeholder="Select customer number"
                                                                                            popupHeight="220px"
                                                                                            sortOrder="Ascending"
                                                                                            change={(
                                                                                                e
                                                                                            ) => {
                                                                                                
                                                                                                if (
                                                                                                    e.value ===
                                                                                                        null ||
                                                                                                    e
                                                                                                        .value
                                                                                                        ?.length ===
                                                                                                        0
                                                                                                ) {
                                                                                                    if (
                                                                                                        item.mandatoryField ===
                                                                                                        true
                                                                                                    ) {
                                                                                                        generateDynamicLink(
                                                                                                            '',
                                                                                                            item?.parameter,
                                                                                                            item?.type
                                                                                                        );
                                                                                                    } else {
                                                                                                        generateDynamicLink(
                                                                                                            null,
                                                                                                            item?.parameter,
                                                                                                            item?.type
                                                                                                        );
                                                                                                    }
                                                                                                } else {
                                                                                                    generateDynamicLink(
                                                                                                        e.value,
                                                                                                        item?.parameter,
                                                                                                        item?.type
                                                                                                    );
                                                                                                }
                                                                                            }}
                                                                                            style={{
                                                                                                borderBottom:
                                                                                                    customerNumberClass,
                                                                                            }}
                                                                                        />
                                                                                    ) : (
                                                                                        <MultiSelectComponent
                                                                                            className="form-control"
                                                                                            dataSource={
                                                                                                item?.wsData
                                                                                            }
                                                                                            fields={{
                                                                                                text: 'customerCombo',
                                                                                                value: 'customerNumber',
                                                                                            }}
                                                                                            placeholder="Select customer number"
                                                                                            mode="CheckBox"
                                                                                            showSelectAll={
                                                                                                true
                                                                                            }
                                                                                            value={
                                                                                                formValues[
                                                                                                    item
                                                                                                        .parameter
                                                                                                ]
                                                                                            }
                                                                                            showDropDownIcon={
                                                                                                true
                                                                                            }
                                                                                            filterBarPlaceholder="Search customer number"
                                                                                            popupHeight="250px"
                                                                                            change={(
                                                                                                e
                                                                                            ) => {
                                                                                                
                                                                                                if (
                                                                                                    e.value ===
                                                                                                        null ||
                                                                                                    e
                                                                                                        .value
                                                                                                        ?.length ===
                                                                                                        0
                                                                                                ) {
                                                                                                    if (
                                                                                                        item.mandatoryField ===
                                                                                                        true
                                                                                                    ) {
                                                                                                        generateDynamicLink(
                                                                                                            '',
                                                                                                            item?.parameter,
                                                                                                            item?.type
                                                                                                        );
                                                                                                    } else {
                                                                                                        generateDynamicLink(
                                                                                                            null,
                                                                                                            item?.parameter,
                                                                                                            item?.type
                                                                                                        );
                                                                                                    }
                                                                                                } else {
                                                                                                    generateDynamicLink(
                                                                                                        e.value,
                                                                                                        item?.parameter,
                                                                                                        item?.type
                                                                                                    );
                                                                                                }
                                                                                            }}
                                                                                            sortOrder="Ascending"
                                                                                            style={{
                                                                                                borderBottom:
                                                                                                    customerNumberClass,
                                                                                            }}
                                                                                        >
                                                                                            <Inject
                                                                                                services={[
                                                                                                    CheckBoxSelection,
                                                                                                ]}
                                                                                            />
                                                                                        </MultiSelectComponent>
                                                                                    )}

                                                                                    {item.mandatoryField ===
                                                                                        true &&
                                                                                    formValues[
                                                                                        item
                                                                                            .parameter
                                                                                    ] ===
                                                                                        '' ? (
                                                                                        <span
                                                                                            className="text-danger  error-text"
                                                                                            style={{
                                                                                                float: 'right',
                                                                                            }}
                                                                                        >
                                                                                            {
                                                                                                errors.customerNumberError
                                                                                            }
                                                                                        </span>
                                                                                    ) : (
                                                                                        ''
                                                                                    )}
                                                                                </>
                                                                            )}

                                                                            {item?.parameter ===
                                                                                'sortCode' && (
                                                                                <>
                                                                                    {item?.multiSelect ===
                                                                                    false ? (
                                                                                        <DropDownListComponent
                                                                                            dataSource={
                                                                                                item?.wsData
                                                                                            }
                                                                                            value={
                                                                                                formValues[
                                                                                                    item
                                                                                                        .parameter
                                                                                                ]
                                                                                            }
                                                                                            fields={{
                                                                                                text: 'debitReasonCode',
                                                                                                value: 'debitReasonCode',
                                                                                            }}
                                                                                            allowFiltering={
                                                                                                true
                                                                                            }
                                                                                            placeholder="Select sort code"
                                                                                            popupHeight="220px"
                                                                                            sortOrder="Ascending"
                                                                                            change={(
                                                                                                e
                                                                                            ) => {
                                                                                                
                                                                                                if (
                                                                                                    e.value ===
                                                                                                        null ||
                                                                                                    e
                                                                                                        .value
                                                                                                        ?.length ===
                                                                                                        0
                                                                                                ) {
                                                                                                    if (
                                                                                                        item.mandatoryField ===
                                                                                                        true
                                                                                                    ) {
                                                                                                        generateDynamicLink(
                                                                                                            '',
                                                                                                            item?.parameter,
                                                                                                            item?.type
                                                                                                        );
                                                                                                    } else {
                                                                                                        generateDynamicLink(
                                                                                                            null,
                                                                                                            item?.parameter,
                                                                                                            item?.type
                                                                                                        );
                                                                                                    }
                                                                                                } else {
                                                                                                    generateDynamicLink(
                                                                                                        e.value,
                                                                                                        item?.parameter,
                                                                                                        item?.type
                                                                                                    );
                                                                                                }
                                                                                            }}
                                                                                            style={{
                                                                                                borderBottom:
                                                                                                    sortCodeClass,
                                                                                            }}
                                                                                        />
                                                                                    ) : (
                                                                                        <MultiSelectComponent
                                                                                            className="form-control"
                                                                                            dataSource={
                                                                                                item?.wsData
                                                                                            }
                                                                                            fields={{
                                                                                                text: 'debitReasonCode',
                                                                                                value: 'debitReasonCode',
                                                                                            }}
                                                                                            placeholder="Select"
                                                                                            mode="CheckBox"
                                                                                            showSelectAll={
                                                                                                true
                                                                                            }
                                                                                            value={
                                                                                                formValues[
                                                                                                    item
                                                                                                        .parameter
                                                                                                ]
                                                                                            }
                                                                                            showDropDownIcon={
                                                                                                true
                                                                                            }
                                                                                            filterBarPlaceholder="Search"
                                                                                            popupHeight="250px"
                                                                                            change={(
                                                                                                e
                                                                                            ) => {
                                                                                               
                                                                                                if (
                                                                                                    e.value ===
                                                                                                        null ||
                                                                                                    e
                                                                                                        .value
                                                                                                        ?.length ===
                                                                                                        0
                                                                                                ) {
                                                                                                    if (
                                                                                                        item.mandatoryField ===
                                                                                                        true
                                                                                                    ) {
                                                                                                        generateDynamicLink(
                                                                                                            '',
                                                                                                            item?.parameter,
                                                                                                            item?.type
                                                                                                        );
                                                                                                    } else {
                                                                                                        generateDynamicLink(
                                                                                                            null,
                                                                                                            item?.parameter,
                                                                                                            item?.type
                                                                                                        );
                                                                                                    }
                                                                                                } else {
                                                                                                    generateDynamicLink(
                                                                                                        e.value,
                                                                                                        item?.parameter,
                                                                                                        item?.type
                                                                                                    );
                                                                                                }
                                                                                            }}
                                                                                            sortOrder="Ascending"
                                                                                            style={{
                                                                                                borderBottom:
                                                                                                    sortCodeClass,
                                                                                            }}
                                                                                        >
                                                                                            <Inject
                                                                                                services={[
                                                                                                    CheckBoxSelection,
                                                                                                ]}
                                                                                            />
                                                                                        </MultiSelectComponent>
                                                                                    )}

                                                                                    {item.mandatoryField ===
                                                                                        true &&
                                                                                    formValues[
                                                                                        item
                                                                                            .parameter
                                                                                    ] ===
                                                                                        '' ? (
                                                                                        <span
                                                                                            className="text-danger  error-text"
                                                                                            style={{
                                                                                                float: 'right',
                                                                                            }}
                                                                                        >
                                                                                            {
                                                                                                errors.sortCodeError
                                                                                            }
                                                                                        </span>
                                                                                    ) : (
                                                                                        ''
                                                                                    )}
                                                                                </>
                                                                            )}

                                                                            {item?.parameter ===
                                                                                'scannedBy' && (
                                                                                <>
                                                                                    {item?.multiSelect ===
                                                                                    false ? (
                                                                                        <DropDownListComponent
                                                                                            dataSource={
                                                                                                item?.wsData
                                                                                            }
                                                                                            value={
                                                                                                formValues[
                                                                                                    item
                                                                                                        .parameter
                                                                                                ]
                                                                                            }
                                                                                            fields={{
                                                                                                text: 'scannedByCombo',
                                                                                                value: 'id',
                                                                                            }}
                                                                                            allowFiltering={
                                                                                                true
                                                                                            }
                                                                                            filtering={getScannedByCombo.bind(
                                                                                                this
                                                                                            )}
                                                                                            placeholder="Select"
                                                                                            popupHeight="220px"
                                                                                            sortOrder="Ascending"
                                                                                            change={(
                                                                                                e
                                                                                            ) => {
                                                                                                if (
                                                                                                    e.value ===
                                                                                                        null ||
                                                                                                    e
                                                                                                        .value
                                                                                                        ?.length ===
                                                                                                        0
                                                                                                ) {
                                                                                                    if (
                                                                                                        item.mandatoryField ===
                                                                                                        true
                                                                                                    ) {
                                                                                                        generateDynamicLink(
                                                                                                            '',
                                                                                                            item?.parameter,
                                                                                                            item?.type
                                                                                                        );
                                                                                                    } else {
                                                                                                        generateDynamicLink(
                                                                                                            null,
                                                                                                            item?.parameter,
                                                                                                            item?.type
                                                                                                        );
                                                                                                    }
                                                                                                } else {
                                                                                                    generateDynamicLink(
                                                                                                        e.value,
                                                                                                        item?.parameter,
                                                                                                        item?.type
                                                                                                    );
                                                                                                }
                                                                                            }}
                                                                                            style={{
                                                                                                borderBottom:
                                                                                                    scannedByClass,
                                                                                            }}
                                                                                        />
                                                                                    ) : (
                                                                                        <>
                                                                                         <div className="col-sm-12 pl-2">
                                                                                         <span className="lblClass cursor-pointer mt-0" style={{ float: "left" }}>
                                                                                        <input type="checkbox" checked={isAllSelected}
                                                                                         id="selectAll"
                                                                                         onChange={(
                                                                                            e:any
                                                                                        ) => {
                                                                                            {
                                                                                                handleSelectAll(e)
                                                                                                }
                                                                                        }}
                                                                                         />
                                                                                         <span className="defaultAdd pl-2">Select All</span>
                                                                                         </span>
                                                                                         </div>
                                                                                        <MultiSelectComponent
                                                                                            className="form-control"
                                                                                            dataSource={
                                                                                                item?.wsData
                                                                                            }
                                                                                            maximumSelectionLength = {10000}
                                                                                            disabled = {isAllSelected}
                                                                                            fields={{
                                                                                                text: 'scannedByCombo',
                                                                                                value: 'id',
                                                                                            }}
                                                                                            filtering={getScannedByCombo.bind(
                                                                                                this
                                                                                            )}
                                                                                            placeholder="Select"
                                                                                            mode="CheckBox"
                                                                                            // showSelectAll={
                                                                                            //     true
                                                                                            // }
                                                                                            value={
                                                                                                formValues[
                                                                                                    item
                                                                                                        .parameter
                                                                                                ]
                                                                                            }
                                                                                            showDropDownIcon={
                                                                                                true
                                                                                            }
                                                                                            filterBarPlaceholder="Search"
                                                                                            popupHeight="250px"
                                                                                            change={(
                                                                                                e
                                                                                            ) => {
                                                                                                if (
                                                                                                    e.value ===
                                                                                                        null ||
                                                                                                    e
                                                                                                        .value
                                                                                                        ?.length ===
                                                                                                        0
                                                                                                ) {
                                                                                                    if (
                                                                                                        item.mandatoryField ===
                                                                                                        true
                                                                                                    ) {
                                                                                                        generateDynamicLink(
                                                                                                            '',
                                                                                                            item?.parameter,
                                                                                                            item?.type
                                                                                                        );
                                                                                                    } else {
                                                                                                        generateDynamicLink(
                                                                                                            null,
                                                                                                            item?.parameter,
                                                                                                            item?.type
                                                                                                        );
                                                                                                    }
                                                                                                } else {
                                                                                                    generateDynamicLink(
                                                                                                        e.value,
                                                                                                        item?.parameter,
                                                                                                        item?.type
                                                                                                    );
                                                                                                }
                                                                                            }}
                                                                                            style={{
                                                                                                borderBottom:
                                                                                                    scannedByClass,
                                                                                            }}
                                                                                            sortOrder="Ascending"
                                                                                        >
                                                                                            <Inject
                                                                                                services={[
                                                                                                    CheckBoxSelection,
                                                                                                ]}
                                                                                            />
                                                                                        </MultiSelectComponent>
                                                                                        </>
                                                                                    )}
                                                                                    {item.mandatoryField ===
                                                                                        true &&
                                                                                    formValues[
                                                                                        item
                                                                                            .parameter
                                                                                    ] ===
                                                                                        '' ? (
                                                                                        <span
                                                                                            className="text-danger  error-text"
                                                                                            style={{
                                                                                                float: 'right',
                                                                                            }}
                                                                                        >
                                                                                            {
                                                                                                errors.scannedByError
                                                                                            }
                                                                                        </span>
                                                                                    ) : (
                                                                                        ''
                                                                                    )}
                                                                                </>
                                                                            )}

                                                                            {item?.parameter ===
                                                                                'commodityCode' && (
                                                                                <>
                                                                                    {item?.multiSelect ===
                                                                                    false ? (
                                                                                        <DropDownListComponent
                                                                                            dataSource={
                                                                                                item?.wsData
                                                                                            }
                                                                                            value={
                                                                                                formValues[
                                                                                                    item
                                                                                                        .parameter
                                                                                                ]
                                                                                            }
                                                                                            fields={{
                                                                                                text: 'code',
                                                                                                value: 'code',
                                                                                            }}
                                                                                            allowFiltering={
                                                                                                true
                                                                                            }
                                                                                            placeholder="Select"
                                                                                            popupHeight="220px"
                                                                                            sortOrder="Ascending"
                                                                                            change={(
                                                                                                e
                                                                                            ) => {
                                                                                                if (
                                                                                                    e.value ===
                                                                                                        null ||
                                                                                                    e
                                                                                                        .value
                                                                                                        ?.length ===
                                                                                                        0
                                                                                                ) {
                                                                                                    if (
                                                                                                        item.mandatoryField ===
                                                                                                        true
                                                                                                    ) {
                                                                                                        generateDynamicLink(
                                                                                                            '',
                                                                                                            item?.parameter,
                                                                                                            item?.type
                                                                                                        );
                                                                                                    } else {
                                                                                                        generateDynamicLink(
                                                                                                            null,
                                                                                                            item?.parameter,
                                                                                                            item?.type
                                                                                                        );
                                                                                                    }
                                                                                                } else {
                                                                                                    generateDynamicLink(
                                                                                                        e.value,
                                                                                                        item?.parameter,
                                                                                                        item?.type
                                                                                                    );
                                                                                                }
                                                                                            }}
                                                                                            style={{
                                                                                                borderBottom:
                                                                                                    commodityCodeClass,
                                                                                            }}
                                                                                        />
                                                                                    ) : (
                                                                                        <MultiSelectComponent
                                                                                            className="form-control"
                                                                                            dataSource={
                                                                                                item?.wsData
                                                                                            }
                                                                                            fields={{
                                                                                                text: 'code',
                                                                                                value: 'code',
                                                                                            }}
                                                                                            placeholder="Select"
                                                                                            mode="CheckBox"
                                                                                            showSelectAll={
                                                                                                true
                                                                                            }
                                                                                            value={
                                                                                                formValues[
                                                                                                    item
                                                                                                        .parameter
                                                                                                ]
                                                                                            }
                                                                                            showDropDownIcon={
                                                                                                true
                                                                                            }
                                                                                            filterBarPlaceholder="Search"
                                                                                            popupHeight="250px"
                                                                                            change={(
                                                                                                e
                                                                                            ) => {
                                                                                                if (
                                                                                                    e.value ===
                                                                                                        null ||
                                                                                                    e
                                                                                                        .value
                                                                                                        ?.length ===
                                                                                                        0
                                                                                                ) {
                                                                                                    if (
                                                                                                        item.mandatoryField ===
                                                                                                        true
                                                                                                    ) {
                                                                                                        generateDynamicLink(
                                                                                                            '',
                                                                                                            item?.parameter,
                                                                                                            item?.type
                                                                                                        );
                                                                                                    } else {
                                                                                                        generateDynamicLink(
                                                                                                            null,
                                                                                                            item?.parameter,
                                                                                                            item?.type
                                                                                                        );
                                                                                                    }
                                                                                                } else {
                                                                                                    generateDynamicLink(
                                                                                                        e.value,
                                                                                                        item?.parameter,
                                                                                                        item?.type
                                                                                                    );
                                                                                                }
                                                                                            }}
                                                                                            sortOrder="Ascending"
                                                                                            style={{
                                                                                                borderBottom:
                                                                                                    commodityCodeClass,
                                                                                            }}
                                                                                        >
                                                                                            <Inject
                                                                                                services={[
                                                                                                    CheckBoxSelection,
                                                                                                ]}
                                                                                            />
                                                                                        </MultiSelectComponent>
                                                                                    )}
                                                                                    {item.mandatoryField ===
                                                                                        true &&
                                                                                    formValues[
                                                                                        item
                                                                                            .parameter
                                                                                    ] ===
                                                                                        '' ? (
                                                                                        <span
                                                                                            className="text-danger  error-text"
                                                                                            style={{
                                                                                                float: 'right',
                                                                                            }}
                                                                                        >
                                                                                            {
                                                                                                errors.commodityCodeError
                                                                                            }
                                                                                        </span>
                                                                                    ) : (
                                                                                        ''
                                                                                    )}
                                                                                </>
                                                                            )}

                                                                            {item?.parameter ===
                                                                                'warehouseLocation' && (
                                                                                <>
                                                                                    {item?.multiSelect ===
                                                                                    false ? (
                                                                                        <DropDownListComponent
                                                                                            dataSource={
                                                                                                item?.wsData
                                                                                            }
                                                                                            value={
                                                                                                formValues[
                                                                                                    item
                                                                                                        .parameter
                                                                                                ]
                                                                                            }
                                                                                            fields={{
                                                                                                text: 'warehouseLocation',
                                                                                                value: 'warehouseLocation',
                                                                                            }}
                                                                                            placeholder="Select"
                                                                                            popupHeight="220px"
                                                                                            sortOrder="Ascending"
                                                                                            allowFiltering={
                                                                                                true
                                                                                            }
                                                                                            change={(
                                                                                                e
                                                                                            ) => {
                                                                                                if (
                                                                                                    e.value ===
                                                                                                        null ||
                                                                                                    e
                                                                                                        .value
                                                                                                        ?.length ===
                                                                                                        0
                                                                                                ) {
                                                                                                    if (
                                                                                                        item.mandatoryField ===
                                                                                                        true
                                                                                                    ) {
                                                                                                        generateDynamicLink(
                                                                                                            '',
                                                                                                            item?.parameter,
                                                                                                            item?.type
                                                                                                        );
                                                                                                    } else {
                                                                                                        generateDynamicLink(
                                                                                                            null,
                                                                                                            item?.parameter,
                                                                                                            item?.type
                                                                                                        );
                                                                                                    }
                                                                                                } else {
                                                                                                    generateDynamicLink(
                                                                                                        e.value,
                                                                                                        item?.parameter,
                                                                                                        item?.type
                                                                                                    );
                                                                                                }
                                                                                            }}
                                                                                            style={{
                                                                                                borderBottom:
                                                                                                    wareHouseLocationClass,
                                                                                            }}
                                                                                        />
                                                                                    ) : (
                                                                                        <MultiSelectComponent
                                                                                            className="form-control"
                                                                                            dataSource={
                                                                                                item?.wsData
                                                                                            }
                                                                                            fields={{
                                                                                                text: 'warehouseLocation',
                                                                                                value: 'warehouseLocation',
                                                                                            }}
                                                                                            placeholder="Select"
                                                                                            mode="CheckBox"
                                                                                            showSelectAll={
                                                                                                true
                                                                                            }
                                                                                            value={
                                                                                                formValues[
                                                                                                    item
                                                                                                        .parameter
                                                                                                ]
                                                                                            }
                                                                                            showDropDownIcon={
                                                                                                true
                                                                                            }
                                                                                            filterBarPlaceholder="Search"
                                                                                            popupHeight="250px"
                                                                                            change={(
                                                                                                e
                                                                                            ) => {
                                                                                                if (
                                                                                                    e.value ===
                                                                                                        null ||
                                                                                                    e
                                                                                                        .value
                                                                                                        ?.length ===
                                                                                                        0
                                                                                                ) {
                                                                                                    if (
                                                                                                        item.mandatoryField ===
                                                                                                        true
                                                                                                    ) {
                                                                                                        generateDynamicLink(
                                                                                                            '',
                                                                                                            item?.parameter,
                                                                                                            item?.type
                                                                                                        );
                                                                                                    } else {
                                                                                                        generateDynamicLink(
                                                                                                            null,
                                                                                                            item?.parameter,
                                                                                                            item?.type
                                                                                                        );
                                                                                                    }
                                                                                                } else {
                                                                                                    generateDynamicLink(
                                                                                                        e.value,
                                                                                                        item?.parameter,
                                                                                                        item?.type
                                                                                                    );
                                                                                                }
                                                                                            }}
                                                                                            sortOrder="Ascending"
                                                                                            style={{
                                                                                                borderBottom:
                                                                                                    wareHouseLocationClass,
                                                                                            }}
                                                                                        >
                                                                                            <Inject
                                                                                                services={[
                                                                                                    CheckBoxSelection,
                                                                                                ]}
                                                                                            />
                                                                                        </MultiSelectComponent>
                                                                                    )}
                                                                                    {item.mandatoryField ===
                                                                                        true &&
                                                                                    formValues[
                                                                                        item
                                                                                            .parameter
                                                                                    ] ===
                                                                                        '' ? (
                                                                                        <span
                                                                                            className="text-danger  error-text"
                                                                                            style={{
                                                                                                float: 'right',
                                                                                            }}
                                                                                        >
                                                                                            {
                                                                                                errors.warehouseLocationError
                                                                                            }
                                                                                        </span>
                                                                                    ) : (
                                                                                        ''
                                                                                    )}
                                                                                </>
                                                                            )}

                                                                            {item?.parameter ===
                                                                                'scanMode' && (
                                                                                <>
                                                                                    {item?.multiSelect ===
                                                                                    false ? (
                                                                                        <DropDownListComponent
                                                                                            dataSource={
                                                                                                item?.wsData
                                                                                            }
                                                                                            value={
                                                                                                formValues[
                                                                                                    item
                                                                                                        .parameter
                                                                                                ]
                                                                                            }
                                                                                            fields={{
                                                                                                text: 'scanMode',
                                                                                                value: 'scanMode',
                                                                                            }}
                                                                                            allowFiltering={
                                                                                                true
                                                                                            }
                                                                                            placeholder="Select"
                                                                                            popupHeight="220px"
                                                                                            sortOrder="Ascending"
                                                                                            change={(
                                                                                                e
                                                                                            ) => {
                                                                                                if (
                                                                                                    e.value ===
                                                                                                        null ||
                                                                                                    e
                                                                                                        .value
                                                                                                        ?.length ===
                                                                                                        0
                                                                                                ) {
                                                                                                    if (
                                                                                                        item.mandatoryField ===
                                                                                                        true
                                                                                                    ) {
                                                                                                        generateDynamicLink(
                                                                                                            '',
                                                                                                            item?.parameter,
                                                                                                            item?.type
                                                                                                        );
                                                                                                    } else {
                                                                                                        generateDynamicLink(
                                                                                                            null,
                                                                                                            item?.parameter,
                                                                                                            item?.type
                                                                                                        );
                                                                                                    }
                                                                                                } else {
                                                                                                    generateDynamicLink(
                                                                                                        e.value,
                                                                                                        item?.parameter,
                                                                                                        item?.type
                                                                                                    );
                                                                                                }
                                                                                            }}
                                                                                            style={{
                                                                                                borderBottom:
                                                                                                    scanModeClass,
                                                                                            }}
                                                                                        />
                                                                                    ) : (
                                                                                        <MultiSelectComponent
                                                                                            className="form-control"
                                                                                            dataSource={
                                                                                                item?.wsData
                                                                                            }
                                                                                            fields={{
                                                                                                text: 'scanMode',
                                                                                                value: 'scanMode',
                                                                                            }}
                                                                                            placeholder="Select"
                                                                                            mode="CheckBox"
                                                                                            showSelectAll={
                                                                                                true
                                                                                            }
                                                                                            value={
                                                                                                formValues[
                                                                                                    item
                                                                                                        .parameter
                                                                                                ]
                                                                                            }
                                                                                            showDropDownIcon={
                                                                                                true
                                                                                            }
                                                                                            filterBarPlaceholder="Search"
                                                                                            popupHeight="250px"
                                                                                            change={(
                                                                                                e
                                                                                            ) => {
                                                                                                if (
                                                                                                    e.value ===
                                                                                                        null ||
                                                                                                    e
                                                                                                        .value
                                                                                                        ?.length ===
                                                                                                        0
                                                                                                ) {
                                                                                                    if (
                                                                                                        item.mandatoryField ===
                                                                                                        true
                                                                                                    ) {
                                                                                                        generateDynamicLink(
                                                                                                            '',
                                                                                                            item?.parameter,
                                                                                                            item?.type
                                                                                                        );
                                                                                                    } else {
                                                                                                        generateDynamicLink(
                                                                                                            null,
                                                                                                            item?.parameter,
                                                                                                            item?.type
                                                                                                        );
                                                                                                    }
                                                                                                } else {
                                                                                                    generateDynamicLink(
                                                                                                        e.value,
                                                                                                        item?.parameter,
                                                                                                        item?.type
                                                                                                    );
                                                                                                }
                                                                                            }}
                                                                                            sortOrder="Ascending"
                                                                                            style={{
                                                                                                borderBottom:
                                                                                                    scanModeClass,
                                                                                            }}
                                                                                        >
                                                                                            <Inject
                                                                                                services={[
                                                                                                    CheckBoxSelection,
                                                                                                ]}
                                                                                            />
                                                                                        </MultiSelectComponent>
                                                                                    )}
                                                                                    {item.mandatoryField ===
                                                                                        true &&
                                                                                    formValues[
                                                                                        item
                                                                                            .parameter
                                                                                    ] ===
                                                                                        '' ? (
                                                                                        <span
                                                                                            className="text-danger  error-text"
                                                                                            style={{
                                                                                                float: 'right',
                                                                                            }}
                                                                                        >
                                                                                            {
                                                                                                errors.scanModeError
                                                                                            }
                                                                                        </span>
                                                                                    ) : (
                                                                                        ''
                                                                                    )}
                                                                                </>
                                                                            )}

                                                                            {item?.parameter ===
                                                                                'disposition' && (
                                                                                <>
                                                                                    {item?.multiSelect ===
                                                                                    false ? (
                                                                                        <DropDownListComponent
                                                                                            dataSource={
                                                                                                item?.wsData
                                                                                            }
                                                                                            value={
                                                                                                formValues[
                                                                                                    item
                                                                                                        .parameter
                                                                                                ]
                                                                                            }
                                                                                            fields={{
                                                                                                text: 'disposition',
                                                                                                value: 'disposition',
                                                                                            }}
                                                                                            allowFiltering={
                                                                                                true
                                                                                            }
                                                                                            placeholder="Select"
                                                                                            popupHeight="220px"
                                                                                            sortOrder="Ascending"
                                                                                            change={(
                                                                                                e
                                                                                            ) => {
                                                                                                if (
                                                                                                    e.value ===
                                                                                                        null ||
                                                                                                    e
                                                                                                        .value
                                                                                                        ?.length ===
                                                                                                        0
                                                                                                ) {
                                                                                                    if (
                                                                                                        item.mandatoryField ===
                                                                                                        true
                                                                                                    ) {
                                                                                                        generateDynamicLink(
                                                                                                            '',
                                                                                                            item?.parameter,
                                                                                                            item?.type
                                                                                                        );
                                                                                                    } else {
                                                                                                        generateDynamicLink(
                                                                                                            null,
                                                                                                            item?.parameter,
                                                                                                            item?.type
                                                                                                        );
                                                                                                    }
                                                                                                } else {
                                                                                                    generateDynamicLink(
                                                                                                        e.value,
                                                                                                        item?.parameter,
                                                                                                        item?.type
                                                                                                    );
                                                                                                }
                                                                                            }}
                                                                                            style={{
                                                                                                borderBottom:
                                                                                                    dispositionClass,
                                                                                            }}
                                                                                        />
                                                                                    ) : (
                                                                                        <MultiSelectComponent
                                                                                            className="form-control"
                                                                                            dataSource={
                                                                                                item?.wsData
                                                                                            }
                                                                                            fields={{
                                                                                                text: 'disposition',
                                                                                                value: 'disposition',
                                                                                            }}
                                                                                            placeholder="Select"
                                                                                            mode="CheckBox"
                                                                                            showSelectAll={
                                                                                                true
                                                                                            }
                                                                                            value={
                                                                                                formValues[
                                                                                                    item
                                                                                                        .parameter
                                                                                                ]
                                                                                            }
                                                                                            showDropDownIcon={
                                                                                                true
                                                                                            }
                                                                                            filterBarPlaceholder="Search"
                                                                                            popupHeight="250px"
                                                                                            change={(
                                                                                                e
                                                                                            ) => {
                                                                                                if (
                                                                                                    e.value ===
                                                                                                        null ||
                                                                                                    e
                                                                                                        .value
                                                                                                        ?.length ===
                                                                                                        0
                                                                                                ) {
                                                                                                    if (
                                                                                                        item.mandatoryField ===
                                                                                                        true
                                                                                                    ) {
                                                                                                        generateDynamicLink(
                                                                                                            '',
                                                                                                            item?.parameter,
                                                                                                            item?.type
                                                                                                        );
                                                                                                    } else {
                                                                                                        generateDynamicLink(
                                                                                                            null,
                                                                                                            item?.parameter,
                                                                                                            item?.type
                                                                                                        );
                                                                                                    }
                                                                                                } else {
                                                                                                    generateDynamicLink(
                                                                                                        e.value,
                                                                                                        item?.parameter,
                                                                                                        item?.type
                                                                                                    );
                                                                                                }
                                                                                            }}
                                                                                            sortOrder="Ascending"
                                                                                            style={{
                                                                                                borderBottom:
                                                                                                    dispositionClass,
                                                                                            }}
                                                                                        >
                                                                                            <Inject
                                                                                                services={[
                                                                                                    CheckBoxSelection,
                                                                                                ]}
                                                                                            />
                                                                                        </MultiSelectComponent>
                                                                                    )}
                                                                                    {item.mandatoryField ===
                                                                                        true &&
                                                                                    formValues[
                                                                                        item
                                                                                            .parameter
                                                                                    ] ===
                                                                                        '' ? (
                                                                                        <span
                                                                                            className="text-danger  error-text"
                                                                                            style={{
                                                                                                float: 'right',
                                                                                            }}
                                                                                        >
                                                                                            {
                                                                                                errors.dispositionError
                                                                                            }
                                                                                        </span>
                                                                                    ) : (
                                                                                        ''
                                                                                    )}
                                                                                </>
                                                                            )}

                                                                            {item?.parameter ===
                                                                                'customerGroup' && (
                                                                                <>
                                                                                    {item?.multiSelect ===
                                                                                    false ? (
                                                                                        <DropDownListComponent
                                                                                            dataSource={
                                                                                                item?.wsData
                                                                                            }
                                                                                            value={
                                                                                                formValues[
                                                                                                    item
                                                                                                        .parameter
                                                                                                ]
                                                                                            }
                                                                                            fields={{
                                                                                                text: 'customerGroupCombo',
                                                                                                value: 'clientGroupName',
                                                                                            }}
                                                                                            placeholder="Select"
                                                                                            popupHeight="220px"
                                                                                            sortOrder="Ascending"
                                                                                            allowFiltering={
                                                                                                true
                                                                                            }
                                                                                            change={(
                                                                                                e
                                                                                            ) => {
                                                                                                if (
                                                                                                    e.value ===
                                                                                                        null ||
                                                                                                    e
                                                                                                        .value
                                                                                                        ?.length ===
                                                                                                        0
                                                                                                ) {
                                                                                                    if (
                                                                                                        item.mandatoryField ===
                                                                                                        true
                                                                                                    ) {
                                                                                                        generateDynamicLink(
                                                                                                            '',
                                                                                                            item?.parameter,
                                                                                                            item?.type
                                                                                                        );
                                                                                                    } else {
                                                                                                        generateDynamicLink(
                                                                                                            null,
                                                                                                            item?.parameter,
                                                                                                            item?.type
                                                                                                        );
                                                                                                    }
                                                                                                } else {
                                                                                                    generateDynamicLink(
                                                                                                        e.value,
                                                                                                        item?.parameter,
                                                                                                        item?.type
                                                                                                    );
                                                                                                }
                                                                                            }}
                                                                                            style={{
                                                                                                borderBottom:
                                                                                                    customerGroupClass,
                                                                                            }}
                                                                                        />
                                                                                    ) : (
                                                                                        <MultiSelectComponent
                                                                                            className="form-control"
                                                                                            dataSource={
                                                                                                item?.wsData
                                                                                            }
                                                                                            fields={{
                                                                                                text: 'customerGroupCombo',
                                                                                                value: 'clientGroupName',
                                                                                            }}
                                                                                            placeholder="Select"
                                                                                            mode="CheckBox"
                                                                                            showSelectAll={
                                                                                                true
                                                                                            }
                                                                                            value={
                                                                                                formValues[
                                                                                                    item
                                                                                                        .parameter
                                                                                                ]
                                                                                            }
                                                                                            showDropDownIcon={
                                                                                                true
                                                                                            }
                                                                                            filterBarPlaceholder="Search"
                                                                                            popupHeight="250px"
                                                                                            change={(
                                                                                                e
                                                                                            ) => {
                                                                                                if (
                                                                                                    e.value ===
                                                                                                        null ||
                                                                                                    e
                                                                                                        .value
                                                                                                        ?.length ===
                                                                                                        0
                                                                                                ) {
                                                                                                    if (
                                                                                                        item.mandatoryField ===
                                                                                                        true
                                                                                                    ) {
                                                                                                        generateDynamicLink(
                                                                                                            '',
                                                                                                            item?.parameter,
                                                                                                            item?.type
                                                                                                        );
                                                                                                    } else {
                                                                                                        generateDynamicLink(
                                                                                                            null,
                                                                                                            item?.parameter,
                                                                                                            item?.type
                                                                                                        );
                                                                                                    }
                                                                                                } else {
                                                                                                    generateDynamicLink(
                                                                                                        e.value,
                                                                                                        item?.parameter,
                                                                                                        item?.type
                                                                                                    );
                                                                                                }
                                                                                            }}
                                                                                            sortOrder="Ascending"
                                                                                            style={{
                                                                                                borderBottom:
                                                                                                    customerGroupClass,
                                                                                            }}
                                                                                        >
                                                                                            <Inject
                                                                                                services={[
                                                                                                    CheckBoxSelection,
                                                                                                ]}
                                                                                            />
                                                                                        </MultiSelectComponent>
                                                                                    )}
                                                                                    {item.mandatoryField ===
                                                                                        true &&
                                                                                    formValues[
                                                                                        item
                                                                                            .parameter
                                                                                    ] ===
                                                                                        '' ? (
                                                                                        <span
                                                                                            className="text-danger  error-text"
                                                                                            style={{
                                                                                                float: 'right',
                                                                                            }}
                                                                                        >
                                                                                            {
                                                                                                errors.customerGroupError
                                                                                            }
                                                                                        </span>
                                                                                    ) : (
                                                                                        ''
                                                                                    )}
                                                                                </>
                                                                            )}

                                                                            {item?.parameter ===
                                                                                'scannedByInternal' && (
                                                                                <>
                                                                                    {item?.multiSelect ===
                                                                                    false ? (
                                                                                        <DropDownListComponent
                                                                                            dataSource={
                                                                                                item?.wsData
                                                                                            }
                                                                                            value={
                                                                                                formValues[
                                                                                                    item
                                                                                                        .parameter
                                                                                                ]
                                                                                            }
                                                                                            fields={{
                                                                                                text: 'scannedByInternalCombo',
                                                                                                value: 'id',
                                                                                            }}
                                                                                            placeholder="Select"
                                                                                            popupHeight="220px"
                                                                                            sortOrder="Ascending"
                                                                                            allowFiltering={
                                                                                                true
                                                                                            }
                                                                                            change={(
                                                                                                e
                                                                                            ) => {
                                                                                                if (
                                                                                                    e.value ===
                                                                                                        null ||
                                                                                                    e
                                                                                                        .value
                                                                                                        ?.length ===
                                                                                                        0
                                                                                                ) {
                                                                                                    if (
                                                                                                        item.mandatoryField ===
                                                                                                        true
                                                                                                    ) {
                                                                                                        generateDynamicLink(
                                                                                                            '',
                                                                                                            item?.parameter,
                                                                                                            item?.type
                                                                                                        );
                                                                                                    } else {
                                                                                                        generateDynamicLink(
                                                                                                            null,
                                                                                                            item?.parameter,
                                                                                                            item?.type
                                                                                                        );
                                                                                                    }
                                                                                                } else {
                                                                                                    generateDynamicLink(
                                                                                                        e.value,
                                                                                                        item?.parameter,
                                                                                                        item?.type
                                                                                                    );
                                                                                                }
                                                                                            }}
                                                                                            style={{
                                                                                                borderBottom:
                                                                                                    scannedByInternalClass,
                                                                                            }}
                                                                                        />
                                                                                    ) : (
                                                                                        <MultiSelectComponent
                                                                                            className="form-control"
                                                                                            dataSource={
                                                                                                item?.wsData
                                                                                            }
                                                                                            fields={{
                                                                                                text: 'scannedByInternalCombo',
                                                                                                value: 'id',
                                                                                            }}
                                                                                            placeholder="Select"
                                                                                            mode="CheckBox"
                                                                                            showSelectAll={
                                                                                                true
                                                                                            }
                                                                                            value={
                                                                                                formValues[
                                                                                                    item
                                                                                                        .parameter
                                                                                                ]
                                                                                            }
                                                                                            showDropDownIcon={
                                                                                                true
                                                                                            }
                                                                                            filterBarPlaceholder="Search"
                                                                                            popupHeight="250px"
                                                                                            change={(
                                                                                                e
                                                                                            ) => {
                                                                                                if (
                                                                                                    e.value ===
                                                                                                        null ||
                                                                                                    e
                                                                                                        .value
                                                                                                        ?.length ===
                                                                                                        0
                                                                                                ) {
                                                                                                    if (
                                                                                                        item.mandatoryField ===
                                                                                                        true
                                                                                                    ) {
                                                                                                        generateDynamicLink(
                                                                                                            '',
                                                                                                            item?.parameter,
                                                                                                            item?.type
                                                                                                        );
                                                                                                    } else {
                                                                                                        generateDynamicLink(
                                                                                                            null,
                                                                                                            item?.parameter,
                                                                                                            item?.type
                                                                                                        );
                                                                                                    }
                                                                                                } else {
                                                                                                    generateDynamicLink(
                                                                                                        e.value,
                                                                                                        item?.parameter,
                                                                                                        item?.type
                                                                                                    );
                                                                                                }
                                                                                            }}
                                                                                            sortOrder="Ascending"
                                                                                            style={{
                                                                                                borderBottom:
                                                                                                    scannedByInternalClass,
                                                                                            }}
                                                                                        >
                                                                                            <Inject
                                                                                                services={[
                                                                                                    CheckBoxSelection,
                                                                                                ]}
                                                                                            />
                                                                                        </MultiSelectComponent>
                                                                                    )}
                                                                                    {item.mandatoryField ===
                                                                                        true &&
                                                                                    formValues[
                                                                                        item
                                                                                            .parameter
                                                                                    ] ===
                                                                                        '' ? (
                                                                                        <span
                                                                                            className="text-danger  error-text"
                                                                                            style={{
                                                                                                float: 'right',
                                                                                            }}
                                                                                        >
                                                                                            {
                                                                                                errors.scannedByInternalError
                                                                                            }
                                                                                        </span>
                                                                                    ) : (
                                                                                        ''
                                                                                    )}
                                                                                </>
                                                                            )}

                                                                            {(item?.parameter ===
                                                                                'storeId' ||
                                                                                item?.parameter ===
                                                                                    'storeNumber' ||
                                                                                    item?.parameter ===
                                                                                    'mainframeCustomerNumber'
                                                                                    ) && (
                                                                                <>
                                                                                    {item?.multiSelect ===
                                                                                    false ? (
                                                                                        <AsyncPaginate
                                                                                            additional={
                                                                                                defaultAdditional
                                                                                            }
                                                                                            value={
                                                                                                storeValues
                                                                                            }
                                                                                            loadOptions={
                                                                                                loadPageOptions
                                                                                            }
                                                                                            closeMenuOnSelect={
                                                                                                false
                                                                                            }
                                                                                            onChange={(
                                                                                                e
                                                                                            ) =>
                                                                                                generateDynamicLink(
                                                                                                    e,
                                                                                                    item?.parameter,
                                                                                                    item?.type
                                                                                                )
                                                                                            }
                                                                                        />
                                                                                    ) : (
                                                                                        // <AsyncPaginate additional={defaultAdditional} value={storeValues}
                                                                                        //     loadOptions={loadPageOptions}
                                                                                        //     closeMenuOnSelect={false}
                                                                                        //     onChange={(e: any) => {
                                                                                        //         console.log(e, item.parameter)
                                                                                        //         if (e === null || e?.length === 0 ) {
                                                                                        //             if (item.mandatoryField === true) {
                                                                                        //                 generateDynamicLink([], item?.parameter, item?.type)
                                                                                        //             } else {
                                                                                        //                 generateDynamicLink([], item?.parameter, item?.type)
                                                                                        //             }
                                                                                        //         } else {
                                                                                        //             generateDynamicLink(e, item?.parameter, item?.type)
                                                                                        //         }
                                                                                        //     }}
                                                                                        // />
                                                                                        <AsyncPaginate
                                                                                            additional={
                                                                                                defaultAdditional
                                                                                            }
                                                                                            value={
                                                                                                storeValues
                                                                                            }
                                                                                            loadOptions={
                                                                                                loadPageOptions
                                                                                            }
                                                                                            isMulti
                                                                                            closeMenuOnSelect={
                                                                                                false
                                                                                            }
                                                                                            onChange={(
                                                                                                e
                                                                                            ) =>
                                                                                                generateDynamicLink(
                                                                                                    e,
                                                                                                    item?.parameter,
                                                                                                    item?.type
                                                                                                )
                                                                                            }
                                                                                        />
                                                                                    )}
                                                                                    {item.mandatoryField ===
                                                                                        true &&
                                                                                    formValues[
                                                                                        item
                                                                                            .parameter
                                                                                    ] ===
                                                                                        '' ? (
                                                                                        <span
                                                                                            className="text-danger error-text"
                                                                                            style={{
                                                                                                float: 'right',
                                                                                            }}
                                                                                        >
                                                                                            {
                                                                                                errors.storeIdError
                                                                                            }
                                                                                        </span>
                                                                                    ) : (
                                                                                        ''
                                                                                    )}
                                                                                </>
                                                                            )}
                                                                            {item?.parameter ===
                                                                                'vendorId' && (
                                                                                <>
                                                                                    {item?.multiSelect ===
                                                                                    false ? (
                                                                                        <DropDownListComponent
                                                                                            dataSource={
                                                                                                item?.wsData
                                                                                            }
                                                                                            value={
                                                                                                formValues[
                                                                                                    item
                                                                                                        .parameter
                                                                                                ]
                                                                                            }
                                                                                            fields={{
                                                                                                text: 'vendorName',
                                                                                                value: 'vendorNumber',
                                                                                            }}
                                                                                            placeholder="Select"
                                                                                            popupHeight="220px"
                                                                                            filtering={getVendorList.bind(
                                                                                                this
                                                                                            )}
                                                                                            allowFiltering={
                                                                                                true
                                                                                            }
                                                                                            sortOrder="Ascending"
                                                                                            style={{
                                                                                                borderBottom:
                                                                                                    vendorIdClass,
                                                                                            }}
                                                                                            change={(
                                                                                                e
                                                                                            ) => {
                                                                                                if (
                                                                                                    e.value ===
                                                                                                        null ||
                                                                                                    e
                                                                                                        .value
                                                                                                        ?.length ===
                                                                                                        0
                                                                                                ) {
                                                                                                    if (
                                                                                                        item.mandatoryField ===
                                                                                                        true
                                                                                                    ) {
                                                                                                        generateDynamicLink(
                                                                                                            '',
                                                                                                            item?.parameter,
                                                                                                            item?.type
                                                                                                        );
                                                                                                    } else {
                                                                                                        generateDynamicLink(
                                                                                                            null,
                                                                                                            item?.parameter,
                                                                                                            item?.type
                                                                                                        );
                                                                                                    }
                                                                                                } else {
                                                                                                    generateDynamicLink(
                                                                                                        e.value,
                                                                                                        item?.parameter,
                                                                                                        item?.type
                                                                                                    );
                                                                                                }
                                                                                            }}
                                                                                        />
                                                                                    ) : (
                                                                                        <MultiSelectComponent
                                                                                            className="form-control"
                                                                                            dataSource={
                                                                                                item?.wsData
                                                                                            }
                                                                                            fields={{
                                                                                                text: 'vendorName',
                                                                                                value: 'vendorNumber',
                                                                                            }}
                                                                                            placeholder="Select"
                                                                                            mode="CheckBox"
                                                                                            showSelectAll={
                                                                                                false
                                                                                            }
                                                                                            value={
                                                                                                formValues[
                                                                                                    item
                                                                                                        .parameter
                                                                                                ]
                                                                                            }
                                                                                            showDropDownIcon={
                                                                                                true
                                                                                            }
                                                                                            allowFiltering={
                                                                                                true
                                                                                            }
                                                                                            filterBarPlaceholder="Search"
                                                                                            popupHeight="250px"
                                                                                            filtering={getVendorList.bind(
                                                                                                this
                                                                                            )}
                                                                                            change={(
                                                                                                e
                                                                                            ) => {
                                                                                                if (
                                                                                                    e.value ===
                                                                                                        null ||
                                                                                                    e
                                                                                                        .value
                                                                                                        ?.length ===
                                                                                                        0
                                                                                                ) {
                                                                                                    if (
                                                                                                        item.mandatoryField ===
                                                                                                        true
                                                                                                    ) {
                                                                                                        generateDynamicLink(
                                                                                                            '',
                                                                                                            item?.parameter,
                                                                                                            item?.type
                                                                                                        );
                                                                                                    } else {
                                                                                                        generateDynamicLink(
                                                                                                            null,
                                                                                                            item?.parameter,
                                                                                                            item?.type
                                                                                                        );
                                                                                                    }
                                                                                                } else {
                                                                                                    generateDynamicLink(
                                                                                                        e.value,
                                                                                                        item?.parameter,
                                                                                                        item?.type
                                                                                                    );
                                                                                                }
                                                                                            }}
                                                                                            sortOrder="Ascending"
                                                                                            style={{
                                                                                                borderBottom:
                                                                                                    vendorIdClass,
                                                                                            }}
                                                                                        >
                                                                                            <Inject
                                                                                                services={[
                                                                                                    CheckBoxSelection,
                                                                                                ]}
                                                                                            />
                                                                                        </MultiSelectComponent>
                                                                                    )}
                                                                                    {item.mandatoryField ===
                                                                                        true &&
                                                                                    formValues[
                                                                                        item
                                                                                            .parameter
                                                                                    ] ===
                                                                                        '' ? (
                                                                                        <span
                                                                                            className="text-danger  error-text"
                                                                                            style={{
                                                                                                float: 'right',
                                                                                            }}
                                                                                        >
                                                                                            {
                                                                                                errors.vendorIdError
                                                                                            }
                                                                                        </span>
                                                                                    ) : (
                                                                                        ''
                                                                                    )}
                                                                                </>
                                                                            )}

                                                                            {item?.parameter ===
                                                                                'upc' && (
                                                                                <>
                                                                                    {item?.multiSelect ===
                                                                                    false ? (
                                                                                        <DropDownListComponent
                                                                                            dataSource={
                                                                                                item?.wsData
                                                                                            }
                                                                                            value={
                                                                                                formValues[
                                                                                                    item
                                                                                                        .parameter
                                                                                                ]
                                                                                            }
                                                                                            fields={{
                                                                                                text: 'upc',
                                                                                                value: 'upc',
                                                                                            }}
                                                                                            placeholder="Select"
                                                                                            popupHeight="220px"
                                                                                            sortOrder="Ascending"
                                                                                            allowFiltering={
                                                                                                true
                                                                                            }
                                                                                            filtering={getUPClist.bind(
                                                                                                this
                                                                                            )}
                                                                                            change={(
                                                                                                e
                                                                                            ) => {
                                                                                                if (
                                                                                                    e.value ===
                                                                                                        null ||
                                                                                                    e
                                                                                                        .value
                                                                                                        ?.length ===
                                                                                                        0
                                                                                                ) {
                                                                                                    if (
                                                                                                        item.mandatoryField ===
                                                                                                        true
                                                                                                    ) {
                                                                                                        generateDynamicLink(
                                                                                                            '',
                                                                                                            item?.parameter,
                                                                                                            item?.type
                                                                                                        );
                                                                                                    } else {
                                                                                                        generateDynamicLink(
                                                                                                            null,
                                                                                                            item?.parameter,
                                                                                                            item?.type
                                                                                                        );
                                                                                                    }
                                                                                                } else {
                                                                                                    generateDynamicLink(
                                                                                                        e.value,
                                                                                                        item?.parameter,
                                                                                                        item?.type
                                                                                                    );
                                                                                                }
                                                                                            }}
                                                                                            style={{
                                                                                                borderBottom:
                                                                                                    upcClass,
                                                                                            }}
                                                                                        />
                                                                                    ) : (
                                                                                        <MultiSelectComponent
                                                                                            className="form-control"
                                                                                            dataSource={
                                                                                                item?.wsData
                                                                                            }
                                                                                            fields={{
                                                                                                text: 'upc',
                                                                                                value: 'upc',
                                                                                            }}
                                                                                            allowFiltering={
                                                                                                true
                                                                                            }
                                                                                            filtering={getUPClist.bind(
                                                                                                this
                                                                                            )}
                                                                                            placeholder="Select"
                                                                                            mode="CheckBox"
                                                                                            showSelectAll={
                                                                                                false
                                                                                            }
                                                                                            value={
                                                                                                formValues[
                                                                                                    item
                                                                                                        .parameter
                                                                                                ]
                                                                                            }
                                                                                            showDropDownIcon={
                                                                                                true
                                                                                            }
                                                                                            filterBarPlaceholder="Search"
                                                                                            popupHeight="250px"
                                                                                            change={(
                                                                                                e
                                                                                            ) => {
                                                                                                if (
                                                                                                    e.value ===
                                                                                                        null ||
                                                                                                    e
                                                                                                        .value
                                                                                                        ?.length ===
                                                                                                        0
                                                                                                ) {
                                                                                                    if (
                                                                                                        item.mandatoryField ===
                                                                                                        true
                                                                                                    ) {
                                                                                                        generateDynamicLink(
                                                                                                            '',
                                                                                                            item?.parameter,
                                                                                                            item?.type
                                                                                                        );
                                                                                                    } else {
                                                                                                        generateDynamicLink(
                                                                                                            null,
                                                                                                            item?.parameter,
                                                                                                            item?.type
                                                                                                        );
                                                                                                    }
                                                                                                } else {
                                                                                                    generateDynamicLink(
                                                                                                        e.value,
                                                                                                        item?.parameter,
                                                                                                        item?.type
                                                                                                    );
                                                                                                }
                                                                                            }}
                                                                                            sortOrder="Ascending"
                                                                                            style={{
                                                                                                borderBottom:
                                                                                                    upcClass,
                                                                                            }}
                                                                                        >
                                                                                            <Inject
                                                                                                services={[
                                                                                                    CheckBoxSelection,
                                                                                                ]}
                                                                                            />
                                                                                        </MultiSelectComponent>
                                                                                    )}
                                                                                    {item.mandatoryField ===
                                                                                        true &&
                                                                                    formValues[
                                                                                        item
                                                                                            .parameter
                                                                                    ] ===
                                                                                        '' ? (
                                                                                        <span
                                                                                            className="text-danger error-text"
                                                                                            style={{
                                                                                                float: 'right',
                                                                                            }}
                                                                                        >
                                                                                            {
                                                                                                errors.upcError
                                                                                            }
                                                                                        </span>
                                                                                    ) : (
                                                                                        ''
                                                                                    )}
                                                                                </>
                                                                            )}
                                                                          {item?.parameter ===
                                                                                'finalDisposition' ? (
                                                                                    <MultiSelectComponent
                                                                                    className="form-control"
                                                                                    dataSource={
                                                                                        item?.wsData?.data
                                                                                    }
                                                                                    allowFiltering={
                                                                                        true
                                                                                    }
                                                                                    placeholder="Select"
                                                                                    popupHeight="220px"
                                                                                    sortOrder="Ascending"
                                                                                    style={{
                                                                                        borderBottom:
                                                                                            finalDispositionClass,
                                                                                    }}
                                                                                    showDropDownIcon={
                                                                                        true
                                                                                    }
                                                                                    filterBarPlaceholder="Search"
                                                                                    change={(
                                                                                        e
                                                                                    ) => {
                                                                                        if (
                                                                                            e.value ===
                                                                                                null ||
                                                                                            e
                                                                                                .value
                                                                                                ?.length ===
                                                                                                0
                                                                                        ) {
                                                                                            if (
                                                                                                item.mandatoryField ===
                                                                                                true
                                                                                            ) {
                                                                                                generateDynamicLink(
                                                                                                    '',
                                                                                                    item?.parameter,
                                                                                                    item?.type
                                                                                                );
                                                                                            } else {
                                                                                                generateDynamicLink(
                                                                                                    null,
                                                                                                    item?.parameter,
                                                                                                    item?.type
                                                                                                );
                                                                                            }
                                                                                        } else {
                                                                                            generateDynamicLink(
                                                                                                e.value,
                                                                                                item?.parameter,
                                                                                                item?.type
                                                                                            );
                                                                                        }
                                                                                    }}
                                                                                    >
                                                                                         <Inject
                                                                                                services={[
                                                                                                    CheckBoxSelection,
                                                                                                ]}
                                                                                            />
                                                                                    </MultiSelectComponent>
                                                                                ):""}
                                                                            {item?.parameter ===
                                                                                'scanDate' && (
                                                                                <>
                                                                                    {item?.multiSelect ===
                                                                                    false ? (
                                                                                        <DropDownListComponent
                                                                                            dataSource={
                                                                                                item?.wsData
                                                                                            }
                                                                                            value={
                                                                                                formValues[
                                                                                                    item
                                                                                                        .parameter
                                                                                                ]
                                                                                            }
                                                                                            fields={{
                                                                                                text: 'dates',
                                                                                                value: 'dates',
                                                                                            }}
                                                                                            placeholder="Select"
                                                                                            popupHeight="220px"
                                                                                            sortOrder="Ascending"
                                                                                            style={{
                                                                                                borderBottom:
                                                                                                    scanDateClass,
                                                                                            }}
                                                                                            change={(
                                                                                                e
                                                                                            ) => {
                                                                                               
                                                                                                if (
                                                                                                    e.value ===
                                                                                                        null ||
                                                                                                    e
                                                                                                        .value
                                                                                                        ?.length ===
                                                                                                        0
                                                                                                ) {
                                                                                                    if (
                                                                                                        item.mandatoryField ===
                                                                                                        true
                                                                                                    ) {
                                                                                                        generateDynamicLink(
                                                                                                            '',
                                                                                                            item?.parameter,
                                                                                                            item?.type
                                                                                                        );
                                                                                                    } else {
                                                                                                        generateDynamicLink(
                                                                                                            null,
                                                                                                            item?.parameter,
                                                                                                            item?.type
                                                                                                        );
                                                                                                    }
                                                                                                } else {
                                                                                                    generateDynamicLink(
                                                                                                        e.value,
                                                                                                        item?.parameter,
                                                                                                        item?.type
                                                                                                    );
                                                                                                }
                                                                                            }}
                                                                                        />
                                                                                    ) : (
                                                                                        <MultiSelectComponent
                                                                                            className="form-control"
                                                                                            dataSource={
                                                                                                item?.wsData
                                                                                            }
                                                                                            fields={{
                                                                                                text: 'dates',
                                                                                                value: 'dates',
                                                                                            }}
                                                                                            placeholder="Select"
                                                                                            mode="CheckBox"
                                                                                            showSelectAll={
                                                                                                true
                                                                                            }
                                                                                            value={
                                                                                                formValues[
                                                                                                    item
                                                                                                        .parameter
                                                                                                ]
                                                                                            }
                                                                                            showDropDownIcon={
                                                                                                true
                                                                                            }
                                                                                            filterBarPlaceholder="Search"
                                                                                            popupHeight="250px"
                                                                                            change={(
                                                                                                e
                                                                                            ) => {
                                                                                               
                                                                                                if (
                                                                                                    e.value ===
                                                                                                        null ||
                                                                                                    e
                                                                                                        .value
                                                                                                        ?.length ===
                                                                                                        0
                                                                                                ) {
                                                                                                    if (
                                                                                                        item.mandatoryField ===
                                                                                                        true
                                                                                                    ) {
                                                                                                        generateDynamicLink(
                                                                                                            '',
                                                                                                            item?.parameter,
                                                                                                            item?.type
                                                                                                        );
                                                                                                    } else {
                                                                                                        generateDynamicLink(
                                                                                                            null,
                                                                                                            item?.parameter,
                                                                                                            item?.type
                                                                                                        );
                                                                                                    }
                                                                                                } else {
                                                                                                    generateDynamicLink(
                                                                                                        e.value,
                                                                                                        item?.parameter,
                                                                                                        item?.type
                                                                                                    );
                                                                                                }
                                                                                            }}
                                                                                            sortOrder="Ascending"
                                                                                            style={{
                                                                                                borderBottom:
                                                                                                    scanDateClass,
                                                                                            }}
                                                                                        >
                                                                                            <Inject
                                                                                                services={[
                                                                                                    CheckBoxSelection,
                                                                                                ]}
                                                                                            />
                                                                                        </MultiSelectComponent>
                                                                                    )}
                                                                                    {item.mandatoryField ===
                                                                                        true &&
                                                                                    formValues[
                                                                                        item
                                                                                            .parameter
                                                                                    ] ===
                                                                                        '' ? (
                                                                                        <span
                                                                                            className="text-danger  error-text"
                                                                                            style={{
                                                                                                float: 'right',
                                                                                            }}
                                                                                        >
                                                                                            {
                                                                                                errors.scanDateError
                                                                                            }
                                                                                        </span>
                                                                                    ) : (
                                                                                        ''
                                                                                    )}
                                                                                </>
                                                                            )}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            )}
                                                        </div>
                                                    ) : item?.type === 'Date' ||
                                                      item?.type === 'date' ? (
                                                        <div className="col-sm-12 p-0">
                                                            <div className="control-pane">
                                                                <div className="control-section">
                                                                    <div className="datepicker-control-section">
                                                                        {item?.parameter ===
                                                                            'fromDate' && (
                                                                            <>
                                                                                <DatePickerComponent
                                                                                    key={
                                                                                        formValues[
                                                                                            item
                                                                                                .parameter
                                                                                        ]
                                                                                    }
                                                                                    value={
                                                                                        formValues[
                                                                                            item
                                                                                                .parameter
                                                                                        ]
                                                                                    }
                                                                                    format="MMM dd, yyyy"
                                                                                    // max={maxDate}
                                                                                    change={(
                                                                                        e
                                                                                    ) => {
                                                                                        if (
                                                                                            e.value ===
                                                                                            null
                                                                                        ) {
                                                                                            if (
                                                                                                item.mandatoryField ===
                                                                                                true
                                                                                            ) {
                                                                                                generateDynamicLink(
                                                                                                    '',
                                                                                                    item?.parameter,
                                                                                                    item?.type
                                                                                                );
                                                                                            } else {
                                                                                                generateDynamicLink(
                                                                                                    e.value,
                                                                                                    item?.parameter,
                                                                                                    item?.type
                                                                                                );
                                                                                            }
                                                                                        } else {
                                                                                            generateDynamicLink(
                                                                                                e.value,
                                                                                                item?.parameter,
                                                                                                item?.type
                                                                                            );
                                                                                        }
                                                                                    }}
                                                                                    style={{
                                                                                        borderBottom:
                                                                                            fromDateClass,
                                                                                    }}
                                                                                ></DatePickerComponent>
                                                                                {item.mandatoryField ===
                                                                                    true &&
                                                                                formValues[
                                                                                    item
                                                                                        .parameter
                                                                                ] ===
                                                                                    '' ? (
                                                                                    <span
                                                                                        className="text-danger  error-text"
                                                                                        style={{
                                                                                            float: 'right',
                                                                                        }}
                                                                                    >
                                                                                        {
                                                                                            errors.fromDateError
                                                                                        }
                                                                                    </span>
                                                                                ) : (
                                                                                    ''
                                                                                )}
                                                                            </>
                                                                        )}

                                                                        {item?.parameter ===
                                                                            'toDate' && (
                                                                            <>
                                                                                <DatePickerComponent
                                                                                    key={
                                                                                        formValues[
                                                                                            item
                                                                                                .parameter
                                                                                        ]
                                                                                    }
                                                                                    value={
                                                                                        formValues[
                                                                                            item
                                                                                                .parameter
                                                                                        ]
                                                                                    }
                                                                                    format="MMM dd, yyyy"
                                                                                    min={
                                                                                        formValues?.fromDate
                                                                                    }
                                                                                    change={(
                                                                                        e
                                                                                    ) => {
                                                                                        if (
                                                                                            e.value ===
                                                                                            null
                                                                                        ) {
                                                                                            if (
                                                                                                item.mandatoryField ===
                                                                                                true
                                                                                            ) {
                                                                                                generateDynamicLink(
                                                                                                    '',
                                                                                                    item?.parameter,
                                                                                                    item?.type
                                                                                                );
                                                                                            } else {
                                                                                                generateDynamicLink(
                                                                                                    e.value,
                                                                                                    item?.parameter,
                                                                                                    item?.type
                                                                                                );
                                                                                            }
                                                                                        } else {
                                                                                            generateDynamicLink(
                                                                                                e.value,
                                                                                                item?.parameter,
                                                                                                item?.type
                                                                                            );
                                                                                        }
                                                                                    }}
                                                                                    style={{
                                                                                        borderBottom:
                                                                                            toDateClass,
                                                                                    }}
                                                                                ></DatePickerComponent>
                                                                                {item.mandatoryField ===
                                                                                    true &&
                                                                                formValues[
                                                                                    item
                                                                                        .parameter
                                                                                ] ===
                                                                                    '' ? (
                                                                                    <span
                                                                                        className="text-danger  error-text"
                                                                                        style={{
                                                                                            float: 'right',
                                                                                        }}
                                                                                    >
                                                                                        {
                                                                                            errors.toDateError
                                                                                        }
                                                                                    </span>
                                                                                ) : (
                                                                                    ''
                                                                                )}
                                                                            </>
                                                                        )}

                                                                        {item?.parameter ===
                                                                            'auditDate' && (
                                                                            <>
                                                                                <DatePickerComponent
                                                                                    key={
                                                                                        formValues[
                                                                                            item
                                                                                                .parameter
                                                                                        ]
                                                                                    }
                                                                                    value={
                                                                                        formValues[
                                                                                            item
                                                                                                .parameter
                                                                                        ]
                                                                                    }
                                                                                    format="MMM dd, yyyy"
                                                                                    change={(
                                                                                        e
                                                                                    ) => {
                                                                                        if (
                                                                                            e.value ===
                                                                                            null
                                                                                        ) {
                                                                                            if (
                                                                                                item.mandatoryField ===
                                                                                                true
                                                                                            ) {
                                                                                                generateDynamicLink(
                                                                                                    '',
                                                                                                    item?.parameter,
                                                                                                    item?.type
                                                                                                );
                                                                                            } else {
                                                                                                generateDynamicLink(
                                                                                                    e.value,
                                                                                                    item?.parameter,
                                                                                                    item?.type
                                                                                                );
                                                                                            }
                                                                                        } else {
                                                                                            generateDynamicLink(
                                                                                                e.value,
                                                                                                item?.parameter,
                                                                                                item?.type
                                                                                            );
                                                                                        }
                                                                                    }}
                                                                                    style={{
                                                                                        borderBottom:
                                                                                            auditDateClass,
                                                                                    }}
                                                                                ></DatePickerComponent>
                                                                                {item.mandatoryField ===
                                                                                    true &&
                                                                                (formValues[
                                                                                    item
                                                                                        .parameter
                                                                                ] ===
                                                                                    '' ||
                                                                                    formValues[
                                                                                        item
                                                                                            .parameter
                                                                                    ] ===
                                                                                        null) ? (
                                                                                    <span
                                                                                        className="text-danger  error-text"
                                                                                        style={{
                                                                                            float: 'right',
                                                                                        }}
                                                                                    >
                                                                                        {
                                                                                            errors.auditDateError
                                                                                        }
                                                                                    </span>
                                                                                ) : (
                                                                                    ''
                                                                                )}
                                                                            </>
                                                                        )}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ) : item?.type ===
                                                      'varchar' ? (
                                                        <div className="col-sm-12 p-0">
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                defaultValue={
                                                                    formValues[
                                                                        item
                                                                            .parameter
                                                                    ]
                                                                }
                                                                onKeyUp={(
                                                                    event: any
                                                                ) => {
                                                                   
                                                                    if (
                                                                        event.keyCode ===
                                                                        86
                                                                    ) {
                                                                        eventKeyCode =
                                                                            event.keyCode;
                                                                        generateDynamicLink(
                                                                            event
                                                                                .target
                                                                                .value,
                                                                            item?.parameter,
                                                                            item?.type
                                                                        );
                                                                    } else if (
                                                                        event.keyCode ===
                                                                        8
                                                                    ) {
                                                                        eventKeyCode =
                                                                            event.keyCode;
                                                                        if (
                                                                            item.mandatoryField ===
                                                                            true
                                                                        ) {
                                                                            generateDynamicLink(
                                                                                event
                                                                                    .target
                                                                                    .value,
                                                                                item?.parameter,
                                                                                item?.type
                                                                            );
                                                                        } else {
                                                                            generateDynamicLink(
                                                                                null,
                                                                                item?.parameter,
                                                                                item?.type
                                                                            );
                                                                        }
                                                                    } else {
                                                                        eventKeyCode =
                                                                            event.keyCode;
                                                                    }
                                                                }}
                                                                onBlur={(e) => {
                                                                    if (
                                                                        eventKeyCode !==
                                                                            8 &&
                                                                        eventKeyCode !==
                                                                            86
                                                                    ) {
                                                                        generateDynamicLink(
                                                                            e
                                                                                .target
                                                                                .value,
                                                                            item?.parameter,
                                                                            item?.type
                                                                        );
                                                                    }
                                                                }}
                                                                style={{
                                                                    borderBottom:
                                                                        myFormControl,
                                                                }}
                                                            />
                                                            {item.mandatoryField ===
                                                                true &&
                                                            formValues[
                                                                item.parameter
                                                            ] === '' ? (
                                                                <span
                                                                    className="text-danger error-text"
                                                                    style={{
                                                                        float: 'right',
                                                                    }}
                                                                >
                                                                    {
                                                                        errors.inputFieldError
                                                                    }
                                                                </span>
                                                            ) : (
                                                                ''
                                                            )}
                                                        </div>
                                                    ) : (
                                                        ''
                                                    )}
                                                </div>
                                            </div>
                                        )
                                    )}
                            </div>
                        ) : (
                            <div className="col-sm-12 px-5 noParamsList d-flex align-items-center">
                                <div className="col-sm-12 col-md-12 col-lg-12 d-flex justify-content-center">
                                    {previewData[0]?.name ===
                                        'PWCC Bale Report' ||
                                    previewData[0]?.name ===
                                        'PWCC Shrink Report' ||
                                    previewData[0]?.name ===
                                        'Pallets Not Invoiced' ? (
                                        <p className="preview-msg">
                                            No parameters required. Download the
                                            report in Excel or PDF
                                        </p>
                                    ) : (
                                        <p className="preview-msg">
                                            Loading Parameters...
                                        </p>
                                    )}
                                </div>
                            </div>
                        )}

                        <div className="col-sm-12 px-5 pb-4 ">
                            <div className="row mx-0 ">
                                <div className="col-sm-6 col-md-6 col-lg-5 pl-4 pr-0 ">
                                    <ButtonComponent
                                        cssClass="e-outline opt-btn-light float-left mt-3"
                                        onClick={() => clearPreview()}
                                    >
                                        Reset
                                    </ButtonComponent>
                                </div>

                                <div className="col-sm-6 col-md-6 col-lg-7 pl-0 pr-5">
                                    <button
                                        type="button"
                                        className="float-right report-dwnload-btn p-0 m-0"
                                        onClick={() => downloadFile(1)}
                                        style={{ border: 'none' }}
                                        disabled={exportEnabled}
                                    >
                                        <img src={pdfIcon} />
                                    </button>

                                    <button
                                        type="button"
                                        className="float-right report-dwnload-btn p-0 m-0 mr-3"
                                        onClick={() => downloadFile(0)}
                                        style={{ border: 'none' }}
                                        disabled={exportEnabled}
                                    >
                                        <img src={excelIcon} />
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        );
    };

    const settingsPreview = () => {
        return (
            <div>
                <div className="col-sm-12 px-0 reports-preview-details  ">
                    <div className="col-sm-12 px-3 pt-3 settingsList">
                        <div className="col-sm-12 px-0 pt-4 ">
                            <div className="col-sm-6 col-md-6 col-lg-5 reports-keys  ">
                                Name
                            </div>
                            <div className="col-sm-6 col-md-6 col-lg-7 reports-values ">
                                <p>{settingsInfo?.name}</p>
                            </div>
                        </div>
                        <div className="col-sm-12 px-0 pt-4">
                            <div className="col-sm-6 col-md-6 col-lg-5 reports-keys  ">
                                Type
                            </div>
                            <div className="col-sm-6 col-md-6 col-lg-7 reports-values ">
                                <select
                                    className="form-control border-none storeNumber"
                                    value={settingsInfo?.type}
                                    defaultValue={settingsInfo?.type}
                                    onChange={(e) =>
                                        changeSettings(e.target.value, 'type')
                                    }
                                >
                                    <option value="">Select</option>
                                    <option value="finance">Finance</option>
                                    <option value="scan">Scan</option>
                                </select>
                            </div>
                        </div>
                        <div className="col-sm-12 px-0 pt-4">
                            <div className="col-sm-6 col-md-6 col-lg-5 reports-keys  ">
                                Permissions
                            </div>
                            <div className="col-sm-6 col-md-6 col-lg-7 reports-values ">
                                <select
                                    className="form-control border-none storeNumber"
                                    defaultValue={settingsInfo?.permissions}
                                    value={settingsInfo?.permissions}
                                    onChange={(e) =>
                                        changeSettings(
                                            e.target.value,
                                            'permissions'
                                        )
                                    }
                                >
                                    <option value="">Select</option>
                                    <option value="private">Private</option>
                                    <option value="public">Public</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-12 px-4 pb-2">
                        {footerPreview(settingsInfo)}
                    </div>
                </div>
            </div>
        );
    };

    const footerPreview = (data: any) => {
        return (
            <div className="col-sm-12 px-0 pt-5 ">
                <div className="col-sm-6 col-md-6 col-lg-6 pl-0 pr-1 preview-Key  ">
                    <ButtonComponent
                        cssClass="e-outline opt-btn-light float-left"
                        onClick={() => updateReportSettings()}
                    >
                        <img src={saveIcon} alt="Save" />
                    </ButtonComponent>

                    <ButtonComponent
                        cssClass="e-outline opt-btn-light float-left ml-3"
                        onClick={() => clearSettingsPreview()}
                    >
                        <img src={closeIcon} alt="Close" />
                    </ButtonComponent>
                </div>
                <div className="col-sm-6 col-md-6 col-lg-6 preview-value pt-3 pr-0">
                    <p className="preview-status">
                        {data.status === 'Active' ||
                        data.status === 'active' ? (
                            <span>
                                <img
                                    src={activeIcon}
                                    alt="Status"
                                    onClick={() =>
                                        changeStatusForRowData(false)
                                    }
                                />
                                &nbsp; &nbsp; Active
                            </span>
                        ) : (
                            <span>
                                <img
                                    src={inactiveIcon}
                                    alt="Status"
                                    onClick={() => changeStatusForRowData(true)}
                                />
                                &nbsp; &nbsp; Inactive
                            </span>
                        )}
                    </p>
                </div>
            </div>
        );
    };

    return (
        <div>
            <div className="col-sm-12 p-0 report-preview-card ">
                {tabName && tabName === 'Saved Reports' ? (
                    <div className="col-sm-12 col-md-12 col-lg-12 px-0 ">
                        <div className="col-sm-12 col-md-12 col-lg-12 px-3 pt-3 d-flex justify-content-center">
                            <p className="reports-header-text mb-2">
                                {previewData[0]?.name}
                            </p>
                        </div>
                        <div className="col-sm-12 p-0 mb-2 d-flex justify-content-center">
                            <p className="reports-sub-header-text mb-2">
                                Report
                            </p>
                        </div>
                        <div className="col-sm-12 col-md-12 col-lg-12 px-0">
                            <TabComponent
                                heightAdjustMode="Auto"
                                cssClass="orientation-tab preview-custom-tab"
                            >
                                <TabItemsDirective>
                                    <TabItemDirective
                                        header={previewTab[0]}
                                        content={parametersPreview}
                                    />
                                    <TabItemDirective
                                        header={previewTab[1]}
                                        content={settingsPreview}
                                    />
                                </TabItemsDirective>
                            </TabComponent>
                        </div>
                    </div>
                ) : (
                    ''
                )}
            </div>
        </div>
    );
};

export default ReportPreviewTab;
