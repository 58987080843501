import * as React from 'react';
import { useEffect, useState } from 'react';
import './label-print.scss';
import { InputField } from '../../input-field/input-field';
import { ListBoxComponent } from '@syncfusion/ej2-react-dropdowns';
import { ButtonComponent } from '@syncfusion/ej2-react-buttons';
import ServiceHandler from '../../../services/service-handler';
import { LabelPrintRequest } from '../../../services/label-print-service/label-print-service';
import { LabelType, PrintManager } from '../../printer/printermanager';
import { BoxLabel } from './box-label';
import { ScanModes } from './scan-modes';
import { BinLabel } from './bin-label';
import { PalletLabel } from './pallet-label';
import { OutBoundBoxUtil } from '../OutBoundBoxUtil';
import { useSelector } from 'react-redux';
import { RootState } from '../../../redux/store';

type Props = {
    refreshPrintLabelData: boolean;
};
export const LabelPrint = (props: Props) => {
    const [scanMode, setScanMode] = useState<LabelType>('box');
    const [query, setQuery] = useState('');
    const [listBoxData, setListBoxData] = useState<string[]>([]);
    const [closedLabelsData, setClosedLabelsData] = useState<any>();
    const [closedLabelDetails, setClosedLabelDetails] = useState<any>();
    let listBoxRef: ListBoxComponent;
    const scanSettings = useSelector(
        (state: RootState) => state.scanReducer.scanSettings
    );

    const updateClosedLabelDetails = (
        mode: LabelType,
        filteredData: any,
        selectedValue: string
    ) => {
        setClosedLabelDetails({});
        filteredData?.forEach((obj: any) => {
            if (mode === 'box' && obj.outboundBoxId === selectedValue) {
                setClosedLabelDetails(obj);
            }
            if (mode === 'bin' && obj.binId === selectedValue) {
                setClosedLabelDetails(obj);
            }
            if (mode === 'pallet' && obj.palletId === selectedValue) {
                setClosedLabelDetails(obj);
            }
        });
    };
    const updateListBoxData = (
        mode: LabelType,
        filteredData: any,
        listData: string[]
    ) => {
        setClosedLabelsData(filteredData);
        if (listData && listData.length > 0) {
            setListBoxData(listData);
            listBoxRef.selectAll(false);
            listBoxRef.selectItems([listData[0]]);
            updateClosedLabelDetails(mode, filteredData, listData[0]);
        } else {
            setListBoxData([]);
        }
    };
    const getClosedLabels = (mode: LabelType, inputValue?: any) => {
        if (!scanSettings.payload.reclaimCenter) return;
        const labelRequest: LabelPrintRequest = {
            reclaimCenter: scanSettings.payload.reclaimCenter,
            query: inputValue || '',
        };
        if (mode === 'box') {
            ServiceHandler.LabelPrintService.closedOutBoundBoxes(
                labelRequest
            ).then((data) => {
                const listData: string[] = data.map((obj) => {
                    return obj.outboundBoxId;
                });
                updateListBoxData(mode, data, listData);
            });
        } else if (mode === 'bin') {
            ServiceHandler.LabelPrintService.closedBins(labelRequest).then(
                (data) => {
                    const listData: string[] = data.map((obj) => {
                        return obj.binId;
                    });
                    updateListBoxData(mode, data, listData);
                }
            );
        } else if (mode === 'pallet') {
            ServiceHandler.LabelPrintService.closedPallets(labelRequest).then(
                (data) => {
                    setClosedLabelsData(data);
                    const listData: string[] = data.map((obj) => {
                        return obj.palletId;
                    });
                    updateListBoxData(mode, data, listData);
                }
            );
        }
    };
    const updateQuery = (value: string) => {
        setQuery(value);
        getClosedLabels(scanMode, value);
    };

    const onChangeListBox = (event: any) => {
        updateClosedLabelDetails(scanMode, closedLabelsData, event.value[0]);
    };
    const printLabel = () => {
        if (scanMode === 'box') {
            PrintManager.printLabel(
                closedLabelDetails,
                'box',
                closedLabelDetails.closedBy,
                closedLabelDetails.boxNumberOnPallet + 1
            );
        }
        if (scanMode === 'bin') {
            PrintManager.printLabel(
                closedLabelDetails,
                'bin',
                closedLabelDetails.closedBy,
                1
            );
        }
        if (scanMode === 'pallet') {
            PrintManager.printLabel(
                OutBoundBoxUtil.getPalletPrintLabelRequest(
                    closedLabelDetails,
                    scanSettings.payload,
                    closedLabelDetails.closedBy
                ),
                'pallet',
                closedLabelDetails.closedBy
            );
        }
    };
    const onScanModeChange = (mode: LabelType) => {
        setScanMode(mode);
        getClosedLabels(mode, query);
    };
    useEffect(() => {
        if (props.refreshPrintLabelData) {
            getClosedLabels(scanMode);
        }
    }, []);
    return (
        <div className="col-sm-12 col-md-12 col-lg-12 label-print">
            <div className="control-pane">
                <div className="control-section tab-control-section">
                    <div className="col-sm-12 col-md-12 col-lg-12  child-data-section ">
                        <p className="page-title">Label Print</p>
                    </div>
                    <div className="col-sm-12 col-md-12 col-lg-12">
                        <div className="type-title">Type</div>
                    </div>
                    <div className="col-sm-12 col-md-12 col-lg-12">
                        <ScanModes
                            scanMode={scanMode}
                            onScanModeChange={onScanModeChange}
                        />
                    </div>
                    <div className="col-sm-3 label-search-container ">
                        <div className="label-search-field mb-5">
                            <InputField
                                id="label-id-search"
                                type="text"
                                className="form-control label-id-search"
                                initialValue={query}
                                onChange={updateQuery}
                            />
                        </div>
                        <div className="label-search-results ">
                            <ListBoxComponent
                                ref={(e) => {
                                    if (e) listBoxRef = e;
                                }}
                                className="label-list-box"
                                dataSource={listBoxData}
                                height="300px"
                                onChange={onChangeListBox}
                            />
                        </div>
                    </div>
                    <div className="col-sm-9 label-details-container">
                        {scanMode === 'box' && (
                            <BoxLabel details={closedLabelDetails} />
                        )}
                        {scanMode === 'bin' && (
                            <BinLabel details={closedLabelDetails} />
                        )}
                        {scanMode === 'pallet' && (
                            <PalletLabel
                                details={closedLabelDetails}
                                scanSettings={scanSettings.payload}
                            />
                        )}

                        <div className="print-label">
                            <ButtonComponent
                                className="e-outline opt-btn-dark reprint-label float-right "
                                onClick={printLabel}
                            >
                                <span className="mr-2">RE-PRINT</span>
                            </ButtonComponent>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
