import * as React from 'react';

export const FinanceReportBuilder = () => {
    return (
        <div className="col-sm-12 col-md-12 col-lg-12 ">
            <div className="finance-report-section control-pane">
                <div className="control-section">
                    <div className="row m-4">
                        <div className="col-lg-10 control-section">
                            <h2>Under Progress........ </h2>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
