import { createSlice } from "@reduxjs/toolkit";

export interface settingsState {
  mobileSettings: any[];
  localScanSettings: any[];
  globalScanSettings: any[];
  generalSettings: any[];
  reclaimScanMsgData: any[];
  mobileMsgData: any[];
  casualDataList: any[];
  casualDataReclaimCentersList: any[];
  casualDataVendorsList: any[];
  casualDataStoresList: any[];
  casualDataClientGroupsList: any[];
  casualDataUpcList: any[];
  languagesList: any[];
  selectedRcList: any[];
  selVendorsList: any[];
  selStoresList: any[];
  selUpcsList: any[];
  selClientGroupList: any[];
  selectedCasulDataList: any;
  selectedDefaultSuperGroup: any;
}

const initialState: settingsState = {
  mobileSettings: [],
  localScanSettings: [],
  globalScanSettings: [],
  generalSettings: [],
  reclaimScanMsgData: [],
  mobileMsgData: [],
  casualDataList: [],
  casualDataReclaimCentersList: [],
  casualDataVendorsList: [],
  casualDataStoresList: [],
  casualDataClientGroupsList: [],
  casualDataUpcList: [],
  languagesList: [],
  selectedRcList: [],
  selVendorsList: [],
  selStoresList: [],
  selUpcsList: [],
  selClientGroupList: [],
  selectedCasulDataList: {},
  selectedDefaultSuperGroup: 0
};

export const settingsSlice = createSlice({
  name: "settings",
  initialState,
  reducers: {
    setGeneralSettingsListData: (state, { payload }) => {
      state.generalSettings = payload;
    },
    setMobileSettings: (state, { payload }) => {
      state.mobileSettings = payload;
    },
    setLocalScanSettings: (state, { payload }) => {
      state.localScanSettings = payload;
    },
    setGlobalScanSettingsList: (state, { payload }) => {
      state.globalScanSettings = payload;
    },
    setReclaimScanMsgData: (state, { payload }) => {
      state.reclaimScanMsgData = payload;
    },
    setMobileMsgData: (state, { payload }) => {
      state.mobileMsgData = payload;
    },
    setCasualDataList: (state, { payload }) => {
      state.casualDataList = payload;
    },
    setcasualDataReclaimCentersList: (state, { payload }) => {
      state.casualDataReclaimCentersList = payload;
    },
    setcasualDataClientGroupsList: (state, { payload }) => {
      state.casualDataClientGroupsList = payload;
    },
    setcasualDataStoresList: (state, { payload }) => {
      state.casualDataStoresList = payload;
    },
    setcasualDataVendorsList: (state, { payload }) => {
      state.casualDataVendorsList = payload;
    },
    setcasualDataUpcList: (state, { payload }) => {
      state.casualDataUpcList = payload;
    },
    setLanguageData: (state, { payload }) => {
      state.languagesList = payload;
    },
    setselectedRcList: (state, { payload }) => {
      state.selectedRcList = payload;
    },
    setSelVendorsList: (state, { payload }) => {
      state.selVendorsList = payload;
    },
    setSelUpcsList: (state, { payload }) => {
      state.selUpcsList = payload;
    },
    setSelClientGroupsList: (state, { payload }) => {
      state.selClientGroupList = payload;
    },
    setSelStoresList: (state, { payload }) => {
      state.selStoresList = payload;
    },
    setSelectedCasualDataList: (state, { payload }) => {
      state.selectedCasulDataList = payload;
    },
    setSelectedDefaultSuperGroup: (state, { payload }) => {
      state.selectedDefaultSuperGroup = payload;
    },
  },
});

export const {
  setGeneralSettingsListData,
  setMobileSettings,
  setLocalScanSettings,
  setGlobalScanSettingsList,
  setReclaimScanMsgData,
  setMobileMsgData,
  setCasualDataList,
  setcasualDataReclaimCentersList,
  setcasualDataClientGroupsList,
  setcasualDataStoresList,
  setcasualDataVendorsList,
  setcasualDataUpcList,
  setLanguageData,
  setselectedRcList,
  setSelClientGroupsList,
  setSelStoresList,
  setSelUpcsList,
  setSelVendorsList,
  setSelectedCasualDataList,
  setSelectedDefaultSuperGroup
} = settingsSlice.actions;

export default settingsSlice.reducer;
