import {
  TabComponent,
  TabItemDirective,
  TabItemsDirective,
} from "@syncfusion/ej2-react-navigations";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "./previewaccessmgmt.scss";
import { RootState } from "../../../redux/store";
import PreviewRole from "../roles/previewrole";
import userIcon from "../../../assets/userIcon.png";
import editIcon from "../../../assets/EditRuleExcep.png";
import UserGeneralPreview from "../usermanagement/userGeneralPreview";
import UserScanPreview from "../usermanagement/userScanPreview";
import UserContactPreview from "../usermanagement/userContactPreview";
import AddFileUploadScreen from "../addScreen/fileUpload";
import { setPreviewImage } from "../accessmgmtReducer";

const previewTab = [{ text: "General", id: "0" }];
const userpreviewTab = [
  { text: "General" },
  { text: "Contact" },
  { text: "Scan" },
];
const AccessPreviewTab = () => {
  const dispatch = useDispatch();
  const [fileUploadAddScreen, setFileUploadAddScreen] = useState(false);
  const [fileUploadScreenTabName, setFileUploadScreenTabName] = useState(null);
  const [screenType, setScreenType] = useState("update");

  let selectedImage: any;

  const selRowData = useSelector(
    (state: RootState) => state.gridReducer.selectedRowData
  );

  const handlePopupActionAddUser = (type: any) => {
    setFileUploadAddScreen(true);
    setFileUploadScreenTabName(type);
  };

  useEffect(() => {
    if (selRowData && selRowData.length > 0) {
      dispatch(setPreviewImage([]));
      // console.log(selRowData)
      // if (selRowData[0].tabName === "Users" ) {

      // }
      // selectedImage = selRowData[0].image !== undefined && selRowData[0].image !== null && selRowData[0].image !== '' ? selRowData[0].image : ''
    }
  }, [selRowData]);

  const handleEditImage = () => {};

  let uploadedImageFile = useSelector(
    (state: RootState) => state.accessMgmtReducer.previewImage
  );

  return (
    <div>
      {fileUploadAddScreen && (
        <AddFileUploadScreen
          addScreen={fileUploadAddScreen}
          setFileUploadAddScreen={setFileUploadAddScreen}
          tabName={fileUploadScreenTabName}
          screenType={screenType}
        />
      )}
      {selRowData && selRowData.length > 0 ? (
        <div className="col-sm-12 p-0 access-preview-pane ">
          {selRowData[0].tabName === "Roles" ? (
            <div className="col-sm-12 col-md-12 col-lg-12 px-0">
              <div className="col-sm-12 col-md-12 col-lg-12 px-3 py-3">
                <div className="col-sm-6 col-md-5 col-lg-4 pl-0 pr-4">
                  <p className="header-logo">
                    {selRowData[0]?.roleName?.substring(0, 2)?.toUpperCase()}
                  </p>
                </div>
                <div className="col-sm6 col-md-7 col-lg-8 pt-4 ">
                  <div className="col-sm-12 p-0 main-header-text text-transform-normal">
                    {selRowData[0]?.roleName}
                  </div>
                  <div className="col-sm-12 p-0 sub-header-text">
                    <p className="mb-0">Role</p>
                  </div>
                </div>
              </div>
              <div className="col-sm-12 col-md-12 col-lg-12 px-0">
                <TabComponent
                  heightAdjustMode="Auto"
                  cssClass="orientation-tab preview-custom-tab"
                >
                  <TabItemsDirective>
                    <TabItemDirective
                      header={previewTab[0]}
                      content={PreviewRole}
                    />
                  </TabItemsDirective>
                </TabComponent>
              </div>
            </div>
          ) : selRowData[0].tabName === "Users" ? (
            <div
              className="col-sm-12 col-md-12 col-lg-12 px-0"
              style={{ height: "140px" }}
            >
              <div className="col-sm-12 col-md-12 col-lg-12 px-3 py-3">
                <div
                  className="col-sm-6 col-md-5 col-lg-4 pl-0 pr-0 UserImageHover"
                  style={{ height: "90px" }}
                >
                  {/* <input accept="image/*" type="file" onChange={imageChange} /> */}

                  {uploadedImageFile && uploadedImageFile.length > 0 ? (
                    <img
                      src={URL.createObjectURL(uploadedImageFile[0].file)}
                      className="imagePreview"
                      height="70"
                      width="70"
                      onClick={() => handlePopupActionAddUser("fileUpload")}
                    />
                  ) : selRowData[0].image !== undefined &&
                    selRowData[0].image !== null &&
                    selRowData[0].image !== "" ? (
                    <img
                      src={`data:image/png;base64,${selRowData[0].image}`}
                      alt="edit "
                      height="70"
                      width="70"
                      className="imagePreview"
                      onClick={() => handlePopupActionAddUser("fileUpload")}
                    />
                  ) : (
                    <img
                      src={userIcon}
                      className="userPreviewIcon"
                      style={{ height: "70px" }}
                      onClick={() => handlePopupActionAddUser("fileUpload")}
                    />
                  )}
                  <div className="HoverEditIcon">
                  <img
                    src={editIcon}
                    alt="edit "
                    width="15"
                    height="15"
                    className="m-3 pointer avatarUpdateImage"
                    onClick={() => handlePopupActionAddUser("fileUpload")}
                  /></div>
                </div>
                <div className="col-sm6 col-md-7 col-lg-8 pt-4 ">
                  <div className="col-sm-12 p-0 user-main-header-text text-transform-normal">
                    {selRowData[0]?.fullName}
                  </div>
                  <div className="col-sm-12 p-0 sub-header-text">
                    <p className="mb-0 mt-3"> {selRowData[0]?.roleName}</p>
                  </div>
                </div>
              </div>
              <div className="col-sm-12 col-md-12 col-lg-12 px-0">
                <TabComponent
                  heightAdjustMode="Auto"
                  cssClass="orientation-tab preview-custom-tab"
                >
                  <TabItemsDirective>
                    <TabItemDirective
                      header={userpreviewTab[0]}
                      content={UserGeneralPreview}
                    />
                    <TabItemDirective
                      header={userpreviewTab[1]}
                      content={UserContactPreview}
                    />
                    {selRowData[0]?.userType !== "Mobile" &&
                    selRowData[0]?.userType !== "MOBILE" &&
                    selRowData[0]?.userType !== "mobile" ? (
                      <TabItemDirective
                        header={userpreviewTab[2]}
                        content={UserScanPreview}
                      />
                    ) : (
                      ""
                    )}
                  </TabItemsDirective>
                </TabComponent>
              </div>
            </div>
          ) : (
            ""
          )}
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default AccessPreviewTab;
