import * as React from 'react';
import {useEffect, useState} from 'react';
import {ScanReportsListGridConfig} from './scan-reports-config';
import {ColumnDirective, ColumnsDirective, Edit, Grid, GridComponent, Inject,} from '@syncfusion/ej2-react-grids';
import ServiceHandler from '../../../services/service-handler';
import {ButtonComponent} from '@syncfusion/ej2-react-buttons';
import {downloadExcel} from './scan-reports-util';
import ExcelICONSVG from '../../../assets/ExcelSvgIcon.svg';
import deleteIcon from '../../../icons/deleteNew.svg';
import AddPlus from '../../../assets/PlusAddSvg.svg';
import notify from '../../../services/toasterService';

type Props = {
    selectedRowId?: number;
    viewReportDetails: boolean;
    setViewReportDetails(value: boolean): void;
    setSelectedRowId(value: number | undefined): void;
};
export const ScanReportsList = (props: Props) => {
    const [selectedCheckBoxId, setSelectedCheckBoxId] = useState<number | undefined>(undefined);
    const [enableDownloadButton, setEnableDownloadButton] =
        useState<boolean>(false);
    let gridRef: Grid;
    const editSettings: any = {allowDeleting: true};

    const updateGridData = (
        selectedRowId: number | undefined,
        viewDetailsFlag: boolean
    ) => {
        props.setSelectedRowId(selectedRowId);
        props.setViewReportDetails(viewDetailsFlag);
    };
    const handleSelectedRow = (event: any) => {
        if (
            event.target.classList.contains('e-gridchkbox') ||
            event.target.classList.contains('e-checkbox-wrapper') ||
            event.target.parentElement?.classList.contains('e-checkbox-wrapper')
        ) {
            event.cancel = true;
            if (event.data.reportStatus === 'COMPLETED') {
                setEnableDownloadButton(true);
            } else {
                setEnableDownloadButton(false);
            }
            setSelectedCheckBoxId(event.data.id);
        } else {
            updateGridData(event.data.id, true);
        }
    };
    const createReport = () => {
        updateGridData(undefined, true);
    };
    const deleteCriteria = () => {
        if (selectedCheckBoxId) {
            ServiceHandler.ReportsService.delete(selectedCheckBoxId)
                .then(() => {
                    notify('Deleted Report Criteria Successfully', 'success');
                    setSelectedCheckBoxId(undefined);
                    gridRef.deleteRecord();
                })
                .catch(() => {
                    notify('Failed to Delete Report Criteria', 'error');
                });
        }
    };
    const clearPreviouslySelectedRows = () => {
        if (gridRef.getSelectedRecords().length > 0) {
            gridRef.clearSelection();
        }
    };
    useEffect(() => {
        if (!props.viewReportDetails && !props.selectedRowId) {
            ServiceHandler.ReportsService.getReportsList().then((data) => {
                if (gridRef) {
                    gridRef.dataSource = data;
                }
            });
        }
    }, []);
    return (
        <>
            <div className="scan-report-section control-pane">
                <div className="control-section">
                    <div className="row mt-3 mb-3">
                        <div className="col-lg-4 control-section">
                            <p className="child-tab-title">
                                Scan Report Builder
                            </p>
                        </div>
                        <div className="col-lg-8 control-section ">
                            <ButtonComponent
                                className="e-outline opt-btn-dark create-report float-right "
                                onClick={createReport}
                            >
                                <img src={AddPlus} alt="Create Scan Report"/>{' '}
                                <span className="mr-2">Create Scan Report</span>
                            </ButtonComponent>
                            <ButtonComponent
                                disabled={!selectedCheckBoxId}
                                className="reports-button float-right "
                                onClick={deleteCriteria}
                            >
                                <img src={deleteIcon} alt="Delete"/>
                            </ButtonComponent>
                            <ButtonComponent
                                disabled={!enableDownloadButton}
                                className="reports-button float-right "
                                onClick={() =>
                                    downloadExcel(selectedCheckBoxId)
                                }
                            >
                                <img src={ExcelICONSVG} alt="File "/>
                            </ButtonComponent>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12 control-section">
                            <GridComponent
                                id="scan-reports-list-grid"
                                ref={(g) => {
                                    if (g) gridRef = g;
                                }}
                                rowHeight={48}
                                height={'434'}
                                allowResizing={true}
                                rowSelected={(event) =>
                                    handleSelectedRow(event)
                                }
                                editSettings={editSettings}
                                rowSelecting={clearPreviouslySelectedRows}
                            >
                                <ColumnsDirective>
                                    <ColumnDirective
                                        type="checkbox"
                                        width="72"
                                    />
                                    {ScanReportsListGridConfig.map(
                                        (col: any) => {
                                            return (
                                                <ColumnDirective
                                                    key={col.field}
                                                    field={col.field}
                                                    headerText={col.label}
                                                    textAlign={'Center'}
                                                    format={col.format}
                                                    type={col.type}
                                                />
                                            );
                                        }
                                    )}
                                </ColumnsDirective>
                                <Inject services={[Edit]}/>
                            </GridComponent>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
