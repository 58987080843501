import "./mobile-field.scss";
import NumberFormat from "react-number-format";
import { useEffect, useState } from "react";
import Data from "./countrydata";
import { useDispatch } from "react-redux";
import {
  setAltMobileWithCountry,
  setCountryCodeForAltMobile,
  setCountryCodeForFax,
  setCountryCodeForMobile,
  setFaxWithCountry,
  setMobileWithCountry,
} from "../contactTabs/contactReducer";

const CountryDropdown = (props: any) => {
  const { type } = props;

  const inputype = type;
  const [SelectedCode, setSelectedCode] = useState(
    `https://countryflagsapi.com/png/us`
  );
  const [SelectedTel, setSelectedTel] = useState("");
  const [inputValue, setinputvalue] = useState("");
  const dispatch = useDispatch();

  let arr = Object.values(Data.Results);

  let mydata = arr.map((element: any) => {
    return {
      Name: element.Name,
      Code: element.CountryCodes.tld,
      Tel: element.TelPref,
    };
  });
  mydata.sort(function (a, b) {
    if (a.Name < b.Name) {
      return -1;
    }
    if (a.Name > b.Name) {
      return 1;
    }
    return 0;
  });

  const handleChange = (e: any) => {
    setSelectedCode(`https://countryflagsapi.com/png/${e.target.value}`);
    let selectedObj = mydata.find((el: any) => el.Code === e.target.value);
    setSelectedTel(selectedObj?.Tel);
    if (type === "Phone") {
      dispatch(setCountryCodeForMobile("+" + selectedObj?.Tel));
    } else if (type === "Alt Phone") {
      dispatch(setCountryCodeForAltMobile("+" + selectedObj?.Tel));
    } else if (type === "Fax") {
      dispatch(setCountryCodeForFax("+" + selectedObj?.Tel));
    }
  };

  function handleFinal(event: any) {
    let check = "";
    check = event.target.value;
    setinputvalue(check);
    dispatch(setMobileWithCountry(check));
  }
  const handlePhone = (event: any, type: string) => {
    let check = "";
    check = event.target.value;
    setinputvalue(check);
    dispatch(setMobileWithCountry(null));
    dispatch(setAltMobileWithCountry(null));
    dispatch(setFaxWithCountry(null));
    if (type === "Phone") {
      dispatch(setMobileWithCountry(check));
    } else if (type === "Alt Phone") {
      dispatch(setAltMobileWithCountry(check));
    } else if (type === "Fax") {
      dispatch(setFaxWithCountry(check));
    }
  };
  useEffect(() => {
    setinputvalue("");
  }, [SelectedTel]);

  return (
    <div className="countrcodemain p-0">
      <div className="mobilefieldmain px-3 py-2">
        {SelectedCode && (
          <img src={SelectedCode} alt="img" className="countryFlagImage"></img>
        )}
        {SelectedTel && (
          <span className="countrycodetext">(+{SelectedTel})</span>
        )}{" "}
        {""}
        <select
          className="Countrydropdown"
          onChange={(event) => handleChange(event)}
        >
          {mydata.map((e: any) => (
            <option value={e.Code}>{e.Name}</option>
          ))}
        </select>
        {inputype === "Phone" ? (
          <NumberFormat
            value={inputValue}
            className="MobileInputNumber"
            format="(###) ###-####"
            allowEmptyFormatting
            mask="_"
            onChange={(e: any) => handlePhone(e, "Phone")}
          ></NumberFormat>
        ) : inputype === "Alt Phone" ? (
          <NumberFormat
            value={inputValue}
            className="MobileInputNumber"
            format="(###) ###-####"
            allowEmptyFormatting
            mask="_"
            onChange={(e: any) => handlePhone(e, "Alt Phone")}
          ></NumberFormat>
        ) : inputype === "Fax" ? (
          <NumberFormat
            value={inputValue}
            className="MobileInputNumber"
            format="(###) ###-####"
            allowEmptyFormatting
            onChange={(e: any) => handlePhone(e, "Fax")}
          ></NumberFormat>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

export default CountryDropdown;
