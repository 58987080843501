import { combineReducers } from "@reduxjs/toolkit";
//import contactReducer from "../components/contactTabs/contactReducer";
import contactReducer from "../components/contactTabs/contactReducer";
import gridReducer from "../components/grid/gridReducer";
import invoiceReducer from "../components/invoices/invoiceReducer";
import LlAdminSlice from "../components/lladmin/lladminreducer";
import scanReducer from "../components/scan/scanReducer";
import settingsSlice from "../components/settings/settingsReducers";
import ReportSlice from "../components/reports/reportreducer";
import AccessMgmtSlice from "../components/accessMgmt/accessmgmtReducer";
import SideBarSlice from "../components/sidebar/sidebarreducer";

const reducers = {
  contactReducer: contactReducer,
  gridReducer: gridReducer,
  invoiceReducer: invoiceReducer,
  scanReducer: scanReducer,
  lladminReducer: LlAdminSlice,
  settingsReducer: settingsSlice,
  reportReducer: ReportSlice,
  accessMgmtReducer: AccessMgmtSlice,
  sideBarReducer: SideBarSlice,
};

const rootReducer = combineReducers(reducers);

export default rootReducer;
