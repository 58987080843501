import {
  TabComponent,
  TabItemDirective,
  TabItemsDirective,
} from "@syncfusion/ej2-react-navigations";
import React from "react";
import "./settings.scss";
import ScanSettings from "./scansettings/scanSettings";
import GeneralSettings from "./generalsettings/generalSettings";
import CasualDataSettings from "./casualdatasettings/casualDataSettings";
import MobileDataSettings from "./mobileDataSettings/mobileSettings";
import MessageManagement from "./messageManagementSettings/messageManagement";
import { setSelectedDefaultSuperGroup } from "./settingsReducers";
import { dispatch } from "react-hot-toast/dist/core/store";
import { useDispatch } from "react-redux";

const parentTab = [
  { text: "General Settings" },
  { text: "Scan Settings" },
  { text: "Mobile Settings" },
  { text: "Causal Data" },
  { text: "Message Management" },
];

const Settings = () => {

  const dispatch = useDispatch();
  
  let selectedTab: string;

  const TabChangeEvent = async (e: any) => {
    selectedTab = e.selectingItem.innerText;
    console.log(selectedTab)

    if(selectedTab === 'Mobile Settings') {
     // dispatch(setSelectedDefaultSuperGroup(0))
    }
   // dispatch(setRowData([]));
    // console.log(e.selectingIndex, e.selectingItem.innerText);
   
    // if (selectedTab === "Client ID Management") {
    //   await LlAdminTabApi(e.selectingItem.innerText);
    // } else  if (selectedTab === "Message Management") { 
    //   await LlAdminTabApi(e.selectingItem.innerText);
    // }
  };

  return (
    <div className="col-sm-12 col-md-12 col-lg-12 parent-tab-width">
      <div className="control-pane">
        <div className="control-section tab-control-section">
          <TabComponent
            heightAdjustMode="Auto"
            cssClass="orientation-tab ll-custom-tab"
            selecting={TabChangeEvent}
          >
            <TabItemsDirective>
              <TabItemDirective
                header={parentTab[0]}
                content={generalSettings}
              />

              <TabItemDirective header={parentTab[1]} content={scanSettings} />

              <TabItemDirective
                header={parentTab[2]}
                content={mobileSettings}
              />

              <TabItemDirective
                header={parentTab[3]}
                content={casualDataSettings}
              />

              {/* <TabItemDirective header={parentTab[4]} content={messageManagement} /> */}
            </TabItemsDirective>
          </TabComponent>
        </div>
      </div>
    </div>
  );

  function generalSettings() {
    return <GeneralSettings />;
  }

  function scanSettings() {
    return <ScanSettings />;
  }

  function mobileSettings() {
    return <MobileDataSettings />;
  }
  function casualDataSettings() {
    return <CasualDataSettings />;
  }

  function messageManagement() {
    return <MessageManagement />;
  }
};

export default Settings;
