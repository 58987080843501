import {FC, useEffect, useState} from 'react';
import {
    ColumnDirective,
    ColumnsDirective,
    CommandColumn,
    DataStateChangeEventArgs,
    Edit,
    Filter,
    Grid,
    GridComponent,
    Inject,
    Page,
    Sort,
} from '@syncfusion/ej2-react-grids';
import {useDispatch} from 'react-redux';
import '../Grid.scss';
import {setSelectedRecrdsExport,} from '../../contactTabs/contactReducer';
import {customizeCell} from '../CustomCell';
import {FilterOptions, getDefaultFilterOperator, pageOptions, selectionOptions,} from '../gridConfig';
import ServiceHandler from '../../../services/service-handler';
import {filterQuery} from '../grid-events';
import {config} from '../config';
import loader from '../../../assets/loader.gif';
import moment from 'moment';
import {setSelectedIdForCancel} from "../../invoices/invoiceReducer";
import { invoiceGridConfig } from '../../invoices/config';

interface Props {
    hideSpinner?: boolean;
    onRowSelect?: (selectedRecords: any[]) => void;
}

const CreditMemoGrid: FC<Props> = (props: Props) => {
    const [loaderStatus, setLoaderStatus] = useState(true);
    const dispatch = useDispatch();
    let gridRef: Grid;
    const handleSelectedRow = (event: any) => {
        dispatch(setSelectedRecrdsExport(gridRef.getSelectedRecords()));
        if (event.target.classList.contains('e-check')) {
            event.cancel = true;
            dispatch(setSelectedIdForCancel(event));
        } else if (gridRef && undefined !== event.data.invoiceNumber) {
            props.onRowSelect && props.onRowSelect(event);
        }
    };

    const handleDeSelectedRow = (event: any) => {
        dispatch(setSelectedRecrdsExport(gridRef.getSelectedRecords()));
    };

    const onFirstGridCreated = () => {
        if (gridRef) {
            !props.hideSpinner && gridRef.showSpinner();
        }
    };

    const dataStateChange = (state: DataStateChangeEventArgs) => {
        ServiceHandler.ARInvoiceService.getFilteredCreditMemo(
            filterQuery(state)
        ).then((data) => {
            setLoaderStatus(false);
            if (gridRef) {
                const rulesArray: any = [];
                data.result.map((row: any) => {
                    rulesArray.push({
                        id: row.id,
                        CMid: row.invoiceNumber + 'CM',
                        invoiceNumber: row.invoiceNumber,
                        description: row.description,
                        customerName: row.customerName,
                        period: row.period,
                        cost: row.cost.toFixed(2),
                        price: row.price.toFixed(2),
                        createdDate: moment(row.createdDate).format(
                            'MMM DD, YYYY'
                        ),
                        reclaimCenterName: row.reclaimCenterName,
                        credit: '$' + row.credit.toFixed(2),
                        facility: row.reclaimCenterName,
                        status:
                            row.status === 'Pickup, Auto Approved'
                                ? 'Pickup Auto'
                                : row.status === 'Ready for Salvager'
                                    ? 'Ready Slvg'
                                    : row.status === 'Ready for Extract'
                                        ? 'Ready Extrct'
                                        : row.status === 'Pickup, Manual Override'
                                            ? 'Pickup Mnul'
                                            : row.status,
                    });
                });
                data.result = rulesArray;
                gridRef.dataSource = [];
                gridRef.dataSource = data;
                dispatch(setSelectedRecrdsExport(data.result));
            }
        });
    };

    useEffect(() => {
        dataStateChange({skip: 0});
        setLoaderStatus(true);
    }, []);

    return (
        <>
            <GridComponent
                id="FGrid"
                ref={(g) => {
                    if (g) gridRef = g;
                }}
                dataStateChange={dataStateChange}
                queryCellInfo={customizeCell}
                rowHeight={48}
                height={'434'}
                created={onFirstGridCreated}
                allowSorting={true}
                allowPaging={true}
                filterSettings={FilterOptions}
                allowFiltering={true}
                selectionSettings={selectionOptions}
                pageSettings={pageOptions}
                rowSelected={(event) => handleSelectedRow(event)}
                rowDeselected={(event) => handleDeSelectedRow(event)}
            >
                <ColumnsDirective>
                    <ColumnDirective type="checkbox" width="50"/>
                    {(invoiceGridConfig[1] as any).map((col: any) => {
                        return (
                            <ColumnDirective
                                filter={getDefaultFilterOperator(col.dataType)}
                                key={col.field}
                                field={col.field}
                                headerText={col.header}
                                commands={col?.commands}
                                width={col.width ? col.width : '100'}
                                textAlign={col?.textAlign}
                            />
                        );
                    })}
                </ColumnsDirective>
                <Inject services={[Page, Sort, Filter, CommandColumn, Edit]}/>
            </GridComponent>
            <div className="LoadImg">
                {loaderStatus ? (
                    <img className="loaderClass" src={loader}/>
                ) : (
                    <></>
                )}
            </div>
        </>
    );
};
export default CreditMemoGrid;
