import { ButtonComponent } from "@syncfusion/ej2-react-buttons";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import Grid from "../../grid/Grid";
import AddAccessManagementScreen from "../addScreen/addScreen";
import AccessPreviewTab from "../previewAccessMgmt/previewaccessmgmt";
import { generateGridData, roleHeaders } from "../utils";
import { ReactComponent as Add } from "../../../icons/AddNew.svg";
import loader from "../../../assets/loader.gif";

const RoleManagement = () => {
  let selectedTab = "Roles";
  const [addScreen, setAddScreen] = useState(false);
  const [addScreenTabName, setAddScreenTabName] = useState(null);

  /* Get data from Grid Reducer */
  const selectedRowData = useSelector(
    (state: RootState) => state.gridReducer.selectedRowData
  );

  /* Get roles data from Access management Reducer */
  const roleData = useSelector(
    (state: RootState) => state.accessMgmtReducer.rolesData
  );

  /* Defined Grid Hieght based on Data  */
  let gridHieght = "calc(100vh - 375px)";

  useEffect(() => {
    // console.log(roleData);
  }, []);

  const handlePopupActionLocation = (selectedTabName: any) => {
    setAddScreen(true);
    setAddScreenTabName(selectedTabName);
  };

  const loaderStatus = useSelector(
    (state: RootState) => state.accessMgmtReducer.isLoaderShow
  );

  return (
    <div>
      {addScreen && (
        <AddAccessManagementScreen
          addScreen={addScreen}
          setAddScreen={setAddScreen}
          tabName={addScreenTabName}
        />
      )}
      <div className="col-sm-12 col-md-12 col-lg-12">
        <div className="col-sm-6 pl-0 pt-4">
          <p className="access-mgmt-title">Roles</p>
        </div>
        <div className="col-sm-6 pr-0 pt-3">
          <ButtonComponent
            type="button"
            className="iconCopy float-right ml-1"
            onClick={() => handlePopupActionLocation(selectedTab)}
          >
            <Add />
          </ButtonComponent>
        </div>
      </div>
      <div className="col-sm-12 col-md-12 col-lg-12 ">
        <div className="control-pane">
          <div className="control-section tab-control-section">
            <div className="col-sm-12 col-md-12 col-lg-12 ">
              <div className="col-sm-8 col-md-12 col-lg-8 pl-0 pr-4 " style={{width: '75%'}}>
              <>
            {loaderStatus ? (
              <div className="LoadImg">
                <img className="loaderClass" src={loader} />
                </div> ) :
                 <div className="access-mgmt-text-transform">
                <Grid
                  config={roleHeaders}
                  data={generateGridData("Roles", roleData)}
                  width={"100%"}
                  height={gridHieght}
                />
                </div>
              }</>
              </div>
              {selectedRowData && selectedRowData.length > 0 ? (
                <div className="col-sm-4 col-md-12 col-lg-4 pl-4 " style={{width: '25%'}}>
                  <AccessPreviewTab />
                </div>
              ) : (
                <div className="col-sm-4 col-md-12 col-lg-4 pl-4 " style={{width: '25%'}}>
                  <div className="access-preview d-flex align-items-center">
                    <div className="col-sm-12 col-md-12 col-lg-12 d-flex justify-content-center">
                      <p className="preview-msg">Select role to view details</p>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RoleManagement;
