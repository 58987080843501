import home from "../../icons/home.svg";
import scan from "../../icons/scan.svg";
import admin from "../../icons/admin.svg";
import invoice from "../../icons/invoices.svg";
import report from "../../icons/reports.svg";
import settings from "../../icons/settings.svg";
import ll_admin from "../../icons/llAdmin.svg";
import accessmng from "../../icons/accessmgmt.svg";

export const mediaQuery = "(min-width: 600px)";
export const menuItems = [
  {
    text: "Home",
    mapper: "/home",
    iconCss: "sidemenu-icons ",
    imgPath: home,
  },
  {
    text: "Scan",
    mapper: "/scan",
    iconCss: "sidemenu-icons ",
    imgPath: scan,
  },
  {
    text: "Master Data",
    mapper: "/masterdata",
    iconCss: "sidemenu-icons",
    imgPath: admin,
  },
  {
    text: "Invoices",
    mapper: "/invoices",
    iconCss: "sidemenu-icons",
    imgPath: invoice,
  },
  {
    text: "Reports",
    mapper: "/reports",
    iconCss: "sidemenu-icons",
    imgPath: report,
  },
  {
    text: "Old Reports",
    mapper: "/oldReports",
    iconCss: "sidemenu-icons",
    imgPath: report,
  },
  {
    text: "Access Mngmt",
    mapper: "/accessManagement",
    iconCss: "sidemenu-icons",
    imgPath: accessmng,
  },
  {
    text: "Settings",
    mapper: "/settings",
    iconCss: "sidemenu-icons",
    imgPath: settings,
  },
  {
    text: "LL Admin",
    mapper: "/LLAdmin",
    iconCss: "sidemenu-icons",
    imgPath: ll_admin,
  },
];

export const AccountMenuItem = [
  {
    text: "Account",
  },
  {
    text: "SignOut",
    url: "/signout",
    // items: [
    //     { text: 'Profile' },
    //     { text: 'Sign out' },
    //     { text: 'Account'}
    // ]
  },
  {
    text: "Keyboard shortcuts",
  },
  {
    text: "Help & Support",
  },
  {
    text: "Sign Out",
  },
];
export const enableDock = true;
export const dockSize = "52px";
export const width = "120px";
export const target = ".main-content";
