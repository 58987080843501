import { PrintManager } from '../printer/printermanager';
import { OutBoundBoxUtil } from '../scan/OutBoundBoxUtil';
import { getLocalStorageItems } from '../scan/utils';

export class InventoryCatalogUtil {
    public static printPalletLabel(data: any) {
        if (data && data[0]) {
            if (!PrintManager.webSocketStatus()) {
                PrintManager.start().then(() => {
                    PrintManager.printLabel(
                        OutBoundBoxUtil.getPalletPrintLabelRequest(
                            data[0],
                            getLocalStorageItems('scanSettings'),
                            data[0].closedBy
                        ),
                        'pallet'
                    );
                });
            } else {
                PrintManager.printLabel(
                    OutBoundBoxUtil.getPalletPrintLabelRequest(
                        data[0],
                        getLocalStorageItems('scanSettings'),
                        data[0].closedBy
                    ),
                    'pallet'
                );
            }
        }
    }
}
