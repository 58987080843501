import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import userIcon from "../../assets/userIcon.png";
import axios from "axios";
import { ContactType } from "./constants";
import { addMasterDataEndPoint, getEndPoint } from "./utils";
import notify from "../../services/toasterService";
import { setCurrentContact } from "./contactReducer";
import { ButtonComponent } from "@syncfusion/ej2-react-buttons";

const StoreDispositionsAddScreen = (props: any) => {
  const { setAddScreen } = props;
  const dispatch = useDispatch();
  const [uPCDispo, setUPCDispo] = useState("");
  const [storeDispo, setStoreDispo] = useState("");
  const [voice, setVoice] = useState("");
  const [authFlag, setAuthFlag] = useState("");
  const [selectedImage, setSelectedImage] = useState();
  const dispositionInfo = useSelector(
    (state: RootState) => state.contactReducer?.dispositionData
  );
  const upcDefaultData = useSelector(
    (state: RootState) => state.contactReducer?.WHUPCDefaultData
  );
  console.log(upcDefaultData);
  const imageChange = (e: any) => {
    if (e.target.files && e.target.files.length > 0) {
      setSelectedImage(e.target.files[0]);
    }
  };
  const handleActionUPCDispo = (e: any) => {
    setUPCDispo(e.target.value);
  };
  const handleActionVoice = (e: any) => {
    setVoice(e.target.value);
  };
  const handleActionStoreDispo = (e: any) => {
    setStoreDispo(e.target.value);
  };
  const handleActionAuthFlag = (e: any) => {
    setAuthFlag(e.target.value);
  };

  const body = {
    authorizationFlag: authFlag,
    dispositionId: parseInt(uPCDispo),
    storeDisposition: storeDispo,
    voiceText: voice,
    dispositionName: "",
  };
  let tabName: any = ContactType.STORE_DISPOSITIONS;
  const handleAddStoreDispo = () => {
    const endpoint = addMasterDataEndPoint(tabName);
    const getEndpoint = getEndPoint(tabName);
    handleMasterDataChange(endpoint, getEndpoint);
  };
  const handleMasterDataChange = async (
    endpoint: string,
    getEndpoint: string
  ) => {
    const result = await axios
      .post(endpoint, body)
      .then((results: any) => {
        if (results && results.data) {
          setAddScreen(false);
          notify("Store Disposition created successfully", "success");
        } else {
          notify("Failed to create Store Disposition", "error");
        }
      })
      .catch((error: any) => {
        if (error.response) {
          notify(error.response?.data?.message, "error");
        }
      });

    const getresult = await axios.get(getEndpoint);
    dispatch(setCurrentContact(getresult.data));
  };

  const handleCloseStoreDispoPopup = () => {
    setAddScreen(false);
  };

  return (
    <div>
      <div className="row">
      <div className="col-sm-12 textAlign-center">
          <img src={userIcon} className="userAddIcon" />
          {selectedImage && (
            <div className="imagePreviewDiv">
              <img
                src={URL.createObjectURL(selectedImage)}
                className="imagePreview"
                height="100"
                width="100"
              />
            </div>
          )}
        </div>
      </div>
      <div className="col-sm-12 row m-0 mt-2">
        <div className="col-sm-6">
        <div className="col-sm-12">
          <label>UPC Disposition</label>
          <select
            className="form-control"
            onChange={(e) => handleActionUPCDispo(e)}
          >
            <option value="0">Select</option>
            {upcDefaultData?.map((dp) => (
              <option value={dp.id}>{dp.disposition}</option>
            ))}
          </select>
        </div>
        <div className="col-sm-12 mt-1">
          <label>Voice</label>
          <input
            type="text"
            className="form-control"
            onChange={(e) => handleActionVoice(e)}
          />
        </div>
        </div>
      
      
        <div className="col-sm-6">
        <div className="col-sm-12 mt-3">
          <label>Authorization Flag</label>
          <div onChange={(e: any) => handleActionAuthFlag(e)}>
            <div className="col-sm-3 pl-0">
              <input type="radio" name="authFlag" value="Y" /> <span> Yes</span>
            </div>
            <div className="col-sm-3 pl-0">
              <input type="radio" name="authFlag" value="N" /> <span> No</span>
            </div>
          </div>
        </div>        
        <div className="col-sm-12 mt-1">
          <label>Store Disposition</label>
          <input
            type="text"
            className="form-control"
            onChange={(e) => handleActionStoreDispo(e)}
          />
        </div>
        </div>      
      </div>

      <div className="col-sm-12 px-0 mt-5">
        <ButtonComponent
          cssClass="eg-btn-primary float-right"
          onClick={() => handleAddStoreDispo()}
        >
          ADD STORE DISPOSITION
        </ButtonComponent>
        <ButtonComponent
          cssClass="e-control e-btn e-lib eg-btn-primary-link e-flat mt-3"
          onClick={() => handleCloseStoreDispoPopup()}
        >
          CANCEL
        </ButtonComponent>
      </div>
    </div>
  );
};
export default StoreDispositionsAddScreen;
