import * as React from 'react';
import moment from 'moment';
import { Pallet } from '../../../services/label-print-service/label-print-service';

type Props = {
    details: Pallet;
    scanSettings: any;
};
export const PalletLabel = (props: Props) => {
    const details = props.details;
    return (
        <div className="label-details-view">
            <div className="label-row">
                <div className="label-name">Pallet ID</div>
                <div className="label-value">{details?.palletId}</div>
            </div>
            <div className="label-row">
                <div className="label-name">Closed Date</div>
                <div className="label-value">
                    {details?.closedOn && moment(details.closedOn).format('L')}
                </div>
            </div>
            <div className="label-row">
                <div className="label-name">Opened Date</div>
                <div className="label-value">
                    {details?.closedOn &&
                        moment(details.createdDate).format('L')}
                </div>
            </div>
            {details?.isBoxPallet && (
                <div className="label-row">
                    <div className="label-name">Box Count</div>
                    <div className="label-value">{details?.closedBoxes}</div>
                </div>
            )}
            {!details?.isBoxPallet && (
                <div className="label-row">
                    <div className="label-name">Bin Count</div>
                    <div className="label-value">{details?.bins}</div>
                </div>
            )}

            <div className="label-row">
                <div className="label-name">Item Count</div>
                <div className="label-value">{details?.items}</div>
            </div>
            <div className="label-row">
                <div className="label-name">Location</div>
                <div className="label-value"> {details?.palletLocation}</div>
            </div>
            <div className="label-row">
                <div className="label-name">WH Location</div>
                <div className="label-value"> {details?.warehouseLocation}</div>
            </div>
            <div className="label-row">
                <div className="label-name">Group Code</div>
                <div className="label-value">
                    {' '}
                    {props.scanSettings.scangroup}
                </div>
            </div>
            <div className="label-row">
                <div className="label-name">Facility</div>
                <div className="label-value">
                    {' '}
                    {props.scanSettings.reclaimCenter}
                </div>
            </div>
            <div className="label-row">
                <div className="label-name">Closed By</div>
                <div className="label-value"> {details?.closedBy}</div>
            </div>
        </div>
    );
};
