import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { accessReqOpenHeaders, addAccessReqEndPoint, generateGridData, getAccessMgmtEndPoint } from "../../utils";
import { RootState } from "../../../../redux/store";
import Grid from "../../../grid/Grid";
import { ButtonComponent } from "@syncfusion/ej2-react-buttons";
import AddAccessMgmtScreen from "../../addReqScreens/addReqScreen";
import notify from "../../../../services/toasterService";
import axios from "axios";
import NoCheckBoXGrid from '../../../grid/fiscal-year-setup-grid/fiscal-year-setup-grid';
import { setRowData } from "../../../grid/gridReducer";
import { setOpenAccessReqData } from "../../accessmgmtReducer";
const OpenRequest = () => {
  const dispatch = useDispatch();
  const [addScreen, setAddScreen] = useState(false);
  const [addScreenTabName, setAddScreenTabName] = useState("Open");
  /* Get data from Grid Reducer */
  const selectedRowData = useSelector(
    (state: RootState) => state.gridReducer.selectedRowData
  );
  /* Get Open Requests data from Access management Reducer */
  const openReqData = useSelector(
    (state: RootState) => state.accessMgmtReducer.openAccessReqData
  );

  /* Defined Grid Hieght based on Data  */
  let gridHieght = "calc(100vh - 475px)";

  /* Excute code on page load */
  useEffect(() => {
    // Write some code
  });

   const approvalDialog = () => {
    if (selectedRowData && selectedRowData?.length > 0) {
      setAddScreen(true);
    } else {
      notify("Select atleast one record", "warning");
    }
  };

  const rejectDialog = () => {
    if (selectedRowData && selectedRowData?.length > 0) {
      const addEndPoint = addAccessReqEndPoint("denyRequest");
      denyReqWS(addEndPoint);
    } else {
      notify("Select atleast one record", "warning");
    }
  };

  const denyReqWS = async (addEndPoint: string) => {
  const body = {
    roleId: selectedRowData && selectedRowData[0].roleId,
    accountNumber: selectedRowData && selectedRowData[0].accountNumber,
    storeEmail: selectedRowData && selectedRowData[0].storeEmail,
    phoneNumber:selectedRowData && selectedRowData[0].phoneNumber,
    id:selectedRowData && selectedRowData[0].id,
    storeName:selectedRowData && selectedRowData[0].storeName,
  };
    const result = await axios.post(addEndPoint, body);
    if (result && result.data) {
      setAddScreen(false);
      notify("Request denied successfully", "success");
      dispatch(setRowData([]));
      loadOpenRequests();
    } else {
      notify("Failed to deny request", "error");
    }
  };

  const loadOpenRequests = async () =>{
    const endPointURL = getAccessMgmtEndPoint('Open');
    await axios
        .get(endPointURL, {})
        .then((res: { data: any }) => {
          console.log(res.data);
             dispatch(setOpenAccessReqData(res.data));
        })
        .catch((err: any) => {
          console.log(err.response);
          notify(err.response.data.error, "error");
        });
  }


  const getDeSelectedRecord = (e: any) => {
    dispatch(setRowData([]));
  }


  return (
    <div className="col-sm-12 col-md-12 col-lg-12 pt-2">
      {addScreen && (
        <AddAccessMgmtScreen
          addScreen={addScreen}
          setAddScreen={setAddScreen}
          tabName={addScreenTabName}
        />
      )}

      <div className="col-sm-12 col-md-12 col-lg-12 px-0">
        <ButtonComponent
          type="button"
          cssClass="e-outline opt-btn-dark highlight-btn float-right ml-3"
          onClick={approvalDialog}
        >
          APPROVE SELECTED
        </ButtonComponent>

        <ButtonComponent cssClass="e-outline opt-btn-light float-right ml-3 highlight-btn"
        onClick={rejectDialog}
        >
          REJECT SELECTED
        </ButtonComponent>
      </div>

      <div className="col-sm-12 col-md-12 col-lg-12 px-0 pt-2">
      <div className="access-mgmt">
        {/* <Grid
          config={accessReqOpenHeaders}
          data={generateGridData("Open", openReqData)}
          width={"100%"}
          height={gridHieght}
          hideSpinner={true}
        /> */}
         <NoCheckBoXGrid
        config={accessReqOpenHeaders}
        data={generateGridData("Open", openReqData)}
        width={"100%"}
        height={gridHieght}
        hideSpinner={true}
        />
        </div>
      </div>
    </div>
  );
};

export default OpenRequest;
