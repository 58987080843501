export class ZebraZPLLabel {
    public static getBoxLabel(obj?: any): string {
        let labelContent = '^XA';
        labelContent += '^FX Top section with Box Id.';
        labelContent += '^CFA,20';
        labelContent += '^FO30,30^FDUSER :^FS';
        labelContent += `^FO200,30^FD${obj.userId}^FS`;
        labelContent += `^FO30,80^FD${obj.scanMode} # :^FS`;
        labelContent += `^FO200,80^FD${obj.closedBoxes}^FS`;
        labelContent += '^FO30,130^FDQTY :^FS';
        labelContent += `^FO200,130^FD${obj.currentQuantity}^FS`;
        labelContent += '^CF0,40';
        labelContent += `^FO30,520^FD${obj.warehouseLocation}^FS`;
        labelContent += '^CFA,16';
        labelContent += '^FO30,180^FDLOCATION :^FS';
        labelContent += `^FO200,180^FD${obj.palletLocation}^FS`;
        labelContent += '^FO30,230^FDDATE :^FS';
        labelContent += `^FO200,230^FD${obj.date}^FS`;
        labelContent += '^CF0,40';
        labelContent += `^FO30,280^FD${obj.boxId}^FS`;
        labelContent += '^FX  section with bar code.';
        labelContent += '^BY2,3';
        labelContent += `^FO25,350^BCN,120,Y,N,N,A^FD${obj.boxId}`;
        labelContent += '^XZ';
        console.log('labelContent' + labelContent);
        return labelContent;
    }

    public static getPalletLabel(obj?: any): string {
        let labelContent = '^XA';
        labelContent += '^FX Top section with Pallet Id.';
        labelContent += '^CF0,60';
        labelContent += `^FO200,20^FD${obj.palletId}^FS`;
        labelContent += '^FX Second section with Details';
        labelContent += '^CFA,30';
        labelContent += '^FO30,100^FDCLOSED DATE :^FS';
        labelContent += `^FO280,100^FD${obj.closedOn}^FS`;
        labelContent += '^FO30,160^FDOPENED DATE :^FS';
        labelContent += `^FO280,160^FD${obj.openedDate}^FS`;
        labelContent += `^FO30,220^FD${obj.scanMode} COUNT :^FS`;
        labelContent += `^FO280,220^FD${obj.boxCount}^FS`;
        labelContent += '^FO30,280^FDITEM COUNT :^FS';
        labelContent += `^FO280,280^FD${obj.itemCount}^FS`;
        labelContent += '^CF0,60';
        labelContent += `^FO300,340^FD${obj.warehouseLocation}^FS`;
        labelContent += '^CFA,30';
        labelContent += '^FX Third section with Item Details.';
        labelContent += '^FO30,430^FDGROUP CODE :^FS';
        labelContent += `^FO280,430^FD${obj.groupCode}^FS`;
        labelContent += '^FO30,490^FDLOCATION :^FS';
        labelContent += `^FO280,490^FD${obj.palletLocation}^FS`;
        labelContent += '^FO30,550^FDFACILITY :^FS';
        labelContent += `^FO280,550^FD${obj.reclaimCenter}^FS`;
        labelContent += '^FO30,610^FDCLOSED BY :^FS';
        labelContent += `^FO280,610^FD${obj.closedBy}^FS`;
        labelContent += '^FX Third section with bar code.';
        labelContent += '^BY4,3,120';
        labelContent += `^FO30,670^BC^FD${obj.palletId}`;
        labelContent += '^XZ';
        console.log('labelContent' + labelContent);
        return labelContent;
    }
}
