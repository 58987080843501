import {ButtonComponent} from "@syncfusion/ej2-react-buttons";
import {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import userIcon from "../../../assets/userIcon.png";
import editIcon from "../../../assets/EditRuleExcep.png";
import toggleIconOn from "../../../icons/ToggleIconOn.png";

import {
  addAccessReqEndPoint,
  getAccessMgmtEndPoint,
  getAdListEndPoint,
  getScanDetailsById,
  getStatesByCountryCode,
  getStoreWsUpdate,
} from "../utils";
import axios from "axios";
import notify from "../../../services/toasterService";
import AddFileUploadScreen from "../addScreen/fileUpload";
import {setUploadedImage, setUserAccessData} from "../accessmgmtReducer";
import {RootState} from "../../../redux/store";
import toggleIconOff from "../../../icons/ToggleIconOff.png";
import {DropDownListComponent,} from "@syncfusion/ej2-react-dropdowns";
import CountryDropdown from "../../ContactsDetailsPanel/mobile-field-component";

const AddUser = (props: any) => {
    const {setAddScreen, setTabName} = props;

    let selectedImage: any;

    const [value, setvalue] = useState("");
    const [items, setItems] = useState([]);

    const [screenType, setScreenType] = useState("add");

    const [addScreen, setFileUploadAddScreen] = useState(false);
    const [addScreenTabName, setAddScreenTabName] = useState(null);

    const dispatch = useDispatch();
    const [userType, setUserType] = useState("");

    let [stepperindexvalue, setStepperIndexValue] = useState(1);

    const [displayScreen, setDisplayScreen] = useState("userSelection");

    const [activeDirectory, setActiveDirectory] = useState("");
    const [statesList, setStateList] = useState<any>([]);

    /* user fileds */
    const [userPassword, setUserPassword] = useState("");
    const [userFirstName, setUserFirstName] = useState("");
    const [userLastName, setUserLastName] = useState("");
    const [username, setUsername] = useState("");
    const [userEmail, setUserEmail] = useState("");
    const [userAddress, setUserAddress] = useState("");
    const [userRole, setUserRole] = useState(0);
    const [userState, setUserState] = useState(0);
    const [userCity, setUserCity] = useState("");
    const [userStreet, setUserStreet] = useState("");
    const [userZip, setUserZip] = useState("");
    const [userCountry, setUserCountry] = useState(0);
    const [userStore, setUserStore] = useState("");

    const [userLocation, setUserLocation] = useState(0);
    const [company, setCompany] = useState("");
    const [jobTitle, setJobTitle] = useState("");
    const [endDate, setEndDate] = useState("");
    const [userPhone, setUserPhone] = useState("");
    const [userFax, setUserFax] = useState("");
    const [userLanguage, setUserLanguage] = useState<any>();
    const [timeZone, setTimeZone] = useState<any>();

    const [myEmail, setMyEmail] = useState("");
    const [myPassword, setMyPassword] = useState("");
    const [myFirstName, setMyFirstName] = useState("");
    const [myLastName, setMyLastName] = useState("");
    const [myUserName, setMyUserName] = useState("");
    const [myRole, setMyRole] = useState("");

    const [myMobileFirstname, setMyMobileFirstname] = useState("");
    const [myMobileLastname, setMyMobileLastname] = useState("");
    const [myMobileUsername, setMyMobileUsername] = useState("");
    const [myMobileRole, setMyMobileRole] = useState("");
    const [myMobilePassword, setMyMobilePassword] = useState("");
    const [myMobileEmail, setMyMobileEmail] = useState("");

    const [hourlyScanGoal, setHourlyScanGoal] = useState("");
    const [binMaxScanQty, setBinMaxScanQty] = useState("");
    const [boxMaxScanQty, setBoxScanQty] = useState("");
    const [binClose, setBinClose] = useState(false);

    const [userDisabeled, setUserDisabeled] = useState(true);
    const [mobileUserDisabeled, setMobileUserDisabeled] = useState(true);
    const [internalUserDisabeled, setInternalUserBtnDisabeled] = useState(true);
    const [externalUserDisabeled, setExternalUserDisabeled] = useState(true);

    const [noAdUserMessage, setNoADUserMsg] = useState("");

    const [mobileUserSelectFieldsObj, setMobileUserSelectFieldsObj] = useState<any[]>([
        {
            roleId: "",
            language: "en-US",
            timeZone: "Eastern Standard Time",
            stateId: "",
        },
    ]);
    const [extUserSelectFieldsObj, setextUserSelectFieldsObj] = useState<any[]>([
        {
            roleId: "",
            language: "en-US",
            timeZone: "Eastern Standard Time",
            stateId: "",
        },
    ]);

    const [internalUserSelectFieldsObj, setInternalUserSelectFieldsObj] =
        useState<any[]>([
            {
                roleId: "",
                language: "en-US",
                timeZone: "Eastern Standard Time",
            },
        ]);

    const [internalUserInfo, setInternalUserInfo] = useState<any[]>([
        {
            roleId: 0,
            language: "en-US",
            timezone: "Eastern Standard Time",
        },
    ]);

    const [internalUserScanFields, setInternalUserScanFields] = useState<any[]>([
        {
            binMaxScanQty: "",
            boxMaxScanQty: "",
            scanMaxQty: "",
            hourlyScanGoal: "",
            canCloseBin: false,
            supervisorPassword: "",
        },
    ]);

    const [mobileUserInfo, setMobileUserInfo] = useState<any[]>([
        {
            firstname: "",
            lastname: "",
            username: "",
            roleId: 0,
            email: "",
            company: "",
            endDate: "",
            phoneNumber: "",
            language: "en-US",
            jobTitle: "",
            password: "",
            zip: "",
            timezone: "Eastern Standard Time",
            storeId: "",
        },
    ]);

    const [externalUserFields, setExternalUserFields] = useState<any[]>([
        {
            firstname: "",
            lastname: "",
            username: "",
            roleId: 0,
            email: "",
            company: "",
            endDate: "",
            phoneNumber: "",
            language: "en-US",
            jobTitle: "",
            password: "",
            zip: "",
            timezone: "Eastern Standard Time",
        },
    ]);

    const [externalUserScanFields, setExternalUserScanFields] = useState<any[]>([
        {
            binMaxScanQty: "",
            boxMaxScanQty: "",
            hourlyScanGoal: "",
            scanMaxQty: "",
            canCloseBin: false,
            supervisorPassword: '',
        },
    ]);

    const [mobileUserErrors, setMobileUserError] = useState({
        locationError: "",
        languageError: "",
        timeZoneError: "",
        userFirstNameError: "",
        userLastNameError: "",
        usernameError: "",
        userEmailError: "",
        mobileRoleError: "",
        userCountryError: "",
        companyError: "",
        jobTitleError: "",
        endDateError: "",
        phoneError: "",
        phoneMaxLenError: "",
        zipLenError: "",
        passwordError: "",
        storeError: "",
    });

    const [internalUserError, setInternalUserErros] = useState({
        userLocationError: "",
        userRoleError: "",
        userLanguageError: "",
        userTimeZoneError: "",
        userPasswordError: "",
        userBoxScanQtyError: "",
        userBinScanQtyError: "",
        userHourlyScanQtyError: "",
    });

    const [externalUserError, setExternalUserErrors] = useState({
        userFirstNameError: "",
        userLastNameError: "",
        usernameError: "",
        userEmailError: "",
        userRoleError: "",
        userCountryError: "",

        companyError: "",
        jobTitleError: "",
        endDateError: "",
        userLocationError: "",
        userLanguageError: "",
        userTimeZoneError: "",
        phoneError: "",
        phoneMaxLenError: "",

        passwordError: "",
        zipLenError: "",

        userBoxScanQtyError: "",
        userBinScanQtyError: "",
        userHourlyScanQtyError: "",
    });

    /* Get data from Grid Reducer */
    const languagesDataList = useSelector(
        (state: RootState) => state.accessMgmtReducer.languagesData
    );
    const timeZonesDataList = useSelector(
        (state: RootState) => state.accessMgmtReducer.timeZonesData
    );
    const locationsDataList = useSelector(
        (state: RootState) => state.accessMgmtReducer.locatonsList
    );
    const rolesListData = useSelector(
        (state: RootState) => state.accessMgmtReducer.rolesList
    );
    const countryCodesDataList = useSelector(
        (state: RootState) => state.accessMgmtReducer.countryCodesList
    );
    let uploadedImageFile = useSelector(
        (state: RootState) => state.accessMgmtReducer.uploadedImage
    );
    // let internaluserInfo = useSelector(
    //     (state: RootState) => state.accessMgmtReducer.activeDirectoryData
    // );

    // let activeDirectoryUsersList = useSelector(
    //     (state: RootState) => state.accessMgmtReducer.activeDirectoryUsersList
    // );

    const [activeDirectoryUsersList, setActiveDirectoryUsersList] = useState<any[]>([]);

    const base64Image = useSelector(
        (state: RootState) => state.accessMgmtReducer.base64Image
    );

    const mobileWithFlag = useSelector(
        (state: RootState) => state.contactReducer?.selectedMobileWithCountryFlag
    );
    const altMobileWithFlag = useSelector(
        (state: RootState) => state.contactReducer?.selectedAltMobileWithCountryFlag
    );
    const faxWithFlag = useSelector(
        (state: RootState) => state.contactReducer?.selectedFaxWithCountryFlag
    );
    const phoneCountryCode = useSelector(
        (state: RootState) => state.contactReducer?.selectedCountryCodeForMobile
    );
    const AltPhoneCountryCode = useSelector(
        (state: RootState) => state.contactReducer?.selectedCountryCodeForAltMobile
    );
    const faxCountryCode = useSelector(
        (state: RootState) => state.contactReducer?.selectedCountryCodeForFax
    );

    const handleAddLocationEnabled = (e: any) => {
        setNoADUserMsg("");
        var internalUser = document.getElementById(
            "internaluserEnabeled"
        ) as HTMLInputElement;
        var externalUser = document.getElementById(
            "externalUserEnabeled"
        ) as HTMLInputElement;
        var mobile = document.getElementById(
            "mobileUserEnabeled"
        ) as HTMLInputElement;
        setUserType(e.target.value);
        //validate();
    };

    let dropDownListObject: any;
    const [storesList, setStoresList] = useState([]);
    const [store, setStore] = useState("");

    const getStoreNamewithMainFrameCustomer = (e: any) => {
        getStoreList(e.text);
    };

    const handleStoreNamewithMainFrame = (e: any) => {
        handleStore(e.itemData);
    };

    const autoCompleteFields = {
        value: "id",
        text: "storeName",
    };

    const getStoreList = (storeName?: any) => {
        if (storeName.length > 2) {
            const endpoint: any = getStoreWsUpdate(storeName);
            const result = axios.get(endpoint);
            result
                .then((data: any) => {
                    setStoresList(data.data.content);
                })
                .catch((error: any) => {
                    notify(error.message, "error");
                });
        } else {
        }
    };

    const handleStore = (e: any) => {
        setStore(e.id);
    };

    useEffect(() => {
        setDisplayScreen("userSelection");
        //  axios.get(`https://jsonplaceholder.typicode.com/users`).then((res: any) => {
        //   console.log(res.data);
        //setItems(res.data);
        // });
    }, []);

    let [internalAdUserInfo, setAdInternalUserInfo] = useState<any[]>([]);

    const handlePopUpClose = () => {
        setAddScreen(false);
        setDisplayScreen("userSelection");
        setStepperIndexValue(1)
    };

    const handleNext = () => {
        setAdInternalUserInfo([]);
        setDisplayScreen("internal");
    };

    const goToNextStep = (index: number) => {
        if (index === 1) {
            setStepperIndexValue(1);
        } else if (index === 2) {
            setStepperIndexValue(2);
        } else if (index === 3) {
            setStepperIndexValue(3);
        }
    };

    const handleActiveDirectoryLookup = (e: any) => {

        if (e.item !== undefined && e.item !== null) {
            setAdInternalUserInfo([]);
            if (e.itemData !== null && e.itemData !== undefined) {
                let items = [e.itemData];
                setAdInternalUserInfo(items);
            } else {
                setAdInternalUserInfo([]);
            }
        } else {
        }

    };

    const handleInternalUserSelectFields = (event: any, key: any) => {
        const dataa = JSON.parse(event.target.value);
        console.log(dataa)
        if (key === "roleId") {
            setInternalUserSelectFieldsObj([
                {...internalUserSelectFieldsObj[0], [key]: dataa},
            ]);
            setInternalUserInfo([{...internalUserInfo[0], [key]: dataa.id}]);
            setInternalUserBtnDisabeled(false);
            console.log(internalUserSelectFieldsObj[0].roleId.roleName)
        }
        if (key === "reclaimCenterId") {
            setInternalUserSelectFieldsObj([
                {...internalUserSelectFieldsObj[0], [key]: dataa},
            ]);
            setInternalUserInfo([{...internalUserInfo[0], [key]: dataa.id}]);
            getReclimCenterDataBasedOnId(dataa.id, "internal");
        }
        if (key === "language") {
            setInternalUserSelectFieldsObj([
                {...internalUserSelectFieldsObj[0], [key]: dataa},
            ]);
            setInternalUserInfo([
                {...internalUserInfo[0], [key]: dataa.isoLongCode},
            ]);
        }
        if (key === "timezone") {
            setInternalUserSelectFieldsObj([
                {...internalUserSelectFieldsObj[0], [key]: dataa},
            ]);
            setInternalUserInfo([
                {...internalUserInfo[0], [key]: dataa.timezoneName},
            ]);
        }

        // validateInternalUsers();
    };

    const handleInternalUserScanFields = (event: any, key: any) => {
        setInternalUserScanFields([
            {...internalUserScanFields[0], [key]: event.target.value},
        ]);
        validateInternalUsers();
    };

    const handleMobileUserTextFields = (event: any, key: any) => {
        if (key === "storeId") {
            console.log(event.value)
            setMobileUserInfo([{...mobileUserInfo[0], [key]: event.value}]);
            //  setUserStore(event.value);
            validateMobileUsers();
        } else if (key === "zip") {
            if (event.target.value.length <= 5) {
                setMobileUserInfo([
                    {...mobileUserInfo[0], [key]: event.target.value},
                ]);
                validateMobileUsers();
            }
        } else {
            setMobileUserInfo([{...mobileUserInfo[0], [key]: event.target.value}]);
            validateMobileUsers();
        }
    };

    const handleMobileUserSelectFields = (event: any, key: any) => {
        const dataa = JSON.parse(event.target.value);
        if (key === "countryId") {
            const dataa = JSON.parse(event.target.value);
            getStatesList(dataa.isoCountryCode);
            setMobileUserSelectFieldsObj([
                {...mobileUserSelectFieldsObj[0], [key]: dataa},
            ]);
            setMobileUserInfo([{...mobileUserInfo[0], [key]: dataa.id}]);
        }
        // setMobileUserSelectFields([{...mobileUserSelectFields[0], [key]: dataa}])

        if (key === "roleId") {
            // setMobileUserSelectFieldsObj([
            //   { ...mobileUserSelectFieldsObj[0], [key]: dataa },
            // ]);
            setMobileUserInfo([{...mobileUserInfo[0], [key]: dataa}]);
        }
        if (key === "reclaimCenterId") {
            setMobileUserSelectFieldsObj([
                {...mobileUserSelectFieldsObj[0], [key]: dataa},
            ]);
            setMobileUserInfo([{...mobileUserInfo[0], [key]: dataa.id}]);
        }
        if (key === "stateId") {
            setMobileUserSelectFieldsObj([
                {...mobileUserSelectFieldsObj[0], [key]: dataa},
            ]);
            setMobileUserInfo([{...mobileUserInfo[0], [key]: dataa.id}]);
        }
        if (key === "language") {
            setMobileUserSelectFieldsObj([
                {...mobileUserSelectFieldsObj[0], [key]: dataa},
            ]);
            setMobileUserInfo([{...mobileUserInfo[0], [key]: dataa.isoLongCode}]);
        }
        if (key === "timezone") {
            setMobileUserSelectFieldsObj([
                {...mobileUserSelectFieldsObj[0], [key]: dataa},
            ]);
            setMobileUserInfo([{...mobileUserInfo[0], [key]: dataa.timezoneName}]);
        }

        validateMobileUsers();
    };

    const handleExternalUserTextFields = (event: any, key: any) => {
        if (key === "zip") {
            if (event.target.value.length <= 5) {
                setExternalUserFields([
                    {...externalUserFields[0], [key]: event.target.value},
                ]);
            }
        } else {
            setExternalUserFields([
                {...externalUserFields[0], [key]: event.target.value},
            ]);
        }
        validateExternalUser();
    };

    const handleExternalUserSelectFields = (event: any, key: any) => {
        const dataa = JSON.parse(event.target.value);
        if (key === "countryId") {
            const dataa = JSON.parse(event.target.value);
            getStatesList(dataa.isoCountryCode);
            getDefaultSelectionsList(dataa.isoCountryCode, "Default Language");
            getDefaultSelectionsList(dataa.isoCountryCode, "Default TimeZone");
            setextUserSelectFieldsObj([
                {...extUserSelectFieldsObj[0], [key]: dataa},
            ]);
            setExternalUserFields([{...externalUserFields[0], [key]: dataa.id}]);
        }
        // setMobileUserSelectFields([{...mobileUserSelectFields[0], [key]: dataa}])
        if (key === "roleId") {
            // setextUserSelectFieldsObj([
            //   { ...extUserSelectFieldsObj[0], [key]: dataa },
            // ]);
            setExternalUserFields([{...externalUserFields[0], [key]: dataa}]);
        }
        if (key === "reclaimCenterId") {
            setextUserSelectFieldsObj([
                {...extUserSelectFieldsObj[0], [key]: dataa},
            ]);
            setExternalUserFields([{...externalUserFields[0], [key]: dataa.id}]);
            getReclimCenterDataBasedOnId(dataa.id, "external");
        }
        if (key === "stateId") {
            setextUserSelectFieldsObj([
                {...extUserSelectFieldsObj[0], [key]: dataa},
            ]);
            setExternalUserFields([{...externalUserFields[0], [key]: dataa.id}]);
        }
        if (key === "language") {
            setextUserSelectFieldsObj([
                {...extUserSelectFieldsObj[0], [key]: dataa},
            ]);
            setExternalUserFields([
                {...externalUserFields[0], [key]: dataa.isoLongCode},
            ]);
        }
        if (key === "timezone") {
            setextUserSelectFieldsObj([
                {...extUserSelectFieldsObj[0], [key]: dataa},
            ]);
            setExternalUserFields([
                {...externalUserFields[0], [key]: dataa.timezoneName},
            ]);
        }
        validateExternalUser();
    };

    const handleExternalUserScanFields = (event: any, key: any) => {
        setExternalUserScanFields([
            {...externalUserScanFields[0], [key]: event.target.value},
        ]);
    };

    const handleCloseBin = (e: any) => {
        setBinClose(e);
    };

    const handleInternalUsers = () => {

        internalUserInfo[0]["userType"] = "Internal";
        internalUserInfo[0]["firstname"] = internalAdUserInfo[0]?.firstName;
        internalUserInfo[0]["lastname"] = internalAdUserInfo[0]?.lastName;
        internalUserInfo[0]["username"] = internalAdUserInfo[0]?.username;
        internalUserInfo[0]["email"] = internalAdUserInfo[0]?.email;
        internalUserInfo[0]["userId"] = internalAdUserInfo[0]?.id;
        internalUserInfo[0]["image"] =
            uploadedImageFile.length > 0 &&
            uploadedImageFile !== [] &&
            uploadedImageFile !== undefined
                ? uploadedImageFile[0]?.base64
                : "";
        internalUserInfo[0]["scanUser"] =
            internalUserScanFields !== undefined && internalUserScanFields.length > 0
                ? internalUserScanFields[0]
                : {};
        internalUserInfo[0]["scanUser"]["canCloseBin"] = binClose;
        internalUserInfo[0]["federationLink"] =
            internalAdUserInfo[0]?.federationLink;
        const addEndPoint = addAccessReqEndPoint("Users");
        const getEndPoint = getAccessMgmtEndPoint("Users");
        adduserWs(addEndPoint, getEndPoint, internalUserInfo[0]);
    };

    const handleAddMobileUser = () => {
        mobileUserInfo[0]["userType"] = "Mobile";
        mobileUserInfo[0]["scanUser"] = {};
        mobileUserInfo[0]["phoneNumber"] = mobileWithFlag
            ?.toString()
            .replace(/[^0-9]/g, "");
        mobileUserInfo[0]["fax"] = faxWithFlag?.toString().replace(/[^0-9]/g, "");
        mobileUserInfo[0]["image"] =
            uploadedImageFile.length > 0 &&
            uploadedImageFile !== [] &&
            uploadedImageFile !== undefined
                ? uploadedImageFile[0].base64
                : "";
        const addEndPoint = addAccessReqEndPoint("Users");
        const getEndPoint = getAccessMgmtEndPoint("Users");

        adduserWs(addEndPoint, getEndPoint, mobileUserInfo[0]);
    };

    const handleExternalUsers = () => {
        externalUserFields[0]["userType"] = "External";
        externalUserFields[0]["image"] =
            uploadedImageFile.length > 0 &&
            uploadedImageFile !== [] &&
            uploadedImageFile !== undefined
                ? uploadedImageFile[0].base64
                : "";
        externalUserFields[0]["scanUser"] =
            externalUserScanFields !== undefined && externalUserScanFields.length > 0
                ? externalUserScanFields[0]
                : [];
        externalUserFields[0]["scanUser"]["canCloseBin"] = binClose;
        externalUserFields[0]["phoneNumber"] = mobileWithFlag
            ?.toString()
            .replace(/[^0-9]/g, "");
        externalUserFields[0]["fax"] = faxWithFlag
            ?.toString()
            .replace(/[^0-9]/g, "");
        const addEndPoint = addAccessReqEndPoint("Users");
        const getEndPoint = getAccessMgmtEndPoint("Users");
        adduserWs(addEndPoint, getEndPoint, externalUserFields[0]);
    };

    const adduserWs = async (
        addEndPoint: string,
        getEndPoint: string,
        reqBody: any
    ) => {
        let result: any;
        result = await axios.post(addEndPoint, reqBody);
        if (result && result.data) {
            if (result.data.status !== 200) {
                notify(result.data.message, "error-right");
            } else {
                setAddScreen(false);
                notify("User added successfully", "success");
                uploadedImageFile = [];
                dispatch(setUploadedImage([]));
                //  await axios.get(getEndPoint, { headers: { 'Authorization': `Bearer ${token}` } }).then((res) => {
                await axios
                    .get(getEndPoint)
                    .then((res) => {
                        dispatch(setUserAccessData(res.data));
                        // getRoles('Roles');
                    })
                    .catch((err) => {
                        console.log(err);
                        notify(
                            "Error! Unable to fetch data. Please try after sometime.",
                            "error"
                        );
                    });
            }
        } else {
            notify("Failed to create user", "error");
        }
    };

    const handlePopupActionAddUser = (selectedTabName: any) => {
        uploadedImageFile = [];
        setFileUploadAddScreen(true);
        setAddScreenTabName(selectedTabName);
    };

    const handleInternalUserFields = (e: any, type: any) => {
        if (type === "location") {
            setUserLocation(e.target.value);
        } else if (type === "role") {
            setUserRole(e.target.value);
        } else if (type === "language") {
            setUserLanguage(JSON.parse(e.target.value));
        } else if (type === "timezone") {
            setTimeZone(JSON.parse(e.target.value));
        } else if (type === "password") {
            setUserPassword(e.target.value);
        } else if (type === "boxMaxScanQty") {
            setBoxScanQty(e.target.value);
        } else if (type === "binMaxScanQty") {
            setBinMaxScanQty(e.target.value);
        } else if (type === "hourlyScanGoal") {
            setHourlyScanGoal(e.target.value);
        }

        validateInternalUsers();
    };

    const getStatesList = async (countryCode: any) => {
        const endPointUrl = getStatesByCountryCode(countryCode);
        await axios.get(endPointUrl).then(
            (res) => {
                setStateList(res.data);
                // dispatch(setStatesList(res.data));
            },
            (err) => {
                notify("Failed to fetch data", "error");
            }
        );
    };

    const getReclimCenterDataBasedOnId = async (id: any, type: string) => {
        const endPointUrl = getScanDetailsById(id);
        await axios.get(endPointUrl).then(
            (res) => {
                let savedScanDtls: any;
                savedScanDtls = res.data;
                if (type === "external") {
                    if (
                        savedScanDtls &&
                        savedScanDtls.scanSettings !== undefined &&
                        savedScanDtls.scanSettings !== null
                    ) {
                        externalUserScanFields[0].boxMaxScanQty =
                            savedScanDtls.scanSettings.boxMaxItemQty;
                        externalUserScanFields[0].binMaxScanQty =
                            savedScanDtls.scanSettings.binMaxItemQty;
                    }
                } else if (type === "internal") {
                    if (
                        savedScanDtls &&
                        savedScanDtls.scanSettings !== undefined &&
                        savedScanDtls.scanSettings !== null
                    ) {
                        internalUserScanFields[0].boxMaxScanQty =
                            savedScanDtls.scanSettings.boxMaxItemQty;
                        internalUserScanFields[0].binMaxScanQty =
                            savedScanDtls.scanSettings.binMaxItemQty;
                    }
                }
                // dispatch(setStatesList(res.data));
            },
            (err) => {
                notify("Failed to fetch data", "error");
            }
        );
    };

    const getDefaultSelectionsList = async (id: any, type: string) => {
        const endPointUrl = getAccessMgmtEndPoint(type);
        await axios.get(endPointUrl).then(
            (res) => {
                if (type === "Default Language") {
                }
                // dispatch(setStatesList(res.data));
            },
            (err) => {
                notify("Failed to fetch data", "error");
            }
        );
    };

    const validateInternalUsers = () => {
        let userLocationError = "";
        let userRoleError = "";
        let userLanguageError = "";
        let userTimeZoneError = "";
        let userPasswordError = "";
        let userBoxScanQtyError = "";
        let userBinScanQtyError = "";
        let userHourlyScanQtyError = "";
        setInternalUserErros({
            userLocationError: "",
            userRoleError: "",
            userLanguageError: "",
            userTimeZoneError: "",
            userPasswordError: "",
            userBoxScanQtyError: "",
            userBinScanQtyError: "",
            userHourlyScanQtyError: "",
        });
        // if (internalUserInfo[0]?.reclaimCenterId === 0) {
        //     userLocationError = 'Location is required'
        // }
        // if (internalUserInfo[0]?.language === '') {
        //     userLanguageError = "Language is required"
        // }

        if (internalUserInfo[0]?.roleId === 0) {
            userRoleError = "Role selection is required";
        }

        // if (internalUserInfo[0]?.supervisorPassword === "") {
        //   userPasswordError = "Supervisor Password is required";
        // }
        // if (boxMaxScanQty === '') {
        //     userBoxScanQtyError = "Box scan qty is required"
        // }
        // if (binMaxScanQty === '') {
        //     userBinScanQtyError = 'Bin scan qty is required'
        // }
        // if (hourlyScanGoal === '') {
        //     userHourlyScanQtyError = "Hourly scan is required"
        // }

        if (
            internalUserInfo[0]?.roleId === 0
            // || internalUserScanFields[0].supervisorPassword === ""
        ) {
            setInternalUserErros({
                userLocationError,
                userTimeZoneError,
                userRoleError,
                userLanguageError,
                userPasswordError,
                userBoxScanQtyError,
                userBinScanQtyError,
                userHourlyScanQtyError,
            });
            setInternalUserBtnDisabeled(true);
            return false;
        } else {
            setInternalUserBtnDisabeled(false);
        }
        return true;
    };

    const validateMobileUsers = () => {
        let locationError = "";
        let languageError = "";
        let timeZoneError = "";
        let userFirstNameError = "";
        let userLastNameError = "";
        let usernameError = "";
        let userEmailError = "";
        let mobileRoleError = "";
        let userCountryError = "";
        let companyError = "";
        let jobTitleError = "";
        let endDateError = "";
        let phoneError = "";
        let phoneMaxLenError = "";
        let zipLenError = "";
        let passwordError = "";
        let storeError = "";

        setMobileUserError({
            locationError: "",
            languageError: "",
            timeZoneError: "",
            userFirstNameError: "",
            userLastNameError: "",
            usernameError: "",
            userEmailError: "",
            mobileRoleError: "",
            userCountryError: "",
            companyError: "",
            jobTitleError: "",
            endDateError: "",
            phoneError: "",
            phoneMaxLenError: "",
            zipLenError: "",
            passwordError: "",
            storeError: "",
        });

        //  if (mobileUserInfo[0]?.reclaimCenterId === 0) {
        //     locationError = 'Location is required'
        // }
        if (mobileUserInfo[0]?.password === "") {
            passwordError = "Required Field";
            setMyPassword("1px solid red");
        } else {
            setMyPassword("");
        }
        if (mobileUserInfo[0]?.roleId === 0) {
            mobileRoleError = "Required Field";
            setMyRole("1px solid red");
        } else {
            setMyRole("");
        }
        if (mobileUserInfo[0]?.storeId === "") {
            storeError = "Required Field";
        }

        // if (mobileUserInfo[0]?.firstname === "") {
        //   userFirstNameError = "Required Field";
        //   setMyFirstName("1px solid red");
        // } else {
        //   setMyFirstName("");
        // }
        // if (mobileUserInfo[0]?.lastname === "") {
        //   userLastNameError = "Required Field";
        //   setMyLastName("1px solid red");
        // } else {
        //   setMyLastName("");
        // }
        if (mobileUserInfo[0]?.username === "") {
            usernameError = "Required Field";
            setMyUserName("1px solid red");
        } else {
            setMyUserName("");
        }
        //  if (mobileUserInfo[0]?.email === "") {
        //    userEmailError = "Required Field";
        //    setMyEmail("1px solid red");
        //  } else {
        //    setMyEmail("");
        //  }
        // if (mobileUserInfo[0]?.phoneNumber === '' ) {
        //     phoneError = 'Phone is required'
        // }
        if (
            mobileUserInfo[0]?.phoneNumber !== "" &&
            mobileUserInfo[0]?.phoneNumber.length !== 10
        ) {
            phoneMaxLenError = "Invalid Phone Number";
        }
        // if (mobileUserInfo[0]?.countryId === 0) {
        //     userCountryError = "Country is required"
        // }
        // if (mobileUserInfo[0]?.company === '') {
        //     companyError = 'Company is required'
        // }
        // if (mobileUserInfo[0]?.jobTitle === '') {
        //     jobTitleError = "Job Title is required"
        // }
        // if (mobileUserInfo[0]?.endDate === '') {
        //     endDateError = "End dateis required"
        // }
        // if (mobileUserInfo[0]?.endDate === '') {
        //     endDateError = "End dateis required"
        // }
        if (mobileUserInfo[0]?.zip !== "" && mobileUserInfo[0]?.zip.length !== 4) {
            zipLenError = "Invalid zip";
        }

        if (
            //mobileUserInfo[0]?.firstname === "" ||
            mobileUserInfo[0]?.storeId === "" ||
            //mobileUserInfo[0]?.lastname === "" ||
            mobileUserInfo[0]?.roleId === 0 ||
            mobileUserInfo[0]?.username === "" ||
            mobileUserInfo[0]?.password === ""
        ) {
            setMobileUserError({
                userFirstNameError,
                storeError,
                zipLenError,
                phoneMaxLenError,
                userLastNameError,
                passwordError,
                userCountryError,
                userEmailError,
                usernameError,
                locationError,
                languageError,
                timeZoneError,
                mobileRoleError,
                companyError,
                jobTitleError,
                phoneError,
                endDateError,
            });
            setMobileUserDisabeled(true);
            return false;
        } else {
            setMobileUserDisabeled(false);
        }
        return true;
    };

    const validateExternalUser = () => {
        let userFirstNameError = "";
        let userLastNameError = "";
        let usernameError = "";
        let userEmailError = "";
        let userCountryError = "";
        let companyError = "";
        let jobTitleError = "";
        let endDateError = "";
        let phoneError = "";
        let userLocationError = "";
        let userRoleError = "";
        let userLanguageError = "";
        let userTimeZoneError = "";
        let userBoxScanQtyError = "";
        let userBinScanQtyError = "";
        let userHourlyScanQtyError = "";
        let phoneMaxLenError = "";
        let passwordError = "";
        let zipLenError = "";
        setExternalUserErrors({
            userFirstNameError: "",
            userLastNameError: "",
            usernameError: "",
            userEmailError: "",
            userRoleError: "",
            userCountryError: "",

            companyError: "",
            jobTitleError: "",
            endDateError: "",
            userLocationError: "",
            userLanguageError: "",
            userTimeZoneError: "",
            phoneError: "",
            phoneMaxLenError: "",
            passwordError: "",
            zipLenError: "",

            userBoxScanQtyError: "",
            userBinScanQtyError: "",
            userHourlyScanQtyError: "",
        });
        //    if (externalUserFields[0]?.reclaimCenterId === 0) {
        //     userLocationError = 'Location is required'
        // }
        // if (externalUserFields[0]?.language === '') {
        //     userLanguageError = "Language is required"
        // }
        if (externalUserFields[0]?.roleId === 0) {
            userRoleError = "Required Field";
            setMyMobileRole("1px solid red");
        } else {
            setMyMobileRole("");
        }
        if (externalUserFields[0]?.password === "") {
            passwordError = "Required Field";
            setMyMobilePassword("1px solid red");
        } else {
            setMyMobilePassword("");
        }
        // if (externalUserFields[0]?.firstname === "") {
        //   userFirstNameError = "Required Field";
        //   setMyMobileFirstname("1px solid red");
        // } else {
        //   setMyMobileFirstname("");
        // }
        // if (externalUserFields[0]?.lastname === "") {
        //   userLastNameError = "Required Field";
        //   setMyMobileLastname("1px solid red");
        // } else {
        //   setMyMobileLastname("");
        // }
        if (externalUserFields[0]?.username === "") {
            usernameError = "Required Field";
            setMyMobileUsername("1px solid red");
        } else {
            setMyMobileUsername("");
        }
        // if (externalUserFields[0]?.email === "") {
        //   userEmailError = "Required Field";
        //   setMyMobileEmail("1px solid red");
        // } else {
        //   setMyMobileEmail("");
        // }
        // if (externalUserFields[0]?.phoneNumber === '') {
        //     phoneError = 'Phone is required'
        // }
        if (
            externalUserFields[0]?.phoneNumber !== "" &&
            externalUserFields[0]?.phoneNumber.length !== 10
        ) {
            phoneMaxLenError = "Invalid Phone";
        }
        if (
            externalUserFields[0]?.zip !== "" &&
            externalUserFields[0]?.zip.length !== 4
        ) {
            zipLenError = "Invalid Zip";
        }
        // if (externalUserFields[0]?.countryId === 0) {
        //     userCountryError = "Country is required"
        // }
        // if (externalUserFields[0]?.company === '') {
        //     companyError = 'Company is required'
        // }
        // if (externalUserFields[0]?.jobTitle === '') {
        //     jobTitleError = "Job Title is required"
        // }
        // if (externalUserFields[0]?.endDate === '') {
        //     endDateError = "End dateis required"
        // }
        // if (boxMaxScanQty === '') {
        //     userBoxScanQtyError = "Box scan qty is required"
        // }
        // if (binMaxScanQty === '') {
        //     userBinScanQtyError = 'Bin scan qty is required'
        // }
        // if (hourlyScanGoal === '') {
        //     userHourlyScanQtyError = "Hourly scan is required"
        // }

        if (
            // externalUserFields[0]?.firstname === "" ||
            // externalUserFields[0]?.lastname === "" ||
            externalUserFields[0]?.roleId === 0 ||
            externalUserFields[0]?.username === "" ||
            externalUserFields[0]?.countryId === 0 ||
            externalUserFields[0]?.password === ""
        ) {
            setExternalUserErrors({
                userFirstNameError,
                phoneMaxLenError,
                userLastNameError,
                userCountryError,
                userEmailError,
                usernameError,
                userRoleError,
                userLocationError,
                userLanguageError,
                userTimeZoneError,
                companyError,
                jobTitleError,
                phoneError,
                endDateError,
                userBoxScanQtyError,
                userBinScanQtyError,
                userHourlyScanQtyError,
                passwordError,
                zipLenError,
            });
            setExternalUserDisabeled(true);
            return false;
        } else {
            setExternalUserDisabeled(false);
        }
        return true;
    };

    const getActiveDirectoryUsersOnSearch = (e: any) => {
        getADUsersOnSearch(e.text);
    };

    const sportsData: string[] = [];
    const userautocompletefields = {value: "fullname"};

    const getADUsersOnSearch = async (e: any) => {

        if (e.length >= 2 && e.length <= 6) {
            const getEndPointUrl = getAdListEndPoint(e);
            await axios.post(getEndPointUrl, {}).then(
                (res: any) => {

                    let dataaa = res.data;
                    let respData;
                    if (dataaa?.body !== undefined && dataaa.body !== null) {
                        respData = dataaa.body;

                        if (respData.length > 0) {
                            setNoADUserMsg("");
                            respData.map((itm: any) => {
                                const fullName = itm.firstName + " " + itm.lastName;
                                Object.assign(itm, {fullname: fullName});
                            });
                        }
                    } else {

                        setNoADUserMsg(res.data.message);
                    }

                    // autocompletefields.value = fullname;

                    setActiveDirectoryUsersList(respData);
                    console.log(activeDirectoryUsersList)
                    //dispatch(setLungaguesData(res.data));
                },
                (error: any) => {
                    notify(error, "error");
                }
            );
        }

        //}

        const handleOnSearch = (string: any, results: any) => {
            // onSearch will have as the first callback parameter
            // the string searched and for the second the results.
        };

        const handleOnHover = (result: any) => {
            // the item hovered
        };

        const handleOnSelect = (item: any) => {
            // the item selected
        };

        const handleOnFocus = () => {
            console.log("Focused");
        };

        items.sort(function (a: any, b: any) {
            if (a.name < b.name) {
                return -1;
            }
            if (a.name > b.name) {
                return 1;
            }
            return 0;
        });
    };

    return (
        <div>
            {addScreen && (
                <AddFileUploadScreen
                    addScreen={addScreen}
                    setFileUploadAddScreen={setFileUploadAddScreen}
                    tabName={addScreenTabName}
                    screenType={screenType}
                />
            )}
            {displayScreen === "userSelection" ? (
                <div>
                    <div className="row m-0 m-auto mr-5">
                        <div className="col-sm-12 col-md-12 col-lg-12 text-center">
              <span className=" accessMgmtLabel fw-bold text-center">
                Select User Type
              </span>
                        </div>
                        <div
                            className="col-sm-12 palletRatelbl"
                            onChange={(e) => handleAddLocationEnabled(e)}
                        >
                            <div className="row m-0 mt-4 mb-4">
                                <div className="col-sm-3 col-md-3 col-lg-3"></div>
                                <div className="col-sm-6 col-md-6 col-lg-6 ">
                                    <div className="row m-0">
                                        <div className="col-sm-4 p-0">
                                            <input
                                                type="radio"
                                                id="internaluserEnabeled"
                                                name="locatioEnabled"
                                                value="internal"
                                            />{" "}
                                            <span className="usertTypeRadioLabel"> Internal</span>
                                            <br/>
                                            {/* {(locationEnabledPL1) ?<span className="text-danger">{errors.locationEnabldError}</span>:''} */}
                                        </div>
                                        <div className="col-sm-4 p-0">
                                            <input
                                                type="radio"
                                                id="externalUserEnabeled"
                                                name="locatioEnabled"
                                                value="external"
                                            />
                                            <span className="usertTypeRadioLabel"> External</span>
                                        </div>
                                        <div className="col-sm-4 p-0">
                                            <input
                                                type="radio"
                                                id="mobileUserEnabeled"
                                                name="locatioEnabled"
                                                value="mobile"
                                            />
                                            <span className="usertTypeRadioLabel"> Mobile</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-3 col-md-3 col-lg-3"></div>
                            </div>
                        </div>

                        <div className="col-sm-12 px-0 my-5">
                            <ButtonComponent
                                cssClass="float-right px-5 py-3"
                                style={
                                    userType !== undefined && userType !== ""
                                        ? {
                                            opacity: "1",
                                            borderRadius: "4px",
                                            pointerEvents: "all",
                                            backgroundColor: "#636799",
                                            color: "#ffffff",
                                            cursor: "pointer",
                                        }
                                        : {
                                            opacity: "0.5",
                                            pointerEvents: "none",
                                            backgroundColor: "#e8e8e8",
                                            color: "#969696",
                                            borderRadius: "4px",
                                        }
                                }
                                onClick={() => handleNext()}
                            >
                                Next
                            </ButtonComponent>
                            <ButtonComponent
                                cssClass="eg-btn-primary-link e-flat mt-3"
                                onClick={() => handlePopUpClose()}
                            >
                                Cancel
                            </ButtonComponent>
                        </div>
                    </div>
                </div>
            ) : (
                <div>
                    <div className="StepperControl mb-5">
                        <div className="col-6 pl-2 pt-0 mt-auto mb-auto">
                            <div className="page-header--col col--steps">
                                <ul className="list--steps pt-3 mb-0 pointer list-tabs">
                                    <li
                                        className={` ${stepperindexvalue === 1 ? "active" : ""}`}
                                        onClick={(e) => goToNextStep(1)}
                                    >
                                        <strong className="steps--number">1</strong>
                                    </li>
                                    <li
                                        className={` ${stepperindexvalue === 2 ? "active" : ""}`}
                                        onClick={(e) => goToNextStep(2)}
                                    >
                                        <strong className="steps--number">2</strong>
                                    </li>
                                    {userType === "external" ? (
                                        <li
                                            className={` ${stepperindexvalue === 3 ? "active" : ""}`}
                                            onClick={(e) => goToNextStep(3)}
                                        >
                                            <strong className="steps--number">3</strong>
                                        </li>
                                    ) : (
                                        <></>
                                    )}
                                </ul>
                            </div>
                        </div>
                    </div>

                    <div className="row m-0">
                        <div className="col-sm-12 textAlign-center AddUsersImageHover">
                            {/* <input accept="image/*" type="file" onChange={imageChange} /> */}
                            <div className="">
                                {/* <img src={editIcon} alt="edit " width="24" height="24" className=" m-3 pointer" onClick={() => handleEditImage()}/> */}
                            </div>

                            {uploadedImageFile && uploadedImageFile.length > 0 ? (
                                <img
                                    src={URL.createObjectURL(uploadedImageFile[0].file)}
                                    className="userAddIcon"
                                    height="100"
                                    width="100"
                                />
                            ) : (
                                <img src={userIcon} className="userAddIcon"/>
                            )}
                            <div className="HoverEditIconUsers">
                                <img
                                    src={editIcon}
                                    alt="edit "
                                    width="24"
                                    height="24"
                                    className="m-3 pointer"
                                    onClick={() => handlePopupActionAddUser("fileUpload")}
                                />
                            </div>
                        </div>
                    </div>
                    {userType === "internal" ? (
                        <div>
                            {stepperindexvalue === 1 ? (
                                <div className="row m-0">
                                    <div className="col-sm-4 col-md-4 col-lg-4 mt-3">
                                        <label className="label-title fw-bold">
                                            Active Directory Lookup{" "}
                                            <span className="requiredStar">*</span>
                                        </label>

                                        {/* <AutoCompleteComponent
                      id="atcelement"
                      dataSource={activeDirectoryUsersList}
                      filtering={getActiveDirectoryUsersOnSearch.bind(this)}
                      fields={autocompletefields}
                      change={(e: any) => handleActiveDirectoryLookup(e)}
                    /> */}
                                        <DropDownListComponent
                                            allowFiltering={true}
                                            dataSource={activeDirectoryUsersList}
                                            filtering={getActiveDirectoryUsersOnSearch.bind(this)}
                                            fields={userautocompletefields}
                                            change={(e: any) => handleActiveDirectoryLookup(e)}
                                            //{
                                            // if (e && e?.itemData) {
                                            //   setMiscItemDetails({
                                            //     ...MiscItemDetails,
                                            //     upc: e.itemData["upc"],
                                            //     description:
                                            //       e.itemData["description"] ||
                                            //       MiscItemDetails["description"],
                                            //     itemCost:
                                            //       (e.itemData["unitCost"] || MiscItemDetails["itemCost"]) +
                                            //       "",
                                            //   });
                                            // } else {
                                            //   setMiscItemDetails({
                                            //     ...MiscItemDetails,
                                            //     upc: "",
                                            //     description: "",
                                            //     itemCost: "0",
                                            //   });
                                            // }
                                            // }
                                            //}
                                        />
                                    </div>

                                    <div className="col-sm-12 col-md-12 col-lg-12 selectedListShowDiv mt-3">
                                        {internalAdUserInfo && internalAdUserInfo?.length > 0 ? (
                                            <div className="col-sm-12 px-5 py-4">
                                                <div className="row">
                                                    <div className="col-sm-6 col-md-6 col-lg-6 px-0 pt-4 ">
                                                        <div className="row mb-3">
                                                            <div className="col-sm-4 col-md-4 col-lg-4 preview-Key  ">
                                                                First Name
                                                            </div>
                                                            <div className="col-sm-8 col-md-8 col-lg-8 preview-value ">
                                                                {internalAdUserInfo[0]?.firstName}
                                                            </div>
                                                        </div>
                                                        <div className="row mb-3">
                                                            <div className="col-sm-4 col-md-4 col-lg-4 preview-Key  ">
                                                                Last Name
                                                            </div>
                                                            <div className="col-sm-8 col-md-8 col-lg-8 preview-value ">
                                                                {internalAdUserInfo[0]?.lastName}
                                                            </div>
                                                        </div>
                                                        <div className="row mb-3">
                                                            <div className="col-sm-4 col-md-4 col-lg-4 preview-Key  ">
                                                                Username
                                                            </div>
                                                            <div className="col-sm-8 col-md-8 col-lg-8 preview-value ">
                                                                {internalAdUserInfo[0]?.username}
                                                            </div>
                                                        </div>
                                                        <div className="row mb-3">
                                                            <div className="col-sm-4 col-md-4 col-lg-4 preview-Key  ">
                                                                Email
                                                            </div>
                                                            <div className="col-sm-8 col-md-8 col-lg-8 preview-value ">
                                                                {internalAdUserInfo[0]?.email}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-6 col-md-6 col-lg-6 px-0 pt-4 ">
                                                        <div className="row mb-3">
                                                            <div className="col-sm-4 col-md-4 col-lg-4 preview-Key  ">
                                                                Address
                                                            </div>
                                                            <div className="col-sm-8 col-md-8 col-lg-8 preview-value ">
                                                                {internalAdUserInfo[0]?.address}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        ) : (
                                            <div
                                                className="col-sm-12 d-flex align-items-center "
                                                style={{height: "150px"}}
                                            >
                                                {" "}
                                                <div className="col-sm-12 d-flex justify-content-center">
                                                    {" "}
                                                    <p className="font-24  text-danger">
                                                        {noAdUserMessage}.{" "}
                                                    </p>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                    <div className="col-sm-12 px-0 my-5">
                                        <ButtonComponent
                                            cssClass="float-right px-5 py-3 mr-2"
                                            style={
                                                internalAdUserInfo.length > 0
                                                    ? {
                                                        opacity: "1",
                                                        borderRadius: "4px",
                                                        pointerEvents: "all",
                                                        backgroundColor: "#636799",
                                                        color: "#ffffff",
                                                        cursor: "pointer",
                                                    }
                                                    : {
                                                        opacity: "0.5",
                                                        pointerEvents: "none",
                                                        backgroundColor: "#e8e8e8",
                                                        color: "#969696",
                                                        borderRadius: "4px",
                                                    }
                                            }
                                            onClick={() => goToNextStep(2)}
                                        >
                                            Next
                                        </ButtonComponent>
                                        <ButtonComponent
                                            cssClass="eg-btn-primary-link e-flat mt-3"
                                            onClick={() => handlePopUpClose()}
                                        >
                                            Cancel
                                        </ButtonComponent>
                                    </div>
                                </div>
                            ) : (
                                <div>
                                    <div className="col-sm-12 row m-0 mt-2">
                                        <div className="col-sm-6">
                                            <div className="col-sm-12 mt-3">
                                                <label className="label-title">Location </label>
                                                <select
                                                    className="form-control"
                                                    onChange={(e: any) =>
                                                        handleInternalUserSelectFields(e, "reclaimCenterId")
                                                    }
                                                    onBlur={(e) =>
                                                        handleInternalUserSelectFields(e, "reclaimCenterId")
                                                    }
                                                    onKeyUp={(e) =>
                                                        handleInternalUserSelectFields(e, "reclaimCenterId")
                                                    }
                                                >
                                                    {internalUserSelectFieldsObj[0]?.reclaimCenterId !==
                                                    undefined &&
                                                    internalUserSelectFieldsObj[0]?.reclaimCenterId !==
                                                    null &&
                                                    internalUserSelectFieldsObj[0]?.reclaimCenterId !==
                                                    "" ? (
                                                        <option
                                                            value={
                                                                internalUserSelectFieldsObj[0]?.reclaimCenterId
                                                                    ?.id
                                                            }
                                                        >
                                                            {
                                                                internalUserSelectFieldsObj[0]?.reclaimCenterId
                                                                    ?.reclaimcenterName
                                                            }
                                                        </option>
                                                    ) : (
                                                        <option value="">Select</option>
                                                    )}
                                                    {locationsDataList?.map((item: any) => (
                                                        <option value={JSON.stringify(item)}>
                                                            {item?.reclaimcenterName}
                                                        </option>
                                                    ))}
                                                </select>
                                                {/* {userLocation === 0 ? <span className='text-danger'>{internalUserError.userLocationError}</span> : ''} */}
                                            </div>
                                            <div className="col-sm-12 mt-3">
                                                <label className="label-title">
                                                    Role <span className="requiredStar">*</span>
                                                </label>
                                                <select
                                                    id="ddlRole"
                                                    className="form-control"
                                                    onChange={(e: any) =>
                                                        handleInternalUserSelectFields(e, "roleId")
                                                    }
                                                >
                                                    {/*  {internalUserSelectFieldsObj[0]?.roleId !== undefined && internalUserSelectFieldsObj[0]?.roleId !== null && internalUserSelectFieldsObj[0]?.roleId !== '' ? <option value={internalUserSelectFieldsObj[0]?.roleId?.id}>{internalUserSelectFieldsObj[0]?.roleId?.roleName}</option>
                                       : <option value="">Select</option>} */}
                                                    <option
                                                        value={internalUserSelectFieldsObj[0].roleId.id}>{internalUserSelectFieldsObj[0].roleId.roleName}</option>
                                                    {rolesListData?.map((item: any) => (
                                                        <option value={JSON.stringify(item)}>
                                                            {item?.roleName}
                                                        </option>
                                                    ))}
                                                </select>
                                                {userRole === 0 ? (
                                                    <span className="text-danger">
                            {internalUserError.userRoleError}
                          </span>
                                                ) : (
                                                    ""
                                                )}
                                            </div>
                                            <div className="col-sm-12 mt-3">
                                                <label className="label-title">Language</label>
                                                <select
                                                    className="form-control"
                                                    onChange={(e: any) =>
                                                        handleInternalUserSelectFields(e, "language")
                                                    }
                                                    onBlur={(e) =>
                                                        handleInternalUserSelectFields(e, "language")
                                                    }
                                                    onKeyUp={(e) =>
                                                        handleInternalUserSelectFields(e, "language")
                                                    }
                                                >
                                                    {/* {internalUserSelectFieldsObj[0]?.language !== undefined && internalUserSelectFieldsObj[0]?.language !== null  && internalUserSelectFieldsObj[0]?.language !== '' ?
                                               <option value={internalUserSelectFieldsObj[0]?.language?.isoLongCode} >{internalUserSelectFieldsObj[0]?.language?.isoLongCode}</option> :
                                               <option value="" >Select</option> } */}
                                                    {languagesDataList &&
                                                        languagesDataList?.map(
                                                            (item: { isoLongCode: any }) => (
                                                                <option value={JSON.stringify(item)}>
                                                                    {item?.isoLongCode}
                                                                </option>
                                                            )
                                                        )}
                                                </select>
                                                {/* {userLanguage === '' ? <span className='text-danger'>{internalUserError.userLanguageError}</span> : ''} */}
                                            </div>
                                            <div className="col-sm-12 mt-3">
                                                <label className="label-title">Time Zone</label>
                                                <select
                                                    className="form-control"
                                                    onChange={(e: any) =>
                                                        handleInternalUserSelectFields(e, "timezone")
                                                    }
                                                    onBlur={(e) =>
                                                        handleInternalUserSelectFields(e, "timezone")
                                                    }
                                                    onKeyUp={(e) =>
                                                        handleInternalUserSelectFields(e, "timezone")
                                                    }
                                                >
                                                    {/* {internalUserSelectFieldsObj[0]?.timezone !== undefined && internalUserSelectFieldsObj[0]?.timezone !== null && internalUserSelectFieldsObj[0]?.timezone !== '' ? <option  value={internalUserSelectFieldsObj[0]?.timezone?.timezoneName}>{internalUserSelectFieldsObj[0]?.timezone?.timezoneName}</option>:
                                               <option  value=''>Select</option> } */}
                                                    {timeZonesDataList &&
                                                        timeZonesDataList?.map((tz) => (
                                                            <option value={JSON.stringify(tz)}>
                                                                {tz.timezoneName}
                                                                {tz.utc}
                                                            </option>
                                                        ))}
                                                </select>
                                                {/* {language === '' ? <span className='text-danger'>{errors.languageError}</span> : ''} */}
                                            </div>
                                        </div>
                                        <div className="col-sm-6">
                                            <div className="col-sm-12 mt-3">
                                                <label className="label-title">
                                                    Supervisor Password
                                                </label>
                                                <input
                                                    className=" form-control"
                                                    type="password"
                                                    value={internalUserScanFields[0]?.supervisorPassword}
                                                    onChange={(e) =>
                                                        handleInternalUserScanFields(
                                                            e,
                                                            "supervisorPassword"
                                                        )
                                                    }
                                                    onBlur={(e) =>
                                                        handleInternalUserScanFields(
                                                            e,
                                                            "supervisorPassword"
                                                        )
                                                    }
                                                    onKeyUp={(e) =>
                                                        handleInternalUserScanFields(
                                                            e,
                                                            "supervisorPassword"
                                                        )
                                                    }
                                                />
                                                {userPassword === "" ? (
                                                    <span className="text-danger">
                            {internalUserError.userPasswordError}
                          </span>
                                                ) : (
                                                    ""
                                                )}
                                            </div>
                                            <div className="col-sm-12 mt-3">
                                                <label className="label-title">
                                                    Default I/B Box Max Qty
                                                </label>
                                                <input
                                                    className=" form-control"
                                                    type="number"
                                                    value={internalUserScanFields[0]?.boxMaxScanQty}
                                                    onChange={(e) =>
                                                        handleInternalUserScanFields(e, "boxMaxScanQty")
                                                    }
                                                    onBlur={(e) =>
                                                        handleInternalUserScanFields(e, "boxMaxScanQty")
                                                    }
                                                    onKeyUp={(e) =>
                                                        handleInternalUserScanFields(e, "boxMaxScanQty")
                                                    }
                                                />
                                                {/* {boxMaxScanQty === '' ? <span className='text-danger'>{internalUserError.userBoxScanQtyError}</span> : ''} */}
                                            </div>
                                            <div className="col-sm-12 mt-3">
                                                <label className="label-title">
                                                    Default O/B Box Max Qty
                                                </label>
                                                <input
                                                    className=" form-control"
                                                    type="number"
                                                    value={internalUserScanFields[0]?.binMaxScanQty}
                                                    onChange={(e) =>
                                                        handleInternalUserScanFields(e, "binMaxScanQty")
                                                    }
                                                    onBlur={(e) =>
                                                        handleInternalUserScanFields(e, "binMaxScanQty")
                                                    }
                                                    onKeyUp={(e) =>
                                                        handleInternalUserScanFields(e, "binMaxScanQty")
                                                    }
                                                />
                                                {/* {binMaxScanQty === '' ? <span className='text-danger'>{internalUserError.userBinScanQtyError}</span> : ''} */}
                                            </div>
                                            <div className="col-sm-12 mt-3">
                                                <label className="label-title">Scan Max Qty</label>
                                                <input
                                                    className=" form-control"
                                                    type="number"
                                                    value={internalUserScanFields[0]?.scanMaxQty}
                                                    onChange={(e) =>
                                                        handleInternalUserScanFields(e, "scanMaxQty")
                                                    }
                                                    onBlur={(e) =>
                                                        handleInternalUserScanFields(e, "scanMaxQty")
                                                    }
                                                    onKeyUp={(e) =>
                                                        handleInternalUserScanFields(e, "scanMaxQty")
                                                    }
                                                />
                                                {/* {timeZone === '' ? <span className='text-danger'>{internalUserError.userHourlyScanQtyError}</span> : ''} */}
                                            </div>
                                            <div className="col-sm-12 mt-3">
                                                <label className="label-title">Hourly Scan Goal</label>
                                                <input
                                                    className=" form-control"
                                                    type="number"
                                                    value={internalUserScanFields[0]?.hourlyScanGoal}
                                                    onChange={(e) =>
                                                        handleInternalUserScanFields(e, "hourlyScanGoal")
                                                    }
                                                    onBlur={(e) =>
                                                        handleInternalUserScanFields(e, "hourlyScanGoal")
                                                    }
                                                    onKeyUp={(e) =>
                                                        handleInternalUserScanFields(e, "hourlyScanGoal")
                                                    }
                                                />
                                                {/* {timeZone === '' ? <span className='text-danger'>{internalUserError.userHourlyScanQtyError}</span> : ''} */}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-12 px-0 my-5">
                                        <ButtonComponent
                                            cssClass="float-right px-5 py-3 mr-5"
                                            style={
                                                internalUserDisabeled
                                                    ? {
                                                        opacity: "0.5",
                                                        pointerEvents: "none",
                                                        backgroundColor: "#e8e8e8",
                                                        color: "#969696",
                                                        borderRadius: "4px",
                                                    }
                                                    : {
                                                        opacity: "1",
                                                        borderRadius: "4px",
                                                        pointerEvents: "all",
                                                        backgroundColor: "#636799",
                                                        color: "#ffffff",
                                                        cursor: "pointer",
                                                    }
                                            }
                                            onClick={() => handleInternalUsers()}
                                        >
                                            Add User
                                        </ButtonComponent>
                                        <ButtonComponent
                                            cssClass="float-right px-5 py-3 mr-2"
                                            style={{
                                                opacity: "1",
                                                borderRadius: "4px",
                                                pointerEvents: "all",
                                                backgroundColor: "#636799",
                                                color: "#ffffff",
                                                cursor: "pointer",
                                            }}
                                            onClick={() => goToNextStep(1)}
                                        >
                                            Back
                                        </ButtonComponent>
                                        <ButtonComponent
                                            cssClass="eg-btn-primary-link e-flat mt-3"
                                            onClick={() => handlePopUpClose()}
                                        >
                                            Cancel
                                        </ButtonComponent>
                                    </div>
                                </div>
                            )}
                        </div>
                    ) : userType === "mobile" ? (
                        <div>
                            {stepperindexvalue === 1 ? (
                                <div>
                                    <div className="col-sm-12 row m-0 mt-2">
                                        <div className="col-sm-6">
                                            <div className="col-sm-12 mt-3">
                                                <label className="label-title">
                                                    First Name
                                                </label>
                                                {/* {userFirstName === "" ? (
                          <span
                            className="text-danger"
                            style={{ float: "right" }}
                          >
                            {mobileUserErrors.userFirstNameError}
                          </span>
                        ) : (
                          ""
                        )} */}
                                                <input
                                                    className=" formcontrol"
                                                    type="text"
                                                    value={mobileUserInfo[0]?.firstname}
                                                    onChange={(e) =>
                                                        handleMobileUserTextFields(e, "firstname")
                                                    }
                                                    onBlur={(e) =>
                                                        handleMobileUserTextFields(e, "firstname")
                                                    }
                                                    onKeyUp={(e) =>
                                                        handleMobileUserTextFields(e, "firstname")
                                                    }
                                                    style={{border: myFirstName}}
                                                />
                                            </div>
                                            <div className="col-sm-12 mt-3">
                                                <label className="label-title">
                                                    Last Name
                                                </label>
                                                {/* {userLastName === "" ? (
                          <span
                            className="text-danger"
                            style={{ float: "right" }}
                          >
                            {mobileUserErrors.userLastNameError}
                          </span>
                        ) : (
                          ""
                        )} */}
                                                <input
                                                    className=" formcontrol"
                                                    type="text"
                                                    value={mobileUserInfo[0]?.lastname}
                                                    onChange={(e) =>
                                                        handleMobileUserTextFields(e, "lastname")
                                                    }
                                                    onBlur={(e) =>
                                                        handleMobileUserTextFields(e, "lastname")
                                                    }
                                                    onKeyUp={(e) =>
                                                        handleMobileUserTextFields(e, "lastname")
                                                    }
                                                    style={{border: myLastName}}
                                                />
                                            </div>
                                            <div className="col-sm-12 mt-3">
                                                <label className="label-title">
                                                    Username <span className="requiredStar">*</span>
                                                </label>
                                                {username === "" ? (
                                                    <span
                                                        className="text-danger"
                                                        style={{float: "right"}}
                                                    >
                            {mobileUserErrors.usernameError}
                          </span>
                                                ) : (
                                                    ""
                                                )}
                                                <input
                                                    className=" formcontrol"
                                                    type="text"
                                                    value={mobileUserInfo[0]?.username}
                                                    onChange={(e) =>
                                                        handleMobileUserTextFields(e, "username")
                                                    }
                                                    onBlur={(e) =>
                                                        handleMobileUserTextFields(e, "username")
                                                    }
                                                    onKeyUp={(e) =>
                                                        handleMobileUserTextFields(e, "username")
                                                    }
                                                    style={{border: myUserName}}
                                                />
                                            </div>
                                            <div className="col-sm-12 mt-3">
                                                <label className="label-title">
                                                    Role <span className="requiredStar">*</span>
                                                </label>
                                                {userRole === 0 ? (
                                                    <span
                                                        className="text-danger"
                                                        style={{float: "right"}}
                                                    >
                            {mobileUserErrors.mobileRoleError}
                          </span>
                                                ) : (
                                                    ""
                                                )}
                                                <select
                                                    className="formcontrol"
                                                    value={mobileUserInfo[0]?.roleId}
                                                    onChange={(e: any) =>
                                                        handleMobileUserSelectFields(e, "roleId")
                                                    }
                                                    style={{border: myRole}}
                                                >
                                                    <option
                                                        value={mobileUserSelectFieldsObj[0].roleId?.id}
                                                    >
                                                        Select
                                                    </option>
                                                    {rolesListData?.map((role: any) => (
                                                        <option value={role?.id}>
                                                            {role?.roleName}
                                                        </option>
                                                    ))}
                                                </select>
                                            </div>
                                            <div className="col-sm-12 mt-3">
                                                <label className="label-title">
                                                    Store <span className="requiredStar">*</span>
                                                </label>
                                                {userStore === "" ? (
                                                    <span
                                                        className="text-danger"
                                                        style={{float: "right"}}
                                                    >
                            {mobileUserErrors.storeError}
                          </span>
                                                ) : (
                                                    ""
                                                )}
                                                <DropDownListComponent
                                                    allowFiltering={true}
                                                    ref={(scope) => {
                                                        dropDownListObject = scope;
                                                    }}
                                                    value={mobileUserInfo[0]?.storeId}
                                                    dataSource={storesList}
                                                    filtering={getStoreNamewithMainFrameCustomer.bind(
                                                        this
                                                    )}
                                                    fields={autoCompleteFields}
                                                    change={(e: any) =>
                                                        handleMobileUserTextFields(e, "storeId")
                                                    }
                                                />

                                                {/* <input className=" form-control" type="text" value={mobileUserInfo[0]?.storeId}
                                                    onChange={(e) => handleMobileUserTextFields(e, 'storeId')}
                                                    onBlur={(e) => handleMobileUserTextFields(e, 'storeId')}
                                                    onKeyUp={(e) => handleMobileUserTextFields(e, 'storeId')} /> */}
                                            </div>
                                        </div>
                                        <div className="col-sm-6">
                                            <div className="col-sm-12 mt-3">
                                                <label className="label-title">
                                                    Email
                                                </label>
                                                {/* {userEmail === "" ? (
                          <span
                            className="text-danger"
                            style={{ float: "right" }}
                          >
                            {mobileUserErrors.userEmailError}
                          </span>
                        ) : (
                          ""
                        )} */}
                                                <input
                                                    className=" formcontrol"
                                                    type="email"
                                                    value={mobileUserInfo[0]?.email}
                                                    onChange={(e) =>
                                                        handleMobileUserTextFields(e, "email")
                                                    }
                                                    onBlur={(e) => handleMobileUserTextFields(e, "email")}
                                                    onKeyUp={(e) =>
                                                        handleMobileUserTextFields(e, "email")
                                                    }
                                                    style={{border: myEmail}}
                                                />
                                            </div>
                                            <div className="col-sm-12 mt-3">
                                                <label className="label-title">
                                                    Password <span className="requiredStar">*</span>
                                                </label>
                                                {userPassword === "" ? (
                                                    <span
                                                        className="text-danger"
                                                        style={{float: "right"}}
                                                    >
                            {mobileUserErrors.passwordError}
                          </span>
                                                ) : (
                                                    ""
                                                )}
                                                <input
                                                    className=" formcontrol"
                                                    type="password"
                                                    value={mobileUserInfo[0]?.password}
                                                    onChange={(e) =>
                                                        handleMobileUserTextFields(e, "password")
                                                    }
                                                    onBlur={(e) =>
                                                        handleMobileUserTextFields(e, "password")
                                                    }
                                                    onKeyUp={(e) =>
                                                        handleMobileUserTextFields(e, "password")
                                                    }
                                                    style={{border: myPassword}}
                                                />
                                            </div>
                                            <div className="col-sm-12 mt-3  ">
                                                <label className="label-title ">Address </label>
                                                <select
                                                    className="form-control"
                                                    onChange={(e) =>
                                                        handleMobileUserSelectFields(e, "countryId")
                                                    }
                                                    placeholder="Select Country"
                                                >
                                                    <option
                                                        value={mobileUserSelectFieldsObj[0].countryId?.id}
                                                    >
                                                        Select
                                                    </option>
                                                    {countryCodesDataList?.map((cn) => (
                                                        <option value={JSON.stringify(cn)}>
                                                            {cn.countryName}
                                                        </option>
                                                    ))}
                                                </select>
                                                {/* {userCountry === 0 ? <span className='text-danger'>{mobileUserErrors.userCountryError}</span> : ''} */}
                                            </div>

                                            <div className="col-sm-12 mt-3">
                                                <label className="label-title "></label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    value={mobileUserInfo[0]?.street}
                                                    placeholder="Street"
                                                    onChange={(e) =>
                                                        handleMobileUserTextFields(e, "street")
                                                    }
                                                />
                                            </div>
                                            <div className="col-sm-12 mt-3">
                                                <label className="label-title "></label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    value={mobileUserInfo[0]?.city}
                                                    placeholder="City"
                                                    onChange={(e) =>
                                                        handleMobileUserTextFields(e, "city")
                                                    }
                                                />
                                            </div>
                                            <div className="col-sm-8 mt-3">
                                                <label className="label-title "></label>
                                                <select
                                                    className="form-control"
                                                    onChange={(e) =>
                                                        handleMobileUserSelectFields(e, "stateId")
                                                    }
                                                >
                                                    <option
                                                        value={mobileUserSelectFieldsObj[0].stateId?.id}
                                                    >
                                                        {mobileUserSelectFieldsObj[0].stateId?.stateName}
                                                    </option>
                                                    {statesList &&
                                                        statesList.length > 0 &&
                                                        statesList.map((st: any) => (
                                                            <option value={JSON.stringify(st)}>
                                                                {st.stateName}
                                                            </option>
                                                        ))}
                                                </select>
                                            </div>
                                            <div className="col-sm-4 mt-1">
                                                <label className="label-title "></label>
                                                <input
                                                    type="number"
                                                    className="form-control"
                                                    value={mobileUserInfo[0]?.zip}
                                                    placeholder="ZIP"
                                                    onChange={(e) => handleMobileUserTextFields(e, "zip")}
                                                />
                                                {userZip.length !== 4 ? (
                                                    <span className="text-danger">
                            {mobileUserErrors.zipLenError}
                          </span>
                                                ) : (
                                                    ""
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-12 px-0 my-5">
                                        <ButtonComponent
                                            cssClass="float-right px-5 py-3 mr-2"
                                            style={{
                                                opacity: "1",
                                                borderRadius: "4px",
                                                pointerEvents: "all",
                                                backgroundColor: "#636799",
                                                color: "#ffffff",
                                                cursor: "pointer",
                                            }}
                                            onClick={() => goToNextStep(2)}
                                        >
                                            Next
                                        </ButtonComponent>
                                        <ButtonComponent
                                            cssClass="eg-btn-primary-link e-flat mt-3"
                                            onClick={() => handlePopUpClose()}
                                        >
                                            Cancel
                                        </ButtonComponent>
                                    </div>
                                </div>
                            ) : (
                                <div>
                                    <div>
                                        <div className="col-sm-12 row m-0 mt-2">
                                            <div className="col-sm-6">
                                                <div className="col-sm-12 mt-3">
                                                    <label className="label-title">Location </label>
                                                    <select
                                                        className="form-control"
                                                        onChange={(e: any) =>
                                                            handleMobileUserSelectFields(e, "reclaimCenterId")
                                                        }
                                                    >
                                                        <option
                                                            value={
                                                                mobileUserSelectFieldsObj[0].reclaimCenterId?.id
                                                            }
                                                        >
                                                            {
                                                                mobileUserSelectFieldsObj[0].reclaimCenterId
                                                                    ?.reclaimcenterName
                                                            }
                                                        </option>
                                                        {locationsDataList?.map((item: any) => (
                                                            <option value={JSON.stringify(item)}>
                                                                {item?.reclaimcenterName}
                                                            </option>
                                                        ))}
                                                    </select>
                                                    {userLocation === 0 ? (
                                                        <span className="text-danger">
                              {mobileUserErrors.locationError}
                            </span>
                                                    ) : (
                                                        ""
                                                    )}
                                                </div>
                                                <div className="col-sm-12 mt-3">
                                                    <label className="label-title">Company</label>
                                                    <input
                                                        className=" form-control"
                                                        type="text"
                                                        value={mobileUserInfo[0]?.company}
                                                        onChange={(e) =>
                                                            handleMobileUserTextFields(e, "company")
                                                        }
                                                        onBlur={(e) =>
                                                            handleMobileUserTextFields(e, "company")
                                                        }
                                                        onKeyUp={(e) =>
                                                            handleMobileUserTextFields(e, "company")
                                                        }
                                                    />
                                                    {/* {company === '' ? <span className='text-danger'>{mobileUserErrors.companyError}</span> : ''} */}
                                                </div>
                                                <div className="col-sm-12 mt-3">
                                                    <label className="label-title">Job Title</label>
                                                    <input
                                                        className=" form-control"
                                                        type="text"
                                                        value={mobileUserInfo[0]?.jobTitle}
                                                        onChange={(e) =>
                                                            handleMobileUserTextFields(e, "jobTitle")
                                                        }
                                                        onBlur={(e) =>
                                                            handleMobileUserTextFields(e, "jobTitle")
                                                        }
                                                        onKeyUp={(e) =>
                                                            handleMobileUserTextFields(e, "jobTitle")
                                                        }
                                                    />
                                                    {/* {jobTitle === '' ? <span className='text-danger'>{mobileUserErrors.jobTitleError}</span> : ''} */}
                                                </div>
                                                <div className="col-sm-12 mt-3">
                                                    <label className="label-title">End Date</label>
                                                    <input
                                                        type="date"
                                                        className="form-control"
                                                        value={mobileUserInfo[0]?.endDate}
                                                        onChange={(e) =>
                                                            handleMobileUserTextFields(e, "endDate")
                                                        }
                                                    />
                                                    {/* <DatePickerComponent /> */}

                                                    {/* {endDate === '' ? <span className='text-danger'>{mobileUserErrors.endDateError}</span> : ''} */}
                                                </div>
                                            </div>
                                            <div className="col-sm-6">
                                                <div className="col-sm-12 mt-3">
                                                    <label className="label-title">Phone </label>
                                                    <CountryDropdown type="Phone"/>
                                                    {/* <input type="number" id="txtPhone" className="form-control" value={mobileUserInfo[0]?.phoneNumber} onChange={(e) => handleMobileUserTextFields(e, 'phoneNumber')}
                                                            onBlur={(e) => handleMobileUserTextFields(e, 'phoneNumber')} onKeyUp={(e) => handleMobileUserTextFields(e, 'phoneNumber')} maxLength={10} /> */}
                                                    {/* {userPhone === '' ? <span className='text-danger'>{mobileUserErrors.phoneError}</span> : ''} */}
                                                    {userPhone.length !== 10 ? (
                                                        <span className="text-danger">
                              {mobileUserErrors.phoneMaxLenError}
                            </span>
                                                    ) : (
                                                        ""
                                                    )}
                                                </div>
                                                <div className="col-sm-12 mt-3">
                                                    <label className="label-title">Fax</label>
                                                    <CountryDropdown type="Fax"/>
                                                    {/* <input type="text" id="txtFax" className="form-control" value={mobileUserInfo[0]?.fax} onChange={(e) => handleMobileUserTextFields(e, 'fax')} /> */}
                                                    {/* {msgId === '' ? <span className='text-danger'>{errors.msgIdError}</span> : ''} */}
                                                </div>
                                                <div className="col-sm-12 mt-3">
                                                    <label className="label-title">Language</label>
                                                    <select
                                                        className="form-control"
                                                        onChange={(e: any) =>
                                                            handleMobileUserSelectFields(e, "language")
                                                        }
                                                    >
                                                        {/* <option value={mobileUserSelectFieldsObj[0].language?.isoLongCode}>{mobileUserSelectFieldsObj[0].language?.isoLongCode}</option> */}
                                                        {languagesDataList?.map(
                                                            (item: { isoLongCode: any }) => (
                                                                <option value={JSON.stringify(item)}>
                                                                    {item?.isoLongCode}
                                                                </option>
                                                            )
                                                        )}
                                                    </select>
                                                    {userLanguage === "" ? (
                                                        <span className="text-danger">
                              {mobileUserErrors.languageError}
                            </span>
                                                    ) : (
                                                        ""
                                                    )}
                                                </div>
                                                <div className="col-sm-12 mt-3">
                                                    <label className="label-title">Time Zone</label>
                                                    <select
                                                        className="form-control"
                                                        onChange={(e: any) =>
                                                            handleMobileUserSelectFields(e, "timezone")
                                                        }
                                                    >
                                                        {/* <option value={mobileUserSelectFieldsObj[0]?.timezone?.timezoneName} >{mobileUserSelectFieldsObj[0].timezone?.timezoneName}</option> */}
                                                        {timeZonesDataList?.map((tz) => (
                                                            <option value={JSON.stringify(tz)}>
                                                                {tz.timezoneName}
                                                                {tz.utc}
                                                            </option>
                                                        ))}
                                                    </select>
                                                    {/* {timeZone === '' ? <span className='text-danger'>{mobileUserErrors.timeZoneError}</span> : ''} */}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-sm-12 px-0 my-5">
                                            <ButtonComponent
                                                cssClass="float-right px-5 py-3 mr-5"
                                                style={
                                                    mobileUserDisabeled
                                                        ? {
                                                            opacity: "0.5",
                                                            pointerEvents: "none",
                                                            backgroundColor: "#e8e8e8",
                                                            color: "#969696",
                                                            borderRadius: "4px",
                                                        }
                                                        : {
                                                            opacity: "1",
                                                            borderRadius: "4px",
                                                            pointerEvents: "all",
                                                            backgroundColor: "#636799",
                                                            color: "#ffffff",
                                                            cursor: "pointer",
                                                        }
                                                }
                                                onClick={() => handleAddMobileUser()}
                                            >
                                                Add User
                                            </ButtonComponent>
                                            <ButtonComponent
                                                cssClass="float-right px-5 py-3 mr-2"
                                                onClick={() => goToNextStep(1)}
                                            >
                                                Go Back
                                            </ButtonComponent>
                                            <ButtonComponent
                                                cssClass="eg-btn-primary-link e-flat mt-3"
                                                onClick={() => handlePopUpClose()}
                                            >
                                                Cancel
                                            </ButtonComponent>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    ) : userType === "external" ? (
                        <div>
                            {stepperindexvalue === 1 ? (
                                <div>
                                    <div className="col-sm-12 row m-0 mt-2">
                                        <div className="col-sm-6">
                                            <div className="col-sm-12 mt-3">
                                                <label className="label-title">
                                                    First Name
                                                </label>
                                                {/* {userFirstName === "" ? (
                          <span
                            className="text-danger"
                            style={{ float: "right" }}
                          >
                            {externalUserError.userFirstNameError}
                          </span>
                        ) : (
                          ""
                        )} */}
                                                <input
                                                    className=" formcontrol"
                                                    type="text"
                                                    value={externalUserFields[0]?.firstname}
                                                    onChange={(e) =>
                                                        handleExternalUserTextFields(e, "firstname")
                                                    }
                                                    onBlur={(e) =>
                                                        handleExternalUserTextFields(e, "firstname")
                                                    }
                                                    onKeyUp={(e) =>
                                                        handleExternalUserTextFields(e, "firstname")
                                                    }
                                                    style={{border: myMobileFirstname}}
                                                />
                                            </div>
                                            <div className="col-sm-12 mt-3">
                                                <label className="label-title">
                                                    Last Name
                                                </label>
                                                {/* {userLastName === "" ? (
                          <span
                            className="text-danger"
                            style={{ float: "right" }}
                          >
                            {externalUserError.userLastNameError}
                          </span>
                        ) : (
                          ""
                        )} */}
                                                <input
                                                    className=" formcontrol"
                                                    type="text"
                                                    value={externalUserFields[0]?.lastname}
                                                    onChange={(e) =>
                                                        handleExternalUserTextFields(e, "lastname")
                                                    }
                                                    onBlur={(e) =>
                                                        handleExternalUserTextFields(e, "lastname")
                                                    }
                                                    onKeyUp={(e) =>
                                                        handleExternalUserTextFields(e, "lastname")
                                                    }
                                                    style={{border: myMobileLastname}}
                                                />
                                            </div>
                                            <div className="col-sm-12 mt-3">
                                                <label className="label-title">
                                                    Username <span className="requiredStar">*</span>
                                                </label>
                                                {username === "" ? (
                                                    <span
                                                        className="text-danger"
                                                        style={{float: "right"}}
                                                    >
                            {externalUserError.usernameError}
                          </span>
                                                ) : (
                                                    ""
                                                )}
                                                <input
                                                    className=" formcontrol"
                                                    type="text"
                                                    value={externalUserFields[0]?.username}
                                                    onChange={(e) =>
                                                        handleExternalUserTextFields(e, "username")
                                                    }
                                                    onBlur={(e) =>
                                                        handleExternalUserTextFields(e, "username")
                                                    }
                                                    onKeyUp={(e) =>
                                                        handleExternalUserTextFields(e, "username")
                                                    }
                                                    style={{border: myMobileUsername}}
                                                />
                                            </div>
                                            <div className="col-sm-12 mt-3">
                                                <label className="label-title">
                                                    Role <span className="requiredStar">*</span>
                                                </label>
                                                {userRole === 0 ? (
                                                    <span
                                                        className="text-danger"
                                                        style={{float: "right"}}
                                                    >
                            {externalUserError.userRoleError}
                          </span>
                                                ) : (
                                                    ""
                                                )}
                                                <select
                                                    className="formcontrol"
                                                    value={externalUserFields[0]?.roleId}
                                                    onChange={(e: any) =>
                                                        handleExternalUserSelectFields(e, "roleId")
                                                    }
                                                    style={{border: myMobileRole}}
                                                >
                                                    <option value={extUserSelectFieldsObj[0].roleId?.id}>
                                                        Select
                                                    </option>
                                                    {rolesListData?.map((role: any) => (
                                                        <option value={role?.id}>
                                                            {role?.roleName}
                                                        </option>
                                                    ))}
                                                </select>
                                            </div>
                                            <div className="col-sm-12 mt-3">
                                                <label className="label-title">
                                                    Password <span className="requiredStar">*</span>
                                                </label>
                                                {userPassword === "" ? (
                                                    <span
                                                        className="text-danger"
                                                        style={{float: "right"}}
                                                    >
                            {externalUserError.passwordError}
                          </span>
                                                ) : (
                                                    ""
                                                )}
                                                <input
                                                    className=" formcontrol"
                                                    type="password"
                                                    value={externalUserFields[0]?.password}
                                                    onChange={(e) =>
                                                        handleExternalUserTextFields(e, "password")
                                                    }
                                                    onBlur={(e) =>
                                                        handleExternalUserTextFields(e, "password")
                                                    }
                                                    onKeyUp={(e) =>
                                                        handleExternalUserTextFields(e, "password")
                                                    }
                                                    style={{border: myMobilePassword}}
                                                />
                                            </div>
                                            {/* <div className='col-sm-12 mt-3'>
                               <label className='label-title'>Store</label>
                              <input className=" form-control" type="text" value={externalUserFields[0]?.store} onChange={(e) => handleExternalUserTextFields(e,'storeId')} onBlur={(e) => handleExternalUserTextFields(e,'storeId')} onKeyUp={(e) => handleExternalUserTextFields(e,'storeId')}/>
                          </div> */}
                                        </div>
                                        <div className="col-sm-6">
                                            <div className="col-sm-12 mt-3">
                                                <label className="label-title">
                                                    Email
                                                </label>
                                                {/* {userEmail === "" ? (
                          <span
                            className="text-danger"
                            style={{ float: "right" }}
                          >

                          </span>
                        ) : (
                          ""
                        )} */}
                                                <input
                                                    className=" formcontrol"
                                                    type="email"
                                                    value={externalUserFields[0]?.email}
                                                    onChange={(e) =>
                                                        handleExternalUserTextFields(e, "email")
                                                    }
                                                    onBlur={(e) =>
                                                        handleExternalUserTextFields(e, "email")
                                                    }
                                                    onKeyUp={(e) =>
                                                        handleExternalUserTextFields(e, "email")
                                                    }
                                                    style={{border: myMobileEmail}}
                                                />
                                            </div>
                                            <div className="col-sm-12 mt-3  ">
                                                <label className="label-title ">Address</label>
                                                <select
                                                    className="form-control"
                                                    placeholder="a"
                                                    onChange={(e) =>
                                                        handleExternalUserSelectFields(e, "countryId")
                                                    }
                                                >
                                                    {/* <option selected disabled hidden className='select-placeholder'>Country</option> */}
                                                    <option
                                                        value={extUserSelectFieldsObj[0].countryId?.id}
                                                    >
                                                        Select
                                                    </option>
                                                    {countryCodesDataList?.map((cn) => (
                                                        <option value={JSON.stringify(cn)}>
                                                            {cn.countryName}
                                                        </option>
                                                    ))}
                                                </select>
                                                {userCountry === 0 ? (
                                                    <span className="text-danger">
                            {externalUserError.userCountryError}
                          </span>
                                                ) : (
                                                    ""
                                                )}
                                            </div>

                                            <div className="col-sm-12 mt-4">
                                                <label className="label-title "></label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    value={externalUserFields[0]?.street}
                                                    placeholder="Street"
                                                    onChange={(e) =>
                                                        handleExternalUserTextFields(e, "street")
                                                    }
                                                />
                                            </div>
                                            <div className="col-sm-12 mt-4">
                                                <label className="label-title "></label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    value={externalUserFields[0]?.city}
                                                    placeholder="City"
                                                    onChange={(e) =>
                                                        handleExternalUserTextFields(e, "city")
                                                    }
                                                />
                                            </div>
                                            <div className="col-sm-8 mt-4">
                                                <label className="label-title mb-0"></label>
                                                <select
                                                    className="form-control"
                                                    onChange={(e) =>
                                                        handleExternalUserSelectFields(e, "stateId")
                                                    }
                                                >
                                                    <option
                                                        selected
                                                        disabled
                                                        hidden
                                                        className="select-placeholder"
                                                    >
                                                        State
                                                    </option>
                                                    <option value={extUserSelectFieldsObj[0].stateId?.id}>
                                                        {extUserSelectFieldsObj[0].stateId?.stateName}
                                                    </option>
                                                    {statesList &&
                                                        statesList.length > 0 &&
                                                        statesList.map((st: any) => (
                                                            <option value={JSON.stringify(st)}>
                                                                {st.stateName}
                                                            </option>
                                                        ))}
                                                </select>
                                            </div>
                                            <div className="col-sm-4 mt-4">
                                                <label className="label-title mb-0"></label>
                                                <input
                                                    type="number"
                                                    className="form-control"
                                                    value={externalUserFields[0]?.zip}
                                                    placeholder="ZIP"
                                                    onChange={(e) =>
                                                        handleExternalUserTextFields(e, "zip")
                                                    }
                                                />
                                                {userZip === "" ? (
                                                    <span className="text-danger">
                            {externalUserError.zipLenError}
                          </span>
                                                ) : (
                                                    ""
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-12 px-0 my-5">
                                        <ButtonComponent
                                            cssClass="float-right px-5 py-3"
                                            style={{
                                                opacity: "1",
                                                borderRadius: "4px",
                                                pointerEvents: "all",
                                                backgroundColor: "#636799",
                                                color: "#ffffff",
                                                cursor: "pointer",
                                                marginRight: "3.6rem",
                                            }}
                                            onClick={() => goToNextStep(2)}
                                        >
                                            Next
                                        </ButtonComponent>
                                        <ButtonComponent
                                            cssClass="eg-btn-primary-link e-flat mt-3"
                                            onClick={() => handlePopUpClose()}
                                        >
                                            Cancel
                                        </ButtonComponent>
                                    </div>
                                </div>
                            ) : stepperindexvalue === 2 ? (
                                <div>
                                    <div>
                                        <div className="col-sm-12 row m-0 mt-2">
                                            <div className="col-sm-6">
                                                <div className="col-sm-12 mt-3">
                                                    <label className="label-title">Location </label>
                                                    <select
                                                        className="form-control"
                                                        onChange={(e: any) =>
                                                            handleExternalUserSelectFields(
                                                                e,
                                                                "reclaimCenterId"
                                                            )
                                                        }
                                                    >
                                                        <option
                                                            value={
                                                                extUserSelectFieldsObj[0].reclaimCenterId?.id
                                                            }
                                                        >
                                                            {
                                                                extUserSelectFieldsObj[0].reclaimCenterId
                                                                    ?.reclaimcenterName
                                                            }
                                                        </option>
                                                        {locationsDataList?.map((item: any) => (
                                                            <option value={JSON.stringify(item)}>
                                                                {item?.reclaimcenterName}
                                                            </option>
                                                        ))}
                                                    </select>
                                                    {/* {userLocation === 0 ? <span className='text-danger'>{externalUserError.userLocationError}</span> : ''} */}
                                                </div>
                                                <div className="col-sm-12 mt-3">
                                                    <label className="label-title">Company</label>
                                                    <input
                                                        className=" form-control"
                                                        type="text"
                                                        value={externalUserFields[0]?.company}
                                                        onChange={(e) =>
                                                            handleExternalUserTextFields(e, "company")
                                                        }
                                                        onBlur={(e) =>
                                                            handleExternalUserTextFields(e, "company")
                                                        }
                                                        onKeyUp={(e) =>
                                                            handleExternalUserTextFields(e, "company")
                                                        }
                                                    />
                                                    {/* {company === '' ? <span className='text-danger'>{externalUserError.companyError}</span> : ''} */}
                                                </div>
                                                <div className="col-sm-12 mt-3">
                                                    <label className="label-title">Job Title</label>
                                                    <input
                                                        className=" form-control"
                                                        type="text"
                                                        value={externalUserFields[0]?.jobTitle}
                                                        onChange={(e) =>
                                                            handleExternalUserTextFields(e, "jobTitle")
                                                        }
                                                        onBlur={(e) =>
                                                            handleExternalUserTextFields(e, "jobTitle")
                                                        }
                                                        onKeyUp={(e) =>
                                                            handleExternalUserTextFields(e, "jobTitle")
                                                        }
                                                    />
                                                    {/* {jobTitle === '' ? <span className='text-danger'>{externalUserError.jobTitleError}</span> : ''} */}
                                                </div>
                                                <div className="col-sm-12 mt-3">
                                                    <label className="label-title">End Date</label>
                                                    <input
                                                        type="date"
                                                        className="form-control"
                                                        value={externalUserFields[0]?.endDate}
                                                        onChange={(e) =>
                                                            handleExternalUserTextFields(e, "endDate")
                                                        }
                                                    />
                                                    {/* <DatePickerComponent /> */}

                                                    {/* {endDate === '' ? <span className='text-danger'>{externalUserError.endDateError}</span> : ''} */}
                                                </div>
                                            </div>
                                            <div className="col-sm-6">
                                                <div className="col-sm-12 mt-3">
                                                    <label className="label-title">Phone </label>
                                                    <CountryDropdown type="Phone"/>
                                                    {/* <input type="number" id="txtPhone" className="form-control" value={externalUserFields[0]?.phoneNumber} onChange={(e) => handleExternalUserTextFields(e, 'phoneNumber')}
                                                                        onBlur={(e) => handleExternalUserTextFields(e, 'phoneNumber')} onKeyUp={(e) => handleExternalUserTextFields(e, 'phoneNumber')} maxLength={10} /> */}
                                                    {/* {userPhone === '' ? <span className='text-danger'>{externalUserError.phoneError}</span> : ''} */}
                                                    {userPhone.length !== 10 ? (
                                                        <span className="text-danger">
                              {externalUserError.phoneMaxLenError}
                            </span>
                                                    ) : (
                                                        ""
                                                    )}
                                                </div>
                                                <div className="col-sm-12 mt-3">
                                                    <label className="label-title">Fax</label>
                                                    <CountryDropdown type="Fax"/>
                                                    {/* <input type="text" id="txtFax" className="form-control" value={externalUserFields[0]?.fax} onChange={(e) => handleExternalUserTextFields(e, 'fax')} /> */}
                                                    {/* {msgId === '' ? <span className='text-danger'>{errors.msgIdError}</span> : ''} */}
                                                </div>
                                                <div className="col-sm-12 mt-3">
                                                    <label className="label-title">Language</label>
                                                    <select
                                                        className="form-control"
                                                        onChange={(e: any) =>
                                                            handleExternalUserSelectFields(e, "language")
                                                        }
                                                    >
                                                        {/* <option value={extUserSelectFieldsObj[0].language?.isoLongCode}>{extUserSelectFieldsObj[0].language?.isoLongCode}</option> */}
                                                        {languagesDataList?.map((item) => (
                                                            <option value={JSON.stringify(item)}>
                                                                {item?.isoLongCode}
                                                            </option>
                                                        ))}
                                                    </select>
                                                    {/* {userLanguage === '' ? <span className='text-danger'>{externalUserError.userLanguageError}</span> : ''} */}
                                                </div>
                                                <div className="col-sm-12 mt-3">
                                                    <label className="label-title">Time Zone</label>
                                                    <select
                                                        className="form-control"
                                                        onChange={(e: any) =>
                                                            handleExternalUserSelectFields(e, "timezone")
                                                        }
                                                    >
                                                        {/* <option value={extUserSelectFieldsObj[0].timezone?.timezoneName} >{extUserSelectFieldsObj[0].timezone?.timezoneName}</option> */}
                                                        {timeZonesDataList?.map((tz) => (
                                                            <option value={JSON.stringify(tz)}>
                                                                {tz.timezoneName}
                                                                {tz.utc}
                                                            </option>
                                                        ))}
                                                    </select>
                                                    {/* {timeZone === '' ? <span className='text-danger'>{externalUserError.userTimeZoneError}</span> : ''} */}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-sm-12 px-0 my-5">
                                            <ButtonComponent
                                                cssClass="float-right px-5 py-3 mr-5"
                                                style={{
                                                    opacity: "1",
                                                    borderRadius: "4px",
                                                    pointerEvents: "all",
                                                    backgroundColor: "#636799",
                                                    color: "#ffffff",
                                                    cursor: "pointer",
                                                }}
                                                onClick={() => goToNextStep(3)}
                                            >
                                                Next
                                            </ButtonComponent>
                                            <ButtonComponent
                                                cssClass="float-right px-5 py-3 mr-2"
                                                style={{
                                                    opacity: "1",
                                                    borderRadius: "4px",
                                                    pointerEvents: "all",
                                                    backgroundColor: "#636799",
                                                    color: "#ffffff",
                                                    cursor: "pointer",
                                                }}
                                                onClick={() => goToNextStep(1)}
                                            >
                                                Back
                                            </ButtonComponent>
                                            <ButtonComponent
                                                cssClass="eg-btn-primary-link e-flat mt-3"
                                                onClick={() => handlePopUpClose()}
                                            >
                                                Cancel
                                            </ButtonComponent>
                                        </div>
                                    </div>
                                </div>
                            ) : (
                                <div>
                                    <div className="col-sm-12 row m-0 mt-2">
                                        <div className="col-sm-6">
                                            <div className="col-sm-12 mt-3">
                                                <label className="label-title">
                                                    Default I/B Box Max Qty
                                                </label>
                                                <input
                                                    className=" form-control"
                                                    type="number"
                                                    value={externalUserScanFields[0].boxMaxScanQty}
                                                    onChange={(e) =>
                                                        handleExternalUserScanFields(e, "boxMaxScanQty")
                                                    }
                                                    onBlur={(e) =>
                                                        handleExternalUserScanFields(e, "boxMaxScanQty")
                                                    }
                                                    onKeyUp={(e) =>
                                                        handleExternalUserScanFields(e, "boxMaxScanQty")
                                                    }
                                                />
                                                {/* {boxMaxScanQty === '' ? <span className='text-danger'>{externalUserError.userBoxScanQtyError}</span> : ''} */}
                                            </div>
                                            <div className="col-sm-12 mt-3">
                                                <label className="label-title">
                                                    Default O/B Box Max Qty
                                                </label>
                                                <input
                                                    className=" form-control"
                                                    type="number"
                                                    value={externalUserScanFields[0].binMaxScanQty}
                                                    onChange={(e) =>
                                                        handleExternalUserScanFields(e, "binMaxScanQty")
                                                    }
                                                    onBlur={(e) =>
                                                        handleExternalUserScanFields(e, "binMaxScanQty")
                                                    }
                                                    onKeyUp={(e) =>
                                                        handleExternalUserScanFields(e, "binMaxScanQty")
                                                    }
                                                />
                                                {/* {binMaxScanQty === '' ? <span className='text-danger'>{externalUserError.userBinScanQtyError}</span> : ''} */}
                                            </div>
                                            <div className="col-sm-12 mt-3">
                                                <label className="label-title">
                                                    Supervisor Password
                                                </label>
                                                <input
                                                    className=" form-control"
                                                    type="password"
                                                    value={externalUserScanFields[0]?.supervisorPassword}
                                                    onChange={(e) =>
                                                        handleExternalUserScanFields(
                                                            e,
                                                            "supervisorPassword"
                                                        )
                                                    }
                                                    onBlur={(e) =>
                                                        handleExternalUserScanFields(
                                                            e,
                                                            "supervisorPassword"
                                                        )
                                                    }
                                                    onKeyUp={(e) =>
                                                        handleExternalUserScanFields(
                                                            e,
                                                            "supervisorPassword"
                                                        )
                                                    }
                                                />
                                                {userPassword === "" ? (
                                                    <span className="text-danger">
                            {internalUserError.userPasswordError}
                          </span>
                                                ) : (
                                                    ""
                                                )}
                                            </div>
                                        </div>
                                        <div className="col-sm-6">
                                            <div className="col-sm-12 mt-3">
                                                <label className="label-title">Scan Max Qty</label>
                                                <input
                                                    className=" form-control"
                                                    type="number"
                                                    value={externalUserScanFields[0].scanMaxQty}
                                                    onChange={(e) =>
                                                        handleExternalUserScanFields(e, "scanMaxQty")
                                                    }
                                                    onBlur={(e) =>
                                                        handleExternalUserScanFields(e, "scanMaxQty")
                                                    }
                                                    onKeyUp={(e) =>
                                                        handleExternalUserScanFields(e, "scanMaxQty")
                                                    }
                                                />
                                                {/* {hourlyScanGoal === '' ? <span className='text-danger'>{externalUserError.userHourlyScanQtyError}</span> : ''} */}
                                            </div>
                                            <div className="col-sm-12 mt-3">
                                                <label className="label-title">Daily Scan Goal</label>
                                                <input
                                                    className=" form-control"
                                                    type="number"
                                                    value={externalUserScanFields[0].hourlyScanGoal}
                                                    onChange={(e) =>
                                                        handleExternalUserScanFields(e, "hourlyScanGoal")
                                                    }
                                                    onBlur={(e) =>
                                                        handleExternalUserScanFields(e, "hourlyScanGoal")
                                                    }
                                                    onKeyUp={(e) =>
                                                        handleExternalUserScanFields(e, "hourlyScanGoal")
                                                    }
                                                />
                                                {/* {hourlyScanGoal === '' ? <span className='text-danger'>{externalUserError.userHourlyScanQtyError}</span> : ''} */}
                                            </div>
                                            <div className="col-sm-12 mt-5">
                                                <label className="label-title">Can Close Bin</label>
                                                {binClose ? (
                                                    <img
                                                        src={toggleIconOn}
                                                        alt="close "
                                                        className="p-3"
                                                        onClick={() => handleCloseBin(false)}
                                                    />
                                                ) : (
                                                    <img
                                                        src={toggleIconOff}
                                                        alt="close "
                                                        className="p-3"
                                                        onClick={() => handleCloseBin(true)}
                                                    />
                                                )}
                                                {/* {msgId === '' ? <span className='text-danger'>{errors.msgIdError}</span> : ''} */}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-12 px-0 my-5">
                                        <ButtonComponent
                                            cssClass="float-right px-5 py-3 mr-5"
                                            style={
                                                externalUserDisabeled
                                                    ? {
                                                        opacity: "0.5",
                                                        pointerEvents: "none",
                                                        backgroundColor: "#e8e8e8",
                                                        color: "#969696",
                                                        borderRadius: "4px",
                                                    }
                                                    : {
                                                        opacity: "1",
                                                        borderRadius: "4px",
                                                        pointerEvents: "all",
                                                        backgroundColor: "#636799",
                                                        color: "#ffffff",
                                                        cursor: "pointer",
                                                    }
                                            }
                                            onClick={() => handleExternalUsers()}
                                        >
                                            Add User
                                        </ButtonComponent>
                                        <ButtonComponent
                                            cssClass="float-right px-5 py-3 mr-2"
                                            style={{
                                                opacity: "1",
                                                borderRadius: "4px",
                                                pointerEvents: "all",
                                                backgroundColor: "#636799",
                                                color: "#ffffff",
                                                cursor: "pointer",
                                            }}
                                            onClick={() => goToNextStep(2)}
                                        >
                                            Back
                                        </ButtonComponent>
                                        <ButtonComponent
                                            cssClass="eg-btn-primary-link e-flat mt-3"
                                            onClick={() => handlePopUpClose()}
                                        >
                                            Cancel
                                        </ButtonComponent>
                                    </div>
                                </div>
                            )}
                        </div>
                    ) : (
                        ""
                    )}
                </div>
            )}
        </div>
    );
};

export default AddUser;
