const Divider = () => {
  return (
    <div
      style={{
        width: "100%",
        borderTop: "1px solid #CCCCCC",
        marginTop: 8,
      }}
    ></div>
  );
};

export default Divider;
