import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import {
  setcasualDataClientGroupsList,
  setcasualDataReclaimCentersList,
  setcasualDataStoresList,
  setcasualDataUpcList,
  setcasualDataVendorsList,
  setSelClientGroupsList,
  setselectedRcList,
  setSelStoresList,
  setSelUpcsList,
  setSelVendorsList,
} from "../settingsReducers";
import closeIcon from "../../../assets/closeIcon.png";
import { casualDataAddPopupEndPoints, getSavedCasualDataById } from "../utils";
import axios from "axios";

const RuleCriteria = () => {
  const dispatch = useDispatch();

  const [reclaimCentersList, setRecliamCentersList] = useState<any>([]);
  let [vendorsList, setVendorsList] = useState<any>([]);
  let [storesList, setStoresList] = useState<any>([]);
  let [upcsList, setUpcsList] = useState<any>([]);
  const [clientGroupsList, setClientGroupsList] = useState<any>([]);

  let selectedReclaimCenters: any[] = [];
  let selectedVendors: any[] = [];
  let selectedStores: any[] = [];
  let selectedClientGroups: any[] = [];
  let selectedUpcs: any[] = [];

  const selectedRowDataList = useSelector(
    (state: RootState) => state.gridReducer?.selectedRowData
  );

  let selectedSavedRowData: any[] = [];
  const [selectedRuleCriteria, setSelectedRuleCriteria] = useState<any[]>([]);

  const savedCasualDataList = useSelector(
    (state: RootState) => state.settingsReducer?.selectedCasulDataList
  );

  useEffect(() => {
    if (savedCasualDataList && savedCasualDataList?.length > 0) {
      console.log(savedCasualDataList)
      setSelectedRuleCriteria(savedCasualDataList);
    }
    popupMasterData("ReclaimCenters");
    popupMasterData("ClientGroups");
    popupMasterData("Vendors");
    popupMasterData("Stores");
    popupMasterData("UPCs");
    appendSelectedDetails();
  }, [savedCasualDataList]);

  const reclaimCentersDataList = useSelector(
    (state: RootState) => state.settingsReducer?.casualDataReclaimCentersList
  );
  let selectedRclistData = useSelector(
    (state: RootState) => state.settingsReducer?.selectedRcList
  );
  const selectedVendorsListData = useSelector(
    (state: RootState) => state.settingsReducer?.selVendorsList
  );
  const selectedUpcsListData = useSelector(
    (state: RootState) => state.settingsReducer?.selUpcsList
  );
  const selectedClientGroupsListData = useSelector(
    (state: RootState) => state.settingsReducer?.selClientGroupList
  );
  const selectedStoresListData = useSelector(
    (state: RootState) => state.settingsReducer?.selStoresList
  );

  const storesDataList = useSelector(
    (state: RootState) => state.settingsReducer?.casualDataStoresList
  );

  const clientGroupsDataList = useSelector(
    (state: RootState) => state.settingsReducer?.casualDataClientGroupsList
  );

  const upcsDataList = useSelector(
    (state: RootState) => state.settingsReducer?.casualDataUpcList
  );

  const vendorsDataList = useSelector(
    (state: RootState) => state.settingsReducer?.casualDataVendorsList
  );

  const getSavedCasualData = async (id: any) => {
    const endPointUrl = getSavedCasualDataById(id);
    await axios
      .get(endPointUrl)
      .then((resp) => {
        selectedSavedRowData.push(resp.data);
        console.log(selectedSavedRowData);
      })
      .catch((err) => err);
  };

  const handleReclaimCenterChange = (e: any) => {
    selectedReclaimCenters.push(JSON.parse(e.target.value));
    dispatch(setselectedRcList(selectedReclaimCenters));
    // setSelectedReclaimCenters(e.target.value);
  };
  const handleVendorChange = (e: any) => {
    selectedVendors.push(JSON.parse(e.target.value));
    dispatch(setSelVendorsList(selectedVendors));
  };
  const handleUPCChange = (e: any) => {
    selectedUpcs.push(JSON.parse(e.target.value));
    dispatch(setSelUpcsList(selectedUpcs));
  };
  const handleClientGroupChange = (e: any) => {
    selectedClientGroups.push(JSON.parse(e.target.value));
    dispatch(setSelClientGroupsList(selectedClientGroups));
  };
  const handleStoreChange = (e: any) => {
    selectedStores.push(JSON.parse(e.target.value));
    dispatch(setSelStoresList(selectedStores));
  };

  const appendSelectedDetails = () => {
    console.log(savedCasualDataList)
    selectedRclistData = savedCasualDataList?.reclaimCenter;
    console.log(selectedRclistData)
  };

  const handleCancelSelectedItem = (type: string, idx: number) => {
    if (type === "ReclaimCenters") {
      selectedRclistData.splice(idx, 1);
    } else if (type === "Vendors") {
      selectedVendorsListData.splice(idx, 1);
    } else if (type === "UPC") {
      selectedUpcsListData.splice(idx, 1);
    } else if (type === "ClientGroup") {
      selectedClientGroupsListData.splice(idx, 1);
    } else if (type === "Stores") {
      selectedStoresListData.splice(idx, 1);
    } else {
    }
  };

  const popupMasterData = async (listname: any) => {
    //  vendorsList.push(VendorList);
    //  storesList.push(StoresList);
    //  upcsList.push(UpcList);

    const endPointUrl = casualDataAddPopupEndPoints(listname, 1, 200);
    await axios
      .get(endPointUrl)
      .then((resp: any) => {
        if (listname === "ReclaimCenters") {
          reclaimCentersList.push(resp.data);
          dispatch(setcasualDataReclaimCentersList(resp.data));
        } else if (listname === "Vendors") {
          // const vlist = resp['data']['content']
          vendorsList.push(resp.data.content);
          //  vendorsList = VendorList
          dispatch(setcasualDataVendorsList(resp.data.content));
        } else if (listname === "Stores") {
          storesList.push(resp.data.content);
          // storesList = StoresList
          dispatch(setcasualDataStoresList(resp.data.content));
        } else if (listname === "ClientGroups") {
          clientGroupsList.push(resp.data);
          dispatch(setcasualDataClientGroupsList(resp.data));
        } else if (listname === "UPCs") {
          upcsList.push(resp.data.content);
          // upcsList = UpcList
          dispatch(setcasualDataUpcList(resp.data.content));
        } else {
        }

        console.log(resp);
      })
      .catch((err) => err);
  };

  return (
    <div>
      <div className="row my-5">
        <div className="col-sm-3 col-md-3 col-lg-3 mb-4">
          <span className="form-input-settings-label float-left fw-bold">
            Reclaim Center(s)<b className="text-danger ml-2">*Required</b>
          </span>
          <select
            className="form-control"
            placeholder="Search Reclaim Center"
            onChange={(e: any) => handleReclaimCenterChange(e)}
          >
            <option value="">Search Reclaim Centers</option>
            {reclaimCentersList[0]?.map((item: any) => (
              <option value={JSON.stringify(item)}>
                {item?.reclaimcenterName}
              </option>
            ))}
          </select>
          <div className="selectedListEditShowDiv mt-3">
            {savedCasualDataList?.reclaimCenter && savedCasualDataList?.reclaimCenter?.length > 0 &&
              savedCasualDataList?.reclaimCenter?.map((item: any, i: number) => (
                <div className="row m-2 selectedRow">
                  <p className="col-sm-10 col-md-10 col-lg-10 pt-1 pb-1 pl-1 pr-0 mb-0 selectedtem">
                    {item?.reclaimcenterName}
                  </p>
                  <img
                    src={closeIcon}
                    alt="edit "
                    width="14"
                    height="14"
                    className="float-right m-3"
                    onClick={() =>
                      handleCancelSelectedItem("ReclaimCenters", i)
                    }
                  />
                </div>
              ))}
          </div>
        </div>
        <div className="col-sm-3 col-md-3 col-lg-3 mb-4">
          <span className="form-input-settings-label float-left fw-bold">
            Vendor(s) <b className="text-danger ml-2">*Required</b>
          </span>
          <select
            className="form-control"
            onChange={(e: any) => handleVendorChange(e)}
            onBlur={(e) => handleVendorChange(e)}
            onKeyUp={(e) => handleVendorChange(e)}
          >
            <option value="">Search Vendors</option>
            {vendorsList[0]?.map((item: any) => (
              <option value={JSON.stringify(item)}>{item?.apVendorName}</option>
            ))}
          </select>
          <div className="selectedListEditShowDiv mt-3">
            {savedCasualDataList?.vendor && savedCasualDataList?.vendor?.length > 0 &&
              savedCasualDataList?.vendor?.map((item: any, i: number) => (
                <div className="row m-2 selectedRow">
                  <p className="col-sm-10 col-md-10 col-lg-10 pt-1 pb-1 pl-1 pr-0 mb-0 selectedtem">
                    ({item.vendorNumber}) {item.apVendorName}
                  </p>
                  <img
                    src={closeIcon}
                    alt="edit "
                    width="14"
                    height="14"
                    className="float-right m-3"
                    onClick={() => handleCancelSelectedItem("Vendors", i)}
                  />
                </div>
              ))}
          </div>
        </div>
        <div className="col-sm-2 col-md-2 col-lg-2 mb-4">
          <span className="form-input-settings-label float-left fw-bold">
            UPC(s)
          </span>
          <select
            className="form-control"
            onChange={(e: any) => handleUPCChange(e)}
            onBlur={(e) => handleUPCChange(e)}
            onKeyUp={(e) => handleUPCChange(e)}
          >
            <option value="">Search UPCs</option>
            {upcsList[0]?.map((item: any) => (
              <option value={JSON.stringify(item)}>{item?.upc}</option>
            ))}
          </select>
          <div className="selectedListEditShowDiv mt-3">
            {savedCasualDataList && savedCasualDataList?.upc?.length > 0 &&
              savedCasualDataList?.upc?.map((item: any, i: number) => (
                <div className="row m-2 selectedRow">
                  <p className="col-sm-10 col-md-10 col-lg-10 pt-1 pb-1 pl-1 pr-0 mb-0 selectedtem">
                    {item.upcReference}
                  </p>
                  <img
                    src={closeIcon}
                    alt="edit "
                    width="14"
                    height="14"
                    className="float-right m-3"
                    onClick={() => handleCancelSelectedItem("UPC", i)}
                    style={{position: 'absolute', right: '20px'}}
                  />
                </div>
              ))}
          </div>
        </div>
        <div className="col-sm-2 col-md-2 col-lg-2 mb-4">
          <span className="form-input-settings-label float-left fw-bold">
            Client Group(s)
          </span>
          <select
            className="form-control"
            onChange={(e: any) => handleClientGroupChange(e)}
          >
            <option value="">Search Client Groups</option>
            {clientGroupsList[0]?.map((item: any) => (
              <option value={JSON.stringify(item)}>
                {item?.clientGroupName}
              </option>
            ))}
          </select>
          <div className="selectedListEditShowDiv mt-3">
            {savedCasualDataList && savedCasualDataList?.clientGroup?.length > 0 &&
              savedCasualDataList?.clientGroup?.map((item: any, i: number) => (
                <div className="row m-2 selectedRow">
                  <p className="col-sm-10 col-md-10 col-lg-10 pt-1 pb-1 pl-1 pr-0 mb-0 selectedtem">
                    {item}
                  </p>
                  <img
                    src={closeIcon}
                    alt="edit "
                    width="14"
                    height="14"
                    className="float-right m-3"
                    onClick={() => handleCancelSelectedItem("ClientGroup", i)}
                    style={{position: 'absolute', right: '20px'}}
                  />
                </div>
              ))}
          </div>
        </div>
        <div className="col-sm-2 col-md-2 col-lg-2 mb-4">
          <span className="form-input-settings-label float-left fw-bold">
            Store(s)
          </span>
          <select
            className="form-control"
            onChange={(e: any) => handleStoreChange(e)}
            onBlur={(e) => handleStoreChange(e)}
            onKeyUp={(e) => handleStoreChange(e)}
          >
            <option value="">Search Stores</option>
            {storesList[0]?.map((item: any) => (
              <option value={JSON.stringify(item)}>{item?.storeName}</option>
            ))}
          </select>
          <div className="selectedListEditShowDiv mt-3">
            {savedCasualDataList && savedCasualDataList?.store?.length > 0 &&
              savedCasualDataList?.store?.map((item: any, i: number) => (
                <div className="row m-2 selectedRow">
                  <p className="col-sm-10 col-md-10 col-lg-10 pt-1 pb-1 pl-1 pr-0 mb-0 selectedtem">
                    {item.storeName}
                  </p>
                  <img
                    src={closeIcon}
                    alt="edit "
                    width="14"
                    height="14"
                    className="float-right m-3"
                    onClick={() => handleCancelSelectedItem("Stores", i)}
                    style={{position: 'absolute', right: '20px'}}
                  />
                </div>
              ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default RuleCriteria;
