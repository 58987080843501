import HttpService from '../http-service/http-service';
import { API_PATH, getApiBaseUrl } from '../../api-paths';
import { SearchCriteriaRequest } from '../../components/grid/gridConfig';

export interface Client {
    createdDate?: any;
    lastModifiedDate?: any;
    organizationId?: any;
    tenantId?: any;
    id: number;
    clientId: string;
    clientName: string;
}

export interface ItemCatalog {
    createdDate: Date;
    lastModifiedDate: Date;
    organizationId: string;
    tenantId: string;
    id: number;
    upcReference: string;
    description: string;
    pack: number;
    unitFactor: number;
    disposition: string;
    vendorNumber: string;
    itemCode: string;
    itemIdType: string;
    storeCost: number;
    programType: string;
    programName: string;
    clientCustomerNumber: number;
    retail?: any;
    cost: number;
    crossQuantityRequiredFlag: string;
    size: string;
    batchStartTime: Date;
    client: Client;
    debitReasonCode?: any;
    hazardousFlag: string;
    subDisposition?: any;
    maStateDisposition?: any;
    flStateDisposition?: any;
    txStateDisposition?: any;
    caStateDisposition?: any;
    ruleExceptionList?: any;
    warehouseLocation?: any;
    storeDisposition?: any;
    vendorName?: any;
    vendorId?: any;
    active: boolean;
    ruleActive: boolean;
    deleted: boolean;
}

export interface ItemCatalogGridView {
    id: number;
    upcReference: string;
    description: string;
    vendorInfo: string;
    disposition: string;
    warehouseLocation?: any;
    activeForGridItemCatalog: string;
    getActiveForGridItemCatalog: string;
}

export interface ItemsCatalogResponse {
    result: ItemCatalogGridView[];
    count: number;
}

export default class ItemsCatalogService {
    public async getFilteredItems(
        searchCriteria: SearchCriteriaRequest
    ): Promise<ItemsCatalogResponse> {
        const url = getApiBaseUrl() + API_PATH.ITEMS_CATALOG_FILTER_PATH;
        return HttpService.post(url, searchCriteria).then((response) => {
            return response.data;
        });
    }

    public async getItem(id: number): Promise<ItemCatalog> {
        const url = getApiBaseUrl() + API_PATH.ITEM_CATALOG_ITEM + id;
        return HttpService.get<ItemCatalog>(url).then((response) => {
            return response.data;
        });
    }

    public async updateItem(
        id: number,
        rowInfoObject: any
    ): Promise<ItemCatalog> {
        const url = getApiBaseUrl() + API_PATH.ITEM_CATALOG_ITEM + id;
        return HttpService.put<ItemCatalog>(url, rowInfoObject).then(
            (response) => {
                return response.data;
            }
        );
    }
}
