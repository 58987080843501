import {FC, useCallback, useEffect, useRef, useState} from "react";
import {ButtonComponent, RadioButtonComponent,} from "@syncfusion/ej2-react-buttons";
import {TabComponent, TabItemDirective, TabItemsDirective,} from "@syncfusion/ej2-react-navigations";
import {DatePickerComponent} from "@syncfusion/ej2-react-calendars";
import ListObject from "../listObject/FileListItem";
import LLModal from "../llModal/LLModal";
import NotesCard from "../notesCard/NotesCard";
import "./InputCollection.scss";
import ContentHeader from "../ContentHeader/ContentHeader";
import Divider from "../divider/Divider";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../../redux/store";
import {
    setCustomerIdForInvoiceInBOL,
    setIsBOLDataClear,
    setIsInvoiceUpdated,
    setWarehouseIdForInvoiceInBOL,
} from "../../invoices/invoiceReducer";
import moment from "moment";

interface IFiles {
    name: string;
    size: number;
    type: string;
    data: string | ArrayBuffer | null;
    file: File;
}

interface IInputCollectionBol {
    showInvoice?: boolean;
    showBol?: boolean;
    showBilling?: boolean;
    showRaNumber?: boolean;
    attachment?: boolean;
    showReclaimCenter?: boolean;
    showPeriod?: boolean;
    showDescription?: boolean;
    showCustomer?: boolean;
    showAccount?: boolean;
    showPickupFrom?: boolean;
    showTrainerNumber?: boolean;
    showAttachment?: boolean;
    showDeliverTo?: boolean;
    showSealNumber?: boolean;
    showNotes?: boolean;
    showCarrier?: boolean;
    showPickedDate?: boolean;
    showGrossWeight?: boolean;
    handleChange: (data: any) => void;
}

const useInputCollectionBol: FC<IInputCollectionBol> = ({handleChange}) => {
    const [bolNumber, setBolNumber] = useState<string>("");

    const [period, setPeriod] = useState("");
    const [billingMode, setBillingMode] = useState("");
    // const [billToCust, setBillToCust] = useState(false);
    // const [thirdParty, setThirdParty] = useState(false);
    // const [freight, setFreight] = useState(
    //   !bolData?.id ? true : false);

    const [selectedCust, setSelectedCust] = useState<any[]>([]);
    const [selectedCustDetails, setSelectedCustDetails] = useState([]);
    const [showAttachmentModal, setShowAttachmentModal] = useState(false);
    const [showDeliverAddrDiv, setShowDeliverAddrDiv] = useState(false);
    const [selectedAttachmentTab, setSelectedAttachmentTab] = useState(0);

    const [loadedAttachmentFiles, setLoadedAttachmentFiles] = useState<IFiles[]>(
        []
    );
    const [savedBolData, setSavedBolData] = useState<any[]>([]);
    const dispatch = useDispatch();

    const reclaimCentersData = useSelector(
        (state: RootState) => state.contactReducer?.reclaimCenters
    );
    const fiscalYearPeriodsData = useSelector(
        (state: RootState) => state.contactReducer?.fiscalYearPeriods
    );
    let fiscalYearPeriodsDataObj: any = fiscalYearPeriodsData;
    const customerData = useSelector(
        (state: RootState) => state.contactReducer?.customers
    );
    const carrierData = useSelector(
        (state: RootState) => state.contactReducer?.carriers
    );
    const bolNumForNew = useSelector(
        (state: RootState) => state.invoiceReducer?.bolNumberForADD
    );

    const whData = useSelector(
        (state: RootState) => state.contactReducer?.warehouses
    );
    const bolData = useSelector(
        (state: RootState) => state?.invoiceReducer?.bolDataById
    );
    useEffect(() => {
        setSavedBolData([bolData]);
        if (bolData && bolData.billingType) {
            setBillingType(bolData.billingType);
            if ('3rd Party' === bolData.billingType) {
                setBilling(bolData.billing);
            }
        }

    }, [bolData]);

    const [description, setDescription] = useState<string>(
        bolData && bolData?.description ? bolData?.description : ""
    );
    const [reclaimCenterId, setReclaimCenter] = useState(
        bolData && bolData?.reclaimCenter?.id
            ? bolData?.reclaimCenter?.id
            : bolNumForNew?.reclaimCenter?.id
    );
    const [customerId, setCustomer] = useState<string>(
        bolData && bolData?.customer?.id ? bolData?.customer?.id : ""
    );
    const [customername, setCustomerName] = useState<string>(
        bolData && bolData?.customer?.customerName ? bolData?.customer?.customerName : ""
    );
    const [pickUpFromId, setPickupFrom] = useState<string>(
        bolData && bolData?.pickUpFrom?.id ? bolData?.pickUpFrom?.id : ""
    );
    const [primaryAddressId, setDeliverTo] = useState(
        bolData && bolData?.primaryAddress?.id ? bolData?.primaryAddress?.id : ""
    );
    const [billingType, setBillingType] = useState('Freight Collect');
    const [billingTo, setBilling] = useState(
        bolData && bolData?.customer?.id ? bolData?.customer?.id : ""
    );
    const [carrierId, setCarrier] = useState(
        bolData && bolData?.carrier?.id ? bolData?.carrier?.id : ""
    );
    const [billToCust, setBillToCust] = useState(bolData && bolData?.billingType === 'Bill To Customer' ? true : false);
    const [thirdParty, setThirdParty] = useState(bolData && bolData?.billingType === '3rd Party' ? true : false);

    const [freight, setFreight] = useState(
        !bolData?.id ? true : bolData && bolData?.billingType === 'Freight Collect' ? true : false);
    const [ra, setRANumber] = useState<string>(
        bolData && bolData?.ra ? bolData?.ra : ""
    );
    const [notes, setNotes] = useState(
        bolData && bolData?.notes ? bolData?.notes : ""
    );
    const [trailerNumber, setTrailerNumber] = useState(
        bolData && bolData?.trailer ? bolData?.trailer : ""
    );
    const [grossWeight, setGrossWeight] = useState(
        bolData && bolData?.grossWeight ? bolData?.grossWeight : ""
    );
    const [sealNumber, setSealNumber] = useState(
        bolData && bolData?.seal ? bolData?.seal : ""
    );
    const [pickedDate, setPickedDate] = useState(
        bolData && bolData?.pickedDate ? bolData?.pickedDate : ""
    );
    const isBOLClear = useSelector(
        (store: RootState) => store.invoiceReducer.isBOLDataClear
    );

    useEffect(() => {
        let updatedBillTo = customerId;
        if ('3rd Party' === billingType) {
            updatedBillTo = billingTo;
        }
        handleChange({
            bolId: bolNumForNew?.id,
            reclaimCenterId: reclaimCenterId,
            period: parseInt(fiscalYearPeriodsDataObj?.id),
            description,
            customerId: parseInt(customerId),
            pickUpFromId: parseInt(pickUpFromId),
            primaryAddressId: parseInt(primaryAddressId),
            billingTo: updatedBillTo,
            carrierId: parseInt(carrierId),
            ra,
            notes,
            trailerNumber,
            sealNumber,
            pickedDate,
            billingType,
            grossWeight,
            files: loadedAttachmentFiles.map((e) => e.file),
        });
    }, [
        bolNumber,
        reclaimCenterId,
        period,
        description,
        customerId,
        pickUpFromId,
        primaryAddressId,
        billingTo,
        carrierId,
        ra,
        notes,
        trailerNumber,
        sealNumber,
        pickedDate,
        billingType,
        loadedAttachmentFiles,
        grossWeight
    ]);
    useEffect(() => {
        if (isBOLClear) {
            dispatch(setWarehouseIdForInvoiceInBOL(''));
            dispatch(setCustomerIdForInvoiceInBOL(''));
            dispatch(setIsBOLDataClear(false));
            setDescription("");
            setCustomerName("");
            var ddlSelectPickup = document.getElementById(
                "ddlPickupFrom"
            ) as HTMLSelectElement;
            ddlSelectPickup.selectedIndex = 0;
            setPickupFrom("");
            var ddlSelectCust = document.getElementById(
                "ddlCustomer"
            ) as HTMLSelectElement;
            ddlSelectCust.selectedIndex = 0;
            setCustomer("");
            var ddlSelectOrigin = document.getElementById(
                "ddlOrigin"
            ) as HTMLSelectElement;
            ddlSelectOrigin.selectedIndex = 0;
            setReclaimCenter(bolNumForNew?.reclaimCenter?.id);
            var ddlSelectDeliver = document.getElementById(
                "ddlDeliverTo"
            ) as HTMLSelectElement;
            ddlSelectDeliver.selectedIndex = 0;
            setDeliverTo("");
            var ddlSelectCarrier = document.getElementById(
                "ddlCarrier"
            ) as HTMLSelectElement;
            ddlSelectCarrier.selectedIndex = 0;
            setCarrier("");
            setBillToCust(false);
            setThirdParty(false);
            setFreight(true);
            setRANumber("");
            setNotes("");
            setTrailerNumber("");
            setSealNumber("");
            setPickedDate("");
            setGrossWeight("");
        } else {
            dispatch(setWarehouseIdForInvoiceInBOL(pickUpFromId));
            dispatch(setCustomerIdForInvoiceInBOL(customerId));
        }
    });
    const fileInput = useRef(null);

    const attachmentsTabs = ["Internal", "Customer"];

    const date = "Oct 18, 2021, 8:07pm EST";
    const dummyText =
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Velit aliquet sed nibh laoreet urna. Sed augue gravida elementum at. Mi, mauris, ornare interdum interdum in massa nascetur. Tempus, tempor nec adipiscing donec mi. ";

    const handleBOLNumChange = (value: string) => {
        setBolNumber(value);
        dispatch(setIsInvoiceUpdated(true));
    };

    const handleRCChange = (value: string) => {
        setReclaimCenter(parseInt(value));
        dispatch(setIsInvoiceUpdated(true));
    };
    const handlePeriodChange = (value: string) => {
        setPeriod(fiscalYearPeriodsDataObj?.id);
        dispatch(setIsInvoiceUpdated(true));
    };
    const handleDescriptionChange = (value: any) => {
        setDescription(value);
        dispatch(setIsInvoiceUpdated(true));
    };
    const handleCustomerChange = (e: any) => {
        if (e.target.value !== '0') {
            const custDetls = JSON.parse(e.target.value);
            setSelectedCustDetails(custDetls);
            setCustomer(custDetls.id);
            setCustomerName(custDetls.customerName);
            dispatch(setCustomerIdForInvoiceInBOL(custDetls.id));
            setSelectedCust(custDetls.address);
            setShowDeliverAddrDiv(true);

        } else {
            setSelectedCust([]);
            setCustomerName('');
            setCustomer('');
        }
        dispatch(setIsInvoiceUpdated(true));
    };
    const handleRadiobutton = (value: string) => {
        setBillingType(value);
        dispatch(setIsInvoiceUpdated(true));
    };
    const handlePickupFromChange = (value: string) => {
        setPickupFrom(value);
        dispatch(setWarehouseIdForInvoiceInBOL(value));
        dispatch(setIsInvoiceUpdated(true));
    };
    const handleDeliverToChange = (value: string) => {
        setDeliverTo(value);
        dispatch(setIsInvoiceUpdated(true));
    };
    const handleBillingChange = (value: string) => {
        setBilling(value);
        dispatch(setIsInvoiceUpdated(true));
    };
    const handleCarrierChange = (value: string) => {
        setCarrier(value);
        dispatch(setIsInvoiceUpdated(true));
    };
    const handleRANumberChange = (value: string) => {
        setRANumber(value);
        dispatch(setIsInvoiceUpdated(true));
    };
    const handleNotesChange = (value: any) => {
        setNotes(value);
        dispatch(setIsInvoiceUpdated(true));
    };
    const handleTrailerNumberChange = (value: string) => {
        setTrailerNumber(value);
        dispatch(setIsInvoiceUpdated(true));
    };

    const handleSealNumberChange = (value: string) => {
        setSealNumber(value);
        dispatch(setIsInvoiceUpdated(true));
    };
    const handlePickedDateChange = (event: any) => {
        setPickedDate(event.value);
        dispatch(setIsInvoiceUpdated(true));
    };
    const handleGrossWeightChange = (value: string) => {
        setGrossWeight(value);
        dispatch(setIsInvoiceUpdated(true));
    };
    const handleAttachmentTabSelection = (params: any) => {
        setSelectedAttachmentTab(params.selectedIndex);
    };

    const handleFileInputChange = useCallback((e: any) => {
        const file = e.currentTarget.files[0];
        const fileReader = new FileReader();

        fileReader.onload = () => {
            const newFile: IFiles = {
                name: file.name,
                type: file.type,
                size: file.size,
                data: fileReader.result,
                file: file,
            };
            addLoadedFiles(newFile);
        };

        fileReader.onabort = () => {
        };

        fileReader.onerror = () => {
        };
        fileReader.readAsDataURL(file);
        e.preventDefault();
        e.stopPropagation();
    }, []);

    const addLoadedFiles = (file: IFiles) => {
        setLoadedAttachmentFiles((prev) => [...prev, file]);
    };

    const removeLoadedFiles = (fileName: string) => {
        const updatedLoadedFiles = loadedAttachmentFiles.filter(
            (file) => file.name !== fileName
        );
        setLoadedAttachmentFiles(updatedLoadedFiles);
    };

    const AttachmentModalContent = () => {
        return (
            <div>
                <ContentHeader value="Invoice Attachment"/>
                <Divider/>
                <TabComponent
                    heightAdjustMode="Auto"
                    selected={handleAttachmentTabSelection}
                >
                    <TabItemsDirective>
                        {attachmentsTabs.map((tab) => {
                            return <TabItemDirective key={tab} header={{text: tab}}/>;
                        })}
                    </TabItemsDirective>
                </TabComponent>
                <div className="attachment-modal-content">
                    <div className="attachment-modal-filedrop">
                        <p style={{textAlign: "center"}}>Files</p>
                        <div className="attachment-modal-filesection">
                            <input
                                type="file"
                                onDragOver={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                }}
                                onDragEnter={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                }}
                                name="invoice-attachment-input"
                                id="invoice-attachment-input"
                                ref={fileInput}
                                onDrop={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                }}
                                onChange={handleFileInputChange}
                            />
                            <p>Drag and Drop files here</p>

                            {fileInput && fileInput.current && (
                                // @ts-ignore
                                <ButtonComponent onClick={() => fileInput.current.click()}>
                                    Browse
                                </ButtonComponent>
                            )}
                        </div>
                        <div className="attachment-modal-filedroplist">
                            {loadedAttachmentFiles.map((file) => (
                                <ListObject
                                    text={file.name}
                                    key={file.name}
                                    bgColor="#bbbeee"
                                    color="#000"
                                    handleDelete={removeLoadedFiles}
                                />
                            ))}
                        </div>
                    </div>
                    <div className="attachment-modal-notes">
                        <NotesCard
                            date={date}
                            note={dummyText}
                            onTextAreaChange={() => {
                            }}
                        />
                        <NotesCard
                            date={date}
                            note={dummyText}
                            onTextAreaChange={() => {
                            }}
                        />
                    </div>
                </div>
            </div>
        );
    };

    const populateBillingFields = (info?: any) => {
        return (
            <>
                <div className="col-sm-4">
                    <span className="lblClass">Billing</span>
                </div>
                <div className="col-sm-8">
                    {billingType === '3rd Party' ?
                        <input
                            type="text"
                            className="form-control"
                            value={billingTo}
                            disabled={
                                info?.status?.status === "Cancelled" ||
                                info?.status?.status === "Picked" ||
                                info?.status?.status === "Released"
                            }
                            onChange={(e) => handleBillingChange(e.target.value)}
                        />
                        : <select
                            className="form-control"
                            onChange={(e: any) =>
                                handleBillingChange(e.target.value)
                            }
                            disabled={
                                info?.status?.status === "Cancelled" ||
                                info?.status?.status === "Picked" ||
                                info?.status?.status === "Released"
                            }
                        >
                            {!customername ?
                                <option value={info?.customer?.id}>{info?.billing}</option>
                                : <option value={customerId}>{customername}</option>
                            }
                        </select>}
                </div>
            </>
        )
    };
    return (
        <>
            {savedBolData && savedBolData[0] === "" ? (
                <>
                    <div className="input-container">
                        <div className="col-sm-12">
                            <div className="col-sm-4">
                                <div className="col-sm-4">
                                    <span className="lblClass">BOL #</span>
                                </div>
                                <div className="col-sm-8 bolHead">
                                    <span className="bolIDForUpdate"></span>
                                </div>
                            </div>
                            <div className="col-sm-4">
                                <div className="col-sm-4">
                  <span className="lblClass">
                    Origin<b className="requiredStar">*</b>
                  </span>
                                </div>
                                <div className="col-sm-8">
                                    {/* <CustomDropDown
              data={reclaimCentersData ? reclaimCentersData : []}
              valueField={"id"}
              textField={"reclaimcenterName"}
              onChange={(e: any) => handleRCChange(e.target.value)}
              selectedOption={bolNumForNew.reclaimCenter.reclaimcenterName}
            /> */}
                                    <select
                                        className="form-control"
                                        id="ddlOrigin"
                                        onChange={(e: any) => handleRCChange(e.target.value)}
                                    >
                                        <option value={bolNumForNew?.reclaimCenter?.id}>
                                            {bolNumForNew?.reclaimCenter?.reclaimcenterName}
                                        </option>
                                        {reclaimCentersData?.map((rc) => (
                                            <option value={rc.id}>{rc.reclaimcenterName}</option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                            <div className="col-sm-4">
                                <div className="col-sm-4">
                                    <span className="lblClass">Period</span>
                                </div>
                                <div className="col-sm-8">
                                    {/* <select
                    className="form-control"

                    onChange={(e: any) => handlePeriodChange(e.target.value)}
                  >
                    <option value={fiscalYearPeriodsDataObj?.id}>
                      {fiscalYearPeriodsDataObj?.calendarName +
                        "-" +
                        fiscalYearPeriodsDataObj?.week}
                    </option>
                  </select> */}
                                    <input type="text" disabled className="form-control"
                                           value={fiscalYearPeriodsDataObj?.calendarName + "-" + fiscalYearPeriodsDataObj?.week}/>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-12">
                            <div className="col-sm-4  mt-1rem">
                                <div className="col-sm-4">
                  <span className="lblClass">
                    Description<b className="requiredStar">*</b>
                  </span>
                                </div>
                                <div className="col-sm-8">
                                    <input
                                        type="text"
                                        className="form-control"
                                        value={description}
                                        onChange={(e: any) =>
                                            handleDescriptionChange(e.target.value)
                                        }
                                    />
                                    {/* <TextBoxComponent className="inputTextBol"
            value={description}
            input={(e: any) => handleDescriptionChange(e.event.target.value)}
          /> */}
                                </div>
                            </div>
                            <div className="col-sm-4 mt-1rem">
                                <div className="col-sm-4">
                                    <span className="lblClass">Customer</span>
                                </div>
                                <div className="col-sm-8">
                                    <select
                                        className="form-control"
                                        id="ddlCustomer"
                                        onChange={(e: any) => handleCustomerChange(e)}
                                    >
                                        <option value="0">Select</option>
                                        {customerData?.map((c) => (
                                            <option value={JSON.stringify(c)}>
                                                {c.customerName}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                            <div className="col-sm-4">
                                <div className="col-sm-11 billingTypeRadio">
                                    <div className="radio-group-invoice bol-radio-btns">
                    <span>
                      <RadioButtonComponent
                          label="Customer"
                          name="invoice-details-message"
                          value="Bill To Customer"
                          checked={billToCust}
                          change={(prams: any) => handleRadiobutton(prams.value)}
                      />
                    </span>
                                        <span>
                      <RadioButtonComponent
                          label="3rd Party"
                          name="invoice-details-message"
                          value="3rd Party"
                          checked={thirdParty}
                          change={(prams: any) => handleRadiobutton(prams.value)}
                      />
                    </span>
                                        <span className="freight-radio">
                      <RadioButtonComponent
                          label="Freight Collect"
                          name="invoice-details-message"
                          value="Freight Collect"
                          checked={freight}
                          change={(prams: any) => handleRadiobutton(prams.value)}
                      />
                    </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-12">
                            <div className="col-sm-4 ">
                                <div className="col-sm-4">
                  <span className="lblClass">
                    Pickup From<b className="requiredStar">*</b>
                  </span>
                                </div>
                                <div className="col-sm-8">
                                    <select
                                        className="form-control"
                                        id="ddlPickupFrom"
                                        onChange={(e: any) =>
                                            handlePickupFromChange(e.target.value)
                                        }
                                    >
                                        <option value="0">Select</option>
                                        {whData?.map((rc) => (
                                            <option value={rc.id}>{rc.warehouseName}</option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                            <div className="col-sm-4 ">
                                <div className="col-sm-4 ">
                                    <span className="lblClass">Deliver To</span>
                                </div>
                                <div className="col-sm-8">

                                    {selectedCust?.length === 0 ?
                                        <select
                                            className="form-control"
                                            id="ddlDeliverTo"
                                            onChange={(e: any) => handleDeliverToChange(e.target.value)}
                                        >
                                            <option value="">Select</option>
                                            {whData?.map((rc) => (
                                                <option value={rc.id}>{rc.warehouseName}</option>
                                            ))}
                                        </select>
                                        : <select
                                            className="form-control"
                                            id="ddlDeliverTo"
                                            onChange={(e: any) => handleDeliverToChange(e.target.value)}
                                        >
                                            <option value="">Select</option>
                                            {selectedCust?.map((c) => (
                                                <option value={c.id}>
                                                    {
                                                        c.city +
                                                        ", " +
                                                        c.state
                                                    }
                                                </option>
                                            ))}
                                        </select>}
                                </div>
                            </div>
                            <div className="col-sm-4">
                                {populateBillingFields()}
                            </div>
                        </div>

                        {/* <div className="input-control">
          <p>GL Account</p>
          <DropDownListComponent className="select-control" />
        </div> */}
                        <div className="col-sm-12">
                            <div className="col-sm-4 mt-1rem">
                                <div className="col-sm-4">
                                    <span className="lblClass">Carrier</span>
                                </div>
                                <div className="col-sm-8">
                                    <select
                                        className="form-control"
                                        id="ddlCarrier"
                                        onChange={(e: any) => handleCarrierChange(e.target.value)}
                                    >
                                        <option>Select</option>
                                        {carrierData?.map((c) => (
                                            <option value={c.id}>{c.carrierName}</option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                            <div className="col-sm-4 mt-1rem">
                                <div className="col-sm-4">
                                    <span className="lblClass">RA #</span>
                                </div>
                                <div className="col-sm-8">
                                    <input
                                        type="text"
                                        className="form-control"
                                        value={ra}
                                        onChange={(e) => handleRANumberChange(e.target.value)}
                                    />
                                    {/* <TextBoxComponent  className="inputTextBol"
              value={ra}
              type="number"
              input={(e) => handleRANumberChange(e.event.target.value)}
            /> */}
                                </div>
                            </div>
                            <div className="col-sm-4 mt-1rem">
                                <div className="col-sm-4">
                                    <span className="lblClass">Notes</span>
                                </div>
                                <div className="col-sm-8">
                                    <input
                                        type="text"
                                        className="form-control"
                                        value={notes}
                                        onChange={(e: any) => handleNotesChange(e.target.value)}
                                    />
                                    {/* <TextBoxComponent className="inputTextBol"
              value={notes}
              input={(e) => handleNotesChange(e.event.target.value)}
            /> */}
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-12">
                            <div className="col-sm-4 mt-1rem">
                                <div className="col-sm-4">
                                    <span className="lblClass">Trailer #</span>
                                </div>
                                <div className="col-sm-8">
                                    <input
                                        type="text"
                                        className="form-control"
                                        value={trailerNumber}
                                        onChange={(e) => handleTrailerNumberChange(e.target.value)}
                                    />
                                    {/* <TextBoxComponent className="inputTextBol"
                value={trailerNumber}
                type="number"
              input={(e) => handleTrailerNumberChange(e.event.target.value)}
            /> */}
                                </div>
                            </div>
                            <div className="col-sm-4 mt-1rem">
                                <div className="col-sm-4">
                                    <span className="lblClass">Seal #</span>
                                </div>
                                <div className="col-sm-8">
                                    <input
                                        type="text"
                                        className="form-control"
                                        value={sealNumber}
                                        onChange={(e) => handleSealNumberChange(e.target.value)}
                                    />
                                    {/* <TextBoxComponent className="inputTextBol"
              value={sealNumber}
              input={(e) => handleSealNumberChange(e.event.target.value)}
            /> */}
                                </div>
                            </div>
                            <div className="col-sm-4 mt-1rem">
                                <div className="col-sm-4">
                                    <span className="lblClass">Picked Date</span>
                                </div>
                                <div className="col-sm-8">

                                    <DatePickerComponent
                                        disabled={true}
                                        onChange={handlePickedDateChange}
                                        value={pickedDate}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-12">
                        <div className="col-sm-4">
                            <div className="col-sm-4">
                                <span className="lblClass">Gross Weight</span>
                            </div>
                            <div className="col-sm-8">
                                <input
                                    type="text"
                                    className="form-control"
                                    value={grossWeight}
                                    onChange={(e) => handleGrossWeightChange(e.target.value)}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="input-container">
                        <div className="col-sm-12">
                            {/* <div className="col-sm-4">
        <div className="col-sm-4">
        <span className="lblClass">Carrier<b className="requiredStar">*</b></span>
        </div>
        <div className="col-sm-8">
        <select className="form-control" onChange={(e: any) => handleCarrierChange(e.target.value)}>
            <option>Select</option>
            {carrierData?.map((c) =>
            <option value={c.id}>{c.carrierName}</option>
            )}
          </select>
        </div>
        </div> */}
                        </div>
                        <div className="col-sm-12 mt-1rem">
                            {/* <div className="col-sm-4">
        <div className="col-sm-4">
        <span className="lblClass">Attachments</span>
        </div>
        <div className="col-sm-8">
        <div
              className="invoice-attachment invoice-attachment-bol"
              onClick={() => setShowAttachmentModal(true)}
            >
              <Add width={30} height={30} />
            </div>
        </div>
        </div> */}
                        </div>
                    </div>

                    <LLModal
                        handleClose={() => setShowAttachmentModal(false)}
                        show={showAttachmentModal}
                        padding={20}
                    >
                        <AttachmentModalContent/>
                    </LLModal>
                </>
            ) : (
                <>
                    {savedBolData &&
                        savedBolData?.map((info) => (
                            <>
                                <div className="input-container">
                                    <div className="col-sm-12">
                                        <div className="col-sm-4">
                                            <div className="col-sm-4">
                                                <span className="lblClass">BOL #</span>
                                            </div>
                                            <div className="col-sm-8 bolHead">
                                                <span className="bolIDForUpdate">{info?.id}</span>
                                            </div>
                                        </div>
                                        <div className="col-sm-4">
                                            <div className="col-sm-4">
                        <span className="lblClass">
                          Origin
                            {info?.status?.status === "Cancelled" ||
                            info?.status?.status === "Picked" || info?.status?.status === "Released" ? (
                                ""
                            ) : (
                                <b className="requiredStar">*</b>
                            )}
                        </span>
                                            </div>
                                            <div className="col-sm-8">
                                                <select
                                                    className="form-control"
                                                    onChange={(e: any) => handleRCChange(e.target.value)}
                                                    disabled={
                                                        info?.status?.status === "Cancelled" ||
                                                        info?.status?.status === "Picked" ||
                                                        info?.status?.status === "Released"
                                                    }
                                                >
                                                    <option value={info?.reclaimCenter?.id}>
                                                        {info?.reclaimCenter?.reclaimcenterName}
                                                    </option>
                                                    {reclaimCentersData?.map((rc) => (
                                                        <option value={rc.id}>
                                                            {rc.reclaimcenterName}
                                                        </option>
                                                    ))}
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-sm-4">
                                            <div className="col-sm-4">
                                                <span className="lblClass">Period</span>
                                            </div>
                                            <div className="col-sm-8">
                                                <select
                                                    className="form-control"
                                                    onChange={(e: any) =>
                                                        handlePeriodChange(e.target.value)
                                                    }
                                                    disabled
                                                >
                                                    <option value={info?.period?.id}>
                                                        {info?.period?.fiscalYear?.calendarYear +
                                                            "-" +
                                                            info?.period?.week}
                                                    </option>
                                                    <option value={fiscalYearPeriodsDataObj?.id}>
                                                        {fiscalYearPeriodsDataObj?.calendarName +
                                                            "-" +
                                                            fiscalYearPeriodsDataObj?.week}
                                                    </option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-12">
                                        <div className="col-sm-4 mt-1rem">
                                            <div className="col-sm-4">
                        <span className="lblClass">
                          Description
                            {info?.status?.status === "Cancelled" ||
                            info?.status?.status === "Picked" || info?.status?.status === "Released" ? (
                                ""
                            ) : (
                                <b className="requiredStar">*</b>
                            )}
                        </span>
                                            </div>
                                            <div className="col-sm-8">
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    defaultValue={info?.description}
                                                    onChange={(e) =>
                                                        handleDescriptionChange(e.target.value)
                                                    }
                                                    disabled={
                                                        info?.status?.status === "Cancelled" ||
                                                        info?.status?.status === "Picked" || info?.status?.status === "Released"
                                                    }
                                                />
                                                {/* <TextBoxComponent className="form-control"
        value={info?.description}
        type="text"
        input={(e) => handleDescriptionChange(e.event.target.value)}
        disabled={info?.status?.status === 'Cancelled' || info?.status?.status === 'Picked'}
        /> */}
                                            </div>
                                        </div>
                                        <div className="col-sm-4  mt-1rem">
                                            <div className="col-sm-4">
                                                <span className="lblClass">Customer</span>
                                            </div>
                                            <div className="col-sm-8">
                                                <select
                                                    className="form-control"
                                                    onChange={(e: any) => handleCustomerChange(e)}
                                                    disabled={
                                                        info?.status?.status === "Cancelled" ||
                                                        info?.status?.status === "Picked" || info?.status?.status === "Released"
                                                    }
                                                >
                                                    {info?.customer?.id ? <option value={info?.customer?.id}>
                                                        {info?.customer?.customerName}
                                                    </option> : <option value="0">Select</option>}
                                                    {customerData?.map((c) => (
                                                        <option value={JSON.stringify(c)}>
                                                            {c.customerName}
                                                        </option>
                                                    ))}
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-sm-4">
                                            <div className="col-sm-11 billingTypeRadio">
                                                <div className="radio-group-invoice bol-radio-btns">
                          <span>
                            <RadioButtonComponent
                                label="Customer"
                                name="invoice-details-message"
                                value="Bill To Customer"
                                checked={billToCust}
                                change={(prams: any) =>
                                    handleRadiobutton(prams.value)
                                }
                                disabled={
                                    info?.status?.status === "Cancelled" ||
                                    info?.status?.status === "Picked" || info?.status?.status === "Released"
                                }
                            />
                          </span>
                                                    <span>
                            <RadioButtonComponent
                                label="3rd Party"
                                name="invoice-details-message"
                                value="3rd Party"
                                checked={thirdParty}
                                change={(prams: any) =>
                                    handleRadiobutton(prams.value)
                                }
                                disabled={
                                    info?.status?.status === "Cancelled" ||
                                    info?.status?.status === "Picked" || info?.status?.status === "Released"
                                }
                            />
                          </span>
                                                    <span className="freight-radio">
                            <RadioButtonComponent
                                label="Freight Collect"
                                name="invoice-details-message"
                                value="Freight Collect"
                                checked={freight}
                                change={(prams: any) =>
                                    handleRadiobutton(prams.value)
                                }
                                disabled={
                                    info?.status?.status === "Cancelled" ||
                                    info?.status?.status === "Picked" || info?.status?.status === "Released"
                                }
                            />
                          </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-12">
                                        <div className="col-sm-4 ">
                                            <div className="col-sm-4">
                        <span className="lblClass">
                          Pickup From
                            {info?.status?.status === "Cancelled" ||
                            info?.status?.status === "Picked" || info?.status?.status === "Released" ? (
                                ""
                            ) : (
                                <b className="requiredStar">*</b>
                            )}
                        </span>
                                            </div>
                                            <div className="col-sm-8">
                                                <select
                                                    className="form-control"
                                                    onChange={(e: any) =>
                                                        handlePickupFromChange(e.target.value)
                                                    }
                                                    disabled={
                                                        info?.status?.status === "Cancelled" ||
                                                        info?.status?.status === "Picked" || info?.status?.status === "Released"
                                                    }
                                                >
                                                    <option value={info?.pickUpFrom?.id}>
                                                        {info?.pickUpFrom?.warehouseName}
                                                    </option>
                                                    {whData?.map((rc) => (
                                                        <option value={rc.id}>{rc.warehouseName}</option>
                                                    ))}
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-sm-4 ">
                                            <div className="col-sm-4 ">
                                                <span className="lblClass">Deliver To</span>
                                            </div>
                                            <div className="col-sm-8">
                                                {info?.primaryAddress || selectedCust?.length !== 0 ?
                                                    <select
                                                        className="form-control"
                                                        onChange={(e: any) =>
                                                            handleDeliverToChange(e.target.value)
                                                        }
                                                        disabled={
                                                            info?.status?.status === "Cancelled" ||
                                                            info?.status?.status === "Picked" || info?.status?.status === "Released"
                                                        }
                                                    >
                                                        {info?.primaryAddress && !showDeliverAddrDiv ?
                                                            <option value={info?.primaryAddress?.id}>
                                                                {info?.primaryAddress?.city === undefined
                                                                    ? ""
                                                                    :
                                                                    info?.primaryAddress?.city +
                                                                    ", " +
                                                                    info?.primaryAddress?.state
                                                                }
                                                            </option>
                                                            : ''}
                                                        {!showDeliverAddrDiv ? '' : <option>Select</option>}
                                                        {selectedCust?.map((c) => (

                                                            <option value={c.id}>
                                                                {
                                                                    c.city +
                                                                    ", " +
                                                                    c.state
                                                                }
                                                            </option>
                                                        ))}
                                                    </select>
                                                    :
                                                    <select
                                                        className="form-control"
                                                        onChange={(e: any) =>
                                                            handleDeliverToChange(e.target.value)
                                                        }
                                                        disabled={
                                                            info?.status?.status === "Cancelled" ||
                                                            info?.status?.status === "Picked" || info?.status?.status === "Released"
                                                        }
                                                    >
                                                        <option value={info?.deliverTo?.id}>
                                                            {info?.deliverTo?.warehouseName}
                                                        </option>
                                                        {whData?.map((rc) => (
                                                            <option value={rc.id}>{rc.warehouseName}</option>
                                                        ))}
                                                    </select>}
                                            </div>
                                        </div>
                                        <div className="col-sm-4">
                                            {populateBillingFields(info)}
                                        </div>
                                    </div>
                                    <div className="col-sm-12">
                                        <div className="col-sm-4 mt-1rem">
                                            <div className="col-sm-4">
                                                <span className="lblClass">Carrier</span>
                                            </div>
                                            <div className="col-sm-8">
                                                <select
                                                    className="form-control"
                                                    onChange={(e: any) =>
                                                        handleCarrierChange(e.target.value)
                                                    }
                                                    disabled={
                                                        info?.status?.status === "Cancelled" ||
                                                        info?.status?.status === "Picked" || info?.status?.status === "Released"
                                                    }
                                                >
                                                    <option value={info?.carrier?.id}>
                                                        {info?.carrier?.carrierName}
                                                    </option>
                                                    {carrierData?.map((c) => (
                                                        <option value={c.id}>{c.carrierName}</option>
                                                    ))}
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-sm-4 mt-1rem">
                                            <div className="col-sm-4">
                                                <span className="lblClass">RA #</span>
                                            </div>
                                            <div className="col-sm-8">
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    defaultValue={info?.ra}
                                                    onChange={(e) => handleRANumberChange(e.target.value)}
                                                    disabled={
                                                        info?.status?.status === "Cancelled" ||
                                                        info?.status?.status === "Picked" || info?.status?.status === "Released"
                                                    }
                                                />
                                                {/* <TextBoxComponent className="inputTextBol"
        value={info?.ra}
        type="number"
        input={(e) => handleRANumberChange(e.event.target.value)}
        disabled={info?.status?.status === 'Cancelled' || info?.status?.status === 'Picked'}
        /> */}
                                            </div>
                                        </div>
                                        <div className="col-sm-4 mt-1rem">
                                            <div className="col-sm-4">
                                                <span className="lblClass">Notes</span>
                                            </div>
                                            <div className="col-sm-8">
                                                {/* <TextBoxComponent className="inputTextBol"
              value={info?.notes}
              input={(e) => handleNotesChange(e.event.target.value)}
              disabled={info?.status?.status === 'Cancelled' || info?.status?.status === 'Picked'}/> */}
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    defaultValue={info?.notes}
                                                    onChange={(e) => handleNotesChange(e.target.value)}
                                                    disabled={
                                                        info?.status?.status === "Cancelled" ||
                                                        info?.status?.status === "Picked" || info?.status?.status === "Released"
                                                    }
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-12">
                                        <div className="col-sm-4 mt-1rem">
                                            <div className="col-sm-4">
                                                <span className="lblClass">Trailer #</span>
                                            </div>
                                            <div className="col-sm-8">
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    defaultValue={info?.trailer}
                                                    onChange={(e) =>
                                                        handleTrailerNumberChange(e.target.value)
                                                    }
                                                    disabled={
                                                        info?.status?.status === "Cancelled" ||
                                                        info?.status?.status === "Picked" || info?.status?.status === "Released"
                                                    }
                                                />
                                                {/* <TextBoxComponent className="inputTextBol"
        value={info?.trailer}
        type="number"
        input={(e) => handleTrailerNumberChange(e.event.target.value)}
        disabled={info?.status?.status === 'Cancelled' || info?.status?.status === 'Picked'}
        /> */}
                                            </div>
                                        </div>
                                        <div className="col-sm-4 mt-1rem">
                                            <div className="col-sm-4">
                                                <span className="lblClass">Seal #</span>
                                            </div>
                                            <div className="col-sm-8">
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    defaultValue={info?.seal}
                                                    onChange={(e) =>
                                                        handleSealNumberChange(e.target.value)
                                                    }
                                                    disabled={
                                                        info?.status?.status === "Cancelled" ||
                                                        info?.status?.status === "Picked" || info?.status?.status === "Released"
                                                    }
                                                />
                                                {/* <TextBoxComponent className="inputTextBol"
        value={info?.seal}
        type="number"
        input={(e) => handleSealNumberChange(e.event.target.value)}
        disabled={info?.status?.status === 'Cancelled' || info?.status?.status === 'Picked'}
        /> */}
                                            </div>
                                        </div>
                                        <div className="col-sm-4 mt-1rem">
                                            <div className="col-sm-4">
                                                <span className="lblClass">Picked Date</span>
                                            </div>
                                            <div className="col-sm-8">
                                                {/* <DatePickerComponent
                          className="datePicked"
                          onChange={handlePickedDateChange}
                          value={info?.pickedDate}
                          disabled={true}
                        /> */}
                                                <input
                                                    className="form-control"
                                                    type="text"
                                                    value={info?.pickedDate === null ? info?.pickedDate : moment(info?.pickedDate).format('MMM DD, YYYY')}
                                                    disabled
                                                />
                                                {/* disabled={info?.status?.status === 'Cancelled' || info?.status?.status === 'Picked'} */}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-12">
                                        <div className="col-sm-4 mt-1rem">
                                            <div className="col-sm-4">
                                                <span className="lblClass">Gross Weight</span>
                                            </div>
                                            <div className="col-sm-8">
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    defaultValue={info?.grossWeight}
                                                    onChange={(e) =>
                                                        handleGrossWeightChange(e.target.value)
                                                    }
                                                    disabled={
                                                        info?.status?.status === "Cancelled" ||
                                                        info?.status?.status === "Picked" ||
                                                        info?.status?.status === "Released"
                                                    }
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="input-container">
                                    <div className="col-sm-12">
                                        {/* <div className="col-sm-4">
    <div className="col-sm-4">
       <span className="lblClass">Carrier<b className="requiredStar">*</b></span>
      </div>
      <div className="col-sm-8">
      <select className="form-control" onChange={(e: any) => handleCarrierChange(e.target.value)} disabled={info?.status?.status === 'Cancelled' || info?.status?.status === 'Picked'}>
           <option value={info?.carrier?.id}>{info?.carrier?.carrierName}</option>
           {carrierData?.map((c) =>
           <option value={c.id}>{c.carrierName}</option>
           )}
         </select>
      </div>
    </div> */}
                                    </div>
                                    <br/>
                                    <div className="col-sm-12 mt-2rem">
                                        {/* <div className="col-sm-4">
    <div className="col-sm-4">
       <span className="lblClass">Attachments</span>
      </div>
      <div className="col-sm-8">
      <div
             className="invoice-attachment-bol invoice-attachment"
             onClick={() => setShowAttachmentModal(true)}
           >
             <Add width={30} height={30} />
           </div>
      </div>
    </div> */}
                                    </div>
                                </div>
                            </>
                        ))}
                </>
            )}
        </>
    );
};

export default useInputCollectionBol;
