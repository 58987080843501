import { environment } from "../../environment";
import moment from 'moment';


const baseURL = environment.react_app_url;
const clientContext = environment.react_app_client_context;

const reportsURL = environment.react_bold_reports_url;

/**
 * Modal Mapping and Data binding
 */
export const generateGridData = (tabName: string, data: any) => {
  // console.log(data);
  switch (tabName) {
    case "Saved Reports": {
      return data.map((row: any) => ({
        id: isEmpty(row?.id),
        name: isEmpty(row?.name),
        permissions: isEmpty(row?.permissions),
        apiUrl: isEmpty(row?.apiUrl),
        reportParameters: isEmpty(row?.reportParameters),
        // lastRun: isEmpty(formatDate(row?.lastRun)),
        lastRun: isEmpty(moment(row?.lastRun).format('MMM DD, YYYY')),
        type: isEmpty(row?.type),
        tabName: "Saved Reports",
        status: isEmpty(row?.status),
        wsData: isEmpty(row?.wsData),
        multiSelect: isEmpty(row?.multiSelect),
      }));
    }

    default:
      return [];
  }
};

const formatDate = (date: any) => {
  const allMonth = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sept",
    "Oct",
    "Nov",
    "Dec",
  ];
  const dateSplit = date.split("T");
  const d = dateSplit[0]?.split("-");
  const monthNum = d[1].split("");
  // console.log(monthNum)
  const finalMonth = monthNum[1] - 1;
  const month = allMonth[finalMonth];
  const fullDate = month + " " + d[2] + ", " + d[0];
  return fullDate;
};

const isEmpty = (val: any) => {
  if (val !== undefined && val !== null && val !== "") {
    return val;
  } else {
    return "";
  }
};

export const getReportsEndPoint = (tabName: any) => {
  switch (tabName) {
    case "Saved Reports": {
      return baseURL + `${clientContext}/v1/reports/reportSettings`;
    }
    default:
      return "";
  }
};

export const getDynamicValues = (key: any) => {
  switch (key) {
    case "reclaimCenter": {
      return baseURL + `${clientContext}/v1/masterdata/reclaimcenters/list`;
    }
    case "location": {
      return baseURL + `${clientContext}/v1/masterdata/reclaimcenters/list`;
    }
    case "center": {
      return baseURL + `${clientContext}/v1/masterdata/reclaimcenters/list`;
    }
    case "locationId": {
      return baseURL + `${clientContext}/v1/masterdata/reclaimcenters/list`;
    }
    case "client": {
      return baseURL + `${clientContext}/v1/masterdata/clientgroup/list`;
    }
    case "sortCode": {
      return baseURL + `${clientContext}/v1/masterdata/debitreasoncode/list`;
    }
    case "commodityCode": {
      return baseURL + `${clientContext}/v1/masterdata/commoditytype/list`;
    }
    case "customerNumber": {
      return baseURL + `${clientContext}/v1/masterdata/customer/list`;
    }
    case "salvageCustomerNumber": {
      return baseURL + `${clientContext}/v1/masterdata/customer/list/Salvage`;
    }
    case "customerGroup": {
      return baseURL + `${clientContext}/v1/masterdata/clientgroup/list`;
    }
    case "soldStatus": {
      return baseURL + `${clientContext}/v1/masterdata/arinvoicestatus/list`;
    }
    case "closedInvoiceStatus": {
      return baseURL + `${clientContext}/v1/masterdata/arinvoicestatus/closedstatuslist`;
    }
    case "status": {
      return baseURL + `${clientContext}/v1/masterdata/arinvoicestatus/list`;
    }
    case "warehouse": {
      return baseURL + `${clientContext}/v1/masterdata/disposition/list`;
    }
    case "warehouseLocation": {
      return baseURL + `${clientContext}/v1/masterdata/disposition/list/upcdefault/warehouselocations`;
    }
    case "scanflag": {
      return baseURL + `${clientContext}/v1/reports/scanmodelist`;
    }
    case "period": {
      return baseURL + `${clientContext}/v1/reports/perioddropdown`;
    }
    case "scanMode": {
      return baseURL + `${clientContext}/v1/reports/scanmodelist`;
    }
    case "scannedBy": {
      return baseURL + `${clientContext}/v1/reports/storescanuserslist`;
    }
    case "disposition": {
      return baseURL + `${clientContext}/v1/reports/disposition/list`
    }
    case "scannedByInternal": {
      return baseURL + `${clientContext}/v1/reports/scanuserslist`
    }
    case 'finalDisposition' : {
      return baseURL + `${clientContext}/v1/scan/store/warehouselocation/list`
    }
    // No dropdown data for scannedBy,
    default:
      return "";
  }
};

export const getUPCWs = (params: any) => {
  return ( baseURL + `${clientContext}/v1/masterdata/itemcatalogue/list/pagination?upc=${params}&size=50` );
};

export const getVendorIDWs = () => {
  return ( baseURL + `${clientContext}/v1/masterdata/vendor/list/filter` );
};

export const getScanDateWS = () => {
  return ( baseURL + `${clientContext}/v1/reports/getDatesBasedOnPeriods` );
}


export const getStoreWsUpdate = (params: any) => {
  return (
    baseURL +
    `${clientContext}/v1/masterdata/store/list/pagination?page=0&size=50&storename=${params}`
  );
};

export const getBoldReportsAccessTokenByUSer = () => {
  return ( baseURL +
  `${clientContext}/v1/reports/boldToken`);
}

export const updateReportsEndPoint = (tabName: any) => {
  switch (tabName) {
    case "Settings": {
      return baseURL + `${clientContext}/v1/reports/updateReportSettings`;
    }
    case "downloadFile": {
      return baseURL + `${clientContext}/`;
    }
    default:
      return "";
  }
};

export const savedReportHeaders = [
  // {
  //   field: "id",
  //   header: "REPORT ID",
  //   width: "150",
  //   textAlign: "Right",
  //   dataType: "number",
  // },
  { field: "name", header: "NAME", width: "250", textAlign: "Left" },
  {
    field: "permissions",
    header: "Permissions",
    width: "150",
    textAlign: "Left",
  },
  { field: "lastRun", header: "Last Run", width: "120", textAlign: "center" },
];

export const reportParametersMapping = {
  'invoice summary report': ["InvoiceNumber", "InvoiceStatus", "StartDate", "EndDate"],
  'box bin pallet report': ["Location", "PalletId", "BoxId", "BinId"],
  'closed invoice report': ["Period", "InvoiceStatus", "CUSTOMER_NUMBER", "RECLAIMCENTER_NAME", "ScanDates"],
  'invoice detail lookup report': ["InvoiceNumber", "Period", "Client", "InvoiceStatus", "Location"],
  'invoice by period': ["Period", "InvoiceStatus", "CUSTOMER_NUMBER", "RECLAIMCENTER_NAME"],
  'pallets not invoiced report': ["StartDate", "EndDate"],
  'pwcc shrink report': ["StartDate","EndDate","CustomerNumber"]
}

export const getDynamicBoldReportUrl = (key: any) => {
  // debugger
  switch (key) {
    case "Invoice Summary Report": {
      return  `Invoice Summary Report`;
    }
    case "Item Location Lookup": {
      return `Item Location Look Up Report`;
    }
    case "Audit Comparison Outbound": {
      return `Audit comparison outbound Report`;
    }
    case "Audit Comparison Inbound": {
      return `Audit comparison inbound Report`;
    }
    case "Hazardous Items": {
      return `Hazardous Items`;
    }
    case "Box Bin Pallet Lookup": {
      return `Box Bin Pallet report`;
    }
    case "Closed Invoice Report": {
      return `Closed invoice report`;
    }
    case "Invoice Detail Lookup": {
      return `Invoice Detail Lookup Report`;
    }
    case "Invoices By Period": {
      return `Invoice by period`;
    }
    case "No Credit Scans Report": {
      return `No Credit Scan Report`;
    }
    case "Not Founds": {
      return `NOT FOUNDS Report`;
    }
    case "PWCC Bale Report": {
      return `PWCC Bale Report`;
    }
    case "PWCC Shrink Report": {
      return `PWCC Shrink Report`;
    }
    case "Pallets Not Invoiced": {
      return `Pallets Not Invoiced Report`;
    }
    case "Salvage Drilldown": {
      return `Salvage Drill Down Report`;
    }
    case "Salvage Summary": {
      return `Salvage Summary Report`;
    }
    case "Scan Lookup": {
      return `Scan Lookup`;
    }
    case "Scan Productivity": {
      return `Scan Lookup`;
    }
    case "Scan Tracker": {
      return `Scan Tracker Report`;
    }
    case "Store Credit Report": {
      return `Store Credit Scan`;
    }
    case "Store Scan Lookup": {
      return `Store Scan Report`;
    }



    default:
      return "";
  }
};
