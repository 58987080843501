import { FC, useEffect, useState } from 'react';
import './ContactsDetailsPanel.scss';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import {
    getAllMasterData,
    getEndPoint,
    updateMasterDataEndPoint,
} from '../contactTabs/utils';
import axios from 'axios';
import updateIcon from '../../assets/updateForTabs.png';
import cancelIcon from '../../assets/cancelIcon.png';
import plusIcon from '../../assets/IconPlus.png';
import closeIcon from '../../assets/iconClose.png';
import closeIconCancel from '../../icons/common/cancel.png';
import loader from '../../assets/loader.gif';
import { InlineInputEdit } from 'react-inline-input-edit';
import {
    setCurrentContact,
    setCustomerTypeData,
    setDepartmentMasterData,
    setGLAccountMasterData,
    setUpdatedStoresORVendorData,
    setWHLocCustomData,
    setWHLocUPCDefaultData,
} from '../contactTabs/contactReducer';
import { ContactType } from '../contactTabs/constants';
import notify from '../../services/toasterService';
import { environment } from '../../environment';
import { setRowData, setStoresORVendorsRowData } from '../grid/gridReducer';
import { setGlobalScanSettingsList } from '../settings/settingsReducers';
import toggleIconOff from "../../icons/ToggleIconOff.png";
import toggleIconOn from "../../icons/ToggleIconOn.png";

interface Props {
    hideSpinner?: boolean;
}

const StoreContactView: FC<Props> = (props: Props) => {
    const [rowInfo, setRowInfo] = useState<any[]>([]);
    const [warehouseInfo, setWarehouseInfo] = useState(false);
    // const [finalAddedWHS, setFinalAddedWHS] = useState<any[]>([]);
    // const [showAddWarehouseDropdown, setShowAddWarehouseDropdown] = useState(false);
    const [showAddCustomerTypeDropdown, setShowAddCustomerTypeDropdown] =
        useState(false);
    const [showAddVendorTypeDropdown, setShowAddVendorTypeDropdown] =
        useState(false);
    const [showAddStoresDropdown, setShowAddStoresDropdown] = useState(false);
    const [showAddGLAccDropdown, setShowAddGLAccDropdown] = useState(false);
    const [isFttEnabled, setIsFttEnabled] = useState(false);
    const [showAddDeptDropdown, setShowAddDeptDropdown] = useState(false);
    const [showUpdateCancelIcons, setShowUpdateCancelIcons] = useState(false);
    const [loaderStatus, setLoaderStatus] = useState(true);
    const [pricingTypeSymbal, setPricingTypeSymbal] = useState('');
    const dispatch = useDispatch();
    const [mobileTracking, setMobileTracking] = useState(false);
    const [mobileScanEnabled, setMobileScanEnabled] = useState(false);
    const [selCustomerTypeObj, setSelCustomerTypeObj] = useState<any[]>([]);
    const [errors, setErrors] = useState({
        nameError: '',
        webURLError: '',
    });
    let nameError = '';
    let webURLError = '';

    const setTabHeading = useSelector(
        (state: RootState) => state.contactReducer?.tabHeading
    );
    const selRowData = useSelector(
        (state: RootState) => state.gridReducer?.selectedRowData
    );
    const setRowId = useSelector(
        (state: RootState) => state.gridReducer?.selectedRowDataId
    );
    // const warehouseData = useSelector(
    //     (state: RootState) => state.contactReducer.warehouses
    //   );
    // const storeData = useSelector(
    //     (state: RootState) => state.contactReducer.stores
    // );
    const customerTypeData = useSelector(
        (state: RootState) => state.contactReducer?.customerTypeData
    );
    const superGroupData = useSelector(
        (state: RootState) => state.contactReducer?.superGroupData
    );
    const clientGroupData = useSelector(
        (state: RootState) => state.contactReducer?.clientGroupData
    );
    const reasonCodeData = useSelector(
        (state: RootState) => state.contactReducer?.reasonCodeData
    );
    const reclaimCentersData = useSelector(
        (state: RootState) => state.contactReducer?.reclaimCenters
    );
    const paymentTermsData = useSelector(
        (state: RootState) => state.contactReducer?.paymentTermsData
    );
    let glAccData = useSelector(
        (state: RootState) => state.contactReducer?.glAccountMasterData
    );
    const pricingTypeData = useSelector(
        (state: RootState) => state.contactReducer?.pricingTypeMasterData
    );
    const palletNameData = useSelector(
        (state: RootState) => state.contactReducer?.palletNameMasterData
    );
    const sacnGroupData = useSelector(
        (state: RootState) => state.contactReducer?.scanGroupMasterData
    );
    const deptData = useSelector(
        (state: RootState) => state.contactReducer?.departmentsMasterData
    );
    const dispositionInfo = useSelector(
        (state: RootState) => state.contactReducer?.dispositionData
    );
    const tabNameForWHLoc = useSelector(
        (state: RootState) => state.contactReducer?.tabNameWHLoc
    );
    const billingModeData = useSelector(
        (state: RootState) => state.contactReducer?.billingModeData
    );
    const upcDefaultData = useSelector(
        (state: RootState) => state.contactReducer?.WHUPCDefaultData
    );
    const selStoreAndVendorRowData = useSelector(
        (state: RootState) => state.gridReducer?.selectedStoresOrVendorsRowData
    );
    let pageNo = environment.react_app_page; //process.env.REACT_APP_PAGE
    let pageSize = environment.react_app_pagesize; // process.env.REACT_APP_PAGESIZE;
    useEffect(() => {
        console.log(selStoreAndVendorRowData);
        if (
            setTabHeading.payload === ContactType.STORES ||
            setTabHeading.payload === ContactType.VENDORS
        ) {
            if (
                selStoreAndVendorRowData &&
                selStoreAndVendorRowData?.length > 0
            ) {
                setRowInfo(selStoreAndVendorRowData);
            }
        } else {
            if (selRowData && selRowData?.length > 0) {
                setRowInfo(selRowData);
                if (
                    selRowData[0].pricingType === 'Fixed price' ||
                    selRowData[0].pricingType === 'User Set'
                ) {
                    setPricingTypeSymbal('$');
                } else {
                    setPricingTypeSymbal('%');
                }
            }
        }
        setLoaderStatus(false);
        console.log(rowInfo);
    }, [selRowData, selStoreAndVendorRowData, setRowId]);

    //   const _handleFocusStoreStoreNumber = (text: any) => {
    //     setRowInfo([{...rowInfo[0], storeNumber: text}])
    //   };
    //   const _handleFocusOutStoreStoreNumber = (text: any) => {
    //     setRowInfo([{...rowInfo[0], storeNumber: text}])
    //   };
    //   const _handleFocusStoreStoreType = (text: any) => {
    //     setRowInfo([{...rowInfo[0], storeType: text}])
    //   };
    //   const _handleFocusOutStorestoreType = (text: any) => {
    //     setRowInfo([{...rowInfo[0], storeType: text}])
    //   };
    //   const handleCompanyNameRemove = (e:any)=>{
    //     setRowInfo([{...rowInfo[0], companyCode: e}])
    //   }

    const handleChangePaymetTerms = (e: any) => {
        setRowInfo([{ ...rowInfo[0], paymentTerms: e.target.value }]);
    };
    const handleSwitch = (e: any) => {
        setShowUpdateCancelIcons(true);
        setRowInfo([{ ...rowInfo[0], active: e.target.checked }]);
    };
    //  const handleAddWarehouse = () =>{
    //     setShowAddWarehouseDropdown(true)
    //  }
    const handleViewCustomerTypeDDL = () => {
        setShowUpdateCancelIcons(true);
        setShowAddCustomerTypeDropdown(true);
    };
    const handleCloseCustomerTypeDDL = () => {
        setShowUpdateCancelIcons(true);
        setShowAddCustomerTypeDropdown(false);
    };

    const handleViewVendorTypeDDL = () => {
        setShowUpdateCancelIcons(true);
        setShowAddVendorTypeDropdown(true);
    };
    const handleCloseVendorTypeDDL = () => {
        setShowUpdateCancelIcons(true);
        setShowAddVendorTypeDropdown(false);
    };
    const handleAddVendorType = (e: any) => {
        const addCustomerTypeItem = e.target.value;
        const rowInfoCopy = JSON.parse(JSON.stringify(rowInfo));
        if (!rowInfoCopy[0].vendorType.includes(addCustomerTypeItem)) {
            rowInfoCopy[0].vendorType.push(addCustomerTypeItem);
            setRowInfo(rowInfoCopy);
            var ddlSelectCustType = document.getElementById(
                'ddlCustomerType'
            ) as HTMLSelectElement;
            if (ddlSelectCustType) {
                ddlSelectCustType.selectedIndex = 0;
            }
        }
        var ddlSelectCustType = document.getElementById(
            'ddlCustomerType'
        ) as HTMLSelectElement;
        if (ddlSelectCustType) {
            ddlSelectCustType.selectedIndex = 0;
        }
    };
    const handleRemoveVendorType = (custType: any) => {
        setShowUpdateCancelIcons(true);
        setShowUpdateCancelIcons(true);
        let linkedWarehouseData = [...rowInfo];
        let updatedStoreData = linkedWarehouseData.map((item) => ({
            ...item,
            vendorType: item.vendorType.filter(
                (itemData: any) => itemData !== custType
            ),
        }));
        setRowInfo(updatedStoreData);
    };

    const handleAddCustomerType = (e: any) => {
        const addCustomerTypeItem = JSON.parse(e.target.value);
        console.log(addCustomerTypeItem);
        selCustomerTypeObj.push(addCustomerTypeItem);
        //customerType
        const rowInfoCopy = JSON.parse(JSON.stringify(rowInfo));
        //  if (!rowInfoCopy[0].customerType.includes(addCustomerTypeItem)) {
        rowInfoCopy[0].customerType.push(addCustomerTypeItem.customerType);
        setRowInfo(rowInfoCopy);
        var ddlSelectCustType = document.getElementById(
            'ddlCustomerType'
        ) as HTMLSelectElement;
        if (ddlSelectCustType) {
            ddlSelectCustType.selectedIndex = 0;
        }
        //  }
        var ddlSelectCustType = document.getElementById(
            'ddlCustomerType'
        ) as HTMLSelectElement;
        if (ddlSelectCustType) {
            ddlSelectCustType.selectedIndex = 0;
        }
        let selIndex = customerTypeData.findIndex((ele) => {
            if (ele.id === addCustomerTypeItem.id) {
                return true;
            }
        });
        // const glAccDataCopy = glAccData
        const customerTypeDataCopy = [...customerTypeData];
        customerTypeDataCopy?.splice(selIndex, 1);
        dispatch(setCustomerTypeData([]));
        dispatch(setCustomerTypeData(customerTypeDataCopy));
    };
    /* customerType Change */
    const handleRemoveCustomerType = (custType: any) => {
        setShowUpdateCancelIcons(true);
        setShowUpdateCancelIcons(true);
        let linkedWarehouseData = [...rowInfo];
        let updatedStoreData = linkedWarehouseData.map((item) => ({
            ...item,
            customerType: item.customerType.filter(
                (itemData: any) => itemData !== custType
            ),
        }));
        setRowInfo(updatedStoreData);
        console.log(selCustomerTypeObj);
        // let selIndex = customerTypeData.findIndex(ele=>{
        //   if (ele.id === glAccObj.id) {
        //     return true
        //   }
        // })
        selCustomerTypeObj?.map((item, idx) => {
            if (item.customerType === custType) {
                const customerTypeDataCopy = [...customerTypeData];
                customerTypeDataCopy?.splice(0, 0, item);
                dispatch(setCustomerTypeData([]));
                dispatch(setCustomerTypeData(customerTypeDataCopy));
                selCustomerTypeObj.splice(idx, 1);
            }
        });
    };
    //  const handleCloseWarehouseDropdown = () =>{
    //     setShowAddWarehouseDropdown(false)
    //  }

    const handleAdStoreInWH = () => {
        setShowUpdateCancelIcons(true);
        setShowAddStoresDropdown(true);
    };
    const handleCloseStoreDropdown = () => {
        setShowAddStoresDropdown(false);
    };
    const handleSwitchVendorHold = (e: any) => {
        setShowUpdateCancelIcons(true);
        setRowInfo([{ ...rowInfo[0], hold: e.target.checked }]);
    };
    /* Store update starts */

    const handleChangeStoreSuperGroup = (e: any) => {
        setShowUpdateCancelIcons(true);
        setRowInfo([{ ...rowInfo[0], superGroup: e.target.value }]);
    };
    const handleSwitchStoreSalvager = (e: any) => {
        setShowUpdateCancelIcons(true);
        setRowInfo([{ ...rowInfo[0], salvager: e.target.checked }]);
    };
    const handleChangeStoreClientGroup = (e: any) => {
        const info = JSON.parse(e.target.value);
        setShowUpdateCancelIcons(true);
        setRowInfo([
            {
                ...rowInfo[0],
                reclaimCustomerGroupName: info.clientGroupName,
                reclaimCustomerGroupNumber: info.clientGroupNumber,
            },
        ]);
    };
    const handleChangeStoreReasonCode = (e: any) => {
        setShowUpdateCancelIcons(true);
        setRowInfo([{ ...rowInfo[0], reasonCode: e.target.value }]);
    };
    const handleChangePricingType = (e: any) => {
        setShowUpdateCancelIcons(true);
        if (
            e.target.value === 'Fixed price' ||
            e.target.value === 'User Set' ||
            e.target.value === '0'
        ) {
            setPricingTypeSymbal('$');
        } else {
            setPricingTypeSymbal('%');
        }
        setRowInfo([{ ...rowInfo[0], pricingType: e.target.value }]);
    };
    const _handleFocusEditCommodityCode = (text: any) => {
        setShowUpdateCancelIcons(true);
        setRowInfo([{ ...rowInfo[0], code: text }]);
    };
    const _handleFocusOutEditCommodityCode = (text: any) => {
        setShowUpdateCancelIcons(true);
        setRowInfo([{ ...rowInfo[0], code: text }]);
    };
    const _handleFocusEditCommodityPrice = (text: any) => {
        setShowUpdateCancelIcons(true);
        setRowInfo([{ ...rowInfo[0], price: text }]);
    };
    const _handleFocusOutEditCommodityPrice = (text: any) => {
        setShowUpdateCancelIcons(true);
        setRowInfo([{ ...rowInfo[0], price: text }]);
    };
    /* const handleEditCommodityPrice= (e: any) =>{
        setShowUpdateCancelIcons(true);
        setRowInfo([{...rowInfo[0], price: e.target.value}])
     }
 */
    const handleEditCommodityPrice = (e: any) => {
        if (e.target.value.includes('.') === true) {
            if (e.target.value.split('.')[1].length > 2) {
                setRowInfo([
                    {
                        ...rowInfo[0],
                        price: parseFloat(e.target.value).toFixed(2),
                    },
                ]);

                // setPrice(parseFloat(e.target.value).toFixed(2))
            } else {
                setRowInfo([{ ...rowInfo[0], price: e.target.value }]);
                //setPrice(e.target.value)
            }
        } else {
            setRowInfo([{ ...rowInfo[0], price: e.target.value }]);

            //setPrice(e.target.value)
        }
        setShowUpdateCancelIcons(true);

        // setRowInfo([{...rowInfo[0], price: e.target.value}])
    };

    const _handleFocusEditCommodityDesc = (text: any) => {
        setShowUpdateCancelIcons(true);
        setRowInfo([{ ...rowInfo[0], description: text }]);
    };
    const _handleFocusOutEditCommodityDesc = (text: any) => {
        setShowUpdateCancelIcons(true);
        setRowInfo([{ ...rowInfo[0], description: text }]);
    };
    const _handleFocusAltPhone = (text: any) => {
        setRowInfo([{ ...rowInfo[0], alternatePhoneNumber: text }]);
    };
    const _handleFocusOutAltPhone = (text: any) => {
        setRowInfo([{ ...rowInfo[0], alternatePhoneNumber: text }]);
    };
    const _handleFocusEditCompanyCodeName = (e: any) => {
        setShowUpdateCancelIcons(true);
        setRowInfo([{ ...rowInfo[0], companyName: e.target.value }]);
    };
    const _handleFocusOutEditCompanyCodeName = (text: any) => {
        setShowUpdateCancelIcons(true);
        setRowInfo([{ ...rowInfo[0], companyName: text }]);
    };
    const _handleFocusEditWebURL = (e: any) => {
        setShowUpdateCancelIcons(false);
        //setRowInfo([{...rowInfo[0], webUrl: text}])
        var res =
            '^(https?:\\/\\/)?' +
            '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' +
            '((\\d{1,3}\\.){3}\\d{1,3}))' +
            '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' +
            '(\\?[;&a-z\\d%_.~+=-]*)?';
        if (e.target.value != '') {
            if (e.target.value.match(res)) {
                setRowInfo([{ ...rowInfo[0], webUrl: e.target.value }]);
                setShowUpdateCancelIcons(true);
                webURLError = '';
                setErrors({ nameError, webURLError });
                return true;
            } else {
                webURLError = 'Web URL is invalid';
            }
        }
        if (webURLError) {
            setErrors({ nameError, webURLError });
            return false;
        }
    };
    const _handleFocusOutEditWebURL = (text: any) => {
        var res =
            '^(https?:\\/\\/)?' +
            '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' +
            '((\\d{1,3}\\.){3}\\d{1,3}))' +
            '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' +
            '(\\?[;&a-z\\d%_.~+=-]*)?';
        if (text.match(res)) {
            setRowInfo([{ ...rowInfo[0], webUrl: text }]);
            setShowUpdateCancelIcons(true);
            webURLError = '';
            setErrors({ nameError, webURLError });
            return true;
        } else {
            webURLError = 'Web URL is invalid';
        }
        if (webURLError) {
            setErrors({ nameError, webURLError });
            return false;
        }
    };

    const _handleFocusEditRCName = (e: any) => {
        setShowUpdateCancelIcons(true);
        setRowInfo([{ ...rowInfo[0], reclaimcenterName: e.target.value }]);
    };
    const _handleHourlyScanGoal = (e: any) => {
        setShowUpdateCancelIcons(true);
        setRowInfo([{ ...rowInfo[0], hourlyScanGoal: e.target.value }]);
    };
    const _handleFocusOutEditRCName = (text: any) => {
        setShowUpdateCancelIcons(true);
        setRowInfo([{ ...rowInfo[0], reclaimcenterName: text }]);
    };

    const _handleFocusEditWHName = (e: any) => {
        setShowUpdateCancelIcons(true);
        setRowInfo([{ ...rowInfo[0], warehouseName: e.target.value }]);
    };
    const _handleFocusEditCarrierName = (e: any) => {
        setShowUpdateCancelIcons(true);
        setRowInfo([{ ...rowInfo[0], carrierName: e.target.value }]);
    };
    const _handleFocusOutEditWHName = (text: any) => {
        setShowUpdateCancelIcons(true);
        setRowInfo([{ ...rowInfo[0], warehouseName: text }]);
    };
    const handleChangeCustomerPrepaid = (e: any) => {
        setShowUpdateCancelIcons(true);
        setRowInfo([{ ...rowInfo[0], prepaid: e.target.checked }]);
    };
    const handleChangeCustomerBillingMode = (e: any) => {
        setShowUpdateCancelIcons(true);
        setRowInfo([{ ...rowInfo[0], billingMode: e.target.value }]);
    };

    const handleSwitchStoreUnderReview = (e: any) => {
        setShowUpdateCancelIcons(true);
        setRowInfo([{ ...rowInfo[0], underReview: e.target.checked }]);
    };
    const handleFTTEnable = (e: any) => {
        const rowObject = {...rowInfo[0]};
        Object.assign(rowObject,{
            fttenabled : e
          });
       setShowUpdateCancelIcons(true);
       setRowInfo([{ ...rowObject}]);      
    };
    useEffect(() => {
        if(rowInfo && rowInfo.length>0) {
        if(rowInfo[0].fttenabled==='Yes') {
        setIsFttEnabled(true);
        } if(rowInfo[0].fttenabled==='No') {
        setIsFttEnabled(false);
        }
        if(rowInfo[0].fttenabled===true) {
            setIsFttEnabled(true);
            }
        if(rowInfo[0].fttenabled===false) {
            setIsFttEnabled(false);
                }
        }
      }, [rowInfo])
    const handleSwitchStoreMobileReview = (e: any) => {
        setShowUpdateCancelIcons(true);
        setRowInfo([{ ...rowInfo[0], mobileTracking: e.target.checked }]);
    };

    const handleSwitchStoreMobileScanEnabled = (e: any) => {
        setShowUpdateCancelIcons(true);
        setRowInfo([{ ...rowInfo[0], mobileScanEnabled: e.target.checked }]);
    };
    const handleEndDateChange = (event: any) => {
        setShowUpdateCancelIcons(true);
        setRowInfo([{ ...rowInfo[0], endDate: event.value }]);
    };

    /* Store update starts */

    //  const handleReclaimCenterLinkWH = (e: any) => {
    //     setWarehouseInfo(false);
    //     const addWarehouseItem = JSON.parse(e.target.value);
    //     const rowInfoCopy = JSON.parse(JSON.stringify(rowInfo));
    //     rowInfoCopy[0].warehouses.push(addWarehouseItem)
    //     setRowInfo(rowInfoCopy);
    //     var mySelect = document.getElementById("warehousesChange") as HTMLSelectElement;;
    //     if(mySelect) {
    //         mySelect.selectedIndex = 0;
    //     }
    //   }
    const handleWarehouseLinkStores = (e: any) => {
        // const addStoreItem = JSON.parse(e.target.value);
        // const rowInfoCopy = JSON.parse(JSON.stringify(rowInfo));
        // rowInfoCopy[0].reclaimCenters.push(addStoreItem)
        // setRowInfo(rowInfoCopy);
        const addDeptItem = JSON.parse(e.target.value);
        const rowInfoCopy = JSON.parse(JSON.stringify(rowInfo));
        const storeInfo = [...rowInfoCopy[0].reclaimCenters];
        rowInfoCopy[0].reclaimCenters.push(addDeptItem);
        if (!storeInfo.some((st) => st.id === addDeptItem.id)) {
            storeInfo.push(addDeptItem);
            setRowInfo(rowInfoCopy);
            var mySelectDept = document.getElementById(
                'ddlLinkRCInWH'
            ) as HTMLSelectElement;
            if (mySelectDept) {
                mySelectDept.selectedIndex = 0;
            }
        }
        var mySelectDept = document.getElementById(
            'ddlLinkRCInWH'
        ) as HTMLSelectElement;
        if (mySelectDept) {
            mySelectDept.selectedIndex = 0;
        }
    };
    //   const handleRemoveWarehouse = (e: any)=>{
    //     console.log("rowInfo",e)
    //     let linkedWarehouseData = [...rowInfo];
    //     let updatedStoreData = linkedWarehouseData.map(item =>
    //         ({
    //             ...item,
    //             warehouses: item.warehouses.filter((itemData: any) => (itemData.id !== e))
    //         })
    //     );
    //     setRowInfo(updatedStoreData);
    //   }

    /* GL info in Department */
    const handleAddGLAccInDept = () => {
        setShowUpdateCancelIcons(true);
        setShowAddGLAccDropdown(true);
    };
    const handleCloseGLAccountDropdown = () => {
        setShowAddGLAccDropdown(false);
    };
    const handleGLAccInDept = (e: any) => {
        const addGlAccItem = JSON.parse(e.target.value);
        console.log(addGlAccItem);
        let selIndex = glAccData.findIndex((ele) => {
            if (ele.id === addGlAccItem.id) {
                return true;
            }
        });
        // const glAccDataCopy = glAccData
        const glAccDataCopy = [...glAccData];
        glAccDataCopy?.splice(selIndex, 1);
        dispatch(setGLAccountMasterData([]));
        dispatch(setGLAccountMasterData(glAccDataCopy));
        console.log(selIndex);
        const rowInfoCopy = JSON.parse(JSON.stringify(rowInfo));
        const storeInfo = [...rowInfoCopy[0].glAccounts];
        rowInfoCopy[0].glAccounts.push(addGlAccItem);
        if (!storeInfo.some((st) => st.id === addGlAccItem.id)) {
            storeInfo.push(addGlAccItem);
            setRowInfo(rowInfoCopy);
            var mySelectDept = document.getElementById(
                'ddlGLAccInDept'
            ) as HTMLSelectElement;
            if (mySelectDept) {
                mySelectDept.selectedIndex = 0;
            }
        }
        var mySelectDept = document.getElementById(
            'ddlGLAccInDept'
        ) as HTMLSelectElement;
        if (mySelectDept) {
            mySelectDept.selectedIndex = 0;
        }
    };
    const handleRemoveGLAccInDept = (glAccObj: any) => {
        setShowUpdateCancelIcons(true);
        let linkedGLAccData = [...rowInfo];
        let updatedGLData = linkedGLAccData.map((item) => ({
            ...item,
            glAccounts: item.glAccounts.filter(
                (itemData: any) => itemData.id !== glAccObj?.id
            ),
        }));
        setRowInfo(updatedGLData);
        let selIndex = glAccData.findIndex((ele) => {
            if (ele.id === glAccObj.id) {
                return true;
            }
        });
        const glAccDataCopy = [...glAccData];
        glAccDataCopy?.splice(selIndex, 0, glAccObj);
        dispatch(setGLAccountMasterData([]));
        dispatch(setGLAccountMasterData(glAccDataCopy));
    };
    /* GL info in Department */

    /* Dept info in GLAcc */

    const handleAddDeptInGLAcc = () => {
        setShowUpdateCancelIcons(true);
        setShowAddDeptDropdown(true);
    };
    const handleCloseDropdownDeptInGLAcc = () => {
        setShowAddDeptDropdown(false);
    };
    const handleDeptInGLAcc = (e: any) => {
        const addDeptItem = JSON.parse(e.target.value);
        let selIndex = deptData.findIndex((ele) => {
            if (ele.id === addDeptItem.id) {
                return true;
            }
        });
        // const glAccDataCopy = glAccData
        const deptDataCopy = [...deptData];
        deptDataCopy?.splice(selIndex, 1);
        dispatch(setDepartmentMasterData([]));
        dispatch(setDepartmentMasterData(deptDataCopy));
        const rowInfoCopy = JSON.parse(JSON.stringify(rowInfo));
        const storeInfo = [...rowInfoCopy[0].departments];
        rowInfoCopy[0].departments.push(addDeptItem);
        if (!storeInfo.some((st) => st.id === addDeptItem.id)) {
            storeInfo.push(addDeptItem);
            setRowInfo(rowInfoCopy);
            var mySelectDept = document.getElementById(
                'ddlAddDepartments'
            ) as HTMLSelectElement;
            if (mySelectDept) {
                mySelectDept.selectedIndex = 0;
            }
        }
        var mySelectDept = document.getElementById(
            'ddlAddDepartments'
        ) as HTMLSelectElement;
        if (mySelectDept) {
            mySelectDept.selectedIndex = 0;
        }
    };
    const handleRemoveDeptINGLAcc = (glAccId: any) => {
        setShowUpdateCancelIcons(true);
        let linkedDeptData = [...rowInfo];
        let updatedDeptData = linkedDeptData.map((item) => ({
            ...item,
            departments: item.departments.filter(
                (itemData: any) => itemData.id !== glAccId.id
            ),
        }));
        setRowInfo(updatedDeptData);
        let selIndex = deptData.findIndex((ele) => {
            if (ele.id === glAccId.id) {
                return true;
            }
        });
        // const glAccDataCopy = glAccData
        const deptDataCopy = [...deptData];
        deptDataCopy?.splice(selIndex, 0, glAccId);
        dispatch(setDepartmentMasterData([]));
        dispatch(setDepartmentMasterData(deptDataCopy));
    };
    /* Dept info in GLAcc */
    /*Store remove from Warehouse */
    const handleRemoveStoreFromWarehouse = (storeId: any) => {
        setShowUpdateCancelIcons(true);
        let linkedWarehouseData = [...rowInfo];
        let updatedStoreData = linkedWarehouseData.map((item) => ({
            ...item,
            reclaimCenters: item.reclaimCenters.filter(
                (itemData: any) => itemData.id !== storeId
            ),
        }));
        setRowInfo(updatedStoreData);
    };
    /* Update Store Disposition starts */
    const handleChangeUPCDispoInStoreDispo = (e: any) => {
        setShowUpdateCancelIcons(true);
        setRowInfo([
            { ...rowInfo[0], dispositionId: parseInt(e.target.value) },
        ]);
    };
    const _handleFocusEditStoreDipoInStoreDispo = (text: any) => {
        setShowUpdateCancelIcons(true);
        setRowInfo([{ ...rowInfo[0], storeDisposition: text }]);
    };
    const _handleFocusOutEditStoreDipoInStoreDispo = (text: any) => {
        setShowUpdateCancelIcons(true);
        setRowInfo([{ ...rowInfo[0], storeDisposition: text }]);
    };
    const _handleFocusEditVoiceTextInStoreDispo = (text: any) => {
        setShowUpdateCancelIcons(true);
        setRowInfo([{ ...rowInfo[0], storeDispositionVoiceText: text }]);
    };
    const _handleFocusOutEditVoiceTextInStoreDispo = (text: any) => {
        setShowUpdateCancelIcons(true);
        setRowInfo([{ ...rowInfo[0], storeDispositionVoiceText: text }]);
    };
    const handleChangeAuthFlagInStoreDispo = (e: any) => {
        setShowUpdateCancelIcons(true);
        setRowInfo([{ ...rowInfo[0], authorizationFlag: e.target.value }]);
    };

    /* Update Store Disposition ends */
    /* Update WH Location starts */
    const handleChangeUPCDispoInWHloc = (e: any) => {
        const rowObject = {...rowInfo[0]};
        Object.assign(rowObject,{
            fttenabled : rowObject.fttenabled==='Yes' ? true : false,
            disposition: e.target.value
          });
       setShowUpdateCancelIcons(true);
       setRowInfo([{ ...rowInfo[0], disposition: e.target.value, fttenabled: rowObject.fttenabled==='Yes' ? true : false }]);
    };
    const _handleFocusEditWHLocInWHLoc = (text: any) => {
        const rowObject = {...rowInfo[0]};
        Object.assign(rowObject,{
            fttenabled : rowObject.fttenabled==='Yes' ? true : false,
            warehouseLocation: text
          });
       setShowUpdateCancelIcons(true);
       setRowInfo([{ ...rowInfo[0], warehouseLocation: text, fttenabled: rowObject.fttenabled==='Yes' ? true : false }]);
    };
    const _handleFocusOutEditWHLocInWHLoc = (text: any) => {
        const rowObject = {...rowInfo[0]};
        Object.assign(rowObject,{
            fttenabled : rowObject.fttenabled==='Yes' ? true : false,
            warehouseLocation: text
          });
       setShowUpdateCancelIcons(true);
       setRowInfo([{ ...rowInfo[0], warehouseLocation: text, fttenabled: rowObject.fttenabled==='Yes' ? true : false }]);
    };
    const _handleFocusEditVoiceTextInWhLoc = (text: any) => {
        const rowObject = {...rowInfo[0]};
        Object.assign(rowObject,{
            fttenabled : rowObject.fttenabled==='Yes' ? true : false,
            dispositionVoiceText: text
          });
       setShowUpdateCancelIcons(true);
       setRowInfo([{ ...rowInfo[0], dispositionVoiceText: text, fttenabled: rowObject.fttenabled==='Yes' ? true : false }]);
    };
    const _handleFocusOutEditVoiceTextInWhLoc = (text: any) => {
        const rowObject = {...rowInfo[0]};
        Object.assign(rowObject,{
            fttenabled : rowObject.fttenabled==='Yes' ? true : false,
            dispositionVoiceText: text
          });
       setShowUpdateCancelIcons(true);
       setRowInfo([{ ...rowInfo[0], dispositionVoiceText: text, fttenabled: rowObject.fttenabled==='Yes' ? true : false }]);
    };
    const _handleFocusEditMaxBoxInWhLoc = (text: any) => {
        const rowObject = {...rowInfo[0]};
        Object.assign(rowObject,{
            fttenabled : rowObject.fttenabled==='Yes' ? true : false,
            boxMaxCount: text
          });
       setShowUpdateCancelIcons(true);
       setRowInfo([{ ...rowInfo[0], boxMaxCount: text, fttenabled: rowObject.fttenabled==='Yes' ? true : false }]);
    };
    const _handleFocusOutEditMaxBoxInWhLoc = (text: any) => {
        const rowObject = {...rowInfo[0]};
        Object.assign(rowObject,{
            fttenabled : rowObject.fttenabled==='Yes' ? true : false,
            boxMaxCount: text
          });
       setShowUpdateCancelIcons(true);
       setRowInfo([{ ...rowInfo[0], boxMaxCount: text, fttenabled: rowObject.fttenabled==='Yes' ? true : false }]);
    };
    /* Update WH Location ends */

    const rcFinalAddedWH: any[] = [];
    const handleUpdate = () => {
        if (setTabHeading.payload === ContactType.COMPANY_CODES) {
            var Cname = document.getElementById(
                'txtCompanyName'
            ) as HTMLInputElement;
            if (Cname.value === '') {
                notify(`Name is required`, 'warning');
            }
            //  else if(webURLError! == ''){
            //   webURLError = 'Web URL is invalid';
            //  }
            else {
                setWarehouseInfo(true);
            }
        } else if (setTabHeading.payload === ContactType.RECLAIM_CENTERS) {
            var Cname = document.getElementById(
                'txtRCName'
            ) as HTMLInputElement;
            if (Cname.value === '') {
                notify(`Name is required`, 'warning');
            } else {
                setWarehouseInfo(true);
            }
        } else if (setTabHeading.payload === ContactType.CARRIERS) {
            // var Crname = document.getElementById('txtCarrierName') as HTMLInputElement;
            // if(Crname.value === ''){
            //  notify(`Name is required`, 'warning');
            // }
            // else{
            setWarehouseInfo(true);
            //}
        } else if (setTabHeading.payload === ContactType.CUSTOMERS) {
            setWarehouseInfo(true);
        } else if (setTabHeading.payload === ContactType.COMMODITY_TYPES) {
            console.log('rowInfo rowInfo', rowInfo);
            const PT = JSON.parse(JSON.stringify(rowInfo[0].pricingType));
            if (PT === 'Percent' && rowInfo[0].price > 100) {
                notify(`Percent should not exceed 100%`, 'warning');
            } else {
                setWarehouseInfo(true);
            }
        } else if (setTabHeading.payload === ContactType.WAREHOUSES) {
            var Cname = document.getElementById(
                'txtWHName'
            ) as HTMLInputElement;
            if (Cname.value === '') {
                notify(`Name is required`, 'warning');
            } else {
                const whArray = JSON.parse(
                    JSON.stringify(rowInfo[0].reclaimCenters)
                );
                whArray.filter((item: any) => {
                    return rcFinalAddedWH.push(item.id);
                });
                setRowInfo([
                    { ...rowInfo[0], reclaimCenterIds: rcFinalAddedWH },
                ]);
                setWarehouseInfo(true);
            }
        } else if (setTabHeading.payload === ContactType.DEPARTMENTS) {
            const whArray = JSON.parse(JSON.stringify(rowInfo[0].glAccounts));
            whArray.filter((item: any) => {
                return rcFinalAddedWH.push(item.id);
            });
            setRowInfo([{ ...rowInfo[0], glAccountIds: rcFinalAddedWH }]);
            setWarehouseInfo(true);
        } else if (setTabHeading.payload === ContactType.GL_ACCOUNTS) {
            const whArray = JSON.parse(JSON.stringify(rowInfo[0].departments));
            whArray.filter((item: any) => {
                return rcFinalAddedWH.push(item.id);
            });
            setRowInfo([{ ...rowInfo[0], departmentIds: rcFinalAddedWH }]);
            setWarehouseInfo(true);
        } else if (setTabHeading.payload === ContactType.STORES) {
            const endpoint = updateMasterDataEndPoint(
                setTabHeading.payload,
                rowInfo
            );
            const getEndpoint = getEndPoint(setTabHeading.payload, 1, 1);
            handleMasterDataChange(endpoint, getEndpoint);
        } else if (setTabHeading.payload === ContactType.VENDORS) {
            const endpoint = updateMasterDataEndPoint(
                setTabHeading.payload,
                rowInfo
            );
            const getEndpoint = getEndPoint(setTabHeading.payload, 1, 1);
            handleMasterDataChange(endpoint, getEndpoint);
        } else if (setTabHeading.payload === ContactType.STORE_DISPOSITIONS) {
            const endpoint = updateMasterDataEndPoint(
                setTabHeading.payload,
                rowInfo
            );
            const getEndpoint = getEndPoint(setTabHeading.payload);
            handleMasterDataChange(endpoint, getEndpoint);
        } else if (tabNameForWHLoc.payload === ContactType.UPCDefaultWHLoc) {
            const endpoint = updateMasterDataEndPoint(
                tabNameForWHLoc.payload,
                rowInfo
            );
            const getEndpoint = getAllMasterData(tabNameForWHLoc.payload);
            handleMasterDataChange(endpoint, getEndpoint);
        } else if (tabNameForWHLoc.payload === ContactType.CustomWHLoc) {
            const endpoint = updateMasterDataEndPoint(
                tabNameForWHLoc.payload,
                rowInfo
            );
            const getEndpoint = getAllMasterData(tabNameForWHLoc.payload);
            handleMasterDataChange(endpoint, getEndpoint);
        } else {
            const endpoint = updateMasterDataEndPoint(
                setTabHeading.payload,
                rowInfo
            );
            const getEndpoint = getEndPoint(setTabHeading.payload);
            handleMasterDataChange(endpoint, getEndpoint);
        }
    };
    useEffect(() => {
        if (warehouseInfo) {
            const endpoint = updateMasterDataEndPoint(
                setTabHeading.payload,
                rowInfo
            );
            const getEndpoint = getEndPoint(setTabHeading.payload);
            handleMasterDataChange(endpoint, getEndpoint);
        }
    }, [warehouseInfo]);
    const handleCancel = () => {
        if (setTabHeading.payload === ContactType.STORES) {
            const getEndpoint = getEndPoint(setTabHeading.payload, 1, 1);
            handleCancelAction(getEndpoint);
        } else if (setTabHeading.payload === ContactType.VENDORS) {
            const getEndpoint = getEndPoint(setTabHeading.payload, 1, 1);
            handleCancelAction(getEndpoint);
        } else {
            const getEndpoint = getEndPoint(setTabHeading.payload);
            handleCancelAction(getEndpoint);
        }
    };
    const handleCancelAction = async (getEndpoint: string) => {
        if (setTabHeading.payload === ContactType.STORES) {
            const getresult = await axios.get(getEndpoint);
            let storesArray: any = getresult.data;
            // dispatch(setCurrentContact(storesArray?.content));
            dispatch(setUpdatedStoresORVendorData(storesArray?.content));
        } else if (setTabHeading.payload === ContactType.VENDORS) {
            const getresult = await axios.get(getEndpoint);
            let vendorsArray: any = getresult.data;
            dispatch(setUpdatedStoresORVendorData(vendorsArray?.content));
            // dispatch(setCurrentContact(vendorsArray?.content));
        } else {
            const getresult = await axios.get(getEndpoint);
            dispatch(setCurrentContact(getresult.data));
        }
        notify(`Information! Changes discarded`, 'warning');
    };
    const handleMasterDataChange = async (
        endpoint: string,
        getEndpoint: string
    ) => {
        const rowInfoObject = Object.assign({}, ...rowInfo);
        //     const result = await axios.put(endpoint, rowInfoObject);
        //    if (result && result.data) {
        //     alert('Done');
        //     const getresult = await axios.get(getEndpoint);
        //     dispatch(setCurrentContact(getresult.data));
        //   } else {
        //     alert('Error');
        //   }
        //const result = await axios.put(endpoint, rowInfoObject);
        // if (result && result.data) {
        //  notify(`${setTabHeading.payload} updated successfully`, 'success');
        //   //toast.success('Success')
        // } else {
        //   notify(`Failed to update ${setTabHeading.payload}`, 'error');
        //   //toast.error('Error')
        // }
        const result = await axios
            .put(endpoint, rowInfoObject)
            .then((results: any) => {
                if (results && results.data) {
                    notify('Updated successfully', 'success');
                } else {
                    notify('Failed to update', 'error');
                }
            })
            .catch((error: any) => {
                if (error.response) {
                    notify(error.response?.data?.message, 'error');
                }
            });
        let getresult: any;
        if (setTabHeading.payload === ContactType.STORES) {
            getresult = await axios.get(getEndpoint);
            let storesArray: any = getresult.data;
            dispatch(setUpdatedStoresORVendorData(storesArray?.content));
            // setTimeout(() => {
            //   dispatch(setCurrentContact(storesArray?.content));
            // }, 1000);
            // props.storeDataStateChange({skip: 0})
        } else if (setTabHeading.payload === ContactType.VENDORS) {
            getresult = await axios.get(getEndpoint);
            let vendorsArray: any = getresult.data;
            dispatch(setUpdatedStoresORVendorData(vendorsArray?.content));
            // dispatch(setCurrentContact(vendorsArray?.content));
        } else if (setTabHeading.payload === ContactType.COMPANY_CODES) {
            getresult = await axios.get(getEndpoint);
            dispatch(setCurrentContact(getresult.data));
        } else if (setTabHeading.payload === ContactType.CARRIERS) {
            getresult = await axios.get(getEndpoint);
            dispatch(setCurrentContact(getresult.data));
        } else if (setTabHeading.payload === ContactType.CUSTOMERS) {
            getresult = await axios.get(getEndpoint);
            dispatch(setCurrentContact(getresult.data));
        } else if (setTabHeading.payload === ContactType.RECLAIM_CENTERS) {
            getresult = await axios.get(getEndpoint);
            dispatch(setCurrentContact(getresult.data));
        } else if (setTabHeading.payload === ContactType.WAREHOUSES) {
            getresult = await axios.get(getEndpoint);
            dispatch(setCurrentContact(getresult.data));
        } else if (setTabHeading.payload === ContactType.COMMODITY_TYPES) {
            getresult = await axios.get(getEndpoint);
            dispatch(setCurrentContact(getresult.data));
        } else if (setTabHeading.payload === ContactType.STORE_DISPOSITIONS) {
            getresult = await axios.get(getEndpoint);
            dispatch(setCurrentContact(getresult?.data));
        } else if (tabNameForWHLoc.payload === ContactType.UPCDefaultWHLoc) {
            getresult = await axios.get(getEndpoint);
            dispatch(setWHLocUPCDefaultData(getresult?.data));
        } else if (tabNameForWHLoc.payload === ContactType.CustomWHLoc) {
            getresult = await axios.get(getEndpoint);
            dispatch(setWHLocCustomData(getresult?.data));
        } else {
            getresult = await axios.get(getEndpoint);
            dispatch(setCurrentContact(getresult.data));
        }
        // if (
        //   setTabHeading.payload === ContactType.STORES ||
        //   setTabHeading.payload === ContactType.VENDORS
        // ) {
        //   let dataa: any;
        //   setTimeout(() => {
        //     dataa = getresult.data[0];
        //     dispatch(setStoresORVendorsRowData(dataa));
        //   }, 10);
        // } else {
        let dataa: any;
        setTimeout(() => {
            dataa = getresult.data[0];
            dispatch(setRowData(dataa));
        }, 10);
        // }
    };
    // const AccordianTabInfo = () => {
    //     return (
    //         <div>
    //             {(setTabHeading.payload === "Reclaim centers" && setRowData && setRowData.length > 0) ?
    //                 <div>
    //                 {rowInfo.map((info) => (
    //                     <div>
    //                         {info.warehouses.map((warehouseInfo: any) => (
    //                            <div>
    //                             {warehouseInfo.stores.map((storesInfo: any) => (
    //                              <div className="row generalTabWareHouseData p-10">
    //                                 <div className="col-sm-6">
    //                                  <span className="linkedStoreData">{storesInfo.name}</span>
    //                                  </div>
    //                                  <div className="col-sm-4">
    //                                  <span className="linkedStoreData">{storesInfo.storeNumber}</span>
    //                                   </div>
    //                                     <div className="col-sm-2">
    //                                       <span className="linkedStoreData">X</span>
    //                                     </div>
    //                                  </div>

    //                                     ))}
    //                                 </div>
    //                             ))}
    //                         </div>
    //                     ))}
    //                 </div>
    //                 : ''}
    //         </div>
    //     )
    // }
    return (
        <div id="">
            {loaderStatus ? (
                <img className="loaderClass" src={loader} />
            ) : (
                <>
                    {setTabHeading.payload === ContactType.STORES &&
                    rowInfo &&
                    rowInfo?.length > 0 ? (
                        <div className="">
                            {rowInfo &&
                                rowInfo?.map((info) => (
                                    <div>
                                        <div className="col-sm-12 px-2 py-4 preview-details d-flex flex-column align-items-start">
                                            <div className="col-sm-12 p-0">
                                                <div className="col-sm-12 px-0 pt-4 ">
                                                    <div className="col-sm-6 col-md-6 col-lg-5 preview-Key  ">
                                                        <span className="storeNumHeading lblName">
                                                            Name
                                                        </span>
                                                    </div>

                                                    <div className="col-sm-6 col-md-6 col-lg-7 preview-value ">
                                                        <span
                                                            className="storeNumber lblStoreName"
                                                            title={
                                                                info?.storeName
                                                            }
                                                        >
                                                            {info?.storeName}
                                                        </span>
                                                    </div>
                                                </div>
                                                <div className="col-sm-12 px-0 pt-4 ">
                                                    <div className="col-sm-6 col-md-6 col-lg-5 preview-Key  ">
                                                        <span className="storeNumHeading">
                                                            Salvager
                                                        </span>
                                                    </div>
                                                    <div className="col-sm-6 col-md-6 col-lg-7 preview-value ">
                                                        <input
                                                            type="checkbox"
                                                            defaultChecked={
                                                                info?.salvager
                                                            }
                                                            id="salvager"
                                                            className="toggle"
                                                            onChange={(
                                                                e: any
                                                            ) =>
                                                                handleSwitchStoreSalvager(
                                                                    e
                                                                )
                                                            }
                                                        />
                                                        <label
                                                            className="chkbx"
                                                            htmlFor="salvager"
                                                        ></label>
                                                        {/* <SwitchComponent checked={info?.salvager} onChange={(e: any) => handleSwitchStoreSalvager(e)}/>        */}
                                                    </div>
                                                </div>
                                                <div className="col-sm-12 px-0 pt-4 ">
                                                    <div className="col-sm-6 col-md-6 col-lg-5 preview-Key  ">
                                                        <span className="storeNumHeading">
                                                            Account #
                                                        </span>
                                                    </div>
                                                    <div className="col-sm-6 col-md-6 col-lg-7 preview-value ">
                                                        <span className="storeNumber">
                                                            {
                                                                info?.mainframeCustomerNumber
                                                            }
                                                        </span>
                                                    </div>
                                                </div>
                                                <div className="col-sm-12 px-0 pt-4 ">
                                                    <div className="col-sm-6 col-md-6 col-lg-5 preview-Key  ">
                                                        <span className="storeNumHeading">
                                                            Store #
                                                        </span>
                                                    </div>
                                                    <div className="col-sm-6 col-md-6 col-lg-7 preview-value ">
                                                        <span className="storeNumber">
                                                            {info?.storeNumber}
                                                        </span>
                                                    </div>
                                                </div>
                                                <div className="col-sm-12 px-0 pt-4 ">
                                                    <div className="col-sm-6 col-md-6 col-lg-5 preview-Key  ">
                                                        <span className="storeNumHeading">
                                                            Chain #
                                                        </span>
                                                    </div>
                                                    <div className="col-sm-6 col-md-6 col-lg-7 preview-value ">
                                                        <span className="storeNumber">
                                                            {info?.chainNumber}
                                                        </span>
                                                    </div>
                                                </div>
                                                {/* <div className="row generalTabInfo">
                                <div className="col-sm-6">
                                    <span className="storeNumHeading">Store Type</span>
                                </div>
                                <div className="col-sm-6">
                                <span className="storeNumber">{info.storeType}</span>
                                </div>
                            </div> */}
                                                <div className="col-sm-12 px-0 pt-4 ">
                                                    <div className="col-sm-6 col-md-6 col-lg-5 preview-Key  ">
                                                        <span className="storeNumHeading lblSupergrp">
                                                            Super Group
                                                        </span>
                                                    </div>
                                                    <div className="col-sm-6 col-md-6 col-lg-7 preview-value ">
                                                        <select
                                                            className="form-control border-none pl-0"
                                                            value={
                                                                info?.superGroup
                                                            }
                                                            onChange={(e) =>
                                                                handleChangeStoreSuperGroup(
                                                                    e
                                                                )
                                                            }
                                                        >
                                                            {/* <option value={info?.superGroup}>{info?.superGroup}</option> */}
                                                            {superGroupData?.map(
                                                                (sg) => (
                                                                    <option
                                                                        value={
                                                                            sg.superGroup
                                                                        }
                                                                    >
                                                                        {
                                                                            sg.superGroup
                                                                        }
                                                                    </option>
                                                                )
                                                            )}
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className="col-sm-12 px-0 pt-4 ">
                                                    <div className="col-sm-6 col-md-6 col-lg-5 preview-Key  ">
                                                        <span className="storeNumHeading">
                                                            Client Group
                                                        </span>
                                                    </div>
                                                    <div className="col-sm-6 col-md-6 col-lg-7 preview-value ">
                                                        <select
                                                            className="form-control border-none pl-0"
                                                            onChange={(e) =>
                                                                handleChangeStoreClientGroup(
                                                                    e
                                                                )
                                                            }
                                                        >
                                                            <option
                                                                value={
                                                                    info?.reclaimCustomerGroupName
                                                                }
                                                            >
                                                                (
                                                                {
                                                                    info?.reclaimCustomerGroupNumber
                                                                }
                                                                ){' '}
                                                                {
                                                                    info.reclaimCustomerGroupName
                                                                }
                                                            </option>
                                                            {clientGroupData?.map(
                                                                (cg) => (
                                                                    <option
                                                                        value={JSON.stringify(
                                                                            cg
                                                                        )}
                                                                    >
                                                                        (
                                                                        {
                                                                            cg.clientGroupName
                                                                        }
                                                                        ){' '}
                                                                        {
                                                                            cg.clientGroupName
                                                                        }
                                                                    </option>
                                                                )
                                                            )}
                                                        </select>
                                                    </div>
                                                </div>
                                                <hr />
                                                <div className="col-sm-12 px-0 pt-4 ">
                                                    <div className="col-sm-6 col-md-6 col-lg-5 preview-Key">
                                                        <span className="storeNumHeading">
                                                            Under Review
                                                        </span>
                                                    </div>
                                                    <div className="col-sm-6 col-md-6 col-lg-7 preview-value ">
                                                        <input
                                                            type="checkbox"
                                                            defaultChecked={
                                                                info?.underReview
                                                            }
                                                            id="underReview"
                                                            className="toggle"
                                                            onChange={(
                                                                e: any
                                                            ) =>
                                                                handleSwitchStoreUnderReview(
                                                                    e
                                                                )
                                                            }
                                                        />
                                                        <label
                                                            className="chkbx"
                                                            htmlFor="underReview"
                                                        ></label>
                                                        {/* <SwitchComponent checked={info?.underReview} onChange={(e: any) => handleSwitchStoreUnderReview(e)}/>        */}
                                                    </div>
                                                </div>
                                                {info.underReview ? (
                                                    <>
                                                        <div className="col-sm-12 px-0 pt-4 ">
                                                            <div className="col-sm-6 col-md-6 col-lg-5 preview-Key">
                                                                <span className="storeNumHeading lblReasoncode">
                                                                    Reason Code
                                                                </span>
                                                            </div>
                                                            <div className="col-sm-6 col-md-6 col-lg-7 preview-value ">
                                                                <select
                                                                    className="form-control border-none"
                                                                    value={
                                                                        info?.reasonCode
                                                                    }
                                                                    onChange={(
                                                                        e
                                                                    ) =>
                                                                        handleChangeStoreReasonCode(
                                                                            e
                                                                        )
                                                                    }
                                                                >
                                                                    {/* <option value={info?.reasonCode}>{info?.reasonCode}</option> */}
                                                                    <option>
                                                                        Select
                                                                    </option>
                                                                    {reasonCodeData?.map(
                                                                        (
                                                                            rc
                                                                        ) => (
                                                                            <option
                                                                                value={
                                                                                    rc.reasonCode
                                                                                }
                                                                            >
                                                                                {
                                                                                    rc.reasonCode
                                                                                }
                                                                            </option>
                                                                        )
                                                                    )}
                                                                </select>
                                                            </div>
                                                        </div>
                                                    </>
                                                ) : (
                                                    ''
                                                )}
                                                <hr />
                                                <div className="col-sm-12 px-0 pt-4 ">
                                                    <div className="col-sm-6 col-md-6 col-lg-6 preview-Key">
                                                        <span className="storeNumHeading">
                                                            Store Label Tracking
                                                        </span>
                                                    </div>
                                                    <div className="col-sm-6 col-md-6 col-lg-6 p-0 preview-value ">
                                                        <input
                                                            type="checkbox"
                                                            defaultChecked={
                                                                info?.mobileTracking
                                                            }
                                                            id="mobileTracking"
                                                            className="toggle"
                                                            onChange={(
                                                                e: any
                                                            ) =>
                                                                handleSwitchStoreMobileReview(
                                                                    e
                                                                )
                                                            }
                                                        />
                                                        <label
                                                            className="chkbox"
                                                            htmlFor="mobileTracking"
                                                        ></label>
                                                        {/* <SwitchComponent checked={info?.mobileTracking} onChange={(e: any) => handleSwitchStoreMobileReview(e)}/>        */}
                                                    </div>
                                                </div>
                                                <div className="col-sm-12 px-0 pt-4 ">
                                                    <div className="col-sm-6 col-md-6 col-lg-6 preview-Key">
                                                        <span className="storeNumHeading">
                                                            Mobile Scan Enabled
                                                        </span>
                                                    </div>
                                                    <div className="col-sm-6 col-md-6 col-lg-6 p-0 preview-value ">
                                                        <input
                                                            type="checkbox"
                                                            defaultChecked={
                                                                info?.mobileScanEnabled
                                                            }
                                                            id="mobileScanEnabled"
                                                            className="toggle"
                                                            onChange={(
                                                                e: any
                                                            ) =>
                                                                handleSwitchStoreMobileScanEnabled(
                                                                    e
                                                                )
                                                            }
                                                        />
                                                        <label
                                                            className="chkbox"
                                                            htmlFor="mobileScanEnabled"
                                                        ></label>
                                                        {/* <SwitchComponent checked={info?.mobileScanEnabled} onChange={(e: any) => handleSwitchStoreMobileScanEnabled(e)}/>        */}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                        </div>
                    ) : setTabHeading.payload === ContactType.WAREHOUSES &&
                      rowInfo &&
                      rowInfo?.length > 0 ? (
                        <div className="">
                            {rowInfo &&
                                rowInfo?.map((info) => (
                                    <div>
                                        <div className="col-sm-12 px-2 py-4 preview-details d-flex flex-column align-items-start">
                                            <div className="col-sm-12 p-0">
                                                <div className="col-sm-12 px-0 pt-4 ">
                                                    <div className="col-sm-6 col-md-6 col-lg-5 preview-Key  ">
                                                        <span className="storeNumHeading lblName">
                                                            Name
                                                        </span>
                                                    </div>
                                                    <div className="col-sm-6 col-md-6 col-lg-7 preview-value p-0">
                                                        {/* <InlineInputEdit inputClassName="txtEditClassNameInput"
                text={info.warehouseName}
                labelClassName="txtEditClassName"
                onFocus={_handleFocusEditWHName}
                onFocusOut={_handleFocusOutEditWHName}
                labelPlaceHolder='- - -'/>  */}
                                                        <input
                                                            type="text"
                                                            id="txtWHName"
                                                            className="form-control border-none cursor-pointer txtEditFieldFocus boxShadow-None"
                                                            onChange={(
                                                                e: any
                                                            ) =>
                                                                _handleFocusEditWHName(
                                                                    e
                                                                )
                                                            }
                                                            defaultValue={
                                                                info?.warehouseName
                                                            }
                                                            onBlur={(e: any) =>
                                                                _handleFocusEditWHName(
                                                                    e
                                                                )
                                                            }
                                                            onKeyUp={(e: any) =>
                                                                _handleFocusEditWHName(
                                                                    e
                                                                )
                                                            }
                                                        />
                                                    </div>
                                                </div>
                                                {/* <div className="row generalTabWareHouseInfo">
        <div className="col-sm-8">
        <h5 className="linkedStoreHeading">Linked Reclaim Centers</h5>
        </div>
        <div className="col-sm-4">
        {showAddStoresDropdown?
        <span className="plusIcon cursor-pointer iconsForLinking" onClick={() =>handleCloseStoreDropdown()}> <img src={closeIcon}/></span>
        :
         <span className="plusIcon cursor-pointer iconsForLinking" onClick={() =>handleAdStoreInWH()}><img src={plusIcon}/></span>
        }
        </div>
        </div>  */}
                                                {/* {showAddStoresDropdown?
        <div className="row generalTabWareHouseInfo">
        <div className="col-sm-12">
        <select className="form-control" id="ddlLinkRCInWH" onChange={(e) => handleWarehouseLinkStores(e)}>
          <option>Select</option>
            {reclaimCentersData?.map((st) =>
          <option value={JSON.stringify(st)}>{st.reclaimcenterName}</option>
           )}
        </select>
        </div>
        </div>
        :''}      */}
                                                {/* <div className="deptsInGLAccDiv">
        {info.reclaimCenters.map((storesInfo: any) => (
        <div>
        <div className="row generalTabWareHouseData ml-0 mr-0">
        <div className="col-sm-10">
        <span className="linkedStoreData">{storesInfo.reclaimcenterName}</span>
         </div>
         <div className="col-sm-2">
        <span className="linkedStoreData closeIconLinkedActions cursor-pointer" onClick={() =>handleRemoveStoreFromWarehouse(storesInfo.id)}>X</span>
        </div>
        </div>
        </div>

        ))}
        </div> */}
                                            </div>
                                        </div>
                                    </div>
                                ))}
                        </div>
                    ) : setTabHeading.payload === ContactType.RECLAIM_CENTERS &&
                      rowInfo &&
                      rowInfo?.length > 0 ? (
                        <div>
                            {rowInfo &&
                                rowInfo?.map((info) => (
                                    <div className="">
                                        <div className="col-sm-12 px-2 py-4 preview-details d-flex flex-column align-items-start">
                                            <div className="col-sm-12 p-0">
                                                <div className="col-sm-12 px-0 pt-4 ">
                                                    <div className="col-sm-6 col-md-6 col-lg-5 preview-Key  ">
                                                        <span className="storeNumHeading mt-1rem">
                                                            Name
                                                        </span>
                                                    </div>
                                                    <div className="col-sm-6 col-md-6 col-lg-7 preview-value ">
                                                        {/* <InlineInputEdit inputClassName="txtEditClassNameInput"
                text={info.reclaimcenterName}
                labelClassName="txtEditClassName"
                onFocus={_handleFocusEditRCName}
                onFocusOut={_handleFocusOutEditRCName}
                labelPlaceHolder='- - -'/>  */}
                                                        <input
                                                            type="text"
                                                            id="txtRCName"
                                                            className="form-control border-none pl-0 cursor-pointer txtEditFieldFocus boxShadow-None"
                                                            onChange={(
                                                                e: any
                                                            ) =>
                                                                _handleFocusEditRCName(
                                                                    e
                                                                )
                                                            }
                                                            defaultValue={
                                                                info?.reclaimcenterName
                                                            }
                                                            onBlur={(e: any) =>
                                                                _handleFocusEditRCName(
                                                                    e
                                                                )
                                                            }
                                                            onKeyUp={(e: any) =>
                                                                _handleFocusEditRCName(
                                                                    e
                                                                )
                                                            }
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-sm-12 px-0 pt-5 ">
                                                    <div className="col-sm-6 col-md-6 col-lg-5 preview-Key  ">
                                                        <span className="storeNumHeading">
                                                            Company Code
                                                        </span>
                                                    </div>
                                                    <div className="col-sm-6 col-md-6 col-lg-7 preview-value ">
                                                        <span
                                                            className="storeNumber textOverlap"
                                                            title={
                                                                info
                                                                    ?.companyCode
                                                                    ?.companyName
                                                            }
                                                        >
                                                            (
                                                            {
                                                                info
                                                                    ?.companyCode
                                                                    ?.id
                                                            }
                                                            )
                                                            {
                                                                info
                                                                    ?.companyCode
                                                                    ?.companyName
                                                            }
                                                        </span>
                                                        {/* <select className="form-control border-none">
                                            <option>{info?.companyCode?.companyCode}({info?.companyCode?.companyName})</option>
                                            {info.companyCode.map((cc: any) => (
                                            <option value={cc}>{cc.companyCode}({cc.companyName})</option>
                                            ))}
                                          </select> */}
                                                    </div>
                                                </div>
                                                <div className="col-sm-12 px-0 pt-5 ">
                                                    <div className="col-sm-6 col-md-6 col-lg-5 preview-Key  ">
                                                        <span className="storeNumHeading-hourly">
                                                            Hourly Scan Goal
                                                            (Items)
                                                        </span>
                                                    </div>
                                                    <div className="col-sm-6 col-md-6 col-lg-7 preview-value ">
                                                        {/* <span
                              className="storeNumber textOverlap"
                              title={info?.hourlyScanGoal}
                            >
                              {info?.hourlyScanGoal}
                            </span> */}
                                                        <input
                                                            type="text"
                                                            id="txtRCName"
                                                            className="form-control border-none pl-0 cursor-pointer txtEditFieldFocus boxShadow-None"
                                                            onChange={(
                                                                e: any
                                                            ) =>
                                                                _handleHourlyScanGoal(
                                                                    e
                                                                )
                                                            }
                                                            defaultValue={
                                                                info?.hourlyScanGoal
                                                            }
                                                            onBlur={(e: any) =>
                                                                _handleHourlyScanGoal(
                                                                    e
                                                                )
                                                            }
                                                            onKeyUp={(e: any) =>
                                                                _handleHourlyScanGoal(
                                                                    e
                                                                )
                                                            }
                                                        />
                                                    </div>
                                                </div>
                                                {/* <div className="row generalTabWareHouseInfo">
                                            <div className="col-sm-8">
                                                <h5 className="linkedStoreHeading">Linked Warehouses</h5>
                                            </div>
                                            {showAddWarehouseDropdown?
                                            <div className="col-sm-2">
                                            <span className="plusIcon cursor-pointer" onClick={() =>handleCloseWarehouseDropdown()}> X </span>
                                       </div>
                                            :  <div className="col-sm-2">
                                            <span className="plusIcon cursor-pointer" onClick={() =>handleAddWarehouse()}>+</span>
                                        </div>}
                                        </div> */}
                                                {/* {showAddWarehouseDropdown?
                                        <div className="row generalTabWareHouseInfo">
                                        <div className="col-sm-10">
                                            <select className="form-control" id="warehousesChange" onChange={(e) => handleReclaimCenterLinkWH(e)}>
                                                <option>Select</option>
                                                {warehouseData.map((wh) =>
                                                <option value={JSON.stringify(wh)}>{wh.warehouseName}</option>
                                                )}
                                            </select>
                                        </div>
                                        </div>
                                        :''} */}
                                                {/* {info.warehouses.map((warehouseInfo: any) => (
                                        <div>
                                        <div className="row generalTabWareHouseData ml-0 mr-0">
                                            <div className="col-sm-10">
                                                <span className="linkedStoreData">{warehouseInfo.warehouseName}</span>
                                            </div>
                                            <div className="col-sm-2">
                                                <span className="linkedStoreData cursor-pointer" onClick ={() =>handleRemoveWarehouse(warehouseInfo.id)} >X</span>
                                            </div>
                                        </div>
                                    </div>
                                    ))}  */}
                                            </div>
                                        </div>
                                    </div>
                                ))}
                        </div>
                    ) : setTabHeading.payload === ContactType.VENDORS &&
                      rowInfo &&
                      rowInfo?.length > 0 ? (
                        <div>
                            {rowInfo &&
                                rowInfo?.map((info) => (
                                    <div className="">
                                        <div className="col-sm-12 px-2 py-4 preview-details d-flex flex-column align-items-start">
                                            <div className="col-sm-12 p-0">
                                                <div className="col-sm-12 px-0 pt-4 ">
                                                    <div className="col-sm-6 col-md-6 col-lg-5 preview-Key  ">
                                                        <span className="storeNumHeading lblVendorName">
                                                            Name
                                                        </span>
                                                    </div>
                                                    <div className="col-sm-6 col-md-6 col-lg-7 preview-value ">
                                                        <span
                                                            className="storeNumber lblStoreName"
                                                            title={
                                                                info?.apVendorName
                                                            }
                                                        >
                                                            {info?.apVendorName}
                                                        </span>
                                                    </div>
                                                </div>
                                                <div className="col-sm-12 px-0 pt-4 ">
                                                    <div className="col-sm-6 col-md-6 col-lg-5 preview-Key  ">
                                                        <span className="storeNumHeading">
                                                            Hold
                                                        </span>
                                                    </div>
                                                    <div className="col-sm-6 col-md-6 col-lg-7 preview-value ">
                                                        {/* <SwitchComponent checked={info?.hold} onChange={(e: any) => handleSwitchVendorHold(e)}/>        */}
                                                        <input
                                                            type="checkbox"
                                                            defaultChecked={
                                                                info?.hold
                                                            }
                                                            id="HoldToggle"
                                                            className="toggle"
                                                            onChange={(
                                                                e: any
                                                            ) =>
                                                                handleSwitchVendorHold(
                                                                    e
                                                                )
                                                            }
                                                        />
                                                        <label
                                                            className="chkbx"
                                                            htmlFor="HoldToggle"
                                                        ></label>
                                                    </div>
                                                </div>
                                                <div className="col-sm-12 px-0 pt-4 ">
                                                    <div className="col-sm-6 col-md-6 col-lg-5 preview-Key  ">
                                                        <span className="storeNumHeading">
                                                            Vendor #
                                                        </span>
                                                    </div>
                                                    <div className="col-sm-6 col-md-6 col-lg-7 preview-value ">
                                                        <span className="storeNumber">
                                                            {
                                                                info?.apVendorNumber
                                                            }
                                                        </span>
                                                    </div>
                                                </div>
                                                <div className="col-sm-12 px-0 pt-4 ">
                                                    <div className="col-sm-6 col-md-6 col-lg-5 preview-Key  ">
                                                        <span className="storeNumHeading">
                                                            Account #
                                                        </span>
                                                    </div>
                                                    <div className="col-sm-6 col-md-6 col-lg-7 preview-value ">
                                                        <span className="storeNumber">
                                                            {info?.vendorNumber}
                                                        </span>
                                                    </div>
                                                </div>
                                                <hr />
                                                {/* <div className="col-sm-12 px-0 pt-4">
         <div className="col-sm-8 col-md-8 col-lg-8 preview-Key">
        <h5 className="linkedStoreHeading">Type</h5>
        </div>
        <div className="col-sm-4 col-md-4 col-lg-4 preview-value textAlign-right">
        {showAddVendorTypeDropdown?
        <span className="plusIcon iconsForLinking cursor-pointer" onClick={() =>handleCloseVendorTypeDDL()}><img src={closeIcon}/></span>
        :<span className="plusIcon iconsForLinking cursor-pointer" onClick={() =>handleViewVendorTypeDDL()}><img src={plusIcon}/></span>
        }</div>
        </div>  */}
                                                {/* {showAddVendorTypeDropdown?
        <div className="col-sm-12">
        <div className="">
        <select className="form-control" id="ddlCustomerType" onChange={(e) => handleAddVendorType(e)}>
         <option>Select</option>
         <option value="Vendor">Vendor</option>
         <option value="Hold">Hold</option>
         {/* {customerTypeData?.map((ct) =>
        <option value={ct.customerType}>{ct.customerType}</option>
        )}
        </select>
        </div>
        </div>
         :''}
        <div className="col-sm-12">
        {info?.vendorType?.map((ct: any) =>
         <div className="row generalTabWareHouseData ml-0 mr-0">
         <div className="col-sm-4">
        <span className="linkedStoreData">{ct}</span>
        </div>
        <div className="col-sm-6"></div>
        <div className="col-sm-2">
         <span className="linkedStoreData closeIconLinkedActions cursor-pointer" onClick={() => handleRemoveVendorType(ct)}><img src={closeIconCancel} className="imgCanceIcon"/></span>
        </div>
        </div>
        )}
        </div>
      */}
                                            </div>
                                        </div>
                                    </div>
                                ))}
                        </div>
                    ) : setTabHeading.payload === ContactType.CARRIERS &&
                      rowInfo &&
                      rowInfo?.length > 0 ? (
                        <div>
                            {rowInfo &&
                                rowInfo?.map((info) => (
                                    <div className="">
                                        <div className="col-sm-12 px-2 py-4 preview-details d-flex flex-column align-items-start">
                                            <div className="col-sm-12 p-0">
                                                <div className="col-sm-12 px-0 pt-4 ">
                                                    <div className="col-sm-6 col-md-6 col-lg-5 preview-Key">
                                                        <span className="storeNumHeading">
                                                            {' '}
                                                            Name
                                                        </span>
                                                    </div>
                                                    <div className="col-sm-6 col-md-6 col-lg-7 preview-value ">
                                                        <span className="storeNumber">
                                                            {info?.carrierName}
                                                        </span>
                                                        {/* <input type="text" id="txtCarrierName" className="form-control border-none cursor-pointer txtEditFieldFocus boxShadow-None" onChange={(e: any)=>_handleFocusEditCarrierName(e)} defaultValue={info?.carrierName}
              onBlur={(e: any)=>_handleFocusEditCarrierName(e)} onKeyUp={(e: any)=>_handleFocusEditCarrierName(e)}/>             */}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                        </div>
                    ) : setTabHeading.payload === ContactType.CUSTOMERS &&
                      rowInfo &&
                      rowInfo?.length > 0 ? (
                        <div>
                            {rowInfo &&
                                rowInfo?.map((info) => (
                                    <div className="">
                                        <div className="col-sm-12 px-2 py-4 preview-details d-flex flex-column align-items-start">
                                            <div className="col-sm-12 p-0">
                                                <div className="col-sm-12 px-0 pt-4 ">
                                                    <div className="col-sm-6 col-md-6 col-lg-5 preview-Key">
                                                        <span className="storeNumHeading">
                                                            Name{' '}
                                                        </span>
                                                    </div>
                                                    <div className="col-sm-6 col-md-6 col-lg-7 preview-value ">
                                                        <span
                                                            className="storeNumber lblStoreName"
                                                            title={
                                                                info?.customerName
                                                            }
                                                        >
                                                            {info?.customerName}
                                                        </span>
                                                    </div>
                                                </div>
                                                <div className="col-sm-12 px-0 pt-4 ">
                                                    <div className="col-sm-6 col-md-6 col-lg-5 preview-Key">
                                                        <span className="storeNumHeading">
                                                            Party #{' '}
                                                        </span>
                                                    </div>
                                                    <div className="col-sm-6 col-md-6 col-lg-7 preview-value ">
                                                        <span className="storeNumber">
                                                            {
                                                                info?.customerNumber
                                                            }
                                                        </span>
                                                    </div>
                                                </div>
                                                <div className="col-sm-12 px-0 pt-4 mb-1rem">
                                                    <div className="col-sm-6 col-md-6 col-lg-5 preview-Key">
                                                        <span className="storeNumHeading">
                                                            Account #{' '}
                                                        </span>
                                                    </div>
                                                    <div className="col-sm-6 col-md-6 col-lg-7 preview-value ">
                                                        <span className="storeNumber">
                                                            {
                                                                info?.accountNumber
                                                            }
                                                        </span>
                                                    </div>
                                                </div>
                                                <div>
                                                    <hr className="horizontalLineBar" />
                                                </div>
                                                {/* <Divider/> */}
                                                <div className="col-sm-12 px-0 pt-4 ">
                                                    <div className="col-sm-8 col-md-8 col-lg-8 preview-Key">
                                                        <h5 className="linkedStoreHeading">
                                                            Customer Type
                                                        </h5>
                                                    </div>
                                                    <div className="col-sm-4 col-md-4 col-lg-4 preview-value textAlign-right">
                                                        {showAddCustomerTypeDropdown ? (
                                                            <span
                                                                className="plusIcon iconsForLinking cursor-pointer"
                                                                onClick={() =>
                                                                    handleCloseCustomerTypeDDL()
                                                                }
                                                            >
                                                                <img
                                                                    src={
                                                                        closeIcon
                                                                    }
                                                                />
                                                            </span>
                                                        ) : (
                                                            <span
                                                                className="plusIcon iconsForLinking cursor-pointer"
                                                                onClick={() =>
                                                                    handleViewCustomerTypeDDL()
                                                                }
                                                            >
                                                                <img
                                                                    src={
                                                                        plusIcon
                                                                    }
                                                                />
                                                            </span>
                                                        )}
                                                    </div>
                                                </div>
                                                {showAddCustomerTypeDropdown ? (
                                                    <div className="col-sm-12 px-0 pt-4 ">
                                                        <div className="col-sm-12">
                                                            <select
                                                                className="form-control"
                                                                id="ddlCustomerType"
                                                                onChange={(e) =>
                                                                    handleAddCustomerType(
                                                                        e
                                                                    )
                                                                }
                                                            >
                                                                <option>
                                                                    Select
                                                                </option>
                                                                {customerTypeData?.map(
                                                                    (ct) => (
                                                                        <option
                                                                            value={JSON.stringify(
                                                                                ct
                                                                            )}
                                                                        >
                                                                            {
                                                                                ct.customerType
                                                                            }
                                                                        </option>
                                                                    )
                                                                )}
                                                            </select>
                                                        </div>
                                                    </div>
                                                ) : (
                                                    ''
                                                )}
                                                <div className="col-sm-12">
                                                    {info?.customerType?.map(
                                                        (ct: any) => (
                                                            <div className="row generalTabWareHouseData ml-0 mr-0">
                                                                <div className="col-sm-5 col-md-5 col-lg-5">
                                                                    <span className="linkedStoreData">
                                                                        {ct}
                                                                    </span>
                                                                </div>
                                                                <div className="col-sm-6 col-md-6 col-lg-6"></div>
                                                                <div className="col-sm-1 col-md-1 col-lg-1 p-0">
                                                                    <span
                                                                        className="linkedStoreData closeIconLinkedActions cursor-pointer"
                                                                        onClick={() =>
                                                                            handleRemoveCustomerType(
                                                                                ct
                                                                            )
                                                                        }
                                                                    >
                                                                        <img
                                                                            src={
                                                                                closeIconCancel
                                                                            }
                                                                            className="imgCanceIcon m-auto "
                                                                            style={{
                                                                                margin: '0px',
                                                                            }}
                                                                        />
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        )
                                                    )}
                                                </div>
                                                <hr />
                                                {/* <div className="row generalTabInfo">
                                                    <div className="col-sm-6">
                                                        <span className="storeNumHeading">Payment Terms</span>
                                                    </div>
                                                    <div className="col-sm-6">
                                                        <select className="form-control" onChange = {(e) => handleChangePaymetTerms(e)}>
                                                            <option>{info.paymentTerms}</option>
                                                            {paymentTermsData.map((pt) =>
                                                            <option value={pt.paymentTerm}>{pt.paymentTerm}</option>
                                                            )}
                                                        </select>

                                                    </div>
                                                </div>  */}
                                                {/* <div className="row generalTabInfo">
                                                    <div className="col-sm-6">
                                                        <span className="storeNumHeading">Adv Payment </span>
                                                    </div>
                                                    <div className="col-sm-6">
                                                    <SwitchComponent checked={true}/>
                                                    </div>
                                                </div> */}
                                                <div className="col-sm-12 px-0 pt-4 ">
                                                    <div className="col-sm-6 col-md-6 col-lg-5 preview-Key">
                                                        <span className="storeNumHeading">
                                                            Prepaid{' '}
                                                        </span>
                                                    </div>
                                                    <div className="col-sm-6 col-md-6 col-lg-7 preview-value ">
                                                        {/* <SwitchComponent checked={info?.prepaid} onChange={(e: any) =>handleChangeCustomerPrepaid(e)}/> */}
                                                        <input
                                                            type="checkbox"
                                                            defaultChecked={
                                                                info?.prepaid
                                                            }
                                                            id="PrepaidToggle"
                                                            className="toggle"
                                                            onChange={(
                                                                e: any
                                                            ) =>
                                                                handleChangeCustomerPrepaid(
                                                                    e
                                                                )
                                                            }
                                                        />
                                                        <label
                                                            className="chkbx"
                                                            htmlFor="PrepaidToggle"
                                                        ></label>
                                                    </div>
                                                </div>
                                                <div className="col-sm-12 px-0 pt-4 ">
                                                    <div className="col-sm-6 col-md-6 col-lg-5 preview-Key">
                                                        <span className="storeNumHeading">
                                                            Billing Mode
                                                        </span>
                                                    </div>
                                                    <div className="col-sm-6 col-md-6 col-lg-7 preview-value ">
                                                        {billingModeData?.map(
                                                            (bm) => (
                                                                <>
                                                                    <input
                                                                        type="radio"
                                                                        name="billingType"
                                                                        value={
                                                                            bm.billingMode
                                                                        }
                                                                        onChange={(
                                                                            e: any
                                                                        ) =>
                                                                            handleChangeCustomerBillingMode(
                                                                                e
                                                                            )
                                                                        }
                                                                        checked={
                                                                            info.billingMode ===
                                                                            bm.billingMode
                                                                        }
                                                                    />{' '}
                                                                    <span>
                                                                        {' '}
                                                                        {
                                                                            bm.billingMode
                                                                        }
                                                                    </span>
                                                                    <br />
                                                                </>
                                                            )
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                        </div>
                    ) : setTabHeading.payload === ContactType.COMPANY_CODES &&
                      rowInfo &&
                      rowInfo?.length > 0 ? (
                        <div>
                            {rowInfo &&
                                rowInfo?.map((info) => (
                                    <div className="">
                                        <div className="col-sm-12 px-2 py-4 preview-details d-flex flex-column align-items-start">
                                            <div className="col-sm-12 p-0">
                                                <div className="col-sm-12 px-0 pt-4 ">
                                                    <div className="col-sm-6 col-md-6 col-lg-5 preview-Key">
                                                        <span className="storeNumHeading">
                                                            Code
                                                        </span>
                                                    </div>
                                                    <div className="col-sm-6 col-md-6 col-lg-7 preview-value ">
                                                        <span className="storeNumber">
                                                            {info?.id}
                                                        </span>
                                                    </div>
                                                </div>
                                                <div className="col-sm-12 px-0 pt-4 ">
                                                    <div className="col-sm-6 col-md-6 col-lg-5 preview-Key">
                                                        <span className="storeNumHeading mt-1rem">
                                                            Name
                                                        </span>
                                                    </div>
                                                    <div className="col-sm-6 col-md-6 col-lg-7 preview-value ">
                                                        {/* <InlineInputEdit inputClassName="txtEditClassNameInput"
                text={info.companyName}
                labelClassName="txtEditClassName"
                onFocus={_handleFocusEditCompanyCodeName}
                onFocusOut={_handleFocusOutEditCompanyCodeName}
                labelPlaceHolder='- - -'/>   */}
                                                        {/* <span className="storeNumber">{info.companyName}</span> */}
                                                        <input
                                                            type="text"
                                                            title={
                                                                info?.companyName
                                                            }
                                                            id="txtCompanyName"
                                                            className="form-control border-none pl-0 cursor-pointer txtEditFieldFocus boxShadow-None textOverlap"
                                                            onChange={(
                                                                e: any
                                                            ) =>
                                                                _handleFocusEditCompanyCodeName(
                                                                    e
                                                                )
                                                            }
                                                            defaultValue={
                                                                info?.companyName
                                                            }
                                                            onBlur={(e: any) =>
                                                                _handleFocusEditCompanyCodeName(
                                                                    e
                                                                )
                                                            }
                                                            onKeyUp={(e: any) =>
                                                                _handleFocusEditCompanyCodeName(
                                                                    e
                                                                )
                                                            }
                                                        />
                                                        {info.companyName ===
                                                        '' ? (
                                                            <span className="text-danger">
                                                                {
                                                                    errors.nameError
                                                                }
                                                            </span>
                                                        ) : (
                                                            ''
                                                        )}
                                                    </div>
                                                </div>
                                                <div className="col-sm-12 px-0 pt-4">
                                                    <div className="col-sm-6 col-md-6 col-lg-5 preview-Key">
                                                        <span className="storeNumHeading">
                                                            Client ID
                                                        </span>
                                                    </div>
                                                    <div className="col-sm-6 col-md-6 col-lg-7 preview-value ">
                                                        <span className="storeNumber">
                                                            {
                                                                info?.client
                                                                    ?.clientName
                                                            }
                                                        </span>
                                                    </div>
                                                </div>
                                                <div className="horizontalLineBarCompanyCode" />
                                                {/* <div className="row generalTabWareHouseInfo">
                                                        <div className="col-sm-8">
                                                            <h5 className="linkedStoreHeading">Linked Reclaim Centers</h5>
                                                        </div> */}
                                                {/* <div className="col-sm-4">
                                                            <span className="plusIcon">+</span>
                                                        </div> */}
                                                {/* </div> */}
                                                {/* <div className="row generalTabWareHouseData ml-0 mr-0">
                                                        <div className="col-sm-9">
                                                            <span className="linkedStoreData">{info.companyName}</span>
                                                        </div> */}
                                                {/* <div className="col-sm-3">
                                                            <span className="linkedStoreData" onClick={() =>handleCompanyNameRemove(info.companyCode)}>X</span>
                                                        </div> */}
                                                {/* </div> */}
                                                {/* <hr/> */}
                                                <div className="col-sm-12 px-0 pt-4">
                                                    <div className="col-sm-6 col-md-6 col-lg-5 preview-Key">
                                                        <span className="storeNumHeading mt-1rem">
                                                            Web URL
                                                        </span>
                                                    </div>
                                                    <div className="col-sm-6 col-md-6 col-lg-7 preview-value ">
                                                        {/* <InlineInputEdit inputClassName="txtEditClassNameInput"
                text={info.webUrl}
                labelClassName="txtEditClassName"
                onFocus={_handleFocusEditWebURL}
                onFocusOut={_handleFocusOutEditWebURL}
                labelPlaceHolder='- - -'/>   */}
                                                        <input
                                                            type="text"
                                                            className="form-control boxShadow-None border-none cursor-pointer pl-0 txtEditFieldFocus"
                                                            onChange={(
                                                                e: any
                                                            ) =>
                                                                _handleFocusEditWebURL(
                                                                    e
                                                                )
                                                            }
                                                            defaultValue={
                                                                info?.webUrl
                                                            }
                                                            onBlur={(e: any) =>
                                                                _handleFocusEditWebURL(
                                                                    e
                                                                )
                                                            }
                                                            onKeyUp={(e: any) =>
                                                                _handleFocusEditWebURL(
                                                                    e
                                                                )
                                                            }
                                                        />
                                                        {errors.webURLError ? (
                                                            <span className="text-danger">
                                                                {
                                                                    errors.webURLError
                                                                }
                                                            </span>
                                                        ) : (
                                                            ''
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                        </div>
                    ) : setTabHeading.payload === ContactType.GL_ACCOUNTS &&
                      rowInfo &&
                      rowInfo?.length > 0 ? (
                        <div className=" ">
                            {rowInfo &&
                                rowInfo?.map((info) => (
                                    <div>
                                        <div className="col-sm-12 px-2 py-4 preview-details d-flex flex-column align-items-start">
                                            <div className="col-sm-12 p-0">
                                                <div className="col-sm-12 px-0 pt-4 ">
                                                    <div className="col-sm-6 col-md-6 col-lg-5 preview-Key">
                                                        <span className="storeNumHeading">
                                                            Name
                                                        </span>
                                                    </div>
                                                    <div className="col-sm-6 col-md-6 col-lg-7 preview-value ">
                                                        <span className="storeNumber">
                                                            {
                                                                info?.glAccountName
                                                            }
                                                        </span>
                                                    </div>
                                                </div>
                                                <div className="col-sm-12 px-0 pt-4 mb-1rem">
                                                    <div className="col-sm-6 col-md-6 col-lg-5 preview-Key">
                                                        <span className="storeNumHeading">
                                                            Account #
                                                        </span>
                                                    </div>
                                                    <div className="col-sm-6 col-md-6 col-lg-7 preview-value ">
                                                        <span
                                                            className="storeNumber"
                                                            style={{
                                                                wordBreak:
                                                                    'break-word',
                                                            }}
                                                        >
                                                            {
                                                                info?.glAccountNumber
                                                            }
                                                        </span>
                                                    </div>
                                                </div>
                                                <hr className="horizontalLineBar" />
                                                <div className="col-sm-12 px-0 pt-4 ">
                                                    <div className="col-sm-8 col-md-8 col-lg-8 preview-Key">
                                                        <h5 className="linkedStoreHeading">
                                                            Linked Departments
                                                        </h5>
                                                    </div>
                                                    <div className="col-sm-4 col-md-4 col-lg-4 preview-value textAlign-right">
                                                        {showAddDeptDropdown ? (
                                                            <span
                                                                className="plusIcon float-right cursor-pointer"
                                                                onClick={() =>
                                                                    handleCloseDropdownDeptInGLAcc()
                                                                }
                                                            >
                                                                {' '}
                                                                <img
                                                                    src={
                                                                        closeIcon
                                                                    }
                                                                />{' '}
                                                            </span>
                                                        ) : (
                                                            <span
                                                                className="plusIcon float-right cursor-pointer"
                                                                onClick={() =>
                                                                    handleAddDeptInGLAcc()
                                                                }
                                                            >
                                                                <img
                                                                    src={
                                                                        plusIcon
                                                                    }
                                                                />
                                                            </span>
                                                        )}
                                                    </div>
                                                </div>
                                                {showAddDeptDropdown ? (
                                                    <div className="col-sm-12 pt-4">
                                                        <div className="col-sm-12 col-md-12 col-lg-12 preview-Key">
                                                            <select
                                                                className="form-control"
                                                                id="ddlAddDepartments"
                                                                onChange={(e) =>
                                                                    handleDeptInGLAcc(
                                                                        e
                                                                    )
                                                                }
                                                            >
                                                                <option>
                                                                    Select
                                                                </option>
                                                                {deptData?.map(
                                                                    (d) => (
                                                                        <option
                                                                            value={JSON.stringify(
                                                                                d
                                                                            )}
                                                                        >
                                                                            {
                                                                                d.departmentName
                                                                            }
                                                                        </option>
                                                                    )
                                                                )}
                                                            </select>
                                                        </div>
                                                    </div>
                                                ) : (
                                                    ''
                                                )}
                                                <div className="col-sm-12 glAccInDept">
                                                    {info?.departments?.map(
                                                        (deptInfo: any) => (
                                                            <div>
                                                                <div className="col-sm-12 col-lg-12 col-md-12 generalTabWareHouseData p-0 p-0 ">
                                                                    <div className="col-sm-11 col-md-11 col-lg-11 ">
                                                                        <span className="linkedStoreData">
                                                                            {
                                                                                deptInfo?.departmentName
                                                                            }
                                                                        </span>
                                                                    </div>
                                                                    <div className="col-md-1 col-lg-1 col-sm-1 pl-0">
                                                                        <span
                                                                            className="linkedStoreData closeIconLinkedActions cursor-pointer"
                                                                            onClick={() =>
                                                                                handleRemoveDeptINGLAcc(
                                                                                    deptInfo
                                                                                )
                                                                            }
                                                                        >
                                                                            <img
                                                                                src={
                                                                                    closeIconCancel
                                                                                }
                                                                                className="imgCanceIcon m-auto"
                                                                                style={{
                                                                                    margin: '0',
                                                                                }}
                                                                            />
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                        </div>
                    ) : setTabHeading.payload === ContactType.DEPARTMENTS &&
                      rowInfo &&
                      rowInfo.length > 0 ? (
                        <div className=" ">
                            {rowInfo &&
                                rowInfo.map((info) => (
                                    <div>
                                        <div className="col-sm-12 px-2 py-4 preview-details d-flex flex-column align-items-start">
                                            <div className="col-sm-12 p-0">
                                                <div className="col-sm-12 px-0 pt-4 mb-1rem">
                                                    <div className="col-sm-6 col-md-6 col-lg-5 preview-Key">
                                                        <span className="storeNumHeading">
                                                            Name
                                                        </span>
                                                    </div>
                                                    <div className="col-sm-6 col-md-6 col-lg-7 preview-value ">
                                                        <span className="storeNumber">
                                                            {
                                                                info?.departmentName
                                                            }
                                                        </span>
                                                    </div>
                                                </div>
                                                <hr className="horizontalLineBar" />
                                                <div className="col-sm-12 px-0 pt-4 ">
                                                    <div className="col-sm-8 col-md-8 col-lg-8 preview-Key">
                                                        <h5 className="linkedStoreHeading">
                                                            Linked GL Accounts
                                                        </h5>
                                                    </div>

                                                    <div className="col-sm-4 col-md-4 col-lg-4 preview-value textAlign-right">
                                                        {showAddGLAccDropdown ? (
                                                            <span
                                                                className="plusIcon iconsForLinking cursor-pointer"
                                                                onClick={() =>
                                                                    handleCloseGLAccountDropdown()
                                                                }
                                                            >
                                                                {' '}
                                                                <img
                                                                    src={
                                                                        closeIcon
                                                                    }
                                                                />{' '}
                                                            </span>
                                                        ) : (
                                                            <span
                                                                className="plusIcon iconsForLinking cursor-pointer"
                                                                onClick={() =>
                                                                    handleAddGLAccInDept()
                                                                }
                                                            >
                                                                <img
                                                                    src={
                                                                        plusIcon
                                                                    }
                                                                />
                                                            </span>
                                                        )}
                                                    </div>
                                                </div>
                                                {showAddGLAccDropdown ? (
                                                    <div className="col-sm-12 pt-4 ">
                                                        <div className="col-sm-12 col-md-12">
                                                            <select
                                                                className="form-control"
                                                                id="ddlGLAccInDept"
                                                                onChange={(e) =>
                                                                    handleGLAccInDept(
                                                                        e
                                                                    )
                                                                }
                                                            >
                                                                <option>
                                                                    Select
                                                                </option>
                                                                {glAccData?.map(
                                                                    (gl) => (
                                                                        <option
                                                                            value={JSON.stringify(
                                                                                gl
                                                                            )}
                                                                        >
                                                                            {
                                                                                gl?.glAccountName
                                                                            }{' '}
                                                                            (
                                                                            {
                                                                                gl?.glAccountNumber
                                                                            }
                                                                            )
                                                                        </option>
                                                                    )
                                                                )}
                                                            </select>
                                                        </div>
                                                    </div>
                                                ) : (
                                                    ''
                                                )}
                                                <div className="col-sm-12 glAccInDept">
                                                    {info?.glAccounts?.map(
                                                        (glInfo: any) => (
                                                            <div>
                                                                <div className="row generalTabWareHouseData ml-0 mr-0">
                                                                    <div className="col-sm-5 col-xl-5 col-md-5">
                                                                        <span className="linkedDeptInfoInGL">
                                                                            {
                                                                                glInfo?.glAccountName
                                                                            }
                                                                        </span>
                                                                    </div>
                                                                    <div className="col-sm-6 col-xl-6 col-md-6 glAccountNumSpan pl-0">
                                                                        <span className="linkedDeptInfoInGL">
                                                                            (
                                                                            {
                                                                                glInfo?.glAccountNumber
                                                                            }
                                                                            )
                                                                        </span>
                                                                    </div>
                                                                    <div className="col-sm-1 col-xl-1 col-md-1 p-0">
                                                                        <span
                                                                            className="linkedStoreData closeIconLinkedActions cursor-pointer"
                                                                            onClick={() =>
                                                                                handleRemoveGLAccInDept(
                                                                                    glInfo
                                                                                )
                                                                            }
                                                                        >
                                                                            <img
                                                                                src={
                                                                                    closeIconCancel
                                                                                }
                                                                                className="imgCanceIcon ml-0"
                                                                            />
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                        </div>
                    ) : setTabHeading.payload === ContactType.COMMODITY_TYPES &&
                      rowInfo &&
                      rowInfo?.length > 0 ? (
                        <div>
                            {rowInfo &&
                                rowInfo?.map((info) => (
                                    <div>
                                        <div className="col-sm-12 px-2 py-4 preview-details d-flex flex-column align-items-start">
                                            <div className="col-sm-12 p-0">
                                                <div className="col-sm-12 px-0 pt-4 ">
                                                    <div className="col-sm-6 col-md-6 col-lg-5 preview-Key">
                                                        <span className="storeNumHeading">
                                                            Code
                                                        </span>
                                                    </div>
                                                    <div className="col-sm-6 col-md-6 col-lg-7 preview-value">
                                                        <InlineInputEdit
                                                            inputClassName="txtEditClassNameInput"
                                                            text={info?.code}
                                                            labelClassName="txtEditClassName"
                                                            onFocus={
                                                                _handleFocusEditCommodityCode
                                                            }
                                                            onFocusOut={
                                                                _handleFocusOutEditCommodityCode
                                                            }
                                                            labelPlaceHolder="- - -"
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-sm-12 px-0 pt-4 ">
                                                    <div className="col-sm-6 col-md-6 col-lg-5 preview-Key">
                                                        <span className="storeNumHeading upcDispoHeadingSD">
                                                            Pricing Type
                                                        </span>
                                                    </div>
                                                    <div className="col-sm-6 col-md-6 col-lg-7 preview-value">
                                                        <select
                                                            className="form-control border-none pl-0 storeNumber ddlFocus boxShadow-None"
                                                            value={
                                                                info?.pricingType
                                                            }
                                                            onChange={(e) =>
                                                                handleChangePricingType(
                                                                    e
                                                                )
                                                            }
                                                        >
                                                            {/* <option value={info?.pricingType}>{info?.pricingType}</option> */}
                                                            {pricingTypeData?.map(
                                                                (pt) => (
                                                                    <option
                                                                        value={
                                                                            pt.pricingType
                                                                        }
                                                                    >
                                                                        {
                                                                            pt.pricingType
                                                                        }
                                                                    </option>
                                                                )
                                                            )}
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className="col-sm-12 px-0 pt-4 ">
                                                    <div className="col-sm-6 col-md-6 col-lg-5 preview-Key">
                                                        <span
                                                            className="storeNumHeading"
                                                            style={{
                                                                marginTop:
                                                                    '7px',
                                                            }}
                                                        >
                                                            Price{' '}
                                                            {pricingTypeSymbal}
                                                        </span>
                                                    </div>

                                                    <div className="col-sm-6 col-md-6 col-lg-7 preview-value">
                                                        <input
                                                            type="text"
                                                            className="form-control border-none pl-0 cursor-pointer txtEditFieldFocus boxShadow-None"
                                                            value={info?.price}
                                                            onChange={(
                                                                e: any
                                                            ) =>
                                                                handleEditCommodityPrice(
                                                                    e
                                                                )
                                                            }
                                                        />
                                                        {/* <InlineInputEdit inputClassName="txtEditClassNameInput"
                        text={info.price}
                        labelClassName="txtEditClassName"
                        onFocus={_handleFocusEditCommodityPrice}
                        onFocusOut={_handleFocusOutEditCommodityPrice}
                        labelPlaceHolder='- - -'/>   */}
                                                        {/* <span className="storeNumber">{info.price}</span> */}
                                                    </div>
                                                </div>
                                                <div className="col-sm-12 px-0 pt-4 ">
                                                    <div className="col-sm-6 col-md-6 col-lg-5 preview-Key">
                                                        <span className="storeNumHeading">
                                                            Description
                                                        </span>
                                                    </div>
                                                    <div className="col-sm-6 col-md-6 col-lg-7 preview-value">
                                                        <InlineInputEdit
                                                            inputClassName="txtEditClassNameInput"
                                                            text={
                                                                info?.description
                                                            }
                                                            labelClassName="txtEditClassName"
                                                            onFocus={
                                                                _handleFocusEditCommodityDesc
                                                            }
                                                            onFocusOut={
                                                                _handleFocusOutEditCommodityDesc
                                                            }
                                                            labelPlaceHolder="- - -"
                                                        />
                                                        {/* <span className="storeNumber">{info.description}</span> */}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                        </div>
                    ) : //  : (setTabHeading.payload === ContactType.PALLET_LOCATIONS && setRowData && setRowData.length > 0) ?
                    //  <div>
                    //      {rowInfo && rowInfo?.map((info) => (
                    //         <div className="p-15 height-350">
                    //         <div className="row generalTabInfo">
                    //              <div className="col-sm-6">
                    //                  <span className="storeNumHeading">Pallet Name</span>
                    //              </div>
                    //              <div className="col-sm-6">
                    //              <select className="form-control border-none" onChange = {(e) => handleChangePricingType(e)}>
                    //                  <option>{info.palletName}</option>
                    //                  {palletNameData?.map((pt) =>
                    //                  <option value={pt.palletName}>{pt.palletName}</option>
                    //                  )}
                    //              </select>
                    //              </div>
                    //          </div>
                    //          <div className="row generalTabInfo">
                    //           <div className="col-sm-6">
                    //               <span className="storeNumHeading">Scan Group</span>
                    //           </div>
                    //           <div className="col-sm-6">
                    //              <select className="form-control border-none" onChange = {(e) => handleChangePricingType(e)}>
                    //                  <option>{info.scanGroup}</option>
                    //                  {sacnGroupData?.map((pt) =>
                    //                  <option value={pt.scanGroup}>{pt.scanGroup}</option>
                    //                  )}
                    //              </select>
                    //           </div>
                    //          </div>
                    //          <div className="row generalTabInfo">
                    //           <div className="col-sm-6">
                    //               <span className="storeNumHeading">Reclaim Center</span>
                    //           </div>
                    //           <div className="col-sm-6">
                    //           <select className="form-control border-none" onChange = {(e) => handleChangePricingType(e)}>
                    //                  <option>{info?.reclaimCenter?.reclaimcenterName}</option>
                    //                  {reclaimCentersData?.map((st) =>
                    //                     <option value={JSON.stringify(st)}>{st.reclaimcenterName}</option>
                    //                     )}
                    //              </select>
                    //           </div>
                    //          </div>
                    //          <div className="row generalTabInfo">
                    //           <div className="col-sm-6">
                    //               <span className="storeNumHeading">Position</span>
                    //           </div>
                    //           <div className="col-sm-6">
                    //          <input type="text" value={info.position}/>
                    //           </div>
                    //          </div>
                    //          <div className="row generalTabInfo">
                    //           <div className="col-sm-6">
                    //               <span className="storeNumHeading">Has Pallet</span>
                    //           </div>
                    //           <div className="col-sm-6">
                    //           <input type="radio" name="hasPallet" value="true" /> <span> Yes</span>
                    //           <input type="radio" name="hasPallet" value="false" /> <span> No</span>
                    //           </div>
                    //          </div>
                    //          <div className="row generalTabInfo">
                    //           <div className="col-sm-6">
                    //               <span className="storeNumHeading">Location Enabled</span>
                    //           </div>
                    //           <div className="col-sm-6">
                    //           <input type="radio" name="hasPallet" value="true" /> <span> Yes</span>
                    //           <input type="radio" name="hasPallet" value="false" /> <span> No</span>
                    //           </div>
                    //          </div>
                    //       </div>
                    //      ))}
                    //  </div>
                    setTabHeading.payload === ContactType.STORE_DISPOSITIONS &&
                      rowInfo &&
                      rowInfo?.length > 0 ? (
                        <div>
                            {rowInfo &&
                                rowInfo?.map((info) => (
                                    <div>
                                        <div className="col-sm-12 px-2 py-4 preview-details d-flex flex-column align-items-start">
                                            <div className="col-sm-12 p-0">
                                                <div className="col-sm-12 px-0 pt-4 mb-1rem">
                                                    <div className="col-sm-6 col-md-6 col-lg-5 preview-Key">
                                                        <span
                                                            className="storeNumHeading upcDispoHeadingSD"
                                                            style={{
                                                                whiteSpace:
                                                                    'normal',
                                                            }}
                                                        >
                                                            UPC Disposition{' '}
                                                        </span>
                                                    </div>
                                                    <div
                                                        className="col-sm-6 col-md-6 col-lg-7 preview-value"
                                                        style={{
                                                            marginTop:
                                                                '10px !important',
                                                        }}
                                                    >
                                                        <select
                                                            className="form-control border-none pl-0"
                                                            value={
                                                                info
                                                                    ?.disposition
                                                                    ?.id
                                                            }
                                                            onChange={(
                                                                e: any
                                                            ) =>
                                                                handleChangeUPCDispoInStoreDispo(
                                                                    e
                                                                )
                                                            }
                                                        >
                                                            {/* <option value={info?.disposition.id}>{info?.disposition.disposition}</option> */}
                                                            {upcDefaultData?.map(
                                                                (pt) => (
                                                                    <option
                                                                        value={
                                                                            pt.id
                                                                        }
                                                                    >
                                                                        {
                                                                            pt.disposition
                                                                        }
                                                                    </option>
                                                                )
                                                            )}
                                                        </select>
                                                    </div>
                                                </div>

                                                <div className="col-sm-12 px-0 pt-4 mb-1rem">
                                                    <div className="col-sm-6 col-md-6 col-lg-5 preview-Key">
                                                        <span
                                                            className="storeNumHeading"
                                                            style={{
                                                                whiteSpace:
                                                                    'normal',
                                                            }}
                                                        >
                                                            Store Disposition
                                                        </span>
                                                    </div>
                                                    <div
                                                        className="col-sm-6 col-md-6 col-lg-7 mt-1 preview-value"
                                                        style={{
                                                            marginTop:
                                                                '10px !important',
                                                        }}
                                                    >
                                                        <InlineInputEdit
                                                            inputClassName="txtEditClassNameInput"
                                                            text={
                                                                info?.storeDisposition
                                                            }
                                                            labelClassName="txtEditClassName"
                                                            onFocus={
                                                                _handleFocusEditStoreDipoInStoreDispo
                                                            }
                                                            onFocusOut={
                                                                _handleFocusOutEditStoreDipoInStoreDispo
                                                            }
                                                            labelPlaceHolder="- - -"
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-sm-12 px-0 pt-4 mb-1rem">
                                                    <div className="col-sm-6 col-md-6 col-lg-5 preview-Key">
                                                        <span className="storeNumHeading">
                                                            Voice
                                                        </span>
                                                    </div>
                                                    <div className="col-sm-6 col-md-6 col-lg-7 preview-value">
                                                        <InlineInputEdit
                                                            inputClassName="txtEditClassNameInput"
                                                            text={
                                                                info?.storeDispositionVoiceText
                                                            }
                                                            labelClassName="txtEditClassName"
                                                            onFocus={
                                                                _handleFocusEditVoiceTextInStoreDispo
                                                            }
                                                            onFocusOut={
                                                                _handleFocusOutEditVoiceTextInStoreDispo
                                                            }
                                                            labelPlaceHolder="- - -"
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-sm-12 px-0 pt-4 mb-1rem">
                                                    <div className="col-sm-6 col-md-6 col-lg-5 preview-Key">
                                                        <span
                                                            className="storeNumHeading"
                                                            style={{
                                                                marginTop:
                                                                    '6px',
                                                            }}
                                                        >
                                                            Auth Flag
                                                        </span>
                                                    </div>
                                                    <div className="col-sm-6 col-md-6 col-lg-7 preview-value">
                                                        <select
                                                            className="form-control border-none pl-0"
                                                            value={
                                                                info?.authorizationFlag
                                                            }
                                                            onChange={(
                                                                e: any
                                                            ) =>
                                                                handleChangeAuthFlagInStoreDispo(
                                                                    e
                                                                )
                                                            }
                                                        >
                                                            {/* <option value={info?.authorizationFlag}>{info?.authorizationFlag}</option>                         */}
                                                            <option value="Y">
                                                                Y
                                                            </option>
                                                            <option value="N">
                                                                N
                                                            </option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                        </div>
                    ) : setTabHeading.payload === ContactType.UPCDefaultWHLoc &&
                      rowInfo &&
                      rowInfo?.length > 0 ? (
                        <div>
                            {rowInfo &&
                                rowInfo?.map((info) => (
                                    <div className="col-sm-12 px-2 py-4 preview-details d-flex flex-column align-items-start">
                                        <div className="col-sm-12 p-0">
                                            <div className="col-sm-12 px-0 pt-4 mb-1rem">
                                                <div className="col-sm-6 col-md-6 col-lg-5 preview-Key">
                                                    <span className="storeNumHeading upcDispoHeading">
                                                        UPC Disposition
                                                    </span>
                                                </div>
                                                <div className="col-sm-6 col-md-6 col-lg-7 preview-value">
                                                    <select
                                                        className="form-control border-none pl-0"
                                                        value={
                                                            info?.disposition
                                                        }
                                                        onChange={(e: any) =>
                                                            handleChangeUPCDispoInWHloc(
                                                                e
                                                            )
                                                        }
                                                    >
                                                        {/* <option value={info?.disposition}>{info?.disposition}</option> */}
                                                        {upcDefaultData?.map(
                                                            (pt) => (
                                                                <option
                                                                    value={
                                                                        pt.disposition
                                                                    }
                                                                >
                                                                    {
                                                                        pt.disposition
                                                                    }
                                                                </option>
                                                            )
                                                        )}
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-sm-12 px-0 pt-4 mb-1rem">
                                                <div className="col-sm-6 col-md-6 col-lg-5 preview-Key">
                                                    <span className="storeNumHeading">
                                                        Whse Location
                                                    </span>
                                                </div>
                                                <div className="col-sm-6 col-md-6 col-lg-7 preview-value">
                                                    <InlineInputEdit
                                                        inputClassName="txtEditClassNameInput"
                                                        text={
                                                            info?.warehouseLocation
                                                        }
                                                        labelClassName="txtEditClassName"
                                                        onFocus={
                                                            _handleFocusEditWHLocInWHLoc
                                                        }
                                                        onFocusOut={
                                                            _handleFocusOutEditWHLocInWHLoc
                                                        }
                                                        labelPlaceHolder="- - -"
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-sm-12 px-0 pt-4 mb-1rem">
                                                <div className="col-sm-6 col-md-6 col-lg-5 preview-Key">
                                                    <span className="storeNumHeading">
                                                        Voice
                                                    </span>
                                                </div>
                                                <div className="col-sm-6 col-md-6 col-lg-7 preview-value">
                                                    <InlineInputEdit
                                                        inputClassName="txtEditClassNameInput"
                                                        text={
                                                            info?.dispositionVoiceText
                                                        }
                                                        labelClassName="txtEditClassName"
                                                        onFocus={
                                                            _handleFocusEditVoiceTextInWhLoc
                                                        }
                                                        onFocusOut={
                                                            _handleFocusOutEditVoiceTextInWhLoc
                                                        }
                                                        labelPlaceHolder="- - -"
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-sm-12 px-0 pt-4 mb-1rem">
                                                <div className="col-sm-6 col-md-6 col-lg-5 preview-Key">
                                                    <span className="storeNumHeading">
                                                        Max Box/Pallet
                                                    </span>
                                                </div>
                                                <div className="col-sm-6 col-md-6 col-lg-7 preview-value">
                                                    <InlineInputEdit
                                                        inputClassName="txtEditClassNameInput"
                                                        text={info?.boxMaxCount?.toString()}
                                                        labelClassName="txtEditClassName"
                                                        onFocus={
                                                            _handleFocusEditMaxBoxInWhLoc
                                                        }
                                                        onFocusOut={
                                                            _handleFocusOutEditMaxBoxInWhLoc
                                                        }
                                                        labelPlaceHolder="- - -"
                                                    />
                                                    {/* <span className="storeNumber">{}</span> */}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                        </div>
                    ) : setTabHeading.payload === ContactType.CustomWHLoc &&
                      rowInfo &&
                      rowInfo?.length > 0 ? (
                        <div>
                            {rowInfo &&
                                rowInfo?.map((info) => (
                                    <div className="col-sm-12 px-2 py-4 preview-details d-flex flex-column align-items-start">
                                        <div className="col-sm-12 p-0">
                                            <div className="col-sm-12 px-0 pt-4 mb-1rem">
                                                <div className="col-sm-6 col-md-6 col-lg-5 preview-Key">
                                                    <span className="storeNumHeading upcDispoHeading">
                                                        UPC Disposition
                                                    </span>
                                                </div>
                                                <div className="col-sm-6 col-md-6 col-lg-7 preview-value">
                                                    {/* <span className="storeNumber">{info?.disposition}</span> */}
                                                    <input
                                                        type="text"
                                                        className="form-control border-none cursor-pointer txtEditFieldFocus boxShadow-None pl-0"
                                                        onChange={(e: any) =>
                                                            handleChangeUPCDispoInWHloc(
                                                                e
                                                            )
                                                        }
                                                        defaultValue={
                                                            info?.disposition
                                                        }
                                                        onBlur={(e: any) =>
                                                            handleChangeUPCDispoInWHloc(
                                                                e
                                                            )
                                                        }
                                                        onKeyUp={(e: any) =>
                                                            handleChangeUPCDispoInWHloc(
                                                                e
                                                            )
                                                        }
                                                    />
                                                    {/* <select className="form-control border-none" onChange={(e: any) => handleChangeUPCDispoInWHloc(e)}>
                            <option value={info?.disposition}>{info?.disposition}</option>
                            {dispositionInfo?.map((pt) =>
                            <option value={pt.disposition}>{pt.disposition}</option>
                            )}
                             </select> */}
                                                </div>
                                            </div>
                                            <div className="col-sm-12 px-0 pt-4 mb-1rem">
                                                <div className="col-sm-6 col-md-6 col-lg-5 preview-Key">
                                                    <span className="storeNumHeading">
                                                        Whse Location
                                                    </span>
                                                </div>
                                                <div className="col-sm-6 col-md-6 col-lg-7 preview-value">
                                                    <InlineInputEdit
                                                        inputClassName="txtEditClassNameInput"
                                                        text={
                                                            info?.warehouseLocation
                                                        }
                                                        labelClassName="txtEditClassName"
                                                        onFocus={
                                                            _handleFocusEditWHLocInWHLoc
                                                        }
                                                        onFocusOut={
                                                            _handleFocusOutEditWHLocInWHLoc
                                                        }
                                                        labelPlaceHolder="- - -"
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-sm-12 px-0 pt-4 mb-1rem">
                                                <div className="col-sm-6 col-md-6 col-lg-5 preview-Key">
                                                    <span className="storeNumHeading">
                                                        Voice
                                                    </span>
                                                </div>
                                                <div className="col-sm-6 col-md-6 col-lg-7 preview-value">
                                                    <InlineInputEdit
                                                        inputClassName="txtEditClassNameInput"
                                                        text={
                                                            info?.dispositionVoiceText
                                                        }
                                                        labelClassName="txtEditClassName"
                                                        onFocus={
                                                            _handleFocusEditVoiceTextInWhLoc
                                                        }
                                                        onFocusOut={
                                                            _handleFocusOutEditVoiceTextInWhLoc
                                                        }
                                                        labelPlaceHolder="- - -"
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-sm-12 px-0 pt-4 mb-1rem">
                                                <div className="col-sm-6 col-md-6 col-lg-5 preview-Key">
                                                    <span className="storeNumHeading">
                                                        Max Box/Pallet
                                                    </span>
                                                </div>
                                                <div className="col-sm-6 col-md-6 col-lg-7 preview-value">
                                                    <InlineInputEdit
                                                        inputClassName="txtEditClassNameInput"
                                                        text={info?.boxMaxCount?.toString()}
                                                        labelClassName="txtEditClassName"
                                                        onFocus={
                                                            _handleFocusEditMaxBoxInWhLoc
                                                        }
                                                        onFocusOut={
                                                            _handleFocusOutEditMaxBoxInWhLoc
                                                        }
                                                        labelPlaceHolder="- - -"
                                                    />
                                                    {/* <span className="storeNumber">{}</span> */}
                                                </div>
                                            </div>
                                            <div className="col-sm-12 px-0 pt-4 ">
                                            <div className="col-sm-6 col-md-6 col-lg-6 preview-Key  ">
                                            FTT Enabled
                                            </div>
                                {isFttEnabled ? (
                                <div className="col-sm-6 col-md-6 col-lg-5 preview-Key pl-0">
                    <img
                        src={toggleIconOn}
                        alt="close "
                        className="p-0"
                        onClick={() => handleFTTEnable(false)}
                    />
                  </div>
              ) : (
                  <div className="col-sm-6 col-md-6 col-lg-5 preview-Key pl-0">
                    <img
                        src={toggleIconOff}
                        alt="close "
                        className="p-0"
                        onClick={() => handleFTTEnable(true)}
                    />
                  </div>
              )}
            </div>
                                            </div>
                                            
                                        </div>
                                
                                ))}
                        </div>
                    ) : (
                        <div></div>
                    )}
                </>
            )}

            <div className="col-sm-12 pt-1">
                <div className="col-sm-6 pl-1">
                    {showUpdateCancelIcons ? (
                        <>
                            <img
                                className="updateIconImg "
                                onClick={handleUpdate}
                                src={updateIcon}
                            />
                            <img
                                className="updateIconImg "
                                src={cancelIcon}
                                onClick={handleCancel}
                            />
                        </>
                    ) : (
                        ''
                    )}
                </div>
                {setTabHeading.payload !== ContactType.WAREHOUSE_LOCATIONS &&
                setTabHeading.payload !== ContactType.STORE_DISPOSITIONS &&
                setTabHeading.payload !== ContactType.CustomWHLoc &&
                setTabHeading.payload !== ContactType.UPCDefaultWHLoc ? (
                    <>
                        {rowInfo.map((info) => (
                            <div className="col-sm-6 px-0 pt-3">
                                {/* <SwitchComponent checked={info.active} onChange={(e: any) => handleSwitch(e)}/><span className="switchText switchTextTabs">
                          {info.active ? 'Active' : 'Inactive'}</span> */}
                                <input
                                    type="checkbox"
                                    defaultChecked={info.active}
                                    id="generalTabActive"
                                    className="toggle"
                                    onChange={(e: any) => handleSwitch(e)}
                                />
                                <label
                                    className="chkbx"
                                    htmlFor="generalTabActive"
                                    style={{ marginLeft: '45px' }}
                                >
                                    <span className="ActiveInactiveFont">
                                        {info.active ? 'Active' : 'Inactive'}
                                    </span>
                                </label>
                            </div>
                        ))}
                    </>
                ) : (
                    ''
                )}
            </div>
        </div>
    );
};

export default StoreContactView;
