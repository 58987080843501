import { FC } from "react";
import { ReactComponent as Delete } from "../../../icons/deleteNew.svg";
import { ReactComponent as Save } from "../../../icons/saveNew.svg";
import { ButtonComponent } from "@syncfusion/ej2-react-buttons";
import BOLControls from "../BOLcontrols";

const exportMenu = [
  "",
  // {
  //   text: "BOL Details",
  //   exportType: "csv",
  // },
  // {
  //   text: "BOL Summary",
  //   exportType: "csv",
  // },
  //   {
  //   text:"Invoice",
  //   exportType:"csv",
  //   screenType:"InvoiceDetails"
  // },
  // {
  //   text:"Invoice w/o pricing",
  //   exportType:"csv",
  // },
  // {
  //   text:"Invoice details",
  //   exportType:"csv",
  // },
  // {
  //   text:"Invoice details with client",
  //   exportType:"csv",
  // },
  // {
  //   text:"Invoice",
  //   exportType:"pdf",
  // },
  // {
  //   text:"Invoice w/o pricing",
  //   exportType:"pdf",
  // },
  // {
  //   text:"Invoice details",
  //   exportType:"pdf",
  // },
  // {
  //   text:"Invoice details with client",
  //   exportType:"pdf",
  // },
];
interface IProps {
  handleAddInvoice: () => void;
  handleAddToBOL: () => void;
  handleSave: () => void;
  handleDeleteBOL: () => void;
  handleActionHistoryBOL: () => void;
  disableControls?: boolean;
  hideDeleteBtnBOL?: Boolean;
  hideAddBtnBOL?: boolean;
}

const BOLDetailsTabControl: FC<IProps> = ({
  handleAddInvoice,
  handleAddToBOL,
  handleSave,
  handleDeleteBOL,
  handleActionHistoryBOL,
  disableControls,
  hideDeleteBtnBOL,
  hideAddBtnBOL,
}) => {
  return (
    <div style={{ display: "flex" }}>
      <BOLControls
        handleActionHistoryBOLClick={handleActionHistoryBOL}
        handleAddIconClick={handleAddInvoice}
        handleCancelButtonClick={handleAddToBOL}
        exportTypes={exportMenu}
        hideAddBtnBOL={hideAddBtnBOL}
      />
      <span style={{ padding: "5px 0" }}>
        {hideDeleteBtnBOL && (
          <ButtonComponent
            className="iconCopy btnDeleteIcon"
            disabled={disableControls}
            onClick={handleDeleteBOL}
          >
            <Delete />
          </ButtonComponent>
        )}
        <ButtonComponent
          disabled={disableControls}
          className="iconCopy"
          onClick={handleSave}
        >
          <Save color={"green"} />
        </ButtonComponent>
      </span>
    </div>
  );
};

export default BOLDetailsTabControl;
