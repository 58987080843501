import { ButtonComponent } from "@syncfusion/ej2-react-buttons";
import {
  TabComponent,
  TabItemDirective,
  TabItemsDirective,
} from "@syncfusion/ej2-react-navigations";
import { ReactComponent as Add } from "../../../icons/AddNew.svg";
import { ReactComponent as Download } from "../../../icons/download.svg";
import { generateGridData, getLlAdminEndPoint, messageMgmtHeaders } from "../utils";
import axios from "axios";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  setLanguageData,
  setMobileMsgData,
  setReclaimMsgData,
} from "../lladminreducer";
import { setRowData } from "../../grid/gridReducer";
import ExcelService from "../../../services/excel";
import AddLlAdminScreen from "../addScreen/addScreen";
import ReclaimCenter from "./reclaimcenter/reclaimcenter";
import LLAdminMobile from "./mobile/mobile";
import notify from "../../../services/toasterService";
import { RootState } from "../../../redux/store";
import Grid from "../../grid/Grid";
import LLAdminPreviewTab from "../previewTab/previewPane";
import loader from "../../../assets/loader.gif";
// const childTab = [{ text: "Reclaim Scan" }, { text: "Mobile" }];

const reclaimScan = () => {
  return <ReclaimCenter />;
};

const mobile = () => {
  return <LLAdminMobile />;
};

const MessageManagement = () => {
  // const dispatch = useDispatch();

  // const [addScreen, setAddScreen] = useState(false);
  // const [addScreenTabName, setAddScreenTabName] = useState(null);

  // let TabData: any = [];
  // let selectedTab: string;

  // useEffect(() => {
  //   selectedTab = "Reclaim Scan";
  //   messageManagamentTabApi(selectedTab);
  // });

  // const TabChangeEvent = async (e: any) => {
  //   dispatch(setRowData([]));
  //   // console.log(e.selectingIndex, e.selectingItem.innerText);
  //   selectedTab = e.selectingItem.innerText;
  //   await messageManagamentTabApi(e.selectingItem.innerText);
  // };

  // const handlePopupActionLocation = (selectedTabName: any) => {
  //   setAddScreen(true);
  //   setAddScreenTabName(selectedTabName);
  // };

  // /**
  //  * Message management Sub tabs Get WS
  //  * @author Anudeep
  //  * @param TabName - Selected Tab name
  //  */
  // const messageManagamentTabApi = async (TabName: any) => {
  //   const endPointURL = getLlAdminEndPoint(TabName);
  //   await axios
  //     .get(endPointURL)
  //     .then((res) => {
  //       if (TabName === "Reclaim Scan") {
  //         dispatch(setReclaimMsgData(res.data));
  //       } else if (TabName === "Mobile") {
  //         dispatch(setMobileMsgData(res.data));
  //       }
  //       getLanguages("Languages");
  //       TabData = res.data;
  //     })
  //     .catch((err) => {
  //       notify("Failed to fetch data", "error");
  //     });
  // };

  // const getLanguages = async (tabName: any) => {
  //   const endPointURL = getLlAdminEndPoint(tabName);
  //   await axios
  //     .get(endPointURL)
  //     .then((res) => {
  //       if (tabName === "Languages") {
  //         dispatch(setLanguageData(res.data));
  //       }
  //     })
  //     .catch((err) => {
  //       notify("Failed to update data", "error");
  //     });
  // };

  /* Get Client data from Grid Reducer */
  const selectedRowData = useSelector(
    (state: RootState) => state.gridReducer.selectedRowData
  );
  // console.log(selectedRowData, 'selectedRowData');

  /* Get Message Management data from LL admin Reducer */
  const msgMgmtData = useSelector(
    (state: RootState) => state.lladminReducer.msgMgmtData
  );

  let gridHieght = "calc(100vh - 450px)";

  const seletedDataToExport = useSelector(
    (state: RootState) => state.contactReducer.selectedRecordsForExport
  );

  const loaderStatus = useSelector(
    (state: RootState) => state.lladminReducer.isLoaderShow
  );

  const excelExport = () => {
    if (
      seletedDataToExport !== null &&
      seletedDataToExport !== undefined &&
      seletedDataToExport.length > 0
    ) {
      ExcelService.exportAsExcel('','Message Management', seletedDataToExport);
    } else {
      ExcelService.exportAsExcel('','Message Management', msgMgmtData);
    }
  };

  return (

    <div>
      <div className="col-sm-12 col-md-12 col-lg-12 pt-5">
        <div className="col-sm-6 pl-0">
          <p className="child-tab-title">Message Management</p>
        </div>
        <div className="col-sm-6 pr-0">
          <ButtonComponent
              className="iconCopy float-right ml-1"
              onClick={() => excelExport()}
            >
              <Download />
          </ButtonComponent>
          {/* <ButtonComponent className="iconCopy float-right ml-1">
            <Add />
          </ButtonComponent> */}
        </div>
      </div>
      <div className="col-sm-12 col-md-12 col-lg-12 ">
        <div className="control-pane">
          <div className="control-section tab-control-section">
            <div className="col-sm-12 col-md-12 col-lg-12 ">
              <div className="col-sm-8 col-md-12 col-lg-8 pl-0 pr-4 pt-4 " style={{width: '75%'}}>
              <>
            {loaderStatus ? (
              <div className="LoadImg">
                <img className="loaderClass" src={loader} />
                </div> ) :
                <Grid
                  config={messageMgmtHeaders}
                  data={generateGridData("Message Management", msgMgmtData)}
                  width={"100%"}
                  height={gridHieght}
                />
            } </>
              </div>
              {selectedRowData && selectedRowData.length > 0 ? (
                <div className="col-sm-4 col-md-12 col-lg-4 pl-4 pt-0 child-data-section" style={{width: '25%',marginTop: '16px'}}>
                  <LLAdminPreviewTab previewData={selectedRowData} />
                </div>
              ) : (
                <div className="col-sm-4 col-md-12 col-lg-4 pl-4 pt-4 " style={{width: '25%'}}>
                  <div className="childTab-preview-pane d-flex align-items-center">
                    <div className="col-sm-12 col-md-12 col-lg-12 d-flex justify-content-center">
                      <p className="preview-msg">
                        Select client to view details
                      </p>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
    // <div>
    //   {addScreen && (
    //     <AddLlAdminScreen
    //       addScreen={addScreen}
    //       setAddScreen={setAddScreen}
    //       tabName={addScreenTabName}
    //     />
    //   )}
    //   <div className="col-sm-12 col-md-12 col-lg-12 pt-5">
    //     <div className="col-sm-6 pl-0">
    //       <p className="child-tab-title">Message Management</p>
    //     </div>
    //     <div className="col-sm-6 pr-0">
    //       <ButtonComponent
    //         type="button"
    //         className="iconCopy float-right ml-1"
    //         onClick={() => handlePopupActionLocation(selectedTab)}
    //       >
    //         <Add />
    //       </ButtonComponent>

    //       <ButtonComponent
    //         className="iconCopy float-right ml-1"
    //         onClick={() => excelExport()}
    //       >
    //         <Download />
    //       </ButtonComponent>
    //     </div>
    //   </div>
    //   <div className="col-sm-12 col-md-12 col-lg-12 ">
    //     <div className="control-pane">
    //       <div className="control-section tab-control-section">
    //         <TabComponent
    //           heightAdjustMode="Auto"
    //           cssClass="orientation-tab ll-custom-tab ll-tab-width"
    //           selecting={TabChangeEvent}
    //         >
    //           <TabItemsDirective>
    //             <TabItemDirective header={childTab[0]} content={reclaimScan} />

    //             <TabItemDirective header={childTab[1]} content={mobile} />
    //           </TabItemsDirective>
    //         </TabComponent>
    //       </div>
    //     </div>
    //   </div>
    // </div>
  );
};

export default MessageManagement;
