import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import editIcon from "../../../assets/EditRuleExcep.png";

let gridHieght = "calc(100vh - 400px)";
const QuestionSet = () => {
  const [selectedQuestionSet, setSelectedQuestionSet] = useState<any[]>([]);

  const savedCasualDataList = useSelector(
    (state: RootState) => state.settingsReducer?.selectedCasulDataList
  );

  let selecteQuestionSet: never[] = [];

  useEffect(() => {
    if (savedCasualDataList && savedCasualDataList?.length > 0) {
      console.log(savedCasualDataList);
      setSelectedQuestionSet(savedCasualDataList);
    }
    appendSelectedDetails();
  }, [savedCasualDataList]);

  const appendSelectedDetails = () => {
    console.log(savedCasualDataList);
    selecteQuestionSet = savedCasualDataList.questionSet;
    console.log(selecteQuestionSet);
  };

  const handleEditDataRules = () => {};

  return (
    <div>
      {selecteQuestionSet &&
        selecteQuestionSet?.map((item: any) => {
          <p>{item.sequence}111</p>;
        })}
      {/* <Grid
                config={casualDataQuestionSetHeaders}
                data={generateGridData('Casual Question Set', selecteQuestionSet )}
                width={"100%"}
                height={gridHieght}
            /> */}
      <div className="table-responsive">
        <table className="table casualDataQuestionSet-table">
          <thead>
            <tr className="m-0">
              <th>
                <span className="p-1 fw-bold "></span>
              </th>
              <th>
                <span className="p-1 fw-bold color-white" style={{fontWeight: 'normal' }}>SEQUENCE</span>
              </th>
              <th>
                <span className="p-1 fw-bold color-white" style={{fontWeight: 'normal' }}>TYPE</span>
              </th>
              <th>
                <span className="p-1 p-4 fw-bold color-white" style={{fontWeight: 'normal' }}>QUESTION</span>
              </th>
            </tr>
          </thead>

          <tbody>
            <tr>
              {selecteQuestionSet &&
                selecteQuestionSet?.map((item: any) => {
                  <p>{item.sequence}111</p>;
                })}
              {selecteQuestionSet &&
                selecteQuestionSet?.map((item: any) => (
                  <td className="col-sm-12 col-md-12 col-lg-12 px-3 py-3 mb-0 selectedtem">
                    <img
                      src={editIcon}
                      alt="edit "
                      width="24"
                      height="24"
                      className="float-right m-3 pointer"
                      onClick={() => handleEditDataRules()}
                    />
                  </td>
                ))}
            </tr>

            {selecteQuestionSet &&
              selecteQuestionSet.length > 0 &&
              selecteQuestionSet?.map((item: any) => (
                <td className="col-sm-12 col-md-12 col-lg-12 px-3 py-3 mb-0 selectedtem">
                  <img
                    src={editIcon}
                    alt="edit "
                    width="24"
                    height="24"
                    className="float-right m-3 pointer"
                    onClick={() => handleEditDataRules()}
                  />
                </td>
              ))}

            {selecteQuestionSet.length > 0 &&
              selecteQuestionSet?.map((item: any) => (
                <td className="col-sm-12 col-md-12 col-lg-12 px-3 py-3 mb-0 selectedtem">
                  {item?.sequence}
                </td>
              ))}
            {selecteQuestionSet.length > 0 &&
              selecteQuestionSet?.map((item: any) => (
                <td className="col-sm-12 col-md-12 col-lg-12 px-3 py-3 mb-0 selectedtem">
                  {item?.type}
                </td>
              ))}

            {selecteQuestionSet.length > 0 &&
              selecteQuestionSet?.map((item: any) => (
                <td className="col-sm-12 col-md-12 col-lg-12 px-3 py-3 mb-0 selectedtem">
                  {item?.question}
                </td>
              ))}

            {/* </tr>  */}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default QuestionSet;
