import React, { useEffect, useState } from "react";
import { ButtonComponent } from "@syncfusion/ej2-react-buttons";
import addIcon from "../../../icons/Add.png";
import toggleIconOn from "../../../icons/ToggleIconOn.png";
import toggleIconOff from "../../../icons/ToggleIconOff.png";
import crossIcon from "../../../icons/Close_Delete.png";
import fileIcon from "../../../icons/File.png";
import {
  deleteGlobalSettings,
  getGlobalSettings,
  saveGlobalSettings,
  updateGlobalSettings,
} from "../utils";
import axios from "axios";
import { setGlobalScanSettingsList } from "../settingsReducers";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import notify from "../../../services/toasterService";

const GlobalScanSettings = () => {
  let [enableSaveButton, setEnableSaveBtn] = useState(false);

  useEffect(() => {
    getSavedGlobalSettings();
  }, []);

  const dispatch = useDispatch();

  const globalSettingsDataList = useSelector(
    (state: RootState) => state.settingsReducer?.globalScanSettings
  );

  const showToggleIconOn = true;

  const storeReviewReasonCodesArray: {}[] = [];
  let [storeReviewRCInputList, setStoreReviewInputList] = useState([
    { reasonCode: "", description: "", id: 0, active: false },
  ]);
  const [captureReasonCode, setCaptureReasonCode] = useState(false);
  let [globalSettingsList, setGlobalSettingsList] = useState<any>([]);

  // handle input change
  const handleStoreInputChange = (e: any, index: number) => {
    setEnableSaveBtn(true);
    const name = e.target.name;
    const value = e.target.value;
    const list = [...storeReviewRCInputList];
    let items: any;
    items = [...storeReviewRCInputList];
    items[index][name] = value;
    setStoreReviewInputList(items);
  };

  // handle click event of the Remove button
  const handleStoreReviewRemoveClick = (item: any, index: number) => {
    const list = [...storeReviewRCInputList];
    list.splice(index, 1);
    setStoreReviewInputList(list);
    if (item.id !== undefined && item.id !== null && item.id != 0) {
      deleteGlobalSettingsById(item, index);
    }
  };

  // handle click event of the Add button
  const handleStoreReviewRCAddClick = (rc: any, desc: any) => {
    setStoreReviewInputList([
      ...storeReviewRCInputList,
      { reasonCode: rc, description: desc, id: 0, active: false },
    ]);
  };

  const handleStoreGroupToggle = (event: any, type: boolean) => {
    setEnableSaveBtn(true);
    setCaptureReasonCode(type);
  };

  /*get saved  Mobile Settings APi Call */
  const getSavedGlobalSettings = async () => {
    setEnableSaveBtn(false);
    globalSettingsList = [];
    const endPointUrl = getGlobalSettings();
    await axios
      .get(endPointUrl)
      .then((resp) => {
        globalSettingsList.push(resp.data);
        dispatch(setGlobalScanSettingsList(globalSettingsList));
        setCaptureReasonCode(globalSettingsList[0].reasonCodeCaptured);
        if (globalSettingsList[0] !== "") {
          let rcList: any[] = [];
          globalSettingsList[0].reasonCodes.map((item: any, i: any) => {
            const obj = {
              reasonCode: item.reasonCode,
              description: item.description,
              id: item.id,
              active: item.active,
            };
            rcList.push(obj);
          });
          setStoreReviewInputList(rcList);
        } else {
          storeReviewRCInputList = [];
          handleStoreReviewRCAddClick("", "");
        }
      })
      .catch((err) => {
        console.log(err);
        notify(
          "Error! Unable to fetch data. Please try after sometime.",
          "error"
        );
      });
  };

  /*get saved Global Settings APi Call */
  const saveGlobalScanSettings = async (data: any) => {
    storeReviewRCInputList.map((item, i) => {
      if (globalSettingsDataList[0]["reasonCodes"].length > 0) {
        if (item.id !== undefined && item.id !== null && item.id != 0) {
          const obj = {
            reasonCode: item.reasonCode,
            description: item.description,
            id: item.id,
            active: item.active,
          };
          storeReviewReasonCodesArray.push(obj);
        } else {
          const obj = {
            reasonCode: item.reasonCode,
            description: item.description,
          };
          storeReviewReasonCodesArray.push(obj);
        }
      } else {
        const obj = {
          reasonCode: item.reasonCode,
          description: item.description,
        };
        storeReviewReasonCodesArray.push(obj);
      }
    });

    const reqBody = {
      reasonCodes: storeReviewReasonCodesArray,
      reasonCodeCaptured: captureReasonCode,
    };

    if (globalSettingsDataList[0]["reasonCodes"].length > 0) {
      let body = reqBody;
      const endPointURL = updateGlobalSettings();
      await axios
        .put(endPointURL, body)
        .then((res) => {
          notify(" Updated Successfully", "success");
        })
        .catch((err) => err);
    } else {
      let body = reqBody;
      const endPointURL = saveGlobalSettings();
      await axios
        .post(endPointURL, body)
        .then((res) => {
          notify(" Saved Successfully", "success");
        })
        .catch((err) => {
          notify(
            "Error! Unable to save data. Please try after sometime.",
            "error"
          );
        });
    }
    getSavedGlobalSettings();
  };

  /* delete mobile settings by id */
  const deleteGlobalSettingsById = async (item: any, idx: number) => {
    const endPointUrl = deleteGlobalSettings(item.id);
    await axios
      .delete(endPointUrl)
      .then((res) => {
        console.log(res);
        notify(" Item Deleted Successfully", "success");
      })
      .catch((err) => {
        notify(err, "error");
      });
    // getSavedGlobalSettings();
  };

  return (
    <div>
      <div className="row">
        <ButtonComponent
          className="e-outline opt-btn-dark float-right mt-4"
          style={
            enableSaveButton === true
              ? { opacity: "1", pointerEvents: "all", cursor: "pointer" }
              : { opacity: "0.5", pointerEvents: "none" }
          }
          onClick={(e) => saveGlobalScanSettings(e)}
        >
          <img src={fileIcon} alt="File " />
        </ButtonComponent>
      </div>
      <div className="row mt-0">
        <div className="col-sm-6 col-md-6 col-lg-6 settings-tab-sub-title-div mt-0">
          <p className="settings-tab-sub-title fw-bold">
            Store Under Review - Reason Codes
          </p>
          {storeReviewRCInputList.map((x, i) => {
            return (
              <div className="row m-0">
                {i === 0 ? (
                  <p className="col-sm-12 col-md-12 col-lg-12 mb-0 pt-3 pl-3 pr-0 pb-0 font-14 text-align-left " style={{marginLeft: '15px'}}>
                    Capture Reason Codes
                    {captureReasonCode ? (
                      <img
                        src={toggleIconOn}
                        alt="close "
                        className="p-3"
                        onClick={() => handleStoreGroupToggle(i, false)}
                      />
                    ) : (
                      <img
                        src={toggleIconOff}
                        alt="close "
                        className="p-3"
                        onClick={() => handleStoreGroupToggle(i, true)}
                      />
                    )}{" "}
                  </p>
                ) : (
                  <p></p>
                )}

                <div className="col-sm-4 col-md-4 col-lg-4 m-2">
                  {i === 0 ? (
                    <span className="form-input-settings-label text-center fw-bold">
                      Reason Code
                    </span>
                  ) : (
                    <span></span>
                  )}
                  <input
                    className="form-control"
                    name="reasonCode"
                    placeholder="Enter Reason Code"
                    value={x.reasonCode}
                    onChange={(e) => handleStoreInputChange(e, i)}
                  />
                </div>
                <div className="col-sm-6 col-md-6 col-lg-6 m-2">
                  {i === 0 ? (
                    <span className="form-input-settings-label text-center fw-bold">
                      Description
                    </span>
                  ) : (
                    <span></span>
                  )}
                  <input
                    className="form-control"
                    name="description"
                    placeholder="Enter Description"
                    value={x.description}
                    onChange={(e) => handleStoreInputChange(e, i)}
                  />
                </div>
                {i === 0 ? (
                  <img
                    src={crossIcon}
                    alt="close "
                    className="closeIconMain"
                    onClick={() => handleStoreReviewRemoveClick(x, i)}
                  />
                ) : (
                  <img
                    src={crossIcon}
                    alt="close "
                    className="closeIconSub"
                    onClick={() => handleStoreReviewRemoveClick(x, i)}
                  />
                )}
              </div>
            );
          })}
          <div className="col-sm-4 col-md-4 col-lg-4 pt-2 pl-0 mt-2">
            <span className="float-left">
              {" "}
              <img
                src={addIcon}
                alt="plus "
                className=""
                onClick={() => handleStoreReviewRCAddClick("", "")}
              />
              <span className="settingslblClass mt-0 ml-2">
                Add Reason Code
              </span>{" "}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GlobalScanSettings;
