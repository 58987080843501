import { ButtonComponent } from "@syncfusion/ej2-react-buttons";
import axios from "axios";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setCurrentContact, setMasterDataAddImage, setStatesData } from "./contactReducer";
import {
  addMasterDataEndPoint,
  getEndPoint,
  getStatesByCountry,
} from "./utils";
import userIcon from "../../assets/userIcon.png";
import { RootState } from "../../redux/store";
import { ContactType } from "./constants";
import notify from "../../services/toasterService";
import CountryDropdown from "../ContactsDetailsPanel/mobile-field-component";
import editIcon from "../../assets/EditRuleExcep.png";
import AddFileUploadScreen from "../accessMgmt/addScreen/fileUpload";
import { getStatesByCountryCode } from "../accessMgmt/utils";

const CompanyCodeAddScreen = (props: any) => {
  const { setAddScreen } = props;
  useEffect(() => {
    getStatesList('USA');
  },[]);  
  const [SelectedCode, setSelectedCode] = useState(
    `https://countryflagsapi.com/png/us`
  );
  const dispatch = useDispatch();
  const [selectedImage, setSelectedImage] = useState();
  const [companyCodeClientId, setCompanyCodeClientId] = useState("");
  const [companyCodeName, setCompanyCodeName] = useState("");
  const [companyCodeEmail, setCompanyCodeEmail] = useState("");
  const [companyCodePhone, setCompanyCodePhone] = useState("");
  const [companyCodeCountryCodePhone, setCompanyCodeCountryCodePhone] =
    useState("");
  const [companyCodeAltPhone, setCompanyCodeAltPhone] = useState("");
  const [companyCodeCountryCodeAltPhone, setCompanyCodeCountryCodeAltPhone] =
    useState("");
  const [companyCodeFax, setCompanyCodeFax] = useState("");
  const [companyCodeCountryCodeFax, setCompanyCodeCountryCodeFax] =
    useState("");
  const [companyCodeWebUrl, setCompanyCodeWebUrl] = useState("");
  const [companyCodeAddressStreet, setCompanyCodeAddressStreet] = useState("");
  const [companyCodeAddressCity, setCompanyCodeAddressCity] = useState("");
  const [companyCodeAddressState, setCompanyCodeAddressState] = useState("");
  const [companyCodeAddressZip, setCompanyCodeAddressZip] = useState("");
  const [companyCodeAddressCountry, setCompanyCodeAddressCountry] =
    useState("");
  const [companyCodeLinkReclCenter, setCompanyCodeLinkReclCenter] =
    useState("");
  const [companyCodeLanguage, setCompanyCodeLanguage] = useState("en-US");
  const [companyCodeCurrencyCode, setCompanyCodeCurrencyCode] =
    useState("US Dollar");
  const [companyCodeTimeZone, setCompanyCodeTimeZone] = useState(
    "Eastern Standard Time"
  );
  const [reclaimCenterArray, setReclaimCenterArray] = useState<any[]>([]);

  const [myName, setMyName] = useState("");
  const [myEmail, setMyEmail] = useState("");
  const [myPhone, setMyPhone] = useState("");

  const [selected, setSelected] = useState([]);
  const [errors, setErrors] = useState({
    nameError: "",
    emailError: "",
    clientIdError: "",
    phoneNumberError: "",
    altPhoneError: "",
    webURLError: "",
    languageError: "",
    currencyCodeError: "",
    timezoneError: "",
    zipError: "",
    faxError: "",
  });
  let nameError = "";
  let emailError = "";
  let clientIdError = "";
  let phoneNumberError = "";
  let altPhoneError = "";
  let webURLError = "";
  let languageError = "";
  let currencyCodeError = "";
  let timezoneError = "";
  let zipError = "";
  let faxError = "";
  const currencyData = useSelector(
    (state: RootState) => state.contactReducer?.currencyData
  );
  const timezoneData = useSelector(
    (state: RootState) => state.contactReducer?.timezoneData
  );
  const languageInfo = useSelector(
    (state: RootState) => state.contactReducer?.laungageData
  );
  const clientsData = useSelector(
    (state: RootState) => state.contactReducer?.clientsData
  );
  const countryInfo = useSelector(
    (state: RootState) => state.contactReducer?.countryData
  );
  const countryCodeInfo = useSelector(
    (state: RootState) => state.contactReducer?.countryCodePhoneData
  );
  const statesInfo = useSelector(
    (state: RootState) => state.contactReducer?.statesData
  );
  const mobileWithFlag = useSelector(
    (state: RootState) => state.contactReducer?.selectedMobileWithCountryFlag
  );
  const altMobileWithFlag = useSelector(
    (state: RootState) => state.contactReducer?.selectedAltMobileWithCountryFlag
  );
  const faxWithFlag = useSelector(
    (state: RootState) => state.contactReducer?.selectedFaxWithCountryFlag
  );
  const phoneCountryCode = useSelector(
    (state: RootState) => state.contactReducer?.selectedCountryCodeForMobile
  );
  const AltPhoneCountryCode = useSelector(
    (state: RootState) => state.contactReducer?.selectedCountryCodeForAltMobile
  );
  const faxCountryCode = useSelector(
    (state: RootState) => state.contactReducer?.selectedCountryCodeForFax
  );



//******************** */
const [emailErrorMsg, setEmailErrorMsg] = useState("");
const [myCompanyCodeName, setMyCompanyCodeName] = useState("");
const [isCompanyCodeScreenDisabeled, setisCompanyCodeScreenDisabeled] = useState(true);
const [companyCodeInfoSelectFieldObj, setCompanyCodeInfoSelectFieldObj] = useState<
any[]
>([
{
  language: "en-US",
  timeZone: "Eastern Standard Time",
  countryId: "United States of America",
  stateId: "",
  currencyCode: "US Dollar",
},
]);

const [companyCodeInfo, setCompanyCodeInfo] = useState<any[]>([
{
  companyName: "",
  clientId: clientsData[0]?.id,
  clientName: clientsData[0]?.clientName,
  email: "",
  phoneNumber: "",
  zipcode: "",
  addressLine1: "",
  addressLine2: "",
  alternatePhoneNumber: "",
  phoneCountryCode: phoneCountryCode,
  altPhoneCountryCode: AltPhoneCountryCode,
  faxCountryCode: faxCountryCode,
  fax: "",
  webUrl: companyCodeWebUrl,
},
]);
const handleCompanyCodeTextFields = (event: any, key: any) => {
  if (key === "zipcode") {
      if (event.target.value.length <= 5) {
        setCompanyCodeInfo([
          { ...companyCodeInfo[0], [key]: event.target.value },
        ]);
        validateCompanyCodes();
      }
    } else {
      setCompanyCodeInfo([{ ...companyCodeInfo[0], [key]: event.target.value }]);
      validateCompanyCodes();
    }
  };

  const [statesList, setStateList] = useState<any>([]);

  const getStatesList = async (countryCode: any) => {
    const endPointUrl = getStatesByCountryCode(countryCode);
    await axios.get(endPointUrl).then(
      (res) => {
        setStateList(res.data);
        // dispatch(setStatesList(res.data));
      },
      (err) => {
        notify("Failed to fetch data", "error");
      }
    );
  };

  const handleCompanyCodeSelectFields = (event: any, key: any) => {
    console.log(event.target.value)
    //const dataa = JSON.parse(event.target.value);
    if (key === "countryId") {
      const dataa = JSON.parse(event.target.value);
      getStatesList(dataa.isoCountryCode);
      setCompanyCodeInfoSelectFieldObj([
        { ...companyCodeInfoSelectFieldObj[0], [key]: dataa.countryName },
      ]);
      setCompanyCodeInfo([{ ...companyCodeInfo[0], [key]: dataa.id }]);
    }
    // setMobileUserSelectFields([{...mobileUserSelectFields[0], [key]: dataa}])
    if (key === "stateId") {
      setCompanyCodeInfoSelectFieldObj([
        { ...companyCodeInfoSelectFieldObj[0], [key]: event.target.value },
      ]);
     // setCompanyCodeInfo([{ ...companyCodeInfo[0], [key]: dataa.id }]);
    }
    if (key === "language") {
      setCompanyCodeInfoSelectFieldObj([
        { ...companyCodeInfoSelectFieldObj[0], [key]: event.target.value },
      ]);
      //setCompanyCodeInfo([{ ...companyCodeInfo[0], [key]: dataa.isoLongCode }]);
    }
    if (key === "timezone") {
      setCompanyCodeInfoSelectFieldObj([
        { ...companyCodeInfoSelectFieldObj[0], [key]: event.target.value },
      ]);
     // setCompanyCodeInfo([{ ...companyCodeInfo[0], [key]: dataa.timezoneName }]);
    }
    validateCompanyCodes();
  };

  const validateCompanyCodes = () =>{
    if (companyCodeInfo[0].companyName === "") {
      nameError = "Required Field";
      setMyCompanyCodeName("1px solid red");
    } else {
      setMyCompanyCodeName("");
    }
    
    if (companyCodeInfo[0].email !== "") {
      console.log(emailError)
      var regexFax = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    if (!regexFax.test(companyCodeInfo[0].email)) {
      console.log(emailError)
      emailError = "Invalid Email";
      setEmailErrorMsg("Invalid Email")
    }      
     else {
      setEmailErrorMsg("");
    }
  }
    if (companyCodeInfoSelectFieldObj[0].currencyCode === "") {
      currencyCodeError = "Required Field";
      //setMyUserName("1px solid red");
    } else {
      //setMyUserName("");
    }
    if (companyCodeInfoSelectFieldObj[0].language === "") {
      languageError = "Required Field";
      //setMyUserName("1px solid red");
    } else {
      //setMyUserName("");
    }
    if (companyCodeInfoSelectFieldObj[0].timeZone === "") {
      timezoneError = "Required Field";
      //setMyUserName("1px solid red");
    } else {
      //setMyUserName("");
    }
    if (
      //mobileUserInfo[0].firstname === "" ||
      companyCodeInfo[0].companyName === "" 
     || companyCodeInfoSelectFieldObj[0].timeZone === "" 
     || companyCodeInfoSelectFieldObj[0].language === "" 
     || companyCodeInfoSelectFieldObj[0].currencyCode === "" 
      // mobileUserInfo[0].password === ""
    ) {
      setErrors({
        nameError: "",
    emailError: "",
    clientIdError: "",
    phoneNumberError: "",
    altPhoneError: "",
    webURLError: "",
    languageError: "",
    currencyCodeError: "",
    timezoneError: "",
    zipError: "",
    faxError: "",
      });
      setisCompanyCodeScreenDisabeled(true);
      return false;
    } else {
      setisCompanyCodeScreenDisabeled(false);
    }
    return true;
    }
//******************* */

  const imageChange = (e: any) => {
    if (e.target.files && e.target.files.length > 0) {
      setSelectedImage(e.target.files[0]);
    }
  };

  const handleCompanyCodeClientId = (e: any) => {
    setCompanyCodeClientId(e.target.value);
    validate();
  };
  const handleCompanyCodeName = (e: any) => {
    setCompanyCodeName(e.target.value);
    validate();
  };
  const handleCompanyCodeEmail = (e: any) => {
    //validate();

    setErrors({
      nameError,
      emailError,
      clientIdError,
      phoneNumberError,
      altPhoneError,
      webURLError,
      languageError,
      currencyCodeError,
      timezoneError,
      zipError,
      faxError,
    });
    const reg =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    // if (e.target.value === "") {
    //   emailError = "Email is Required";
    // }
      if (e.target.value && reg.test(e.target.value) === false) {
      emailError = "Email is Invalid ";
    }
    if (emailError) {
      setErrors({
        nameError,
        emailError,
        clientIdError,
        phoneNumberError,
        altPhoneError,
        webURLError,
        languageError,
        currencyCodeError,
        timezoneError,
        zipError,
        faxError,
      });
      return false;
    } else {
      setCompanyCodeEmail(e.target.value);
    }
  };
  const handleCompanyCodePhone = (e: any) => {
    //setCompanyCodePhone(e.target.value);
    //validate();

    var phoneno = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
    if (e.target.value.match(phoneno)) {
      setCompanyCodePhone(e.target.value);
      phoneNumberError = "";
      setErrors({
        nameError,
        emailError,
        clientIdError,
        phoneNumberError,
        altPhoneError,
        webURLError,
        languageError,
        currencyCodeError,
        timezoneError,
        zipError,
        faxError,
      });
      return true;
    } else {
      phoneNumberError = "Phone number is invalid";
    }
    if (phoneNumberError) {
      setErrors({
        nameError,
        emailError,
        clientIdError,
        phoneNumberError,
        altPhoneError,
        webURLError,
        languageError,
        currencyCodeError,
        timezoneError,
        zipError,
        faxError,
      });
      return false;
    }
  };
  const handleCompanyCodeAltPhone = (e: any) => {
    //setCompanyCodeAltPhone(e.target.value);
    //validate();

    var phoneno = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
    if (e.target.value.match(phoneno)) {
      setCompanyCodeAltPhone(e.target.value);
      altPhoneError = "";
      setErrors({
        nameError,
        emailError,
        clientIdError,
        phoneNumberError,
        altPhoneError,
        webURLError,
        languageError,
        currencyCodeError,
        timezoneError,
        zipError,
        faxError,
      });
      return true;
    } else {
      altPhoneError = "Phone number is invalid";
    }
    if (altPhoneError) {
      setErrors({
        nameError,
        emailError,
        clientIdError,
        phoneNumberError,
        altPhoneError,
        webURLError,
        languageError,
        currencyCodeError,
        timezoneError,
        zipError,
        faxError,
      });
      return false;
    }
  };
  const handleCompanyCodeFax = (e: any) => {
    validate();
    setErrors({
      nameError,
      emailError,
      clientIdError,
      phoneNumberError,
      altPhoneError,
      webURLError,
      languageError,
      currencyCodeError,
      timezoneError,
      zipError,
      faxError,
    });
    var phoneno = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
    if (e.target.value.match(phoneno)) {
      setCompanyCodeFax(e.target.value);
      faxError = "";
      setErrors({
        nameError,
        emailError,
        clientIdError,
        phoneNumberError,
        altPhoneError,
        webURLError,
        languageError,
        currencyCodeError,
        timezoneError,
        zipError,
        faxError,
      });
      return true;
    } else {
      faxError = "Fax is invalid";
    }
    if (faxError) {
      setErrors({
        nameError,
        emailError,
        clientIdError,
        phoneNumberError,
        altPhoneError,
        webURLError,
        languageError,
        currencyCodeError,
        timezoneError,
        zipError,
        faxError,
      });
      return false;
    }
  };
  const handleCompanyCodeWebURL = (e: any) => {
    //setCompanyCodeWebUrl(e.target.value);

    var res =
      "^(https?:\\/\\/)?" +
      "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" +
      "((\\d{1,3}\\.){3}\\d{1,3}))" +
      "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" +
      "(\\?[;&a-z\\d%_.~+=-]*)?";
    if (e.target.value.match(res)) {
      setCompanyCodeWebUrl(e.target.value);
      webURLError = "";
      setErrors({
        nameError,
        emailError,
        clientIdError,
        phoneNumberError,
        altPhoneError,
        webURLError,
        languageError,
        currencyCodeError,
        timezoneError,
        zipError,
        faxError,
      });
      return true;
    } else {
      webURLError = "Web URL is invalid";
    }
    if (webURLError) {
      setErrors({
        nameError,
        emailError,
        clientIdError,
        phoneNumberError,
        altPhoneError,
        webURLError,
        languageError,
        currencyCodeError,
        timezoneError,
        zipError,
        faxError,
      });
      return false;
    }
  };
  const handleCompanyCodeAddressStreet = (e: any) => {
    setCompanyCodeAddressStreet(e.target.value);
  };
  const handleCompanyCodeAddressCity = (e: any) => {
    setCompanyCodeAddressCity(e.target.value);
  };
  const handleCompanyCodeAddressState = (e: any) => {
    setCompanyCodeAddressState(e.target.value);
  };
  const handleCompanyCodeAddressZip = (e: any) => {
    setErrors({
      nameError,
      emailError,
      clientIdError,
      phoneNumberError,
      altPhoneError,
      webURLError,
      languageError,
      currencyCodeError,
      timezoneError,
      zipError,
      faxError,
    });
    var regexFax = "^[0-9]{5}(?:-[0-9]{4})?$";
    if (e.target.value.length <= 5) {
      setCompanyCodeAddressZip(e.target.value);
    }
    if (e.target.value.match(regexFax)) {
      setCompanyCodeAddressZip(e.target.value);
      zipError = "";
      setErrors({
        nameError,
        emailError,
        clientIdError,
        phoneNumberError,
        altPhoneError,
        webURLError,
        languageError,
        currencyCodeError,
        timezoneError,
        zipError,
        faxError,
      });
      return true;
    } else {
      zipError = "Zip code is invalid";
    }
    if (zipError) {
      setErrors({
        nameError,
        emailError,
        clientIdError,
        phoneNumberError,
        altPhoneError,
        webURLError,
        languageError,
        currencyCodeError,
        timezoneError,
        zipError,
        faxError,
      });
      return false;
    }
  };
  const handleCompanyCodeAddressCountry = (e: any) => {
    const countryCode = JSON.parse(e.target.value);
    setCompanyCodeAddressCountry(countryCode.countryName);
    const getEndpoint = getStatesByCountry(countryCode.isoCountryCode);
    handleGetStatesList(getEndpoint);
  };
  const handleGetStatesList = async (getEndpoint: string) => {
    const getresult = await axios.get(getEndpoint);
    let cData: any = getresult.data;
    dispatch(setStatesData(cData));
  };
  const handleCompanyCodeLinkReclaimCenters = (e: any) => {
    setCompanyCodeLinkReclCenter(e.target.value);
    const items: number[] = [];
    items.push(e.target.value);
    setReclaimCenterArray(items);
    //console.log("items",reclaimCenterArray)
  };
  const handleCompanyCodeLanguage = (e: any) => {
    setCompanyCodeLanguage(e.target.value);
    validate();
  };
  const handleCompanyCodeCurrencyCode = (e: any) => {
    setCompanyCodeCurrencyCode(e.target.value);
    validate();
  };
  const handleCompanyCodeTimeZone = (e: any) => {
    setCompanyCodeTimeZone(e.target.value);
    validate();
  };
  const handleCancelComapnyCodePopup = () => {
    setAddScreen(false);
    dispatch(setMasterDataAddImage([]));
  };
  const handleChangeCountryCodePhoneCC = (e: any) => {
    setCompanyCodeCountryCodePhone(e.target.value);
  };
  const handleChangeCountryCodeAltPhoneCC = (e: any) => {
    setCompanyCodeCountryCodeAltPhone(e.target.value);
  };
  const handleChangeCountryCodeFaxCC = (e: any) => {
    setCompanyCodeCountryCodeFax(e.target.value);
  };
  const body = {
    active: true,
    address: [
      {
        addressLine1: "string",
        addressLine2: "string",
        alternateAddress: true,
        city: companyCodeAddressCity,
        country: companyCodeAddressCountry,
        deleted: false,
        state: companyCodeAddressState,
        street: companyCodeAddressStreet,
        zipcode: companyCodeAddressZip,
      },
    ],
    alternatePhoneNumber: altMobileWithFlag?.toString().replace(/[^0-9]/g, ""),
    client: {
      clientId: "string",
      clientName: "string",
    },
    clientId: companyCodeClientId,
    phoneCountryCode: phoneCountryCode,
    altPhoneCountryCode: AltPhoneCountryCode,
    faxCountryCode: faxCountryCode,
    companyName: companyCodeName,
    currencyCode: companyCodeCurrencyCode,
    deleted: false,
    email: companyCodeEmail,
    fax: faxWithFlag?.toString().replace(/[^0-9]/g, ""),
    language: companyCodeLanguage,
    notes: "",
    phoneNumber: mobileWithFlag?.toString().replace(/[^0-9]/g, ""),
    timezone: companyCodeTimeZone,
    webUrl: companyCodeWebUrl,
  };
  let tabName: any = ContactType.COMPANY_CODES;
  const handleAddComapnyCode = () => {
    // var val2 = document.getElementById("ddlClientId") as HTMLInputElement;
    // console.log(val2)
    // setCompanyCodeClientId(addObject.clientId);
    var phone = document.getElementById("txtPhone") as HTMLInputElement;
    var countryCodephone = document.getElementById(
      "txtCountryCodePhone"
    ) as HTMLSelectElement;
    var altPhone = document.getElementById("txtAltPhone") as HTMLInputElement;
    var countryCodeAltPhone = document.getElementById(
      "txtCountryCodeAltPhone"
    ) as HTMLSelectElement;
    var fax = document.getElementById("txtFax") as HTMLInputElement;
    var countryCodeFax = document.getElementById(
      "txtCountryCodeFax"
    ) as HTMLSelectElement;
    // if(phone.value !== '' && countryCodephone.value === ''){
    //   notify('Please select Country code for Phone number', 'warning');
    // }
    // else if(altPhone.value !== '' && countryCodeAltPhone.value === ''){
    //   notify('Please select Country code for Alt Phone number', 'warning');
    // }
    // else if(fax.value !== '' && countryCodeFax.value === ''){
    //   notify('Please select Country code for Fax', 'warning');
    // }
    // else{
   // if (validate()) {
      const endpoint = addMasterDataEndPoint(tabName);
      const getEndpoint = getEndPoint(tabName);
      console.log(companyCodeInfo)
      handleMasterDataChange(endpoint, getEndpoint);
   // }
    // }
  };

  const handleMasterDataChange = async (
    endpoint: string,
    getEndpoint: string
  ) => {
    const reqBody = {
      active: true,
      address: [
        {
          addressLine1: companyCodeInfo[0].addressLine1,
          addressLine2: companyCodeInfo[0].addressLine2,
          alternateAddress: true,
          city: companyCodeInfo[0].addressLine2,
          country: companyCodeInfoSelectFieldObj[0].countryId,
          deleted: false,
          state: companyCodeInfoSelectFieldObj[0].stateId,
          street: companyCodeInfo[0].addressLine1,
          zipcode: companyCodeInfo[0].zip,
        },
      ],
      alternatePhoneNumber: altMobileWithFlag?.toString().replace(/[^0-9]/g, ""),
      client: {
        clientId: "string",
        clientName: "string",
      },
      clientId: companyCodeInfo[0].clientId,
      phoneCountryCode: phoneCountryCode,
      altPhoneCountryCode: AltPhoneCountryCode,
      faxCountryCode: faxCountryCode,
      companyName: companyCodeInfo[0].companyName,
      currencyCode: companyCodeInfoSelectFieldObj[0].currencyCode,
      deleted: false,
      email: companyCodeInfo[0].email,
      fax: faxWithFlag?.toString().replace(/[^0-9]/g, ""),
      language: companyCodeInfoSelectFieldObj[0].language,
      notes: "",
      phoneNumber: mobileWithFlag?.toString().replace(/[^0-9]/g, ""),
      timezone: companyCodeInfoSelectFieldObj[0].timeZone,
    };
    const result = await axios
      .post(endpoint, reqBody)
      .then((results: any) => {
        if (results && results.data) {
          setAddScreen(false);
          notify("Company Code created successfully", "success");
        } else {
          notify("Failed to create Company Code", "error");
        }
      })
      .catch((error: any) => {
        if (error.response) {
          notify(error.response?.data?.message, "error");
        }
      });
    const getresult = await axios.get(getEndpoint);
    dispatch(setCurrentContact(getresult.data));
  };

  const validate = (field?: any, value?: any) => {
    if (companyCodeName === "") {
      nameError = "Required Field";
      setMyName("1px solid red");
    } else {
      setMyName("");
    }
    if (companyCodeLanguage === "") {
      languageError = "Required Field";
    }
    if (companyCodeCurrencyCode === "") {
      currencyCodeError = "Currency code is required";
    }
    if (companyCodeTimeZone === "") {
      timezoneError = "Timezone is required";
    }
    // const reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{1,})+$/;
    // if (companyCodeEmail === "") {
    //   emailError = "Required Field";
    //   setMyEmail("1px solid red");
    // } else if (companyCodeEmail && reg.test(companyCodeEmail) === false) {
    //   emailError = "Required Field";
    // } else {
    //   setMyEmail("");
    // }
    // if(companyCodeClientId==="") {
    //   clientIdError="Client Id is Required"
    // }
    const phoneReg =
      /^[(]{0,1}[0-9]{3}[)]{0,1}[-\s\.]{0,1}[0-9]{3}[-\s\.]{0,1}[0-9]{4}$/;
    if (companyCodePhone === "") {
      phoneNumberError = "Required Field";
      setMyPhone("1px solid red");
    } else if (companyCodePhone && phoneReg.test(companyCodePhone) === false) {
      phoneNumberError = "Phone Number is Invalid ";
    } else {
      setMyPhone("");
    }
    if (companyCodeFax != "") {
      var phoneno = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
      if (companyCodeFax && phoneno.test(companyCodeFax) === false) {
        faxError = "Fax is Invalid";
      }
    }
    if (
      emailError ||
      nameError ||
      clientIdError ||
      webURLError ||
      languageError ||
      currencyCodeError ||
      timezoneError ||
      zipError
    ) {
      setErrors({
        nameError,
        emailError,
        clientIdError,
        phoneNumberError,
        altPhoneError,
        webURLError,
        languageError,
        currencyCodeError,
        timezoneError,
        zipError,
        faxError,
      });
      return false;
    }
    return true;
  };

  const handlePopupActionAddCompany = (selectedTabName: any) => {
    uploadedImageFile = [];
    setFileUploadAddScreen(true);
    setAddScreenTabName(selectedTabName);
  };

  let uploadedImageFile = useSelector(
    (state: RootState) => state.contactReducer.uploadedImage
  );

  const [addScreen, setFileUploadAddScreen] = useState(false);
  const [addScreenTabName, setAddScreenTabName] = useState(null);
  const [screenType, setScreenType] = useState("masterdataAdd");

  let [stepperindexvalue, setStepperIndexValue] = useState(1);
  let [addObject, setAddObejct] = useState<any>();

  const goToNextStep = (index: number) => {
    // var val2 = document.getElementById("ddlClientId") as HTMLInputElement;
    console.log(companyCodeInfo)
    console.log(companyCodeInfoSelectFieldObj)
   // setCompanyCodeClientId(val2.value);
   // setAddObejct({clientId : val2.value}) 
    if (index === 1) {
      setStepperIndexValue(1);
    } else if (index === 2) {
      setStepperIndexValue(2);
    }
  };

  return (
    <div className="commodity-popup" style={{marginRight: '0'}}>
      <div className="StepperControl">
        <div className="col-6 pl-2 pt-0 mt-auto mb-auto">
          <div className="page-header--col col--steps">
            <ul className="list--steps pt-3 mb-0 pointer">
              <li
                className={` ${stepperindexvalue === 1 ? "active" : ""}`}
                onClick={(e) => goToNextStep(1)}
              >
                <strong className="steps--number">1</strong>
                <p className="steps--label"></p>
              </li>
              <li
                className={` ${stepperindexvalue === 2 ? "active" : ""}`}
                onClick={(e) => goToNextStep(2)}
              >
                <strong className="steps--number">2</strong>
                <p className="steps--label"></p>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="row">
      {addScreen && (
        <AddFileUploadScreen
          addScreen={addScreen}
          setFileUploadAddScreen={setFileUploadAddScreen}
          tabName={addScreenTabName}
          screenType={screenType}
        />
      )}
        <div className="row d-flex justify-content-center">
          <div className="col-xl-2 col-lg-2 roundImage">
          {/* <input accept="image/*" type="file" onChange={imageChange} /> */}
          {/* <img src={userIcon} className="userAddIcon" />
          {selectedImage && (
            <div className="imagePreviewDiv">
              <img
                src={URL.createObjectURL(selectedImage)}
                className="imagePreview"
                height="100"
                width="100"
              />
            </div>
          )} */}
          {uploadedImageFile && uploadedImageFile.length > 0 ? (
            <div className="ImageContainer">
                <img
                  src={URL.createObjectURL(uploadedImageFile[0].file)}
                  className="userAddIcon"
                  height="125"
                  width="125"
                /></div>
              ) : (
                <img src={userIcon} className="userAddIcon" />
              )}
              <div className="middle">
              <img
                src={editIcon}
                alt="edit "
                width="18"
                height="18"
                className="m-3 pointer avatarEdit"
                onClick={() => handlePopupActionAddCompany("fileUpload")}
                style={{position: 'sticky'}}
              /></div>
        </div>
        </div>
        </div>

        <div>
        {stepperindexvalue === 1 ? (
          <div>
            <div>
        <div className="col-sm-12 row m-0 mt-2">          
            <div className="col-sm-6">
            <div className="col-sm-12">
              <span className="lblClass" style={{float: 'left', marginTop: '8px'}}>
                Client ID<span className="requiredStar">*</span>
              </span>
              <input
                type="text"
                className="formcontrol"
                readOnly
                onChange={(e) => handleCompanyCodeTextFields(e, 'clientId')}
                value={companyCodeInfo[0].clientName}
                style={{ border: myName }}
              />
              <input
                type="text"
                className="formcontrol"
                readOnly
                onChange={(e) => handleCompanyCodeTextFields(e, 'clientId')}
                value={companyCodeInfo[0].clientId}
                style={{ border: myName, display: 'none' }}
              />
              {companyCodeClientId === "" ? (
                <span className="text-danger">{errors.clientIdError}</span>
              ) : (
                ""
              )}
            </div>
            <div className="col-sm-12">
              <span className="lblClass" style={{float: 'left', marginTop: '8px'}}>
                Name<span className="requiredStar">*</span>
              </span>
              {companyCodeInfo[0].companyName === "" ? (
                <span className="text-danger" style={{float: 'right', marginTop: '8px'}} >{errors.nameError}</span>
              ) : (
                ""
              )}
              <input
                type="text"
                className="formcontrol"
                onChange={(e) => handleCompanyCodeTextFields(e, 'companyName')}
                value={companyCodeInfo[0].companyName}
                style={{ border: myCompanyCodeName }}
              />
            </div>
            <div className="col-sm-12">
              <span className="lblClass" style={{float: 'left', marginTop: '8px'}}>Email
                {/* <span className="requiredStar">*</span> */}
              </span> 
              {emailErrorMsg !== '' ? (
                <span className="text-danger mt-3" style={{float: 'right'}}>{emailErrorMsg}</span>
              ) : (
                ""
              )}           
              <input
                type="email"
                className="formcontrol"
                placeholder="someone@email.com"
                onChange={(e) => handleCompanyCodeTextFields(e, 'email')}
                onBlur={(e) => handleCompanyCodeTextFields(e, 'email')}
                onKeyUp={(e) => handleCompanyCodeTextFields(e, 'email')}
                value={companyCodeInfo[0].email}
                style={{ border: myEmail }}
              />
            </div>
            <div className="col-sm-12">
                <span className="lblClass" style={{float: 'left', marginTop: '8px'}}>Web URL</span>
              
                <input
                  type="text"
                  className="form-control"
                  placeholder="https://website.com"
                  onChange={(e) => handleCompanyCodeTextFields(e, 'webUrl')}
                  value={companyCodeInfo[0].webUrl}
                />
                {errors.webURLError ? (
                  <span className="text-danger">{errors.webURLError}</span>
                ) : (
                  ""
                )}
              </div>
              </div>
              <div className="col-sm-6">         
                <div className="col-sm-12">
              <span className="lblClass" style={{float: 'left'}}>Address</span>            
              <input
                type="text"
                className="form-control"
                placeholder="Street"
                onChange={(e) => handleCompanyCodeTextFields(e, 'addressLine1')}
                  value={companyCodeInfo[0].addressLine1}
              />
            </div>    
            
            <div className="col-sm-12 mt-3">
              <input
                type="text"
                className="form-control"
                placeholder="City"
                onChange={(e) => handleCompanyCodeTextFields(e, 'addressLine2')}
                value={companyCodeInfo[0].addressLine2}
              />
            </div>
          
          
            
            <div className="col-sm-12 mt-3">
              <select
                id="ddlCountry"
                className="form-control"
                placeholder="Select Country"
                //value={companyCodeInfoSelectFieldObj[0].country}
                onChange={(e: any) => handleCompanyCodeSelectFields(e, "countryId")}
              >
                {companyCodeInfoSelectFieldObj[0].countryId !== '' ? <option value={companyCodeInfoSelectFieldObj[0].countryId}>{companyCodeInfoSelectFieldObj[0].countryId}</option>
                : <option value="" >Select Country</option> }
                {countryInfo?.map((cn) => (
                  <option value={JSON.stringify(cn)}>{cn.countryName}</option>
                ))}
              </select>
            </div>
         
         
            
            <div className="col-sm-8 mt-3 pr-0">
              <select
              id="ddlCountry"
                className="form-control"
                //value={companyCodeInfoSelectFieldObj[0].stateId}
                onChange={(e: any) => handleCompanyCodeSelectFields(e, "stateId")}
              >
                {companyCodeInfoSelectFieldObj[0].stateId !== '' ?
                <option value={companyCodeInfoSelectFieldObj[0].stateId}>{companyCodeInfoSelectFieldObj[0].stateId}</option>
                : <option value="">Select State</option> }
                {statesList?.map((cn:any) => (
                  <option value={cn.stateName}>{cn.stateName}</option>
                ))}
              </select>
            </div>
            <div className="col-sm-4 mt-3">
              <input
                type="number"
                className="form-control"
                placeholder="ZIP"
                onChange={(e) => handleCompanyCodeTextFields(e, 'zipcode')}
                value={companyCodeInfo[0].zipcode}
              />
              {companyCodeInfo[0].zipcode === "" ? (
                <span className="text-danger">{errors.zipError}</span>
              ) : (
                ""
              )}
            </div>
          

          {/* <div className="row ml-0 mr-0 mt-1rem">
            <div className="col-sm-4">
              <span className="lblClass">Link Reclaim Centers</span>
            </div>
            <div className="col-sm-8">
              <select className="form-control" onChange={(e) => handleCompanyCodeLinkReclaimCenters(e)}>
                <option>Search Reclaim Centers</option>
                {timezoneData.map((tz) =>
                <option value={tz.timezoneName}>{tz.timezoneName}{tz.utc}</option>
                )}
              </select>
            </div>
          </div>
          {reclaimCenterArray} */}
       
        </div>         
        </div>
        <div className="col-sm-12 px-0 mt-5">
                  <ButtonComponent
                    cssClass="eg-btn-primary float-right pt-3 pb-3 pl-5 pr-5"  
                    style={
                      companyCodeInfo[0].companyName !== "" &&
                      emailErrorMsg === ""
                        ? {
                            opacity: "1",
                            pointerEvents: "all",
                            cursor: "pointer",
                          }
                        : {
                            opacity: "0.5",
                            pointerEvents: "none",
                            backgroundColor: "#e8e8e8",
                            color: "#969696",
                          }
                    }                    
                    onClick={() => goToNextStep(2)}
                  >
                    Next
                  </ButtonComponent>
                  <ButtonComponent
                    cssClass="eg-btn-primary-link e-flat mt-3"
                    onClick={() => handleCancelComapnyCodePopup()}
                  >
                    Cancel
                  </ButtonComponent>
                </div>
        </div>
        </div>
        ) : (
          <div>
        <div className="col-sm-12 row m-0 mt-2">
            <div className="col-sm-6">
              <div className="col-sm-12">
              <span className="lblClass" style={{float: 'left', marginLeft: '17px'}}>Phone
                {/* Phone<span className="requiredStar">*</span> */}
              </span>
              <div className="col-sm-12">
              <CountryDropdown type="Phone" /></div>
              {/* <div className="col-sm-4 pl-0 pr-0">
            <select className="form-control" id="txtCountryCodePhone" onChange={(e: any)=>handleChangeCountryCodePhoneCC(e)}>
                  <option value=''>Select</option>
                  {countryCodeInfo?.map((ld) =>
                    <option value={ld?.mobileCountryCode}>{ld?.mobileCountryCode}</option>
                  )}
            </select>
            </div>
            <div className="col-sm-8 pl-0 pr-0">
            <input type="text" className="formcontrol" id="txtPhone" onChange={(e) => handleCompanyCodePhone(e)} style={{border:myPhone}}/>
             {errors.phoneNumberError?<span className="text-danger">{errors.phoneNumberError}</span>:""}
            </div>         */}
            </div>          
            <div className="col-sm-12">
              <span className="lblClass" style={{float: 'left', marginLeft: '17px'}}>Alt Phone</span>
            <div className="col-sm-12">
              <CountryDropdown type="Alt Phone" /></div>
              {/* <div className="col-sm-4 pl-0 pr-0">
            <select className="form-control" id="txtCountryCodeAltPhone" onChange={(e: any)=>handleChangeCountryCodeAltPhoneCC(e)}>
                  <option value=''>Select</option>
                  {countryCodeInfo?.map((ld) =>
                    <option value={ld?.mobileCountryCode}>{ld?.mobileCountryCode}</option>
                  )}
            </select>
            </div>
            <div className="col-sm-8 pl-0 pr-0">
            <input type="text" id="txtAltPhone" className="form-control" onChange={(e) => handleCompanyCodeAltPhone(e)} />
             {errors.altPhoneError?<span className="text-danger">{errors.altPhoneError}</span>:""}
            </div>  */}

              {/* {errors.phoneNumberError?<span className="text-danger">{errors.phoneNumberError}</span>:""} */}
            </div>         
            <div className="col-sm-12">
              <span className="lblClass" style={{float: 'left', marginLeft: '17px'}}>Fax</span>
            <div className="col-sm-12">
              <CountryDropdown type="Fax" /></div>
              {/* <div className="col-sm-4 pl-0 pr-0">
            <select className="form-control" id="txtCountryCodeFax" onChange={(e: any)=>handleChangeCountryCodeFaxCC(e)}>
                  <option value=''>Select</option>
                  {countryCodeInfo?.map((ld) =>
                    <option value={ld?.mobileCountryCode}>{ld?.mobileCountryCode}</option>
                  )}
            </select>
            </div>
            <div className="col-sm-8 pl-0 pr-0">
            <input type="text" id="txtFax" className="form-control" onChange={(e) => handleCompanyCodeFax(e)} />
            {errors.faxError?<span className="text-danger">{errors.faxError}</span>:""}
            </div> */}
            </div>
            </div>         

              <div className="col-sm-6">
                <div className="col-sm-12">
                <span className="lblClass" style={{float: 'left'}}>
                  Language<span className="requiredStar">*</span>
                </span>
                <select
                  className="form-control"
                  value={companyCodeInfoSelectFieldObj[0].language}
                  onChange={(e: any) => handleCompanyCodeSelectFields(e, "language")}
                >
                  {languageInfo?.map((ld) => (
                    <option value={ld?.isoLongCode}>{ld?.isoLongCode}</option>
                  ))}
                </select>
                {companyCodeLanguage === "" ? (
                  <span className="text-danger">{errors.languageError}</span>
                ) : (
                  ""
                )}
              </div>           
              <div className="col-sm-12">
                <span className="lblClass" style={{float: 'left'}}>
                  Currency Code<span className="requiredStar">*</span>
                </span>
                <select
                  className="form-control"
                  value={companyCodeInfoSelectFieldObj[0].currencyCode}
                  onChange={(e: any) => handleCompanyCodeSelectFields(e, "currencyCode")}
                >
                  {currencyData?.map((cc) => (
                    <option value={cc.currency}>{cc.currency}</option>
                  ))}
                </select>
                {companyCodeCurrencyCode === "" ? (
                  <span className="text-danger">
                    {errors.currencyCodeError}
                  </span>
                ) : (
                  ""
                )}
              </div>           
              <div className="col-sm-12">
                <span className="lblClass" style={{float: 'left'}}>
                  Time Zone<span className="requiredStar">*</span>
                </span>
              
                <select
                  className="form-control"
                  value={companyCodeInfoSelectFieldObj[0].timeZone}
                  onChange={(e: any) => handleCompanyCodeSelectFields(e, "timeZone")}
                >
                  {timezoneData?.map((tz) => (
                    <option value={tz.timezoneName}>
                      {tz.timezoneName}
                      {tz.utc}
                    </option>
                  ))}
                </select>
                {companyCodeTimeZone === "" ? (
                  <span className="text-danger">{errors.timezoneError}</span>
                ) : (
                  ""
                )}
              </div>           
            </div>            
            </div>
        
      <div className="col-sm-12 px-0" style={{marginTop: '7rem'}}>
        <ButtonComponent
          cssClass="eg-btn-primary float-right"
          onClick={() => handleAddComapnyCode()}
          style={
            isCompanyCodeScreenDisabeled
              ? {
                  opacity: "0.5",
                  pointerEvents: "none",
                  backgroundColor: "#e8e8e8",
                  color: "#969696",
                  borderRadius: "4px",
                }
              : {
                  opacity: "1",
                  borderRadius: "4px",
                  pointerEvents: "all",
                  backgroundColor: "#636799",
                  color: "#ffffff",
                  cursor: "pointer",
                }
          }
        >
          ADD COMPANY CODE
        </ButtonComponent>
        <ButtonComponent
                      cssClass="eg-btn-secondary float-right mr-4"
                      onClick={() => goToNextStep(1)}
                    >
                      Back
        </ButtonComponent>
        <ButtonComponent
          cssClass="eg-btn-primary-link e-flat mt-3"
          onClick={handleCancelComapnyCodePopup}
        >
          CANCEL
        </ButtonComponent>
      </div>
      </div>
        )}
    </div>
    </div>
  );
};
export default CompanyCodeAddScreen;
