import { ButtonComponent } from "@syncfusion/ej2-react-buttons";
import axios from "axios";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import notify from "../../../services/toasterService";
import AccessMgmtModal from "../../common/accessMgmtModal/acessMgmt";
import { setMasterDataAddImage } from "../../contactTabs/contactReducer";
import { setPreviewFile, setPreviewImage, setUploadedImage } from "../accessmgmtReducer";
import { addAccessReqEndPoint } from "../utils";

const AddFileUploadScreen = (props: any) => {
  const dispatch = useDispatch();
  const [tabNameFinal, setTabNameFinal] = useState("");
  const [selectedImage, setSelectedImage] = useState("");

  const { addScreen, setFileUploadAddScreen, tabName, screenType } = props;

  let cancelDisabeled = true;
  let [photoDisabeled, setPhotoDisbeled] = useState(true);

  let upladedExcelFilePath : any

  const [baseImage, setBaseImage] = useState<any>("");

  const [isExcelFile, setisExcelFile] = useState(false)
  const [uplodedFilePath, setUploadedFilePath] = useState<any>()

  const uploadedExcelFilePreview = useSelector(
    (state: RootState) => state.accessMgmtReducer?.previewFile
  );

  const handleFileChange = async (e: any) => {
    console.log(e.target.files);
    const file = e.currentTarget.files[0];
    const fileReader = new FileReader();
    const newFile = {
      name: file.name,
      type: file.type,
      size: file.size,
      data: fileReader.result,
      file: file,
    };

    setSelectedImage(file);
    setPhotoDisbeled(false);
  };
  const handlePopUpClose = () => {
    setFileUploadAddScreen(false);
    const obj = [selectedImage];
    // dispatch(setUploadedImage(obj))
  };

  const convertBase64 = (file: any) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);

      fileReader.onload = () => {
        resolve(fileReader.result);
      };

      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  const handleChangePhoto = async () => {
    let base64: any;
    base64 = await convertBase64(selectedImage);
    var base64String = base64.toString().split("base64,");
    const obj = [{ file: selectedImage, base64: base64String[1] }];

    // setBaseImage(base64);
    // addLoadedFiles(newFile);
    if (screenType === "update") {
      dispatch(setPreviewImage(obj));
    } else if (screenType === "add") {
      dispatch(setUploadedImage(obj));      
    } else if (screenType === "masterdataAdd") {
      dispatch(setMasterDataAddImage(obj));
    }
    if (base64 !== undefined && base64 !== "") {
      // dispatch(setBaseImage(base64))
    }
    //dispatch(setBaseImage(base64))
    handlePopUpClose();
  };
 
  const handleFileUpload = (e: any) => {
   const target: DataTransfer = (e.target) as DataTransfer;
  // isExcelFile = (!!target.files[0].name.match(/(.xls|.xlsx|.csv)/));
   setisExcelFile(!!target.files[0].name.match(/(.xls|.xlsx|.csv)/))
    upladedExcelFilePath = target.files[0];
    console.log(upladedExcelFilePath)
    const file = e.currentTarget.files[0];
    const fileReader = new FileReader();
    const newFile = {
      name: file.name,
      type: file.type,
      size: file.size,
      data: fileReader.result,
      file: file,
    };
    dispatch(setPreviewFile(file));
    setUploadedFilePath(target.files[0]);
  }
 const  handleExcelUpload = async() => {
     const addEndPoint = addAccessReqEndPoint("ExcelUpload");
     const config = {
      headers: {
        'content-type': 'multipart/form-data',
      },
    };
     const formData = new FormData();
     formData.append(
      'file',
      uplodedFilePath
    );
     if (uploadedExcelFilePreview) {
      await axios.post(addEndPoint, formData, config).then(
        (res: any) => {
          if(res.data.body.status===201) {
          notify(res.data.body.message,'error')
          } else {
            notify(res.data.body.message,'success')
          }
          setFileUploadAddScreen(false);
        }).catch((error:any)=>{
          console.log(error);
        });
     }
     
  }

  
  return (
    <>
   
    {screenType === 'excelUpload' ?
    <div>
      <AccessMgmtModal
        handleClose={() => setFileUploadAddScreen(false)}
        show={addScreen}
        width={800}
      >
        <div className="row ml-0 mr-0 mb-30 modal-title-header">
          <span className="tabNameForAddScreen">Upload Excel  {isExcelFile}</span>
        </div>

        <div className="col-sm-12 col-md-12 col-lg-12 row m-0">
          <div className="col-sm-3"></div>
          <div className="col-sm-6">
            <div className="col-sm-12 mt-3">
              <label className="label-title"> Upload File</label>
              <input
                className=" form-control"
                type="file"
                onChange={(e) => handleFileUpload(e)}
              />
              <span className="font-10 float-right">
                File size must not exceed 250 MB
              </span>
              {/* {roleName === '' ? <span className='text-danger'>{errors.roleNametextError}</span> : ''}   */}
            </div>
          </div>
          <div className="col-sm-3"></div>
        </div>
        <div className="col-sm-12 col-md-12 col-lg-12 row m-0 mt-5 mb-3">
          <div className="col-sm-3"></div>
          <div className="col-sm-6 p-0">
            <ButtonComponent
              cssClass="eg-btn-primary float-right px-5 py-3"
              style={
                isExcelFile
                  ? {
                    opacity: "1",
                    borderRadius: "4px",
                    pointerEvents: "all",
                    backgroundColor: "#636799",
                    color: "#ffffff",
                    cursor: "pointer",
                  } 
                  : {
                    opacity: "1",
                    borderRadius: "4px",
                    pointerEvents: "all",
                    backgroundColor: "#636799",
                    color: "#ffffff",
                    cursor: "pointer",
                  } 
              }
              onClick={() => handleExcelUpload()}
            >
              Upload
            </ButtonComponent>
            <ButtonComponent
              cssClass="px-5 py-3"
              style={
                cancelDisabeled
                  ? {
                      backgroundColor: "#ffffff !important",
                      boxShadow: "none",
                      border: "1px solid #636799",
                      color: "#636799",
                    }
                  : {}
              }
              onClick={() => handlePopUpClose()}
            >
              Cancel
            </ButtonComponent>
          </div>
          <div className="col-sm-3"></div>
        </div>
      </AccessMgmtModal>
    </div> : 
    
    <div>
    <AccessMgmtModal
      handleClose={() => setFileUploadAddScreen(false)}
      show={addScreen}
      width={800}
    >
      <div className="row ml-0 mr-0 mb-30 modal-title-header">
        <span className="tabNameForAddScreen">Change Photo</span>
      </div>

      <div className="col-sm-12 col-md-12 col-lg-12 row m-0">
        <div className="col-sm-3"></div>
        <div className="col-sm-6">
          <div className="col-sm-12 mt-3">
            <label className="label-title">Photo Upload</label>
            <input
              className=" form-control"
              type="file"
              onChange={(e) => handleFileChange(e)}
            />
            <span className="font-10 float-right">
              File size must not exceed 250 MB
            </span>
            {/* {roleName === '' ? <span className='text-danger'>{errors.roleNametextError}</span> : ''}   */}
          </div>
        </div>
        <div className="col-sm-3"></div>
      </div>
      <div className="col-sm-12 col-md-12 col-lg-12 row m-0 mt-5 mb-3">
        <div className="col-sm-3"></div>
        <div className="col-sm-6 p-0">
          <ButtonComponent
            cssClass="eg-btn-primary float-right px-5 py-3"
            style={
              photoDisabeled
                ? {
                    opacity: "0.5",
                    pointerEvents: "none",
                    backgroundColor: "#e8e8e8",
                    color: "#969696",
                    borderRadius: "4px",
                  }
                : {
                    opacity: "1",
                    borderRadius: "4px",
                    pointerEvents: "all",
                    backgroundColor: "#636799",
                    color: "#ffffff",
                    cursor: "pointer",
                  }
            }
            onClick={() => handleChangePhoto()}
          >
            Change Photo
          </ButtonComponent>
          <ButtonComponent
            cssClass="px-5 py-3"
            style={
              cancelDisabeled
                ? {
                    backgroundColor: "#ffffff !important",
                    boxShadow: "none",
                    border: "1px solid #636799",
                    color: "#636799",
                  }
                : {}
            }
            onClick={() => handlePopUpClose()}
          >
            Cancel
          </ButtonComponent>
        </div>
        <div className="col-sm-3"></div>
      </div>
    </AccessMgmtModal>
  </div>}</>
  );
};

export default AddFileUploadScreen;
