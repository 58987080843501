import { useCallback, useEffect, useState } from "react";
import { TextBoxComponent } from "@syncfusion/ej2-react-inputs";
import "./FixedItemDetailsForm.scss";
import { ButtonComponent } from "@syncfusion/ej2-react-buttons";

function FixedItemDetailsEditForm(props: any) {
  // const [bins, setbins] = useState(0);
  // const [boxes, setboxes] = useState(0);
  // const [txtBox, setTxtBox] = useState(false);
  // const [txtBin, setTxtBin] = useState(false);

  // const [commodityType, setCommodityType] = useState("");
  // const [commodityTypeId, setCommodityTypeId] = useState("");
  // const [pricingType, setPricingType] = useState("");
  // const [pricePerBoxBin, setPricePerBoxBin] = useState("0");
  // const [numberOfBins, setNumberOfBins] = useState("0");
  // const [numberOfBoxes, setNumberOfBoxes] = useState("0");
  // const [numberOfItems, setNumberOfItems] = useState("0");

  const [formDetails, setFormDetails] = useState({
    bins: "0",
    boxes: "0",
    items: "0",
    rate: "0",
    barCode: "",
    description: "",
    pricingType: "",
    commodityTypeId: "",
  });

  useEffect(() => {
    if (props?.PickedFPIData.length > 0) {
      console.log(props?.PickedFPIData);
      setFormDetails({
        bins: props?.PickedFPIData[0]["binsCount"] + "",
        boxes: props?.PickedFPIData[0]["boxesCount"] + "",
        items: props?.PickedFPIData[0]["itemsCount"] + "",
        rate: props?.PickedFPIData[0]["pricePerBox"] + "",
        barCode: "",
        description: props?.PickedFPIData[0]["barcode"],
        pricingType: props?.PickedFPIData[0]["pricingType"],
        commodityTypeId:
          props?.PickedFPIData[0]?.commodityTypeId ||
          props?.PickedFPIData[0]?.commodityType?.id,
      });
    } else {
      setFormDetails({
        bins: "0",
        boxes: "0",
        items: "0",
        rate: "",
        barCode: "",
        description: "",
        pricingType: "",
        commodityTypeId: "",
      });
    }
    // // if(props?.props?.length > 0){}
    // setCommodityType(props.props.code ? props.props.code : "");
    // setPricingType(props.props.pricingType ? props.props.pricingType : "");
    // setPricePerBoxBin(props.props?.price || "");
    // setCommodityTypeId(props.props?.id);
    // if (props?.formDetails?.length > 0) {
    //   setPricePerBoxBin(props?.formDetails[0]?.pricePerBox || "");
    //   setNumberOfBoxes(props?.formDetails[0]?.boxesCount);
    //   setNumberOfItems(props?.formDetails[0]?.itemsCount);
    //   setNumberOfBins(props?.formDetails[0]?.binsCount);
    //   setCommodityType(props?.formDetails[0]?.barcode);
    //   setPricingType(props?.formDetails[0]?.pricingType);
    //   setCommodityTypeId(
    //     props?.formDetails[0]?.commodityTypeId ||
    //       props?.formDetails[0]?.commodityType?.id
    //   );
    // }
  }, [props.PickedFPIData]);

  const handleAddItem = useCallback(() => {
    const reqParams = {
      bins: +formDetails["bins"],
      boxes: +formDetails["boxes"],
      items: +formDetails["items"],
      rate: +formDetails["rate"],
      barCode: "",
      description: formDetails["description"],
      pricingType: formDetails["pricingType"],
      commodityTypeId: formDetails["commodityTypeId"],
    };
    props.AddFixedPriceItem(reqParams);
  }, [formDetails]);

  return (
    <div className="fixeditem-form History-grid" style={{ width: "95%" }}>
      <div className="fixeditem-form-heading">Details</div>
      <div className="fixeditem-form-content">
        <div className="fixeditem-form-field">
          <p>Code</p>
          <TextBoxComponent
            disabled
            style={{ fontSize: "15px", textAlign: "center", fontWeight: 500 }}
            value={formDetails["description"]}
          />
        </div>
        <div className="fixeditem-form-field">
          <p>Pricing Type</p>
          <TextBoxComponent
            disabled
            style={{ fontSize: "15px", textAlign: "center", fontWeight: 500 }}
            value={formDetails["pricingType"]}
          />
        </div>
        {/* <Divider /> */}
        <div className="fixeditem-form-field">
          <p>Price Per Box/Bin</p>
          <TextBoxComponent
            disabled={formDetails["pricingType"] === "Fixed price"}
            style={{ fontSize: "15px", textAlign: "center", fontWeight: 500 }}
            value={formDetails["rate"]}
            onChange={(e: any) => {
              setFormDetails({
                ...formDetails,
                rate: e.target.value,
              });
            }}
          />
        </div>
        <div className="fixeditem-form-field">
          <p># of Bins</p>
          <TextBoxComponent
            type={"number"}
            style={{ fontSize: "15px", textAlign: "center", fontWeight: 500 }}
            value={formDetails["bins"]}
            onChange={(e: any) => {
              setFormDetails({
                ...formDetails,
                bins: e.target.value,
                boxes: "0",
              });
            }}
            disabled={formDetails["boxes"] > "0"}
          />
        </div>
        <div className="fixeditem-form-field">
          <p># of Boxes</p>
          <TextBoxComponent
            type={"number"}
            style={{ fontSize: "15px", textAlign: "center", fontWeight: 500 }}
            value={formDetails["boxes"]}
            onChange={(e: any) => {
              setFormDetails({
                ...formDetails,
                boxes: e.target.value,
                bins: "0",
              });
            }}
            disabled={formDetails["bins"] > "0"}
          />
        </div>
        <div className="fixeditem-form-field">
          <p># of Items</p>
          <TextBoxComponent
            type={"number"}
            style={{ fontSize: "15px", textAlign: "center", fontWeight: 500 }}
            value={formDetails["items"]}
            onChange={(e: any) => {
              setFormDetails({
                ...formDetails,
                items: e.target.value,
              });
            }}
          />
        </div>
      </div>

      <div
        className="col-sm-12"
        style={{ display: "flex", justifyContent: "flex-end" }}
      >
        <ButtonComponent
          style={{
            color: "black",
            margin: "20px",
            padding: "10px 30px",
          }}
          onClick={() => props?.setShowAttachmentModal(false)}
        >
          CANCEL
        </ButtonComponent>
        <ButtonComponent
          style={{
            color: "white",
            backgroundColor: "#636799",
            margin: "20px",
            padding: "10px 30px",
          }}
          onClick={() => handleAddItem()}
        >
          UPDATE ITEM
        </ButtonComponent>
      </div>
    </div>
  );
}

export default FixedItemDetailsEditForm;
