import { DialogUtility } from '@syncfusion/ej2-popups';
import { ButtonComponent } from '@syncfusion/ej2-react-buttons';
import { DateTimePickerComponent } from '@syncfusion/ej2-react-calendars';
import axios from 'axios';
import moment from 'moment-timezone';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import uploadIcon from '../../../icons/common/import.png';
import { RootState } from '../../../redux/store';
import ExcelService from '../../../services/excel';
import notify from '../../../services/toasterService';
import ContentHeader from '../../common/ContentHeader/ContentHeader';
import Divider from '../../common/divider/Divider';
import LLModal from '../../common/llModal/LLModal';
import {
    setSelectedRecrdsExport,
    setTabNameForRowData,
} from '../../contactTabs/contactReducer';
import Grid from '../../grid/Grid';
import { EndPoints } from '../constants';
import ForceToTrashModal from '../scannedItems/forceToTrash';
import { setScanHistoryData, setScanHistoryDbCount } from '../scanReducer';
import {
    generateHisoryGrid,
    getEndpoint,
    getScanEndPoint,
    ScanHistoryHeaders,
} from '../utils';
import ManagementApprovalModal from '../scannedItems/managementApproval';
import NoCheckBoXGrid from '../../grid/fiscal-year-setup-grid/fiscal-year-setup-grid';
import HazardousModal from '../scannedItems/hazardousItems';
const ScanHistory = () => {
    const dispatch = useDispatch();
    const [HistoryData, setHistoryData] = useState([]);
    const [selectedrow, setselectedrow] = useState(false);
    const [endDate, setEndDate] = useState(new Date());
    const [startDate, setStartDate] = useState(new Date());
    const [addScreen, setAddScreen] = useState(false);
    const [selectedRowData, setSelectedRowData] = useState<any[]>([]);
    const [isCaptureReasonCode, setIsCaptureReasonCode] = useState(false);
    const [reasonCodeList, setReasonCodeList] = useState([]);
    const [isForceToTrash, setIsForcetoTrash] = useState(false);
    const [isDeleted, setIsDeleted] = useState(false);
    const [isReverseDispose, setIsReverseDispose] = useState(false);
    const [disableForceToTrash, setDisableForceToTrash] = useState(false);
    const [userFilterObj, setUserFilterObj] = useState({});
    // const [DbCount, setDbCount] = useState(0);
    const [isThirteenDigitUPC, setIsThirteenDigitUPC] = useState(false);
    const [showModalType, setShowModalType] = useState('');
    const [isFrom, setIsFrom] = useState('');
    const [reasonCode, setReasonCode] = useState('');
    const [subDisposition, setSubDisposition] = useState('');
    const [thirteenDigitType, setThirteenDigitType] = useState('');

    let historyEndDate;
    let filterDataObj = {};
    const historyDataReducer = useSelector(
        (state: RootState) => state.scanReducer.ScanHistoryData
    );
    // console.log(historyDataReducer.payload);
    const setScanSettings = useSelector(
        (state: RootState) => state.scanReducer.scanSettings
    );

    const scanHistoryDbCount = useSelector(
        (state: RootState) => state.scanReducer.scanHistoryDbCount
    );

    //clear popup data
    const childRef = useRef<null>(null);

    function clearModal() {
        if (childRef?.current) {
            console.log(childRef);
            const clear = childRef.current['clearForm'] as (params?: any) => {};
            clear();
        }
    }

    const getSelectedRecord = (e: any) => {
        setSelectedRowData(e);
        const isTrashable = e.filter((item: any) => {
            // if ( item?.disposition === 'ALERT DESTROY!' || item?.disposition === 'ALERT DISPOSE!' ) {
            //     setIsReverseDispose(false);
            //     setIsDeleted(false);
            //     setDisableForceToTrash(true);
            // } else {
            //     setIsReverseDispose(true);
            //     setIsDeleted(true);
            //     // setDisableForceToTrash(true);
            // }

            // if (item.isDeleted === 'Yes' || item?.isBoxClosed === 'Yes') {
            //     setIsDeleted(true);
            //     setIsReverseDispose(true);
            //     setDisableForceToTrash(true);
            //     if (item?.isBoxClosed === 'Yes') {
            //         notify('Outbound Box is closed!', 'error');
            //     }
            // } else {
            //     setIsDeleted(false);
            //     setIsReverseDispose(true);
            //     setDisableForceToTrash(false);
            // }

            if (
                item?.disposition === 'ALERT DESTROY' ||
                item?.disposition === 'ALERT DISPOSE'
            ) {
                if (item.isDeleted === 'Yes' || item?.isBoxClosed === 'Yes') {
                    setIsDeleted(true);
                    setIsReverseDispose(true);
                    setDisableForceToTrash(true);
                    if (
                        item?.isBoxClosed === 'Yes' &&
                        item?.scanMode === 'bin'
                    ) {
                        notify('Outbound Bin is closed!', 'error');
                    } else if (
                        item?.isBoxClosed === 'Yes' &&
                        item?.scanMode !== 'bin'
                    ) {
                        notify('Outbound Box is closed!', 'error');
                    }
                } else {
                    setIsReverseDispose(false);
                    setIsDeleted(false);
                    setDisableForceToTrash(true);
                }
            } else if (
                item.isDeleted === 'Yes' ||
                item?.isBoxClosed === 'Yes'
            ) {
                setIsDeleted(true);
                setIsReverseDispose(true);
                setDisableForceToTrash(true);
                if (item?.isBoxClosed === 'Yes' && item?.scanMode === 'bin') {
                    notify('Outbound Bin is closed!', 'error');
                } else if (
                    item?.isBoxClosed === 'Yes' &&
                    item?.scanMode !== 'bin'
                ) {
                    notify('Outbound Box is closed!', 'error');
                }
            } else if (
                item.disposition === 'TRASH' ||
                item.disposition === 'DESTROY' ||
                item.disposition === 'DISPOSE'
            ) {
                setIsDeleted(false);
                setIsReverseDispose(false);
                setDisableForceToTrash(true);
            } else {
                setDisableForceToTrash(false);
                setIsDeleted(false);
                setIsReverseDispose(true);
            }
        });
        console.log(isForceToTrash);

        if (e.length !== 0) {
            setselectedrow(true);
        } else {
            setselectedrow(false);
        }
        console.log(e);
        console.log(isForceToTrash, 'isForceToTrash');
        console.log(isDeleted, 'isDeleted');

        console.log(isReverseDispose, 'isReverseDispose');
    };

    const getDeSelectedRecord = (e: any) => {
        setSelectedRowData(e);
        if (e && e.length !== 0) {
            setselectedrow(true);
        } else {
            setDisableForceToTrash(false);
            setIsDeleted(false);
            setselectedrow(false);
        }
    };
    let DialogObj: any;

    const seletedDataToExport = useSelector(
        (state: RootState) => state.contactReducer.selectedRecordsForExport
    );

    /* Excute code on page load */
    useEffect(() => {
        // historyEndDate = new Date();
        // historyStartDate = new Date(historyEndDate);
        // historyStartDate.setDate(historyEndDate.getDate() - 1);
        // setStartDate(historyStartDate);
        dispatch(setTabNameForRowData([]));
        const reasonCodeObj: any = localStorage.getItem('reasonCodes');
        if (reasonCodeObj) {
            const reasonCodeParseObj = JSON.parse(reasonCodeObj);
            setIsCaptureReasonCode(reasonCodeParseObj.captureReasonCode);
            setReasonCodeList(reasonCodeParseObj.reasonCodeList);
            // getHistoryData();
        }

        if (
            seletedDataToExport &&
            seletedDataToExport.length > 0 &&
            seletedDataToExport !== null &&
            seletedDataToExport !== undefined
        ) {
            // setDisableForceToTrash(true);
        } else {
            setDisableForceToTrash(true);
            setIsReverseDispose(true);
            setIsDeleted(true);
        }
    }, [seletedDataToExport]);

    function buttonClick() {
        DialogObj = DialogUtility.confirm({
            animationSettings: { effect: 'Zoom' },
            cancelButton: { text: 'Cancel', click: cancelClick },
            closeOnEscape: true,
            content: 'Are you sure you want to delete ?',
            okButton: { text: 'OK', click: okClick },
            showCloseIcon: true,
            title: ' Confirmation',
            position: { X: 'center', Y: 'center' },
        });
    }

    function okClick() {
        deleteScan();
    }

    function cancelClick() {
        //Hide the dialog
        DialogObj.hide();
    }

    const deleteScan = async () => {
        DialogObj.hide();
        const deleteUrl = getScanEndPoint('deleteHistory');
        const selectedData = selectedRowData;
        console.log(selectedData, 'selectedData');
        // if (selectedData![0]?.id !== undefined && selectedData![0]?.id !== null) {
        axios
            .delete(deleteUrl + selectedData![0]?.id)
            .then((res: any) => {
                console.log(res);
                const data = res.data;
                if (data) {
                    getHistoryData();
                    let array: any;
                    array = [...HistoryData];
                    const itemIndex = array.findIndex(
                        (x: any) => x.id === selectedData![0]?.id
                    );
                    if (itemIndex !== -1) {
                        array.splice(itemIndex, 1);
                        setHistoryData(array);
                        dispatch(setScanHistoryData(array));
                    }
                }
                notify(data.message.messageText, 'error');
                dispatch(setSelectedRecrdsExport([]));
            })
            .catch((err) => err);
        // }
    };

    const hazardousDispositions = async (sub: any, type: string) => {
        setIsFrom(type);
        const selectedData = selectedRowData;
        let deleteUrl: any = getEndpoint(
            EndPoints.UPDATESCANITEM,
            selectedData![0].id
        );
        const body = { disposition: 'hazardous', subDisposition: sub };
        handleReverseDisposition(deleteUrl, body);
    };
    const disposition = async (type: string, reasonCode?: any) => {
        setIsFrom(type);
        setReasonCode(reasonCode);
        const selectedData = selectedRowData;
        let deleteUrl: any = getEndpoint(
            EndPoints.UPDATESCANITEM,
            selectedData![0].id
        );
        let body = {};
        if (type !== 'force') {
            let isThirteenDigit = false;
            const isTrashable = selectedData.filter((item: any) => {
                if (item.description === '13 DIGIT UPC') {
                    setIsThirteenDigitUPC(true);
                    isThirteenDigit = true;
                }
            });
            if (isThirteenDigit) {
                setShowModalType('13d');
                setAddScreen(true);
            } else {
                body = { reverseDisposition: selectedRowData[0].disposition };
                handleReverseDisposition(deleteUrl, body);
            }
        } else {
            body = { disposition: 'trash', reasonCode: reasonCode };
            handleReverseDisposition(deleteUrl, body);
        }
    };

    const handleReverseDispositionDestinations = (type: any) => {
        const selectedData = selectedRowData;
        let body = {};
        let deleteUrl: any = getEndpoint(
            EndPoints.UPDATESCANITEM,
            selectedData![0].id
        );
        setThirteenDigitType(type);
        setIsFrom('13digitReverse');
        body = {
            reverseDisposition: type,
            itemType: '13digit',
            disposition: type,
        };
        handleReverseDisposition(deleteUrl, body);
        setAddScreen(false);
        setShowModalType('');
        setIsThirteenDigitUPC(false);
    };
    const handleReverseDisposition = async (deleteUrl: any, body: any) => {
        await axios
            .patch(deleteUrl, body)
            .then((res: any) => {
                const data = res.data;
                if (data) {
                    getHistoryData();
                    dispatch(setSelectedRecrdsExport([]));
                }
                dispatch(setSelectedRecrdsExport([]));
                notify(data.message.messageText, 'error');
            })
            .catch((err) => {
                if (err?.response?.data?.code === 'SCN-00008') {
                    setShowModalType('OutboundMaxQty');
                    setAddScreen(true);
                }
            });
    };

    const handleOutboundMaxScanQty = () => {
        if (isFrom === 'force') {
            const body = {
                disposition: 'trash',
                reasonCode: reasonCode,
                outboundBoxMaxApproved: true,
            };
            handleReverseDisposition(
                getEndpoint(EndPoints.UPDATESCANITEM, selectedRowData![0].id),
                body
            );
        } else if (isFrom === 'hazardous') {
            const body = {
                disposition: 'hazardous',
                subDisposition: subDisposition,
                outboundBoxMaxApproved: true,
            };
            handleReverseDisposition(
                getEndpoint(EndPoints.UPDATESCANITEM, selectedRowData![0].id),
                body
            );
        } else if (isFrom === '13digitReverse') {
           const body = {
                reverseDisposition: thirteenDigitType,
                itemType: '13digit',
                disposition: thirteenDigitType,
                outboundBoxMaxApproved: true,
            };
            handleReverseDisposition(
                getEndpoint(EndPoints.UPDATESCANITEM, selectedRowData![0].id),
                body
            );

        } else {
            const body = {
                reverseDisposition: selectedRowData[0].disposition,
                outboundBoxMaxApproved: true,
            };
            handleReverseDisposition(
                getEndpoint(EndPoints.UPDATESCANITEM, selectedRowData![0].id),
                body
            );
        }
    };

    const handleForshtoTrash = (reasonCode: any) => {
        disposition('force', reasonCode);
    };

    const changeHistoryDates = (props: any) => {
        historyEndDate = props.value;
        getHistoryData(null, historyEndDate);
    };

    const handleRestForceToTrashModal = (type: any) => {
        if (type === 'force' && isCaptureReasonCode === false) {
            if (
                seletedDataToExport[0].disposition === 'NOT FOUND' &&
                type === 'force'
            ) {
                setShowModalType('forceNF');
                setAddScreen(true);
            } else {
                disposition('force', '');
            }
        } else {
            if (
                seletedDataToExport[0].disposition === 'NOT FOUND' &&
                type === 'force'
            ) {
                setShowModalType('forceNF');
                setAddScreen(true);
            } else {
                setShowModalType('force');
                setAddScreen(true);
            }
        }
    };

    const handleForceTrashModal = (type: any) => {
        if (type === 'force' && isCaptureReasonCode === false) {
            setAddScreen(false);
            disposition('force', '');
        } else if (type === 'hazardous') {
            setShowModalType('hazardous');
            setAddScreen(true);
        } else {
            setShowModalType('force');
            setAddScreen(true);
        }
    };

    const handleHazrdousModal = (e: any, type: any) => {
        hazardousDispositions(e, 'hazardous');
        setSubDisposition(e);
    };

    const resetModal = () => {
        setAddScreen(false);
    };

    // const excelExport = () => {
    //   ExcelService.exportAsExcel('','',"History", HistoryData);
    // };

    const excelExport = () => {
        if (
            seletedDataToExport !== null &&
            seletedDataToExport !== undefined &&
            seletedDataToExport.length > 0
        ) {
            ExcelService.exportAsExcel('', 'History', seletedDataToExport);
        } else {
            // ExcelService.exportAsExcel('','',"History", HistoryData);
            downloadHistoryData(startDate, endDate);
        }
    };

    let timeoutSearch: any;

    const getUserFilters = (events: any) => {
        console.log(events);
        if (events.requestType !== 'refresh') {
            clearTimeout(timeoutSearch);
            timeoutSearch = setTimeout(() => {
                getFilterEvents(events);
            }, 300);
        }
        dispatch(setSelectedRecrdsExport([]));
    };

    const getFilterEvents = (events: any) => {
        if (
            events !== undefined &&
            events !== null &&
            events?.columns?.length > 0 &&
            events.action === 'filter'
        ) {
            let columnFilterData = [];
            filterDataObj = {};
            columnFilterData = events?.columns;
            columnFilterData?.map((item: any) => {
                // const obj = { key: item?.properties?.field, value: item?.properties?.value };
                // filterData.push(obj);
                if (
                    item?.properties?.field !== 'reclaimCenter' &&
                    item?.properties?.field !== 'scanGroup' &&
                    item?.properties?.field !== 'endDate' &&
                    item?.properties?.field !== 'scanStation' &&
                    item?.properties?.field !== 'startDate'
                ) {
                    Object.assign(filterDataObj, {
                        [item?.properties?.field]: item?.properties?.value,
                    });
                }
            });
            setTimeout(() => {
                getHistoryData();
            }, 300);
        } else if (
            events !== undefined &&
            events !== null &&
            events.action === 'clearFilter'
        ) {
            let columnFilterData = [];
            filterDataObj = {};
            columnFilterData =
                events?.currentFilterObject?.parentObj?.properties?.columns;
            columnFilterData?.map((item: any) => {
                if (
                    item?.properties?.field !== 'reclaimCenter' &&
                    item?.properties?.field !== 'scanGroup' &&
                    item?.properties?.field !== 'endDate' &&
                    item?.properties?.field !== 'scanStation' &&
                    item?.properties?.field !== 'startDate'
                ) {
                    Object.assign(filterDataObj, {
                        [item?.properties?.field]: item?.properties?.value,
                    });
                }
            });
            setTimeout(() => {
                getHistoryData();
            }, 300);
        } else if (events?.columns?.length === 0) {
            filterDataObj = {};
            setTimeout(() => {
                getHistoryData();
            }, 300);
        }
        dispatch(setSelectedRecrdsExport([]));
    };

    const getHistoryData = async (startDate1?: any, endDate1?: any) => {
        const startDate = startDate1
            ? moment(startDate1)
            : moment().tz('America/New_York');
        let endDate = moment(endDate1);
        startDate.add(-1, 'day');
        startDate.set('hour', 23);
        startDate.set('minute', parseInt('00', 8));
        startDate.set('second', parseInt('00', 8));
        if (!endDate1) {
            endDate = endDate1
                ? moment(endDate1)
                : moment().tz('America/New_York');
            endDate.set('hour', 22);
            endDate.set('minute', 59);
            endDate.set('second', 59);
        }
        const endPointURL = getScanEndPoint('History');
        const body = {
            filter: {
                scanStation: setScanSettings.payload.scanStation
                    ? setScanSettings.payload.scanStation
                    : '',
                reclaimCenter: setScanSettings.payload.reclaimCenter
                    ? setScanSettings.payload.reclaimCenter
                    : '',
                startDate: startDate.format('yyyy-MM-DD HH:mm:ss.SSS'),
                endDate: endDate?.format('yyyy-MM-DD HH:mm:ss.SSS'),
            },
            // pagination: {
            //     page: 0,
            //     size: 1000,
            // },
        };
        // if (!endDate1) {
        //     delete body.filter.endDate;
        // }
        const filterKeys = Object.keys(filterDataObj);

        if (filterKeys.length > 0) {
            setUserFilterObj(filterDataObj);
            Object.assign(body.filter, filterDataObj);
        } else {
            setUserFilterObj({});
        }

        let TabData: any;
        await axios
            .post(endPointURL, body)
            .then((res: any) => {
                TabData = [];
                TabData = res.data;
                const dBCount = res?.data?.data?.totalElements;
                setTimeout(() => {
                    setHistoryData([]);
                    setHistoryData(TabData.data.content);
                    dispatch(setScanHistoryDbCount(dBCount));
                    dispatch(setScanHistoryData(TabData.data.content));
                }, 20);
                dispatch(setSelectedRecrdsExport([]));
            })
            .catch((err) => err);
    };

    const downloadHistoryData = async (startDate1?: any, endDate1?: any) => {
        const startDate = startDate1 ? moment(startDate1) : moment();
        startDate.add(-1, 'day');
        startDate.set('hour', 23);
        startDate.set('minute', parseInt('00', 8));
        startDate.set('second', parseInt('00', 8));
        let endDate = null;
        if (!endDate1) {
            endDate = endDate1
                ? moment(endDate1)
                : moment().tz('America/New_York');
            endDate.set('hour', 22);
            endDate.set('minute', 59);
            endDate.set('second', 59);
        }
        const endPointURL = getScanEndPoint('History');
        const body = {
            filter: {
                scanGroup: setScanSettings.payload.scangroup
                    ? setScanSettings.payload.scangroup
                    : '',
                scanStation: setScanSettings.payload.scanStation
                    ? setScanSettings.payload.scanStation
                    : '',
                reclaimCenter: setScanSettings.payload.reclaimCenter
                    ? setScanSettings.payload.reclaimCenter
                    : '',
                startDate: startDate.format('yyyy-MM-DD HH:mm:ss.SSS'),
                endDate: endDate?.format('yyyy-MM-DD HH:mm:ss.SSS'),
            },
            pagination: {
                page: 0,
                size:
                    scanHistoryDbCount?.payload !== 0 &&
                    scanHistoryDbCount?.payload !== undefined &&
                    scanHistoryDbCount?.payload !== null
                        ? scanHistoryDbCount?.payload
                        : 1000,
            },
        };
        if (!endDate1) {
            delete body.filter.endDate;
        }
        // console.log(userFilterObj);
        const filterKeys = Object.keys(userFilterObj);
        if (filterKeys.length > 0) {
            Object.assign(body.filter, userFilterObj);
        }
        console.log(body, 'Download');
        let TabData: any;
        await axios
            .post(endPointURL, body)
            .then((res: any) => {
                TabData = [];
                TabData = res.data.data.content;
                const modelData = generateHisoryGrid('getHistory', TabData);
                ExcelService.exportAsExcel('', 'History', modelData);
            })
            .catch((err) => {
                notify(err);
            });
    };
    const handleCancel = () => {
        setAddScreen(false);
        setShowModalType('');
        setIsThirteenDigitUPC(false);
    };

    return (
        <div className="col-sm-12 col-md-12 col-lg-12 ">
            <div className="control-pane">
                <div className="control-section tab-control-section">
                    <div className="col-sm-12 col-md-12 col-lg-12  child-data-section ">
                        <div className="col-sm-3 mt-3">
                            <p className="page-title">History</p>
                        </div>
                        <div className="col-sm-9 px-0">
                            <ButtonComponent
                                // disabled={!selectedrow}
                                disabled={
                                    seletedDataToExport === undefined ||
                                    seletedDataToExport === null ||
                                    seletedDataToExport.length === 0 ||
                                    disableForceToTrash === true
                                }
                                cssClass="e-outline eg-btn-warning float-right ml-3"
                                onClick={() =>
                                    handleRestForceToTrashModal('force')
                                }
                            >
                                FORCE TO TRASH
                            </ButtonComponent>

                            <ButtonComponent
                                // disabled={!selectedrow}
                                disabled={
                                    isDeleted === true ||
                                    isReverseDispose === true ||
                                    seletedDataToExport === undefined ||
                                    seletedDataToExport === null ||
                                    seletedDataToExport.length === 0
                                }
                                cssClass="e-outline eg-btn-secondary float-right ml-3 p-0"
                                onClick={() => disposition('reverse')}
                            >
                                Reverse Disposition
                            </ButtonComponent>

                            <ButtonComponent
                                // disabled={!selectedrow}
                                disabled={
                                    seletedDataToExport === undefined ||
                                    seletedDataToExport === null ||
                                    seletedDataToExport.length === 0 ||
                                    isDeleted === true
                                }
                                cssClass="e-outline eg-btn-secondary float-right ml-3"
                                onClick={() => buttonClick()}
                            >
                                Delete Scan
                            </ButtonComponent>

                            <ButtonComponent
                                cssClass="e-outline opt-btn-light float-right ml-3 highlight-btn py-4 px-4"
                                onClick={() => excelExport()}
                            >
                                <img src={uploadIcon} alt="Import" />
                            </ButtonComponent>

                            <div className="float-right mr-2">
                                <div className="control-pane">
                                    <div className="control-section">
                                        <div className="scanhistory datepicker-control-section">
                                            <DateTimePickerComponent
                                                id="daterangepicker"
                                                value={endDate}
                                                placeholder="Select a range"
                                                delayUpdate={true}
                                                change={changeHistoryDates}
                                                min={startDate}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div
                        className="col-sm-12 col-md-12 col-lg-12 child-data-section"
                        id="sd"
                    >
                        <NoCheckBoXGrid
                            config={ScanHistoryHeaders}
                            data={generateHisoryGrid(
                                'getHistory',
                                historyDataReducer?.payload
                            )}
                            width={'100%'}
                            height={'calc(100vh - 420px)'}
                            onRowSelect={getSelectedRecord}
                            onRowDeselect={getDeSelectedRecord}
                            hideSpinner={true}
                            onFilter={getUserFilters}
                            //isFromScanHistory={true}
                        />
                        {/* <ScanGrid
              data={generateHisoryGrid("getHistory", HistoryData)}
              config={ScanHistoryHeaders}
              width={"100%"}
              height={gridHieght}
            /> */}
                    </div>
                    <LLModal
                        handleClose={() => {
                            setAddScreen(false);
                            clearModal();
                            setIsThirteenDigitUPC(false);
                            setShowModalType('');
                        }}
                        show={addScreen}
                        width={800}
                    >
                        {showModalType === 'force' ? (
                            <ForceToTrashModal
                                ref={childRef}
                                setAddScreen={setAddScreen}
                                handleForceToTrash={handleForshtoTrash}
                                isCaptureReasonCode={isCaptureReasonCode}
                                reasonCodeList={reasonCodeList}
                                clearModal={clearModal}
                            />
                        ) : showModalType === 'hazardous' ? (
                            <HazardousModal
                                setAddScreen={setAddScreen}
                                handleHazardous={handleHazrdousModal}
                                clearModal={resetModal}
                                resetFocusToUpc={resetModal}
                            />
                        ) : showModalType === 'forceNF' ? (
                            <>
                                <ContentHeader value={'Force to Trash'} />
                                <Divider />
                                <div className="col-sm-12 mt-5">
                                    <div className="col-sm-3">
                                        <button
                                            className="btn btn-danger inbound-box trash-button"
                                            onClick={(e) =>
                                                handleForceTrashModal('force')
                                            }
                                        >
                                            FORCE TO <br />
                                            TRASH
                                        </button>
                                    </div>
                                    <div className="col-sm-3">
                                        <button
                                            className="btn btn-primary inbound-box hazardous-btn"
                                            onClick={(e) =>
                                                handleForceTrashModal(
                                                    'hazardous'
                                                )
                                            }
                                        >
                                            HAZARDOUS
                                            <br /> FORCE TO <br />
                                            TRASH
                                        </button>
                                    </div>
                                </div>
                            </>
                        ) : showModalType === 'OutboundMaxQty' ? (
                            <ManagementApprovalModal
                                ref={childRef}
                                heading={
                                    'Outbound scan quantity for this Outbound box'
                                }
                                setAddScreen={setAddScreen}
                                isFrom={'OutboundMaxQty'}
                                handleOutboundMaxScanQty={
                                    handleOutboundMaxScanQty
                                }
                            />
                        ) : (
                            <>
                                <>
                                    <ContentHeader
                                        value={'Reverse Disposition'}
                                    />
                                    <Divider />
                                </>
                                <div className="col-sm-8 mt-5">
                                    <div className="col-sm-4">
                                        <button
                                            className="btn btn-primary inbound-box btninboundBox"
                                            onClick={() =>
                                                handleReverseDispositionDestinations(
                                                    'salvage'
                                                )
                                            }
                                        >
                                            SALVAGABLE
                                        </button>
                                    </div>
                                    <div className="col-sm-4 ml-4">
                                        <button
                                            className="btn btn-danger inbound-box btninboundBox"
                                            onClick={() =>
                                                handleReverseDispositionDestinations(
                                                    'hold'
                                                )
                                            }
                                        >
                                            HOLD
                                        </button>
                                    </div>
                                </div>
                                <div className="col-sm-4 p5"></div>
                                <div className="col-sm-12 mt-2rem">
                                    <ButtonComponent
                                        cssClass="eg-btn-secondary float-right mr-2"
                                        onClick={() => handleCancel()}
                                    >
                                        Cancel
                                    </ButtonComponent>
                                </div>
                            </>
                        )}
                    </LLModal>
                </div>
            </div>
        </div>
    );
};

export default ScanHistory;
