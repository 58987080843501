import { FC, useEffect, useState } from 'react';
import {
    ColumnDirective,
    ColumnsDirective,
    CommandColumn,
    DataStateChangeEventArgs,
    Edit,
    Filter,
    Grid,
    GridComponent,
    Inject,
    Page,
    Sort,
} from '@syncfusion/ej2-react-grids';
import { useDispatch } from 'react-redux';
import '../Grid.scss';
import {
    setItemCatalogData,
    setSelectedRCIdForBin,
    setSelectedRecrdsExport,
} from '../../contactTabs/contactReducer';
import { customizeCell } from '../CustomCell';
import { setRowData, setRowId } from '../gridReducer';
import {
    FilterOptions,
    getDefaultFilterOperator,
    pageOptions,
    selectionOptions,
} from '../gridConfig';
import ServiceHandler from '../../../services/service-handler';
import { filterQuery } from '../grid-events';
import { config } from '../config';
import loader from '../../../assets/loader.gif';
import moment from 'moment';

interface Props {
    hideSpinner?: boolean;
    isFromItemCatalog: boolean;
    upcId: string;
    updateGrid: any[];
    clearSelection?: any;
}

const RuleExceptionGrid: FC<Props> = (props: Props) => {
    const [loaderStatus, setLoaderStatus] = useState(true);
    const dispatch = useDispatch();
    let gridRef: Grid;
    const handleSelectedRow = (event: any) => {
        dispatch(setSelectedRecrdsExport(gridRef.getSelectedRecords()));
        if (event.target.classList.contains('e-check')) {
            event.cancel = true;
        } else if (gridRef && undefined !== event.data.id) {
            ServiceHandler.RuleExceptionService.getItem(event.data.id).then(
                (data) => {
                    dispatch(setRowData([]));
                    dispatch(setRowData([data]));
                    dispatch(setRowId(event.data));
                }
            );
        }
    };

    const onFirstGridCreated = () => {
        if (gridRef) {
            !props.hideSpinner && gridRef.showSpinner();
        }
    };

    const dataStateChange = (state: DataStateChangeEventArgs) => {
        if (!props.isFromItemCatalog) {
            ServiceHandler.RuleExceptionService.getFilteredItems(
                filterQuery(state)
            ).then((data) => {
                setLoaderStatus(false);
                if (gridRef) {
                    const rulesArray: any = [];
                    data.result.map((row: any) => {
                        rulesArray.push({
                            id: row.id,
                            activeForGridRules:
                                row.active === true ? 'Active' : 'Inactive',
                            ruleCode: row.ruleCode,
                            deleted: row.deleted,
                            startDate:
                                row?.startDate !== undefined &&
                                row?.startDate !== null
                                    ? moment(row?.startDate).format(
                                          'MMM DD, YYYY'
                                      )
                                    : '',
                            endDate:
                                row?.endDate !== undefined &&
                                row?.endDate !== null
                                    ? moment(row?.endDate).format(
                                          'MMM DD, YYYY'
                                      )
                                    : '',
                            ruleActive: row.ruleActive,
                        });
                    });
                    data.result = rulesArray;
                    gridRef.dataSource = [];
                    gridRef.dataSource = data;
                    dispatch(setSelectedRecrdsExport(data.result));
                }
            });
        } else {
            ServiceHandler.RuleExceptionService.getRuleExceptionsByItemCatalog(
                filterQuery(state),
                state.action?.requestType ? 0 : props.upcId
            ).then((data) => {
                setLoaderStatus(false);
                if (gridRef) {
                    const rulesArray: any = [];
                    data.result.map((row: any) => {
                        rulesArray.push({
                            id: row.id,
                            activeForGridRules:
                                row.active === true ? 'Active' : 'Inactive',
                            ruleCode: row.ruleCode,
                            deleted: row.deleted,
                            startDate:
                                row?.startDate !== undefined &&
                                row?.startDate !== null
                                    ? moment(row?.startDate).format(
                                          'MMM DD, YYYY'
                                      )
                                    : '',
                            endDate:
                                row?.endDate !== undefined &&
                                row?.endDate !== null
                                    ? moment(row?.endDate).format(
                                          'MMM DD, YYYY'
                                      )
                                    : '',
                            ruleActive: row.ruleActive,
                        });
                    });
                    data.result = rulesArray;
                    gridRef.dataSource = [];
                    gridRef.dataSource = data;
                    dispatch(setSelectedRecrdsExport(data.result));
                }
            });
        }
    };

    useEffect(() => {
        dataStateChange({ skip: 0 });
        setLoaderStatus(true);
    }, [props.updateGrid]);

    useEffect(() => {
        gridRef?.clearSelection();
    }, [props.clearSelection.length === 0]);
    return (
        <>
            <GridComponent
                id="FGrid"
                ref={(g) => {
                    if (g) gridRef = g;
                }}
                enableStickyHeader={true}
                dataStateChange={dataStateChange}
                queryCellInfo={customizeCell}
                rowHeight={48}
                height={'434'}
                created={onFirstGridCreated}
                allowSorting={true}
                allowPaging={true}
                filterSettings={FilterOptions}
                allowFiltering={true}
                selectionSettings={selectionOptions}
                pageSettings={pageOptions}
                rowSelected={(event) => handleSelectedRow(event)}
                // rowDeselected={(event) => handleSelectedRow(event)}
            >
                <ColumnsDirective>
                    <ColumnDirective type="checkbox" width="50" />
                    {config['Rule Exceptions'].map((col: any) => {
                        return (
                            <ColumnDirective
                                filter={getDefaultFilterOperator(col.dataType)}
                                key={col.field}
                                field={col.field}
                                headerText={col.header}
                                commands={col?.commands}
                                width={col.width ? col.width : '100'}
                                textAlign={col?.textAlign}
                            />
                        );
                    })}
                </ColumnsDirective>
                <Inject services={[Page, Sort, Filter, CommandColumn, Edit]} />
            </GridComponent>
            <div className="LoadImg">
                {loaderStatus ? (
                    <img className="loaderClass" src={loader} />
                ) : (
                    <></>
                )}
            </div>
        </>
    );
};
export default RuleExceptionGrid;
