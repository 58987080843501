//import "@syncfusion/ej2-base/styles/material.css";
import "@syncfusion/ej2-buttons/styles/material.css";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import AccessMgmtModal from "../../common/accessMgmtModal/acessMgmt";
import AddRole from "../roles/addRole";
import AddUser from "../usermanagement/addUserScreen";
import Resetpopup from "../usermanagement/resetpopup";

const AddAccessManagementScreen = (props: any) => {
  const dispatch = useDispatch();
  const [tabNameFinal, setTabNameFinal] = useState("");
  const { addScreen, setAddScreen, tabName } = props;

  useEffect(() => {
    console.log(tabName);
    const res = tabName?.charAt(tabName.length - 1);
    if (res === "s") {
      setTabNameFinal(tabName?.slice(0, -1));
    } else {
      setTabNameFinal(tabName);
    }
  });

  return (
    <>
      <AccessMgmtModal
        handleClose={() => setAddScreen(false)}
        show={addScreen}
        width={800}
      >
        <div className="row ml-0 mr-0 mb-30 modal-title-header">
          {tabName === "Users" ? (
            <span className="tabNameForAddScreen">Add User </span>
          ) : tabName === "Roles" ? (
            <span className="tabNameForAddScreen">Add Role</span>
          ) : tabName === "Reset" ? (
            <span className="tabNameForAddScreen">Reset Password</span>
          ) : tabName === "Access" ? (
            <span className="tabNameForAddScreen">Add Access</span>
          ) : (
            ""
          )}
        </div>

        {tabName === "Users" ? (
          <AddUser setAddScreen={setAddScreen} />
        ) : tabName === "Roles" ? (
          <AddRole setAddScreen={setAddScreen} />
        ) : tabName === "Reset" ? (
          <Resetpopup setAddScreen={setAddScreen} />
        ): (
          ""
        )}
      </AccessMgmtModal>
    </>
  );
};

export default AddAccessManagementScreen;
