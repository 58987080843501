import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { ContactType } from "../contactTabs/constants";
import LLModal from "../common/llModal/LLModal";
import { ButtonComponent } from "@syncfusion/ej2-react-buttons";

const TabsTopView = (props: any) => {
  const [tabNameFinal, setTabNameFinal] = useState("");
  const [showUploadModel, setShowUploadModel] = useState(false);
  const [rowInfo, setRowInfo] = useState<any[]>([]);
  const setTabHeading = useSelector(
    (state: RootState) => state.contactReducer?.tabHeading
  );
  const setRowData = useSelector(
    (state: RootState) => state.gridReducer?.selectedRowData
  );
  const setRowId = useSelector(
    (state: RootState) => state.gridReducer?.selectedRowDataId
  );
  const selStoreAndVendorRowData = useSelector(
    (state: RootState) => state.gridReducer?.selectedStoresOrVendorsRowData
  );
  const getInfoName = (value: string) => {
    let avatar: string = "";
    const logo = value?.split(" ");
    if (logo?.length > 1) {
      avatar = logo
        ?.map((word: string) => word[0])
        .join("")
        ?.substring(0, 2)
        ?.toUpperCase();
    } else {
      avatar = value?.substring(0, 2).toUpperCase();
    }
    return avatar;
  };
  useEffect(() => {
    const res = setTabHeading.payload?.charAt(setTabHeading.payload.length - 1);
    if (res === "s") {
      setTabNameFinal(setTabHeading.payload?.slice(0, -1));
    } else {
      setTabNameFinal(setTabHeading.payload);
    }
  });
  useEffect(() => {
    if (
      setTabHeading.payload === ContactType.STORES ||
      setTabHeading.payload === ContactType.VENDORS
    ) {
      if (selStoreAndVendorRowData && selStoreAndVendorRowData?.length > 0) {
        setRowInfo(selStoreAndVendorRowData);
      }
    } else {
      if (setRowData && setRowData?.length > 0) {
        setRowInfo(setRowData);
        // setLoaderStatus(false)
      }
    }
  }, [setRowData, selStoreAndVendorRowData, setRowId]);
  return (
    <div>
      {rowInfo && rowInfo.length > 0 ? (
        <div>
          {rowInfo.map((info) => (
            <div className="row nameCard ml-0 mr-0">
              <div className="col-sm-4 mt-10">
                {setTabHeading.payload === ContactType.RECLAIM_CENTERS ? (
                  <>
                    <span className="shortName">
                      {getInfoName(info && info?.reclaimcenterName)}
                    </span>
                    {/* <span><img src={editIcon}/> </span> */}
                  </>
                ) : setTabHeading.payload === ContactType.WAREHOUSES ? (
                  <span className="shortName">
                    {getInfoName(info && info?.warehouseName)}
                  </span>
                ) : setTabHeading.payload === ContactType.COMPANY_CODES ? (
                  <span className="shortName">
                    {getInfoName(info && info?.companyName)}
                  </span>
                ) : setTabHeading.payload === ContactType.CARRIERS ? (
                  <span className="shortName">
                    {getInfoName(info && info?.carrierName)}
                  </span>
                ) : setTabHeading.payload === ContactType.CUSTOMERS ? (
                  <span className="shortName">
                    {getInfoName(info && info?.customerName)}
                  </span>
                ) : setTabHeading.payload === ContactType.STORES ? (
                  <span className="shortName">
                    {getInfoName(info && info?.storeName)}
                  </span>
                ) : setTabHeading.payload === ContactType.VENDORS ? (
                  <span className="shortName">
                    {getInfoName(info && info?.apVendorName)}
                  </span>
                ) : setTabHeading.payload === ContactType.DEPARTMENTS ? (
                  <span className="shortName">
                    {getInfoName(info && info?.departmentName)}
                  </span>
                ) : setTabHeading.payload === ContactType.GL_ACCOUNTS ? (
                  <span className="shortName">
                    {getInfoName(info && info?.glAccountName)}
                  </span>
                ) : setTabHeading.payload === ContactType.COMMODITY_TYPES ? (
                  <span className="shortName">
                    {getInfoName(info && info?.code)}
                  </span>
                ) : setTabHeading.payload === ContactType.PALLET_LOCATIONS ? (
                  <span className="shortName">
                    {getInfoName(info && info?.palletName)}
                  </span>
                ) : setTabHeading.payload === ContactType.STORE_DISPOSITIONS ? (
                  <span className="shortName">
                    {getInfoName(info && info?.storeDisposition)}
                  </span>
                ) : //    : setTabHeading.payload === ContactType.WAREHOUSE_LOCATIONS ?
                //    <span className="shortName">{getInfoName(info && info?.warehouseLocation)}</span>
                setTabHeading.payload === ContactType.UPCDefaultWHLoc ? (
                  <span className="shortName">
                    {getInfoName(info && info?.warehouseLocation)}
                  </span>
                ) : setTabHeading.payload === ContactType.CustomWHLoc ? (
                  <span className="shortName">
                    {getInfoName(info && info?.warehouseLocation)}
                  </span>
                ) : (
                  ""
                )}
              </div>
              <div className="col-sm-8">
                <div className="row nameRow">
                  {setTabHeading.payload === ContactType.RECLAIM_CENTERS ? (
                    <span
                      className="name fontWeight-Bold"
                      title={info?.reclaimcenterName}
                    >
                      {info?.reclaimcenterName}
                    </span>
                  ) : setTabHeading.payload === ContactType.WAREHOUSES ? (
                    <span
                      className="name fontWeight-Bold"
                      title={info?.warehouseName}
                    >
                      {info?.warehouseName}
                    </span>
                  ) : setTabHeading.payload === ContactType.COMPANY_CODES ? (
                    <span
                      className="name fontWeight-Bold"
                      title={info?.companyName}
                    >
                      {info?.companyName}
                    </span>
                  ) : setTabHeading.payload === ContactType.CARRIERS ? (
                    <span
                      className="name fontWeight-Bold"
                      title={info?.carrierName}
                    >
                      {info?.carrierName}
                    </span>
                  ) : setTabHeading.payload === ContactType.CUSTOMERS ? (
                    <span
                      className="name fontWeight-Bold"
                      title={info?.customerName}
                    >
                      {info?.customerName}
                    </span>
                  ) : setTabHeading.payload === ContactType.VENDORS ? (
                    <span
                      className="name fontWeight-Bold"
                      title={info?.apVendorName}
                    >
                      {info?.apVendorName}
                    </span>
                  ) : setTabHeading.payload === ContactType.STORES ? (
                    <span
                      className="name fontWeight-Bold"
                      title={info?.storeName}
                    >
                      {info?.storeName}
                    </span>
                  ) : setTabHeading.payload === ContactType.DEPARTMENTS ? (
                    <span
                      className="name fontWeight-Bold"
                      title={info?.departmentName}
                    >
                      {info?.departmentName}
                    </span>
                  ) : setTabHeading.payload === ContactType.GL_ACCOUNTS ? (
                    <span
                      className="name fontWeight-Bold"
                      title={info?.glAccountName}
                    >
                      {info?.glAccountName}
                    </span>
                  ) : setTabHeading.payload === ContactType.COMMODITY_TYPES ? (
                    <span className="name fontWeight-Bold" title={info?.code}>
                      {info?.code}
                    </span>
                  ) : setTabHeading.payload === ContactType.PALLET_LOCATIONS ? (
                    <span
                      className="name fontWeight-Bold"
                      title={info?.palletName}
                    >
                      {info?.palletName}
                    </span>
                  ) : setTabHeading.payload ===
                    ContactType.STORE_DISPOSITIONS ? (
                    <span
                      className="name fontWeight-Bold"
                      title={info?.storeDisposition}
                    >
                      {info?.storeDisposition}
                    </span>
                  ) : //    : setTabHeading.payload === ContactType.WAREHOUSE_LOCATIONS ?
                  //    <span className="name" title={info?.warehouseLocation}>{info?.warehouseLocation}</span>
                  setTabHeading.payload === ContactType.UPCDefaultWHLoc ? (
                    <span
                      className="name fontWeight-Bold"
                      title={info?.warehouseLocation}
                    >
                      {info?.warehouseLocation}
                    </span>
                  ) : setTabHeading.payload === ContactType.CustomWHLoc ? (
                    <span
                      className="name fontWeight-Bold"
                      title={info?.warehouseLocation}
                    >
                      {info?.warehouseLocation}
                    </span>
                  ) : (
                    ""
                  )}
                </div>
                <div className="row">
                  <span className="contactType"> {tabNameFinal}</span>
                </div>
              </div>
            </div>
          ))}
        </div>
      ) : (
        <div></div>
      )}
      <LLModal
        handleClose={() => setShowUploadModel(false)}
        show={showUploadModel}
      >
        <div className="row">
          <div className="col-sm-3">
            <span>Photo Upload</span>
          </div>
          <div className="col-sm-4">
            <span>Photo Upload</span>
          </div>
        </div>
        <div className="row mt-2rem">
          <div className="col-sm-4">
            <ButtonComponent cssClass="eg-btn-secondary">
              CANCEL
            </ButtonComponent>
            <ButtonComponent cssClass="eg-btn-primary">
              CHANGE PHOTO
            </ButtonComponent>
          </div>
        </div>
      </LLModal>
    </div>
  );
};

export default TabsTopView;
