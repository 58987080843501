import { ButtonComponent } from '@syncfusion/ej2-react-buttons';
import { DateRangePickerComponent } from '@syncfusion/ej2-react-calendars';
import { TabComponent } from '@syncfusion/ej2-react-navigations';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import editIcon from '../../assets/EditRuleExcep.png';
import { addMasterDataEndPoint, getAllMasterData, getEndPoint } from './utils';
import axios from 'axios';
import { setCurrentContact, setWHLocationsData } from './contactReducer';
import { ContactType } from './constants';
import notify from '../../services/toasterService';
import closeIcon from '../../icons/common/cancel.png';
import AsyncSelect from 'react-select/async';
import { environment } from '../../environment';
import {
    casualDataAddPopupEndPoints,
    RuleExceptionEndPointsForFiltering,
} from '../settings/utils';
import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns';
let vendorList: any;
let itemCatalogList: any;
let storesList: any;
let dropDownListObject: any;
let dropDownVendorsListObject: any;
let dropDownListStoresObject: any;
const RuleExceptionAddScreen = (props: any) => {
    const [show, setShow] = useState('Rule');
    const handleEditDataRules = () => {
        setShow('Rule');
    };

    let [tabObj, setTabObj] = useState<any>();
    const fooRef = React.createRef();
    const { setAddScreen } = props;
    const dispatch = useDispatch();
    let pageNo = environment.react_app_page;
    let pageSize = environment.react_app_pagesize;
    let clientGroups: any;
    const [vendorIds, setVendorIds] = useState('');
    const [customerGroupIds, setClientGroupIds] = useState('');
    let billCostByUpcDynamic: any = [];
    // let vendorIds = "";
    // let customerGroupIds = "";
    let vendorIdsSelected = '';
    let clientGroupIdSelected = '';
    const upcInfo1: any[] = [];

    const RCData = useSelector(
        (state: RootState) => state.contactReducer?.reclaimCenters
    );
    const VendorData = useSelector(
        (state: RootState) => state.contactReducer?.vendors
    );
    const StoredData = useSelector(
        (state: RootState) => state.contactReducer?.stores
    );

    const CustomerData = useSelector(
        (state: RootState) => state.contactReducer?.customers
    );
    const ClientGroupData = useSelector(
        (state: RootState) => state.contactReducer?.clientGroupData
    );
    const DispositioData = useSelector(
        (state: RootState) => state.contactReducer?.dispositionData
    );
    const ItemCatalogData = useSelector(
        (state: RootState) => state.contactReducer?.itemCatalogData
    );
    console.log('ItemCatalogData', ItemCatalogData);
    const DebitReasonData = useSelector(
        (state: RootState) => state.contactReducer?.debitReason
    );
    const upcDefaultData = useSelector(
        (state: RootState) => state.contactReducer?.WHUPCDefaultData
    );
    const upcDataList = useSelector(
        (state: RootState) => state.contactReducer?.upcData
    );
    const whseLocationData = useSelector(
        (state: RootState) => state.contactReducer?.WhareHouseLocationData
    );
    console.log(upcDataList);
    useEffect(() => {
        getWhseLocations();
        loadVendorOptions();
    }, []);
    const getWhseLocations = async () => {
        const whseLocationEndPoint = getAllMasterData('WhseLocations');
        const whseLocationResult = await axios
            .get(whseLocationEndPoint)
            .catch((err) => err);
        dispatch(setWHLocationsData(whseLocationResult.data));
    };
    vendorList = VendorData;
    itemCatalogList = ItemCatalogData;
    storesList = StoredData;
    const headerText = [
        { text: 'DEFINE RULE CRITERIA' },
        { text: 'DEFINE VALUE CHANGES' },
    ];
    const [rcLinkToRuleExcepDefineRule, setrcLinkToRuleExcepDefineRule] =
        useState<any[]>([]);
    const [
        vendorLinkToRuleExcepDefineRule,
        setVendorLinkToRuleExcepDefineRule,
    ] = useState<any[]>([]);
    const [
        customerLinkToRuleExcepDefineRule,
        setCustomerLinkToRuleExcepDefineRule,
    ] = useState<any[]>([]);
    const [
        clientGroupLinkToRuleExcepDefineRule,
        setClientGroupLinkToRuleExcepDefineRule,
    ] = useState<any[]>([]);
    const [upcLinkToRuleExcepDefineRule, setUPCLinkToRuleExcepDefineRule] =
        useState<any[]>([]);
    const [testUPCVal, setTestUPCVal] = useState<{ [key: string]: Object }[]>(
        []
    );
    const [CodeInRuleExcepDefineRule, setCodeInRuleExcepDefineRule] =
        useState('');
    const [dispositionInDefineValueChange, setDispositionInDefineValueChange] =
        useState('');
    const [
        whseLocationInDefineValueChange,
        setWhseLocationInDefineValueChange,
    ] = useState('');
    const [debitReasonInDefineValueChange, setDebitReasonInDefineValueChange] =
        useState('');
    const [billcostInDefineValueChange, setBillcostInDefineValueChange] =
        useState('');
    const [value, setvalue] = useState('');
    const [items, setItems] = useState([]);
    const [billCostByUpc, setBillCostByUpc] = useState<any>();
    const [formValues, setFormValues] = useState<any>({});
    const [upcIdsWithBillCost, setUpcIdsWithBillCost] = useState<any[]>([]);
    const [vendorsList, setVendorsList] = useState<any[]>([]);
    const [upcByVendor, setUpcByVendor] = useState<any[]>([]);
    const [storesByClientGroup, setStoresByClientGroup] = useState<any[]>([]);
    let startdateVal: any;
    let enddateVal: any;
    const autoCompleteUPCFields = {
        value: 'upc',
        text: 'upc',
    };
    const autoCompleteVendorields = {
        value: 'apVendorName',
        text: 'apVendorName',
    };
    const autoCompleteStoreFields = {
        value: 'mainframeCustomerNumber',
        text: 'storeNameAndMainframeCustomerNumber',
    };
    const handleDateRange = (e: any) => {
        const forValuesCopy = { ...formValues };
        forValuesCopy[e.target.name] = e.target.value;
        setFormValues(forValuesCopy);
        //  setSelectedRCInfo([{...selectedRCInfo[0], startdate: startdateVal}])
        //  setSelectedRCInfo([{...selectedRCInfo[0], endDate: enddateVal}])
    };

    const handleRCInDefineRule = (e: any) => {
        const addRCItem = JSON.parse(e.target.value);
        const RCInfo = [...rcLinkToRuleExcepDefineRule];
        if (
            !RCInfo.some(
                (person) =>
                    person.reclaimcenterName === addRCItem.reclaimcenterName
            )
        ) {
            RCInfo.push(addRCItem);
            setrcLinkToRuleExcepDefineRule(RCInfo);
        }
        var ddlSelectCustType = document.getElementById(
            'ReclaimId'
        ) as HTMLSelectElement;
        if (ddlSelectCustType) {
            ddlSelectCustType.selectedIndex = 0;
        }
        //RCInfo.push(addRCItem);
        //setrcLinkToRuleExcepDefineRule(RCInfo);
    };
    const removeLinkedRCInDefineRule = (value: any) => {
        let linkedRCData = [...rcLinkToRuleExcepDefineRule];
        let updatedRCData = linkedRCData.filter((item) => item.id !== value);
        setrcLinkToRuleExcepDefineRule(updatedRCData);
    };

    const handleCustomerInDefineRule = (e: any) => {
        const addVendorItem = e.itemData;
        if (addVendorItem) {
            const VendorInfo = [...customerLinkToRuleExcepDefineRule];
            if (
                !VendorInfo.some(
                    (person) => person?.storeName === addVendorItem?.storeName
                )
            ) {
                VendorInfo.push(addVendorItem);
                setCustomerLinkToRuleExcepDefineRule(VendorInfo);
            }
            var ddlSelectCustType = document.getElementById(
                'StoresID'
            ) as HTMLSelectElement;
            if (ddlSelectCustType) {
                ddlSelectCustType.value = '';
            }
            // VendorInfo.push(addVendorItem);
            // setCustomerLinkToRuleExcepDefineRule(VendorInfo);
        }
    };
    const removeLinkedCustomerInDefineRule = (value: any) => {
        let linkedVendorData = [...customerLinkToRuleExcepDefineRule];
        let updatedVendorData = linkedVendorData.filter(
            (item) => item.id !== value
        );
        setCustomerLinkToRuleExcepDefineRule(updatedVendorData);
    };
    const handleVendorInDefineRule = (e: any) => {
        const addVendorItem = e?.itemData;
        if (addVendorItem) {
            const VendorInfo = [...vendorLinkToRuleExcepDefineRule];
            if (
                !VendorInfo.some(
                    (person) =>
                        person?.apVendorNumber === addVendorItem?.apVendorNumber
                )
            ) {
                VendorInfo.push(addVendorItem);
                setVendorLinkToRuleExcepDefineRule(VendorInfo);
                const vendors = VendorInfo.reduce(
                    (s: any, el: any, idx: any, arr: any) => {
                        s += el?.id;
                        if (idx < arr.length - 1) {
                            s += ',';
                        }
                        return s;
                    },
                    ''
                );
                setVendorIds(vendors);
                vendorIdsSelected = vendors;
                loadUPCOptionsByVendor();
            }
            var ddlSelectCustType = document.getElementById(
                'VendorsId'
            ) as HTMLSelectElement;
            if (ddlSelectCustType) {
                ddlSelectCustType.value = '';
            }
            // VendorInfo.push(addVendorItem);
            // setVendorLinkToRuleExcepDefineRule(VendorInfo);
        }
    };
    const removeLinkedVendorInDefineRule = (value: any) => {
        let linkedVendorData = [...vendorLinkToRuleExcepDefineRule];
        let updatedVendorData = linkedVendorData.filter(
            (item) => item?.id !== value
        );
        setVendorLinkToRuleExcepDefineRule(updatedVendorData);
        console.log(updatedVendorData);
        const vendors = updatedVendorData.reduce(
            (s: any, el: any, idx: any, arr: any) => {
                s += el?.id;
                if (idx < arr.length - 1) {
                    s += ',';
                }
                return s;
            },
            ''
        );
        setVendorIds(vendors);
        vendorIdsSelected = vendors;
        loadUPCOptionsByVendor();
    };
    const handleClientGroupInDefineRule = (e: any) => {
        const addVendorItem = JSON.parse(e.target.value);
        const VendorInfo = [...clientGroupLinkToRuleExcepDefineRule];
        if (
            !VendorInfo.some(
                (person) =>
                    person.clientGroupName === addVendorItem.clientGroupName
            )
        ) {
            VendorInfo.push(addVendorItem);
            setClientGroupLinkToRuleExcepDefineRule(VendorInfo);
            const cg = VendorInfo.reduce(
                (s: any, el: any, idx: any, arr: any) => {
                    s += el.clientGroupNumber;
                    if (idx < arr.length - 1) {
                        s += ',';
                    }
                    return s;
                },
                ''
            );
            setClientGroupIds(cg);
            clientGroupIdSelected = cg;
            loadStoreOptionsByCustomerGroup();
        }
        // customerGroupIds = cg;
        var ddlSelectCustType = document.getElementById(
            'ClientGroupId'
        ) as HTMLSelectElement;
        if (ddlSelectCustType) {
            ddlSelectCustType.selectedIndex = 0;
        }
        // VendorInfo.push(addVendorItem);
        // setClientGroupLinkToRuleExcepDefineRule(VendorInfo);
    };
    const removeLinkedClientGroupInDefineRule = (value: any) => {
        let linkedVendorData = [...clientGroupLinkToRuleExcepDefineRule];
        let updatedVendorData = linkedVendorData.filter(
            (item) => item.id !== value
        );
        setClientGroupLinkToRuleExcepDefineRule(updatedVendorData);
        const cg = updatedVendorData.reduce(
            (s: any, el: any, idx: any, arr: any) => {
                s += el.clientGroupNumber;
                if (idx < arr.length - 1) {
                    s += ',';
                }
                return s;
            },
            ''
        );
        setClientGroupIds(cg);
        clientGroupIdSelected = cg;
        loadStoreOptionsByCustomerGroup();
    };
    const handleUPCInDefineRule = (e: any) => {
        const addVendorItem = e.itemData;
        if (addVendorItem) {
            const VendorInfo = [...upcLinkToRuleExcepDefineRule];
            if (
                !VendorInfo.some((person) => person?.upc === addVendorItem?.upc)
            ) {
                VendorInfo.push(addVendorItem);
                setUPCLinkToRuleExcepDefineRule(VendorInfo);
            }
            var ddlSelectCustType = document.getElementById(
                'UpcId'
            ) as HTMLSelectElement;
            if (ddlSelectCustType) {
                ddlSelectCustType.value = '';
            }
            // VendorInfo.push(addVendorItem);
            // setUPCLinkToRuleExcepDefineRule(VendorInfo);
        }
    };
    const removeLinkedUPCInDefineRule = (value: any) => {
        let linkedUPCData = [...upcLinkToRuleExcepDefineRule];
        let updatedUPCData = linkedUPCData.filter((item) => item.id !== value);
        setUPCLinkToRuleExcepDefineRule(updatedUPCData);
    };

    const handleChangeCode = useCallback(
        (e: any) => {
            const forValuesCopy = { ...formValues };
            forValuesCopy[e.target.name] = e.target.value;
            setFormValues(forValuesCopy);
            setCodeInRuleExcepDefineRule(e.target.value);
        },
        [formValues]
    );
    const handleChangeDisposition = useCallback(
        (e: any) => {
            const forValuesCopy = { ...formValues };
            forValuesCopy[e.target.name] = e.target.value;
            setFormValues(forValuesCopy);
            //setCodeInRuleExcepDefineRule(e.target.value);
        },
        [formValues]
    );
    //setDispositionInDefineValueChange(e.target.value);
    const handleChangeWhseLocation = useCallback(
        (e: any) => {
            const forValuesCopy = { ...formValues };
            forValuesCopy[e.target.name] = e.target.value;
            setFormValues(forValuesCopy);
            //setCodeInRuleExcepDefineRule(e.target.value);
        },
        [formValues]
    );

    const handleChangDebitReason = useCallback(
        (e: any) => {
            //setDebitReasonInDefineValueChange(e.target.value);
            const forValuesCopy = { ...formValues };
            forValuesCopy[e.target.name] = e.target.value;
            setFormValues(forValuesCopy);
            //setCodeInRuleExcepDefineRule(e.target.value);
        },
        [formValues]
    );

    const handleChangeBillCost = useCallback(
        (e: any) => {
            const forValuesCopy = { ...formValues };
            //  forValuesCopy[e.target.name] = parseFloat(e.target.value).toFixed(2);
            if (e.target.value.includes('.') === true) {
                if (e.target.value.split('.')[1].length > 2) {
                    forValuesCopy[e.target.name] = parseFloat(
                        e.target.value
                    ).toFixed(2);
                } else {
                    forValuesCopy[e.target.name] = e.target.value;
                }
            } else {
                forValuesCopy[e.target.name] = e.target.value;
            }
            setFormValues(forValuesCopy);
            //setCodeInRuleExcepDefineRule(e.target.value);
        },
        [formValues]
    );
    //setBillcostInDefineValueChange(e.target.value);
    //let upcInfo []: [];

    const handleChangeBillCostbyUPC = (e: any, UPCNum: any, idx: any) => {
        let id = UPCNum;
        let addVendorItem = {};
        if (e.target.value.toString().includes('.') === true) {
            if (e.target.value.split('.')[1].length > 2) {
                addVendorItem = {
                    billCost: parseFloat(e.target.value).toFixed(2),
                    id: UPCNum,
                };
                upcLinkToRuleExcepDefineRule[idx] = {
                    billCost: parseFloat(e.target.value).toFixed(2),
                    upc: UPCNum,
                };
                setBillCostByUpc(parseFloat(e.target.value).toFixed(2));
                console.log(billCostByUpc);
                // upcInfo1.push(addVendorItem);
            } else {
                addVendorItem = { billCost: e.target.value, id: UPCNum };
                setBillCostByUpc(e.target.value);
                upcLinkToRuleExcepDefineRule[idx] = {
                    billCost: e.target.value,
                    upc: UPCNum,
                };
                console.log(billCostByUpc);
                //upcInfo1.push(addVendorItem);
            }
        } else {
            addVendorItem = { billCost: parseInt(e.target.value), id: UPCNum };
            setBillCostByUpc(e.target.value);
            upcLinkToRuleExcepDefineRule[idx] = {
                billCost: parseInt(e.target.value),
                upc: UPCNum,
            };
            console.log(billCostByUpc);
            billCostByUpcDynamic = [...upcLinkToRuleExcepDefineRule];
        }
    };

    const handleBillCostByUpcBlur = (e: any, UPCNum: any, idx: any) => {
        let upcArray = [...upcLinkToRuleExcepDefineRule];
        upcArray[idx] = {
            billCost: e.target.value,
            id: UPCNum,
        };
        if (upcIdsWithBillCost.length > 0) {
            upcArray = [...upcIdsWithBillCost];
            upcArray[idx] = {
                billCost: e.target.value,
                id: UPCNum,
            };
        }
        setUpcIdsWithBillCost(upcArray);
    };

    const rcLinkFinalToRuleExcepDefineRule: any[] = [];
    const vendorLinkFinalToRuleExcepDefineRule: any[] = [];
    const upcLinkFinalToRuleExcepDefineRule: any[] = [];
    const customersLinkFinalToRuleExcepDefineRule: any[] = [];
    const clientGroupLinkFinalToRuleExcepDefineRule: any[] = [];
    let body: any;

    const handleAddRule = () => {
        //setTestUPCVal(uocInfo);
        console.log(upcInfo1);
        // var rule = document.getElementById('txtCode') as HTMLInputElement;
        // var ruleCodeVal = rule.value;
        // //var date= document.getElementById('txtDateRange') as HTMLInputElement;
        // var whseLoc = document.getElementById('txtWhseLocation') as HTMLSelectElement;
        // var billCostAll = document.getElementById('txtBillCostAllUPC') as HTMLInputElement;
        // var dispo = document.getElementById('ddlDisposition') as HTMLSelectElement;
        // var debitReas = document.getElementById('ddlDebitReason') as HTMLSelectElement;
        // //var dateRange = date.value;
        // var dispositionVal = dispo.value;
        // var whseLocationVal = whseLoc.value;
        // var debitReasonVal = debitReas.value;
        // var billCostAllUPCVal = billCostAll.value;
        // //var stDate = dateRange.split('-')[0];
        // //var edDate = dateRange.split('-')[1];
        // //var stDateVal = stDate.split(' ')[0];
        // //var endDateVal = edDate.split(' ')[1];

        rcLinkToRuleExcepDefineRule.map((item) =>
            rcLinkFinalToRuleExcepDefineRule.push(item.id)
        );

        vendorLinkToRuleExcepDefineRule.map((item) =>
            vendorLinkFinalToRuleExcepDefineRule.push(item.id)
        );

        upcLinkToRuleExcepDefineRule.map((item) =>
            upcLinkFinalToRuleExcepDefineRule.push(item.id)
        );

        customerLinkToRuleExcepDefineRule.map((item) =>
            customersLinkFinalToRuleExcepDefineRule.push(item.id)
        );

        clientGroupLinkToRuleExcepDefineRule.map((item) =>
            clientGroupLinkFinalToRuleExcepDefineRule.push(
                item.clientGroupNumber
            )
        );
        let upcArray: any = [];

        if (upcLinkToRuleExcepDefineRule.length > 0) {
            upcLinkToRuleExcepDefineRule.map((item: any) => {
                upcArray.push({
                    billCost: item?.billCost ? item?.billCost : null,
                    id: item.upc,
                });
            });
        }
        setUpcIdsWithBillCost(upcArray);
        body =
            // {
            //   "active": true,
            //   "billCost": parseInt(billCostAllUPCVal),
            //   "clientGroup": clientGroupLinkFinalToRuleExcepDefineRule,
            //   "customerGroups": [
            //     "string"
            //   ],
            //   "storeIds": customersLinkFinalToRuleExcepDefineRule,
            //   "debitReasonCode": debitReasonVal,
            //   "deleted": false,
            //   "disposition": dispositionVal,
            //   "endDate": enddateVal,
            //   "lastModifiedBy": "string",
            //   "lastModifiedDate": "2021-12-14T06:58:15.082Z",
            //   "reclaimCenterIds": rcLinkFinalToRuleExcepDefineRule,
            //   "ruleCode": ruleCodeVal,
            //   "startDate": startdateVal,
            //   "upcIds": upcLinkFinalToRuleExcepDefineRule,
            //   "vendorIds": vendorLinkFinalToRuleExcepDefineRule,
            //   "warehouseLocation": whseLocationVal
            // }
            {
                active: true,
                billCost: parseInt(formValues?.txtBillCostAllUPC),
                clientGroup: clientGroupLinkFinalToRuleExcepDefineRule,
                debitReasonCode: formValues?.ddlDebitReason,
                disposition: formValues?.ddlDisposition,
                endDate: formValues?.txtDateRange
                    ? formValues?.txtDateRange[1]
                    : null,
                reclaimCenterIds: rcLinkFinalToRuleExcepDefineRule,
                ruleCode: formValues?.txtRuleCode,
                startDate: formValues?.txtDateRange
                    ? formValues?.txtDateRange[0]
                    : null,
                storeIds: customersLinkFinalToRuleExcepDefineRule,
                upcIds: upcArray ? upcArray : upcIdsWithBillCost,
                // "upcIds": [
                // {
                // "billCost": 0.00,
                // "id": 16375
                // },{
                // "billCost": 1.25,
                // "id": 61776
                // }
                // ],
                //"upcIds": testUPCVal,
                vendorIds: vendorLinkFinalToRuleExcepDefineRule,
                warehouseLocation: formValues?.ddlWhseLocation,
            };
        if (rcLinkFinalToRuleExcepDefineRule.length === 0) {
            notify('RC not Empty');
        } else if (vendorLinkFinalToRuleExcepDefineRule.length === 0) {
            notify('Vendor not Empty');
        } else {
            const tabName: any = ContactType.RULE_EXCEPTIONS;
            const endpoint = addMasterDataEndPoint(tabName);
            const getEndpoint = getEndPoint(tabName);
            handleMasterDataChange(endpoint, getEndpoint);
        }
    };

    const handleEditClick = () => {
        const event = document.getElementById('tab-wizard');
        console.log(tabObj);
    };

    const handleMasterDataChange = async (
        endpoint: string,
        getEndpoint: string
    ) => {
        const result = await axios
            .post(endpoint, body)
            .then((results: any) => {
                if (results && results.data) {
                    setAddScreen(false);
                    dispatch(setCurrentContact(results.data));
                    notify('Rule Exception created successfully', 'success');
                } else {
                    notify('Failed to create Rule Exception', 'error');
                }
            })
            .catch((error: any) => {
                if (error.response) {
                    notify(error.response?.data?.message, 'error');
                }
            });
        // {generateGridData(tabName, result.data)}
        //const getresult = await axios.get(getEndpoint);
        //dispatch(setCurrentContact(results.data));
    };

    const loadVendorOptions = async (txt?: any) => {
        const endPointURL = getEndPoint(
            ContactType.VENDORS,
            pageNo,
            pageSize,
            txt?.text
        );
        await axios.get(endPointURL).then((res: any) => {
            setVendorsList(res.data.content);
        });
    };

    const loadUPCOptionsByVendor = async (txt?: any) => {
        vendorIdsSelected = vendorIdsSelected ? vendorIdsSelected : vendorIds;
        const upcEndPoint: any = RuleExceptionEndPointsForFiltering(
            'UPCs',
            pageNo,
            pageSize,
            vendorIdsSelected,
            vendorIdsSelected ? txt?.text : 'a'
        );
        await axios.get(upcEndPoint).then((res: any) => {
            setUpcByVendor(res.data.content);
        });
    };

    const loadStoreOptionsByCustomerGroup = (txt?: any) => {
        clientGroupIdSelected = clientGroupIdSelected
            ? clientGroupIdSelected
            : customerGroupIds;
        const upcEndPoint: any = RuleExceptionEndPointsForFiltering(
            'Stores',
            pageNo,
            pageSize,
            clientGroupIdSelected,
            clientGroupIdSelected ? txt?.text : 'zzz'
        );
        axios.get(upcEndPoint).then((res: any) => {
            setStoresByClientGroup(res.data.content);
        });
    };

    const defineRuleCriteriaContent = () => {
        return (
            <>
                <br />
                <div className="row">
                    <div className="col-sm-4">
                        <span
                            className="lblClass"
                            style={{ paddingRight: '10px', float: 'left' }}
                        >
                            Code
                        </span>
                        <input
                            type="number"
                            autoFocus
                            className="form-control"
                            name="txtRuleCode"
                            value={formValues['txtRuleCode']}
                            onChange={(e: any) => handleChangeCode(e)}
                        />
                    </div>
                    <div className="col-sm-4">
                        <span className="lblClass" style={{ float: 'left' }}>
                            Date Range
                        </span>
                        <span>
                            <DateRangePickerComponent
                                name="txtDateRange"
                                value={formValues['txtDateRange']}
                                id="txtDateRange"
                                onChange={(e: any) => handleDateRange(e)}
                            />
                        </span>
                    </div>
                </div>
                <hr />
                <div className="row">
                    <div className="col-sm-4">
                        <span className="lblClass" style={{ float: 'left' }}>
                            Reclaim Center(s)
                            <span className="requiredStar">*</span>
                        </span>
                        {/* <div className="selectdiv">
                    <ReactAutocomplete
                shouldItemRender={(item, value) =>
                  item.name.toLowerCase().indexOf(value.toLowerCase()) > -1
                }
                items={items}
                getItemValue={(item) => item.name}
                renderItem={(item, highlighted) => (
                  <div
                    key={item.id}
                    style={{ backgroundColor: highlighted ? "#eee" : "transparent" }}
                  >
                    {item.name}
                  </div>
                )}
                value={value}
                onChange={(e) => setvalue(e.target.value)}
                onSelect={(val) => setvalue(val)}
              />
              </div> */}
                        <select
                            className="form-control"
                            id="ReclaimId"
                            onChange={(e) => handleRCInDefineRule(e)}
                        >
                            <option>Select</option>
                            {RCData?.map((rc) => (
                                <option value={JSON.stringify(rc)}>
                                    {rc.reclaimcenterName}
                                </option>
                            ))}
                        </select>
                        <div className="RCListInRuleExceptionAdd mt-3">
                            {rcLinkToRuleExcepDefineRule &&
                                rcLinkToRuleExcepDefineRule.map((item: any) => (
                                    <div className="row linkedStoreDiv ml-0 mr-0">
                                        <div className="col-sm-10 p-0">
                                            <span
                                                title="remove"
                                                className="linkedStoreName text-underline"
                                                style={{
                                                    fontSize: '13px',
                                                    color: 'black',
                                                }}
                                            >
                                                {item.reclaimcenterName}
                                                {/* <span className="linkedStoreNameRemove" onClick={(e) => removeLinkedRCInDefineRule(item.id)}>X</span> */}
                                            </span>
                                        </div>
                                        <div className="col-sm-2 p-0">
                                            <img
                                                src={closeIcon}
                                                alt="Close"
                                                className="contactcloseIcon float-right"
                                                onClick={() =>
                                                    removeLinkedRCInDefineRule(
                                                        item.id
                                                    )
                                                }
                                            />
                                        </div>
                                    </div>
                                ))}
                        </div>
                    </div>

                    <div className="col-sm-4" style={{ paddingRight: '10px' }}>
                        <span className="lblClass" style={{ float: 'left' }}>
                            Vendor(s)<span className="requiredStar">*</span>
                        </span>
                        {/* <br/><span className="requiredText">* Required</span> */}
                        <div className="col-sm-12 p-0 upcDropDownBox">
                            {/* <AsyncSelect
                                //cacheOptions
                                id="VendorsId"
                                defaultOptions
                                getOptionLabel={(e: any) => e.apVendorName}
                                getOptionValue={(e) => e.apVendorName}
                                loadOptions={loadVendorOptions}
                                onChange={handleVendorInDefineRule}
                                placeholder="Select"
                            /> */}
                            <DropDownListComponent
                                placeholder="Select"
                                id="VendorsId"
                                allowFiltering={true}
                                ref={(scope) => {
                                    dropDownVendorsListObject = scope;
                                }}
                                dataSource={vendorsList}
                                filtering={loadVendorOptions.bind(this)}
                                fields={autoCompleteVendorields}
                                change={(e: any) => handleVendorInDefineRule(e)}
                            />
                        </div>
                        <div className="col-sm-12 RCListInRuleExceptionAdd mt-3">
                            {vendorLinkToRuleExcepDefineRule &&
                                vendorLinkToRuleExcepDefineRule.map(
                                    (item: any) => (
                                        <div className="row linkedStoreDiv ml-0 mr-0">
                                            <div className="col-sm-10 p-0">
                                                <span
                                                    title="remove"
                                                    className="linkedStoreName text-underline"
                                                    style={{
                                                        fontSize: '13px',
                                                        color: 'black',
                                                    }}
                                                >
                                                    {/* ({item.apVendorNumber}){' '} */}
                                                    {item?.apVendorName}
                                                    {/* <span className="linkedStoreNameRemove" onClick={(e) => removeLinkedVendorInDefineRule(item.id)}>X</span> */}
                                                </span>
                                            </div>
                                            <div className="col-sm-2 p-0">
                                                <img
                                                    src={closeIcon}
                                                    alt="Close"
                                                    className="contactcloseIcon float-right"
                                                    onClick={() =>
                                                        removeLinkedVendorInDefineRule(
                                                            item?.id
                                                        )
                                                    }
                                                />
                                            </div>
                                        </div>
                                    )
                                )}
                        </div>
                    </div>

                    <div className="col-sm-4">
                        <span className="lblClass" style={{ float: 'left' }}>
                            UPC(s)
                        </span>
                        <div className="col-sm-12 p-0 upcDropDownBox">
                            {/* <AsyncSelect
                                //cacheOptions
                                defaultOptions
                                getOptionLabel={(e: any) => e.upc}
                                getOptionValue={(e) => e.upc}
                                loadOptions={loadUPCOptionsByVendor}
                                onChange={handleUPCInDefineRule}
                                placeholder="Select"
                            /> */}
                            <DropDownListComponent
                                placeholder="Select"
                                id="UpcId"
                                allowFiltering={true}
                                ref={(scope) => {
                                    dropDownListObject = scope;
                                }}
                                dataSource={upcByVendor}
                                filtering={loadUPCOptionsByVendor.bind(this)}
                                fields={autoCompleteUPCFields}
                                change={(e: any) => handleUPCInDefineRule(e)}
                            />
                        </div>
                        <div
                            className="col-sm-12 RCListInRuleExceptionAdd mt-3"
                            style={{ padding: '0 1px' }}
                        >
                            {upcLinkToRuleExcepDefineRule &&
                                upcLinkToRuleExcepDefineRule.map(
                                    (item: any) => (
                                        <div className="row linkedStoreDiv ml-0 mr-0">
                                            <div className="col-sm-10 p-0">
                                                <span
                                                    title="remove"
                                                    className="linkedStoreName text-underline"
                                                    style={{
                                                        fontSize: '13px',
                                                        color: 'black',
                                                    }}
                                                >
                                                    {' '}
                                                    {item.upc
                                                        ? item.upc
                                                        : item.upcReference}
                                                    {/* <span className="linkedStoreNameRemove" onClick={(e) => removeLinkedUPCInDefineRule(item.id)}>X</span> */}
                                                </span>
                                            </div>
                                            <div className="col-sm-2 p-0">
                                                <img
                                                    src={closeIcon}
                                                    alt="Close"
                                                    className="contactcloseIcon float-right"
                                                    onClick={() =>
                                                        removeLinkedUPCInDefineRule(
                                                            item.id
                                                        )
                                                    }
                                                />
                                            </div>
                                        </div>
                                    )
                                )}
                        </div>
                    </div>
                </div>

                <br />
                <div className="row">
                    <div className="col-sm-4">
                        <span className="lblClass" style={{ float: 'left' }}>
                            Client Group(s)
                        </span>
                        <select
                            className="form-control"
                            id="ClientGroupId"
                            onChange={(e) => handleClientGroupInDefineRule(e)}
                        >
                            <option>Select</option>
                            {ClientGroupData?.map((cg: any) => (
                                <option value={JSON.stringify(cg)}>
                                    ({cg.clientGroupNumber}){' '}
                                    {cg.clientGroupName}
                                </option>
                            ))}
                        </select>
                        <div className="RCListInRuleExceptionAdd mt-3">
                            {clientGroupLinkToRuleExcepDefineRule &&
                                clientGroupLinkToRuleExcepDefineRule.map(
                                    (item: any) => (
                                        <div className="row linkedStoreDiv ml-0 mr-0">
                                            <div className="col-sm-10 p-0">
                                                <span
                                                    title="remove"
                                                    className="linkedStoreName text-underline"
                                                    style={{
                                                        fontSize: '13px',
                                                        color: 'black',
                                                    }}
                                                >
                                                    {' '}
                                                    ({
                                                        item.clientGroupNumber
                                                    }) {item.clientGroupName}
                                                    {/* <span className="linkedStoreNameRemove" onClick={(e) => removeLinkedClientGroupInDefineRule(item.id)}>X</span> */}
                                                </span>
                                            </div>
                                            <div className="col-sm-2 p-0">
                                                <img
                                                    src={closeIcon}
                                                    alt="Close"
                                                    className="contactcloseIcon float-right"
                                                    onClick={() =>
                                                        removeLinkedClientGroupInDefineRule(
                                                            item.id
                                                        )
                                                    }
                                                />
                                            </div>
                                        </div>
                                    )
                                )}
                        </div>
                    </div>

                    <div className="col-sm-4">
                        <span className="lblClass" style={{ float: 'left' }}>
                            Stores(s)
                        </span>
                        <div className="col-sm-12 p-0 upcDropDownBox">
                            {/* <AsyncSelect
                                //cacheOptions
                                defaultOptions
                                getOptionLabel={(e: any) => e.storeName}
                                getOptionValue={(e) => e.storeName}
                                loadOptions={loadStoreOptionsByCustomerGroup}
                                onChange={handleCustomerInDefineRule}
                                placeholder='Select'
                            /> */}
                            <DropDownListComponent
                                id="StoresID"
                                placeholder="Select"
                                allowFiltering={true}
                                ref={(scope) => {
                                    dropDownListStoresObject = scope;
                                }}
                                dataSource={storesByClientGroup}
                                filtering={loadStoreOptionsByCustomerGroup.bind(
                                    this
                                )}
                                fields={autoCompleteStoreFields}
                                change={(e: any) =>
                                    handleCustomerInDefineRule(e)
                                }
                            />
                        </div>
                        <div className="col-sm-12 RCListInRuleExceptionAdd mt-3">
                            {customerLinkToRuleExcepDefineRule &&
                                customerLinkToRuleExcepDefineRule.map(
                                    (item: any) => (
                                        <div className="row linkedStoreDiv ml-0 mr-0">
                                            <div className="col-sm-10 p-0">
                                                <span
                                                    title="remove"
                                                    className="linkedStoreName text-underline"
                                                    style={{
                                                        fontSize: '13px',
                                                        color: 'black',
                                                    }}
                                                >
                                                    {
                                                        item.storeNameAndMainframeCustomerNumber
                                                    }
                                                    {/* <span className="linkedStoreNameRemove" onClick={(e) => removeLinkedCustomerInDefineRule(item.id)}>X</span> */}
                                                </span>
                                            </div>
                                            <div className="col-sm-2 p-0">
                                                <img
                                                    src={closeIcon}
                                                    alt="Close"
                                                    className="contactcloseIcon float-right"
                                                    onClick={() =>
                                                        removeLinkedCustomerInDefineRule(
                                                            item.id
                                                        )
                                                    }
                                                />
                                            </div>
                                        </div>
                                    )
                                )}
                        </div>
                    </div>
                </div>

                <div className="row mt-2rem">
                    <ButtonComponent
                        style={
                            rcLinkToRuleExcepDefineRule?.length > 0 &&
                            vendorLinkToRuleExcepDefineRule?.length > 0
                                ? {
                                      opacity: '1',
                                      pointerEvents: 'all',
                                      cursor: 'pointer',
                                      width: '150px',
                                  }
                                : {
                                      opacity: '0.5',
                                      pointerEvents: 'none',
                                      backgroundColor: '#e8e8e8',
                                      color: '#969696',
                                      width: '150px',
                                  }
                        }
                        cssClass="eg-btn-primary float-right"
                        onClick={() => {
                            setShow('valueChange');
                        }}
                    >
                        NEXT
                    </ButtonComponent>
                    <ButtonComponent
                        cssClass="e-control e-btn e-lib eg-btn-primary-link e-flat mt-3"
                        onClick={handleCancelRuleExcep}
                    >
                        CANCEL
                    </ButtonComponent>
                </div>
            </>
        );
    };

    const defineValueChangesContent = () => {
        return (
            <>
                <div className="RCSummaryDiv">
                    <div className="row mt-1rem ml-0 mb-1rem">
                        <div
                            className="col-sm-3"
                            style={{ padding: '0', marginTop: '4px' }}
                        >
                            <span className="RCSummaryText">
                                Reclaim Critera Summary
                            </span>
                        </div>
                        <div className="col-sm-8"></div>
                        <div className="col-sm-1">
                            <span className="">
                                {' '}
                                <img
                                    src={editIcon}
                                    onClick={() => handleEditDataRules()}
                                    style={{
                                        width: '20px',
                                        height: '20px',
                                        marginLeft: '3px',
                                        marginTop: '5px',
                                    }}
                                />
                            </span>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="col-sm-3 billCostByUpcDiv">
                                <span className="RCSummaryInsideHeadings">
                                    Reclaim Center(s)
                                </span>
                                <div>
                                    {rcLinkToRuleExcepDefineRule &&
                                        rcLinkToRuleExcepDefineRule.map(
                                            (item: any) => (
                                                <div>
                                                    <span
                                                        className="linkedStoreName"
                                                        style={{
                                                            fontSize: '11px',
                                                        }}
                                                    >
                                                        {item.reclaimcenterName}
                                                    </span>{' '}
                                                </div>
                                            )
                                        )}
                                </div>
                            </div>
                            <div className="col-sm-3 billCostByUpcDiv pl-0">
                                <span className="RCSummaryInsideHeadings">
                                    Vendor(s)
                                </span>
                                <div>
                                    {vendorLinkToRuleExcepDefineRule &&
                                        vendorLinkToRuleExcepDefineRule.map(
                                            (item: any) => (
                                                <div>
                                                    <span
                                                        className="linkedStoreName"
                                                        style={{
                                                            fontSize: '11px',
                                                        }}
                                                    >
                                                        ({item.apVendorNumber}){' '}
                                                        {item?.apVendorName}
                                                    </span>{' '}
                                                </div>
                                            )
                                        )}
                                </div>
                            </div>
                            <div className="col-sm-2 billCostByUpcDiv">
                                <span className="RCSummaryInsideHeadings">
                                    UPC(s)
                                </span>
                                <div>
                                    {upcLinkToRuleExcepDefineRule &&
                                        upcLinkToRuleExcepDefineRule.map(
                                            (item: any) => (
                                                <div>
                                                    <span
                                                        className="linkedStoreName"
                                                        style={{
                                                            fontSize: '11px',
                                                        }}
                                                    >
                                                        {item.upc}
                                                    </span>{' '}
                                                </div>
                                            )
                                        )}
                                </div>
                            </div>

                            <div className="col-sm-2 billCostByUpcDiv p-0">
                                <span className="RCSummaryInsideHeadings">
                                    Client Group(s)
                                </span>
                                <div>
                                    {clientGroupLinkToRuleExcepDefineRule &&
                                        clientGroupLinkToRuleExcepDefineRule.map(
                                            (item: any) => (
                                                <div>
                                                    <span
                                                        className="linkedStoreName"
                                                        style={{
                                                            fontSize: '11px',
                                                        }}
                                                    >
                                                        (
                                                        {item.clientGroupNumber}
                                                        ) {item.clientGroupName}
                                                    </span>{' '}
                                                </div>
                                            )
                                        )}
                                </div>
                            </div>
                            <div className="col-sm-2 billCostByUpcDiv p-0">
                                <span className="RCSummaryInsideHeadings">
                                    Stores(s)
                                </span>
                                <div>
                                    {customerLinkToRuleExcepDefineRule &&
                                        customerLinkToRuleExcepDefineRule.map(
                                            (item: any) => (
                                                <div>
                                                    <span
                                                        className="linkedStoreName"
                                                        style={{
                                                            fontSize: '11px',
                                                        }}
                                                    >
                                                        {item.storeName}
                                                    </span>{' '}
                                                </div>
                                            )
                                        )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <hr />
                <div className="row">
                    <div className="col-sm-12">
                        <div className="col-sm-4">
                            <span
                                className="lblClass"
                                style={{ float: 'left' }}
                            >
                                UPC Disposition
                                <span className="requiredStar">*</span>
                            </span>
                            <select
                                className="form-control"
                                name="ddlDisposition"
                                value={formValues['ddlDisposition']}
                                onChange={(e: any) =>
                                    handleChangeDisposition(e)
                                }
                            >
                                <option value="">Select</option>
                                {upcDefaultData?.map((d) => (
                                    <option value={d.disposition}>
                                        {d.disposition}
                                    </option>
                                ))}
                            </select>
                        </div>

                        <div className="col-sm-4">
                            <span
                                className="lblClass"
                                style={{ float: 'left' }}
                            >
                                Whse Location
                                <span className="requiredStar">*</span>
                            </span>
                            <select
                                className="form-control"
                                name="ddlWhseLocation"
                                value={formValues['ddlWhseLocation']}
                                onChange={(e: any) =>
                                    handleChangeWhseLocation(e)
                                }
                            >
                                <option value="">Select</option>
                                {whseLocationData?.map((d) => (
                                    <option value={d.warehouseLocation}>
                                        {d.warehouseLocation}
                                    </option>
                                ))}
                            </select>
                            {/* <input type="text" className="form-control" id="txtWhseLocation" /> */}
                        </div>

                        <div className="col-sm-4">
                            <span
                                className="lblClass"
                                style={{ float: 'left' }}
                            >
                                Debit Reason
                            </span>
                            <select
                                className="form-control"
                                name="ddlDebitReason"
                                value={formValues['ddlDebitReason']}
                                onChange={(e: any) => handleChangDebitReason(e)}
                            >
                                <option>Select</option>
                                {DebitReasonData?.map((d) => (
                                    <option value={d.debitReasonCode}>
                                        {d.debitReasonCode}
                                    </option>
                                ))}
                            </select>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-12">
                        <div className="col-sm-4">
                            <span
                                className="lblClass"
                                style={{ float: 'left' }}
                            >
                                Bill Cost
                            </span>
                            <br />
                            <span className="lblClassAppliesTUPC">
                                *Applies to all UPCs
                            </span>

                            <input
                                type="text"
                                autoFocus
                                className="form-control"
                                name="txtBillCostAllUPC"
                                value={formValues['txtBillCostAllUPC']}
                                onChange={(e: any) => handleChangeBillCost(e)}
                            />
                        </div>

                        <div className="col-sm-4">
                            <span
                                className="lblClass"
                                style={{ float: 'left' }}
                            >
                                Bill Cost by UPC($)
                            </span>

                            {upcLinkToRuleExcepDefineRule &&
                                upcLinkToRuleExcepDefineRule.map(
                                    (item: any, i: number) => (
                                        <>
                                            <div className="row pt-1">
                                                <div
                                                    className="col-sm-12"
                                                    style={{ padding: '0' }}
                                                >
                                                    <div className="col-sm-7 pr-0">
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            value={item.upc}
                                                            readOnly
                                                        />
                                                    </div>

                                                    <div className="col-sm-5 pl-1">
                                                        <input
                                                            type="number"
                                                            className="form-control"
                                                            id={
                                                                item.upcReference
                                                            }
                                                            onChange={(
                                                                e: any
                                                            ) =>
                                                                handleChangeBillCostbyUPC(
                                                                    e,
                                                                    item.upcReference
                                                                        ? item.upcReference
                                                                        : item.upc,
                                                                    i
                                                                )
                                                            }
                                                            value={
                                                                item.billCost
                                                            }
                                                            onBlur={(e: any) =>
                                                                handleBillCostByUpcBlur(
                                                                    e,
                                                                    item.upcReference
                                                                        ? item.upcReference
                                                                        : item.upc,
                                                                    i
                                                                )
                                                            }
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                    )
                                )}
                        </div>
                    </div>
                </div>

                <div className="row mt-5">
                    <ButtonComponent
                        disabled={
                            !(
                                formValues['ddlDisposition'] &&
                                formValues['ddlWhseLocation']
                            )
                        }
                        cssClass="eg-btn-primary float-right"
                        onClick={() => handleAddRule()}
                        style={{ width: '163px' }}
                    >
                        ADD RULE
                    </ButtonComponent>
                    <ButtonComponent
                        cssClass="eg-btn-secondary float-right mr-4"
                        onClick={handleEditDataRules}
                    >
                        BACK
                    </ButtonComponent>
                    <ButtonComponent
                        cssClass="e-control e-btn e-lib eg-btn-primary-link e-flat mt-3"
                        onClick={handleCancelRuleExcep}
                    >
                        CANCEL
                    </ButtonComponent>
                </div>
            </>
        );
    };

    const handleCancelRuleExcep = () => {
        setAddScreen(false);
    };

    return (
        <div className="container" style={{ width: '100%' }}>
            {show === 'Rule'
                ? defineRuleCriteriaContent()
                : defineValueChangesContent()}
            <TabComponent
                id="tab-wizard"
                ref={(tab) => {
                    tabObj = tab;
                }}
                style={{ width: '94%' }}
            >
                {/* <TabItemsDirective >
        <TabItemDirective header={headerText[0]} content={defineRuleCriteriaContent} />
        <TabItemDirective header={headerText[1]} content={defineValueChangesContent} />
      </TabItemsDirective> */}
            </TabComponent>
        </div>
    );
};
export default RuleExceptionAddScreen;
