import HttpService from '../http-service/http-service';
import { API_PATH, getApiBaseUrl } from '../../api-paths';
import { SearchCriteriaRequest } from '../../components/grid/gridConfig';

export interface Client {
    createdDate?: any;
    lastModifiedDate?: any;
    organizationId?: any;
    tenantId?: any;
    id: number;
    clientId: string;
    clientName: string;
}

export interface Vendors {
    createdDate: Date;
    lastModifiedDate: Date;
    organizationId: string;
    tenantId: string;
    id: number;
    upcReference: string;
    description: string;
    pack: number;
    unitFactor: number;
    disposition: string;
    vendorNumber: string;
    itemCode: string;
    itemIdType: string;
    VendorCost: number;
    programType: string;
    programName: string;
    clientCustomerNumber: number;
    retail?: any;
    cost: number;
    crossQuantityRequiredFlag: string;
    size: string;
    batchStartTime: Date;
    client: Client;
    debitReasonCode?: any;
    hazardousFlag: string;
    subDisposition?: any;
    maStateDisposition?: any;
    flStateDisposition?: any;
    txStateDisposition?: any;
    caStateDisposition?: any;
    ruleExceptionList?: any;
    warehouseLocation?: any;
    VendorDisposition?: any;
    vendorName?: any;
    vendorId?: any;
    active: boolean;
    ruleActive: boolean;
    deleted: boolean;
}

export interface VendorsGridView {
    active: boolean;
    addressLine1: string;
    addressLine2: string;
    apVendorNumber: string;
    city: string;
    clientId: string;
    country: string;
    deleted: boolean;
    isHold: string;
    id: number;
    state: string;
    street: string;
    vendorName: string;
    vendorNumber: string;
    vendorType: string;
    addressInfo: string;
    isActive: string;
}

export interface VendorResponse {
    result: VendorsGridView[];
    count: number;
}

export default class VendorsService {
    public async getFilteredItems(
        searchCriteria: SearchCriteriaRequest
    ): Promise<VendorResponse> {
        const url = getApiBaseUrl() + API_PATH.VENDORS_PATH;
        return HttpService.post<VendorResponse>(url, searchCriteria).then(
            (response) => {
                return response.data;
            }
        );
    }

    public async getItem(id: number): Promise<Vendors> {
        const url = getApiBaseUrl() + API_PATH.VENDORS_ITEM + id;
        return HttpService.get<Vendors>(url).then((response) => {
            return response.data;
        });
    }

    public async updateItem(id: number, rowInfoObject: any): Promise<Vendors> {
        const url = getApiBaseUrl() + API_PATH.VENDORS_PATH + id;
        return HttpService.put<Vendors>(url, rowInfoObject).then((response) => {
            return response.data;
        });
    }
}
