import { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import ContentHeader from '../../common/ContentHeader/ContentHeader';
import Divider from '../../common/divider/Divider';
import minusicon from '../../../icons/Minus.svg';
import plusicon from '../../../icons/plus.svg';
import upicon from '../../../icons/uparrow.svg';
import downicon from '../../../icons/down_arrow.svg';
import { ButtonComponent } from '@syncfusion/ej2-react-buttons';
import notify from '../../../services/toasterService';

const UnitFactor = forwardRef((props: any, ref) => {
    const { setAddScreen, updateUnitFactor, resetFocusToUpc } = props;
    const [itemQuantity, setItemQuantity] = useState(1);
    const [itemMaxQty, setItemMaxQty] = useState('');
    useEffect(() => {
        const maxQty: any = localStorage.getItem('itemMaxQty');
        setItemMaxQty(maxQty);
    });
    useImperativeHandle(ref, () => ({
        clearForm() {
            console.log('clearing fields...');
            reset();
        },
    }));
    const reset = () => {
        setItemQuantity(1);
    };

    const handleIncrement = (event: number) => {
        if (itemQuantity >= parseInt(itemMaxQty)) {
            setItemQuantity(parseInt(itemMaxQty));
            notify('Max scan quantity allowed is ' + itemMaxQty, 'warning');
        } else if (event && itemQuantity < parseInt(itemMaxQty)) {
            setItemQuantity((prevState) => {
                if (prevState + event < parseInt(itemMaxQty)) {
                    return prevState + event;
                } else {
                    notify(
                        'Max scan quantity allowed is ' + itemMaxQty,
                        'warning'
                    );
                    return parseInt(itemMaxQty);
                }
            });
        }
    };
    const handleDecrement = (event: any) => {
        let val: any;
        val = itemQuantity.toString().replace(/[^-?[0-9]+(\.[0-9]+)?]/g, '');
        if (val && itemQuantity > 1) {
            setItemQuantity((prevState) => prevState - event);
        } else {
            setItemQuantity(1);
        }
    };
    const handleItemQtyOnBlur = () => {
        if (itemQuantity === undefined || !itemQuantity) {
            setItemQuantity(1);
        }
    };
    const minMax = (value: any) => {
        setItemQuantity(value);
        if (itemQuantity === undefined || isNaN(parseInt(value))) {
            setItemQuantity(parseInt(''));
            return parseInt('');
        }

        if (itemQuantity < 1 || isNaN(parseInt(value))) {
            setItemQuantity(1);
            return 1;
        } else if (parseInt(value) <= parseInt(itemMaxQty)) {
            return parseInt(itemMaxQty);
        } else {
            notify('Max scan quantity allowed is ' + itemMaxQty, 'warning');
            setItemQuantity(parseInt(itemMaxQty));
            return parseInt(itemMaxQty);
        }
    };
    const handleDecrementByTen = (event: any) => {
        let val: any;
        val = itemQuantity.toString().replace(/[^-?[0-9]+(\.[0-9]+)?]/g, '');
        if (val && itemQuantity > 10) {
            setItemQuantity((prevState) => prevState - event);
        } else {
            setItemQuantity(1);
        }
    };
    const handleCancel = () => {
        setAddScreen(false);
        resetFocusToUpc();
        reset();
    };
    const handleContinue = () => {
        setAddScreen(false);
        resetFocusToUpc();
        updateUnitFactor(itemQuantity);
        reset();
    };

    //prevent text
    /*check input format function */
    const formatInput = (e: any) => {
        // Prevent characters that are not numbers ("e", ".", "+" & "-")
        let checkIfNum;
        if (e.key !== undefined) {
            // Check if it's a "e", ".", "+" or "-"
            checkIfNum =
                e.key === 'e' ||
                e.key === '.' ||
                e.key === '+' ||
                e.key === '-';
        } else if (e.keyCode !== undefined) {
            // Check if it's a "e" (69), "." (190), "+" (187) or "-" (189)
            checkIfNum =
                e.keyCode === 69 ||
                e.keyCode === 190 ||
                e.keyCode === 187 ||
                e.keyCode === 189;
        }
        return checkIfNum && e.preventDefault();
    };
    //prevent text
    return (
        <>
            <ContentHeader value={props.heading} />
            <Divider />
            <div className="col-sm-12 p5">
                <h5 className="text-center fb-15 Unlabeledtxt">
                    Enter number of units
                </h5>
                <div className="col-sm-9 col-sm-offset-2 btnUPCbody">
                    <div className="col-sm-2 ">
                        <button
                            className="btn btn-primary btnUPC"
                            onClick={(e) => handleDecrementByTen(10)}
                        >
                            <img src={downicon} className="imgToparrow" />
                            10
                        </button>
                    </div>
                    <div className="col-sm-2 ">
                        <button
                            className="btn btn-primary btnUPCMinus"
                            onClick={(e) => handleDecrement(1)}
                        >
                            <img src={minusicon} className="imgMinus" />
                        </button>
                    </div>
                    <div className="col-sm-3 ">
                        <input
                            type="number"
                            onKeyDown={formatInput}
                            onChange={(e) => minMax(parseInt(e.target.value))}
                            onBlur={() => handleItemQtyOnBlur()}
                            id="quantity"
                            className="form-control txtUPCNum"
                            value={itemQuantity}
                        ></input>
                    </div>
                    <div className="col-sm-2 ">
                        <button
                            className="btn btn-primary btnUPCPlus"
                            onClick={(e) => handleIncrement(1)}
                        >
                            <img src={plusicon} className="imgPlus" />
                        </button>
                    </div>
                    <div className="col-sm-2 ">
                        <button
                            className="btn btn-primary btnUPC"
                            onClick={(e) => handleIncrement(10)}
                        >
                            <img src={upicon} className="imgToparrow" />
                            10
                        </button>
                    </div>
                </div>
                <div className="col-sm-9 mt-3rem">
                    <ButtonComponent
                        cssClass="eg-btn-primary float-right btnBox"
                        onClick={(e) => handleContinue()}
                    >
                        Continue
                    </ButtonComponent>
                    <ButtonComponent
                        cssClass="eg-btn-secondary float-right mr-4"
                        onClick={() => handleCancel()}
                    >
                        CANCEL
                    </ButtonComponent>
                </div>
            </div>
        </>
    );
});

export default UnitFactor;
