import { FC } from "react";
import "./invoiceTable.scss";
import { Internationalization } from "@syncfusion/ej2-base";
import NumberFormat from "react-number-format";

interface IInvoiceTable {
  pallets: string;
  bins: string;
  boxes: string;
  items: string;
  cost: string;
  price: string;
}

const InvoiceTable: FC<IInvoiceTable> = ({
  pallets,
  bins,
  boxes,
  items,
  cost,
  price,
}) => {
  let intl = new Internationalization();
  let nFormatter = intl.getNumberFormat({
    skeleton: "C3",
    currency: "USD",
    minimumIntegerDigits: 0,
    minimumFractionDigits: 2,
  });

  return (
    <div className="invoice-table">
      <div className="invoice-table-header">
        <span>PALLETS</span>
        <span>BINS</span>
        <span>BOXES</span>
        <span>ITEMS</span>
        <span>GROSS COST</span>
        <span>NET PRICE</span>
      </div>
      <div className="invoice-table-content">
        <span>{pallets}</span>
        <span>{bins}</span>
        <span>{boxes}</span>
        <span>
          <NumberFormat
            displayType={"text"}
            thousandSeparator={true}
            value={items}
          />
        </span>
        <span>
          <NumberFormat
            displayType={"text"}
            thousandSeparator={true}
            value={`${parseFloat(cost).toFixed(2)}`}
          />
        </span>
        <span>
          <NumberFormat
            displayType={"text"}
            thousandSeparator={true}
            value={`${parseFloat(price).toFixed(2)}`}
          />
        </span>
        {/* <span>{`$${nFormatter(+cost)}`}</span> */}
        {/* <span>{`$${nFormatter(+price)}`}</span> */}
        {/* <span>${parseFloat(cost).toFixed(2)}</span> */}
        {/* <span>${parseFloat(price).toFixed(2)}</span> */}
      </div>
    </div>
  );
};

export default InvoiceTable;
