import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import Grid from "../../grid/Grid";
import {
  generateGridData,
  getAccessMgmtEndPoint,
  userMgmtHeaders,
} from "../utils";
import AccessPreviewTab from "../previewAccessMgmt/previewaccessmgmt";
import { useEffect, useState } from "react";
import { ButtonComponent } from "@syncfusion/ej2-react-buttons";
import { ReactComponent as Add } from "../../../icons/AddNew.svg";
import { ReactComponent as Print } from "../../../icons/printNew.svg";
import ExcelService from "../../../services/excel";
import AddAccessManagementScreen from "../addScreen/addScreen";
import axios from "axios";
import {
  setActiveDirectoryData,
  setCountryCodesList,
  setLocationData,
  setLungaguesData,
  setTimeZonesData,
} from "../accessmgmtReducer";
import notify from "../../../services/toasterService";
import loader from "../../../assets/loader.gif";
import { setSelectedRecrdsExport } from "../../contactTabs/contactReducer";
import { ReactComponent as Download } from "../../../icons/download.svg";
import AddFileUploadScreen from "../addScreen/fileUpload";

const UserManagement = () => {
  const dispatch = useDispatch();

  const [addScreen, setAddScreen] = useState(false);
  const [uploadScreen, setUploadScreen] = useState(false);
  const [addScreenTabName, setAddScreenTabName] = useState(null);

  const [screenType, setScreenType] = useState("excelUpload");

  const [userLoginData, SetUserLoginData] = useState<any>();

  let TabData: any = [];
  let selectedTab = "Users";

  const getSelectedRecord = (e: any) => {
    //console.log(e)
};
const getDeSelectedRecord = (e: any) => {
    //  seletedDataToExport(e);
};

  const internalUserResponse = [
    {
      id: "c5a23f17-2a1d-4e44-bd73-eae35391747e",

      createdTimestamp: 1645691646759,

      username: "ssiragam",

      enabled: true,

      totp: false,

      emailVerified: false,

      firstName: "Saranya",

      lastName: "Siragam",

      email: "ssiragam@cswg.com",

      federationLink: "8bb21ea5-f6b4-4fb8-a5dc-6bde80940335",

      attributes: {
        LDAP_ENTRY_DN: [
          "CN=Saranya Siragam,OU=Users,OU=OPT,OU=UsersAndWorkstations,DC=cswg,DC=com",
        ],

        LDAP_ID: ["85542fe1-bf54-4906-81d8-48d5d4e67aaa"],

        createTimestamp: ["20200207154247.0Z"],

        modifyTimestamp: ["20220303043442.0Z"],
      },

      disableableCredentialTypes: [],

      requiredActions: [],

      notBefore: 0,

      access: {
        manageGroupMembership: true,

        view: true,

        mapRoles: true,

        impersonate: true,

        manage: true,
      },
    },
  ];

  const selectedRowData = useSelector(
    (state: RootState) => state.gridReducer.selectedRowData
  );
  useEffect(() => {
    //  selectedTab = "Users";
    // userSignIn('signin')
    getUserSelectionFields("Default Language");
    getUserSelectionFields("Default TimeZone");
    getUserSelectionFields("Reclaim Centers List");
    getUserSelectionFields("Default Country");
    // getRoles('Roles')
  }, [selectedRowData]);

  /* Get data from Grid Reducer */

  /* Get user data from LL admin Reducer */
  const userManagementData = useSelector(
    (state: RootState) => state.accessMgmtReducer.userAccessData
  );

  const [addFileScreen, setFileUploadAddScreen] = useState(false);

  const seletedDataToExport = useSelector(
    (state: RootState) => state.contactReducer.selectedRecordsForExport
  );

  // const [TabData, setTabData] = useState<any>([])
  const excelExport = () => {
    console.log(seletedDataToExport);
    if (
      seletedDataToExport !== null &&
      seletedDataToExport !== undefined &&
      seletedDataToExport.length > 0
    ) {
      ExcelService.exportAsExcel('acm',selectedTab, seletedDataToExport);
      dispatch(setSelectedRecrdsExport([]));
    } else {
      ExcelService.exportAsExcel('acm',selectedTab, userManagementData);
    }
  };

  /* Defined Grid Hieght based on Data  */
  let gridHieght = "calc(100vh - 375px)";

  // const userSignIn = async (TabName: any) => {
  //     const endPointURL = getAccessMgmtEndPoint(TabName);
  //     const body = {
  //       "email": "pravallika@limnlabs.com",
  //       "password": "pravallika"
  //     }
  //     await axios.post(endPointURL, body).then((res) => {
  //       SetUserLoginData(res.data);
  //       console.log(res.data)
  //       const token = userLoginData.access_token;
  //       sessionStorage.setItem('token', token);
  //       getRoles('Roles');
  //     }).catch((err) => {
  //       notify('Failed to fetch data', 'error');
  //     });
  // }
  const handlePopupActionLocation = (selectedTabName: any) => {
    dispatch(setActiveDirectoryData(internalUserResponse));
    setAddScreen(true);
    setAddScreenTabName(selectedTabName);
  };

  /* Get Dropdown api calls */

  const getUserSelectionFields = async (fieldName: any) => {
    const getEndPointUrl = getAccessMgmtEndPoint(fieldName);
    await axios.get(getEndPointUrl).then(
      (res) => {
        if (fieldName === "Default Language") {
          dispatch(setLungaguesData(res.data));
        } else if (fieldName === "Default TimeZone") {
          dispatch(setTimeZonesData(res.data));
        } else if (fieldName === "Reclaim Centers List") {
          dispatch(setLocationData(res.data));
        } else if (fieldName === "Default Country") {
          dispatch(setCountryCodesList(res.data));
        }
      },
      (err) => {
        notify("Failed to fetch data", "error");
      }
    );
  };

  const loaderStatus = useSelector(
    (state: RootState) => state.accessMgmtReducer.isLoaderShow
  );

  const excelImport = (selectedTabName: any) => {
    console.log('')
    setFileUploadAddScreen(true);
    setAddScreenTabName(selectedTabName);
  }
  return (
    <div>
      {addScreen && (
        <AddAccessManagementScreen
          addScreen={addScreen}
          setAddScreen={setAddScreen}
          tabName={addScreenTabName}
        />
      )}
      {addFileScreen && (
        <AddFileUploadScreen
          addScreen={addFileScreen}
          setFileUploadAddScreen={setFileUploadAddScreen}
          tabName={addScreenTabName}
          screenType={screenType}
        />
      )}
      <div className="col-sm-12 col-md-12 col-lg-12 pt-1 pl-2">
        <div className="col-sm-6 pl-0 pt-4">
          <p className="access-mgmt-title mb-2">Users</p>
        </div>
        <div className="col-sm-6 pr-0">
          <ButtonComponent
            type="button"
            className="iconCopy float-right ml-1"
            alt="Add "
            onClick={() => handlePopupActionLocation(selectedTab)}
          >
            <Add />
          </ButtonComponent>

          <ButtonComponent
            className="iconCopy float-right ml-1"
            alt="Export"
            onClick={() => excelExport()}
          >
            <Print />
          </ButtonComponent>
          <ButtonComponent
            className="iconCopy float-right ml-1"
            onClick={() => excelImport('ExcelUpload')}
          >
            <Download />
          </ButtonComponent>
        </div>
      </div>
      <div className="col-sm-12 col-md-12 col-lg-12 pl-2">
        <div className="control-pane">
          <div className="control-section tab-control-section">
            <div className="col-sm-12 col-md-12 col-lg-12 ">
              <div className="col-sm-8 col-md-12 col-lg-8 pl-0 pr-4 " style={{width: '75%'}}>
              <>
            {loaderStatus ? (
              <div className="LoadImg">
                <img className="loaderClass" src={loader} />
                </div> ) :
                <div className="access-mgmt-text-transform">
                <Grid
                  config={userMgmtHeaders}
                  data={generateGridData("Users", userManagementData)}
                  width={"100%"}
                  height={gridHieght}
                  onRowSelect={getSelectedRecord}
                  onRowDeselect={getDeSelectedRecord}
                  // isFromInvoice={true}
                />
                </div>
            }</>
              </div>
              {selectedRowData && selectedRowData.length > 0 ? (
                <div className="col-sm-4 col-md-12 col-lg-4 pl-4 " style={{width: '25%'}}>
                  <AccessPreviewTab />
                </div>
              ) : (
                <div className="col-sm-4 col-md-12 col-lg-4 pl-4 " style={{width: '25%'}}>
                  <div className="access-preview d-flex align-items-center" >
                    <div className="col-sm-12 col-md-12 col-lg-12 d-flex justify-content-center">
                      <p className="preview-msg">Select user to view details</p>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserManagement;
