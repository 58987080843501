import { ButtonComponent } from "@syncfusion/ej2-react-buttons";
import axios from "axios";
import { useState } from "react";
// import { addMasterDataEndPoint, getEndPoint } from './utils';
import { useDispatch, useSelector } from "react-redux";
import notify from "../../../services/toasterService";
import { RootState } from "../../../redux/store";
import { setTimezoneData } from "../lladminreducer";
import userAvatar from "../../../icons/common/user-avatar.png";
import { addLlAdminEndPoint, getLlAdminEndPoint } from "../utils";

const AddTimeZone = (props: any) => {
  const { setAddScreen } = props;

  const dispatch = useDispatch();
  const [timeZoneName, setTimeZoneName] = useState("");
  const [timeZone, setTimeZone] = useState("");
  const [isoCode, setIsoCode] = useState("");
  const [country, setCountry] = useState("");
  const [timeZoneUTC, setTimeZoneUTC] = useState("");
  const [desc, setDesc] = useState("");
  const [timeZoneDisabled, setTmeZoneDisabled] = useState(true);
  const [myFormControl, setMyFormControl] = useState("");
  const [myFormTimeZone, setMyFormTimeZone] = useState("");
  const [myFormIsoCode, setMyFormIsoCode] = useState("");
  const [myFormUtc, setMyFormUtc] = useState("");
  const [myDescTextArea, setMyDescTextArea] = useState("");
  const [myFormCountry, setMyFormCountry] = useState("");

  const [errors, setErrors] = useState({
    nameError: "",
    timeZoneError: "",
    isoCodeError: "",
    countryError: "",
    timeZoneUTCError: "",
    descError: "",
  });

  const countryData = useSelector(
    (state: RootState) => state.lladminReducer.countryData
  );
  // console.log(countryData, 'countryData on Add Time Zone');

  const handleTimeZoneName = (e: any) => {
    setTimeZoneName(e.target.value);
    validate();
  };

  const handleTimeZone = (e: any) => {
    setTimeZone(e.target.value);
    validate();
  };

  const handleIsoCode = (e: any) => {
    setIsoCode(e.target.value);
    validate();
  };

  const handleCountry = (e: any) => {
    setCountry(e.target.value);
    validate();
  };

  const handleUTC = (e: any) => {
    setTimeZoneUTC(e.target.value);
    validate();
  };

  const handleDesc = (e: any) => {
    setDesc(e.target.value);
    validate();
  };

  /* Create Object to insert the form */
  const body = {
    country: country,
    description: desc,
    isoCode: isoCode,
    timezone: timeZone,
    timezoneName: timeZoneName,
    utc: timeZoneUTC,
  };

  const handleAddTimeZone = () => {
    if (validate()) {
      const addEndPoint = addLlAdminEndPoint("Time Zones");
      const getEndPoint = getLlAdminEndPoint("Time Zones");
      addTimeZoneWS(addEndPoint, getEndPoint);
    }
  };

  /**
   * Add Time zone web service call
   */
  const addTimeZoneWS = async (addEndPoint: string, getEndPoint: string) => {
    console.log(body);

    const result = await axios.post(addEndPoint, body);
    if (result && result.data) {
      setAddScreen(false);
      notify("Timezone added successfully", "success");
      await axios.get(getEndPoint).then(
        (res) => {
          dispatch(setTimezoneData(res.data));
        },
        (err) => {
          notify("Failed to fetch data", "error");
        }
      );
    } else {
      notify("Failed to create timezone", "error");
    }
  };

  const handlePopUpClose = () => {
    setAddScreen(false);
  };

  const validate = (field?: any, value?: any) => {
    let nameError = "";
    let timeZoneError = "";
    let isoCodeError = "";
    let countryError = "";
    let timeZoneUTCError = "";
    let descError = "";

    setErrors({
      nameError: "",
      timeZoneError: "",
      isoCodeError: "",
      countryError: "",
      timeZoneUTCError: "",
      descError: "",
    });

    if (timeZoneName === "") {
      nameError = "Required Field";
      setMyFormControl("1px solid red");
    } else {
      setMyFormControl("");
    }

    if (timeZone === "") {
      timeZoneError = "Required Field";
      setMyFormTimeZone("1px solid red");
    } else {
      setMyFormTimeZone("");
    }

    if (isoCode === "") {
      isoCodeError = "Required Field";
      setMyFormIsoCode("1px solid red");
    } else {
      setMyFormIsoCode("");
    }

    if (timeZoneUTC === "") {
      timeZoneUTCError = "Required Field";
      setMyFormUtc("1px solid red");
    } else {
      setMyFormUtc("");
    }

    if (country === "") {
      countryError = "Required Field";
      setMyFormCountry("1px solid red");
    } else {
      setMyFormCountry("");
    }

    if (desc === "") {
      descError = "Required Field";
      setMyDescTextArea("1px solid red");
    } else {
      setMyDescTextArea("");
    }

    if (
      nameError ||
      timeZoneError ||
      isoCodeError ||
      countryError ||
      timeZoneUTCError ||
      descError
    ) {
      setErrors({
        nameError,
        timeZoneError,
        isoCodeError,
        countryError,
        timeZoneUTCError,
        descError,
      });
      setTmeZoneDisabled(true);
      return false;
    } else {
      setTmeZoneDisabled(false);
    }

    return true;
  };

  return (
    <div>
      <div className="col-sm-12 p-0 d-flex justify-content-center">
        <img src={userAvatar} />
      </div>

      <div className="col-sm-12 row m-0 mt-5">
        <div className="col-sm-6">
          <div className="col-sm-12 mt-3">
            <label className="label-title">Name</label>
            {timeZoneName === "" ? (
              <span className="text-danger" style={{ float: "right" }}>
                {errors.nameError}
              </span>
            ) : (
              ""
            )}
            <input
              type="text"
              className="formcontrol"
              onChange={(e) => handleTimeZoneName(e)}
              onBlur={(e) => handleTimeZoneName(e)}
              onKeyUp={(e) => handleTimeZoneName(e)}
              style={{ border: myFormControl }}
            />
          </div>

          <div className="col-sm-12 mt-3">
            <label className="label-title">Timezone</label>
            {timeZone === "" ? (
              <span className="text-danger" style={{ float: "right" }}>
                {errors.timeZoneError}
              </span>
            ) : (
              ""
            )}
            <input
              type="text"
              className="formcontrol"
              onChange={(e) => handleTimeZone(e)}
              onBlur={(e) => handleTimeZone(e)}
              onKeyUp={(e) => handleTimeZone(e)}
              style={{ border: myFormTimeZone }}
            />
          </div>

          <div className="col-sm-12 mt-3">
            <label className="label-title">ISO code</label>
            {isoCode === "" ? (
              <span className="text-danger" style={{ float: "right" }}>
                {errors.isoCodeError}
              </span>
            ) : (
              ""
            )}
            <input
              type="text"
              className="formcontrol"
              onChange={(e) => handleIsoCode(e)}
              onBlur={(e) => handleIsoCode(e)}
              onKeyUp={(e) => handleIsoCode(e)}
              style={{ border: myFormIsoCode }}
            />
          </div>

          <div className="col-sm-12 mt-3">
            <label className="label-title">UTC Offset</label>
            {timeZoneUTC === "" ? (
              <span className="text-danger" style={{ float: "right" }}>
                {errors.timeZoneUTCError}
              </span>
            ) : (
              ""
            )}
            <input
              type="text"
              className="formcontrol"
              onChange={(e) => handleUTC(e)}
              onBlur={(e) => handleUTC(e)}
              onKeyUp={(e) => handleUTC(e)}
              style={{ border: myFormUtc }}
            />
          </div>
        </div>

        <div className="col-sm-6">
          <div className="col-sm-12 mt-3">
            <label className="label-title">Country</label>
            {country === "" ? (
              <span className="text-danger" style={{ float: "right" }}>
                {errors.countryError}
              </span>
            ) : (
              ""
            )}
            <select
              className="formcontrol"
              onChange={(e: any) => handleCountry(e)}
              onBlur={(e) => handleCountry(e)}
              onKeyUp={(e) => handleCountry(e)}
              style={{ border: myFormCountry }}
            >
              <option value="">Select</option>
              {countryData?.map((item) => (
                <option value={item?.countryName}>{item?.countryName}</option>
              ))}
            </select>
          </div>
          <div className="col-sm-12 mt-3">
            <label className="label-title">Description</label>
            {desc === "" ? (
              <span className="text-danger" style={{ float: "right" }}>
                {errors.descError}
              </span>
            ) : (
              ""
            )}
            <textarea
              rows={5}
              className="formDesc"
              onChange={(e) => handleDesc(e)}
              onKeyUp={(e) => handleDesc(e)}
              onBlur={(e) => handleDesc(e)}
              style={{ border: myDescTextArea }}
            />
          </div>
        </div>
      </div>
      <div className="col-sm-12 px-0 my-5" style={{ width: "94%" }}>
        <ButtonComponent
          cssClass="eg-btn-primary float-right"
          disabled={timeZoneDisabled}
          onClick={() => handleAddTimeZone()}
        >
          ADD TimeZone
        </ButtonComponent>
        <ButtonComponent
          cssClass="eg-btn-primary-link e-flat mt-3"
          onClick={() => handlePopUpClose()}
        >
          Cancel
        </ButtonComponent>
      </div>
    </div>
  );
};
export default AddTimeZone;
