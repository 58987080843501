import { useSelector } from "react-redux";
import { RootState } from "../../../../redux/store";
import Grid from "../../../grid/Grid";
import { useEffect } from "react";
import { generateGridData, reclaimScanHeaders } from "../../utils";
import SettingsTabPreview from "../previewTab/previewTab";

const ReclaimScan = () => {
  /* Get selected data from Grid Reducer */
  const selectedRowData = useSelector(
    (state: RootState) => state.gridReducer.selectedRowData
  );

  /* Get Reclaim center data from LL admin Reducer */
  const reclaimScanData = useSelector(
    (state: RootState) => state.settingsReducer.reclaimScanMsgData
  );

  console.log(reclaimScanData);

  /* Defined Grid Hieght based on Data  */
  let gridHieght = "calc(100vh - 450px)";

  /* Excute code on page load */
  useEffect(() => {
    // Write some code
  });

  return (
    <div className="col-sm-12 col-md-12 col-lg-12 ">
      <div className="control-pane">
        <div className="control-section tab-control-section">
          <div className="col-sm-12 col-md-12 col-lg-12 ">
            <div className="col-sm-8 col-md-12 col-lg-8 pl-0 pr-4 child-data-section ">
              <Grid
                config={reclaimScanHeaders}
                data={generateGridData("Reclaim Scan", reclaimScanData)}
                width={"100%"}
                height={gridHieght}
              />
            </div>
            {selectedRowData && selectedRowData.length > 0 ? (
              <div className="col-sm-4 col-md-12 col-lg-4 pl-4 child-data-section ">
                <SettingsTabPreview previewData={selectedRowData} />
              </div>
            ) : (
              <div className="col-sm-4 col-md-12 col-lg-4 pl-4 child-data-section ">
                <div className="childTab-preview-pane d-flex align-items-center">
                  <div className="col-sm-12 col-md-12 col-lg-12 d-flex justify-content-center">
                    <p className="preview-msg">
                      Select message to view details
                    </p>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReclaimScan;
