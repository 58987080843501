import { DateRangePickerComponent } from "@syncfusion/ej2-react-calendars";
import axios from "axios";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import closeIconCancel from "../../assets/closeIcon.png";
import {
  addMasterDataEndPoint,
  getAllMasterData,
  getEndPoint,
  getScanGroupByDataRC,
} from "./utils";
import { setCurrentContact, setDispositionData, setScanGroupByRC } from "./contactReducer";
import { ContactType } from "./constants";
import notify from "../../services/toasterService";
import { ButtonComponent } from "@syncfusion/ej2-react-buttons";

const BinExceptionAddandViewScreen = (props: any) => {
  const { setAddScreen } = props;
  const [selectedImage, setSelectedImage] = useState();
  const [selectedRCInfo, setSelectedRCInfo] = useState<any[]>([]);
  const [startTimeValueNew, setStartTimeNew] = useState<any>();
  const [startTimeValueUpdate, setStartTimeValueUpdate] = useState<any>();
  const [endTimeValueUpdate, setEndTimeValueUpdate] = useState<any>();
  const [endTimeValueNew, setEndTimeNew] = useState<any>();
  const [dispositionLinkNew, setDispositionLinkNew] = useState<any[]>([]);
  const [startDateNew, setStartDateNew] = useState();
  const [endDateNew, setEndDateNew] = useState();
  const [scanGroupNew, setScanGroupNew] = useState("");
  const [reclaimCenterId, setReclaimCenterId] = useState("");
  const dispatch = useDispatch();
  const binData = useSelector(
    (state: RootState) => state.contactReducer?.BinData
  );
  const RCData = useSelector(
    (state: RootState) => state.contactReducer?.reclaimCenters
  );

  const dispositionInfo = useSelector(
    (state: RootState) => state.contactReducer?.dispositionData
  );
  const scanGroupInfo = useSelector(
    (state: RootState) => state.contactReducer?.scanGroupListByRC
  );

  const { backPressBinException } = props;

  useEffect(() => { 
    getDispositions();
  },[])
  const handleGetScanGroup = async (getEndpointScanGroup: string, e: any) => {
    const result = await axios.get(getEndpointScanGroup, e);
    if (result && result.data) {
      dispatch(setScanGroupByRC(result.data));
    } else {
    }
  };

  let tabName: any = ContactType.BIN_EXCEPTIONS;

  /**add new div  */

  const handleDateRangeNew = (e: any) => {
    if(e.target.value !== undefined && e.target.value !== null && e.target.value !== '') {
      var selectedDates = e.target.value;
    // const startDateSelected = new Date(selectedDates[0]);
    // let startdateValNew: any = new Date(startDateSelected.toLocaleDateString());
    setStartDateNew(selectedDates[0]);
    // const endDateSelection = new Date(selectedDates[1]);
    // let enddateValNew: any = new Date(endDateSelection.toLocaleDateString());
    setEndDateNew(selectedDates[1]);
    }
   
  };

  const getDispositions = async () => {
    const dispositionEndPoint =  getAllMasterData('Disposition');
    const dispositionresult = await axios
        .get(dispositionEndPoint)
        .catch((err) => err);
    dispatch(setDispositionData(dispositionresult.data));

  }
  const handleChangeScanGroupNew = (e: any) => {
    setScanGroupNew(e.target.value);
  };
  const handleDispositionNew = (e: any) => {
    const addDispItemForNew = e.target.value;
    const dispoData = [...dispositionLinkNew];
    if (!dispoData.some((person) => person === addDispItemForNew)) {
      dispoData.push(addDispItemForNew);
      setDispositionLinkNew(dispoData);
    }
    var mySelect = document.getElementById(
      "dispositionDdlNew"
    ) as HTMLSelectElement;
    if (mySelect) {
      mySelect.selectedIndex = 0;
    }
  };

  const removeLinkedGLAccountData = (value: any) => {
    let linkedGLData = [...dispositionLinkNew];
    let updatedStoreData = linkedGLData.filter((item) => item !== value);
    var mySelect = document.getElementById(
      "dispositionDdlNew"
    ) as HTMLSelectElement;
    if (mySelect) {
      mySelect.selectedIndex = 0;
    }
    setDispositionLinkNew(updatedStoreData);
  };
  const handleStartTimeNew = (e: any) => {
    setStartTimeNew(e.target.value);
  };
  const handleEndTimeNew = (e: any) => {
    setEndTimeNew(e.target.value);
  };
  const handleChangeRC = (e: any) => {
    setReclaimCenterId(e.target.value);
    const getEndpointScanGroup = getScanGroupByDataRC(parseInt(e.target.value));
    handleGetScanGroup(getEndpointScanGroup, parseInt(e.target.value));
  };

  const formatDate = (date: any) => {
    const d = new Date(date);
    // this.currentTime = d.getHours() + ':' + d.getMinutes() + ':' + d.getSeconds()
    let month = "" + (d.getMonth() + 1);
    let day = "" + d.getDate();
    const year = d.getFullYear().toString();
    if (month.length < 2) {
      month = "0" + month;
    }
    if (day.length < 2) {
      day = "0" + day;
    }
    return [year, month, day].join("-");
  };

  const body = {
    createdBy: "",
    createdDate: "",
    disposition: dispositionLinkNew,
    endDate: formatDate(endDateNew),
    endTime: endTimeValueNew,
    lastModifiedBy: "",
    lastModifiedDate: "",
    reclaimCenterId: reclaimCenterId,
    startTime: startTimeValueNew,
    startDate: formatDate(startDateNew),
    scanGroupId: parseInt(scanGroupNew),
  };

  const handleSaveRCDataNew = () => {
    console.log("New Bin", body);
    if (
      body.endDate !== "" &&
      body.endDate !== "" &&
      body.startDate !== undefined &&
      body.startDate !== "" &&
      body.startTime !== undefined &&
      body.startTime !== "" &&
      body.endTime !== undefined &&
      body.endTime !== "" &&
      body.reclaimCenterId !== "" &&
      body.reclaimCenterId !== undefined &&
      body.scanGroupId !== undefined &&
      body.reclaimCenterId !== null &&
      body.disposition !== undefined &&
      body.disposition.length > 0
    ) {
      const endpoint = addMasterDataEndPoint(tabName);
      const getEndpoint = getEndPoint(tabName);
      handleMasterDataChange(endpoint, getEndpoint);
    } else {
      notify("Please fill all fields before create exception.", "error");
    }
  };
  const handleMasterDataChange = async (
    endpoint: string,
    getEndpoint: string
  ) => {
    // const result = await axios.post(endpoint, body);
    // if (result && result.data) {
    //   notify('Done', 'success');
    // } else {
    //   notify('Error', 'error');
    // }
    //const getresult = await axios.get(getEndpoint);
    // dispatch(setCurrentContact(getresult.data));
    const result = await axios
      .post(endpoint, body)
      .then((results: any) => {
        if (results && results.data) {
          notify("Created successfully", "success");
          setAddScreen(false);
        } else {
          notify("Failed to create", "error");
        }
      })
      .catch((error: any) => {
        if (error.response) {
          notify(error.response?.data?.message, "error");
        }
      });
    const getresult = await axios.get(getEndpoint);
    dispatch(setCurrentContact(getresult.data));
  };

  const handleClosePopup = () => {
    setAddScreen(false);
  };
  return (
    <>
      <div className="binpop-content" style={{marginRight: '0'}}>
        <div className="col-sm-12 row m-0 mt-2">
          <div className="col-sm-6">
            
              <div className="col-sm-12"> 
                <span className="lblClass" style={{float: 'left'}}>Reclaim Center <span className="requiredStar"> *</span></span>
                <select
                  className="form-control"
                  onChange={(e: any) => handleChangeRC(e)}
                >
                  <option value="">Select</option>
                  {RCData?.map((st) => (
                    <option value={st.id}>{st.reclaimcenterName}</option>
                  ))}
                </select>
              </div>
            
            
              <div className="col-sm-12">
                <span className="lblClass" style={{float: 'left'}}>Date Range <span className="requiredStar"> *</span></span>
                {/* <span><DateRangePickerComponent id="daterangepicker" format="dd-MMMM-yy" startDate={startDate} endDate={endDate} onChange={(e: any)=>handleDateRange(e)} /></span> */}
                <span>
                  <DateRangePickerComponent
                    id="daterangepicker"
                    onChange={(e: any) => handleDateRangeNew(e)}
                  />
                </span>
              </div>

              <div className="col-sm-12">
                
                <span className="lblClass" style={{float: 'left'}}>Time Range <span className="requiredStar"> *</span></span>
                <span className="lblClass-time" style={{float: 'right'}}>Start Time</span>
                <span>
                  <input
                    type="time"
                    step="2"
                    id="start"
                    className="form-control"
                    onChange={(e) => handleStartTimeNew(e)}
                  />
                </span>   
                           
              
                <span className="lblClass-time" style={{float: 'right'}}>End Time</span>
                <span>
                  <input
                    type="time"
                    step="2"
                    id="end"
                    className="form-control"
                    onChange={(e) => handleEndTimeNew(e)}
                  />
                </span>
              
              </div>
            
            
                        
          </div>        
        
          <div className="col-sm-6">
          <div className="col-sm-12">
                <span className="lblClass" style={{float: 'left'}}>Scan Group <span className="requiredStar"> *</span></span>
                <select
                  className="form-control"
                  onChange={(e: any) => handleChangeScanGroupNew(e)}
                >
                  <option value="">Select</option>
                  {scanGroupInfo?.map((st) => (
                    <option value={st.id}>{st.scanGroupDescription}</option>
                  ))}
                </select>
              </div>          
            
              <div className="col-sm-12">
                <span className="lblClass" style={{float: 'left'}}>Warehouse Location(s) <span className="requiredStar"> *</span></span>
                <select
                  className="form-control"
                  id="dispositionDdlNew"
                  onChange={(e) => handleDispositionNew(e)}
                >
                  <option value="">Select</option>
                  {dispositionInfo?.map((st) => (
                    <option value={st.warehouseLocation}>
                      {st.warehouseLocation}
                    </option>
                  ))}
                </select>
                <div>
                  {dispositionLinkNew.length > 0 ? (
                    <div className="linkedStoreDataDiv">
                      <div className="col-sm-12 linkedGLAccAddInDeptDiv">
                        {dispositionLinkNew &&
                          dispositionLinkNew.map((st: any) => (
                            <div className="row linkedStoreDiv">
                              <div className="col-sm-10">
                              <span
                                title="remove"
                                className="linkedStoreData"
                                style={{color: 'black', fontWeight: 'normal'}}
                              >
                                {st}
                              </span></div>{" "}
                              <div className="col-sm-2" style={{padding: "0"}}>
                              <span
                                className="linkedStoreNameRemove"
                                onClick={(e) => removeLinkedGLAccountData(st)}
                              >
                                <img
                                  src={closeIconCancel}
                                  className="imgCanceIcon"
                                />
                              </span></div>
                            </div>
                          ))}
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            
          </div>
          
          <div className="col-sm-12 px-0 mt-5">
            <ButtonComponent
              cssClass="eg-btn-primary float-right"
              onClick={() => handleSaveRCDataNew()}
            >
              ADD BIN EXCEPTION
            </ButtonComponent>
            <ButtonComponent
              cssClass="e-control e-btn e-lib eg-btn-primary-link e-flat mt-3"
              onClick={handleClosePopup}
            >
              CANCEL
            </ButtonComponent>
          </div>
          {/* <div className="row">
              <div className="col-sm-10"></div>
              <div className="col-sm-2">
                <img className="cursor-pointer" src={saveIcon} onClick={() => handleSaveRCDataNew()} />
              </div>
            </div> */}
        </div>
      </div>
    </>
  );
};
export default BinExceptionAddandViewScreen;
