import { DateRangePickerComponent } from "@syncfusion/ej2-react-calendars";
import axios from "axios";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import saveIcon from "../../assets/saveIcon.png";
import closeIconCancel from "../../assets/closeIcon.png";
import {
  addMasterDataEndPoint,
  getAllMasterData,
  getEndPoint,
  getScanGroupByDataRC,
  updateMasterDataEndPoint,
} from "./utils";
import {
  setCurrentContact,
  setDispositionData,
  setRCNameForBinExceptionView,
  setScanGroupByRC,
} from "./contactReducer";
import { ContactType } from "./constants";
import notify from "../../services/toasterService";
import moment from "moment";

const BinExceptionAddandViewScreen = (props: any) => {
  const [selectedImage, setSelectedImage] = useState();
  const [selectedRCInfo, setSelectedRCInfo] = useState<any[]>([]);
  const [startTimeValueNew, setStartTimeNew] = useState<any>();
  const [startTimeValueUpdate, setStartTimeValueUpdate] = useState<any>();
  const [endTimeValueUpdate, setEndTimeValueUpdate] = useState<any>();
  const [endTimeValueNew, setEndTimeNew] = useState<any>();
  const [dispositionLinkNew, setDispositionLinkNew] = useState<any[]>([]);
  const [startDateNew, setStartDateNew] = useState('');
  const [endDateNew, setEndDateNew] = useState('');
  const [scanGroupNew, setScanGroupNew] = useState("");
  const [dateR, setDateR] = useState();
  const dispatch = useDispatch();

  const [selDisposition, setselDisposition] = useState<any[]>([])

  const binData = useSelector(
    (state: RootState) => state.contactReducer?.BinData
  );
  const setRowDataInfo = useSelector(
    (state: RootState) => state.gridReducer?.selectedRowData
  );
  const dispositionInfo = useSelector(
    (state: RootState) => state.contactReducer?.dispositionData
  );
  const scanGroupInfo = useSelector(
    (state: RootState) => state.contactReducer?.scanGroupListByRC
  );
  const selectedRCID = useSelector(
    (state: RootState) => state.contactReducer?.selectedRCIdforBinException
  );

  const { backPressBinException } = props;
  let dateRange:any = '';

  useEffect(() => {
    console.log(setRowDataInfo)
    //setSelectedRCInfo([setRowDataInfo]);
    if (setRowDataInfo && setRowDataInfo?.length > 0) {
      let setRowDataInfoCopy = JSON.parse(JSON.stringify(setRowDataInfo))
      if(setRowDataInfoCopy[0]?.startDate !== undefined &&  setRowDataInfoCopy[0]?.startDate !== null) {
        setRowDataInfoCopy[0].startDate = moment(setRowDataInfoCopy[0]?.startDate).format('MM/DD/YYYY')
      } 
      if(setRowDataInfoCopy[0]?.endDate !== undefined &&  setRowDataInfoCopy[0]?.endDate !== null) {
        setRowDataInfoCopy[0].endDate = moment(setRowDataInfoCopy[0]?.endDate).format('MM/DD/YYYY')
      }
      dateRange = setRowDataInfoCopy[0].startDate + '-' + setRowDataInfoCopy[0].endDate;
      setDateR(dateRange)
      console.log(setRowDataInfoCopy)
      setSelectedRCInfo(setRowDataInfoCopy);
      let rcId = setRowDataInfo?.map((info: any) => info?.reclaimCenter?.id);
      console.log(rcId);
      dispatch(
        setRCNameForBinExceptionView(
          setRowDataInfo?.map(
            (info: any) => info?.reclaimCenter?.reclaimcenterName
          )
        )
      );
      const getEndpointScanGroup = getScanGroupByDataRC(rcId);
      console.log(getEndpointScanGroup);
      handleGetScanGroup(getEndpointScanGroup, rcId);
      getDispositions();
    }
    console.log(setRowDataInfo)
  }, [setRowDataInfo]);
  const handleGetScanGroup = async (getEndpointScanGroup: string, e: any) => {
    const result = await axios.get(getEndpointScanGroup, e);
    if (result && result.data) {
      dispatch(setScanGroupByRC(result.data));
    } else {
    }
  };
 
  const getDispositions = async () => {
    const dispositionEndPoint =  getAllMasterData('Disposition');
    const dispositionresult = await axios
        .get(dispositionEndPoint)
        .catch((err) => err);
    dispatch(setDispositionData(dispositionresult.data));

  }

  const handleDateRangeOnBlur = (e: any) => { 
    const val = e.target.value 
    if(val !== undefined && val !== null) {
      var selectedDates = val.split('-');
      setDateR(val);
      const rowInfoCopy = JSON.parse(JSON.stringify(selectedRCInfo));
      rowInfoCopy[0].startDate = selectedDates[0];
      rowInfoCopy[0].endDate = selectedDates[1];
      setSelectedRCInfo(rowInfoCopy)
    } else {
      const rowInfoCopy = JSON.parse(JSON.stringify(selectedRCInfo));
      rowInfoCopy[0].startDate = '';
      rowInfoCopy[0].endDate = '';
      console.log(rowInfoCopy[0])
      setSelectedRCInfo(rowInfoCopy)
    }
  }
  const handleDateRange = (e: any) => {
    const val = e.value;
    if(val !== undefined && val !== null) {
      var selectedDates = val;
      const rowInfoCopy = JSON.parse(JSON.stringify(selectedRCInfo));
      rowInfoCopy[0].startDate = moment(selectedDates[0]).format('MM/DD/YYYY');
      rowInfoCopy[0].endDate =  moment(selectedDates[1]).format('MM/DD/YYYY');
      const sDate =  moment(rowInfoCopy[0].startDate).format('MM/DD/YYYY');
      const eDate =  moment(rowInfoCopy[0].endDate).format('MM/DD/YYYY');
      setStartDateNew(sDate);
      setStartDateNew(eDate);
      dateRange = startDateNew + '-' + endDateNew;
      setSelectedRCInfo(rowInfoCopy)
    } else {
      const rowInfoCopy = JSON.parse(JSON.stringify(selectedRCInfo));
      rowInfoCopy[0].startDate = '';
      rowInfoCopy[0].endDate = '';
      console.log(rowInfoCopy[0])
      setSelectedRCInfo(rowInfoCopy)
    }
   
   
  };

  const handleStartTime = (e: any) => {
    const rowInfoCopy = JSON.parse(JSON.stringify(selectedRCInfo));
    rowInfoCopy[0].startTime = e.target.value;
    setSelectedRCInfo(rowInfoCopy);
  };
  const handleEndTime = (e: any) => {
    const rowInfoCopy = JSON.parse(JSON.stringify(selectedRCInfo));
    rowInfoCopy[0].endTime = e.target.value;
    setSelectedRCInfo(rowInfoCopy);
  };
  const handleDdlDisposition = (e: any) => {
    const addStoreItem = JSON.parse(e.target.value);
    selDisposition.push(addStoreItem)
    const rowInfoCopy = JSON.parse(JSON.stringify(selectedRCInfo));
    // const dispoData = [...rowInfoCopy[0].disposition];
    //  if(!dispoData.some(person => person === addStoreItem)){
    //   dispoData.push(addStoreItem);
    //   setSelectedRCInfo(dispoData);
    //   }
   if (!rowInfoCopy[0].disposition.includes(addStoreItem.warehouseLocation)) {
      rowInfoCopy[0].disposition.push(addStoreItem.warehouseLocation);
      setSelectedRCInfo(rowInfoCopy);
      }
      var ddlSelectCustType = document.getElementById(
        "ddlDispositiionType"
      ) as HTMLSelectElement;
      if (ddlSelectCustType) {
        ddlSelectCustType.selectedIndex = 0;
      }
  //  }
    // let selIndex = dispositionInfo.findIndex(ele=>{
    //   if (ele.id === addStoreItem.id) {
    //     return true
    //   }
    // })
    // const glAccDataCopy = glAccData
    // const dispositionInfoCopy = [...dispositionInfo]
    // dispositionInfoCopy?.splice(selIndex,1)
    // dispatch(setDispositionData([]))
    // dispatch(setDispositionData(dispositionInfoCopy))
    // rowInfoCopy[0].disposition.push(addStoreItem)
    // setSelectedRCInfo(rowInfoCopy);

    // const addDispItemForNew = (e.target.value);
    // const dispoData = [...dispositionLinkNew];
    // if(!dispoData.some(person => person === addDispItemForNew)){
    //   dispoData.push(addDispItemForNew);
    //   setDispositionLinkNew(dispoData);
    //   }

    // const dispoData = [...selectedRCInfo[0].disposition];
    // if(!dispoData.some(person => person === e.target.value)){
    //   dispoData.push(e.target.value);
    //   setSelectedRCInfo(dispoData);
    //}
  };
  const removeLinkedDispositionData = (e: any) => {
    console.log(e)
    let linkedDisposiData = [...selectedRCInfo];
    let updatedDispositionData = linkedDisposiData.map((item) => ({
      ...item,
      disposition: item.disposition.filter((itemData: any) => itemData !== e),
    }));
    setSelectedRCInfo(updatedDispositionData);
    selDisposition?.map((item, idx) => {
      if(item.warehouseLocation === e) {
        const dispositionInfoCopy = [...dispositionInfo]
        dispositionInfoCopy?.splice(0,0,item)
        dispatch(setDispositionData([]))
        dispatch(setDispositionData(dispositionInfoCopy))
        selDisposition.splice(idx,1)
      }
    })
  };
  let tabName: any = ContactType.BIN_EXCEPTIONS;

  const handleUpdateRCData = () => {
    const rowInfoCopy = JSON.parse(JSON.stringify(selectedRCInfo));
    console.log(rowInfoCopy);
    // setSelectedRCInfo(rowInfoCopy);
    const endpoint = updateMasterDataEndPoint(tabName, rowInfoCopy);
    const getEndpoint = getEndPoint(tabName);
    handleMasterDataBinUpdate(endpoint, getEndpoint);
  };

  const handleMasterDataBinUpdate = async (
    endpoint: string,
    getEndpoint: string
  ) => {
    const rowInfoObject = Object.assign({}, ...selectedRCInfo);
    console.log(scanGroupNew);
    console.log(rowInfoObject);
    if (
      scanGroupNew !== undefined &&
      scanGroupNew !== null &&
      scanGroupNew !== ""
    ) {
      delete rowInfoObject.scanGroupId;
      Object.assign(rowInfoObject, { scanGroupId: parseInt(scanGroupNew) });
    }
    if (
      rowInfoObject.endDate !== "" &&
      rowInfoObject.endDate !== "" &&
      rowInfoObject.startDate !== undefined &&
      rowInfoObject.startDate !== "" &&
      rowInfoObject.startTime !== undefined &&
      rowInfoObject.startTime !== "" &&
      rowInfoObject.endTime !== undefined &&
      rowInfoObject.endTime !== "" &&
      rowInfoObject.scanGroupId !== undefined &&
      rowInfoObject.reclaimCenterId !== null &&
      rowInfoObject.disposition !== undefined &&
      rowInfoObject.disposition.length > 0
    ) {
      if (moment(rowInfoObject?.startDate).format('YYYY-MM-DD') <= moment(rowInfoObject?.endDate).format('YYYY-MM-DD') && rowInfoObject.startTime <= rowInfoObject.endTime
      ) {
        rowInfoObject.startDate = moment(rowInfoObject?.startDate).format('YYYY-MM-DD');
        rowInfoObject.endDate = moment(rowInfoObject?.endDate).format('YYYY-MM-DD');
        console.log(rowInfoObject);
        const result = await axios
          .put(endpoint, rowInfoObject)
          .then((results: any) => {
            if (results && results.data) {
              notify("Updated successfully", "success");
              backPressBinException();
            } else {
              notify("Failed to update", "error");
            }
          })
          .catch((error: any) => {
            if (error.response) {
              notify(error.response?.data?.message, "error");
            }
          });
        const getresult = await axios.get(getEndpoint);
        dispatch(setCurrentContact(getresult.data));
      } else {
        notify('Please select valid date range and time to update record', "error");
      }
   
    } else {
      notify("Please fill all fields before update exception.", "error");
    }
    // rowInfoObject.scanGroup.id = parseInt(scanGroupNew);
    
  };

  /**add new div  */

  const handleDateRangeNew = (e: any) => {
    console.log(e.target.value)
    var selectedDates = e.target.value;
    // const startDateSelected = new Date(selectedDates[0]);
    // let startdateValNew: any = new Date(startDateSelected.toLocaleDateString());
    setStartDateNew(selectedDates[0]);
    // const endDateSelection = new Date(selectedDates[1]);
    // let enddateValNew: any = new Date(endDateSelection.toLocaleDateString());
    setEndDateNew(selectedDates[1]);
  };
  const handleChangeScanGroupNew = (e: any) => {
    // console.log(e.target.value)
    // console.log(selectedRCInfo)
     const rowInfoObject = Object.assign({}, ...selectedRCInfo);
    // console.log(rowInfoObject)
     delete rowInfoObject.scanGroupId;
    // console.log(rowInfoObject)
     Object.assign(rowInfoObject, {scanGroupId: parseInt(e.target.value)})
    // console.log(rowInfoObject)
     setSelectedRCInfo([rowInfoObject]);
    setScanGroupNew(e.target.value);
  };
  const handleDispositionNew = (e: any) => {
    const addDispItemForNew = e.target.value;
    const dispoData = [...dispositionLinkNew];
    if (!dispoData.some((person) => person === addDispItemForNew)) {
      dispoData.push(addDispItemForNew);
      setDispositionLinkNew(dispoData);
    }
  };

  const removeLinkedGLAccountData = (value: any) => {
    let linkedGLData = [...dispositionLinkNew];
    let updatedStoreData = linkedGLData.filter((item) => item !== value);
    var mySelect = document.getElementById(
      "dispositionDdlNew"
    ) as HTMLSelectElement;
    if (mySelect) {
      mySelect.selectedIndex = 0;
    }
    setDispositionLinkNew(updatedStoreData);
  };
  const handleStartTimeNew = (e: any) => {
    setStartTimeNew(e.target.value);
  };
  const handleEndTimeNew = (e: any) => {
    setEndTimeNew(e.target.value);
  };

 
  const body = {
    createdBy: "",
    createdDate: "",
    disposition: dispositionLinkNew,
    endDate: moment(endDateNew).format('YYYY-MM-DD'),
    endTime: endTimeValueNew,
    lastModifiedBy: "",
    lastModifiedDate: "",
    reclaimCenterId: selectedRCID,
    startTime: startTimeValueNew,
    startDate: moment(startDateNew).format('YYYY-MM-DD'),
    scanGroupId: parseInt(scanGroupNew),
  };

  const handleSaveRCDataNew = () => {
    console.log("New Bin", body);
    const endpoint = addMasterDataEndPoint(tabName);
    const getEndpoint = getEndPoint(tabName);
    handleMasterDataChange(endpoint, getEndpoint);
  };
  const handleMasterDataChange = async (
    endpoint: string,
    getEndpoint: string
  ) => {
    // const result = await axios.post(endpoint, body);
    // if (result && result.data) {
    //   notify('Done', 'success');
    // } else {
    //   notify('Error', 'error');
    // }
    //const getresult = await axios.get(getEndpoint);
    // dispatch(setCurrentContact(getresult.data));
    const result = await axios
      .post(endpoint, body)
      .then((results: any) => {
        if (results && results.data) {
          notify("Created successfully", "success");
          backPressBinException();
        } else {
          notify("Failed to create", "error");
        }
      })
      .catch((error: any) => {
        if (error.response) {
          notify(error.response?.data?.message, "error");
        }
      });
  };
  const formatDateToMonDDYY = (date: any) => {
    return moment(date).format('MMM DD, YYYY');
};
  return (
    <>
      <hr className="mainheading-border" />
      <div className="row mb-4">
        <div className="col-sm-10">
          <span className="backToAllText" onClick={backPressBinException}>
            {"<"} Back to all Bin Exceptions
          </span>
        </div>
        <div className="col-sm-2 BinExceptionSaveBtn">
          {selectedRCInfo &&
            selectedRCInfo.map((info) => (
              <>
                {info?.NoRecord === "" ? (
                  <img
                    className="cursor-pointer"
                    src={saveIcon}
                    onClick={() => handleSaveRCDataNew()}
                  />
                ) : (
                  <img
                    className="cursor-pointer btnUpdateBinExcep"
                    src={saveIcon}
                    onClick={() => handleUpdateRCData()}
                    style={{float: 'right'}}
                  />
                )}
              </>
            ))}
        </div>
        {/* <div className="col-sm-2">
            <img className="cursor-pointer" src={saveIcon} onClick={() => handleUpdateRCData()} />
            </div> */}
      </div>

      {selectedRCInfo &&
        selectedRCInfo.map((info) => (
          // <>

          //   {info?.NoRecord === "" ? (
            //   <div>
            //     <div className="row">
            //       <div className="col-sm-12">
            //         <div className="col-sm-4">
            //           <div className="col-sm-4">
            //             <span className="lblClass">Date Range <span className="requiredStar"> *</span></span>
            //           </div>
            //           <div className="col-sm-8">
            //             {/* <span><DateRangePickerComponent id="daterangepicker" format="dd-MMMM-yy" startDate={startDate} endDate={endDate} onChange={(e: any)=>handleDateRange(e)} /></span> */}
            //             <span>
            //               <DateRangePickerComponent
            //                 id="daterangepicker"
            //                 onChange={(e: any) => handleDateRangeNew(e)}
            //                 startDate={startDateNew}
            //                 endDate={endDateNew}
            //               />
            //             </span>
            //           </div>
            //         </div>
            //         <div className="col-sm-4">
            //           <div className="col-sm-4">
            //             <span className="lblClass">Scan Group <span className="requiredStar"> *</span></span>
            //           </div>
            //           <div className="col-sm-8">
            //             <select
            //               className="form-control"
            //               onChange={(e: any) => handleChangeScanGroupNew(e)}
            //             >
            //               {/* <option value="">Select</option> */}
            //               {scanGroupInfo?.map((st) => (
            //                 <option value={st.id}>
            //                   {st.scanGroupDescription}
            //                 </option>
            //               ))}
            //             </select>
            //           </div>
            //         </div>
            //         <div className="col-sm-3">
            //           <div className="col-sm-4">
            //             <span className="lblClass">Last Updated</span>
            //           </div>
            //           <div className="col-sm-8">
            //             <span></span>
            //           </div>
            //         </div>
            //       </div>
            //     </div>
            //     <br />
            //     <div className="row">
            //       <div className="col-sm-12">
            //         <div className="col-sm-4">
            //           <div className="col-sm-4">
            //             <span className="lblClass">Time Range <span className="requiredStar"> *</span></span>
            //           </div>
            //           <div className="col-sm-4">
            //             <span className="lblClass">Start Time</span>
            //             <span>
            //               <input
            //                 type="time"
            //                 step="2"
            //                 id="start"
            //                 className="form-control"
            //                 onChange={(e) => handleStartTimeNew(e)}
            //               />
            //             </span>
            //           </div>
            //           <div className="col-sm-4">
            //             <span className="lblClass">End Time</span>
            //             <span>
            //               <input
            //                 type="time"
            //                 step="2"
            //                 id="end"
            //                 className="form-control"
            //                 onChange={(e) => handleEndTimeNew(e)}
            //               />
            //             </span>
            //           </div>
            //         </div>
            //         <div className="col-sm-4">
            //           <div className="col-sm-4">
            //             <span className="lblClass ml-6rem">
            //               Warehouse Location(s) <span className="requiredStar"> *</span>
            //             </span>
            //           </div>
            //           <div className="col-sm-8">
            //             <select
            //               className="form-control"
            //               id="dispositionDdlNew"
            //               onChange={(e) => handleDispositionNew(e)}
            //             >
            //               <option >Select</option>
            //               {dispositionInfo?.map((st) => (
            //                 <option value={st.warehouseLocation}>
            //                   {st.warehouseLocation}
            //                 </option>
            //               ))}
            //             </select>
            //             <div>
            //               {dispositionLinkNew.length > 0 ? (
            //                 <div className="linkedStoreDataDiv row">
            //                   <div className="col-sm-12 linkedGLAccAddInDeptDiv">
            //                     {dispositionLinkNew &&
            //                       dispositionLinkNew.map((st: any) => (
            //                         <div className="linkedStoreDiv">
            //                           <span
            //                             title="remove"
            //                             className="linkedStoreData"
            //                           >
            //                             <u>{st}</u>
            //                           </span>{" "}
            //                           <span
            //                             className="linkedStoreNameRemove"
            //                             onClick={(e) =>
            //                               removeLinkedGLAccountData(st)
            //                             }
            //                           >
            //                             <img
            //                               src={closeIconCancel}
            //                               className="imgCanceIcon"
            //                             />
            //                           </span>
            //                         </div>
            //                       ))}
            //                   </div>
            //                 </div>
            //               ) : (
            //                 ""
            //               )}
            //             </div>
            //           </div>
            //         </div>
            //         <div className="col-sm-3">
            //           <div className="col-sm-4">
            //             <span className="lblClass">Modifiled By</span>
            //           </div>
            //           <div className="col-sm-8">
            //             <span></span>
            //           </div>
            //         </div>
            //       </div>
            //       {/* <div className="row">
            //   <div className="col-sm-10"></div>
            //   <div className="col-sm-2">
            //     <img className="cursor-pointer" src={saveIcon} onClick={() => handleSaveRCDataNew()} />
            //   </div>
            // </div> */}
            //     </div>
            //   </div>
            //) :
             (
              <div>
                <div className="row">
                  <div className="col-sm-12 p-0">
                  <div className="col-sm-3">
                    <div className="col-sm-12">                      
                        <span className="lblClass float-left">Date Range <span className="requiredStar">*</span> </span>                    
                        {/* <span><DateRangePickerComponent id="daterangepicker" format="dd-MMMM-yy" startDate={startDate} endDate={endDate} onChange={(e: any)=>handleDateRange(e)} /></span> */}
                        <span>
                          <DateRangePickerComponent
                            onChange={(e: any) => handleDateRange(e)}
                            //onBlur = {(e:any) => handleDateRangeOnBlur(e)}
                            // startDate={info.startDate}
                            //  endDate={info.endDate}
                            value = {dateR}
                            delayUpdate={true}
                          />
                          {/* <DateRangePickerComponent id="daterangepicker" placeholder='Select a range' startDate={start} endDate={end} /> */}
                        </span>                      
                    </div>
                    <div className="col-sm-12">
                        <span className="lblClass float-left">Scan Group  <span className="requiredStar">*</span></span>                    
                        <select
                          className="form-control"
                          onChange={(e: any) => handleChangeScanGroupNew(e)}
                          value={info?.scanGroupId}
                        >
                          {/* <option value={info?.scanGroup?.id}>
                            {info?.scanGroup?.scanGroupDescription}
                          </option> */}
                          {scanGroupInfo?.map((st) => (
                            <option value={st.id}>
                              {st.scanGroupDescription}
                            </option>
                          ))}
                        </select>
                      </div>
                      </div>
                    <div className="col-sm-3">
                    <div className="col-sm-12 p-0">               
                      <div className="col-sm-12">
                        <span className="lblClass float-left">Time Range <span className="requiredStar">*</span></span>
                      </div>
                      <div className="col-sm-12 time">
                        <span className="lblClass-time float-right">Start Time</span>
                        <span>
                          <input
                            type="time"
                            step="2"
                            id="startTimeId"
                            value={info.startTime}
                            className="form-control"
                            onChange={(e) => handleStartTime(e)}
                          />
                        </span>
                      </div>
                      <div className="col-sm-12">
                        <span className="lblClass-time float-right">End Time</span>
                        <span>
                          <input
                            type="time"
                            step="2"
                            id="endTimeId"
                            value={info.endTime}
                            className="form-control"
                            onChange={(e) => handleEndTime(e)}
                          />
                        </span>
                      </div> 
                      </div>                 
                                                                           
                    </div>
                    <div className="col-sm-3">
                    <div className="col-sm-12 p-0">
                      <div className="col-sm-12">
                        <span className="lblClass float-left">
                          Warehouse Location(s)
                          <span className="requiredStar"> *</span>
                        </span>
                      </div>
                      <div className="col-sm-12">
                        <select
                          className="form-control"
                          id="ddlDispositiionType"
                          onChange={(e) => handleDdlDisposition(e)}
                        >
                          <option >Select</option>
                          {dispositionInfo?.map((st) => (
                            <option value={JSON.stringify(st)}>
                              {st.warehouseLocation}
                            </option>
                          ))}
                        </select>
                        <div className="col-sm-12 whLocInBinExcep">
                          {info?.disposition?.map((storesInfo: any) => (
                            <div>
                              <div className="row generalTabWareHouseData ml-0 mr-0">
                                <div className="col-sm-10">
                                  <span className="linkedStoreData" style={{fontWeight: 'normal', color: "black"}}>
                                    <u style={{textDecoration: 'none'}}>{storesInfo}</u>
                                  </span>
                                </div>
                                <div className="col-sm-2">
                                  <span
                                    className="linkedStoreData cursor-pointer"
                                    onClick={(e) =>
                                      removeLinkedDispositionData(storesInfo)
                                    }
                                  >
                                    <img
                                      src={closeIconCancel}
                                      className="imgCanceIconBin"
                                    />
                                  </span>
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                      </div>
                    </div>
                    <div className="col-sm-3">                    
                      <div className="col-sm-12">
                        <span className="lblClass float-left">Last Updated</span>
                      </div>
                      <div className="col-sm-12">
                      <span className="storeNumber lastImportDate">
                             {formatDateToMonDDYY(
                               info?.lastModifiedDate
                               )}
                      </span>
                      </div>                   
                      <div className="col-sm-12">
                        <span className="lblClass float-left">Modifiled By</span>
                      </div>
                      <div className="col-sm-12">
                        <span className="storeNumber lastImportDate">
                        {info?.lastModifiedBy}
                        </span>
                      </div>
                      </div>
                    </div>
                    </div>
              </div>
            )
          
        ))}
    </>
  );
};
export default BinExceptionAddandViewScreen;
