export const environment = {
  react_app_url: window.react_app_url,
  react_app_client_context: window.react_app_client_context,
  keycloak_realm: window.keycloak_realm,
  keycloak_auth_server_url: window.keycloak_auth_server_url,
  keycloak_clientId: window.keycloak_clientId,
  react_app_reports: window.react_app_reports,
  react_app_page: window.react_app_page,
  react_app_pagesize: window.react_app_pagesize,
  react_bold_reports_url: window.react_bold_reports_url,
  react_bold_reports_sitePath: window.react_bold_reports_sitePath
};
