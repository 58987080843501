import { ButtonComponent } from "@syncfusion/ej2-react-buttons";
import axios from "axios";
import { FC, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getSavedCasualDataById } from "../utils";
import addIcon from "../../../icons/common/add-white.png";
import Addnew from "../../../icons/AddNew.svg";
import { setSelectedCasualDataList } from "../settingsReducers";
import { RootState } from "../../../redux/store";
import { DateRangePickerComponent } from "@syncfusion/ej2-react-calendars";
import RuleCriteria from "./ruleCriteria";
import QuestionSet from "./questionSet";
import {
  TabComponent,
  TabItemDirective,
  TabItemsDirective,
} from "@syncfusion/ej2-react-navigations";
import leftArrow from "../../../icons/left-arrow.png";
import { setRowData } from "../../grid/gridReducer";
import AddSettingsScreen from "../addScreen/addScreen";

interface IPreviewTab {
  previewData: any[];
}

const childTab = [{ text: "Rule Criteria" }, { text: "Question Set" }];
const ruleCriteria = () => {
  return <RuleCriteria />;
};

const questionSet = () => {
  return <QuestionSet />;
};
const CasualDataPreview: FC<IPreviewTab> = ({ previewData }) => {
  let [selectedStartDate, setSelectedStartDate] = useState<any>();
  let [selectedEndDate, setSelectedEndDate] = useState<any>();
  let selectedSavedRowData: any[] = [];
  const dispatch = useDispatch();

  const [selectedRowData, setSelectedRowData] = useState<any>(previewData[0]);
  const [addScreen, setAddScreen] = useState(false);
  const [addScreenTabName, setAddScreenTabName] = useState(null);
  const [ruleCode, setRuleCode] = useState("");
  const [dateRange, setDateRange] = useState("");

  useEffect(() => {
    if (selectedRowData) {
    getSavedCasualData(selectedRowData.id);
    }
  }, [selectedRowData]);

  const goToBackScreen = (e: any) => {
    dispatch(setRowData([]));
  };
  const savedCasualDataList = useSelector(
    (state: RootState) => state.settingsReducer?.selectedCasulDataList
  );
  const handlePopupActionCasualData = (selectedTabName: any) => {
    setAddScreen(true);
    setAddScreenTabName(selectedTabName);
  };

  const handleRuleCode = (e: any) => {
    setRuleCode(e.target.value);
  };

  const handleDateRange = (e: any) => {
    setDateRange(e.target.value);
  };
  console.log(savedCasualDataList);
  const getSavedCasualData = async (id: any) => {
    const endPointUrl = getSavedCasualDataById(id);
    await axios
      .get(endPointUrl)
      .then((resp) => {
        console.log(resp);
        selectedSavedRowData.push(resp.data);
        dispatch(setSelectedCasualDataList(resp.data));
        setRuleCode(selectedSavedRowData[0].ruleCode);
        console.log(selectedSavedRowData);
        setSelectedStartDate(new Date(selectedSavedRowData[0].startDate));
        setSelectedEndDate(new Date(selectedSavedRowData[0].endDate));
        console.log(selectedStartDate);
        // })
      })
      .catch((err) => err);
  };

  const TabChangeEvent = () => {
    // dispatch(setRowData([]))
  };
  return (
    <div>
      {addScreen && (
        <AddSettingsScreen
          addScreen={addScreen}
          setAddScreen={setAddScreen}
          tabName={addScreenTabName}
        />
      )}
      <div className="col-sm-12 col-md-12 col-lg-12">
        <div className="row bottomBorderRow mt-3 ml-1" style={{marginRight: '0'}}>
          <p className="col-sm-11 col-md-11 col-lg-11 child-tab-title pb-0">
            {savedCasualDataList.ruleCode}
          </p>
          <div className="col-sm-1 col-md-1 col-lg-1 pr-0">
            <ButtonComponent
              cssClass="e-outline opt-btn-dark float-right ml-1 pt-1 p-0"
              onClick={() => handlePopupActionCasualData("Casual Data")}
            >
              <img src={Addnew} alt="Add" />
            </ButtonComponent>
          </div>
        </div>
        <span
          className="float-left mt-3 pointer"
          onClick={(e) => goToBackScreen(e)}
        >
          {" "}
          <img className="ml-2 mr-2" src={leftArrow} alt="Add" />
          Back to all causal data rules
        </span>
        <br></br>
        <div className="row m-0 my-5">
          <div className="col-sm-2 col-md-2 col-lg-2">
            <span className="form-input-settings-label float-left fw-bold">
              Name
            </span>
            <input
              className="form-control"
              name="name"
              placeholder="Name of Rule"
              value={ruleCode}
              onChange={(e) => handleRuleCode(e)}
            />
          </div>
          <div className="col-sm-2 col-md-2 col-lg-2 ">
            <span className="form-input-settings-label float-left fw-bold">
              Date Range
            </span>
            {/* <input className="form-control"  name="daterange" placeholder="Enter Date Range" type="date"
                                        onChange={e => handleDateRange(e)}/> */}
            <div>
              <DateRangePickerComponent
                id="daterangepicker"
                value={selectedStartDate}
                onChange={(e: any) => handleDateRange(e)}
                startDate={selectedStartDate}
                endDate={selectedEndDate}
              />
            </div>
          </div>
        </div>

        <div className="col-sm-12 col-md-12 col-lg-12 ">
          <div className="control-pane">
            <div className="control-section tab-control-section">
              <TabComponent
                heightAdjustMode="Auto"
                cssClass="orientation-tab ll-custom-tab ll-tab-width"
                selecting={TabChangeEvent}
              >
                <TabItemsDirective>
                  <TabItemDirective
                    header={childTab[0]}
                    content={ruleCriteria}
                  />

                  <TabItemDirective
                    header={childTab[1]}
                    content={questionSet}
                  />
                </TabItemsDirective>
              </TabComponent>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CasualDataPreview;
