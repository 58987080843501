import { environment } from '../../environment';

const baseURL = environment.react_app_url;
const clientContext = environment.react_app_client_context;

export const generateGridData = (tabName: string, data: any) => {
    // console.log(data);
    switch (tabName) {
        case 'Reclaim Scan': {
            return data.map((row: any) => ({
                id: row.id,
                type: row.messageType,
                languageKey: row.languageKey,
                text: row.messageText,
                activeForGrid: row.active === true ? 'Active' : 'Inactive',
                tabName: 'Reclaim Scan',
                tabApiName: 'Reclaim Scan',
            }));
        }
        case 'Mobile': {
            return data.map((row: any) => ({
                id: row.id,
                type: row.messageType,
                languageKey: row.languageKey,
                text: row.messageText,
                activeForGrid: row.active === true ? 'Active' : 'Inactive',
                tabName: 'Mobile',
                tabApiName: 'Mobile',
            }));
        }
        case 'Casual Data': {
            return data.map((row: any) => ({
                id: row.id,
                ruleCode: row.ruleCode,
                from: row.startDate,
                to: row.endDate,

                activeForGrid: row.active === true ? 'Active' : 'Inactive',
                tabName: 'Casual Data',
                tabApiName: 'Casual Data',
            }));
        }
        case 'Casual Question Set': {
            return data.map((row: any) => ({
                actions: '',
                seqId: row.sequence,
                type: row.type,
                question: row.question,
                tabName: 'Casual Question Set',
                tabApiName: 'Casual Question Set',
            }));
        }
        default:
            return [];
    }
};

/* General settings Api calls */
export const getGeneralSettings = () => {
    return baseURL + `${clientContext}/v1/masterdata/scangeneralsettings/items`;
};
export const saveGeneralSettingsById = () => {
    return baseURL + `${clientContext}/v1/masterdata/scangeneralsettings/items`;
};
export const updatGeneralSettingsById = (settingsId: any) => {
    return (
        baseURL +
        `${clientContext}/v1/masterdata/scangeneralsettings/items/${settingsId}`
    );
};

/* Local Scan settings Api calls */
export const getReclamCenters = () => {
    return (
        baseURL +
        `${clientContext}/v1/masterdata/reclaimcenters/list?forSelection=true`
    );
};
export const getScanDetailsById = (id: any) => {
    return baseURL + `${clientContext}/v1/masterdata/scansetting/items/${id}`;
};
export const saveScanSettingsByRcId = (reclemCenterId: any) => {
    return (
        baseURL +
        `${clientContext}/v1/masterdata/scansetting/items/${reclemCenterId}`
    );
};
export const updateScanSettingsById = (
    reclemCenterId: any,
    scanSettingsId: any
) => {
    return (
        baseURL +
        `${clientContext}/v1/masterdata/scansetting/items/${reclemCenterId}`
    );
};
export const deleteScanSettingsById = (
    reclemCenterId: any,
    scanSettingsId: any,
    settingType: string
) => {
    return (
        baseURL +
        `${clientContext}/v1/masterdata/scansetting/items/${reclemCenterId}/${settingType}/${scanSettingsId}`
    );
};

/* Global settings Api calls */
export const getGlobalSettings = () => {
    return (
        baseURL + `${clientContext}/v1/masterdata/scansetting/reasoncode/items`
    );
};
export const saveGlobalSettings = () => {
    return (
        baseURL + `${clientContext}/v1/masterdata/scansetting/reasoncode/items`
    );
};
export const updateGlobalSettings = () => {
    return (
        baseURL + `${clientContext}/v1/masterdata/scansetting/reasoncode/items`
    );
};
export const deleteGlobalSettings = (globalSettingsRCId: any) => {
    return (
        baseURL +
        `${clientContext}/v1/masterdata/scansetting/reasoncode/items/${globalSettingsRCId}`
    );
};

/* Mobile settings Api calls */
export const getSuperGroups = () => {
    return baseURL + `${clientContext}/v1/masterdata/supergroup/list`;
};
export const getMobileSettings = (superGrpid: any) => {
    return (
        baseURL +
        `${clientContext}/v1/masterdata/storescansetting/items/${superGrpid}`
    );
};
export const saveMobileSettingsBySuperGrpId = (superGrpid: any) => {
    return (
        baseURL +
        `${clientContext}/v1/masterdata/storescansetting/items/${superGrpid}`
    );
};
export const updateMobileSettings = (
    superGrpid: any,
    mobileSettingsId: any
) => {
    return (
        baseURL +
        `${clientContext}/v1/masterdata/storescansetting/items/${superGrpid}/${mobileSettingsId}`
    );
};
export const deleteMobileSettingsBySuperGroup = (
    superGrpid: any,
    mobileSettingsId: any
) => {
    return (
        baseURL +
        `${clientContext}/v1/masterdata/storescansetting/items/${superGrpid}/${mobileSettingsId}`
    );
};

/* Casual Data List */

export const addCasualData = () => {
    return baseURL + `${clientContext}/v1/masterdata/causaldata/items`;
};

export const getSavedCasualDataById = (id: any) => {
    return baseURL + `${clientContext}/v1/masterdata/causaldata/items/${id}`;
};

export const RuleExceptionEndPointsForFiltering = (
    listname: any,
    page: number,
    size: number,
    param1: any,
    param2: any
) => {
    switch (listname) {
        case 'Stores': {
            return (
                baseURL +
                `${clientContext}/v1/masterdata/store/list/pagination?page=${page}&size=${size}&clientgroup=${
                    param1 ? param1 : ''
                }&storename=${param2 ? param2 : ''}`
            );
        }
        case 'UPCs': {
            return (
                baseURL +
                `${clientContext}/v1/masterdata/itemcatalogue/list/pagination?page=${page}&size=${size}&vendorId=${
                    param1 ? param1 : ''
                }&upc=${param2 ? param2 : ''}`
            );
        }
        default:
            return '';
    }
};

export const casualDataAddPopupEndPoints = (
    listname: any,
    page: number,
    size: number,
    param?: any
) => {
    switch (listname) {
        case 'Casual Data': {
            return baseURL + `${clientContext}/v1/masterdata/causaldata/list`;
        }
        case 'ReclaimCenters': {
            return (
                baseURL + `${clientContext}/v1/masterdata/reclaimcenters/list`
            );
        }
        case 'ClientGroups': {
            return baseURL + `${clientContext}/v1/masterdata/clientgroup/list`;
        }
        case 'Stores': {
            return (
                baseURL +
                `${clientContext}/v1/masterdata/store/list/pagination?page=${page}&size=${size}`
            );
        }
        case 'Vendors': {
            return (
                baseURL +
                `${clientContext}/v1/masterdata/vendor/list/pagination?page=${page}&size=${size}`
            );
        }
        case 'UPCs': {
            return (
                baseURL +
                `${clientContext}/v1/masterdata/itemcatalogue/list/pagination?page=${page}&size=${size}`
            );
        }
        default:
            return '';
    }
};

export const getReclaimScan = () => {
    return (
        baseURL +
        `${clientContext}/v1/masterdata/messsageManagement/list?isReclaim=true&isGlobal=false`
    );
};

export const getMobileManagement = () => {
    return (
        baseURL +
        `${clientContext}/v1/masterdata/messsageManagement/list?isReclaim=false&isGlobal=false`
    );
};

export const messageManagemetnSettingsEndPoint = (tabName: any) => {
    switch (tabName) {
        case 'Reclaim Scan': {
            return (
                baseURL +
                `${clientContext}/v1/masterdata/messsageManagement/list?isReclaim=true&isGlobal=false`
            );
        }
        case 'Mobile': {
            return (
                baseURL +
                `${clientContext}/v1/masterdata/messsageManagement/list?isReclaim=false&isGlobal=false`
            );
        }
        case 'Languages': {
            return baseURL + `${clientContext}/v1/masterdata/language/list`;
        }
        default:
            return '';
    }
};

export const updatemessageManagementSettingsEndPoint = (
    tabName: any,
    id: any
) => {
    switch (tabName) {
        case 'Reclaim Scan': {
            return (
                baseURL +
                `${clientContext}/v1/masterdata/messagemanagement/items/${id}?isReclaim=true&isGlobal=false`
            );
        }
        case 'Mobile': {
            return (
                baseURL +
                `${clientContext}/v1/masterdata/messagemanagement/items/${id}?isReclaim=false&isGlobal=false`
            );
        }
        default:
            return '';
    }
};

export const addMsgMngmntSettingsEndPoint = (tabName: any) => {
    switch (tabName) {
        case 'Reclaim Scan': {
            return (
                baseURL +
                `${clientContext}/v1/masterdata/messagemanagement/items?isReclaim=true&isGlobal=false`
            );
        }
        case 'Mobile': {
            return (
                baseURL +
                `${clientContext}/v1/masterdata/messagemanagement/items?isReclaim=false&isGlobal=false`
            );
        }
        default:
            return '';
    }
};

export const getMasterStoresList = () => {
    return baseURL + `${clientContext}/v1/masterdata/store/item`;
};

export const getMasterClientGroups = () => {
    return baseURL + `${clientContext}/v1/masterdata/clientgroup/list`;
};

export const getMasterVendorsList = () => {
    return baseURL + `${clientContext}/v1/masterdata/vendor/items`;
};

export const getMasterReclaimCentersList = () => {
    return baseURL + `${clientContext}/v1/masterdata/reclaimcenters/list`;
};
export const getMasterUpCList = () => {
    return (
        baseURL +
        `${clientContext}/v1/masterdata/warehouseLocation/list/${false}`
    );
};

export const reclaimScanHeaders = [
    { field: 'id', header: 'Message ID', width: '100', textAlign: 'Right' },
    { field: 'type', header: 'TYPE', width: '80', textAlign: 'Left' },
    {
        field: 'languageKey',
        header: 'LANGUAGE KEY',
        width: '120',
        textAlign: 'Left',
    },
    { field: 'text', header: 'TEXT', width: '150', textAlign: 'Left' },
    { field: 'activeForGrid', header: 'STATUS' },
];

export const messageMobileMgmtHeaders = [
    { field: 'id', header: 'Message ID', width: '100', textAlign: 'Right' },
    { field: 'type', header: 'TYPE', width: '80', textAlign: 'Left' },
    {
        field: 'languageKey',
        header: 'LANGUAGE KEY',
        width: '120',
        textAlign: 'Left',
    },
    { field: 'text', header: 'TEXT', width: '150', textAlign: 'Left' },
    { field: 'activeForGrid', header: 'STATUS' },
];

export const casualDataHeaders = [
    { field: 'id', header: 'ID', width: '60', textAlign: 'Right' },
    { field: 'ruleCode', header: 'Name', width: '150', textAlign: 'Left' },
    { field: 'from', header: 'From', width: '120', textAlign: 'Right' },
    { field: 'to', header: 'To', width: '120', textAlign: 'Right' },
    { field: 'activeForGrid', header: 'STATUS' },
];

export const casualDataQuestionSetHeaders = [
    { field: 'actions', header: '', width: '100', textAlign: 'Right' },
    { field: 'seqId', header: 'SEQUENCE', width: '100', textAlign: 'Right' },
    { field: 'type', header: 'TYPE', width: '80', textAlign: 'Center' },
    {
        field: 'question',
        header: 'QUESTION',
        width: '120',
        textAlign: 'Center',
    },
];

export const formatDate = (date: any) => {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;
    return [year, month, day].join('-');
};
