import { DialogUtility } from '@syncfusion/ej2-popups';
import { DashboardLayoutComponent } from '@syncfusion/ej2-react-layouts';
import axios from 'axios';
import moment from 'moment';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import toast from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';
import downicon from '../../icons/down_arrow.svg';
import minusicon from '../../icons/Minus.svg';
import plusicon from '../../icons/plus.svg';
import scanstation from '../../icons/scanstation-settings.png';
import upicon from '../../icons/uparrow.svg';
import { RootState } from '../../redux/store';
import notify from '../../services/toasterService';
import LLModal from '../common/llModal/LLModal';
import GridWrapper from '../grid/Grid';
import { EndPoints } from './constants';
import './Scan.scss';
//import { ToastProvider, useToasts } from 'react-toast-notifications';
import BinModeWarning from './scannedItems/binModeWarning';
import ClosePalletAndOutBound from './scannedItems/closePalletAndOutBound';
import ForceToTrashModal from './scannedItems/forceToTrash';
import ManagementApprovalModal from './scannedItems/managementApproval';
import OpenInboundBox from './scannedItems/openInboundBox';
import ScanContentBox from './scannedItems/scanContentBox';
import ScannedItemPanel from './scannedItems/scannedItem';
import UnitFactor from './scannedItems/unitFactor';
import UnlabeledItemsModal from './scannedItems/unlabeledItems';
import ServiceHandler from '../../services/service-handler';
import { OutBoundBoxUtil } from './OutBoundBoxUtil';
import {
    setCanCloseBin,
    setForceTrashMode,
    setInboundBoxDetails,
    setIsBoxClosed,
    setIsMaxInboundBoxApproved,
    setIsMaxOutboundBoxApproved,
    setIsOutboundBox,
    setItemDetails,
    setOutboundBinDetails,
    setOutboundBoxDetails,
    setScanDone,
    setScanModeListReducer,
    setScanModeReducer,
    setScannedSessionItemsCount,
    setScanSessionItemDetails,
    setScanSettings,
    setSubDispositionsByState,
    setUserScanQty,
} from './scanReducer';
import {
    deleteItem,
    generateHisoryGrid,
    getEndpoint,
    getListofValues,
    getLocalStorageItems,
    getOutBoundList,
    getPalletList,
    getScanEndPoint,
    handleScanItems,
    openInboundBox,
    scanSessionItems,
    setLocalStorageItems,
    unlabeledEndPoint,
} from './utils';
import { InputField } from '../input-field/input-field';

const ScanScreen = (props: any) => {
    let deletedItem: any = [];
    let trashToastId: any;
    let binToastId: any;
    let noCreditToastId: any;
    let auditToastId: any;
    const [addScreen, setAddScreen] = useState(false);
    const [showModalType, setShowModalType] = useState('');
    const [scanMode, setScanMode] = useState('');
    const [scanSettings, setSettings] = useState({
        scangroup: '',
        scanStation: '',
        reclaimCenter: '',
        reclaimCenterState: '',
    });
    const [trashMode, settrashMode] = useState(false);
    const [binMode, setbinMode] = useState(false);
    const [noCreditMode, setnoCreditMode] = useState(false);
    const [auditMode, setauditMode] = useState(false);
    const [isHazardous, setIsHazardous] = useState(false);
    const [itemQty, setItemQty] = useState(1);
    const [outboundBox, setOutBoundBox] = useState<[{}]>([{}]);
    const [managementApprovalHeading, setManagementApprovalHeading] =
        useState('');
    const [unitFactor, setUnitFactor] = useState(0);
    const [unlabeled, setUnlabeled] = useState('');
    const [upcValue, setUPCValue] = useState('');
    const [unlabeledIsFrom, setunlabeledIsFrom] = useState('');
    const [unlabelledDisposition, setUnlabelledDisposition] = useState('');
    const [unlabelledSubDisposition, setUnlabelledSubDisposition] =
        useState('');
    const [deletedRecords, setDeletedRecords] = useState([{}]);
    const [underReviewObject, setUnderReviewObject] = useState({
        storeNum: '',
        reasonCode: '',
        box: '',
        weekEnding: '',
    });

    const [inboundBoxDetails, setinboundBoxDetails] = useState({
        inboundBoxId: '',
        storeLabel: '',
        storeId: '',
        weekendDate: '',
    });
    const [scannedItemsList, setscannedItemsList] = useState<any[]>([]);
    const [reasonCodeList, setReasonCodeList] = useState<any[]>([]);
    const [isCaptureReasonCode, setIsCaptureReasonCode] = useState(false);
    const [scanItemId, setScanItemId] = useState('');
    const [inboundMaxScan, setInboundMaxScan] = useState('');
    const [outboundMaxScan, setOutboundMaxScan] = useState('');
    //const [canCloseBin, setCanCloseBin] = useState(false);
    const [itemMaxQty, setItemMaxQty] = useState('');
    const [outBoundBoxLabel, setOutBoundBoxLabel] = useState('');
    const [forceToTrash, setForceToTrash] = useState('');
    const [reasonCodeForForceTrash, setReasonCodeForForceTrash] = useState('');
    const [verifiedMultipack, setVerifiedMultipack] = useState(false);
    //const [isBoxClosed, setIsBoxClosed] = useState(false);
    //const [outboundIsBox, setOutboundIsBox] = useState(true);
    const [isFrom, setIsFrom] = useState('');
    const [isBin, setIsBin] = useState(false);
    const [isTrashMode, setIsTrashMode] = useState(false);
    const [isNoCredit, setIsisNOCredit] = useState(false);
    let modesList: any = [];
    // const [userScanQty, setUserScanQty] = useState('');
    let DialogObj: any;
    const upcInputFieldRef = React.createRef<HTMLInputElement>();
    const getItemDetails = useSelector(
        (state: RootState) => state.scanReducer.itemDetails
    );
    const getOutBoundBoxDetails = useSelector(
        (state: RootState) => state.scanReducer.outBoundBoxDetails
    );
    const getOutBoundBinDetails = useSelector(
        (state: RootState) => state.scanReducer.outBoundBinDetails
    );
    const getScanSessionItemDetails = useSelector(
        (state: RootState) => state.scanReducer.scanSessionItemDetails
    );
    const loggedInUserId = useSelector(
        (state: RootState) => state.sideBarReducer.loggedInUserId
    );
    const getInputValue = useSelector(
        (state: RootState) => state.scanReducer.inputValue
    );
    const seletedDataToExport = useSelector(
        (state: RootState) => state.contactReducer.selectedRecordsForExport
    );
    const getReasonCode = useSelector(
        (state: RootState) => state.scanReducer.reasonCode
    );
    const getScanQtyByUser = useSelector(
        (state: RootState) => state.scanReducer.userScanQty
    );
    const getScanModeList = useSelector(
        (state: RootState) => state.scanReducer.scanModeList
    );
    const getForceTrashMode = useSelector(
        (state: RootState) => state.scanReducer.forceTrashMode
    );
    const getIsOutboundBox = useSelector(
        (state: RootState) => state.scanReducer.isOutboundBox
    );
    const getCanCloseBin = useSelector(
        (state: RootState) => state.scanReducer.canCloseBin
    );
    const getIsBoxClosed = useSelector(
        (state: RootState) => state.scanReducer.isBoxClosed
    );
    const getIsMaxInboundBoxApproved = useSelector(
        (state: RootState) => state.scanReducer.isMaxInboundBoxApproved
    );
    const getIsMaxOutboundBoxApproved = useSelector(
        (state: RootState) => state.scanReducer.isMaxOutboundBoxApproved
    );

    // console.log(getIsMaxOutboundBoxApproved);

    const getInputValues = () => {
        // console.log();
        return getInputValue.payload || '';
    };

    let reasonCode = '';

    const getScanSettings = () => {
        const reclaimCenterId = getLocalStorageItems('reclaimCenterId');
        // console.log(reclaimCenterId);
        if (reclaimCenterId) {
            const endpoint: any = getEndpoint(
                EndPoints.SCANSETTINGS,
                reclaimCenterId
            );
            // console.log(endpoint);
            const result = getListofValues(endpoint);
            result.then((data: any) => {
                setIsCaptureReasonCode(
                    data?.data?.scanSettings?.captureReasonCode
                );
                if (
                    data?.data?.scanUser &&
                    data?.data?.scanUser.binMaxScanQty &&
                    data?.data?.scanUser.binMaxScanQty > 0
                ) {
                    setOutboundMaxScan(data?.data?.scanUser.binMaxScanQty);
                } else {
                    setOutboundMaxScan(data?.data?.scanSettings?.binMaxItemQty);
                }
                if (
                    data?.data?.scanUser &&
                    data?.data?.scanUser.boxMaxScanQty &&
                    data?.data?.scanUser.boxMaxScanQty > 0
                ) {
                    setInboundMaxScan(data?.data?.scanUser.boxMaxScanQty);
                } else {
                    setInboundMaxScan(data?.data?.scanSettings?.boxMaxItemQty);
                }

                if(data?.data?.scanUser.scanMaxQty > 0) {
                    localStorage.setItem(
                        'itemMaxQty',
                        data?.data?.scanUser?.scanMaxQty
                    );
                    setItemMaxQty(data?.data?.scanUser?.scanMaxQty);
                } else {
                localStorage.setItem(
                    'itemMaxQty',
                    data?.data?.scanSettings?.itemMaxQty
                );
                setItemMaxQty(data?.data?.scanSettings?.itemMaxQty);
                }
                //setCanCloseBin(data?.data?.scanUser.canCloseBin);
                dispatch(setCanCloseBin(data?.data?.scanUser.canCloseBin));
                const reason = data?.data?.reasonCodes;
                //reasonCodes = [...reasonCodeList, ...reason];
                // console.log(reason);
                setReasonCodeList(reason);
                localStorage.setItem(
                    'reasonCodes',
                    JSON.stringify({
                        reasonCodeList: reason,
                        captureReasonCode:
                            data?.data?.scanSettings?.captureReasonCode,
                    })
                );
                // console.log(reasonCodeList, 'rc');
                // console.log('captureReasonCode', isCaptureReasonCode);
                //console.log("captureReasonCode", reasonCodes);
                // console.log('captureReasonCode', reasonCodeList);
                ServiceHandler.PrinterService.getPrinterSettings(
                    reclaimCenterId,
                    getLocalStorageItems('scanSettings').scanStation
                ).then((printerSettings) => {
                    setLocalStorageItems('printerSettings', printerSettings);
                });
            });
            dispatch(setScanSettings(getLocalStorageItems('scanSettings')));
        }
        //setFocus();
    };

    useEffect(() => {
        getScanSettings();
        setSettings(getLocalStorageItems('scanSettings'));
        getSubdispositionsByState();
        getUserScanQty();
        if (
            getLocalStorageItems('inboundBox') &&
            getLocalStorageItems('inboundBox') !== null
        ) {
            dispatch(setInboundBoxDetails(getLocalStorageItems('inboundBox')));
        }
        dispatch(setScanModeReducer(isBin ? 'bin' : 'box'));
        setScanMode(isBin ? 'bin' : 'box');
        document.body.style.backgroundColor = '#fff';
        const scanSettingsLocal = getLocalStorageItems('scanSettings');
        if (
            scanSettingsLocal.reclaimCenter &&
            scanSettingsLocal.reclaimCenter !== '' &&
            scanSettingsLocal.scanStation &&
            scanSettingsLocal.scanStation !== ''
        ) {
            getRecentScanHistory();
            getUserScanQty();
        }
    }, []);

    useEffect(() => {
        resetFocusToUpc();
        scanModesOnLoad();
    }, []);

    const scanModesOnLoad = () => {
        settrashMode(
            getScanModeList?.payload?.includes('trash') ? true : false
        );
        setbinMode(getScanModeList?.payload?.includes('bin') ? true : false);
        setnoCreditMode(
            getScanModeList?.payload?.includes('no credit') ? true : false
        );
        setauditMode(
            getScanModeList?.payload?.includes('audit') ? true : false
        );
    };
    const getUserScanQty = () => {
        const endpoint: any = getEndpoint(EndPoints.USERSACNQTY);
        const result = getListofValues(endpoint);
        result.then((data: any) => {
            // console.log(data);
            //setUserScanQty(data.data.data);
            dispatch(setUserScanQty(data.data.data));
        });
    };
    const getSubdispositionsByState = () => {
        const reclaimCenterId = getLocalStorageItems('reclaimCenterId');
        const endpoint: any = getEndpoint(
            EndPoints.SUBDISPOSITIONS,
            reclaimCenterId
        );
        const result = getListofValues(endpoint);
        result.then((data: any) => {
            dispatch(setSubDispositionsByState(data.data));
        });
    };
    const getRecentScanHistory = async () => {
        const startDate = moment().tz('America/New_York');
        startDate.add(-1, 'day');
        startDate.set('hour', 23);
        startDate.set('minute', parseInt('00', 8));
        startDate.set('second', parseInt('00', 8));
        const scanSettingsLocal = getLocalStorageItems('scanSettings');
        const requestPayload = {
            filter: {
                scanStation: scanSettingsLocal.scanStation,
                reclaimCenter: scanSettingsLocal.reclaimCenter,
                startDate: startDate.format('yyyy-MM-DD HH:mm:ss.SSS'),
            },
            pagination: {
                page: 0,
                size: scannedItemsList.length ? scannedItemsList.length : 10,
            },
        };
        const endPointURL = getScanEndPoint('History');
        await axios.post(endPointURL, requestPayload).then((res: any) => {
            // console.log(res, 'session');
            let listItems: any = [];
            listItems = getScanSessionItemDetails?.payload;
            const scanList = res.data.data.content;
            setscannedItemsList(scanList);
            dispatch(setItemDetails({ data: res.data.data.content[0] }));
            if (res?.data?.data?.content[0]?.outboundBox) {
                //setOutboundIsBox(true);
                dispatch(setIsOutboundBox(true));
                dispatch(
                    setOutboundBoxDetails(res.data.data.content[0].outboundBox)
                );
            } else {
                dispatch(setOutboundBoxDetails(undefined));
            }
            if (res?.data?.data?.content[0]?.scanBin) {
                //setOutboundIsBox(false);
                dispatch(setIsOutboundBox(false));
                dispatch(
                    setOutboundBinDetails(res.data.data.content[0].scanBin)
                );
            } else {
                dispatch(setOutboundBinDetails(undefined));
            }
            dispatch(setScanSessionItemDetails(scanList));
            // dispatch(setScannedSessionItemsCount(itemList.length));
        });
    };

    useEffect(() => {
        checkBoxOrBinAvailable(getOutBoundBoxDetails, getOutBoundBinDetails);
        resetFocusToUpc();
    }, [getOutBoundBoxDetails, getOutBoundBinDetails]);

    const checkBoxOrBinAvailable = (boxDetails: any, binDetails: any) => {
        let boxAvailable = false;
        let binAvailable = false;
        if (
            getIsOutboundBox?.payload &&
            boxDetails?.payload?.outboundBoxId &&
            !boxDetails?.payload?.isClosed
        ) {
            boxAvailable = true;
        } else if (
            !getIsOutboundBox?.payload &&
            binDetails?.payload?.binId &&
            !binDetails?.payload?.isClosed
        ) {
            binAvailable = true;
        }
        if (!boxAvailable && !binAvailable) dispatch(setIsBoxClosed(true));
        else dispatch(setIsBoxClosed(false));
    };

    const focusDiv = useRef<HTMLInputElement>(null);

    const setUPCFocus = () => {
        if (focusDiv.current) {
            focusDiv.current.focus();
        }
    };
    const dispatch = useDispatch();

    function setFocus() {
        if (!document.getElementById) {
            return;
        }

        var txtMyInputBoxElement = document.getElementById('upcValue');

        if (txtMyInputBoxElement != null) {
            txtMyInputBoxElement.focus();
        }
    }

    const getOpenInbounBoxDetails = useSelector(
        (state: RootState) => state.scanReducer.openInboundDetails
    );

    const updateUnitFactor = (qty: any) => {
        setItemQty(qty);
        if (getOpenInbounBoxDetails.payload !== undefined) {
            setinboundBoxDetails({
                inboundBoxId: getOpenInbounBoxDetails.payload.data.boxId,
                storeId: getOpenInbounBoxDetails.payload.data.storeId,
                storeLabel: getOpenInbounBoxDetails.payload.data.storeLabel,
                weekendDate: getOpenInbounBoxDetails.payload.data.weekendDate,
            });
            // console.log(inboundBoxDetails);
            if (scanSettings && scanSettings.reclaimCenter) {
                const scanItemRequestBody = {
                    scanGroup: scanSettings.scangroup,
                    scanStation: scanSettings.scanStation,
                    reclaimCenter: scanSettings.reclaimCenter,
                    reclaimCenterState: scanSettings.reclaimCenterState,
                    inboundBoxId: getOpenInbounBoxDetails.payload.data.boxId,
                    storeId: getOpenInbounBoxDetails.payload.data.storeId,
                    storeLabel: getOpenInbounBoxDetails.payload.data.storeLabel,
                    weekendDate:
                        getOpenInbounBoxDetails.payload.data.weekendDate,
                    quantity: qty,
                    scanMode: isBin ? 'bin' : scanMode,
                    auditOutboundBoxId: outBoundBoxLabel
                        ? outBoundBoxLabel
                        : '',
                    disposition: forceToTrash ? forceToTrash : null,
                    reasonCode: reasonCodeForForceTrash
                        ? reasonCodeForForceTrash
                        : null,
                    verifiedMultipack: true,
                    outboundBoxMaxApproved: getIsMaxOutboundBoxApproved.payload,
                    inboundBoxMaxApproved: getIsMaxInboundBoxApproved.payload,
                    isBin: isBin ? isBin : binMode,
                    isTrashMode: isTrashMode ? isTrashMode : trashMode,
                    isNoCredit: isNoCredit ? isNoCredit : noCreditMode,
                };
                setVerifiedMultipack(true);
                setUnitFactor(1);
                const endpoint: any = getEndpoint(EndPoints.SCANITEM, upcValue);
                const result = handleGetItemsChange(
                    endpoint,
                    scanItemRequestBody
                );
            } else {
                notify('Please Set ReclaimCenter First', 'error');
            }
        } else {
            notify('Please OpenInbound box First', 'error');
        }
    };

    const handleUPCOnChange = (e: any) => {
        // console.log(e, 'UPC event');
        // console.log(e.target.value, 'UPC event value');
        document.addEventListener('upcValue', (event) =>
            event.preventDefault()
        );
        // console.log('scroll down');
        const code = e.which ? e.which : e.keyCode;
        if (code > 31 && (code < 48 || code > 57)) {
            e.preventDefault();
        } else {
            if (e.keyCode === 13) {
                if (isTrashMode && isCaptureReasonCode) {
                    handleTrashModeSubmit();
                } else {
                    submitUpcData();
                }
            }
        }
    };
    const validQuantity = () => {
        if (itemQty > parseInt(itemMaxQty)) {
            notify(
                'Max scan quantity allowed is ' +
                    itemMaxQty +
                    ' Please update it before scan. ',
                'warning'
            );
            return false;
        }
        return true;
    };
    const submitUpcData = (requestBody?: any) => {
        if (
            getOpenInbounBoxDetails.payload &&
            getOpenInbounBoxDetails.payload?.data?.boxId
        ) {
            if (upcValue.length === 13) {
                setUnlabeled('13 Digit UPC');
                setShowModalType('13d');
                setunlabeledIsFrom('13d');
                setAddScreen(true);
            } else if (upcValue.length < 6 || upcValue.length > 13) {
                notify('Please enter valid UPC Number! ', 'error');
                setUPCValue('');
            } else if (upcValue) {
                setunlabeledIsFrom('');
                if (!validQuantity()) return;
                if (
                    getOpenInbounBoxDetails.payload !== undefined &&
                    getOpenInbounBoxDetails.payload.data !== undefined &&
                    scanMode !== 'audit_outbound'
                ) {
                    setinboundBoxDetails({
                        inboundBoxId:
                            getOpenInbounBoxDetails.payload.data.boxId,
                        storeId: getOpenInbounBoxDetails.payload.data.storeId,
                        storeLabel:
                            getOpenInbounBoxDetails.payload.data.storeLabel,
                        weekendDate:
                            getOpenInbounBoxDetails.payload.data.weekendDate,
                    });
                    // console.log(inboundBoxDetails);
                    if (scanSettings && scanSettings.reclaimCenter) {
                        // if (
                        //   scannedItemsList.length !== 0 &&
                        //   parseInt(maxScan) > scannedItemsList.length
                        // ) {
                        //   setShowModalType("maxscan");
                        // } else {
                        const scanItemRequestBody = {
                            scanGroup: scanSettings.scangroup,
                            scanStation: scanSettings.scanStation,
                            reclaimCenter: scanSettings.reclaimCenter,
                            reclaimCenterState: scanSettings.reclaimCenterState,
                            inboundBoxId:
                                getOpenInbounBoxDetails.payload.data.boxId,
                            storeId:
                                getOpenInbounBoxDetails.payload.data.storeId,
                            storeLabel:
                                getOpenInbounBoxDetails.payload.data.storeLabel,
                            weekendDate:
                                getOpenInbounBoxDetails.payload.data
                                    .weekendDate,
                            quantity: itemQty,
                            scanMode: isBin ? 'bin' : scanMode,
                            isBin: isBin ? isBin : binMode,
                            isTrashMode: isTrashMode ? isTrashMode : trashMode,
                            isNoCredit: isNoCredit ? isNoCredit : noCreditMode,
                            auditOutboundBoxId: outBoundBoxLabel
                                ? outBoundBoxLabel
                                : '',
                            disposition:
                                isTrashMode && isCaptureReasonCode
                                    ? 'trash'
                                    : forceToTrash
                                    ? forceToTrash
                                    : null,
                            reasonCode:
                                isTrashMode && isCaptureReasonCode
                                    ? reasonCode
                                    : reasonCodeForForceTrash
                                    ? reasonCodeForForceTrash
                                    : null,
                            outboundBoxMaxApproved:
                                getIsMaxOutboundBoxApproved.payload,
                            inboundBoxMaxApproved:
                                getIsMaxInboundBoxApproved.payload,
                        };
                        const endpoint: any = getEndpoint(
                            EndPoints.SCANITEM,
                            upcValue
                        );
                        const result = handleGetItemsChange(
                            endpoint,
                            requestBody ? requestBody : scanItemRequestBody
                        );
                        // }
                    } else {
                        notify('Please Set ReclaimCenter First', 'error');
                    }
                } else if (scanMode === 'audit_outbound') {
                    const scanItemRequestBody = {
                        scanGroup: scanSettings.scangroup,
                        scanStation: scanSettings.scanStation,
                        reclaimCenter: scanSettings.reclaimCenter,
                        reclaimCenterState: scanSettings.reclaimCenterState,
                        quantity: itemQty,
                        scanMode: isBin ? 'bin' : scanMode,
                        isBin: isBin ? isBin : binMode,
                        isTrashMode: isTrashMode ? isTrashMode : trashMode,
                        isNoCredit: isNoCredit ? isNoCredit : noCreditMode,
                        auditOutboundBoxId: outBoundBoxLabel
                            ? outBoundBoxLabel
                            : '',
                        disposition: forceToTrash ? forceToTrash : null,
                        reasonCode: reasonCodeForForceTrash
                            ? reasonCodeForForceTrash
                            : null,
                        inboundBoxId:
                            getOpenInbounBoxDetails.payload.data.boxId,
                        storeId: getOpenInbounBoxDetails.payload.data.storeId,
                        storeLabel:
                            getOpenInbounBoxDetails.payload.data.storeLabel,
                        weekendDate:
                            getOpenInbounBoxDetails.payload.data.weekendDate,
                    };
                    const endpoint: any = getEndpoint(
                        EndPoints.SCANITEM,
                        upcValue
                    );
                    const result = handleGetItemsChange(
                        endpoint,
                        requestBody ? requestBody : scanItemRequestBody
                    );
                }
            }
        } else {
            notify('Please OpenInbound box First', 'error');
        }
    };

    const handleGetItemsChange = async (
        endpoint: string,
        scanItemRequestBody: any
    ) => {
        const result = await axios
            .post(endpoint, scanItemRequestBody)
            .then((response: any) => {
                setItemQty(1);
                setUnitFactor(response.data.data.unitFactor);
                dispatch(setScanDone(true));
                //setIsBoxClosed(false);
                dispatch(setIsBoxClosed(false));
                setShowModalType('');
                dispatch(setOutboundBoxDetails({}));
                dispatch(setOutboundBinDetails({}));
                if ('speechSynthesis' in window) {
                    var msg = new SpeechSynthesisUtterance();
                    // if (
                    //     (forceToTrash === 'trash' &&
                    //         response.data.data.hazardousFlag === false) ||
                    //     (scanMode === 'trash' &&
                    //         response.data.data.hazardousFlag === false)
                    // ) {
                    //     msg.text = 'ALERT DESTROY';
                    // } else if (
                    //     (forceToTrash === 'trash' &&
                    //         response.data.data.hazardousFlag === true) ||
                    //     (scanMode === 'trash' &&
                    //         response.data.data.hazardousFlag === true)
                    // ) {
                    //     if (response.data.data.subDisposition) {
                    //         msg.text = response.data.data.subDisposition;
                    //     } else {
                    //         msg.text = 'ALERT DISPOSE';
                    //     }
                    // } else {
                    msg.text = response.data.data.dispositionVoiceText;
                    // }
                    if(msg.text === 'CABA') {
                        msg.text = 'CA,BA'
                      }
                    if(msg.text === 'CACA') {
                          msg.text = 'CA,CA'
                        }
                    window.speechSynthesis.speak(msg);
                    setUPCValue('');
                } else {
                    // Speech Synthesis Not Supported
                    notify(
                        "Sorry, your browser doesn't support text to speech!",
                        'error'
                    );
                }
                dispatch(setItemDetails(response.data));
                const scanList = [
                    { ...response.data.data },
                    ...scannedItemsList,
                ];
                // console.log(scanList);
                setscannedItemsList(scanList);
                dispatch(setScanSessionItemDetails(scanList));
                dispatch(setScannedSessionItemsCount(scanList.length));
                setScanItemId(response.data.data.id);
                setIsHazardous(response.data.data.hazardousFlag);
                dispatch(setOutboundBoxDetails(''));
                dispatch(setOutboundBinDetails(''));
                dispatch(setIsMaxOutboundBoxApproved(false));
                dispatch(setIsMaxInboundBoxApproved(false));
                if (
                    response.data.data.disposition === 'TRASH' ||
                    response.data.data.disposition === 'DESTROY' ||
                    response.data.data.disposition === 'ALERT DESTROY'
                    //response.data.data.disposition === 'ALERT DISPOSE'
                ) {
                    dispatch(setOutboundBoxDetails(''));
                    dispatch(setOutboundBinDetails(''));
                    //setIsBoxClosed(true);
                    dispatch(setIsBoxClosed(true));
                } else {
                    //if (scanMode === 'box' || scanMode === 'nocredit' || scanMode === 'trash') {
                    if (response.data.data.outboundBox) {
                        //setOutboundIsBox(true);
                        dispatch(setIsOutboundBox(true));
                        dispatch(
                            setOutboundBoxDetails(
                                response.data.data.outboundBox
                            )
                        );
                    }
                    //} else if (scanMode === 'bin') {
                    if (response.data.data.scanBin) {
                        // setOutboundIsBox(false);
                        dispatch(setIsOutboundBox(false));
                        dispatch(
                            setOutboundBinDetails(response.data.data.scanBin)
                        );
                    } else if (response.data.data.outboundBox) {
                        //setOutboundIsBox(true);
                        dispatch(setIsOutboundBox(true));
                        dispatch(
                            setOutboundBoxDetails(
                                response.data.data.outboundBox
                            )
                        );
                    }
                    //}
                }
                localStorage.setItem(
                    'scansession',
                    JSON.stringify(scannedItemsList)
                );
                setForceToTrash('');
                setReasonCodeForForceTrash('');
                dispatch(setForceTrashMode(''));
                // if (forceToTrash === '') {
                //     dispatch(setForceTrashMode(''));
                // }
                getUserScanQty();
                setunlabeledIsFrom('');
                if (verifiedMultipack) {
                    setVerifiedMultipack(false);
                }
                // getScannedItemsFromSession = getLocalStorageItems("scansession");
            })
            .catch((err) => {
                if (err?.response?.data?.code === 'SCN-00003') {
                    setUnlabeled('Not Found Item');
                    setunlabeledIsFrom('NF');
                    setShowModalType('NF');
                    setAddScreen(true);
                } else if (err?.response?.status === 500) {
                    notify(err?.response?.data?.messageText, 'error');
                } else if (err?.response?.status === 422) {
                    if (err?.response?.data?.code === 'SCN-00010') {
                        const tbdLoc = {
                            warehouseLocation: 'TBD LOC',
                        };
                        dispatch(setItemDetails(tbdLoc));
                        notify('Pallet Location Not Found', 'error');
                    } else if (err?.response?.data?.code === 'SCN-00009') {
                        setShowModalType('InboundMaxQty');
                        setAddScreen(true);
                        dispatch(setIsMaxInboundBoxApproved(false));
                    } else if (err?.response?.data?.code === 'SCN-00008') {
                        setShowModalType('OutboundMaxQty');
                        setAddScreen(true);
                        dispatch(setIsMaxOutboundBoxApproved(false));
                    } else if (err?.response?.data?.code === 'SCN-00007') {
                        setShowModalType('unitfactor');
                        setAddScreen(true);
                    }
                } else {
                    notify(err?.response?.data?.messageText, 'error');
                }
            });
    };

    const toDate = (txt: any) => {
        return txt.toLocaleDateString();
    };

    const handleStoresUnderReview = (
        storeNum: any,
        reasonCode: any,
        box: any,
        weekEnding: any
    ) => {
        setShowModalType('underreview');
        setAddScreen(true);
        setUnderReviewObject({
            storeNum: storeNum,
            reasonCode: reasonCode,
            box: box,
            weekEnding: weekEnding,
        });
    };

    const openInboundBoxAfterApproval = (data: any) => {
        // console.log(addScreen);
        setAddScreen(false);
        const requestPayload = {
            scanGroup: scanSettings.scangroup,
            scanStation: scanSettings.scanStation,
            reclaimCenter: scanSettings.reclaimCenter,
            storeId: data.storeNum,
            inboundBoxId: data.box,
            weekendDate: data.weekEnding,
        };
        const endpoint: any = getEndpoint(EndPoints.OPENINBOUND);
        const result = openInboundBox(endpoint, requestPayload);
        result
            .then((data) => {
                notify('Opened InboundBox Successfully', 'success');
                dispatch(setInboundBoxDetails(data.data));
            })
            .catch((error) => {
                notify(error.response.data.message.messageText, 'error');
                setAddScreen(false);
            });
    };

    const handleRenderModal = (type: any) => {
        if (
            type === 'unlabeled' &&
            !(
                getOpenInbounBoxDetails.payload &&
                getOpenInbounBoxDetails.payload?.data?.boxId
            )
        ) {
            notify('Please Open InboundBox First!', 'error');
        } else {
            if (type === 'outbound' && getIsOutboundBox?.payload) {
                handleGetOutboundList();
            } else if (type === 'outbound' && !getIsOutboundBox?.payload) {
                handleGetOutboundBinsList();
            }
            setUnlabeled('Unlabeled Item');
            setunlabeledIsFrom('unlabeled');
            if (type === 'forcetrash' && isCaptureReasonCode === false) {
                if (forceToTrash === 'trash') {
                    handleForceToTrash('', 'box');
                } else {
                    handleForceToTrash('');
                }
            } else if (
                type === 'forcetrash' &&
                isCaptureReasonCode === true &&
                forceToTrash === 'trash'
            ) {
                handleForceToTrash('', 'box');
            } else {
                setShowModalType(type);
                setAddScreen(true);
            }
        }
        //setFocus();
    };

    const handleTrash = (event: any, value: any) => {
        if (event.currentTarget.checked) {
            setScanMode(isBin ? 'bin' : value);
            toast.dismiss(binToastId);
            toast.dismiss(noCreditToastId);
            toast.dismiss(auditToastId);
            dispatch(setScanModeReducer(isBin ? 'bin' : value));
            if (!getScanModeList?.payload?.includes('trash')) {
                const modesListString =
                    (getScanModeList?.payload?.includes('audit')
                        ? ''
                        : getScanModeList?.payload
                        ? getScanModeList?.payload
                        : '') +
                    ',' +
                    'trash Mode';
                dispatch(
                    setScanModeListReducer(
                        modesListString.replace(/(^,)|(,$)/g, '')
                    )
                );
            }
            settrashMode(true);
            setauditMode(false);
            dispatch(setScanDone(false));
            setIsTrashMode(true);
            trashToastId = toast('You are scanning in Trash Mode.', {
                duration: 5000,
                position: 'top-center',
                // Styling
                style: {},
                className: 'trashmode',
                // Custom Icon
                icon: '',
                // Change colors of success/error/loading icon
                iconTheme: {
                    primary: '#000',
                    secondary: '#fff',
                },
                // Aria
                ariaProps: {
                    role: 'status',
                    'aria-live': 'polite',
                },
            });
            document.body.style.backgroundColor = '#ece2df';
        } else {
            setScanMode(isBin ? 'bin' : 'box');
            setIsTrashMode(false);
            settrashMode(false);
            dispatch(setScanModeReducer(isBin ? 'bin' : 'box'));
            toast.dismiss(trashToastId);
            document.body.style.backgroundColor = '#fff';
            if (getScanModeList?.payload?.includes('trash')) {
                const str = getScanModeList?.payload.split(',');
                var index = str.indexOf('trash Mode');
                str.splice(index, 1);
                const modesListString = str.join(',');
                dispatch(
                    setScanModeListReducer(
                        modesListString.replace(/(^,)|(,$)/g, '')
                    )
                );
            }
        }
        setIsFrom('');
        // let bin = document.getElementById('binMode') as HTMLInputElement;
        //  let noCredit = document.getElementById(
        //      'noCreditMode'
        //  ) as HTMLInputElement;
        let audit = document.getElementById('auditMode') as HTMLInputElement;
        //bin.checked = false;
        //noCredit.checked = false;
        audit.checked = false;
        resetFocusToUpc();
    };

    const handleBin = (event: any, value: any) => {
        if (event.currentTarget.checked) {
            if (getScanModeList?.payload?.includes('audit')) {
                dispatch(setScanModeListReducer(''));
            }
            toast.dismiss(trashToastId);
            toast.dismiss(noCreditToastId);
            toast.dismiss(auditToastId);
            setShowModalType(value);
            //setOutboundIsBox(false);
            dispatch(setIsOutboundBox(false));
            // setIsBin(true);
            setauditMode(false);
            // setScanMode(value);
            // dispatch(setScanModeReducer(value));
            // setIsBoxClosed(
            //     getOutBoundBinDetails?.payload
            //         ? getOutBoundBinDetails?.payload?.isClosed
            //         : true
            // );
            dispatch(
                setIsBoxClosed(
                    getOutBoundBinDetails?.payload
                        ? getOutBoundBinDetails?.payload?.isClosed
                        : true
                )
            );
            setAddScreen(true);
        } else {
            setShowModalType('');
            setIsBin(false);
            setbinMode(false);
            toast.remove(binToastId);
            if (isNoCredit == true) {
                setScanMode('nocredit');
            } else if (isTrashMode == true) {
                setScanMode('trash')
            } else {
                setScanMode('box');
            }
            dispatch(setScanModeReducer('box'));
            // setIsBoxClosed(
            //     getOutBoundBoxDetails?.payload
            //         ? getOutBoundBoxDetails?.payload?.isClosed
            //         : true
            // );
            dispatch(
                setIsBoxClosed(
                    getOutBoundBoxDetails?.payload
                        ? getOutBoundBoxDetails?.payload?.isClosed
                        : true
                )
            );
            // setOutboundIsBox(true);
            dispatch(setIsOutboundBox(true));
            if (getScanModeList?.payload?.includes('bin')) {
                const str = getScanModeList?.payload.split(',');
                var index = str.indexOf('bin Mode');
                str.splice(index, 1);
                const modesListString = str.join(',');
                dispatch(
                    setScanModeListReducer(
                        modesListString.replace(/(^,)|(,$)/g, '')
                    )
                );
            }
            document.body.style.backgroundColor = 'white';
        }
        setIsFrom('');
        // let trash = document.getElementById('trashMode') as HTMLInputElement;
        // let noCredit = document.getElementById(
        //     'noCreditMode'
        // ) as HTMLInputElement;
        let audit = document.getElementById('auditMode') as HTMLInputElement;
        // trash.checked = false;
        // noCredit.checked = false;
        audit.checked = false;
        resetFocusToUpc();
    };

    const handleNoCredit = (event: any, value: any) => {
        if (event.currentTarget.checked) {
            if (getScanModeList?.payload?.includes('audit')) {
                dispatch(setScanModeListReducer(''));
            }
            // setScanMode(value);
            // dispatch(setScanModeReducer(value));
            setShowModalType(value);
            setAddScreen(true);
            toast.dismiss(binToastId);
            toast.dismiss(trashToastId);
            toast.dismiss(auditToastId);
            // setIsisNOCredit(true);
            setauditMode(false);
        } else {
            setScanMode(isBin ? 'bin' : 'box');
            setIsisNOCredit(false);
            setnoCreditMode(false);
            toast.dismiss(noCreditToastId);
            dispatch(setScanModeReducer(isBin ? 'bin' : 'box'));
            document.body.style.backgroundColor = 'white';
            if (getScanModeList?.payload?.includes('no credit')) {
                const str = getScanModeList?.payload.split(',');
                var index = str.indexOf('no credit Mode');
                str.splice(index, 1);
                const modesListString = str.join(',');
                dispatch(
                    setScanModeListReducer(
                        modesListString.replace(/(^,)|(,$)/g, '')
                    )
                );
            }
        }
        setIsFrom('');
        //let trash = document.getElementById('trashMode') as HTMLInputElement;
        // let bin = document.getElementById('binMode') as HTMLInputElement;
        let audit = document.getElementById('auditMode') as HTMLInputElement;
        //trash.checked = false;
        // bin.checked = false;
        audit.checked = false;
        resetFocusToUpc();
    };

    const handleRestForceToTrashModal = () => {
        handleRenderModal('');
        // handleRenderModal("forcetrash");
        setAddScreen(false);
        //setFocus();
    };

    const handleAudit = (event: any, value: any) => {
        if (event.currentTarget.checked) {
            //dispatch(setScanModeListReducer(''));
            toast.dismiss(binToastId);
            toast.dismiss(trashToastId);
            toast.dismiss(noCreditToastId);
            // setScanMode(value);
            dispatch(setScanModeReducer(value));
            setShowModalType(value);
            setAddScreen(true);
            setIsBin(false);
            setIsTrashMode(false);
            setIsisNOCredit(false);
        } else {
            setScanMode(isBin ? 'bin' : 'box');
            toast.dismiss(auditToastId);
            dispatch(setScanModeReducer(isBin ? 'bin' : 'box'));
            document.body.style.backgroundColor = 'white';
            setIsFrom('');
            if (getScanModeList?.payload?.includes('audit')) {
                //dispatch(setScanModeListReducer(''));
            }
            setauditMode(false);
        }
        // let trash = document.getElementById('trashMode') as HTMLInputElement;
        // let bin = document.getElementById('binMode') as HTMLInputElement;
        // let noCredit = document.getElementById(
        //     'noCreditMode'
        // ) as HTMLInputElement;
        // trash.checked = false;
        // bin.checked = false;
        // noCredit.checked = false;
        resetFocusToUpc();
    };

    const handleScanModes = (event: any) => {
        let trash = document.getElementById('trashMode') as HTMLInputElement;
        let bin = document.getElementById('binMode') as HTMLInputElement;
        let noCredit = document.getElementById(
            'noCreditMode'
        ) as HTMLInputElement;
        let audit = document.getElementById('auditMode') as HTMLInputElement;
        setScanMode(event);
        if (event === 'box') {
            toast.dismiss(binToastId);
            toast.dismiss(trashToastId);
            toast.dismiss(noCreditToastId);
            toast.dismiss(auditToastId);
            dispatch(setScanModeReducer('box'));
            dispatch(setForceTrashMode(''));
            setForceToTrash('');
            setScanMode('box');
        }
        if (event === 'bin') {
            setbinMode(true);
            setScanMode(isBin ? 'bin' : 'box');
            dispatch(setScanModeReducer(isBin ? 'bin' : 'box'));
            binToastId = toast('You are scanning in Bin Mode.', {
                duration: 5000,
                position: 'top-center',
                // Styling
                style: {},
                className: 'binmode',
                // Custom Icon
                icon: '',
                // Change colors of success/error/loading icon
                iconTheme: {
                    primary: '#000',
                    secondary: '#fff',
                },
                // Aria
                ariaProps: {
                    role: 'status',
                    'aria-live': 'polite',
                },
            });
            document.body.style.backgroundColor = '#d0eebe';
        }
        if (event === 'audit_outbound') {
            if (!getScanModeList?.payload?.includes('audit_outbound')) {
                // const modesListString =
                //     (getScanModeList.payload ? getScanModeList.payload : '') +
                //     ',' +
                //     'outbound audit Mode';
                dispatch(setScanModeListReducer('outbound audit Mode'));
            }
            setauditMode(true);
            setbinMode(false);
            settrashMode(false);
            setnoCreditMode(false);
            //setOutboundIsBox(true);
            dispatch(setIsOutboundBox(true));
            setScanMode(isBin ? 'bin' : event);
            dispatch(setScanModeReducer(isBin ? 'bin' : event));
            auditToastId = toast(
                'You are scanning in Outbound Audit Mode. These scans will not be reported.',
                {
                    duration: 5000,
                    position: 'top-center',
                    // Styling
                    style: {},
                    className: 'auditmode',
                    // Custom Icon
                    icon: '',
                    // Change colors of success/error/loading icon
                    iconTheme: {
                        primary: '#000',
                        secondary: '#fff',
                    },
                    // Aria
                    ariaProps: {
                        role: 'status',
                        'aria-live': 'polite',
                    },
                }
            );
            document.body.style.backgroundColor = '#e3e4fa';
            trash.checked = false;
            noCredit.checked = false;
            bin.checked = false;
        }

        if (event === 'audit_inbound') {
            dispatch(setScanModeListReducer(''));
            if (!getScanModeList?.payload?.includes('audit_inbound')) {
                // const modesListString =
                //     (getScanModeList.payload ? getScanModeList.payload : '') +
                //     ',' +
                //     'inbound audit Mode';
                dispatch(setScanModeListReducer('inbound audit Mode'));
            }
            setScanMode(isBin ? 'bin' : event);
            dispatch(setScanModeReducer(isBin ? 'bin' : event));
            setShowModalType('inbound');
            setAddScreen(true);
            setIsFrom('audit_inbound');
            setauditMode(true);
            setbinMode(false);
            settrashMode(false);
            setnoCreditMode(false);
            //setOutboundIsBox(true);
            dispatch(setIsOutboundBox(true));
            trash.checked = false;
            noCredit.checked = false;
            bin.checked = false;
        }
        if (event === 'nocredit') {
            if (!getScanModeList?.payload?.includes('no credit')) {
                const modesListString =
                    (getScanModeList.payload ? getScanModeList.payload : '') +
                    ',' +
                    'no credit Mode';
                dispatch(setScanModeListReducer(modesListString));
            }
            setnoCreditMode(true);
            setScanMode(isBin ? 'bin' : event);
            dispatch(setScanModeReducer(isBin ? 'bin' : event));
            noCreditToastId = toast(
                'You are scanning in No Credit Mode. These scans will not be reported.',
                {
                    duration: 5000,
                    position: 'top-center',
                    // Styling
                    style: {},
                    className: 'nocreditmode',
                    // Custom Icon
                    icon: '',
                    // Change colors of success/error/loading icon
                    iconTheme: {
                        primary: '#000',
                        secondary: '#fff',
                    },
                    // Aria
                    ariaProps: {
                        role: 'status',
                        'aria-live': 'polite',
                    },
                }
            );
            document.body.style.backgroundColor = '#fcf4dc';
        }
        // if (trash.checked) {
        //   bin.checked = false;
        //   //trash.checked= true
        //   //  setbinMode(false)
        //   //  settrashMode(true)
        //   //console.log(binMode)
        // }
        // if (bin.checked) {
        //   //  settrashMode(false)
        //   //  setbinMode(true)
        //   trash.checked = false;
        //   bin.checked = true;
        //   //console.log(trashMode)
        // }
        // //setScanMode(event)
        // // console.log(event)
        //setFocus();
    };

    const handleCloseOutbound = async (value: any) => {
        //setting disable immediately after button click to avoid double clicks
        //setIsBoxClosed(true);
        dispatch(setIsBoxClosed(true));
        DialogObj.hide();
        if (getIsOutboundBox?.payload && getOutBoundBoxDetails?.payload?.id) {
            await OutBoundBoxUtil.processOutboundBoxCloseAction(
                scanSettings,
                [getOutBoundBoxDetails?.payload?.id],
                getOutBoundBoxDetails?.payload,
                resetFocusToUpc,
                loggedInUserId
            )
                .then((response: any) => {
                    if (response === 'true') {
                        getRecentScanHistory();
                    } else {
                        //setIsBoxClosed(false);
                        dispatch(setIsBoxClosed(false));
                    }
                })
                .catch(() => {
                    //setIsBoxClosed(false);
                    dispatch(setIsBoxClosed(false));
                });
        } else if (
            !getIsOutboundBox?.payload &&
            getOutBoundBinDetails?.payload?.id
        ) {
            await OutBoundBoxUtil.processOutBoundBinClosedAction(
                scanSettings,
                [getOutBoundBinDetails?.payload?.id],
                getOutBoundBinDetails?.payload,
                resetFocusToUpc,
                loggedInUserId
            )
                .then((response: any) => {
                    if (response === 'true') {
                        getRecentScanHistory();
                    } else {
                        //setIsBoxClosed(false);
                        dispatch(setIsBoxClosed(false));
                    }
                })
                .catch(() => {
                    // setIsBoxClosed(false);
                    dispatch(setIsBoxClosed(false));
                });
        }
    };

    const handleGetOutboundList = () => {
        setOutBoundBox([{}]);
        setManagementApprovalHeading('Close Outbound Box');
        setSettings(getLocalStorageItems('scanSettings'));
        const requestPayload = {
            scanGroup: scanSettings.scangroup,
            scanStation: scanSettings.scanStation,
            reclaimCenter: scanSettings.reclaimCenter,
        };
        const endpoint: any = getEndpoint(EndPoints.OUTBOUNDLIST);
        const result = getOutBoundList(endpoint, requestPayload);
        result.then((data: any) => {
            // console.log(data.data.data);
            setOutBoundBox(data.data.data);
            // addToast('Closed OutboundBox Successfully', { appearance: 'success' });
        });
        //setFocus();
    };

    const handleGetOutboundBinsList = () => {
        setOutBoundBox([{}]);
        setManagementApprovalHeading('Close Outbound Bin');
        setSettings(getLocalStorageItems('scanSettings'));
        const requestPayload = {
            scanGroup: scanSettings.scangroup,
            scanStation: scanSettings.scanStation,
            reclaimCenter: scanSettings.reclaimCenter,
        };
        const endpoint: any = getEndpoint(EndPoints.OUTBOUNDBINSLIST);
        const result = getOutBoundList(endpoint, requestPayload);
        result.then((data: any) => {
            // console.log(data.data.data);
            setOutBoundBox(data.data.data);
            // addToast('Closed OutboundBox Successfully', { appearance: 'success' });
        });
        //setFocus();
    };

    const updateSettings = useCallback((e) => {
        setSettings(getLocalStorageItems('scanSettings'));
        getScanSettings();
        getRecentScanHistory();
        getUserScanQty();
        // console.log(scanSettings);
        // let bin = document.getElementById('binMode') as HTMLInputElement;
        // bin.checked = false;
        // let nocredit = document.getElementById(
        //     'noCreditMode'
        // ) as HTMLInputElement;
        // nocredit.checked = false;
        let audit = document.getElementById('auditMode') as HTMLInputElement;
        audit.checked = false;
        // let trash = document.getElementById('trashMode') as HTMLInputElement;
        // trash.checked = false;
        // setbinMode(false);
        // settrashMode(false);
        // setnoCreditMode(false);
         setauditMode(false);
       // dispatch(setScanModeListReducer(''));
        // setIsBin(false);
        // setIsTrashMode(false);
        // setIsisNOCredit(false);
        document.body.style.backgroundColor = '#fff';
        resetFocusToUpc();
    }, []);

    const handleGetPalletList = () => {
        setSettings(getLocalStorageItems('scanSettings'));
        const requestPayload = {
            scanGroup: scanSettings.scangroup,
            scanStation: scanSettings.scanStation,
            reclaimCenter: scanSettings.reclaimCenter,
        };
        const endpoint: any = getEndpoint(EndPoints.PALLETLIST);
        const result = getPalletList(endpoint, requestPayload);
        result.then((data: any) => {
            console.log(data.data.data);
            setOutBoundBox(data.data.data);
            // addToast('Closed OutboundBox Successfully', { appearance: 'success' });
        });
        //setFocus();
    };

    const openPallets = () => {
        setShowModalType('outbound');
        setOutBoundBox([{}]);
        setManagementApprovalHeading('Close Pallet');
        handleGetPalletList();
        //setFocus();
    };

    const openAuditMode = () => {
        setManagementApprovalHeading('Audit Mode');
        //setFocus();
    };

    const updateUnlabeled = (e: any, txt: any, isFrom: any) => {
        updateUnlabeledWrapper(e, txt, isFrom, false, false);
    };

    const updateUnlabeledWrapper = (
        e: any,
        txt: any,
        isFrom: any,
        isInboundApproved: boolean,
        isOutboundApproved?: boolean
    ) => {
        let reasonCodeFTT: any;
        let subDispositionFTT: any;
        if (txt === 'Trash') {
            reasonCodeFTT = e;
            setUnlabelledDisposition(txt);
            setReasonCodeForForceTrash(e);
        } else {
            subDispositionFTT = e;
            setUnlabelledDisposition(txt);
            setUnlabelledSubDisposition(e);
        }
        if (!validQuantity()) return;
        let endpoint: any;
        setShowModalType('');
        if (
            getOpenInbounBoxDetails.payload &&
            getOpenInbounBoxDetails.payload?.data?.boxId
        ) {
            setinboundBoxDetails({
                inboundBoxId: getOpenInbounBoxDetails.payload.data.boxId,
                storeId: getOpenInbounBoxDetails.payload.data.storeId,
                storeLabel: getOpenInbounBoxDetails.payload.data.storeLabel,
                weekendDate: getOpenInbounBoxDetails.payload.data.weekendDate,
            });
            if (scanSettings && scanSettings.reclaimCenter) {
                const scanItemRequestBody = {
                    scanGroup: scanSettings.scangroup,
                    scanStation: scanSettings.scanStation,
                    reclaimCenter: scanSettings.reclaimCenter,
                    reclaimCenterState: scanSettings.reclaimCenterState,
                    inboundBoxId: getOpenInbounBoxDetails.payload.data.boxId,
                    storeId: getOpenInbounBoxDetails.payload.data.storeId,
                    storeLabel: getOpenInbounBoxDetails.payload.data.storeLabel,
                    weekendDate:
                        getOpenInbounBoxDetails.payload.data.weekendDate,
                    quantity: itemQty,
                    scanMode: isBin ? 'bin' : scanMode,
                    reasonCode: reasonCodeFTT
                        ? reasonCodeFTT
                        : reasonCodeForForceTrash,
                    disposition: txt,
                    subDisposition: subDispositionFTT,
                    outboundBoxMaxApproved: isOutboundApproved
                        ? isOutboundApproved
                        : getIsMaxOutboundBoxApproved.payload,
                    inboundBoxMaxApproved: isInboundApproved
                        ? isInboundApproved
                        : getIsMaxInboundBoxApproved.payload,
                    isBin: isBin ? isBin : binMode,
                    isTrashMode: isTrashMode ? isTrashMode : trashMode,
                    isNoCredit: isNoCredit ? isNoCredit : noCreditMode,
                    auditOutboundBoxId: outBoundBoxLabel
                        ? outBoundBoxLabel
                        : '',
                };
                if (isFrom === 'unlabeled') {
                    endpoint = unlabeledEndPoint(EndPoints.UNLABELED);
                } else if (isFrom === '13d') {
                    endpoint = unlabeledEndPoint(
                        EndPoints.THIRTEENDIGIT,
                        upcValue
                    );
                } else if (isFrom === 'NF') {
                    endpoint = unlabeledEndPoint(EndPoints.NOTFOUND, upcValue);
                }
                const result = handleGetItemsChange(
                    endpoint,
                    scanItemRequestBody
                );
            } else {
                notify('Please Set ReclaimCenter First', 'error');
            }
        } else {
            notify('Please OpenInbound box First', 'error');
        }
        //setFocus();
    };

    const updateScanItemForForceTrash = (e: any) => {
        let endpoint: any;
        if (getOpenInbounBoxDetails.payload !== undefined) {
            setinboundBoxDetails({
                inboundBoxId: getOpenInbounBoxDetails.payload.data.boxId,
                storeId: getOpenInbounBoxDetails.payload.data.storeId,
                storeLabel: getOpenInbounBoxDetails.payload.data.storeLabel,
                weekendDate: getOpenInbounBoxDetails.payload.data.weekendDate,
            });
            if (scanSettings && scanSettings.reclaimCenter) {
                const scanItemRequestBody = {
                    reasonCode: e,
                    disposition: 'trash',
                    subDisposition: isHazardous !== null ? e : '',
                };
                endpoint = getEndpoint(EndPoints.UPDATESCANITEM, scanItemId);
                const result = handleScanItems(endpoint, scanItemRequestBody)
                    .then((res: any) => {
                        setItemQty(1);
                        dispatch(setItemDetails(res.data));
                        if (
                            res.data.data.disposition === 'TRASH' ||
                            res.data.data.disposition === 'ALERT DESTROY' ||
                            res.data.data.disposition === 'ALERT DISPOSE'
                        ) {
                            // if (scanMode === "box") {
                            //   dispatch(
                            //     setOutboundBoxDetails({
                            //       currentQuantity: "",
                            //       createdDate: "",
                            //       outboundBoxId: "",
                            //       id: [],
                            //     })
                            //   );
                            // } else if (scanMode === "bin") {
                            //   dispatch(
                            //     setOutboundBinDetails({
                            //       currentQuantity: "",
                            //       createdDate: "",
                            //       binId: "",
                            //       id: [],
                            //     })
                            //   );
                            // }
                            const scanList = [...scannedItemsList];
                            let updatedItem = scanList.map((el: any) => {
                                if (el.id === res.data.data.id) {
                                    return {
                                        ...el,
                                        disposition: res.data.data.disposition,
                                        whse: res.data.data.warehouseLocation,
                                    };
                                }
                                return el;
                            });
                            setscannedItemsList(updatedItem);
                            dispatch(setScanSessionItemDetails(updatedItem));
                        } // else {
                        if (scanMode === 'box') {
                            dispatch(
                                setOutboundBoxDetails({
                                    currentQuantity:
                                        res.data.data.outboundBox
                                            .currentQuantity,
                                    createdDate:
                                        res.data.data.outboundBox.createdDate,
                                    outboundBoxId:
                                        res.data.data.outboundBox.outboundBoxId,
                                    id: res.data.data.outboundBox.id,
                                    lastModifiedDate:
                                        res.data.data.outboundBox
                                            .lastModifiedDate,
                                    palletLocation:
                                        res.data.data.outboundBox
                                            .palletLocation,
                                    itemId: res.data.data.id,
                                })
                            );
                            notify('Item forced to trash', 'error');
                        } else if (scanMode === 'bin') {
                            dispatch(
                                setOutboundBinDetails({
                                    currentQuantity:
                                        res.data.data.scanBin.currentQuantity,
                                    createdDate:
                                        res.data.data.scanBin.createdDate,
                                    binId: res.data.data.scanBin.binId,
                                    id: res.data.data.scanBin.id,
                                    itemId: res.data.data.id,
                                })
                            );
                        }
                        // }
                    })
                    .catch((err) => {
                        if (err.response.status === 500) {
                            notify('Invalid data', 'error');
                        } else {
                            notify(err.response.data.messageText, 'error');
                        }
                    });
            } else {
                notify('Please Set ReclaimCenter First', 'error');
            }
        } else {
            notify('Please OpenInbound box First', 'error');
        }
    };

    const handleForceToTrash = (e: any, mode?: any) => {
        if (mode === 'box') {
            setForceToTrash('');
            setReasonCodeForForceTrash(e);
            reasonCode = e;
            dispatch(setForceTrashMode(''));
            dispatch(setScanDone(false));
            if (scanMode !== 'trash') {
                toast('Scanning the current item in Box Mode.', {
                    duration: 5000,
                    position: 'top-center',
                    // Styling
                    style: {},
                    className: '',
                    // Custom Icon
                    icon: '',
                    // Change colors of success/error/loading icon
                    iconTheme: {
                        primary: '#000',
                        secondary: '#fff',
                    },
                    // Aria
                    ariaProps: {
                        role: 'status',
                        'aria-live': 'polite',
                    },
                });
            } else {
                if (isCaptureReasonCode) {
                    submitUpcData();
                }
            }
        } else {
            //updateScanItemForForceTrash(e);
            setForceToTrash('trash');
            setReasonCodeForForceTrash(e);
            reasonCode = e;
            dispatch(setForceTrashMode('trash'));
            dispatch(setScanDone(false));
            if (isTrashMode) {
                submitUpcData();
            } else if (mode === 'FTT') {
                toast('Scanning the current item in Force to Trash Mode.', {
                    duration: 5000,
                    position: 'top-center',
                    // Styling
                    style: {},
                    className: 'trashmode',
                    // Custom Icon
                    icon: '',
                    // Change colors of success/error/loading icon
                    iconTheme: {
                        primary: '#000',
                        secondary: '#fff',
                    },
                    // Aria
                    ariaProps: {
                        role: 'status',
                        'aria-live': 'polite',
                    },
                });
            } else {
                if (isCaptureReasonCode) {
                    submitUpcData();
                }
            }
        }
        resetFocusToUpc();
        // setAddScreen(false);
    };

    function confirmCloseBoxandBin() {
        // console.log('confirmCloseBoxandBin called');
        // console.log('bin value', isBin);
        DialogObj = DialogUtility.confirm({
            animationSettings: { effect: 'Zoom' },
            cancelButton: { text: 'Cancel', click: cancelClick },
            closeOnEscape: true,
            content: 'Are you sure you want to Close ?',
            okButton: { text: 'OK', click:  handleCloseOutbound},
            showCloseIcon: true,
            title: ' Confirmation',
            position: { X: 'center', Y: 'center' },
        });
        //setFocus();
    }

    const handleDeleteScanItems = (e: any) => {
        // setShowModalType("delete");
        // setAddScreen(true);
        resetFocusToUpc();
        console.log(e, 'delr');
        //setFocus();
        deletedItem = seletedDataToExport;
        setDeletedRecords(seletedDataToExport);
        if (deletedItem.length > 0) {
            if (deletedItem[0].isDeleted === 'Yes') {
                notify('The Item you selected, already deleted!', 'error');
            } else if (
                deletedItem[0].isBoxClosed === 'Yes' &&
                deletedItem[0].scanMode === 'bin'
            ) {
                notify('Outbound Bin is closed!', 'error');
            } else if (
                deletedItem[0].isBoxClosed === 'Yes' &&
                deletedItem[0].scanMode !== 'bin'
            ) {
                notify('Outbound Box is closed!', 'error');
            } else {
                buttonClick();
            }
        }
    };

    function cancelClick() {
        //Hide the dialog
        resetFocusToUpc();
        DialogObj.hide();
        //setFocus();
    }

    function buttonClick() {
        DialogObj = DialogUtility.confirm({
            animationSettings: { effect: 'Zoom' },
            cancelButton: { text: 'Cancel', click: cancelClick },
            closeOnEscape: true,
            content: 'Are you sure you want to delete ?',
            okButton: { text: 'OK', click: handleDeleteScanItemsApi },
            showCloseIcon: true,
            title: ' Confirmation',
            position: { X: 'center', Y: 'center' },
        });
        //setFocus();
    }

   

    const handleDeleteScanItemsApi = () => {
        let e: any = deletedItem;
        // console.log(deletedItem, 'del');
        let endpoint: any;
        let itemArray = [...getScanSessionItemDetails.payload]; // make a separate copy of the array
        let itemIndex = itemArray.findIndex((x) => x.id === e[0].id);
        let itemUPC = itemArray.find((x) => x.id === e[0].id);
        // console.log(itemUPC, 'upc');
        endpoint = getEndpoint(EndPoints.DELETEITEM, e[0].id);
        DialogObj.hide();
        const result = deleteItem(endpoint)
            .then((res: any) => {
                if (res) {
                    getRecentScanHistory();
                    getUserScanQty();
                    notify(res?.data?.message?.messageText, 'error');
                }
            })
            .catch((err: any) => {
                notify('Some thing went wrong, Please try again!', 'error');
                // console.error(err);
            });
        //setFocus();
    };
    const onlyNumberKey = (e: any) => {
        var code = e.which ? e.which : e.keyCode;
        if (code > 31 && (code < 48 || code > 57)) {
            e.preventDefault();
        }
        //setFocus();
    };

    //update current box qty

    const updateCurrentBoxQty = async (id: any) => {
        const endpoint: any = getEndpoint(EndPoints.SCANITEM, id);
        const scanItemRequestBody = {
            scanGroup: scanSettings.scangroup,
            scanStation: scanSettings.scanStation,
            reclaimCenter: scanSettings.reclaimCenter,
            inboundBoxId: getOpenInbounBoxDetails.payload.data.boxId,
            storeId: getOpenInbounBoxDetails.payload.data.storeId,
            storeLabel: getOpenInbounBoxDetails.payload.data.storeLabel,
            weekendDate: getOpenInbounBoxDetails.payload.data.weekendDate,
            quantity: 0,
            scanMode: isBin ? 'bin' : scanMode,
            auditOutboundBoxId: outBoundBoxLabel ? outBoundBoxLabel : '',
            disposition: forceToTrash ? forceToTrash : null,
            reasonCode: reasonCodeForForceTrash
                ? reasonCodeForForceTrash
                : null,
        };
        const result = axios
            .post(endpoint, scanItemRequestBody)
            .then((response: any) => {
                if (scanMode === 'box' || scanMode === 'nocredit') {
                    dispatch(
                        setOutboundBoxDetails({
                            currentQuantity:
                                response.data.data.outboundBox.currentQuantity,
                            createdDate:
                                response.data.data.outboundBox.createdDate,
                            outboundBoxId:
                                response.data.data.outboundBox.outboundBoxId,
                            id: response.data.data.outboundBox.id,
                            lastModifiedDate:
                                response.data.data.outboundBox.lastModifiedDate,
                            palletLocation:
                                response.data.data.outboundBox.palletLocation,
                            itemId: response.data.data.id,
                        })
                    );
                } else if (scanMode === 'bin') {
                    if (response.data.data.scanBin) {
                        dispatch(
                            setOutboundBinDetails({
                                currentQuantity:
                                    response.data.data.scanBin.currentQuantity,
                                createdDate:
                                    response.data.data.scanBin.createdDate,
                                binId: response.data.data.scanBin.binId,
                                id: response.data.data.scanBin.id,
                                itemId: response.data.data.id,
                            })
                        );
                    } else if (response.data.data.outboundBox) {
                        dispatch(
                            setOutboundBoxDetails({
                                currentQuantity:
                                    response.data.data.outboundBox
                                        .currentQuantity,
                                createdDate:
                                    response.data.data.outboundBox.createdDate,
                                outboundBoxId:
                                    response.data.data.outboundBox
                                        .outboundBoxId,
                                id: response.data.data.outboundBox.id,
                                itemId: response.data.data.id,
                            })
                        );
                    }
                }
            })
            .catch((error: any) => {
                console.error(error);
            });
    };

    //clear popup data
    const childRef = useRef<null>(null);

    function resetModal() {
        if (childRef?.current) {
            // console.log(childRef);
            const clear = childRef.current['clearForm'] as (params?: any) => {};
            clear();
        }
    }

    const resetScanMode = (txtFrom: any) => {
        // if (txtFrom === 'nocredit' || txtFrom === 'audit') {
        setScanMode('box');
        dispatch(setScanModeReducer('box'));
        let bin = document.getElementById('binMode') as HTMLInputElement;
        let trash = document.getElementById('trashMode') as HTMLInputElement;
        let noCredit = document.getElementById(
            'noCreditMode'
        ) as HTMLInputElement;
        let audit = document.getElementById('auditMode') as HTMLInputElement;
        bin.checked = false;
        trash.checked = false;
        noCredit.checked = false;
        audit.checked = false;
        //}
    };

    //clear popup data

    function compare(a: any, b: any) {
        if (a.id < b.id) {
            return -1;
        }
        if (a.id > b.id) {
            return 1;
        }
        return 0;
    }

    const handleItemQtyOnBlur = () => {
        if (itemQty === undefined || !itemQty) {
            setItemQty(1);
        }
        //   //setFocus();
    };
    const handleIncrement = (incrementedValue: number) => {
        const newValue = incrementedValue + itemQty;
        if (newValue > parseInt(itemMaxQty)) {
            notify(
                'Max scan quantity allowed is ' +
                    itemMaxQty +
                    ' Please correct it. ',
                'warning'
            );
        }
        setItemQty(newValue);
        resetFocusToUpc();
    };
    const handleDecrement = (decrementedValue: any) => {
        const newValue = itemQty - decrementedValue;
        if (newValue < 1) {
            notify('Scan quantity should not be below 1 ', 'warning');
            setItemQty(1);
        } else {
            setItemQty(newValue);
        }
        resetFocusToUpc();
    };

    const updateQuantityValue = (value: any) => {
        if (!value) {
            setItemQty(1);
        }
        setItemQty(parseInt(value));
        if (value > itemMaxQty) {
            notify(
                'Max scan quantity allowed is ' +
                    itemMaxQty +
                    ' Please correct it. ',
                'warning'
            );
        } else if (value < 1) {
            notify('Scan quantity should not be below 1 ', 'warning');
            setItemQty(1);
        }
        ////setFocus();
    };

    const onEnterButtonOnVirtualKeyBoard = () => {
        submitUpcData();
        //setFocus();
    };

    const handleOutboundMaxScanQty = (
        isInboundApproved: any,
        isOutboundApproved: any
    ) => {
        const scanItemRequestBody = {
            scanGroup: scanSettings.scangroup,
            scanStation: scanSettings.scanStation,
            reclaimCenter: scanSettings.reclaimCenter,
            reclaimCenterState: scanSettings.reclaimCenterState,
            inboundBoxId: getOpenInbounBoxDetails.payload.data.boxId,
            storeId: getOpenInbounBoxDetails.payload.data.storeId,
            storeLabel: getOpenInbounBoxDetails.payload.data.storeLabel,
            weekendDate: getOpenInbounBoxDetails.payload.data.weekendDate,
            quantity: itemQty,
            scanMode: isBin ? 'bin' : scanMode,
            auditOutboundBoxId: outBoundBoxLabel ? outBoundBoxLabel : '',
            disposition: forceToTrash ? forceToTrash : null,
            reasonCode: reasonCodeForForceTrash
                ? reasonCodeForForceTrash
                : null,
            outboundBoxMaxApproved: isOutboundApproved
                ? isOutboundApproved
                : getIsMaxOutboundBoxApproved.payload,
            inboundBoxMaxApproved: isInboundApproved
                ? isInboundApproved
                : getIsMaxInboundBoxApproved.payload,
            verifiedMultipack: verifiedMultipack ? verifiedMultipack : null,
            isBin: isBin ? isBin : binMode,
            isTrashMode: isTrashMode ? isTrashMode : trashMode,
            isNoCredit: isNoCredit ? isNoCredit : noCreditMode,
        };

        submitUpcData(scanItemRequestBody);
    };

    const handleInboundMaxScanQty = (
        isInboundApproved: any,
        isOutboundApproved: any
    ) => {
        const scanItemRequestBody = {
            scanGroup: scanSettings.scangroup,
            scanStation: scanSettings.scanStation,
            reclaimCenter: scanSettings.reclaimCenter,
            reclaimCenterState: scanSettings.reclaimCenterState,
            inboundBoxId: getOpenInbounBoxDetails.payload.data.boxId,
            storeId: getOpenInbounBoxDetails.payload.data.storeId,
            storeLabel: getOpenInbounBoxDetails.payload.data.storeLabel,
            weekendDate: getOpenInbounBoxDetails.payload.data.weekendDate,
            quantity: itemQty,
            scanMode: isBin ? 'bin' : scanMode,
            auditOutboundBoxId: outBoundBoxLabel ? outBoundBoxLabel : '',
            disposition: forceToTrash ? forceToTrash : null,
            reasonCode: reasonCodeForForceTrash
                ? reasonCodeForForceTrash
                : null,
            outboundBoxMaxApproved: isOutboundApproved
                ? isOutboundApproved
                : getIsMaxOutboundBoxApproved.payload,
            inboundBoxMaxApproved: isInboundApproved
                ? isInboundApproved
                : getIsMaxInboundBoxApproved.payload,
            verifiedMultipack: verifiedMultipack ? verifiedMultipack : null,
            isBin: isBin ? isBin : binMode,
            isTrashMode: isTrashMode ? isTrashMode : trashMode,
            isNoCredit: isNoCredit ? isNoCredit : noCreditMode,
        };
        submitUpcData(scanItemRequestBody);
    };

    const handleTrashModeSubmit = () => {
        setReasonCodeForForceTrash('');
        setShowModalType('forcetrash');
        setAddScreen(true);
    };

    const resetFocusToUpc = () => {
        upcInputFieldRef.current?.focus();
    };

    const resetFocusToUpcWithOpenInboundBox = () => {
        resetFocusToUpc();
        setShowModalType('');
        setIsFrom('');
    };

    const resetFocusToUpcWithLoadDelay = () => {
        setTimeout(() => {
            resetFocusToUpc();
        }, 100);
    };

    /* check input format function */
    const formatInput = (e: any) => {
        // Prevent characters that are not numbers ("e", ".", "+" & "-") ✨
        let checkIfNum;
        if (e.key !== undefined) {
            // Check if it's a "e", ".", "+" or "-"
            checkIfNum =
                e.key === 'e' ||
                e.key === '.' ||
                e.key === '+' ||
                e.key === '-';
        } else if (e.keyCode !== undefined) {
            // Check if it's a "e" (69), "." (190), "+" (187) or "-" (189)
            checkIfNum =
                e.keyCode === 69 ||
                e.keyCode === 190 ||
                e.keyCode === 187 ||
                e.keyCode === 189;
        }
        return checkIfNum && e.preventDefault();
    };
    return (
        <DashboardLayoutComponent
            id="defaultLayout"
            cellSpacing={[5, 5]}
            allowResizing={true}
            columns={5}
        >
            <div
                className="scan-container"
                data-row="0"
                data-col="0"
                data-sizex="5"
                data-sizey="1"
            >
                {/* <TabComponent heightAdjustMode="Auto" selected={handleTabSelection}>
          <TabItemsDirective>
            {tabConfig.map((tab) => {
              return <TabItemDirective key={tab} header={{ text: tab }} />;
            })}
          </TabItemsDirective>
        </TabComponent> */}
                <div className="scan-controls">
                    <div className="row">
                        <div className="col-sm-12 scandiv">
                            <div className="col-sm-1">
                                <p className="tab-title">Scan</p>
                            </div>
                            <div className="col-sm-4">
                                <div className="col-sm-12 goal-progress no-padding">
                                    <div className="col-sm-7 no-padding">
                                        <p>
                                            {/* Goal{' '} */}
                                            User's Scan Qty :
                                            {getScanQtyByUser.payload
                                                ? getScanQtyByUser.payload
                                                : 0}
                                            <span className="items">
                                                {/* 650 Items */}
                                            </span>
                                        </p>
                                    </div>
                                    <div className="col-sm-5 pt-1 pl-0 pr-0">
                                        <input
                                            type="checkbox"
                                            className="toggle"
                                            checked={binMode}
                                            id="binMode"
                                            onChange={(e: any) =>
                                                handleBin(e, 'bin')
                                            }
                                        />
                                        <label htmlFor="binMode">
                                            BIN MODE
                                        </label>
                                    </div>
                                    {/* <div className="col-sm-3">
                                        <div className="qty-css"> */}

                                    {/* <div className="progress scan-progress">
                                                <div className="progress-wrapper">
                                                    <div
                                                        className="progress-inner"
                                                        style={{
                                                            width: `${userScanQty}%`,
                                                        }}
                                                    ></div>
                                                </div>
                                            </div> */}
                                    {/* <div className="progress-info">
                                                {userScanQty ? userScanQty : 0}
                                            </div> */}
                                    {/* </div>
                                    </div>*/}
                                </div>
                            </div>
                            <div className="col-sm-7 p-0">
                                <div className="col-sm-3 pt-1 pr-0">
                                    <input
                                        type="checkbox"
                                        className="toggle"
                                        id="trashMode"
                                        checked={trashMode}
                                        onChange={(e: any) => {
                                            handleTrash(e, 'trash');
                                        }}
                                    />
                                    <label htmlFor="trashMode">
                                        TRASH MODE
                                    </label>
                                </div>

                                <div className="col-sm-3 pt-1 pl-0 pr-0">
                                    <input
                                        type="checkbox"
                                        checked={noCreditMode}
                                        id="noCreditMode"
                                        className="toggle"
                                        onChange={(e: any) =>
                                            handleNoCredit(e, 'nocredit')
                                        }
                                    />
                                    <label htmlFor="noCreditMode">
                                        NO CREDIT MODE
                                    </label>
                                </div>
                                <div className="col-sm-3 pt-1 auditModediv">
                                    <input
                                        type="checkbox"
                                        checked={auditMode}
                                        id="auditMode"
                                        className="toggle"
                                        onChange={(e: any) =>
                                            handleAudit(e, 'audit')
                                        }
                                    />
                                    <label htmlFor="auditMode">
                                        AUDIT MODE
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-12 upcdiv">
                            <div className="col-sm-6 pr-0">
                                <div className="col-sm-5">
                                    <div className="txtUPC1">
                                        <InputField
                                            type="number"
                                            className="txtUPC"
                                            id="upcValue"
                                            placeholder="UPC"
                                            onKeyUp={handleUPCOnChange}
                                            onKeyDown={formatInput}
                                            onChange={setUPCValue}
                                            initialValue={upcValue}
                                            autoFocus={true}
                                            onEnter={
                                                onEnterButtonOnVirtualKeyBoard
                                            }
                                            showKeyboardIcon={true}
                                            ref={upcInputFieldRef}
                                        />
                                    </div>
                                </div>
                                <div className="col-sm-7 btnUPCbody">
                                    <div className="col-sm-2 btnUPCfirstdiv">
                                        <button
                                            className="btn btn-primary btnUPC"
                                            onClick={(e) => handleDecrement(10)}
                                        >
                                            <img
                                                src={downicon}
                                                className="imgToparrow"
                                            />
                                            10
                                        </button>
                                    </div>
                                    <div className="col-sm-2 btnUPCMinusdiv">
                                        <button
                                            className="btn btn-primary btnUPCMinus"
                                            onClick={(e) => handleDecrement(1)}
                                        >
                                            <img
                                                alt=""
                                                src={minusicon}
                                                className="imgMinus"
                                            />
                                        </button>
                                    </div>
                                    <div className="col-sm-3 qtydiv">
                                        <InputField
                                            type="number"
                                            onChange={updateQuantityValue}
                                            onBlur={handleItemQtyOnBlur}
                                            id="quantity"
                                            className="form-control txtUPCNum"
                                            initialValue={itemQty}
                                            selectTextOnFocus={true}
                                            onEnter={
                                                resetFocusToUpcWithLoadDelay
                                            }
                                            onClose={
                                                resetFocusToUpcWithLoadDelay
                                            }
                                        />
                                    </div>
                                    <div className="col-sm-2 btnUPCPlusdiv">
                                        <button
                                            className="btn btn-primary btnUPCPlus"
                                            onClick={(e) => handleIncrement(1)}
                                        >
                                            <img
                                                src={plusicon}
                                                className="imgPlus"
                                            />
                                        </button>
                                    </div>
                                    <div className="col-sm-2 btnUPCdiv">
                                        <button
                                            className="btn btn-primary btnUPC"
                                            onClick={(e) => handleIncrement(10)}
                                        >
                                            <img
                                                alt=""
                                                src={upicon}
                                                className="imgToparrow"
                                            />
                                            10
                                        </button>
                                    </div>
                                </div>

                                <div className="col-sm-12">
                                    <ScannedItemPanel />
                                </div>
                            </div>
                            <div className="col-sm-6 divBox">
                                <div className="col-sm-3">
                                    <LLModal
                                        handleClose={() => {
                                            resetModal();
                                            setAddScreen(false);
                                            resetFocusToUpc();
                                            setShowModalType('');
                                        }}
                                        show={addScreen}
                                        width={800}
                                        className={
                                            showModalType === 'inbound'
                                                ? 'inbound-modal'
                                                : ''
                                        }
                                    >
                                        {showModalType === 'inbound' ? (
                                            <OpenInboundBox
                                                ref={childRef}
                                                setAddScreen={setAddScreen}
                                                openUnderReview={
                                                    handleStoresUnderReview
                                                }
                                                resetFocusToUpc={
                                                    resetFocusToUpcWithOpenInboundBox
                                                }
                                                isFrom={isFrom}
                                                setScanMode={handleScanModes}
                                                resetScanMode={resetScanMode}
                                                updateSettings={updateSettings}
                                            />
                                        ) : showModalType === 'unlabeled' ||
                                          showModalType === '13d' ||
                                          showModalType === 'NF' ? (
                                            <UnlabeledItemsModal
                                                setAddScreen={setAddScreen}
                                                isFrom={unlabeledIsFrom}
                                                heading={unlabeled}
                                                updateUnlabeled={
                                                    updateUnlabeled
                                                }
                                                reasonCodeList={reasonCodeList}
                                                resetFocusToUpc={
                                                    resetFocusToUpc
                                                }
                                                setunlabeledIsFrom={
                                                    setunlabeledIsFrom
                                                }
                                                isTrashMode={
                                                    isTrashMode
                                                        ? isTrashMode
                                                        : trashMode
                                                }
                                            />
                                        ) : showModalType === 'outbound' ? (
                                            <ClosePalletAndOutBound
                                                setAddScreen={setAddScreen}
                                                heading={
                                                    managementApprovalHeading
                                                }
                                                key1={
                                                    !getIsOutboundBox?.payload
                                                        ? {
                                                              header: 'BIN ID',
                                                              field: 'binId',
                                                          }
                                                        : {
                                                              header: 'BOX ID',
                                                              field: 'outboundBoxId',
                                                          }
                                                }
                                                key2={{
                                                    header: 'WAREHOUSE LOCATION',
                                                    field: 'warehouseLocation',
                                                }}
                                                value={outboundBox}
                                                isFrom={
                                                    !getIsOutboundBox?.payload
                                                        ? 'outboundbinlist'
                                                        : 'outboundboxlist'
                                                }
                                                resetFocusToUpc={
                                                    resetFocusToUpc
                                                }
                                                reset={() => {
                                                    setShowModalType('');
                                                }}
                                            />
                                        ) : showModalType ===
                                          'InboundMaxQty' ? (
                                            <ManagementApprovalModal
                                                ref={childRef}
                                                heading={`Inbound scan quantity for this inbound box
                        ${
                            getOpenInbounBoxDetails.payload.data.boxId
                                ? getOpenInbounBoxDetails.payload.data.boxId
                                : ''
                        }
                            exceeds ${inboundMaxScan}`}
                                                setAddScreen={setAddScreen}
                                                setIsisNOCredit={setIsisNOCredit}
                                                isFrom={
                                                    unlabeledIsFrom ===
                                                        'unlabeled' ||
                                                    unlabeledIsFrom === '13d' ||
                                                    unlabeledIsFrom === 'NF'
                                                        ? unlabeledIsFrom
                                                        : 'InboundMaxQty'
                                                }
                                                unlabelledDisposition={
                                                    unlabelledDisposition
                                                }
                                                unlabelledSubDisposition={
                                                    unlabelledSubDisposition
                                                }
                                                updateUnlabeled={
                                                    updateUnlabeledWrapper
                                                }
                                                handleInboundMaxScanQty={
                                                    handleInboundMaxScanQty
                                                }
                                                resetFocusToUpc={
                                                    resetFocusToUpc
                                                }
                                                isFromInboundMaxQty={true}
                                            />
                                        ) : showModalType ===
                                          'OutboundMaxQty' ? (
                                            <ManagementApprovalModal
                                                ref={childRef}
                                                heading={`Outbound scan quantity for this Outbound box
                            ${
                                getOutBoundBoxDetails?.payload?.outboundBoxId
                                    ? getOutBoundBoxDetails?.payload
                                          ?.outboundBoxId
                                    : ''
                            }
                            exceeds ${outboundMaxScan}`}
                                                setAddScreen={setAddScreen}
                                                setIsisNOCredit={setIsisNOCredit}
                                                isFrom={
                                                    unlabeledIsFrom ===
                                                        'unlabeled' ||
                                                    unlabeledIsFrom === '13d' ||
                                                    unlabeledIsFrom === 'NF'
                                                        ? unlabeledIsFrom
                                                        : 'OutboundMaxQty'
                                                }
                                                unlabelledDisposition={
                                                    unlabelledDisposition
                                                }
                                                unlabelledSubDisposition={
                                                    unlabelledSubDisposition
                                                }
                                                updateUnlabeled={
                                                    updateUnlabeledWrapper
                                                }
                                                handleOutboundMaxScanQty={
                                                    handleOutboundMaxScanQty
                                                }
                                                resetFocusToUpc={
                                                    resetFocusToUpc
                                                }
                                                isFromOutboundMaxQty={true}
                                            />
                                        ) : showModalType === 'pallets' ? (
                                            <ManagementApprovalModal
                                                ref={childRef}
                                                openPallets={openPallets}
                                                setAddScreen={setAddScreen}
                                                setIsisNOCredit={setIsisNOCredit}
                                                isFrom={'pallets'}
                                                heading="Close Pallet"
                                                resetFocusToUpc={
                                                    resetFocusToUpc
                                                }
                                            />
                                        ) : showModalType === 'underreview' ? (
                                            <ManagementApprovalModal
                                                ref={childRef}
                                                setAddScreen={setAddScreen}
                                                setIsisNOCredit={setIsisNOCredit}
                                                isFrom={'underreview'}
                                                heading="Store Under Review"
                                                underReviewObj={
                                                    underReviewObject
                                                }
                                                openInboundBoxAfterApproval={
                                                    openInboundBoxAfterApproval
                                                }
                                                resetFocusToUpc={
                                                    resetFocusToUpc
                                                }
                                            />
                                        ) : showModalType === 'forcetrash' ? (
                                            <ForceToTrashModal
                                                setAddScreen={setAddScreen}
                                                clearModal={
                                                    handleRestForceToTrashModal
                                                }
                                                handleForceToTrash={
                                                    handleForceToTrash
                                                }
                                                isCaptureReasonCode={
                                                    isCaptureReasonCode
                                                }
                                                reasonCodeList={reasonCodeList}
                                                resetFocusToUpc={
                                                    resetFocusToUpc
                                                }
                                            />
                                        ) : //: showModalType === "forcetrash" &&
                                        //   isHazardous === true ? (
                                        //   <HazardousModal
                                        //     setAddScreen={setAddScreen}
                                        //     clearModal={resetForm}
                                        //     handleForceToTrash={handleForceToTrash}
                                        //     isFrom="frocetrashmain"
                                        //   />
                                        // )
                                        showModalType === 'bin' ? (
                                            <BinModeWarning
                                                ref={childRef}
                                                setIsBin={setIsBin}
                                                setAddScreen={setAddScreen}
                                                setScanMode={handleScanModes}
                                                updateSettings={updateSettings}
                                                resetFocusToUpc={
                                                    resetFocusToUpc
                                                }
                                                resetScanMode={resetScanMode}
                                            />
                                        ) : showModalType === 'nocredit' ? (
                                            <ManagementApprovalModal
                                                ref={childRef}
                                                setAddScreen={setAddScreen}
                                                setIsisNOCredit={setIsisNOCredit}
                                                setScanMode={handleScanModes}
                                                isFrom={'nocredit'}
                                                heading="No Credit Mode"
                                                updateSettings={updateSettings}
                                                resetFocusToUpc={
                                                    resetFocusToUpc
                                                }
                                                resetScanMode={resetScanMode}
                                            />
                                        ) : showModalType === 'audit' ? (
                                            <ManagementApprovalModal
                                                ref={childRef}
                                                openAuditMode={openAuditMode}
                                                setAddScreen={setAddScreen}
                                                setIsisNOCredit={setIsisNOCredit}
                                                setScanMode={handleScanModes}
                                                isFrom={'audit'}
                                                heading="Audit Mode"
                                                updateSettings={updateSettings}
                                                outBoundBoxLabel={
                                                    setOutBoundBoxLabel
                                                }
                                                resetFocusToUpc={
                                                    resetFocusToUpc
                                                }
                                                resetScanMode={resetScanMode}
                                            />
                                        ) : showModalType === 'unitfactor' ? (
                                            <UnitFactor
                                                setAddScreen={setAddScreen}
                                                heading="Multi-Pack Item"
                                                updateUnitFactor={
                                                    updateUnitFactor
                                                }
                                                ref={childRef}
                                                resetFocusToUpc={
                                                    resetFocusToUpc
                                                }
                                            />
                                        ) : showModalType === 'scansettings' ? (
                                            <ManagementApprovalModal
                                                ref={childRef}
                                                isFrom="scansettings"
                                                setAddScreen={setAddScreen}
                                                setIsisNOCredit={setIsisNOCredit}
                                                heading="Change Scan Location"
                                                updateSettings={updateSettings}
                                                resetFocusToUpc={
                                                    resetFocusToUpc
                                                }
                                            />
                                        ) : (
                                            ''
                                        )}
                                    </LLModal>
                                    <button
                                        className="btn btn-primary inbound-box"
                                        onClick={(e) =>
                                            handleRenderModal('inbound')
                                        }
                                    >
                                        Open <br />
                                        Inbound
                                        <br />
                                        Box
                                    </button>
                                </div>
                                <div className="col-sm-3 inbounddiv">
                                    <button
                                        disabled={
                                            (!getItemDetails?.payload?.data
                                                ?.scanBin &&
                                                !getItemDetails?.payload?.data
                                                    ?.outboundBox) ||
                                            getItemDetails?.payload?.data
                                                ?.disposition === 'TRASH' ||
                                            getIsBoxClosed?.payload === true ||
                                            (!getIsOutboundBox?.payload &&
                                                getCanCloseBin?.payload ===
                                                    false)
                                        }
                                        className="btn btn-primary inbound-box"
                                        onClick={confirmCloseBoxandBin}
                                    >
                                        Close
                                        <br /> Outbound
                                        <br />
                                        {!getIsOutboundBox?.payload
                                            ? 'Bin'
                                            : 'Box'}
                                    </button>
                                </div>
                                <div className="col-sm-3">
                                    <button
                                        disabled={
                                            getItemDetails?.payload?.data
                                                ?.disposition === 'TRASH' ||
                                            (!getIsOutboundBox?.payload &&
                                                getCanCloseBin?.payload ===
                                                    false)
                                        }
                                        className="btn btn-primary inbound-box"
                                        onClick={(e) =>
                                            handleRenderModal('outbound')
                                        }
                                    >
                                        Close <br />
                                        Multiple
                                        <br /> Outbound <br />
                                        {!getIsOutboundBox?.payload
                                            ? 'Bins'
                                            : 'Boxes'}
                                    </button>
                                </div>
                                <div className="col-sm-3">
                                    <button
                                        className="btn btn-primary inbound-box"
                                        onClick={(e) =>
                                            handleRenderModal('unlabeled')
                                        }
                                    >
                                        Unlabeled <br />
                                        Items
                                    </button>
                                </div>
                                <div className="col-sm-6 btnclose">
                                    <ScanContentBox
                                        heading={'Inbound Box'}
                                        isSettings={false}
                                        key1={'Client Group'}
                                        key2={'Store'}
                                        key3={'Inbound Box'}
                                        value1={
                                            getOpenInbounBoxDetails?.payload
                                                ?.data
                                                ? '(' +
                                                  getOpenInbounBoxDetails
                                                      ?.payload?.data
                                                      ?.customerGroup +
                                                  ')' +
                                                  getOpenInbounBoxDetails
                                                      ?.payload?.data
                                                      ?.customerGroupName
                                                : ''
                                        }
                                        value2={
                                            getOpenInbounBoxDetails?.payload
                                                ? getOpenInbounBoxDetails
                                                      ?.payload?.data?.storeId
                                                : ''
                                        }
                                        value3={
                                            getOpenInbounBoxDetails?.payload
                                                ? getOpenInbounBoxDetails
                                                      ?.payload?.data?.boxId
                                                : ''
                                        }
                                    />
                                    <ScanContentBox
                                        heading={
                                            getIsOutboundBox?.payload === false
                                                ? 'Outbound Bin'
                                                : 'Outbound Box'
                                        }
                                        isSettings={false}
                                        key1={
                                            getIsOutboundBox?.payload === false
                                                ? 'CurrentBin Qty'
                                                : 'CurrentBox Qty'
                                        }
                                        key2={
                                            getIsOutboundBox?.payload === false
                                                ? 'Outbound Bin'
                                                : 'Outbound Box'
                                        }
                                        key3={'Created Date'}
                                        value1={
                                            getIsOutboundBox?.payload === false
                                                ? getOutBoundBinDetails?.payload
                                                      ?.currentQuantity
                                                : getIsOutboundBox?.payload ===
                                                  true
                                                ? getOutBoundBoxDetails?.payload
                                                      ?.currentQuantity
                                                : ''
                                        }
                                        value2={
                                            getIsOutboundBox?.payload === false
                                                ? getOutBoundBinDetails?.payload
                                                      ?.binId
                                                : getIsOutboundBox?.payload ===
                                                  true
                                                ? getOutBoundBoxDetails?.payload
                                                      ?.outboundBoxId
                                                : ''
                                        }
                                        value3={
                                            getIsOutboundBox?.payload === false
                                                ? getOutBoundBinDetails?.payload
                                                      ?.createdDate
                                                    ? moment(
                                                          getOutBoundBinDetails
                                                              ?.payload
                                                              ?.createdDate
                                                      ).format('ll')
                                                    : ''
                                                : getIsOutboundBox?.payload ===
                                                  true
                                                ? getOutBoundBoxDetails?.payload
                                                      ?.createdDate
                                                    ? moment(
                                                          getOutBoundBoxDetails
                                                              ?.payload
                                                              ?.createdDate
                                                      ).format('ll')
                                                    : ''
                                                : ''
                                        }
                                    />
                                </div>
                                <div className="col-sm-6 mt-2rem btnCloseForce">
                                    <div className="col-sm-6 pl-0 pr-3">
                                        <button
                                            className="btn btn-primary inbound-box"
                                            onClick={(e) =>
                                                handleRenderModal('pallets')
                                            }
                                            disabled={
                                                getItemDetails?.payload?.data
                                                    ?.disposition === 'TRASH'
                                            }
                                        >
                                            Close <br />
                                            Pallet
                                        </button>
                                    </div>
                                    <div className="col-sm-6">
                                        <button
                                            style={{
                                                boxShadow:
                                                    forceToTrash === 'trash'
                                                        ? '0px 3px 2px 2px #e69191'
                                                        : '',
                                            }}
                                            disabled={
                                                isTrashMode
                                                    ? isTrashMode
                                                    : trashMode
                                            }
                                            className="btn btn-danger inbound-box trash-button"
                                            onClick={(e) =>
                                                handleRenderModal('forcetrash')
                                            }
                                        >
                                            Force To
                                            <br /> Trash
                                        </button>
                                    </div>
                                    <div className="scanBox">
                                        <ScanContentBox
                                            isSettings={true}
                                            heading={'Scan Location'}
                                            key1={'Reclaim Center'}
                                            key2={'Scan Group'}
                                            key3={'Scan Station'}
                                            value1={scanSettings.reclaimCenter}
                                            value2={scanSettings.scangroup}
                                            value3={scanSettings.scanStation}
                                        />
                                        <span
                                            className="scan-settings-icon"
                                            onClick={(e) =>
                                                handleRenderModal(
                                                    'scansettings'
                                                )
                                            }
                                        >
                                            <img
                                                src={scanstation}
                                                className="imgsetting"
                                            />
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="scan-controls-table">
                    <div
                        className="col-sm-12 col-md-12 col-lg-12 child-data-section scan-data-table"
                        id="sd"
                    >
                        <GridWrapper
                            config={scanSessionItems}
                            data={generateHisoryGrid(
                                'getHistory',
                                getScanSessionItemDetails.payload
                            )}
                            width={'100%'}
                            height={'400'}
                            onDelete={handleDeleteScanItems}
                            hideSpinner={true}
                            allowFilters={false}
                            allowSorting={false}
                        />
                    </div>
                </div>
            </div>
        </DashboardLayoutComponent>
    );
};

export default ScanScreen;
