import { FC, useEffect, useRef } from "react";
import {
  Column,
  ColumnDirective,
  ColumnsDirective,
  CommandColumn,
  Filter,
  FilterSettingsModel,
  Grid,
  GridComponent,
  Inject,
  Page,
  QueryCellInfoEventArgs,
  SelectionSettingsModel,
} from "@syncfusion/ej2-react-grids";
import { useDispatch, useSelector } from "react-redux";
import { getDefaultFilterOperator, pageOptions } from "./gridConfig";
import { getValue, Internationalization } from "@syncfusion/ej2-base";
import "./Grid.scss";
import { RootState } from "../../redux/store";
import { setFpiClearGridRow } from "../invoices/invoiceReducer";

const FilterOptions: FilterSettingsModel = {
  columns: [],
  type: "FilterBar",
  mode: "Immediate",
  showFilterBarStatus: true,
  immediateModeDelay: 10,
  operators: {},
  showFilterBarOperator: true,
  ignoreAccent: true,
};

interface IGridWrapper {
  config: any[];
  data: any[];
  width?: string;
  onRowSelect?: (selectedRecords: any) => void;
  onFilter?: (text: string) => void;
}
// @ts-ignore
const SingleGrid: FC<IGridWrapper> = ({
  config,
  data,
  onRowSelect,
  width = "",
  onFilter,
}) => {
  console.log(data);
  const isFPIClear = useSelector(
    (store: RootState) => store.invoiceReducer.fpiClearGridRow
  );
  let intl = new Internationalization();
  let nFormatter = intl.getNumberFormat({
    skeleton: "C3",
    currency: "USD",
    minimumIntegerDigits: 0,
    minimumFractionDigits: 2,
  });
  // @ts-ignore
  let gridRef: Grid | null = useRef(null);
  const dispatch = useDispatch();
  useEffect(() => {
    if (gridRef) {
      if (isFPIClear) {
        gridRef.refresh();
        dispatch(setFpiClearGridRow(false));
      }
    }
  }, [isFPIClear]);
  const handleSelectedRow = (event: any) => {
    if (gridRef) {
      const selectedrecords: object[] = gridRef.getSelectedRecords();
      //dispatch(setRowData(selectedrecords));
      //dispatch(setRowId(event.data));
      onRowSelect && onRowSelect(event.data);
      // dispatch(setFixedPriceList(event.data));
      // return false;
    }
  };
  const actionCompleteByUser = (args: any) => {
    onFilter && onFilter(args);
  };
  const selectionOptions: SelectionSettingsModel = {
    //checkboxMode: "ResetOnRowClick",
    mode: "Row",
    type: "Single",
  };
  const customizeCell = (args: QueryCellInfoEventArgs) => {
    if ((args.column as Column).field === "price" && args.data && args.cell) {
      args.cell.innerHTML = `$${nFormatter(getValue("price", args.data))}`;
    }
  };

  return (
    <GridComponent
      ref={(g) => (gridRef = g)}
      dataSource={data}
      queryCellInfo={customizeCell}
      width={width}
      height={380}
      // gridLines="Both"
      allowPaging={
        pageOptions.pageSize && data.length > pageOptions.pageSize
          ? true
          : false
      }
      allowFiltering={true}
      //selectionSettings={selectionOptions}
      filterSettings={FilterOptions}
      actionComplete={actionCompleteByUser}
      pageSettings={pageOptions}
      rowSelected={(event) => handleSelectedRow(event)}
    >
      <ColumnsDirective>
        {config.map((col: any) => {
          return (
            <ColumnDirective
              filter={getDefaultFilterOperator(col.dataType)}
              key={col.field}
              field={col.field}
              headerText={col.header}
              width={col.width ? col.width : "100"}
              textAlign={col?.textAlign}
            />
          );
        })}
      </ColumnsDirective>

      <Inject services={[Page, Filter, CommandColumn]} />
    </GridComponent>
  );
};
export default SingleGrid;
