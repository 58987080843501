import "./AddMastersScreen.scss";
//import "@syncfusion/ej2-base/styles/material.css";
import "@syncfusion/ej2-buttons/styles/material.css";
import LLModal from "../common/llModal/LLModal";
import CarrierAddScreen from "./CarrierAddScreen";
import CustomerAddScreen from "./CustomerAddScreen";
import CompanyCodeAddScreen from "./CompanyCodeAddScreen";
import ReclaimCenterAddScreen from "./ReclaimCenterAddScreen";
import WarehouseAddScreen from "./WarehouseAddScreen";
import DepartmentAddScreen from "./DepartmentAddScreen";
import GLAccountAddScreen from "./GLAccountAddScreen";
import CommodityTypeAddScreen from "./CommodityTypeAddScreen";
import PalletLocationAddScreen from "./PalletLocationAddScreen";
import RuleExceptionAddScreen from "./RuleExceptionAddScreen";
import VendorAddScreen from "./VendorAddScreen";
import FiscalYearAddScreen from "./FiscalYearAddScreen";
import StoreAddScreen from "./StoreAddScreen";
import StoreDispositionsAddScreen from "./StoreDispositionsAddScreen";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { ContactType } from "./constants";
import BinExceptionAddScreen from "./BinExceptionAddScreen";

const AddMastersScreen = (props: any) => {
  const dispatch = useDispatch();
  const [tabNameFinal, setTabNameFinal] = useState("");
  const { addScreen, setAddScreen, tabName } = props;
  useEffect(() => {
    const res = tabName?.charAt(tabName.length - 1);
    if (res === "s") {
      setTabNameFinal(tabName?.slice(0, -1));
    } else {
      setTabNameFinal(tabName);
    }
  });

  return (
    <>
      {/* // <div className="contact-screen"> */}
      <LLModal handleClose={() => setAddScreen(false)} show={addScreen} width={800}>
        <div className="row ml-0 mr-0 mb-30 modal-title-header">
          {tabNameFinal == ContactType.FISCAL_YEAR_SETUP ? (
            <span className="tabNameForAddScreen">
              Add Fiscal Year Calendar
            </span>
          ) : (
            <span className="tabNameForAddScreen">Add {tabNameFinal}</span>
          )}
        </div>
        {tabName === ContactType.CARRIERS ? (
          <CarrierAddScreen setAddScreen={setAddScreen} />
        ) : tabName === ContactType.CUSTOMERS ? (
          <CustomerAddScreen setAddScreen={setAddScreen} />
        ) : tabName === ContactType.COMPANY_CODES ? (
          <CompanyCodeAddScreen setAddScreen={setAddScreen} />
        ) : tabName === ContactType.RECLAIM_CENTERS ? (
          <ReclaimCenterAddScreen setAddScreen={setAddScreen} />
        ) : tabName === ContactType.WAREHOUSES ? (
          <WarehouseAddScreen setAddScreen={setAddScreen} />
        ) : tabName === ContactType.DEPARTMENTS ? (
          <DepartmentAddScreen setAddScreen={setAddScreen} />
        ) : tabName === ContactType.GL_ACCOUNTS ? (
          <GLAccountAddScreen setAddScreen={setAddScreen} />
        ) : tabName === ContactType.COMMODITY_TYPES ? (
          <CommodityTypeAddScreen setAddScreen={setAddScreen} />
        ) : tabName === ContactType.PALLET_LOCATIONS ? (
          <PalletLocationAddScreen setAddScreen={setAddScreen} />
        ) : tabName === ContactType.RULE_EXCEPTIONS ? (
          <RuleExceptionAddScreen setAddScreen={setAddScreen} />
        ) : tabName === ContactType.STORES ? (
          <StoreAddScreen setAddScreen={setAddScreen} />
        ) : tabName === ContactType.VENDORS ? (
          <VendorAddScreen setAddScreen={setAddScreen} />
        ) : tabName === ContactType.FISCAL_YEAR_SETUP ? (
          <FiscalYearAddScreen setAddScreen={setAddScreen} />
        ) : tabName === ContactType.STORE_DISPOSITIONS ? (
          <StoreDispositionsAddScreen setAddScreen={setAddScreen} />
        ) : tabName === ContactType.BIN_EXCEPTIONS ? (
          <BinExceptionAddScreen setAddScreen={setAddScreen} />
        ) : (
          ""
        )}
      </LLModal>
      {/* // </div> */}
    </>
  );
};

export default AddMastersScreen;
