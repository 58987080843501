import { environment } from "../../environment";

const baseURL = environment.react_app_url;
const clientContext = environment.react_app_client_context;

const eagelUrl = "https://eagle.arkatiss.com/revlog";

/**
 * Modal Mapping and Data binding
 */
export const generateGridData = (tabName: string, data: any) => {
  console.log(data)
  switch (tabName) {
    case "Users": {
      return data.map((row: any) => ({
        id: parseInt(row.id),
        userId: row?.userId,
        username: row?.username,
        userType: row?.userType,
        firstname: row?.firstname,
        lastName: row?.lastname,
        fullName: row?.firstname + " " + (row?.lastname ? row?.lastname : ''),
        email: row?.email,
        password: row?.password,
        company: row?.company,
        jobTitle: row?.jobTitle,
        language: row?.language,
        reclaimCenterId: row?.reclaimCenterId,
        locationName: row?.reclaimCenter?.reclaimcenterName,
        roleId: row?.roleId,
        roleName: row?.roles?.roleName,
        phoneNumber: row?.phoneNumber,
        fax: row?.fax,
        addressId: row?.addressId,
        address: row?.address,
        timezone: row?.timezone,
        countryId: row?.countryId,
        countryName: row?.country?.countryName,
        countryCode: row?.country,
        endDate: row?.endDate,
        stateId: row?.stateId,
        state: row?.state,
        city: row?.city,
        street: row?.street,
        scanUser: row?.scanUser,
        zip: row?.zip,
        activeForGrid: row?.status,
        active: row?.active,
        tabName: "Users",
        storeId: row?.storeId,
        store: row?.store,
        image: row?.image,
        
      }));
    }
    case "Roles": {
      return data.map((row: any) => ({
        id: row?.id,
        roleName: row?.roleName,
        activeForGrid: row?.status,
        description: row?.description,
        roleModuleMapping: row?.roleModuleMapping,
        tabName: "Roles",
      }));
    }
    case "Open": {
      return data.map((row: any) => ({
        id: row.id,
        phoneNumber: row?.phoneNumber,
        storeEmail: row?.storeEmail,
        storeName: row?.storeName,
        roleName: row?.roleName,
        accountNumber: row?.accountNumber,
        activeForGrid: row?.status,
        requestedDate: row?.requestedDate,
        tabName: "Open",
        roleId: row?.roleId
      }));
    }
    case "Closed": {
      return data.map((row: any) => ({
        id: row.id,
        phoneNumber: row?.phoneNumber,
        storeEmail: row?.storeEmail,
        storeName: row?.storeName,
        roleName: row?.roleName,
        accountNumber: row?.accountNumber,
        activeForGrid: row?.status,
        requestedDate: row?.requestedDate,
        tabName: "Closed",
        roleId: row?.roleId
      }));
    }
    default:
      return [];
  }
};

export const getAccessMgmtEndPoint = (tabName: any) => {
  switch (tabName) {
    case "Users": {
      return baseURL + `${clientContext}/v1/masterdata/user/getUsers`;
      // return "https://eagle.arkatiss.com/revlog/user/getUsers";
    }
    case "signin": {
      return baseURL + `${clientContext}/v1/masterdata/user/signin`;
      //  return "https://eagle.arkatiss.com/revlog/user/signin";
    }
    case "Open": {
      return (
        baseURL + `${clientContext}/v1/masterdata/getOpenRequests`
      );
      // return "https://eagle.arkatiss.com/revlog/user/getOpenAccessRequests";
    }
    case "Closed": {
      return baseURL + `${clientContext}/v1/masterdata/getApprovedAndDeniedRequests`;
      // return "https://eagle.arkatiss.com/revlog/user/getArchiveRequests";
    }
    case "Currency Codes": {
      return baseURL + `${clientContext}/v1/masterdata/currency/list`;
    }
    case "Languages": {
      return baseURL + `${clientContext}/v1/masterdata/language/list`;
    }
    case "Time Zones": {
      return baseURL + `${clientContext}/v1/masterdata/timezone/list`;
    }
    case "Roles": {
      return baseURL + `${clientContext}/v1/masterdata/role/getRoles`;
      //  return "https://eagle.arkatiss.com/revlog/role/getRoles";
    }
    case "All Modules": {
      return baseURL + `${clientContext}/v1/masterdata/role/getAllModules`;
      // return "https://eagle.arkatiss.com/revlog/role/getAllModules";
    }
    case "Modules By Id": {
      return baseURL + `${clientContext}/v1/masterdata/role/modulesBasedOnId`;
      // return "https://eagle.arkatiss.com/revlog/role/getAllModules";
    }
    case "Country List": {
      return baseURL + `${clientContext}/v1/masterdata/country/list/active`;
    }
    case "Reclaim Centers List": {
      return baseURL + `${clientContext}/v1/masterdata/reclaimcenters/list`;
    }
    case "Default Language": {
      return (
        baseURL +
        `${clientContext}/v1/masterdata/language/list?forSelection=true`
      );
    }
    case "Default TimeZone": {
      return (
        baseURL +
        `${clientContext}/v1/masterdata/timezone/list?forSelection=true`
      );
    }
    case "Default Country": {
      return (
        baseURL +
        `${clientContext}/v1/masterdata/country/list/active?forSelection=true`
      );
    }
    case "ADUsers": {
      return baseURL + `${clientContext}/v1/masterdata/reclaimcenters/list`;
    }
    default:
      return "";
  }
};

export const getAdListEndPoint = (username: any) => {
  // return `https://revlogsit.cswg.com/api/revlog/cs0001/v1/masterdata/user/getFederatedUsers?userName=${username}`;
  return (
    baseURL +
    `${clientContext}/v1/masterdata/user/getFederatedUsers?userName=${username}`
  );
};

export const getStoreWsUpdate = (params: any) => {
  return (
    baseURL +
    `${clientContext}/v1/masterdata/store/list/pagination?page=0&size=100&storename=${params}`
  );
};

export const updateAccessReqEndPoint = (tabName: any) => {
  switch (tabName) {
    case "Roles": {
      return baseURL + `${clientContext}/v1/masterdata/role/updateRole`;
      //return "https://eagle.arkatiss.com/revlog/role/updateRole";
    }
    case "Users": {
      return baseURL + `${clientContext}/v1/masterdata/user/create`;
      // return "https://eagle.arkatiss.com/revlog/user/create";
    }
    default:
      return "";
  }
};

export const addAccessReqEndPoint = (tabName: any) => {
  switch (tabName) {
    case "Reclaim Centers List": {
      return baseURL + `${clientContext}/v1/masterdata/reclaimcenters/list`;
    }
    case "Reclaim Scan": {
      return (
        baseURL +
        `${clientContext}/v1/masterdata/messagemanagement/items?isReclaim=true`
      );
    }
    case "Mobile": {
      return (
        baseURL +
        `${clientContext}/v1/masterdata/messagemanagement/items?isReclaim=false`
      );
    }
    case "Role": {
      return baseURL + `${clientContext}/v1/masterdata/role/addRole`;
      //  return "https://eagle.arkatiss.com/revlog/role/addRole";
    }
    case "Users": {
      return baseURL + `${clientContext}/v1/masterdata/user/create`;
      //   return "https://revlogsit.cswg.com/api/revlog/cs0001/v1/masterdata/user/create";
    }
    case "Reset": {
      return baseURL + `${clientContext}/v1/masterdata/user/resetPassword`;
      //   return "https://revlogsit.cswg.com/api/revlog/cs0001/v1/masterdata/user/create";
    }
    case "ExcelUpload": {
      return baseURL + `${clientContext}/v1/masterdata/user/bulkUpload`;
      //   return "https://revlogsit.cswg.com/api/revlog/cs0001/v1/masterdata/user/create";
    }

    case "accessManagement" : {
      return baseURL + `${clientContext}/v1/masterdata/approveAccessRequest`;
    }
    case "denyRequest" : {
      return baseURL + `${clientContext}/v1/masterdata/deniedAccessRequest`;
    }

    default:
      return "";
  }
};

export const getStatesByCountryCode = (countryCode: any) => {
  return (
    baseURL +
    `${clientContext}/v1/masterdata/state/list?countryCode=${countryCode}`
  );
};

export const getScanDetailsById = (reclaimCenterId: any) => {
  return (
    baseURL +
    `${clientContext}/v1/masterdata/scansetting/items/${reclaimCenterId}`
  );
};

export const getUpcDetailsById = () => {
  return (
    baseURL + `${clientContext}/v1/masterdata/itemcatalogue/list/pagination`
  );
};

export const userMgmtHeaders = [
  {
    field: "id",
    header: "User ID",
    width: "100",
    textAlign: "Right",
    dataType: "number",
  },
  { field: "username", header: "UserName", width: "120", textAlign: "Left" },
  { field: "fullName", header: "Full Name", width: "150", textAlign: "Left" },
  { field: "roleName", header: "Role", width: "100", textAlign: "Left" },
  {
    field: "locationName",
    header: "Location",
    width: "130",
    textAlign: "Left",
  },
  { field: "activeForGrid", header: "STATUS", dataType: "enum" },
];

export const roleHeaders = [
  {
    field: "id",
    header: "Role ID",
    width: "100",
    textAlign: "Right",
    dataType: "number",
  },
  { field: "roleName", header: "Name", width: "180", textAlign: "Left" },
  { field: "activeForGrid", header: "STATUS", dataType: "enum" },
];

export const accessReqOpenHeaders = [
  { field: "accountNumber", header: "C&S Account #", width: "125", textAlign: "Left" },
  { field: "phoneNumber", header: "Phone Number", width: "125", textAlign: "Left" },
  { field: "storeEmail", header: "Store Email", width: "160", textAlign: "Left" },
  { field: "storeName", header: "Store Name", width: "120", textAlign: "Left" },
  { field: "roleName", header: "Role", width: "120" },
  {
    field: "requestedDate",
    header: "Request Date",
    width: "120",
    textAlign: "right",
  },
  { field: "activeForGrid", header: "STATUS" },
];

export const accessReqArchieveHeaders = [
  { field: "accountNumber", header: "C&S Account #", width: "125", textAlign: "Left" },
  { field: "phoneNumber", header: "Phone Number", width: "125", textAlign: "Left" },
  { field: "storeEmail", header: "Store Email", width: "160", textAlign: "Left" },
  { field: "storeName", header: "Store Name", width: "120", textAlign: "Left" },
  { field: "roleName", header: "Role", width: "120" },
  {
    field: "requestedDate",
    header: "Request Date",
    width: "120",
    textAlign: "right",
  },
  { field: "activeForGrid", header: "STATUS" },
];
