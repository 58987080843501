import React, { MutableRefObject, useEffect, useState } from 'react';
import Keyboard from 'react-simple-keyboard';
import './input-field.scss';

export type KeyboardType = 'numeric' | 'alphanumeric';
type Props = {
    keyboardType: KeyboardType;
    onChange: (input: string) => void;
    keyboardRef: MutableRefObject<any>;
    keyboardVisibility: boolean;
    setKeyboardVisibility: any;
    defaultValue: any;
    onEnter?: () => void;
    onClose?: () => void;
};

export const KeyboardWrapper = (props: Props) => {
    const [layoutName, setLayoutName] = useState('default');
    const numericLayout = {
        default: ['1 2 3', '4 5 6', '7 8 9', '0 {bksp}', '{close} {enter}'],
    };

    const alphaNumericLayout = {
        shift: [
            '1 2 3 4 5 6 7 8 9 0 -',
            'q w e r t y u i o p',
            'a s d f g h j k l',
            '{shift} z x c v b n m',
            '{bksp} {close} {enter}',
        ],
        default: [
            '1 2 3 4 5 6 7 8 9 0 -',
            'Q W E R T Y U I O P',
            'A S D F G H J K L',
            '{shift} Z X C V B N M',
            '{bksp} {close} {enter}',
        ],
    };

    const display = {
        '{close}': 'Close',
        '{bksp}': 'Backspace',
        '{enter}': 'Enter',
        '{shift}': 'Shift',
    };
    const theme = 'hg-theme-default hg-layout-numeric numeric-theme';
    const closeKeyboard = () => {
        props.setKeyboardVisibility(false);
    };
    const onKeyPress = (button: any) => {
        if (button === '{close}') {
            closeKeyboard();
            if (props.onClose) props.onClose();
        }
        if (button === '{enter}') {
            closeKeyboard();
            if (props.onEnter) props.onEnter();
        }
        if (button === '{shift}') {
            setLayoutName(layoutName === 'default' ? 'shift' : 'default');
        }
    };
    useEffect(() => {
        props.keyboardRef.current.setInput(props.defaultValue);
    }, []);
    return (
        <div
            className={`keyboardContainer ${
                !props.keyboardVisibility ? 'hide-me' : ''
            }`}
        >
            <Keyboard
                keyboardRef={(r) => (props.keyboardRef.current = r)}
                layout={
                    props.keyboardType === 'alphanumeric'
                        ? alphaNumericLayout
                        : numericLayout
                }
                onChange={props.onChange}
                theme={`${theme} ${
                    props.keyboardType === 'numeric'
                        ? 'numericLayout'
                        : 'alpha-numeric-theme'
                } `}
                onKeyPress={onKeyPress}
                display={display}
                layoutName={layoutName}
            />
        </div>
    );
};

export default KeyboardWrapper;
