import React, { useState } from "react";
// import addIcon from "../../../assets/add.png";
import {
  TabComponent,
  TabItemDirective,
  TabItemsDirective,
} from "@syncfusion/ej2-react-navigations";
import LocalScanSettings from "../localScanSettings/localscansettings";
import GlobalScanSettings from "../globalScanSettings/globalscansettings";

const childTab = [{ text: "Local" }, { text: "Global" }];
const local = () => {
  return <LocalScanSettings />;
};

const global = () => {
  return <GlobalScanSettings />;
};
const ScanSettings = () => {
  const [ShowForceToTrashRC, setShowForceToTrashRC] = useState(true);

  const [forceTrashRCInputList, setForceTrashInputList] = useState([
    { reasonCode: "", description: "" },
  ]);
  const [storeReviewRCInputList, setStoreReviewInputList] = useState([
    { reasonCode: "", description: "" },
  ]);
  const [scanGroupsInputList, setScanGroupsInputList] = useState([
    { scanGrpCode: "", scanGrpDesc: "", scanStations: "" },
  ]);

  const TabChangeEvent = async (e: any) => {
    // dispatch(setRowData([]));
    // console.log(e.selectingIndex, e.selectingItem.innerText);
    // selectedTab = e.selectingItem.innerText;
    // await locationSettingTabApi(e.selectingItem.innerText);
  };

  // handle click event of the Add button
  const handleStoreReviewRCAddClick = () => {
    setStoreReviewInputList([
      ...storeReviewRCInputList,
      { reasonCode: "", description: "" },
    ]);
  };

  return (
    <div className="col-sm-12 col-md-12 col-lg-12">
      <div className="row m-0">
        <p className="col-sm-8 col-md-8 col-lg-8 settings-tab-title mt-5 fw-bold">
          Scan Settings
        </p>
      </div>
      <div className="row m-0">
        <div className="col-sm-12 col-md-12 col-lg-12 ">
          <TabComponent
            heightAdjustMode="Auto"
            cssClass="col-sm-10 col-md-10 col-lg-10 childTabsDiv"
            selecting={TabChangeEvent}
          >
            <TabItemsDirective>
              <TabItemDirective header={childTab[0]} content={local} />
              <TabItemDirective header={childTab[1]} content={global} />
            </TabItemsDirective>
          </TabComponent>
          {/* <ButtonComponent cssClass='e-outline opt-btn-dark float-right ml-1 mb-1 mt-1 saveFileBtn' >
                    <img src={fileIcon} alt="File " />
                 </ButtonComponent> */}
        </div>
      </div>
      {/* <ButtonComponent cssClass='e-outline opt-btn-dark float-right ml-1' >
                   <img src={fileIcon} alt="File " />
               </ButtonComponent> */}
    </div>
  );
};

export default ScanSettings;
