import { ButtonComponent } from "@syncfusion/ej2-react-buttons";
import saveIcon from "../../../icons/common/save.png";
import closeIcon from "../../../icons/common/close.png";
import activeIcon from "../../../icons/common/active.png";
import inactiveIcon from "../../../icons/common/inactive.png";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getLlAdminEndPoint, updateLlAdminEndPoint } from "../utils";
import axios from "axios";
import { setRowData } from "../../grid/gridReducer";
import notify from "../../../services/toasterService";
import { RootState } from "../../../redux/store";
import { setTimezoneData } from "../lladminreducer";

const PreviewTimeZone = () => {
  // console.log(previewData);
  const dispatch = useDispatch();
  const [timeZoneInfo, setTimeZoneInfo] = useState<any[]>([]);

  /* Get selected data from Grid Reducer */
  let selRowData = useSelector(
    (state: RootState) => state.gridReducer.selectedRowData
  );
  // console.log(selectedRowData, 'selectedRowData');

  useEffect(() => {
    if (selRowData && selRowData?.length > 0) {
      console.log(selRowData);
      setTimeZoneInfo([]);
      setTimeZoneInfo(selRowData);
    }
    // Write for onload
  }, [selRowData]);

  const timeZoneFieldFocusOut = (value: any, key: any) => {
    setTimeZoneInfo([{ ...timeZoneInfo[0], [key]: value }]);
  };

  /**
   * Changing status for Preview selected tabs
   * @param flag - true/false
   */
  const changeStatusForRowData = (flag: any) => {
    if (flag === false) {
      setTimeZoneInfo([{ ...timeZoneInfo[0], activeForGrid: "Inactive" }]);
    } else {
      setTimeZoneInfo([{ ...timeZoneInfo[0], activeForGrid: "Active" }]);
    }
  };

  const clearPreview = () => {
    dispatch(setRowData([]));
  };

  /**
   * PUT web service methods for Location setting tabs
   * @param data - Tab Name
   */
  const updateLocationSettingTabApi = async (data: any) => {
    console.log(data);
    const endPointURL = updateLlAdminEndPoint(data.tabApiName, data.id);
    let body = {
      country: data.country,
      deleted: data.deleted,
      description: data.description,
      id: data.id,
      isoCode: data.isoCode,
      organizationId: data.organizationId,
      tenantId: data.tenantId,
      timezone: data.timeZone,
      timezoneName: data.name,
      utc: data.utc,
    };
    const status = data.activeForGrid.toLowerCase();
    if (status === "active") {
      Object.assign(body, { active: true });
    } else {
      Object.assign(body, { active: false });
    }
    await axios
      .put(endPointURL, body)
      .then((res) => {
        notify("Time zone updated successfully", "success");
        getlAdminTabs(data.tabApiName);
      })
      .catch((err) => {
        console.log(err, "TimeZone tab");
        notify("Failed to update time zone", "error");
      });
  };

  const getlAdminTabs = async (tabName: any) => {
    const endPointURL = getLlAdminEndPoint(tabName);
    await axios
      .get(endPointURL)
      .then((res: any) => {
        dispatch(setTimezoneData(res.data))
        const dataa = res.data[0];
        setTimeZoneInfo(dataa);
        setTimeout(() => {
          dispatch(setRowData(timeZoneInfo));
        }, 5);

        // dispatch(setRowData(res.data[0]))
      })
      .catch((err) => {
        console.log(err, "TimeZone tab");
        notify("Failed to update data", "error");
      });
  };

  const footerPreview = () => {
    return (
      <div className="col-sm-12 px-0 pt-5 ">
        {timeZoneInfo[0].active}

        <div className="col-sm-6 col-md-6 col-lg-6 pl-1.5rem pr-1 preview-Key  ">
          <ButtonComponent
            cssClass="e-outline opt-btn-light float-left"
            onClick={() => updateLocationSettingTabApi(timeZoneInfo[0])}
          >
            <img src={saveIcon} alt="Save" />
          </ButtonComponent>

          <ButtonComponent
            cssClass="e-outline opt-btn-light float-left ml-3"
            onClick={() => clearPreview()}
          >
            <img src={closeIcon} alt="Close" />
          </ButtonComponent>
        </div>
        <div className="col-sm-6 col-md-6 col-lg-6 preview-value pt-3">
          <p className="preview-status">
            {timeZoneInfo[0]?.activeForGrid === "Active" ||
            timeZoneInfo[0]?.activeForGrid === "active" ? (
              <span>
                <img
                  src={activeIcon}
                  alt="Status"
                  onClick={() => changeStatusForRowData(false)}
                />
                &nbsp; &nbsp; Active
              </span>
            ) : (
              <span>
                <img
                  src={inactiveIcon}
                  alt="Status"
                  onClick={() => changeStatusForRowData(true)}
                />
                &nbsp; &nbsp; Inactive
              </span>
            )}
          </p>
        </div>
      </div>
    );
  };

  return (
    <div>
      {timeZoneInfo && timeZoneInfo.length > 0 ? (
        <div className="col-sm-12 px-3 py-3 preview-details  ">
          <div className="col-sm-12 px-0 pt-4 ">
            <div className="col-sm-6 col-md-6 col-lg-5 preview-Key  ">ID</div>
            <div className="col-sm-6 col-md-6 col-lg-7 preview-value ">
              {timeZoneInfo[0]?.id}
            </div>
          </div>
          <div className="col-sm-12 px-0 pt-4">
            <div className="col-sm-6 col-md-6 col-lg-5 preview-Key pt-2 ">
              Name
            </div>
            <div className="col-sm-6 col-md-6 col-lg-7 preview-value ">
              {/* <InlineInputEdit inputClassName="txtEditClassNameInput"
                                text={timeZoneInfo[0]?.name}
                                labelClassName="txtEditClassName"
                                onFocusOut={(e) => timeZoneFieldFocusOut(e, 'name')}
                                labelPlaceHolder='---'
                            /> */}
              <input
                type="text"
                className="form-control border-none pl-0 cursor-pointer txtEditFieldFocus boxShadow-None"
                value={timeZoneInfo[0]?.name}
                onChange={(e: any) =>
                  timeZoneFieldFocusOut(e.target.value, "name")
                }
              />
            </div>
          </div>
          <div className="col-sm-12 px-0 pt-4">
            <div className="col-sm-6 col-md-6 col-lg-5 preview-Key pt-2 ">
              Timezone
            </div>
            <div className="col-sm-6 col-md-6 col-lg-7 preview-value ">
              {/* <InlineInputEdit inputClassName="txtEditClassNameInput"
                                text={timeZoneInfo[0]?.timeZone}
                                labelClassName="txtEditClassName"
                                onFocusOut={(e) => timeZoneFieldFocusOut(e, 'timeZone')}
                                labelPlaceHolder='---'
                            /> */}
              <input
                type="text"
                className="form-control border-none pl-0 cursor-pointer txtEditFieldFocus boxShadow-None"
                value={timeZoneInfo[0]?.timeZone}
                onChange={(e: any) =>
                  timeZoneFieldFocusOut(e.target.value, "timeZone")
                }
              />
            </div>
          </div>
          <div className="col-sm-12 px-0 pt-4">
            <div className="col-sm-6 col-md-6 col-lg-5 preview-Key pt-2 ">
              ISO Code
            </div>
            <div className="col-sm-6 col-md-6 col-lg-7 preview-value ">
              {/* <InlineInputEdit inputClassName="txtEditClassNameInput"
                                text={timeZoneInfo[0]?.isoCode}
                                labelClassName="txtEditClassName"
                                onFocusOut={(e) => timeZoneFieldFocusOut(e, 'isoCode')}
                                labelPlaceHolder='---'
                            /> */}
              <input
                type="text"
                className="form-control border-none pl-0 cursor-pointer txtEditFieldFocus boxShadow-None"
                value={timeZoneInfo[0]?.isoCode}
                onChange={(e: any) =>
                  timeZoneFieldFocusOut(e.target.value, "isoCode")
                }
              />
            </div>
          </div>
          <div className="col-sm-12 px-0 pt-4">
            <div className="col-sm-6 col-md-6 col-lg-5 preview-Key pt-2 ">
              Country
            </div>
            <div className="col-sm-6 col-md-6 col-lg-7 preview-value ">
              {/* <InlineInputEdit inputClassName="txtEditClassNameInput"
                                text={timeZoneInfo[0]?.country}
                                labelClassName="txtEditClassName"
                                onFocusOut={(e) => timeZoneFieldFocusOut(e, 'country')}
                                labelPlaceHolder='---'
                            /> */}
              <input
                type="text"
                className="form-control border-none pl-0 cursor-pointer txtEditFieldFocus boxShadow-None"
                value={timeZoneInfo[0]?.country}
                onChange={(e: any) =>
                  timeZoneFieldFocusOut(e.target.value, "country")
                }
              />
            </div>
          </div>
          <div className="col-sm-12 px-0 pt-4">
            <div className="col-sm-6 col-md-6 col-lg-5 preview-Key pt-2 ">
              UTC Offset
            </div>
            <div className="col-sm-6 col-md-6 col-lg-7 preview-value ">
              {/* <InlineInputEdit inputClassName="txtEditClassNameInput"
                                text={timeZoneInfo[0]?.utc}
                                labelClassName="txtEditClassName"
                                onFocusOut={(e) => timeZoneFieldFocusOut(e, 'utc')}
                                labelPlaceHolder='---'
                            /> */}
              <input
                type="text"
                className="form-control border-none pl-0 cursor-pointer txtEditFieldFocus boxShadow-None"
                value={timeZoneInfo[0]?.utc}
                onChange={(e: any) =>
                  timeZoneFieldFocusOut(e.target.value, "utc")
                }
              />
            </div>
          </div>

          <div className="col-sm-12 p-0 mb-auto">
            <hr className="divider" />
          </div>

          <div className="col-sm-12 px-0 pt-2 pb-5 mb-auto">
            <div className="col-sm-12 p-0">
              <div className="col-sm-3 col-md-3 col-lg-3 preview-Key  ">
                Desc.
              </div>
              <div className="col-sm-9 col-md-9 col-lg-9 p-0 preview-value ">
                <textarea
                  className="notes-preview"
                  id=""
                  rows={8}
                  value={timeZoneInfo[0]?.description}
                  onChange={(e) =>
                    timeZoneFieldFocusOut(e.target.value, "description")
                  }
                ></textarea>
                {/* {timeZoneInfo[0]?.name}, {timeZoneInfo[0]?.country} */}
              </div>
            </div>
          </div>

          <div className="col-sm-12 px-0 pt-5 pb-4">{footerPreview()}</div>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default PreviewTimeZone;
