import { ButtonComponent } from "@syncfusion/ej2-react-buttons";
import {
  TabComponent,
  TabItemDirective,
  TabItemsDirective,
} from "@syncfusion/ej2-react-navigations";
import { ReactComponent as Add } from "../../../icons/AddNew.svg";
import { ReactComponent as Print } from "../../../icons/printNew.svg";
import { ReactComponent as Download } from "../../../icons/download.svg";
import TimeZone from "../timezone/timezone";
import LanguageTab from "../language/language";
import CurrencyCode from "../currencycode/currencyCode";
import CountryCode from "../countrycode/countryCode";
import { getLlAdminEndPoint } from "../utils";
import axios from "axios";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  setCountryData,
  setCurrencyData,
  setIsLoaderShow,
  setLanguageData,
  setTimezoneData,
} from "../lladminreducer";
import { setRowData } from "../../grid/gridReducer";
import ExcelService from "../../../services/excel";
import AddLlAdminScreen from "../addScreen/addScreen";
import { RootState } from "../../../redux/store";
import notify from "../../../services/toasterService";
import { setSelectedRecrdsExport } from "../../contactTabs/contactReducer";

const childTab = [
  { text: "Time Zones", id: "0" },
  { text: "Languages", id: "1" },
  { text: "Currency Codes", id: "2" },
  { text: "Country Codes", id: "3" },
];

const timeZones = () => {
  return <TimeZone />;
};

const languages = () => {
  return <LanguageTab />;
};

const currencyCodes = () => {
  return <CurrencyCode />;
};

const countryCodes = () => {
  return <CountryCode />;
};

const LocationSettings = (props: any) => {
  const [addScreen, setAddScreen] = useState(false);
  const [addScreenTabName, setAddScreenTabName] = useState(null);

  let TabData: any = [];
  let selectedTab: string;

  useEffect(() => {
    // selectedTab = "Time Zones";
    // locationSettingTabApi(selectedTab);
    // setAddScreen(false);
  });

  const dispatch = useDispatch();

  /* When Tab Changes this event will get you the tab details */
  const TabChangeEvent = async (e: any) => {
    // console.log(e);
    dispatch(setRowData([]));
    dispatch(setSelectedRecrdsExport([]));
    dispatch(setIsLoaderShow(true))
    // console.log(e.selectingIndex, e.selectingItem.innerText);
    selectedTab = e.selectingItem.innerText;
    await locationSettingTabApi(e.selectingItem.innerText);
  };

  /* When Tab Changes this event will get you the selected tab details */
  const getSelectedTabEvent = (e: any) => {
    // console.log(e, 'Get Selected Tab');
  };

  /* On page this event will get you the selected tab details */
  const getTabEvent = (e: any) => {
    // console.log(e, 'On page load Tab');
    if (e !== null) {
      const selectedId = e.selectingID;
      // console.log(selectedId);
      childTab.map((item) => {
        if (item.id === selectedId) {
          selectedTab = item.text;
          locationSettingTabApi(selectedTab);
        }
      });
      // console.log(selectedTab, 'selectedTab');
    }
  };

  const handlePopupActionLocation = (selectedTabName: any) => {
    console.log(selectedTabName);
    setAddScreen(true);
    setAddScreenTabName(selectedTabName);
  };

  /**
   * Location Sub tabs Web service calls
   * @author Anudeep
   * @param TabName - Selected Tab name
   */
  const locationSettingTabApi = async (TabName: any) => {
    console.log(TabName)
    // dispatch(setIsLoaderShow(true))
    const endPointURL = getLlAdminEndPoint(TabName);
    await axios
      .get(endPointURL)
      .then((res) => {
        dispatch(setIsLoaderShow(false))
        if (TabName === "Time Zones") {
          const endPointURL = getLlAdminEndPoint("Country Codes");
          dispatch(setTimezoneData(res.data));
          axios
            .get(endPointURL)
            .then((res) => {
              // console.log(res.data);
              dispatch(setCountryData(res.data));
            })
            .catch((err) => err);
          // console.log(res.data, "Time Zone in Location Settings");
        } else if (TabName === "Languages") {
          dispatch(setLanguageData(res.data));
          // console.log(res.data, " Languages in Location Settings");
        } else if (TabName === "Currency Codes") {
          dispatch(setCurrencyData(res.data));
          // console.log(res.data, "Currency Codes in Location Settings");
        } else if (TabName === "Country Codes") {
          dispatch(setCountryData(res.data));
          // console.log(res.data, "Country Codes in Location Settings");
        }

        TabData = res.data;
      })
      .catch((err) => {
        dispatch(setIsLoaderShow(false));
        notify(err, "error");
      } );
      
  };

  const selectedDataToExport = useSelector(
    (state: RootState) => state.contactReducer.selectedRecordsForExport
  );
  const excelExport = () => {
    if (
      selectedDataToExport !== null &&
      selectedDataToExport !== undefined &&
      selectedDataToExport.length > 0
    ) {
      ExcelService.exportAsExcel('lladmin',selectedTab, selectedDataToExport);
      dispatch(setSelectedRecrdsExport([]));
    } else {
      ExcelService.exportAsExcel('',selectedTab, TabData);
    }
  };

  return (
    <div>
      {addScreen && (
        <AddLlAdminScreen
          addScreen={addScreen}
          setAddScreen={setAddScreen}
          tabName={addScreenTabName}
        />
      )}
      <div className="col-sm-12 col-md-12 col-lg-12 pt-5">
        <div className="col-sm-6 pl-0">
          <p className="child-tab-title">Location Settings</p>
        </div>
        <div className="col-sm-6 pr-0">
          {/* <ButtonComponent
            type="button"
            className="iconCopy float-right ml-1"
            onClick={() => handlePopupActionLocation(selectedTab)}
          >
            <Add />
          </ButtonComponent> */}

          <ButtonComponent className="iconCopy float-right ml-1">
            <Print />
          </ButtonComponent>

          <ButtonComponent
            className="iconCopy float-right ml-1"
            onClick={() => excelExport()}
          >
            <Download />
          </ButtonComponent>
        </div>
      </div>
      <div className="col-sm-12 col-md-12 col-lg-12 ">
        <div className="control-pane">
          <div className="control-section tab-control-section">
            <TabComponent
              heightAdjustMode="Auto"
              cssClass="orientation-tab ll-custom-tab ll-tab"
              selecting={TabChangeEvent}
              selected={getSelectedTabEvent}
              ref={(tab) => getTabEvent(tab)}
            >
              <TabItemsDirective>
                <TabItemDirective header={childTab[0]} content={timeZones} />

                <TabItemDirective header={childTab[1]} content={languages} />

                <TabItemDirective
                  header={childTab[2]}
                  content={currencyCodes}
                />

                <TabItemDirective header={childTab[3]} content={countryCodes} />
              </TabItemsDirective>
            </TabComponent>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LocationSettings;
