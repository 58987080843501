import { createSlice } from "@reduxjs/toolkit";
import { InvoiceScreen } from "./config";

export interface contactsState {
  selectedInvoiceScreen: InvoiceScreen | null;
  fixedPriceItemData: null;
  selectedArInvoiceTabIndex: number;
  manualEntriesList: any[];
  bolsList: any[];
  arInvoicesList: any[];
  arInvoiceSaveData: any;
  arInvoiceDefaultData: any;
  bolDataById: any;
  bolNumberForADD: any;
  salvageItemList: any;
  isInvoiceSaved: boolean;
  isInvoiceClear: boolean;
  newSavedInvoiceList: any[];
  creditMemoSavedData: any;
  invoiceNumberForCMView: any;
  customerIdForInvoiceInBol: any;
  warehouseIdForInvoiceInBol: any;
  selectedIdForCancel: any;
  isManualEntryFormClear: boolean;
  selectedIdsForCancel: any[];
  fpiClearGridRow: boolean;
  isBOLDataClear: boolean;
  bolAttachmentIconsStatus: any;
  changedCredit: any;
  lineLevelCmTotal: any;
  descriptionForCM: any;
  cmIdForStatusUpdate: any;
  scannedPalletsInfo: any[];
  isInvoiceUpdated: boolean;
}

const initialState: contactsState = {
  selectedInvoiceScreen: null,
  fixedPriceItemData: null,
  selectedArInvoiceTabIndex: 0,
  manualEntriesList: [],
  bolsList: [],
  arInvoicesList: [],
  arInvoiceSaveData: {},
  arInvoiceDefaultData: {},
  newSavedInvoiceList: [],
  bolDataById: {},
  bolNumberForADD: null,
  salvageItemList: null,
  isInvoiceSaved: false,
  isInvoiceClear: false,
  creditMemoSavedData: {},
  invoiceNumberForCMView: null,
  customerIdForInvoiceInBol: null,
  warehouseIdForInvoiceInBol: null,
  selectedIdForCancel: null,
  isManualEntryFormClear: false,
  selectedIdsForCancel: [],
  fpiClearGridRow: false,
  isBOLDataClear: false,
  bolAttachmentIconsStatus: null,
  changedCredit: "",
  lineLevelCmTotal: undefined,
  descriptionForCM: null,
  cmIdForStatusUpdate: null,
  scannedPalletsInfo: [],
  isInvoiceUpdated: false
};

export const invoiceSlice = createSlice({
  name: "invoices",
  initialState,
  reducers: {
    setSelectedInvoiceScreen: (state, { payload }) => {
      state.selectedInvoiceScreen = payload;
    },
    setFixedPriceItemData: (state, { payload }) => {
      state.fixedPriceItemData = payload;
    },
    setSelectedArInvoiceTabIndex: (state, { payload }) => {
      state.selectedArInvoiceTabIndex = payload;
    },
    setManualEntriesList: (state, { payload }) => {
      state.manualEntriesList = payload;
    },
    setBOLsList: (state, { payload }) => {
      state.bolsList = payload;
    },
    setARInvoicesList: (state, { payload }) => {
      state.arInvoicesList = payload;
    },
    setNewSavedInvoiceList: (state, { payload }) => {
      state.newSavedInvoiceList = [...state.newSavedInvoiceList, payload];
    },
    setArInvoiceSavedata: (state, { payload }) => {
      state.arInvoiceSaveData = payload;
    },
    setArInvoiceDefaultData: (state, { payload }) => {
      state.arInvoiceDefaultData = payload;
    },
    setBOLDataByIddata: (state, { payload }) => {
      state.bolDataById = payload;
    },
    setBOLNumberForAdd: (state, { payload }) => {
      state.bolNumberForADD = payload;
    },
    setSalvageItemList: (state, { payload }) => {
      state.salvageItemList = payload;
    },
    setIsInvoiceSaved: (state, { payload }) => {
      state.isInvoiceSaved = payload;
    },
    setIsInvoiceClear: (state, { payload }) => {
      state.isInvoiceClear = payload;
    },
    setCreditMemoSavedata: (state, { payload }) => {
      state.creditMemoSavedData = payload;
    },
    setInvoiceNumForCM: (state, { payload }) => {
      state.invoiceNumberForCMView = payload;
    },
    setCustomerIdForInvoiceInBOL: (state, { payload }) => {
      state.customerIdForInvoiceInBol = payload;
    },
    setWarehouseIdForInvoiceInBOL: (state, { payload }) => {
      state.warehouseIdForInvoiceInBol = payload;
    },
    setSelectedIdForCancel: (state, { payload }) => {
      state.selectedIdForCancel = payload;
    },
    setIsManualEntryFormClear: (state, { payload }) => {
      state.isManualEntryFormClear = payload;
    },
    setSelectedIdsForCancel: (state, { payload }) => {
      state.selectedIdsForCancel = [...state.selectedIdsForCancel, payload];
    },
    setFpiClearGridRow: (state, { payload }) => {
      state.fpiClearGridRow = payload;
    },
    setIsBOLDataClear: (state, { payload }) => {
      state.isBOLDataClear = payload;
    },
    setBOLAttachementIconsStatus: (state, { payload }) => {
      state.bolAttachmentIconsStatus = payload;
    },
    setChangedCreditmemo: (state, { payload }) => {
      state.changedCredit = payload;
    },
    setLineLevelCmTotal: (state, { payload }) => {
      state.lineLevelCmTotal = payload;
    },
    setDescriptionForCM: (state, { payload }) => {
      state.descriptionForCM = payload;
    },
    setCMIdForStatusUpdate: (state, { payload }) => {
      state.cmIdForStatusUpdate = payload;
    },
    setScannedPalletsInfo: (state, { payload }) => {
      state.scannedPalletsInfo = payload;
    },
    setIsInvoiceUpdated: (state, { payload }) => {
      state.isInvoiceUpdated = payload;
    },
  },
});

export const {
  setSelectedInvoiceScreen,
  setSelectedArInvoiceTabIndex,
  setManualEntriesList,
  setBOLsList,
  setARInvoicesList,
  setArInvoiceSavedata,
  setArInvoiceDefaultData,
  setBOLDataByIddata,
  setBOLNumberForAdd,
  setSalvageItemList,
  setIsInvoiceSaved,
  setIsInvoiceClear,
  setNewSavedInvoiceList,
  setCreditMemoSavedata,
  setInvoiceNumForCM,
  setCustomerIdForInvoiceInBOL,
  setWarehouseIdForInvoiceInBOL,
  setSelectedIdForCancel,
  setIsManualEntryFormClear,
  setSelectedIdsForCancel,
  setFpiClearGridRow,
  setIsBOLDataClear,
  setBOLAttachementIconsStatus,
  setChangedCreditmemo,
  setLineLevelCmTotal,
  setDescriptionForCM,
  setCMIdForStatusUpdate,
  setScannedPalletsInfo,
  setIsInvoiceUpdated
} = invoiceSlice.actions;

export default invoiceSlice.reducer;
