/* eslint-disable */
var designerStyle = { 'height': '700px', 'width': '100%' };

function ReportDesigner() {
  return (
    <></>
    // <div style={designerStyle} className="App">
    // <BoldReportDesignerComponent
    // id="reportdesigner-container"
    // serviceUrl={'https://demos.boldreports.com/services/api/ReportDesignerWebApi'}>
    // </BoldReportDesignerComponent>
//</div>
  );
}
export default ReportDesigner;