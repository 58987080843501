import { FC, useState } from "react";
import { useSelector } from "react-redux";
import { ButtonComponent } from "@syncfusion/ej2-react-buttons";
import { RootState } from "../../redux/store";
import { ReactComponent as Print } from "../../icons/printNew.svg";
import { ReactComponent as Add } from "../../icons/AddNew.svg";
import { actionHistoryGridConfigAR } from "./config";
import { getInvoiceActionHistoryEndpoint } from "./utils";
import LLModal from "../common/llModal/LLModal";
import axios from "axios";
import { DropDownButtonComponent } from "@syncfusion/ej2-react-splitbuttons";
import NoCheckBoXGrid from "../grid/fiscal-year-setup-grid/fiscal-year-setup-grid";
import moment from 'moment';
interface IInvoicesControlsProps {
  cancelButtonText: string;
  enableActionHistory?: boolean;
  exportTypes: any;
  hideCancelButton?: boolean;
  handleAddIconClick?: () => void;
  handleCancelButtonClick?: () => void;
  handleExportButtonClick?: (e: any) => void;
  hideAddNewButton?: Boolean;
}

const InvoicesControls: FC<IInvoicesControlsProps> = ({
  cancelButtonText,
  enableActionHistory,
  exportTypes,
  hideCancelButton,
  handleAddIconClick,
  handleCancelButtonClick,
  handleExportButtonClick,
  hideAddNewButton = false,
}) => {
  const [showActionHistoryModal, setShowActionHistoryModal] = useState(false);
  const [actionHistoryData, setActionHistoryData] = useState<any[]>([]);
  // const onSelect = useCallback((args) => {
  //   //  if(args.item.text==='Invoice' && args.item.type==='csv') {
  //   console.log(args);
  //   // alert(args.item.text + '' + args.item.exportType + args.item.screenType)
  //   //}
  // }, []);
  const arInvoiceSavedata = useSelector(
    (store: RootState) => store.invoiceReducer.arInvoiceSaveData
  );
  const selectedArInvoiceTabIndex = useSelector(
    (store: RootState) => store.invoiceReducer.selectedArInvoiceTabIndex
  );
  const creditMemoInfo = useSelector(
    (state: RootState) => state.invoiceReducer.creditMemoSavedData
  );
  const handleActionHistory = () => {
    setShowActionHistoryModal(true);
    handleInvoiceActionHistroy();
  };
  let actionHistoryARData: any;
  const handleInvoiceActionHistroy = () => {
    const endpoint = getInvoiceActionHistoryEndpoint();
    let body;
    if (selectedArInvoiceTabIndex === 1) {
      body = {
        module: "CREDIT-MEMO",
        moduleId: creditMemoInfo.creditMemoId,
      };
    } else {
      body = {
        module: "INVOICE",
        moduleId: arInvoiceSavedata.invoiceNumber,
      };
    }

    axios.post(endpoint, body).then((data) => {
      actionHistoryARData = data.data;
      //setActionHistoryData(actionHistoryARData);
      setActionHistoryData(
        actionHistoryARData.map((item: any) => ({
          userName: item.userName,
          lastModifiedDate: moment(item.lastModifiedDate).format('MMM DD, YYYY'),
          status:
            item.description === "Pickup, Auto Approved"
              ? "Pickup Auto"
              : item.description === "Ready for Salvager"
              ? "Ready Slvg"
              : item.description === "Ready for Extract"
              ? "Ready Extrct"
              : item.description === "Pickup, Manual Override"
              ? "Pickup Mnul"
              : item.description === "Draft"
              ? "Draft"
              : item.description === "Cancelled"
              ? "Cancelled"
              : item.description,
        }))
      );
      console.log(">>>>>>>>>>> action history data: ", actionHistoryARData);
    });
  };
  const handleDropdown = (e: any) => {
    if (e?.items[0]?.exportType === 'Empty') {
      const elem = e.element.parentElement;
      elem.style.display = "none";
    }
  };
  return (
    <div className="invoices-controls-container">
      {/* {!hideCancelButton && (
        <ButtonComponent
          cssClass="e-outline btnCancelInvoice"
          onClick={handleCancelButtonClick}
        >
          {cancelButtonText}
        </ButtonComponent>
      )} */}
      {enableActionHistory && (
        <ButtonComponent
          cssClass="e-outline btnCancelInvoice"
          onClick={handleActionHistory}
        >
          ACTION HISTORY
        </ButtonComponent>
      )}
      {/* <ButtonComponent className="iconCopy">
       <Copy />
       </ButtonComponent> */}
          {hideAddNewButton && (
        <DropDownButtonComponent
          open={(e) => handleDropdown(e)}
          style={{ marginRight: 5, padding: 0 }}
          items={exportTypes}
          id={exportTypes.id}
          select={handleExportButtonClick}
        >
          <Print></Print>
        </DropDownButtonComponent>
       )}
      {hideAddNewButton && (
        <ButtonComponent className="iconCopy" onClick={handleAddIconClick}>
          <Add />
        </ButtonComponent>
      )}

      <LLModal
        handleClose={() => setShowActionHistoryModal(false)}
        show={showActionHistoryModal}
      >
        <div className="History-grid">
          <h3>Action History for #{arInvoiceSavedata?.invoiceNumber?.charAt(0)?.includes("C") ? "" :
          arInvoiceSavedata.invoiceNumber}</h3>

          <NoCheckBoXGrid
            config={actionHistoryGridConfigAR}
            data={actionHistoryData}
            width={"100%"}
            hideSpinner={true}
            isFromActionHistory= {true}
          />
        </div>
        <ButtonComponent
          className="btnCloseActionHistoryPopupBol e-control e-btn e-lib eg-btn-primary float-right btnBox"
          onClick={() => setShowActionHistoryModal(false)}
        >
          CANCEL
        </ButtonComponent>
      </LLModal>
    </div>
  );
};

export default InvoicesControls;
