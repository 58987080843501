import { ButtonComponent } from "@syncfusion/ej2-react-buttons";
import axios from "axios";
import { useState } from "react";
import { useDispatch } from "react-redux";
import notify from "../../../services/toasterService";
import { setLanguageData } from "../lladminreducer";
import userAvatar from "../../../icons/common/user-avatar.png";
import { addLlAdminEndPoint, getLlAdminEndPoint } from "../utils";

const AddLanguage = (props: any) => {
  const { setAddScreen } = props;

  const dispatch = useDispatch();
  const [language, setLanguage] = useState("");
  const [isoShort, setIsoShort] = useState("");
  const [isoLong, setIsoLong] = useState("");
  const [desc, setDesc] = useState("");
  const [languageDisabled, setLanguageDisabled] = useState(true);
  const [myFormLanguage, setMyFormLanguage] = useState("");
  const [myFormIsoShort, setMyFormIsoShort] = useState("");
  const [myFormIsoLong, setMyFormIsoLong] = useState("");
  const [myFormDesc, setMyFormDesc] = useState("");

  const [errors, setErrors] = useState({
    languageError: "",
    isoShortError: "",
    isoLongError: "",
    descError: "",
  });

  const handleLanguage = (e: any) => {
    setLanguage(e.target.value);
    validate();
  };

  const handleIsoShort = (e: any) => {
    setIsoShort(e.target.value);
    validate();
  };

  const handleIsoLong = (e: any) => {
    setIsoLong(e.target.value);
    validate();
  };

  const handleDesc = (e: any) => {
    setDesc(e.target.value);
    validate();
  };

  /* Create Object to insert the form */
  const body = {
    country: null,
    isoLongCode: isoShort,
    isoShortCode: isoLong,
    language: language,
    languageDescription: desc,
  };

  const handleAddLanguage = () => {
    if (validate()) {
      const addEndPoint = addLlAdminEndPoint("Languages");
      const getEndPoint = getLlAdminEndPoint("Languages");
      addLanguageWS(addEndPoint, getEndPoint);
    }
  };

  const addLanguageWS = async (addEndPoint: string, getEndPoint: string) => {
    const result = await axios.post(addEndPoint, body);
    if (result && result.data) {
      setAddScreen(false);
      notify("Language added successfully", "success");
      await axios.get(getEndPoint).then(
        (res) => {
          dispatch(setLanguageData(res.data));
        },
        (err) => {
          notify("Failed to fetch data", "error");
        }
      );
    } else {
      notify("Failed to create language", "error");
    }
  };

  const handlePopUpClose = () => {
    setAddScreen(false);
  };

  const validate = (field?: any, value?: any) => {
    let languageError = "";
    let isoShortError = "";
    let isoLongError = "";
    let descError = "";
    setErrors({
      languageError: "",
      isoShortError: "",
      isoLongError: "",
      descError: "",
    });

    if (language === "") {
      languageError = "Required Field";
      setMyFormLanguage("1px solid red");
    } else {
      setMyFormLanguage("");
    }

    if (isoShort === "") {
      isoShortError = "Required Field";
      setMyFormIsoShort("1px solid red");
    } else {
      setMyFormIsoShort("");
    }

    if (isoLong === "") {
      isoLongError = "Required Field";
      setMyFormIsoLong("1px solid red");
    } else {
      setMyFormIsoLong("");
    }

    if (desc === "") {
      descError = "Required Field";
      setMyFormDesc("1px solid red");
    } else {
      setMyFormDesc("");
    }

    if (languageError || isoShortError || isoLongError || descError) {
      setErrors({ languageError, isoShortError, isoLongError, descError });
      setLanguageDisabled(true);
      return false;
    } else {
      setLanguageDisabled(false);
    }

    return true;
  };

  return (
    <div>
      <div className="col-sm-12 p-0 d-flex justify-content-center">
        <img src={userAvatar} />
      </div>

      <div className="col-sm-12 row m-0 mt-5">
        <div className="col-sm-6">
          <div className="col-sm-12 mt-3">
            <label className="label-title">Language</label>
            {language === "" ? (
              <span className="text-danger" style={{ float: "right" }}>
                {errors.languageError}
              </span>
            ) : (
              ""
            )}
            <input
              type="text"
              className="formcontrol"
              onChange={(e) => handleLanguage(e)}
              onBlur={(e) => handleLanguage(e)}
              onKeyUp={(e) => handleLanguage(e)}
              style={{ border: myFormLanguage }}
            />
          </div>

          <div className="col-sm-12 mt-3">
            <label className="label-title">ISO Short Code</label>
            {isoShort === "" ? (
              <span className="text-danger" style={{ float: "right" }}>
                {errors.isoShortError}
              </span>
            ) : (
              ""
            )}
            <input
              type="text"
              className="formcontrol"
              onChange={(e) => handleIsoShort(e)}
              onBlur={(e) => handleIsoShort(e)}
              onKeyUp={(e) => handleIsoShort(e)}
              style={{ border: myFormIsoShort }}
            />
          </div>

          <div className="col-sm-12 mt-3">
            <label className="label-title">ISO Long Code</label>
            {isoLong === "" ? (
              <span className="text-danger" style={{ float: "right" }}>
                {errors.isoLongError}
              </span>
            ) : (
              ""
            )}
            <input
              type="text"
              className="formcontrol"
              onChange={(e) => handleIsoLong(e)}
              onBlur={(e) => handleIsoLong(e)}
              onKeyUp={(e) => handleIsoLong(e)}
              style={{ border: myFormIsoLong }}
            />
          </div>
        </div>

        <div className="col-sm-6">
          <div className="col-sm-12 mt-3">
            <label className="label-title">Description</label>
            {desc === "" ? (
              <span className="text-danger" style={{ float: "right" }}>
                {errors.descError}
              </span>
            ) : (
              ""
            )}
            <textarea
              rows={5}
              className="formDesc"
              onChange={(e) => handleDesc(e)}
              onKeyUp={(e) => handleDesc(e)}
              onBlur={(e) => handleDesc(e)}
              style={{ border: myFormDesc }}
            />
          </div>
        </div>
      </div>
      <div className="col-sm-12 px-0 my-5" style={{ width: "94%" }}>
        <ButtonComponent
          cssClass="eg-btn-primary float-right"
          disabled={languageDisabled}
          onClick={() => handleAddLanguage()}
        >
          ADD Language
        </ButtonComponent>
        <ButtonComponent
          cssClass="eg-btn-primary-link e-flat mt-3"
          onClick={() => handlePopUpClose()}
        >
          Cancel
        </ButtonComponent>
      </div>
    </div>
  );
};
export default AddLanguage;
