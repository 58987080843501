import { createSlice } from "@reduxjs/toolkit";

export interface AccessMgmtState {
  userAccessData: any[];
  openAccessReqData: any[];
  archiveReqData: any[];
  rolesData: any[];
  clientsData: any[];
  countryData: any[];
  reclaimMsgData: any[];
  mobileMsgData: any[];
  languagesData: any[];
  timeZonesData: any[];
  locatonsList: any[];
  rolesList: any[];
  countryCodesList: any[];
  statesList: any[];
  modulesList: any[];
  uploadedImage: any[];
  base64Image: any[];
  convertedEndDate: any[];
  previewImage: any[];
  activeDirectoryData: any[];
  activeDirectoryUsersList: any[];
  isLoaderShow: boolean;
  previewFile: any[];
}

const initialState: AccessMgmtState = {
  userAccessData: [],
  openAccessReqData: [],
  archiveReqData: [],
  rolesData: [],
  countryData: [],
  clientsData: [],
  reclaimMsgData: [],
  mobileMsgData: [],
  languagesData: [],
  timeZonesData: [],
  locatonsList: [],
  rolesList: [],
  countryCodesList: [],
  statesList: [],
  modulesList: [],
  uploadedImage: [],
  base64Image: [],
  convertedEndDate: [],
  previewImage: [],
  activeDirectoryData: [],
  activeDirectoryUsersList: [],
  isLoaderShow: false,
  previewFile: [],
};

export const AccessMgmtSlice = createSlice({
  name: "Access Management",
  initialState,
  reducers: {
    setUserAccessData: (state, { payload }) => {
      state.userAccessData = payload;
    },
    setIsLoaderShow: (state, { payload }) => {
      state.isLoaderShow = payload;
    },
    setOpenAccessReqData: (state, { payload }) => {
      state.openAccessReqData = payload;
    },
    setArchiveReqData: (state, { payload }) => {
      state.archiveReqData = payload;
    },
    setRolesData: (state, { payload }) => {
      state.rolesData = payload;
    },
    setLungaguesData: (state, { payload }) => {
      state.languagesData = payload;
    },
    setTimeZonesData: (state, { payload }) => {
      state.timeZonesData = payload;
    },
    setLocationData: (state, { payload }) => {
      state.locatonsList = payload;
    },
    setRolesList: (state, { payload }) => {
      state.rolesList = payload;
    },
    setCountryCodesList: (state, { payload }) => {
      state.countryCodesList = payload;
    },
    setStatesList: (state, { payload }) => {
      state.statesList = payload;
    },
    setModulesList: (state, { payload }) => {
      state.modulesList = payload;
    },
    setUploadedImage: (state, { payload }) => {
      state.uploadedImage = payload;
    },
    setPreviewImage: (state, { payload }) => {
      state.previewImage = payload;
    },
    setBase64Image: (state, { payload }) => {
      state.base64Image = payload;
    },
    setConvertedEndDate: (state, { payload }) => {
      state.convertedEndDate = payload;
    },
    setActiveDirectoryData: (state, { payload }) => {
      state.activeDirectoryData = payload;
    },
    setActiveDirectoryUsersData: (state, { payload }) => {
      state.activeDirectoryUsersList = payload;
    },
    setPreviewFile: (state, { payload }) => {
      state.previewFile = payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setOpenAccessReqData,
  setUserAccessData,
  setArchiveReqData,
  setRolesData,
  setLungaguesData,
  setTimeZonesData,
  setLocationData,
  setCountryCodesList,
  setRolesList,
  setStatesList,
  setModulesList,
  setUploadedImage,
  setBase64Image,
  setConvertedEndDate,
  setPreviewImage,
  setActiveDirectoryData,
  setActiveDirectoryUsersData,
  setIsLoaderShow,
  setPreviewFile
} = AccessMgmtSlice.actions;

export default AccessMgmtSlice.reducer;
