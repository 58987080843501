import "./ContactsDetailsPanel.scss";
import {
  TabComponent,
  TabItemDirective,
  TabItemsDirective,
} from "@syncfusion/ej2-react-navigations";
import {
  tabsConfigContactDetails,
  tabsConfigContactDetailsForCommodityType,
  tabsConfigContactDetailsForDeptAndGLAcc,
  tabsConfigContactDetailsForPalletLocation,
} from "./tabsConfigContactDetails";
import StoreContactView from "./GeneralTabView";
import WarehouseContactView from "./ContactTabView";
import TabsTopView from "./TabsTopView";
import NotesTabView from "./NotesTabView";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { ContactType } from "../contactTabs/constants";

const ContactsDetailsPanel = () => {
  const setTabHeading = useSelector(
    (state: RootState) => state.contactReducer.tabHeading
  );
  const dispatch = useDispatch();

  return (
    <div>
      <TabsTopView />
      {setTabHeading.payload === ContactType.DEPARTMENTS ||
      setTabHeading.payload === ContactType.GL_ACCOUNTS ? (
        <TabComponent
          heightAdjustMode="Auto"
          cssClass="orientation-tab preview-custom-tab"
        >
          <TabItemsDirective>
            {tabsConfigContactDetailsForDeptAndGLAcc.map((tabName) => (
              <TabItemDirective
                header={{ text: tabName }}
                content={
                  tabName === tabsConfigContactDetailsForDeptAndGLAcc[0]
                    ? () => <StoreContactView />
                    : () => <NotesTabView />
                }
              />
            ))}
          </TabItemsDirective>
        </TabComponent>
      ) : setTabHeading.payload === ContactType.COMMODITY_TYPES ||
        setTabHeading.payload === ContactType.STORE_DISPOSITIONS ||
        setTabHeading.payload === ContactType.UPCDefaultWHLoc ||
        setTabHeading.payload === ContactType.CustomWHLoc ||
        setTabHeading.payload === ContactType.WAREHOUSE_LOCATIONS ? (
        <TabComponent
          heightAdjustMode="Auto"
          cssClass="orientation-tab preview-custom-tab"
        >
          <TabItemsDirective>
            {tabsConfigContactDetailsForCommodityType.map((tabName) => (
              <TabItemDirective
                header={{ text: tabName }}
                content={() => <StoreContactView />}
              />
            ))}
          </TabItemsDirective>
        </TabComponent>
      ) : setTabHeading.payload === ContactType.PALLET_LOCATIONS ? (
        <TabComponent
          heightAdjustMode="Auto"
          cssClass="orientation-tab preview-custom-tab"
        >
          <TabItemsDirective>
            {tabsConfigContactDetailsForPalletLocation.map((tabName) => (
              <TabItemDirective
                header={{ text: tabName }}
                content={() => <StoreContactView />}
              />
            ))}
          </TabItemsDirective>
        </TabComponent>
      ) : (
        <TabComponent
          heightAdjustMode="Auto"
          cssClass="orientation-tab preview-custom-tab"
        >
          <TabItemsDirective>
            {tabsConfigContactDetails.map((tabName) => (
              <TabItemDirective
                header={{ text: tabName }}
                content={
                  tabName === tabsConfigContactDetails[0]
                    ? () => <StoreContactView />
                    : tabName === tabsConfigContactDetails[1]
                    ? () => <WarehouseContactView />
                    : () => <NotesTabView />
                }
              />
            ))}
          </TabItemsDirective>
        </TabComponent>
      )}
    </div>
  );
};

export default ContactsDetailsPanel;
