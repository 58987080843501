// Do not change

export const invoiceGridConfig = [
  [
    { field: "invoiceNumber", header: "INV#", dataType: "number" },
    { field: "description", header: "DESC", width: "180", textAlign: "left" },
    {
      field: "customerName",
      header: "Customer",
      width: "160",
      textAlign: "left",
    },
    { field: "period", header: "PERIOD" },
    { field: "cost", header: "COST", dataType: "number", textAlign: "right" },
    { field: "price", header: "PRICE", dataType: "number", textAlign: "right" },
    { field: "createdDate", header: "CREATED", width: "110" },
    { field: "facility", header: "FACILITY", width: "110" },
    {
      field: "status",
      header: "STATUS",
      dataType: "enum",
      width: "150",
      class: "statusShow",
    },
  ],
  [
    { field: "CMid", header: "CM#"},
    { field: "invoiceNumber", header: "INV#", dataType: "number" },
    { field: "description", header: "DESC", width: "160", textAlign: "left" },
    {
      field: "customerName",
      header: "Customer",
      width: "150",
      textAlign: "left",
    },
    { field: "period", header: "PERIOD" },
    // { field: "cost", header: "COST", dataType: "number", textAlign: "right" },
    { field: "price", header: "PRICE", dataType: "number", textAlign: "right" },
    { field: "credit", header: "Credit", dataType: "number", textAlign: "right" },
    { field: "createdDate", header: "CREATED", width: "90" },
    { field: "facility", header: "FACILITY", width: "100" },
    {
      field: "status",
      header: "STATUS",
      dataType: "enum",
      width: "150",
      class: "statusShow",
    },
  ],
  // [
  //   { field: "CMid", header: "CM#", dataType: "number" },
  //   { field: "invoiceNumber", header: "INV#", dataType: "number" },
  //   { field: "description", header: "DESC", width: "160", textAlign: "left" },
  //   {
  //     field: "customerName",
  //     header: "Customer",
  //     width: "150",
  //     textAlign: "left",
  //   },
  //   { field: "period", header: "PERIOD" },
  //   { field: "price", header: "PRICE", dataType: "number", textAlign: "right" },
  //   { field: "credit", header: "Credit", dataType: "number", textAlign: "right" },
  //   { field: "createdDate", header: "CREATED", width: "90" },
  //   { field: "reclaimCenterName", header: "FACILITY", width: "100" },
  //   {
  //     field: "status",
  //     header: "STATUS",
  //     dataType: "enum",
  //     width: "150",
  //     class: "statusShow",
  //   },
  // ],
  [
    { field: "bolId", header: "BOL#", dataType: "number" },
    {
      field: "customerName",
      header: "Customer",
      width: "150",
      textAlign: "left",
    },
    {
      field: "description",
      header: "Description",
      width: "150",
      textAlign: "left",
    },
    // { field: "carrierName", header: "CARRIER", width: "120", textAlign: "left" },
    { field: "pickUp", header: "Pickup", width: "120", textAlign: "left" },
    { field: "cityState", header: "Delivery", width: "150" },
    // { field: "reclaimCenterName", header: "FROM" },
    { field: "pickUpDate", header: "Pickup Date" },
    { field: "calendarYear", header: "PERIOD" },
    // { field: "createdDate", header: "CREATED", width: "100" },
    // { field: "reclaimCenterName", header: "FACILITY", width: "125" },
    {
      field: "status",
      header: "STATUS",
      width: "150",
      dataType: "enum",
    },
  ],
];

export const bolGridConfig = [
  [
    { field: "bolId", header: "BOL#", dataType: "number" },
    {
      field: "description",
      header: "Description",
      width: "150",
      textAlign: "left",
    },
    { field: "carrierName", header: "CARRIER", width: "120" },
    { field: "reclaimCenterName", header: "FROM" },
    { field: "customerName", header: "TO", textAlign: "left" },
    { field: "calendarYear", header: "PERIOD" },
    { field: "createdDate", header: "CREATED", width: "125" },
    { field: "reclaimCenterName", header: "FACILITY", width: "125" },
    {
      field: "status",
      header: "STATUS",
      width: "150",
      dataType: "enum",
    },
  ],
];

export const actionHistoryGridConfig = [
  { field: "status", header: "STATUS CHANGE", width: "250" },
  { field: "lastModifiedby", header: "LAST MODIFIED BY", width: "250" },
  { field: "lastModifiedDate", header: "DATE MODIFIED", width: "250" },
];

export const actionHistoryGridConfigAR = [
  { field: "status", header: "STATUS CHANGE", width: "250" },
  { field: "userName", header: "LAST MODIFIED BY", width: "250" },
  { field: "lastModifiedDate", header: "DATE MODIFIED", width: "250" },
];

export const invoicesTabs = ["AR INVOICES", "CREDIT MEMOS"];
export const bolTabs = ["BOLs"];

export enum InvoiceScreen {
  INVOICE = "INVOICE",
  BOL = "BOL",
  BOLVIEW = "BOLVIEW",
}

export const invoiceView = "Inv & CM";
export const bolUrl = "bols";
export const bolViewUrl = "bolsView";
