import Grid from "../../grid/Grid";
import { ButtonComponent } from "@syncfusion/ej2-react-buttons";
import { clientIdMgmtHeaders, generateGridData } from "../utils";
import { RootState } from "../../../redux/store";
import { useSelector } from "react-redux";
import LLAdminPreviewTab from "../previewTab/previewPane";
import { ReactComponent as Add } from "../../../icons/AddNew.svg";
import loader from "../../../assets/loader.gif";

const ClientIdManagament = () => {
  /* Get Client data from Grid Reducer */
  const selectedRowData = useSelector(
    (state: RootState) => state.gridReducer.selectedRowData
  );
  // console.log(selectedRowData, 'selectedRowData');

  /* Get Client data from LL admin Reducer */
  const clientMgmt = useSelector(
    (state: RootState) => state.lladminReducer.clientsData
  );

  const loaderStatus = useSelector(
    (state: RootState) => state.lladminReducer.isLoaderShow
  );
  let gridHieght = "calc(100vh - 450px)";

  return (
    <div>
      <div className="col-sm-12 col-md-12 col-lg-12 pt-5">
        <div className="col-sm-6 pl-0">
          <p className="child-tab-title">Client ID Management</p>
        </div>
        <div className="col-sm-6">
          <ButtonComponent className="iconCopy float-right ml-1">
            <Add />
          </ButtonComponent>
        </div>
      </div>
      <div className="col-sm-12 col-md-12 col-lg-12 ">
        <div className="control-pane">
          <div className="control-section tab-control-section">
            <div className="col-sm-12 col-md-12 col-lg-12 ">
              <div className="col-sm-8 col-md-12 col-lg-8 pl-0 pr-4 pt-4 " style={{width: '75%'}}>
                <>
                {loaderStatus ? (
                <div className="LoadImg">               
        <img className="loaderClass" src={loader} />
      </div> ) : 
      <Grid
      config={clientIdMgmtHeaders}
      data={generateGridData("Client ID Management", clientMgmt)}
      width={"100%"}
      height={gridHieght}/>
       }  
    </>
              </div>
              {selectedRowData && selectedRowData.length > 0 ? (
                <div className="col-sm-4 col-md-12 col-lg-4 pl-4 pt-0 child-data-section" style={{width: '25%',marginTop: '16px'}}>
                  <LLAdminPreviewTab previewData={selectedRowData} />
                </div>
              ) : (
                <div className="col-sm-4 col-md-12 col-lg-4 pl-4 pt-4 " style={{width: '25%'}}>
                  <div className="childTab-preview-pane d-flex align-items-center">
                    <div className="col-sm-12 col-md-12 col-lg-12 d-flex justify-content-center">
                      <p className="preview-msg">
                        Select client to view details
                      </p>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ClientIdManagament;
