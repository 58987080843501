import {
  TabComponent,
  TabItemDirective,
  TabItemsDirective,
} from "@syncfusion/ej2-react-navigations";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import OpenRequest from "./open/open";
import ArchiveRequest from "./archive/archive";
import { setRowData } from "../../grid/gridReducer";
import { setTabNameForRowData } from "../../contactTabs/contactReducer";
import notify from "../../../services/toasterService";
import axios from "axios";
import { getAccessMgmtEndPoint } from "../utils";
import { setArchiveReqData, setOpenAccessReqData } from "../accessmgmtReducer";

const accessRequestTabs = [
  { text: "Open", id: "0" },
  { text: "Closed", id: "1" },
];

const AccessMgmt = () => {
  const dispatch = useDispatch();
  let selectedTab: string;

  useEffect(() => {
    dispatch(setRowData([]));
    dispatch(setTabNameForRowData(""));
  });

  /**
   * On page this event will get you the selected tab details
   * @author Anudeep
   * @param e - event from Syncfusion tabs
   */
  const getAccessReqTabEvent = (e: any) => {
    if (e !== null) {
      const selectedId = e.selectingID;
      accessRequestTabs.map((item) => {
        if (item.id === selectedId) {
          selectedTab = item.text;
          getAccessReqTab(selectedTab);
        } else {
          // Write some error condition
        }
      });
    } else {
      // Write some error condition
    }
  };

  const TabChangeEventAccessReq = async (e: any) => {
    dispatch(setRowData([]));
    selectedTab = e.selectingItem.innerText;
    await getAccessReqTab(e.selectingItem.innerText);
  };

  /**
   * Get selected web services
   * @author Anudeep
   * @param TabName - Selected Tab name
   */
  const getAccessReqTab = async (TabName: any) => {
    //  const token = sessionStorage.getItem('token');
    // const token = UserService.getToken();
    const endPointURL = getAccessMgmtEndPoint(TabName);
    // await axios.post(endPointURL, { }, { headers: { 'Authorization': `Bearer ${token}` }}).then((res: { data: any; }) => {
      if(TabName==='Open') {
        await axios
        .get(endPointURL, {})
        .then((res: { data: any }) => {
          console.log(res.data);
             dispatch(setOpenAccessReqData(res.data));
        })
        .catch((err: any) => {
          console.log(err.response);
          notify(err.response.data.error, "error");
        });
      } else {
    await axios
      .get(endPointURL, {})
      .then((res: { data: any }) => {
        console.log(res.data);
          dispatch(setArchiveReqData(res.data));
      })
      .catch((err: any) => {
        console.log(err.response);
        notify(err.response.data.error, "error");
      });
    }
  };

  return (
    <>
      <div className="col-sm-12 p-0">
        <p className="header-name-access-sub">Access Requests</p>
      </div>

      <div className="col-sm-12 col-md-12 col-lg-12 p-0">
        <div className="control-pane">
          <div className="control-section tab-control-section">
            <TabComponent
              heightAdjustMode="Auto"
              cssClass="orientation-tab ll-custom-tab"
              selecting={TabChangeEventAccessReq}
              ref={(tab) => getAccessReqTabEvent(tab)}
            >
              <TabItemsDirective>
                <TabItemDirective
                  header={accessRequestTabs[0]}
                  content={openReq}
                />

                <TabItemDirective
                  header={accessRequestTabs[1]}
                  content={archiveReq}
                />
              </TabItemsDirective>
            </TabComponent>
          </div>
        </div>
      </div>
    </>
  );

  function archiveReq() {
    return (
      <div>
        <div className="col-sm-12 col-md-12 col-lg-12 ">
          <ArchiveRequest />
        </div>
      </div>
    );
  }

  function openReq() {
    return (
      <div>
        <div className="col-sm-12 col-md-12 col-lg-12 pt-2">
          <OpenRequest />
        </div>
      </div>
    );
  }
};

export default AccessMgmt;
