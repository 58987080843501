import {closeOutBoundBox, getEndpoint} from './utils';
import {EndPoints} from './constants';
import moment from 'moment';
import {PrintManager} from '../printer/printermanager';
import {DialogUtility} from '@syncfusion/ej2-popups';

export class OutBoundBoxUtil {
    public static getStatusMessageBody(
        closedId: any,
        warehouseLocation: any,
        type: string
    ) {
        return (
            "<div class='outbound-box-status-alert'><div>Outbound " +
            type +
            ' Closed Successfully</div>' +
            '<h3>' +
            type +
            ' : ' +
            closedId +
            '</h3><div>WH Location : <b>' +
            warehouseLocation +
            ' </b></div></div>'
        );
    }

    public static showStatusMessage(bodyContent: string, resetFocusToUpc: any) {
        resetFocusToUpc();
        const dialogUtility = DialogUtility.alert({
            animationSettings: {effect: 'Zoom'},
            content: bodyContent,
            title: 'Status',
            position: {X: 'center', Y: 'center'},
            isModal: true,
            cssClass: 'outbound-box-status',
            okButton: {
                text: 'Ok', click: () => {
                    dialogUtility.destroy();
                    //doing explicitly this way otherwise focus not going back.
                    document.getElementById("upcValue")?.focus();
                }
            },
        });
    }

    public static getPalletPrintLabelRequest(
        data: any,
        scanSettings: any,
        loggedInUserId: any
    ) {
        let count;
        let itemCount;
        let scanMode;
        if (data?.isBoxPallet) {
            count = data?.closedBoxes;
            itemCount = data?.items || data?.currentQuantity;
            scanMode = 'BOX';
        } else {
            count = data?.bins;
            itemCount = data?.items || data?.currentQuantity;
            scanMode = 'BIN';
        }
        let closedDate = moment().format('L');
        if (data?.closedOn) {
            closedDate = moment(data?.closedOn).format('L');
        }
        return {
            palletId: data?.palletId || '',
            closedOn: closedDate,
            openedDate: moment(data?.createdDate).format('L'),
            boxCount: count || '',
            itemCount: itemCount || '',
            warehouseLocation: data?.warehouseLocation || '',
            palletLocation: data?.palletLocation || '',
            groupCode: scanSettings.scangroup || '',
            reclaimCenter: scanSettings.reclaimCenter || '',
            closedBy: data?.closedBy || loggedInUserId || '',
            scanMode: scanMode,
        };
    }

    public static async processOutBoundBinClosedAction(
        scanSettings: any,
        id: any,
        payload: any,
        resetFocusToUpc: any,
        loggedInUserId: any
    ) {
        let isBinClosed = '';
        const requestPayload = {
            scanGroup: scanSettings.scangroup,
            scanStation: scanSettings.scanStation,
            reclaimCenter: scanSettings.reclaimCenter,
            binIds: id,
        };
        const endpoint: any = getEndpoint(EndPoints.CLOSEOUTBOUNDBINS);
        const result = closeOutBoundBox(endpoint, requestPayload);
        await result.then((data: any) => {
            if (data && data.data.message) {
                isBinClosed = 'true';
                OutBoundBoxUtil.showStatusMessage(
                    OutBoundBoxUtil.getStatusMessageBody(
                        payload.binId,
                        payload.warehouseLocation,
                        'BIN'
                    ),
                    resetFocusToUpc
                );
                PrintManager.printLabel(payload, 'bin', loggedInUserId, 1);
                if (data.data.data[0]?.pallet?.closedOn) {
                    OutBoundBoxUtil.processPalletCloseAction(
                        data.data.data[0],
                        scanSettings,
                        resetFocusToUpc,
                        loggedInUserId,
                        true
                    );
                }
            }
        });
        return isBinClosed;
    }

    public static processMultiplePalletClosedAction(
        scanSettings: any,
        item: any,
        resetFocusToUpc: any,
        loggedInUserId: any
    ) {
        const requestPayload = {
            palletIds: [item.id],
            scanGroup: scanSettings.scangroup,
            reclaimCenter: scanSettings.reclaimCenter,
        };
        const endpoint: any = getEndpoint(EndPoints.CLOSEPALLET);
        const result = closeOutBoundBox(endpoint, requestPayload);
        result.then((data: any) => {
            if (data && data.data.message) {
                OutBoundBoxUtil.showStatusMessage(
                    OutBoundBoxUtil.getStatusMessageBody(
                        item.palletId,
                        item.warehouseLocation,
                        'Pallet'
                    ),
                    resetFocusToUpc
                );
                let palletItem = item;
                if (data.data.message.pallets) {
                    palletItem = data.data.message.pallets[0];
                }
                PrintManager.printLabel(
                    OutBoundBoxUtil.getPalletPrintLabelRequest(
                        palletItem,
                        scanSettings,
                        loggedInUserId
                    ),
                    'pallet'
                );
            }
        });
    }

    public static processPalletCloseAction(
        data: any,
        scanSettings: any,
        resetFocusToUpc: any,
        loggedInUserId: any,
        isBin?: boolean
    ) {
        const printPalletObj = {
            palletId: data?.pallet?.palletId,
            closedOn: moment(data?.pallet?.closedOn).format('L'),
            openedDate: moment(data?.pallet?.createdDate).format('L'),
            boxCount: isBin ? data?.pallet?.bins : data?.pallet?.closedBoxes,
            itemCount: data?.pallet?.items,
            warehouseLocation: data?.warehouseLocation,
            palletLocation: data?.palletLocation,
            groupCode: scanSettings.scangroup,
            reclaimCenter: scanSettings.reclaimCenter,
            closedBy: loggedInUserId,
            scanMode: isBin ? 'BIN' : 'BOX',
        };
        OutBoundBoxUtil.showStatusMessage(
            OutBoundBoxUtil.getStatusMessageBody(
                data?.pallet?.palletId,
                data?.warehouseLocation,
                'Pallet'
            ),
            resetFocusToUpc
        );
        PrintManager.printLabel(printPalletObj, 'pallet');
    }

    public static async processOutboundBoxCloseAction(
        scanSettings: any,
        id: any,
        payload?: any,
        resetFocusToUpc?: any,
        loggedInUserId?: any
    ) {
        let isBoxClosed = '';
        const requestPayload = {
            scanGroup: scanSettings.scangroup,
            scanStation: scanSettings.scanStation,
            reclaimCenter: scanSettings.reclaimCenter,
            outboundBoxIds: id,
        };
        const endpoint: any = getEndpoint(EndPoints.CLOSEOUTBOUND);
        const result = closeOutBoundBox(endpoint, requestPayload);
        await result
            .then((data: any) => {
                if (data) {
                    isBoxClosed = 'true';
                    OutBoundBoxUtil.showStatusMessage(
                        OutBoundBoxUtil.getStatusMessageBody(
                            payload.outboundBoxId,
                            payload.warehouseLocation,
                            'Box'
                        ),
                        resetFocusToUpc
                    );
                    PrintManager.printLabel(
                        payload,
                        'box',
                        loggedInUserId,
                        data.data.data[0]?.boxNumberOnPallet + 1
                    );
                    if (data.data.data[0]?.pallet?.closedOn) {
                        OutBoundBoxUtil.processPalletCloseAction(
                            data.data.data[0],
                            scanSettings,
                            resetFocusToUpc,
                            loggedInUserId,
                            false
                        );
                    }
                }
            })
            .catch((e) => {
                console.error(e);
                isBoxClosed = 'false';
                const bodyContent =
                    "<div class='outbound-box-status-alert'><div>Outbound Box Failed to Close.</div>" +
                    '<h3> BOX : ' +
                    payload.outboundBoxId +
                    '</h3><div>WH Location : ' +
                    payload.warehouseLocation +
                    ' </div></div>';
                OutBoundBoxUtil.showStatusMessage(bodyContent, resetFocusToUpc);
            });

        return isBoxClosed;
    }
}
