import "./addScreen.scss";
//import "@syncfusion/ej2-base/styles/material.css";
import "@syncfusion/ej2-buttons/styles/material.css";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import AddCurrencyCode from "../currencycode/addCurrencyCode";
import AddCountryCode from "../countrycode/addCountryCode";
import AddLanguage from "../language/addLanguage";
import AddTimeZone from "../timezone/addTimeZone";
import LLModal from "../../common/llModal/LLModal";
import AddMessage from "../messagemanagement/addMessage";

const AddLlAdminScreen = (props: any) => {
  const dispatch = useDispatch();
  const [tabNameFinal, setTabNameFinal] = useState("");
  const { addScreen, setAddScreen, tabName } = props;

  useEffect(() => {
    const res = tabName?.charAt(tabName.length - 1);
    if (res === "s") {
      setTabNameFinal(tabName?.slice(0, -1));
    } else {
      setTabNameFinal(tabName);
    }
  });

  return (
    <>
      <LLModal
        handleClose={() => setAddScreen(false)}
        show={addScreen}
        width={800}
      >
        <div className="row ml-0 mr-0 mb-30 modal-title-header">
          {tabName === "Currency Codes" ? (
            <span className="tabNameForAddScreen">Add Currency Code</span>
          ) : tabName === "Country Codes" ? (
            <span className="tabNameForAddScreen">Add Country Code</span>
          ) : tabName === "Languages" ? (
            <span className="tabNameForAddScreen">Add Language</span>
          ) : tabName === "Time Zones" ? (
            <span className="tabNameForAddScreen">Add Time Zone</span>
          ) : tabName === "Reclaim Scan" || tabName === "Mobile" ? (
            <span className="tabNameForAddScreen">Add Message</span>
          ) : (
            ""
          )}
        </div>
        {tabName === "Currency Codes" ? (
          <AddCurrencyCode setAddScreen={setAddScreen} />
        ) : tabName === "Country Codes" ? (
          <AddCountryCode setAddScreen={setAddScreen} />
        ) : tabName === "Languages" ? (
          <AddLanguage setAddScreen={setAddScreen} />
        ) : tabName === "Time Zones" ? (
          <AddTimeZone setAddScreen={setAddScreen} />
        ) : tabName === "Reclaim Scan" || tabName === "Mobile" ? (
          <AddMessage setAddScreen={setAddScreen} setTabName={tabName} />
        ) : (
          ""
        )}
      </LLModal>
    </>
  );
};

export default AddLlAdminScreen;
