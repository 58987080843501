import React, { useEffect, useState } from "react";
import { ButtonComponent } from "@syncfusion/ej2-react-buttons";
import fileIcon from "../../../icons/File.png";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
// import {colorModelMockData} from "./generalSettingsData";
import {
  getGeneralSettings,
  saveGeneralSettingsById,
  updatGeneralSettingsById,
} from "../utils";
import axios from "axios";
import { setGeneralSettingsListData } from "../settingsReducers";
import notify from "../../../services/toasterService";

const GeneralSettings = () => {
  const dispatch = useDispatch();

  const generalSettingsListData = useSelector(
    (state: RootState) => state.settingsReducer?.generalSettings
  );

  let [enableSaveButton, setEnableSaveBtn] = useState(false);

  /* calling save dgeneral settings api on laod */
  useEffect(() => {
    getSavedGeneralSettings();
  }, []);

  const colorModelMockData = [
    { id: 1, name: "Light Mode" },
    { id: 2, name: "Dark Mode" },
  ];

  const [colorModesData, settingColorModesData] = useState<any>([]);
  let [numberOfMaxRows, setNumberOfMaxRows] = useState("");
  let [selectedColorMode, setSelectedColorMode] = useState("");
  let [generalSettingsList, setGeneralSettingsList] = useState<any>([]);
  // settingColorModesData(colorModelMockData)

  /* color mode change event */
  const handleColorMode = (e: any) => {
    setEnableSaveBtn(true);
    setSelectedColorMode(e);
  };

  /* Number of input change event */
  const handleNumberOfRows = (e: any) => {
    setEnableSaveBtn(true);
    setNumberOfMaxRows(e);
  };

  /* get saved scan settings Api call */
  const getSavedGeneralSettings = async () => {
    setEnableSaveBtn(false);
    numberOfMaxRows = "";
    selectedColorMode = "";
    // scanSettingsList = [];
    const endPointUrl = getGeneralSettings();
    await axios
      .get(endPointUrl)
      .then((resp) => {
        generalSettingsList.push(resp.data);
        dispatch(setGeneralSettingsListData(generalSettingsList));
        if (generalSettingsList) {
          generalSettingsList.map((item: any) => {
            setNumberOfMaxRows(item.maxRows);
            setSelectedColorMode(item.colorMode);
            // document.getElementById('colorMode').value(item.colorMode)
          });
        }
      })
      .catch((err) => {
        console.log(err);
        notify(
          "Error! Unable to fetch data. Please try after sometime.",
          "error"
        );
      });
  };

  /* Save General Settings WS call */
  const saveGeneralettings = () => {
    const reqBody = {
      maxRows: numberOfMaxRows,
      colorMode: selectedColorMode,
    };

    let body = reqBody;
    if (selectedColorMode === "" || numberOfMaxRows === "") {
      notify(" Please  fill fields before save");
    } else {
      if (generalSettingsListData.length > 0) {
        let selectedSettingsId = generalSettingsListData[0].id;
        const endPointURL = updatGeneralSettingsById(selectedSettingsId);
        axios
          .put(endPointURL, body)
          .then((res) => {
            console.log(res);
            notify(" Updated Successfully", "success");
            getSavedGeneralSettings();
          })
          .catch((err) => {
            console.log(err);
            notify(
              "Error! Unable to update data. Please try after sometime.",
              "error"
            );
          });
      } else {
        const endPointURL = saveGeneralSettingsById();
        axios
          .post(endPointURL, body)
          .then((res) => {
            notify(" Saved Successfully", "success");
            getSavedGeneralSettings();
          })
          .catch((err) => {
            console.log(err);
            notify(
              "Error! Unable to save data. Please try after sometime.",
              "error"
            );
          });
      }
    }
  };

  /* check input format function */
  const formatInput = (e: any) => {
    // Prevent characters that are not numbers ("e", ".", "+" & "-") ✨
    let checkIfNum;
    if (e.key !== undefined) {
      // Check if it's a "e", ".", "+" or "-"
      checkIfNum =
        e.key === "e" || e.key === "." || e.key === "+" || e.key === "-";
    } else if (e.keyCode !== undefined) {
      // Check if it's a "e" (69), "." (190), "+" (187) or "-" (189)
      checkIfNum =
        e.keyCode === 69 ||
        e.keyCode === 190 ||
        e.keyCode === 187 ||
        e.keyCode === 189;
    }
    return checkIfNum && e.preventDefault();
  };

  return (
    <div className="col-sm-12 col-md-12 col-lg-12">
      <div className="row settingsTitleRow">
        <p className="col-sm-11 col-md-11 col-lg-11 settings-tab-title fw-bold pb-0 pl-3">
          General Settings
        </p>
        <ButtonComponent
          cssClass="e-outline opt-btn-dark float-right ml-1 mb-1"
          style={
            enableSaveButton === true
              ? { opacity: "1", pointerEvents: "all", cursor: "pointer" }
              : { opacity: "0.5", pointerEvents: "none" }
          }
          onClick={(e) => saveGeneralettings()}
        >
          <img src={fileIcon} alt="File " />
        </ButtonComponent>
      </div>
      <div className="col-sm-12 col-md-12 col-lg-12">
        <div className="row">
          <div className="col-sm-3 col-md-3 col-lg-3">
            <span className=" settingslblClass fw-bold" style={{float:'left'}}>
              Number of rows in table
            </span>
          </div></div>
          <div className="row" style={{marginTop:'5px'}}>
          <div className="col-sm-2 col-md-2 col-lg-2">
            <input
              className=" form-control"
              type="number"
              onKeyDown={formatInput}
              value={numberOfMaxRows}
              placeholder="Number of rows"
              onChange={(e) => handleNumberOfRows(e.target.value)}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-sm-2 col-md-2 col-lg-2">
            <span className=" settingslblClass fw-bold" style={{float:'left'}}>Color Mode</span>
          </div></div>
          <div className="row" style={{marginTop:'5px'}}>
          <div className="col-sm-2 col-md-2 col-lg-2">
            <select
              className="form-control"
              value={selectedColorMode}
              onChange={(e: any) => handleColorMode(e.target.value)}
            >
              {colorModelMockData?.map((cm) => (
                <option value={cm?.name}>{cm?.name}</option>
              ))}
            </select>
          </div>
        </div>
        <div className="row">
          {/* <div className="col-sm-2 col-md-2 col-lg-2">
            <span className=" settingslblClass fw-bold">Keyboard Shortcuts</span>
          </div> */}
          <div className="col-sm-2 col-md-2 col-lg-2">
            <span className=" settingslblClass" style={{float:'left'}}>Keyboard Shortcuts</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GeneralSettings;
