import { createSlice } from "@reduxjs/toolkit";

export interface LlAdminState {
  currencyData: any[];
  laungageData: any[];
  timezoneData: any[];
  clientsData: any[];
  countryData: any[];
  reclaimMsgData: any[];
  mobileMsgData: any[];
  msgMgmtData: any[];
  isLoaderShow: boolean;
}

const initialState: LlAdminState = {
  currencyData: [],
  laungageData: [],
  timezoneData: [],
  countryData: [],
  clientsData: [],
  reclaimMsgData: [],
  mobileMsgData: [],
  msgMgmtData: [],
  isLoaderShow: false
};

export const LlAdminSlice = createSlice({
  name: "LL Admin",
  initialState,
  reducers: {
    setCurrencyData: (state, { payload }) => {
      state.currencyData = payload;
    },
    setCountryData: (state, { payload }) => {
      state.countryData = payload;
    },
    setLanguageData: (state, { payload }) => {
      state.laungageData = payload;
    },
    setTimezoneData: (state, { payload }) => {
      state.timezoneData = payload;
    },
    setClientsData: (state, { payload }) => {
      state.clientsData = payload;
    },
    setReclaimMsgData: (state, { payload }) => {
      state.reclaimMsgData = payload;
    },
    setMobileMsgData: (state, { payload }) => {
      state.mobileMsgData = payload;
    },
    setMsgMgmtData: (state, { payload }) => {
      state.msgMgmtData = payload;
    },
    setIsLoaderShow: (state, { payload }) => {
      state.isLoaderShow = payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setCurrencyData,
  setLanguageData,
  setTimezoneData,
  setCountryData,
  setClientsData,
  setReclaimMsgData,
  setMobileMsgData,
  setMsgMgmtData,
  setIsLoaderShow
} = LlAdminSlice.actions;

export default LlAdminSlice.reducer;
