import { ButtonComponent } from '@syncfusion/ej2-react-buttons';
import { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { useDispatch } from 'react-redux';
import ContentHeader from '../../common/ContentHeader/ContentHeader';
import Divider from '../../common/divider/Divider';
import { EndPoints } from '../constants';
import { setReasonCode } from '../scanReducer';
import { getEndpoint, getListofValues } from '../utils';

const ForceToTrashModal = forwardRef((props: any, ref) => {
    const { setAddScreen, clearModal, resetFocusToUpc } = props;
    const [reasonCodeList, setReasonCodeList] = useState([]);
    const [reasonCode, setReasonCodes] = useState('');
    const [errors, setErrors] = useState({
        reasonCodeError: '',
    });
    let reasonCodeError = '';
    const dispatch = useDispatch();
    let getreasonCodeById = document.getElementById(
        'reason-code'
    ) as HTMLInputElement;
    useEffect(() => {
        // getReasonCodeList();
    }, []);

    useImperativeHandle(ref, () => ({
        clearForm() {
            getreasonCodeById.value = '';
        },
    }));

    const validate = () => {
        if (reasonCode === '') {
            reasonCodeError = 'Reason Code is required';
        }
        if (reasonCodeError) {
            setErrors({ reasonCodeError });
            return false;
        } else {
            return true;
        }
    };

    const handleCancel = () => {
        setAddScreen(false);
        if (resetFocusToUpc) {
            resetFocusToUpc();
        }
        clearModal();
    };
    const handleReasonCode = () => {
        if (validate()) {
            dispatch(setReasonCode(reasonCode));
            setAddScreen(false);
            clearModal();
            props.handleForceToTrash(reasonCode, 'FTT');

            if (resetFocusToUpc) {
                resetFocusToUpc();
            }
        }
    };
    const getReasonCodeList = () => {
        const endpoint: any = getEndpoint(EndPoints.REASONCODES);
        const result = getListofValues(endpoint);
        result.then((data: any) => {
            // notify();
            console.log(data.data.reasonCodes);
            setReasonCodeList(data.data.reasonCodes);
        });
    };
    return (
        <>
            <ContentHeader value="Force To Trash" />
            <Divider />
            <div className="col-sm-12 p5">
                <div className="col-sm-12">
                    <div className="text-center p1 fb-15">
                        Please select reason code for force to trash item
                    </div>
                </div>
                <div className="col-sm-4 p5"></div>
                <div className="col-sm-4 p5">
                    <select
                        className="form-control"
                        id="reason-code"
                        onChange={(e) => setReasonCodes(e.target.value)}
                    >
                        <option value="">Select</option>
                        {props.reasonCodeList?.map((rc: any) => (
                            <option value={rc?.reasonCode}>
                                {rc?.reasonCode}
                            </option>
                        ))}
                    </select>
                    {reasonCode === '' ? (
                        <span className="text-danger">
                            {errors.reasonCodeError}
                        </span>
                    ) : (
                        ''
                    )}
                </div>
                <div className="col-sm-4 p5"></div>
                <div className="col-sm-9 mt-2rem">
                    <ButtonComponent
                        cssClass="eg-btn-primary float-right btnBox"
                        onClick={() => handleReasonCode()}
                    >
                        Ok
                    </ButtonComponent>
                    <ButtonComponent
                        cssClass="eg-btn-secondary float-right mr-4"
                        onClick={() => handleCancel()}
                    >
                        Cancel
                    </ButtonComponent>
                </div>
            </div>
        </>
    );
});
export default ForceToTrashModal;
