import {
  ButtonComponent,
  SwitchComponent,
} from "@syncfusion/ej2-react-buttons";
import axios from "axios";
import { useState } from "react";
import { addMasterDataEndPoint, getEndPoint } from "./utils";
import userIcon from "../../assets/userIcon.png";
import { setCurrentContact } from "./contactReducer";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { ContactType } from "./constants";
import notify from "../../services/toasterService";

const StoreAddScreen = (props: any) => {
  const { setAddScreen } = props;
  const [showAltAddressInfo, setShowAltAddressInfo] = useState(false);
  const [selectedImage, setSelectedImage] = useState();
  const dispatch = useDispatch();
  const [storeNumber, setStoreNumber] = useState("");
  const [storeName, setStoreName] = useState("");
  const [storeType, setStoreType] = useState("");
  const [storeSuperGrpoup, setStoreSuperGrpoup] = useState("");
  const [storeClientGrpoup, setStoreClientGrpoup] = useState("");
  const [storeStatus, setStoreStatus] = useState("");
  const [storeReasonCode, setStoreReasonCode] = useState("");
  const [storeEmail, setStoreEmail] = useState("");
  const [storePhone, setStorePhone] = useState("");
  const [storeAltPhone, setStoreAltPhone] = useState("");
  const [storeFax, setStoreFax] = useState("");
  const [storeStartDate, setStoreStartDate] = useState("");
  const [storeEndDate, setStoreEndDate] = useState("");
  const [storeAddressStreet, setStoreAddressStreet] = useState("");
  const [storeAddressCity, setStoreAddressCity] = useState("");
  const [storeAddressState, setStoreAddressState] = useState("");
  const [storeAddressZip, setStoreAddressZip] = useState("");
  const [storeAddressCountry, setStoreAddressCountry] = useState("");
  const [storeLanguage, setStoreLanguage] = useState("");
  const [storeCurrencyCode, setStoreCurrencyCode] = useState("");
  const [storeTimeZone, setStoreTimeZone] = useState("");
  const [storeUnderReview, setStoreUnderReview] = useState(false);
  const [showReasonCodeDIv, setShowReasonCodeDIv] = useState(false);

  const currencyData = useSelector(
    (state: RootState) => state.contactReducer?.currencyData
  );
  const timezoneData = useSelector(
    (state: RootState) => state.contactReducer?.timezoneData
  );
  const languageData = useSelector(
    (state: RootState) => state.contactReducer?.laungageData
  );
  const storeTypeData = useSelector(
    (state: RootState) => state.contactReducer?.storeTypeData
  );
  const countryInfo = useSelector(
    (state: RootState) => state.contactReducer?.countryData
  );
  const superGroupData = useSelector(
    (state: RootState) => state.contactReducer?.superGroupData
  );
  const clientGroupData = useSelector(
    (state: RootState) => state.contactReducer?.clientGroupData
  );
  const statusData = useSelector(
    (state: RootState) => state.contactReducer?.statusData
  );
  const reasonCodeData = useSelector(
    (state: RootState) => state.contactReducer?.reasonCodeData
  );
  const statesInfo = useSelector(
    (state: RootState) => state.contactReducer?.statesData
  );
  const imageChange = (e: any) => {
    if (e.target.files && e.target.files.length > 0) {
      setSelectedImage(e.target.files[0]);
    }
  };

  const handleAltAdress = () => {
    setShowAltAddressInfo(true);
  };
  const handleAltAdressClose = () => {
    setShowAltAddressInfo(false);
  };
  const handleStoreNumber = (e: any) => {
    setStoreNumber(e.target.value);
  };
  const handleStoreName = (e: any) => {
    setStoreName(e.target.value);
  };
  const handleStoreType = (e: any) => {
    setStoreType(e.target.value);
  };
  const handleStoreSuperGroup = (e: any) => {
    setStoreSuperGrpoup(e.target.value);
  };
  const handleStoreClientGroup = (e: any) => {
    setStoreClientGrpoup(e.target.value);
  };
  const handleStoreStatus = (e: any) => {
    setStoreStatus(e.target.value);
    if (e.target.value === "Hold") {
      setShowReasonCodeDIv(true);
    } else {
      setShowReasonCodeDIv(false);
    }
  };
  const handleStoreReasonCode = (e: any) => {
    setStoreReasonCode(e.target.value);
  };
  const handleStoreEmail = (e: any) => {
    setStoreEmail(e.target.value);
  };
  const handleStorePhone = (e: any) => {
    setStorePhone(e.target.value);
  };
  const handleStoreAltPhone = (e: any) => {
    setStoreAltPhone(e.target.value);
  };
  const handleStoreFax = (e: any) => {
    setStoreFax(e.target.value);
  };
  const handleStoreStartDate = (e: any) => {
    setStoreStartDate(e.target.value);
  };
  const handleStoreEndDate = (e: any) => {
    setStoreEndDate(e.target.value);
  };
  const handleStoreAddressStreet = (e: any) => {
    setStoreAddressStreet(e.target.value);
  };
  const handleStoreAddressCity = (e: any) => {
    setStoreAddressCity(e.target.value);
  };
  const handleStoreAddressState = (e: any) => {
    setStoreAddressState(e.target.value);
  };
  const handleStoreAddressZip = (e: any) => {
    setStoreAddressZip(e.target.value);
  };
  const handleStoreAddressCountry = (e: any) => {
    setStoreAddressCountry(e.target.value);
  };
  const handleStoreLanguage = (e: any) => {
    setStoreLanguage(e.target.value);
  };
  const handleStoreCurrencyCode = (e: any) => {
    setStoreCurrencyCode(e.target.value);
  };
  const handleStoreTimezone = (e: any) => {
    setStoreTimeZone(e.target.value);
  };
  const handleSwitchStoreUnderReview = (e: any) => {
    setStoreUnderReview(e.target.checked);
  };

  const body = {
    active: true,
    address: [
      {
        addressLine1: "",
        addressLine2: "",
        alternateAddress: true,
        city: storeAddressCity,
        country: storeAddressCountry,
        deleted: false,
        state: storeAddressState,
        street: storeAddressStreet,
        zipcode: storeAddressZip,
      },
    ],
    alternateCustomerNumber: "",
    alternatePhoneNumber: storeAltPhone,
    chainNumber: "string",
    clientGroup: storeClientGrpoup,
    // "companyCode": {
    //   "active": true,
    //   "address": [
    //     {
    //       "addressLine1": "string",
    //       "addressLine2": "string",
    //       "alternateAddress": true,
    //       "city": "string",
    //       "country": "string",
    //       "deleted": true,
    //       "id": 0,
    //       "state": "string",
    //       "street": "string",
    //       "zipcode": "string"
    //     }
    //   ],
    //   "alternatePhoneNumber": "string",
    //   "client": {
    //     "clientId": "string",
    //     "clientName": "string",
    //     "id": 0
    //   },
    //   "clientId": 0,
    //   "companyCode": "string",
    //   "companyName": "string",
    //   "currencyCode": "string",
    //   "deleted": true,
    //   "email": "string",
    //   "fax": "string",
    //   "id": 0,
    //   "language": "string",
    //   "notes": "string",
    //   "phoneNumber": "string",
    //   "timezone": "string",
    //   "webUrl": "string"
    // },
    currencyCode: storeCurrencyCode,
    deactivationDate: "",
    deleted: false,
    email: storeEmail,
    endDate: storeEndDate,
    fax: storeFax,
    fillerOne: "",
    fillerTwo: "",
    language: storeLanguage,
    notes: "string",
    phoneNumber: storePhone,
    reasonCode: storeReasonCode,
    reclaimCustomerGroupName: "",
    reclaimCustomerGroupNumber: "",
    startDate: storeStartDate,
    status: storeStatus,
    //"storeLogo": "string",
    storeName: storeName,
    storeNumber: storeNumber,
    storeType: storeType,
    superGroup: storeSuperGrpoup,
    timezone: storeTimeZone,
    underReview: storeUnderReview,
  };
  let tabName: any = ContactType.STORES;
  const handleAddstore = () => {
    const endpoint = addMasterDataEndPoint(tabName);
    const getEndpoint = getEndPoint(tabName);
    handleMasterDataChange(endpoint, getEndpoint);
  };

  const handleMasterDataChange = async (
    endpoint: string,
    getEndpoint: string
  ) => {
    const result = await axios.post(endpoint, body);
    if (result && result.data) {
      setAddScreen(false);
      notify("Done");
    } else {
      notify("Error");
    }
    const getresult = await axios.get(getEndpoint);
    dispatch(setCurrentContact(getresult.data));
  };
  const handleCancelstorePopup = () => {
    setAddScreen(false);
  };
  return (
    <div>
      <div className="row">
        <div className="col-sm-2 textAlign-center mt-6rem">
          {/* <input accept="image/*" type="file" onChange={imageChange} /> */}
          <img src={userIcon} className="userAddIcon" />
          {selectedImage && (
            <div className="imagePreviewDiv">
              <img
                src={URL.createObjectURL(selectedImage)}
                className="imagePreview"
                height="100"
                width="100"
              />
            </div>
          )}
        </div>

        <div className="col-sm-5">
          <div className="row ml-0 mr-0 mt-1rem">
            <div className="col-sm-4">
              <span className="lblClass">Store Number</span>
            </div>
            <div className="col-sm-8">
              <input
                type="text"
                className="form-control"
                onChange={(e) => handleStoreNumber(e)}
              />
            </div>
          </div>
          <div className="row ml-0 mr-0 mt-1rem">
            <div className="col-sm-4">
              <span className="lblClass">Name</span>
            </div>
            <div className="col-sm-8">
              <input
                type="text"
                className="form-control"
                onChange={(e) => handleStoreName(e)}
              />
            </div>
          </div>
          <div className="row ml-0 mr-0 mt-1rem">
            <div className="col-sm-4">
              <span className="lblClass">Store Type</span>
            </div>
            <div className="col-sm-8">
              <select
                className="form-control"
                onChange={(e) => handleStoreType(e)}
              >
                <option value="0">Select</option>
                {storeTypeData?.map((st) => (
                  <option value={st.storeType}>{st.storeType}</option>
                ))}
              </select>
            </div>
          </div>
          <div className="row ml-0 mr-0 mt-1rem">
            <div className="col-sm-4">
              <span className="lblClass">Super Group</span>
            </div>
            <div className="col-sm-8">
              <select
                className="form-control"
                onChange={(e) => handleStoreSuperGroup(e)}
              >
                <option value="0">Select</option>
                {superGroupData?.map((sg) => (
                  <option value={sg.superGroup}>{sg.superGroup}</option>
                ))}
              </select>
            </div>
          </div>
          <div className="row ml-0 mr-0 mt-1rem">
            <div className="col-sm-4">
              <span className="lblClass">Client Group</span>
            </div>
            <div className="col-sm-8">
              <select
                className="form-control"
                onChange={(e) => handleStoreClientGroup(e)}
              >
                <option value="0">Select</option>
                {clientGroupData?.map((cg) => (
                  <option value={cg.clientGroupName}>
                    {cg.clientGroupName}
                  </option>
                ))}
              </select>
            </div>
          </div>
          {/* <div className="row ml-0 mr-0 mt-1rem">
            <div className="col-sm-4">
              <span className="lblClass">Status</span>
            </div>
            <div className="col-sm-8">
              <select className="form-control" onChange={(e) => handleStoreStatus(e)}>
              <option value="0">Select</option>
              {statusData.map((s) =>
                <option value={s.status}>{s.status}</option>
                )}
              </select>
            </div>
          </div> */}
          <div className="row ml-0 mr-0 mt-1rem">
            <div className="col-sm-4">
              <span className="lblClass">Under Review</span>
            </div>
            <div className="col-sm-8">
              <SwitchComponent
                onChange={(e: any) => handleSwitchStoreUnderReview(e)}
              />
            </div>
          </div>
          {showReasonCodeDIv ? (
            <div className="row ml-0 mr-0 mt-1rem">
              <div className="col-sm-4">
                <span className="lblClass">Reason Code</span>
              </div>
              <div className="col-sm-8">
                <select
                  className="form-control"
                  onChange={(e) => handleStoreReasonCode(e)}
                >
                  <option value="0">Select</option>
                  {reasonCodeData.map((rc) => (
                    <option value={rc.reasonCode}>{rc.reasonCode}</option>
                  ))}
                </select>
              </div>
            </div>
          ) : (
            ""
          )}
          <div className="row ml-0 mr-0 mt-1rem">
            <div className="col-sm-4">
              <span className="lblClass">Email</span>
            </div>
            <div className="col-sm-8">
              <input
                type="text"
                className="form-control"
                placeholder="someone@email.com"
                onChange={(e) => handleStoreEmail(e)}
              />
            </div>
          </div>
          <div className="row ml-0 mr-0 mt-1rem">
            <div className="col-sm-4">
              <span className="lblClass">Phone</span>
            </div>
            <div className="col-sm-8">
              <input
                type="text"
                className="form-control txtPhone"
                onChange={(e) => handleStorePhone(e)}
              />
            </div>
          </div>
          <div className="row ml-0 mr-0 mt-1rem">
            <div className="col-sm-4">
              <span className="lblClass">Alt Phone</span>
            </div>
            <div className="col-sm-8">
              <input
                type="text"
                className="form-control txtPhone"
                onChange={(e) => handleStoreAltPhone(e)}
              />
            </div>
          </div>
          <div className="row ml-0 mr-0 mt-1rem">
            <div className="col-sm-4">
              <span className="lblClass">Fax</span>
            </div>
            <div className="col-sm-8">
              <input
                type="text"
                className="form-control"
                onChange={(e) => handleStoreFax(e)}
              />
            </div>
          </div>
          <div className="row ml-0 mr-0 mt-1rem">
            <div className="col-sm-4">
              <span className="lblClass">Start Date</span>
            </div>
            <div className="col-sm-8 input-control">
              <input
                type="date"
                className="form-control"
                onChange={(e) => handleStoreStartDate(e)}
              />
            </div>
          </div>
          <div className="row ml-0 mr-0 mt-1rem">
            <div className="col-sm-4">
              <span className="lblClass">End Date</span>
            </div>
            <div className="col-sm-8 input-control">
              <input
                type="date"
                className="form-control"
                onChange={(e) => handleStoreEndDate(e)}
              />
            </div>
          </div>
        </div>
        <div className="col-sm-5">
          <div className="row ml-0 mr-0 mt-1rem">
            <div className="col-sm-4">
              <span className="lblClass">Language</span>
            </div>
            <div className="col-sm-8">
              <select
                className="form-control"
                onChange={(e) => handleStoreLanguage(e)}
              >
                <option>Select</option>
                {languageData?.map((ld) => (
                  <option value={ld?.isoLongCode}>{ld?.isoLongCode}</option>
                ))}
              </select>
            </div>
          </div>
          <div className="row ml-0 mr-0 mt-1rem">
            <div className="col-sm-4">
              <span className="lblClass">Currency Code</span>
            </div>
            <div className="col-sm-8">
              <select
                className="form-control"
                onChange={(e) => handleStoreCurrencyCode(e)}
              >
                <option>Select</option>
                {currencyData?.map((cc) => (
                  <option value={cc.currency}>{cc.currency}</option>
                ))}
              </select>
            </div>
          </div>
          <div className="row ml-0 mr-0 mt-1rem">
            <div className="col-sm-4">
              <span className="lblClass">Time Zone</span>
            </div>
            <div className="col-sm-8">
              <select
                className="form-control"
                onChange={(e) => handleStoreTimezone(e)}
              >
                <option>Select</option>
                {timezoneData?.map((tz) => (
                  <option value={tz.timezoneName}>
                    {tz.timezoneName}
                    {tz.utc}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="row ml-0 mr-0 mt-1rem">
            <div className="col-sm-4">
              <span className="lblClass">Address</span>
            </div>
            <div className="col-sm-8">
              <input
                type="text"
                className="form-control"
                placeholder="Street"
                onChange={(e) => handleStoreAddressStreet(e)}
              />
            </div>
          </div>
          <div className="row ml-0 mr-0 mt-1rem">
            <div className="col-sm-4"></div>
            <div className="col-sm-8">
              <input
                type="text"
                className="form-control"
                placeholder="City"
                onChange={(e) => handleStoreAddressCity(e)}
              />
            </div>
          </div>
          <div className="row ml-0 mr-0 mt-1rem">
            <div className="col-sm-4"></div>
            <div className="col-sm-5">
              <select
                className="form-control"
                onChange={(e) => handleStoreAddressState(e)}
              >
                <option value="">Select State</option>
                {statesInfo?.map((cn) => (
                  <option value={cn.stateName}>{cn.stateName}</option>
                ))}
              </select>
            </div>
            <div className="col-sm-3">
              <input
                type="text"
                className="form-control"
                placeholder="ZIP"
                onChange={(e) => handleStoreAddressZip(e)}
              />
            </div>
          </div>
          <div className="row ml-0 mr-0 mt-1rem">
            <div className="col-sm-4"></div>
            <div className="col-sm-8">
              <select
                className="form-control"
                onChange={(e) => handleStoreAddressCountry(e)}
              >
                <option>Select Country</option>
                {countryInfo?.map((cn) => (
                  <option value={cn.countryName}>{cn.countryName}</option>
                ))}
              </select>
            </div>
          </div>
          {/* <div className="row ml-0 mr-0 mt-1rem">
            <div className="col-sm-6">
            {showAltAddressInfo ?
              <span className="lblClass cursor-pointer" onClick={() => handleAltAdressClose()}><img className="" src={closeIcon} /> <span>Alt Address</span></span>
              :<span className="lblClass cursor-pointer" onClick={() => handleAltAdress()}><img className="addIconImgAltAddress" src={addIcon} /> <span>Alt Address</span></span>}
            </div>
          </div> */}

          {showAltAddressInfo ? (
            <div className="row ml-0 mr-0 mt-1rem">
              <div className="col-sm-4"></div>
              <div className="col-sm-8">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Street"
                />
                <div className="row ml-0 mr-0 mt-1rem">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="City"
                  />
                </div>
                <div className="row ml-0 mr-0 mt-1rem">
                  <div className="col-sm-7 pl-0">
                    <select className="form-control">
                      <option value="">Select State</option>
                      {statesInfo?.map((cn) => (
                        <option value={cn.stateName}>{cn.stateName}</option>
                      ))}
                    </select>
                  </div>
                  <div className="col-sm-5 pr-0">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="ZIP"
                    />
                  </div>
                </div>
                <div className="row ml-0 mr-0 mt-1rem">
                  <select className="form-control">
                    <option>Select Country</option>
                    {countryInfo?.map((cn) => (
                      <option value={cn.countryName}>{cn.countryName}</option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
          ) : (
            <div></div>
          )}
        </div>
      </div>
      <div className="row mt-2rem">
        <ButtonComponent
          cssClass="eg-btn-primary float-right"
          onClick={() => handleAddstore()}
        >
          ADD STORE
        </ButtonComponent>
        <ButtonComponent
          cssClass="eg-btn-secondary float-right mr-4"
          onClick={handleCancelstorePopup}
        >
          CANCEL
        </ButtonComponent>
      </div>
    </div>
  );
};
export default StoreAddScreen;
