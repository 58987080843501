import { createSlice } from '@reduxjs/toolkit';

export interface scanState {
    tabHeading: any;
    scanMode: any;
    scanModeList: any;
    itemDetails: any;
    scanSettings: any;
    ScanHistoryData: any;
    upcScanHistoryData: any;
    openInboundDetails: any;
    reasonCode: any;
    outBoundBoxDetails: any;
    outBoundBinDetails: any;
    scanSessionItemDetails: any;
    enableTouchKeyboard: any;
    inputValue: any;
    forceTrashMode: any;
    scanHistoryDbCount: any;
    scanDone: any;
    scannedSessionItemsCount: any;
    subDispositionsByState: any;
    userScanQty: any;
    isOutboundBox: any;
    canCloseBin: any;
    isBoxClosed: any;
    isMaxOutboundBoxApproved: any;
    isMaxInboundBoxApproved: any;
}

const initialState: scanState = {
    tabHeading: [],
    scanMode: [],
    scanModeList: [],
    itemDetails: [],
    scanSettings: [],
    ScanHistoryData: [],
    upcScanHistoryData: [],
    openInboundDetails: [],
    reasonCode: [],
    outBoundBoxDetails: undefined,
    outBoundBinDetails: undefined,
    scanSessionItemDetails: [],
    enableTouchKeyboard: true,
    inputValue: [],
    forceTrashMode: [],
    scanHistoryDbCount: 0,
    scanDone: false,
    scannedSessionItemsCount: [],
    subDispositionsByState: [],
    userScanQty: [],
    isOutboundBox: false,
    canCloseBin: false,
    isBoxClosed: false,
    isMaxOutboundBoxApproved: false,
    isMaxInboundBoxApproved: false,
};
export const scanSlice = createSlice({
    name: 'scans',
    initialState,
    reducers: {
        setTabHeading: (state, payload) => {
            state.tabHeading = payload;
        },
        setScanModeReducer: (state, payload) => {
            state.scanMode = payload;
        },
        setScanModeListReducer: (state, payload) => {
            state.scanModeList = payload;
        },
        setItemDetails: (state, payload) => {
            state.itemDetails = payload;
        },
        setScanSettings: (state, payload) => {
            state.scanSettings = payload;
        },
        setScanHistoryData: (state, payload) => {
            state.ScanHistoryData = payload;
        },
        setupcScanHistoryData: (state, payload) => {
            state.upcScanHistoryData = payload;
        },
        setInboundBoxDetails: (state, payload) => {
            state.openInboundDetails = payload;
        },
        setReasonCode: (state, payload) => {
            state.reasonCode = payload;
        },
        setOutboundBoxDetails: (state, payload) => {
            state.outBoundBoxDetails = payload;
        },
        setOutboundBinDetails: (state, payload) => {
            state.outBoundBinDetails = payload;
        },
        setScanSessionItemDetails: (state, payload) => {
            state.scanSessionItemDetails = payload;
        },
        setEnableTouchKeyboard: (state, payload) => {
            state.enableTouchKeyboard = payload;
        },
        setInputValue: (state, payload) => {
            state.inputValue = payload;
        },
        setForceTrashMode: (state, payload) => {
            state.forceTrashMode = payload;
        },
        setScanHistoryDbCount: (state, payload) => {
            state.scanHistoryDbCount = payload;
        },
        setScanDone: (state, payload) => {
            state.scanDone = payload;
        },
        setScannedSessionItemsCount: (state, payload) => {
            state.scannedSessionItemsCount = payload;
        },
        setSubDispositionsByState: (state, payload) => {
            state.subDispositionsByState = payload;
        },
        setUserScanQty: (state, payload) => {
            state.userScanQty = payload;
        },
        setIsOutboundBox: (state, payload) => {
            state.isOutboundBox = payload;
        },
        setCanCloseBin: (state, payload) => {
            state.canCloseBin = payload;
        },
        setIsBoxClosed: (state, payload) => {
            state.isBoxClosed = payload;
        },
        setIsMaxOutboundBoxApproved: (state, payload) => {
            state.isMaxOutboundBoxApproved = payload;
        },
        setIsMaxInboundBoxApproved: (state, payload) => {
            state.isMaxInboundBoxApproved = payload;
        },
    },
});

// Action creators are generated for each case reducer function
export const {
    setTabHeading,
    setScanModeReducer,
    setScanModeListReducer,
    setItemDetails,
    setScanSettings,
    setScanHistoryData,
    setupcScanHistoryData,
    setInboundBoxDetails,
    setReasonCode,
    setOutboundBoxDetails,
    setScanSessionItemDetails,
    setOutboundBinDetails,
    setEnableTouchKeyboard,
    setInputValue,
    setForceTrashMode,
    setScanHistoryDbCount,
    setScanDone,
    setScannedSessionItemsCount,
    setSubDispositionsByState,
    setUserScanQty,
    setIsOutboundBox,
    setCanCloseBin,
    setIsBoxClosed,
    setIsMaxOutboundBoxApproved,
    setIsMaxInboundBoxApproved,
} = scanSlice.actions;

export default scanSlice.reducer;
