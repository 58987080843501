import { useEffect } from 'react';
import { ScanReportDetails } from '../scan-reports/scan-report-details';
import { ScanReportsList } from '../scan-reports/scan-reports-list';

type Props = {
    viewReportDetails: boolean;
    setViewReportDetails(value: boolean): void;
    selectedRowId: number | undefined;
    setSelectedRowId(value: number | undefined): void;
};
const ScanReportBuilder = (props: Props) => {
    useEffect(() => {}, []);

    return (
        <div className="col-sm-12 col-md-12 col-lg-12 ">
            {!props.viewReportDetails ? (
                <div className="reports-list">
                    <ScanReportsList
                        setSelectedRowId={props.setSelectedRowId}
                        selectedRowId={props.selectedRowId}
                        setViewReportDetails={props.setViewReportDetails}
                        viewReportDetails={props.viewReportDetails}
                    />
                </div>
            ) : (
                <div className="report-details">
                    <ScanReportDetails
                        setViewReportDetails={props.setViewReportDetails}
                        selectedRowId={props.selectedRowId}
                        setSelectedRowId={props.setSelectedRowId}
                    />
                </div>
            )}
        </div>
    );
};

export default ScanReportBuilder;
