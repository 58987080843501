import { FC, useEffect, useState } from "react";
import {
  ColumnDirective,
  ColumnsDirective,
  CommandColumn,
  DataStateChangeEventArgs,
  Edit,
  Filter,
  Grid,
  GridComponent,
  Inject,
  Page,
  Sort,
} from "@syncfusion/ej2-react-grids";
import { useDispatch, useSelector } from "react-redux";
import "../Grid.scss";
import {
  setSelectedRCIdForBin,
  setSelectedRecrdsExport,
  setVendors,
} from "../../contactTabs/contactReducer";
import { customizeCell } from "../CustomCell";
import { setRowId, setStoresORVendorsRowData } from "../gridReducer";
import {
  FilterOptions,
  getDefaultFilterOperator,
  pageOptions,
  selectionOptions,
} from "../gridConfig";
import ServiceHandler from "../../../services/service-handler";
import { filterQuery } from "../grid-events";
import { config } from "../config";
import { RootState } from "../../../redux/store";
import ContactsDetailspanel from "../../ContactsDetailsPanel/ContactsDetailsPanel";
import loader from "../../../assets/loader.gif";

interface Props {
  hideSpinner?: boolean;
}

const VendorsGrid: FC<Props> = (props: Props) => {
  const [loaderStatus, setLoaderStatus] = useState(true)
  let gridHieght = "calc(100vh - 385px)";

  const dispatch = useDispatch();

  let gridRef: Grid;

  const selectedRowData = useSelector(
    (state: RootState) => state.gridReducer?.selectedRowData
  );
  const StoreOrVendorData = useSelector(
    (state: RootState) => state?.contactReducer?.updateStoreOrVendorData
  )

  const [selectedStoresRowData, setSelectedStoresRowData] = useState<any>();
  const tabName = "Vendor";

  const handleSelectedStoreRow = (event: any) => {
    dispatch(setSelectedRecrdsExport(gridRef.getSelectedRecords()));
    if (gridRef && undefined !== event.data.id) {
      ServiceHandler.VendorsService.getItem(event.data.id).then((data) => {
        dispatch(setStoresORVendorsRowData([]));
        //dispatch(setRowData([data]));
        setSelectedStoresRowData([data]);
        dispatch(setStoresORVendorsRowData([data]));
        console.log(selectedStoresRowData + "11");
        dispatch(setSelectedRCIdForBin(event.data.id));
        dispatch(setRowId(event.data));
      });
    }
  };

  const onFirstGridCreated = () => {
    if (gridRef) {
      !props.hideSpinner && gridRef.showSpinner();
    }
  };

  const dataStateChange = (state: DataStateChangeEventArgs) => {
    ServiceHandler.VendorsService.getFilteredItems(filterQuery(state)).then(
      (data) => {
        setLoaderStatus(false)
        if (gridRef) {
          console.log(data);
          gridRef.dataSource = data;
          console.log(gridRef);
          dispatch(setVendors(data.result));
          dispatch(setSelectedRecrdsExport(data.result));
          if (StoreOrVendorData && StoreOrVendorData?.length > 0) {
            const event = {data : {id: data?.result[0].id }}
            handleSelectedStoreRow(event)
            //dispatch(setStoresORVendorsRowData([data?.result[0]]));
          }
        }
      }
    );
  };

  useEffect(() => {
    dataStateChange({ skip: 0 });
    setLoaderStatus(true)
  }, [StoreOrVendorData]);

  return (
    <>
      <div className="contacts-wrapper">
        <div className="masterDataGrid">
          <GridComponent
            id="FGrid"
            ref={(g) => {
              if (g) gridRef = g;
            }}
            enableStickyHeader={true}
            dataStateChange={dataStateChange}
            queryCellInfo={customizeCell}
            rowHeight={48}
            height={gridHieght}
            created={onFirstGridCreated}
            allowSorting={true}
            allowPaging={true}
            filterSettings={FilterOptions}
            allowFiltering={true}
            selectionSettings={selectionOptions}
            pageSettings={pageOptions}
            rowSelected={(event) => handleSelectedStoreRow(event)}
          >
            <ColumnsDirective>
              <ColumnDirective type="checkbox" width="50" />
              {config["Vendors"].map((col: any) => {
                return (
                  <ColumnDirective
                    filter={getDefaultFilterOperator(col.dataType)}
                    key={col.field}
                    field={col.field}
                    headerText={col.header}
                    width={col.width ? col.width : "100"}
                    textAlign={col?.textAlign}
                  />
                );
              })}
            </ColumnsDirective>
            <Inject services={[Page, Sort, Filter, CommandColumn, Edit]} />
          </GridComponent>
          <div className="LoadImg">
                {loaderStatus ? (
        <img className="loaderClass" src={loader} />
      ) : <></>}
      </div>
        </div>
        {selectedStoresRowData && selectedStoresRowData.length > 0 ? (
          <div className="detailsTab">
            <ContactsDetailspanel />
          </div>
        ) : (
          <>
            <div className="detailsTabEmpty d-flex align-items-center">
              <div className="col-sm-12 col-md-12 col-lg-12 d-flex justify-content-center">
                <p className="selectTextForEmpty ">
                  Select {tabName} to view details{" "}
                </p>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};
export default VendorsGrid;
