import { ButtonComponent } from '@syncfusion/ej2-react-buttons';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../redux/store';
import ContentHeader from '../../common/ContentHeader/ContentHeader';
import Divider from '../../common/divider/Divider';

const HazardousModal = (props: any) => {
    const { setAddScreen, clearModal, resetFocusToUpc } = props;
    const [subDispositions, setSubDispositions] = useState('');
    const [errors, setErrors] = useState({
        subDispositionError: '',
    });
    const getSubDispositions = useSelector(
        (state: RootState) => state.scanReducer.subDispositionsByState
    );
    let subDispositionError = '';
    const handleCancel = () => {
        setAddScreen(false);
        resetFocusToUpc();
        clearModal();
    };

    const handleSubDispositions = () => {
        if (props.isFrom === 'frocetrashmain') {
            props.handleForceToTrash(subDispositions);
            setAddScreen(false);
            resetFocusToUpc();
            clearModal();
        } else {
            if (validate()) {
                props.handleHazardous(subDispositions);
                setAddScreen(false);
                resetFocusToUpc();
                clearModal();
            }
        }
    };
    const validate = () => {
        if (subDispositions === '') {
            subDispositionError = 'Sub-disposition is required';
        }
        if (subDispositionError) {
            setErrors({ subDispositionError });
            return false;
        } else {
            return true;
        }
    };
    return (
        <>
            <ContentHeader value="Hazardous Force to Trash" />
            <Divider />
            <div className="col-sm-12">
                <div className="text-center p5 fb-15">
                    Please select sub-disposition for hazardous force to trash
                    item
                </div>
            </div>
            <div className="col-sm-4 p5"></div>
            <div className="col-sm-4 p5">
                <select
                    className="form-control"
                    onChange={(e) => setSubDispositions(e.target.value)}
                >
                    <option value="">Select</option>
                    {getSubDispositions.payload?.map((sd: any) => (
                        <option value={sd.code}>
                            {' '}
                            {sd.code + '(' + sd.description + ')'}
                        </option>
                    ))}
                </select>
                {subDispositions === '' ? (
                    <span className="text-danger">
                        {errors.subDispositionError}
                    </span>
                ) : (
                    ''
                )}
            </div>
            <div className="col-sm-4 p5"></div>
            <div className="col-sm-9 mt-2rem">
                <ButtonComponent
                    cssClass="eg-btn-primary float-right btnBox"
                    onClick={() => handleSubDispositions()}
                >
                    Ok
                </ButtonComponent>
                <ButtonComponent
                    cssClass="eg-btn-secondary float-right mr-4"
                    onClick={() => handleCancel()}
                >
                    Cancel
                </ButtonComponent>
            </div>
        </>
    );
};

export default HazardousModal;
