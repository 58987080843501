import { reportParametersMapping } from './components/reports/utils';
import { useSelector } from 'react-redux';
import { useMemo } from 'react';

/* eslint-disable */
var viewerStyle = {'height': '700px', 'width': '100%'};

function ReportViewer(props) {
  const { reportName,reportServiceUrl,reportServerUrl,accessToken } = props;

  const onExportItemClick = (event) => {
    let today = new Date().toLocaleDateString();
    event.fileName = `${reportName} ${today}`;
  }

  const getParameterSettings = () => {
    const reportNameLowerCase = reportName.toLowerCase();
    const parameters = reportParametersMapping[reportNameLowerCase];
    const settings = { showRequiredFieldIndicator: true,  popupHeight: "200px", };
    if (parameters) {
      settings.optionalGroupSettings = [
        {
          names: parameters,
          promptMessage: "Atleast one parameter value is required"
        }
      ]
    }
    return settings;
  }

  const parameterSettings = useMemo(() => {
    return getParameterSettings();
  },[])

  return (
    <div style={viewerStyle}>
     <BoldReportViewerComponent
     id="reportviewer-container"
     reportServiceUrl = {reportServiceUrl}
     reportServerUrl= {reportServerUrl}
     autoRender= {false}
     serviceAuthorizationToken = {`bearer ${accessToken}`}
     reportPath = {`/Completed Reports/${reportName}`} 
     enablePopupResize={true}
     exportItemClick={onExportItemClick}
     parameterSettings = {parameterSettings} />
    </div>
  );
}

export default ReportViewer;