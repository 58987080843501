import {
    FilterSettingsModel,
    PageSettingsModel,
    SelectionSettingsModel,
} from '@syncfusion/ej2-react-grids';

export const pageOptions: PageSettingsModel = {
    pageSize: 50,
    pageSizes: ['50', '100', '150', '200'],
};
export const FilterOptions: FilterSettingsModel = {
    columns: [],
    type: 'FilterBar',
    mode: 'Immediate',
    showFilterBarStatus: true,
    immediateModeDelay: 500,
    operators: {},
    showFilterBarOperator: true,
    ignoreAccent: true,
};
export const selectionOptions: SelectionSettingsModel = {
    checkboxMode: 'ResetOnRowClick',
    mode: 'Row',
    type: 'Single',
};
export const getDefaultFilterOperator = (dataType = 'string') => {
    if (dataType === 'number') {
        return { operator: 'equal' };
    } else if (dataType === 'enum') {
        return { operator: 'startswith' };
    }
    return { operator: 'contains' };
};

export interface Predicate {
    key: string;
    operation: string;
    value: any;
}

export interface Sort {
    key?: string;
    direction?: string;
}

export interface Page {
    pageNumber: number;
    pageSize: number;
}

export interface SearchCriteriaRequest {
    filters?: Predicate[];
    sort?: Sort;
    page: Page;
}
