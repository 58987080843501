import * as React from 'react';

type Props = {
    onScanModeChange(mode: string): void;
    scanMode: string;
};
export const ScanModes = (props: Props) => {
    return (
        <div className="scan-mode-types">
            <div
                className="type-group"
                onClick={() => {
                    props.onScanModeChange('box');
                }}
            >
                <input
                    className="type-radio-button"
                    type="radio"
                    value={props.scanMode}
                    name="scanMode"
                    checked={props.scanMode === 'box'}
                    readOnly={true}
                />
                <span className="radio-button-title">Box</span>
            </div>
            <div
                className="type-group"
                onClick={() => {
                    props.onScanModeChange('bin');
                }}
            >
                <input
                    className="type-radio-button"
                    type="radio"
                    value={props.scanMode}
                    name="scanMode"
                    checked={props.scanMode === 'bin'}
                    readOnly={true}
                />
                <span className="radio-button-title">Bin</span>
            </div>
            <div
                className="type-group"
                onClick={() => {
                    props.onScanModeChange('pallet');
                }}
            >
                <input
                    className="type-radio-button"
                    type="radio"
                    value="pallet"
                    name="scanMode"
                    checked={props.scanMode === 'pallet'}
                    readOnly={true}
                />
                <span className="radio-button-title">Pallet</span>
            </div>
        </div>
    );
};
