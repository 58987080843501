//import "@syncfusion/ej2-base/styles/material.css";
import "@syncfusion/ej2-buttons/styles/material.css";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import LLModal from "../../common/llModal/LLModal";
import ApproveOpenRequest from "../accessrequest/open/addOpen";

const AddAccessMgmtScreen = (props: any) => {
  const dispatch = useDispatch();
  const [tabNameFinal, setTabNameFinal] = useState("");
  const { addScreen, setAddScreen, tabName } = props;

  useEffect(() => {
    const res = tabName?.charAt(tabName.length - 1);
    if (res === "s") {
      setTabNameFinal(tabName?.slice(0, -1));
    } else {
      setTabNameFinal(tabName);
    }
  });

  return (
    <>
      <LLModal
        handleClose={() => setAddScreen(false)}
        show={addScreen}
        width={800}
      >
        <div className="row ml-0 mr-0 mb-30 modal-title-header">
          {tabName === "Open" ? (
            <span className="tabNameForAddScreen">Approve Selected</span>
          ) : (
            ""
          )}
        </div>
        {tabName === "Open" ? (
          <ApproveOpenRequest setAddScreen={setAddScreen} />
        ) : (
          ""
        )}
      </LLModal>
    </>
  );
};

export default AddAccessMgmtScreen;
