import userIcon from "../../../assets/userIcon.png";
import editIcon from "../../../assets/EditRuleExcep.png";
import { useDispatch, useSelector } from "react-redux";
import { ButtonComponent } from "@syncfusion/ej2-react-buttons";
import { useEffect, useState } from "react";
import AddFileUploadScreen from "../addScreen/fileUpload";
import {
  CheckBoxSelection,
  Inject,
  MultiSelectComponent,
} from "@syncfusion/ej2-react-dropdowns";
import "./../checkboxes.scss";
import { RootState } from "../../../redux/store";
import axios from "axios";
import notify from "../../../services/toasterService";
import { addAccessReqEndPoint, getAccessMgmtEndPoint } from "../utils";
import { setRolesData, setRolesList } from "../accessmgmtReducer";

const Resetpopup = (props: any) => {
  const { setAddScreen, setTabName } = props;
  const [addScreen, setFileUploadAddScreen] = useState(false);
  const [addScreenTabName, setAddScreenTabName] = useState(null);

  const [myName, setMyName] = useState("");
  const [myDescription, setMyDescription] = useState("");
  const [myLinkModules, setMyLinkModules] = useState("");

  const [roleName, setRoleName] = useState("");
  const [roleDescription, setRoleDescription] = useState("");
  let [modules, setModules] = useState<any>([]);

  let [rolesDisabeled, setRoleDisabeled] = useState(true);

  useEffect(() => {
    setRoleDisabeled(true);
    setModules([]);
  }, []);

  const handlePopupActionLocation = (selectedTabName: any) => {
    setFileUploadAddScreen(true);
    setAddScreenTabName(selectedTabName);
  };

  /* Get data from Grid Reducer */
  const modulesDataList = useSelector(
    (state: RootState) => state.accessMgmtReducer.modulesList
  );
  const dispatch = useDispatch();

  const [errors, setErrors] = useState({
    roleNametextError: "",
    roleDescriptionError: "",
    modulesError: "",
  });

  const handleEditImage = () => {};
  const handleRoleName = (e: any) => {
    setRoleName(e.target.value);
    validate();
  };
  const handleDescription = (e: any) => {
    setRoleDescription(e.target.value);
    validate();
  };
  

  const handlePopUpClose = () => {
    setAddScreen(false);
  };
  const handleAddRole = async () => {
    const reqBody = {
        userId: localStorage.getItem("userId"),
        password: roleDescription,
    };
    const endPointUrl = addAccessReqEndPoint("Reset");
    const result = await axios.post(endPointUrl, reqBody).then(
      async (res: any) => {
        if (res.data.status != 200 ) {
          notify(res.data.Message, "error");
        } else {
          setAddScreen(false);
          notify(res.data.Message, "success");
          const getEndPointUrl = getAccessMgmtEndPoint("Roles");
          await axios.post(getEndPointUrl, {}).then(
        (res) => {
          dispatch(setRolesData(res.data));
          dispatch(setRolesList(res.data));
        },
        (err) => {
          notify(res.data.Message, "error");
        }
      );
        }
        // clearPreview();
      }
    )
    .catch((err) => {
      notify("Failed", "error");
    });
    };

  const countriesList = [{ Name: "United States", Code: "USA" }];

  let checkFields: Object = { text: "modulename", value: "moduleMenuId" };

  const validate = (field?: any, value?: any) => {
    let roleNametextError = "";
    let roleDescriptionError = "";
    let modulesError = "";
    setErrors({
      roleNametextError: "",
      roleDescriptionError: "",
      modulesError: "",
    });

    if (roleName === "" || roleName.length < 3) {
      roleNametextError = "Required Field";
      setMyName("1px solid red");
    } else {
      setMyName("");
    }
    if (roleDescription === "" || roleDescription.length < 3) {
      roleDescriptionError = "Required Field";
      setMyDescription("1px solid red");
    } else {
      setMyDescription("");
    }

    if (roleName === "" || roleDescription === "" || roleName.length < 3 || roleDescription.length < 3 || roleName !== roleDescription ) {
      setErrors({ roleNametextError, roleDescriptionError, modulesError });
      setRoleDisabeled(true);
      return false;
    } else {
      setRoleDisabeled(false);
    }
    return true;
  };

  return (
    <div>
      <div className="col-sm-12 row m-0 mt-2">
      <div className="col-sm-3"></div>
        <div className="col-sm-6">
          <div className="col-sm-12 mt-3">
            <label className="label-title">Password</label>
            {roleName === "" ? (
              <span className="text-danger" style={{ float: "right" }}>
                {errors.roleNametextError}
              </span>
            ) : (
              ""
            )}
            <input
              className=" formcontrol"
              type="password"
              onChange={(e) => handleRoleName(e)}
              onBlur={(e) => handleRoleName(e)}
              onKeyUp={(e) => handleRoleName(e)}
              style={{ border: myName }}
            />
          </div>
         <div className="col-sm-12 mt-3">
            <label className="label-title">Confirm Password</label>
            {roleName === "" ? (
              <span className="text-danger" style={{ float: "right" }}>
                 {errors.roleDescriptionError}
              </span>
            ) : (
              ""
            )}
            <input
              className=" formcontrol"
              type="password"
              onChange={(e) => handleDescription(e)}
              onBlur={(e) => handleDescription(e)}
              onKeyUp={(e) => handleDescription(e)}
              style={{ border: myDescription }}
            />
          </div>
        </div>
      </div>

      <div className="col-sm-11 px-0 my-5 ml-4">
        <ButtonComponent
          cssClass="float-right px-5 py-3"
          style={
            rolesDisabeled
              ? {
                  opacity: "0.5",
                  pointerEvents: "none",
                  backgroundColor: "#e8e8e8",
                  color: "#969696",
                  borderRadius: "4px",
                }
              : {
                  opacity: "1",
                  borderRadius: "4px",
                  pointerEvents: "all",
                  backgroundColor: "#636799",
                  color: "#ffffff",
                  cursor: "pointer",
                }
          }
          onClick={() => handleAddRole()}
        >
          Save
        </ButtonComponent>
        <ButtonComponent
          cssClass="eg-btn-primary-link e-flat mt-3"
          onClick={() => handlePopUpClose()}
        >
          Cancel
        </ButtonComponent>
      </div>
    </div>
  );
};

export default Resetpopup;
