import { useDispatch } from "react-redux";
import {
  TabComponent,
  TabItemDirective,
  TabItemsDirective,
} from "@syncfusion/ej2-react-navigations";
import UPCDefaultView from "./UPCDefaultView";
import { setTabHeading } from "./contactReducer";
import UpcCustomView from "./upccustomview";

const WarehouseLocationsScreen = (props: any) => {
  const dispatch = useDispatch();
  const { setAddScreen } = props;
  const wareHouseTabs = [{ text: "UPC Default" }, { text: "Custom" }];
  const TabChangeEvent = async (e: any) => {
    dispatch(setTabHeading(e.selectingItem.innerText));
  };
  return (
    <>
      <TabComponent
        cssClass="orientation-tab ll-custom-tab"
        selecting={TabChangeEvent}
      >
        <TabItemsDirective>
          <TabItemDirective
            header={wareHouseTabs[0]}
            content={UPCDefaultView}
          />
          <TabItemDirective header={wareHouseTabs[1]} content={UpcCustomView} />
        </TabItemsDirective>
      </TabComponent>
    </>
  );
};
export default WarehouseLocationsScreen;
