import HttpService from '../http-service/http-service';
import { API_PATH, getApiBaseUrl } from '../../api-paths';
import { SearchCriteriaRequest } from '../../components/grid/gridConfig';

export interface Client {
    createdDate?: any;
    lastModifiedDate?: any;
    organizationId?: any;
    tenantId?: any;
    id: number;
    clientId: string;
    clientName: string;
}

// export interface RuleExceptions {
//     id: number;
//     activeForGrid: boolean;
//     ruleCode: string;
//     deleted: string;
//     startDate: string;
//     endDate: string;
//     ruleActive: boolean;
// }

export interface RuleExceptionGridView {
    id: number;
    activeForGrid: boolean;
    active: boolean;
    ruleCode: string;
    deleted: string;
    startDate: Date;
    endDate: Date;
    ruleActive: boolean;
    activeForGridRules: boolean;
}

export interface RuleExceptionResponse {
    result: RuleExceptionGridView[];
    count: number;
}

export default class RuleExceptionService {
    public async getFilteredItems(
        searchCriteria: SearchCriteriaRequest,
        param?: any
    ): Promise<RuleExceptionResponse> {
        const url =
            getApiBaseUrl() +
            API_PATH.RULE_EXCEPTIONS_PATH +
            `?upc=${param ? param : 0}`;
        return HttpService.post(url, searchCriteria).then((response) => {
            return response.data;
        });
    }

    public async getRuleExceptionsByItemCatalog(
        searchCriteria: SearchCriteriaRequest,
        param?: any
    ) {
        const url =
            getApiBaseUrl() +
            API_PATH.RULE_EXCEPTIONS_PATH +
            `?upc=${param ? param : 0}`;
        return HttpService.post(url, searchCriteria).then((response) => {
            return response.data;
        });
    }

    public async getItem(id: number) {
        const url = getApiBaseUrl() + API_PATH.RULE_EXCEPTIONS_RULE + id;
        return HttpService.get(url).then((response) => {
            return response.data;
        });
    }
}
