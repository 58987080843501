import { ContactType } from './constants';
import { environment } from '../../environment';
import moment from 'moment';

export const generateGridData = (contactType: ContactType, data: any) => {
    switch (contactType) {
        case ContactType.STORES: {
            return (
                data &&
                data.map((row: any) => ({
                    id: row.id,
                    //store: row.name,
                    storeName: row.storeName,
                    addressView: row.address
                        ?.map(
                            (addr: any) =>
                                addr?.street +
                                ', ' +
                                addr?.city +
                                ', ' +
                                addr?.country
                        )
                        ?.toString(),
                    address: row?.address,
                    active: row.active,
                    activeForGrid: row.activeForGridStore,
                    email: row.email,
                    alternatePhoneNumber: row.alternatePhoneNumber,
                    phoneNumber: row.phoneNumber,
                    notes: row.notes,
                    startDate: row.startDate,
                    archieved: row.archieved,
                    clientGroup: row.clientGroup,
                    deleted: row.deleted,
                    fax: row.fax,
                    language: row.language,
                    reasonCode: row.reasonCode,
                    status: row.status,
                    storeNumber: row.storeNumber,
                    storeType: row.storeType,
                    superGroup: row.superGroup,
                    tenantId: row.tenantId,
                    timezone: row.timezone,
                    currencyCode: row.currencyCode,
                    endDate: row.endDate,
                    underReview: row.underReview,
                    mainframeCustomerNumber: row.mainframeCustomerNumber,
                    reclaimCustomerGroupName: row.reclaimCustomerGroupName,
                    reclaimCustomerGroupNumber: row.reclaimCustomerGroupNumber,
                    clientGroupData:
                        row.reclaimCustomerGroupNumber +
                        ' ' +
                        row.reclaimCustomerGroupName,
                }))
            );
        }
        case ContactType.WAREHOUSES: {
            return (
                data &&
                data?.map((row: any) => ({
                    id: row.id,
                    //store: row.name,
                    warehouseName: row.warehouseName,
                    addressView: addressMapping(row?.address),
                    address: row.address,
                    activeForGrid: row.active === true ? 'Active' : 'Inactive',
                    active: row.active,
                    email: row.email,
                    alternatePhoneNumber: row.alternatePhoneNumber,
                    phoneNumber: row.phoneNumber,
                    startDate: row.startDate,
                    //startDate:new Date(row.startDate),
                    archieved: row.archieved,
                    clientGroup: row.clientGroup,
                    deleted: row.deleted,
                    fax: row.fax,
                    notes: row.notes,
                    language: row.language,
                    reasonCode: row.reasonCode,
                    status: row.status,
                    storeNumber: row.storeNumber,
                    storeType: row.storeType,
                    superGroup: row.superGroup,
                    tenantId: row.tenantId,
                    timezone: row.timezone,
                    reclaimCenters: row.reclaimCenters,
                    currencyCode: row.currencyCode,
                    reclaimCenterIds: row.reclaimCenterIds,
                }))
            );
        }
        case ContactType.VENDORS: {
            return (
                data &&
                data?.map((row: any) => ({
                    id: row.id,
                    active: row.active,
                    activeForGrid: row.active === true ? 'Active' : 'Inactive',
                    addressView: row?.address
                        ?.map(
                            (addr: any) =>
                                addr?.street +
                                ', ' +
                                addr?.city +
                                ', ' +
                                addr?.state +
                                ', ' +
                                addr?.country
                        )
                        ?.toString(),
                    address: row.address,
                    alternatePhoneNumber: row.alternatePhoneNumber,
                    apVendorNumber: row.apVendorNumber,
                    apVendorName: row.apVendorName,
                    attnTo: row.attnTo,
                    archieved: row.archieved,
                    deleted: row.deleted,
                    email: row.email,
                    fax: row.fax,
                    language: row.language,
                    notes: row.notes,
                    phoneNumber: row.phoneNumber,
                    timezone: row.timezone,
                    vendorNumber: row.vendorNumber,
                    currencyCode: row.currencyCode,
                    disposition: row.disposition,
                    ircVendorNumber: row.ircVendorNumber,
                    programTypeCode: row.programTypeCode,
                    purpose: row.purpose,
                    recallNumber: row.recallNumber,
                    vendorName: row.vendorName,
                    hold: row.hold === true ? 'Hold' : '',
                    vendorType: row?.vendorType?.toString(),
                }))
            );
        }
        case ContactType?.RECLAIM_CENTERS: {
            return (
                data &&
                data.map((row: any) => ({
                    id: row.id,
                    reclaimcenterName: row.reclaimcenterName,
                    email: row.email,
                    phoneNumber: row.phoneNumber,
                    alternatePhoneNumber: row.alternatePhoneNumber,
                    fax: row.fax,
                    notes: row.notes,
                    tenantId: row.tenantId,
                    orgId: row.orgId,
                    addressView: addressMapping(row?.address),
                    address: row.address,
                    warehouses: row.warehouses,
                    // warehouses: row.warehouses.map((wh: any) => (`${wh.warehouseName}\n`+`\n${wh.warehouseName}`+', '+wh.warehouseName)),
                    stores: row.stores,
                    active: row.active,
                    activeForGrid: row.active === true ? 'Active' : 'Inactive',
                    deleted: row.deleted,
                    archieved: row.archieved,
                    currencyCode: row.currencyCode,
                    client: row.client,
                    companyCode: row.companyCode,
                    reclaimCenterLogo: row.reclaimCenterLogo,
                    language: row.language,
                    timezone: row.timezone,
                    clientId: row.clientId,
                    companyId: row.companyId,
                    warehouseIds: row.warehouseIds,
                    hourlyScanGoal: row?.hourlyScanGoal,
                }))
            );
        }
        case ContactType.CARRIERS: {
            return (
                data &&
                data?.map((row: any) => ({
                    id: row.id,
                    active: row.active,
                    activeForGrid: row.active === true ? 'Active' : 'Inactive',
                    addressView: addressMapping(row?.address),
                    address: row.address,
                    alternatePhoneNumber: row.alternatePhoneNumber,
                    archieved: row.archieved,
                    deleted: row.deleted,
                    email: row.email,
                    endDate: row.endDate,
                    fax: row.fax,
                    language: row.language,
                    carrierName: row.carrierName,
                    notes: row.notes,
                    orgId: row.orgId,
                    phoneNumber: row.phoneNumber,
                    tenantId: row.tenantId,
                    timezone: row.timezone,
                    currencyCode: row.currencyCode,
                    addCountryIsoCode: '',
                }))
            );
        }
        case ContactType.CUSTOMERS: {
            return (
                data &&
                data?.map((row: any) => ({
                    id: row.id,
                    accountNumber: row.accountNumber,
                    active: row.active,
                    activeForGrid: row.active === true ? 'Active' : 'Inactive',
                    addressView: addressMapping(row?.address),
                    address: row.address,
                    alternatePhoneNumber: row.alternatePhoneNumber,
                    billingMode: row.billingMode,
                    client: row.client,
                    clientId: row.clientId,
                    //companyCode: row.companyCode,
                    companyId: row.companyId,
                    currencyCode: row.currencyCode,
                    customerName: row.customerName,
                    customerNumber: row.customerNumber,
                    customerType: row?.customerType?.toString(),
                    deleted: row.deleted,
                    email: row.email,
                    fax: row.fax,
                    language: row.language,
                    notes: row.notes,
                    paymentTerms: row.paymentTerms,
                    phoneNumber: row.phoneNumber,
                    timezone: row.timezone,
                    prepaid: row.prepaid,
                    prepadidFlag: row.prepaid === true ? 'Yes' : 'No',
                }))
            );
        }
        case ContactType.COMPANY_CODES: {
            return (
                data &&
                data?.map((row: any) => ({
                    createdDate: row.createdDate,
                    lastModifiedDate: row.lastModifiedDate,
                    id: row.id,
                    active: row.active,
                    activeForGrid: row.active === true ? 'Active' : 'Inactive',
                    address1: addressMapping(row?.address),
                    address: row.address,
                    alternatePhoneNumber: row.alternatePhoneNumber,
                    client: row.client,
                    clientId: row.clientId,
                    companyName: row.companyName,
                    companyCode: row.companyCode,
                    currencyCode: row.currencyCode,
                    deleted: row.deleted,
                    email: row.email,
                    fax: row.fax,
                    language: row.language,
                    notes: row.notes,
                    phoneNumber: row.phoneNumber,
                    timezone: row.timezone,
                    webUrl: row.webUrl,
                }))
            );
        }
        case ContactType.DEPARTMENTS: {
            return (
                data &&
                data?.map((row: any) => ({
                    id: row.id,
                    client: row.client,
                    departmentName: row.departmentName,
                    notes: row.notes,
                    active: row.active,
                    activeForGrid: row.active === true ? 'Active' : 'Inactive',
                    glAccounts: row.glAccounts,
                    glAccountsView: row?.glAccounts
                        ?.map((addr: any) => addr?.glAccountName)
                        ?.toString(),
                    deleted: row.deleted,
                }))
            );
        }
        case ContactType.GL_ACCOUNTS: {
            return (
                data &&
                data?.map((row: any) => ({
                    id: row.id,
                    departmentIds: row.departmentIds,
                    notes: row.notes,
                    client: row.client,
                    active: row.active,
                    activeForGrid: row.active === true ? 'Active' : 'Inactive',
                    glAccountName: row.glAccountName,
                    deleted: row.deleted,
                    glAccountNumber: row.glAccountNumber,
                    createdDate: moment(row?.startDate).format('MMM DD, YY'),
                    departments: row.departments,
                    departmentsList: row?.departments
                        ?.map((addr: any) => addr?.departmentName)
                        ?.toString(),
                }))
            );
        }
        case ContactType.COMMODITY_TYPES: {
            return (
                data &&
                data?.map((row: any) => ({
                    id: row.id,
                    code: row.code,
                    description: row.description,
                    active: row.active,
                    activeForGrid: row.active === true ? 'Active' : 'Inactive',
                    // price: row.price,
                    priceCommodity: row.price,
                    // row.pricingType === "Percent" ? row.price + "%" : "$" + row.price,
                    deleted: row.deleted,
                    pricingType: row.pricingType,
                    client: row.client,
                }))
            );
        }
        case ContactType.PALLET_LOCATIONS: {
            return (
                data &&
                data?.map((row: any) => ({
                    id: row.id,
                    palletName: row.palletName,
                    position: row.position,
                    active: row.active,
                    scanGroupCode: row.scanGroup?.scanGroupCode,
                    scanGroupDescription: row.scanGroup?.scanGroupDescription,
                    scanGroupDesc: row.scanGroup?.scanGroupDesc,
                    reclaimCenter: row.reclaimCenter?.reclaimcenterName,
                    whLocation: row.whLocation?.warehouseLocation,
                    pricingType: row.pricingType,
                    pricePallet: row.price,
                    binPrice: row?.binPrice ? row?.binPrice : 0,
                    deleted: row.deleted,
                    pallet: row.pallet,
                    locationEnabled: row.locationEnabled,
                    locationEnabledForGrid:
                        row.locationEnabled === true ? 'Yes' : 'No',
                    reclaimCenterId: row.reclaimCenterId,
                    client: row.client,
                }))
            );
        }
        case ContactType.RULE_EXCEPTIONS: {
            return (
                data &&
                data?.map((row: any) => ({
                    id: row.id,
                    active: row.active,
                    activeForGrid: row.active === true ? 'Active' : 'Inactive',
                    billCost: row.billCost,
                    client: row.client,
                    clientGroup: row.clientGroup,
                    customer: row.customer,
                    debitReasonCode: row.debitReasonCode,
                    deleted: row.deleted,
                    disposition: row.disposition,
                    endDate:
                        row?.endDate !== undefined && row?.endDate !== null
                            ? moment(row?.endDate).format('MMM DD, YYYY')
                            : '',
                    reclaimCenter: row.reclaimCenter,
                    ruleCode: row.ruleCode,
                    startDate:
                        row?.startDate !== undefined && row?.startDate !== null
                            ? moment(row?.startDate).format('MMM DD, YYYY')
                            : '',
                    upc: row.upc,
                    vendor: row.vendor,
                    warehouseLocation: row.warehouseLocation,
                    upcIds: row.upcIds,
                    vendorIds: row.vendorIds,
                    reclaimCenterIds: row.reclaimCenterIds,
                    customerIds: row.customerIds,
                }))
            );
        }
        case ContactType.FISCAL_YEAR_SETUP: {
            return (
                data &&
                data?.map((row: any) => ({
                    id: row.id,
                    endDate: moment(row?.endDate).format('MMM DD, YYYY'),
                    period: row.period,
                    active: row.active,
                    activeForFiscal: row.active === true ? 'Open' : 'Close',
                    quarter: row.quarter,
                    deleted: row.deleted,
                    startDate: moment(row?.startDate).format('MMM DD, YYYY'),
                    week: row.week,
                    client: row.client,
                }))
            );
        }
        case ContactType.INVENTORY_CATALOG: {
            return (
                data &&
                data?.map((row: any) => ({
                    id: isEmpty(row.id),
                    active: isEmpty(row.active),
                    palletId: isEmpty(row.palletId),
                    bins: isEmpty(row.bins),
                    boxes: isEmpty(row.boxes),
                    closedOn:
                        row.closedOn !== undefined && row.closedOn !== null
                            ? moment(row.closedOn).format('MMM DD, YYYY')
                            : '',
                    cost: isEmpty(row.cost),
                    deleted: isEmpty(row.deleted),
                    palletDescription: isEmpty(row?.warehouseLocation),
                    whseLocation: isEmpty(row.warehouseLocation),
                    items: isEmpty(row.items),
                    openedOn:
                        row.openedOn !== undefined && row.openedOn !== null
                            ? moment(row.openedOn).format('MMM DD, YYYY')
                            : '',
                    price: isEmpty(row.price),
                    pricingType: row.pricingType,
                    invoiceRaised: row.invoiceRaised,
                    invoiceRaisedForGrid:
                        row.invoiceRaised === true ? 'Yes' : 'No',
                    reclaimCenter: isEmpty(row.reclaimCenterName),
                    //rate: isEmpty(row.rate),
                    pricePallet: row.rate ? row.rate : 0.0,
                }))
            );
        }
        case ContactType.STORE_DISPOSITIONS: {
            return (
                data &&
                data?.map((row: any) => ({
                    id: row.id,
                    storeDisposition: row.storeDisposition,
                    authorizationFlag: row.authorizationFlag,
                    authorizationFlagGrid:
                        row.authorizationFlag === 'Y' ? 'Yes' : 'No',
                    dispositionName: row.dispositionName,
                    voiceText: row.voiceText,
                }))
            );
        }
        case ContactType.WAREHOUSE_LOCATIONS: {
            return (
                data &&
                data?.map((row: any) => ({
                    id: row.id,
                    active: row.active,
                    dispositionVoiceText: row.dispositionVoiceText,
                    disposition: row.disposition,
                    warehouseLocation: row.warehouseLocation,
                }))
            );
        }
        case ContactType.CustomWHLoc: {
            return (
                data &&
                data?.map((row: any) => ({
                    id: row.id,
                    dispositionVoiceText: row.dispositionVoiceText,
                    fttenabled: row.fttenabled ? 'Yes' : 'No',
                    warehouseLocation: row.warehouseLocation,
                    boxMaxCount:row.boxMaxCount,
                    disposition: row.disposition
                }))
            );
        }
        case ContactType?.BIN_EXCEPTIONS: {
            return (
                data &&
                data.map((row: any) => ({
                    id: row.id,
                    reclaimcenterName: row?.reclaimCenterName,
                    reclaimCenterId: row?.reclaimCenter?.id,
                    dateRange:
                        moment(row?.startDate).format('MMM DD, YY') +
                        // new Date(row?.startDate).toLocaleString("en-GB", {
                        //   day: "numeric",
                        //   month: "short",
                        //   year: "2-digit",
                        // }) +
                        ' - ' +
                        moment(row?.endDate).format('MMM DD, YY'),
                    // new Date(row?.endDate).toLocaleString("en-GB", {
                    //   day: "numeric",
                    //   month: "short",
                    //   year: "2-digit",
                    // }),
                    timeRange: renderTime(row?.startTime, row?.endTime),
                    // timeRange: row?.startTime + "-" + row.endTime,
                    scanGroup: row?.scanGroupCode,
                    activeFlagBinException:
                        row?.active === true ? 'Active' : 'Inactive',
                }))
            );
        }
        default:
            return [];
    }
};

const isEmpty = (val: any) => {
    if (val !== undefined && val !== null && val !== '') {
        return val;
    } else {
        return '';
    }
};

const baseURL = environment.react_app_url;
const clientContext = environment.react_app_client_context;
// const  clientContext = '/revlog';
export const getEndPoint = (
    selectedTab: ContactType,
    page?: any,
    size?: any,
    param?: any
) => {
    switch (selectedTab) {
        case ContactType.STORES: {
            return (
                baseURL +
                `${clientContext}/v1/masterdata/store/list/pagination?page=${page}&size=${size}`
            );
        }
        case ContactType.WAREHOUSES: {
            return baseURL + `${clientContext}/v1/masterdata/warehouse/list`;
        }
        case ContactType.RECLAIM_CENTERS: {
            return (
                baseURL + `${clientContext}/v1/masterdata/reclaimcenters/list`
            );
        }
        case ContactType.CARRIERS: {
            return baseURL + `${clientContext}/v1/masterdata/carrier/list`;
        }
        case ContactType.VENDORS: {
            return (
                baseURL +
                `${clientContext}/v1/masterdata/vendor/list/pagination?page=${page}&size=${size}&vendor=${
                    param ? param : ''
                }`
            );
        }
        case ContactType.CUSTOMERS: {
            return baseURL + `${clientContext}/v1/masterdata/customer/list`;
        }
        case ContactType.COMPANY_CODES: {
            return baseURL + `${clientContext}/v1/masterdata/companycode/list`;
        }
        case ContactType.DEPARTMENTS: {
            return baseURL + `${clientContext}/v1/masterdata/departments/list`;
        }
        case ContactType.GL_ACCOUNTS: {
            return baseURL + `${clientContext}/v1/masterdata/glaccount/list`;
        }
        case ContactType.COMMODITY_TYPES: {
            return (
                baseURL + `${clientContext}/v1/masterdata/commoditytype/list`
            );
        }
        case ContactType.PALLET_LOCATIONS: {
            return (
                baseURL + `${clientContext}/v1/masterdata/palletlocation/list`
            );
        }

        case ContactType.INVENTORY_CATALOG: {
            return (
                baseURL + `${clientContext}/v1/masterdata/inventorycatalog/list`
            );
        }
        case ContactType.STORE_DISPOSITIONS: {
            return (
                baseURL + `${clientContext}/v1/masterdata/storeDisposition/list`
            );
        }
        case ContactType.BIN_EXCEPTIONS: {
            return baseURL + `${clientContext}/v1/masterdata/binexception/list`;
        }
        case ContactType.UPCDefaultWHLoc: {
            return (
                baseURL +
                `${clientContext}/v1/masterdata/warehouseLocation/list/${false}`
            );
        }
        default:
            return '';
    }
};

export const addMasterDataEndPoint = (selectedTab: ContactType) => {
    switch (selectedTab) {
        case ContactType.STORES: {
            return baseURL + `${clientContext}/v1/masterdata/store/item`;
        }
        case ContactType.WAREHOUSES: {
            return baseURL + `${clientContext}/v1/masterdata/warehouse/items`;
        }
        case ContactType.RECLAIM_CENTERS: {
            return (
                baseURL + `${clientContext}/v1/masterdata/reclaimcenters/items`
            );
        }
        case ContactType.CARRIERS: {
            return baseURL + `${clientContext}/v1/masterdata/carrier/items`;
        }
        case ContactType.VENDORS: {
            return baseURL + `${clientContext}/v1/masterdata/vendor/items`;
        }
        case ContactType.CUSTOMERS: {
            return baseURL + `${clientContext}/v1/masterdata/customer/items`;
        }
        case ContactType.COMPANY_CODES: {
            return baseURL + `${clientContext}/v1/masterdata/companycode/items`;
        }
        case ContactType.DEPARTMENTS: {
            return baseURL + `${clientContext}/v1/masterdata/departments/items`;
        }
        case ContactType.GL_ACCOUNTS: {
            return baseURL + `${clientContext}/v1/masterdata/glaccounts/items`;
        }
        case ContactType.COMMODITY_TYPES: {
            return (
                baseURL + `${clientContext}/v1/masterdata/commoditytype/items`
            );
        }
        case ContactType.PALLET_LOCATIONS: {
            return (
                baseURL + `${clientContext}/v1/masterdata/palletlocation/items`
            );
        }
        case ContactType.BIN_EXCEPTIONS: {
            return (
                baseURL + `${clientContext}/v1/masterdata/binexception/items`
            );
        }
        case ContactType.RULE_EXCEPTIONS: {
            return (
                baseURL + `${clientContext}/v1/masterdata/ruleexception/items`
            );
        }
        case ContactType.FISCAL_YEAR_SETUP: {
            return baseURL + `${clientContext}/v1/masterdata/fiscalyear/items`;
        }
        case ContactType.STORE_DISPOSITIONS: {
            return (
                baseURL +
                `${clientContext}/v1/masterdata/storedisposition/items`
            );
        }
        case ContactType.UPCDefaultWHLoc: {
            return (
                baseURL +
                `${clientContext}/v1/masterdata/warehouseLocation/items/false`
            );
        }
        case ContactType.CustomWHLoc: {
            return (
                baseURL +
                `${clientContext}/v1/masterdata/warehouseLocation/items/true`
            );
        }
        default:
            return '';
    }
};

export const getTabData = (selectedTab: ContactType, id: any) => {
    // let getId = id;
    switch (selectedTab) {
        case ContactType.STORES: {
            return baseURL + `${clientContext}/v1/masterdata/store/item/${id}`;
        }
        case ContactType.WAREHOUSES: {
            return (
                baseURL + `${clientContext}/v1/masterdata/warehouse/items/${id}`
            );
        }
        case ContactType.RECLAIM_CENTERS: {
            return (
                baseURL +
                `${clientContext}/v1/masterdata/reclaimcenters/items/${id}`
            );
        }
        case ContactType.COMPANY_CODES: {
            return (
                baseURL +
                `${clientContext}/v1/masterdata/companycode/items/${id}`
            );
        }
        case ContactType.CARRIERS: {
            return (
                baseURL + `${clientContext}/v1/masterdata/carrier/items/${id}`
            );
        }
        case ContactType.VENDORS: {
            return (
                baseURL + `${clientContext}/v1/masterdata/vendor/items/${id}`
            );
        }
        case ContactType.CUSTOMERS: {
            return (
                baseURL + `${clientContext}/v1/masterdata/customer/items/${id}`
            );
        }
        case ContactType.BIN_EXCEPTIONS: {
            return (
                baseURL +
                `${clientContext}/v1/masterdata/binexception/items/${id}`
            );
        }
        case ContactType.FISCAL_YEAR_SETUP: {
            return (
                baseURL +
                `${clientContext}/v1/masterdata/fiscalyear/items/${id}`
            );
        }
        case ContactType.INVENTORY_CATALOG: {
            return (
                baseURL +
                `${clientContext}/v1/masterdata/inventorycatalog/items/${id}`
            );
        }
        case ContactType.RULE_EXCEPTIONS: {
            return (
                baseURL +
                `${clientContext}/v1/masterdata/ruleexception/items/${id}`
            );
        }
        case ContactType.DEPARTMENTS: {
            return (
                baseURL +
                `${clientContext}/v1/masterdata/departments/items/${id}`
            );
        }
        case ContactType.GL_ACCOUNTS: {
            return (
                baseURL +
                `${clientContext}/v1/masterdata/glaccounts/items/${id}`
            );
        }
        case ContactType.COMMODITY_TYPES: {
            return (
                baseURL +
                `${clientContext}/v1/masterdata/commoditytype/items/${id}`
            );
        }
        // case ContactType.RULE_EXCEPTIONS: {
        //   return baseURL+ `${clientContext}/v1/masterdata/ruleexception/list?upc=${id}`;
        // }
        case ContactType.PALLET_LOCATIONS: {
            return (
                baseURL +
                `${clientContext}/v1/masterdata/palletlocation/items/${id}`
            );
        }
        case ContactType.STORE_DISPOSITIONS: {
            return (
                baseURL +
                `${clientContext}/v1/masterdata/storedisposition/items/${id}`
            );
        }
        case ContactType.CustomWHLoc: {
            return (
                baseURL +
                `${clientContext}/v1/masterdata/warehouseLocation/items/true/${id}`
            );
        }
        default:
            return '';
    }
};
export const getRuleExceptionsByUPCId = (selectedTab: ContactType, id: any) => {
    // let getId = id;
    switch (selectedTab) {
        case ContactType.RULE_EXCEPTIONS: {
            return (
                baseURL +
                `${clientContext}/v1/masterdata/ruleexception/list?upc=${id}`
            );
        }
        default:
            return '';
    }
};
export const getUPCAndWHLocationByRC = (selectedTab: ContactType, id: any) => {
    // let getId = id;
    switch (selectedTab) {
        case ContactType.WAREHOUSE_LOCATIONS: {
            return (
                baseURL +
                `${clientContext}/v1/masterdata/ruleexception/list?upc=${id}`
            );
        }
        default:
            return '';
    }
};
export const getItemsDataForBins = (
    selectedTab: ContactType,
    palletId: any,
    binId: any
) => {
    // let getId = id;
    switch (selectedTab) {
        case ContactType.BINANDBOX_ITEMS: {
            return (
                baseURL +
                `${clientContext}/v1/masterdata/inventorycatalog/items/${palletId}/box/items/${binId}`
            );
        }
        default:
            return '';
    }
};
export const getScanGroupByRC = (rcid: any) => {
    return (
        baseURL +
        `${clientContext}/v1/masterdata/reclaimcenters/items/${rcid}/scangroup`
    );
};
export const getScanGroupByDataRC = (rcid: any) => {
    return (
        baseURL +
        `${clientContext}/v1/masterdata/scansetting/items/${rcid}/scangroup/list`
    );
};
export const updateMasterDataEndPoint = (selectedTab: ContactType, id: any) => {
    let getId = id[0].id;
    switch (selectedTab) {
        case ContactType.STORES: {
            return (
                baseURL + `${clientContext}/v1/masterdata/store/item/${getId}`
            );
        }
        case ContactType.WAREHOUSES: {
            return (
                baseURL +
                `${clientContext}/v1/masterdata/warehouse/items/${getId}`
            );
        }
        case ContactType.RECLAIM_CENTERS: {
            return (
                baseURL +
                `${clientContext}/v1/masterdata/reclaimcenters/items/${getId}`
            );
        }
        case ContactType.CARRIERS: {
            return (
                baseURL +
                `${clientContext}/v1/masterdata/carrier/items/${getId}`
            );
        }
        case ContactType.VENDORS: {
            return (
                baseURL + `${clientContext}/v1/masterdata/vendor/items/${getId}`
            );
        }
        case ContactType.CUSTOMERS: {
            return (
                baseURL +
                `${clientContext}/v1/masterdata/customer/items/${getId}`
            );
        }
        case ContactType.COMPANY_CODES: {
            return (
                baseURL +
                `${clientContext}/v1/masterdata/companycode/items/${getId}`
            );
        }
        case ContactType.DEPARTMENTS: {
            return (
                baseURL +
                `${clientContext}/v1/masterdata/departments/items/${getId}`
            );
        }
        case ContactType.GL_ACCOUNTS: {
            return (
                baseURL +
                `${clientContext}/v1/masterdata/glaccounts/items/${getId}`
            );
        }
        case ContactType.COMMODITY_TYPES: {
            return (
                baseURL +
                `${clientContext}/v1/masterdata/commoditytype/items/${getId}`
            );
        }
        case ContactType.BIN_EXCEPTIONS: {
            return (
                baseURL +
                `${clientContext}/v1/masterdata/binexception/items/${getId}`
            );
        }
        case ContactType.PALLET_LOCATIONS: {
            return (
                baseURL +
                `${clientContext}/v1/masterdata/palletlocation/items/${getId}`
            );
        }
        case ContactType.RULE_EXCEPTIONS: {
            return (
                baseURL +
                `${clientContext}/v1/masterdata/ruleexception/items/${getId}`
            );
        }
        case ContactType.STORE_DISPOSITIONS: {
            return (
                baseURL +
                `${clientContext}/v1/masterdata/storedisposition/items/${getId}`
            );
        }
        case ContactType.UPCDefaultWHLoc: {
            return (
                baseURL +
                `${clientContext}/v1/masterdata/warehouseLocation/items/false/${getId}`
            );
        }
        case ContactType.INVENTORY_CATALOG: {
            return (
                baseURL +
                `${clientContext}/v1/masterdata/inventorycatalog/items/${getId}`
            );
        }
        case ContactType.CustomWHLoc: {
            return (
                baseURL +
                `${clientContext}/v1/masterdata/warehouseLocation/items/true/${getId}`
            );
        }
        default:
            return '';
    }
};

export const getAllMasterData = (masterType: any) => {
    switch (masterType) {
        case 'Language': {
            return (
                baseURL +
                `${clientContext}/v1/masterdata/language/list?forSelection=true`
            );
        }
        case 'TimeZone': {
            return (
                baseURL +
                `${clientContext}/v1/masterdata/timezone/list?forSelection=true`
            );
        }
        case 'CurrencyCode': {
            return (
                baseURL +
                `${clientContext}/v1/masterdata/currency/list?forSelection=true`
            );
        }
        case 'Client': {
            return (
                baseURL + `${clientContext}/v1/masterdata/administration/list`
            );
        }
        case 'CustomerType': {
            return baseURL + `${clientContext}/v1/masterdata/customertype/list`;
        }
        case 'StoreType': {
            return baseURL + `${clientContext}/v1/masterdata/storetype/list`;
        }
        case 'SuperGroup': {
            return baseURL + `${clientContext}/v1/masterdata/supergroup/list`;
        }
        case 'ClientGroup': {
            return baseURL + `${clientContext}/v1/masterdata/clientgroup/list`;
        }
        case 'StatusList': {
            return baseURL + `${clientContext}/v1/masterdata/status/list`;
        }
        case 'ReasonCode': {
            return baseURL + `${clientContext}/v1/masterdata/reasoncode/list`;
        }
        case 'PaymentTerms': {
            return baseURL + `${clientContext}/v1/masterdata/paymentterm/list`;
        }
        case 'BillingMode': {
            return baseURL + `${clientContext}/v1/masterdata/billingmode/list`;
        }
        case 'GLAccountMasterData': {
            return baseURL + `${clientContext}/v1/masterdata/glaccount/list`;
        }
        case 'PricingType': {
            return baseURL + `${clientContext}/v1/masterdata/pricingtype/list`;
        }
        case 'PalletName': {
            return baseURL + `${clientContext}/v1/masterdata/palletname/list`;
        }
        case 'ScanGroup': {
            return baseURL + `${clientContext}/v1/masterdata/scangroup/list`;
        }
        case 'Disposition': {
            return baseURL + `${clientContext}/v1/masterdata/disposition/list`;
        }
        case 'DebitReason': {
            return (
                baseURL + `${clientContext}/v1/masterdata/debitreasoncode/list`
            );
        }
        case 'FiscalYear': {
            return baseURL + `${clientContext}/v1/masterdata/fiscalyear/list`;
        }
        case 'FiscalYearTemplate': {
            return (
                baseURL +
                `${clientContext}/v1/masterdata/fiscalyeartemplateheader/list`
            );
        }
        case 'FiscalYearPeriods': {
            return (
                baseURL +
                `${clientContext}/v1/masterdata/fiscalyear/items/fiscalyearperiods`
            );
        }
        case 'Country': {
            return (
                baseURL + `${clientContext}/v1/masterdata/country/list/active`
            );
        }
        case 'StateList': {
            return baseURL + `${clientContext}/v1/masterdata/state/list/`;
        }
        case 'CountryCodePhone': {
            return (
                baseURL +
                `${clientContext}/v1/masterdata/mobilecountrycode/list`
            );
        }
        case 'WhseLocations': {
            return baseURL + `${clientContext}/v1/masterdata/disposition/list`;
        }
        case 'ReclamCenters': {
            return (
                baseURL +
                `${clientContext}/v1/masterdata/reclaimcenters/list?forSelection=true`
            );
        }
        case 'UPCDefaultWHLoc': {
            return (
                baseURL +
                `${clientContext}/v1/masterdata/warehouseLocation/list/${false}`
            );
        }
        case 'UPC Default': {
            return (
                baseURL +
                `${clientContext}/v1/masterdata/warehouseLocation/list/${false}`
            );
        }
        case 'CustomWHLoc': {
            return (
                baseURL +
                `${clientContext}/v1/masterdata/warehouseLocation/list/${true}`
            );
        }
        case 'Custom': {
            return (
                baseURL +
                `${clientContext}/v1/masterdata/warehouseLocation/list/${true}`
            );
        }
        case 'StoreDisposition': {
            return (
                baseURL + `${clientContext}/v1/masterdata/storeDisposition/list`
            );
        }
        case 'DepartmentsDropdown': {
            return (
                baseURL +
                `${clientContext}/v1/masterdata/departments/list?forSelection=true`
            );
        }
        case 'GLAccountsDropdown': {
            return (
                baseURL +
                `${clientContext}/v1/masterdata/glaccount/list?forSelection=true`
            );
        }
        case 'ReclaimCentersDropdown': {
            return (
                baseURL +
                `${clientContext}/v1/masterdata/reclaimcenters/list?forSelection=true`
            );
        }
        default:
            return '';
    }
};

export const getStatesByCountry = (cid: any) => {
    return (
        baseURL + `${clientContext}/v1/masterdata/state/list?countryCode=${cid}`
    );
};

export const addressMapping = (address: any) => {
    let finaladdress = '';
    address?.map((item: any) => {
        if (
            item.street !== undefined &&
            item.street !== null &&
            item.street !== ''
        ) {
            finaladdress = finaladdress.replace('', item.street);
        }
        if (item.city !== undefined && item.city !== null && item.city !== '') {
            finaladdress =
                finaladdress !== ''
                    ? finaladdress + ', ' + item.city
                    : finaladdress.replace('', item.city);
        }
        if (
            item.state !== undefined &&
            item.state !== null &&
            item.state !== ''
        ) {
            finaladdress =
                finaladdress !== ''
                    ? finaladdress + ', ' + item.state
                    : finaladdress.replace('', item.state);
        }
        if (
            item.country !== undefined &&
            item.country !== null &&
            item.country !== ''
        ) {
            finaladdress =
                finaladdress !== ''
                    ? finaladdress + ', ' + item.country
                    : finaladdress.replace('', item.country);
        }
    });
    return finaladdress;
};

// export const formatDateToYMD = (date: any) => {
//   if(date !== undefined && date !== null && date !== '') {

//     const d = new Date(date);
//     let month = "" + (d.getMonth() + 1);
//     let day = "" + d.getDate();
//     const year = d.getFullYear().toString();
//     if (month.length < 2) {
//       month = "0" + month;
//     }
//     if (day.length < 2) {
//       day = "0" + day;
//     }
//     const hours = d.getUTCHours();
//     const minutes = d.getMinutes();
//     const seconds = d.getSeconds();

//     const time = [hours, minutes, seconds].join(":");
//     const formateddate = [year, month, day].join("-");
//     return formateddate + " " + time;
//   }
// };
// export const formatDateToMonDDYY = (date: any,type: string) => {
//   if(date !== undefined && date !== null && date !== '') {
//    let year = ""
//     const d = new Date(date);
//     let month = "" + (d.getMonth() + 1);
//     let day = "" + d.getDate();
//     if (type === "fullyear") {

//        year = d.getFullYear().toString();
//     }
//     else {
//       year = d.getFullYear().toString().substring(2,4);
//     }
//     if (month.length < 2) {
//       month = "0" + month;
//     }
//     if (day.length < 2) {
//       day = "0" + day;
//     }
//     const hours = d.getUTCHours();
//     const minutes = d.getMinutes();
//     const seconds = d.getSeconds();
//     const monthFull = ["January","February","March","April","May","June","July","August","September","October","November","December"];
//     const monthShort = ["Jan","Feb","Mar","Apr","May","Jun","Jul","Aug","Sep","Oct","Nov","Dec"];

//     let name = monthShort[parseInt(month) - 1];
//     const time = [hours, minutes, seconds].join(":");
//     const formateddate = [ name + ' ' + day, year].join(", ");
//     return formateddate;
//   }
// };

// export const formatDateToMM = (date: any) => {

//   var d = date.split("-");
//   console.log(d[1].charAt(1))

//   var Rmonth = "" + (d[1]);
//   let day = "" + d[2];
//   const year = d[0];
//   if (Rmonth.length < 2) {
//     Rmonth = "0" + Rmonth;
//   }
//   if (day.length < 2) {
//     day = "0" + day;
//   }
//   const monthh = ["January","February","March","April","May","June","July","August","September","October","November","December"];
//   let name = monthh[d[1].charAt(1) - 1];
//   const monthNameShort = d.toLocaleString("en-US", { month: "short" });
//   const formateddatee = [day , name, year].join("-");

//   return formateddatee;
// };

export const renderTime = (startTime: any, endTime: any) => {
    let time = '';
    let timeinAMPM = '';
    if (startTime !== undefined && startTime !== null && startTime !== '') {
        let splitedTime = startTime.split(':');
        if (splitedTime[0] > '12') {
            time = splitedTime[0] - 12 + ':' + splitedTime[1] + ' PM';
        } else {
            time = splitedTime[0] + ':' + splitedTime[1] + ' AM';
        }
        // time = startTime
    }
    if (endTime !== undefined && endTime !== null && endTime !== '') {
        //  time = time === '' ?  endTime : time + ' - ' + endTime;
        let splitedTime = endTime.split(':');
        let endTimes = '';
        if (splitedTime[0] > '12') {
            endTimes = splitedTime[0] - 12 + ':' + splitedTime[1] + ' PM';
        } else {
            endTimes = splitedTime[0] + ':' + splitedTime[1] + ' AM';
        }
        time = endTimes === '' ? endTimes : time + ' - ' + endTimes;
    }
    return time;
};
