import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../redux/store';
import Grid from '../../grid/Grid';
import { useEffect, useState } from 'react';
import { generateGridData, getBoldReportsAccessTokenByUSer, getDynamicBoldReportUrl, savedReportHeaders } from '../utils';
import { ButtonComponent } from '@syncfusion/ej2-react-buttons';
import ReportPreviewTab from '../previewReport/previewreport';
import { ReactComponent as Add } from '../../../icons/AddNew.svg';
import loader from '../../../assets/loader.gif';
import LLModal from '../../common/llModal/LLModal';
import ContentHeader from '../../common/ContentHeader/ContentHeader';
import Divider from '../../common/divider/Divider';
import ReportViewer from '../../../report-viewer';
import { environment } from '../../../environment';
import axios from 'axios';
import { useLocation } from 'react-router-dom';
import { setGridRefInfo, setRowData } from '../../grid/gridReducer';
import { setSavedReportData } from '../reportreducer';

const SavedReport = (props: any) => {
    const sortCodeArr = [
        { name: 'All' },
        { name: 'Sold' },
        { name: 'Not Sold' },
    ];
    const dispatch = useDispatch();
    const [selectedTab, setSelectedTab] = useState('');
    const [reportSettingsTab, setreportSettingsTab] = useState<any>([]);
    const [addScreen, setAddScreen] = useState(false);
    const [boldReportUrl, setBoldReportUrl] = useState('');
    const [boldReportAccessToken, setBoldReportAccessToken] = useState('');
    const location = useLocation();
    const [selectedMenu, setSelectedMenu] = useState('boldReports');

    /* Get selected data from Grid Reducer */
    const selectedRowData = useSelector(
        (state: RootState) => state.gridReducer.selectedRowData
    );

    /* Get Saved Reports data from reports Reducer */
    const savedReportData = useSelector(
        (state: RootState) => state.reportReducer.savedReportData
    );

    let gridHieght = 'calc(100vh - 400px)';
    let reportUrl;
    const boldReportsURL = environment.react_bold_reports_url;
    const sitePath = environment.react_bold_reports_sitePath ? environment.react_bold_reports_sitePath : "/reporting/api/site/site1" ;

    useEffect(() => {
        getToken();
        if(location.pathname === '/oldReports') {
            setSelectedMenu('oldReports')
        } else if (location.pathname === '/reports') {
            setSelectedMenu('boldReports')
        }
    },[location])

    useEffect(() => {
        if(selectedRowData && selectedRowData.length>0) {
            setAddScreen(true);
             reportUrl =  getDynamicBoldReportUrl(selectedRowData[0].name);
             setBoldReportUrl(reportUrl);
            
        }

    },[selectedRowData]);

    const getToken= async()=>{
    const endPointURL= getBoldReportsAccessTokenByUSer();
        await axios.get(endPointURL,)
            .then((res: any) => {
                setBoldReportAccessToken(res.data.access_token);
                // debugger
            })
    }
    const handlePopupActionLocation = (e: any) => {};

    const loaderStatus = useSelector(
        (state: RootState) => state.reportReducer.isLoaderShow
    );

    const handleCloseBoldReports = () => {
        setAddScreen(false);
        setBoldReportUrl('');
        // var reportviewerObj = $("#reportviewer-container").data("boldReportViewer");
        // reportviewerObj.destroy();
    }

    return (
        <div className="col-sm-12 col-md-12 col-lg-12 ">
            <div className="control-pane">
                <div className="control-section tab-control-section">
                    <div className="col-sm-12 col-md-12 col-lg-12 pt-4 pl-2">
                        <div className="col-sm-6 pl-0">
                            <p className="child-tab-title">Saved Reports</p>
                        </div>
                        <div className="col-sm-6 pr-0">
                            <ButtonComponent
                                type="button"
                                className="iconCopy float-right ml-1"
                                onClick={(e) => handlePopupActionLocation(0)}
                            >
                                <Add />
                            </ButtonComponent>
                        </div>
                    </div>

                    <div className="col-sm-12 col-md-12 col-lg-12 pl-2">
                        <div className={`col-sm-12 col-md-12 pl-0 pr-4 ${selectedMenu === "oldReports" ? 'col-lg-6' : 'col-lg-12'}`} >
                            <>
                                {loaderStatus ? (
                                    <div className="LoadImg">
                                        <img
                                            className="loaderClass"
                                            src={loader}
                                        />
                                    </div>
                                ) : (
                                    <Grid
                                        config={savedReportHeaders}
                                        data={generateGridData(
                                            'Saved Reports',
                                            savedReportData
                                        )}
                                        width={'100%'}
                                        height={gridHieght}
                                    />
                                )}
                            </>
                        </div>
                        {selectedRowData && selectedRowData.length > 0 ? (
                            <div
                                className="col-sm-12 col-md-12 col-lg-6 pl-4 "  >
                                {selectedMenu === 'oldReports' && selectedRowData && <ReportPreviewTab
                                    previewData={selectedRowData as any}
                                />}
                                 
                                {selectedMenu === 'boldReports' && selectedRowData && <LLModal
                                        handleClose={() => {
                                         handleCloseBoldReports()
                                        }}
                                        show={addScreen}
                                        width={1200}
                                    >
                                     <>
                                    
                                    <ContentHeader value={selectedRowData[0]?.name} />
                                    <Divider />
                                    {boldReportUrl ?
                                     <ReportViewer 
                                     reportName={getDynamicBoldReportUrl(selectedRowData[0].name)}
                                     reportServiceUrl ={`${boldReportsURL}/reporting/reportservice/api/Viewer`}
                                     reportServerUrl = {`${boldReportsURL}${sitePath}`}
                                     accessToken = {boldReportAccessToken}
                                     ></ReportViewer>
                                     :""}
                                        </>
                                    </LLModal>}
                            </div>
                        ):""}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SavedReport;
