import { ButtonComponent } from "@syncfusion/ej2-react-buttons";
import axios from "axios";
import { useEffect, useState } from "react";
import {
  addMasterDataEndPoint,
  getEndPoint,
  getStatesByCountry,
} from "./utils";
import { useDispatch, useSelector } from "react-redux";
import userIcon from "../../assets/userIcon.png";
import { setCurrentContact, setMasterDataAddImage, setStatesData } from "./contactReducer";
import { RootState } from "../../redux/store";
import { ContactType } from "./constants";
import notify from "../../services/toasterService";
import CountryDropdown from "../ContactsDetailsPanel/mobile-field-component";
import editIcon from "../../assets/EditRuleExcep.png";
import AddFileUploadScreen from "../accessMgmt/addScreen/fileUpload";
import { getStatesByCountryCode } from "../accessMgmt/utils";

const ReclaimCenterAddScreen = (props: any) => {
  const { setAddScreen } = props;
  useEffect(() => {
    getStatesList('USA');
  },[]);  
  const dispatch = useDispatch();
  const [selectedImage, setSelectedImage] = useState();
  const [reclaimCenterName, setReclaimCenterName] = useState("");
  const [reclaimCenterEmail, setReclaimCenterEmail] = useState("");
  const [reclaimCenterPhone, setReclaimCenterPhone] = useState("");
  const [reclaimCenterAltPhone, setReclaimCenterAltPhone] = useState("");
  const [reclaimCenterFax, setReclaimCenterFax] = useState("");
  const [reclaimCenterAddressStreet, setReclaimCenterAddressStreet] =
    useState("");
  const [reclaimCenterAddressCity, setReclaimCenterAddressCity] = useState("");
  const [reclaimCenterAddressState, setReclaimCenterAddressState] =
    useState("");
  const [reclaimCenterAddressZip, setReclaimCenterAddressZip] = useState("");
  const [reclaimCenterAddressCountry, setReclaimCenterAddressCountry] =
    useState("");
  const [reclaimCenterLinkWarehouse, setReclaimCenterLinkWarehouse] = useState<
    any[]
  >([]);
  const [reclaimCenterLanguage, setReclaimCenterLanguage] = useState("en-US");
  const [reclaimCenterCountryCodePhone, setReclaimCenterCountryCodePhone] =
    useState("");
  const [reclaimCenterCountryCodeFax, setReclaimCenterCountryCodeFax] =
    useState("");
  const [reclaimCenterCodeAltPhone, setReclaimCenterCodeAltPhone] =
    useState("");
  const [reclaimCenterCurrencyCode, setReclaimCenterCurrencyCode] =
    useState("US Dollar");
  const [reclaimCenterTimeZone, setReclaimCenterTimeZone] = useState(
    "Eastern Standard Time"
  );
  const [reclaimCenterCompanyId, setReclaimCenterCompanyId] = useState("");
  const [addButtonDisabledRC, setAddButtonDisabledRC] = useState(true);

  const [myName, setMyName] = useState("");
  const [myEmail, setMyEmail] = useState("");
  const [myPhone, setMyPhone] = useState("");
  const [myStreet, setMyStreet] = useState("");
  const [myCity, setMyCity] = useState("");
  const [myCountry, setMyCountry] = useState("");
  const [myState, setMyState] = useState("");
  const [myZip, setMyZip] = useState("");
  const [myCompanyCode, setMyCompanyCode] = useState("");

  const [errors, setErrors] = useState({
    nameError: "",
    emailError: "",
    companyCodeError: "",
    phoneNumberError: "",
    altPhoneError: "",
    languageError: "",
    currencyCodeError: "",
    timezoneError: "",
    zipError: "",
    streetError: "",
    cityError: "",
    stateError: "",
    countryError: "",
    faxError: "",
  });
  let nameError = "";
  let emailError = "";
  let companyCodeError = "";
  let phoneNumberError = "";
  let altPhoneError = "";
  let languageError = "";
  let currencyCodeError = "";
  let timezoneError = "";
  let zipError = "";
  let streetError = "";
  let cityError = "";
  let stateError = "";
  let countryError = "";
  let faxError = "";
  const currencyData = useSelector(
    (state: RootState) => state.contactReducer?.currencyData
  );
  const timezoneData = useSelector(
    (state: RootState) => state.contactReducer?.timezoneData
  );
  const languageData = useSelector(
    (state: RootState) => state.contactReducer?.laungageData
  );
  const warehouseData = useSelector(
    (state: RootState) => state.contactReducer?.warehouses
  );
  const companyCodeData = useSelector(
    (state: RootState) => state.contactReducer?.companyCodes
  );
  const countryInfo = useSelector(
    (state: RootState) => state.contactReducer?.countryData
  );
  const countryCodeInfo = useSelector(
    (state: RootState) => state.contactReducer?.countryCodePhoneData
  );
  const statesInfo = useSelector(
    (state: RootState) => state.contactReducer?.statesData
  );
  const imageChange = (e: any) => {
    if (e.target.files && e.target.files.length > 0) {
      setSelectedImage(e.target.files[0]);
    }
  };

  const mobileWithFlag = useSelector(
    (state: RootState) => state.contactReducer?.selectedMobileWithCountryFlag
  );
  const altMobileWithFlag = useSelector(
    (state: RootState) => state.contactReducer?.selectedAltMobileWithCountryFlag
  );
  const faxWithFlag = useSelector(
    (state: RootState) => state.contactReducer?.selectedFaxWithCountryFlag
  );
  const phoneCountryCode = useSelector(
    (state: RootState) => state.contactReducer?.selectedCountryCodeForMobile
  );
  const AltPhoneCountryCode = useSelector(
    (state: RootState) => state.contactReducer?.selectedCountryCodeForAltMobile
  );
  const faxCountryCode = useSelector(
    (state: RootState) => state.contactReducer?.selectedCountryCodeForFax
  );

  //******************** */
  const [emailErrorMsg, setEmailErrorMsg] = useState("");
  const [myRcCenterName, setMyRcCenterName] = useState("");
  const [myRcCompanyCode, setMyRcCompanyCode] = useState("");
  const [isReclaimCenterScreenDisabeled, setIsReclaimCenterScreenDisabeled] = useState(true);

  const [rcInfoSelectFieldObj, setRcInfoSelectFieldObj] = useState<
    any[]
  >([
    {
      companyId: reclaimCenterCompanyId,
      language: "en-US",
      timeZone: "Eastern Standard Time",
      countryId: "United States of America",
      stateId: "",
      currencyCode: "US Dollar"
    },
  ]);

  const [rcInfo, setRcInfo] = useState<any[]>([
    {
      reclaimcenterName: "",
      email: "",
      phoneNumber: "",
      zipcode: "",
      addressLine1: "",
      addressLine2: "",
      alternatePhoneNumber: "",
    phoneCountryCode: phoneCountryCode,
    altPhoneCountryCode: AltPhoneCountryCode,
    faxCountryCode: faxCountryCode,
    fax: "",
    },
  ]);

  const handleReclaimCenterTextFields = (event: any, key: any) => {
  if (key === "zipcode") {
      if (event.target.value.length <= 5) {
        setRcInfo([
          { ...rcInfo[0], [key]: event.target.value },
        ]);
        validateReclaimCenters();
      }
    } else {
      setRcInfo([{ ...rcInfo[0], [key]: event.target.value }]);
      validateReclaimCenters();
    }
  };


  const [statesList, setStateList] = useState<any>([]);

  const getStatesList = async (countryCode: any) => {
    const endPointUrl = getStatesByCountryCode(countryCode);
    await axios.get(endPointUrl).then(
      (res) => {
        setStateList(res.data);
        // dispatch(setStatesList(res.data));
      },
      (err) => {
        notify("Failed to fetch data", "error");
      }
    );
  };
  
  const handleReclaimCenterSelectFields = (event: any, key: any) => {
    //const dataa = JSON.parse(event.target.value);
    if (key === "countryId") {
      const dataa = JSON.parse(event.target.value);
      getStatesList(dataa.isoCountryCode);
      setRcInfoSelectFieldObj([
        { ...rcInfoSelectFieldObj[0], [key]: dataa.countryName },
      ]);
      setRcInfo([{ ...rcInfo[0], [key]: dataa.id }]);
    }
    // setMobileUserSelectFields([{...mobileUserSelectFields[0], [key]: dataa}])
    if (key === "stateId") {
      setRcInfoSelectFieldObj([
        { ...rcInfoSelectFieldObj[0], [key]: event.target.value },
      ]);
      //setRcInfo([{ ...rcInfo[0], [key]: dataa.id }]);
    }
    if (key === "language") {
      setRcInfoSelectFieldObj([
        { ...rcInfoSelectFieldObj[0], [key]: event.target.value },
      ]);
      //setRcInfo([{ ...rcInfo[0], [key]: dataa.isoLongCode }]);
    }
    if (key === "timezone") {
      setRcInfoSelectFieldObj([
        { ...rcInfoSelectFieldObj[0], [key]: event.target.value },
      ]);
      //setRcInfo([{ ...rcInfo[0], [key]: dataa.timezoneName }]);
    }
    if (key === "companyId") {
      setRcInfoSelectFieldObj([
        { ...rcInfoSelectFieldObj[0], [key]: event.target.value },
      ]);
      //setRcInfo([{ ...rcInfo[0], [key]: dataa.timezoneName }]);
    }

    //validateMobileUsers();
  };

  const validateReclaimCenters = () =>{
    if (rcInfo[0].reclaimcenterName === "") {
      nameError = "Required Field";
      setMyRcCenterName("1px solid red");
    } else {
      setMyRcCenterName("");
    }
    if (rcInfo[0].email !== "") {
      console.log(emailError)
      var regexFax = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    if (!regexFax.test(rcInfo[0].email)) {
      console.log(emailError)
      emailError = "Invalid Email";
      setEmailErrorMsg("Invalid Email")
    }      
     else {
      setEmailErrorMsg("");
    }
  }
    if (rcInfoSelectFieldObj[0].companyId === "") {
      companyCodeError = "Required Field";
      setMyRcCompanyCode("1px solid red");
    } else {
      setMyRcCompanyCode("");
    }
    if (rcInfo[0].currencyCode === "") {
      currencyCodeError = "Required Field";
      //setMyUserName("1px solid red");
    } else {
      //setMyUserName("");
    }
    if (rcInfoSelectFieldObj[0].currencyCode === "") {
      currencyCodeError = "Required Field";
      //setMyUserName("1px solid red");
    } else {
      //setMyUserName("");
    }
    if (rcInfoSelectFieldObj[0].language === "") {
      languageError = "Required Field";
      //setMyUserName("1px solid red");
    } else {
      //setMyUserName("");
    }
    if (rcInfoSelectFieldObj[0].timeZone === "") {
      timezoneError = "Required Field";
      //setMyUserName("1px solid red");
    } else {
      //setMyUserName("");
    }
    if (
      //mobileUserInfo[0].firstname === "" ||
      rcInfo[0].reclaimcenterName === "" 
     || rcInfoSelectFieldObj[0].timeZone === "" 
     || rcInfoSelectFieldObj[0].language === "" 
     || rcInfoSelectFieldObj[0].currencyCode === "" 
      // mobileUserInfo[0].password === ""
    ) {
      setErrors({
        nameError,
        emailError,
        companyCodeError,
        phoneNumberError,
        altPhoneError,
        languageError,
        currencyCodeError,
        timezoneError,
        zipError,
        streetError,
        cityError,
        stateError,
        countryError,
        faxError,
      });
      setIsReclaimCenterScreenDisabeled(true);
      return false;
    } else {
      setIsReclaimCenterScreenDisabeled(false);
    }
    return true;
    }
  //******************* */

  const handleReclaimCenterName = (e: any) => {
    setReclaimCenterName(e.target.value);
    validate();
  };
  const handleReclaimCenterEmail = (e: any) => {
    if (e.target.value === "") {
      setAddButtonDisabledRC(true);
    } else {
      setReclaimCenterEmail(e.target.value);
      validate();

      setErrors({
        nameError,
        emailError,
        companyCodeError,
        phoneNumberError,
        altPhoneError,
        languageError,
        currencyCodeError,
        timezoneError,
        zipError,
        streetError,
        cityError,
        stateError,
        countryError,
        faxError,
      });
      const reg =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      // if (e.target.value === "") {
      //   emailError = "Email is Required";
      // }
      if (e.target.value && reg.test(e.target.value) === false) {
        emailError = "Email is Invalid ";
      }
      if (emailError) {
        setErrors({
          nameError,
          emailError,
          companyCodeError,
          phoneNumberError,
          altPhoneError,
          languageError,
          currencyCodeError,
          timezoneError,
          zipError,
          streetError,
          cityError,
          stateError,
          countryError,
          faxError,
        });
        setAddButtonDisabledRC(true);
        return false;
      } else {
        setReclaimCenterEmail(e.target.value);
        return true;
      }
    }
  };
  const handleReclaimCenterPhone = (e: any) => {
    if (e.target.value === "") {
      setAddButtonDisabledRC(true);
    } else {
      setReclaimCenterPhone(e.target.value);
      validate();
      var phoneno = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
      setErrors({
        nameError,
        emailError,
        companyCodeError,
        phoneNumberError,
        altPhoneError,
        languageError,
        currencyCodeError,
        timezoneError,
        zipError,
        streetError,
        cityError,
        stateError,
        countryError,
        faxError,
      });
      if (e.target.value.match(phoneno)) {
        setReclaimCenterPhone(e.target.value);
        phoneNumberError = "";
        setErrors({
          nameError,
          emailError,
          companyCodeError,
          phoneNumberError,
          altPhoneError,
          languageError,
          currencyCodeError,
          timezoneError,
          zipError,
          streetError,
          cityError,
          stateError,
          countryError,
          faxError,
        });
        setAddButtonDisabledRC(false);
        return true;
      } else {
        setAddButtonDisabledRC(true);
        phoneNumberError = "Phone number is invalid";
      }
      if (phoneNumberError) {
        setErrors({
          nameError,
          emailError,
          companyCodeError,
          phoneNumberError,
          altPhoneError,
          languageError,
          currencyCodeError,
          timezoneError,
          zipError,
          streetError,
          cityError,
          stateError,
          countryError,
          faxError,
        });
        setAddButtonDisabledRC(true);
        return false;
      } else {
        setAddButtonDisabledRC(false);
      }
    }
  };
  const handleReclaimCenterAltPhone = (e: any) => {
    setReclaimCenterAltPhone(e.target.value);
    validate();
    var phoneno = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
    setErrors({
      nameError,
      emailError,
      companyCodeError,
      phoneNumberError,
      altPhoneError,
      languageError,
      currencyCodeError,
      timezoneError,
      zipError,
      streetError,
      cityError,
      stateError,
      countryError,
      faxError,
    });
    if (e.target.value.match(phoneno)) {
      setReclaimCenterAltPhone(e.target.value);
      altPhoneError = "";
      setErrors({
        nameError,
        emailError,
        companyCodeError,
        phoneNumberError,
        altPhoneError,
        languageError,
        currencyCodeError,
        timezoneError,
        zipError,
        streetError,
        cityError,
        stateError,
        countryError,
        faxError,
      });
      setAddButtonDisabledRC(false);
      return true;
    } else {
      setAddButtonDisabledRC(true);
      altPhoneError = "Phone number is invalid";
    }
    if (altPhoneError) {
      setErrors({
        nameError,
        emailError,
        companyCodeError,
        phoneNumberError,
        altPhoneError,
        languageError,
        currencyCodeError,
        timezoneError,
        zipError,
        streetError,
        cityError,
        stateError,
        countryError,
        faxError,
      });
      setAddButtonDisabledRC(true);
      return false;
    } else {
      setAddButtonDisabledRC(false);
    }
  };
  const handleReclaimCenterFax = (e: any) => {
    validate();
    setErrors({
      nameError,
      emailError,
      companyCodeError,
      phoneNumberError,
      altPhoneError,
      languageError,
      currencyCodeError,
      timezoneError,
      zipError,
      streetError,
      cityError,
      stateError,
      countryError,
      faxError,
    });
    var phoneno = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
    if (e.target.value.match(phoneno)) {
      setReclaimCenterFax(e.target.value);
      faxError = "";
      setErrors({
        nameError,
        emailError,
        companyCodeError,
        phoneNumberError,
        altPhoneError,
        languageError,
        currencyCodeError,
        timezoneError,
        zipError,
        streetError,
        cityError,
        stateError,
        countryError,
        faxError,
      });
      return true;
    } else {
      faxError = "Fax is invalid";
    }
    if (faxError) {
      setErrors({
        nameError,
        emailError,
        companyCodeError,
        phoneNumberError,
        altPhoneError,
        languageError,
        currencyCodeError,
        timezoneError,
        zipError,
        streetError,
        cityError,
        stateError,
        countryError,
        faxError,
      });
      return false;
    }
  };
  const handleReclaimCenterAddressStreet = (e: any) => {
    setReclaimCenterAddressStreet(e.target.value);
    validate();
  };
  const handleReclaimCenterAddressCity = (e: any) => {
    setReclaimCenterAddressCity(e.target.value);
    validate();
  };

  const handleReclaimCenterAddressState = (e: any) => {
    setReclaimCenterAddressState(e.target.value);
    validate();
  };
  const handleReclaimCenterAddressZip = (e: any) => {
    setErrors({
      nameError,
      emailError,
      companyCodeError,
      phoneNumberError,
      altPhoneError,
      languageError,
      currencyCodeError,
      timezoneError,
      zipError,
      streetError,
      cityError,
      stateError,
      countryError,
      faxError,
    });
    var regexFax = "^[0-9]{5}(?:-[0-9]{4})?$";
    if (e.target.value.length <= 5) {
      setReclaimCenterAddressZip(e.target.value);
    }
    if (e.target.value.match(regexFax)) {
      setReclaimCenterAddressZip(e.target.value);
      zipError = "";
      setErrors({
        nameError,
        emailError,
        companyCodeError,
        phoneNumberError,
        altPhoneError,
        languageError,
        currencyCodeError,
        timezoneError,
        zipError,
        streetError,
        cityError,
        stateError,
        countryError,
        faxError,
      });
      return true;
    } else {
      zipError = "Zip code is invalid";
    }
    if (zipError) {
      setErrors({
        nameError,
        emailError,
        companyCodeError,
        phoneNumberError,
        altPhoneError,
        languageError,
        currencyCodeError,
        timezoneError,
        zipError,
        streetError,
        cityError,
        stateError,
        countryError,
        faxError,
      });
      return false;
    }
    validate();
  };
  const handleReclaimCenterAddressCountry = (e: any) => {
    //setReclaimCenterAddressCountry(e.target.value);
    validate();
    const countryCode = JSON.parse(e.target.value);
    setReclaimCenterAddressCountry(countryCode.countryName);
    const getEndpoint = getStatesByCountry(countryCode.isoCountryCode);
    handleGetStatesList(getEndpoint);
  };
  const handleGetStatesList = async (getEndpoint: string) => {
    const getresult = await axios.get(getEndpoint);
    let cData: any = getresult.data;
    dispatch(setStatesData(cData));
  };
  const handleReclaimCeterCompanyId = (e: any) => {
    setReclaimCenterCompanyId(e.target.value);
    validate();
  };
  const handleReclaimCenterLinkWH = (e: any) => {
    //setReclaimCenterLinkWarehouse(e.target.value);
    const addWarehouseItem = JSON.parse(e.target.value);
    const whInfo = [...reclaimCenterLinkWarehouse];
    whInfo.push(addWarehouseItem);
    setReclaimCenterLinkWarehouse(whInfo);
  };
  const removeLinkWarehouseData = (value: any) => {
    let linkedWarehouseData = [...reclaimCenterLinkWarehouse];
    let updatedWarehouseData = linkedWarehouseData.filter(
      (item) => item.id !== value
    );
    var mySelect = document.getElementById(
      "reclaimCenterWareHouseLinkDropDown"
    ) as HTMLSelectElement;
    if (mySelect) {
      mySelect.selectedIndex = 0;
    }
    setReclaimCenterLinkWarehouse(updatedWarehouseData);
  };
  const handleReclaimCenterLanguage = (e: any) => {
    setReclaimCenterLanguage(e.target.value);
    validate();
  };
  const handleReclaimCenterCurrencyCode = (e: any) => {
    setReclaimCenterCurrencyCode(e.target.value);
    validate();
  };
  const handleReclaimCenterTimeZone = (e: any) => {
    setReclaimCenterTimeZone(e.target.value);
    validate();
  };
  const handleChangeCountryCodePhoneRC = (e: any) => {
    setReclaimCenterCountryCodePhone(e.target.value);
  };
  const handleChangeCountryCodeAltPhoneRC = (e: any) => {
    setReclaimCenterCodeAltPhone(e.target.value);
  };
  const handleChangeCountryCodeFaxRC = (e: any) => {
    setReclaimCenterCountryCodeFax(e.target.value);
  };
  const reclaimCenterFinalAddedWH: any[] = [];
  const body = {
    active: true,
    address: [
      {
        addressLine1: "16 Elim Street",
        addressLine2: "Street12",
        alternateAddress: false,
        city: reclaimCenterAddressCity,
        country: reclaimCenterAddressCountry,
        deleted: false,
        state: reclaimCenterAddressState,
        street: reclaimCenterAddressStreet,
        zipcode: reclaimCenterAddressZip,
      },
    ],
    alternatePhoneNumber: altMobileWithFlag?.toString().replace(/[^0-9]/g, ""),
    phoneCountryCode: phoneCountryCode,
    altPhoneCountryCode: AltPhoneCountryCode,
    faxCountryCode: faxCountryCode,
    clientId: 1,
    companyId: reclaimCenterCompanyId,
    currencyCode: reclaimCenterCurrencyCode,
    deleted: false,
    email: reclaimCenterEmail,
    fax: faxWithFlag?.toString().replace(/[^0-9]/g, ""),
    language: reclaimCenterLanguage,
    notes: "",
    phoneNumber: mobileWithFlag?.toString().replace(/[^0-9]/g, ""),
    reclaimcenterName: reclaimCenterName,
    timezone: reclaimCenterTimeZone,
    warehouseIds: reclaimCenterFinalAddedWH,
  };
  let tabName: any = ContactType.RECLAIM_CENTERS;
  const handleAddReclaimCenter = () => {
    var phone = document.getElementById("txtPhone") as HTMLInputElement;
    var countryCodephone = document.getElementById(
      "txtCountryCodePhone"
    ) as HTMLSelectElement;
    var altPhone = document.getElementById("txtAltPhone") as HTMLInputElement;
    var countryCodeAltPhone = document.getElementById(
      "txtCountryCodeAltPhone"
    ) as HTMLSelectElement;
    var fax = document.getElementById("txtFax") as HTMLInputElement;
    var countryCodeFax = document.getElementById(
      "txtCountryCodeFax"
    ) as HTMLSelectElement;
    // if(phone.value !== '' && countryCodephone.value === ''){
    //   notify('Please select Country code for Phone number', 'warning');
    // }
    // else if(altPhone.value !== '' && countryCodeAltPhone.value === ''){
    //   notify('Please select Country code for Alt Phone number', 'warning');
    // }
    // else if(fax.value !== '' && countryCodeFax.value === ''){
    //   notify('Please select Country code for Fax', 'warning');
    // }
    // else{
   // if (validate()) {
      reclaimCenterLinkWarehouse.map((item) =>
        reclaimCenterFinalAddedWH.push(item.id)
      );
      //console.log("warehouseFinalLinkStores", body)
      const endpoint = addMasterDataEndPoint(tabName);
      const getEndpoint = getEndPoint(tabName);
      handleMasterDataChange(endpoint, getEndpoint);
      //  }
   // }
  };

  const handleMasterDataChange = async (
    endpoint: string,
    getEndpoint: string
  ) => {
   
    const reqBody = {
      active: true,
      address: [
        {
          addressLine1: rcInfo[0].addressLine1,
          addressLine2: rcInfo[0].addressLine2,
          alternateAddress: false,
          city: rcInfo[0].addressLine2,
          country: rcInfoSelectFieldObj[0].countryId,
          deleted: false,
          state: rcInfoSelectFieldObj[0].stateId,
          street: rcInfo[0].addressLine1,
          zipcode: rcInfo[0].zip,
        },
      ],
      alternatePhoneNumber: altMobileWithFlag?.toString().replace(/[^0-9]/g, ""),
      phoneCountryCode: phoneCountryCode,
      altPhoneCountryCode: AltPhoneCountryCode,
      faxCountryCode: faxCountryCode,
      clientId: 1,
      companyId: rcInfoSelectFieldObj[0].companyId,
      currencyCode: rcInfoSelectFieldObj[0].currencyCode,
      deleted: false,
      email: rcInfo[0].email,
      fax: faxWithFlag?.toString().replace(/[^0-9]/g, ""),
      language: rcInfoSelectFieldObj[0].language,
      notes: "",
      phoneNumber: mobileWithFlag?.toString().replace(/[^0-9]/g, ""),
      reclaimcenterName: rcInfo[0].reclaimcenterName,
      timezone: rcInfoSelectFieldObj[0].timeZone,
      warehouseIds: reclaimCenterFinalAddedWH,
    };
    const result = await axios
      .post(endpoint, reqBody)
      .then((results: any) => {
        if (results && results.data) {
          setAddScreen(false);
          notify("Reclaim Center created successfully", "success");
        } else {
          notify("Failed to create Reclaim Center", "error");
        }
      })
      .catch((error: any) => {
        if (error.response) {
          notify(error.response?.data?.message, "error");
        }
      });
    const getresult = await axios.get(getEndpoint);
    dispatch(setCurrentContact(getresult.data));
  };
  const handleCancelReclaimCenterPopup = () => {
    setAddScreen(false);
    dispatch(setMasterDataAddImage([]));
  };
  const validate = (field?: any, value?: any) => {
    setErrors({
      nameError,
      emailError,
      companyCodeError,
      phoneNumberError,
      altPhoneError,
      languageError,
      currencyCodeError,
      timezoneError,
      zipError,
      streetError,
      cityError,
      stateError,
      countryError,
      faxError,
    });
    if (reclaimCenterLanguage === "") {
      languageError = "Language is required";
    }
    if (reclaimCenterCurrencyCode === "") {
      currencyCodeError = "Currency code is required";
    }
    if (reclaimCenterTimeZone === "") {
      timezoneError = "Timezone is required";
    }
    if (reclaimCenterName === "") {
      nameError = "Required Field";
      setMyName("1px solid red");
    } else {
      setMyName("");
    }
    // if (reclaimCenterAddressState === "") {
    //   stateError = "Required Field";
    //   setMyState("1px solid red");
    // } else {
    //   setMyState("");
    // }
    // if (reclaimCenterAddressCountry === "") {
    //   countryError = "Required Field";
    //   setMyCountry("1px solid red");
    // } else {
    //   setMyCountry("");
    // }
    // const reg =
    //   /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    // if (reclaimCenterEmail === "") {
    //   emailError = "Required Field";
    //   setMyEmail("1px solid red");
    // } else if (reclaimCenterEmail && reg.test(reclaimCenterEmail) === false) {
    //   emailError = "Email is Invalid ";
    // } else {
    //   setMyEmail("");
    // }

    if (reclaimCenterCompanyId === "") {
      companyCodeError = "Required Field";
      setMyCompanyCode("1px solid red");
    } else {
      setMyCompanyCode("");
    }
    const phoneReg =
      /^[(]{0,1}[0-9]{3}[)]{0,1}[-\s\.]{0,1}[0-9]{3}[-\s\.]{0,1}[0-9]{4}$/;
    // if (reclaimCenterPhone === "") {
    //   phoneNumberError = "Required Field";
    //   setMyPhone("1px solid red");
    // } 
     if (
      reclaimCenterPhone &&
      phoneReg.test(reclaimCenterPhone) === false
    ) {
      phoneNumberError = "Phone Number is Invalid";
    } else {
      setMyPhone("");
    }
    if (
      reclaimCenterAltPhone &&
      phoneReg.test(reclaimCenterAltPhone) === false
    ) {
      altPhoneError = "Phone Number is Invalid";
    }

    if (reclaimCenterFax != "") {
      var phoneno = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
      if (reclaimCenterFax && phoneno.test(reclaimCenterFax) === false) {
        faxError = "Fax is Invalid";
      }
    }
    // var regexFax = /^[0-9]{5}(?:-[0-9]{4})?$/;

    //   if(reclaimCenterAddressZip.match(regexFax)) {
    //   zipError = "Zip code is invalid";
    //   }

    // if (reclaimCenterAddressStreet === "") {
    //   streetError = "Required Field";
    //   setMyStreet("1px solid red");
    // } else {
    //   setMyStreet("");
    // }
    // if (reclaimCenterAddressCity === "") {
    //   cityError = "Required Field";
    //   setMyCity("1px solid red");
    // } else {
    //   setMyCity("");
    // }

    // if (reclaimCenterAddressZip === "") {
    //   zipError = "Required Field";
    //   setMyZip("1px solid red");
    // } else {
    //   setMyZip("");
    // }

    if (
      emailError ||
      nameError ||
      companyCodeError ||
      languageError ||
      currencyCodeError ||
      timezoneError ||
      zipError ||
      streetError ||
      cityError ||
      stateError ||
      countryError
    ) {
      setErrors({
        nameError,
        emailError,
        companyCodeError,
        phoneNumberError,
        altPhoneError,
        languageError,
        currencyCodeError,
        timezoneError,
        zipError,
        streetError,
        cityError,
        stateError,
        countryError,
        faxError,
      });
      //setAddButtonDisabledRC(true)
      return false;
    } else {
      setAddButtonDisabledRC(false);
    }
    return true;
  };

  let uploadedImageFile = useSelector(
    (state: RootState) => state.contactReducer.uploadedImage
  );

  const [addScreen, setFileUploadAddScreen] = useState(false);
  const [addScreenTabName, setAddScreenTabName] = useState(null);
  const [screenType, setScreenType] = useState("masterdataAdd");

  const handlePopupActionAddReclaim = (selectedTabName: any) => {
    uploadedImageFile = [];
    setFileUploadAddScreen(true);
    setAddScreenTabName(selectedTabName);
  };

  let [stepperindexvalue, setStepperIndexValue] = useState(1);
  const goToNextStep = (index: number) => {
    if (index === 1) {
      setStepperIndexValue(1);
    } else if (index === 2) {
      setStepperIndexValue(2);
    }
  };

  return (
    <div className="commodity-popup" style={{marginRight: '0'}}>
      <div className="StepperControl">
        <div className="col-6 pl-2 pt-0 mt-auto mb-auto">
          <div className="page-header--col col--steps">
            <ul className="list--steps pt-3 mb-0 pointer">
              <li
                className={` ${stepperindexvalue === 1 ? "active" : ""}`}
                onClick={(e) => goToNextStep(1)}
              >
                <strong className="steps--number">1</strong>
                <p className="steps--label"></p>
              </li>
              <li
                className={` ${stepperindexvalue === 2 ? "active" : ""}`}
                onClick={(e) => goToNextStep(2)}
              >
                <strong className="steps--number">2</strong>
                <p className="steps--label"></p>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="row">
      {addScreen && (
        <AddFileUploadScreen
          addScreen={addScreen}
          setFileUploadAddScreen={setFileUploadAddScreen}
          tabName={addScreenTabName}
          screenType={screenType}
        />
      )}
        <div className="row d-flex justify-content-center">
          <div className="col-xl-2 col-lg-2 roundImage">
          {/* <input accept="image/*" type="file" onChange={imageChange} /> */}
          {/* <img src={userIcon} className="userAddIcon" />
          {selectedImage && (
            <div className="imagePreviewDiv">
              <img
                src={URL.createObjectURL(selectedImage)}
                className="imagePreview"
                height="100"
                width="100"
              />
            </div>
          )} */}
          {uploadedImageFile && uploadedImageFile.length > 0 ? (
            <div className="ImageContainer">
                <img
                  src={URL.createObjectURL(uploadedImageFile[0].file)}
                  className="userAddIcon"
                  height="140"
                  width="140"
                /></div>
              ) : (
                <img src={userIcon} className="userAddIcon" />
              )}
              <div className="middle">
              <img
                src={editIcon}
                alt="edit "
                width="18"
                height="18"
                className="m-3 pointer avatarEdit"
                onClick={() => handlePopupActionAddReclaim("fileUpload")}
                style={{position: 'sticky'}}
              /></div>
        </div>
        </div>
        </div>

        <div>
        {stepperindexvalue === 1 ? (
          <div>
        <div className="col-sm-12 row m-0 mt-3">
          <div className="col-sm-6">
            <div className="col-sm-12 mt-2">
              <span className="lblClass" style={{float: 'left', marginTop: '0'}}>
                Name<span className="requiredStar">*</span>
              </span>
              {rcInfo[0].reclaimcenterName === "" ? (
                <span className="text-danger" style={{float: 'right'}}>{errors.nameError}</span>
              ) : (
                ""
              )}
              <input
                type="text"
                className="formcontrol"
                name="name"
                onChange={(e) => handleReclaimCenterTextFields(e, 'reclaimcenterName')}
                value={rcInfo[0].reclaimcenterName}
                style={{ border: myRcCenterName }}/>              
            </div>        
          
            <div className="col-sm-12 mt-3">
              <span className="lblClass" style={{float: 'left',marginTop: '0'}}>Email
                {/* <span className="requiredStar">*</span> */}
              </span>
              {emailErrorMsg !== '' ? (
                <span className="text-danger mt-3" style={{float: 'right'}}>{emailErrorMsg}</span>
              ) : (
                ""
              )}          
              <input
                type="text"
                className="formcontrol"
                name="email"
                placeholder="someone@email.com"
                onChange={(e) => handleReclaimCenterTextFields(e, 'email')}
                onBlur={(e) => handleReclaimCenterTextFields(e, 'email')}
                onKeyUp={(e) => handleReclaimCenterTextFields(e, 'email')}
                value={rcInfo[0].email}
                style={{ border: myEmail }}
              />              
            </div>
            
            <div className="col-sm-12 mt-3">
              <span className="lblClass" style={{float: 'left', marginTop: '0'}}>
                Company Code<span className="requiredStar">*</span>
              </span>
              {rcInfoSelectFieldObj[0].companyId === "" ? (
                <span className="text-danger" style={{float: 'right'}}>{errors.companyCodeError}</span>
              ) : (
                ""
              )}            
              <select
                id="ddlCountry"
                className="formcontrol"                
                onChange={(e: any) => handleReclaimCenterSelectFields(e, "companyId")}
                style={{ border: myRcCompanyCode }}
              >
                {rcInfoSelectFieldObj[0].companyId !== "" ?
                <option value={rcInfoSelectFieldObj[0].companyId}>{rcInfoSelectFieldObj[0].companyId}</option>
                : <option value="">Select Company Code</option> }
                {companyCodeData?.map((cc) => (
                  <option value={cc.id}>{cc.companyName}</option>
                ))}
              </select>              
            </div>          
            </div>

            <div className="col-sm-6">          
            <div className="col-sm-12">
              <span className="lblClass" style={{float: 'left', marginTop: '0'}}>Address
                {/* <span className="requiredStar">*</span> */}
              </span>
            
              <input
                type="text"
                className="formcontrol"
                placeholder="Street"
                onChange={(e) => handleReclaimCenterTextFields(e, 'addressLine1')}
                value={rcInfo[0].addressLine1}
                style={{ border: myStreet }}
              />
              {streetError === "" ? (
                <span className="text-danger">{errors.streetError}</span>
              ) : (
                ""
              )}
            </div>         
            
            <div className="col-sm-12 mt-3">
              <input
                type="text"
                className="formcontrol"
                placeholder="City"
                onChange={(e) => handleReclaimCenterTextFields(e, 'addressLine2')}
                value={rcInfo[0].addressLine2}
                style={{ border: myCity }}
              />
              {cityError === "" ? (
                <span className="text-danger">{errors.cityError}</span>
              ) : (
                ""
              )}
            </div>         
            
            <div className="col-sm-12 mt-3">
              <select
                id="ddlCountry"
                className="formcontrol"
                onChange={(e: any) => handleReclaimCenterSelectFields(e, "countryId")}
                style={{ border: myCountry }}
              >
                {rcInfoSelectFieldObj[0].countryId !== "" ?
                <option value={rcInfoSelectFieldObj[0].countryId}>{rcInfoSelectFieldObj[0].countryId}</option>
               : <option value="">Select Country</option> }
                {countryInfo?.map((cn) => (
                  <option value={JSON.stringify(cn)}>{cn.countryName}</option>
                ))}
              </select>
              {reclaimCenterAddressCountry === "" ? (
                <span className="text-danger">{errors.countryError}</span>
              ) : (
                ""
              )}
            </div>         
            
            <div className="col-sm-8 pr-0 mt-3">
              <select
                id="ddlCountry"
                className="formcontrol"
                //value={rcInfoSelectFieldObj[0].stateId}
                onChange={(e: any) => handleReclaimCenterSelectFields(e, "stateId")}
                style={{ border: myState }}
              >
                {rcInfoSelectFieldObj[0].stateId !== "" ?
                <option value={rcInfoSelectFieldObj[0].stateId}>{rcInfoSelectFieldObj[0].stateId}</option>
                : <option value="">Select State</option> }
                {statesList?.map((cn:any) => (
                  <option value={cn.stateName}>{cn.stateName}</option>
                ))}
              </select>
              {reclaimCenterAddressState === "" ? (
                <span className="text-danger">{errors.stateError}</span>
              ) : (
                ""
              )}
            </div>

            <div className="col-sm-4 mt-3">
              <input
                type="number"
                className="formcontrol"
                placeholder="ZIP"
                onChange={(e) => handleReclaimCenterTextFields(e, 'zipcode')}
                value={rcInfo[0].zipcode}
                style={{ border: myZip }}
              />
              {zipError === "" ? (
                <span className="text-danger">{errors.zipError}</span>
              ) : (
                ""
              )}
            </div>

          {/* <div className="row ml-0 mr-0 mt-1rem">
            <div className="col-sm-4">
              <span className="lblClass">Link Warehouses</span>
            </div>
            <div className="col-sm-8">
              <select className="form-control" id="reclaimCenterWareHouseLinkDropDown" name="reclaimCenterWareHouseLinkDropDown" onChange={(e) => handleReclaimCenterLinkWH(e)}>
                <option>Search Warehouses</option>
                {warehouseData.map((wh) =>
                <option value={JSON.stringify(wh)}>{wh.warehouseName}</option>
                )}
              </select>
            </div>
          </div> */}
          <div className="linkedStoreDataDiv row">
            <div className="col-sm-4"></div>
            <div className="col-sm-7">
              {reclaimCenterLinkWarehouse &&
                reclaimCenterLinkWarehouse.map((item: any) => (
                  <div className="linkedStoreDiv">
                    <span title="remove" className="linkedStoreName">
                      {item.warehouseName}
                    </span>{" "}
                    <span
                      className="linkedStoreNameRemove"
                      onClick={(e) => removeLinkWarehouseData(item.id)}
                    >
                      X
                    </span>
                  </div>
                ))}
            </div>
          </div>
        </div>
        </div>
        <div className="col-sm-12 px-0" style={{marginTop: '7rem'}}>
                  <ButtonComponent
                    cssClass="eg-btn-primary float-right pt-3 pb-3 pl-5 pr-5"
                    style={
                      rcInfo[0].reclaimcenterName !== "" &&
                      rcInfoSelectFieldObj[0].companyId !== "" &&
                      emailErrorMsg === ""
                        ? {
                            opacity: "1",
                            pointerEvents: "all",
                            cursor: "pointer",
                          }
                        : {
                            opacity: "0.5",
                            pointerEvents: "none",
                            backgroundColor: "#e8e8e8",
                            color: "#969696",
                          }
                    }                    
                    onClick={() => goToNextStep(2)}
                  >
                    Next
                  </ButtonComponent>
                  <ButtonComponent
                    cssClass="eg-btn-primary-link e-flat mt-3"
                    onClick={() => handleCancelReclaimCenterPopup()}
                  >
                    Cancel
                  </ButtonComponent>
                </div>
        </div>
        ) : (
          <div>
        <div className="col-sm-12 row m-0 mt-2">
          <div className="col-sm-6">
            <div className="col-sm-12">
              <span className="lblClass" style={{float: 'left', marginLeft: '17px'}}>Phone
                {/* <span className="requiredStar">*</span> */}
              </span>
              <div className="col-sm-12">
              <CountryDropdown type="Phone" /></div>
              {/* <div className="col-sm-4 pl-0 pr-0">
            <select className="form-control" id="txtCountryCodePhone" onChange={(e: any)=>handleChangeCountryCodePhoneRC(e)}>
                  <option value=''>Select</option>
                  {countryCodeInfo?.map((ld) =>
                    <option value={ld?.mobileCountryCode}>{ld?.mobileCountryCode}</option>
                  )}
            </select>
            </div>
            <div className="col-sm-8 pl-0 pr-0">
            <input type="text" id="txtPhone" className="formcontrol" onChange={(e) => handleReclaimCenterPhone(e)}
            onKeyUp={(e) => handleReclaimCenterPhone(e)} onBlur={(e) => handleReclaimCenterPhone(e)} style={{border:myPhone}}/>
             {errors.phoneNumberError?<span className="text-danger">{errors.phoneNumberError}</span>:''}
            </div>  */}
            </div>
          
            <div className="col-sm-12">
              <span className="lblClass" style={{float: 'left', marginLeft: '17px', marginTop: '8px'}}>Alt Phone</span>
            <div className="col-sm-12">
              <CountryDropdown type="Alt Phone" />
              {/* <div className="col-sm-4 pl-0 pr-0">
            <select className="form-control" id="txtCountryCodeAltPhone"  onChange={(e: any)=>handleChangeCountryCodeAltPhoneRC(e)}>
                  <option value=''>Select</option>
                  {countryCodeInfo?.map((ld) =>
                    <option value={ld?.mobileCountryCode}>{ld?.mobileCountryCode}</option>
                  )}
            </select>
            </div>
            <div className="col-sm-8 pl-0 pr-0">
            <input type="text" id="txtAltPhone" className="form-control" onChange={(e) => handleReclaimCenterAltPhone(e)}
            onBlur={(e) => handleReclaimCenterAltPhone(e)} onKeyUp={(e) => handleReclaimCenterAltPhone(e)} />
            {errors.altPhoneError?<span className="text-danger">{errors.altPhoneError}</span>:""}
            </div> */}
            </div>
            </div>
          
          
          
            <div className="col-sm-12">
              <span className="lblClass" style={{float: 'left', marginLeft: '17px', marginTop: '8px'}}>Fax</span>
            <div className="col-sm-12">
              <CountryDropdown type="Fax" />
              {/* <div className="col-sm-4 pl-0 pr-0">
            <select className="form-control" id="txtCountryCodeFax" onChange={(e: any)=>handleChangeCountryCodeFaxRC(e)}>
                  <option value=''>Select</option>
                  {countryCodeInfo?.map((ld) =>
                    <option value={ld?.mobileCountryCode}>{ld?.mobileCountryCode}</option>
                  )}
            </select>
            </div>
            <div className="col-sm-8 pl-0 pr-0">
            <input type="text" id="txtFax" className="form-control" onChange={(e) => handleReclaimCenterFax(e)} />
            {errors.faxError?<span className="text-danger">{errors.faxError}</span>:""}
            </div> */}
            </div> 
            </div>         
          </div>
          
          <div className="col-sm-6">
            <div className="col-sm-12">
              <span className="lblClass" style={{float: 'left'}}>
                Language<span className="requiredStar">*</span>
              </span>            
              <select
                className="form-control"
                value={rcInfoSelectFieldObj[0].language}
                onChange={(e: any) => handleReclaimCenterSelectFields(e, "language")}
              >
                {languageData?.map((ld) => (
                  <option value={ld?.isoLongCode}>{ld?.isoLongCode}</option>
                ))}
              </select>
              {reclaimCenterLanguage === "" ? (
                <span className="text-danger">{errors.languageError}</span>
              ) : (
                ""
              )}
            </div>
          
          
            <div className="col-sm-12">
              <span className="lblClass" style={{float: 'left', marginTop: '8px'}}>
                Currency Code<span className="requiredStar">*</span>
              </span>
              <select
                className="form-control"
                value={rcInfoSelectFieldObj[0].currencyCode}
                onChange={(e: any) => handleReclaimCenterSelectFields(e, "currencyCode")}
              >
                {currencyData?.map((cc) => (
                  <option value={cc.currency}>{cc.currency}</option>
                ))}
              </select>
              {reclaimCenterCurrencyCode === "" ? (
                <span className="text-danger">{errors.currencyCodeError}</span>
              ) : (
                ""
              )}
            </div>
          
          
            <div className="col-sm-12">
              <span className="lblClass" style={{float: 'left', marginTop: '8px'}}>
                Time Zone<span className="requiredStar">*</span>
              </span>            
              <select
                className="form-control"
                value={rcInfoSelectFieldObj[0].timeZone}
                onChange={(e: any) => handleReclaimCenterSelectFields(e, "timeZone")}
              >
                {timezoneData?.map((tz) => (
                  <option value={tz.timezoneName}>
                    {tz.timezoneName}
                    {tz.utc}
                  </option>
                ))}
              </select>
              {reclaimCenterTimeZone === "" ? (
                <span className="text-danger">{errors.timezoneError}</span>
              ) : (
                ""
              )}
            </div>
          
          </div>
      
          <div className="col-sm-12 px-0" style={{marginTop: '7rem'}}>
        <ButtonComponent
          cssClass="eg-btn-primary float-right"
          onClick={() => handleAddReclaimCenter()}
          style={
            isReclaimCenterScreenDisabeled
              ? {
                  opacity: "0.5",
                  pointerEvents: "none",
                  backgroundColor: "#e8e8e8",
                  color: "#969696",
                  borderRadius: "4px",
                }
              : {
                  opacity: "1",
                  borderRadius: "4px",
                  pointerEvents: "all",
                  backgroundColor: "#636799",
                  color: "#ffffff",
                  cursor: "pointer",
                }
          }
        >
          ADD RECLAIM CENTER
        </ButtonComponent>
        <ButtonComponent
                      cssClass="eg-btn-secondary float-right mr-4"
                      onClick={() => goToNextStep(1)}
                    >
                      Back
                    </ButtonComponent>
        <ButtonComponent
                    cssClass="eg-btn-primary-link e-flat mt-3"
                    onClick={() => handleCancelReclaimCenterPopup()}
                  >
                    Cancel
                  </ButtonComponent>
      </div>
      </div>
      </div>
        )}
      </div>
    </div>
  );
};
export default ReclaimCenterAddScreen;
