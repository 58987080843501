import {
  TabComponent,
  TabItemDirective,
  TabItemsDirective,
} from "@syncfusion/ej2-react-navigations";
import "./previewPane.scss";
import { FC, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { InlineInputEdit } from "react-inline-input-edit";
import PreviewReclaimScan from "../messagemanagement/reclaimcenter/previewReclaimCenter";
import PreviewTimeZone from "../timezone/previewtimezone";
import PreviewLanguage from "../language/previewlanguage";
import PreviewCurrency from "../currencycode/previewcurrencycode";
import PreviewCountry from "../countrycode/previewcountrycode";
import PreviewMsgMmt from "../messagemanagement/previewMsgManagement";

const previewTab = [{ text: "General" }];

/* Declaring Interfaces for getting inputs other tabs */
interface IPreviewTab {
  previewData: any[];
}

const LLAdminPreviewTab: FC<IPreviewTab> = ({ previewData }) => {
  // console.log(previewData);
  const dispatch = useDispatch();
  const [clientInfo, setClientInfo] = useState<any>(previewData[0]);

  const tabName = previewData[0]?.tabApiName;

  useEffect(() => {
    // Write for onload
  }, []);

  function ClientPreview() {
    return (
      <div className="col-sm-12 px-3 py-3 preview-details  ">
        <div className="col-sm-12 px-0 pt-4 ">
          <div className="col-sm-6 col-md-6 col-lg-5 preview-Key  ">ID</div>
          <div className="col-sm-6 col-md-6 col-lg-7 preview-value ">
            {clientInfo.id}
          </div>
        </div>
        <div className="col-sm-12 px-0 pt-4">
          <div className="col-sm-6 col-md-6 col-lg-5 preview-Key  ">Name</div>
          <div className="col-sm-6 col-md-6 col-lg-7 preview-value ">
            <InlineInputEdit
              inputClassName="txtEditClassNameInput"
              text={clientInfo.clientName}
              labelClassName="txtEditClassName"
              // onFocusOut={(e) => countryFieldFocusOut(e, 'clientName')}
              labelPlaceHolder="---"
            />
          </div>
        </div>

        <div className="col-sm-12 p-0 mb-auto">
          <hr className="divider" />
        </div>

        <div className="col-sm-12 px-0 pt-4">
          <div className="col-sm-6 col-md-6 col-lg-5 preview-Key  ">
            Language
          </div>
          <div className="col-sm-6 col-md-6 col-lg-7 preview-value ">
            <InlineInputEdit
              inputClassName="txtEditClassNameInput"
              text="EN_US"
              labelClassName="txtEditClassName"
              // onFocusOut={(e) => countryFieldFocusOut(e, 'isoCountryCode')}
              labelPlaceHolder="---"
            />
          </div>
        </div>
        <div className="col-sm-12 px-0 pt-4">
          <div className="col-sm-6 col-md-6 col-lg-5 preview-Key  ">
            Time Zone
          </div>
          <div className="col-sm-6 col-md-6 col-lg-7 preview-value ">
            <InlineInputEdit
              inputClassName="txtEditClassNameInput"
              text="EST"
              labelClassName="txtEditClassName"
              // onFocusOut={(e) => countryFieldFocusOut(e, 'region')}
              labelPlaceHolder="---"
            />{" "}
          </div>
        </div>
        <div className="col-sm-12 px-0 pt-4">
          <div className="col-sm-6 col-md-6 col-lg-5 preview-Key  ">
            Currency Code
          </div>
          <div className="col-sm-6 col-md-6 col-lg-7 preview-value ">
            <InlineInputEdit
              inputClassName="txtEditClassNameInput"
              text="USD"
              labelClassName="txtEditClassName"
              // onFocusOut={(e) => countryFieldFocusOut(e, 'subRegion')}
              labelPlaceHolder="---"
            />
          </div>
        </div>

        {/* <div className="col-sm-12 px-0 pt-5 pb-4">
                    {footerPreview(countryInfo)}
                </div> */}
      </div>
    );
  }

  return (
    <div>
      <div className="col-sm-12 p-0 preview-card ">
        {tabName && tabName === "Time Zones" ? (
          <div className="col-sm-12 col-md-12 col-lg-12 px-0">
            <div className="row nameCard ml-0 mr-0" >
              <div className="col-sm-6 col-md-5 col-lg-4 pl-1 pr-2">
                <span className="shortNamePreviewPanel">
                  {previewData[0]?.name
                    ?.match(/\b(\w)/g)
                    ?.join("")
                    ?.substring(0, 2)
                    ?.toUpperCase().length > 1
                    ? previewData[0]?.name
                        ?.match(/\b(\w)/g)
                        ?.join("")
                        ?.substring(0, 2)
                        ?.toUpperCase()
                    : previewData[0]?.name?.substring(0, 2)?.toUpperCase()}
                </span>
              </div>
              <div className="col-sm6 col-md-7 col-lg-8 pl-0 pr-2">
                <div className="col-sm-12 p-0 main-header-text">
                  {previewData[0]?.name}
                </div>
                <div className="col-sm-12 p-0 sub-header-text">
                  {previewData[0]?.tabName}
                </div>
              </div>
            </div>
            <div className="col-sm-12 col-md-12 col-lg-12 px-0">
              <TabComponent
                heightAdjustMode="Auto"
                cssClass="orientation-tab preview-custom-tab"
              >
                <TabItemsDirective>
                  <TabItemDirective
                    header={previewTab[0]}
                    content={PreviewTimeZone}
                  />
                </TabItemsDirective>
              </TabComponent>
            </div>
          </div>
        ) : tabName && tabName === "Languages" ? (
          <div className="col-sm-12 col-md-12 col-lg-12 px-0">
            <div className="row nameCard ml-0 mr-0">
              <div className="col-sm-6 col-md-5 col-lg-4 pl-1 pr-2">
                <span className="shortNamePreviewPanel">
                  {previewData[0]?.language
                    ?.match(/\b(\w)/g)
                    ?.join("")
                    ?.substring(0, 2)
                    ?.toUpperCase().length > 1
                    ? previewData[0]?.language
                        ?.match(/\b(\w)/g)
                        ?.join("")
                        ?.substring(0, 2)
                        ?.toUpperCase()
                    : previewData[0]?.language?.substring(0, 2)?.toUpperCase()}
                  {/* {previewData[0]?.language?.substring(0, 2)?.toUpperCase()} */}
                </span>
              </div>
              <div className="col-sm-6 col-md-7 col-lg-8 pl-0 pr-2">
                <div className="col-sm-12 p-0 main-header-text">
                  {previewData[0]?.language}
                </div>
                <div className="col-sm-12 p-0 sub-header-text">
                  {previewData[0]?.tabName}
                </div>
              </div>
            </div>
            <div className="col-sm-12 col-md-12 col-lg-12 px-0">
              <TabComponent
                heightAdjustMode="Auto"
                cssClass="orientation-tab preview-custom-tab"
              >
                <TabItemsDirective>
                  <TabItemDirective
                    header={previewTab[0]}
                    content={PreviewLanguage}
                  />
                </TabItemsDirective>
              </TabComponent>
            </div>
          </div>
        ) : tabName && tabName === "Currency Codes" ? (
          <div className="col-sm-12 col-md-12 col-lg-12 px-0">
            <div className="row nameCard ml-0 mr-0">
              <div className="col-sm-6 col-md-5 col-lg-4 pl-1 pr-2">
                <span className="shortNamePreviewPanel">
                  {previewData[0]?.currency
                    ?.match(/\b(\w)/g)
                    ?.join("")
                    ?.substring(0, 2)
                    ?.toUpperCase().length > 1
                    ? previewData[0]?.currency
                        ?.match(/\b(\w)/g)
                        ?.join("")
                        ?.substring(0, 2)
                        ?.toUpperCase()
                    : previewData[0]?.currency?.substring(0, 2)?.toUpperCase()}
                </span>
              </div>
              <div className="col-sm-6 col-md-7 col-lg-8 pl-0 pr-2">
                <div className="col-sm-12 p-0 main-header-text">
                  {previewData[0]?.currency}
                </div>
                <div className="col-sm-12 p-0 sub-header-text">
                  {previewData[0]?.tabName}
                </div>
              </div>
            </div>
            <div className="col-sm-12 col-md-12 col-lg-12 px-0">
              <TabComponent
                heightAdjustMode="Auto"
                cssClass="orientation-tab preview-custom-tab"
              >
                <TabItemsDirective>
                  <TabItemDirective
                    header={previewTab[0]}
                    content={PreviewCurrency}
                  />
                </TabItemsDirective>
              </TabComponent>
            </div>
          </div>
        ) : tabName && tabName === "Country Codes" ? (
          <div className="col-sm-12 col-md-12 col-lg-12 px-0">
            <div className="row nameCard ml-0 mr-0">
              <div className="col-sm-6 col-md-5 col-lg-4 pl-1 pr-2">
                <span className="shortNamePreviewPanel">
                  {previewData[0]?.countryName
                    .match(/\b(\w)/g)
                    ?.join("")
                    .substring(0, 2)
                    ?.toUpperCase().length > 1
                    ? previewData[0]?.countryName
                        .match(/\b(\w)/g)
                        ?.join("")
                        .substring(0, 2)
                        ?.toUpperCase()
                    : previewData[0]?.countryName
                        .substring(0, 2)
                        ?.toUpperCase()}
                </span>
              </div>
              <div className="col-sm-6 col-md-7 col-lg-8 pl-0 pr-2">
                <div className="col-sm-12 p-0 main-header-text">
                  {previewData[0]?.countryName}
                </div>
                <div className="col-sm-12 p-0 sub-header-text">
                  {previewData[0]?.tabName}
                </div>
              </div>
            </div>
            <div className="col-sm-12 col-md-12 col-lg-12 px-0">
              <TabComponent
                heightAdjustMode="Auto"
                cssClass="orientation-tab preview-custom-tab"
              >
                <TabItemsDirective>
                  <TabItemDirective
                    header={previewTab[0]}
                    content={PreviewCountry}
                  />
                </TabItemsDirective>
              </TabComponent>
            </div>
          </div>
        ) : tabName && tabName === "Client ID Management" ? (
          <div className="col-sm-12 col-md-12 col-lg-12 px-0">
            <div className="row nameCard ml-0 mr-0">
              <div className="col-sm-6 col-md-5 col-lg-4 pl-1 pr-2">
                <span className="shortNamePreviewPanel">
                  {previewData[0]?.clientName
                    ?.match(/\b(\w)/g)
                    ?.join("")
                    .substring(0, 2)
                    ?.toUpperCase()}
                </span>
              </div>
              <div className="col-sm-6 col-md-7 col-lg-8 pl-0 pr-2">
                <div className="col-sm-12 p-0 main-header-text">
                  {previewData[0]?.clientName}
                </div>
                <div className="col-sm-12 p-0 sub-header-text">
                  {previewData[0]?.id}
                </div>
              </div>
            </div>
            <div className="col-sm-12 col-md-12 col-lg-12 px-0">
              <TabComponent
                heightAdjustMode="Auto"
                cssClass="orientation-tab preview-custom-tab"
              >
                <TabItemsDirective>
                  <TabItemDirective
                    header={previewTab[0]}
                    content={ClientPreview}
                  />
                </TabItemsDirective>
              </TabComponent>
            </div>
          </div>
        ) : tabName && (tabName === "Mobile" || tabName === "Reclaim Scan" || tabName === "Message Management") ? (
          <div className="col-sm-12 col-md-12 col-lg-12 px-0">
            <div className="col-sm-12 col-md-12 col-lg-12 px-3 py-3">
              <div className="col-sm-6 col-md-5 col-lg-4 pl-4 pr-4">
                {previewData[0]?.type !== undefined &&
                previewData[0]?.type !== null ? (
                  <p className="header-logo">
                    {previewData[0]?.type?.substring(0, 2)?.toUpperCase()}
                  </p>
                ) : (
                  ""
                )}
              </div>
              <div className="col-sm-6 col-md-7 col-lg-8 pt-2 ">
                <div className="col-sm-12 p-0 main-header-text">
                  {previewData[0]?.type} Msg
                </div>
                <div className="col-sm-12 p-0 sub-header-text">
                  {previewData[0]?.id}
                </div>
              </div>
            </div>
            <div className="col-sm-12 col-md-12 col-lg-12 px-0">
              <TabComponent
                heightAdjustMode="Auto"
                cssClass="orientation-tab preview-custom-tab"
              >
                <TabItemsDirective>
                  <TabItemDirective
                    header={previewTab[0]}
                    content={PreviewMsgMmt}
                  />
                </TabItemsDirective>
              </TabComponent>
            </div>
          </div>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

export default LLAdminPreviewTab;
