import React, { useEffect, useState } from "react";
import { Prompt } from "react-router-dom";
import ConfirmBox from "./ConfirmBox";
//import ConfirmBox from "./ConfirmBox";

const RouteLeavingGuard = (props:any) => {
 const { 
  navigate,
  when,
  shouldBlockNavigation,
  yes,
  no,
  content,
  onClickYes } = props;
  const [modalVisible, updateModalVisible] = useState(false);
  const [lastLocation, updateLastLocation] = useState<any>();
  const [confirmedNavigation, updateConfirmedNavigation] = useState(false);

  const showModal = (location:any) => {
    updateModalVisible(true);
    updateLastLocation(location);
  };

  const closeModal = (cb:any) => {
    updateModalVisible(false);
    if (cb) {
      cb();
    } 
  };

  const handleBlockedNavigation = (nextLocation:any) => {
    if (!confirmedNavigation && shouldBlockNavigation(nextLocation)) {
      showModal(nextLocation);
      return false;
    }
    return true;
  };
  const handleConfirmNavigationClick = () => {
    closeModal(() => {
      if (lastLocation) {
        updateConfirmedNavigation(true);
      }
    });
  };

  useEffect(() => {
    if (confirmedNavigation) {
      if(lastLocation.mapper) {
      navigate(lastLocation.mapper);
      } else {
        navigate(lastLocation.pathname); 
      }
      updateConfirmedNavigation(false);
    }
  }, [confirmedNavigation]);

  return (
    <>
      <Prompt when={when} message={handleBlockedNavigation} />
      <ConfirmBox
        yes={yes}
        no={no}
        visible={modalVisible}
        onCancel={closeModal}
        onConfirm={handleConfirmNavigationClick}
        className="prompt_guide"
      >
        <p className="main_text">{content}</p>
      </ConfirmBox>
    </>
  );
};

export default RouteLeavingGuard;
