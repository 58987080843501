import { DataStateChangeEventArgs } from "@syncfusion/ej2-react-grids";
import { Page, Predicate, SearchCriteriaRequest, Sort } from "./gridConfig";

export function getEntityColumn(gridColumn: string) {
  console.log(gridColumn);
  if ("vendorInfo" === gridColumn) {
    return "vendorName";
  } else if (
    "activeForGridStore" === gridColumn ||
    "activeForGridVendor" === gridColumn
  ) {
    return "isActive";
  } else if ("clientGroupInfo" === gridColumn) {
    return "clientGroupName";
  } else if ("vendorType" === gridColumn) {
    return "isHold";
  } else if ("addressInfo" === gridColumn) {
    return "street";
  } else {
    return gridColumn;
  }
}

export function getEntityColumnValue(gridColumn: string, value: any) {
  // if ("getActiveForGridItemCatalog" === gridColumn) {
  //     return !"yes".includes(value.toString().toLocaleLowerCase());
  // } else if ("activeForGridStore" === gridColumn || "activeForGridVendor" === gridColumn) {
  //     return "active".includes(value.toString().toLocaleLowerCase());
  // } else if ("vendorType" === gridColumn) {
  //     return "hold".includes(value.toString().toLocaleLowerCase());
  // }
  return value;
}

export function getEntityOperation(
  gridColumn: string,
  operation: string,
  value: any
) {
  console.log(operation);
  // if ("getActiveForGridItemCatalog" === gridColumn) {
  //     if (operation === 'startswith') {
  //         return 'like'
  //     }
  // }
  // if ("activeForGridItemCatalog" === gridColumn || "activeForGridStore" === gridColumn || "activeForGridVendor" === gridColumn || "vendorType" === gridColumn) {
  //     value = value.toLowerCase();
  //     if (value === 'active' || value === 'hold' || value === 'yes') {
  //         return 'IS'
  //     } else {
  //         return 'NOT'
  //     }
  // }

  return operation;
}

export function filterQuery(
  state: DataStateChangeEventArgs
): SearchCriteriaRequest {
  let sort: Sort = {};
  let page: Page = {
    pageNumber: 0,
    pageSize: 50,
  };
  let filters: Predicate[] = [];
  if (state.take) {
    page.pageSize = state.take;
  }
  if (state.skip) {
    page.pageNumber = state.skip / page.pageSize;
  }

  if (state.sorted && state.sorted.length > 0) {
    const sorts = state.sorted[0];
    if (sorts?.name) {
      sort.key = getEntityColumn(sorts.name);
      sort.direction = sorts.direction;
    }
  }
  if (state.where && state.where.length > 0) {
    state.where[0].predicates.forEach((pre) => {
      filters.push({
        key: getEntityColumn(pre.field),
        value: getEntityColumnValue(pre.field, pre.value),
        operation: getEntityOperation(pre.field, pre.operator, pre.value),
      });
    });
  }
  return { filters, sort, page };
}
