import { useEffect, useState } from "react";
import { ButtonComponent } from "@syncfusion/ej2-react-buttons";
import fileIcon from "../../../icons/File.png";
import crossIcon from "../../../icons/Close_Delete.png";
import addIcon from "../../../icons/Add.png";
import toggleIconOff from "../../../icons/ToggleIconOff.png";
import toggleIconOn from "../../../icons/ToggleIconOn.png";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import {
  deleteMobileSettingsBySuperGroup,
  getMobileSettings,
  getSuperGroups,
  saveMobileSettingsBySuperGrpId,
  updateMobileSettings,
} from "../utils";
import axios from "axios";
import { setMobileSettings } from "../settingsReducers";
import notify from "../../../services/toasterService";
// import "../Settings/forms.scss";

const MobileDataSettings = () => {
 

  const dispatch = useDispatch();

  const [selectedSuperGroup, setSelectedSuperGroup] = useState<any>();
 
  let forceReasonCodesArray: {}[] = [];

  let [forceTrashRCInputList, setForceTrashInputList] = useState([
    { reasonCode: "", description: "", id: 0, active: false },
  ]);
  const [bulkItemQty, setBulkItemQty] = useState("");
  const [notFoundItemCost, setNotFoundItemCost] = useState("");

  const [captureReasonCode, setCaptureReasonCode] = useState(false);

  const [superGroupsData, setSuperGroupsData] = useState<any>([]);

  const [errors, setErrors] = useState({
    codeError: "",
    pricingTypeError: "",
    priceError: "",
  });
  let codeError = "";
  let pricingTypeError = "";
  let priceError = "";
  const [price, setPrice] = useState("");

  let [mobileSettingsList, setMobileSettingsList] = useState<any>([]);
  let [limitMaxQty, setLimitMaxQty] = useState("");

  const mobileSettingsDataList = useSelector(
    (state: RootState) => state.settingsReducer?.mobileSettings
  );

  const defaultSelectSG = useSelector(
    (state: RootState) => state.settingsReducer?.selectedDefaultSuperGroup
  );

  useEffect(() => {
  
    // setSelectedSuperGroup(defaultSelectSG);   
    getSuperGroupsList();
   // setSelectedSuperGroup(defaultSelectSG);
  }, []);

  /* handle input change event for reason codes */
  const handleForceTrashInputChange = (e: any, index: number) => {
    const name = e.target.name;
    const value = e.target.value;
    const list = [...forceTrashRCInputList];
    let items: any;
    items = [...forceTrashRCInputList];
    items[index][name] = value;
    setForceTrashInputList(items);
  };

  /* handle reason codes click event of the Remove button */
  const handleForceTrashReviewRemoveClick = (index: number, item: any) => {
    const list = [...forceTrashRCInputList];
    list.splice(index, 1);
    setForceTrashInputList(list);

    if (item.id !== undefined && item.id !== null && item.id != 0) {
      deleteMobileSettings(index, item);
    }
  };

  /* handle reason codes click event of the add button */
  const handleForceTrashReviewRCAddClick = (rc: any, desc: any) => {
    setForceTrashInputList([
      ...forceTrashRCInputList,
      { reasonCode: rc, description: desc, id: 0, active: false },
    ]);
  };

  /* handle not found item chnage event */
  const handleNotFoundItemValue = (event: any) => {
   // setNotFoundItemCost(event.target.value);
   console.log(event.target.value)
    if (event.target.value === "") {
      priceError = "Required ";
      setNotFoundItemCost('');
    } else {
      var regexFax = /^[0-9]*(?:\.\d{1,2})?$/;
    if (!regexFax.test(event.target.value)) {
      priceError = "Invalid ";
    } else {
      if (event.target.value.includes(".") === true) {
        if (event.target.value.split(".")[1].length > 2) {
          setNotFoundItemCost(parseFloat(event.target.value).toFixed(2));
          setPrice(parseFloat(event.target.value).toFixed(2));
        } else {
          setNotFoundItemCost(event.target.value);
          setPrice(event.target.value);
        }
      } else {
        setNotFoundItemCost(event.target.value);
        setPrice(event.target.value);
      }
    }
    if (priceError) {
      setErrors({ codeError, pricingTypeError, priceError });
      return false;
    } else {
      if (event.target.value.includes(".") === true) {
        if (event.target.value.split(".")[1].length > 2) {
          setNotFoundItemCost(parseFloat(event.target.value).toFixed(2));
          setPrice(parseFloat(event.target.value).toFixed(2));
        } else {
          setNotFoundItemCost(event.target.value);
          setPrice(event.target.value);
        }
      } else {
        setNotFoundItemCost(event.target.value);
        setPrice(event.target.value);
      }
      return true;
    }
    }    
  };
  /* handle bulk qty item chnage event */
  const handleBulkQtyValue = (event: any) => {
    setBulkItemQty(event);
  };

  const handleLimitMaxQty = (event: any) => {
    setLimitMaxQty(event.target.value);
  };

  /* handle capture reason code changeevent  */
  const handleToggleChange = (e: any, type: boolean) => {
    setCaptureReasonCode(type);
  };

  /* handle super groups change event */
  const handleSuperGroup = (e: any) => {
    console.log(e)
    setSelectedSuperGroup(e);
    getMobileSettingsList(e);
    setPrice('');
  };

  /*check input format function */
  const formatInput = (e: any) => {
    // Prevent characters that are not numbers ("e", ".", "+" & "-") ✨
    let checkIfNum;
    if (e.key !== undefined) {
      // Check if it's a "e", ".", "+" or "-"
      checkIfNum =
        e.key === "e" || e.key === "." || e.key === "+" || e.key === "-";
    } else if (e.keyCode !== undefined) {
      // Check if it's a "e" (69), "." (190), "+" (187) or "-" (189)
      checkIfNum =
        e.keyCode === 69 ||
        e.keyCode === 190 ||
        e.keyCode === 187 ||
        e.keyCode === 189;
    }
    return checkIfNum && e.preventDefault();
  };

  /* get Super Groups Api Call */
  const getSuperGroupsList = async () => {
    const endPointUrl = getSuperGroups();
    await axios
      .get(endPointUrl)
      .then((resp) => {
        setSuperGroupsData(resp.data);
      })
      .catch((err) => {
        console.log(err);
        notify(
          "Error! Unable to fetch data. Please try after sometime.",
          "error"
        );
      });
  };

  /*get saved  Mobile Settings APi Call */
  const getMobileSettingsList = async (id: any) => {
    mobileSettingsList = [];
    const endPointUrl = getMobileSettings(id);
    await axios
      .get(endPointUrl)
      .then((resp) => {
        mobileSettingsList.push(resp.data);
        dispatch(setMobileSettings(mobileSettingsList));

        /* mobile settings update oject */
        mobileSettingsList[0].bulkQty !== undefined && mobileSettingsList[0].bulkQty !== null  ? setBulkItemQty(mobileSettingsList[0].bulkQty) : setBulkItemQty('');
        mobileSettingsList[0].notFoundItemCost !== undefined && mobileSettingsList[0].bulkQty !== null ? setNotFoundItemCost(mobileSettingsList[0].notFoundItemCost) : setNotFoundItemCost('');
        mobileSettingsList[0].itemMaxQty !== undefined && mobileSettingsList[0].bulkQty !== null ? setLimitMaxQty(mobileSettingsList[0].itemMaxQty) : setLimitMaxQty('');
        setCaptureReasonCode(mobileSettingsList[0].captureReasonCode);

        /* reason code array update */
        if (
          mobileSettingsList[0] !== "" &&
          mobileSettingsList[0] !== undefined
        ) {
          let fcList: any[] = [];
          mobileSettingsList[0].storeReasonCodesList.map(
            (item: any, i: any) => {
              let items: any;
              const obj = {
                reasonCode: item.reasonCode,
                description: item.description,
                id: item.id,
                active: item.active,
              };
              fcList.push(obj);
            }
          );
          setForceTrashInputList(fcList);
        } else {
          forceTrashRCInputList = [];
          setBulkItemQty("");
          setNotFoundItemCost("");
          setLimitMaxQty("");
          setCaptureReasonCode(false);
          handleForceTrashReviewRCAddClick("", "");
        }
      })
      .catch((err) => {
        console.log(err);
        notify(
          "Error! Unable to fetch data. Please try after sometime.",
          "error"
        );
      });
  };

  /* Save and update Mobile Settings APi Call */
  const saveMobileSettings = async (data: any) => {
    console.log(mobileSettingsDataList)
    forceReasonCodesArray = [];
    forceTrashRCInputList.map((item, i) => {
      if (mobileSettingsDataList.length > 0) {
        if (item.id !== undefined && item.id != 0) {
          const obj = {
            active: item.active,
            id: item.id,
            reasonCode: item.reasonCode,
            description: item.description,
          };
          forceReasonCodesArray.push(obj);
        } else {
          const obj = {
            reasonCode: item.reasonCode,
            description: item.description,
          };
          forceReasonCodesArray.push(obj);
        }
      } else {
        const obj = {
          reasonCode: item.reasonCode,
          description: item.description,
        };
        forceReasonCodesArray.push(obj);
      }
    });
    
    const reqBody = {
      active: true,
      bulkQty: bulkItemQty !== undefined && bulkItemQty !== null && bulkItemQty !== '' ? parseInt(bulkItemQty) : 0,
      notFoundItemCost: notFoundItemCost !== '' && notFoundItemCost !== null && notFoundItemCost !== undefined ? parseFloat(notFoundItemCost) : 0,
      itemMaxQty: limitMaxQty !== '' && limitMaxQty !== undefined && limitMaxQty !== null ? parseInt(limitMaxQty) : 0,

      captureReasonCode: captureReasonCode,
      storeReasonCodesList: forceReasonCodesArray,
    };
    const mobileSettingsListId =
      mobileSettingsDataList.length > 0 ? mobileSettingsDataList[0].id : 0;

    let body = reqBody;

  
      if (mobileSettingsDataList?.length > 0 && mobileSettingsDataList[0] !== '') {
          const endPointURL = updateMobileSettings(
          selectedSuperGroup,
          mobileSettingsListId
        );
        await axios
          .put(endPointURL, body)
          .then((res) => {
            notify(" Updated Successfully", "success");
          })
          .catch((err) => {
            console.log(err);
            notify(
              "Error! Unable to fetch data. Please try after sometime.",
              "error"
            );
          });
      } else {
       
        const endPointURL = saveMobileSettingsBySuperGrpId(selectedSuperGroup);
        await axios
          .post(endPointURL, body)
          .then((res) => {
            notify(" Saved Successfully", "success");
          })
          .catch((err) => {
            console.log(err);
            notify(
              "Error! Unable to save data. Please try after sometime.",
              "error"
            );
          });
      }
    
    getMobileSettingsList(selectedSuperGroup);
  };

  /* Delete  Mobile Settings APi Call */
  const deleteMobileSettings = async (index: number, item: any) => {

    const endPointUrl = deleteMobileSettingsBySuperGroup(
      mobileSettingsDataList[0]?.id,
      item?.id
    );
    await axios
      .delete(endPointUrl)
      .then((res) => {
        notify(" Item Deleted Successfully", "success");
      })
      .catch((err) => {
        notify(err, "error");
      });
    // getMobileSettingsList(selectedSuperGroup)
  };

  /* **** Html Rendering **** */
  return (
    <div className="col-sm-12 col-md-12 col-lg-12">
      <div className="row bottomBorderRow mb-0 ml-1">
        <p className="col-sm-7 col-md-7 col-lg-7 settings-tab-title mt-5 mr-5 mb-1 fw-bold pl-0">
          Mobile Settings
        </p>
        <div className="col-sm-2 col-md-2 col-lg-2  mt-4 ml-5 pr-0">
          <span className=" settingslblClass ">Super Group</span>
        </div>
        <div className="col-sm-2 col-md-2 col-lg-2 mt-4 ml-0 mb-0">
          <select
            className="form-control"
            id="companyTypeLinkDropDown"
            onChange={(e) => handleSuperGroup(e.target.value)}
          >
            <option value="">Select Super Group</option>
            {superGroupsData?.map((sg: any) => (
              <option value={sg.id}>{sg.superGroup}</option>
            ))}
          </select>
        </div>
        <ButtonComponent
          className="e-outline opt-btn-dark float-left mt-4"
          style={
            selectedSuperGroup !== undefined && selectedSuperGroup !== ""
              ? { opacity: "1", pointerEvents: "all", cursor: "pointer" }
              : { opacity: "0.5", pointerEvents: "none" }
          }
          onClick={(e) => saveMobileSettings(e)}
        >
          <img src={fileIcon} alt="File " />
        </ButtonComponent>
      </div>
      <div className="container-fluid" style={{paddingLeft: '0'}}>
      <div className="row mt-3 mb-2" style={{ marginLeft: "2rem" }}>
        <div className="col-sm-3 col-md-3 col-lg-3 p-0">
          <div className="row m-0">
            <div className="col-sm-6 col-md-6 col-lg-6 p-0">
              <span className=" settingslblClass " style={{float: 'left'}}>Bulk Qty Value</span>
            </div>
            </div>
            <div className="row">
            <div className="col-sm-6 col-md-6 col-lg-6" style={{padding: '0', marginTop: '5px'}}>
              <input
                className=" form-control"
                type="number"
                onKeyDown={formatInput}
                value={bulkItemQty}
                placeholder="Enter bulk qty"
                onChange={(e) => handleBulkQtyValue(e.target.value)}
              />
            </div>
          </div>
        </div>
        <div className="col-sm-3 col-md-3 col-lg-3 p-0">
          <div className="row m-0">
            <div className="col-sm-6 col-md-6 col-lg-6 p-0 ">
              <span className=" settingslblClass " style={{float: 'left'}}>Not Found Item Cost</span>
            </div>
            </div>
            <div className="row">
            <div className="col-sm-6 col-md-6 col-lg-6" style={{padding: '0', marginTop: '5px'}}>
              <input
                className=" form-control"
                type="number"
                style={{ width: "200px" }}
                placeholder="Enter not found item cost"
                //onKeyDown={formatInput}
                value={notFoundItemCost}
                onChange={(e) => handleNotFoundItemValue(e)}
                onBlur={(e) => handleNotFoundItemValue(e)}
                onKeyUp={(e) => handleNotFoundItemValue(e)}
              />
            </div>
          </div>
        </div>
        <div className="col-sm-3 col-md-3 col-lg-3 p-0">
          <div className="row m-0">
            <div className="col-sm-6 col-md-6 col-lg-6 p-0 ">
              <span className=" settingslblClass " style={{float: 'left'}}>Limit Max Qty</span>
            </div>
            </div>
            <div className="row">
            <div className="col-sm-6 col-md-6 col-lg-6" style={{padding: '0', marginTop: '5px'}}>
              <input
                className=" form-control"
                type="number"
                placeholder="Enter limit max qty"
                onKeyDown={formatInput}
                value={limitMaxQty}
                onChange={(e) => handleLimitMaxQty(e)}
              />
            </div>
          </div>
        </div>
      </div>
      </div>

      <div className="row mt-1">
        <div className="col-sm-6 col-md-6 col-lg-6 settings-tab-sub-title-div mt-2">
          <p className="settings-tab-sub-title fw-bold">
            Force to Trash - Reason Codes
          </p>
          {forceTrashRCInputList.map((x, i) => {
            return (
              <div className="row m-0">
                {i === 0 ? (
                  <p className="col-sm-12 col-md-12 col-lg-12 mb-0 pt-3 pl-4 pr-0 pb-0 font-16 text-align-left">
                    Capture Reason Codes
                    {captureReasonCode ? (
                      <img
                        src={toggleIconOn}
                        alt="close "
                        className="p-3"
                        onClick={() => handleToggleChange(i, false)}
                      />
                    ) : (
                      <img
                        src={toggleIconOff}
                        alt="close "
                        className="p-3"
                        onClick={() => handleToggleChange(i, true)}
                      />
                    )}
                  </p>
                ) : (
                  <p></p>
                )}
                <div className="col-sm-4 col-md-4 col-lg-4 mt-2 mb-1">
                  {i === 0 ? (
                    <span className="form-input-settings-label fw-bold">
                      Reason Code
                    </span>
                  ) : (
                    <span></span>
                  )}
                  <input
                    className="form-control"
                    name="reasonCode"
                    placeholder="Enter Reason Code"
                    value={x.reasonCode}
                    onChange={(e) => handleForceTrashInputChange(e, i)}
                  />
                </div>
                <div className="col-sm-6 col-md-6 col-lg-6 mt-2 mb-1">
                  {i === 0 ? (
                    <span className="form-input-settings-label fw-bold">
                      Description
                    </span>
                  ) : (
                    <span></span>
                  )}
                  <input
                    className="form-control"
                    name="description"
                    placeholder="Enter Description"
                    value={x.description}
                    onChange={(e) => handleForceTrashInputChange(e, i)}
                  />
                </div>
                {i === 0 ? (
                  <img
                    src={crossIcon}
                    alt="close "
                    className="closeIconMain"
                    onClick={() => handleForceTrashReviewRemoveClick(i, x)}
                  />
                ) : (
                  <img
                    src={crossIcon}
                    alt="close "
                    className="closeIconSub"
                    onClick={() => handleForceTrashReviewRemoveClick(i, x)}
                  />
                )}{" "}
              </div>
            );
          })}
          <div className="col-sm-4 col-md-4 col-lg-4 pt-2 pl-0">
            <span className="float-left">
              {" "}
              <img
                src={addIcon}
                alt="plus "
                className=""
                onClick={() => handleForceTrashReviewRCAddClick("", "")}
              />
              <span className="settingslblClass mt-0 ml-2">
                Add Reason Code
              </span>{" "}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
  /* **** Html Rendering **** */
};

export default MobileDataSettings;
