import userIcon from "../../../assets/userIcon.png";
import editIcon from "../../../assets/EditRuleExcep.png";
import { useDispatch, useSelector } from "react-redux";
import { ButtonComponent } from "@syncfusion/ej2-react-buttons";
import { useEffect, useState } from "react";
import AddFileUploadScreen from "../addScreen/fileUpload";
import {
  CheckBoxSelection,
  Inject,
  MultiSelectComponent,
} from "@syncfusion/ej2-react-dropdowns";
import "./../checkboxes.scss";
import { RootState } from "../../../redux/store";
import axios from "axios";
import notify from "../../../services/toasterService";
import { addAccessReqEndPoint, getAccessMgmtEndPoint } from "../utils";
import { setRolesData, setRolesList } from "../accessmgmtReducer";

const AddRole = (props: any) => {
  const { setAddScreen, setTabName } = props;

  let selectedImage: any;
  const [addScreen, setFileUploadAddScreen] = useState(false);
  const [addScreenTabName, setAddScreenTabName] = useState(null);

  const [myName, setMyName] = useState("");
  const [myDescription, setMyDescription] = useState("");
  const [myLinkModules, setMyLinkModules] = useState("");

  const [roleName, setRoleName] = useState("");
  const [roleDescription, setRoleDescription] = useState("");
  let [modules, setModules] = useState<any>([]);

  let [rolesDisabeled, setRoleDisabeled] = useState(true);

  useEffect(() => {
    setRoleDisabeled(true);
    setModules([]);
  }, []);

  const handlePopupActionLocation = (selectedTabName: any) => {
    setFileUploadAddScreen(true);
    setAddScreenTabName(selectedTabName);
  };

  /* Get data from Grid Reducer */
  const modulesDataList = useSelector(
    (state: RootState) => state.accessMgmtReducer.modulesList
  );
  const dispatch = useDispatch();

  const [errors, setErrors] = useState({
    roleNametextError: "",
    roleDescriptionError: "",
    modulesError: "",
  });

  const handleEditImage = () => {};
  const handleRoleName = (e: any) => {
    setRoleName(e.target.value);
    validate();
  };
  const handleDescription = (e: any) => {
    setRoleDescription(e.target.value);
    validate();
  };
  const handleModules = (e: any) => {
    console.log(e.value);
    const addModules = e.value;
    const CTInfo = [...modules];
    if (!CTInfo.some((mod) => mod === addModules)) {
      CTInfo.push(addModules);
      setModules(CTInfo);
    }
    // setModules(e.value)
    validate();
  };

  const handlePopUpClose = () => {
    setAddScreen(false);
  };
  const handleAddRole = async () => {
    // const token = sessionStorage.getItem('token');
    // const token = UserService.getToken()
    const roleModuleMapping: any[] = [];
    const roleModuleMenuMapping: any[] = [];
    const modulesMap: { moduleId: any }[] = [];
    modules[modules.length - 1].map((mod: any) => {
      modulesDataList.map((item: any) => {
        if (item.moduleMenuId === mod) {
          const obj1 = {
            moduleId: item.moduleId,
            moduleMenuId: item.moduleMenuId,
          };
          const obj2 = { moduleId: item.moduleId };
          roleModuleMapping.push(obj2);
          roleModuleMenuMapping.push(obj1);
        }
      });
    });
    const uniqueroleModuleMapping = roleModuleMapping.filter(
      (ele, ind) =>
        ind ===
        roleModuleMapping.findIndex((elem) => elem.moduleId === ele.moduleId)
    );
    const reqBody = {
      roleName: roleName,
      description: roleDescription,
      roleModuleMapping: uniqueroleModuleMapping,
      roleModuleMenuMapping: roleModuleMenuMapping,
    };
    const endPointUrl = addAccessReqEndPoint("Role");
    // const result = await axios.post(endPointUrl, reqBody,{ headers: { 'Authorization': `Bearer ${token}` }})
    const result = await axios.post(endPointUrl, reqBody);
    if (result && result.data) {
      setAddScreen(false);
      notify("Role added successfully", "success");
      const getEndPointUrl = getAccessMgmtEndPoint("Roles");
      // await axios.post(getEndPointUrl, {}, { headers: { 'Authorization': `Bearer ${token}` }}).then(
      await axios.post(getEndPointUrl, {}).then(
        (res) => {
          dispatch(setRolesData(res.data));
          dispatch(setRolesList(res.data));
        },
        (err) => {
          notify("Failed to fetch data", "error");
        }
      );
    } else {
      notify("Failed to add role", "error");
    }
  };

  const countriesList = [{ Name: "United States", Code: "USA" }];

  let checkFields: Object = { text: "modulename", value: "moduleMenuId" };

  const validate = (field?: any, value?: any) => {
    let roleNametextError = "";
    let roleDescriptionError = "";
    let modulesError = "";
    setErrors({
      roleNametextError: "",
      roleDescriptionError: "",
      modulesError: "",
    });

    if (roleName === "") {
      roleNametextError = "Required Field";
      setMyName("1px solid red");
    } else {
      setMyName("");
    }
    if (roleDescription === "") {
      roleDescriptionError = "Required Field";
      setMyDescription("1px solid red");
    } else {
      setMyDescription("");
    }
    if (modules.length === 0) {
      modulesError = "Modules are required";
      setMyLinkModules("1px solid red");
    } else {
      setMyLinkModules("");
    }

    if (roleName === "" || roleDescription === "" || modules.length === 0) {
      setErrors({ roleNametextError, roleDescriptionError, modulesError });
      setRoleDisabeled(true);
      return false;
    } else {
      setRoleDisabeled(false);
    }
    return true;
  };

  let uploadedImageFile = useSelector(
    (state: RootState) => state.accessMgmtReducer.previewImage
  );

  const handlePopupActionAddCompany = (type: any) => {
    setFileUploadAddScreen(true);
    setAddScreenTabName(type);
  };
  
  return (
    <div>
      {addScreen && (
        <AddFileUploadScreen
          addScreen={addScreen}
          setFileUploadAddScreen={setFileUploadAddScreen}
          tabName={addScreenTabName}
        />
      )}
      {rolesDisabeled}
      <div className="row m-0">
        <div className="col-sm-12 textAlign-center RoleImageHover">
          {/* <input accept="image/*" type="file" onChange={imageChange} /> */}
          {/* <div className="">
            <img
              src={userIcon}
              className="userAddIcon"
              onClick={() => handlePopupActionLocation("fileUpload")}
            />
            <img src={editIcon} alt="edit " width="24" height="24" className=" m-3 pointer" onClick={() => handleEditImage()}/>
          </div>

          {selectedImage && (
            <div className="imagePreviewDiv">
              <img
                src={URL.createObjectURL(selectedImage)}
                className="imagePreview"
                height="100"
                width="100"
              />
              <img
                src={editIcon}
                alt="edit "
                width="24"
                height="24"
                className="float-right m-3 pointer"
                onClick={() => handleEditImage()}
              />
            </div>
          )} */}
          {uploadedImageFile && uploadedImageFile.length > 0 ? (
            <div className="ImageContainer">
                <img
                  src={URL.createObjectURL(uploadedImageFile[0].file)}
                  className="userAddIcon"
                  height="125"
                  width="125"
                /></div>
              ) : (
                <img src={userIcon} className="userAddIcon" />
              )}
              <div className="RoleHoverEditIcon">
              <img
                src={editIcon}
                alt="edit "
                width="24"
                height="24"
                className="m-3 pointer"
                onClick={() => handlePopupActionAddCompany("fileUpload")}
                style={{position: 'sticky'}}
              /></div>
        </div>
      </div>
      <div className="col-sm-12 row m-0 mt-2">
        <div className="col-sm-6">
          <div className="col-sm-12 mt-3">
            <label className="label-title">Name</label>
            {roleName === "" ? (
              <span className="text-danger" style={{ float: "right" }}>
                {errors.roleNametextError}
              </span>
            ) : (
              ""
            )}
            <input
              className=" formcontrol"
              type="text"
              onChange={(e) => handleRoleName(e)}
              onBlur={(e) => handleRoleName(e)}
              onKeyUp={(e) => handleRoleName(e)}
              style={{ border: myName }}
            />
          </div>
          <div className="col-sm-12 mt-3">
            <label className="label-title">Description</label>
            {roleDescription === "" ? (
              <span className="text-danger" style={{ float: "right" }}>
                {errors.roleDescriptionError}
              </span>
            ) : (
              ""
            )}
            <textarea
              rows={5}
              className="formDesc"
              onChange={(e) => handleDescription(e)}
              onKeyUp={(e) => handleDescription(e)}
              onBlur={(e) => handleDescription(e)}
              style={{ border: myDescription }}
            />
          </div>
        </div>
        <div className="col-sm-6">
          <div className="col-sm-12 mt-3">
            {/* <label className='label-title'> Link Modules</label> */}
            {/* <select className='form-control' onChange={(e: any) => handleModules(e)} onBlur={(e) => handleModules(e)} onKeyUp={(e) => handleModules(e)} >
                            <option value=''>Select</option>
                             <option value='MULTI-SELECT'>MASTER DATA _PARTENERS</option>
                            <option value='SINGLE-SELECT'>MASTER DATA - FINANCE</option>
                            <option value='MULTI-SELECT'>MASTER DATA - INVENTORY</option>
                            <option value='SINGLE-SELECT'>INVOICES - AR INVOICES</option>
                         </select> */}
            <div id="ll-autocomplete" className="control-pane ">
              <div className="control-section col-sm-12 p-0">
                <div id="multigroup" className="control-styles">
                  <label className="label-title"> Link Modules</label>
                  {modules === [] ? (
                    <span className="text-danger">{errors.modulesError}</span>
                  ) : (
                    ""
                  )}
                  <MultiSelectComponent
                    className="formcontrol"
                    id="checkbox"
                    dataSource={modulesDataList}
                    fields={checkFields}
                    placeholder="Select Modules"
                    mode="CheckBox"
                    showSelectAll={true}
                    showDropDownIcon={true}
                    filterBarPlaceholder="Search Modules"
                    popupHeight="350px"
                    change={handleModules.bind(this)}
                    style={{ border: myLinkModules }}
                  >
                    <Inject services={[CheckBoxSelection]} />
                  </MultiSelectComponent>
                </div>
              </div>
            </div>
            {/* <div className='form-control'>
                         <MultiSelectComponent className='form-control' id="checkbox"  dataSource={countriesList}
                            fields={checkFields} placeholder="Select countries" mode="CheckBox" showSelectAll={true}
                             showDropDownIcon={true} filterBarPlaceholder="Search countries" popupHeight="350px">
                            <Inject services={[CheckBoxSelection]} />
                            </MultiSelectComponent>
                         </div> */}
          </div>
        </div>
      </div>

      <div className="col-sm-11 px-0 my-5 ml-4">
        <ButtonComponent
          cssClass="float-right px-5 py-3"
          style={
            rolesDisabeled
              ? {
                  opacity: "0.5",
                  pointerEvents: "none",
                  backgroundColor: "#e8e8e8",
                  color: "#969696",
                  borderRadius: "4px",
                }
              : {
                  opacity: "1",
                  borderRadius: "4px",
                  pointerEvents: "all",
                  backgroundColor: "#636799",
                  color: "#ffffff",
                  cursor: "pointer",
                }
          }
          onClick={() => handleAddRole()}
        >
          Add Role
        </ButtonComponent>
        <ButtonComponent
          cssClass="eg-btn-primary-link e-flat mt-3"
          onClick={() => handlePopUpClose()}
        >
          Cancel
        </ButtonComponent>
      </div>
    </div>
  );
};

export default AddRole;
