import { FC } from "react";
import { createPortal } from "react-dom";
import "./LLModal.scss";
import closeDialog from "../../../icons/common/close-dialog.png";

interface IModalProps {
  children?: any;
  width?: string | number;
  padding?: number;
  show: boolean;
  handleClose: () => void;
  className?: string;
}

const LLModal: FC<IModalProps> = ({
  children,
  handleClose,
  show,
  width,
  padding,
  className,
}) => {
  return createPortal(
    <div
      className="modal-container"
      style={{ display: show ? "block" : "none" }}
    >
      <div
        className={`modal-content-wrapper ${className}`}
        style={{ width, padding: padding ? padding : 40 }}
      >
        <div className="close-button" onClick={handleClose}>
          <span>
            <img src={closeDialog} alt="Close" />
          </span>
        </div>
        <div className="model-content">{children}</div>
      </div>
    </div>,
    document.body
  );
};

export default LLModal;
