import axios from 'axios';
import moment from 'moment';
import { environment } from '../../environment';
import { EndPoints, ScanType } from './constants';

const baseURL = environment.react_app_url;
const clientContext = environment.react_app_client_context;

const pageNo = environment.react_app_page; //process.env.REACT_APP_PAGE;
const pageSize = environment.react_app_pagesize; //process.env.REACT_APP_PAGESIZE;
//const clientContext = process.env.REACT_APP_CLIENT_CONTEXT;
export const generateGridData = (scanType: ScanType, data: any) => {
    switch (scanType) {
        case ScanType.HISTORY: {
            return data.map((row: any) => ({
                id: row.id,
                //store: row.name,
                name: row.name,
                address: row.address,
                active: row.active,
                email: row.email,
                alternatePhone: row.alternatePhone,
                phone: row.phone,
                notes: row.notes,
                startDate: row.startDate,
                //startDate:new Date(row.startDate),
                archieved: row.archieved,
                clientGroup: row.clientGroup,
                deleted: row.deleted,
                fax: row.fax,
                language: row.language,
                reasonCode: row.reasonCode,
                status: row.status,
                storeNumber: row.storeNumber,
                storeType: row.storeType,
                superGroup: row.superGroup,
                tenantId: row.tenantId,
                timezone: row.timezone,
            }));
        }
        default:
            return [];
    }
};

export const getUPCDetails = () => {
    const items = {
        alertTitle: 'food',
        upccode: '60265218102',
        qty: '1',
        description: 'Healthy Bar DRK',
        vendor: '(004163)KIND LLC',
        packSize: '8',
        debitReason: 'N/A',
        unitFactor: '1',
        ruleException: 'N/A',
        customerGroup: '003warehouse',
        store: '00015 VT',
        inboundBox: '1001',
        currentBoxQty: '8',
        outboundBox: 'BOX ID',
        createdDate: '10-14-2021',
        reclaimCenter: 'Hatfield PRC',
        scanGroup: '03',
        scanStation: '10',
        scanItems: [
            {
                upc: '00099',
                desc: 'ddfff',
                qty: '8',
                storenumber: '890',
                storelabel: 'fgh',
                disposition: 'food',
                cost: '8900',
                scantime: '7:30am',
            },
        ],
        scanneditems: 20,
    };
    return items;
};

export const getTabData = (selectedTab: ScanType, id: any) => {
    // let getId = id;
    switch (selectedTab) {
        case ScanType.HISTORY: {
            return ``;
        }
        default:
            return '';
    }
};

export const unlabeledEndPoint = (endPoint: any, param?: any) => {
    switch (endPoint) {
        case EndPoints.THIRTEENDIGIT:
            return (
                baseURL + `${clientContext}/v1/scan/item/13DigitUpc/` + param
            );
        case EndPoints.UNLABELED:
            return baseURL + `${clientContext}/v1/scan/item/unlabeled`;
        case EndPoints.NOTFOUND:
            return baseURL + `${clientContext}/v1/scan/item/notfound/` + param;
    }
};

export const handleScanItems = async (
    endpoint: string,
    requestPayload: any
) => {
    const result = await axios.patch(endpoint, requestPayload);
    return result;
};

export const getEndpoint = (endPoint: any, param?: any) => {
    switch (endPoint) {
        case EndPoints.SCANITEM:
            return baseURL + `${clientContext}/v1/scan/item/` + param;
        case EndPoints.OPENINBOUND:
            return baseURL + `${clientContext}/v1/scan/inbound`;

        case EndPoints.CLOSEOUTBOUND:
            return baseURL + `${clientContext}/v1/scan/outbound/close`;
        case EndPoints.OUTBOUNDLIST:
            return baseURL + `${clientContext}/v1/scan/outbound/list`;
        case EndPoints.PALLETLIST:
            return baseURL + `${clientContext}/v1/scan/pallet/list`;
        case EndPoints.CLOSEPALLET:
            return baseURL + `${clientContext}/v1/scan/pallet/close`;
        case EndPoints.STORELIST:
            return (
                baseURL +
                `${clientContext}/v1/masterdata/store/list/pagination?page=${pageNo}&size=${pageSize}&storename=${param}`
            );
        case EndPoints.RECLAIMCENTERSLIST:
            return (
                baseURL + `${clientContext}/v1/masterdata/reclaimcenters/list`
            );
        case EndPoints.REASONCODES:
            return (
                baseURL +
                `${clientContext}/v1/masterdata/scansetting/reasoncode/items`
            );
        case EndPoints.OUTBOUNDBINSLIST:
            return baseURL + `${clientContext}/v1/scan/bin/list`;
        case EndPoints.CLOSEOUTBOUNDBINS:
            return baseURL + `${clientContext}/v1/scan/bin/close`;
        case EndPoints.UPDATESCANITEM:
            return baseURL + `${clientContext}/v1/scan/item/` + param;
        case EndPoints.SCANSETTINGS:
            return (
                baseURL +
                `${clientContext}/v1/masterdata/scansetting/items/` +
                param
            );
        case EndPoints.DELETEITEM:
            return baseURL + `${clientContext}/v1/scan/item/` + param;
        case EndPoints.ACCESSSUPERVISOR:
            return (
                baseURL + `${clientContext}/v1/masterdata/user/supervisorCheck`
            );
        case EndPoints.OUTBOUNDAUDIT:
            return baseURL + `${clientContext}/v1/scan/outbound/item/` + param;
        case EndPoints.SUBDISPOSITIONS:
            return (
                baseURL +
                `${clientContext}/v1/masterdata/reclaimcenters/items/` +
                param +
                `/subsortation`
            );
        case EndPoints.USERSACNQTY:
            return baseURL + `${clientContext}/v1/scan/item/count`;
    }
};

export const getScanItemsByUpc = async (e: any) => {
    const scanItems = await axios.get(e);
    //console.log(scanItems)
    return scanItems;
};
export const openInboundBox = async (endpoint: string, requestPayload: any) => {
    const result = await axios.post(endpoint, requestPayload);
    return result;
};
export const closeOutBoundBox = async (
    endpoint: string,
    requestPayload: any
) => {
    console.log(requestPayload);
    const result = await axios.post(endpoint, requestPayload);
    console.log(result);
    return result;
};
export const getOutBoundList = async (
    endpoint: string,
    requestPayload: any
) => {
    const result = await axios.post(endpoint, requestPayload);
    console.log(result);
    return result;
};

export const authenticateSupervisor = async (
    endpoint: string,
    requestPayload: any
) => {
    const result = await axios.post(endpoint, requestPayload);
    console.log(result);
    return result;
};

export const getPalletList = async (endpoint: string, requestPayload: any) => {
    const result = await axios.post(endpoint, requestPayload);
    console.log(result);
    return result;
};
export const setLocalStorageItems = (key: any, value: any) => {
    localStorage.setItem(key, JSON.stringify(value));
};

export const getLocalStorageItems = (key: any) => {
    const scanSettings = localStorage.getItem(key);
    return JSON.parse(scanSettings || '{}');
};

export const getListofValues = async (endpoint: string) => {
    const result = await axios.get(endpoint);
    console.log(result);
    return result;
};

export const deleteItem = async (endpoint: string) => {
    const result = await axios.delete(endpoint);
    console.log(result);
    return result;
};

export const getScanEndPoint = (tabName: any) => {
    switch (tabName) {
        case 'History': {
            return baseURL + `${clientContext}/v1/scan/item/history/list`;
        }
        case 'deleteHistory': {
            return baseURL + `${clientContext}/v1/scan/item/`;
        }
        default:
            return '';
    }
};

const isEmpty = (val: any) => {
    if (val !== undefined && val !== null && val !== '') {
        return val;
    } else {
        return '';
    }
};

export const generateHisoryGrid = (tabName: string, data: any) => {
    if (data !== undefined) {
        switch (tabName) {
            case 'getHistory': {
                const fullData = data.map((row: any) => ({
                    id: isEmpty(row.id),
                    scanMode: isEmpty(row.scanMode),
                    reclaimCenter: isEmpty(row.reclaimCenter),
                    scanGroup: isEmpty(row.scanGroup),
                    scanStation: isEmpty(row.scanStation),
                    inboundBoxScanID: isEmpty(row.inboundBoxScanId),
                    scannedOn: moment(row.scannedOn).format(
                        'MMMM Do YYYY, h:mm:ss a'
                    ),
                    storeID: isEmpty(row.storeId),
                    storeLabel: isEmpty(row.storeLabel),
                    uPC: isEmpty(row.upc),
                    description: isEmpty(row.description),
                    vendorID: isEmpty(row.vendorId),
                    quantity: isEmpty(row.quantity),
                    unitFactor: isEmpty(row.unitFactor),
                    packSize: isEmpty(row.packSize),
                    unitCost: isEmpty(row.unitCost),
                    disposition: isEmpty(row.disposition),
                    warehouseLocation: isEmpty(row.warehouseLocation),
                    hazFlag: row.hazFlag ? 'Yes' : 'No',
                    isActive: isEmpty(row.isActive),
                    isDeleted: row.isDeleted ? 'Yes' : 'No',
                    creditFlag: row.creditFlag ? 'Yes' : 'No',
                    isAudit:
                        row.scanMode === 'audit_inbound' ||
                        row.scanMode === 'audit_outbound'
                            ? 'Yes'
                            : 'No',
                    hazDisposition: isEmpty(row.hazDisposition),
                    fttReasonCode: isEmpty(row.reasonCode),
                    debitReason: isEmpty(row.debitReason),
                    inboundBoxScanId: isEmpty(row.inboundBoxScanId),
                    outboundId: isEmpty(
                        row?.outboundBox?.outboundBoxId
                            ? row?.outboundBox?.outboundBoxId
                            : row?.scanBin?.binId
                    ),
                    isBoxClosed:
                        row?.outboundBox?.isClosed || row?.scanBin?.isClosed
                            ? 'Yes'
                            : 'No',
                    originalDisposition: row?.originalDisposition,
                }));
                return fullData;
            }
            default:
                return [];
        }
    } else {
        return [];
    }
};

export const generateUpcHisoryGrid = (tabName: string, data: any) => {
    if (data !== undefined) {
        switch (tabName) {
            case 'getHistory': {
                return data.map((row: any) => ({
                    iD: row.id,
                    scanMode: row.scanMode,
                    reclaimCenter: row.reclaimCenter,
                    scanGroup: row.scanGroup,
                    scanStation: row.scanStation,
                    inboundBoxScanID: row.inboundBoxScanId,
                    scannedOn: row.scannedOn,
                    storeID: row.storeId,
                    storeLabel: row.storeLabel,
                    uPC: row.upc,
                    description: row.description,
                    vendorID: row.vendorId,
                    quantity: row.quantity,
                    unitFactor: row.unitFactor,
                    packSize: row.packSize,
                    unitCost: row.unitCost,
                    disposition: row.disposition,
                    hazFlag: row.hazFlag,
                    isActive: row.isActive,
                    isDeleted: row.isDeleted,
                }));
            }
            default:
                return [];
        }
    } else {
        return [];
    }
};

export const ScanHistoryHeaders = [
    {
        field: 'scannedOn',
        header: 'Scan Time',
        width: '230',
        textAlign: 'Right',
    },
    {
        field: 'storeID',
        header: 'Store Number',
        width: '180',
        textAlign: 'Right',
    },
    {
        field: 'storeLabel',
        header: 'Store Label',
        width: '160',
        textAlign: 'Left',
    },
    {
        field: 'inboundBoxScanId',
        header: 'Inbound box ID',
        width: '200',
        textAlign: 'Right',
    },
    { field: 'uPC', header: 'UPC', width: '150', textAlign: 'Left' },
    {
        field: 'description',
        header: 'Description',
        width: '180',
        textAlign: 'Left',
    },
    { field: 'quantity', header: 'Qty', width: '80', textAlign: 'Right' },
    {
        field: 'warehouseLocation',
        header: 'Whse Location',
        width: '190',
        textAlign: 'Left',
    },
    { field: 'hazFlag', header: 'Hazardous', width: '140' },
    { field: 'hazDisposition', header: 'Sub Sortation', width: '160' },

    {
        field: 'outboundId',
        header: 'Outbound box ID',
        width: '180',
        textAlign: 'Left',
    },
    { field: 'disposition', header: 'Disposition', width: '160' },
    {
        field: 'originalDisposition',
        header: 'Original Disposition',
        width: '220',
    },
    {
        field: 'isBoxClosed',
        header: 'Box Closed',
        width: '140',
        textAlign: 'Left',
    },
    { field: 'unitCost', header: 'Cost', width: '160' },
    {
        field: 'unitFactor',
        header: 'Unit Factor',
        width: '180',
        textAlign: 'Right',
    },
    {
        field: 'packSize',
        header: 'Pack/Size',
        width: '150',
        textAlign: 'Right',
    },
    { field: 'creditFlag', header: 'Credit Flag', width: '160' },
    { field: 'isAudit', header: 'Audit Flag', width: '140' },
    { field: 'vendorID', header: 'Vendor ID', width: '125', textAlign: 'Left' },
    {
        field: 'fttReasonCode',
        header: 'Force to trash reason code',
        width: '300',
    },
    { field: 'debitReason', header: 'Debit Reason', width: '160' },
    { field: 'isDeleted', header: 'Deleted', width: '160' },
    { field: 'reclaimCenter', header: 'Reclaim Center', width: '200' },
    { field: 'scanGroup', header: 'Scan Group', width: '160' },
];
export const upcScanHistoryHeaders = [
    {
        field: 'scannedOn',
        header: 'Scan Time',
        width: '120',
        textAlign: 'Right',
    },
    {
        field: 'storeLabel',
        header: 'Store Label',
        width: '160',
        textAlign: 'Left',
    },
    { field: 'storeId', header: 'Store', width: '90', textAlign: 'Left' },
    { field: 'upc', header: 'UPC', width: '80', textAlign: 'Left' },
    { field: 'desc', header: 'Description', width: '180', textAlign: 'Left' },
    { field: 'vendorId', header: 'Vendor ID', width: '125', textAlign: 'Left' },
    { field: 'quantity', header: 'QTY', width: '80', textAlign: 'Left' },
    {
        field: 'unitFactor',
        header: 'Unit Factor',
        width: '140',
        textAlign: 'Left',
    },
    { field: 'packSize', header: 'Pack Size', width: '120', textAlign: 'Left' },
    { field: 'status', header: 'Credit Flag', width: '160' },
];

export const scanSessionItems = [
    { field: 'uPC', header: 'UPC', width: '140', textAlign: 'Left' },
    {
        field: 'description',
        header: 'Description',
        width: '90',
        textAlign: 'Left',
    },
    { field: 'quantity', header: 'QTY', width: '80', textAlign: 'Center' },
    { field: 'storeID', header: 'Store Num', width: '120', textAlign: 'Left' },
    {
        field: 'storeLabel',
        header: 'StoreLabel',
        width: '170',
        textAlign: 'Left',
    },
    {
        field: 'warehouseLocation',
        header: 'WHSE Location',
        width: '130',
        textAlign: 'Left',
    },
    { field: 'unitCost', header: 'Cost', width: '90', textAlign: 'Right' },
    {
        field: 'scannedOn',
        header: 'SCAN Time',
        width: '240',
        textAlign: 'Left',
    },
    {
        field: 'delete',
        header: 'Delete',
        textAlign: 'Center',
        commands: [
            {
                type: 'delete',
                buttonOption: { iconCss: '', cssClass: 'clear-item' },
            },
        ],
    },
];

export const generateCoulmns = (key1: any, key2: any) => {
    return [
        {
            field: key1.field,
            header: key1.header,
            width: '140',
            textAlign: 'Left',
        },
        {
            field: key2.field,
            header: key2.header,
            width: '120',
            textAlign: 'Left',
        },
    ];
};
