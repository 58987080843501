export const invoiceDetailsTab = ["AR INVOICES", "CREDIT MEMOS"];

export const invoiceAttachmentsTabs = [
  "Pallets",
  "Fixed Price Items",
  "Misc-Salvage Items",
];

export const bolAttachmentsTabs = ["Invoice", "Manual Entries"];

export const invoiceAttachmentGridConfig = [
  [
    {
      field: "barcode",
      header: "BARCODE",
      width: "125",
      key: 1,
      dataType: "enum",
    },
    {
      field: "description",
      header: "DESCRIPTION",
      width: "150",
      key: 2,
      textAlign: "left",
    },
    { field: "bins", header: "BINS", width: "125", key: 3, dataType: "number" },
    { field: "boxes", header: "BOXES", key: 4, dataType: "number" },
    { field: "totalItemsCount", header: "ITEMS", key: 5, dataType: "number" },
    {
      field: "totalItemCost",
      header: "COST",
      key: 6,
      dataType: "number",
      textAlign: "right",
    },
    {
      field: "rate",
      header: "RATE",
      width: "150",
      key: 7,
      dataType: "number",
      textAlign: "right",
    },
    {
      field: "price",
      header: "PRICE",
      width: "150",
      key: 8,
      dataType: "number",
      textAlign: "right",
    },
  ],
  [
    { field: "barcode", header: "BARCODE", key: 9, dataType: "enum" },
    {
      field: "description",
      header: "DESCRIPTION",
      width: "150",
      key: 10,
      dataType: "number",
      textAlign: "left",
    },
    {
      field: "binsCount",
      header: "BINS",
      width: "150",
      key: 11,
      dataType: "number",
    },
    { field: "boxesCount", header: "BOXES", key: 12, dataType: "number" },
    { field: "itemsCount", header: "ITEMS", key: 13, dataType: "number" },
    {
      field: "pricePerBox",
      header: "RATE",
      width: "150",
      key: 14,
      dataType: "number",
      textAlign: "right",
    },
    {
      field: "price",
      header: "PRICE",
      width: "150",
      key: 15,
      dataType: "number",
      textAlign: "right",
    },
  ],
  [
    {
      field: "barcode",
      header: "BARCODE",
      width: "125",
      key: 16,
      dataType: "enum",
    },
    {
      field: "description",
      header: "DESCRIPTION",
      width: "150",
      key: 17,
      textAlign: "left",
    },
    {
      field: "bins",
      header: "BINS",
      width: "125",
      key: 18,
      dataType: "number",
    },
    { field: "boxes", header: "BOXES", key: 19, dataType: "number" },
    { field: "totalItemsCount", header: "ITEMS", key: 20, dataType: "number" },
    {
      field: "totalItemCost",
      header: "COST",
      key: 21,
      dataType: "number",
      textAlign: "right",
    },
    {
      field: "rate",
      header: "RATE",
      width: "150",
      key: 22,
      dataType: "number",
    },
    {
      field: "price",
      header: "PRICE",
      width: "150",
      key: 23,
      dataType: "number",
      textAlign: "right",
    },
  ],
];

export const addAttachmentGridConfig = [
  [
    // { field: "barcode", header: "BARCODE", width: "125",dataType: "enum" },
    // { field: "description", header: "DESCRIPTION", width: "150" },
    // { field: "bins", header: "BINS", width: "125", dataType: "number" },
    // { field: "boxes", header: "BOXES", dataType: "number" },
    // { field: "totalItemsCount", header: "ITEMS", dataType: "number" },
    // { field: "totalItemCost", header: "COST", dataType: "number",textAlign:"Right" },
    // { field: "rate", header: "RATE", width: "150", dataType: "number",textAlign:"right"  },
    // { field: "price", header: "PRICE", width: "150", dataType: "number",textAlign:"right"  },

    { field: "barcode", header: "BARCODE", width: "125", dataType: "enum" },
    {
      field: "description",
      header: "DESCRIPTION",
      width: "150",
      textAlign: "left",
    },
    {
      field: "bins",
      header: "BIN COUNT",
      width: "150",
      dataType: "number",
      textAlign: "center",
    },
    {
      field: "boxes",
      header: "BOX COUNT",
      width: "150",
      dataType: "number",
      textAlign: "center",
    },
    {
      field: "totalItemsCount",
      header: "ITEM COUNT",
      width: "150",
      dataType: "number",
      textAlign: "center",
    },
    {
      field: "totalItemCost",
      header: "COST",
      dataType: "number",
      textAlign: "right",
    },
    {
      field: "rate",
      header: "RATES",
      width: "150",
      dataType: "number",
      textAlign: "right",
    },
    {
      field: "price",
      header: "PRICE",
      width: "150",
      dataType: "number",
      textAlign: "right",
    },
  ],
  [
    { field: "code", header: "CODE", width: "150" },
    {
      field: "description",
      header: "DESCRIPTION",
      width: "150",
      textAlign: "left",
    },
    { field: "pricingType", header: "PRICING TYPE", width: "150" },
    {
      field: "price",
      header: "PRICE",
      width: "100",
      dataType: "number",
      textAlign: "right",
    },
  ],
  [
    { field: "upc", header: "BARCODE", width: "150", dataType: "enum" },
    {
      field: "description",
      header: "DESCRIPTION",
      width: "150",
      textAlign: "left",
    },
    { field: "binsCount", header: "BINS", width: "150", dataType: "number" },
    { field: "boxesCount", header: "BOXES", dataType: "number" },
    { field: "totalItemsCount", header: "ITEMS", dataType: "number" },
    { field: "totalItemCost", header: "COST", dataType: "number" },
    {
      field: "costPercentage",
      header: "RATES",
      width: "150",
      dataType: "number",
    },
    {
      field: "calculatePrice",
      header: "PRICE",
      width: "150",
      dataType: "number",
    },
  ],
];
export const bolAttachmentGridConfig = [
  [
    { field: "invoiceNumber", header: "INV #", width: "125" },
    {
      field: "description",
      header: "Description",
      width: "150",
      textAlign: "left",
    },
    { field: "pallets", header: "Pallets", width: "125" },
    { field: "bins", header: "BINS", width: "125" },
    { field: "boxes", header: "BOXES" },
    { field: "items", header: "ITEMS" },
    // { field: "weight", header: "WEIGHT" },
  ],
  [
    // { field: "id", header: "ENTRY #" },
    {
      field: "description",
      header: "Description",
      width: "150",
      textAlign: "left",
    },
    { field: "pallet", header: "PALLETS", width: "150" },
    { field: "bin", header: "BINS", width: "150" },
    { field: "box", header: "BOXES" },
    { field: "weight", header: "WEIGHT" },
  ],
];

// export const addAttachmentGridConfig = [[{ field: "barcode", header: "BARCODE", width: "125"}, { field: "description", header: "DESCRIPTION", width: "150"}, {field: "binsCount", header: "BIN COUNT", width: "150"},{ field: "boxCount", header: "BOX COUNT", width: "150"}, { field: "itemCount", header: "ITEM COUNT", width:  "150"}, { field: "cost", header: "COST"}, { field: "price", header: "PRICE", width: "150"}],
// [{ field: "code", header: "CODE", width: "150"}, { field: "description", header: "DESCRIPTION", width: "150"},{ field: "type", header: "TYPE", width: "150"}, { field: "price", header: "PRICE", width: "150"} ]
// ];

export const creditMemoAttachmentGridConfig = [
  [
    { field: "barCode", header: "BARCODE", width: "125" },
    {
      field: "description",
      header: "DESCRIPTION",
      width: "150",
      textAlign: "left",
    },
    { field: "bins", header: "BINS", width: "125" },
    { field: "boxes", header: "BOXES" },
    { field: "items", header: "ITEMS" },
    { field: "cost", header: "COST" },
    { field: "rate", header: "RATES", width: "150" },
    { field: "price", header: "PRICE", width: "150", textAlign: "right" },
    {
      field: "fixedPrice",
      header: "Fixed Price",
      width: "150",
      textAlign: "right",
    },
  ],
  [
    { field: "barCode", header: "BARCODE" },
    {
      field: "description",
      header: "DESCRIPTION",
      width: "150",
      textAlign: "left",
    },
    { field: "bins", header: "BINS", width: "150" },
    { field: "boxes", header: "BOXES" },
    { field: "items", header: "ITEMS" },
    { field: "rate", header: "RATES", width: "150" },
    { field: "price", header: "PRICE", width: "150", textAlign: "right" },
    {
      field: "fixedPrice",
      header: "Fixed Price",
      width: "150",
      textAlign: "right",
    },
  ],
  [
    { field: "udc", header: "BARCODE" },
    { field: "desc", header: "DESCRIPTION", width: "150", textAlign: "left" },
    { field: "bins", header: "BINS", width: "150" },
    { field: "boxes", header: "BOXES" },
    { field: "items", header: "ITEMS" },
    { field: "itemCost", header: "COST" },
    { field: "percent", header: "RATES", width: "150" },
    { field: "total", header: "PRICE", width: "150", textAlign: "right" },
    {
      field: "fixedPrice",
      header: "Fixed Price",
      width: "150",
      textAlign: "right",
    },
  ],
];
