import { ButtonComponent } from '@syncfusion/ej2-react-buttons';
import { FC, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { RootState } from '../../../redux/store';
import ContentHeader from '../../common/ContentHeader/ContentHeader';
import Divider from '../../common/divider/Divider';
import GridWrapper from '../../grid/Grid';
import { OutBoundBoxUtil } from '../OutBoundBoxUtil';
import { generateCoulmns, getLocalStorageItems } from '../utils';
import { setTabNameForRowData } from "../../contactTabs/contactReducer";
import {
    setOutboundBinDetails,
    setOutboundBoxDetails
} from './../scanReducer';

interface BoxAndPallet {
    heading: string;
    key1: {};
    key2: {};
    value: any;
    setAddScreen: any;
    isFrom: any;
    reset?: any;
    resetFocusToUpc: any;
}

const ClosePalletAndOutBound: FC<BoxAndPallet> = ({
    heading,
    key1,
    key2,
    value,
    setAddScreen,
    isFrom,
    reset,
    resetFocusToUpc,
}) => {
    const setScanSettings = useSelector(
        (state: RootState) => state.scanReducer.scanSettings
    );
    const getScanMode = useSelector(
        (state: RootState) => state.scanReducer.scanMode
    );
    const [boxes, setBoxes] = useState([0]);
    const [selectedRecord, setSelectedRecord] = useState<any[]>([]);
    const [scanSettings, setSettings] = useState({
        scangroup: '',
        scanStation: '',
        reclaimCenter: '',
    });
    const loggedInUserId = useSelector(
        (state: RootState) => state.sideBarReducer.loggedInUserId
    );

    const getOutBoundBoxDetails = useSelector(
        (state: RootState) => state.scanReducer.outBoundBoxDetails
    );
    const getOutBoundBinDetails = useSelector(
        (state: RootState) => state.scanReducer.outBoundBinDetails
    );
    const dispatch = useDispatch();

    useEffect(() => {
        setSettings(getLocalStorageItems('scanSettings'));
        dispatch(setTabNameForRowData(''));
    }, []);

    const handleCancel = () => {
        setAddScreen(false);
        resetFocusToUpc();
        if (isFrom === 'pallets') {
            reset(true);
        } else if(reset) {
            reset();
        }
    };

    const handleSelectRecords = (e: any) => {
        setSelectedRecord(e);
        const itemList = e.map((item: any) => item.id);
        console.log(itemList);
        setBoxes(itemList);
    };

    const handleCloseOutboundboxes = () => {
        if(!selectedRecord || selectedRecord.length < 1 ){
            setAddScreen(false);
            resetFocusToUpc();
            reset();
            return;
        }
            
        if (
            isFrom === 'outboundboxlist' &&
            (getScanMode.payload === 'bin' || 
                getScanMode.payload === 'box' || 
                getScanMode.payload === 'nocredit' ||
                getScanMode.payload === 'trash')
        ) {
            selectedRecord.forEach(async (item: any) => {
                await OutBoundBoxUtil.processOutboundBoxCloseAction(
                    scanSettings,
                    [item.id],
                    item,
                    resetFocusToUpc,
                    loggedInUserId
                ).then((response: any) => {
                    if (response === 'true' && getOutBoundBoxDetails?.payload?.id === item.id) {
                        dispatch(setOutboundBoxDetails({ ...getOutBoundBoxDetails.payload, isClosed: true }))
                    }
                    setSelectedRecord([]);
                    reset();
                });
            });
        } else if (
            isFrom === 'outboundbinlist' &&
            (getScanMode.payload === 'bin' || 
                getScanMode.payload === 'box'||
                getScanMode.payload === 'nocredit' ||
                getScanMode.payload === 'trash')
        ) {
            selectedRecord.forEach(async (item: any) => {
                await OutBoundBoxUtil.processOutBoundBinClosedAction(
                    scanSettings,
                    [item.id],
                    item, resetFocusToUpc, loggedInUserId
                ).then((response: any) => {
                    if (response === 'true' && getOutBoundBinDetails?.payload?.id === item.id) {
                        dispatch(setOutboundBinDetails({ ...getOutBoundBinDetails.payload, isClosed: true }))
                    }
                    setSelectedRecord([]);
                    reset();
                });
            });
        } else if (isFrom === 'pallets') {
            selectedRecord.forEach((item: any) => {
                OutBoundBoxUtil.processMultiplePalletClosedAction(
                    scanSettings,
                    item,
                    resetFocusToUpc,
                    loggedInUserId
                );
            });
            reset(true);
        }
        setAddScreen(false);
        resetFocusToUpc();
    };
    return (
        <>
            <ContentHeader value={heading} />
            <Divider />
            <div className="table-responsive outbound-box table-bordered">
                {/* <table className="table">
          <thead>
            <tr>
              <th>
                <input
                  type="checkbox"
                  checked={checkedAll}
                  onChange={(event) => selectAll(event.target.checked)}
                ></input>
              </th>
              <th>{key1}</th>
              <th>{key2}</th>
            </tr>
          </thead>
          <tbody>{outboundBoxList}</tbody>
        </table> */}
                <GridWrapper
                    config={generateCoulmns(key1, key2)}
                    data={value}
                    width={'100%'}
                    height={'200'}
                    hideSpinner={true}
                    onRowSelect={handleSelectRecords}
                    onRowDeselect={handleSelectRecords}
                />
            </div>
            <div className="col-sm-9 mt-2rem">
                <ButtonComponent
                    cssClass="eg-btn-primary float-right btnBox"
                    onClick={handleCloseOutboundboxes}
                    disabled={!selectedRecord || selectedRecord.length < 1}
                >
                    CLOSE{' '}
                    {isFrom === 'outboundboxlist'
                        ? 'Box'
                        : isFrom === 'outboundbinlist'
                            ? 'Bin'
                            : 'Pallet'}
                    (s)
                </ButtonComponent>
                <ButtonComponent
                    cssClass="eg-btn-secondary float-right mr-4"
                    onClick={() => handleCancel()}
                >
                    CANCEL
                </ButtonComponent>
            </div>
        </>
    );
};

export default ClosePalletAndOutBound;
