import {useEffect, useState} from 'react';
import {DashboardLayoutComponent} from '@syncfusion/ej2-react-layouts';
import {useDispatch, useSelector} from 'react-redux';
import {TabComponent, TabItemDirective, TabItemsDirective,} from '@syncfusion/ej2-react-navigations';
import ContentHeader from '../common/ContentHeader/ContentHeader';
import InvoicesControls from './controls';
import Grid from '../grid/Grid';
import loader from '../../assets/loader.gif';
import {bolTabs, bolUrl, invoiceGridConfig, InvoiceScreen, invoicesTabs, invoiceView,} from './config';
import {
    setArInvoiceDefaultData,
    setArInvoiceSavedata,
    setBOLDataByIddata,
    setBOLNumberForAdd,
    setBOLsList,
    setCreditMemoSavedata,
    setDescriptionForCM,
    setInvoiceNumForCM,
    setIsInvoiceUpdated,
    setManualEntriesList,
    setSelectedArInvoiceTabIndex,
    setSelectedIdsForCancel,
} from './invoiceReducer';
import './Invoices.scss';
import {RootState} from '../../redux/store';
import {getAllMasterData} from '../contactTabs/utils';
import axios from 'axios';
import {
    setCarriers,
    setCustomers,
    setFiscalYearPeriodsData,
    setReclaimCenters,
    setWarehouses,
} from '../contactTabs/contactReducer';
import {
    creditMemoById,
    getArInvoiceMockData,
    getArInvoiceNumberEndPoint,
    getArInvoicesExport,
    getARInvoicesListEndPoint,
    getBOLDataByIdEndPoint,
    getBOLListEndPoint,
    getBOLNumForADD,
    getBOLPrint,
    getCarrierListEndPoint,
    getCreditMemoListEndPoint,
    getCustomerListEndPoint,
    getInvoiceWithClient,
    getInvoiceWithDetails,
    getInvoiceWithOutPricing,
    getManualItemsEndPoint,
    getPickUPFromListEndPoint,
    getReclaimCenterListEndPoint,
    handleCancelActionCall,
    handleCancelActionCallBOL,
    handleCancelActionCallCMemo,
    SaveArInvoicesEndPoint,
} from './utils';
import notify from '../../services/toasterService';
import * as FileSaver from 'file-saver';
import moment from 'moment';
import ARInvoiceGrid from '../grid/ar-invoice-grid/ar-invoice-grid';
import CreditMemoGrid from '../grid/credit-memo-grid/credit-memo-grid';

const exportMenu = [
    {
        text: 'Invoice',
        exportType: 'excel',
        id: 1,
        // imgPath: home
    },
    {
        text: 'Invoice w/o pricing',
        exportType: 'excel',
        id: 2,
    },
    {
        text: 'Invoice detail',
        exportType: 'excel',
        id: 3,
    },
    {
        text: 'Invoice detail with client',
        exportType: 'excel',
        id: 4,
    },
    {
        text: 'Invoice PDF',
        exportType: 'pdf',
        id: 5,
    },
    {
        text: 'Invoice w/o pricing PDF',
        exportType: 'pdf',
        id: 6,
    },
    {
        text: 'Invoice detail PDF',
        exportType: 'pdf',
        id: 7,
    },
    {
        text: 'Invoice detail with client PDF',
        exportType: 'pdf',
        id: 8,
    },
];
const exportMenuBol = [
    {
        text: 'BOL',
        exportType: 'excel',
        id: 'B1',
        // imgPath: home
    },
    {
        text: 'BOL PDF',
        exportType: 'pdf',
        id: 'B2',
        // imgPath: home
    },
    // {
    //   text: "BOL w/o pricing",
    //   exportType: "excel",
    //   id: 2,
    // },
    // {
    //   text: "BOL detail",
    //   exportType: "excel",
    //   id: 3,
    // },
    // {
    //   text: "BOL detail with client",
    //   exportType: "excel",
    //   id: 4,
    // },
    // {
    //   text: "BOL PDF",
    //   exportType: "pdf",
    //   id: 5
    // },
    // {
    //   text: "BOL w/o pricing PDF",
    //   exportType: "pdf",
    //   id: 6,
    // },
    // {
    //   text: "BOL detail PDF",
    //   exportType: "pdf",
    //   id: 7,
    // },
    // {
    //   text: "BOL detail with client PDF",
    //   exportType: "pdf",
    //   id: 8,
    // }
];
const exportMenuCM = [
    {
        text: 'CM',
        exportType: 'Empty',
        id: 1,
        // imgPath: home
    },
];
const Invoice = (props: any) => {
    const [loaderStatus, setLoaderStatus] = useState(true);
    const [selectedScreen, setSelectedScreen] = useState(
        props?.match?.url?.includes(invoiceView)
            ? InvoiceScreen.INVOICE
            : props?.match?.url?.includes(bolUrl)
                ? InvoiceScreen.BOL
                : InvoiceScreen.BOLVIEW
    );
    let gridHieght = 'calc(100vh - 410px)';
    console.log(selectedScreen, 'screen');
    const [selectedTabIndex, setSelectedTabIndex] = useState(0);
    const [arInvoices, setARInvoices] = useState([]);
    const [creditMemoInfo, setCreditMemoInfo] = useState([]);
    // const [bolInfo, setBolInfo] = useState([]);
    const [bolInfo, setBolInfo] = useState<{ [key: string]: Object }[]>([]);

    const selectedInvoiceScreen = useSelector(
        (state: RootState) => state.invoiceReducer.selectedInvoiceScreen
    );
    const selectedArInvoiceTabIndex = useSelector(
        (store: RootState) => store.invoiceReducer.selectedArInvoiceTabIndex
    );
    const bolData = useSelector(
        (state: RootState) => state.invoiceReducer.bolsList
    );
    const checkedIdForCancel = useSelector(
        (state: RootState) => state.invoiceReducer.selectedIdForCancel
    );
    console.log('checkedIdForCancel', checkedIdForCancel);
    const checkedIdsForCancel = useSelector(
        (state: RootState) => state.invoiceReducer.selectedIdsForCancel
    );
    const selectedRecordsForExport = useSelector(
        (state: RootState) => state.contactReducer.selectedRecordsForExport
    );
    const isBOLClear = useSelector(
        (store: RootState) => store.invoiceReducer.isBOLDataClear
    );
    const gridRef = useSelector(
        (store: RootState) => store.gridReducer.gridRefInfo
    );
    const dispatch = useDispatch();
    const handleTabSelection = (params: any) => {
        gridRef?.clearFiltering();
        gridRef?.clearSorting();
        setSelectedTabIndex(params?.selectedIndex == 1 ? 1 : 0);
        dispatch(
            setSelectedArInvoiceTabIndex(params?.selectedIndex == 1 ? 1 : 0)
        );
        dispatch(setIsInvoiceUpdated(false));
    };
    const handleGridRowSelect = () => {
        dispatch(setBOLDataByIddata(''));
        if (selectedInvoiceScreen) {
            const route =
                selectedInvoiceScreen === InvoiceScreen.INVOICE
                    ? invoiceView
                    : bolUrl;
            if (selectedInvoiceScreen === InvoiceScreen.INVOICE) {
                if (selectedArInvoiceTabIndex === 1) {
                    props.history.push(`${route}/newcreditmemo`);
                } else {
                    handleGetArInvoiceNumber(`${route}/Details`);
                }
            } else {
                props.history?.push(`${route}/Details`);
            }
        }
    };
    const handleGridRowSelectBOLandInvoice = (event: any) => {
        if (!event) {
            return;
        }
        if (selectedInvoiceScreen === InvoiceScreen.BOL) {
            // handleGetBolDataById(event[0]?.bolId);
            const endPoint = getBOLDataByIdEndPoint(event[0]?.bolId);
            handleGetBolDataById(endPoint, event[0]?.bolId);
        } else if (
            selectedInvoiceScreen === InvoiceScreen.INVOICE &&
            selectedArInvoiceTabIndex === 0
        ) {
            console.log('>>>>>>>>>> event: ', event);
            event && handleGetArInvoiceDataById(event);
        } else if (
            selectedInvoiceScreen === InvoiceScreen.INVOICE &&
            selectedArInvoiceTabIndex === 1
        ) {
            console.log('>>>>>>>>>> event: ', event);
            event && handleGetArInvoiceDataById(event);
        }
    };
    const handleGetBolDataById = async (e: any, bolId: any) => {
        const result = await axios.get(e, bolId);
        if (result && result.data) {
            dispatch(setBOLDataByIddata(result.data));
            props?.history?.push(`invoiceView/Details`);
            console.log('Action History data', result.data);
        } else {
            props.history.push(`invoiceView/Details`);
        }
    };
    // const handleGetBolDataById = (data: any) => {
    //   const options = {
    //     method: "GET",
    //     headers: {
    //       "Content-Type": "application/json",
    //     },
    //   };
    //   const endpoint = getBOLDataByIdEndPoint(data);
    //   fetch(endpoint, options)
    //     .then((data) => data.json())
    //     .then((result) => {
    //       if (!result) {
    //       } else {
    //         dispatch(setBOLDataByIddata(result));

    //         props.history.push(`invoiceView/Details?bolId=${result?.id}`);
    //       }
    //     });
    // };
    const handleGetArInvoiceDataById = async (data: any) => {
        // await dispatch(setArInvoiceSavedata(data));
        if (selectedArInvoiceTabIndex === 0) {
            dispatch(setCreditMemoSavedata(['']));
            const endpoint = SaveArInvoicesEndPoint(data.data.invoiceNumber);
            if (data.data.invoiceNumber) {
                axios.get(endpoint).then(async (result: any) => {
                    if (!result) {
                    } else {
                        console.log(result?.data);
                        dispatch(setArInvoiceSavedata(result?.data));
                        dispatch(setArInvoiceDefaultData(result?.data));
                        if (result && result?.data) {
                            await props?.history?.push(
                                `invoiceView/Details?ArInvoiceId=${data.data?.invoiceNumber}`
                            );
                        }
                    }
                });
            } else {
                await props.history.push(`invoiceView/Details`);
            }
        } else {
            const endpointCM = creditMemoById(data.data.id);
            axios.get(endpointCM).then(async (result: any) => {
                if (!result) {
                } else {
                    dispatch(setArInvoiceSavedata(['']));
                    dispatch(setDescriptionForCM(''));
                    //dispatch(setInvoiceNumForCM(''));
                    dispatch(setInvoiceNumForCM(result.data.invoiceNumber));
                    dispatch(setCreditMemoSavedata(result.data));
                    await props?.history?.push(
                        `invoiceView/Details?CreditMemoId=${data.data?.id}`
                    );
                }
            });
        }
    };
    const handleGetArInvoiceNumber = (route: any) => {
        const body = JSON.stringify(getArInvoiceMockData());

        const endpoint = getArInvoiceNumberEndPoint();
        axios
            .post(endpoint, body, {
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
            })
            .then((result: any) => {
                if (!result) {
                } else {
                    console.log(result.data);
                    dispatch(setArInvoiceDefaultData({...result.data}));
                    dispatch(setArInvoiceSavedata({...result.data}));

                    props.history?.push(route);
                }
            });
    };
    const handleGetMasters = async () => {
        const rcEndPoint = getReclaimCenterListEndPoint();
        const rcResult = await axios.get(rcEndPoint).catch((err) => err);
        dispatch(setReclaimCenters(rcResult.data));
        const whEndPoint = getPickUPFromListEndPoint();
        const whResult = await axios.get(whEndPoint).catch((err) => err);
        dispatch(setWarehouses(whResult.data));
        const fyEndpoint = getAllMasterData('FiscalYearPeriods');
        const fyResult = await axios.get(fyEndpoint).catch((err) => err);
        dispatch(setFiscalYearPeriodsData(fyResult.data));
        const customerEndPoint = getCustomerListEndPoint();
        const customerResult = await axios
            .get(customerEndPoint)
            .catch((err) => err);
        dispatch(setCustomers(customerResult.data));
        const carrierEndPoint = getCarrierListEndPoint();
        const carrierResult = await axios
            .get(carrierEndPoint)
            .catch((err) => err);
        dispatch(setCarriers(carrierResult.data));
        const manualEntryEndPoint = getManualItemsEndPoint();
        const manualEntryResult = await axios
            .get(manualEntryEndPoint)
            .catch((err) => err);
        dispatch(setManualEntriesList(manualEntryResult.data));
        const BOLsEndPoint = getBOLListEndPoint();
        const bolResult = await axios.get(BOLsEndPoint).catch((err) => err);
        dispatch(setBOLsList(bolResult.data));
    };
    const handleAddNewItem = (selectedArInvoiceTabIndex: number) => {
        handleGetBolNum();
        dispatch(setSelectedArInvoiceTabIndex(selectedArInvoiceTabIndex));
        handleGridRowSelect();
    };
    const handleGetBolNum = async () => {
        const bolNumEndPoint = getBOLNumForADD();
        const bolNumResult = await axios
            .post(bolNumEndPoint)
            .catch((err) => err);
        dispatch(setBOLNumberForAdd(bolNumResult.data));
    };
    const bolGridData = useSelector(
        (store: RootState) => store.invoiceReducer.bolsList
    );

    useEffect(() => {
        setBolInfo(
            bolGridData &&
            bolGridData?.map((el: any) => {
                return {
                    ...el,
                    cityState: el?.deliveryAddress
                        ? el?.deliveryAddress?.city +
                        ', ' +
                        el?.deliveryAddress?.state
                        : '',
                    pickUpDate: el?.pickUpDate
                        ? moment(el?.pickUpDate).format('MMM DD, YYYY')
                        : '',
                };
            })
        );
    }, [bolGridData]);
    const tabConfig =
        selectedScreen === InvoiceScreen.INVOICE ? invoicesTabs : bolTabs;

    const gridConfig =
        selectedScreen === InvoiceScreen.INVOICE &&
        selectedArInvoiceTabIndex === 0
            ? invoiceGridConfig[0]
            : selectedScreen === InvoiceScreen.INVOICE &&
            selectedArInvoiceTabIndex === 1
                ? invoiceGridConfig[1]
                : invoiceGridConfig[2];

    const gridData =
        selectedScreen === InvoiceScreen.INVOICE &&
        selectedArInvoiceTabIndex === 0
            ? arInvoices
            : selectedScreen === InvoiceScreen.INVOICE &&
            selectedArInvoiceTabIndex === 1
                ? creditMemoInfo
                : bolInfo;

    useEffect(() => {
        setBolInfo(
            bolGridData &&
            bolGridData?.map((el: any) => {
                return {
                    ...el,
                    cityState: el?.deliveryAddress
                        ? el?.deliveryAddress?.city +
                        ', ' +
                        el?.deliveryAddress?.state
                        : '',
                    pickUpDate: el?.pickUpDate
                        ? moment(el?.pickUpDate).format('MMM DD, YYYY')
                        : '',
                };
            })
        );
        const endpoint = getARInvoicesListEndPoint();
        const endpointCMemo = getCreditMemoListEndPoint();
        handleGetMasters();
        handleActionGetLists(endpoint);
        handleActionGetCMList(endpointCMemo);
        dispatch(setIsInvoiceUpdated(false));
        // fetch(endpoint)
        //   .then((data) => data.json())
        //   .then((result) => {
        //     if (!result || result.length === 0) {
        //       //alert("Fetch list successful, but no list items are present");
        //     } else {
        //       //alert("Fetch list successful");
        //       let arlist: any = [];
        //        result.forEach((element: any) => {
        //         arlist.push(CovertArInvoiceGridData(element));
        //       });
        //       setARInvoices(arlist);
        //       // setARInvoices(
        //       //   arlist.map((el: any) => {
        //       //     return {
        //       //       ...el,
        //       //       cost: el?.cost ? "$" + el?.cost.toFixed(2) : "",
        //       //       price: el?.price ? "$" + el?.price.toFixed(2) : "",
        //       //     };
        //       //   })
        //       // );
        //       console.log("arInvoices", result);
        //       //alert("An error has occurd while fetching a list: " + result.error);
        //     }
        //   })
        //   .catch((error) => console.log(error));

        // fetch(endpointCMemo)
        //   .then((data) => data.json())
        //   .then((result) => {
        //     console.log("CMemo data", result);
        //     //setCreditMemoInfo(result);
        //     if (!result || result.length === 0) {
        //       //alert("Fetch list successful, but no list items are present");
        //     } else {
        //       setCreditMemoInfo(result.content);
        //       //alert("An error has occurd while fetching a list: " + result.error);
        //     }
        //   })
        //   .catch((error) => console.log(error));
        dispatch(setIsInvoiceUpdated(false));
    }, []);
    const handleActionGetLists = async (endpoint: string) => {
        dispatch(setIsInvoiceUpdated(false));
        const result = await axios
            .get(endpoint)
            .then((results: any) => {
                setLoaderStatus(false);
                if (results && results.data) {
                    let arlist: any = [];
                    results.data.forEach((element: any) => {
                        arlist.push(CovertArInvoiceGridData(element));
                    });
                    setARInvoices(arlist);
                } else {
                    notify('Failed', 'error');
                }
            })
            .catch((error: any) => {
                if (error.response) {
                    notify(error.response?.data?.message, 'error');
                }
            });
    };
    const handleActionGetCMList = async (endpoint: string) => {
        const result = await axios
            .get(endpoint)
            .then((results: any) => {
                setLoaderStatus(false);
                if (results && results.data) {
                    setCreditMemoInfo(results.data.content);
                    setCreditMemoInfo(
                        results &&
                        results.data.content?.map((el: any) => {
                            return {
                                ...el,
                                CMid: el.invoiceNumber + 'CM',
                                createdDate: moment(el.createdDate).format(
                                    'MMM DD, YYYY'
                                ),
                                facility: el.reclaimCenterName,
                                //credit: '$'+(el.credit).toFixed(2)
                            };
                        })
                    );
                } else {
                    notify('Failed', 'error');
                }
            })
            .catch((error: any) => {
                if (error.response) {
                    notify(error.response?.data?.message, 'error');
                }
            });
    };
    const CovertArInvoiceGridData = (data: any) => {
        return {
            ...data,
            invoiceNumber: data.invoiceNumber,
            description: data.description,
            customerId: data.customerId,
            period: data.period,
            cost: data.cost.toFixed(2),
            price: data.price.toFixed(2),
            createdDate: moment(data.createdDate).format('MMM DD, YYYY'),
            facility: data.reclaimCenterName,
            status:
                data.status.status === 'Pickup, Auto Approved'
                    ? 'Pickup Auto'
                    : data.status.status === 'Ready for Salvager'
                        ? 'Ready Slvg'
                        : data.status.status === 'Ready for Extract'
                            ? 'Ready Extrct'
                            : data.status.status === 'Pickup, Manual Override'
                                ? 'Pickup Mnul'
                                : data.status.status,
        };
    };
    let checkedInfo: any[] = [];
    const handleCancelAction = () => {
        let body;
        let endpoint;
        if (
            selectedInvoiceScreen === InvoiceScreen.INVOICE &&
            selectedArInvoiceTabIndex === 0
        ) {
            checkedIdsForCancel.some((item) => {
                checkedInfo.push(item[0].invoiceNumber);
            });
            body = {
                arInvoiceList: checkedInfo,
                status: 'Cancelled',
            };
            endpoint = handleCancelActionCall();
            handleActionForCancel(endpoint, body);
        } else if (
            selectedInvoiceScreen === InvoiceScreen.INVOICE &&
            selectedArInvoiceTabIndex === 1
        ) {
            checkedIdsForCancel.some((item) => {
                checkedInfo.push(item[0].id);
            });
            body = {
                creditMemoList: checkedInfo,
                status: 'Cancelled',
            };
            endpoint = handleCancelActionCallCMemo();
            handleActionForCancel(endpoint, body);
        } else {
            checkedIdsForCancel.some((item) => {
                checkedInfo.push(item[0].bolId);
            });
            body = {bolList: checkedInfo, status: 'Cancelled'};
            endpoint = handleCancelActionCallBOL();
            handleActionForCancel(endpoint, body);
        }
    };
    const handleActionForCancel = async (endpoint: string, body: any) => {
        const result = await axios
            .put(endpoint, body)
            .then((results: any) => {
                if (results && results.data) {
                    notify('Success', 'success');
                    handleGetList();
                    dispatch(setSelectedIdsForCancel([]));
                } else {
                    notify('Failed', 'error');
                }
            })
            .catch((error: any) => {
                if (error.response) {
                    notify(error.response?.data?.message, 'error');
                }
            });
    };
    const handleGetList = async () => {
        if (
            selectedInvoiceScreen === InvoiceScreen.INVOICE &&
            selectedArInvoiceTabIndex === 0
        ) {
            // const ARInvoicesEndPoint = getARInvoicesListEndPoint();
            // const ARResult = await axios.get(ARInvoicesEndPoint).catch((err) => err);
            // //dispatch(setARInvoicesList(ARResult.data));
            // setARInvoices(ARResult.data);
            const endpoint = getARInvoicesListEndPoint();
            handleGetMasters();
            fetch(endpoint)
                .then((data) => data.json())
                .then((result) => {
                    if (!result || result.length === 0) {
                        //alert("Fetch list successful, but no list items are present");
                    } else {
                        //alert("Fetch list successful");
                        let arlist: any = [];
                        setARInvoices(arlist);
                        result?.forEach((element: any) => {
                            arlist?.push(CovertArInvoiceGridData(element));
                        });
                        //setARInvoices(result);
                        // setARInvoices(
                        //   result && result?.map((el: any) => {
                        //     return {
                        //       ...el,
                        //       created: el.createdDate,
                        //       facility: el.reclaimCenterName,
                        //       status:
                        //       el.status.status === "Pickup, Auto Approved"
                        //           ? "Pickup Auto"
                        //           : el.status.status === "Ready for Salvager"
                        //           ? "Ready Slvg"
                        //           : el.status.status === "Ready for Extract"
                        //           ? "Ready Extrct"
                        //           : el.status.status === "Pickup, Manual Override"
                        //           ? "Pickup Mnul"
                        //           : el.status.status,
                        //     };
                        //   }),
                        // );
                    }
                });
        } else if (
            selectedInvoiceScreen === InvoiceScreen.INVOICE &&
            selectedArInvoiceTabIndex === 1
        ) {
            // const ARInvoicesEndPoint = getCreditMemoListEndPoint();
            // const ARResult = await axios.get(ARInvoicesEndPoint).catch((err) => err);
            // setCreditMemoInfo(ARResult.data);
            const endpoint = getCreditMemoListEndPoint();
            fetch(endpoint)
                .then((data) => data.json())
                .then((result) => {
                    //setCreditMemoInfo(result);
                    if (!result || result.length === 0) {
                        //alert("Fetch list successful, but no list items are present");
                    } else {
                        setCreditMemoInfo(result.content);
                        //alert("An error has occurd while fetching a list: " + result.error);
                    }
                });
        } else {
            const BOLsEndPoint = getBOLListEndPoint();
            const bolResult = await axios.get(BOLsEndPoint).catch((err) => err);
            //setBolInfo(bolResult.data);
            setBolInfo(bolResult.data);
            //   bolResult &&
            //   bolResult?.data.map((el: any) => {
            //       return {
            //         ...el,
            //         calendarYear: el?.calendarYear
            //           ? el.calendarYear + "-" + el.fiscalYearPeriodWeek
            //           : "",
            //       };
            //     })
            // );
        }
    };
    let selectedRecordId: any;
    const handleActionExport = (e: any) => {
        setLoaderStatus(true);
        if (!checkedIdForCancel) {
            notify('Please select a record', 'error');
            setLoaderStatus(false);
        } else {
            selectedRecordId = checkedIdForCancel?.data?.invoiceNumber
                ? checkedIdForCancel?.data?.invoiceNumber
                : checkedIdForCancel?.data?.bolId;
            // console.log("exportTypes", exportTypes.selectItemByText)
            if (e.element.id === '1') {
                const body = {
                    invoiceNumber: selectedRecordId,
                    flag: 0,
                };
                const endpoint = getArInvoicesExport();
                handleGetARExportExcel(endpoint, body, 'Invoice');
            } else if (e.element.id === '2') {
                const body = {
                    invoiceNumber: selectedRecordId,
                    flag: 0,
                };
                const endpoint = getInvoiceWithOutPricing();
                handleGetARExportExcel(endpoint, body, 'Invoice W/O Pricing');
            } else if (e.element.id === '3') {
                const body = {
                    invoiceNumber: selectedRecordId,
                    flag: 0,
                };
                const endpoint = getInvoiceWithDetails();
                handleGetARExportExcel(endpoint, body, 'Invoice Detail');
            } else if (e.element.id === '4') {
                const body = {
                    invoiceNumber: selectedRecordId,
                    flag: 0,
                };
                const endpoint = getInvoiceWithClient();
                handleGetARExportExcel(
                    endpoint,
                    body,
                    'Invoice Detail With Client'
                );
            } else if (e.element.id === '5') {
                selectedRecordsForExport?.forEach((data) => {
                    const body = {
                        invoiceNumber: data.invoiceNumber,
                        flag: 1,
                    };
                    const endpoint = getArInvoicesExport();
                    handleGetARExportPDF(endpoint, body, 'Invoice',
                        data.invoiceNumber);
                });
            } else if (e.element.id === '6') {
                const body = {
                    invoiceNumber: selectedRecordId,
                    flag: 1,
                };
                const endpoint = getInvoiceWithOutPricing();
                handleGetARExportPDF(endpoint, body, 'Invoice W/O Pricing');
            } else if (e.element.id === '7') {
                selectedRecordsForExport?.forEach((data) => {
                    const body = {
                        invoiceNumber: data.invoiceNumber,
                        flag: 1,
                    };
                    const endpoint = getInvoiceWithDetails();
                    handleGetARExportPDF(
                        endpoint,
                        body,
                        'Invoice Detail',
                        data.invoiceNumber
                    );
                });
            } else if (e.element.id === '8') {
                const body = {
                    invoiceNumber: selectedRecordId,
                    flag: 1,
                };
                const endpoint = getInvoiceWithClient();
                handleGetARExportPDF(
                    endpoint,
                    body,
                    'Invoice Detail With Client'
                );
            } else if (e.element.id === 'B1') {
                const body = {
                    invoiceNumber: selectedRecordId,
                    flag: 0,
                };
                const endpoint = getBOLPrint();
                handleGetARExportExcel(endpoint, body, 'BOL');
            } else if (e.element.id === 'B2') {
                const body = {
                    invoiceNumber: selectedRecordId,
                    flag: 1,
                };
                const endpoint = getBOLPrint();
                handleGetARExportPDF(endpoint, body, 'BOL');
            } else {
            }
        }
    };
    let exportInfo: any;
    const handleGetARExportExcel = async (
        endpoint: string,
        body: any,
        name: any
    ) => {
        const result = await axios
            .post(endpoint, body, {responseType: 'blob'})
            .then((res) => {
                setLoaderStatus(false);
                exportInfo = res.data;
                const file = new Blob([exportInfo], {
                    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                });
                FileSaver.saveAs(file, selectedRecordId + '-' + name + '.xlsx');
                // dispatch(setSelectedIdForCancel(['']));
            })
            .catch((err) => {
                setLoaderStatus(false);
                console.log(err);
                notify('No data found for ' + selectedRecordId, 'error');
            });
    };
    const handleGetARExportPDF = async (
        endpoint: string,
        body: any,
        name: any,
        invoiceNumber?: any
    ) => {
        const result = await axios
            .post(endpoint, body, {responseType: 'blob'})
            .then((res) => {
                setLoaderStatus(false);
                exportInfo = res.data;
                const file = new Blob([exportInfo], {
                    type: 'application/pdf',
                });
                const fileURL = URL.createObjectURL(file);
                const link = document.createElement('a');
                link.href = fileURL;
                if (invoiceNumber) {
                    link.download = invoiceNumber + '-' + name + '.pdf';
                } else {
                    link.download = selectedRecordId + '-' + name + '.pdf';
                }
                link.click();
                //window.open(fileURL);

                // dispatch(setSelectedIdForCancel(['']));
            })
            .catch((err) => {
                setLoaderStatus(false);
                console.log(err);
                notify('No data found for ' + selectedRecordId, 'error');
            });
    };
    return (
        <DashboardLayoutComponent
            id="defaultLayout"
            cellSpacing={[5, 5]}
            allowResizing={true}
            columns={5}
        >
            <div
                className="invoices-container"
                data-row="0"
                data-col="0"
                data-sizex="5"
                data-sizey="1"
            >
                <TabComponent
                    heightAdjustMode="Auto"
                    selected={handleTabSelection}
                    selectedItem={selectedArInvoiceTabIndex == 1 ? 1 : 0}
                >
                    <TabItemsDirective>
                        {tabConfig.map((tab) => {
                            return (
                                <TabItemDirective
                                    key={tab}
                                    header={{text: tab}}
                                />
                            );
                        })}
                    </TabItemsDirective>
                </TabComponent>
                <div className="invoices-controls">
                    <ContentHeader
                        value={
                            selectedScreen === InvoiceScreen.INVOICE
                                ? selectedArInvoiceTabIndex == 0
                                    ? 'AR Invoices'
                                    : 'Credit Memos'
                                : 'BOLs'
                        }
                    />
                    <InvoicesControls
                        cancelButtonText={
                            selectedScreen === InvoiceScreen.INVOICE
                                ? selectedArInvoiceTabIndex == 0
                                    ? 'CANCEL INVOICE'
                                    : 'CANCEL MEMO'
                                : 'CANCEL BOL'
                        }
                        handleCancelButtonClick={() => handleCancelAction()}
                        hideAddNewButton={true}
                        exportTypes={
                            selectedScreen === InvoiceScreen.INVOICE &&
                            selectedArInvoiceTabIndex == 0
                                ? exportMenu
                                : selectedScreen === InvoiceScreen.INVOICE &&
                                selectedArInvoiceTabIndex === 0
                                    ? exportMenu
                                    : selectedScreen === InvoiceScreen.INVOICE &&
                                    selectedArInvoiceTabIndex === 1
                                        ? exportMenuCM
                                        : exportMenuBol
                        }
                        handleExportButtonClick={(e: any) =>
                            handleActionExport(e)
                        }
                        handleAddIconClick={() =>
                            handleAddNewItem(selectedTabIndex)
                        }
                    />
                </div>
                <div className="invoices-content">
                    {selectedScreen === InvoiceScreen.INVOICE &&
                    selectedArInvoiceTabIndex === 0 ? (
                        <ARInvoiceGrid
                            onRowSelect={(event) =>
                                handleGridRowSelectBOLandInvoice(event)
                            }
                        />
                    ) : selectedScreen === InvoiceScreen.INVOICE &&
                    selectedArInvoiceTabIndex === 1 ? (
                        <CreditMemoGrid
                            onRowSelect={(event) =>
                                handleGridRowSelectBOLandInvoice(event)
                            }
                        />
                    ) : (
                        <Grid
                            config={gridConfig}
                            height={gridHieght}
                            data={bolInfo}
                            width={'100%'}
                            isFromInvoice={true}
                            onRowSelect={(event) =>
                                handleGridRowSelectBOLandInvoice(event)
                            }
                        />
                    )}

                    <div className="LoadImg">
                        {loaderStatus ? (
                            <img className="loaderClass" src={loader}/>
                        ) : (
                            <></>
                        )}
                    </div>
                </div>
            </div>
        </DashboardLayoutComponent>
    );
};

export default Invoice;
