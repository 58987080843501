import { FC } from "react";
import { ReactComponent as Delete } from "../../../icons/deleteNew.svg";
import { ReactComponent as Edit } from "../../../icons/editNew.svg";
import { ReactComponent as Add } from "../../../icons/AddNew.svg";
import { ButtonComponent } from "@syncfusion/ej2-react-buttons";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";

interface IAttachmentTabControls {
  handleAddAttachment: () => void;
  handleEditAttachment: () => void;
  handleDeleteAttachment: () => void;
  disableControls?: boolean;
  selectedArInvoiceTabIndex?: number;
  hideEditButton?: boolean;
}
function paramsToObject(entries: any) {
  const result: any = {};
  for (const [key, value] of entries) {
    result[key] = value;
  }
  return result;
}
const AttachmentTabControls: FC<IAttachmentTabControls> = ({
  handleAddAttachment,
  handleEditAttachment,
  handleDeleteAttachment,
  disableControls,
  selectedArInvoiceTabIndex,
  hideEditButton
}) => {
  // console.log("selectedArInvoiceTabIndex", selectedArInvoiceTabIndex);
  const history = useHistory();
  const urlParams = new URLSearchParams(history.location.search);
  const paramEntries = urlParams.entries();
  const params = paramsToObject(paramEntries);
  const selectedInvoiceScreen = useSelector(
    (store: RootState) => store.invoiceReducer.selectedInvoiceScreen
  );
  const bolAttachedTabIndex = useSelector(
    (store: RootState) => store.invoiceReducer.bolAttachmentIconsStatus
  );
  console.log("bolAttachedTabIndex", bolAttachedTabIndex);
  return (
    <div className="attachement-tab-controls">
      {!disableControls && (
        <ButtonComponent
          disabled={disableControls}
          cssClass="attachmentsbuttons"
          onClick={handleDeleteAttachment}
          style={{ marginRight: 5, padding: 0 }}
        >
          <Delete width={26} height={26} />
        </ButtonComponent>
      )}

      {selectedArInvoiceTabIndex !== 0 && !disableControls && !hideEditButton? (
        <ButtonComponent
          disabled={disableControls}
          cssClass="attachmentsbuttons"
          onClick={handleEditAttachment}
          style={{ marginRight: 5, padding: 0 }}
        >
          <Edit width={26} height={26} />
        </ButtonComponent>
      ) : (
        ""
      )}
      {!disableControls && (
        <ButtonComponent
          onClick={handleAddAttachment}
          cssClass="attachmentsbuttons"
          style={{ padding: 0 }}
          disabled={disableControls}
        >
          <Add width={26} height={26} />
        </ButtonComponent>
      )}
    </div>
  );
};

export default AttachmentTabControls;
