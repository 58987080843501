import Grid from "../../grid/Grid";
import React, { useEffect, useState } from "react";
import { ReactComponent as Add } from "../../../icons/AddNew.svg";
import { ButtonComponent } from "@syncfusion/ej2-react-buttons";
import AddSettingsScreen from "../addScreen/addScreen";
import { useDispatch, useSelector } from "react-redux";
import {
  setcasualDataClientGroupsList,
  setCasualDataList,
  setcasualDataReclaimCentersList,
  setcasualDataStoresList,
  setcasualDataUpcList,
  setcasualDataVendorsList,
} from "../settingsReducers";
import {
  casualDataAddPopupEndPoints,
  casualDataHeaders,
  generateGridData,
} from "../utils";
import axios from "axios";
import { RootState } from "../../../redux/store";
import CasualDataPreview from "./casualDataPreview";
import notify from "../../../services/toasterService";
import loader from "../../../assets/loader.gif";
// import "../Settings/forms.scss";
let gridHieght = "calc(100vh - 400px)";

const CasualDataSettings = () => {
  const [loaderStatus, setLoaderStatus] = useState(true)
  const [showScreen, setShowScreen] = useState("list");

  const [reclaimCentersList, setRecliamCentersList] = useState<any>([]);
  let [vendorsList, setVendorsList] = useState<any>([]);
  let [storesList, setStoresList] = useState<any>([]);
  let [upcsList, setUpcsList] = useState<any>([]);
  const [clientGroupsList, setClientGroupsList] = useState<any>([]);

  const dispatch = useDispatch();

  const [addScreen, setAddScreen] = useState(false);
  const [addScreenTabName, setAddScreenTabName] = useState(null);

  let TabData: any = [];
  let selectedTab: string;

  useEffect(() => {
    selectedTab = "Casual Data";
    casualDataTabAPi(selectedTab);
    setLoaderStatus(true)
  }, []);

  const savedCasualDataList = useSelector(
    (state: RootState) => state.settingsReducer?.casualDataList
  );

  const changeScreen = (e: any) => {
    setShowScreen("add");
  };

  const goToBackScreen = (e: any) => {
    setShowScreen("list");
  };

  const handleName = (e: any) => {};
  const handleDateRange = (e: any) => {};
  let [casualDataList, setcasualDataList] = useState<any>([]);
  const casualDataTabAPi = async (TabName: any) => {
    if (TabName === "Casual Data") {
      const endPointURL = casualDataAddPopupEndPoints(TabName, 0, 0);
      await axios.get(endPointURL).then((res) => {
        setLoaderStatus(false)
        casualDataList = res.data;
        dispatch(setCasualDataList(casualDataList));
      });
      // dispatch(setCasualDataList(casualDataSettingsData));
    }
  };

  const handlePopupActionCasualData = (selectedTabName: any) => {
    setAddScreen(true);
    setAddScreenTabName(selectedTabName);

    // const endPointURL = messageManagemetnSettingsEndPoint(TabName);
    // await axios.get(endPointURL).then((res) => {
    //     if (TabName === "Casual Data") {
    //         console.log(res.data)
    //         dispatch(setReclaimScanMsgData(res.data));

    //     } else if (TabName === "Mobile") {
    //         dispatch(setMobileMsgData(res.data));
    //     }
    //   //  getLanguages('Languages');
    //     TabData = res.data;
    // }).catch((err) => {
    //     notify('Failed to fetch data', 'error');
    // });
  };

  const selectedRowData = useSelector(
    (state: RootState) => state.gridReducer.selectedRowData
  );

  const getSavedCasualDataById = (id: any) => {};

  const popupMasterData = async (listname: any) => {
    //  vendorsList.push(VendorList);
    //  storesList.push(StoresList);
    //  upcsList.push(UpcList);

    const endPointUrl = casualDataAddPopupEndPoints(listname, 1, 500);
    await axios
      .get(endPointUrl)
      .then((resp: any) => {
        if (listname === "ReclaimCenters") {
          reclaimCentersList.push(resp.data);
          dispatch(setcasualDataReclaimCentersList(resp.data));
        } else if (listname === "Vendors") {
          // const vlist = resp['data']['content']
          vendorsList.push(resp.data.content);
          //  vendorsList = VendorList
          dispatch(setcasualDataVendorsList(resp.data.content));
        } else if (listname === "Stores") {
          storesList.push(resp.data.content);
          // storesList = StoresList
          dispatch(setcasualDataStoresList(resp.data.content));
        } else if (listname === "ClientGroups") {
          clientGroupsList.push(resp.data);
          dispatch(setcasualDataClientGroupsList(resp.data));
        } else if (listname === "UPCs") {
          upcsList.push(resp.data.content);
          // upcsList = UpcList
          dispatch(setcasualDataUpcList(resp.data.content));
        } else {
        }

        console.log(resp);
      })
      .catch((err) => {
        console.log(err);
        notify(
          "Error! Unable to fetch data. Please try after sometime.",
          "error"
        );
      });
  };

  return (
    <div>
      {addScreen && (
        <AddSettingsScreen
          addScreen={addScreen}
          setAddScreen={setAddScreen}
          tabName={addScreenTabName}
        />
      )}

      {selectedRowData && selectedRowData.length > 0 ? (
        <div className="col-sm-12 col-md-12 col-lg-12 child-data-section ">
          <CasualDataPreview previewData={selectedRowData} />
        </div>
      ) : (
        <div className="col-sm-12 col-md-12 col-lg-12">
          <div className="row mt-5">
            <p className="col-sm-11 col-md-11 col-lg-11 settings-tab-title fw-bold pb-0">
              Causal Data
            </p>
            <div className="col-sm-1 col-md-1 col-lg-1 pr-5">
              <ButtonComponent
                className="iconCopy float-right ml-1"
                onClick={() => handlePopupActionCasualData("Casual Data")}
              >
                <Add />
              </ButtonComponent>
            </div>
          </div>
          <div className="col-sm-12 col-md-12 col-lg-12 pl-2">
            <div className="control-pane">
              <div className="control-section tab-control-section">
                <div className="col-sm-12 col-md-12 col-lg-12 ">
                  <div className="col-sm-12 col-md-12 col-lg-12 pl-0 pr-4 child-data-section mt-0 ">
                    <Grid
                      config={casualDataHeaders}
                      data={generateGridData(
                        "Casual Data",
                        savedCasualDataList
                      )}
                      width={"100%"}
                      height={gridHieght}
                    />
                     <div className="LoadImg">
                {loaderStatus ? (
        <img className="loaderClass" src={loader} />
      ) : <></>}
      </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* </div>  :
                    <div className="col-sm-12 col-md-12 col-lg-12" >
                    <div className="row settingsTitleRow mt-5 ml-3">
                      <p className="col-sm-11 col-md-11 col-lg-11 child-tab-title pb-0" >Quaker Oats 21</p>
                        <div className="col-sm-1 col-md-1 col-lg-1">
                        <ButtonComponent cssClass='e-outline opt-btn-dark float-right ml-1 pt-1' >
                                  <img src={addIcon} alt="Add" onClick={(e) => changeScreen(e)} />
                        </ButtonComponent>
                        </div>
                      </div>
                      <div className="row m-0">
                      <span className="float-left ml-4" onClick={(e) => goToBackScreen(e)}> <img className="ml-2" src={leftArrow} alt="Add"  />Back to all causal data rules</span><br></br>
                        <div className="col-sm-2 col-md-2 col-lg-2 ">
                          <span className="form-input-label float-left">Name</span>
                            <input className="form-control"  name="name" placeholder="Enter Name"
                                onChange={e => handleName(e)}/>
                        </div>
                        <div className="col-sm-2 col-md-2 col-lg-2 ">
                          <span className="form-input-label  float-left">Date Range</span>
                            <input className="form-control"  name="daterange" placeholder="Enter Date Range"
                                onChange={e => handleDateRange(e)}/>
                        </div>
                      </div>
                      </div> } */}
        </div>
      )}
    </div>
  );
};

export default CasualDataSettings;
