import React from "react";
import closeDialog from "../../../icons/common/close-dialog.png";
const ConfirmBox = (props:any) => {
  const {
        yes,
        no,
        visible,
        onCancel,
        onConfirm,
        className,
        children
      } = props;
  const confirm = () => {
    onCancel();
    onConfirm();
  };
  return (
    <>
      {visible && (
        <div className="modal_container">
          <div className="bg_modal" />
          <div className="inner_modal">
            <div className="modal_body">{children}</div>
            <div className="modal_foot">
              <button onClick={() => onCancel()} type="button">
                {yes}
              </button>
              <button onClick={confirm} type="button">
                {no}
              </button>
            </div>
            <button
              onClick={() => onCancel()}
              type="button"
              className="btn_close"
            > <img src={closeDialog} alt="Close" />
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default ConfirmBox;
