import { ButtonComponent } from "@syncfusion/ej2-react-buttons";
import { DateRangePickerComponent } from "@syncfusion/ej2-react-calendars";
import axios from "axios";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import {
  setcasualDataClientGroupsList,
  setCasualDataList,
  setcasualDataReclaimCentersList,
  setcasualDataStoresList,
  setcasualDataUpcList,
  setcasualDataVendorsList,
  setSelClientGroupsList,
  setselectedRcList,
  setSelStoresList,
  setSelUpcsList,
  setSelVendorsList,
} from "../settingsReducers";
import {
  addCasualData,
  casualDataAddPopupEndPoints,
  formatDate,
} from "../utils";
// import { StoresList, VendorList, UpcList } from "./casualDataSettingsData";
import editIcon from "../../../assets/EditRuleExcep.png";
import closeIcon from "../../../assets/closeIcon.png";
import notify from "../../../services/toasterService";

const AddCasualDataRule = (props: any) => {
  const { setAddScreen, setTabName } = props;

  let [stepperindexvalue, setStepperIndexValue] = useState(1);
  useEffect(() => {
    popupMasterData("ReclaimCenters");
    popupMasterData("ClientGroups");
    popupMasterData("Vendors");
    popupMasterData("Stores");
    popupMasterData("UPCs");
  }, []);

  const dispatch = useDispatch();
  let [showScreen, setShowScreen] = useState("Rule");

  const [reclaimCentersList, setRecliamCentersList] = useState<any>([]);
  let [vendorsList, setVendorsList] = useState<any>([]);
  let [storesList, setStoresList] = useState<any>([]);
  let [upcsList, setUpcsList] = useState<any>([]);
  const [clientGroupsList, setClientGroupsList] = useState<any>([]);

  const [seqId, setSeqId] = useState(0);
  const [type, setType] = useState("");
  const [answer, setAnswer] = useState("");
  const [question, setQuestion] = useState("");
  const [msgDisabled, setMsgDisabled] = useState(true);

  const [ruleCode, setRuleCode] = useState("");
  const [dateRange, setDateRange] = useState("");

  const [mySequence, setMySequence] = useState("");
  const [myTypeSelect, setMyTypeSelect] = useState("");
  const [myQuestion, setMyQuestion] = useState("");
  const [myAnswer, setMyAnswer] = useState("");

  let [answersList, setAnswersList] = useState<any>([]);
  let [selectedReclaimCenters, setSelectedReclaimCenters] = useState<any>([]);
  let [selectedVendors, setSelectedVendors] = useState<any>([]);
  let [selectedStores, setSelectedStores] = useState<any>([]);
  let [selectedClientGroups, setSelectedClientGroups] = useState<any>([]);
  let [selectedUpcs, setSelectedUpcs] = useState<any>([]);

  //  let selectedReclaimCenters: any[] =[];
  // let selectedVendors: any[] =[];
//  let selectedStores: any[] = [];
 // let selectedClientGroups: any[] = [];
 // let selectedUpcs: any[] = [];

  // let [selRcList: any[] = []
  // let selVendorList: any[] =[]

  const handlePopUpClose = () => {
    setAddScreen(false);
    notify("Information! Changes discarded.", "info");
  };

  const reclaimCentersDataList = useSelector(
    (state: RootState) => state.settingsReducer?.casualDataReclaimCentersList
  );

  const selectedRclistData = useSelector(
    (state: RootState) => state.settingsReducer?.selectedRcList
  );
  const selectedVendorsListData = useSelector(
    (state: RootState) => state.settingsReducer?.selVendorsList
  );
  const selectedUpcsListData = useSelector(
    (state: RootState) => state.settingsReducer?.selUpcsList
  );
  const selectedClientGroupsListData = useSelector(
    (state: RootState) => state.settingsReducer?.selClientGroupList
  );
  const selectedStoresListData = useSelector(
    (state: RootState) => state.settingsReducer?.selStoresList
  );

  const storesDataList = useSelector(
    (state: RootState) => state.settingsReducer?.casualDataStoresList
  );

  const clientGroupsDataList = useSelector(
    (state: RootState) => state.settingsReducer?.casualDataClientGroupsList
  );

  const upcsDataList = useSelector(
    (state: RootState) => state.settingsReducer?.casualDataUpcList
  );

  const vendorsDataList = useSelector(
    (state: RootState) => state.settingsReducer?.casualDataVendorsList
  );

  /* get Reclaim Centers Api call */
  const popupMasterData = async (listname: any) => {
    //  vendorsList.push(VendorList);
    //  storesList.push(StoresList);
    //  upcsList.push(UpcList);

    const endPointUrl = casualDataAddPopupEndPoints(listname, 1, 200);
    await axios
      .get(endPointUrl)
      .then((resp: any) => {
        if (listname === "ReclaimCenters") {
          reclaimCentersList.push(resp.data);
          dispatch(setcasualDataReclaimCentersList(resp.data));
        } else if (listname === "Vendors") {
          // const vlist = resp['data']['content']
          vendorsList.push(resp.data.content);
          //  vendorsList = VendorList
          dispatch(setcasualDataVendorsList(resp.data.content));
        } else if (listname === "Stores") {
          storesList.push(resp.data.content);
          // storesList = StoresList
          dispatch(setcasualDataStoresList(resp.data.content));
        } else if (listname === "ClientGroups") {
          clientGroupsList.push(resp.data);
          dispatch(setcasualDataClientGroupsList(resp.data));
        } else if (listname === "UPCs") {
          upcsList.push(resp.data.content);
          // upcsList = UpcList
          dispatch(setcasualDataUpcList(resp.data.content));
        } else {
        }
      })
      .catch((err) => err);
  };

  const handleRuleCode = (e: any) => {
    setRuleCode(e.target.value);
  };

  const handleDateRange = (e: any) => {
    setDateRange(e.target.value);
  };

  const handleReclaimCenterChange = (e: any) => {
    const addReclimCenters = JSON.parse(e.target.value);
    const CTInfo = [...selectedReclaimCenters];
    if (
      !CTInfo.some(
        (rc) => rc.reclaimcenterName === addReclimCenters.reclaimcenterName
      )
    ) {
      CTInfo.push(addReclimCenters);
      setSelectedReclaimCenters(CTInfo);
    }
    // selectedReclaimCenters.push(JSON.parse(e.target.value))
 //   dispatch(setselectedRcList(selectedReclaimCenters));
    // setSelectedReclaimCenters(e.target.value);
    //dispatch(setSelVendorsList(selectedVendors));
    var ddlSelectCustType = document.getElementById(
      "ReclaimId"
    ) as HTMLSelectElement;
    if (ddlSelectCustType) {
      ddlSelectCustType.selectedIndex = 0;
    }
  };

  const handleVendorChange = (e: any) => {
    //     console.log(e.target.value)
    const addVendorsList = JSON.parse(e.target.value);
    const CTInfo = [...selectedVendors];
    if (!CTInfo.some((rc) => rc.apVendorName === addVendorsList.apVendorName)) {
      CTInfo.push(addVendorsList);
      setSelectedVendors(CTInfo);
    }
    // venderId

    // selectedVendors.push(JSON.parse(e.target.value));
  //  dispatch(setSelVendorsList(selectedVendors));
    var ddlSelectCustType = document.getElementById(
      "venderId"
    ) as HTMLSelectElement;
    if (ddlSelectCustType) {
      ddlSelectCustType.selectedIndex = 0;
    }
  };

  const handleUPCChange = (e: any) => {
    const addupcList = JSON.parse(e.target.value);
    const CTInfo = [...selectedUpcs];
    if (!CTInfo.some((rc) => rc.upc === addupcList.upc)) {
      CTInfo.push(addupcList);
      setSelectedUpcs(CTInfo);
    }

    // selectedUpcs.push(JSON.parse(e.target.value));
    // dispatch(setSelUpcsList(selectedUpcs));
    var ddlSelectCustType = document.getElementById(
      "upcId"
    ) as HTMLSelectElement;
    if (ddlSelectCustType) {
      ddlSelectCustType.selectedIndex = 0;
    }
  };

  const handleClientGroupChange = (e: any) => {
    const addClientGroups = JSON.parse(e.target.value);
    const CTInfo = [...selectedClientGroups];
    if (!CTInfo.some((rc) => rc.clientGroupName === addClientGroups.clientGroupName)) {
      CTInfo.push(addClientGroups);
      setSelectedClientGroups(CTInfo);
    }

  //  selectedClientGroups.push(JSON.parse(e.target.value));
   // dispatch(setSelClientGroupsList(selectedClientGroups));
     var ddlSelectCustType = document.getElementById("clientId") as HTMLSelectElement;
    if (ddlSelectCustType) {
        ddlSelectCustType.selectedIndex = 0;
    }
  };

  const handleStoreChange = (e: any) => {
    const addStores = JSON.parse(e.target.value);
    const CTInfo = [...selectedStores];
    if (!CTInfo.some((rc) => rc.storeName === addStores.storeName)) {
      CTInfo.push(addStores);
      setSelectedStores(CTInfo);
    }
    // selectedStores.push(JSON.parse(e.target.value));
    // dispatch(setSelStoresList(selectedStores));
     var ddlSelectCustType = document.getElementById("storeId") as HTMLSelectElement;
    if (ddlSelectCustType) {
        ddlSelectCustType.selectedIndex = 0;
    }
  };


  const goToNextStep = (index: number) => {
    if (index === 1) {
      setStepperIndexValue(1);
    } else if (index === 2) {
      setStepperIndexValue(2);
    }
  };

  const handleNext = () => {
    setShowScreen("Add");
  };
  const handleCancel = () => {};
  const handleSeqId = (e: any) => {
    setSeqId(e.target.value);
    validate();
  };

  const handleEditDataRules = () => {
    setShowScreen("Rule");
  };

  const handleType = (e: any) => {
    setType(e.target.value);
    validate();
  };

  const handleQuestion = (e: any) => {
    setQuestion(e.target.value);
    validate();
  };

  const handleAnswer = (e: any) => {
    console.log(e.keyCode);

    setAnswer(e.target.value);
    validate();
    if (e.keyCode === 13) {
      const obj = [];
      const addAnswersList = e.target.value;
      const CTInfo = [...answersList];
      if (!CTInfo.some((rc) => rc === addAnswersList)) {
        CTInfo.push(addAnswersList);
        setAnswersList(CTInfo);
      }
      // obj.push(e.target.value);
      // setAnswersList(obj);
    }
  };
  const [errors, setErrors] = useState({
    seqIdError: "",
    typeError: "",
    questionError: "",
    answerError: "",
  });

  const validate = (field?: any, value?: any) => {
    let seqIdError = "";
    let typeError = "";
    let questionError = "";
    let answerError = "";

    setErrors({
      seqIdError: "",
      typeError: "",
      questionError: "",
      answerError: "",
    });

    if (seqId === 0) {
      seqIdError = "Seq Id is required";
      setMySequence("1px solid red");
    } else {
      setMySequence("");
    }
    if (type === "") {
      typeError = "Type  is required";
      setMyTypeSelect("1px solid red");
    } else {
      setMyTypeSelect("");
    }
    if (question === "") {
      questionError = "Question is required";
      setMyQuestion("1px solid red");
    } else {
      setMyQuestion("");
    }

    if (answer === "") {
      answerError = "Answer is required";
      setMyAnswer("1px solid red");
    } else {
      setMyAnswer("");
    }
    if (seqId || type || question || answer) {
      setErrors({ seqIdError, typeError, questionError, answerError });
      setMsgDisabled(true);
      return false;
    } else {
      setMsgDisabled(false);
    }
    return true;
  };

  const handleAddMsg = () => {};

  const removeLinkedRCInDefineRule = (value: any) => {
    let linkedRCData = [...selectedReclaimCenters];
    let updatedRCData = linkedRCData.filter((item) => item.id !== value);
    setSelectedReclaimCenters(updatedRCData);
  };

  const removeLinkedVendorInDefineRule = (value: any) => {
    let linkedRCData = [...selectedVendors];
    let updatedRCData = linkedRCData.filter((item) => item.id !== value);
    setSelectedVendors(updatedRCData);
  };

  const removeLinkedUPCInDefineRule = (value: any) => {
    let linkedRCData = [...selectedUpcs];
    let updatedRCData = linkedRCData.filter((item) => item.id !== value);
    setSelectedUpcs(updatedRCData);
  };

  const removeLinkedClientGroupRule = (value: any) => {
    let linkedRCData = [...selectedClientGroups];
    let updatedRCData = linkedRCData.filter((item) => item.id !== value);
    setSelectedClientGroups(updatedRCData);
  };

  const removeLinkedClientStoreRule = (value: any) => {
    let linkedRCData = [...selectedStores];
    let updatedRCData = linkedRCData.filter((item) => item.id !== value);
    setSelectedStores(updatedRCData);
  };



  const handleAddQuestion = () => {
    setStepperIndexValue(2);
  };
  const handleRemoveAnswer = (idx: number) => {
    console.log(idx);
    answersList.splice(idx, 1);
    setAnswersList(answersList);
  };

  const saveCasualDataRule = async () => {
    const qsnObj = {
      sequence: seqId,
      question: question,
      type: type,
      answers: [answer],
    };
    const questionSet = [];

    const rcIdsList: any[] = [];
    const storeiDsList: any[] = [];
    const UpcIdList: any[] = [];
    const vendorIdList: any[] = [];
    const clientGrpIdList: any[] = [];
    questionSet.push(qsnObj);
    selectedReclaimCenters?.map((rcItem: any) => {
      rcIdsList.push(rcItem.id);
    });
    selectedClientGroups?.map((clientGrp: any) => {
      clientGrpIdList.push(clientGrp.clientGroupNumber);
    });
    selectedStores?.map((store: any) => {
      storeiDsList.push(store.id);
    });
    selectedUpcs?.map((upc: any) => {
      UpcIdList.push(upc.id);
    });
    selectedVendors?.map((vendor: any) => {
      vendorIdList.push(vendor.id);
    });

    const reqBody = {
      clientGroup: clientGrpIdList,
      reclaimCenterIds: rcIdsList,
      storeIds: storeiDsList,
      upcIds: UpcIdList,
      vendorIds: vendorIdList,
      ruleCode: ruleCode,
      startDate: formatDate(dateRange[0]),
      endDate: formatDate(dateRange[1]),
      questionSet: questionSet,
    };
   console.log(reqBody)
    const body = reqBody;
    const endPointURL = addCasualData();
    axios.post(endPointURL, body).then(
      async (res: any) => {
        console.log(res);
        setAddScreen(false);
        notify("Causal Data added successfully", "success");
        handlePopUpClose();
        const endPointURL = casualDataAddPopupEndPoints("Casual Data", 0, 0);
        await axios.get(endPointURL).then(
          (res) => {
            const casualDataList = res.data;
            dispatch(setCasualDataList(casualDataList));
          },
          (err) => {
            notify("Failed to fetch data", "error");
          }
        );
      },
      (err) => {
        notify("Failed to fetch data", "error");
      }
    );
  };

  const handleAddNextQuestion = () => {};

  /* check input format function */
  const formatInput = (e: any) => {
    // Prevent characters that are not numbers ("e", ".", "+" & "-") ✨
    let checkIfNum;
    if (e.key !== undefined) {
      // Check if it's a "e", ".", "+" or "-"
      checkIfNum =
        e.key === "e" || e.key === "." || e.key === "+" || e.key === "-";
    } else if (e.keyCode !== undefined) {
      // Check if it's a "e" (69), "." (190), "+" (187) or "-" (189)
      checkIfNum =
        e.keyCode === 69 ||
        e.keyCode === 190 ||
        e.keyCode === 187 ||
        e.keyCode === 189;
    }
    return checkIfNum && e.preventDefault();
  };

  return (
    <div className="Qtn-popup">
      <div className="StepperControl mb-5">
        <div className="col-6 pl-2 pt-0 mt-auto mb-auto">
          <div className="page-header--col col--steps">
            <ul className="list--steps pt-3 mb-0 pointer">
              <li
                className={` ${stepperindexvalue === 1 ? "active" : ""}`}
                onClick={(e) => goToNextStep(1)}
              >
                <strong className="steps--number">1</strong>
                <p className="steps--label">RULE CRITERIA</p>
              </li>
              <li
                className={` ${stepperindexvalue === 2 ? "active" : ""}`}
                onClick={(e) => goToNextStep(2)}
              >
                <strong className="steps--number">2</strong>
                <p className="steps--label">QUESTION SET</p>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div>
        {stepperindexvalue === 1 ? (
          <div>
            {showScreen === "Rule" ? (
              <div>
                <div className="row m-0">
                  <div className="col-sm-4 col-md-4 col-lg-4">
                    <span className="form-input-settings-label float-left fw-bold">
                      Code <b className="text-danger">*Required</b>
                    </span>
                    <input
                      className="form-control"
                      name="name"
                      placeholder="Name of Rule"
                      onChange={(e) => handleRuleCode(e)}
                    />
                  </div>
                  <div className="col-sm-4 col-md-4 col-lg-4 ">
                    <span className="form-input-settings-label float-left fw-bold">
                      Date Range <b className="text-danger">*Required</b>
                    </span>
                    {/* <input className="form-control"  name="daterange" placeholder="Enter Date Range" type="date"
                                        onChange={e => handleDateRange(e)}/> */}
                    <div>
                      <DateRangePickerComponent
                        id="daterangepicker"
                        onChange={(e: any) => handleDateRange(e)}
                      />
                    </div>
                  </div>
                </div>
                <hr className="previewScreenDevider"></hr>

                <div className="row m-0">
                  <div className="col-sm-4 col-md-4 col-lg-4 mb-4">
                    <span className="form-input-settings-label float-left fw-bold">
                      Reclaim Center(s) <b className="text-danger">*Required</b>
                    </span>
                    <select
                      className="form-control"
                      id="ReclaimId"
                      placeholder="Search Reclaim Center"
                      onChange={(e: any) => handleReclaimCenterChange(e)}
                    >
                      <option value="">Search Reclaim Centers</option>
                      {reclaimCentersList[0]?.map((item: any) => (
                        <option value={JSON.stringify(item)}>
                          {item?.reclaimcenterName}
                        </option>
                      ))}
                    </select>
                    <div className="selectedListShowDiv selectedListShowDivv mt-3">
                      {selectedReclaimCenters && selectedReclaimCenters.length > 0 &&
                        selectedReclaimCenters?.map((item: any, i: number) => (
                          <div className="row m-2 selectedRow">
                            <p className="col-sm-10 col-md-10 col-lg-10 pt-1 pb-1 pl-1 pr-0 mb-0 selectedtem">
                              {item.reclaimcenterName}
                            </p>
                            <img
                              src={closeIcon}
                              alt="edit "
                              width="14"
                              height="14"
                              className="float-right m-3"
                              onClick={() => removeLinkedRCInDefineRule(item.id)
                              }
                            />
                          </div>
                        ))}
                    </div>
                  </div>
                  <div className="col-sm-4 col-md-4 col-lg-4 mb-4">
                    <span className="form-input-settings-label float-left fw-bold">
                      Vendor(s) <b className="text-danger">*Required</b>
                    </span>
                    <select
                      className="form-control"
                      id="venderId"
                      onChange={(e: any) => handleVendorChange(e)}
                    >
                      <option value="">Search Vendors</option>
                      {vendorsList[0]?.map((item: any) => (
                        <option value={JSON.stringify(item)}>
                          {item?.apVendorName}
                        </option>
                      ))}
                    </select>
                    <div className="selectedListShowDiv selectedListShowDivv mt-3">
                      {selectedVendors && selectedVendors?.length > 0 &&
                        selectedVendors?.map((item: any, i: number) => (
                          <div className="row m-2 selectedRow">
                            <p className="col-sm-10 col-md-10 col-lg-10 pt-1 pb-1 pl-1 pr-0 mb-0 selectedtem">
                              ({item.apVendorNumber}){item.apVendorName}
                            </p>
                            <img
                              src={closeIcon}
                              alt="edit "
                              width="14"
                              height="14"
                              className="float-right m-3"
                              onClick={() =>
                                removeLinkedVendorInDefineRule(item.id)
                              }
                            />
                          </div>
                        ))}
                    </div>
                  </div>
                  <div className="col-sm-4 col-md-4 col-lg-4 mb-4">
                    <span className="form-input-settings-label float-left fw-bold">
                      UPC(s)
                    </span>
                    <select
                      className="form-control"
                      id="upcId"
                      onChange={(e: any) => handleUPCChange(e)}
                    >
                      <option value="">Search UPCs</option>
                      {upcsList[0]?.map((item: any) => (
                        <option value={JSON.stringify(item)}>
                          {item?.upc}
                        </option>
                      ))}
                    </select>
                    <div className="selectedListShowDiv selectedListShowDivv mt-3">
                      {selectedUpcs && selectedUpcs?.length > 0 &&
                        selectedUpcs?.map((item: any, i: number) => (
                          <div className="row m-2 selectedRow">
                            <p className="col-sm-10 col-md-10 col-lg-10 pt-1 pb-1 pl-1 pr-0 mb-0 selectedtem">
                              {item.upc}
                            </p>
                            <img
                              src={closeIcon}
                              alt="edit "
                              width="14"
                              height="14"
                              className="float-right m-3"
                              onClick={() => removeLinkedUPCInDefineRule(item.id)}
                            />
                          </div>
                        ))}
                    </div>
                  </div>
                  <div className="col-sm-4 col-md-4 col-lg-4 mt-4">
                    <span className="form-input-settings-label float-left fw-bold">
                      Client Group(s)
                    </span>
                    <select
                      className="form-control"
                      id="clientId"
                      onChange={(e: any) => handleClientGroupChange(e)}
                    >
                      <option value="">Search Client Groups</option>
                      {clientGroupsList[0]?.map((item: any) => (
                        <option value={JSON.stringify(item)}>
                          {item?.clientGroupName}
                        </option>
                      ))}
                    </select>
                    <div className="selectedListShowDiv selectedListShowDivv mt-3">
                      {selectedClientGroups && selectedClientGroups?.length > 0 &&
                        selectedClientGroups?.map(
                          (item: any, i: number) => (
                            <div className="row m-2 selectedRow">
                              <p className="col-sm-10 col-md-10 col-lg-10 pt-1 pb-1 pl-1 pr-0 mb-0 selectedtem">
                                {item.clientGroupName}
                              </p>
                              <img
                                src={closeIcon}
                                alt="edit "
                                width="14"
                                height="14"
                                className="float-right m-3"
                                onClick={() =>
                                  removeLinkedClientGroupRule(item.id)
                                }
                              />
                            </div>
                          )
                        )}
                    </div>
                  </div>
                  <div className="col-sm-4 col-md-4 col-lg-4 mt-4">
                    <span className="form-input-settings-label float-left fw-bold">
                      Store(s)
                    </span>
                    <select
                      className="form-control"
                      id="storeId"
                      onChange={(e: any) => handleStoreChange(e)}
                    >
                      <option value="">Search Stores</option>
                      {storesList[0]?.map((item: any) => (
                        <option value={JSON.stringify(item)}>
                          {item?.storeName}
                        </option>
                      ))}
                    </select>
                    <div className="selectedListShowDiv selectedListShowDivv mt-3">
                      {selectedStores && selectedStores?.length > 0 &&
                        selectedStores?.map((item: any, i: number) => (
                          <div className="row m-2 selectedRow">
                            <p className="col-sm-10 col-md-10 col-lg-10 pt-1 pb-1 pl-1 pr-0 mb-0 selectedtem">
                              {item.storeName}
                            </p>
                            <img
                              src={closeIcon}
                              alt="edit "
                              width="14"
                              height="14"
                              className="float-right m-3"
                              onClick={() =>
                                removeLinkedClientStoreRule(item.id)
                              }
                            />
                          </div>
                        ))}
                    </div>
                  </div>
                </div>

                <div className="col-sm-12 px-0 my-5">
                  <ButtonComponent
                    cssClass="eg-btn-primary float-right pt-3 pb-3 pl-5 pr-5"
                    style={
                      selectedReclaimCenters?.length > 0 &&
                      selectedVendors?.length > 0
                        ? {
                            opacity: "1",
                            pointerEvents: "all",
                            cursor: "pointer",
                          }
                        : {
                            opacity: "0.5",
                            pointerEvents: "none",
                            backgroundColor: "#e8e8e8",
                            color: "#969696",
                          }
                    }
                    onClick={() => handleNext()}
                  >
                    Next
                  </ButtonComponent>
                  <ButtonComponent
                    cssClass="eg-btn-primary-link e-flat mt-3"
                    onClick={() => handlePopUpClose()}
                  >
                    Cancel
                  </ButtonComponent>
                </div>
              </div>
            ) : (
              <div>
                <div className="row selectedListAddShowDiv">
                  <ButtonComponent
                    cssClass="ruleCriteriaBtn m-3"
                    style={{ backgroundColor: "#f2f2f2" }}
                    onClick={() => handleCancel()}
                  >
                    Rule Criteria Summary
                  </ButtonComponent>
                  <img
                    src={editIcon}
                    alt="edit "
                    width="24"
                    height="24"
                    className="float-right m-3 pointer"
                    onClick={() => handleEditDataRules()}
                  />
                  <div className="table-responsive">
                    <table className="table casualData-table">
                      <thead>
                        {/* <div className="col-12"> */}
                        <tr className="m-0">
                          <th>
                            <span className=" p-1 fw-bold text-underline">
                              Reclaim Center(s)
                            </span>
                          </th>
                          <th>
                            <span className="p-1 fw-bold text-underline">
                              Vendor(s)
                            </span>
                          </th>
                          <th>
                            <span className="p-1 fw-bold text-underline">
                              UPC(s)
                            </span>
                          </th>
                          <th>
                            <span className="p-1 fw-bold text-underline">
                              Client Group(s)
                            </span>
                          </th>
                          <th>
                            <span className="p-1 p-4 fw-bold text-underline">
                              Store(s)
                            </span>
                          </th>
                        </tr>
                      </thead>

                      {/* <div className="row m-1"> */}
                      <tbody>
                        <tr>
                          <td>
                            {selectedReclaimCenters?.length > 0 &&
                              selectedReclaimCenters?.map(
                                (item: any, i: number) => (
                                  <div>
                                    <p className="col-sm-10 col-md-10 col-lg-10 pt-1 pb-1 pl-1 pr-0 mb-0 selectedtem">
                                      {item.reclaimcenterName}
                                    </p>
                                  </div>
                                )
                              )}
                          </td>
                          <td>
                            {selectedVendors?.length > 0 &&
                              selectedVendors?.map(
                                (item: any, i: number) => (
                                  <div>
                                    <p className="col-sm-10 col-md-10 col-lg-10 pt-1 pb-1 pl-1 pr-0 mb-0 selectedtem">
                                      ({item.ircVendorNumber})
                                      {item.apVendorName}
                                    </p>
                                  </div>
                                )
                              )}
                          </td>
                          <td>
                            {selectedUpcs?.length > 0 &&
                              selectedUpcs?.map(
                                (item: any, i: number) => (
                                  <div>
                                    <p className="col-sm-10 col-md-10 col-lg-10 pt-1 pb-1 pl-1 pr-0 mb-0 selectedtem">
                                      {item.upc}
                                    </p>
                                  </div>
                                )
                              )}
                          </td>
                          <td>
                            {selectedClientGroups?.length > 0 &&
                              selectedClientGroups?.map(
                                (item: any, i: number) => (
                                  <div>
                                    <p className="col-sm-10 col-md-10 col-lg-10 pt-1 pb-1 pl-1 pr-0 mb-0 selectedtem">
                                      {item.clientGroupName}
                                    </p>
                                  </div>
                                )
                              )}
                          </td>
                          <td>
                            {selectedStores?.length > 0 &&
                              selectedStores?.map(
                                (item: any, i: number) => (
                                  <div>
                                    <p className="col-sm-10 col-md-10 col-lg-10 pt-1 pb-1 pl-1 pr-0 mb-0 selectedtem">
                                      {item.storeName}
                                    </p>
                                  </div>
                                )
                              )}
                          </td>

                          {/* {selectedStoresListData.length > 0 && selectedStoresListData?.map((item: any) => <td className="col-sm-12 col-md-12 col-lg-12 px-3 py-3 mb-0 selectedtem">{item?.storeName}
                                        </td> )}
                                        {selectedClientGroupsListData.length > 0 && selectedClientGroupsListData?.map((item: any) => <td className="col-sm-12 col-md-12 col-lg-12 px-3 py-3 mb-0 selectedtem">{item?.clientGroupName}
                                        </td> )}
                                        {selectedUpcsListData.length > 0 && selectedUpcsListData?.map((item: any) => <td className="col-sm-12 col-md-12 col-lg-12 px-3 py-3 mb-0 selectedtem">{item?.upc}
                                        </td> )} */}
                        </tr>
                        {/* </div> */}
                      </tbody>
                    </table>
                  </div>
                  <div className="col-sm-12 px-0 my-5">
                    <ButtonComponent
                      cssClass="addQnsBtn float-right mr-4"
                      onClick={() => handleAddQuestion()}
                    >
                      Add Question
                    </ButtonComponent>
                  </div>
                  <div className="col-sm=12 px-0 my-5">
                    <ButtonComponent
                      cssClass="eg-btn-primary float-right mr-2"
                      style={
                        answer !== "" &&
                        seqId !== 0 &&
                        question !== "" &&
                        answersList.length > 0
                          ? {
                              opacity: "1",
                              pointerEvents: "all",
                              cursor: "pointer",
                            }
                          : {
                              opacity: "0.5",
                              pointerEvents: "none",
                              backgroundColor: "#f2f2f2",
                            }
                      }
                      onClick={() => handleAddNextQuestion()}
                    >
                      Add Rule
                    </ButtonComponent>
                    <ButtonComponent
                      cssClass="addQnsBtn float-right mr-3"
                      onClick={() => handleEditDataRules()}
                    >
                      Back
                    </ButtonComponent>
                    <ButtonComponent
                      cssClass="eg-btn-primary-link e-flat mt-3 pointer"
                      onClick={() => handlePopUpClose()}
                    >
                      Cancel
                    </ButtonComponent>
                  </div>
                </div>
              </div>
            )}
          </div>
        ) : (
          <div>
            <div className="col-sm-12 row m-0 mt-2">
              <div className="col-sm-6">
                <div className="col-sm-12 mt-3">
                  <label className="label-title">Sequence</label>
                  <input
                    className=" formcontrol"
                    type="number"
                    onKeyDown={formatInput}
                    onChange={(e) => handleSeqId(e)}
                    onBlur={(e) => handleSeqId(e)}
                    onKeyUp={(e) => handleSeqId(e)}
                    style={{ border: mySequence }}
                  />
                  {seqId === 0 ? (
                    <span className="text-danger">{errors.seqIdError}</span>
                  ) : (
                    ""
                  )}
                </div>
                <div className="col-sm-12 mt-3">
                  <label className="label-title"> Type</label>
                  <select
                    className="formcontrol"
                    onChange={(e: any) => handleType(e)}
                    onBlur={(e) => handleType(e)}
                    onKeyUp={(e) => handleType(e)}
                    style={{ border: myTypeSelect }}
                  >
                    <option value="">Select</option>
                    <option value="MULTI-SELECT">MULTI-SELECT</option>
                    <option value="SINGLE-SELECT">SINGLE-SELECT</option>
                  </select>
                  {type === "" ? (
                    <span className="text-danger">{errors.typeError}</span>
                  ) : (
                    ""
                  )}
                </div>
                <div className="col-sm-12 mt-3">
                  <label className="label-title">Question</label>
                  <textarea
                    rows={5}
                    className="formDesc"
                    onChange={(e) => handleQuestion(e)}
                    onKeyUp={(e) => handleQuestion(e)}
                    onBlur={(e) => handleQuestion(e)}
                    style={{ border: myQuestion }}
                  />
                  {question === "" ? (
                    <span className="text-danger">{errors.questionError}</span>
                  ) : (
                    ""
                  )}
                </div>
              </div>

              <div className="col-sm-6">
                <div className="col-sm-12 mt-3">
                  <label className="label-title">Answers</label>
                  <input
                    className=" formcontrol"
                    type="text"
                    value={answer}
                    onChange={(e) => handleAnswer(e)}
                    onKeyUp={(e) => handleAnswer(e)}
                    onBlur={(e) => handleAnswer(e)}
                    style={{ border: myAnswer }}
                  />
                  {answer === "" ? (
                    <span className="text-danger">{errors.answerError}</span>
                  ) : (
                    ""
                  )}
                </div>
                <div className="col-sm-12 mt-3">
                  {answersList.length > 0 &&
                    answersList.map((item: any, i: number) => (
                      <div className="row m-2 selectedRow">
                        <p className="col-sm-10 col-md-10 col-lg-10 pt-1 pb-1 pl-1 pr-0 mb-0 selectedtem font-14">
                          {item}
                        </p>
                        <img
                          src={closeIcon}
                          alt="edit "
                          width="14"
                          height="14"
                          className="float-right m-3"
                          onClick={() => handleRemoveAnswer(i)}
                        />
                      </div>
                    ))}
                </div>
              </div>
            </div>
            {msgDisabled}
            <div className="col-sm-12 px-0 my-5">
              <ButtonComponent
                cssClass="eg-btn-primary float-right mr-2"
                style={
                  answer !== "" &&
                  seqId !== 0 &&
                  question !== "" &&
                  answersList.length > 0
                    ? { opacity: "1", pointerEvents: "all", cursor: "pointer" }
                    : { opacity: "0.5", pointerEvents: "none" }
                }
                onClick={() => handleAddNextQuestion()}
              >
                Next Question
              </ButtonComponent>
              <ButtonComponent
                cssClass="addQnsBtn float-right mr-3"
                style={
                  answer !== "" &&
                  seqId !== 0 &&
                  question !== "" &&
                  answersList.length > 0
                    ? { opacity: "1", pointerEvents: "all", cursor: "pointer" }
                    : { opacity: "0.5", pointerEvents: "none" }
                }
                onClick={() => saveCasualDataRule()}
              >
                Add Question And Exit
              </ButtonComponent>

              <ButtonComponent
                cssClass="eg-btn-primary-link e-flat mt-3"
                onClick={() => handlePopUpClose()}
              >
                Cancel
              </ButtonComponent>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default AddCasualDataRule;
