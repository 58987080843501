import "./mobile-field Preview.scss";
import NumberFormat from "react-number-format";
import { useEffect, useState } from "react";
import Data from "./countrydata";
import { useDispatch } from "react-redux";
import {
  setAltMobileWithCountryPreview,
  setCountryCodeForAltMobilePreview,
  setCountryCodeForFaxPreview,
  setCountryCodeForMobilePreview,
  setFaxWithCountryPreview,
  setMobileWithCountry,
  setMobileWithCountryPreview,
  setShowUpdateAndCancelIconsOnPreview,
} from "../contactTabs/contactReducer";

const CountryDropdownPreview = (props: any) => {
  const { type, value } = props;

  const inputype = type;
  const [SelectedCode, setSelectedCode] = useState(
    `https://countryflagsapi.com/png/us`
  );
  const [SelectedTel, setSelectedTel] = useState("");
  const [inputValue, setinputvalue] = useState("");
  const dispatch = useDispatch();

  let arr = Object.values(Data.Results);

  let mydata = arr.map((element: any) => {
    return {
      Name: element.Name,
      Code: element.CountryCodes.tld,
      Tel: element.TelPref,
    };
  });
  mydata.sort(function (a, b) {
    if (a.Name < b.Name) {
      return -1;
    }
    if (a.Name > b.Name) {
      return 1;
    }
    return 0;
  });

  const handleChange = (e: any) => {
    setSelectedCode(`https://countryflagsapi.com/png/${e.target.value}`);
    let selectedObj = mydata.find((el: any) => el.Code === e.target.value);
    setSelectedTel(selectedObj?.Tel);
    dispatch(setShowUpdateAndCancelIconsOnPreview(true));
    if (type === "Phone") {
      dispatch(setCountryCodeForMobilePreview("+" + selectedObj?.Tel));
    } else if (type === "Alt Phone") {
      dispatch(setCountryCodeForAltMobilePreview("+" + selectedObj?.Tel));
    } else if (type === "Fax") {
      dispatch(setCountryCodeForFaxPreview("+" + selectedObj?.Tel));
    }
  };

  function handleFinal(event: any) {
    let check = "";
    check = event.target.value;
    setinputvalue(check);
    dispatch(setMobileWithCountry(check));
  }

  const handlePhone = (event: any, type: string) => {
    let check = "";
    check = event.target.value;
    console.log(check);
    setinputvalue(check);
    dispatch(setShowUpdateAndCancelIconsOnPreview(true));
    dispatch(setMobileWithCountryPreview(null));
    dispatch(setAltMobileWithCountryPreview(null));
    dispatch(setFaxWithCountryPreview(null));
    console.log(type);
    if (type === "Phone") {
      dispatch(setMobileWithCountryPreview(check));
    } else if (type === "Alt Phone") {
      dispatch(setAltMobileWithCountryPreview(check));
    } else if (type === "Fax") {
      dispatch(setFaxWithCountryPreview(check));
    }
  };

  useEffect(() => {
    setinputvalue(value);
  }, [SelectedTel]);

  return (
    <div className="countrcodemainpreview p-0">
      <div className="mobilefieldmainpreview">
        {SelectedCode && (
          <img
            src={SelectedCode}
            alt="img"
            className="countryFlagImagepreview"
          ></img>
        )}
        {SelectedTel && (
          <span className="countrycodetext">(+{SelectedTel})</span>
        )}{" "}
        {""}
        <select
          className="Countrydropdownpreview"
          value={SelectedCode}
          onChange={(event) => handleChange(event)}
        >
          {mydata.map((e: any) => (
            <option value={e.Code}>{e.Name}</option>
          ))}
        </select>
        {inputype === "Phone" ? (
          <NumberFormat
            value={inputValue}
            className="MobileInputNumber"
            format="(###) ###-####"
            allowEmptyFormatting
            mask="_"
            onChange={(e: any) => handlePhone(e, "Phone")}
          ></NumberFormat>
        ) : inputype === "Alt Phone" ? (
          <NumberFormat
            value={inputValue}
            className="MobileInputNumber"
            format="(###) ###-####"
            allowEmptyFormatting
            mask="_"
            onChange={(e: any) => handlePhone(e, "Alt Phone")}
          ></NumberFormat>
        ) : inputype === "Fax" ? (
          <NumberFormat
            value={inputValue}
            className="MobileInputNumber"
            format="(###) ###-####"
            allowEmptyFormatting
            onChange={(e: any) => handlePhone(e, "Fax")}
          ></NumberFormat>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

export default CountryDropdownPreview;
