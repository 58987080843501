import { useSelector } from "react-redux";
import { useEffect } from "react";
import { accessReqArchieveHeaders, generateGridData } from "../../utils";
import { RootState } from "../../../../redux/store";
import Grid from "../../../grid/Grid";
import NoCheckBoXGrid from '../../../grid/fiscal-year-setup-grid/fiscal-year-setup-grid';
const ArchiveRequest = () => {
  /* Get data from Grid Reducer */
  const selectedRowData = useSelector(
    (state: RootState) => state.gridReducer.selectedRowData
  );
  // console.log(selectedRowData, 'selectedRowData');

  /* Get Archive Requests data from Access management Reducer */
  const archiveReqData = useSelector(
    (state: RootState) => state.accessMgmtReducer.archiveReqData
  );

  /* Defined Grid Hieght based on Data  */
  let gridHieght = "calc(100vh - 425px)";

  /* Excute code on page load */
  useEffect(() => {
    // Write some code
  });

  return (
    <div className="col-sm-12 col-md-12 col-lg-12 ">
      <div className="col-sm-12 col-md-12 col-lg-12 px-0 pt-2">
      <div className="access-mgmt">
        {/* <Grid
          config={accessReqArchieveHeaders}
          data={generateGridData("Closed", archiveReqData)}
          width={"100%"}
          height={gridHieght}
          hideSpinner={true}
        /> */}
        <NoCheckBoXGrid
         config={accessReqArchieveHeaders}
         data={generateGridData("Closed", archiveReqData)}
         width={"100%"}
         height={gridHieght}
         hideSpinner={true}
        />
        </div>
      </div>
    </div>
  );
};

export default ArchiveRequest;
