import { DropDownListComponent } from "@syncfusion/ej2-react-dropdowns";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { bolUrl, InvoiceScreen, invoicesTabs, invoiceView } from "../config";
import {
  TabComponent,
  TabItemDirective,
  TabItemsDirective,
} from "@syncfusion/ej2-react-navigations";
import {
  getArInvoicesEndPointForCM,
  getARInvoicesListEndPointForCreditMemo,
} from "../utils";
import axios from "axios";
import {
  setArInvoiceSavedata,
  setCreditMemoSavedata,
  setDescriptionForCM,
  setInvoiceNumForCM,
  setSelectedArInvoiceTabIndex,
} from "../invoiceReducer";
import { RootState } from "../../../redux/store";
import { useHistory } from "react-router-dom";
import notify from "../../../services/toasterService";

const NewCreditMemoScreen = (props: any) => {
  const history = useHistory();
  const [invoices, setARInvoices] = useState();
  const dispatch = useDispatch();
  const selectedInvoiceScreen = useSelector(
    (store: RootState) => store.invoiceReducer.selectedInvoiceScreen
  );
  const CovertArInvoiceGridData = (data: any) => {
    return {
      ...data,
      invoiceNumber: data.invoiceNumber,
      description: data.description,
      customerId: data.customerId,
      period: data.period,
      cost: data.cost,
      price: data.price,
      created: data.createdDate,
      facility: data.reclaimCenterName,
      status: data.status.status,
    };
  };
  const handleGoBack = () => {
    let routeToGoBack = "";
    if (selectedInvoiceScreen) {
      routeToGoBack =
        selectedInvoiceScreen === InvoiceScreen.INVOICE
          ? `/${invoiceView}`
          : `/${bolUrl}`;
    }
    history.push(`/invoices${routeToGoBack}`);
  };
  useEffect(() => {
    const endpoint = getARInvoicesListEndPointForCreditMemo();
    getInvoicesForCM(endpoint);
    // fetch(endpoint)
    //   .then((data) => data.json())
    //   .then((result) => {
    //     if (!result || result.length === 0) {
    //       //alert("Fetch list successful, but no list items are present");
    //     } else {
    //       //alert("Fetch list successful");
    //       let arlist: any = [];
    //       result.forEach((element: any) => {
    //         arlist.push(CovertArInvoiceGridData(element));
    //       });
    //       setARInvoices(arlist);
    //       console.log("arInvoices CM", result);
    //     }
    //   })
    //   .catch((error) => console.log(error));
  }, []);
  const getInvoicesForCM = async (endpoint: string) => {
    const result = await axios
      .get(endpoint)
      .then((results: any) => {
        if (results && results.data) {
          let arlist: any = [];
          results.data.forEach((element: any) => {
            arlist.push(CovertArInvoiceGridData(element));
          });
          setARInvoices(arlist);
          console.log("arInvoices CM", result);
        } else {
          notify("Failed", "error");
        }
      })
      .catch((error: any) => {
        if (error.response) {
          notify(error.response?.data?.message, "error");
        }
      });
  };
  const handleInvoiceSelect = (invoiceNumber: string) => {
    dispatch(setArInvoiceSavedata([""]));
    dispatch(setDescriptionForCM(""));
    dispatch(setCreditMemoSavedata([""]));
    dispatch(setInvoiceNumForCM(invoiceNumber));
    dispatch(setSelectedArInvoiceTabIndex(1));
    const endpoint = getArInvoicesEndPointForCM(invoiceNumber);

    axios.get(endpoint).then((result: any) => {
      console.log(">>>>>>>>> save result: ", result);
      if (!result) {
        //alert("An error occured while saving");
      } else {
        //alert("Save successful");
        dispatch(setArInvoiceSavedata(result.data));
        dispatch(setCreditMemoSavedata(result.data));

        if (result.data.invoiceNumber) {
          props?.history?.push(
            `Details?ArInvoiceId=${result.data.invoiceNumber}`
          );
        } else {
          props.history.push(`invoiceView/Details`);
        }
      }
    });
  };
  return (
    <div className="New-creditmemo-screen">
      <TabComponent heightAdjustMode="Auto" selectedItem={1}>
        <TabItemsDirective>
          {invoicesTabs.map((tab) => {
            return <TabItemDirective key={tab} header={{ text: tab }} />;
          })}
        </TabItemsDirective>
      </TabComponent>
      <span onClick={handleGoBack} className="backToCM">{`< Back to Credit Memos
            `}</span>
      <span style={{ marginTop: 20 }}>Select Invoice</span>
      <div className="invoice-list-dropdown">
        <DropDownListComponent
          className="select-control"
          dataSource={invoices}
          fields={{ text: "invoiceNumber", value: "invoiceNumber" }}
          onChange={(event: any) => {
            console.log(">>>>>>>>>>>> picked carrier: ", event.value);
            handleInvoiceSelect(event.value);
          }}
        />
      </div>
    </div>
  );
};

export default NewCreditMemoScreen;
