import { useSelector } from 'react-redux';
import { RootState } from '../../../redux/store';
import infoicon from '../../../icons/info.svg';
import { useEffect, useState } from 'react';

interface IScanedItem {
    scanMode: string;
}
const ScannedItemPanel = () => {
    const [scanModeTitleList, setScanModeTitleList] = useState('');
    const getScanMode = useSelector(
        (state: RootState) => state.scanReducer.scanMode
    );
    const getScanModeList = useSelector(
        (state: RootState) => state.scanReducer.scanModeList
    );
    const getScanDone = useSelector(
        (state: RootState) => state.scanReducer.scanDone
    );

    const getForceTrashMode = useSelector(
        (state: RootState) => state.scanReducer.forceTrashMode
    );

    const setItemDetails = useSelector(
        (state: RootState) => state.scanReducer.itemDetails
    );
   
    useEffect(()=>{
   const removed = getScanModeList?.payload?.replace(/(^,)|(,$)/g, "");
   setScanModeTitleList(removed);
    },[getScanModeList?.payload])
    const getDispositionType = (disposition: any) => {
        let choosen;
        switch (disposition) {
            case 'bin':
                choosen = 'bin';
                break;
            case 'box':
                choosen = 'box';
                break;
            case 'nocredit':
                choosen = 'box';
                break;
            case 'audit_inbound':
                choosen = 'box';
                break;
            case 'audit_outbound':
                choosen = 'box';
                break;
            case 'DESTROY':
                choosen = '#8C4D3F';
                break;
            case 'ALERT DESTROY':
                choosen = '#8C4D3F';
                break;
            case 'TRASH':
                choosen = '#8C4D3F';
                break;
            case 'ALERT DISPOSE':
                choosen = '#ce2029';
                break;
            case 'trash':
                choosen = 'bin';
                break;
            case 'SALVAGE - GM/HBA':
                choosen = 'rgba(115, 122, 230, 1)';
                break;
            case 'SALVAGE - FOOD':
                choosen =
                    'linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), linear-gradient(0deg, #79C24F, #79C24F)';
                break;
            case 'HOLD - SPECIAL REVIEW':
                choosen =
                    'linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), linear-gradient(0deg, #F2CA52, #F2CA52)';
                break;
            case '13 DIGIT UPC HOLD':
                choosen =
                    'linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), linear-gradient(0deg, #F2CA52, #F2CA52)';
                break;
            case 'HOLD - VENDOR REQUEST':
                choosen =
                    'linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), linear-gradient(0deg, #F2CA52, #F2CA52)';
                break;
            case 'DONATE - NON FOOD':
                choosen =
                    'linear-gradient(0deg, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), linear-gradient(0deg, #737ae6, #737ae6)';
                break;
            case 'DONATE - FOOD':
                choosen =
                    'linear-gradient(0deg, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), linear-gradient(0deg, #737ae6, #737ae6)';
                break;
            case 'SALVAGE - MEDS':
                choosen = 'rgba(150, 150, 150, 1)';
                break;
            default:
                choosen =
                    'linear-gradient(0deg, rgba(255, 255, 255, 0.4), rgba(255, 255, 255, 0.4)), linear-gradient(0deg, #636799, #636799)';
        }
        return choosen;
    };

    const getDispositionModeRow = (dispositionMode: any) => {
        let choosen;
        switch (dispositionMode) {
            case 'SALVAGE - GM/HBA':
                choosen = 'salvage-hba-gm';
                break;
            case 'SALVAGE - FOOD':
                choosen = 'salvage-hba-food';
                break;
            case 'HOLD - SPECIAL REVIEW':
                choosen = 'hold-special-review';
                break;
            case 'HOLD - VENDOR REQUEST':
                choosen = 'hold-special-review';
                break;
            case 'DONATE - NON FOOD':
                choosen = 'donate-non-food';
                break;
            case 'DONATE - FOOD':
                choosen = 'donate-non-food';
                break;
            case 'SALVAGE - MEDS':
                choosen = 'meds';
                break;
            case 'DESTROY':
                choosen = 'trash';
                break;
            case 'ALERT DESTROY':
                choosen = 'trash';
                break;
            case 'TRASH':
                choosen = 'trash';
                break;
            case 'ALERT DISPOSE':
                choosen = 'hazardous';
                break;
            case '13 DIGIT UPC HOLD':
                choosen = 'hold-special-review';
                break;
            default:
                choosen = 'box';
        }
        return choosen;
    };

    return (
        <>
            <div
                className="col-sm-12 alertBox"
                style={{
                    background: `${getDispositionType(
                        setItemDetails?.payload?.data?.disposition
                    )}`,
                }}
            >
                { getScanModeList?.payload ? (
                    <div className="scanmode-warning bin-mode">
                        <strong className="text-left">
                            <img src={infoicon} className="info-icon" />
                            {scanModeTitleList}
                        </strong>
                    </div>
                ) : (
                    ''
                )}
                <div className="warehouse-location-heading">
                    <>
                        {(setItemDetails?.payload?.data?.subDisposition &&
                            setItemDetails?.payload?.data?.subDisposition !==
                                '') ||
                        (setItemDetails?.payload?.data?.hazDisposition &&
                            setItemDetails?.payload?.data?.hazDisposition !==
                                '') ? (
                            <>
                                <div className="alertText">
                                    {setItemDetails?.payload?.data
                                        ?.subDisposition ||
                                        setItemDetails?.payload?.data
                                            ?.hazDisposition}
                                </div>
                                <span className="text-center sub-disposition">
                                    {
                                        setItemDetails?.payload?.data
                                            ?.warehouseLocation
                                    }
                                </span>
                            </>
                        ) : getScanDone.payload === true &&
                          ((!setItemDetails?.payload?.data?.subDisposition &&
                              setItemDetails?.payload?.data?.subDisposition ===
                                  '') ||
                              (!setItemDetails?.payload?.data?.hazDisposition &&
                                  setItemDetails?.payload?.data
                                      ?.hazDisposition === '')) ? (
                            <div className="alertText">
                                {
                                    setItemDetails?.payload?.data
                                        ?.warehouseLocation
                                }
                            </div>
                        ) : (setItemDetails?.payload?.data?.description ===
                              'UNLABELED ITEM' &&
                              setItemDetails?.payload?.data
                                  ?.warehouseLocation === 'NOT FOUND') ||
                          (setItemDetails?.payload?.data?.description ===
                              'NF' &&
                              setItemDetails?.payload?.data
                                  ?.warehouseLocation === 'NOT FOUND') ||
                          (setItemDetails?.payload?.data?.description ===
                              '13 DIGIT UPC' &&
                              setItemDetails?.payload?.data
                                  ?.warehouseLocation === 'NOT FOUND') ? (
                            <div className="alertText">
                                {setItemDetails?.payload?.data
                                    ?.warehouseLocation
                                    ? setItemDetails?.payload?.data
                                          ?.warehouseLocation
                                    : setItemDetails?.payload?.warehouseLocation
                                    ? setItemDetails?.payload?.warehouseLocation
                                    : 'SCAN ITEM'}
                            </div>
                        ) : (
                            <div className="alertText">
                                {setItemDetails?.payload?.data
                                    ?.warehouseLocation
                                    ? setItemDetails?.payload?.data
                                          ?.warehouseLocation
                                    : setItemDetails?.payload?.warehouseLocation
                                    ? setItemDetails?.payload?.warehouseLocation
                                    : 'SCAN ITEM'}
                            </div>
                        )}
                    </>
                </div>
                <div
                    className={`row ml-0 mr-0 mt-1rem upcDetailsRowOne ${
                        setItemDetails?.payload?.data?.disposition ===
                            'TRASH' &&
                        setItemDetails?.payload?.data?.hazardousFlag === true
                            ? getDispositionModeRow('ALERT DISPOSE')
                            : setItemDetails?.payload?.data?.disposition
                            ? getDispositionModeRow(
                                  setItemDetails?.payload?.data?.disposition
                              )
                            : getDispositionType(getScanMode.payload)
                    }`}
                >
                    <div className="col-sm-4 pl-0">
                        <div className="col-sm-3 pl-0 fw-600 alertBoxName">
                            UPC
                        </div>
                        <div className="col-sm-6 pl-0">
                            <span className="item-descriptio">
                                {setItemDetails?.payload?.data?.upc}
                            </span>
                        </div>
                    </div>
                    <div className="col-sm-2 pl-0">
                        <div className="col-sm-6 pl-0 fw-600 alertBoxName">
                            Qty
                        </div>
                        <div className="col-sm-6 pl-0">
                            <span className="item-descriptio">
                                {setItemDetails?.payload?.data?.quantity}
                            </span>
                        </div>
                    </div>
                    <div className="col-sm-6 pl-0">
                        <div className="col-sm-4 pl-0 pr-0 fw-600 alertBoxName">
                            Description
                        </div>
                        <div className="col-sm-8 pl-0">
                            <span
                                className="item-description"
                                title={
                                    setItemDetails?.payload?.data?.description
                                }
                            >
                                {setItemDetails?.payload?.data?.description}
                            </span>
                        </div>
                    </div>
                </div>
                <div className="row ml-0 mr-0 mt-1rem upcDetailsRowTwo">
                    <div className="col-sm-12 pl-0">
                        <div className="col-sm-6 pl-0">
                            <div className="col-sm-2 pl-0 fw-600 alertBoxName">
                                Vendor
                            </div>
                            <div className="col-sm-10 pl-2 pr-0">
                                <span
                                    className="item-description"
                                    title={
                                        setItemDetails?.payload?.data
                                            ?.vendorName
                                    }
                                >
                                    {setItemDetails?.payload?.data?.vendorId
                                        ? '(' +
                                          setItemDetails?.payload?.data
                                              ?.vendorId +
                                          ')' +
                                          setItemDetails?.payload?.data
                                              ?.vendorName
                                        : ''}
                                </span>
                            </div>
                        </div>
                        <div className="col-sm-6 pl-0">
                            <div className="col-sm-5 pl-0 fw-600 alertBoxName">
                                Pack Size
                            </div>
                            <div className="col-sm-2 pl-0">
                                <span className="item-descriptio">
                                    {setItemDetails?.payload?.data?.packSize}
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    className={`row ml-0 mr-0 mt-1rem upcDetailsRowOne ${
                        setItemDetails?.payload?.data?.disposition ===
                            'TRASH' &&
                        setItemDetails?.payload?.data?.hazardousFlag === true
                            ? getDispositionModeRow('ALERT DISPOSE')
                            : setItemDetails?.payload?.data?.disposition
                            ? getDispositionModeRow(
                                  setItemDetails?.payload?.data?.disposition
                              )
                            : getDispositionType(getScanMode.payload)
                    }`}
                >
                    <div className="col-sm-6 pl-0">
                        <div className="col-sm-4 pl-0 fw-600 txtdbtrsn">
                            <span className="alertBoxName">Debit Reason</span>
                        </div>
                        <div className="col-sm-4">
                            <span className="item-description">
                                {setItemDetails?.payload?.data?.debitReason
                                    ? setItemDetails?.payload?.data?.debitReason
                                    : 'N/A'}
                            </span>
                        </div>
                    </div>
                    <div className="col-sm-4 pl-0">
                        <div className="col-sm-6 fw-600 txtunit">
                            <span className="alertBoxName">Unit Factor</span>
                        </div>
                        <div className="col-sm-1">
                            <span className="">
                                {setItemDetails?.payload?.data?.unitFactor}
                            </span>
                        </div>
                    </div>
                </div>
                <div className="row ml-0 mr-0 mt-1rem upcDetailsRowTwo">
                    <div className="col-sm-3 pl-0">
                        <div className="col-sm-10 pl-0 fw-600 txtdbtrsn alertBoxName">
                            Rule Exception
                        </div>
                        <div className="col-sm-2 pl-0">
                            <span className="">
                                {setItemDetails?.payload?.data?.ruleException
                                    ? setItemDetails?.payload?.data
                                          ?.ruleException
                                    : 'N/A'}
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ScannedItemPanel;
