import React, { useEffect, useState } from "react";
import { ButtonComponent } from "@syncfusion/ej2-react-buttons";
import fileIcon from "../../../icons/File.png";
import crossIcon from "../../../icons/Close_Delete.png";
import addIcon from "../../../icons/Add.png";
import toggleIconOff from "../../../icons/ToggleIconOff.png";
import toggleIconOn from "../../../icons/ToggleIconOn.png";
// import addIcon from "../../../assets/add.png";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import {
  deleteScanSettingsById,
  getReclamCenters,
  getScanDetailsById,
  saveScanSettingsByRcId,
  updateScanSettingsById,
} from "../utils";
import axios from "axios";
import { setLocalScanSettings } from "../settingsReducers";
import notify from "../../../services/toasterService";

const LocalScanSettings = () => {
  /* calling get reclaim centers api on tab load */
  useEffect(() => {
    console.log("on load", selectedReclaimCenterId);
    getReclamCenter();
  }, []);

  const dispatch = useDispatch();

  /* store get scan settings results in reducer */
  const savedLocalScanSettingsListData = useSelector(
    (state: RootState) => state.settingsReducer?.localScanSettings
  );

  let showToggleIconOn = false;

  const [reclaimCentersList, setReclaimCentersList] = useState<any>([]);
  let forceReasonCodesArray: {}[] = [];
  const scanGroupArray: {}[] = [];

  let [unbilledItemCost, setUnbilledItemCost] = useState("");
  let [numOfBoxesInPallet, setNumOfBoxesInPallet] = useState("");
  let [binMaxItemQty, setBinMaxItemQty] = useState("");
  let [boxMaxItemQty, setBoxMaxItemQty] = useState("");
  let [notFoundItemCost, setNotFoundItemCost] = useState("");
  let [limitMaxQty, setLimitMaxQty] = useState("");

  let [forceTrashRCInputList, setForceTrashInputList] = useState([
    { reasonCode: "", description: "", id: 0, active: false },
  ]);
  let [scanGroupsInputList, setScanGroupsInputList] = useState([
    {
      scanGrpCode: "",
      scanGrpDesc: "",
      scanStations: "",
      id: 0,
      active: false,
    },
  ]);

  const [captureReasonCode, setCaptureReasonCode] = useState(false);

  const [administartion, setAdministration] = useState({ clientId: "", id: 0 });

  const [state, setState] = useState();
  const listdata: any[] = [];

  const [selectedReclaimCenterId, setselectedReclaimCenterId] = useState(0);
  let [scanSettingsList, setScanSettingsList] = useState<any>([]);

  /* scan setting handle events */

  const handleUnbilledItemCost = (event: any) => {
    if (event.target.value.includes(".") === true) {
      if (event.target.value.split(".")[1].length > 2) {
        setUnbilledItemCost(parseFloat(event.target.value).toFixed(2));
      } else {
        setUnbilledItemCost(event.target.value);
      }
    }
    else {
      setUnbilledItemCost(event.target.value);
     
   }
  };
  const handleNumOfBoxesPallet = (event: any) => {
    setNumOfBoxesInPallet(event.target.value);
  };
  const handleBimMaxItemQty = (event: any) => {
    setBinMaxItemQty(event.target.value);
  };
  const handleBoxMaxItemQty = (event: any) => {
    setBoxMaxItemQty(event.target.value);
  };
  const handleNotFoundItemCost = (event: any) => {
 
     if (event.target.value.includes(".") === true) {
       if (event.target.value.split(".")[1].length > 2) {
         setNotFoundItemCost(parseFloat(event.target.value).toFixed(2));
       } else {
         setNotFoundItemCost(event.target.value);
       }
     }
     else {
      setNotFoundItemCost(event.target.value);
      
    }
  };

  const handleLimitMaxQty = (event: any) => {
    setLimitMaxQty(event.target.value);
  };

  /* handle input change of reason codes */
  const handleForceTrashInputChange = (e: any, index: any) => {
    const name = e.target.name;
    const value = e.target.value;
    const list = [...forceTrashRCInputList];
    let items: any;
    items = [...forceTrashRCInputList];
    items[index][name] = value;
    setForceTrashInputList(items);
  };

  /*  handle click event of the reason codes Remove button */
  const handleForceTrashReviewRemoveClick = (item: any, index: number) => {
    const list = [...forceTrashRCInputList];
    list.splice(index, 1);
    setForceTrashInputList(list);
    if (item.id !== undefined && item.id !== null && item.id != 0) {
      deleteScanLocalSettingsById(item, index, "reasoncode");
    }
  };

  /*  handle click event of the reason codes  Add button */
  const handleForceTrashReviewRCAddClick = (rc: any, desc: any) => {
    setForceTrashInputList([
      ...forceTrashRCInputList,
      { reasonCode: rc, description: desc, id: 0, active: false },
    ]);
  };

  /* handle input change of scan groups  */
  const handleScanGrpInputChange = (e: any, index: number) => {
    const name = e.target.name;
    const value = e.target.value;
    const list = [...scanGroupsInputList];
    let items: any;
    items = [...scanGroupsInputList];
    items[index][name] = value;
    setScanGroupsInputList(items);
  };

  /* handle click event of the scan groups Remove button */
  const handleScanGrpsRemoveClick = (item: any, index: number) => {
    const list = [...scanGroupsInputList];
    list.splice(index, 1);
    setScanGroupsInputList(list);
    if (item.id !== undefined && item.id !== null && item.id != 0) {
      deleteScanLocalSettingsById(item, index, "scanstation");
    }
  };

  /* handle click event of the scan groups Add button */
  const handleScanGrpsAddClick = (sgc: any, sgd: any, scanStations: any) => {
    setScanGroupsInputList([
      ...scanGroupsInputList,
      {
        scanGrpCode: sgc,
        scanGrpDesc: sgd,
        scanStations: scanStations,
        id: 0,
        active: false,
      },
    ]);
  };

  /* Capture reason code change event */
  const handleToggleChange = (event: any, type: any) => {
    setCaptureReasonCode(type);
  };

  /*Reclaim center change event */
  const handleReclaimCenter = (e: any) => {
    setselectedReclaimCenterId(e);
    reclaimCentersList.map((item: any) => {
      if (item.id == e) {
        const obj = { clientId: item.client.clientId, id: item.client.id };
        setAdministration(obj);
      }
    });
    /*calling saved scan settings based on sekected reclaim center */
    getScanDetailsByReclaimCentersId(e);
  };

  /* get Reclaim Centers Api call */
  const getReclamCenter = async () => {
    const endPointUrl = getReclamCenters();
    await axios
      .get(endPointUrl)
      .then((resp) => {
        setReclaimCentersList(resp.data);
      })
      .catch((err) => err);
  };

  /* get saved scan settings Api call */
  const getScanDetailsByReclaimCentersId = async (id: any) => {
    scanSettingsList = [];
    const endPointUrl = getScanDetailsById(id);
    await axios
      .get(endPointUrl)
      .then((resp) => {
        scanSettingsList.push(resp.data);
        const dataa = [];
        dataa.push(resp.data);
        dispatch(setLocalScanSettings(scanSettingsList));

        /*update scan settings object from get results */
        if (
          scanSettingsList[0].scanSettings !== undefined &&
          scanSettingsList[0].scanSettings !== null
        ) {
          setBinMaxItemQty(scanSettingsList[0]["scanSettings"].binMaxItemQty);
          setBoxMaxItemQty(scanSettingsList[0]["scanSettings"].boxMaxItemQty);
          setNotFoundItemCost(
            scanSettingsList[0]["scanSettings"].notFoundItemCost
          );
          setNumOfBoxesInPallet(
            scanSettingsList[0]["scanSettings"].boxesInPallet
          );
          setUnbilledItemCost(
            scanSettingsList[0]["scanSettings"].unlabeledItemCost
          );
          setLimitMaxQty(scanSettingsList[0]["scanSettings"].itemMaxQty);
          setCaptureReasonCode(
            scanSettingsList[0]["scanSettings"].captureReasonCode
          );
        } else {
          setBinMaxItemQty("");
          setBoxMaxItemQty("");
          setNotFoundItemCost("");
          setNumOfBoxesInPallet("");
          setUnbilledItemCost("");
          setLimitMaxQty("");
          setCaptureReasonCode(false);
        }

        /*update reason codes array from get results */
        let reasonCodeList: any[] = [];
        let scanGroupsList: any[] = [];
        if (scanSettingsList[0].reasonCodes.length > 0) {
          scanSettingsList[0].reasonCodes.map((item: any, i: any) => {
            let items: any;
            const obj = {
              reasonCode: item.reasonCode,
              description: item.description,
              id: item.id,
              active: item.active,
            };
            reasonCodeList.push(obj);
          });
          setForceTrashInputList(reasonCodeList);
        } else {
          forceTrashRCInputList = [];
          handleForceTrashReviewRCAddClick("", "");
        }

        /*update scan groups  array from get results */
        if (scanSettingsList[0].scanGroups.length > 0) {
          scanSettingsList[0].scanGroups.map((item: any, i: any) => {
            const obj = {
              scanGrpCode: item.scanGroupCode,
              scanGrpDesc: item.scanGroupDescription,
              scanStations: item.scanStation,
              id: item.id,
              active: item.active,
            };
            scanGroupsList.push(obj);
          });
          setScanGroupsInputList(scanGroupsList);
        } else {
          scanGroupsInputList = [];
          handleScanGrpsAddClick("", "", "");
        }
      })
      .catch((err) => {
        console.log(err);
        notify(
          "Error! Unable to fetch data. Please try after sometime.",
          "error"
        );
      });
  };

  /* save and update  scan settings api call */
  const saveScanSettings = () => {
    let scanSettingsSelectedId: any;
    if (savedLocalScanSettingsListData.length > 0) {
      scanSettingsSelectedId =
        savedLocalScanSettingsListData[0].scanSettings !== undefined &&
          savedLocalScanSettingsListData[0].scanSettings !== null
          ? savedLocalScanSettingsListData[0].scanSettings.id
          : 0;
    }

    /*prepare request body for reason codes array */
    forceTrashRCInputList.map((item, i) => {
      if (
        savedLocalScanSettingsListData.length > 0 &&
        savedLocalScanSettingsListData[0]["reasonCodes"].length > 0
      ) {
        if (item.id !== undefined && item.id !== null && item.id != 0) {
          const obj = {
            id: item.id,
            active: item.active,
            reasonCode: item.reasonCode,
            description: item.description,
          };
          forceReasonCodesArray.push(obj);
        } else {
          const obj = {
            reasonCode: item.reasonCode,
            description: item.description,
          };
          forceReasonCodesArray.push(obj);
        }
      } else {
        const obj = {
          reasonCode: item.reasonCode,
          description: item.description,
        };
        forceReasonCodesArray.push(obj);
      }
    });

    /*prepare request body for scan groups array */
    scanGroupsInputList.map((scanitem, j) => {
      let scanStationsObj = scanitem.scanStations;
      if (typeof scanStationsObj === "string") {
        if (
          savedLocalScanSettingsListData.length > 0 &&
          savedLocalScanSettingsListData[0]["scanGroups"].length > 0
        ) {
          if (
            scanitem.id !== undefined &&
            scanitem.id !== null &&
            scanitem.id != 0
          ) {
            const scanStationsArray = scanitem.scanStations;
            const scanStationsObject = scanStationsObj
              .split(",")
              .map((n) => parseInt(n, 10));
            const scanobj = {
              id: scanitem.id,
              active: scanitem.active,
              scanGroupCode: scanitem.scanGrpCode,
              scanGroupDescription: scanitem.scanGrpDesc,
              scanStation: scanStationsObject,
            };
            scanGroupArray.push(scanobj);
          } else {
            const scanStationsArray = scanitem.scanStations;
            const scanStationsObject = scanStationsObj
              .split(",")
              .map((n) => parseInt(n, 10));
            const scanobj = {
              scanGroupCode: scanitem.scanGrpCode,
              scanGroupDescription: scanitem.scanGrpDesc,
              scanStation: scanStationsObject,
            };
            scanGroupArray.push(scanobj);
          }
        } else {
          const scanStationsArray = scanitem.scanStations;
          const scanStationsObject = scanStationsObj
            .split(",")
            .map((n) => parseInt(n, 10));
          const scanobj = {
            scanGroupCode: scanitem.scanGrpCode,
            scanGroupDescription: scanitem.scanGrpDesc,
            scanStation: scanStationsObject,
          };
          scanGroupArray.push(scanobj);
        }
      } else {
        if (
          savedLocalScanSettingsListData.length > 0 &&
          savedLocalScanSettingsListData[0]["scanGroups"].length > 0
        ) {
          if (
            scanitem.id !== undefined &&
            scanitem.id !== null &&
            scanitem.id != 0
          ) {
            const scanStationsObjectData = scanitem.scanStations;
            const scanobj = {
              id: scanitem.id,
              active: scanitem.active,
              scanGroupCode: scanitem.scanGrpCode,
              scanGroupDescription: scanitem.scanGrpDesc,
              scanStation: scanStationsObjectData,
            };
            scanGroupArray.push(scanobj);
          } else {
            const scanStationsObjectData = scanitem.scanStations;
            const scanobj = {
              scanGroupCode: scanitem.scanGrpCode,
              scanGroupDescription: scanitem.scanGrpDesc,
              scanStation: scanStationsObjectData,
            };
            scanGroupArray.push(scanobj);
          }
        } else {
          const scanStationsObjectData = scanitem.scanStations;
          const scanobj = {
            scanGroupCode: scanitem.scanGrpCode,
            scanGroupDescription: scanitem.scanGrpDesc,
            scanStation: scanStationsObjectData,
          };
          scanGroupArray.push(scanobj);
        }
      }
    });

    let scansettings: any;

    /*prepare request body for scan settings object */
    if (
      savedLocalScanSettingsListData.length > 0 &&
      savedLocalScanSettingsListData[0].scanSettings !== undefined &&
      savedLocalScanSettingsListData[0].scanSettings !== null
    ) {
      scansettings = {
        binMaxItemQty: binMaxItemQty !== undefined && binMaxItemQty !== null ? parseInt(binMaxItemQty) : 0,
        boxMaxItemQty: boxMaxItemQty !== undefined && boxMaxItemQty !== null ? parseInt(boxMaxItemQty) : 0,
        boxesInPallet: numOfBoxesInPallet !== undefined && numOfBoxesInPallet !== null ? parseInt(numOfBoxesInPallet) : 0,
        unlabeledItemCost: unbilledItemCost !== undefined && unbilledItemCost !== null ? parseFloat(unbilledItemCost) : 0,
        notFoundItemCost: notFoundItemCost !== undefined && notFoundItemCost !== null ? parseFloat(notFoundItemCost) : 0,
        captureReasonCode: captureReasonCode,
        itemMaxQty: limitMaxQty !== undefined && limitMaxQty !== null ? parseInt(limitMaxQty) : 0,
        id: savedLocalScanSettingsListData[0].scanSettings.id,
      };
    } else {
      scansettings = {
        binMaxItemQty: binMaxItemQty,
        boxMaxItemQty: boxMaxItemQty,
        boxesInPallet: numOfBoxesInPallet,
        unlabeledItemCost: unbilledItemCost,
        notFoundItemCost: notFoundItemCost,
        itemMaxQty: limitMaxQty,
        captureReasonCode: captureReasonCode,
      };
    }

    const reqBody = {
      reasonCodes: forceReasonCodesArray,
      scanGroups: scanGroupArray,
      scanSettings: scansettings,
    };
console.log(savedLocalScanSettingsListData)
    if (selectedReclaimCenterId != 0) {
      /*update api call */
      if (
        savedLocalScanSettingsListData.length > 0 &&
        scanSettingsSelectedId !== 0
      ) {
        const endPointURL = updateScanSettingsById(
          selectedReclaimCenterId,
          scanSettingsSelectedId
        );
        let body = reqBody;
        axios
          .put(endPointURL, body)
          .then((res) => {
            notify(" Updated Successfully", "success");
            getScanDetailsByReclaimCentersId(selectedReclaimCenterId);
          })
          .catch((err) => {
            console.log(err);
            notify(
              "Error! Unable to update data. Please try after sometime.",
              "error"
            );
          });
      } else {
        /*save api call */
        const endPointURL = saveScanSettingsByRcId(selectedReclaimCenterId);
        let body = reqBody;
        axios
          .post(endPointURL, body)
          .then((res) => {
            notify(" Saved Successfully", "success");
            getScanDetailsByReclaimCentersId(selectedReclaimCenterId);
          })
          .catch((err) => {
            console.log(err);
            notify(
              "Error! Unable to save data. Please try after sometime.",
              "error"
            );
          });
      }
    } else {
      notify("Please selcet reclaim center before save");
    }
  };

  /* delete local scan settings by id api call*/
  const deleteScanLocalSettingsById = async (
    item: any,
    idx: number,
    type: string
  ) => {
    const endPointUrl = deleteScanSettingsById(
      selectedReclaimCenterId,
      item.id,
      type
    );
    await axios
      .delete(endPointUrl)
      .then((res) => {
        console.log(res);
        notify(" Item Deleted Successfully", "success");
      })
      .catch((err) => {
        notify(err, "error");
      });
    // getScanDetailsByReclaimCentersId(selectedReclaimCenterId);
  };

  /* check is input format function */
  const formatInput = (e: any) => {
    // Prevent characters that are not numbers ("e", ".", "+" & "-") ✨
    let checkIfNum;
    if (e.key !== undefined) {
      // Check if it's a "e", ".", "+" or "-"
      checkIfNum =
        e.key === "e"  || e.key === "+" || e.key === "-";
        if(e.currentTarget.id.indexOf('decimal') === -1 ) {
          checkIfNum = checkIfNum || e.key === ".";
        }
    } else if (e.keyCode !== undefined) {
      // Check if it's a "e" (69), "." (190), "+" (187) or "-" (189)
      checkIfNum =
        e.keyCode === 69 ||
        e.keyCode === 190 ||
        e.keyCode === 187 ||
        e.keyCode === 189;
    }
    return checkIfNum && e.preventDefault();
  };


/* ****** Html Rendering ******** */
return (
  <div className="row">
    <div style={{ paddingLeft: "26px", paddingRight: "26px" }}>
      <ButtonComponent
        className="e-outline opt-btn-dark float-right mt-4"
        style={
          selectedReclaimCenterId != 0 &&
            selectedReclaimCenterId !== null &&
            selectedReclaimCenterId !== undefined
            ? { opacity: "1", pointerEvents: "all", cursor: "pointer" }
            : { opacity: "0.5", pointerEvents: "none" }
        }
        onClick={(e) => saveScanSettings()}
      >
        <img src={fileIcon} alt="File " />
      </ButtonComponent>
      <div className="row settingsSubTitleRow mt-1">
        <div className="col-sm-2 col-md-2 col-lg-2" style={{ marginTop: '10px', width: '10%', marginLeft: '5px', padding: '0' }}>
          <span className="settingslblClass" style={{ float: 'left' }}>Reclaim Center</span>
        </div>
        <div className="col-sm-2 col-md-2 col-lg-2" style={{ marginTop: '10px', padding: '0' }}>
          <select
            className="form-control p-0"
            id="companyTypeLinkDropDown"
            onChange={(e: any) => handleReclaimCenter(e.target.value)}
          >
            <option value="">Select Reclaim Center</option>
            {reclaimCentersList &&
              reclaimCentersList?.map(
                (ld: {
                  id: string | number | readonly string[] | undefined;
                  reclaimcenterName: React.ReactNode;
                }) => <option value={ld?.id}>{ld?.reclaimcenterName}</option>
              )}
          </select>
        </div>
      </div>

      {/* <p className="col-sm-1 col-md-12 col-lg-12 settings-tab-title m-3" >Reclaim Center
                <DropDownListComponent className="settings-select-control" dataSource={HeaderDropDownData} fields={{ text: 'name', value: 'id' }}
                     onChange={(event: any) => {
                     console.log(">>>>>>>>>>>> picked reclaim center: ", event.value);}}/></p> */}
    </div>
    <div className="container-fluid" style={{ paddingLeft: '35px' }}>
      <div className="row">
        <div className="col-sm-3 col-md-3 col-lg-3 p-0 scanSetingInpDivs">
          <div className="row m-0">
            <div className="col-sm-8 col-md-8 col-lg-8">
              <span className=" settingslblClass" style={{ float: 'left' }}>Unlabelled Item Cost</span>
            </div></div>
          <div className="row">
            <div className="col-sm-8 col-md-8 col-lg-8">
              <input id ="decimal_unbilledItemCost"
                className=" form-control"
                type="number"
                onKeyDown={formatInput}
                value={unbilledItemCost}
                onChange={(e) => handleUnbilledItemCost(e)}
              />
            </div>
          </div>
        </div>
        <div className="col-sm-3 col-md-3 col-lg-3 p-0 scanSetingInpDivs">
          <div className="row m-0">
            <div className="col-sm-9 col-md-9 col-lg-9 ">
              <span className=" settingslblClass " style={{ float: 'left' }}>
                Number of Boxes in Pallet
              </span>
            </div></div>
          <div className="row">
            <div className="col-sm-8 col-md-8 col-lg-8">
              <input
                className="form-control"
                type="number"
                value={numOfBoxesInPallet}
                onKeyDown={formatInput}
                placeholder=""
                onChange={(e) => handleNumOfBoxesPallet(e)}
              />
            </div>
          </div>
        </div>
        <div className="col-sm-3 col-md-3 col-lg-3 p-0 scanSetingInpDivs">
          <div className="row m-0">
            <div className="col-sm-8 col-md-8 col-lg-8">
              <span className=" settingslblClass " style={{ float: 'left' }}>
                Default O/B Box Max Qty
              </span>
            </div></div>
          <div className="row">
            <div className="col-sm-8 col-md-8 col-lg-8">
              <input
                className="form-control"
                type="number"
                value={binMaxItemQty}
                onKeyDown={formatInput}
                placeholder=""
                onChange={(e) => handleBimMaxItemQty(e)}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-sm-3 col-md-3 col-lg-3 p-0 scanSetingInpDivs">
          <div className="row m-0">
            <div className="col-sm-8 col-md-8 col-lg-8">
              <span className=" settingslblClass " style={{ float: 'left' }}>Not Found Item Cost</span>
            </div></div>
          <div className="row">
            <div className="col-sm-8 col-md-8 col-lg-8">
              <input id ="decimal_notFoundItemCost"
                className=" form-control"
                type="number"
                value={notFoundItemCost}
                onKeyDown={formatInput}
                placeholder=""
                onChange={(e) => handleNotFoundItemCost(e)}
                onBlur={(e) => handleNotFoundItemCost(e)}
                onKeyUp={(e) => handleNotFoundItemCost(e)}
              />
            </div>
          </div>
        </div>
        <div className="col-sm-3 col-md-3 col-lg-3 p-0 scanSetingInpDivs">
          <div className="row m-0">
            <div className="col-sm-8 col-md-8 col-lg-8">
              <span className=" settingslblClass " style={{ float: 'left' }}>
                Default I/B Box Max Qty
              </span>
            </div></div>
          <div className="row">
            <div className="col-sm-8 col-md-8 col-lg-8">
              <input
                className="form-control"
                type="number"
                value={boxMaxItemQty}
                onKeyDown={formatInput}
                placeholder=""
                onChange={(e) => handleBoxMaxItemQty(e)}
              />
            </div>
          </div>
        </div>
        <div className="col-sm-3 col-md-3 col-lg-3 p-0 scanSetingInpDivs">
          <div className="row m-0">
            <div className="col-sm-8 col-md-8 col-lg-8">
              <span className=" settingslblClass " style={{ float: 'left' }}>Scan Max Qty</span>
            </div></div>
          <div className="row">
            <div className="col-sm-8 col-md-8 col-lg-8">
              <input
                className="form-control"
                type="number"
                value={limitMaxQty}
                onKeyDown={formatInput}
                placeholder=""
                onChange={(e) => handleLimitMaxQty(e)}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="row mt-2 ml-2 mb-2 float-left">
      <div className="col-sm-6 col-md-6 col-lg-6 settings-tab-sub-title-div mt-5">
        <p className="settings-tab-sub-title">
          Force to Trash - Reason Codes
        </p>
        {forceTrashRCInputList.map((x, i) => {
          return (
            <div className="row m-0">
              {i === 0 ? (
                <p className="col-sm-12 col-md-12 col-lg-12 mb-0 pt-3 pl-4 pr-0 pb-0 font-16 text-align-left ">
                  Capture Reason Codes
                  {captureReasonCode ? (
                    <img
                      src={toggleIconOn}
                      alt="close "
                      className="p-3"
                      onClick={() => handleToggleChange(i, false)}
                    />
                  ) : (
                    <img
                      src={toggleIconOff}
                      alt="close "
                      className="p-3"
                      onClick={() => handleToggleChange(i, true)}
                    />
                  )}
                </p>
              ) : (
                <p></p>
              )}
              <div className="col-sm-4 col-md-4 col-lg-4 mt-2">
                {i === 0 ? (
                  <span className="form-input-settings-label fw-bold">
                    Reason Code
                  </span>
                ) : (
                  <span></span>
                )}
                <input
                  className="form-control"
                  name="reasonCode"
                  value={x.reasonCode}
                  onChange={(e) => handleForceTrashInputChange(e, i)}
                  placeholder="Enter Reason Code"
                />
              </div>
              <div className="col-sm-6 col-md-6 col-lg-6 mt-2">
                {i === 0 ? (
                  <span className="form-input-settings-label fw-bold">
                    Description
                  </span>
                ) : (
                  <span></span>
                )}
                <input
                  className="form-control"
                  name="description"
                  value={x.description}
                  placeholder="Enter Description"
                  onChange={(e) => handleForceTrashInputChange(e, i)}
                />
              </div>
              {i === 0 ? (
                <img
                  src={crossIcon}
                  alt="close "
                  className="closeIconMain"
                  onClick={() => handleForceTrashReviewRemoveClick(x, i)}
                />
              ) : (
                <img
                  src={crossIcon}
                  alt="close "
                  className="closeIconSub"
                  onClick={() => handleForceTrashReviewRemoveClick(x, i)}
                />
              )}
            </div>
          );
        })}
        <div className="col-sm-4 col-md-4 col-lg-4 pt-2 pl-0 mt-2">
          <span className="float-left">
            {" "}
            <img
              src={addIcon}
              alt="plus "
              className=""
              onClick={() => handleForceTrashReviewRCAddClick("", "")}
            />
            <span className="settingslblClass mt-0 ml-2">
              Add Reason Code
            </span>{" "}
          </span>
        </div>
      </div>
      <div className="col-sm-6 col-md-6 col-lg-6 settings-tab-sub-title-div mt-5">
        <p className="settings-tab-sub-title">Scan Groups</p>
        <div className="row m-0 settingsLocalScanRow"></div>
        {scanGroupsInputList.map((x, i) => {
          return (
            <div className="row m-0 mb-3">
              <div className="col-sm-2 col-md-2 col-lg-2 pr-0 ">
                {i === 0 ? (
                  <span className="form-input-settings-label fw-bold">
                    {" "}
                    Code
                  </span>
                ) : (
                  <span></span>
                )}
                <input
                  className="form-control"
                  name="scanGrpCode"
                  value={x.scanGrpCode}
                  placeholder="Enter Code"
                  onChange={(e) => handleScanGrpInputChange(e, i)}
                />
              </div>
              <div className="col-sm-4 col-md-4 col-lg-4  pr-0">
                {i === 0 ? (
                  <span className="form-input-settings-label fw-bold">
                    Description
                  </span>
                ) : (
                  <span></span>
                )}
                <input
                  className="form-control"
                  name="scanGrpDesc"
                  value={x.scanGrpDesc}
                  placeholder="Enter Scan Group Description"
                  onChange={(e) => handleScanGrpInputChange(e, i)}
                />
              </div>
              <div className="col-sm-5 col-md-5 col-lg-5">
                {i === 0 ? (
                  <span className="form-input-settings-label fw-bold">
                    Scan Station(s) format: csv
                  </span>
                ) : (
                  <span></span>
                )}
                <input
                  className="form-control"
                  name="scanStations"
                  value={x.scanStations}
                  placeholder="Enter Scan Stations"
                  onChange={(e) => handleScanGrpInputChange(e, i)}
                />
              </div>
              {i === 0 ? (
                <img
                  src={crossIcon}
                  alt="close "
                  className="col-sm-1 col-md-1 col-lg-1 closeIconScan mt-4 pt-3"
                  onClick={() => handleScanGrpsRemoveClick(x, i)}
                />
              ) : (
                <img
                  src={crossIcon}
                  alt="close "
                  className="col-sm-1 col-md-1 col-lg-1 closeIconScanSub mt-2 pt-2"
                  onClick={() => handleScanGrpsRemoveClick(x, i)}
                />
              )}
            </div>
          );
        })}
        <div className="col-sm-4 col-md-4 col-lg-4 pt-2 pl-0">
          <span className="float-left">
            {" "}
            <img
              src={addIcon}
              alt="plus "
              className=""
              onClick={() => handleScanGrpsAddClick("", "", "")}
            />
            <span className="settingslblClass mt-0 ml-2">Add Scan Group</span>{" "}
          </span>
        </div>
      </div>
    </div>
  </div>
);

  /* ****** Html Rendering ******** */
};

export default LocalScanSettings;
