import * as React from 'react';
import moment from 'moment';
import { OutBoundBox } from '../../../services/label-print-service/label-print-service';

type Props = {
    details: OutBoundBox;
};
export const BoxLabel = (props: Props) => {
    const details = props.details;
    const boxNumberOnPallet = () => {
        if (details?.boxNumberOnPallet) {
            return details?.boxNumberOnPallet + 1;
        } else if (details?.boxNumberOnPallet === 0) {
            return 1;
        } else {
            return '';
        }
    };
    return (
        <div className="label-details-view">
            <div className="label-row">
                <div className="label-name">User</div>
                <div className="label-value"> {details?.closedBy}</div>
            </div>
            <div className="label-row">
                <div className="label-name">Box #</div>
                <div className="label-value">{boxNumberOnPallet()}</div>
            </div>
            <div className="label-row">
                <div className="label-name">Location</div>
                <div className="label-value"> {details?.palletLocation}</div>
            </div>
            <div className="label-row">
                <div className="label-name">WH Location</div>
                <div className="label-value"> {details?.warehouseLocation}</div>
            </div>
            <div className="label-row">
                <div className="label-name">Closed Date</div>
                <div className="label-value">
                    {details?.closedOn && moment(details.closedOn).format('L')}
                </div>
            </div>
            <div className="label-row">
                <div className="label-name">BOX ID</div>
                <div className="label-value">{details?.outboundBoxId}</div>
            </div>
        </div>
    );
};
