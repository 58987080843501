import { FC, useEffect, useState } from 'react';
import { DropDownButtonComponent } from '@syncfusion/ej2-react-splitbuttons';
import './StatusButton.scss';
import { useSelector } from 'react-redux';
import { RootState } from '../../../redux/store';

interface IProps {
    handleChange: (str: string) => void;
    id: string;
    isCreditMemo?: boolean;
    disable?: boolean;
}

const ButtonControlBol: FC<IProps> = ({
    handleChange,
    id,
    disable,
    isCreditMemo,
}) => {
    const [Trimedid, SetTrimedId] = useState('');
    const [value, setValue] = useState('');
    const bolData = useSelector(
        (state: RootState) => state.invoiceReducer.bolDataById
    );
    console.log('bolData status', bolData);
    const filterRules = (currentStatus: string) => {
        switch (currentStatus) {
            case 'Draft':
                if (bolData?.id) {
                    if (bolData?.arInvoiceExists) {
                        return [];
                    } else {
                        return ['Released', 'Picked', 'Cancelled'];
                    }
                } else return [];

            // return ["Picked", "Cancelled"];
            case 'Released':
                return ['Draft', 'Picked', 'Cancelled'];
            case 'Picked':
                return [];
            case 'Cancelled':
                return [];
        }
    };
    const data = [
        {
            id: 'Draft',
            _id: 1,
            text: 'Draft',
        },
        {
            id: 'Released',
            _id: 4,
            text: 'Released',
        },
        {
            id: 'Picked',
            _id: 2,
            text: 'Picked',
        },
        {
            id: 'Cancelled',
            _id: 3,
            text: 'Cancelled',
        },
    ];
    useEffect(() => {
        let tt: any = data.find((e) => e.text === id);
        let tid: string = tt.id.replaceAll(' ', '');
        SetTrimedId(tid);
        setValue(tt.text);
    }, [id]);

    const handleSelectedData = (e: any) => {
        handleChange(e.item);
    };
    const filterArInvoiceStatus = (data: any[]) => {
        const rules = filterRules(id.replaceAll(' ', '').replaceAll(',', ''));
        return data.filter((el) => rules?.includes(el.id));
    };
    const handleDropdown = (e: any) => {
        if (e.items.length === 0) {
            const elem = e.element.parentElement;
            elem.style.display = 'none';
        }
    };
    return (
        <div className="statusbutton">
            {Trimedid !== '' && (
                <DropDownButtonComponent
                    id="status-button-dropdown"
                    className={`statusbutton_${Trimedid}`}
                    open={(e) => handleDropdown(e)}
                    style={
                        disable
                            ? {
                                  opacity: 0.7,
                                  pointerEvents: 'none',
                                  cursor: 'default',
                              }
                            : {}
                    }
                    items={disable ? undefined : filterArInvoiceStatus(data)}
                    content={value}
                    select={handleSelectedData}
                ></DropDownButtonComponent>
            )}
        </div>
    );
};

export default ButtonControlBol;
