import { FC, useCallback, useEffect, useRef, useState, useMemo } from "react";
import { TextBoxComponent } from "@syncfusion/ej2-react-inputs";
import CustomDropDown from "./CustomDropDown/CustomDropDown";
import CustomDropDownForTwoValues from "./CustomDropDown/CustomDropDownForTwoValues";

import {
  ButtonComponent,
  RadioButtonComponent,
} from "@syncfusion/ej2-react-buttons";
import {
  TabComponent,
  TabItemDirective,
  TabItemsDirective,
} from "@syncfusion/ej2-react-navigations";
// import { DropDownListComponent } from "@syncfusion/ej2-react-dropdowns";
import ListObject from "../listObject/FileListItem";
import LLModal from "../llModal/LLModal";
import NotesCard from "../notesCard/NotesCard";
import "./InputCollection.scss";
import ContentHeader from "../ContentHeader/ContentHeader";
import Divider from "../divider/Divider";
import { useDispatch, useSelector } from "react-redux";
import {
  setArInvoiceDefaultData,
  setArInvoiceSavedata,
  setIsInvoiceClear,
  setIsInvoiceSaved,
  setIsInvoiceUpdated,
  setScannedPalletsInfo,
} from "../../invoices/invoiceReducer";
import { RootState } from "../../../redux/store";
import {
  getCarrierListEndPoint,
  getCustomerListEndPoint,
  getDepartmentListEndPoint,
  getPeriodListEndPoint,
  getPickUPFromListEndPoint,
  getReclaimCenterListEndPoint,
  SaveArInvoicesEndPoint,
} from "../../invoices/utils";
import { useHistory } from "react-router-dom";
import axios from "axios";
import { DatePickerComponent } from "@syncfusion/ej2-react-calendars";
import notify from "../../../services/toasterService";
import moment from "moment";

interface IFiles {
  name: string;
  size: number;
  type: string;
  data: string | ArrayBuffer | null;
  file: File;
}

interface IInputCollection {
  showInvoice?: boolean;
  showBol?: boolean;
  showBilling?: boolean;
  showRaNumber?: boolean;
  attachment?: boolean;
  showReclaimCenter?: boolean;
  showPeriod?: boolean;
  showDescription?: boolean;
  showCustomer?: boolean;
  showAccount?: boolean;
  showPickupFrom?: boolean;
  showTrainerNumber?: boolean;
  showAttachment?: boolean;
  showDeliverTo?: boolean;
  showSealNumber?: boolean;
  showNotes?: boolean;
  showCarrier?: boolean;
  showPickedDate?: boolean;
  handleChange: (data: any) => void;
}
function paramsToObject(entries: any) {
  const result: any = {};
  for (const [key, value] of entries) {
    result[key] = value;
  }
  return result;
}

const useInputCollection: FC<IInputCollection> = ({ handleChange }) => {
  const history = useHistory();
  // const urlParams = new URLSearchParams(history.location.search);
  // const paramEntries = urlParams.entries();
  // const params = paramsToObject(paramEntries);

  const [viewData, setViewData] = useState({
    reclaimcenterName: "",
    customerName: "",
    departmentName: "",
    warehouseName: "",
    city: "",
    carrierName: "",
  });
  const dispatch = useDispatch();
  const arInvoiceSavedata = useSelector(
    (store: RootState) => store.invoiceReducer.arInvoiceSaveData
  );
  const arInvoiceDefaultData = useSelector(
    (store: RootState) => store.invoiceReducer.arInvoiceDefaultData
  );

  const fyYearData: any = useSelector(
    (store: RootState) => store.contactReducer.fiscalYearPeriods
  );
  const isInvoiceSaved = useSelector(
    (store: RootState) => store.invoiceReducer.isInvoiceSaved
  );

  const isInvoiceClear = useSelector(
    (store: RootState) => store.invoiceReducer.isInvoiceClear
  );
  const [isViewOnly, setIsViewOnly] = useState(() => {
    if (typeof arInvoiceSavedata.status === "string") {
      return arInvoiceSavedata.status === "Draft" ? false : true;
    } else {
      return arInvoiceSavedata.status?.status === "Draft" ? false : true;
    }
  });
  const [originalData, setOriginalData] = useState(arInvoiceSavedata);
  const [invoice, setInvoice] = useState<string>("");
  useEffect(() => {
    if (typeof arInvoiceSavedata.status === "string") {
      setIsViewOnly(arInvoiceSavedata.status === "Draft" ? false : true);
    } else if (arInvoiceSavedata.status?.status) {
      setIsViewOnly(
        arInvoiceSavedata.status?.status === "Draft" ? false : true
      );
    }
  }, [arInvoiceSavedata.status]);

  //////dynamic list/////////
  const [recalaimCenterList, SetReclaimCenterList] = useState<
    { [key: string]: Object }[]
  >([]);
  const [carrierList, SetCarrierList] = useState<{ [key: string]: Object }[]>(
    []
  );
  const [GlAccountList, SetGlAccountList] = useState<
    { [key: string]: Object }[]
  >([]);
  const [DepartmentsList, SetDepartmentsList] = useState<
    { [key: string]: Object }[]
  >([]);
  const [PickUpFromList, SetPickupFromList] = useState<
    { [key: string]: Object }[]
  >([]);
  const [CustomerList, SetCustomerList] = useState<{ [key: string]: Object }[]>(
    []
  );
  const [DeliverToList, SetDeliverToList] = useState<
    { [key: string]: Object }[]
  >([]);
  const [PeriodList, SetPeriodList] = useState<{ [key: string]: Object }[]>([]);

  ///////////////////////////

  const baseURL = process.env.REACT_APP_MASTER_URL;
  const clientContext = process.env.REACT_APP_CLIENT_CONTEXT;

  const [description, setDescription] = useState<string>(
    arInvoiceSavedata.description ? arInvoiceSavedata.description : ""
  );
  const [pickupFrom, setPickupFrom] = useState<string>(
    arInvoiceSavedata.pickFromId ? arInvoiceSavedata.pickFromId : ""
  );
  const [trailerNumber, setTrailerNumber] = useState<string>(
    arInvoiceSavedata.trailerNumber ? arInvoiceSavedata.trailerNumber : ""
  );
  const [deliverTo, setDeliverTo] = useState<string>(
    arInvoiceSavedata.primaryAddressId ? arInvoiceSavedata.primaryAddressId : ""
  );
  const [sealNumber, setSealNumber] = useState<string>(
    arInvoiceSavedata.sealNumber ? arInvoiceSavedata.sealNumber : ""
  );
  const [notes, setNotes] = useState<string>(
    arInvoiceSavedata.notes ? arInvoiceSavedata.notes : ""
  );
  const [carrier, setCarrier] = useState<string>(
    arInvoiceSavedata.carrierId ? arInvoiceSavedata.carrierId : ""
  );
  const [invoiceDate, setInvoiceDate] = useState<string>(
    arInvoiceSavedata.createdDate ? arInvoiceSavedata.createdDate : ""
  );
  // const [pickedDate, setPickedDate] = useState<Date>(
  //   arInvoiceSavedata.pickedDate
  //     ? new Date(arInvoiceSavedata.pickedDate)
  //     : new Date()
  // );
  // const [extractDate, setExtractDate] = useState<Date>(
  //   arInvoiceSavedata.extractDate
  //     ? new Date(arInvoiceSavedata.extractDate)
  //     : new Date()
  // );
  const [includeMessage, setIncludeMessage] = useState(
    arInvoiceSavedata.invoiceNumber.charAt(0).includes("C") ? true : arInvoiceSavedata.doNotFreeze
  );
  const [status, setStatus] = useState(
    // arInvoiceSavedata.status ? arInvoiceSavedata.status : "Draft"
    () => {
      if (typeof arInvoiceSavedata.status === "string") {
        return arInvoiceSavedata.status === "Draft"
          ? "Draft"
          : arInvoiceSavedata.status;
      } else if (arInvoiceSavedata.status?.status) {
        return arInvoiceSavedata.status?.status === "Draft"
          ? "Draft"
          : arInvoiceSavedata.status?.status;
      }
    }
  );
  const [salvageItem, setSalvageItem] = useState([]);
  const [showDeliverTo, setShowDeliverTo] = useState(true);
  const [reclaimCenter, setReclaimCenter] = useState(
    arInvoiceSavedata.reclaimCenterId ? arInvoiceSavedata.reclaimCenterId : ""
  );
  const [primaryAddress, setPrimaryAddress] = useState("");

  const period = useMemo(() => {
    return arInvoiceSavedata.period
    ? arInvoiceSavedata.period
    : (fyYearData ? `${fyYearData?.calendarName}-${fyYearData?.week}` : '')
  },[fyYearData, arInvoiceSavedata]);

  const [pallets, setPallets] = useState([]);
  const [fixedPrices, setFixedPrices] = useState([]);
  const [customer, setCustomer] = useState<string>(
    arInvoiceSavedata.customerId ? arInvoiceSavedata.customerId : ""
  );
  const [glAccount, setGLAccount] = useState<string>(
    arInvoiceSavedata.glAccounts ? arInvoiceSavedata.glAccounts : ""
  );
  const [Department, setDepartment] = useState<string>(
    arInvoiceSavedata.depratmentId ? arInvoiceSavedata.depratmentId : ""
  );

  const [showAttachmentModal, setShowAttachmentModal] = useState(false);
  const [selectedAttachmentTab, setSelectedAttachmentTab] = useState(0);

  useEffect(() => {
    if (isInvoiceSaved) {
      setOriginalData(arInvoiceSavedata);
      dispatch(setIsInvoiceSaved(false));
    }
  }, [isInvoiceSaved]);

  useEffect(() => {
    if (isInvoiceClear) {
      setOriginalData({ ...arInvoiceDefaultData });
      dispatch(setIsInvoiceClear(false));
      setDescription("");
      setPickupFrom("");
      setTrailerNumber("");
      setDeliverTo("");
      setSealNumber("");
      setNotes("");
      setCarrier("");
      setIncludeMessage(true);
      setStatus("Draft");
      setReclaimCenter("");
      setCustomer("");
      setGLAccount("");
      setDepartment("");
      setInvoiceDate("");
    }
  }, [isInvoiceClear]);

  const [loadedAttachmentFiles, setLoadedAttachmentFiles] = useState<IFiles[]>(
    []
  );
  //console.log(">>>>>>>>>>>>>> period: ", period);
  useEffect(() => {
    if (
      (originalData.status === "Draft" &&
        originalData.status !== arInvoiceSavedata.status) ||
      (originalData.status?.status === "Draft" &&
        originalData.status?.status !== arInvoiceSavedata.status?.status)
    ) {
      setDescription(originalData.description ? originalData.description : "");
      setPickupFrom(originalData.pickFromId ? originalData.pickFromId : "");
      setTrailerNumber(
        originalData.trailerNumber ? originalData.trailerNumber : ""
      );
      setDeliverTo(
        originalData.primaryAddressId ? originalData.primaryAddressId : ""
      );
      setSealNumber(originalData.sealNumber ? originalData.sealNumber : "");
      setNotes(originalData.notes ? originalData.notes : "");
      setCarrier(originalData.carrierId ? originalData.carrierId : "");
      setInvoiceDate(originalData.createdDate ? originalData.createdDate : "");
      // setPickedDate(
      //   originalData.pickedDate ? new Date(originalData.pickedDate) : new Date()
      // );
      // setExtractDate(
      //   originalData.extractDate
      //     ? new Date(originalData.extractDate)
      //     : new Date()
      // );
      // setIncludeMessage(
      //   originalData.doNotFreeze
      // );
      setStatus(originalData.status.status ? originalData.status : "Draft");
      setReclaimCenter(
        originalData.reclaimCenterId ? originalData.reclaimCenterId : ""
      );
      // setPeriod(originalData.period ? originalData.period : "");
      setCustomer(originalData.customerId ? originalData.customerId : "");
      setGLAccount(originalData.glAccounts ? originalData.glAccounts : "");
      setDepartment(originalData.departmentId ? originalData.departmentId : "");
    }
  }, [arInvoiceSavedata.status, originalData]);

  // useEffect(() => {
  //   if (fyYearData) {
  //     //console.log(">>>>>>>>>>>> period in fyYears data: ", fyYearData);
  //     setPeriod(`${fyYearData.calendarName}-${fyYearData.week}`);
  //   }
  // }, [fyYearData]);
  useEffect(() => {
    // if (isViewOnly) {
    if (isViewOnly) {
      // axios
      //   .get(
      //     `https://dev0-revlog.limnlabs.com/api/revlog/CS0001/v1/invoice/arinvoices/items/${arInvoiceSavedata.invoiceNumber}`
      //   )
      //   .then((res: any) => {
      //     if (res) {
      //       dispatch(setArInvoiceSavedata(res.data));
      //     }
      //   });
      const endpoint = SaveArInvoicesEndPoint(arInvoiceSavedata.invoiceNumber);
      axios.get(endpoint).then(async (result: any) => {
        if (!result) {
        } else {
          console.log(result?.data);
          dispatch(setArInvoiceSavedata(result?.data));
        }
      });
      //}
    }
  }, []);

  useEffect(() => {
    if (invoice === "" && arInvoiceSavedata.invoiceNumber !== "") {
      setInvoice(arInvoiceSavedata.invoiceNumber ? arInvoiceSavedata.invoiceNumber : arInvoiceDefaultData.invoiceNumber);
    }
  }, [arInvoiceSavedata]);

  useEffect(() => {
    //console.log("arinvoice save data", loadedAttachmentFiles);
  }, [loadedAttachmentFiles]);
  //console.log(">>>>>>>> isViewOnly: ", isViewOnly, arInvoiceSavedata, status);

  useEffect(() => {
    handleChange({
      invoiceNumber: invoice,
      description,
      trailerNumber,
      sealNumber,
      notes,
      carrier,
      pickedDate: arInvoiceSavedata.pickedDate,
      extractDate: arInvoiceSavedata.extractDate,
      doNotFreeze: includeMessage,
      status,
      salvageItem,
      primaryAddressId: deliverTo,
      reclaimCenter,
      primaryAddress,
      pallets,
      fixedPrices,
      customer,
      glAccount,
      Department,
      period: period,
      invoiceDate,
    });
  }, [
    invoice,
    description,
    pickupFrom,
    trailerNumber,
    deliverTo,
    sealNumber,
    notes,
    carrier,
    includeMessage,
    status,
    salvageItem,
    reclaimCenter,
    primaryAddress,
    period,
    pallets,
    fixedPrices,
    customer,
    glAccount,
    Department,
    invoiceDate,
  ]);

  useEffect(() => {
    dispatch(
      setArInvoiceSavedata({
        ...arInvoiceSavedata,
        carrierId: carrier,
        customerId: +customer,
        description: description,
        doNotFreeze: includeMessage,
        extractDate: arInvoiceSavedata.extractDate,
        files: loadedAttachmentFiles.map((e) => e.file),
        glAccounts: glAccount,
        invoiceNumber: invoice,
        notes: notes,
        period: period,
        pickFromId: pickupFrom,
        pickedDate: arInvoiceSavedata.pickedDate,
        primaryAddressId: deliverTo,
        reclaimCenterId: reclaimCenter,
        sealNumber: sealNumber,
        status: status,
        trailerNumber: trailerNumber,
        departmentId: Department,
        invoiceDate: invoiceDate,
      })
    );
  }, [
    description,
    pickupFrom,
    trailerNumber,
    deliverTo,
    sealNumber,
    notes,
    carrier,
    includeMessage,
    status,
    salvageItem,
    reclaimCenter,
    primaryAddress,
    period,
    pallets,
    fixedPrices,
    customer,
    glAccount,
    Department,
    loadedAttachmentFiles,
    invoiceDate,
  ]);

  //////fetch reclaim center list///
  useEffect(() => {
    const endPointRC = getReclaimCenterListEndPoint();
    handleGetRCList(endPointRC);
    const endPointCarrier = getCarrierListEndPoint();
    handleGetCarrierList(endPointCarrier);
    const endpointPeriod = getPeriodListEndPoint();
    handleGetPeriodList(endpointPeriod);
    const endpointDept = getDepartmentListEndPoint();
    handleGetDeptList(endpointDept);
    const endpointPickup = getPickUPFromListEndPoint();
    handleGetPickupFrom(endpointPickup);
    const endpointCust = getCustomerListEndPoint();
    handleGetCustList(endpointCust);
  },[])

  const handleGetRCList = async (endpoint: string) => {
    const result = await axios
      .get(endpoint)
      .then((results: any) => {
        if (results && results.data) {
          SetReclaimCenterList(results.data);
          if (isViewOnly) {
            let select = results.data.find((e: any) => e.id === reclaimCenter);
            setViewData((prevState) => {
              return {
                ...prevState,
                reclaimcenterName: select?.reclaimcenterName,
              };
            });
          }
        } else {
          notify("Failed", "error");
        }
      })
      .catch((error: any) => {
        if (error.response) {
          notify(error.response?.data?.message, "error");
        }
      });
  };
  const handleGetCarrierList = async (endpoint: string) => {
    const result = await axios
      .get(endpoint)
      .then((results: any) => {
        if (results && results.data) {
          SetCarrierList(results.data);
          if (isViewOnly) {
            let select = results.data.find((e: any) => e.id === carrier);
            setViewData((prevState) => {
              return {
                ...prevState,
                carrierName: select?.carrierName,
              };
            });
          }
        } else {
          notify("Failed", "error");
        }
      })
      .catch((error: any) => {
        if (error.response) {
          notify(error.response?.data?.message, "error");
        }
      });
  };
  const handleGetPeriodList = async (endpoint: string) => {
    const result = await axios
      .get(endpoint)
      .then((results: any) => {
        if (results && results.data) {
          SetPeriodList(results.data);
        } else {
          notify("Failed", "error");
        }
      })
      .catch((error: any) => {
        if (error.response) {
          notify(error.response?.data?.message, "error");
        }
      });
  };
  // useEffect(() => {
  //   const endpoint = getReclaimCenterListEndPoint();
  //   fetch(endpoint)
  //     .then((data) => data.json())
  //     .then((result) => {
  //       if (!result || result.length === 0) {
  //         //alert("Fetch list successful, but no list items are present");
  //       } else {
  //         //console.log("result", result);

  //         SetReclaimCenterList(result);
  //         if (isViewOnly) {
  //           let select = result.find((e: any) => e.id === reclaimCenter);
  //           setViewData((prevState) => {
  //             return {
  //               ...prevState,
  //               reclaimcenterName: select?.reclaimcenterName,
  //             };
  //           });
  //         }
  //         //alert("An error has occurd while fetching a list: " + result.error);
  //       }
  //     })
  //     .catch((error) => {
  //       //console.log("Catched error", error);
  //     });
  // }, []);

  ///////fetch carrier list////////
  // useEffect(() => {
  //   const endpoint = getCarrierListEndPoint();
  //   fetch(endpoint)
  //     .then((data) => data.json())
  //     .then((result) => {
  //       if (!result || result.length === 0) {
  //         //alert("Fetch list successful, but no list items are present");
  //       } else {
  //         //console.log("result", result);
  //         SetCarrierList(result);
  //         if (isViewOnly) {
  //           let select = result.find((e: any) => e.id === carrier);
  //           setViewData((prevState) => {
  //             return {
  //               ...prevState,
  //               carrierName: select?.carrierName,
  //             };
  //           });
  //         }
  //         //alert("An error has occurd while fetching a list: " + result.error);
  //       }
  //     })
  //     .catch((error) => {
  //       //console.log("Catched error", error);
  //     });
  // }, []);
  ////////fetch period list///////////////
  // const endpoint = getPeriodListEndPoint();
  // useEffect(() => {
  //   fetch(endpoint)
  //     .then((data) => data.json())
  //     .then((result) => {
  //       if (!result || result.length === 0) {
  //         alert("Fetch list successful, but no list items are present");
  //       } else {
  //         //console.log("result", result);
  //         SetPeriodList(result);
  //       }
  //     })
  //     .catch((error) => {
  //       //console.log("Catched error", error);
  //     });
  // }, []);
  ///////fetch Gl Acccount list////////
  // useEffect(() => {
  //   const endpoint = getGlAcoountListEndPoint();
  //   fetch(endpoint)
  //     .then((data) => data.json())
  //     .then((result) => {
  //       if (!result || result.length === 0) {
  //         alert("Fetch list successful, but no list items are present");
  //       } else {
  //         //console.log("result", result);
  //         SetGlAccountList(result);
  //       }
  //     })
  //     .catch((error) => {
  //       //console.log("Catched error", error);
  //     });
  // }, []);
  // useEffect(() => {
  //   if (Department) {
  //     SetGlAccountList(Department.glAccounts);
  //   }
  // }, [Department]);
  ///////fetch Departments list////////
  const handleGetDeptList = async (endpoint: string) => {
    const result = await axios
      .get(endpoint)
      .then((results: any) => {
        if (results && results.data) {         
          SetDepartmentsList(results.data);
          const selectedDepartment: any = results.data.find(
            (el: any) => el.departmentName == arInvoiceSavedata.departmentName
          );
          setDepartment(selectedDepartment.id);
          SetGlAccountList(selectedDepartment?.glAccounts || []);
          if (isViewOnly) {
            let select = results.data.find((e: any) => e.id === Department);
            setViewData((prevState) => {
              return {
                ...prevState,
                departmentName: select?.departmentName,
              };
            });
          }
        } else {
          notify("Failed", "error");
        }
      })
      .catch((error: any) => {
        if (error.response) {
          notify(error.response?.data?.message, "error");
        }
      });
  };
  const handleGetPickupFrom = async (endpoint: string) => {
    const result = await axios
      .get(endpoint)
      .then((results: any) => {
        if (results && results.data) {
          SetPickupFromList(results.data);
          if (isViewOnly) {
            let select = results.data.find((e: any) => e.id === pickupFrom);
            setViewData((prevState) => {
              return {
                ...prevState,
                warehouseName: select?.warehouseName,
              };
            });
          }
        } else {
          notify("Failed", "error");
        }
      })
      .catch((error: any) => {
        if (error.response) {
          notify(error.response?.data?.message, "error");
        }
      });
  };
  // useEffect(() => {
  //   const endpoint = getDepartmentListEndPoint();
  //   fetch(endpoint)
  //     .then((data) => data.json())
  //     .then((result) => {
  //       if (!result || result.length === 0) {
  //         //alert("Fetch list successful, but no list items are present");
  //       } else {
  //         // console.log(arInvoiceSavedata);

  //         SetDepartmentsList(result);
  //         const selectedDepartment: any = result.find(
  //           (el: any) => el.departmentName == arInvoiceSavedata.departmentName
  //         );

  //         setDepartment(selectedDepartment.id);
  //         SetGlAccountList(selectedDepartment?.glAccounts || []);
  //         if (isViewOnly) {
  //           let select = result.find((e: any) => e.id === Department);
  //           setViewData((prevState) => {
  //             return {
  //               ...prevState,
  //               departmentName: select?.departmentName,
  //             };
  //           });
  //         }
  //       }
  //     })
  //     .catch((error) => {
  //       //console.log("Catched error", error);
  //     });
  // }, []);
  ///////fetch Pickup From list////////
  // useEffect(() => {
  //   const endpoint = getPickUPFromListEndPoint();
  //   fetch(endpoint)
  //     .then((data) => data.json())
  //     .then((result) => {
  //       if (!result || result.length === 0) {
  //         //alert("Fetch list successful, but no list items are present");
  //       } else {
  //         //console.log("result", result);
  //         SetPickupFromList(result);

  //         if (isViewOnly) {
  //           let select = result.find((e: any) => e.id === pickupFrom);
  //           setViewData((prevState) => {
  //             return {
  //               ...prevState,
  //               warehouseName: select?.warehouseName,
  //             };
  //           });
  //         }
  //       }
  //     })
  //     .catch((error) => {
  //       //console.log("Catched error", error);
  //     });
  // }, []);

  ///////fetch Customer list////////
  const handleGetCustList = async (endpoint: string) => {
    const result = await axios
      .get(endpoint)
      .then((results: any) => {
        if (results && results.data) {
          SetCustomerList(results.data);
          if (isViewOnly) {
            let select = results.data.find((e: any) => e.id === customer);
            setViewData((prevState) => {
              return {
                ...prevState,
                customerName: select?.customerName,
              };
            });
          }
        } else {
          notify("Failed", "error");
        }
      })
      .catch((error: any) => {
        if (error.response) {
          notify(error.response?.data?.message, "error");
        }
      });
  };
  // useEffect(() => {
  //   const endpoint = getCustomerListEndPoint();
  //   fetch(endpoint)
  //     .then((data) => data.json())
  //     .then((result) => {
  //       if (!result || result.length === 0) {
  //         //alert("Fetch list successful, but no list items are present");
  //       } else {
  //         SetCustomerList(result);
  //         if (isViewOnly) {
  //           let select = result.find((e: any) => e.id === customer);
  //           setViewData((prevState) => {
  //             return {
  //               ...prevState,
  //               customerName: select?.customerName,
  //             };
  //           });
  //         }
  //       }
  //     })
  //     .catch((error) => {
  //       //console.log("Catched error", error);
  //     });
  // }, []);

  const fileInput = useRef(null);

  const attachmentsTabs = ["Internal", "Customer"];

  const date = "Oct 18, 2021, 8:07pm EST";
  const dummyText =
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Velit aliquet sed nibh laoreet urna. Sed augue gravida elementum at. Mi, mauris, ornare interdum interdum in massa nascetur. Tempus, tempor nec adipiscing donec mi. ";

  const handleDescriptionChange = (value: string) => {
    setDescription(value);
    dispatch(setIsInvoiceUpdated(true));
  };

  const handlePickupFromChange = (value: string) => {
    setPickupFrom(value);
    dispatch(setIsInvoiceUpdated(true));
  };
  const handleTrailerNumberChange = (value: string) => {
    setTrailerNumber(value);
    dispatch(setIsInvoiceUpdated(true));
  };

  const handleDeliverToChange = (value: string) => {
    setDeliverTo(value);
    dispatch(setIsInvoiceUpdated(true));
  };
  const handleSealNumberChange = (value: string) => {
    setSealNumber(value);
   dispatch(setIsInvoiceUpdated(true));
  };
  const handleNotesChange = (value: any) => {
    setNotes(value);
    dispatch(setIsInvoiceUpdated(true));
  };
  const handleInvoiceDateChange = (val: any) => {
    setInvoiceDate(val.value);
    //dispatch(setIsInvoiceUpdated(true));
  };

  // const handleExtractDateChange = (event: any) => {
  //   setExtractDate(event.value);
  // };
  // const handlePickedDateChange = (event: any) => {
  //   setPickedDate(event.value);
  // };

  const handleAttachmentTabSelection = (params: any) => {
    setSelectedAttachmentTab(params.selectedIndex);
  };

  const handleRadiobutton = (value: string) => {
    const val = value === "true" ? true : false;
    setIncludeMessage(val);
    dispatch(setIsInvoiceUpdated(true));
  };

  const handleFileInputChange = useCallback((e: any) => {
    const file = e.currentTarget.files[0];
    const fileReader = new FileReader();

    fileReader.onload = () => {
      const newFile: IFiles = {
        name: file.name,
        type: file.type,
        size: file.size,
        data: fileReader.result,
        file: file,
      };
      addLoadedFiles(newFile);
    };

    fileReader.onabort = () => {};

    fileReader.onerror = () => {};
    fileReader.readAsDataURL(file);
    e.preventDefault();
    e.stopPropagation();
  }, []);

  const addLoadedFiles = (file: IFiles) => {
    setLoadedAttachmentFiles((prev) => [...prev, file]);
  };

  const removeLoadedFiles = (fileName: string) => {
    const updatedLoadedFiles = loadedAttachmentFiles.filter(
      (file) => file.name !== fileName
    );
    setLoadedAttachmentFiles(updatedLoadedFiles);
  };
  const [deliverToView, setDeliverToView] = useState<string>("");
  /////set deliver to list/////
  // useEffect(() => {
  //   if (CustomerList) {
  //     let dt: any = CustomerList.filter((e) => e.id == customer);
  //     //console.log(">>>>>>>>> dt", dt);
  //     // var ddlSelectCustType = document.getElementById(
  //     //   "ddlDeliverTo"
  //     // ) as HTMLSelectElement;
  //     // if (ddlSelectCustType) {
  //     //   ddlSelectCustType.length = 0;
  //     // }
  //     SetDeliverToList(dt[0]?.address);
    
  //     // setDeliverToView(dt[0]?.address.map((i: any)=>{
  //     //    i.country
  //     // }))
  //     if (isViewOnly) {
  //       let select = dt[0]?.address.find((e: any) => e.id === deliverTo);
  //       setViewData((prevState) => {
  //         return {
  //           ...prevState,
  //           city: select?.city? select?.city +', '+select?.state :'',
           
  //         };
  //       });
  //     }
  //   }
  // }, [CustomerList, customer, deliverTo, isViewOnly]);

  const HandleSetDeliverTo = (e: any) =>{
        
    // var ddlSelectPickup = document.getElementById(
    //   "ddlDeliverTo"
    // ) as HTMLSelectElement;
    // ddlSelectPickup.selectedIndex = 0;
    setDeliverTo(e);
    dispatch(setIsInvoiceUpdated(true));
  }

  const handleSetCustomer = (val: any) =>{
      var ddlSelectCustType = document.getElementById(
        "ddlDeliverTo"
      ) as HTMLSelectElement;
      if (ddlSelectCustType) {
        ddlSelectCustType.selectedIndex = 0; 
      }
    setCustomer(val);
    setShowDeliverTo(false)
    handleDeliverToDetails(val);
    dispatch(setIsInvoiceUpdated(true));
  }
    const handleDeliverToDetails = (val: any) =>{
    if (CustomerList) {
      let dt: any = CustomerList.filter((e) => e.id == parseInt(val));
      SetDeliverToList(dt[0]?.address);
      if (isViewOnly) {
        let select = dt[0]?.address.find((e: any) => e.id === deliverTo);
        setViewData((prevState) => {
          return {
            ...prevState,
            city: select?.city? select?.city +', '+select?.state :'',
           
          };
        });
      }
      dispatch(setIsInvoiceUpdated(true));
    }

  }
  const AttachmentModalContent = () => {
    return (
      <div>
        <ContentHeader value="Invoice Attachment" />
        <Divider />
        <TabComponent
          heightAdjustMode="Auto"
          selected={handleAttachmentTabSelection}
        >
          <TabItemsDirective>
            {attachmentsTabs.map((tab) => {
              return <TabItemDirective key={tab} header={{ text: tab }} />;
            })}
          </TabItemsDirective>
        </TabComponent>
        <div className="attachment-modal-content">
          <div className="attachment-modal-filedrop">
            <p style={{ textAlign: "center" }}>Files</p>
            <div className="attachment-modal-filesection">
              <input
                type="file"
                onDragOver={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                }}
                onDragEnter={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                }}
                name="invoice-attachment-input"
                id="invoice-attachment-input"
                ref={fileInput}
                onDrop={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                }}
                onChange={handleFileInputChange}
              />
              <p>Drag and Drop files here</p>

              {fileInput && fileInput.current && (
                // @ts-ignore
                <ButtonComponent onClick={() => fileInput.current.click()}>
                  Browse
                </ButtonComponent>
              )}
            </div>
            <div className="attachment-modal-filedroplist">
              {loadedAttachmentFiles.map((file) => (
                <ListObject
                  text={file.name}
                  key={file.name}
                  bgColor="#bbbeee"
                  color="#000"
                  handleDelete={removeLoadedFiles}
                />
              ))}
            </div>
          </div>
          <div className="attachment-modal-notes">
            <NotesCard
              date={date}
              note={dummyText}
              onTextAreaChange={() => {}}
            />
            <NotesCard
              date={date}
              note={dummyText}
              onTextAreaChange={() => {}}
            />
          </div>
        </div>
      </div>
    );
  };
  //console.log(">>>>>>>>>>>>>>>>> deliver to list: ", DeliverToList);
  // console.log(arInvoiceSavedata);
  // console.log(DepartmentsList)
  return (
    <>
      <div className="col-sm-12 row mt-4 p-0 ar-invoice-row">
        <div className="col-sm-4">
          <div className="col-sm-4 pr-3">
            <span className=" settingslblClass fw-bold">
              Origin {!isViewOnly && <sup className="requiredStar">*</sup>}
            </span>
          </div>
          <div className="col-sm-8  m-0 p-0">
            <div>
              {!isViewOnly && (
                <CustomDropDown
                  data={recalaimCenterList ? recalaimCenterList : []}
                  valueField={"id"}
                  textField={"reclaimcenterName"}
                  onChange={(value: any) => {
                    setReclaimCenter(value);
                    dispatch(setIsInvoiceUpdated(true))
                  }}
                  selectedOption={arInvoiceSavedata.reclaimCenterName}
                />
              )}
              {isViewOnly && (
                <input
                  className="form-control"
                  type="text"
                  value={arInvoiceSavedata.reclaimCenterName}
                  disabled={isViewOnly}
                />
              )}
            </div>
          </div>
        </div>
        <div className="col-sm-4">
          <div className="col-sm-4">
            <span className=" settingslblClass fw-bold">Period</span>
          </div>
          <div className="col-sm-8  m-0 p-0">
            <input
              readOnly
              className="form-control"
              type="text"
              value={
                period
                  ? period
                  : fyYearData
                  ? `${fyYearData?.calendarName}-${fyYearData?.week}`
                  : ""
              }
            />
          </div>
        </div>
        <div className="col-sm-4">
          <div className="col-sm-4 pr-3 pr-3">
            <span className=" settingslblClass fw-bold">
              Description{!isViewOnly && <sup className="requiredStar">*</sup>}
            </span>
          </div>
          <div className="col-sm-8  m-0 p-0">
            <input
              className="form-control"
              type="text"
              onChange={(e: any) => handleDescriptionChange(e.target.value)}
              defaultValue={description}
              disabled={isViewOnly}
            />
          </div>
        </div>
      </div>
      <div className="col-sm-12 row mt-4 p-0 ar-invoice-row">
        <div className="col-sm-4">
          <div className="col-sm-4 cus-field">
            <span className=" settingslblClass fw-bold">
              Customer {!isViewOnly && <sup className="requiredStar">*</sup>}
            </span>
          </div>
          <div className="col-sm-8  m-0 p-0">
            <div>
              {!isViewOnly && (
                <CustomDropDown
                  data={CustomerList ? CustomerList : []}
                  valueField={"id"}
                  textField={"customerName"}
                  onChange={(value: any) => {
                    handleSetCustomer(value);
                  }}
                  selectedOption={
                    arInvoiceSavedata.customerName
                      ? arInvoiceSavedata.customerName
                      : ""
                  }
                />
              )}
              {isViewOnly && (
                <input
                  className="form-control"
                  type="text"
                  value={arInvoiceSavedata.customerName}
                  disabled={isViewOnly}
                />
              )}
            </div>
          </div>
        </div>
        <div className="col-sm-4">
          <div className="col-sm-4 pr-3">
            <span className=" settingslblClass fw-bold">
              Department{!isViewOnly && <sup className="requiredStar">*</sup>}
            </span>
          </div>
          <div className="col-sm-8  m-0 p-0">
            {!isViewOnly && (
              <CustomDropDown
                data={DepartmentsList ? DepartmentsList : []}
                valueField={"id"}
                textField={"departmentName"}
                onChange={(value: any) => {
                  const selectedDepartment: any = DepartmentsList.find(
                    (el) => el.id == value
                  );
                  // console.log(
                  //   ">>>>>>>>>>>>>> selectedDepartment: ",
                  //   selectedDepartment
                  // );
                  SetGlAccountList(
                    selectedDepartment?.glAccounts
                      ? [...selectedDepartment?.glAccounts]
                      : []
                  );
                  setDepartment(value);
                  setGLAccount(selectedDepartment?.glAccounts[0]?.id);
                  dispatch(setIsInvoiceUpdated(true));
                }}
                selectedOption={arInvoiceSavedata.departmentName}
              />
            )}
            {isViewOnly && (
              <input
                className="form-control"
                type="text"
                value={arInvoiceSavedata.departmentName}
                disabled={isViewOnly}
              />
            )}
          </div>
        </div>
        <div className="col-sm-4">
          <div className="col-sm-4 pr-3">
            <span className=" settingslblClass fw-bold">
              GL Account{!isViewOnly && <sup className="requiredStar">*</sup>}
            </span>
          </div>
          <div className="col-sm-8  m-0 p-0">
            {!isViewOnly && (
              <CustomDropDown
                data={GlAccountList ? GlAccountList : []}
                valueField={"id"}
                textField={"glAccountName"}
                onChange={(value: any) => {
                  setGLAccount(value);
                  dispatch(setIsInvoiceUpdated(true))
                }}
                selectedOption={arInvoiceSavedata.glAccountName}
              />
            )}
            {isViewOnly && (
              <input
                className="form-control"
                type="text"
                value={arInvoiceSavedata.glAccountName}
                disabled={isViewOnly}
              />
            )}
          </div>
        </div>
      </div>
      <div className="col-sm-12 row mt-4 p-0 ar-invoice-row">
        <div className="col-sm-4">
          <div className="col-sm-4 pr-3">
            <span className=" settingslblClass fw-bold">
              Pickup From{!isViewOnly && <sup className="requiredStar">*</sup>}
            </span>
          </div>
          <div className="col-sm-8  m-0 p-0">
            <div>
              {!isViewOnly && (
                <CustomDropDown
                  data={PickUpFromList ? PickUpFromList : []}
                  valueField={"id"}
                  textField={"warehouseName"}
                  onChange={(value: any) => {
                    setPickupFrom(value);
                    dispatch(setIsInvoiceUpdated(true));
                  }}
                  // @ts-ignore
                  selectedOption={
                    PickUpFromList
                      ? PickUpFromList.find(
                          (el) => el.id == arInvoiceSavedata.pickFromId
                        )?.warehouseName
                      : ""
                  }
                />
              )}
              {isViewOnly && (
                <input
                  className="form-control"
                  type="text"
                  value={arInvoiceSavedata.warehouseName}
                  disabled={isViewOnly}
                />
              )}
            </div>
          </div>
        </div>
        <div className="col-sm-4">
          <div className="col-sm-4">
            <span className=" settingslblClass fw-bold">Trailer No</span>
          </div>
          <div className="col-sm-8  m-0 p-0">
            {!isViewOnly && (
              <input
                value={trailerNumber}
                type="text"
                className="pl-1 form-control"
                onChange={(e) => handleTrailerNumberChange(e.target.value)}
              />
            )}
            {isViewOnly && (
              <input
                className="form-control"
                type="text"
                value={trailerNumber}
                disabled={isViewOnly}
              />
            )}
          </div>
        </div>
        <div className="col-sm-4">
          <div className="col-sm-4">
            <span className=" settingslblClass fw-bold">Invoice Date</span>
          </div>
          <div className="col-sm-8  m-0 p-0">
            {!isViewOnly && (
              // <DatePickerComponent
              //   max={new Date()}
              //   onChange={handleInvoiceDateChange}
              //   value={arInvoiceSavedata.createdDate ? arInvoiceSavedata.createdDate : new Date()}
              //   disabled
              // />
              <input
                className="form-control"
                type="text"
                value={moment(arInvoiceSavedata.createdDate ? arInvoiceSavedata.createdDate : new Date()).format('MMM DD, YYYY')}
                disabled
              />
            )}
            {isViewOnly && (
              <input
                className="form-control"
                type="text"
                value={moment(invoiceDate).format('MMM DD, YYYY')}
                disabled={isViewOnly}
              />
            )}
          </div>
        </div>
      </div>
      <div className="col-sm-12 row mt-4 p-0 ar-invoice-row">
        <div className="col-sm-4">
          <div className="col-sm-4">
            <span className=" settingslblClass fw-bold">Deliver To</span>
          </div>
          <div className="col-sm-8  m-0 p-0">
            <div>
              {!isViewOnly && (
                // <CustomDropDownForTwoValues
                //   data={DeliverToList ? DeliverToList : []}
                //   valueField={"id"}
                //   textField={"city"}
                //   textField2={"state"}
                //   onChange={(value: any) => {
                //     setDeliverTo(value);
                //   }}
                //   // @ts-ignore
                //   selectedOption={
                //     DeliverToList
                //       ? DeliverToList?.find(
                //           (el) => el.id == arInvoiceSavedata.primaryAddressId
                //         )?.city
                //       : viewData.city
                //   }
                // />
                <select className="form-control" id="ddlDeliverTo" onChange={(e: any) => {
                      HandleSetDeliverTo(e.target.value);
                  }}>
                     
                  {showDeliverTo ? 
                  <option value={arInvoiceSavedata?.deliveryAddress?.id}>
                  {arInvoiceSavedata?.deliveryAddress?.city === undefined
                    ? ""
                    :
                    arInvoiceSavedata?.deliveryAddress?.city +
                      ", " +
                      arInvoiceSavedata?.deliveryAddress?.state
                     }
                </option> 
               :''}

               {showDeliverTo ? '': <option value='0'>Select</option>}
              {DeliverToList?.map((c: any) => (
                <>
                <option value={c.id}>{
                  c?.city === ''
                    ? ""
                    :
                 c.city +
                 ", " +
                 c.state
                }</option>
                </>
              ))}
            
                  

                 {/* {arInvoiceSavedata.invoiceNumber.charAt(0).includes("C") ? 
                   DeliverToList?.map((c: any) => (
                       <option value={c.id}>{
                        c.city +
                        ", " +
                        c.state
                       }</option>
                     ))
                 :   <option value={arInvoiceSavedata?.deliveryAddress?.id}>
                 {arInvoiceSavedata?.deliveryAddress?.city === undefined
                   ? ""
                   :
                   arInvoiceSavedata?.deliveryAddress?.city +
                     "," +
                     arInvoiceSavedata?.deliveryAddress?.state
                    }
               </option> } */}
                </select>
              )}
              {isViewOnly && (
                <input
                  className="form-control"
                  type="text"
                  value={arInvoiceSavedata?.deliveryAddress?.city +
                    ", " +
                    arInvoiceSavedata?.deliveryAddress?.state}
                  disabled={isViewOnly}
                />
              )}
            </div>
          </div>
        </div>
        <div className="col-sm-4">
          <div className="col-sm-4">
            <span className=" settingslblClass fw-bold">Seal Number</span>
          </div>
          <div className="col-sm-8  m-0 p-0">
            {!isViewOnly && (
              <input
                value={sealNumber}
                className="pl-1 form-control"
                onChange={(e) => handleSealNumberChange(e.target.value)}
              />
            )}
            {isViewOnly && (
              <input
                className="form-control"
                type="text"
                value={sealNumber}
                disabled={isViewOnly}
              />
            )}
          </div>
        </div>
        <div className="col-sm-4">
          <div className="col-sm-4">
            <span className=" settingslblClass fw-bold">Closed Date</span>
          </div>
          <div className="col-sm-8  m-0 p-0">
            {!isViewOnly && (
              //   <DatePickerComponent
              //   value={arInvoiceSavedata?.extractDate}
              //   disabled
              // />
              <TextBoxComponent
                value={arInvoiceSavedata?.extractDate === null ? arInvoiceSavedata?.extractDate : moment(arInvoiceSavedata?.extractDate).format('MMM DD, YYYY')}
                className="pl-1"
                disabled
              />
            )}
            {isViewOnly && (
              <input
                className="form-control"
                type="text"
                value={arInvoiceSavedata?.extractDate === null ? arInvoiceSavedata?.extractDate : moment(arInvoiceSavedata?.extractDate).format('MMM DD, YYYY')}
                disabled={isViewOnly}
              />
            )}
          </div>
        </div>
      </div>
      <div className="col-sm-12 row mt-4 p-0 mb-4 ar-invoice-row">
        <div className="col-sm-4">
          <div className="col-sm-4">
            <span className=" settingslblClass fw-bold">Carrier</span>
          </div>
          <div className="col-sm-8  m-0 p-0">
            <div>
              {!isViewOnly && (
                <CustomDropDown
                  data={carrierList ? carrierList : []}
                  valueField={"id"}
                  textField={"carrierName"}
                  onChange={(value: any) => {
                    setCarrier(value);
                    dispatch(setIsInvoiceUpdated(true));
                  }}
                  selectedOption={arInvoiceSavedata.carrierName}
                />
              )}{" "}
              {isViewOnly && (
                <input
                  className="form-control"
                  type="text"
                  value={arInvoiceSavedata?.carrierName}
                  disabled={isViewOnly}
                />
              )}
            </div>
          </div>
        </div>
        <div className="col-sm-4">
          <div className="col-sm-4">
            <span className=" settingslblClass fw-bold">Notes</span>
          </div>
          <div className="col-sm-8  m-0 p-0">
            {!isViewOnly && (
              <input
                type="text"
                value={notes}
                className="pl-1 form-control"
                // multiline={true}
                onChange={(e) => handleNotesChange(e.target.value)}
              />
            )}
            {isViewOnly && (
              <input
                className="form-control"
                type="text"
                value={notes}
                disabled={isViewOnly}
              />
            )}
          </div>
        </div>
        <div className="col-sm-4">
          <div className="col-sm-4">
            <span className=" settingslblClass fw-bold">
              Do Not Freeze Message
            </span>
          </div>
          <div className="col-sm-8  m-0 p-0 do-not-freeze">
            <span className="ml-3">
           
            <RadioButtonComponent
            label="Include"
            name="invoice-details-message"
            value="true"
            checked={includeMessage}
            change={(prams: any) => handleRadiobutton(prams.value)}
          />
            
            </span>
            <span className="ml-3">
              <RadioButtonComponent
                label="Do Not Include"
                name="invoice-details-message"
                value="false"
                checked={!includeMessage}
                change={(prams: any) => handleRadiobutton(prams.value)}
              />
            </span>
          </div>
        </div>
      </div>

      <LLModal
        handleClose={() => setShowAttachmentModal(false)}
        show={showAttachmentModal}
        padding={20}
      >
        <AttachmentModalContent />
      </LLModal>
    </>
  );
};

export default useInputCollection;
