import * as React from 'react';
import moment from 'moment';
import { Bin } from '../../../services/label-print-service/label-print-service';

type Props = {
    details: Bin;
};
export const BinLabel = (props: Props) => {
    const details = props.details;
    return (
        <div className="label-details-view">
            <div className="label-row">
                <div className="label-name">User</div>
                <div className="label-value"> {details?.closedBy}</div>
            </div>
            <div className="label-row">
                <div className="label-name">Bin #</div>
                <div className="label-value"> 1</div>
            </div>
            <div className="label-row">
                <div className="label-name">Location</div>
                <div className="label-value"> {details?.palletLocation}</div>
            </div>
            <div className="label-row">
                <div className="label-name">WH Location</div>
                <div className="label-value"> {details?.warehouseLocation}</div>
            </div>
            <div className="label-row">
                <div className="label-name">Closed Date</div>
                <div className="label-value">
                    {details?.closedOn && moment(details.closedOn).format('L')}
                </div>
            </div>
            <div className="label-row">
                <div className="label-name">BIN ID</div>
                <div className="label-value">{details?.binId}</div>
            </div>
        </div>
    );
};
