import { FC } from "react";
import "./CreditMemoTable.scss";

interface ICreditMemoTable {
  value: number | string;
  color?: string;
  headerValue: string;
  errorMessage: string;
  showError?: boolean;
  onChange: (value: string) => void;
  isEditable?: boolean;
}

const InvoiceTable: FC<ICreditMemoTable> = ({
  value,
  color,
  isEditable,
  headerValue,
  errorMessage,
  showError,
  onChange,
}) => {
  return (
    <div className="creditmemo-table">
      <div
        className="creditmemo-table-header"
        style={color ? { backgroundColor: color } : {}}
      >
        <span>{headerValue}</span>
      </div>
      <div className="creditmemo-table-content">
        {isEditable ? (
          <input
            value={value}
            style={{ color: "red" }}
            onChange={(e) => onChange(e.target.value)}
          />
        ) : (
          <span>{value}</span>
        )}
        {errorMessage && showError && (
          <p style={{ fontSize: 8, color: "red" }}>{errorMessage}</p>
        )}
      </div>
    </div>
  );
};

export default InvoiceTable;
