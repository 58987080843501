import {useEffect, useRef, useState} from 'react';
import {useHistory} from 'react-router-dom';
import './SideBarWithMenu.scss';
// import Dropdown from 'react-bootstrap/Dropdown';
// import Dropdown from 'react-dropdown';
// import 'react-dropdown/style.css';
// import {
//   SidebarComponent,
//   MenuComponent,
// } from "@syncfusion/ej2-react-navigations";
import CSLogo from '../../icons/CSLogo.png';
import QuestionIn from '../../icons/question-help.png';
import Limn_labs_logo from '../../icons/LL_LOGO.svg';
import {menuItems} from './constants.js';
import dateFormat from 'dateformat';
import UserService from '../../services/UserService';

import support from '../../icons/submenu/support.png';
import logout from '../../icons/submenu/logout.png';
import settings from '../../icons/settings.png';
import chat from '../../icons/submenu/chat.png';
import contact from '../../icons/submenu/contact.png';
import product from '../../icons/submenu/product.png';

import QuestionIcon from '../../assets/QuestionIcon.svg'

///network status///
import NetworkStatus from '../networkStatus/networkStatus';
//import { ToastContainer } from "react-toastify";
//import "react-toastify/dist/ReactToastify.css";
import Breadcrumb from './Breadcrumb';
import axios from 'axios';
import {getLoggedInUserInfo} from './utils';
import {setLoggedInUserId, setLoggedinUsername} from './sidebarreducer';

import {useDispatch} from 'react-redux';
import {css} from '@emotion/react';
import {setEnableTouchKeyboard} from '../scan/scanReducer';
import virtualKeyboard from '../../icons/virtual-keyboard.png';

const now = new Date();

const SidebarWithMenu = ({children}) => {
    const sideBarRef = useRef(null);
    const history = useHistory();
    const [currentMapper, setCurrentMapper] = useState('/home');

    const [showMenu, setShowMenu] = useState(false);

    const [showHelpMenu, setShowHelpMenu] = useState(false);

    const options = ['one', 'two', 'three'];
    const defaultOption = options[0];

    const [showPageContent, setShowPageContent] = useState(false);

    const [username, setUserName] = useState('');
    let [structedMenuItems, setStructeredMenuItems] = useState([]);
    let [loggedinUserInfo, setLoggedInUserInfo] = useState([]);

    let [loading, setLoading] = useState(true);
    let [color, setColor] = useState('#ffffff');
    const [touchMode, setTouchMode] = useState(true);
    const [reclaimCenterName, setReclaimCenterName] = useState('');
    const signOut = () => {
        alert('signout');
    };
    // const signOutButton = <button onClick></button>

    useEffect(() => {
        // perform side effect
        usergetLoggedInUser(UserService.getUsername());
    }, [UserService.getUsername()]);

    const AccountMenuItem = [
        {
            text: (
                <div>
                    <img
                        style={{borderRadius: 20, width: 20, height: 20}}
                        src="https://ej2.syncfusion.com/demos/src/grid/images/2.png"
                    />
                    <div style={{display: 'flex', flexDirection: 'column'}}>
                        <span
                            style={{
                                fontWeight: 100,
                                fontSize: 15,
                                height: 15,
                            }}
                        >
                            Krish Kendali
                        </span>
                        <span>krish.kendali@test.com</span>
                    </div>
                </div>
            ),
            // items: [
            //     { text: 'Profile' },
            //     { text: 'Sign out' },
            //     { text: 'Account'}
            // ]
        },
        {
            text: 'Keyboard shortcuts',
        },
        {
            text: 'Help & Support',
            icon: support,
        },
        {
            text: `Sign Out`,
            url: '/signout',
            icon: logout,
        },
    ];

    const openClick = () => {
        if (sideBarRef.current) {
            //sideBarRef.current.toggle();
        }
    };
    const handleLogout = () => {
        history.push('/Home');
        UserService.doLogout();
    };
    const dispatch = useDispatch();

    // const acccesstoken = useSelector(
    //   (state) => state.sidebarreducer.acccesstoken
    // );

    useEffect(() => {
        // console.log(UserService.loggedInUserData);
        // setUserName("");
        if (sideBarRef.current) {
            if (sideBarRef.current.isOpen) {
                sideBarRef.current.toggle();
            }
        }
        // console.log(window.location.pathname);
        // if (window.location.pathname == "/") {
        //   setCurrentMapper("/home");
        // } else {
        //   setCurrentMapper(window.location.pathname);
        // }
        // console.log(loggedInUserData)
        //console.log(UserService.getLoggedInUser(localStorage.getItem("token")));
        // setLoggedInUserInfo(
        //   UserService.getLoggedInUser(localStorage.getItem("token"))
        // );
        // console.log(loggedinUserInfo);
    }, [1]);

    const getLoggedInUser = async () => {
        const menuJson = [];

        //setLoggedInUserInfo(sessionStorage.getItem("logedUserData"));
        if (loggedinUserInfo && loggedinUserInfo.length > 0) {
            menuItems?.map((parent) => {
                loggedinUserInfo[0]?.roles?.roleModuleMapping?.map(
                    (children) => {
                        if (
                            parent.text.toLowerCase() ===
                            children.roleModule.moduleName.toLowerCase()
                        ) {
                            Object.assign(parent, {
                                roleModuleMenu:
                                children.roleModule.roleModuleMenu,
                            });
                            menuJson.push(parent);
                        }
                    }
                );
            });
            setStructeredMenuItems(
                menuJson.filter(
                    (ele, ind) =>
                        ind ===
                        menuJson.findIndex((elem) => elem.text === ele.text)
                )
            );
        }

        // if (window.location.pathname == "/") {
        //   setCurrentMapper("/home");
        // } else {
        //   setCurrentMapper(structedMenuItems.mapper);
        // }
        setCurrentMapper(structedMenuItems[0].mapper);
        // const obj = {email: email};
        // const endPointURL = getLoggedInUserInfo();
        // await axios.post(endPointURL, obj).then((res) => {
        //   console.log(res)
        //   setLoggedInUserInfo(res.data)
        //   if(loggedinUserInfo && loggedinUserInfo.length > 0){
        //     menuItems?.map((parent) => {
        //       loggedinUserInfo[0]?.roles?.roleModuleMapping?.map((children) => {
        //         if(parent.text.toLowerCase() === children.roleModule.moduleName.toLowerCase()) {
        //           Object.assign(parent, {roleModuleMenu: children.roleModule.roleModuleMenu})
        //           menuJson.push(parent)
        //         }
        //       })

        //      })
        //     setStructeredMenuItems(menuJson.filter( (ele, ind) => ind === menuJson.findIndex( elem => elem.text === ele.text)))
        //     }
        // }).catch((err) => {
        //   console.log(err);
        //   notify('Error! Unable to fetch data. Please try after sometime.', 'error');
        // });
        // const menuJson = [];
        console.log(structedMenuItems);
    };

    const usergetLoggedInUser = async (token) => {
        //  const dispatch = useDispatch();
        let tokenStr = '';
        tokenStr = token;
        setUserName(token.preferred_username);
        // const base64Url = tokenStr.split(".")[1];
        // const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
        // const jsonPayload = decodeURIComponent(
        //   atob(base64)
        //     .split("")
        //     .map(function (c) {
        //       return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
        //     })
        //     .join("")
        // );
        // const decodedObj = JSON.parse(jsonPayload);
        const obj = {email: token.email};
        const endPointURL = getLoggedInUserInfo();
        await axios
            .post(endPointURL)
            .then((res) => {
                setShowPageContent(true);
                let dataa = res.data;
                setLoggedInUserInfo(dataa);
                dispatch(setLoggedinUsername(dataa.username));
                dispatch(setLoggedInUserId(dataa.id));
                const menuJson = [];
                if (dataa && dataa !== undefined) {
                    setReclaimCenterName(
                        dataa?.reclaimCenter?.reclaimcenterName
                    );
                    menuItems?.map((parent) => {
                        dataa?.roles?.roleModuleMapping?.map((children) => {
                            if (
                                parent.text.toLowerCase() ===
                                children.roleModule.moduleName.toLowerCase()
                            ) {
                                Object.assign(parent, {
                                    roleModuleMenu:
                                    children.roleModule.roleModuleMenu,
                                });
                                menuJson.push(parent);
                            }
                        });
                    });
                    setStructeredMenuItems(
                        menuJson.filter(
                            (ele, ind) =>
                                ind ===
                                menuJson.findIndex(
                                    (elem) => elem.text === ele.text
                                )
                        )
                    );
                }
                console.log(structedMenuItems);
                const responsedata = dataa;
                if (responsedata && responsedata !== undefined) {
                    return responsedata;
                }
            })
            .catch((err) => {
                setShowPageContent(true);
                console.log(err);
            });
        const menuJson = [];
    };

    const handleTouch = (value) => {
        const checked = Boolean(value.target.checked);
        setTouchMode(checked);
        dispatch(setEnableTouchKeyboard(checked));
    };

    const handleItemSelect = (...args) => {
        const index = args[0].item.id?.split('_')[1] - 1;
        history.push(menuItems[index].mapper);
    };

    const handleItemSelectNew = (mapper, item) => {
        setCurrentMapper(mapper);
        history.push(mapper);
        history.push(item);
        console.log(showHelpMenu)
        console.log(showMenu)
        setShowHelpMenu(false);
        setShowMenu(false);
    };

    const handleHelpMenu = () => {
        setShowHelpMenu(!showHelpMenu);
        setShowMenu(false);
    };
    const handleProfileMenu = () => {
        setShowHelpMenu(false);
        setShowMenu(!showMenu);
    };

    const override = css`
      display: block;
      margin: 0 auto;
      border-color: red;
    `;
    return (
        <div>
            {/* <div className="sweet-loading"> */}
            {/* <button onClick={() => setLoading(!loading)}>Toggle Loader</button>
      <input value={color} onChange={(input) => setColor(input.target.value)} placeholder="Color of the loader" /> */}

            {/* <ClipLoader color={color} loading={loading}  size={150} />
    </div> */}
            {showPageContent ? (
                <div className="control-section sidebar-menu">
                    <>
                        <NetworkStatus/>
                        <div id="wrapper">
                            <div className="col-lg-12 col-sm-12 col-md-12">
                                {/* <div className="row"> */}
                                <div className="col-lg-1 col-sm-1 col-md-1 sideBar">
                                    {/* <SidebarComponent
      id="default-sidebar"
      ref={sideBarRef}
      enableDock={enableDock}
      mediaQuery={mediaQuery}
      dockSize={80}
      width={width}
      target={target}
    > */}

                                    {/* <div className="main-menu"> */}
                                    {/* <MenuComponent
          select={handleItemSelect}
          items={menuItems}
          orientation="Vertical"
          cssClass="dock-menu"
        ></MenuComponent> */}
                                    <div className="menu-item-logo">
                                        <img src={CSLogo}/>
                                    </div>

                                    {
                                        structedMenuItems &&
                                        structedMenuItems.length > 0 &&
                                        structedMenuItems !== undefined ? (
                                            <div>
                                                {structedMenuItems &&
                                                    structedMenuItems?.map(
                                                        (item) => {
                                                            return (
                                                                <div
                                                                    className={`menu-item ${
                                                                        currentMapper ==
                                                                        item.mapper &&
                                                                        currentMapper !==
                                                                        '/LLAdmin'
                                                                            ? ' menu-item-active'
                                                                            : currentMapper ==
                                                                            item.mapper &&
                                                                            currentMapper ===
                                                                            '/LLAdmin'
                                                                                ? 'lladmin-active'
                                                                                : ''
                                                                    } `}
                                                                    onClick={() =>
                                                                        handleItemSelectNew(
                                                                            item.mapper,
                                                                            item
                                                                        )
                                                                    }
                                                                >
                                                                    <li
                                                                        className={
                                                                            item.mapper ==
                                                                            '/settings'
                                                                                ? 'touch-border'
                                                                                : ''
                                                                        }
                                                                    >
                                                                        <li
                                                                            className={
                                                                                currentMapper ==
                                                                                item.mapper &&
                                                                                currentMapper ==
                                                                                '/touch'
                                                                                    ? 'cs-touch-icon-active sidemenu-icons'
                                                                                    : item.iconCss
                                                                            }
                                                                        >
                                                                            <img
                                                                                src={
                                                                                    item.imgPath
                                                                                }
                                                                                className="img img-fluid"
                                                                            />
                                                                        </li>

                                                                        <p
                                                                            className={`menu-name${
                                                                                currentMapper ==
                                                                                item.mapper &&
                                                                                currentMapper !==
                                                                                '/LLAdmin'
                                                                                    ? ' menu-name-active'
                                                                                    : currentMapper ==
                                                                                    item.mapper &&
                                                                                    currentMapper ===
                                                                                    '/LLAdmin'
                                                                                        ? ' lladmin-name-active'
                                                                                        : ''
                                                                            } `}
                                                                        >
                                                                            {
                                                                                item.text
                                                                            }
                                                                        </p>
                                                                    </li>
                                                                </div>
                                                            );
                                                        }
                                                    )}
                                            </div>
                                        ) : (
                                            <></>
                                        )
                                        //   <div>
                                        //   {menuItems.map((item) => {
                                        //   return (
                                        //     <div
                                        //       className={`menu-item ${
                                        //         currentMapper == item.mapper && currentMapper !== "/LLAdmin"
                                        //           ? " menu-item-active"
                                        //           : currentMapper == item.mapper &&
                                        //             currentMapper === "/LLAdmin"
                                        //           ? "lladmin-active"
                                        //           : ""
                                        //       } `}
                                        //       onClick={() => handleItemSelectNew(item.mapper)}
                                        //     >
                                        //       <li
                                        //         className={item.mapper == "/settings" ? "touch-border" : ""}
                                        //       >
                                        //         <li
                                        //           className={
                                        //             currentMapper == item.mapper &&
                                        //             currentMapper == "/touch"
                                        //               ? "cs-touch-icon-active sidemenu-icons"
                                        //               : item.iconCss
                                        //           }
                                        //         >
                                        //           <img src={item.imgPath} className="img img-fluid" />
                                        //         </li>
                                        //         <p
                                        //           className={`menu-name${
                                        //             currentMapper == item.mapper &&
                                        //             currentMapper !== "/LLAdmin"
                                        //               ? " menu-name-active"
                                        //               : currentMapper == item.mapper &&
                                        //                 currentMapper === "/LLAdmin"
                                        //               ? " lladmin-name-active"
                                        //               : ""
                                        //           } `}
                                        //         >
                                        //           {item.text}
                                        //         </p>
                                        //       </li>
                                        //     </div>
                                        //   );
                                        // })} </div>
                                    }
                                    <div className="touch-mode">
                                        <input
                                            type="checkbox"
                                            defaultChecked={touchMode}
                                            id="touchMode"
                                            className="toggle"
                                            onChange={handleTouch}
                                        />

                                        <label htmlFor="touchMode"/>
                                        <img
                                            alt="Touch Mode"
                                            src={virtualKeyboard}
                                        />
                                    </div>

                                    <div className="Limn_labs_logo">
                                        <img
                                            src={Limn_labs_logo}
                                            className="img img-responsive"
                                        />
                                    </div>
                                    {/* </div> */}
                                    {/* </SidebarComponent> */}
                                </div>
                                <div className="col-lg-11 col-sm-11 col-md-11 mainBar">
                                    <div
                                        className="col-lg-12 col-sm-12 col-md-12 p-0 header-section dock-menu"
                                        id="header"
                                    >
                                        <ul className="header-list">
                                            <li
                                                id="hamburger"
                                                className="icon list"
                                            ></li>

                                            <li className="list">
                                                <div className="left-header">
                                                    <p className="location-name">
                                                        {reclaimCenterName}
                                                    </p>
                                                </div>
                                            </li>

                                            <li className="menubar-options">
                                                <span className="current-day-time-text">
                                                    {dateFormat(
                                                        now,
                                                        'dddd, mmmm dS, yyyy'
                                                    )}
                                                </span>
                                            </li>
                                            <li className="right-header list">
                                                <div className="horizontal-menu">
                                                    <div
                                                        className="e-avatar e-avatar-small e-avatar-circle profile-image"
                                                        onClick={() =>
                                                            handleHelpMenu()
                                                        }
                                                    >
                                                        <img
                                                            src={QuestionIcon}
                                                            className="img-fluid"
                                                        />
                                                    </div>
                                                    {showHelpMenu ? (
                                                        <ul
                                                            className="submenu-show help-menu"
                                                            style={{
                                                                background:
                                                                    'white !important',
                                                            }}
                                                        >
                                                            <li className="submenu-list list-border">
                                                                <span className="submenu-option ">
                                                                    <img
                                                                        src={
                                                                            support
                                                                        }
                                                                        className="img-fluid submenu-option-icon"
                                                                    />
                                                                    Help &
                                                                    Support
                                                                </span>
                                                            </li>

                                                            <li className="submenu-list list-border">
                                                                <span className="submenu-option ">
                                                                    <img
                                                                        src={
                                                                            chat
                                                                        }
                                                                        className="img-fluid submenu-option-icon"
                                                                    />
                                                                    Chat with us
                                                                </span>
                                                            </li>

                                                            <li className="submenu-list list-border">
                                                                <span className="submenu-option ">
                                                                    <img
                                                                        src={
                                                                            product
                                                                        }
                                                                        className="img-fluid submenu-option-icon"
                                                                    />
                                                                    Product
                                                                    Updates
                                                                </span>
                                                            </li>

                                                            <li className="submenu-list">
                                                                <span className="submenu-option-left">
                                                                    <img
                                                                        src={
                                                                            contact
                                                                        }
                                                                        className="img-fluid submenu-option-icon"
                                                                    />
                                                                    Contact Us
                                                                </span>
                                                            </li>

                                                            <li className="copyright-submenu">
                                                                <span className="copyright-option">
                                                                    Copyright ©
                                                                    2022 Limn
                                                                    Labs Inc.,
                                                                    All rights
                                                                    reserved
                                                                </span>
                                                            </li>
                                                        </ul>
                                                    ) : null}
                                                </div>
                                            </li>
                                            <li className="right-header list">
                                                <div className="horizontal-menu">
                                                    <div
                                                        className="e-avatar e-avatar-medium e-avatar-circle mb-1"
                                                        onClick={() =>
                                                            handleProfileMenu()
                                                        }
                                                    >
                                                        {loggedinUserInfo?.image !==
                                                        undefined &&
                                                        loggedinUserInfo?.image !==
                                                        null &&
                                                        loggedinUserInfo?.image !==
                                                        '' ? (
                                                            <img
                                                                alt="avatar"
                                                                src={`data:image/png;base64,${loggedinUserInfo.image}`}
                                                                className="profile-avatar"
                                                                height="100"
                                                                width="100"
                                                            />
                                                        ) : loggedinUserInfo?.firstname !==
                                                        undefined ? (
                                                            <div className="header-profile-logo">
                                                                <p className="mt-1">
                                                                    {loggedinUserInfo?.firstname
                                                                            ?.substring(
                                                                                0,
                                                                                1
                                                                            )
                                                                            ?.toUpperCase() +
                                                                        loggedinUserInfo?.lastname
                                                                            ?.substring(
                                                                                0,
                                                                                1
                                                                            )
                                                                            ?.toUpperCase()}
                                                                    {/* {loggedinUserInfo?.firstname?.match(/\b(\w)/g)?.join('')?.substring(0, 2)?.toUpperCase().length > 1
                                    ? loggedinUserInfo?.firstname?.match(/\b(\w)/g)?.join('')?.substring(0, 2)?.toUpperCase() : loggedinUserInfo?.firstname?.substring(0, 2)?.toUpperCase()} */}
                                                                </p>
                                                            </div>
                                                        ) : (
                                                            <div className="header-profile-logo">
                                                                <p className="mt-1">
                                                                    {username
                                                                        ?.match(
                                                                            /\b(\w)/g
                                                                        )
                                                                        ?.join(
                                                                            ''
                                                                        )
                                                                        ?.substring(
                                                                            0,
                                                                            2
                                                                        )
                                                                        ?.toUpperCase()
                                                                        .length >
                                                                    1
                                                                        ? username
                                                                            ?.match(
                                                                                /\b(\w)/g
                                                                            )
                                                                            ?.join(
                                                                                ''
                                                                            )
                                                                            ?.substring(
                                                                                0,
                                                                                2
                                                                            )
                                                                            ?.toUpperCase()
                                                                        : username
                                                                            ?.substring(
                                                                                0,
                                                                                2
                                                                            )
                                                                            ?.toUpperCase()}
                                                                </p>
                                                            </div>
                                                        )}

                                                        {/* <img
      className="image"
      src="https://ej2.syncfusion.com/demos/src/grid/images/2.png"
      alt="avatar"
    /> */}
                                                    </div>
                                                    {showMenu ? (
                                                        <ul
                                                            className="submenu-show"
                                                            style={{
                                                                background:
                                                                    'white !important',
                                                            }}
                                                        >
                                                            <li className="submenu-list">
                                                                <span className="submenu-option ">
                                                                    {loggedinUserInfo?.image !==
                                                                    undefined &&
                                                                    loggedinUserInfo?.image !==
                                                                    null &&
                                                                    loggedinUserInfo?.image !==
                                                                    '' ? (
                                                                        <div
                                                                            className="e-avatar e-avatar-medium e-avatar-circle">
                                                                            <img
                                                                                alt="avatar"
                                                                                src={`data:image/png;base64,${loggedinUserInfo.image}`}
                                                                                className="profile-avatar header-user-logo"
                                                                                height="100"
                                                                                width="100"
                                                                            />
                                                                        </div>
                                                                    ) : loggedinUserInfo?.firstname !==
                                                                    undefined ? (
                                                                        <p className="header-user-logo">
                                                                            {loggedinUserInfo?.firstname
                                                                                    ?.substring(
                                                                                        0,
                                                                                        1
                                                                                    )
                                                                                    ?.toUpperCase() +
                                                                                loggedinUserInfo?.lastname
                                                                                    ?.substring(
                                                                                        0,
                                                                                        1
                                                                                    )
                                                                                    ?.toUpperCase()}
                                                                            {/* {loggedinUserInfo?.firstname + ' ' + loggedinUserInfo?.lastname?.match(/\b(\w)/g)?.join('')?.substring(0, 2)?.toUpperCase().length > 1
                                    ? loggedinUserInfo?.firstname?.match(/\b(\w)/g)?.join('')?.substring(0, 2)?.toUpperCase() : loggedinUserInfo?.firstname?.substring(0, 2)?.toUpperCase()} */}
                                                                        </p>
                                                                    ) : (
                                                                        <p className="header-user-logo">
                                                                            {username
                                                                                ?.substring(
                                                                                    0,
                                                                                    2
                                                                                )
                                                                                ?.toUpperCase()}
                                                                        </p>
                                                                    )}

                                                                    {loggedinUserInfo &&
                                                                    loggedinUserInfo?.firstname !==
                                                                    undefined ? (
                                                                        <p className="user-info">
                                                                            {' '}
                                                                            {loggedinUserInfo?.firstname?.toUpperCase() +
                                                                                ' ' +
                                                                                loggedinUserInfo?.lastname?.toUpperCase()}
                                                                        </p>
                                                                    ) : (
                                                                        <p className="user-info">
                                                                            {username?.toUpperCase()}
                                                                        </p>
                                                                    )}
                                                                    <img
                                                                        src={
                                                                            settings
                                                                        }
                                                                        className="img-fluid"
                                                                    />
                                                                </span>
                                                            </li>

                                                            <li
                                                                className="submenu-list"
                                                                onClick={() => {
                                                                    handleLogout();
                                                                }}
                                                            >
                                                                <span className="submenu-option">
                                                                    <img
                                                                        src={
                                                                            logout
                                                                        }
                                                                        className="img-fluid submenu-option-icon"
                                                                    />
                                                                    Sign out
                                                                </span>
                                                            </li>
                                                        </ul>
                                                    ) : null}
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                    {structedMenuItems &&
                                    structedMenuItems.length > 0 &&
                                    structedMenuItems !== undefined ? (
                                        <div className="col-lg-12 col-sm-12 col-md-12 brdCmb-secondary">
                                            <Breadcrumb/>
                                        </div>
                                    ) : (
                                        <></>
                                    )}
                                    <div
                                        className="col-lg-12 col-sm-12 col-md-12 p-0 main-content body-route"
                                        id="maintext"
                                    >
                                        {structedMenuItems ? (
                                            <>
                                                {structedMenuItems?.length >
                                                0 &&
                                                structedMenuItems !==
                                                undefined ? (
                                                    <div className="sidebar-menu-content">
                                                        {children}
                                                    </div>
                                                ) : (
                                                    <div className="col-sm-12 d-flex align-items-center errormsgDiv">
                                                        {' '}
                                                        <div className="col-sm-12 d-flex justify-content-center">
                                                            {' '}
                                                            <p className="font-24  text-danger">
                                                                You DO NOT have
                                                                designated role
                                                                assigned to
                                                                access this
                                                                page.{' '}
                                                            </p>
                                                        </div>
                                                    </div>
                                                )}
                                            </>
                                        ) : (
                                            <></>
                                        )}
                                    </div>
                                </div>
                                {/* </div> */}
                            </div>
                        </div>
                    </>
                </div>
            ) : (
                <></>
            )}
        </div>
    );

    function getPageLocation() {
        // console.log(window.location.pathname);
        const str = window.location.pathname;
        const url = str.split('revlog');
        const endPointUrl = url[1];
        if (endPointUrl === 'home' || endPointUrl === '') {
            return false;
        } else {
            let pageName = '';
            menuItems.map((item) => {
                if (item.mapper === endPointUrl) {
                    pageName = item.text;
                }
            });
            return pageName;
        }
    }

    // function goToHomePage() {
    //   setCurrentMapper('/home')
    // }
};
export default SidebarWithMenu;


// <li className="submenu-list list-border">
//                                                                 <span className="submenu-option ">
//                                                                     <img
//                                                                         src={
//                                                                             support
//                                                                         }
//                                                                         className="img-fluid submenu-option-icon"
//                                                                     />
//                                                                     <span>
//                                                                         Keyboard
//                                                                         Shortcuts
//                                                                         <span className="submenu-option-sm">
//                                                                             Press
//                                                                             ? to
//                                                                             view
//                                                                             &nbsp;
//                                                                             <a>
//                                                                                 shortcuts
//                                                                             </a>
//                                                                         </span>
//                                                                     </span>
//                                                                 </span>
//                                                             </li>

//                                                             <li className="submenu-list list-border">
//                                                                 <span className="submenu-option ">
//                                                                     <img
//                                                                         src={
//                                                                             support
//                                                                         }
//                                                                         className="img-fluid submenu-option-icon"
//                                                                     />
//                                                                     Help &
//                                                                     Support
//                                                                 </span>
//                                                             </li>
