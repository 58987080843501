import { ButtonComponent } from '@syncfusion/ej2-react-buttons';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../redux/store';
import ContentHeader from '../../common/ContentHeader/ContentHeader';
import Divider from '../../common/divider/Divider';
import ForceToTrashModal from './forceToTrash';
import HazardousModal from './hazardousItems';

const UnlabeledItemsModal = (props: any) => {
    const [showModalType, setShowModalType] = useState('salvage');
    const { setAddScreen, resetFocusToUpc, setunlabeledIsFrom, isTrashMode } = props;

    const setItemDetails = useSelector(
        (state: RootState) => state.scanReducer.itemDetails
    );
    const getScanMode = useSelector(
        (state: RootState) => state.scanReducer.scanMode
    );

    const getForceTrashMode = useSelector(
        (state: RootState) => state.scanReducer.forceTrashMode
    );

    const handleForceToTrash = (e: any) => {
        setAddScreen(false);
        resetFocusToUpc();
        props.updateUnlabeled(e, 'Trash', props.isFrom);
    };

    const handleHazardous = (e: any) => {
        setAddScreen(false);
        resetFocusToUpc();
        props.updateUnlabeled(e, 'Hazardous', props.isFrom);
    };
    const handleHold = (e: any) => {
        setAddScreen(false);
        resetFocusToUpc();
        props.updateUnlabeled(
            'HOLD-13 DIGIT UPC',
            'HOLD-13 DIGIT UPC',
            props.isFrom
        );
    };
    const handleRenderModal = (type: any) => {
        if (type === 'salvage') {
            props.updateUnlabeled('', 'SALVAGE - GM/HBA', props.isFrom);
            setAddScreen(false);
            resetFocusToUpc();
        } else if (
            (type === 'trash' && props.isFrom === '13d') ||
            (type === 'trash' && props.isFrom === 'NF') ||
            (type === 'trash' && props.isFrom === 'unlabeled')
        ) {
            props.updateUnlabeled('', 'Trash', props.isFrom);
            setAddScreen(false);
            resetFocusToUpc();
            // setShowModalType(type);
        } else if (
            (type === 'hazardous' && props.isFrom === '13d') ||
            (type === 'hazardous' && props.isFrom === 'NF')
        ) {
            //props.updateUnlabeled("", "Hazardous", props.isFrom);
            //setAddScreen(false);
            setShowModalType(type);
        } else {
            setShowModalType(type);
        }
    };
    const handleCancel = () => {
        setAddScreen(false);
        setunlabeledIsFrom('');
        resetFocusToUpc();
    };
    const reset = () => {
        setShowModalType('salvage');
    };
    return (
        <>
            {showModalType === 'salvage' ? (
                <>
                    <ContentHeader value={props.heading} />
                    <Divider />
                </>
            ) : (
                ''
            )}
            {showModalType === 'salvage' ? (
                <div className="col-sm-12 p5">
                    {props.isFrom === 'unlabeled' ? (
                        <h5 className="text-center fb-15 Unlabeledtxt mr-5">
                            Select Unlabeled Item Destination
                        </h5>
                    ) : props.isFrom === '13d' ? (
                        <h5 className="text-center p1 fb-15 ml-5">
                            Select 13 Digit UPC Item Destination
                        </h5>
                    ) : props.isFrom === 'NF' ? (
                        <h5 className="text-center fb-15 Unlabeledtxt">
                            Select Not Found Item Destination
                        </h5>
                    ) : (
                        ''
                    )}
                    <div className="col-sm-9 col-sm-offset-2 popupbtns">
                        <div className="col-sm-3">
                            {isTrashMode ||
                            getForceTrashMode.payload === 'trash' ? (
                                ''
                            ) : (
                                <button
                                    className="btn btn-primary inbound-box btninboundBox"
                                    onClick={(e) =>
                                        handleRenderModal('salvage')
                                    }
                                >
                                    SALVAGABLE
                                </button>
                            )}
                        </div>
                        {props.isFrom === '13d' ? (
                            <>
                                <div className="col-sm-3">
                                    {isTrashMode ||
                                    getForceTrashMode.payload === 'trash' ? (
                                        ''
                                    ) : (
                                        <button
                                            className="btn btn-danger inbound-box btninboundBox"
                                            onClick={(e) => handleHold('hold')}
                                        >
                                            HOLD
                                        </button>
                                    )}
                                </div>
                            </>
                        ) : (
                            ''
                        )}
                        <div className="col-sm-3">
                            <button
                                className="btn btn-danger inbound-box trash-button"
                                onClick={(e) => handleRenderModal('trash')}
                            >
                                FORCE TO <br />
                                TRASH
                            </button>
                        </div>
                        <div className="col-sm-3">
                            <button
                                className="btn btn-primary inbound-box hazardous-btn"
                                onClick={(e) => handleRenderModal('hazardous')}
                            >
                                HAZARDOUS
                                <br /> FORCE TO <br />
                                TRASH
                            </button>
                        </div>
                    </div>
                </div>
            ) : //: showModalType === 'trash' ? (
            //     <ForceToTrashModal
            //         setAddScreen={setAddScreen}
            //         clearModal={reset}
            //         handleForceToTrash={handleForceToTrash}
            //         reasonCodeList={props.reasonCodeList}
            //         resetFocusToUpc={resetFocusToUpc}
            //     />
            // ) :
            showModalType === 'hazardous' ? (
                <HazardousModal
                    setAddScreen={setAddScreen}
                    clearModal={reset}
                    handleHazardous={handleHazardous}
                    resetFocusToUpc={resetFocusToUpc}
                />
            ) : (
                ''
            )}
            {showModalType === 'salvage' ? (
                <div className="col-sm-11 mt-2rem">
                    <ButtonComponent
                        cssClass="eg-btn-secondary float-right mr-4"
                        onClick={() => handleCancel()}
                    >
                        CANCEL
                    </ButtonComponent>
                </div>
            ) : (
                ''
            )}
        </>
    );
};

export default UnlabeledItemsModal;
