import { FC } from "react";
import { ReactComponent as Delete } from "../../../icons/cross.svg";
import "./FileListItem.scss";

interface IProps {
  bgColor: string;
  color: string;
  text: string;
  handleDelete: (fileName: string) => void;
}

const ListObject: FC<IProps> = ({ bgColor, color, text, handleDelete }) => {
  return (
    <div
      className="fileListItem-container"
      style={{ backgroundColor: bgColor, color }}
    >
      <span>{text}</span>
      <span
        onClick={() => handleDelete(text)}
        style={{ marginBottom: -5 }}
        className="fileListItem-delete"
      >
        {" "}
        <Delete width={40} height={40} color={"red"} />
      </span>
    </div>
  );
};

export default ListObject;
