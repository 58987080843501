import { FC } from "react";
import { createPortal } from "react-dom";
import "./settingsModal.scss";
import closeDialog from "../../../icons/common/close-dialog.png";

interface IModalProps {
  children?: any;
  width?: string | number;
  padding?: number;
  show: boolean;
  handleClose: () => void;
}

const SettingsModal: FC<IModalProps> = ({
  children,
  handleClose,
  show,
  width,
  padding,
}) => {
  return createPortal(
    <div
      className="modal-container"
      style={{ display: show ? "block" : "none" }}
    >
      <div
        className="modal-content-wrapper"
        style={{ width, padding: padding ? padding : 40 }}
      >
        <div className="close-button">
          <span onClick={handleClose}>
            <img src={closeDialog} alt="Close" />
          </span>
        </div>
        <div className="model-content">{children}</div>
      </div>
    </div>,
    document.body
  );
};

export default SettingsModal;
