import { useEffect, useState } from 'react';
import './ContactsDetailsPanel.scss';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import updateIcon from '../../assets/updateForTabs.png';
import cancelIcon from '../../assets/cancelIcon.png';
import axios from 'axios';
import {
    getEndPoint,
    getStatesByCountry,
    updateMasterDataEndPoint,
} from '../contactTabs/utils';
import {
    setCurrentContact,
    setShowUpdateAndCancelIconsOnPreview,
    setStatesData,
    setStatesAddrDataOne,
    setUpdatedStoresORVendorData,
} from '../contactTabs/contactReducer';
import plusIcon from '../../assets/IconPlus.png';
import closeIcon from '../../assets/iconClose.png';
import { ContactType } from '../contactTabs/constants';
import notify from '../../services/toasterService';
import closeModuleIcon from '../../icons/common/cancel.png';
import CountryDropdownPreview from './mobile-field-component Preview';
import { setRowData, setStoresORVendorsRowData } from '../grid/gridReducer';
// import closeModuleIcon from "../../icons/common/closemodule.png";

const WarehouseContactView = () => {
    const [result, setResult] = useState();
    const [showAddressListInfo, setShowAddressListInfo] = useState(false);
    const [rowInfo, setRowInfo] = useState<any[]>([]);
    const [finalRowInfoCopy, SetfinalRowInfoCopy] = useState<any[]>([]);
    const [finalCountryList, setfinalCountryList] = useState<any[]>([]);
    const [warehouseInfo, setWarehouseInfo] = useState(false);
    const [storeUpdateFlag, setStoreUpdateFlag] = useState(false);
    const [showUpdateCancelIcons, setShowUpdateCancelIcons] = useState(false);
    const [altEmailStore, setAaltEmailStore] = useState<
        { [key: string]: Object }[]
    >([]);
    const [altEmailCustomer, setAaltEmailCustomer] = useState<
        { [key: string]: Object }[]
    >([]);
    const [altEmailDiv, setAltEmailDiv] = useState(false);
    //const [emailError, setEmailError] = useState('');
    const [showNewAddressDiv, setShowNewAddressDiv] = useState(false);
    const [removedState, setRemovedState] = useState(false);
    const [addressList, setaddressList] = useState<{ [key: string]: Object }[]>(
        []
    );
    const dispatch = useDispatch();
    const [errors, setErrors] = useState({
        faxError: '',
        emailError: '',
        phoneNumberError: '',
        altPhoneError: '',
        zipError: '',
    });
    let faxError = '';
    let emailError = '';
    let phoneNumberError = '';
    let altPhoneError = '';
    let zipError = '';
    const setTabHeading = useSelector(
        (state: RootState) => state?.contactReducer?.tabHeading
    );
    const setRowDataSelected = useSelector(
        (state: RootState) => state?.gridReducer?.selectedRowData
    );
    const selRowData = useSelector(
        (state: RootState) => state.gridReducer?.selectedRowData
    );
    const setRowDataForTabs = useSelector(
        (state: RootState) => state?.gridReducer?.selectedRowDataForTabs
    );
    const setRowId = useSelector(
        (state: RootState) => state?.gridReducer?.selectedRowDataId
    );
    const currencyData = useSelector(
        (state: RootState) => state?.contactReducer?.currencyData
    );
    const timezoneData = useSelector(
        (state: RootState) => state?.contactReducer?.timezoneData
    );
    const languageData = useSelector(
        (state: RootState) => state?.contactReducer?.laungageData
    );
    const countryInfo = useSelector(
        (state: RootState) => state.contactReducer?.countryData
    );
    const countryAltInfo = useSelector(
        (state: RootState) => state.contactReducer?.countryData
    );
    const countryCodeInfo = useSelector(
        (state: RootState) => state.contactReducer?.countryCodePhoneData
    );
    const statesInfo = useSelector(
        (state: RootState) => state.contactReducer?.statesData
    );
    const altStatesInfo = useSelector(
        (state: RootState) => state.contactReducer?.statesAddrDataOne
    );
    const selStoreAndVendorRowData = useSelector(
        (state: RootState) => state.gridReducer?.selectedStoresOrVendorsRowData
    );

    const mobileWithFlag = useSelector(
        (state: RootState) =>
            state.contactReducer?.selectedMobileWithCountryFlagPreview
    );
    const altMobileWithFlag = useSelector(
        (state: RootState) =>
            state.contactReducer?.selectedAltMobileWithCountryFlagPreview
    );
    const faxWithFlag = useSelector(
        (state: RootState) =>
            state.contactReducer?.selectedFaxWithCountryFlagPreview
    );
    const phoneCountryCode = useSelector(
        (state: RootState) =>
            state.contactReducer?.selectedCountryCodeForMobilePreview
    );
    const AltPhoneCountryCode = useSelector(
        (state: RootState) =>
            state.contactReducer?.selectedCountryCodeForAltMobilePreview
    );
    const faxCountryCode = useSelector(
        (state: RootState) =>
            state.contactReducer?.selectedCountryCodeForFaxPreview
    );
    const showUpdateCancelIconsOnPreview = useSelector(
        (state: RootState) =>
            state.contactReducer?.showUpdateAndCancelIconsOnPreview
    );

    const [selZipCode, setZipCode] = useState<any>(0);
    useEffect(() => {
        console.log(showUpdateCancelIconsOnPreview);
        setResult(setRowDataForTabs);
        setShowUpdateCancelIcons(showUpdateCancelIconsOnPreview);
    }, [setRowDataForTabs, showUpdateCancelIconsOnPreview]);
    const _handleFocusEmail = (e: any) => {
        setShowUpdateCancelIcons(false);
        setErrors({
            emailError,
            faxError,
            phoneNumberError,
            altPhoneError,
            zipError,
        });
        const reg =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (e.target.value === '') {
            emailError = 'Email is Required';
            setShowUpdateCancelIcons(false);
        } else if (e.target.value && reg.test(e.target.value) === false) {
            emailError = 'Email is Invalid ';
            setShowUpdateCancelIcons(false);
        }
        if (emailError) {
            setErrors({
                emailError,
                faxError,
                phoneNumberError,
                altPhoneError,
                zipError,
            });
            setShowUpdateCancelIcons(false);
            return false;
        } else {
            setRowInfo([{ ...rowInfo[0], email: e.target.value }]);
            setShowUpdateCancelIcons(true);
            return true;
        }
        //setRowInfo([{ ...rowInfo[0], email: text }])
    };
    const _handleFocusOutEmail = (text: any) => {
        setShowUpdateCancelIcons(false);
        setErrors({
            emailError,
            faxError,
            phoneNumberError,
            altPhoneError,
            zipError,
        });
        const reg =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (text === '') {
            emailError = 'Email is Required';
            setShowUpdateCancelIcons(false);
        } else if (text && reg.test(text) === false) {
            emailError = 'Email is Invalid ';
            setShowUpdateCancelIcons(false);
        }
        if (emailError) {
            setErrors({
                emailError,
                faxError,
                phoneNumberError,
                altPhoneError,
                zipError,
            });
            setShowUpdateCancelIcons(false);
            return false;
        } else {
            setRowInfo([{ ...rowInfo[0], email: text }]);
            setShowUpdateCancelIcons(true);
            return true;
        }
    };
    const _handleFocusPhone = (e: any) => {
        setShowUpdateCancelIcons(false);
        setErrors({
            emailError,
            faxError,
            phoneNumberError,
            altPhoneError,
            zipError,
        });
        var phoneno = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
        if (e.target.value.match(phoneno)) {
            setRowInfo([{ ...rowInfo[0], phoneNumber: e.target.value }]);
            setShowUpdateCancelIcons(true);
            phoneNumberError = '';
            setErrors({
                emailError,
                faxError,
                phoneNumberError,
                altPhoneError,
                zipError,
            });
            return true;
        } else {
            phoneNumberError = 'Phone number is invalid';
            setShowUpdateCancelIcons(false);
        }
        if (phoneNumberError) {
            setErrors({
                emailError,
                faxError,
                phoneNumberError,
                altPhoneError,
                zipError,
            });
            return false;
        }
    };
    const _handleFocusOutPhone = (text: any) => {
        setShowUpdateCancelIcons(false);

        setErrors({
            emailError,
            faxError,
            phoneNumberError,
            altPhoneError,
            zipError,
        });
        var phoneno = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
        if (text.match(phoneno)) {
            setRowInfo([{ ...rowInfo[0], phoneNumber: text }]);
            setShowUpdateCancelIcons(true);
            phoneNumberError = '';
            setErrors({
                emailError,
                faxError,
                phoneNumberError,
                altPhoneError,
                zipError,
            });
            return true;
        } else {
            phoneNumberError = 'Phone number is invalid';
            setShowUpdateCancelIcons(false);
        }
        if (phoneNumberError) {
            setErrors({
                emailError,
                faxError,
                phoneNumberError,
                altPhoneError,
                zipError,
            });
            return false;
        }
    };
    const _handleFocusAltPhone = (e: any) => {
        setShowUpdateCancelIcons(false);
        setErrors({
            emailError,
            faxError,
            phoneNumberError,
            altPhoneError,
            zipError,
        });
        var phoneno = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
        if (e.target.value.match(phoneno)) {
            setRowInfo([
                { ...rowInfo[0], alternatePhoneNumber: e.target.value },
            ]);
            setShowUpdateCancelIcons(true);
            altPhoneError = '';
            setErrors({
                emailError,
                faxError,
                phoneNumberError,
                altPhoneError,
                zipError,
            });
            return true;
        } else {
            altPhoneError = 'Phone number is invalid';
            setShowUpdateCancelIcons(false);
        }
        if (altPhoneError) {
            setErrors({
                emailError,
                faxError,
                phoneNumberError,
                altPhoneError,
                zipError,
            });
            return false;
        }
    };
    const _handleFocusOutAltPhone = (text: any) => {
        setShowUpdateCancelIcons(false);

        setErrors({
            emailError,
            faxError,
            phoneNumberError,
            altPhoneError,
            zipError,
        });
        var phoneno = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
        if (text.match(phoneno)) {
            setRowInfo([{ ...rowInfo[0], alternatePhoneNumber: text }]);
            setShowUpdateCancelIcons(true);
            altPhoneError = '';
            setErrors({
                emailError,
                faxError,
                phoneNumberError,
                altPhoneError,
                zipError,
            });
            return true;
        } else {
            altPhoneError = 'Phone number is invalid';
            setShowUpdateCancelIcons(false);
        }
        if (altPhoneError) {
            setErrors({
                emailError,
                faxError,
                phoneNumberError,
                altPhoneError,
                zipError,
            });
            return false;
        }
    };
    const _handleFocusStreet = (id: any, text: any) => {
        setShowUpdateCancelIcons(true);
    };
    const _handleFocusOutStreet = (id: any, e: any) => {
        setShowUpdateCancelIcons(true);
        const rowInfoCopy = JSON.parse(JSON.stringify(rowInfo));
        const addrInfo = [...rowInfoCopy[0].address];
        addrInfo.filter((st) => {
            if (st.id === id) {
                st.street = e.target.value;
            }
        });
        setRowInfo([{ ...rowInfo[0], address: addrInfo }]);
    };

    const _handleFocusCity = (id: any, text: any) => {
        setShowUpdateCancelIcons(true);
    };
    const _handleFocusOutCity = (id: any, e: any) => {
        setShowUpdateCancelIcons(true);
        const rowInfoCopy = JSON.parse(JSON.stringify(rowInfo));
        const addrInfo = [...rowInfoCopy[0].address];
        addrInfo.filter((st) => {
            if (st.id === id) {
                st.city = e.target.value;
            }
        });
        setRowInfo([{ ...rowInfo[0], address: addrInfo }]);
    };
    const _handleFocusState = (id: any, text: any) => {
        setShowUpdateCancelIcons(true);
    };
    const _handleFocusOutState = (id: any, e: any, index: any, name: any) => {
        setShowUpdateCancelIcons(true);
        const rowInfoCopy = JSON.parse(JSON.stringify(rowInfo));
        const value = e.target.value;
        let list = [...rowInfoCopy[0].address];
        list[index][name] = value;
        rowInfoCopy[0].address = [...list];
        setRowInfo(rowInfoCopy);
    };

    const handleCustomerTextFields = (event: any, key: any) => {
        if (key === 'zipcode') {
            if (event.target.value.length <= 5) {
                setRowInfo([{ ...rowInfo[0], [key]: event.target.value }]);
            }
        } else {
            setRowInfo([{ ...rowInfo[0], [key]: event.target.value }]);
        }
    };

    const _handleFocusOutAltState = (id: any, e: any) => {
        setShowUpdateCancelIcons(true);
        const rowInfoCopy = JSON.parse(JSON.stringify(rowInfo));
        const addrInfo = [...rowInfoCopy[0].address];
        addrInfo.filter((st) => {
            if (st.id === id) {
                st.state = e.target.value;
            }
        });
        setRowInfo([{ ...rowInfo[0], address: addrInfo }]);
    };
    const _handleFocusZipCode = (id: any, text: any) => {
        setShowUpdateCancelIcons(true);
    };

    const _handleDefaultBillingAddress = (e: any, index: any, name: any) => {
        let billingAddress0 = document.getElementById(
            'billingAddress0'
        ) as HTMLInputElement;
        let billingAddress1 = document.getElementById(
            'billingAddress1'
        ) as HTMLInputElement;
        let billingAddress2 = document.getElementById(
            'billingAddress2'
        ) as HTMLInputElement;
        console.log(e, 'eve');
        const rowInfoCopy = JSON.parse(JSON.stringify(rowInfo));
        const value = e.target.checked;
        const targetName = e.target.name;
        let list = [...rowInfoCopy[0].address];
        if (targetName === 'billingAddress0') {
            if (billingAddress1) {
                billingAddress1.checked = false;
                list[1][name] = false;
            } 
            if (billingAddress2) {
                billingAddress2.checked = false;
                list[2][name] = false;
            }
            billingAddress0.checked = value;
            list[0][name] = value;
        } 
        if (targetName === 'billingAddress1') {
            if (billingAddress0) {
                billingAddress0.checked = false;
                list[0][name] = false;
            } 
             if (billingAddress2) {
                billingAddress2.checked = false;
                list[2][name] = false;
            }
            billingAddress1.checked = value;
            list[1][name] = value;
        } 
         if (targetName === 'billingAddress2') {
            if (billingAddress0) {
                billingAddress0.checked = false;
                list[0][name] = false;
            } 
            if (billingAddress1) {
                billingAddress1.checked = false;
                list[1][name] = false;
            }
        }
        rowInfoCopy[0].address = [...list];
        setRowInfo(rowInfoCopy);
        setShowUpdateCancelIcons(true);
    };
    const _handleFocusOutZipCode = (id: any, e: any, index: any) => {
        setShowUpdateCancelIcons(true);
        setErrors({
            emailError,
            faxError,
            phoneNumberError,
            altPhoneError,
            zipError,
        });
        // var regexFax = "^[0-9]{5}(?:-[0-9]{4})?$";
        if (e.target.value.length <= 5) {
            const rowInfoCopy = JSON.parse(JSON.stringify(rowInfo));
            const name = e.target.name;
            const value = e.target.value;
            let list = [...rowInfoCopy[0].address];
            list[index][name] = value;
            rowInfoCopy[0].address = [...list];
            console.log(rowInfoCopy);
            setRowInfo(rowInfoCopy);
            // setRowInfo([{ ...rowInfo[0], address: addrInfo }]);
            zipError = '';
            setErrors({
                emailError,
                faxError,
                phoneNumberError,
                altPhoneError,
                zipError,
            });
            return true;
        } else {
            zipError = 'Zip code is invalid';
            setShowUpdateCancelIcons(false);
        }
        if (zipError) {
            setErrors({
                emailError,
                faxError,
                phoneNumberError,
                altPhoneError,
                zipError,
            });
            return false;
        }
    };

    const handleChangeCountry = (id: any, e: any, index: any, name: any) => {
        setShowUpdateCancelIcons(true);
        setRemovedState(true);
        let parsedCountry = JSON.parse(JSON.stringify(e.target.value));
        // const parsedCountry = e?.target?.value?.split('-');
        const rowInfoCopy = JSON.parse(JSON.stringify(rowInfo));
        let list = [...rowInfoCopy[0].address];
        if (parsedCountry === 'Select') {
            parsedCountry = 'Select';
            list[index]['state'] = null;
        } else {
            parsedCountry = JSON.parse(parsedCountry);
        }

        if (parsedCountry && parsedCountry?.countryName) {
            list[index][name] = parsedCountry?.countryName;
        } else {
            list[index][name] = null;
        }
        rowInfoCopy[0].address = [...list];
        setRowInfo(rowInfoCopy);
        //setfinalCountryList(countryInfo);
        const getEndpoint = getStatesByCountry(parsedCountry?.isoCountryCode);
        handleGetStatesList(getEndpoint);
    };
    const handleGetStatesList = async (getEndpoint: string) => {
        const getresult = await axios.get(getEndpoint);
        let cData: any = getresult.data;
        dispatch(setStatesData(cData));
    };

    const handleChangeAltCountry = (id: any, e: any) => {
        setShowUpdateCancelIcons(true);
        setRemovedState(true);
        const parsedCountry = JSON.parse(e.target.value);
        // const parsedCountry = e?.target?.value?.split('-');
        const rowInfoCopy = JSON.parse(JSON.stringify(rowInfo));
        const addrInfo = [...rowInfoCopy[0].address];
        addrInfo.filter((st) => {
            if (st.id === id) {
                st.country = parsedCountry.countryName;
            }
        });
        setRowInfo([{ ...rowInfo[0], address: addrInfo }]);
        setfinalCountryList(countryInfo);
        const getEndpoint = getStatesByCountry(parsedCountry.isoCountryCode);
        handleGetAltStatesList(getEndpoint);
    };
    const handleGetAltStatesList = async (getEndpoint: string) => {
        const getresult = await axios.get(getEndpoint);
        let cData: any = getresult.data;
        dispatch(setStatesAddrDataOne(cData));
    };

    const _handleFocusFax = (e: any) => {
        setErrors({
            emailError,
            faxError,
            phoneNumberError,
            altPhoneError,
            zipError,
        });
        var phoneno = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
        if (e.target.value.match(phoneno)) {
            setRowInfo([{ ...rowInfo[0], fax: e.target.value }]);
            setShowUpdateCancelIcons(true);
            faxError = '';
            setErrors({
                emailError,
                faxError,
                phoneNumberError,
                altPhoneError,
                zipError,
            });
            return true;
        } else {
            faxError = 'Fax is invalid';
            setShowUpdateCancelIcons(false);
        }
        if (faxError) {
            setErrors({
                emailError,
                faxError,
                phoneNumberError,
                altPhoneError,
                zipError,
            });
            return false;
        }
        // setShowUpdateCancelIcons(true);
        // setRowInfo([{ ...rowInfo[0], fax: e.target.value }])
    };

    const _handleFocusOutFax = (text: any) => {
        setShowUpdateCancelIcons(true);
        setRowInfo([{ ...rowInfo[0], fax: text }]);
    };

    const handleUpdateLanguage = (e: any) => {
        setShowUpdateCancelIcons(true);
        setRowInfo([{ ...rowInfo[0], language: e.target.value }]);
    };
    const handleUpdateTimezone = (e: any) => {
        setShowUpdateCancelIcons(true);
        setRowInfo([{ ...rowInfo[0], timezone: e.target.value }]);
    };
    const handleUpdateCurrencycode = (e: any) => {
        setShowUpdateCancelIcons(true);
        setRowInfo([{ ...rowInfo[0], currencyCode: e.target.value }]);
    };

    const handleChangeCountryCodePhone = (e: any) => {
        setShowUpdateCancelIcons(true);
        setRowInfo([{ ...rowInfo[0], phoneCountryCode: e.target.value }]);
    };
    const handleChangeCountryCodeAltPhone = (e: any) => {
        setShowUpdateCancelIcons(true);
        setRowInfo([{ ...rowInfo[0], altPhoneCountryCode: e.target.value }]);
    };
    const handleChangeCountryCodeFax = (e: any) => {
        setShowUpdateCancelIcons(true);
        setRowInfo([{ ...rowInfo[0], faxCountryCode: e.target.value }]);
    };
    const handleSwitch = (e: any) => {
        setShowUpdateCancelIcons(true);
        setRowInfo([{ ...rowInfo[0], active: e.target.checked }]);
    };
    const handleViewAddressList = () => {
        setShowAddressListInfo(true);
    };
    const handleHideAddressList = () => {
        setShowAddressListInfo(false);
    };
    const handleStoreAltEmailAddClick = () => {
        setAltEmailDiv(true);
        setShowUpdateCancelIcons(true);
        setAaltEmailStore([...altEmailStore, { altEmail: '' }]);
    };
    const handleAltEmailInputChange = (e: any, index: number) => {
        const name = e.target.name;
        const value = e.target.value;
        const list = [...altEmailStore];
        list[index][name] = value;
        setAaltEmailStore(list);
    };
    const handleStoreAltEmailRemoveClick = (index: number) => {
        setShowUpdateCancelIcons(true);
        const list = [...altEmailStore];
        list.splice(index, 1);
        setAaltEmailStore(list);
    };
    /*Alt Email Customers */
    const handleCustomerAltEmailAddClick = () => {
        setAltEmailDiv(true);
        setShowUpdateCancelIcons(true);
        setAaltEmailCustomer([...altEmailCustomer, { altEmail: '' }]);
    };
    const handleAltEmailInputChangeCustomer = (e: any, index: number) => {
        const name = e.target.name;
        const value = e.target.value;
        const list = [...altEmailCustomer];
        list[index][name] = value;
        setAaltEmailCustomer(list);
    };
    const handleCustomerAltEmailRemoveClick = (index: number) => {
        setShowUpdateCancelIcons(true);
        const list = [...altEmailCustomer];
        list.splice(index, 1);
        setAaltEmailCustomer(list);
    };
    /*Alt Email Customers */
    const handleRemoveAddressList = (id: any) => {
        setShowUpdateCancelIcons(true);
        let linkedGLAccData = [...rowInfo];
        let addr = linkedGLAccData.map((item) => ({
            ...item,
            address: item.address.filter((itemData: any) => itemData.id !== id),
        }));
        setRowInfo(addr);
    };
    const handleAltEmailRemoveExisting = (custType: any) => {
        setShowUpdateCancelIcons(true);
        let linkedWarehouseData = [...rowInfo];
        let updatedStoreData = linkedWarehouseData.map((item) => ({
            ...item,
            alternateEmailList: item.alternateEmailList.filter(
                (itemData: any) => itemData !== custType
            ),
        }));
        setRowInfo(updatedStoreData);
    };

    const handleAltEmailRemoveExistingCustomer = (custType: any) => {
        setShowUpdateCancelIcons(true);
        let linkedWarehouseData = [...rowInfo];
        let updatedStoreData = linkedWarehouseData.map((item) => ({
            ...item,
            alternateEmailList: item.alternateEmailList.filter(
                (itemData: any) => itemData !== custType
            ),
        }));
        setRowInfo(updatedStoreData);
    };

    const handleShowNewAddress = () => {
        // const { name, value } = e.target;
        // const list = [...addressList,index[name]];
        // // list[index][name] = value;
        // setaddressList(list);
        // console.log(addressList)
        // setaddressList([
        //     ...addressList,
        //     {
        //         street: '',
        //         city: '',
        //         state: '',
        //         zipcode: '',
        //         country: '',
        //         active: true,
        //         alternateAddress: false,
        //         billingAddress: false,
        //         deleted: false,
        //     },
        // ]);
        const rowInfoCopy = JSON.parse(JSON.stringify(rowInfo));
        let address: any = [...rowInfoCopy[0].address];
        if (rowInfoCopy[0].address.length < 3) {
            const customerAddress = [
                ...addressList,
                ...[
                    {
                        street: '',
                        city: '',
                        state: '',
                        zipcode: '',
                        country: '',
                        active: true,
                        alternateAddress: true,
                        billingAddress: false,
                        deleted: false
                    },
                ],
            ];
            rowInfoCopy[0].address = [
                ...rowInfoCopy[0].address,
                ...customerAddress,
            ];
            setRowInfo(rowInfoCopy);
            setShowNewAddressDiv(true);
            setShowUpdateCancelIcons(true);
        }
    };

    const handleRemoveClick = (index: any) => {
        const list = [...addressList];
        list.splice(index, 1);
        setaddressList(list);
        setShowUpdateCancelIcons(true);
    };
    let finalAddressList: any;
    let listofAddresses: any;

    const handleStreetInputChange = (e: any, index: number) => {
        const name = e.target.name;
        const value = e.target.value;
        const rowInfoCopy = JSON.parse(JSON.stringify(rowInfo));
        let list = [...rowInfoCopy[0].address];
        list[index][name] = value;
        rowInfoCopy[0].address = [...list];
        setRowInfo(rowInfoCopy);
    };

    const handleCityInputChange = (e: any, index: number) => {
        const name = e.target.name;
        const value = e.target.value;
        const rowInfoCopy = JSON.parse(JSON.stringify(rowInfo));
        let list = [...rowInfoCopy[0].address];
        list[index][name] = value;
        rowInfoCopy[0].address = [...list];
        setRowInfo(rowInfoCopy);
    };
    //let finalCountryList :  any[] = [];
    let [addCountryIsoCode, setaddCountryIsoCode] = useState('');
    useEffect(() => {
        let filteredCountry: any;
        console.log(showUpdateCancelIconsOnPreview);
        if (
            setTabHeading.payload === ContactType.STORES ||
            setTabHeading.payload === ContactType.VENDORS
        ) {
            if (
                selStoreAndVendorRowData &&
                selStoreAndVendorRowData?.length > 0
            ) {
                setRowInfo(selStoreAndVendorRowData);
                selStoreAndVendorRowData.map((i: any) => {
                    i?.address?.map((c: any) => {
                        filteredCountry = c?.country;
                    });
                    //  const getEndpoint = getStatesByCountry('USA');
                    //  handleGetStatesList(getEndpoint);
                });
            }
        } else {
            if (setRowDataSelected && setRowDataSelected?.length > 0) {
                setRowInfo(setRowDataSelected);
                setRowDataSelected.map((i: any) => {
                    i?.address?.map((c: any) => {
                        filteredCountry = c?.country;
                    });
                    //  const getEndpoint = getStatesByCountry('USA');
                    //  handleGetStatesList(getEndpoint);
                });
            }
        }
        console.log(setRowDataSelected);
        console.log(rowInfo);
        setfinalCountryList(
            countryInfo.filter(
                (itemData: any) => itemData?.countryName !== filteredCountry
            )
        );
        let countryIsoCode: any;

        console.log(rowInfo);

        if (setRowDataSelected && setRowDataSelected[0]) {
            const selRowInfoCopy = setRowDataSelected[0];
            if (
                setRowDataSelected[0]?.address !== undefined &&
                setRowDataSelected[0]?.address !== null &&
                setRowDataSelected[0]?.address !== ''
            ) {
                setRowDataSelected[0]?.address?.map((add: any, idx: number) => {
                    countryInfo.map((item: any) => {
                        if (item.countryName === filteredCountry) {
                            countryIsoCode = item.isoCountryCode;
                            const getEndpoint =
                                getStatesByCountry(countryIsoCode);
                            handleGetStatesList(getEndpoint);
                        }
                        if (item?.countryName === add?.country) {
                            //selRowInfoCopy[0]['address'][idx]['addCountryIsoCode'] = item?.id + ' ' + item.isoCountryCode;
                            setaddCountryIsoCode(
                                item?.countryName + '-' + item.isoCountryCode
                            );
                        }
                    });
                });
            }
        }
        console.log(addCountryIsoCode);
    }, [
        setRowDataSelected,
        selStoreAndVendorRowData,
        showUpdateCancelIconsOnPreview,
    ]);

    const handleMasterDataChange = async (
        endpoint: string,
        getEndpoint: string
    ) => {
        let rowInfoObject: any;
        if (finalRowInfoCopy.length === 0) {
            rowInfoObject = Object.assign({}, ...rowInfo);
        } else {
            rowInfoObject = Object.assign({}, ...finalRowInfoCopy);
        }

        const tempData = rowInfoObject;
        if (
            mobileWithFlag !== null &&
            mobileWithFlag !== undefined &&
            mobileWithFlag !== ''
        ) {
            tempData.phoneNumber = mobileWithFlag
                ?.toString()
                .replace(/[^0-9]/g, '');
        }
        if (
            altMobileWithFlag !== null &&
            altMobileWithFlag !== undefined &&
            altMobileWithFlag !== ''
        ) {
            tempData.alternatePhoneNumber = altMobileWithFlag
                ?.toString()
                .replace(/[^0-9]/g, '');
        }
        if (
            faxWithFlag !== null &&
            faxWithFlag !== undefined &&
            faxWithFlag !== ''
        ) {
            tempData.fax = faxWithFlag?.toString().replace(/[^0-9]/g, '');
        }
        if (
            phoneCountryCode !== null &&
            phoneCountryCode !== undefined &&
            phoneCountryCode !== ''
        ) {
            tempData.phoneCountryCode = phoneCountryCode;
        }
        if (
            AltPhoneCountryCode !== null &&
            AltPhoneCountryCode !== undefined &&
            AltPhoneCountryCode !== ''
        ) {
            tempData.altPhoneCountryCode = AltPhoneCountryCode;
        }
        if (
            faxCountryCode !== null &&
            faxCountryCode !== undefined &&
            faxCountryCode !== ''
        ) {
            tempData.faxCountryCode = faxCountryCode;
        }
        //Object.assign({rowInfoObject});

        //  const result = await axios.put(endpoint, rowInfoObject);
        // if (result && result.data) {
        //  notify(` Success! Changes saved`, 'success');
        // } else {
        //   notify(`Failed to update ${setTabHeading.payload}`, 'error');
        // }
        const result = await axios
            .put(endpoint, tempData)
            .then((results: any) => {
                if (results && results.data) {
                    notify('Success! Changes saved', 'success');
                    dispatch(setShowUpdateAndCancelIconsOnPreview(false));
                } else {
                    notify(
                        `Failed to update ${setTabHeading.payload}`,
                        'error'
                    );
                }
            })
            .catch((error: any) => {
                if (error.response) {
                    notify(error.response?.data?.message, 'error');
                }
            });
        let getresult: any;
        if (setTabHeading.payload === ContactType.STORES) {
            getresult = await axios.get(getEndpoint);
            let storesArray: any = getresult.data;
            dispatch(setUpdatedStoresORVendorData(storesArray?.content));
            // dispatch(setCurrentContact(storesArray?.content));
            // setTimeout(() => {
            //   const dataa = getresult.data[0];
            //   dispatch(setStoresORVendorsRowData(dataa));
            // }, 10);
        } else if (setTabHeading.payload === ContactType.VENDORS) {
            getresult = await axios.get(getEndpoint);
            let vendorsArray: any = getresult.data;
            dispatch(setUpdatedStoresORVendorData(vendorsArray?.content));
            // dispatch(setCurrentContact(vendorsArray?.content));
            // setTimeout(() => {
            //   const dataa = getresult.data[0];
            //   dispatch(setStoresORVendorsRowData(dataa));
            // }, 10);
        } else {
            getresult = await axios.get(getEndpoint);
            dispatch(setCurrentContact(getresult.data));
            setTimeout(() => {
                const dataa = getresult.data[0];
                dispatch(setRowData(dataa));
            }, 10);
        }
    };

    const handleCancel = () => {
        if (setTabHeading.payload === ContactType.STORES) {
            const getEndpoint = getEndPoint(setTabHeading.payload, 1, 1);
            handleCancelAction(getEndpoint);
        } else if (setTabHeading.payload === ContactType.VENDORS) {
            const getEndpoint = getEndPoint(setTabHeading.payload, 1, 1);
            handleCancelAction(getEndpoint);
        } else {
            const getEndpoint = getEndPoint(setTabHeading.payload);
            handleCancelAction(getEndpoint);
        }
    };

    const handleCancelAction = async (getEndpoint: string) => {
        if (setTabHeading.payload === ContactType.STORES) {
            const getresult = await axios.get(getEndpoint);
            let storesArray: any = getresult.data;
            // dispatch(setCurrentContact(storesArray?.content));
            dispatch(setUpdatedStoresORVendorData(storesArray?.content));
        } else if (setTabHeading.payload === ContactType.VENDORS) {
            const getresult = await axios.get(getEndpoint);
            let vendorsArray: any = getresult.data;
            // dispatch(setCurrentContact(vendorsArray?.content));
            dispatch(setUpdatedStoresORVendorData(vendorsArray?.content));
        } else {
            const getresult = await axios.get(getEndpoint);
            dispatch(setCurrentContact(getresult.data));
        }
        notify(`Information! Changes discarded`, 'warning');
    };

    let finalAltEmails: any[] = [];
    const updateIDs: any[] = [];

    const handleUpdate = () => {
        if (setTabHeading.payload === ContactType.WAREHOUSES) {
            // var CCPhone = document.getElementById("txtPhone") as HTMLInputElement;
            // var countryCodephone = document.getElementById(
            //   "txtCountryCodePhone"
            // ) as HTMLSelectElement;
            // var altPhone = document.getElementById("txtAltPhone") as HTMLInputElement;
            // var countryCodeAltPhone = document.getElementById(
            //   "txtCountryCodeAltPhone"
            // ) as HTMLSelectElement;
            // var fax = document.getElementById("txtFax") as HTMLInputElement;
            // var countryCodeFax = document.getElementById(
            //   "txtCountryCodeFax"
            // ) as HTMLSelectElement;
            // if (CCPhone.value !== "" && countryCodephone.value === "") {
            //   notify("Please select Country code for Phone number", "warning");
            // } else if (altPhone.value !== "" && countryCodeAltPhone.value === "") {
            //   notify("Please select Country code for Alt Phone number", "warning");
            // } else if (fax.value !== "" && countryCodeFax.value === "") {
            //   notify("Please select Country code for Fax", "warning");
            // } else {
            const whArray = JSON.parse(
                JSON.stringify(rowInfo[0].reclaimCenters)
            );
            whArray.filter((item: any) => {
                return updateIDs.push(item.id);
            });

            setRowInfo([{ ...rowInfo[0], reclaimCenterIds: updateIDs }]);
            setWarehouseInfo(true);
            //  }
        } else if (setTabHeading.payload === ContactType.DEPARTMENTS) {
            const whArray = JSON.parse(JSON.stringify(rowInfo[0].glAccounts));
            whArray.filter((item: any) => {
                return updateIDs.push(item.id);
            });
            setRowInfo([{ ...rowInfo[0], glAccountIds: updateIDs }]);
            setWarehouseInfo(true);
        } else if (setTabHeading.payload === ContactType.GL_ACCOUNTS) {
            const whArray = JSON.parse(JSON.stringify(rowInfo[0].departments));
            whArray.filter((item: any) => {
                return updateIDs.push(item.id);
            });
            setRowInfo([{ ...rowInfo[0], departmentIds: updateIDs }]);
            setWarehouseInfo(true);
        } else if (setTabHeading.payload === ContactType.STORES) {
            // var CCPhone = document.getElementById("txtPhone") as HTMLInputElement;
            // var countryCodephone = document.getElementById(
            //   "txtCountryCodePhone"
            // ) as HTMLSelectElement;
            // var altPhone = document.getElementById("txtAltPhone") as HTMLInputElement;
            // var countryCodeAltPhone = document.getElementById(
            //   "txtCountryCodeAltPhone"
            // ) as HTMLSelectElement;
            // var fax = document.getElementById("txtFax") as HTMLInputElement;
            // var countryCodeFax = document.getElementById(
            //   "txtCountryCodeFax"
            // ) as HTMLSelectElement;
            // if (CCPhone.value !== "" && countryCodephone.value === "") {
            //   notify("Please select Country code for Phone number", "warning");
            // } else if (altPhone.value !== "" && countryCodeAltPhone.value === "") {
            //   notify("Please select Country code for Alt Phone number", "warning");
            // } else if (fax.value !== "" && countryCodeFax.value === "") {
            //   notify("Please select Country code for Fax", "warning");
            // } else {
            const altEmailStor = JSON.parse(JSON.stringify(altEmailStore));
            finalAltEmails = [...rowInfo[0].alternateEmailList];
            altEmailStor.filter((item: any) => {
                return updateIDs.push(item.altEmail);
            });
            finalAltEmails.push.apply(finalAltEmails, updateIDs);
            setRowInfo([{ ...rowInfo[0], alternateEmailList: finalAltEmails }]);
            setStoreUpdateFlag(true);
            // }
        } else if (setTabHeading.payload === ContactType.CUSTOMERS) {
            // var CCPhone = document.getElementById("txtPhone") as HTMLInputElement;
            // var countryCodephone = document.getElementById(
            //   "txtCountryCodePhone"
            // ) as HTMLSelectElement;
            // var altPhone = document.getElementById("txtAltPhone") as HTMLInputElement;
            // var countryCodeAltPhone = document.getElementById(
            //   "txtCountryCodeAltPhone"
            // ) as HTMLSelectElement;
            // var fax = document.getElementById("txtFax") as HTMLInputElement;
            // var countryCodeFax = document.getElementById(
            //   "txtCountryCodeFax"
            // ) as HTMLSelectElement;
            // if (CCPhone.value !== "" && countryCodephone.value === "") {
            //   notify("Please select Country code for Phone number", "warning");
            // } else if (altPhone.value !== "" && countryCodeAltPhone.value === "") {
            //   notify("Please select Country code for Alt Phone number", "warning");
            // } else if (fax.value !== "" && countryCodeFax.value === "") {
            //   notify("Please select Country code for Fax", "warning");
            // } else {
            const altEmailCust = JSON.parse(JSON.stringify(altEmailCustomer));
            finalAltEmails = [...rowInfo[0].alternateEmailList];
            altEmailCust.filter((item: any) => {
                return updateIDs.push(item.altEmail);
            });
            finalAltEmails.push.apply(finalAltEmails, updateIDs);
            setRowInfo([{ ...rowInfo[0], alternateEmailList: finalAltEmails }]);
            setWarehouseInfo(true);
            // }
            // } else if (setTabHeading.payload === ContactType.COMPANY_CODES) {
            var CCEmail = document.getElementById(
                'txtEmail'
            ) as HTMLInputElement;
            // var CCPhone = document.getElementById("txtPhone") as HTMLInputElement;
            // var countryCodephone = document.getElementById(
            //   "txtCountryCodePhone"
            // ) as HTMLSelectElement;
            // var altPhone = document.getElementById("txtAltPhone") as HTMLInputElement;
            // var countryCodeAltPhone = document.getElementById(
            //   "txtCountryCodeAltPhone"
            // ) as HTMLSelectElement;
            // var fax = document.getElementById("txtFax") as HTMLInputElement;
            // var countryCodeFax = document.getElementById(
            //   "txtCStateountryCodeFax"
            // ) as HTMLSelectElement;
            if (CCEmail.value === '') {
                notify(`Email is required`, 'warning');
                setShowUpdateCancelIcons(false);
            }
            // else if (CCPhone.value === "") {
            //   notify(`Phone is required`, "warning");
            //   setShowUpdateCancelIcons(false);
            // } else if (CCPhone.value !== "" && countryCodephone.value === "") {
            //   notify("Please select Country code for Phone number", "warning");
            // } else if (altPhone.value !== "" && countryCodeAltPhone.value === "") {
            //   notify("Please select Country code for Alt Phone number", "warning");
            // } else if (fax.value !== "" && countryCodeFax.value === "") {
            //   notify("Please select Country code for Fax", "warning");
            // }
            //  else {
            setWarehouseInfo(true);
            //  setRowInfo([{ ...rowInfo[0], alternateEmailList: finalAltEmails }])
            //  setWarehouseInfo(true);
            // }
        } else if (setTabHeading.payload === ContactType.CARRIERS) {
            console.log('carriers');
            // var CCPhone = document.getElementById("txtPhone") as HTMLInputElement;
            // var countryCodephone = document.getElementById(
            //   "txtCountryCodePhone"
            // ) as HTMLSelectElement;
            // var altPhone = document.getElementById("txtAltPhone") as HTMLInputElement;
            // var countryCodeAltPhone = document.getElementById(
            //   "txtCountryCodeAltPhone"
            // ) as HTMLSelectElement;
            // var fax = document.getElementById("txtFax") as HTMLInputElement;
            // var countryCodeFax = document.getElementById(
            //   "txtCountryCodeFax"
            // ) as HTMLSelectElement;
            // if (CCPhone.value !== "" && countryCodephone.value === "") {
            //   notify("Please select Country code for Phone number", "warning");
            // } else if (altPhone.value !== "" && countryCodeAltPhone.value === "") {
            //   notify("Please select Country code for Alt Phone number", "warning");
            // } else if (fax.value !== "" && countryCodeFax.value === "") {
            //   notify("Please select Country code for Fax", "warning");
            // } else {
            setWarehouseInfo(true);
            //  }
        } else if (setTabHeading.payload === ContactType.VENDORS) {
            // var CCPhone = document.getElementById("txtPhone") as HTMLInputElement;
            // var countryCodephone = document.getElementById(
            //   "txtCountryCodePhone"
            // ) as HTMLSelectElement;
            // var altPhone = document.getElementById("txtAltPhone") as HTMLInputElement;
            // var countryCodeAltPhone = document.getElementById(
            //   "txtCountryCodeAltPhone"
            // ) as HTMLSelectElement;
            // var fax = document.getElementById("txtFax") as HTMLInputElement;
            // var countryCodeFax = document.getElementById(
            //   "txtCountryCodeFax"
            // ) as HTMLSelectElement;
            // if (CCPhone.value !== "" && countryCodephone.value === "") {
            //   notify("Please select Country code for Phone number", "warning");
            // } else if (altPhone.value !== "" && countryCodeAltPhone.value === "") {
            //   notify("Please select Country code for Alt Phone number", "warning");
            // } else if (fax.value !== "" && countryCodeFax.value === "") {
            //   notify("Please select Country code for Fax", "warning");
            // } else {
            const endpoint = updateMasterDataEndPoint(
                setTabHeading.payload,
                rowInfo
            );
            const getEndpoint = getEndPoint(setTabHeading.payload, 1, 1);
            handleMasterDataChange(endpoint, getEndpoint);
            //  }
        } else if (setTabHeading.payload === ContactType.RECLAIM_CENTERS) {
            // var CCPhone = document.getElementById("txtPhone") as HTMLInputElement;
            // var countryCodephone = document.getElementById(
            //   "txtCountryCodePhone"
            // ) as HTMLSelectElement;
            // var altPhone = document.getElementById("txtAltPhone") as HTMLInputElement;
            // var countryCodeAltPhone = document.getElementById(
            //   "txtCountryCodeAltPhone"
            // ) as HTMLSelectElement;
            // var fax = document.getElementById("txtFax") as HTMLInputElement;
            // var countryCodeFax = document.getElementById(
            //   "txtCountryCodeFax"
            // ) as HTMLSelectElement;
            // if (CCPhone.value !== "" && countryCodephone.value === "") {
            //   notify("Please select Country code for Phone number", "warning");
            // } else if (altPhone.value !== "" && countryCodeAltPhone.value === "") {
            //   notify("Please select Country code for Alt Phone number", "warning");
            // } else if (fax.value !== "" && countryCodeFax.value === "") {
            //   notify("Please select Country code for Fax", "warning");
            // } else {
            setWarehouseInfo(true);
            // }
        } else {
            const endpoint = updateMasterDataEndPoint(
                setTabHeading.payload,
                rowInfo
            );
            const getEndpoint = getEndPoint(setTabHeading.payload);
            handleMasterDataChange(endpoint, getEndpoint);
        }
    };

    useEffect(() => {
        console.log(showUpdateCancelIconsOnPreview);
        if (warehouseInfo) {
            const endpoint = updateMasterDataEndPoint(
                setTabHeading.payload,
                rowInfo
            );
            const getEndpoint = getEndPoint(setTabHeading.payload);
            handleMasterDataChange(endpoint, getEndpoint);
        }
    }, [warehouseInfo, showUpdateCancelIconsOnPreview]);

    useEffect(() => {
        console.log(showUpdateCancelIconsOnPreview);
        if (storeUpdateFlag) {
            const endpoint = updateMasterDataEndPoint(
                setTabHeading.payload,
                rowInfo
            );
            const getEndpoint = getEndPoint(setTabHeading.payload, 1, 1000);
            handleMasterDataChange(endpoint, getEndpoint);
        }
    }, [storeUpdateFlag, showUpdateCancelIconsOnPreview]);

    const validate = (field?: any, value?: any) => {
        if (zipError === '') {
            zipError = 'Name is required';
        }
        if (
            emailError ||
            faxError ||
            phoneNumberError ||
            altPhoneError ||
            zipError
        ) {
            setErrors({
                emailError,
                faxError,
                phoneNumberError,
                altPhoneError,
                zipError,
            });
            //setAddButtonDisabledCarrier(true);
            return false;
        } else {
            //setAddButtonDisabledCarrier(false);
        }
        return true;
    };

    console.log(rowInfo);
    return (
        <div>
            {rowInfo && rowInfo?.length > 0 ? (
                <>
                    <div className="paddingGeneralTab generalTabStoreDiv">
                        {rowInfo?.map((info) => (
                            <>
                                <div className="row generalTabInfo">
                                    <div className="col-sm-4">
                                        <span className="storeNumHeading lblemail">
                                            Email
                                        </span>
                                    </div>
                                    <div className="col-sm-8 pl-0">
                                        {/* <InlineInputEdit inputClassName="txtEditClassNameInput"
                      text={info.email}
                      labelClassName="txtEditClassName"
                      onFocus={_handleFocusEmail}
                      onFocusOut={_handleFocusOutEmail}
                      labelPlaceHolder='- - -'
                      /> */}
                                        <input
                                            type="text"
                                            id="txtEmail"
                                            className="form-control pl-0 boxShadow-None border-none cursor-pointer txtEditFieldFocus boxShadow-None"
                                            onChange={(e: any) =>
                                                _handleFocusEmail(e)
                                            }
                                            defaultValue={info.email}
                                            onBlur={(e: any) =>
                                                _handleFocusEmail(e)
                                            }
                                            onKeyUp={(e: any) =>
                                                _handleFocusEmail(e)
                                            }
                                            style={{
                                                textTransform: 'lowercase',
                                            }}
                                        />
                                        {errors.emailError ? (
                                            <span className="text-danger">
                                                {errors.emailError}
                                            </span>
                                        ) : (
                                            ''
                                        )}
                                    </div>
                                </div>
                                <div className="row generalTabInfo">
                                    <div className="col-sm-4">
                                        <span className="storeNumHeading lblphone ">
                                            Phone
                                        </span>
                                    </div>
                                    <div className="col-sm-8 pl-0 pr-0">
                                        <CountryDropdownPreview
                                            type="Phone"
                                            value={info.phoneNumber}
                                        />
                                        {/* <select className="border-none countryCodeDdl boxShadow-None ddlFocus" value={info.phoneCountryCode} id="txtCountryCodePhone" onChange={(e)=> handleChangeCountryCodePhone(e)}>
                    <option value={info.phoneCountryCode}> {info.phoneCountryCode}</option>
                  {countryCodeInfo?.map((ld) =>
                    <option value={ld?.mobileCountryCode}>{ld?.mobileCountryCode}</option>
                  )}
                  </select> */}
                                    </div>
                                    {/* <div className="col-sm-6">
                  <input type="text" id="txtPhone" className="form-control boxShadow-None border-none cursor-pointer txtEditFieldFocus boxShadow-None" onChange={(e: any)=>_handleFocusPhone(e)} defaultValue={info.phoneNumber}
                      onBlur={(e: any)=>_handleFocusPhone(e)} onKeyUp={(e: any)=>_handleFocusPhone(e)}/>
                     <InlineInputEdit inputClassName="txtEditClassNameInput"
                      text={info.phoneNumber}
                      labelClassName="txtEditClassName"
                      onFocus={_handleFocusPhone}
                      onFocusOut={_handleFocusOutPhone}
                      labelPlaceHolder='- - -' />
                      {errors.phoneNumberError?<span className="text-danger">{errors.phoneNumberError}</span>:""}
                  </div> */}
                                </div>
                                <div className="row generalTabInfo">
                                    <div className="col-sm-4">
                                        <span className="storeNumHeading lblaltphone">
                                            Alt Phone
                                        </span>
                                    </div>
                                    <div className="col-sm-8 pl-0 pr-0">
                                        <CountryDropdownPreview
                                            type="Alt Phone"
                                            value={info.alternatePhoneNumber}
                                        />
                                        {/* <select className="border-none countryCodeDdl boxShadow-None ddlFocus" value={info.altPhoneCountryCode} id="txtCountryCodeAltPhone" onChange={(e)=> handleChangeCountryCodeAltPhone(e)}>
                  <option value={info.altPhoneCountryCode}> {info.altPhoneCountryCode}</option>
                  {countryCodeInfo?.map((ld) =>
                    <option value={ld?.mobileCountryCode}>{ld?.mobileCountryCode}</option>
                  )}
                  </select>  */}
                                    </div>
                                    {/* <div className="col-sm-6">
                   <InlineInputEdit inputClassName="txtEditClassNameInput"
                      text={info.alternatePhoneNumber}
                      labelClassName="txtEditClassName"
                      onFocus={_handleFocusAltPhone}
                      onFocusOut={_handleFocusOutAltPhone}
                      labelPlaceHolder='- - -' />
                       <input type="text" id="txtAltPhone" className="form-control boxShadow-None border-none cursor-pointer txtEditFieldFocus boxShadow-None" onChange={(e: any)=>_handleFocusAltPhone(e)} defaultValue={info.alternatePhoneNumber}
                      onBlur={(e: any)=>_handleFocusAltPhone(e)} onKeyUp={(e: any)=>_handleFocusAltPhone(e)}/>
                         {errors.altPhoneError?<span className="text-danger">{errors.altPhoneError}</span>:""}
                  </div> */}
                                </div>
                                <div className="row generalTabInfo">
                                    <div className="col-sm-4">
                                        <span className="storeNumHeading lblFax">
                                            Fax
                                        </span>
                                    </div>
                                    <div className="col-sm-8 pl-0 pr-0">
                                        <CountryDropdownPreview
                                            type="Fax"
                                            value={info.fax}
                                        />
                                        {/* <select className="border-none countryCodeDdl boxShadow-None ddlFocus" value={info.faxCountryCode} id="txtCountryCodeFax" onChange={(e)=> handleChangeCountryCodeFax(e)}>
                   <option value={info.faxCountryCode}> {info.faxCountryCode}</option>
                  {countryCodeInfo?.map((ld) =>
                    <option value={ld?.mobileCountryCode}>{ld?.mobileCountryCode}</option>
                  )}
                  </select>  */}
                                    </div>

                                    {/* <div className="col-sm-6">
                     <InlineInputEdit inputClassName="txtEditClassNameInput"
                      text={info.fax}
                      labelClassName="txtEditClassName"
                      onFocus={_handleFocusFax}
                      onFocusOut={_handleFocusOutFax}
                      labelPlaceHolder='- - -'
                      />
                       <input type="text" id="txtFax" className="form-control boxShadow-None border-none cursor-pointer txtEditFieldFocus boxShadow-None" onChange={(e: any)=>_handleFocusFax(e)} defaultValue={info.fax}
                      onBlur={(e: any)=>_handleFocusFax(e)} onKeyUp={(e: any)=>_handleFocusFax(e)}/>
                       {errors.faxError?<span className="text-danger">{errors.faxError}</span>:""}
                  </div> */}
                                </div>
                                <hr />
                                <div className="row generalTabInfo">
                                    <div className="col-sm-5">
                                        <span className="storeNumHeading lbllanguage">
                                            Language
                                        </span>
                                    </div>
                                    <div className="col-sm-7">
                                        <select
                                            className="form-control border-none storeNumber ddlFocus boxShadow-None"
                                            value={info?.language}
                                            onChange={(e) =>
                                                handleUpdateLanguage(e)
                                            }
                                        >
                                            {/* <option value={info?.language}>{info?.language}</option> */}
                                            {languageData?.map((ld) => (
                                                <option value={ld?.isoLongCode}>
                                                    {ld?.isoLongCode}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                                <div className="row generalTabInfo">
                                    <div className="col-sm-5">
                                        <span className="storeNumHeading lbllanguage">
                                            Time Zone
                                        </span>
                                    </div>
                                    <div className="col-sm-7">
                                        <select
                                            className="form-control border-none storeNumber ddlFocus boxShadow-None"
                                            value={info.timezone}
                                            onChange={(e) =>
                                                handleUpdateTimezone(e)
                                            }
                                        >
                                            {/* <option value={info.timezone}>{info.timezone}</option> */}
                                            {timezoneData?.map((tz) => (
                                                <option value={tz.timezoneName}>
                                                    {tz.timezoneName}
                                                    {tz.utc}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                                <div className="row generalTabInfo">
                                    <div className="col-sm-5">
                                        <span className="storeNumHeading lblCurrency">
                                            Currency Code
                                        </span>
                                    </div>
                                    <div className="col-sm-7">
                                        {/* <DropDownListComponent id="ddlelement" placeholder={info.currencyCode}  dataSource = {info.currencyCode} /> */}
                                        <select
                                            className="form-control border-none storeNumber ddlFocus boxShadow-None"
                                            value={info.currencyCode}
                                            onChange={(e) =>
                                                handleUpdateCurrencycode(e)
                                            }
                                        >
                                            {/* <option value={info.currencyCode}>{info.currencyCode}</option> */}
                                            {currencyData?.map((cc) => (
                                                <option value={cc.currency}>
                                                    {cc.currency}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                                <hr />
                                {setTabHeading.payload === ContactType.STORES ||
                                setTabHeading.payload ===
                                    ContactType.VENDORS ? (
                                    <>
                                        <div className="col-sm-12 pl-0 pr-0">
                                            <div className="row addHeadingInContactTab ml-0 mr-0">
                                                <div className="col-sm-10 p-0">
                                                    <span>Address</span>
                                                </div>
                                            </div>
                                        </div>

                                        {info?.address?.map((addr: any) => (
                                            <>
                                                <div className="row">
                                                    <div className="col-sm-4 pl-3rem pt-1">
                                                        <span className="storeNumHeading lbllanguage">
                                                            Street
                                                        </span>
                                                    </div>
                                                    <div className="col-sm-7 pl-3rem pt-1">
                                                        <span className="storeNumber">
                                                            {addr.addressLine1}
                                                        </span>
                                                    </div>
                                                </div>
                                                <div className="row mb-1rem pt-1">
                                                    <div className="col-sm-4 pl-3rem">
                                                        <span className="storeNumHeading">
                                                            City
                                                        </span>
                                                    </div>
                                                    <div className="col-sm-7 pl-3rem">
                                                        <span className="storeNumber">
                                                            {addr.city}
                                                        </span>
                                                    </div>
                                                </div>
                                                <div className="row mb-1rem pt-1">
                                                    <div className="col-sm-4 pl-3rem">
                                                        <span className="storeNumHeading">
                                                            State
                                                        </span>
                                                    </div>
                                                    <div className="col-sm-7 pl-3rem">
                                                        <span className="storeNumber">
                                                            {addr.state}
                                                        </span>
                                                        {/* <select className ="form-control border-none storeNumber ddlFocus boxShadow-None">
                   <option value={addr.state}>{addr.state}</option>
                   {statesInfo?.map((cn) =>
                  <option value={cn.stateName}>{cn.stateName}</option>
                    )}
                    </select> */}
                                                    </div>
                                                </div>
                                                <div className="row mb-1rem pt-1">
                                                    <div className="col-sm-4 pl-3rem">
                                                        <span className="storeNumHeading">
                                                            Zipcode
                                                        </span>
                                                    </div>
                                                    <div className="col-sm-7 pl-3rem">
                                                        <span className="storeNumber">
                                                            {addr.zipcode}
                                                        </span>
                                                    </div>
                                                </div>
                                                <div className="row mb-1rem pt-1">
                                                    <div className="col-sm-4 pl-3rem">
                                                        <span className="storeNumHeading">
                                                            Country
                                                        </span>
                                                    </div>
                                                    <div className="col-sm-7 pl-3rem">
                                                        <span className="storeNumber">
                                                            {addr.country}
                                                        </span>
                                                    </div>
                                                </div>
                                            </>
                                        ))}
                                    </>
                                ) : (
                                    <div className="row generalTabWareHouseInfo">
                                        <div className="row generalTabWareHouseInfo">
                                            <div className="col-sm-1"></div>
                                            <div className="col-sm-7 pl-0">
                                                <h5 className="linkedStoreHeading">
                                                    Address List
                                                </h5>
                                            </div>
                                            {setTabHeading.payload ===
                                                ContactType.CUSTOMERS ||
                                            setTabHeading.payload ===
                                                ContactType.CARRIERS ? (
                                                <div className="col-sm-2">
                                                    {info.address.length >=
                                                    3 ? (
                                                        ''
                                                    ) : (
                                                        <span
                                                            className="plusIcon iconsForLinking cursor-pointer"
                                                            onClick={
                                                                handleShowNewAddress
                                                            }
                                                        >
                                                            <img
                                                                src={plusIcon}
                                                            />
                                                        </span>
                                                    )}
                                                </div>
                                            ) : setTabHeading.payload !=
                                                  ContactType.CUSTOMERS ||
                                              setTabHeading.payload !=
                                                  ContactType.CARRIERS ? (
                                                <div className="col-sm-2">
                                                    {info?.address?.length ===
                                                    0 ? (
                                                        <span
                                                            className="plusIcon iconsForLinking cursor-pointer"
                                                            onClick={
                                                                handleShowNewAddress
                                                            }
                                                        >
                                                            <img
                                                                src={plusIcon}
                                                            />
                                                        </span>
                                                    ) : (
                                                        ''
                                                    )}
                                                </div>
                                            ) : (
                                                ''
                                            )}
                                        </div>

                                        {info?.address?.map(
                                            (addr: any, i: any) => (
                                                <>
                                                    <div className="col-sm-12">
                                                        {addr.alternateAddress ? (
                                                            <div className="row addHeadingInContactTab ml-0 mr-0">
                                                                <div className="col-sm-10 p-2">
                                                                    <span>
                                                                        Alt
                                                                        Address
                                                                    </span>
                                                                </div>
                                                                {setTabHeading.payload ===
                                                                    ContactType.CARRIERS ||
                                                                setTabHeading.payload ===
                                                                    ContactType.CUSTOMERS ? (
                                                                    <div
                                                                        className="col-sm-2"
                                                                        style={{
                                                                            padding:
                                                                                '2px 0',
                                                                        }}
                                                                    >
                                                                        <span
                                                                            className="linkedStoreData closeIconLinkedActions cursor-pointer"
                                                                            style={{
                                                                                float: 'right',
                                                                            }}
                                                                        >
                                                                            <img
                                                                                src={
                                                                                    closeModuleIcon
                                                                                }
                                                                                alt="Close"
                                                                                className="float-right addCloseIcon"
                                                                                onClick={() =>
                                                                                    handleRemoveAddressList(
                                                                                        addr.id
                                                                                    )
                                                                                }
                                                                            />
                                                                        </span>
                                                                    </div>
                                                                ) : (
                                                                    ''
                                                                )}
                                                            </div>
                                                        ) : (
                                                            <div className="row addHeadingInContactTab ml-0 mr-0">
                                                                <div className="col-sm-10 p-2">
                                                                    <span>
                                                                        Address
                                                                    </span>
                                                                </div>
                                                                {setTabHeading.payload ===
                                                                    ContactType.CARRIERS ||
                                                                setTabHeading.payload ===
                                                                    ContactType.CUSTOMERS ? (
                                                                    <div
                                                                        className="col-sm-2"
                                                                        style={{
                                                                            padding:
                                                                                '2px 0',
                                                                        }}
                                                                    >
                                                                     {addr.alternateAddress ?
                                                                     <span
                                                                            className="linkedStoreData closeIconLinkedActions cursor-pointer"
                                                                            style={{
                                                                                float: 'right',
                                                                            }}
                                                                        >
                                                                            <img
                                                                                src={
                                                                                    closeModuleIcon
                                                                                }
                                                                                alt="Close"
                                                                                className="float-right addCloseIcon"
                                                                                onClick={() =>
                                                                                    handleRemoveAddressList(
                                                                                        addr.id
                                                                                    )
                                                                                }
                                                                            />
                                                                        </span>
                                                                        :'' }
                                                                    </div>
                                                                ) : (
                                                                    ''
                                                                )}
                                                            </div>
                                                        )}
                                                    </div>
                                                    <div className="row col-sm-12 p-0 mt-1">
                                                        <div className="col-sm-4 pl-3rem mt-1rem">
                                                            <span className="storeNumHeading lbladdress">
                                                                Street
                                                            </span>
                                                        </div>
                                                        <div className="col-sm-8 pl-3rem mt-1rem">
                                                            {/* <input type="text" value={addr.street}/> */}
                                                            {/* <InlineInputEdit inputClassName="txtEditClassNameInput"
                          text={addr.street}
                          labelClassName="txtEditClassName"
                          labelPlaceHolder='- - -'
                          onFocus={(text: any)=>_handleFocusStreet(addr.id, text)}
                          onFocusOut={(text: any)=>_handleFocusOutStreet(addr.id, text)} /> */}
                                                            <input
                                                                type="text"
                                                                className="form-control border-none cursor-pointer txtEditFieldFocus boxShadow-None"
                                                                onChange={(
                                                                    e: any
                                                                ) =>
                                                                    _handleFocusOutStreet(
                                                                        addr.id,
                                                                        e
                                                                    )
                                                                }
                                                                defaultValue={
                                                                    addr.street
                                                                }
                                                                onBlur={(
                                                                    e: any
                                                                ) =>
                                                                    _handleFocusOutStreet(
                                                                        addr.id,
                                                                        e
                                                                    )
                                                                }
                                                                onKeyUp={(
                                                                    e: any
                                                                ) =>
                                                                    _handleFocusOutStreet(
                                                                        addr.id,
                                                                        e
                                                                    )
                                                                }
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="row col-sm-12 p-0 mt-3">
                                                        <div className="col-sm-4 pl-3rem">
                                                            <span className="storeNumHeading lbladdress">
                                                                City
                                                            </span>
                                                        </div>
                                                        <div className="col-sm-8 pl-3rem">
                                                            {/* <span className="storeNumber">{addr.city}</span> */}
                                                            {/* <InlineInputEdit inputClassName="txtEditClassNameInput"
                      text={addr.city}
                      labelClassName="txtEditClassName"
                      labelPlaceHolder='- - -'
                      onFocus={(text: any)=>_handleFocusCity(addr.id, text)}
                      onFocusOut={(text: any)=>_handleFocusOutCity(addr.id, text)} /> */}
                                                            <input
                                                                type="text"
                                                                className="form-control border-none cursor-pointer txtEditFieldFocus boxShadow-None"
                                                                onChange={(
                                                                    e: any
                                                                ) =>
                                                                    _handleFocusOutCity(
                                                                        addr.id,
                                                                        e
                                                                    )
                                                                }
                                                                defaultValue={
                                                                    addr.city
                                                                }
                                                                onBlur={(
                                                                    e: any
                                                                ) =>
                                                                    _handleFocusOutCity(
                                                                        addr.id,
                                                                        e
                                                                    )
                                                                }
                                                                onKeyUp={(
                                                                    e: any
                                                                ) =>
                                                                    _handleFocusOutCity(
                                                                        addr.id,
                                                                        e
                                                                    )
                                                                }
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="row col-sm-12 p-0 mt-3">
                                                        <div className="col-sm-4 pl-3rem">
                                                            <span className="storeNumHeading lbladdress">
                                                                Country
                                                            </span>
                                                        </div>
                                                        <div className="col-sm-8 pl-3rem">
                                                            {/* <span className="storeNumber">{addr.country}</span> */}
                                                            <select
                                                                className="form-control border-none storeNumber ddlFocus boxShadow-None"
                                                                id="ddlCountry"
                                                                defaultValue={
                                                                    addr?.country
                                                                }
                                                                onChange={(
                                                                    e: any
                                                                ) =>
                                                                    handleChangeCountry(
                                                                        addr.id,
                                                                        e,
                                                                        i,
                                                                        'country'
                                                                    )
                                                                }
                                                            >
                                                                <option
                                                                    value={
                                                                        addr.country
                                                                            ? addr.country
                                                                            : 'Select'
                                                                    }
                                                                >
                                                                    {addr.country
                                                                        ? addr.country
                                                                        : 'Select'}
                                                                </option>
                                                                {/* JSON.stringify(cn)} cn.countryName + '+' + cn.isoCountryCode*/}
                                                                <option value="Select">
                                                                    Select
                                                                </option>
                                                                {countryInfo?.map(
                                                                    (cn) => (
                                                                        <option
                                                                            value={JSON.stringify(
                                                                                cn
                                                                            )}
                                                                        >
                                                                            {
                                                                                cn.countryName
                                                                            }
                                                                        </option>
                                                                    )
                                                                )}
                                                                {/* {countryInfo?.map((cn) =>
                          <option value={JSON.stringify(cn)}>{cn.countryName}</option>
                        )} */}
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="row col-sm-12 p-0 mt-3">
                                                        <div className="col-sm-4 pl-3rem">
                                                            <span className="storeNumHeading lbladdress">
                                                                State
                                                            </span>
                                                        </div>
                                                        <div className="col-sm-8 pl-3rem">
                                                            {/* <span className="storeNumber">{addr.state}</span> */}
                                                            <select
                                                                className="form-control border-none storeNumber ddlFocus boxShadow-None"
                                                                defaultValue={
                                                                    addr.state
                                                                }
                                                                onChange={(
                                                                    e: any
                                                                ) =>
                                                                    _handleFocusOutState(
                                                                        addr.id,
                                                                        e,
                                                                        i,
                                                                        'state'
                                                                    )
                                                                }
                                                            >
                                                                {/* {!removedState ?
                          <option value={addr.state}>{addr.state}</option>
                          :''} */}{' '}
                                                                <option
                                                                    value={
                                                                        addr.state
                                                                            ? addr.state
                                                                            : 'Select'
                                                                    }
                                                                >
                                                                    {addr.state
                                                                        ? addr.state
                                                                        : 'Select'}
                                                                </option>
                                                                {/* <option value="Select">
                                                                    Select
                                                                </option> */}
                                                                {statesInfo?.map(
                                                                    (cn) => (
                                                                        <option
                                                                            value={
                                                                                cn.stateName
                                                                            }
                                                                        >
                                                                            {
                                                                                cn.stateName
                                                                            }
                                                                        </option>
                                                                    )
                                                                )}
                                                            </select>
                                                            {/* <InlineInputEdit inputClassName="txtEditClassNameInput"
                      text={addr.state}
                      labelClassName="txtEditClassName"
                      labelPlaceHolder='- - -'
                      onFocus={(text: any)=>_handleFocusState(addr.id, text)}
                      onFocusOut={(text: any)=>_handleFocusOutState(addr.id, text)} /> */}
                                                        </div>
                                                    </div>
                                                    <div className="row col-sm-12 p-0 mt-3">
                                                        <div className="col-sm-4 pl-3rem">
                                                            <span className="storeNumHeading lbladdress">
                                                                Zipcode
                                                            </span>
                                                        </div>
                                                        <div className="col-sm-8 pl-3rem">
                                                            {/* <span className="storeNumber">{addr.zipcode}</span> */}
                                                            {/* <InlineInputEdit inputClassName="txtEditClassNameInput"
                          text={addr.zipcode}
                          labelClassName="txtEditClassName"
                          labelPlaceHolder='- - -'
                          onFocus={(text: any)=>_handleFocusZipCode(addr.id, text)}
                          onFocusOut={(text: any)=>_handleFocusOutZipCode(addr.id, text)} /> */}
                                                            <input
                                                                type="number"
                                                                className="form-control border-none cursor-pointer txtEditFieldFocus boxShadow-None"
                                                                maxLength={5}
                                                                defaultValue={
                                                                    addr.zipcode
                                                                }
                                                                name="zipcode"
                                                                onBlur={(
                                                                    e: any
                                                                ) =>
                                                                    _handleFocusOutZipCode(
                                                                        addr.id,
                                                                        e,
                                                                        i
                                                                    )
                                                                }
                                                                onKeyUp={(
                                                                    e: any
                                                                ) =>
                                                                    _handleFocusOutZipCode(
                                                                        addr.id,
                                                                        e,
                                                                        i
                                                                    )
                                                                }
                                                            />
                                                            {zipError === '' ? (
                                                                <span className="text-danger">
                                                                    {
                                                                        errors.zipError
                                                                    }
                                                                </span>
                                                            ) : (
                                                                ''
                                                            )}
                                                        </div>
                                                    </div>
                                                    {setTabHeading.payload ===
                                                    ContactType.CUSTOMERS ? (
                                                        <div className="row col-sm-12">
                                                            <div
                                                                className="col-sm-1"
                                                                style={{
                                                                    marginTop:
                                                                        '5px',
                                                                }}
                                                            >
                                                                <input
                                                                    type="checkbox"
                                                                    id={
                                                                        'billingAddress' +
                                                                        i
                                                                    }
                                                                    defaultChecked={
                                                                        addr.billingAddress
                                                                            ? addr.billingAddress
                                                                            : false
                                                                    }
                                                                    onClick={(
                                                                        e: any
                                                                    ) =>
                                                                        _handleDefaultBillingAddress(
                                                                            e,
                                                                            i,
                                                                            'billingAddress'
                                                                        )
                                                                    }
                                                                    name={
                                                                        'billingAddress' +
                                                                        i
                                                                    }
                                                                />
                                                            </div>
                                                            <div className="col-sm-10 pl-2">
                                                                <label
                                                                    className="chkbox"
                                                                    htmlFor={
                                                                        'billingAddress' +
                                                                        i
                                                                    }
                                                                >
                                                                    <span className="storeNumHeading lbladdress">
                                                                        Default
                                                                        Billing
                                                                        Address
                                                                    </span>
                                                                </label>
                                                            </div>
                                                        </div>
                                                    ) : (
                                                        ''
                                                    )}
                                                </>
                                            )
                                        )}
                                    </div>
                                )}

                                {/* :''} */}
                                {/* <hr/>
                            <div className="row generalTabInfo">
                            <div className="col-sm-6">
                                <span className="storeNumHeading">Address List</span>
                            </div>
                            </div> */}
                                {setTabHeading.payload ===
                                ContactType.STORES ? (
                                    <>
                                        <div className="horizontalLineBarReportingMail" />
                                        <div className="row generalTabWareHouseInfo">
                                            <div className="col-sm-10">
                                                <h5 className="linkedStoreHeading fontSize-14">
                                                    Reporting Email Accounts
                                                </h5>
                                            </div>
                                            <div className="col-sm-2 pl-0">
                                                <span className="plusIcon cursor-pointer altEmailAddImgStore">
                                                    <img
                                                        src={plusIcon}
                                                        onClick={
                                                            handleStoreAltEmailAddClick
                                                        }
                                                    />
                                                </span>
                                            </div>
                                        </div>
                                        {info.alternateEmailList.map(
                                            (ae: any) => (
                                                <div>
                                                    <div className="row generalTabInfo reportHeading ml-0 mr-0">
                                                        <div className="col-sm-10 reportHeadingText">
                                                            <span className="">
                                                                Reporting Email
                                                            </span>
                                                        </div>
                                                        <div className="col-sm-2">
                                                            <img
                                                                src={closeIcon}
                                                                alt="close"
                                                                onClick={() =>
                                                                    handleAltEmailRemoveExisting(
                                                                        ae
                                                                    )
                                                                }
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="row generalTabInfo">
                                                        <div className="col-sm-12">
                                                            <span>{ae}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        )}
                                        {altEmailStore.map((x, i) => {
                                            return (
                                                <div className="row generalTabInfo">
                                                    <div className="col-sm-4">
                                                        <span
                                                            className="storeNumHeading newAltEmailLabel"
                                                            style={{
                                                                whiteSpace:
                                                                    'inherit',
                                                            }}
                                                        >
                                                            Reporting Email
                                                        </span>
                                                    </div>
                                                    <div className="col-sm-7 pr-0">
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            name="altEmail"
                                                            onChange={(e) =>
                                                                handleAltEmailInputChange(
                                                                    e,
                                                                    i
                                                                )
                                                            }
                                                        />
                                                    </div>
                                                    <div className="col-sm-1 pl-0">
                                                        <img
                                                            src={closeIcon}
                                                            alt="close "
                                                            onClick={() =>
                                                                handleStoreAltEmailRemoveClick(
                                                                    i
                                                                )
                                                            }
                                                        />
                                                    </div>
                                                </div>
                                            );
                                        })}
                                    </>
                                ) : (
                                    ''
                                )}
                                {setTabHeading.payload ===
                                ContactType.CUSTOMERS ? (
                                    <>
                                        <div className="horizontalLineBarCustomer" />
                                        <div className="row generalTabWareHouseInfo">
                                            <div className="col-sm-11">
                                                <h5 className="linkedStoreHeading fontSize-14">
                                                    Associated Email Accounts
                                                </h5>
                                            </div>
                                            <div className="col-sm-1 pl-0">
                                                <span className="plusIcon cursor-pointer">
                                                    <img
                                                        src={plusIcon}
                                                        onClick={
                                                            handleCustomerAltEmailAddClick
                                                        }
                                                    />
                                                </span>
                                            </div>
                                        </div>
                                        {info.alternateEmailList.map(
                                            (ae: any) => (
                                                <div className="row generalTabInfo">
                                                    <div className="col-sm-3">
                                                        <span className="">
                                                            Alt Email
                                                        </span>
                                                    </div>
                                                    <div className="col-sm-8">
                                                        <span>{ae}</span>
                                                    </div>
                                                    <div className="col-sm-1 pl-0">
                                                        <img
                                                            src={closeIcon}
                                                            alt="close"
                                                            onClick={() =>
                                                                handleAltEmailRemoveExistingCustomer(
                                                                    ae
                                                                )
                                                            }
                                                        />
                                                    </div>
                                                </div>
                                            )
                                        )}
                                        {altEmailCustomer.map((x, i) => {
                                            return (
                                                <div className="row generalTabInfo">
                                                    <div className="col-sm-4">
                                                        <span className="storeNumHeading">
                                                            Alt Email
                                                        </span>
                                                    </div>
                                                    <div className="col-sm-7">
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            name="altEmail"
                                                            onChange={(e) =>
                                                                handleAltEmailInputChangeCustomer(
                                                                    e,
                                                                    i
                                                                )
                                                            }
                                                        />
                                                    </div>
                                                    <div className="col-sm-1 pl-0">
                                                        <img
                                                            src={closeIcon}
                                                            alt="close "
                                                            onClick={() =>
                                                                handleCustomerAltEmailRemoveClick(
                                                                    i
                                                                )
                                                            }
                                                        />
                                                    </div>
                                                </div>
                                            );
                                        })}
                                    </>
                                ) : (
                                    ''
                                )}
                            </>
                        ))}
                    </div>
                </>
            ) : (
                <div></div>
            )}
            <div className="col-sm-12 pt-1">
                {showUpdateCancelIconsOnPreview}
                <div className="col-sm-6 pl-1">
                    {showUpdateCancelIcons ? (
                        <>
                            <img
                                className="updateIconImg "
                                onClick={handleUpdate}
                                src={updateIcon}
                            />
                            <img
                                className="updateIconImg "
                                src={cancelIcon}
                                onClick={handleCancel}
                            />
                        </>
                    ) : (
                        ''
                    )}
                </div>

                {rowInfo &&
                    rowInfo?.map((info) => (
                        <div className="col-sm-6 pl-0">
                            {/* <SwitchComponent
              checked={info.active}
              onChange={(e: any) => handleSwitch(e)}
            />
            <span className="switchText switchTextTabs">
              {info.active ? "Active" : "Inactive"}
            </span> */}
                            <input
                                type="checkbox"
                                defaultChecked={info.active}
                                id="ContactTabActive"
                                className="toggle"
                                onChange={(e: any) => handleSwitch(e)}
                            />
                            <label
                                className="chkbx"
                                htmlFor="ContactTabActive"
                                style={{ marginLeft: '45px' }}
                            >
                                <span className="ActiveInactiveFont">
                                    {info.active ? 'Active' : 'Inactive'}
                                </span>
                            </label>
                        </div>
                    ))}
            </div>
        </div>
    );
};

export default WarehouseContactView;
