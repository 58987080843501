import * as React from 'react';
import { useEffect, useState } from 'react';
import Grid from '../grid/Grid';

import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { ButtonComponent } from '@syncfusion/ej2-react-buttons';
import { config } from '../grid/config';
import { ContactType } from './constants';
import {
    generateGridData,
    getAllMasterData,
    getEndPoint,
    getRuleExceptionsByUPCId,
    getTabData,
} from './utils';
import ContactsDetailspanel from '../ContactsDetailsPanel/ContactsDetailsPanel';
import { ReactComponent as Download } from '../../icons/download.svg';
import { ReactComponent as Add } from '../../icons/AddNew.svg';
import { ReactComponent as Print } from '../../icons/printNew.svg';
import loader from '../../assets/loader.gif';
import {
    setBillingModeData,
    setClientGroupData,
    setClientsData,
    setCompanyCodes,
    setCountryData,
    setCountryPhoneData,
    setCurrencyData,
    setCurrentContact,
    setCustomers,
    setCustomerTypeData,
    setDebitReasonData,
    setDepartmentMasterData,
    setDispositionData,
    setFiscalYearsData,
    setFiscalYearTemplateData,
    setGLAccountMasterData,
    setLaungageData,
    setMasterDataAddImage,
    setPalletNameMasterData,
    setPricingTypeMasterData,
    setReasonCodeData,
    setReclaimCenters,
    setScanGroupMasterData,
    setSelectedRecrdsExport,
    setStatesData,
    setStoreDispositionList,
    setStores,
    setStoresConfig,
    setStoreTypeData,
    setSuperGroupData,
    setTabHeading,
    setTabNameForRowData,
    setTimezoneData,
    setUpcList,
    setUpdatedStoresORVendorData,
    setVendors,
    setWHLocationsData,
    setWHLocCustomData,
    setWHLocUPCDefaultData,
} from './contactReducer';
import './ContactScreenWithTabs.scss';
import { RootState } from '../../redux/store';
import { setRowData } from '../grid/gridReducer';
import AddMastersScreen from './AddMastersScreen';
import ItemCatalogViewScreen from './ItemCatalogViewScreen';
import PalletLocationViewScreen from './PalletLocationViewScreen';
import RuleExceptionViewScreen from './RuleExceptionViewScreen';
import FiscalYearViewScreen from './FiscalYearViewScreen';
import InventoryCatalogViewScreen from './InventoryCatalogViewScreen';
import WarehouseLocationsScreen from './WarehouseLocationsScreen';
import ExcelService from '../../services/excel';
import BinExceptionAddandViewScreen from './BinExceptionAddandViewScreen';
import ItemsCatalogGrid from '../grid/items-catalog-grid/items-catalog-grid';
import StoresGrid from '../grid/mdm-parteners-grid/stores-grid';
import VendorsGrid from '../grid/mdm-parteners-grid/vendors-grid';
import printerIcon from '../../assets/pallet-printer.png';
import { InventoryCatalogUtil } from './InventoryCatalogUtil';
import { casualDataAddPopupEndPoints } from '../settings/utils';
import RuleExceptionGrid from '../grid/rule-exceptions-grid/rule-exception-grid';

const ContactScreenWithTabs = (props: any) => {
    const params = new URL(window.location.href).searchParams;
    const tabNameForRoute: any = params.get('tab');
    // const [selectedTab, setSelectedTab] = useState(props.location && props.location.state && props.location.state.value &&
    //     props.location.state.value == "OrgStructureDefaultTab" ? ContactType.COMPANY_CODES
    //   : props.location.state.value == "PartnersDefaultTab" ? ContactType.STORES
    //   : props.location.state.value == "FinanceDefaultTab" ? ContactType.DEPARTMENTS
    //   : props.location.state.value == "ItemsDefaultTab" ? ContactType.ITEM_CATALOG
    //   : props.location.state.value == "InventoryDefaultTab" ? ContactType.INVENTORY_CATALOG
    //   : ContactType.STORES);
    const [selectedTab, setSelectedTab] = useState(
        props?.location?.state?.value.includes('OrgStructureDefaultTab')
            ? ContactType.COMPANY_CODES
            : props?.location?.state?.value.includes('PartnersDefaultTab')
            ? ContactType.STORES
            : props?.location?.state?.value.includes('FinanceDefaultTab')
            ? ContactType.DEPARTMENTS
            : props?.location?.state?.value.includes('ItemsDefaultTab')
            ? ContactType.ITEM_CATALOG
            : props?.location?.state?.value.includes('InventoryDefaultTab')
            ? ContactType.INVENTORY_CATALOG
            : tabNameForRoute
    );
    //const [selectedTab, setSelectedTab] = useState(ContactType.STORES);
    const [tabName, setTabName] = useState('');
    const [
        buttonFlagForRuleExceptionSingleView,
        setButtonFlagForRuleExceptionSingleView,
    ] = useState(false);
    const [masterDataType, setMasterDataType] = useState('');
    const [visibility, setDialogVisibility] = useState(false);
    const [addScreen, setAddScreen] = useState(false);
    const [addScreenTabName, setAddScreenTabName] = useState(null);
    const [showGrid, setShowGrid] = useState(true);
    const [isFromItemCatalog, setIsFromItemCatalog] = useState(false);
    const [upcId, setUpcId] = useState('');
    const [storeRows, setstoreRows] = useState<any[]>([]);
    const [vendorRows, setVendorRows] = useState<any[]>([]);
    const [storePaging, setStorePaging] = useState({
        pageNO: 0,
        totalRecords: 0,
        totalPages: 0,
    });
    const [vendorsPaging, setVendorsPaging] = useState({
        pageNO: 0,
        totalRecords: 0,
        totalPages: 0,
    });
    const dispatch = useDispatch();
    let stores: any;
    let vendors: any;
    const currentContact = useSelector(
        (state: RootState) => state.contactReducer?.currentContact
    );
    const FiscYearData = useSelector(
        (state: RootState) => state.contactReducer?.fiscalYears
    );
    const selectedRowData = useSelector(
        (state: RootState) => state.gridReducer?.selectedRowData
    );
    const palletName = useSelector(
        (state: RootState) => state.contactReducer?.palletNameForViewScreen
    );
    const InventoryName = useSelector(
        (state: RootState) => state.contactReducer?.inentoryNameForMasterData
    );
    const descriptionForItem = useSelector(
        (state: RootState) =>
            state.contactReducer?.descriptionForItemCatalogView
    );
    const RCNameBinExcep = useSelector(
        (state: RootState) => state.contactReducer?.RCNameForBinExceptionView
    );
    const exportRecords = useSelector(
        (state: RootState) => state.contactReducer?.selectedRecordsForExport
    );
    const RECodeRuleExcep = useSelector(
        (state: RootState) => state.contactReducer?.RECodeForRuleExceptionView
    );
    let [datee, setDate] = useState('');
    const gridRef = useSelector(
        (state: RootState) => state.gridReducer?.gridConfig
    );

    const StoreData = useSelector(
        (state: RootState) => state?.contactReducer?.updateStoreOrVendorData
    );
    const [loaderStatus, setLoaderStatus] = useState(true);

    console.log(gridRef);
    useEffect(() => {
        setLoaderStatus(true);
        //  console.log(gridRef)
        const date = new Date();

        const formattedDate = date.toLocaleString('en-GB', {
            day: 'numeric',
            month: 'short',
            year: 'numeric',
        });
        setDate(formattedDate);

        console.log(formattedDate);
        if (!buttonFlagForRuleExceptionSingleView) {
            setMasterDataType(
                window.location.href.substring(
                    window.location.href.lastIndexOf('/') + 1
                )
            );
            let endpoint;
            if (
                selectedTab === ContactType.STORES ||
                selectedTab === ContactType.VENDORS
            ) {
                endpoint = getEndPoint(selectedTab, 1, 1);
            } else {
                endpoint = getEndPoint(selectedTab);
            }
            if (endpoint) {
                handleGridDataChange(endpoint, selectedTab, '');
            }

            props.history.push({
                search: `?tab=${tab}`,
            });
            setTabName(selectedTab.slice(0, -1));
            //  if (selectedTab != ContactType.WAREHOUSES) {
            //   setTabName(selectedTab.slice(0, -1));
            // } else {
            //   setTabName(selectedTab);
            // }
            dispatch(setTabHeading(selectedTab));
        }
        if (StoreData && StoreData?.length > 0) {
            console.log('useEffect');
            console.log(StoreData);
        }
    }, [StoreData, selectedTab, buttonFlagForRuleExceptionSingleView]);

    useEffect(() => {
        getActiveTabonRefresh();
    }, [params, currentContact]);

    /** For dynamic active tab on page refresh
     * Getting the List of Classes by getElementsByClassName('textTransform-initial'),
     * And match with the tab url.toLowerCase() with newly added class names for the buttons,
     * Apply the activeTab class to it.
     *  **/
    const getActiveTabonRefresh = () => {
        let btnSet;
        const elements = document.getElementsByClassName('activeTab');
        while (elements.length > 0) elements[0].classList.remove('activeTab');
        btnSet = document.getElementsByClassName('textTransform-initial');
        for (let i = 0; i < btnSet.length; i++) {
            for (let j = 0; j < btnSet[i].classList.length; j++) {
                const replacedText = btnSet[i].classList[j].replace(/-/g, ' ');
                if (replacedText === tabNameForRoute.toLowerCase()) {
                    btnSet[i].classList.add('activeTab');
                }
            }
        }
    };
    /** For dynamic active tab on page refresh*/

    const handleGetMastersForStores = async () => {
        const lanEndpoint = getAllMasterData('Language');
        const timeZonendpoint = getAllMasterData('TimeZone');
        const cuurencyCodeEndpoint = getAllMasterData('CurrencyCode');
        const countryEndpoint = getAllMasterData('Country');
        const lanResult = await axios.get(lanEndpoint).catch((err) => err);
        const timeZoneResult = await axios
            .get(timeZonendpoint)
            .catch((err) => err);
        const cureencyCoderesult = await axios
            .get(cuurencyCodeEndpoint)
            .catch((err) => err);
        const countryresult = await axios
            .get(countryEndpoint)
            .catch((err) => err);
        dispatch(setCurrencyData(cureencyCoderesult.data));
        dispatch(setTimezoneData(timeZoneResult.data));
        dispatch(setLaungageData(lanResult.data));
        dispatch(setCountryData(countryresult.data));
        const storeTypeEndPoint = getAllMasterData('StoreType');
        const superGroupEndPoint = getAllMasterData('SuperGroup');
        const clientGroupEndPoint = getAllMasterData('ClientGroup');
        const reasonCodeEndPoint = getAllMasterData('ReasonCode');
        const storeTyperesult = await axios
            .get(storeTypeEndPoint)
            .catch((err) => err);
        const superGroupresult = await axios
            .get(superGroupEndPoint)
            .catch((err) => err);
        const clientGroupresult = await axios
            .get(clientGroupEndPoint)
            .catch((err) => err);
        const reasonCoderesult = await axios
            .get(reasonCodeEndPoint)
            .catch((err) => err);
        dispatch(setStoreTypeData(storeTyperesult.data));
        dispatch(setSuperGroupData(superGroupresult.data));
        dispatch(setClientGroupData(clientGroupresult.data));
        dispatch(setReasonCodeData(reasonCoderesult.data));
        const countryCodeEndpoint = getAllMasterData('CountryCodePhone');
        const countryCoderesult = await axios
            .get(countryCodeEndpoint)
            .catch((err) => err);
        dispatch(setCountryPhoneData(countryCoderesult.data));
    };
    const handleGetMastersForCustomers = async () => {
        const lanEndpoint = getAllMasterData('Language');
        const timeZonendpoint = getAllMasterData('TimeZone');
        const cuurencyCodeEndpoint = getAllMasterData('CurrencyCode');
        const countryEndpoint = getAllMasterData('Country');
        const billingModeEndpoint = getAllMasterData('BillingMode');
        const countryCodeEndpoint = getAllMasterData('CountryCodePhone');
        const lanResult = await axios.get(lanEndpoint).catch((err) => err);
        const timeZoneResult = await axios
            .get(timeZonendpoint)
            .catch((err) => err);
        const cureencyCoderesult = await axios
            .get(cuurencyCodeEndpoint)
            .catch((err) => err);
        const billingModeresult = await axios
            .get(billingModeEndpoint)
            .catch((err) => err);
        const countryresult = await axios
            .get(countryEndpoint)
            .catch((err) => err);
        const countryCoderesult = await axios
            .get(countryCodeEndpoint)
            .catch((err) => err);
        dispatch(setCurrencyData(cureencyCoderesult.data));
        dispatch(setTimezoneData(timeZoneResult.data));
        dispatch(setLaungageData(lanResult.data));
        dispatch(setCountryData(countryresult.data));
        dispatch(setBillingModeData(billingModeresult.data));
        dispatch(setCountryPhoneData(countryCoderesult.data));
        const customerTypeEndPoint = getAllMasterData('CustomerType');
        const customerTyperesult = await axios
            .get(customerTypeEndPoint)
            .catch((err) => err);
        dispatch(setCustomerTypeData(customerTyperesult.data));
        const statesEndpoint = getAllMasterData('StateList');
        const statesResult = await axios
            .get(statesEndpoint)
            .catch((err) => err);
        dispatch(setStatesData([]));
    };
    const handleGetMastersForReclaimCenters = async () => {
        const lanEndpoint = getAllMasterData('Language');
        const timeZonendpoint = getAllMasterData('TimeZone');
        const cuurencyCodeEndpoint = getAllMasterData('CurrencyCode');
        const countryEndpoint = getAllMasterData('Country');
        const statesEndpoint = getAllMasterData('StateList');
        const countryCodeEndpoint = getAllMasterData('CountryCodePhone');
        const lanResult = await axios.get(lanEndpoint).catch((err) => err);
        const timeZoneResult = await axios
            .get(timeZonendpoint)
            .catch((err) => err);
        const cureencyCoderesult = await axios
            .get(cuurencyCodeEndpoint)
            .catch((err) => err);
        const countryresult = await axios
            .get(countryEndpoint)
            .catch((err) => err);
        const countryCoderesult = await axios
            .get(countryCodeEndpoint)
            .catch((err) => err);
        const statesResult = await axios
            .get(statesEndpoint)
            .catch((err) => err);
        dispatch(setCurrencyData(cureencyCoderesult.data));
        dispatch(setTimezoneData(timeZoneResult.data));
        dispatch(setLaungageData(lanResult.data));
        dispatch(setCountryData(countryresult.data));
        dispatch(setCountryPhoneData(countryCoderesult.data));
        dispatch(setStatesData([]));
        const companyCodeEndPoint = getEndPoint(ContactType.COMPANY_CODES);
        const companyCodeResult = await axios.get(companyCodeEndPoint);
        dispatch(setCompanyCodes(companyCodeResult.data));
    };
    const handleGetMastersForComapnyCodes = async () => {
        const lanEndpoint = getAllMasterData('Language');
        const timeZonendpoint = getAllMasterData('TimeZone');
        const cuurencyCodeEndpoint = getAllMasterData('CurrencyCode');
        const clientListEndPoint = getAllMasterData('Client');
        const countryEndpoint = getAllMasterData('Country');
        const countryCodeEndpoint = getAllMasterData('CountryCodePhone');
        const lanResult = await axios.get(lanEndpoint).catch((err) => err);
        const timeZoneResult = await axios
            .get(timeZonendpoint)
            .catch((err) => err);
        const cureencyCoderesult = await axios
            .get(cuurencyCodeEndpoint)
            .catch((err) => err);
        const clientsresult = await axios
            .get(clientListEndPoint)
            .catch((err) => err);
        const countryresult = await axios
            .get(countryEndpoint)
            .catch((err) => err);
        const countryCoderesult = await axios
            .get(countryCodeEndpoint)
            .catch((err) => err);
        dispatch(setCurrencyData(cureencyCoderesult.data));
        dispatch(setTimezoneData(timeZoneResult.data));
        dispatch(setLaungageData(lanResult.data));
        dispatch(setClientsData(clientsresult.data));
        dispatch(setCountryPhoneData(countryCoderesult.data));
        dispatch(setCountryData(countryresult.data));
        // const statesEndpoint = getAllMasterData('StateList');
        // const statesResult = await axios.get(statesEndpoint).catch(err => err);
        dispatch(setStatesData([]));
    };
    const handleGetMastersForWareHouses = async () => {
        const lanEndpoint = getAllMasterData('Language');
        const timeZonendpoint = getAllMasterData('TimeZone');
        const cuurencyCodeEndpoint = getAllMasterData('CurrencyCode');
        const countryEndpoint = getAllMasterData('Country');
        const countryCodeEndpoint = getAllMasterData('CountryCodePhone');
        const lanResult = await axios.get(lanEndpoint).catch((err) => err);
        const timeZoneResult = await axios
            .get(timeZonendpoint)
            .catch((err) => err);
        const cureencyCoderesult = await axios
            .get(cuurencyCodeEndpoint)
            .catch((err) => err);
        const countryresult = await axios
            .get(countryEndpoint)
            .catch((err) => err);
        const countryCoderesult = await axios
            .get(countryCodeEndpoint)
            .catch((err) => err);
        dispatch(setCurrencyData(cureencyCoderesult.data));
        dispatch(setTimezoneData(timeZoneResult.data));
        dispatch(setLaungageData(lanResult.data));
        dispatch(setCountryData(countryresult.data));
        dispatch(setCountryPhoneData(countryCoderesult.data));
        const rcEndPoint = getEndPoint(ContactType.RECLAIM_CENTERS);
        const rcResult = await axios.get(rcEndPoint);
        dispatch(setReclaimCenters(rcResult.data));
        const statesEndpoint = getAllMasterData('StateList');
        const statesResult = await axios
            .get(statesEndpoint)
            .catch((err) => err);
        dispatch(setStatesData([]));
    };
    const handleGetMastersForCarriers = async () => {
        const lanEndpoint = getAllMasterData('Language');
        const timeZonendpoint = getAllMasterData('TimeZone');
        const cuurencyCodeEndpoint = getAllMasterData('CurrencyCode');
        const countryEndpoint = getAllMasterData('Country');
        const countryCodeEndpoint = getAllMasterData('CountryCodePhone');
        const lanResult = await axios.get(lanEndpoint).catch((err) => err);
        const timeZoneResult = await axios
            .get(timeZonendpoint)
            .catch((err) => err);
        const cureencyCoderesult = await axios
            .get(cuurencyCodeEndpoint)
            .catch((err) => err);
        const countryresult = await axios
            .get(countryEndpoint)
            .catch((err) => err);
        const countryCoderesult = await axios
            .get(countryCodeEndpoint)
            .catch((err) => err);
        dispatch(setCurrencyData(cureencyCoderesult.data));
        dispatch(setTimezoneData(timeZoneResult.data));
        dispatch(setLaungageData(lanResult.data));
        dispatch(setCountryData(countryresult.data));
        dispatch(setCountryPhoneData(countryCoderesult.data));
        const statesEndpoint = getAllMasterData('StateList');
        const statesResult = await axios
            .get(statesEndpoint)
            .catch((err) => err);
        dispatch(setStatesData([]));
    };
    const handleGetMastersForVendors = async () => {
        const lanEndpoint = getAllMasterData('Language');
        const timeZonendpoint = getAllMasterData('TimeZone');
        const cuurencyCodeEndpoint = getAllMasterData('CurrencyCode');
        const countryEndpoint = getAllMasterData('Country');
        const lanResult = await axios.get(lanEndpoint).catch((err) => err);
        const timeZoneResult = await axios
            .get(timeZonendpoint)
            .catch((err) => err);
        const cureencyCoderesult = await axios
            .get(cuurencyCodeEndpoint)
            .catch((err) => err);
        const countryresult = await axios
            .get(countryEndpoint)
            .catch((err) => err);
        dispatch(setCurrencyData(cureencyCoderesult.data));
        dispatch(setTimezoneData(timeZoneResult.data));
        dispatch(setLaungageData(lanResult.data));
        dispatch(setCountryData(countryresult.data));
        const customerTypeEndPoint = getAllMasterData('CustomerType');
        const customerTyperesult = await axios
            .get(customerTypeEndPoint)
            .catch((err) => err);
        dispatch(setCustomerTypeData(customerTyperesult.data));
        const countryCodeEndpoint = getAllMasterData('CountryCodePhone');
        const countryCoderesult = await axios
            .get(countryCodeEndpoint)
            .catch((err) => err);
        dispatch(setCountryPhoneData(countryCoderesult.data));
    };
    const handleGetMastersForDepartments = async () => {
        const glEndPoint = getAllMasterData('GLAccountsDropdown');
        const glResult = await axios.get(glEndPoint);
        dispatch(setGLAccountMasterData(glResult.data));
    };
    const handleGetMastersForGLAccounts = async () => {
        const deptEndPoint = getAllMasterData('DepartmentsDropdown');
        const deptResult = await axios.get(deptEndPoint);
        dispatch(setDepartmentMasterData(deptResult.data));
    };
    const handleGetMastersForFiscalYear = async () => {
        const fiscalYearEndPoint = getAllMasterData('FiscalYear');
        const fiscalYearTemplateEndPoint =
            getAllMasterData('FiscalYearTemplate');
        const fiscalYearresult = await axios
            .get(fiscalYearEndPoint)
            .catch((err) => err);
        const fiscalYearTemplateresult = await axios
            .get(fiscalYearTemplateEndPoint)
            .catch((err) => err);
        dispatch(setFiscalYearsData(fiscalYearresult.data));
        dispatch(setFiscalYearTemplateData(fiscalYearTemplateresult.data));
    };
    const handleGetMastersForCommodityTypes = async () => {
        const pricingTypeEndPoint = getAllMasterData('PricingType');
        const pricingTyperesult = await axios
            .get(pricingTypeEndPoint)
            .catch((err) => err);
        dispatch(setPricingTypeMasterData(pricingTyperesult.data));
    };
    const handleGetMastersForRuleExceptions = async () => {
        const clientGroupEndPoint = getAllMasterData('ClientGroup');
        const clientGroupresult = await axios
            .get(clientGroupEndPoint)
            .catch((err) => err);
        dispatch(setClientGroupData(clientGroupresult.data));
        const rcEndPoint = getAllMasterData('ReclaimCentersDropdown');
        const rcResult = await axios.get(rcEndPoint).catch((err) => err);
        dispatch(setReclaimCenters(rcResult.data));
        // const dispositionEndPoint = getAllMasterData('Disposition');
        const debitReasonEndPoint = getAllMasterData('DebitReason');
        // const dispositionresult = await axios
        //     .get(dispositionEndPoint)
        //     .catch((err) => err);
        const debitReasonresult = await axios
            .get(debitReasonEndPoint)
            .catch((err) => err);
        //dispatch(setDispositionData(dispositionresult.data));
        dispatch(setDebitReasonData(debitReasonresult.data));
        const UPCDefaultEndPoint = getAllMasterData('UPCDefaultWHLoc');
        const UPCDefaultResult = await axios
            .get(UPCDefaultEndPoint)
            .catch((err) => err);
        dispatch(setWHLocUPCDefaultData(UPCDefaultResult.data));
        const customerEndPoint = getEndPoint(ContactType.CUSTOMERS);
        const customerResult = await axios
            .get(customerEndPoint)
            .catch((err) => err);
        dispatch(setCustomers(customerResult.data));
        // const vendorEndPoint = getEndPoint(ContactType.VENDORS, 1, 1000);
        // const vendorResult = await axios.get(vendorEndPoint).catch((err) => err);
        // dispatch(setVendors(vendorResult.data));
        // const storesEndPoint = getEndPoint(ContactType.STORES, 1, 1000);
        // const storesResult = await axios
        //     .get(storesEndPoint)
        //     .catch((err) => err);
        // dispatch(setStores(storesResult.data));
        // const upcEndPoint = casualDataAddPopupEndPoints('UPCs', 1, 1000);
        // const upcResult = await axios.get(upcEndPoint).catch((err) => err);
        // console.log(upcResult);
        // dispatch(setUpcList(upcResult.data?.content));
    };
    const handleGetMastersForBinExceptions = async () => {
        // const dispositionEndPoint = getAllMasterData('Disposition');
        // const dispositionresult = await axios.get(dispositionEndPoint).catch(err => err);
        // dispatch(setDispositionData(dispositionresult.data));
        const rcEndPoint = getEndPoint(ContactType.RECLAIM_CENTERS);
        const rcActiveEndPoint = getAllMasterData('ReclamCenters');
        console.log(rcActiveEndPoint);
        const rcResult = await axios.get(rcActiveEndPoint);
        dispatch(setReclaimCenters(rcResult.data));
    };
    const handleGetMastersForPalletLocations = async () => {
        const palletNameEndPoint = getAllMasterData('PalletName');
        const palletNameresult = await axios
            .get(palletNameEndPoint)
            .catch((err) => err);
        dispatch(setPalletNameMasterData(palletNameresult.data));
        const scanGroupEndPoint = getAllMasterData('ScanGroup');
        const scanGroupresult = await axios
            .get(scanGroupEndPoint)
            .catch((err) => err);
        dispatch(setScanGroupMasterData(scanGroupresult.data));
        const rcEndPoint = getEndPoint(ContactType.RECLAIM_CENTERS);
        const rcResult = await axios.get(rcEndPoint);
        dispatch(setReclaimCenters(rcResult.data));
        const pricingTypeEndPoint = getAllMasterData('PricingType');
        const pricingTyperesult = await axios
            .get(pricingTypeEndPoint)
            .catch((err) => err);
        dispatch(setPricingTypeMasterData(pricingTyperesult.data));
        const whLocEndPoint = getAllMasterData('WhseLocations');
        const whLocEndPointresult = await axios
            .get(whLocEndPoint)
            .catch((err) => err);
        dispatch(setWHLocationsData(whLocEndPointresult.data));
    };
    const handleGetMastersForInventoryCatalog = async () => {
        const palletNameEndPoint = getAllMasterData('PalletName');
        const palletNameresult = await axios
            .get(palletNameEndPoint)
            .catch((err) => err);
        dispatch(setPalletNameMasterData(palletNameresult.data));
        const scanGroupEndPoint = getAllMasterData('ScanGroup');
        const scanGroupresult = await axios
            .get(scanGroupEndPoint)
            .catch((err) => err);
        dispatch(setScanGroupMasterData(scanGroupresult.data));
        const rcEndPoint = getEndPoint(ContactType.RECLAIM_CENTERS);
        const rcResult = await axios.get(rcEndPoint);
        dispatch(setReclaimCenters(rcResult.data));
        const pricingTypeEndPoint = getAllMasterData('PricingType');
        const pricingTyperesult = await axios
            .get(pricingTypeEndPoint)
            .catch((err) => err);
        dispatch(setPricingTypeMasterData(pricingTyperesult.data));
        const whLocEndPoint = getAllMasterData('WhseLocations');
        const whLocEndPointresult = await axios
            .get(whLocEndPoint)
            .catch((err) => err);
        dispatch(setWHLocationsData(whLocEndPointresult.data));
    };
    const handleGetMastersForWHLocations = async () => {
        // const rcEndPoint = getEndPoint(ContactType.RECLAIM_CENTERS);
        // const rcResult = await axios.get(rcEndPoint);
        // dispatch(setReclaimCenters(rcResult.data));
        const UPCDefaultEndPoint = getAllMasterData('UPCDefaultWHLoc');
        const UPCDefaultResult = await axios
            .get(UPCDefaultEndPoint)
            .catch((err) => err);
        dispatch(setWHLocUPCDefaultData(UPCDefaultResult.data));
        const CustomWHEndPoint = getAllMasterData('CustomWHLoc');
        const CustomWHResult = await axios
            .get(CustomWHEndPoint)
            .catch((err) => err);
        dispatch(setWHLocCustomData(CustomWHResult.data));
        const dispositionEndPoint = getAllMasterData('Disposition');
        const dispositionresult = await axios
            .get(dispositionEndPoint)
            .catch((err) => err);
        dispatch(setDispositionData(dispositionresult.data));
    };
    const handleGetMastersForItemCatalog = async () => {
        const dispositionEndPoint = getAllMasterData('Disposition');
        const debitReasonEndPoint = getAllMasterData('DebitReason');
        const dispositionresult = await axios
            .get(dispositionEndPoint)
            .catch((err) => err);
        const debitReasonresult = await axios
            .get(debitReasonEndPoint)
            .catch((err) => err);
        dispatch(setDispositionData(dispositionresult.data));
        dispatch(setDebitReasonData(debitReasonresult.data));
        const UPCDefaultEndPoint = getAllMasterData('UPCDefaultWHLoc');
        const UPCDefaultResult = await axios
            .get(UPCDefaultEndPoint)
            .catch((err) => err);
        dispatch(setWHLocUPCDefaultData(UPCDefaultResult.data));
        const storeDispositionEndPoint = getAllMasterData('StoreDisposition');
        const StoreDispositionresult = await axios
            .get(storeDispositionEndPoint)
            .catch((err) => err);
        dispatch(setStoreDispositionList(StoreDispositionresult.data));
        const vendorEndPoint = getEndPoint(ContactType.VENDORS, 1, 1000);
        const vendorResult = await axios
            .get(vendorEndPoint)
            .catch((err) => err);
        dispatch(setVendors(vendorResult.data));
        const whLocEndPoint = getAllMasterData('WhseLocations');
        const whLocEndPointresult = await axios
            .get(whLocEndPoint)
            .catch((err) => err);
        dispatch(setWHLocationsData(whLocEndPointresult.data));
    };
    const handleGetMastersForStoreDispositions = async () => {
        const dispositionEndPoint = getAllMasterData('Disposition');
        const dispositionresult = await axios
            .get(dispositionEndPoint)
            .catch((err) => err);
        dispatch(setDispositionData(dispositionresult.data));
        const UPCDefaultEndPoint = getAllMasterData('UPCDefaultWHLoc');
        const UPCDefaultResult = await axios
            .get(UPCDefaultEndPoint)
            .catch((err) => err);
        dispatch(setWHLocUPCDefaultData(UPCDefaultResult.data));
    };

    useEffect(() => {
        if (selectedTab) {
            dispatch(setTabNameForRowData(selectedTab));
            if (selectedTab === ContactType.STORES) {
                handleGetMastersForStores();
            } else if (selectedTab === ContactType.CUSTOMERS) {
                handleGetMastersForCustomers();
            } else if (selectedTab === ContactType.RECLAIM_CENTERS) {
                handleGetMastersForReclaimCenters();
            } else if (selectedTab === ContactType.WAREHOUSES) {
                handleGetMastersForWareHouses();
            } else if (selectedTab === ContactType.COMPANY_CODES) {
                handleGetMastersForComapnyCodes();
            } else if (selectedTab === ContactType.CARRIERS) {
                handleGetMastersForCarriers();
            } else if (selectedTab === ContactType.VENDORS) {
                handleGetMastersForVendors();
            } else if (selectedTab === ContactType.DEPARTMENTS) {
                handleGetMastersForDepartments();
            } else if (selectedTab === ContactType.GL_ACCOUNTS) {
                handleGetMastersForGLAccounts();
            } else if (selectedTab === ContactType.FISCAL_YEAR_SETUP) {
                handleGetMastersForFiscalYear();
                //dispatch(setPalletName(''));
            } else if (selectedTab === ContactType.COMMODITY_TYPES) {
                handleGetMastersForCommodityTypes();
            } else if (selectedTab === ContactType.RULE_EXCEPTIONS) {
                handleGetMastersForRuleExceptions();
            } else if (selectedTab === ContactType.BIN_EXCEPTIONS) {
                handleGetMastersForBinExceptions();
            } else if (selectedTab === ContactType.WAREHOUSE_LOCATIONS) {
                handleGetMastersForWHLocations();
            } else if (selectedTab === ContactType.PALLET_LOCATIONS) {
                handleGetMastersForPalletLocations();
            } else if (selectedTab === ContactType.INVENTORY_CATALOG) {
                handleGetMastersForInventoryCatalog();
            } else if (selectedTab === ContactType.ITEM_CATALOG) {
                handleGetMastersForItemCatalog();
            } else if (selectedTab === ContactType.STORE_DISPOSITIONS) {
                handleGetMastersForStoreDispositions();
            }
        }
    }, [selectedTab]);

    // useEffect(() => {
    //   if(masterDataType) {
    //     setSelectedTab(masterDataType === "partners" ? ContactType.STORES : masterDataType === "orgStructure" ? ContactType.COMPANY_CODES : masterDataType === "finance" ? ContactType.DEPARTMENTS : masterDataType === "items" ? ContactType.ITEM_CATALOG:  masterDataType === "inventory" ? ContactType.INVENTORY_CATALOG : ContactType.STORES);
    //   }
    // }, [masterDataType]);

    useEffect(() => {
        if (selectedRowData && selectedRowData.length > 0) {
            setShowGrid(false);
        }
    }, [selectedRowData]);

    const handleGridDataChange = async (
        endpoint: string,
        selectedTab: ContactType,
        filterdata: any
    ) => {
        const result = await axios.get(endpoint).then((data) => {
            setLoaderStatus(false);
            if (selectedTab === ContactType.STORES) {
                console.log(gridRef);
                let storesConf: any = data.data;
                stores = data.data;
                let storesConfig: any = {
                    pageNO: storesConf.pageable.pageNumber,
                    totalRecords: storesConf.totalElements,
                    totalPages: storesConf.totalPages,
                };
                const renderFunc = () => {
                    const storeList = [...storeRows, ...stores.content];
                    setstoreRows(storeList);
                    dispatch(setCurrentContact(storeList));
                };
                if (storeRows.length > 0) {
                    storeRows && renderFunc();
                } else {
                    setstoreRows(stores?.content);
                    dispatch(setCurrentContact(stores?.content));
                }
                // dispatch(setStoresConfig(storesConfig));
                setStorePaging(storesConfig);
            } else if (selectedTab === ContactType.VENDORS) {
                vendors = data.data;
                let vendorConf: any = data.data;
                let vendorsConfig: any = {
                    pageNO: vendorConf.pageable.pageNumber,
                    totalRecords: vendorConf.totalElements,
                    totalPages: vendorConf.totalPages,
                };
                const renderVendorFunc = () => {
                    const vendorsList = [...vendorRows, ...vendors.content];
                    setVendorRows(vendorsList);
                    dispatch(setCurrentContact(vendorsList));
                };
                if (storeRows.length > 0) {
                    vendorRows && renderVendorFunc();
                } else {
                    setVendorRows(vendors?.content);
                    dispatch(setCurrentContact(vendors?.content));
                }
                // dispatch(setStoresConfig(vendorsConfig));
                setVendorsPaging(vendorsConfig);
            } else {
                if (selectedTab === ContactType.FISCAL_YEAR_SETUP) {
                    dispatch(setCurrentContact([]));
                } else {
                    dispatch(setCurrentContact(data?.data));
                }
            }
        });

        //   switch (selectedTab) {
        //     case ContactType.STORES: {
        //       //console.log(result.data)
        //       dispatch(setStores(result.data));
        //       break;
        //     }
        //     case ContactType.CARRIERS: {
        //       dispatch(setCarriers(result.data));
        //       break;
        //     }
        //     case ContactType.RECLAIM_CENTERS: {
        //       dispatch(setReclaimCenters(result.data));
        //       break;
        //     }
        //     case ContactType.CUSTOMERS: {
        //       dispatch(setSecondaryRetails(result.data));
        //       break;
        //     }
        //     case ContactType.VENDORS: {
        //       dispatch(setVendors(result.data));
        //       break;
        //     }
        //     case ContactType.WAREHOUSES: {
        //       dispatch(setWarehouses(result.data));
        //       break;
        //     }
        //     case ContactType.COMPANY_CODES: {
        //       dispatch(setCompanyCodes(result.data));
        //       break;
        //     }
        //     default: {
        //       dispatch(setStores(result.data));
        //       break;
        //     }
        //   }
    };
    let tab = ContactType.STORES;
    if (selectedTab) {
        tab = selectedTab;
    }
    // const getSelectedRecord=(selectedTab:any,e:any)=>{
    //   console.log(selectedTab,e);
    //   if(selectedTab===ContactType.STORE_DISPOSITIONS){
    //     dispatch(setRowData(e))
    //   }
    // }
    const handleOnPage = (pageNO: number) => {
        let endpoints: any;
        if (
            selectedTab === ContactType.STORES ||
            selectedTab === ContactType.VENDORS
        ) {
            endpoints = getEndPoint(selectedTab, pageNO, 1000);
        }
        handleGridDataChange(endpoints, selectedTab, '');
    };

    const handleTabSelection = (event: any, tab: ContactType) => {
        setLoaderStatus(false);
        setIsFromItemCatalog(false);
        setUpcId('');
        var btnSet;
        dispatch(setUpdatedStoresORVendorData([]));
        dispatch(setSelectedRecrdsExport([]));
        dispatch(setMasterDataAddImage([]));
        const elements = document.getElementsByClassName('activeTab');
        while (elements.length > 0) elements[0].classList.remove('activeTab');
        if (tab === ContactType.CARRIERS) {
            btnSet = document.getElementById('btnCarriers') as HTMLElement;
            btnSet.classList.add('activeTab');
        } else if (tab === ContactType.STORES) {
            dispatch(setCurrentContact([]));
            setstoreRows([]);
            setStoresConfig({});
            btnSet = document.getElementById('btnStores') as HTMLElement;
            btnSet.classList.add('activeTab');
        } else if (tab === ContactType.VENDORS) {
            dispatch(setCurrentContact([]));
            setVendorRows([]);
            setStoresConfig({});
            btnSet = document.getElementById('btnVendors') as HTMLElement;
            btnSet.classList.add('activeTab');
        } else if (tab === ContactType.CUSTOMERS) {
            btnSet = document.getElementById('btnCustomers') as HTMLElement;
            btnSet.classList.add('activeTab');
        } else if (tab === ContactType.COMPANY_CODES) {
            btnSet = document.getElementById('btnCompanyCodes') as HTMLElement;
            btnSet.classList.add('activeTab');
        } else if (tab === ContactType.RECLAIM_CENTERS) {
            btnSet = document.getElementById(
                'btnReclaimCenters'
            ) as HTMLElement;
            btnSet.classList.add('activeTab');
        } else if (tab === ContactType.WAREHOUSES) {
            btnSet = document.getElementById('btnWarehouses') as HTMLElement;
            btnSet.classList.add('activeTab');
        } else if (tab === ContactType.DEPARTMENTS) {
            btnSet = document.getElementById('btnDepartments') as HTMLElement;
            btnSet.classList.add('activeTab');
        } else if (tab === ContactType.GL_ACCOUNTS) {
            btnSet = document.getElementById('btnGLAccounts') as HTMLElement;
            btnSet.classList.add('activeTab');
        } else if (tab === ContactType.FISCAL_YEAR_SETUP) {
            btnSet = document.getElementById('btnFiscalYear') as HTMLElement;
            btnSet.classList.add('activeTab');
        } else if (tab === ContactType.ITEM_CATALOG) {
            btnSet = document.getElementById('btnItemCatalog') as HTMLElement;
            btnSet.classList.add('activeTab');
        } else if (tab === ContactType.COMMODITY_TYPES) {
            btnSet = document.getElementById(
                'btnCommodityTypes'
            ) as HTMLElement;
            btnSet.classList.add('activeTab');
        } else if (tab === ContactType.RULE_EXCEPTIONS) {
            btnSet = document.getElementById('btnRuleException') as HTMLElement;
            btnSet.classList.add('activeTab');
        } else if (tab === ContactType.BIN_EXCEPTIONS) {
            btnSet = document.getElementById('btnBinException') as HTMLElement;
            btnSet.classList.add('activeTab');
        } else if (tab === ContactType.WAREHOUSE_LOCATIONS) {
            btnSet = document.getElementById('btnWHLocations') as HTMLElement;
            btnSet.classList.add('activeTab');
        } else if (tab === ContactType.STORE_DISPOSITIONS) {
            btnSet = document.getElementById(
                'btnStoreDisposition'
            ) as HTMLElement;
            btnSet.classList.add('activeTab');
        } else if (tab === ContactType.INVENTORY_CATALOG) {
            btnSet = document.getElementById(
                'btnInventoryCatalog'
            ) as HTMLElement;
            btnSet.classList.add('activeTab');
        } else if (tab === ContactType.PALLET_LOCATIONS) {
            btnSet = document.getElementById(
                'btnPalletLocations'
            ) as HTMLElement;
            btnSet.classList.add('activeTab');
        } else {
        }
        setButtonFlagForRuleExceptionSingleView(false);
        dispatch(setCurrentContact([]));
        if (selectedTab == tab) {
            event?.preventDefault();
            event?.stopPropagation();
        } else {
            dispatch(setRowData([]));
            setSelectedTab(tab);
            setShowGrid(true);
        }
    };
    const dialogClose = () => {
        setDialogVisibility(false);
    };
    const handleClick = () => {
        setDialogVisibility(true);
    };

    const handlePopupAction = (selectedTabName: any) => {
        setAddScreen(true);
        setAddScreenTabName(selectedTabName);
    };

    const handleBackClick = () => {
        setShowGrid(true);
    };
    const handleBackToRuleClick = (e: any) => {
        setLoaderStatus(true);
        setButtonFlagForRuleExceptionSingleView(true);
        setSelectedTab(ContactType.RULE_EXCEPTIONS);
        setShowGrid(true);
        setIsFromItemCatalog(true);
        setUpcId(e);
        // const endpoint = getRuleExceptionsByUPCId(
        //     ContactType.RULE_EXCEPTIONS,
        //     e
        // );
        // handleGetSelectedRuleRowData(endpoint, e);
    };

    const handleGetSelectedRuleRowData = async (endpoint: string, e: any) => {
        const result = await axios.get(endpoint, e);
        let ruleExceptions: any = [];
        if (result && result.data) {
            ruleExceptions = result.data;
            // dispatch(setRowData(result.data));
            dispatch(setCurrentContact(result.data));
            setSelectedTab(ContactType.RULE_EXCEPTIONS);
            if (ruleExceptions && ruleExceptions.length > 1) {
                setShowGrid(true);
            } else {
                const endpoint = getTabData(
                    ContactType.RULE_EXCEPTIONS,
                    ruleExceptions[0]?.id
                );
                handleGetSelectedRowData(endpoint);
            }
            // } else {
            setShowGrid(true);
            setLoaderStatus(false);
            // }
        } else {
            alert('Error');
        }
    };

    const handleGetSelectedRowData = async (endpoint: string) => {
        const result = await axios.get(endpoint);
        if (result && result.data) {
            dispatch(setRowData([result.data]));
            setShowGrid(false);
        } else {
            alert('Error');
        }
    };
    const handleBackToParticularRuleClick = (e: any) => {
        const endpoint = getTabData(ContactType.RULE_EXCEPTIONS, e);
        handleGetSelectedRuleRowData(endpoint, e);
    };

    const handleBackClickPallet = () => {
        setSelectedTab(ContactType.PALLET_LOCATIONS);
        setShowGrid(true);
    };
    const handleBackClickRuleException = () => {
        setSelectedTab(ContactType.RULE_EXCEPTIONS);
        setShowGrid(true);
        setButtonFlagForRuleExceptionSingleView(false);
    };

    const handleBackClickBinException = () => {
        setSelectedTab(ContactType.BIN_EXCEPTIONS);
        setShowGrid(true);
    };

    const handleBackClickInventoryCatalog = () => {
        setSelectedTab(ContactType.INVENTORY_CATALOG);
        setShowGrid(true);
    };

    const handleChangeFiscalYearCalendar = (e: any) => {
        const endpoint = getTabData(
            ContactType.FISCAL_YEAR_SETUP,
            e.target.value
        );
        handleMasterDataChange(endpoint, e);
    };

    const handleMasterDataChange = async (endpoint: string, e: any) => {
        const result = await axios.get(endpoint, e);
        if (result && result.data) {
            dispatch(setCurrentContact(result.data));
        } else {
        }
    };
    let gridHieght = 'calc(100vh - 385px)';
    const getSelectedRecord = (e: any) => {
        //console.log(e)
    };
    const getDeSelectedRecord = (e: any) => {
        //  seletedDataToExport(e);
    };

    const seletedDataToExport = useSelector(
        (state: RootState) => state.contactReducer.selectedRecordsForExport
    );

    const excelExport = () => {
        console.log(seletedDataToExport);
        // if (exportRecords.length > 0) {
        //     // console.log(exportRecords, 'exportRecords');
        //     ExcelService.exportAsExcel('',selectedTab, exportRecords);
        // }
        if (
            seletedDataToExport !== null &&
            seletedDataToExport !== undefined &&
            seletedDataToExport.length > 0
        ) {
            ExcelService.exportAsExcel('', selectedTab, seletedDataToExport);
            dispatch(setSelectedRecrdsExport([]));
        } else {
            if (currentContact && currentContact.length > 0) {
                ExcelService.exportAsExcel('', selectedTab, currentContact);
            }
        }
    };

    return (
        <div className="col-sm-12 col-md-12 col-lg-12 parent-tab-width">
            <div className="contact-screen">
                <div className="tab-wrapper">
                    {addScreen && (
                        <AddMastersScreen
                            addScreen={addScreen}
                            setAddScreen={setAddScreen}
                            tabName={addScreenTabName}
                        />
                    )}
                    {masterDataType.includes('partners') ? (
                        <>
                            <ButtonComponent
                                className="textTransform-initial  stores activeTab"
                                id="btnStores"
                                onClick={(e) =>
                                    handleTabSelection(e, ContactType.STORES)
                                }
                            >
                                Stores
                            </ButtonComponent>
                            <ButtonComponent
                                className="textTransform-initial carriers"
                                id="btnCarriers"
                                onClick={(e) =>
                                    handleTabSelection(e, ContactType.CARRIERS)
                                }
                            >
                                Carriers
                            </ButtonComponent>
                            <ButtonComponent
                                className="textTransform-initial vendors"
                                id="btnVendors"
                                onClick={(e) =>
                                    handleTabSelection(e, ContactType.VENDORS)
                                }
                            >
                                Vendors
                            </ButtonComponent>

                            <ButtonComponent
                                className="textTransform-initial customers"
                                id="btnCustomers"
                                onClick={(e) =>
                                    handleTabSelection(e, ContactType.CUSTOMERS)
                                }
                            >
                                Customers
                            </ButtonComponent>
                            {/* <ButtonComponent className="textTransform-initial"
             onClick={(e) => handleTabSelection(e, ContactType.PARTNER_SETTINGS)}
            >Partner Settings</ButtonComponent> */}
                        </>
                    ) : masterDataType.includes('orgStructure') ? (
                        <>
                            <ButtonComponent
                                className="textTransform-initial company-codes activeTab"
                                id="btnCompanyCodes"
                                onClick={(e) =>
                                    handleTabSelection(
                                        e,
                                        ContactType.COMPANY_CODES
                                    )
                                }
                            >
                                Company Codes
                            </ButtonComponent>
                            <ButtonComponent
                                className="textTransform-initial reclaim-centers"
                                id="btnReclaimCenters"
                                onClick={(e) =>
                                    handleTabSelection(
                                        e,
                                        ContactType.RECLAIM_CENTERS
                                    )
                                }
                            >
                                Reclaim Centers
                            </ButtonComponent>
                            <ButtonComponent
                                className="textTransform-initial warehouses"
                                id="btnWarehouses"
                                onClick={(e) =>
                                    handleTabSelection(
                                        e,
                                        ContactType.WAREHOUSES
                                    )
                                }
                            >
                                Warehouses
                            </ButtonComponent>
                        </>
                    ) : masterDataType.includes('finance') ? (
                        <>
                            <ButtonComponent
                                className="textTransform-initial departments activeTab"
                                id="btnDepartments"
                                onClick={(e) =>
                                    handleTabSelection(
                                        e,
                                        ContactType.DEPARTMENTS
                                    )
                                }
                            >
                                Departments
                            </ButtonComponent>
                            <ButtonComponent
                                className="textTransform-initial gl-accounts"
                                id="btnGLAccounts"
                                onClick={(e) =>
                                    handleTabSelection(
                                        e,
                                        ContactType.GL_ACCOUNTS
                                    )
                                }
                            >
                                GL Accounts
                            </ButtonComponent>
                            {/* <ButtonComponent className="textTransform-initial"
          onClick={(e) => handleTabSelection(e, ContactType.PAYMENT_TERMS)}
        >
          Payment Terms
        </ButtonComponent> */}
                            <ButtonComponent
                                className="textTransform-initial fiscal-year-setup"
                                id="btnFiscalYear"
                                onClick={(e) =>
                                    handleTabSelection(
                                        e,
                                        ContactType.FISCAL_YEAR_SETUP
                                    )
                                }
                            >
                                Fiscal Year Setup
                            </ButtonComponent>
                        </>
                    ) : masterDataType.includes('items') ? (
                        <>
                            <ButtonComponent
                                className="textTransform-initial item-catalog activeTab"
                                id="btnItemCatalog"
                                onClick={(e) =>
                                    handleTabSelection(
                                        e,
                                        ContactType.ITEM_CATALOG
                                    )
                                }
                            >
                                Item Catalog
                            </ButtonComponent>
                            <ButtonComponent
                                className="textTransform-initial commodity-types"
                                id="btnCommodityTypes"
                                onClick={(e) =>
                                    handleTabSelection(
                                        e,
                                        ContactType.COMMODITY_TYPES
                                    )
                                }
                            >
                                Commodity Types
                            </ButtonComponent>
                            <ButtonComponent
                                className="textTransform-initial rule-exceptions"
                                id="btnRuleException"
                                onClick={(e) =>
                                    handleTabSelection(
                                        e,
                                        ContactType.RULE_EXCEPTIONS
                                    )
                                }
                            >
                                Rule Exceptions
                            </ButtonComponent>
                            <ButtonComponent
                                className="textTransform-initial bin-exceptions"
                                id="btnBinException"
                                onClick={(e) =>
                                    handleTabSelection(
                                        e,
                                        ContactType.BIN_EXCEPTIONS
                                    )
                                }
                            >
                                Bin Exceptions
                            </ButtonComponent>
                            <ButtonComponent
                                className="textTransform-initial warehouse-locations"
                                id="btnWHLocations"
                                onClick={(e) =>
                                    handleTabSelection(
                                        e,
                                        ContactType.WAREHOUSE_LOCATIONS
                                    )
                                }
                            >
                                Warehouse Locations
                            </ButtonComponent>
                            <ButtonComponent
                                className="textTransform-initial store-dispositions"
                                id="btnStoreDisposition"
                                onClick={(e) =>
                                    handleTabSelection(
                                        e,
                                        ContactType.STORE_DISPOSITIONS
                                    )
                                }
                            >
                                Store Dispositions
                            </ButtonComponent>
                        </>
                    ) : masterDataType.includes('inventory') ? (
                        <>
                            <ButtonComponent
                                className="textTransform-initial inventory-catalog activeTab"
                                id="btnInventoryCatalog"
                                onClick={(e) =>
                                    handleTabSelection(
                                        e,
                                        ContactType.INVENTORY_CATALOG
                                    )
                                }
                            >
                                Inventory Catalog
                            </ButtonComponent>
                            <ButtonComponent
                                className="textTransform-initial pallet-locations"
                                id="btnPalletLocations"
                                onClick={(e) =>
                                    handleTabSelection(
                                        e,
                                        ContactType.PALLET_LOCATIONS
                                    )
                                }
                            >
                                Pallet Locations
                            </ButtonComponent>
                        </>
                    ) : (
                        ''
                    )}
                </div>
                {selectedTab === ContactType.ITEM_CATALOG ? (
                    <div className="float-right pt-4">
                        {/* <span><img className="ItemCatImages cursor-pointer" src={downloadIcon}
                                   onClick={() => excelExport()}/></span>
                        <span><img className="ItemCatImages ml-1rem" src={uploadIcon}/></span> */}
                        <span>
                            <ButtonComponent
                                type="button"
                                className="iconCopy float-right ml-1"
                            >
                                <Print />
                            </ButtonComponent>
                        </span>
                        <span>
                            <ButtonComponent
                                type="button"
                                className="iconCopy float-right ml-1"
                                onClick={() => excelExport()}
                            >
                                <Download />
                            </ButtonComponent>
                        </span>
                    </div>
                ) : // : (selectedTab === ContactType.BIN_EXCEPTIONS) ? ''
                selectedTab === ContactType.INVENTORY_CATALOG ? (
                    <div className="float-right pt-4">
                        <ButtonComponent
                            type="button"
                            className="iconCopy float-right ml-1"
                            onClick={() => excelExport()}
                        >
                            <Download />
                        </ButtonComponent>
                        {selectedTab === ContactType.INVENTORY_CATALOG &&
                            !showGrid && (
                                <ButtonComponent
                                    className="e-outline  print-pallet-label float-right"
                                    onClick={() => {
                                        InventoryCatalogUtil.printPalletLabel(
                                            selectedRowData
                                        );
                                    }}
                                >
                                    <img
                                        src={printerIcon}
                                        alt="Print Pallet Label"
                                    />
                                    <span className="mr-2">
                                        Print Pallet Label
                                    </span>
                                </ButtonComponent>
                            )}
                    </div>
                ) : selectedTab === ContactType.WAREHOUSE_LOCATIONS ? (
                    ''
                ) : selectedTab === ContactType.FISCAL_YEAR_SETUP ? (
                    <div className=" pt-4">
                        <div className="row">
                            <div className="col-sm-9">
                                <p className="contact-name">{selectedTab}</p>
                            </div>
                            <div className="col-sm-2 no-padding">
                                <select
                                    className="form-control mb-1rem"
                                    onChange={(e) =>
                                        handleChangeFiscalYearCalendar(e)
                                    }
                                >
                                    <option value="">Select</option>
                                    {FiscYearData?.map((fy) => (
                                        <option value={fy.id}>
                                            {fy.calendarName}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <div
                                className="col-sm-1 no-padding"
                                style={{ left: '-8px' }}
                            >
                                {/* <span><img className="ItemCatImages ml-4 cursor-pointer"
                                                           src={downloadIcon} onClick={() => excelExport()}/></span>
                                                <span><img className="ItemCatImages addPlusIcon float-right mr-4"
                                                           onClick={() => handlePopupAction(selectedTab)}
                                                           src={addIcon}/></span> */}
                                <span>
                                    <ButtonComponent
                                        type="button"
                                        className="iconCopy float-right ml-1"
                                        onClick={() =>
                                            handlePopupAction(selectedTab)
                                        }
                                    >
                                        <Add />
                                    </ButtonComponent>
                                </span>
                                <span>
                                    <ButtonComponent
                                        type="button"
                                        className="iconCopy float-right ml-1"
                                        onClick={() => excelExport()}
                                    >
                                        <Print />
                                    </ButtonComponent>
                                </span>
                            </div>
                        </div>
                    </div>
                ) : selectedTab === ContactType.STORES ? (
                    ''
                ) : selectedTab === ContactType.VENDORS ? (
                    ''
                ) : selectedTab === ContactType.RULE_EXCEPTIONS ? (
                    <>
                        <div className="float-right pt-4">
                            {/* <span><img className="ItemCatImages cursor-pointer"
                                                                   src={downloadIcon}
                                                                   onClick={() => excelExport()}/></span> */}
                            {/* <span><img className="ItemCatImages ml-1rem addPlusIcon"
                                                                   onClick={() => handlePopupAction(selectedTab)}
                                                                   src={addIcon}/></span> */}
                            <span>
                                <ButtonComponent
                                    type="button"
                                    className="iconCopy float-right ml-1"
                                    onClick={() =>
                                        handlePopupAction(selectedTab)
                                    }
                                >
                                    <Add />
                                </ButtonComponent>
                            </span>
                            <span>
                                <ButtonComponent
                                    type="button"
                                    className="iconCopy float-right ml-1"
                                    onClick={() => excelExport()}
                                >
                                    <Download />
                                </ButtonComponent>
                            </span>
                        </div>
                    </>
                ) : (
                    <div className="float-right pt-4">
                        {/* <span><img className="ItemCatImages cursor-pointer"
                                                               src={downloadIcon} onClick={() => excelExport()}/></span>
                                                    <span><img className="ItemCatImages ml-1rem addPlusIcon"
                                                               onClick={() => handlePopupAction(selectedTab)}
                                                               src={addIcon}/></span> */}
                        <span>
                            <ButtonComponent
                                type="button"
                                className="iconCopy float-right ml-1"
                                onClick={() => handlePopupAction(selectedTab)}
                            >
                                <Add />
                            </ButtonComponent>
                        </span>
                        <span>
                            <ButtonComponent
                                type="button"
                                className="iconCopy float-right ml-1"
                                onClick={() => excelExport()}
                            >
                                <Download />
                            </ButtonComponent>
                        </span>
                    </div>
                )}

                {selectedTab == ContactType.PALLET_LOCATIONS && !showGrid ? (
                    <p className="contact-name pt-5">{palletName}</p>
                ) : selectedTab == ContactType.INVENTORY_CATALOG &&
                  !showGrid ? (
                    <p className="contact-name pt-5">{InventoryName}</p>
                ) : selectedTab == ContactType.RULE_EXCEPTIONS && !showGrid ? (
                    <p className="contact-name pt-5">{RECodeRuleExcep}</p>
                ) : selectedTab == ContactType.ITEM_CATALOG && !showGrid ? (
                    <p className="contact-name pt-5">{descriptionForItem}</p>
                ) : selectedTab == ContactType.BIN_EXCEPTIONS && !showGrid ? (
                    <p className="contact-name pt-5">{RCNameBinExcep}</p>
                ) : selectedTab == ContactType.FISCAL_YEAR_SETUP ? (
                    ''
                ) : (
                    <p className="contact-name pt-5">{selectedTab} </p>
                )}

                {/* selectedTab === ContactType.BIN_EXCEPTIONS ?
          <BinExceptionsScreen /> */}
                {selectedTab === ContactType.FISCAL_YEAR_SETUP ? (
                    <FiscalYearViewScreen />
                ) : selectedTab === ContactType.WAREHOUSE_LOCATIONS ? (
                    <WarehouseLocationsScreen />
                ) : (selectedTab === ContactType.PALLET_LOCATIONS ||
                      selectedTab === ContactType.BIN_EXCEPTIONS ||
                      selectedTab === ContactType.INVENTORY_CATALOG) &&
                  showGrid ? (
                    <div className="contacts-wrapper">
                        <Grid
                            key={tab}
                            config={config[tab]}
                            data={generateGridData(selectedTab, currentContact)}
                            isFromInvoice={true}
                            onRowSelect={getSelectedRecord}
                            onRowDeselect={getDeSelectedRecord}
                        />
                        <div className="LoadImg">
                            {loaderStatus ? (
                                <img className="loaderClass" src={loader} />
                            ) : (
                                <></>
                            )}
                        </div>
                    </div>
                ) : selectedTab === ContactType.ITEM_CATALOG && showGrid ? (
                    <ItemsCatalogGrid key={tab} />
                ) : selectedTab === ContactType.VENDORS && showGrid ? (
                    <VendorsGrid key={tab} />
                ) : selectedTab === ContactType.STORES && showGrid ? (
                    <StoresGrid key={tab} />
                ) : selectedTab === ContactType.RULE_EXCEPTIONS && showGrid ? (
                    <RuleExceptionGrid
                        key={tab}
                        updateGrid={currentContact}
                        isFromItemCatalog={isFromItemCatalog}
                        upcId={upcId}
                        clearSelection={seletedDataToExport}
                    />
                ) : (
                    selectedTab !== ContactType.ITEM_CATALOG &&
                    selectedTab !== ContactType.STORES &&
                    selectedTab !== ContactType.VENDORS &&
                    selectedTab !== ContactType.RULE_EXCEPTIONS &&
                    selectedTab !== ContactType.PALLET_LOCATIONS &&
                    selectedTab !== ContactType.INVENTORY_CATALOG &&
                    selectedTab !== ContactType.BIN_EXCEPTIONS && (
                        <div className="contacts-wrapper">
                            <div className="masterDataGrid">
                                <Grid
                                    key={tab}
                                    onRowSelect={getSelectedRecord}
                                    onRowDeselect={getDeSelectedRecord}
                                    config={config[tab]}
                                    width={'100%'}
                                    height={gridHieght}
                                    data={generateGridData(
                                        selectedTab,
                                        currentContact
                                    )}
                                    pageConfig={storePaging}
                                    onPaging={handleOnPage}
                                    isFromInvoice={true}
                                />
                                <div className="LoadImg">
                                    {loaderStatus ? (
                                        <img
                                            className="loaderClass"
                                            src={loader}
                                        />
                                    ) : (
                                        <></>
                                    )}
                                </div>
                            </div>
                            {selectedRowData && selectedRowData.length > 0 ? (
                                <div className="detailsTab">
                                    <ContactsDetailspanel />
                                </div>
                            ) : (
                                <>
                                    {masterDataType != 'inventory' ? (
                                        <div className="detailsTabEmpty d-flex align-items-center">
                                            <div className="col-sm-12 col-md-12 col-lg-12 d-flex justify-content-center">
                                                <p className="selectTextForEmpty ">
                                                    Select {tabName} to view
                                                    details{' '}
                                                </p>
                                            </div>
                                        </div>
                                    ) : (
                                        ''
                                    )}
                                </>
                            )}
                        </div>
                    )
                )}
                {selectedRowData && selectedRowData.length > 0 ? (
                    <>
                        {selectedTab == ContactType.ITEM_CATALOG &&
                        !showGrid ? (
                            <ItemCatalogViewScreen
                                backPress={handleBackClick}
                                backPressToRule={handleBackToRuleClick}
                            />
                        ) : selectedTab == ContactType.PALLET_LOCATIONS &&
                          !showGrid ? (
                            <PalletLocationViewScreen
                                backPressPallet={handleBackClickPallet}
                            />
                        ) : selectedTab == ContactType.BIN_EXCEPTIONS &&
                          !showGrid ? (
                            <BinExceptionAddandViewScreen
                                backPressBinException={
                                    handleBackClickBinException
                                }
                            />
                        ) : selectedTab == ContactType.RULE_EXCEPTIONS &&
                          !showGrid ? (
                            <RuleExceptionViewScreen
                                backPressRuleException={
                                    handleBackClickRuleException
                                }
                            />
                        ) : selectedTab == ContactType.INVENTORY_CATALOG &&
                          !showGrid ? (
                            <InventoryCatalogViewScreen
                                backPressInventoryCatalog={
                                    handleBackClickInventoryCatalog
                                }
                            />
                        ) : (
                            ''
                        )}
                    </>
                ) : (
                    ''
                )}
            </div>
        </div>
    );
};

export default ContactScreenWithTabs;
