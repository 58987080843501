import { ButtonComponent } from "@syncfusion/ej2-react-buttons";
import axios from "axios";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import userIcon from "../../assets/userIcon.png";
import { RootState } from "../../redux/store";
import notify from "../../services/toasterService";
import { ContactType } from "./constants";
import { setCurrentContact, setMasterDataAddImage } from "./contactReducer";
import { addMasterDataEndPoint, getEndPoint } from "./utils";
import editIcon from "../../assets/EditRuleExcep.png";
import AddFileUploadScreen from "../accessMgmt/addScreen/fileUpload";

const DepartmentAddScreen = (props: any) => {
  const { setAddScreen } = props;
  const dispatch = useDispatch();
  const [showAltAddressInfo, setShowAltAddressInfo] = useState(false);
  const [selectedImage, setSelectedImage] = useState();
  const [glAccount, setGLAccount] = useState("");
  const [deptName, setDeptName] = useState("");
  const [deptNote, setDeptNote] = useState("");

  const [myDeptName, setMyDeptName] = useState("");
  const [myLinkAccount, setMyLinkAccount] = useState("");

  const [glAccountsLinkToDepartments, setGLAccountsLinkToDepartments] =
    useState<any[]>([]);
  const [errors, setErrors] = useState({
    nameError: "",
    glError: "",
  });
  const imageChange = (e: any) => {
    if (e.target.files && e.target.files.length > 0) {
      setSelectedImage(e.target.files[0]);
    }
  };
  const GLAccountData = useSelector(
    (state: RootState) => state.contactReducer?.glAccountMasterData
  );

  const handleAddDeptName = (e: any) => {
    setDeptName(e.target.value);
    validate();
  };
  const handleAddDeptNotes = (e: any) => {
    setDeptNote(e.target.value);
  };
  const handleGLAccountInDept = (e: any) => {
    validate();
    const addGLAccItem = JSON.parse(e.target.value);
    const CTInfo = [...glAccountsLinkToDepartments];
    if (
      !CTInfo.some(
        (person) => person.glAccountName === addGLAccItem.glAccountName
      )
    ) {
      CTInfo.push(addGLAccItem);
      setGLAccountsLinkToDepartments(CTInfo);
      var mySelect = document.getElementById(
        "glAccountLinkDropDown"
      ) as HTMLSelectElement;
      if (mySelect) {
        mySelect.selectedIndex = 0;
      }
    }
    var mySelect = document.getElementById(
      "glAccountLinkDropDown"
    ) as HTMLSelectElement;
    if (mySelect) {
      mySelect.selectedIndex = 0;
    }
  };

  const removeLinkedGLAccountData = (value: any) => {
    let linkedGLData = [...glAccountsLinkToDepartments];
    let updatedStoreData = linkedGLData.filter((item) => item.id !== value);
    var mySelect = document.getElementById(
      "glAccountLinkDropDown"
    ) as HTMLSelectElement;
    if (mySelect) {
      mySelect.selectedIndex = 0;
    }
    setGLAccountsLinkToDepartments(updatedStoreData);
  };
  const deptAddedFinalGLAcc: any[] = [];
  const body = {
    active: true,
    deleted: false,
    departmentName: deptName,
    glAccountIds: deptAddedFinalGLAcc,
    notes: deptNote,
  };
  let tabName: any = ContactType.DEPARTMENTS;
  const handleAddDepartment = () => {
    if (validate()) {
      glAccountsLinkToDepartments.map((item) =>
        deptAddedFinalGLAcc.push(item.id)
      );
      const endpoint = addMasterDataEndPoint(tabName);
      const getEndpoint = getEndPoint(tabName);
      handleMasterDataChange(endpoint, getEndpoint);
    }
  };

  const handleMasterDataChange = async (
    endpoint: string,
    getEndpoint: string
  ) => {
    // const result = await promiseToaster(axios.post(endpoint, body),{pending:"Please Wait...",success:"Department created successfully",error:"Failed to create Department"});
    // setAddScreen(false);
    // const getresult = await axios.get(getEndpoint);
    // const getResultCopy = JSON.parse(JSON.stringify(getresult.data));
    // dispatch(setCurrentContact(getResultCopy));
    const result = await axios
      .post(endpoint, body)
      .then((results: any) => {
        if (results && results.data) {
          setAddScreen(false);
          notify("Department created successfully", "success");
        } else {
          notify("Failed to create Department", "error");
        }
      })
      .catch((error: any) => {
        if (error.response) {
          notify(error.response?.data?.message, "error");
        }
      });
    const getresult = await axios.get(getEndpoint);
    dispatch(setCurrentContact(getresult.data));
  };
  const handleCancleDepartment = () => {
    setAddScreen(false);
    dispatch(setMasterDataAddImage([]));
  };
  const validate = (field?: any, value?: any) => {
    let nameError = "";
    let glError = "";

    if (deptName === "") {
      nameError = "Required Field";
      setMyDeptName("1px solid red");
    } else {
      setMyDeptName("");
    }
    if (glAccountsLinkToDepartments.length === 0) {
      glError = "Required";
      setMyLinkAccount("1px solid red");
    } else {
      setMyLinkAccount("");
    }
    if (glError || nameError) {
      setErrors({ nameError, glError });
      return false;
    }
    return true;
  };

  const handlePopupActionAddDepartments = (selectedTabName: any) => {
    uploadedImageFile = [];
    setFileUploadAddScreen(true);
    setAddScreenTabName(selectedTabName);
  };

  let uploadedImageFile = useSelector(
    (state: RootState) => state.contactReducer.uploadedImage
  );

  const [addScreen, setFileUploadAddScreen] = useState(false);
  const [addScreenTabName, setAddScreenTabName] = useState(null);
  const [screenType, setScreenType] = useState("masterdataAdd");

  return (
    <div className="addDepart-popup" style={{marginRight: '0'}}>
      <div className="row">
      {addScreen && (
        <AddFileUploadScreen
          addScreen={addScreen}
          setFileUploadAddScreen={setFileUploadAddScreen}
          tabName={addScreenTabName}
          screenType={screenType}
        />
      )}
        <div className="row d-flex justify-content-center">
          <div className="col-xl-2 col-lg-2 roundImage">
          {/* <input accept="image/*" type="file" onChange={imageChange} /> */}
          {/* <img src={userIcon} className="userAddIcon" />
          {selectedImage && (
            <div className="imagePreviewDiv">
              <img
                src={URL.createObjectURL(selectedImage)}
                className="imagePreview"
                height="100"
                width="100"
              />
            </div>
          )} */}
          {uploadedImageFile && uploadedImageFile.length > 0 ? (
            <div className="ImageContainer">
                <img
                  src={URL.createObjectURL(uploadedImageFile[0].file)}
                  className="userAddIcon"
                  height="140"
                  width="140"
                /></div>
              ) : (
                <img src={userIcon} className="userAddIcon" />
              )}
              <div className="middle">
              <img
                src={editIcon}
                alt="edit "
                width="18"
                height="18"
                className="m-3 pointer avatarEdit"
                onClick={() => handlePopupActionAddDepartments("fileUpload")}
                style={{position: 'sticky'}}
              /></div>
        </div>
        </div>

        
          <div className="col-sm-12 row m-0 mt-3">
            <div className="col-sm-6">
              <div className="col-sm-12">
              <label className="label-title">Name<span className="requiredStar">*</span></label>
              {deptName === "" ? (
                <span className="text-danger" style={{float: 'right'}}>{errors.nameError}</span>
              ) : (
                ""
              )}
              <input
                type="text"
                className="formcontrol"
                onChange={(e) => handleAddDeptName(e)}
                style={{ border: myDeptName }}
              />
            </div>
            </div>
          {/* <div className="row ml-0 mr-0 mt-1rem">
            <div className="col-sm-4">
              <span className="lblClass">Notes</span>
            </div>
            <div className="col-sm-8">
              <textarea className="form-control" onChange={(e) => handleAddDeptNotes(e)}></textarea>
            </div>
          </div> */}
        
            <div className="col-sm-6">
              <div className="col-sm-12">
              <label className="label-title">Link GL Accounts<span className="requiredStar">*</span></label>
              {glAccountsLinkToDepartments.length === 0 ? (
                <span className="text-danger" style={{float: 'right'}}>{errors.glError}</span>
              ) : (
                ""
              )}
              <select
                className="formcontrol"
                id="glAccountLinkDropDown"
                onChange={(e) => handleGLAccountInDept(e)}
                style={{ border: myLinkAccount }}
              >
                <option>Search GL Accounts</option>
                {GLAccountData?.map((gl) => (
                  <option value={JSON.stringify(gl)}>
                    {gl.glAccountName}
                    {gl.glAccountNumber}
                  </option>
                ))}
              </select>
            </div>
            <div className="col-sm-12 linkedGLAccAddInDeptDiv" style={{padding: '0px 30px'}}>
              {glAccountsLinkToDepartments &&
                glAccountsLinkToDepartments.map((item: any) => (
                  <div className="row linkedStoreDiv">
                    <div className="col-sm-10 pl-2" style={{overflow: 'hidden', fontSize: '12px'}}>
                    <span title="remove" className="linkedStoreName">
                      {item.glAccountName} {item.glAccountNumber}
                    </span></div>{" "}
                    <div className="col-sm-2 p-1">
                    <span
                      className="linkedStoreNameRemove"
                      onClick={(e) => removeLinkedGLAccountData(item.id)}
                    >
                      X
                    </span></div>
                  </div>
                ))}
            </div>
            </div>
          
        </div>
      </div>
      <div className="row mt-2rem mr-1">
        <ButtonComponent
          style={
            deptName !== ""
              ? {
                  opacity: "1",
                  pointerEvents: "all",
                  cursor: "pointer",
                }
              : {
                  opacity: "0.5",
                  pointerEvents: "none",
                  backgroundColor: "#e8e8e8",
                  color: "#969696",
                }
          }  
          cssClass="eg-btn-primary float-right"
          onClick={() => handleAddDepartment()}
        >
          ADD DEPARTMENT
        </ButtonComponent>
        <ButtonComponent
          cssClass="e-control e-btn e-lib eg-btn-primary-link e-flat mt-3"
          onClick={handleCancleDepartment}
        >
          CANCEL
        </ButtonComponent>
      </div>
    </div>
  );
};
export default DepartmentAddScreen;
