import { ButtonComponent } from "@syncfusion/ej2-react-buttons";
import axios from "axios";
import { useEffect, useState } from "react";
import notify from "../../../services/toasterService";
import {
  addMsgMngmntSettingsEndPoint,
  messageManagemetnSettingsEndPoint,
} from "../utils";
import { setMobileMsgData, setReclaimScanMsgData } from "../settingsReducers";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../redux/store";

const AddMessage = (props: any) => {
  const { setAddScreen, setTabName } = props;

  const dispatch = useDispatch();

  useEffect(() => {});
  const [msgId, setMsgId] = useState("");
  const [msgText, setMsgText] = useState("");
  const [language, setLanguage] = useState("");
  const [msgType, setMsgType] = useState("");
  const [msgDisabled, setMsgDisabled] = useState(true);
  const [myMessageType, setMyMessageType] = useState("");
  const [myLanguageType, setMyLanguageType] = useState("");
  const [myMessageText, setMyMessageText] = useState("");

  const languageData = useSelector(
    (state: RootState) => state.settingsReducer.languagesList
  );

  const [errors, setErrors] = useState({
    msgTextError: "",
    languageError: "",
    msgTypeError: "",
  });

  const handleMsgId = (e: any) => {
    setMsgId(e.target.value);
    validate();
  };

  const handleMsgType = (e: any) => {
    setMsgType(e.target.value);
    validate();
  };

  const handleMsgTxt = (e: any) => {
    setMsgText(e.target.value);
    validate();
  };

  const handleLanguage = (e: any) => {
    setLanguage(e.target.value);
    validate();
  };

  /* Create Object to insert the form */
  const body = {
    messageType: msgType,
    messageText: msgText,
    languageKey: language,
  };

  const handleAddMsg = () => {
    // if (validate()) {
    const addEndPoint = addMsgMngmntSettingsEndPoint(setTabName);
    const getEndPoint = messageManagemetnSettingsEndPoint(setTabName);
    if (
      body.messageType === "" &&
      body.messageText === "" &&
      body.languageKey === ""
    ) {
      notify("Please fill mandatory fields", "error");
    } else {
      addMsgWS(addEndPoint, getEndPoint);
    }
    // addMsgWS(addEndPoint, getEndPoint);
    // }
  };

  const addMsgWS = async (addEndPoint: string, getEndPoint: string) => {
    const result = await axios.post(addEndPoint, body);
    if (result && result.data) {
      setAddScreen(false);
      notify(setTabName + " added successfully", "success");
      await axios.get(getEndPoint).then(
        (res) => {
          if (setTabName === "Reclaim Scan") {
            dispatch(setReclaimScanMsgData(res.data));
          } else {
            dispatch(setMobileMsgData(res.data));
          }
        },
        (err) => {
          notify("Failed to fetch data", "error");
        }
      );
    } else {
      notify("Failed to create country code", "error");
    }
  };

  const handlePopUpClose = () => {
    setAddScreen(false);
  };

  const validate = (field?: any, value?: any) => {
    let msgTextError = "";
    let languageError = "";
    let msgTypeError = "";

    setErrors({ msgTextError: "", languageError: "", msgTypeError: "" });

    if (msgText === "") {
      msgTextError = "Message text is required";
      setMyMessageText("1px solid red");
    } else {
      setMyMessageText("");
    }
    // if (msgId === '') {
    //     msgIdError = 'Message id is required';
    // }
    if (language === "") {
      languageError = "Language is required";
      setMyLanguageType("1px solid red");
    } else {
      setMyLanguageType("");
    }

    if (msgType === "") {
      msgTypeError = "Message type is required";
      setMyMessageType("1px solid red");
    } else {
      setMyMessageType("");
    }
    if (msgText || language || msgType) {
      setErrors({ msgTextError, languageError, msgTypeError });
      setMsgDisabled(true);
      return false;
    } else {
      setMsgDisabled(false);
    }
    return true;
  };

  return (
    <div>
      <div className="col-sm-12 row m-0 mt-2">
        <div className="col-sm-6">
          {/* <div className='col-sm-12 mt-3'>
                        <label className='label-title'>Message ID</label>
                        <input className=" form-control" type="number"   value={msgId}  onChange={(e) => handleMsgId(e)} onBlur={(e) => handleMsgId(e)} onKeyUp={(e) => handleMsgId(e)}/>
                        {msgId === '' ? <span className='text-danger'>{errors.msgIdError}</span> : ''}
                    </div> */}
          <div className="col-sm-12 mt-3">
            <label className="label-title">Message Type</label>
            <select
              className="formcontrol"
              onChange={(e: any) => handleMsgType(e)}
              onBlur={(e) => handleMsgType(e)}
              onKeyUp={(e) => handleMsgType(e)}
              style={{ border: myMessageType }}
            >
              <option value="">Select</option>
              <option value="Success">Success</option>
              <option value="Information">Information</option>
              <option value="Warning">Warning</option>
              <option value="Error">Error</option>
            </select>
            {msgType === "" ? (
              <span className="text-danger">{errors.msgTypeError}</span>
            ) : (
              ""
            )}
          </div>
          <div className="col-sm-12 mt-3">
            <label className="label-title">Language</label>
            <select
              className="formcontrol"
              onChange={(e: any) => handleLanguage(e)}
              onBlur={(e) => handleLanguage(e)}
              onKeyUp={(e) => handleLanguage(e)}
              style={{ border: myLanguageType }}
            >
              <option value="">Select</option>
              {languageData?.map((item: { isoLongCode: any }) => (
                <option value={item?.isoLongCode}>{item?.isoLongCode}</option>
              ))}
            </select>
            {language === "" ? (
              <span className="text-danger">{errors.languageError}</span>
            ) : (
              ""
            )}
          </div>
        </div>

        <div className="col-sm-6">
          <div className="col-sm-12 mt-3">
            <label className="label-title">Message Text</label>
            <textarea
              rows={5}
              className="formDesc"
              onChange={(e) => handleMsgTxt(e)}
              onKeyUp={(e) => handleMsgTxt(e)}
              onBlur={(e) => handleMsgTxt(e)}
              style={{ border: myMessageText }}
            />
            {msgText === "" ? (
              <span className="text-danger">{errors.msgTextError}</span>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>

      <div className="col-sm-11 px-0 my-5 ml-4">
        <ButtonComponent
          cssClass="eg-btn-primary float-right"
          onClick={() => handleAddMsg()}
        >
          Add Message
        </ButtonComponent>
        <ButtonComponent
          cssClass="eg-btn-primary-link e-flat mt-3"
          onClick={() => handlePopUpClose()}
        >
          Cancel
        </ButtonComponent>
      </div>
    </div>
  );
};
export default AddMessage;
