import { SwitchComponent } from '@syncfusion/ej2-react-buttons';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import saveIcon from '../../assets/saveIcon.png';
import { RootState } from '../../redux/store';
import { setDescriptioForItemCatalog } from './contactReducer';
import notify from '../../services/toasterService';
import loader from '../../assets/loader.gif';
import ServiceHandler from '../../services/service-handler';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import { ContactType } from './constants';
const ItemCatalogViewScreen = (props: any) => {
    const { backPress, backPressToRule } = props;
    const history = useHistory();
    const [rowInfo, setRowInfo] = useState<any[]>([]);
    const [loaderStatus, setLoaderStatus] = useState(true);

    //const [vendorList, setVendorList] = useState({});
    let vendorList: any;
    const dispatch = useDispatch();
    const setRowData = useSelector(
        (state: RootState) => state.gridReducer?.selectedRowData
    );
    const setRowId = useSelector(
        (state: RootState) => state.gridReducer?.selectedRowDataId
    );
    const upcDefaultData = useSelector(
        (state: RootState) => state.contactReducer?.WHUPCDefaultData
    );

    const DebitReasonData = useSelector(
        (state: RootState) => state.contactReducer?.debitReason
    );
    const VendorData = useSelector(
        (state: RootState) => state.contactReducer?.vendors
    );
    const whLocationsInfo = useSelector(
        (state: RootState) => state.contactReducer?.WhareHouseLocationData
    );
    vendorList = VendorData;
    useEffect(() => {
        if (setRowData && setRowData?.length > 0) {
            setRowInfo(
                setRowData.filter((item) =>
                    item.id && setRowId ? item.id === setRowId.id : ''
                )
            );
            setLoaderStatus(false);

            dispatch(setDescriptioForItemCatalog(setRowData[0].description));
        }
    }, [setRowData, setRowId]);

    const handleChangeDescription = (e: any) => {
        setRowInfo([{ ...rowInfo[0], description: e.target.value }]);
    };
    const handleChangeDisposition = (e: any) => {
        setRowInfo([{ ...rowInfo[0], disposition: e.target.value }]);
    };
    const handleChangeDebitReason = (e: any) => {
        setRowInfo([{ ...rowInfo[0], debitReasonCode: e.target.value }]);
    };

    const handleChangeRetailCost = (e: any) => {
        setRowInfo([{ ...rowInfo[0], retail: e.target.value }]);
    };
    const handleChangeUnitFactor = (e: any) => {
        setRowInfo([{ ...rowInfo[0], unitFactor: e.target.value }]);
    };
    const handleChangeBillCost = (e: any) => {
        setRowInfo([{ ...rowInfo[0], cost: e.target.value }]);
    };
    const handleChangePackSize = (e: any) => {
        setRowInfo([{ ...rowInfo[0], pack: e.target.value }]);
    };
    const handleChangeVendor = (e: any) => {
        setRowInfo([{ ...rowInfo[0], vendorNumber: e.target.value }]);
    };
    const handleChangeHazardous = (e: any) => {
        if (e.target.checked) {
            setRowInfo([{ ...rowInfo[0], hazardousFlag: 'Y' }]);
        } else {
            setRowInfo([{ ...rowInfo[0], hazardousFlag: 'N' }]);
        }
    };
    const handleChangeCASubDisposition = (e: any) => {
        setRowInfo([{ ...rowInfo[0], caStateDisposition: e.target.value }]);
    };
    const handleChangeSCSubDisposition = (e: any) => {
        setRowInfo([{ ...rowInfo[0], scStateDisposition: e.target.value }]);
    };
    const handleChangeWISubDisposition = (e: any) => {
        setRowInfo([{ ...rowInfo[0], wiStateDisposition: e.target.value }]);
    };
    const handleChangeCTGlobalSubDisposition = (e: any) => {
        setRowInfo([{ ...rowInfo[0], ctStateDisposition: e.target.value }]);
    };
    const handleChangeTXSubDisposition = (e: any) => {
        setRowInfo([{ ...rowInfo[0], txStateDisposition: e.target.value }]);
    };
    const handleChangeFLSubDisposition = (e: any) => {
        setRowInfo([{ ...rowInfo[0], flStateDisposition: e.target.value }]);
    };
    const handleChangeMASubDispo = (e: any) => {
        setRowInfo([{ ...rowInfo[0], maStateDisposition: e.target.value }]);
    };

    const handleChangeWHLoc = (e: any) => {
        const rowObject = {...rowInfo[0]};
        Object.assign(rowObject,{
            warehouseLocation:{...rowObject.warehouseLocation, warehouseLocation: e.target.value},
            whLocation: e.target.value
          });
        setRowInfo([{ ...rowObject}]);
    };

    const handleMasterDataChange = async () => {
        const rowInfoObject = Object.assign({}, ...rowInfo);

        ServiceHandler.ItemsCatalogService.updateItem(
            rowInfo[0].id,
            rowInfoObject
        )
            .then((data) => {
                if (data) {
                    notify('Updated successfully', 'success');
                    backPress();
                } else {
                    notify('Failed', 'error');
                }
            })
            .catch((error: any) => {
                if (error.response) {
                    notify(error.response?.data?.message, 'error');
                }
            });
    };

    const backPressToParticularRuleItem = (e: any) => {
        backPressToRule(e.toString());
        history.push(`/masterdata/items?tab=${ContactType.RULE_EXCEPTIONS}`);
    };

    const formatDateToMonDDYY = (date: any) => {
        return moment(date).format('MMM DD, YYYY');
    };

    return (
        <div>
            {loaderStatus ? (
                <img className="loaderClass" src={loader} />
            ) : (
                <>
                    {setRowData && setRowData?.length > 0 ? (
                        <>
                            {rowInfo?.map((info) => (
                                <>
                                    <hr className="mainheading-border" />
                                    <div className="row ml-0 mr-0 mb-10">
                                        <div className="col-sm-3">
                                            <span
                                                className="backToAllText"
                                                onClick={backPress}
                                            >
                                                {'<'} Back to all Items
                                            </span>
                                        </div>
                                        <div className="col-sm-8" />
                                        <div className="col-sm-1 ItemCataLogBtn">
                                            <img
                                                alt=""
                                                className="cursor-pointer"
                                                src={saveIcon}
                                                onClick={handleMasterDataChange}
                                                style={{ float: 'right' }}
                                            />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-sm-3">
                                            <div className="col-sm-12">
                                                <span
                                                    className="lblClass"
                                                    style={{ float: 'left' }}
                                                >
                                                    UPC
                                                </span>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    value={info?.upcReference}
                                                    readOnly
                                                />
                                            </div>
                                            <div className="col-sm-12">
                                                <span
                                                    className="lblClass"
                                                    style={{ float: 'left' }}
                                                >
                                                    Description
                                                </span>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    value={info?.description}
                                                    onChange={(e) =>
                                                        handleChangeDescription(
                                                            e
                                                        )
                                                    }
                                                />
                                            </div>
                                            <div className="col-sm-12">
                                                <span
                                                    className="lblClass"
                                                    style={{ float: 'left' }}
                                                >
                                                    Vendor
                                                </span>
                                                <select
                                                    className="form-control"
                                                    onChange={(e) =>
                                                        handleChangeVendor(e)
                                                    }
                                                >
                                                    <option
                                                        value={
                                                            info?.vendorNumber
                                                        }
                                                    >
                                                        ({info.vendorNumber}){' '}
                                                        {info.vendorName}
                                                    </option>
                                                    {vendorList?.content &&
                                                        vendorList?.content?.map(
                                                            (v: any) => (
                                                                <option
                                                                    value={
                                                                        v.apVendorNumber
                                                                    }
                                                                >
                                                                    (
                                                                    {
                                                                        v.apVendorNumber
                                                                    }
                                                                    ){' '}
                                                                    {
                                                                        v.apVendorName
                                                                    }
                                                                </option>
                                                            )
                                                        )}
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-sm-3">
                                            <div className="col-sm-12">
                                                <span
                                                    className="lblClass"
                                                    style={{ float: 'left' }}
                                                >
                                                    Unit Factor
                                                </span>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    value={info?.unitFactor}
                                                    onChange={(e) =>
                                                        handleChangeUnitFactor(
                                                            e
                                                        )
                                                    }
                                                />
                                            </div>
                                            <div className="col-sm-12">
                                                <span
                                                    className="lblClass"
                                                    style={{ float: 'left' }}
                                                >
                                                    Pack Size
                                                </span>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    value={info?.pack}
                                                    onChange={(e) =>
                                                        handleChangePackSize(e)
                                                    }
                                                />
                                            </div>
                                            <div className="col-sm-12">
                                                <span
                                                    className="lblClass"
                                                    style={{ float: 'left' }}
                                                >
                                                    Bill Cost ($)
                                                </span>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    value={info?.cost}
                                                    onChange={(e) =>
                                                        handleChangeBillCost(e)
                                                    }
                                                />
                                            </div>
                                        </div>
                                        <div className="col-sm-3">
                                            <div className="col-sm-12">
                                                <span
                                                    className="lblClass"
                                                    style={{ float: 'left' }}
                                                >
                                                    UPC Disposition
                                                </span>
                                                <select
                                                    className="form-control"
                                                    value={info?.disposition}
                                                    onChange={(e) =>
                                                        handleChangeDisposition(
                                                            e
                                                        )
                                                    }
                                                >
                                                    {/* <option value={info?.disposition}>{info?.disposition}</option> */}
                                                    {upcDefaultData?.map(
                                                        (st) => (
                                                            <option
                                                                value={
                                                                    st.disposition ? st.disposition :'Select'
                                                                }
                                                            >
                                                                {st.disposition ? st.disposition :'Select'}
                                                            </option>
                                                        )
                                                    )}
                                                </select>
                                            </div>
                                            <div className="col-sm-12">
                                                <span
                                                    className="lblClass"
                                                    style={{ float: 'left' }}
                                                >
                                                    Whse Location
                                                </span>
                                                <select
                                                    className="form-control"
                                                    value={
                                                        info?.warehouseLocation
                                                            ?.warehouseLocation
                                                    }
                                                    onChange={(e: any) =>
                                                        handleChangeWHLoc(e)
                                                    }
                                                >
                                                    {/* <option
                                        value={info?.warehouseLocation?.warehouseLocation}>{info?.warehouseLocation?.warehouseLocation}</option> */}
                                                    {whLocationsInfo.map(
                                                        (st) => (
                                                            <option
                                                                value={
                                                                    st.warehouseLocation ?  st.warehouseLocation :'Select'
                                                                }
                                                            >
                                                                {
                                                                    st.warehouseLocation ?  st.warehouseLocation :'Select'
                                                                }
                                                            </option>
                                                        )
                                                    )}
                                                </select>
                                            </div>
                                            <div className="col-sm-12">
                                                <span
                                                    className="lblClass"
                                                    style={{ float: 'left' }}
                                                >
                                                    Retail Cost ($)
                                                </span>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    value={info?.retail}
                                                    onChange={(e) =>
                                                        handleChangeRetailCost(
                                                            e
                                                        )
                                                    }
                                                />
                                            </div>
                                        </div>
                                        <div className="col-sm-3">
                                            <div className="col-sm-12">
                                                <span
                                                    className="lblClass"
                                                    style={{ float: 'left' }}
                                                >
                                                    Last Import Date
                                                </span>
                                            </div>
                                            <div className="col-sm-12">
                                                <span className="storeNumber lastImportDate">
                                                    {formatDateToMonDDYY(
                                                        info?.lastModifiedDate
                                                    )}
                                                </span>
                                            </div>
                                            <div className="col-sm-12 mt-3">
                                                <span
                                                    className="lblClass"
                                                    style={{ float: 'left' }}
                                                >
                                                    Debit Reason
                                                </span>
                                                <select
                                                    className="form-control"
                                                    value={
                                                        info?.debitReasonCode
                                                    }
                                                    onChange={(e) =>
                                                        handleChangeDebitReason(
                                                            e
                                                        )
                                                    }
                                                >
                                                    <option value="">
                                                        Select Debit Reason Code
                                                    </option>
                                                    {DebitReasonData?.map(
                                                        (st) => (
                                                            <option
                                                                value={
                                                                    st.debitReasonCode
                                                                }
                                                            >
                                                                {
                                                                    st.debitReasonCode
                                                                }
                                                            </option>
                                                        )
                                                    )}
                                                </select>
                                            </div>
                                        </div>
                                    </div>

                                    {/* <div className="row ml-0 mr-0 mb-10">
                    <div className="col-sm-12">
                      <div className="col-sm-4">
                    <div className="col-sm-4">
                      <span className="lblClass">Store Disposition</span>
                    </div>
                    <div className="col-sm-8">
                      <select className="form-control" onChange={(e) => handleChangeStoreDisposition(e)}>
                        <option value={info.storeDisposition.storeDisposition}>{info.storeDisposition.storeDisposition}</option>
                          {StoredispositionInfo.map((st) =>
                          <option value={st.storeDisposition}>{st.storeDisposition}</option>
                          )}
                      </select>
                    </div>
                  </div>
                    </div>
                  </div> */}

                                    <div className="row ml-0 mr-0 mb-10 mt-5">
                                        <div className="col-sm-6">
                                            <span>Hazardous Attributes</span>
                                            <hr className="Hazardous-border" />
                                            <div className="row">
                                                <div className="col-sm-12 p-0">
                                                    <div className="col-sm-2">
                                                        <span className="lblClass">
                                                            Hazardous
                                                        </span>
                                                    </div>
                                                    <div
                                                        className="col-sm-8"
                                                        style={{ width: '31%' }}
                                                    >
                                                        <SwitchComponent
                                                            checked={
                                                                info?.hazardousFlag ===
                                                                'Y'
                                                            }
                                                            onChange={(
                                                                e: any
                                                            ) =>
                                                                handleChangeHazardous(
                                                                    e
                                                                )
                                                            }
                                                        />
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-sm-6">
                                                        <div className="col-sm-12">
                                                            <span
                                                                className="lblClass"
                                                                style={{
                                                                    float: 'left',
                                                                }}
                                                            >
                                                                CA Sub -
                                                                disposition
                                                            </span>
                                                            <input
                                                                type="text"
                                                                className="form-control "
                                                                value={
                                                                    info?.caStateDisposition
                                                                }
                                                                onChange={(
                                                                    e: any
                                                                ) =>
                                                                    handleChangeCASubDisposition(
                                                                        e
                                                                    )
                                                                }
                                                            />
                                                        </div>
                                                        <div className="col-sm-12">
                                                            <span
                                                                className="lblClass"
                                                                style={{
                                                                    float: 'left',
                                                                }}
                                                            >
                                                                CT Sub -
                                                                disposition
                                                            </span>
                                                            <input
                                                                type="text"
                                                                className="form-control "
                                                                value={
                                                                    info?.ctStateDisposition
                                                                }
                                                                onChange={(
                                                                    e: any
                                                                ) =>
                                                                    handleChangeCTGlobalSubDisposition(
                                                                        e
                                                                    )
                                                                }
                                                            />
                                                        </div>
                                                        <div className="col-sm-12">
                                                            <span
                                                                className="lblClass"
                                                                style={{
                                                                    float: 'left',
                                                                }}
                                                            >
                                                                FL Sub -
                                                                disposition
                                                            </span>
                                                            <input
                                                                type="text"
                                                                className="form-control "
                                                                value={
                                                                    info?.flStateDisposition
                                                                }
                                                                onChange={(
                                                                    e: any
                                                                ) =>
                                                                    handleChangeFLSubDisposition(
                                                                        e
                                                                    )
                                                                }
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-6">
                                                        <div className="col-sm-12">
                                                            <span
                                                                className="lblClass"
                                                                style={{
                                                                    float: 'left',
                                                                }}
                                                            >
                                                                WI Sub -
                                                                disposition
                                                            </span>
                                                            <input
                                                                type="text"
                                                                className="form-control "
                                                                value={
                                                                    info?.wiStateDisposition
                                                                }
                                                                onChange={(
                                                                    e: any
                                                                ) =>
                                                                    handleChangeSCSubDisposition(
                                                                        e
                                                                    )
                                                                }
                                                            />
                                                        </div>
                                                        <div className="col-sm-12">
                                                            <span
                                                                className="lblClass"
                                                                style={{
                                                                    float: 'left',
                                                                }}
                                                            >
                                                                TX Sub -
                                                                disposition
                                                            </span>
                                                            <input
                                                                type="text"
                                                                className="form-control "
                                                                value={
                                                                    info?.txStateDisposition
                                                                }
                                                                onChange={(
                                                                    e: any
                                                                ) =>
                                                                    handleChangeTXSubDisposition(
                                                                        e
                                                                    )
                                                                }
                                                            />
                                                        </div>
                                                        <div className="col-sm-12">
                                                            <span
                                                                className="lblClass"
                                                                style={{
                                                                    float: 'left',
                                                                }}
                                                            >
                                                                MA Sub -
                                                                disposition
                                                            </span>
                                                            <input
                                                                type="text"
                                                                className="form-control "
                                                                value={
                                                                    info?.maStateDisposition
                                                                }
                                                                onChange={(
                                                                    e: any
                                                                ) =>
                                                                    handleChangeMASubDispo(
                                                                        e
                                                                    )
                                                                }
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div
                                            className="col-sm-6"
                                            style={{ paddingLeft: '30px' }}
                                        >
                                            <span>Rule Exceptions</span>
                                            <hr className="Hazardous-border" />
                                            <div className="row col-sm-12 p-0">
                                                <span className="col-sm-6 activerule">
                                                    Active Rule Exception ?
                                                </span>
                                            </div>
                                            <div className="row col-sm-12 p-0">
                                                <span className="col-sm-2">
                                                    {info?.ruleExceptionList
                                                        ?.length === 0 ? (
                                                        <span className="noactviverule-btn">
                                                            No
                                                        </span>
                                                    ) : (
                                                        <span className="yesactviverule-btn">
                                                            Yes
                                                        </span>
                                                    )}
                                                </span>
                                                <div className="col-sm-10">
                                                    {info.ruleExceptionList
                                                        .length > 0 ? (
                                                        <span
                                                            className="activeRuleExcepItemCatalog cursor-pointer"
                                                            onClick={(e) =>
                                                                backPressToParticularRuleItem(
                                                                    info?.id
                                                                        ? info?.id.toString()
                                                                        : 0
                                                                )
                                                            }
                                                        >
                                                            View all related
                                                            rule exceptions
                                                        </span>
                                                    ) : (
                                                        //  : <span className="activeRuleExcepItemCatalog cursor-pointer">Active Rule Exception</span>}
                                                        // : <span className="activeRuleExcepItemCatalog cursor-pointer" onClick={(e) => backPressToParticularRuleItem(info.id)}>Active Rule Exception</span>}
                                                        ''
                                                    )}
                                                </div>
                                            </div>
                                            {/* <div className="col-sm-12">
                      {info.ruleExceptionList.length === 0 ?
                        ''
                        : <span className="holdFlagTextItemCatlog">{info?.ruleExceptionList[0].disposition}</span>}
                    </div> */}
                                            {/* <div className="col-sm-12">
                      {info.ruleExceptionList.length > 0 ?
                        <span className="activeRuleExcepItemCatalog cursor-pointer" onClick={(e) => backPressToParticularRuleItem(0)}>View all related rule exceptions</span>
                        //  : <span className="activeRuleExcepItemCatalog cursor-pointer">Active Rule Exception</span>}
                        // : <span className="activeRuleExcepItemCatalog cursor-pointer" onClick={(e) => backPressToParticularRuleItem(info.id)}>Active Rule Exception</span>}
                        :''}
                    </div> */}
                                        </div>
                                    </div>
                                </>
                            ))}
                        </>
                    ) : (
                        <div />
                    )}
                </>
            )}
        </div>
    );
};
export default ItemCatalogViewScreen;
