import {ButtonComponent} from "@syncfusion/ej2-react-buttons";
import {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../../redux/store";
import saveIcon from "../../../icons/common/save.png";
import closeIcon from "../../../icons/common/close.png";
import activeIcon from "../../../icons/common/active.png";
import inactiveIcon from "../../../icons/common/inactive.png";
import toggleIconOff from "../../../icons/ToggleIconOff.png";
import toggleIconOn from "../../../icons/ToggleIconOn.png";
import showpassword from "../../../icons/common/showview.png";
import hidepassword from "../../../icons/common/hideview.png";
import {getAccessMgmtEndPoint, updateAccessReqEndPoint} from "../utils";
import {setUploadedImage, setUserAccessData} from "../accessmgmtReducer";

import axios from "axios";
import notify from "../../../services/toasterService";
import {setRowData} from "../../grid/gridReducer";

const UserScanPreview = () => {
  const dispatch = useDispatch();
  const [userScanInfo, setUserScanInfo] = useState<any[]>([]);
  const [showAdd, setShowAdd] = useState(true);

  const selRowData = useSelector(
      (state: RootState) => state.gridReducer.selectedRowData
  );
  const selUsloggedinUserInfo = useSelector(
      (state: RootState) => state.sideBarReducer.loggdInUserName
  );


  const [statesList, setStateList] = useState<any>([]);

  const [closeBin, setCloaseBin] = useState(false);

  const [UserScanFields, setUserScanFields] = useState<any[]>([{}]);
  let uploadedImageFile = useSelector(
      (state: RootState) => state.accessMgmtReducer.uploadedImage
  );

  const [showPassword, setShowPassword] = useState(false);
  useEffect(() => {
    if (selRowData && selRowData?.length > 0) {

      dispatch(setUploadedImage([]));
      // getStatesList(selRowData[0].country.isoCountryCode)
      setUserScanInfo(selRowData);
      // if(selRowData[0]?.scanUser?.length > 0 && selRowData[0]?.scanUser?.length !== []) {
      UserScanFields[0].binMaxScanQty =
          selRowData[0]?.scanUser !== undefined &&
          selRowData[0]?.scanUser !== null &&
          selRowData[0]?.scanUser !== ""
              ? selRowData[0]?.scanUser?.binMaxScanQty?.toString()
              : "";
      UserScanFields[0].boxMaxScanQty =
          selRowData[0]?.scanUser !== undefined &&
          selRowData[0]?.scanUser !== null &&
          selRowData[0]?.scanUser !== ""
              ? selRowData[0]?.scanUser?.boxMaxScanQty?.toString()
              : "";
      UserScanFields[0].scanMaxQty =
          selRowData[0]?.scanUser !== undefined &&
          selRowData[0]?.scanUser !== null &&
          selRowData[0]?.scanUser !== ""
              ? selRowData[0]?.scanUser?.scanMaxQty?.toString()
              : "";
      UserScanFields[0].hourlyScanGoal =
          selRowData[0]?.scanUser !== undefined &&
          selRowData[0]?.scanUser !== null &&
          selRowData[0]?.scanUser !== ""
              ? selRowData[0]?.scanUser?.hourlyScanGoal?.toString()
              : "";
      UserScanFields[0].canCloseBin =
          selRowData[0]?.scanUser !== undefined &&
          selRowData[0]?.scanUser !== null &&
          selRowData[0]?.scanUser !== ""
              ? selRowData[0]?.scanUser?.canCloseBin
              : "";
      UserScanFields[0].supervisorPassword =
          selRowData[0]?.scanUser !== undefined &&
          selRowData[0]?.scanUser !== null &&
          selRowData[0]?.scanUser !== ""
              ? selRowData[0]?.scanUser?.supervisorPassword
              : "";
      UserScanFields[0].id = selRowData[0]?.scanUser?.id;
      setShowPassword(false);
      setCloaseBin(selRowData[0]?.scanUser?.canCloseBin);
      let temp = document.getElementById("togglepwd") as HTMLInputElement;
      if (temp !== null && temp !== undefined) {
        temp.type = "password";
      }
      // }
      //  setRemoveSelModules([]);
      // setModules([]);
    }
    // Write for onload
  }, [selRowData]);

  /**
   * Changing status for Preview selected tabs
   * @param flag - true/false
   */
  const changeStatusForRowData = (flag: any) => {
    if (flag === false) {
      setUserScanInfo([{...userScanInfo[0], activeForGrid: "Inactive"}]);
    } else {
      setUserScanInfo([{...userScanInfo[0], activeForGrid: "Active"}]);
    }
  };

  /* Update Users WS Call */
  const updateUserScanTabApi = async (data: any) => {
    //  const token = sessionStorage.getItem('token');
    // const token = UserService.getToken()
    const endPointURL = updateAccessReqEndPoint("Users");
    let body = {
      // userId: userScanInfo[0].company

      firstname: userScanInfo[0].firstname,
      lastname: userScanInfo[0].lastName,
      company: userScanInfo[0].company,
      jobTitle: userScanInfo[0].jobTitle,
      username: userScanInfo[0].username,
      roleId: userScanInfo[0].roleId,
      reclaimCenterId: userScanInfo[0].reclaimCenterId,
      endDate: userScanInfo[0].endDate,

      email: userScanInfo[0].email,
      phoneNumber: userScanInfo[0].phoneNumber,
      fax: userScanInfo[0].fax,
      language: userScanInfo[0].language,
      timezone: userScanInfo[0].timezone,
      countryId: userScanInfo[0].country,
      stateId: userScanInfo[0].stateId,
      city: userScanInfo[0].city,
      street: userScanInfo[0].street,

      password: userScanInfo[0].password,
      storeId: userScanInfo[0].storeId,
      userType: userScanInfo[0].userType,
      userId: userScanInfo[0].userId,
      id: userScanInfo[0].id,

      status: userScanInfo[0]?.activeForGrid,
    };
    if (
        uploadedImageFile.length > 0 &&
        uploadedImageFile !== [] &&
        uploadedImageFile !== undefined
    ) {
      Object.assign(body, {image: uploadedImageFile[0].base64});
    } else {
      Object.assign(body, {image: userScanInfo[0].image});
    }
    if (
        UserScanFields[0] !== undefined &&
        userScanInfo[0] !== null &&
        userScanInfo[0] !== ""
    ) {

      Object.assign(body, {
        scanUser: {
          binMaxScanQty:
              UserScanFields[0]?.binMaxScanQty !== undefined &&
              UserScanFields[0]?.binMaxScanQty !== null
                  ? UserScanFields[0]?.binMaxScanQty
                  : 0,
          boxMaxScanQty:
              UserScanFields[0]?.boxMaxScanQty !== undefined &&
              UserScanFields[0]?.boxMaxScanQty !== null
                  ? parseInt(UserScanFields[0]?.boxMaxScanQty)
                  : 0,
          scanMaxQty:
              UserScanFields[0]?.scanMaxQty !== undefined &&
              UserScanFields[0]?.scanMaxQty !== null
                  ? UserScanFields[0]?.scanMaxQty
                  : 0,
          canCloseBin: UserScanFields[0]?.canCloseBin,
          hourlyScanGoal:
              UserScanFields[0]?.hourlyScanGoal !== undefined &&
              UserScanFields[0]?.hourlyScanGoal !== null
                  ? UserScanFields[0]?.hourlyScanGoal
                  : 0,
          supervisorPassword: UserScanFields[0]?.supervisorPassword,
          id: UserScanFields[0].id,
          userId: userScanInfo[0]?.id,
        },
      });
    } else {
      Object.assign(body, {scanUser: {}});
    }


    // await axios.post(endPointURL, body, { headers: { 'Authorization': `Bearer ${token}` } }).then(
    await axios
        .post(endPointURL, body)
        .then(
            // (res) => {
            //     notify('User updated successfully', 'success');
            //     getUsers('Users');
            // }
            (res: any) => {
              if (res.data.status !== 200) {
                notify(res.data.message, "error");
              } else {
                notify(res.data.message, "success");
                getUsers("Users");
              }
              // clearPreview();
            }
        )
        .catch((err) => {
          notify("Failed to update user", "error");
        });
  };

  /* get Users WS Call */
  const getUsers = async (fieldName: any) => {
    //   const token = sessionStorage.getItem('token');
    // const token = UserService.getToken()
    const getEndPointUrl = getAccessMgmtEndPoint(fieldName);
    // await axios.get(getEndPointUrl, { headers: { 'Authorization': `Bearer ${token}` } }).then((res: { data: any; }) => {
    await axios
        .get(getEndPointUrl)
        .then((res: { data: any }) => {
          dispatch(setUserAccessData(res.data));
          setTimeout(() => {
             setUserScanInfo(res.data);
              setCloaseBin(res.data[0]?.scanUser?.canCloseBin);
             dispatch(setRowData(userScanInfo));
          }, 4);
        })
        .catch((err: any) => {
          notify(
              "Error! Unable to fetch data. Please try after sometime.",
              "error"
          );
        });
  };

  const clearPreview = () => {
    dispatch(setRowData([]));
    dispatch(setUploadedImage([]));
  };

  /* update user scan fields  */
  const userInfoFieldScanFocusOut = (e: any, key: any) => {
    setUserScanFields([{...UserScanFields[0], [key]: e.value}]);
    const scanObj = {...userScanInfo[0]};
    Object.assign(scanObj,{
      scanUser:{...scanObj.scanUser, [key]: e.value}
    })
    setUserScanInfo([{...scanObj}]);
  };

  /*toggle close bin  */
  const handleCloseBin = (e: any) => {
    setCloaseBin(e);
    UserScanFields[0].canCloseBin = e;
    const scanObj = {...userScanInfo[0]};
    Object.assign(scanObj,{
      scanUser:{...scanObj.scanUser,canCloseBin:e}
    }
    )
    setUserScanInfo([{...scanObj}]);
  };

  const toggleView = (type: boolean) => {
    setShowPassword(type);

    let temp = document.getElementById("togglepwd") as HTMLInputElement;

    if (temp !== null && temp !== undefined) {
      temp?.type === "password" ? (temp.type = "text") : (temp.type = "password");
    }

  };

  /* User Scan preview tab  */
  const UserScanPreviewData = (data: any) => {
    return (
        <div className="col-sm-12 px-4 py-2 ">
          <>
            <div className="row">
              <div className="col-sm-12 px-0 pt-2 ">
                <div className="col-sm-6 col-md-6 col-lg-6 preview-Key  ">
                  Supervisor Password {showPassword}
                </div>

                {/* {
                  UserScanFields[0]?.supervisorPassword !== undefined &&
                  UserScanFields[0]?.supervisorPassword !== null && UserScanFields[0]?.supervisorPassword !== '' ? ( */}
                      <>
                        <div className="col-sm-5 col-md-5 col-lg-5 preview-value ">
                          {/* {UserScanFields[0]?.supervisorPassword}  */}
                          <input
                              type="password"
                              id="togglepwd"
                              className="form-control boxShadow-None border-none cursor-pointer txtEditFieldFocus accMgmtFields boxShadow-None"
                              value={UserScanFields[0]?.supervisorPassword}
                              style={{
                                border: "none",
                                width: "100%",
                                textOverflow: "ellipsis",
                              }}
                              onChange={(e: any) =>
                                  userInfoFieldScanFocusOut(
                                      e.target,
                                      "supervisorPassword"
                                  )
                              }
                              onBlur={(e: any) =>
                                  userInfoFieldScanFocusOut(
                                      e.target,
                                      "supervisorPassword"
                                  )
                              }
                              onKeyUp={(e: any) =>
                                  userInfoFieldScanFocusOut(
                                      e.target,
                                      "supervisorPassword"
                                  )
                              }
                          />
                        </div>
                        <div className="col-sm-1 col-md-1 col-lg-1  p-0">
                          {showPassword ? (
                              <img
                                  src={hidepassword}
                                  alt="close "
                                  className=""
                                  onClick={() => toggleView(false)}
                                  style={{width: "25px", height: "25px"}}
                              />
                          ) : (
                              <img
                                  src={showpassword}
                                  alt="close "
                                  className=""
                                  onClick={() => toggleView(true)}
                                  style={{width: "25px", height: "25px"}}
                              />
                          )}
                        </div>
                        {" "}
                      </> 
                      <>
                        <div className="col-sm-3 col-md-3 col-lg-4 preview-value ">
                          {/* {UserScanFields[0]?.supervisorPassword}  */}
                          {/* <input
                        type="password"
                        id="togglepwd"
                        readOnly
                        value={UserScanFields[0]?.supervisorPassword}
                        className="accMgmtFields"
                        style={{
                          border: "none",
                          width: "100%",
                          textOverflow: "ellipsis",
                        }}
                      /> */}
                        </div>
                      </>
                  {/* )} */}
              </div>
            </div>
            <hr className="previewScreenDevider"></hr>
            {" "}
          </>

          <div className="row">
            <div
                className="col-sm-12"
                style={{backgroundColor: "#e8e8e8", color: "#969696"}}
            >
            <span className="font-14 float-left p-2">
              Default Qty Limits
            </span>
            </div>
            <div className="col-sm-12 px-0 pt-4 ">
              <div className="col-sm-8 col-md-8 col-lg-8 preview-Key  ">
                Default I/B Box Max Qty
              </div>
              {/* <InlineInputEdit inputClassName="txtEditClassNameInput"  text={UserScanFields[0].boxMaxScanQty} labelClassName="txtEditClassName"
                        onFocusOut={(e) => userInfoFieldScanFocusOut(e, 'boxMaxScanQty')}
                        labelPlaceHolder='---'/> */}
              <div className="col-sm-4 col-md-4 col-lg-4 preview-value ">
                <input
                    type="text"
                    className="form-control boxShadow-None border-none cursor-pointer txtEditFieldFocus accMgmtFields boxShadow-None pl-0"
                    value={UserScanFields[0]?.boxMaxScanQty}
                    onChange={(e: any) =>
                        userInfoFieldScanFocusOut(e.target, "boxMaxScanQty")
                    }
                    onBlur={(e: any) =>
                        userInfoFieldScanFocusOut(e.target, "boxMaxScanQty")
                    }
                    onKeyUp={(e: any) =>
                        userInfoFieldScanFocusOut(e.target, "boxMaxScanQty")
                    }
                />
              </div>
            </div>
            <div className="col-sm-12 px-0 pt-4 ">
              <div className="col-sm-8 col-md-8 col-lg-8 preview-Key  ">
                Default O/B Box Max Qty
              </div>
              {/* <InlineInputEdit inputClassName="txtEditClassNameInput"  text={UserScanFields[0].binMaxScanQty} labelClassName="txtEditClassName"
                       onFocusOut={(e) => userInfoFieldScanFocusOut(e, 'binMaxScanQty')}
                     labelPlaceHolder='---'/>   */}
              <div className="col-sm-4 col-md-4 col-lg-4 preview-value ">
                <input
                    type="text"
                    className="form-control boxShadow-None border-none cursor-pointer txtEditFieldFocus accMgmtFields boxShadow-None pl-0"
                    value={UserScanFields[0]?.binMaxScanQty}
                    onChange={(e: any) =>
                        userInfoFieldScanFocusOut(e.target, "binMaxScanQty")
                    }
                    onBlur={(e: any) =>
                        userInfoFieldScanFocusOut(e.target, "binMaxScanQty")
                    }
                    onKeyUp={(e: any) =>
                        userInfoFieldScanFocusOut(e.target, "binMaxScanQty")
                    }
                />
              </div>
            </div>
            <div className="col-sm-12 px-0 pt-4 ">
              <div className="col-sm-8 col-md-8 col-lg-8 pt-1 preview-Key  ">
                Scan Max Qty
              </div>
              <div className="col-sm-4 col-md-4 col-lg-4 preview-value ">
                <input
                    type="text"
                    className="form-control boxShadow-None border-none cursor-pointer txtEditFieldFocus accMgmtFields boxShadow-None pl-0"
                    value={UserScanFields[0]?.scanMaxQty}
                    onChange={(e: any) =>
                        userInfoFieldScanFocusOut(e.target, "scanMaxQty")
                    }
                    onBlur={(e: any) =>
                        userInfoFieldScanFocusOut(e.target, "scanMaxQty")
                    }
                    onKeyUp={(e: any) =>
                        userInfoFieldScanFocusOut(e.target, "scanMaxQty")
                    }
                />
                {/* <InlineInputEdit inputClassName="txtEditClassNameInput"  text={UserScanFields[0].scanMaxQty} labelClassName="txtEditClassName"
                       onFocusOut={(e) => userInfoFieldScanFocusOut(e, 'scanMaxQty')}
                     labelPlaceHolder='---'/>   */}
              </div>
            </div>
          </div>
          <hr className="previewScreenDevider"></hr>
          <div className="row">
            <div
                className="col-sm-12"
                style={{backgroundColor: "#e8e8e8", color: "#969696"}}
            >
              <span className="font-14 float-left p-2">Bin</span>
            </div>
            {/* <div className="col-sm-12 px-0 pt-4 ">
                     <div className="col-sm-6 col-md-6 col-lg-5 preview-Key  ">
                        Max Item Qty
                    </div>
                    <InlineInputEdit inputClassName="txtEditClassNameInput"  text={UserScanFields[0].binMaxScanQty} labelClassName="txtEditClassName"
                    onFocusOut={(e) => userInfoFieldScanFocusOut(e, 'binMaxScanQty')} labelPlaceHolder='---'/>

                </div>   */}
            <div className="col-sm-12 px-0 pt-4 ">
              <div className="col-sm-6 col-md-6 col-lg-6 preview-Key  ">
                Can Close Bin
              </div>
              {data?.scanUser?.canCloseBin ? (
                  <div className="col-sm-6 col-md-6 col-lg-5 preview-Key pl-0">
                    <img
                        src={toggleIconOn}
                        alt="close "
                        className="p-0"
                        onClick={() => handleCloseBin(false)}
                    />
                  </div>
              ) : (
                  <div className="col-sm-6 col-md-6 col-lg-5 preview-Key pl-0">
                    <img
                        src={toggleIconOff}
                        alt="close "
                        className="p-0"
                        onClick={() => handleCloseBin(true)}
                    />
                  </div>
              )}
            </div>
          </div>
          <hr className="previewScreenDevider"></hr>
          <div className="row">
            <div
                className="col-sm-12"
                style={{backgroundColor: "#e8e8e8", color: "#969696"}}
            >
              <span className="font-14 float-left p-2">Scan Goal</span>
            </div>
            <div className="col-sm-12 px-0 pt-4 ">
              <div className="col-sm-8 col-md-8 col-lg-8 pt-2 preview-Key  ">
                Hourly Scan Goal
              </div>
              <div className="col-sm-4 col-md-4 col-lg-4 preview-value ">
                <input
                    type="text"
                    className="form-control boxShadow-None border-none cursor-pointer txtEditFieldFocus accMgmtFields boxShadow-None pl-0"
                    value={data?.scanUser?.hourlyScanGoal}
                    onChange={(e: any) =>
                        userInfoFieldScanFocusOut(e.target, "hourlyScanGoal")
                    }
                    onBlur={(e: any) =>
                        userInfoFieldScanFocusOut(e.target, "hourlyScanGoal")
                    }
                    onKeyUp={(e: any) =>
                        userInfoFieldScanFocusOut(e.target, "hourlyScanGoal")
                    }
                />
                {/* <InlineInputEdit inputClassName="txtEditClassNameInput"  text={UserScanFields[0].hourlyScanGoal} labelClassName="txtEditClassName"
                    onFocusOut={(e) => userInfoFieldScanFocusOut(e, 'hourlyScanGoal')} labelPlaceHolder='---'/> */}
              </div>
            </div>
          </div>
        </div>
    );
  };

  /* User Scan preview tab  footer */
  const footerPreview = (data: any) => {
    return (
        <div className="col-sm-12 px-0 pt-5 ">
          <div className="col-sm-6 col-md-6 col-lg-6 pl-1.5rem pr-1 preview-Key  ">
            <ButtonComponent
                cssClass="e-outline opt-btn-light float-left"
                onClick={() => updateUserScanTabApi(data)}
            >
              <img src={saveIcon} alt="Save"/>
            </ButtonComponent>

            <ButtonComponent
                cssClass="e-outline opt-btn-light float-left ml-3"
                onClick={() => clearPreview()}
            >
              <img src={closeIcon} alt="Close"/>
            </ButtonComponent>
          </div>
          <div className="col-sm-6 col-md-6 col-lg-6 preview-value pt-3">
            <p className="preview-status">
              {data?.activeForGrid === "Active" ||
              data?.activeForGrid === "active" ? (
                  <span>
                <img
                    src={activeIcon}
                    alt="Status"
                    onClick={() => changeStatusForRowData(false)}
                />
                    &nbsp; &nbsp; Active
              </span>
              ) : (
                  <span>
                <img
                    src={inactiveIcon}
                    alt="Status"
                    onClick={() => changeStatusForRowData(true)}
                />
                    &nbsp; &nbsp; Inactive
              </span>
              )}
            </p>
          </div>
        </div>
    );
  };

  return (
      <div>
        {userScanInfo && userScanInfo.length > 0 ? (
            <div className="col-sm-12 px-3 py-3 user-preview-details">
              <div className="col-sm-12 px-0 pt-2 pb-4">
                {UserScanPreviewData(userScanInfo[0])}
              </div>
              <div className="col-sm-12 px-0 pt-2 pb-4">
                {footerPreview(userScanInfo[0])}
              </div>
            </div>
        ) : (
            ""
        )}
      </div>
  );
};

export default UserScanPreview;
