import {ButtonComponent} from "@syncfusion/ej2-react-buttons";
import axios from "axios";
import {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../../../redux/store";
import notify from "../../../../services/toasterService";
import {setOpenAccessReqData, setRolesData} from "../../accessmgmtReducer";
import {addAccessReqEndPoint, getAccessMgmtEndPoint} from "../../utils";
// import userAvatar from '../../../icons/common/user-avatar.png'

const ApproveOpenRequest = (props: any) => {
    const {setAddScreen} = props;

    const dispatch = useDispatch();
    const [userName, setUserName] = useState("");
    const [password, setPassword] = useState("");
    const [role, setRole] = useState("");
    const [requestDisabled, setRequestDisabled] = useState(true);


    const selectedRowData = useSelector(
        (state: RootState) => state.gridReducer.selectedRowData
    );

    console.log(selectedRowData, 'fsdf')
    const roleData = useSelector(
        (state: RootState) => state.accessMgmtReducer.rolesData
    );

    useEffect(() => {
        const getEndPointUrl = getAccessMgmtEndPoint('Roles');
        axios
            .post(getEndPointUrl, {}, {})
            .then((res: { data: any }) => {
                dispatch(setRolesData(res.data));
            })
    }, []);

    console.log(selectedRowData);

    const [errors, setErrors] = useState({
        userNameError: "",
        passwordError: "",
        //roleError: "",
    });

    const handleUserName = (e: any) => {
        setUserName(e.target.value);
        validate();
    };

    const handlePassword = (e: any) => {
        setPassword(e.target.value);
        validate();
    };

    const handleRole = (e: any) => {
        setRole(e.target.value);
        validate();
    };

    /* Create Object to insert the form */
    const body = {
        userName: userName,
        password: password,
        roleId: selectedRowData && selectedRowData[0].roleId,
        accountNumber: selectedRowData && selectedRowData[0].accountNumber,
        storeEmail: selectedRowData && selectedRowData[0].storeEmail,
        phoneNumber: selectedRowData && selectedRowData[0].phoneNumber,
        id: selectedRowData && selectedRowData[0].id,
        storeName: selectedRowData && selectedRowData[0].storeName,
    };

    const handleApproval = () => {
        if (validate()) {
            const addEndPoint = addAccessReqEndPoint("accessManagement");
            const getEndPoint = getAccessMgmtEndPoint("Open");
            approveReqWS(addEndPoint, getEndPoint);
        }
    };

    const approveReqWS = async (addEndPoint: string, getEndPoint: string) => {
        const result: any = await axios.post(addEndPoint, body);
        if (result && result.data) {
            if (result.data.status === 201) {
                notify(result.data.message, "error");
            } else {
                setAddScreen(false);
                notify("Request approved successfully", "success");
                await axios.get(getEndPoint).then(
                    (res) => {
                        dispatch(setOpenAccessReqData(res.data));
                    },
                    (err) => {
                        notify("Failed to fetch data", "error");
                    }
                );
            }

        } else {
            notify("Failed to approve request", "error");
        }
    };

    const handlePopUpClose = () => {
        setAddScreen(false);
        notify("Information! Changes discarded.", "info");
    };

    const validate = (field?: any, value?: any) => {
        let userNameError = "";
        let passwordError = "";
        //let roleError = "";

        setErrors({userNameError: "", passwordError: ""});

        if (userName === "") {
            userNameError = "Username is required";
        }

        if (password === "") {
            passwordError = "Password is required";
        }

        // if (role === "") {
        //   roleError = "Role is required";
        // }

        if (userNameError || passwordError) {
            setErrors({userNameError, passwordError});
            setRequestDisabled(true);
            return false;
        } else {
            setRequestDisabled(false);
        }
        return true;
    };

    return (
        <div>
            <div className="col-sm-12 ">
                <div className="col-sm-6 pl-0 mt-4">
                    {selectedRowData && selectedRowData.length > 0 ? (
                        <>
                            <div className="col-sm-12 px-0 row m-0">
                                <div className="col-sm-6 p-0">
                                    <p className="add-accessReq-Key">Account Number</p>
                                </div>
                                <div className="col-sm-6 p-0">
                                    <p className="add-accessReq-value">
                                        {selectedRowData[0].accountNumber}
                                    </p>
                                </div>
                            </div>

                            <div className="col-sm-12 px-0 mt-4 row m-0">
                                <div className="col-sm-6 p-0">
                                    <p className="add-accessReq-Key">Email</p>
                                </div>
                                <div className="col-sm-6 p-0">
                                    <p className="add-accessReq-value">
                                        {selectedRowData[0].storeEmail}
                                    </p>
                                </div>
                            </div>

                            <div className="col-sm-12 px-0 mt-4 row m-0">
                                <div className="col-sm-6 p-0">
                                    <p className="add-accessReq-Key">Phone</p>
                                </div>
                                <div className="col-sm-6 p-0">
                                    <p className="add-accessReq-value">{selectedRowData[0].phoneNumber}</p>
                                </div>
                            </div>

                            <div className="col-sm-12 px-0 mt-4 row m-0">
                                <div className="col-sm-6 p-0">
                                    <p className="add-accessReq-Key">Location</p>
                                </div>
                                <div className="col-sm-6 p-0">
                                    <p className="add-accessReq-value">{selectedRowData[0].storeName}</p>
                                </div>
                            </div>
                        </>
                    ) : (
                        ""
                    )}
                </div>

                <div className="col-sm-6 pl-0">
                    <div className="col-sm-12 mt-0 ">
                        <label className="label-title mb-0">Username</label>
                        <input
                            type="text"
                            className="form-control"
                            onChange={(e) => handleUserName(e)}
                            onBlur={(e) => handleUserName(e)}
                            onKeyUp={(e) => handleUserName(e)}
                        />
                        {userName === "" ? (
                            <span className="text-danger">{errors.userNameError}</span>
                        ) : (
                            ""
                        )}
                    </div>

                    <div className="col-sm-12 mt-2">
                        <label className="label-title mb-0">Password</label>
                        <input
                            type="text"
                            className="form-control"
                            onChange={(e) => handlePassword(e)}
                            onBlur={(e) => handlePassword(e)}
                            onKeyUp={(e) => handlePassword(e)}
                        />
                        {password === "" ? (
                            <span className="text-danger">{errors.passwordError}</span>
                        ) : (
                            ""
                        )}
                    </div>

                    {/* <div className="col-sm-12 mt-2">
            <label className="label-title mb-0">Assign Role</label>
            <select
              className="form-control storeNumber"
              onChange={(e) => handleRole(e)}
            >
               <option value="">Select</option>
               {roleData?.map((st) => (
                            <option value={st.id}>
                              {st.roleName}
                            </option>
                          ))}
            </select>
            {role === "" ? (
              <span className="text-danger">{errors.roleError}</span>
            ) : (
              ""
            )}
          </div> */}
                </div>
            </div>

            <div className="col-sm-12 px-0 my-5">
                <ButtonComponent
                    cssClass="eg-btn-primary float-right"
                    disabled={requestDisabled}
                    onClick={() => handleApproval()}
                >
                    Approve
                </ButtonComponent>
                <ButtonComponent
                    cssClass="eg-btn-primary-link e-flat mt-3"
                    onClick={() => handlePopUpClose()}
                >
                    Cancel
                </ButtonComponent>
            </div>
        </div>
    );
};
export default ApproveOpenRequest;
