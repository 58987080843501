import HttpService from '../http-service/http-service';
import { API_PATH, getApiBaseUrl } from '../../api-paths';

export interface Pallet {
    createdDate: number;
    lastModifiedDate: number;
    id: number;
    palletId: string;
    currentQuantity: number;
    closedOn: number;
    warehouseLocation: string;
    isClosed: boolean;
    labelPrefix: string;
    palletLocation: string;
    isBoxPallet: boolean;
    invoiceRaised: boolean;
    deleted: boolean;
    active: boolean;
    closedBoxes?: number | null;
    closedBy: number;
    bins: number;
    boxes: number;
    items: number;
}

export interface OutBoundBox {
    createdDate: number;
    lastModifiedDate: number;
    id: number;
    outboundBoxId: string;
    currentQuantity: number;
    closedOn: number;
    isClosed: boolean;
    warehouseLocation: string;
    labelPrefix: string;
    palletLocation: string;
    pallet: Pallet;
    deleted: boolean;
    active: boolean;
    box: boolean;
    closedBy: number;
    boxNumberOnPallet: number;
}

export interface Bin {
    createdDate: number;
    lastModifiedDate: number;
    id: number;
    binId: string;
    reclaimCenter: string;
    scanGroup: string;
    scanStation: string;
    currentQuantity: number;
    closedOn: number;
    isClosed: boolean;
    warehouseLocation: string;
    labelPrefix: string;
    palletLocation: string;
    pallet: Pallet;
    active: boolean;
    box: boolean;
    deleted: boolean;
    closedBy: number;
    binNumberOnPallet: number;
}

export interface LabelPrintRequest {
    query: string;
    reclaimCenter: string;
}

export default class LabelPrintService {
    public async closedOutBoundBoxes(
        query: LabelPrintRequest
    ): Promise<OutBoundBox[]> {
        const url = getApiBaseUrl() + API_PATH.CLOSED_OUTBOUND_BOXES;

        return HttpService.post<any>(url, query)
            .then((response) => {
                return response.data?.data?.content;
            })
            .catch(() => {
                return [];
            });
    }

    public async closedPallets(query: LabelPrintRequest): Promise<Pallet[]> {
        const url = getApiBaseUrl() + API_PATH.CLOSED_PALLETS;
        return HttpService.post<any>(url, query)
            .then((response) => {
                return response.data?.data?.content;
            })
            .catch(() => {
                return [];
            });
    }

    public async closedBins(query: LabelPrintRequest): Promise<Bin[]> {
        const url = getApiBaseUrl() + API_PATH.CLOSED_BINS;
        return HttpService.post<any>(url, query)
            .then((response) => {
                return response.data?.data?.content;
            })
            .catch(() => {
                return [];
            });
    }
}
