import {
    TabComponent,
    TabItemDirective,
    TabItemsDirective,
} from '@syncfusion/ej2-react-navigations';
import axios from 'axios';
import moment from 'moment';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import ScanHistory from './history/history';
import ScanScreen from './Scan';
import './scanroot.scss';
import {
    setIsBoxClosed,
    setIsOutboundBox,
    setItemDetails,
    setOutboundBinDetails,
    setOutboundBoxDetails,
    setScanHistoryData,
    setScanHistoryDbCount,
    setScanSessionItemDetails,
    setUserScanQty,
} from './scanReducer';
import { getEndpoint, getListofValues, getScanEndPoint } from './utils';
import { LabelPrint } from './label-print/label-print';
import printerStatusIcon from '../../icons/printer-status.png';
import { PrintManager } from '../printer/printermanager';
import { EndPoints } from './constants';

const parentTab = [
    { text: 'Scan' },
    { text: 'History' },
    { text: 'Label Print' },
];

const ScanRoot = () => {
    // const [HistoryData, setHistoryData] = useState([]);
    const [printerStatus, setPrinterStatus] = useState<boolean>(true);
    const [refreshPrintLabelData, setRefreshPrintLabelData] =
        useState<boolean>(false);
    const [scannedItemsList, setscannedItemsList] = useState<any[]>([]);
    const setScanSettings = useSelector(
        (state: RootState) => state.scanReducer.scanSettings
    );
    const getScanSessionItemDetails = useSelector(
        (state: RootState) => state.scanReducer.scanSessionItemDetails
    );
    const getScannedSessionItemsCount = useSelector(
        (state: RootState) => state.scanReducer.scannedSessionItemsCount
    );

    const dispatch = useDispatch();

    const tabChange = (e: any) => {
        if (e.selectingItem.innerText === 'History') {
            getHistoryData();
            getUserScanQty();
            setRefreshPrintLabelData(false);
        } else if (e.selectingItem.innerText === 'Scan') {
            getScanSessionData();
            getUserScanQty();
            setRefreshPrintLabelData(false);
        } else if (e.selectingItem.innerText === 'Label Print') {
            setRefreshPrintLabelData(true);
        }
    };

    const getScanSessionData = async (startDate1?: any, endDate1?: any) => {
        const listItems = getScanSessionItemDetails?.payload?.length;
        const startDate = moment().tz('America/New_York');
        startDate.add(-1, 'day');
        startDate.set('hour', 23);
        startDate.set('minute', parseInt('00', 8));
        startDate.set('second', parseInt('00', 8));
        const requestPayload = {
            filter: {
                scanStation: setScanSettings.payload.scanStation,
                reclaimCenter: setScanSettings.payload.reclaimCenter,
                startDate: startDate.format('yyyy-MM-DD HH:mm:ss.SSS'),
            },
            pagination: {
                page: 0,
                size: listItems ? listItems : 10,
            },
        };

        const endPointURL = getScanEndPoint('History');
        await axios.post(endPointURL, requestPayload).then((res: any) => {
            dispatch(setOutboundBoxDetails({}));
            dispatch(setOutboundBinDetails({}));
            const scanList = res.data.data.content;
            dispatch(setItemDetails({ data: res.data.data.content[0] }));
            if (res?.data?.data?.content[0]?.outboundBox) {
                dispatch(
                    setOutboundBoxDetails(res.data.data.content[0].outboundBox)
                );
                dispatch(setIsOutboundBox(true));
                dispatch(
                    setIsBoxClosed(
                        res.data.data.content[0].outboundBox.isClosed
                    )
                );
            }
            // else {
            //     dispatch(setOutboundBoxDetails(undefined));
            // }

            if (res?.data?.data?.content[0]?.scanBin) {
                dispatch(
                    setOutboundBinDetails(res.data.data.content[0]?.scanBin)
                );
                dispatch(setIsOutboundBox(false));
                dispatch(
                    setIsBoxClosed(res.data.data.content[0].scanBin.isClosed)
                );
            } else {
                dispatch(setOutboundBinDetails(undefined));
            }
            dispatch(setScanSessionItemDetails(scanList));
        });
    };

    const getHistoryData = async (startDate1?: any, endDate1?: any) => {
        let fromDate;
        let toDate;
        const startDate = startDate1
            ? moment(startDate1)
            : moment().tz('America/New_York');
        let endDate = null;
        startDate.add(-1, 'day');

        startDate.set('hour', 23);

        startDate.set('minute', parseInt('00', 8));

        startDate.set('second', parseInt('00', 8));
        if (!endDate1) {
            endDate = endDate1
                ? moment(endDate1)
                : moment().tz('America/New_York');

            endDate.set('hour', 22);

            endDate.set('minute', 59);

            endDate.set('second', 59);
        }
        const endPointURL = getScanEndPoint('History');
        const body = {
            //startDate: fromDate,
            //endDate: toDate,
            filter: {
                //unitCost: 0.01,
                // scanGroup: setScanSettings.payload.scangroup
                //   ? setScanSettings.payload.scangroup
                //   : "",
                scanStation: setScanSettings.payload.scanStation
                    ? setScanSettings.payload.scanStation
                    : '',
                reclaimCenter: setScanSettings.payload.reclaimCenter
                    ? setScanSettings.payload.reclaimCenter
                    : '',
                startDate: startDate.format('yyyy-MM-DD HH:mm:ss.SSS'),
                endDate: endDate?.format('yyyy-MM-DD HH:mm:ss.SSS'),
            },
            // pagination: {
            //     page: 0,
            //     size: 1000,
            // },
        };
        if (!endDate1) {
            delete body.filter.endDate;
        }
        let TabData: any;
        let excelHistoryData: any;
        await axios
            .post(endPointURL, body)
            .then((res) => {
                TabData = [];
                TabData = res.data;
                // setHistoryData([]);
                // setHistoryData(TabData.data.content);
                dispatch(setScanHistoryData([]));
                dispatch(setScanHistoryData(TabData.data.content));
                dispatch(setScanHistoryDbCount(TabData.data.totalElements));
                var excelHistoryData = TabData.data.content;
            })
            .catch((err) => err);
    };

    const getUserScanQty = () => {
        const endpoint: any = getEndpoint(EndPoints.USERSACNQTY);
        const result = getListofValues(endpoint);
        result.then((data: any) => {
            console.log(data);
            //setUserScanQty(data.data.data);
            dispatch(setUserScanQty(data.data.data));
        });
    };

    const getLabelPrintContent = () => {
        return <LabelPrint refreshPrintLabelData={refreshPrintLabelData} />;
    };

    useEffect(() => {
        PrintManager.start()
            .then(() => {
                if (!PrintManager.webSocketStatus()) {
                    setPrinterStatus(false);
                }
            })
            .catch(() => {
                setPrinterStatus(false);
            });
    }, []);
    return (
        <div className="col-sm-12 col-md-12 col-lg-12 parent-tab-width">
            {!printerStatus && (
                <div className="scan-root-printer-status float-right">
                    <img
                        alt="keyboard"
                        className="keyboard-icon"
                        src={printerStatusIcon}
                    />
                    <span>Error! Cannot connect to printer.</span>
                </div>
            )}

            <div className="control-pane">
                <div className="control-section tab-control-section">
                    <TabComponent
                        heightAdjustMode="Auto"
                        cssClass="orientation-tab ll-custom-tab"
                        selecting={tabChange}
                    >
                        <TabItemsDirective>
                            <TabItemDirective
                                header={parentTab[0]}
                                content={ScanScreen}
                            />
                            <TabItemDirective
                                header={parentTab[1]}
                                content={ScanHistory}
                            />
                            <TabItemDirective
                                header={parentTab[2]}
                                content={getLabelPrintContent}
                            />
                        </TabItemsDirective>
                    </TabComponent>
                </div>
            </div>
        </div>
    );
};

export default ScanRoot;
