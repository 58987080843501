import { useEffect, useState } from 'react';
import { ButtonComponent } from '@syncfusion/ej2-react-buttons';
import { TextBoxComponent } from '@syncfusion/ej2-react-inputs';
import ContentHeader from '../../../common/ContentHeader/ContentHeader';
import Divider from '../../../common/divider/Divider';
import './ManualEntryForm.scss';
import { addManualEntryEndPoint } from '../../utils';
import axios from 'axios';
import {
    setIsInvoiceUpdated,
    setIsManualEntryFormClear,
    setManualEntriesList,
} from '../../invoiceReducer';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../redux/store';

const ManualEntryForm = (props: any) => {
    const dispatch = useDispatch();
    const [description, setDescription] = useState<string>('');
    const [numOfPallets, setNumOfPallets] = useState<string>('');
    const [numOfBins, setNumOfBins] = useState<string>('');
    const [numOfBoxes, setNumOfBoxes] = useState<string>('');
    const [numOfItems, setNumOfItems] = useState<string>('');
    const [weight, setWeight] = useState<string>('');

    console.log('BOL >>>', props.PickedManualEntryDataForEdit);
    const isFormClear = useSelector(
        (store: RootState) => store.invoiceReducer.isManualEntryFormClear
    );
    const handleDescriptionChange = (value: string) => {
        setDescription(value);
    };
    const handleNumOfPalletsChange = (value: string) => {
        setNumOfPallets(value);
    };
    const handleNumOfBinsChange = (value: string) => {
        setNumOfBins(value);
    };
    const handleNumOfBoxesChange = (value: string) => {
        setNumOfBoxes(value);
    };
    const handleNumOfItemsChange = (value: string) => {
        setNumOfItems(value);
    };
    const handleWeightChange = (value: string) => {
        setWeight(value);
    };
    const handleAddItem = () => {
        console.log('desc', description);
        console.log('noofPallets', numOfPallets);
        console.log('noofBins', numOfBins);
        console.log('noofBoxes', numOfBoxes);
        console.log('noofItems', numOfItems);
        console.log('weight', weight);
        const body = {
            active: true,
            bin: parseInt(numOfBins),
            box: parseInt(numOfBoxes),
            deleted: false,
            description: description,
            pallet: parseInt(numOfPallets),
            weight: weight,
        };
        const endpoint = addManualEntryEndPoint();
        //const getEndpoint = getEndPoint(tabName);
        //handleMasterDataChange(endpoint, getEndpoint)
        handleADDManualItemData(endpoint, body);
    };
    const handleADDManualItemData = async (endpoint: string, body: any) => {
        const result = await axios.post(endpoint, body);
        if (result && result.data) {
            console.log('result', result.data);
            dispatch(setManualEntriesList([result.data]));
        } else {
            alert('Error');
        }
        // const getresult = await axios.get(getEndpoint);
        // dispatch(setCurrentContact(getresult.data));
    };
    useEffect(() => {
        if (isFormClear) {
            dispatch(setIsManualEntryFormClear(false));
            setDescription('');
            setNumOfBins('');
            setNumOfBoxes('');
            setNumOfItems('');
            setWeight('');
            setNumOfPallets('');
        }
    }, [isFormClear]);
    return (
        <div>
            <ContentHeader value="Manual Entry Item" />
            <Divider />
            <div className="miscSalvage-form">
                <div className="miscSalvage-form-column">
                    <div className="des-txt">
                        <p>Description</p>
                        <TextBoxComponent
                            value={
                                props.PickedManualEntryDataForEdit[0]
                                    ?.description
                                    ? props.PickedManualEntryDataForEdit[0]
                                          ?.description
                                    : description
                            }
                            input={(e: any) =>
                                handleDescriptionChange(e.event.target.value)
                            }
                            className="pl-2 miscSalvage-form-width"
                        />
                    </div>
                    <div className="des-txt">
                        <p>
                            # of Pallets<b className="requiredStar">*</b>
                        </p>
                        <TextBoxComponent
                            type={'number'}
                            value={
                                props.PickedManualEntryDataForEdit[0]?.pallet
                                    ? props.PickedManualEntryDataForEdit[0]
                                          ?.pallet
                                    : numOfPallets
                            }
                            input={(e: any) =>
                                handleNumOfPalletsChange(e.event.target.value)
                            }
                            className="pl-2"
                        />
                    </div>
                    <div className="des-txt">
                        <p># of Bins</p>
                        <TextBoxComponent
                            type={'number'}
                            value={
                                props.PickedManualEntryDataForEdit[0]?.bin
                                    ? props.PickedManualEntryDataForEdit[0]?.bin
                                    : numOfBins
                            }
                            input={(e: any) =>
                                handleNumOfBinsChange(e.event.target.value)
                            }
                            className="pl-2"
                        />
                    </div>
                    <div className="des-txt">
                        <p># of Boxes</p>
                        <TextBoxComponent
                            type={'number'}
                            value={
                                props.PickedManualEntryDataForEdit[0]?.box
                                    ? props.PickedManualEntryDataForEdit[0]?.box
                                    : numOfBoxes
                            }
                            input={(e: any) =>
                                handleNumOfBoxesChange(e.event.target.value)
                            }
                            className="pl-2"
                        />
                    </div>
                    {/* <div className="miscSalvage-form-field">
            <p># of Items</p>
            <TextBoxComponent value={numOfItems}
             input={(e: any) => handleNumOfItemsChange(e.event.target.value)} />
          </div> */}
                </div>
                <div className="miscSalvage-form-column weightdiv">
                    <div className="des-txt">
                        <p>Weight</p>
                        <TextBoxComponent
                            type={'number'}
                            value={
                                props.PickedManualEntryDataForEdit[0]?.weight
                                    ? props.PickedManualEntryDataForEdit[0]
                                          ?.weight
                                    : weight
                            }
                            input={(e: any) =>
                                handleWeightChange(e.event.target.value)
                            }
                            className="pl-2"
                        />
                    </div>
                </div>
            </div>
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    marginRight: '5rem',
                }}
            >
                {/* <ButtonComponent style={{ margin:"5px" }}>CANCEL</ButtonComponent> */}
                <ButtonComponent
                    style={{
                        color: 'white',
                        backgroundColor: '#636799',
                        margin: '5px',
                        padding: '10px 30px',
                    }}
                    onClick={() => {
                        props.setData([
                            {
                                description,
                                pallet: parseInt(numOfPallets),
                                bin: parseInt(numOfBins),
                                box: parseInt(numOfBoxes),
                                weight: weight,
                            },
                        ]);
                        dispatch(setIsInvoiceUpdated(true));
                        props.handleAddManualItem({
                            description: description
                                ? description
                                : props.PickedManualEntryDataForEdit[0]
                                      ?.description,
                            pallet: parseInt(numOfPallets)
                                ? parseInt(numOfPallets)
                                : props.PickedManualEntryDataForEdit[0]?.pallet,
                            bin: parseInt(numOfBins)
                                ? parseInt(numOfBins)
                                : props.PickedManualEntryDataForEdit[0]?.bin,
                            box: parseInt(numOfBoxes)
                                ? parseInt(numOfBoxes)
                                : props.PickedManualEntryDataForEdit[0]?.box,
                            weight: weight
                                ? weight
                                : props.PickedManualEntryDataForEdit[0]?.weight,
                        });
                    }}
                >
                    ADD ITEM
                </ButtonComponent>
            </div>
        </div>
    );
};

export default ManualEntryForm;
