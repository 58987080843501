import { ButtonComponent } from "@syncfusion/ej2-react-buttons";
import axios from "axios";
import { useState } from "react";
import { addMasterDataEndPoint, getEndPoint } from "./utils";
import userIcon from "../../assets/userIcon.png";
import { setCurrentContact } from "./contactReducer";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { ContactType } from "./constants";

const VendorAddScreen = (props: any) => {
  const { setAddScreen } = props;
  const [showAltAddressInfo, setShowAltAddressInfo] = useState(false);
  const [selectedImage, setSelectedImage] = useState();
  const dispatch = useDispatch();
  const [vendorNumber, setVendorNumber] = useState("");
  const [apVendorNumber, setAPVendorNumber] = useState("");
  const [vendorName, setVendorName] = useState("");
  const [vendorEmail, setVendorEmail] = useState("");
  const [vendorPhone, setVendorPhone] = useState("");
  const [vendorAltPhone, setVendorAltPhone] = useState("");
  const [vendorFax, setVendorFax] = useState("");
  const [vendorEndaDate, setVendorEndaDate] = useState("");
  const [vendorAddressStreet, setVendorAddressStreet] = useState("");
  const [vendorAddressCity, setVendorAddressCity] = useState("");
  const [vendorAddressState, setVendorAddressState] = useState("");
  const [vendorAddressZip, setVendorAddressZip] = useState("");
  const [vendorAddressCountry, setVendorAddressCountry] = useState("");
  const [vendorLanguage, setVendorLanguage] = useState("");
  const [vendorCurrencyCode, setVendorCurrencyCode] = useState("");
  const [vendorTimeZone, setVendorTimeZone] = useState("");
  const currencyData = useSelector(
    (state: RootState) => state.contactReducer?.currencyData
  );
  const timezoneData = useSelector(
    (state: RootState) => state.contactReducer?.timezoneData
  );
  const languageData = useSelector(
    (state: RootState) => state.contactReducer?.laungageData
  );
  const countryInfo = useSelector(
    (state: RootState) => state.contactReducer?.countryData
  );
  const statesInfo = useSelector(
    (state: RootState) => state.contactReducer?.statesData
  );
  const imageChange = (e: any) => {
    if (e.target.files && e.target.files.length > 0) {
      setSelectedImage(e.target.files[0]);
    }
  };
  const handleAltAdress = () => {
    setShowAltAddressInfo(true);
  };
  const handleAltAdressClose = () => {
    setShowAltAddressInfo(false);
  };

  const handleVendorNumber = (e: any) => {
    setVendorNumber(e.target.value);
  };

  const handleAPVendorNumber = (e: any) => {
    setAPVendorNumber(e.target.value);
  };
  const handleVendorName = (e: any) => {
    setVendorName(e.target.value);
  };
  const handleVendorEmail = (e: any) => {
    setVendorEmail(e.target.value);
  };
  const handleVendorPhone = (e: any) => {
    setVendorPhone(e.target.value);
  };
  const handleVendorAltPhone = (e: any) => {
    setVendorAltPhone(e.target.value);
  };
  const handleVendorFax = (e: any) => {
    setVendorFax(e.target.value);
  };
  const handleVendorAddressStreet = (e: any) => {
    setVendorAddressStreet(e.target.value);
  };
  const handleVendorAddressCity = (e: any) => {
    setVendorAddressCity(e.target.value);
  };
  const handleVendorAddressState = (e: any) => {
    setVendorAddressState(e.target.value);
  };
  const handleVendorAddressZip = (e: any) => {
    setVendorAddressZip(e.target.value);
  };
  const handleVendorAddressCountry = (e: any) => {
    setVendorAddressCountry(e.target.value);
  };
  const handleVendorLanguage = (e: any) => {
    setVendorLanguage(e.target.value);
  };
  const handleVendorCurrencyCode = (e: any) => {
    setVendorCurrencyCode(e.target.value);
  };
  const handleVendorTimezone = (e: any) => {
    setVendorTimeZone(e.target.value);
  };

  const body = {
    active: true,
    address: [
      {
        addressLine1: "string",
        addressLine2: "string",
        alternateAddress: true,
        city: vendorAddressCity,
        country: vendorAddressCountry,
        deleted: false,
        state: vendorAddressState,
        street: vendorAddressStreet,
        zipcode: vendorAddressZip,
      },
    ],
    alternatePhoneNumber: vendorAltPhone,
    apVendorNumber: apVendorNumber,
    attnTo: "",
    // "client": {
    //   "clientId": "string",
    //   "clientName": "string",
    //   "id": 0
    // },
    // "companyCode": {
    //   "active": true,
    //   "address": [
    //     {
    //       "addressLine1": "string",
    //       "addressLine2": "string",
    //       "alternateAddress": true,
    //       "city": "string",
    //       "country": "string",
    //       "deleted": true,
    //       "id": 0,
    //       "state": "string",
    //       "street": "string",
    //       "zipcode": "string"
    //     }
    //   ],
    //   "alternatePhoneNumber": "string",
    //   "client": {
    //     "clientId": "string",
    //     "clientName": "string",
    //     "id": 0
    //   },
    //   "clientId": 0,
    //   "companyCode": "string",
    //   "companyName": "string",
    //   "currencyCode": "string",
    //   "deleted": true,
    //   "email": "string",
    //   "fax": "string",
    //   "id": 0,
    //   "language": "string",
    //   "notes": "string",
    //   "phoneNumber": "string",
    //   "timezone": "string",
    //   "webUrl": "string"
    // },
    currencyCode: vendorCurrencyCode,
    deleted: false,
    disposition: "COPT",
    email: vendorEmail,
    fax: vendorFax,
    ircVendorNumber: "9999",
    language: vendorLanguage,
    notes: "string",
    phoneNumber: vendorPhone,
    programTypeCode: "DMG",
    purpose: "Bill To",
    recallNumber: "0",
    timezone: vendorTimeZone,
    vendorName: vendorName,
    vendorNumber: vendorNumber,
  };
  let tabName: any = ContactType.VENDORS;
  const handleAddvendor = () => {
    const endpoint = addMasterDataEndPoint(tabName);
    const getEndpoint = getEndPoint(tabName);
    handleMasterDataChange(endpoint, getEndpoint);
  };

  const handleMasterDataChange = async (
    endpoint: string,
    getEndpoint: string
  ) => {
    const result = await axios.post(endpoint, body);
    if (result && result.data) {
      setAddScreen(false);
      alert("Done");
    } else {
      alert("Error");
    }
    const getresult = await axios.get(getEndpoint);
    dispatch(setCurrentContact(getresult.data));
  };
  const handleCancelvendorPopup = () => {
    setAddScreen(false);
  };
  return (
    <div>
      <div className="row">
        <div className="col-sm-2 textAlign-center mt-6rem">
          {/* <input accept="image/*" type="file" onChange={imageChange} /> */}
          <img src={userIcon} className="userAddIcon" />
          {selectedImage && (
            <div className="imagePreviewDiv">
              <img
                src={URL.createObjectURL(selectedImage)}
                className="imagePreview"
                height="100"
                width="100"
              />
            </div>
          )}
        </div>

        <div className="col-sm-5">
          <div className="row ml-0 mr-0 mt-1rem">
            <div className="col-sm-4">
              <span className="lblClass">AP Vendor Number</span>
            </div>
            <div className="col-sm-8">
              <input
                type="text"
                className="form-control"
                onChange={(e) => handleAPVendorNumber(e)}
              />
            </div>
          </div>
          <div className="row ml-0 mr-0 mt-1rem">
            <div className="col-sm-4">
              <span className="lblClass">Vendor Number</span>
            </div>
            <div className="col-sm-8">
              <input
                type="text"
                className="form-control"
                onChange={(e) => handleVendorNumber(e)}
              />
            </div>
          </div>
          <div className="row ml-0 mr-0 mt-1rem">
            <div className="col-sm-4">
              <span className="lblClass">Name</span>
            </div>
            <div className="col-sm-8">
              <input
                type="text"
                className="form-control"
                onChange={(e) => handleVendorName(e)}
              />
            </div>
          </div>
          <div className="row ml-0 mr-0 mt-1rem">
            <div className="col-sm-4">
              <span className="lblClass">Email</span>
            </div>
            <div className="col-sm-8">
              <input
                type="text"
                className="form-control"
                placeholder="someone@email.com"
                onChange={(e) => handleVendorEmail(e)}
              />
            </div>
          </div>
          <div className="row ml-0 mr-0 mt-1rem">
            <div className="col-sm-4">
              <span className="lblClass">Phone</span>
            </div>
            <div className="col-sm-8">
              <input
                type="text"
                className="form-control txtPhone"
                onChange={(e) => handleVendorPhone(e)}
              />
            </div>
          </div>
          <div className="row ml-0 mr-0 mt-1rem">
            <div className="col-sm-4">
              <span className="lblClass">Alt Phone</span>
            </div>
            <div className="col-sm-8">
              <input
                type="text"
                className="form-control txtPhone"
                onChange={(e) => handleVendorAltPhone(e)}
              />
            </div>
          </div>
          <div className="row ml-0 mr-0 mt-1rem">
            <div className="col-sm-4">
              <span className="lblClass">Fax</span>
            </div>
            <div className="col-sm-8">
              <input
                type="text"
                className="form-control"
                onChange={(e) => handleVendorFax(e)}
              />
            </div>
          </div>
          <div className="row ml-0 mr-0 mt-1rem">
            <div className="col-sm-4">
              <span className="lblClass">Language</span>
            </div>
            <div className="col-sm-8">
              <select
                className="form-control"
                onChange={(e) => handleVendorLanguage(e)}
              >
                <option>Select</option>
                {languageData?.map((ld) => (
                  <option value={ld?.isoLongCode}>{ld?.isoLongCode}</option>
                ))}
              </select>
            </div>
          </div>
          <div className="row ml-0 mr-0 mt-1rem">
            <div className="col-sm-4">
              <span className="lblClass">Currency Code</span>
            </div>
            <div className="col-sm-8">
              <select
                className="form-control"
                onChange={(e) => handleVendorCurrencyCode(e)}
              >
                <option>Select</option>
                {currencyData?.map((cc) => (
                  <option value={cc.currency}>{cc.currency}</option>
                ))}
              </select>
            </div>
          </div>
          <div className="row ml-0 mr-0 mt-1rem">
            <div className="col-sm-4">
              <span className="lblClass">Time Zone</span>
            </div>
            <div className="col-sm-8">
              <select
                className="form-control"
                onChange={(e) => handleVendorTimezone(e)}
              >
                <option>Select</option>
                {timezoneData?.map((tz) => (
                  <option value={tz.timezoneName}>
                    {tz.timezoneName}
                    {tz.utc}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div>
            {/* <div className="row ml-0 mr-0 mt-1rem">
              <div className="col-sm-4">
                <span className="lblClass">End Date</span>
              </div>
              <div className="col-sm-8 input-control">
                <input type="date" className="form-control" onChange={(e) => handleVendorEndDate(e)} />
              </div>
            </div> */}
          </div>
        </div>
        <div className="col-sm-5">
          <div className="row ml-0 mr-0 mt-1rem">
            <div className="col-sm-4">
              <span className="lblClass">Address</span>
            </div>
            <div className="col-sm-8">
              <input
                type="text"
                className="form-control"
                placeholder="Street"
                onChange={(e) => handleVendorAddressStreet(e)}
              />
            </div>
          </div>
          <div className="row ml-0 mr-0 mt-1rem">
            <div className="col-sm-4"></div>
            <div className="col-sm-8">
              <input
                type="text"
                className="form-control"
                placeholder="City"
                onChange={(e) => handleVendorAddressCity(e)}
              />
            </div>
          </div>
          <div className="row ml-0 mr-0 mt-1rem">
            <div className="col-sm-4"></div>
            <div className="col-sm-5">
              <select
                className="form-control"
                onChange={(e) => handleVendorAddressState(e)}
              >
                <option value="">Select State</option>
                {statesInfo?.map((cn) => (
                  <option value={cn.stateName}>{cn.stateName}</option>
                ))}
              </select>
            </div>
            <div className="col-sm-3">
              <input
                type="text"
                className="form-control"
                placeholder="ZIP"
                onChange={(e) => handleVendorAddressZip(e)}
              />
            </div>
          </div>
          <div className="row ml-0 mr-0 mt-1rem">
            <div className="col-sm-4"></div>
            <div className="col-sm-8">
              <select
                className="form-control"
                onChange={(e) => handleVendorAddressCountry(e)}
              >
                <option>Select Country</option>
                {countryInfo?.map((cn) => (
                  <option value={cn.countryName}>{cn.countryName}</option>
                ))}
              </select>
            </div>
          </div>
          {/* <div className="row ml-0 mr-0 mt-1rem">
            <div className="col-sm-6">
            {showAltAddressInfo ?
              <span className="lblClass cursor-pointer" onClick={() => handleAltAdressClose()}><img className="" src={closeIcon} /> <span>Alt Address</span></span>
              :<span className="lblClass cursor-pointer" onClick={() => handleAltAdress()}><img className="addIconImgAltAddress" src={addIcon} /> <span>Alt Address</span></span>}
            </div>
          </div> */}

          {showAltAddressInfo ? (
            <div className="row ml-0 mr-0 mt-1rem">
              <div className="col-sm-4"></div>
              <div className="col-sm-8">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Street"
                />
                <div className="row ml-0 mr-0 mt-1rem">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="City"
                  />
                </div>
                <div className="row ml-0 mr-0 mt-1rem">
                  <div className="col-sm-7 pl-0">
                    <select className="form-control">
                      <option value="">Select State</option>
                      {statesInfo?.map((cn) => (
                        <option value={cn.stateName}>{cn.stateName}</option>
                      ))}
                    </select>
                  </div>
                  <div className="col-sm-5 pr-0">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="ZIP"
                    />
                  </div>
                </div>
                <div className="row ml-0 mr-0 mt-1rem">
                  <select className="form-control">
                    <option>Select Country</option>
                    {countryInfo?.map((cn) => (
                      <option value={cn.countryName}>{cn.countryName}</option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
          ) : (
            <div></div>
          )}
        </div>
      </div>
      <div className="row mt-2rem">
        <ButtonComponent
          cssClass="eg-btn-primary float-right"
          onClick={() => handleAddvendor()}
        >
          ADD VENDOR
        </ButtonComponent>
        <ButtonComponent
          cssClass="eg-btn-secondary float-right mr-4"
          onClick={handleCancelvendorPopup}
        >
          CANCEL
        </ButtonComponent>
      </div>
    </div>
  );
};
export default VendorAddScreen;
