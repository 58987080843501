import SideBarWithMenu from "./components/sidebar/SideBarWithMenu";
import "./App.scss";
import "./styles/global.scss";
import { Route, Switch } from "react-router-dom";
import Settings from "./components/settings/Settings";
import Home from "./components/home/Home";
import Invoices from "./components/invoices/Invoices";
import InvoicesHome from "./components/invoices/InvoicesHome";
import InvoiceDetails from "./components/invoices/InvoiceDetails/InvoiceDetails";
import MasterData from "./components/contactTabs/MasterData";
import ContactScreenWithTabs from "./components/contactTabs/ContactScreenWithTabs";
import LlAdmin from "./components/lladmin/lladmin";
import { useDispatch } from "react-redux";
import AccessMgmt from "./components/accessMgmt/AccessMgmt";
import ScanRoot from "./components/scan/scanroot";
import { Toaster } from "react-hot-toast";
//import { ToastProvider, useToasts } from 'react-toast-notifications';
//import { ToastContainer } from "react-toastify";
import NewCreditMemoScreen from "./components/invoices/CreditMemos/NewCreditMemoScreen";
import Reports, { OldReports, NewReports } from "./components/reports/Reports";
import { useState } from "react";
import ReportDesigner from "./report-designer";
import ReportViewer from "./report-viewer";

function App() {
  const dispatch = useDispatch();

  const [token, setToken] = useState("");

  return (
    <div className="App">
      {/* <ToastProvider> */}
      {/* <ToastContainer /> */}
      <Toaster />
      <SideBarWithMenu>
        <Switch>
          <Route exact path="/settings" component={Settings} />
          <Route exact path="/invoices" component={InvoicesHome} />
          <Route exact path="/LLAdmin" component={LlAdmin} />
          <Route exact path="/invoices/:invoicetype" component={Invoices} />
          <Route
            exact
            path="/invoices/:invoicetype/newcreditmemo"
            component={NewCreditMemoScreen}
          />
          <Route
            exact
            path="/invoices/:invoicetype/Details"
            component={InvoiceDetails}
          />
          <Route exact path="/scan" component={ScanRoot} />
          <Route exact path="/Home" component={Home} />
          <Route exact path="/reports" component={NewReports} />
          <Route exact path="/masterdata" component={MasterData} />
          <Route exact path="/accessManagement" component={AccessMgmt} />
          <Route exact path="/oldReports" component={OldReports} />
          <Route
            exact
            path="/masterData/:masterType"
            component={ContactScreenWithTabs}
          />
          <Route
            exact
            path="/"
            component={Home}
          />
            <Route path="/report-designer" component={ReportDesigner} exact></Route>
            <Route path="/report-viewer" component={ReportViewer} exact></Route>        
        </Switch>
      </SideBarWithMenu>
      {/* </ToastProvider> */}
    </div>
  );
}

export default App;
