export const tabsConfigContactDetails = ["General", "Contact", "Notes"];
export const tabsConfigContactDetailsForDeptAndGLAcc = ["General", "Notes"];
export const tabsConfigContactDetailsForCommodityType = ["General"];
export const tabsConfigContactDetailsForPalletLocation = ["General"];
export const tabsContent = [
  {
    store: "001A",
    name: "Store 1",
    storeType: "Chain",
    active: true,
  },
];
