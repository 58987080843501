import { ButtonComponent } from "@syncfusion/ej2-react-buttons";
import axios from "axios";
import { useState } from "react";
// import { addMasterDataEndPoint, getEndPoint } from './utils';
import { useDispatch } from "react-redux";
import notify from "../../../services/toasterService";
import { setCurrencyData } from "../lladminreducer";
import userAvatar from "../../../icons/common/user-avatar.png";
import { addLlAdminEndPoint, getLlAdminEndPoint } from "../utils";

const AddCurrencyCode = (props: any) => {
  const { setAddScreen } = props;

  const dispatch = useDispatch();
  const [currency, setCurrency] = useState("");
  const [currencyCode, setCurrencode] = useState("");
  const [isoCode, setIsoCode] = useState("");
  const [currencyDisabled, setCurrencyDisabled] = useState(true);
  const [myCurrency, setMyCurrency] = useState("");
  const [myIsoCode, setMyIsoCode] = useState("");
  const [myCurrencyCode, setMyCurrencyCode] = useState("");

  const [errors, setErrors] = useState({
    currencyError: "",
    currencyCodeError: "",
    isoCodeError: "",
  });

  const handleCurrency = (e: any) => {
    setCurrency(e.target.value);
    validate();
  };

  const handleCurrencyCode = (e: any) => {
    setCurrencode(e.target.value);
    validate();
  };

  const handleIsoCode = (e: any) => {
    setIsoCode(e.target.value);
    validate();
  };

  /* Create Object to insert the form */
  const body = {
    alternateCurrencyCode: null,
    currencyValidity: null,
    currency: currency,
    primaryCurrencyCode: currencyCode,
    isoCode: isoCode,
  };

  const handleAddCurrency = () => {
    if (validate()) {
      const addEndPoint = addLlAdminEndPoint("Currency Codes");
      const getEndPoint = getLlAdminEndPoint("Currency Codes");
      addCurrencyWS(addEndPoint, getEndPoint);
    }
  };

  const addCurrencyWS = async (addEndPoint: string, getEndPoint: string) => {
    const result = await axios.post(addEndPoint, body);
    if (result && result.data) {
      setAddScreen(false);
      notify("Currency code added successfully", "success");
      await axios.get(getEndPoint).then(
        (res) => {
          dispatch(setCurrencyData(res.data));
        },
        (err) => {
          notify("Failed to fetch data", "error");
        }
      );
    } else {
      notify("Failed to create currency code", "error");
    }
  };

  const handlePopUpClose = () => {
    setAddScreen(false);
  };

  const validate = (field?: any, value?: any) => {
    let currencyError = "";
    let currencyCodeError = "";
    let isoCodeError = "";

    setErrors({ currencyError: "", currencyCodeError: "", isoCodeError: "" });

    if (currency === "") {
      currencyError = "Required Field";
      setMyCurrency("1px solid red");
    } else {
      setMyCurrency("");
    }

    if (currencyCode === "") {
      currencyCodeError = "Required Field";
      setMyIsoCode("1px solid red");
    } else {
      setMyIsoCode("");
    }

    if (isoCode === "") {
      isoCodeError = "Required Field";
      setMyCurrencyCode("1px solid red");
    } else {
      setMyCurrencyCode("");
    }

    if (currencyError || currencyCodeError || isoCodeError) {
      setErrors({ currencyError, currencyCodeError, isoCodeError });
      setCurrencyDisabled(true);
      return false;
    } else {
      setCurrencyDisabled(false);
    }
    return true;
  };

  return (
    <div>
      <div className="col-sm-12 p-0 d-flex justify-content-center">
        <img src={userAvatar} />
      </div>

      <div className="col-sm-12 row m-0 mt-5">
        <div className="col-sm-6">
          <div className="col-sm-12 mt-3">
            <label className="label-title">Currency</label>
            {currency === "" ? (
              <span className="text-danger" style={{ float: "right" }}>
                {errors.currencyError}
              </span>
            ) : (
              ""
            )}
            <input
              type="text"
              className="formcontrol"
              onChange={(e) => handleCurrency(e)}
              onBlur={(e) => handleCurrency(e)}
              onKeyUp={(e) => handleCurrency(e)}
              style={{ border: myCurrency }}
            />
          </div>

          <div className="col-sm-12 mt-3">
            <label className="label-title">ISO Code</label>
            {isoCode === "" ? (
              <span className="text-danger" style={{ float: "right" }}>
                {errors.isoCodeError}
              </span>
            ) : (
              ""
            )}
            <input
              type="text"
              className="formcontrol"
              onChange={(e) => handleIsoCode(e)}
              onBlur={(e) => handleIsoCode(e)}
              onKeyUp={(e) => handleIsoCode(e)}
              style={{ border: myIsoCode }}
            />
          </div>
        </div>

        <div className="col-sm-6">
          <div className="col-sm-12 mt-3">
            <label className="label-title">Currency Code</label>
            {currencyCode === "" ? (
              <span className="text-danger" style={{ float: "right" }}>
                {errors.currencyCodeError}
              </span>
            ) : (
              ""
            )}
            <input
              type="text"
              className="formcontrol"
              onChange={(e) => handleCurrencyCode(e)}
              onBlur={(e) => handleCurrencyCode(e)}
              onKeyUp={(e) => handleCurrencyCode(e)}
              style={{ border: myCurrencyCode }}
            />
          </div>
        </div>
      </div>

      <div className="col-sm-12 px-0 my-5" style={{ width: "94%" }}>
        <ButtonComponent
          cssClass="eg-btn-primary float-right"
          disabled={currencyDisabled}
          onClick={() => handleAddCurrency()}
        >
          Add Currency
        </ButtonComponent>
        <ButtonComponent
          cssClass="eg-btn-primary-link e-flat mt-3"
          onClick={() => handlePopUpClose()}
        >
          Cancel
        </ButtonComponent>
      </div>
    </div>
  );
};
export default AddCurrencyCode;
