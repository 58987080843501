export enum ScanType {
    SCAN = 'Scan',
    HISTORY = 'History',
}

export const ScanModes = [
    {
        name: 'Trash Mode',
        value: 'trash',
    },
    {
        name: 'Bin Mode',
        value: 'bin',
    },
    {
        name: 'No Credit Mode',
        value: 'nocredit',
    },
    {
        name: 'Audit Mode',
        value: 'audit',
    },
];

export enum DispositionType {
    SCANITEM = 'SCAN ITEM',
    TRASH = 'ALERT DESTROY',
    FOOD = 'FOOD',
    HBA = 'HBA/GM',
}

export const itemDetails = {
    upc: '0978922',
    qty: 2,
};
export const scanTabs = ['Scan', 'History'];

export enum EndPoints {
    SCANITEM = 'SCANITEM',
    OPENINBOUND = 'OPEN INBOUND',
    CLOSEOUTBOUND = 'CLOSE OUTBOUND',
    OUTBOUNDLIST = 'OUTBOUND LIST',
    PALLETLIST = 'PALLET LIST',
    CLOSEPALLET = 'CLOSE PALLET',
    STORELIST = 'STORE LIST',
    RECLAIMCENTERSLIST = 'RECLAIM CENTERSLIST',
    THIRTEENDIGIT = 'THIRTEEN DIGIT',
    UNLABELED = 'UNLABELED',
    NOTFOUND = 'NOTFOUND',
    REASONCODES = 'REASONCODES',
    OUTBOUNDBINSLIST = 'OUTBOUNDBINS LIST',
    CLOSEOUTBOUNDBINS = 'CLOSE OUTBOUNDBINS',
    UPDATESCANITEM = 'UPDATE SCANITEM',
    SCANSETTINGS = 'SCANSETTINGS',
    DELETEITEM = 'DELETE ITEM',
    ACCESSSUPERVISOR = 'ACCESS SUPERVISOR',
    OUTBOUNDAUDIT = 'OUTBOUND AUDIT',
    SUBDISPOSITIONS = 'SUBDISPOSITIONS',
    USERSACNQTY = 'USERSACNQTY',
}
