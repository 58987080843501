import * as JSPM from 'jsprintmanager';
import { getLocalStorageItems, setLocalStorageItems } from '../scan/utils';
import ServiceHandler from '../../services/service-handler';
import { ZebraZPLLabel } from './zebralabel';
import moment from 'moment';
import { API_PATH, getApiBaseUrl } from '../../api-paths';

export type LabelType = 'box' | 'pallet' | 'bin';

export class PrintManager {
    public static start(): Promise<void> {
        JSPM.JSPrintManager.license_url =
            getApiBaseUrl() + API_PATH.PRINTER_SETTINGS_JSPM;
        JSPM.JSPrintManager.auto_reconnect = true;
        return JSPM.JSPrintManager.start();
    }

    public static webSocketStatus(): boolean {
        return JSPM.JSPrintManager.websocket_status === JSPM.WSStatus.Open;
    }

    public static printerName(type: LabelType): string {
        let localStoragePrinterSettings =
            getLocalStorageItems('printerSettings');
        if (!localStoragePrinterSettings) {
            //Back up in case not there in local storage.
            ServiceHandler.PrinterService.getPrinterSettings(
                getLocalStorageItems('reclaimCenterId'),
                getLocalStorageItems('scanSettings').scanStation
            ).then((printerSettings) => {
                setLocalStorageItems('printerSettings', printerSettings);
                localStoragePrinterSettings = printerSettings;
            });
        }
        if (localStoragePrinterSettings) {
            switch (type) {
                case 'box':
                    return localStoragePrinterSettings.boxLabelPrinterName;
                case 'bin':
                    return localStoragePrinterSettings.boxLabelPrinterName;
                case 'pallet':
                    return localStoragePrinterSettings.palletLabelPrinterName;
                default:
                    return 'default';
            }
        } else {
            return 'default';
        }
    }

    public static printLabel(
        payload: any,
        printerType: LabelType,
        loggedInUserId?: any,
        closedBoxes?: any
    ) {
        if (!PrintManager.webSocketStatus()) {
            return;
        }
        const clientPrintJob = new JSPM.ClientPrintJob();
        const printerName = PrintManager.printerName(printerType);
        if (printerType === 'box' || printerType === 'bin') {
            clientPrintJob.printerCommands = ZebraZPLLabel.getBoxLabel(
                PrintManager.getBoxPrintLabelRequest(
                    payload,
                    printerType,
                    loggedInUserId,
                    closedBoxes
                )
            );
        }
        if (printerType === 'pallet') {
            clientPrintJob.printerCommands =
                ZebraZPLLabel.getPalletLabel(payload);
        }
        clientPrintJob.clientPrinter = new JSPM.InstalledPrinter(printerName);
        JSPM.JSPrintManager.getPrinters().then((printersList: any) => {
            if (printersList) {
                const printersArray = printersList as string[];
                const validPrinter = printersArray.includes(printerName);
                if (validPrinter) {
                    clientPrintJob
                        .sendToClient()
                        .then(() => {})
                        .catch();
                }
            }
        });
    }

    public static getBoxPrintLabelRequest(
        payload: any,
        printerType: LabelType,
        loggedInUserId: any,
        closedBoxes: any
    ) {
        let boxId;
        if (printerType === 'box') {
            boxId = payload.outboundBoxId;
        } else if (printerType === 'bin') {
            boxId = payload.binId;
        }
        return {
            scanMode: printerType === 'bin' ? 'Bin' : 'Box',
            boxId: boxId || '',
            date: moment(payload?.lastModifiedDate).format('L'),
            palletLocation: payload?.palletLocation || '',
            currentQuantity: payload?.currentQuantity || '',
            warehouseLocation: payload.warehouseLocation || '',
            closedBoxes: closedBoxes || '',
            userId: loggedInUserId || '',
        };
    }
}
