export const config = Object.freeze({
    Stores: [
        {
            field: 'mainframeCustomerNumber',
            header: 'ACCOUNT #',
            textAlign: 'right',
        },
        {
            field: 'storeName',
            header: 'NAME',
        },
        { field: 'addressInfo', header: 'ADDRESS' },
        {
            field: 'clientGroupInfo',
            header: 'CLIENT GROUP',
        },
        {
            dataType: 'enum',
            field: 'isMobileScanEnabled',
            textAlign: 'center',
            header: 'Mobile Scan Enabeled',
        },
        {
            dataType: 'enum',
            field: 'isActive',
            textAlign: 'center',
            header: 'STORE STATUS',
        },
    ],
    Warehouses: [
        { field: 'warehouseName', header: 'NAME' },
        {
            field: 'addressView',
            header: 'ADDRESS',
        },
        {
            dataType: 'enum',
            field: 'activeForGrid',
            width: '60',
            textAlign: 'center',
            header: 'STATUS',
        },
    ],
    'Reclaim Centers': [
        { field: 'reclaimcenterName', header: 'NAME' },
        {
            field: 'addressView',
            header: 'ADDRESS',
        },
        {
            dataType: 'enum',
            field: 'activeForGrid',
            width: '60',
            textAlign: 'center',
            header: 'STATUS',
        },
    ],
    Carriers: [
        { field: 'carrierName', header: 'NAME' },
        {
            field: 'addressView',
            header: 'ADDRESS',
        },
        {
            field: 'phoneNumber',
            header: 'PHONE',
            width: '60',
            textAlign: 'right',
        },
        {
            dataType: 'enum',
            field: 'activeForGrid',
            width: '50',
            header: 'STATUS',
        },
    ],
    Vendors: [
        { field: 'vendorNumber', header: 'VENDOR #', textAlign: 'right' },
        { field: 'vendorName', header: 'NAME' },
        { field: 'addressInfo', header: 'ADDRESS' },
        { field: 'isHold', header: 'TYPE' },
        {
            dataType: 'enum',
            field: 'isActive',
            header: 'STATUS',
            textAlign: 'center',
        },
    ],
    Customers: [
        { field: 'customerNumber', header: 'CUSTOMER #', textAlign: 'right' },
        {
            field: 'customerName',
            header: 'NAME',
        },
        { field: 'addressView', header: 'ADDRESS' },
        { field: 'customerType', header: 'CUSTOMER TYPE', width: '120' },
        { field: 'prepadidFlag', header: 'prepaid' },
        {
            dataType: 'enum',
            field: 'activeForGrid',
            textAlign: 'left',
            header: 'STATUS',
        },
    ],
    'Partner Settings': [
        { field: 'name', header: 'Name' },
        { field: 'address', header: 'Address' },
        {
            field: 'retailerType',
            header: 'Retailer Type',
        },
        { dataType: 'enum', field: 'active', header: 'STATUS' },
    ],
    'Company Codes': [
        {
            dataType: 'number',
            field: 'id',
            header: 'CODE',
            textAlign: 'right',
            width: '50',
        },
        { field: 'companyName', header: 'NAME' },
        { field: 'address1', header: 'ADDRESS' },
        {
            dataType: 'enum',
            field: 'activeForGrid',
            width: '60',
            textAlign: 'center',
            header: 'STATUS',
        },
    ],
    Departments: [
        { field: 'departmentName', header: 'NAME' },
        {
            field: 'glAccountsView',
            header: 'ACCOUNTS',
        },
        {
            dataType: 'enum',
            field: 'activeForGrid',
            width: '40',
            header: 'STATUS',
        },
    ],
    'GL Accounts': [
        { field: 'glAccountName', header: 'NAME' },
        {
            field: 'glAccountNumber',
            header: 'ACCOUNT #',
        },
        { field: 'departmentsList', header: 'DEPARTMENTS' },
        {
            dataType: 'enum',
            field: 'activeForGrid',
            width: '60',
            header: 'STATUS',
        },
    ],
    'Payment Terms': [
        { field: 'name', header: 'Name' },
        { field: 'accounts', header: 'Accounts' },
        {
            field: 'departments',
            header: 'Departments',
        },
        { dataType: 'enum', field: 'active', header: 'STATUS' },
    ],
    'Fiscal Year Setup': [
        { field: 'week', header: 'WEEK', textAlign: 'center' },
        {
            field: 'period',
            header: 'PERIOD',
            textAlign: 'center',
        },
        { field: 'quarter', header: 'QUARTER', textAlign: 'center' },
        {
            field: 'startDate',
            header: 'START DATE',
            textAlign: 'center',
        },
        { field: 'endDate', header: 'END DATE', textAlign: 'center' },
        {
            dataType: 'enum',
            field: 'activeForFiscal',
            width: '60',
            header: 'STATUS',
        },
    ],
    'Item Catalog': [
        { field: 'upcReference', header: 'UPC', textAlign: 'right' },
        {
            field: 'description',
            header: 'DESCRIPTION',
        },
        { field: 'vendorInfo', header: 'VENDOR' },
        {
            field: 'disposition',
            header: 'UPC DISPOSITION',
        },
        { field: 'warehouseLocation', header: 'WH LOCATION' },
        {
            dataType: 'enum',
            field: 'getActiveForGridItemCatalog',
            header: 'ACTIVE RULE?',
            textAlign: 'center',
        },
    ],
    'Commodity Types': [
        { field: 'code', header: 'CODE' },
        {
            field: 'description',
            header: 'DESCRIPTION',
        },
        { field: 'pricingType', header: 'PRICING TYPE' },
        {
            dataType: 'number',
            field: 'priceCommodity',
            header: 'PRICE',
            textAlign: 'right',
        },
        {
            dataType: 'enum',
            field: 'activeForGrid',
            textAlign: 'center',
            header: 'STATUS',
        },
    ],
    'Rule Exceptions': [
        {
            dataType: 'number',
            field: 'id',
            header: 'RULE ID',
            textAlign: 'right',
        },
        {
            field: 'ruleCode',
            header: 'CODE',
            textAlign: 'right',
        },
        { field: 'startDate', header: 'START DATE' },
        { field: 'endDate', header: 'END DATE' },
        {
            dataType: 'enum',
            field: 'activeForGridRules',
            textAlign: 'center',
            header: 'STATUS',
        },
    ],
    'Bin Exceptions': [
        { field: 'reclaimcenterName', header: 'Reclaim Center' },
        { field: 'dateRange', header: 'Date Range' },
        { field: 'timeRange', header: 'Time Range' },
        { field: 'scanGroup', header: 'SCAN GRP', textAlign: 'right' },
        {
            field: 'activeFlagBinException',
            header: 'STATUS',
            textAlign: 'center',
            dataType: 'enum',
        },
    ],
    'Inventory Catalog': [
        { field: 'palletId', header: 'BAR CODE', textAlign: 'right' },
        {
            field: 'palletDescription',
            header: 'PALLET NAME',
        },
        {
            field: 'whseLocation',
            header: 'WHSE LOCATION',
        },
        // { field: "disposition", header: "DISPOSITION" },
        // { field: "pricingType", header: "PRICING TYPE" },
        // { field: "pricePallet", header: "RATE" },
        {
            field: 'openedOn',
            header: 'OPEN DATE',
            textAlign: 'center',
        },
        { field: 'closedOn', header: 'CLOSE DATE', textAlign: 'center' },
        { field: 'reclaimCenter', header: 'RECLAIM CENTER' },
        {
            dataType: 'enum',
            field: 'invoiceRaisedForGrid',
            header: 'INVOICED',
        },
    ],
    'Pallet Locations': [
        { field: 'palletName', header: 'PALLET NAME' },
        {
            dataType: 'number',
            field: 'position',
            header: 'POSITION',
            textAlign: 'right',
        },
        {
            field: 'scanGroupCode',
            header: 'SCAN GROUP CODE',
            textAlign: 'right',
        },
        {
            field: 'scanGroupDescription',
            header: 'SCAN GROUP NAME',
        },
        { field: 'reclaimCenter', header: 'RECLAIM CENTER' },
        {
            field: 'whLocation',
            header: 'WHSE LOCATION',
        },
        { field: 'pricingType', header: 'PRICING TYPE' },
        {
            dataType: 'number',
            field: 'pricePallet',
            header: 'BOX RATE',
            textAlign: 'right',
        },

        {
            dataType: 'number',
            field: 'binPrice',
            header: 'BIN RATE',
            textAlign: 'right',
        },
        {
            dataType: 'enum',
            field: 'locationEnabledForGrid',
            header: 'LOC ENABLED?',
        },
    ],
    'Box and Bins': [
        { field: 'barCode', header: 'BAR CODE' },
        { field: 'description', header: 'DESC' },
        {
            field: 'type',
            header: 'TYPE',
        },
        { field: 'quantity', header: 'QUANTITY' },
    ],
    Items: [
        { field: 'palletName', header: 'UPC' },
        { field: 'position', header: 'DESC' },
        {
            field: 'pallet',
            header: 'QTY',
        },
    ],
    'Warehouse Locations': [
        { field: 'palletName', header: 'UPC' },
        { field: 'position', header: 'DESC' },
        {
            field: 'pallet',
            header: 'QTY',
        },
    ],
    'Store Dispositions': [
        { field: 'dispositionName', header: 'UPC DISPOSITION' },
        {
            field: 'storeDisposition',
            header: 'STORE DISPOSITION',
        },
        { field: 'voiceText', header: 'VOICE' },
        {
            dataType: 'enum',
            field: 'authorizationFlagGrid',
            header: 'AUTH FLAG',
            width: '60',
            textAlign: 'center',
        },
    ],
    'UPC Default': [
        { field: 'disposition', header: 'UPC DISPOSITION' },
        {
            field: 'warehouseLocation',
            header: 'WAREHOUSE LOCATION',
        },
        { field: 'dispositionVoiceText', header: 'VOICE' },
        {
            dataType: 'number',
            field: 'boxMaxCount',
            header: 'MAX BOX',
            textAlign: 'center',
        },
    ],
    Custom: [
        { field: 'disposition', header: 'NAME' },
        {
            field: 'warehouseLocation',
            header: 'WAREHOUSE LOCATION',
        },
        { field: 'dispositionVoiceText', header: 'VOICE' },
        {
            dataType: 'number',
            field: 'boxMaxCount',
            header: 'MAX BOX',
            textAlign: 'center',
        },
        {
            field: 'fttenabled',
            header: 'FTT ENABLED',
            textAlign: 'center',
        },
    ],
    
    'AR Invoices': [
        { field: 'invoiceNumber', header: 'INV#', dataType: 'number' },
        {
            field: 'description',
            header: 'DESC',
            width: '180',
            textAlign: 'left',
        },
        {
            field: 'customerName',
            header: 'Customer',
            width: '160',
            textAlign: 'left',
        },
        { field: 'period', header: 'PERIOD' },
        {
            field: 'cost',
            header: 'COST',
            dataType: 'number',
            textAlign: 'right',
        },
        {
            field: 'price',
            header: 'PRICE',
            dataType: 'number',
            textAlign: 'right',
        },
        { field: 'createdDate', header: 'CREATED', width: '110' },
        { field: 'reclaimCenterName', header: 'FACILITY', width: '110' },
        {
            field: 'status',
            header: 'STATUS',
            dataType: 'enum',
            width: '150',
            class: 'statusShow',
        },
    ],
});

export const partnersGridConfig = [
    [
        { field: 'storeNumber', header: 'Store #' },
        { field: 'storeName', header: 'Name' },
        {
            field: 'email',
            header: 'Email',
        },
        { field: 'storeType', header: 'Store Type' },
        { dataType: 'enum', field: 'active', header: 'Active' },
    ],
    [
        { field: 'carrierName', header: 'Name' },
        { field: 'address', header: 'Address' },
        {
            field: 'phoneNumber',
            header: 'Phone',
        },
        { dataType: 'enum', field: 'active', header: 'Active' },
    ],
    [
        { field: 'name', header: 'Vendors' },
        { field: 'email', header: 'Email' },
        {
            field: 'address',
            header: 'Address',
        },
        { field: 'active', header: 'Active' },
    ],
    [
        { field: 'name', header: 'Name' },
        { field: 'address', header: 'Address' },
        {
            field: 'retailerType',
            header: 'Retailer Type',
        },
        { dataType: 'enum', field: 'active', header: 'Active' },
    ],
    [
        { field: 'name', header: 'Name' },
        { field: 'address', header: 'Address' },
        {
            field: 'retailerType',
            header: 'Retailer Type',
        },
        { dataType: 'enum', field: 'active', header: 'Active' },
    ],
];

export const invoiceGridConfig = [];
