import ItemsCatalogService from './items-catalog/items-catalog-service';
import ReportsService from './reports-service/reports-service';
import PrinterService from './printer-service/printer-service';
import StoresService from './partners-service/stores-service';
import VendorsService from './partners-service/vendors-service';
import LabelPrintService from './label-print-service/label-print-service';
import RuleExceptionService from './rule-exceptions/rule-exception-service';
import ARInvoiceService from './ar-invoices/ar-invoice-service';

const ServiceHandler = {
    ItemsCatalogService: new ItemsCatalogService(),
    ReportsService: new ReportsService(),
    PrinterService: new PrinterService(),
    StoresService: new StoresService(),
    VendorsService: new VendorsService(),
    RuleExceptionService: new RuleExceptionService(),
    LabelPrintService: new LabelPrintService(),
    ARInvoiceService: new ARInvoiceService(),
};

export default ServiceHandler;
