import { FC, useCallback, useState } from "react";
import { ButtonComponent } from "@syncfusion/ej2-react-buttons";
import Grid from "../grid/Grid";
import { ReactComponent as Print } from "../../icons/printNew.svg";
import { ReactComponent as Add } from "../../icons/AddNew.svg";
import { actionHistoryGridConfig } from "./config";
import LLModal from "../common/llModal/LLModal";
import { DropDownButtonComponent } from "@syncfusion/ej2-react-splitbuttons";

interface IBOLControlsProps {
  // cancelButtonText: string;
  enableActionHistory?: boolean;
  exportTypes: any;
  handleAddIconClick?: () => void;
  handleCancelButtonClick?: () => void;
  handleActionHistoryBOLClick?: () => void;
  hideAddBtnBOL?: boolean;
}

const BOLControls: FC<IBOLControlsProps> = ({
  //cancelButtonText,
  enableActionHistory,
  exportTypes,
  handleAddIconClick,
  handleCancelButtonClick,
  handleActionHistoryBOLClick,
  hideAddBtnBOL,
}) => {
  const [showActionHistoryModal, setShowActionHistoryModal] = useState(false);
  const onSelect = useCallback((args) => {
    //  if(args.item.text==='Invoice' && args.item.type==='csv') {
    console.log(args);
    // alert(args.item.text + '' + args.item.exportType + args.item.screenType)
    //}
  }, []);
  const handleActionHistory = () => {
    setShowActionHistoryModal(true);
  };
  const handleDropdown = (e: any) => {
    if (e?.items.length === 1) {
      const elem = e.element.parentElement;
      elem.style.display = "none";
    }
  };
  return (
    <div className="invoices-controls-container">
      {/* <ButtonComponent
        cssClass="e-outline"
        style={{ marginRight: 5, textAlign: "center" }}
        onClick={handleCancelButtonClick}
      >
        {cancelButtonText}
      </ButtonComponent>*/}

      <ButtonComponent
        cssClass="e-outline btnCancelInvoice"
        onClick={handleActionHistoryBOLClick}
      >
        ACTION HISTORY
      </ButtonComponent>

      {/* <ButtonComponent className="iconCopy">
        <Copy />
      </ButtonComponent> */}
       {hideAddBtnBOL && (
      <DropDownButtonComponent
      open={(e) => handleDropdown(e)}
        style={{ marginRight: 5, padding: 0 }}
        items={exportTypes}
        select={onSelect}
      >
        <Print></Print>
      </DropDownButtonComponent>
      )}
      {hideAddBtnBOL && (
        <ButtonComponent className="iconCopy" onClick={handleAddIconClick}>
          <Add />
        </ButtonComponent>
      )}
      <LLModal
        handleClose={() => setShowActionHistoryModal(false)}
        show={showActionHistoryModal}
      >
        <Grid config={actionHistoryGridConfig} data={[]} width={"100%"} />
      </LLModal>
    </div>
  );
};

export default BOLControls;
