export enum ContactType {
  STORES = "Stores",
  WAREHOUSES = "Warehouses",
  RECLAIM_CENTERS = "Reclaim Centers",
  CARRIERS = "Carriers",
  VENDORS = "Vendors",
  CUSTOMERS = "Customers",
  COMPANY_CODES = "Company Codes",
  PARTNER_SETTINGS = "Partner Settings",
  DEPARTMENTS = "Departments",
  GL_ACCOUNTS = "GL Accounts",
  PAYMENT_TERMS = "Payment Terms",
  FISCAL_YEAR_SETUP = "Fiscal Year Setup",
  ITEM_CATALOG = "Item Catalog",
  COMMODITY_TYPES = "Commodity Types",
  RULE_EXCEPTIONS = "Rule Exceptions",
  BIN_EXCEPTIONS = "Bin Exceptions",
  INVENTORY_CATALOG = "Inventory Catalog",
  PALLET_LOCATIONS = "Pallet Locations",
  BINANDBOX_ITEMS = "Items",
  WAREHOUSE_LOCATIONS = "Warehouse Locations",
  STORE_DISPOSITIONS = "Store Dispositions",
  UPCDefaultWHLoc = "UPC Default",
  CustomWHLoc = "Custom",
}

export const masterTabsOrgStructure = [
  "Company Codes",
  "Reclaim Centers",
  "Warehouses",
];
export const masterTabsPartners = [
  "Stores",
  "Carriers",
  "Vendors",
  "Customers",
  "Partner Settings",
];
export const masterTabsFinance = [
  "Departments",
  "GL Accounts",
  "Payment Terms",
  "Fiscal Year Setup",
];
export const masterTabsItems = [
  "Item Catalog",
  "Commodity Types",
  "Rule Exceptions",
  "Bin Exceptions",
];
export const masterTabsInventory = ["Inventory Catalog", "Pallet Locations"];

export enum MasterDataScreen {
  PARTNERS = "PARTNERS",
  ORGSTRUCTURE = "ORGSTRUCTURE",
  FINANCE = "FINANCE",
  ITEMS = "ITEMS",
  INVENTORY = "INVENTORY",
}

export const partnersUrl = "partners";
export const orgStructureUrl = "orgStructure";
export const financeUrl = "finance";
export const itemsUrl = "items";
export const inventoryUrl = "inventory";
