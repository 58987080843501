import { ButtonComponent } from "@syncfusion/ej2-react-buttons";
import saveIcon from "../../../icons/common/save.png";
import closeIcon from "../../../icons/common/close.png";
import activeIcon from "../../../icons/common/active.png";
import inactiveIcon from "../../../icons/common/inactive.png";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getLlAdminEndPoint, updateLlAdminEndPoint } from "../utils";
import axios from "axios";
import { setRowData } from "../../grid/gridReducer";
import notify from "../../../services/toasterService";
import { RootState } from "../../../redux/store";
import { setCountryData } from "../lladminreducer";

const PreviewCountry = () => {
  const dispatch = useDispatch();
  const [countryInfo, setCountryInfo] = useState<any[]>([]);

  /* Get selected data from Grid Reducer */
  const selRowData = useSelector(
    (state: RootState) => state.gridReducer.selectedRowData
  );
  // console.log(selectedRowData, 'selectedRowData');

  useEffect(() => {
    if (selRowData && selRowData?.length > 0) {
      setCountryInfo(selRowData);
    }
    // Write for onload
  }, [selRowData]);

  const countryFieldFocusOut = (value: any, key: any) => {
    setCountryInfo([{ ...countryInfo[0], [key]: value }]);
  };

  /**
   * Changing status for Preview selected tabs
   * @param flag - true/false
   */
  const changeStatusForRowData = (flag: any) => {
    if (flag === false) {
      setCountryInfo([{ ...countryInfo[0], activeForGrid: "Inactive" }]);
    } else {
      setCountryInfo([{ ...countryInfo[0], activeForGrid: "Active" }]);
    }
  };

  const clearPreview = () => {
    dispatch(setRowData([]));
  };

  /**
   * PUT web service methods for Location setting tabs
   * @param data - Tab Name
   */
  const updateLocationSettingTabApi = async (data: any) => {
    console.log(data);
    const endPointURL = updateLlAdminEndPoint(data.tabApiName, data.id);
    let body = {
      countryName: data.countryName,
      id: data.id,
      isoCountryCode: data.isoCountryCode,
      region: data.region,
      subRegion: data.subRegion,
      deleted: data.deleted,
      organizationId: data.organizationId,
      tenantId: data.tenantId,
    };
    const status = data.activeForGrid.toLowerCase();
    if (status === "active") {
      Object.assign(body, { active: true });
    } else {
      Object.assign(body, { active: false });
    }
    await axios
      .put(endPointURL, body)
      .then((res) => {
        notify("Country code updated successfully", "success");
        getlAdminTabs(data.tabApiName);
      })
      .catch((err) => {
        console.log(err, "Country code");
        notify("Failed to update country code", "error");
      });
  };

  const getlAdminTabs = async (tabName: any) => {
    const endPointURL = getLlAdminEndPoint(tabName);
    await axios
      .get(endPointURL)
      .then((res: any) => {
        dispatch(setCountryData(res.data));
        const dataa = res.data[0];
        setCountryInfo(dataa);
        setTimeout(() => {
          dispatch(setRowData(countryInfo));
        }, 10);
      })
      .catch((err) => {
        console.log(err, "Country code");
        notify("Failed to update country code", "error");
      });
  };

  const footerPreview = (data: any) => {
    return (
      <div className="col-sm-12 px-0 pt-5 ">
        <div className="col-sm-6 col-md-6 col-lg-6 pl-1.5rem pr-1 preview-Key  ">
          <ButtonComponent
            cssClass="e-outline opt-btn-light float-left"
            onClick={() => updateLocationSettingTabApi(data)}
          >
            <img src={saveIcon} alt="Save" />
          </ButtonComponent>

          <ButtonComponent
            cssClass="e-outline opt-btn-light float-left ml-3"
            onClick={() => clearPreview()}
          >
            <img src={closeIcon} alt="Close" />
          </ButtonComponent>
        </div>
        <div className="col-sm-6 col-md-6 col-lg-6 preview-value pt-3">
          <p className="preview-status">
            {data.activeForGrid === "Active" ||
            data.activeForGrid === "active" ? (
              <span>
                <img
                  src={activeIcon}
                  alt="Status"
                  onClick={() => changeStatusForRowData(false)}
                />
                &nbsp; &nbsp; Active
              </span>
            ) : (
              <span>
                <img
                  src={inactiveIcon}
                  alt="Status"
                  onClick={() => changeStatusForRowData(true)}
                />
                &nbsp; &nbsp; Inactive
              </span>
            )}
          </p>
        </div>
      </div>
    );
  };

  return (
    <div>
      {countryInfo && countryInfo.length > 0 ? (
        <div className="col-sm-12 px-3 py-3 preview-details  ">
          <div className="col-sm-12 px-0 pt-4 ">
            <div className="col-sm-6 col-md-6 col-lg-5 preview-Key  " style={{lineHeight: '18px'}}>ID</div>
            <div className="col-sm-6 col-md-6 col-lg-7 preview-value ">
              {countryInfo[0].id}
            </div>
          </div>
          <div className="col-sm-12 px-0 pt-4">
            <div className="col-sm-6 col-md-6 col-lg-5 preview-Key  " style={{lineHeight: '33px'}}>Name</div>
            <div className="col-sm-6 col-md-6 col-lg-7 preview-value ">
              {/* <InlineInputEdit inputClassName="txtEditClassNameInput"
                                text={countryInfo[0].countryName}
                                labelClassName="txtEditClassName"
                                onFocusOut={(e) => countryFieldFocusOut(e, 'countryName')}
                                labelPlaceHolder='---'
                            /> */}
              <input
                type="text"
                className="form-control border-none pl-0 cursor-pointer txtEditFieldFocus boxShadow-None"
                value={countryInfo[0]?.countryName}
                onChange={(e: any) =>
                  countryFieldFocusOut(e.target.value, "countryName")
                }
              />
            </div>
          </div>
          <div className="col-sm-12 px-0 pt-4">
            <div className="col-sm-6 col-md-6 col-lg-5 preview-Key  " style={{lineHeight: '33px'}}>
              ISO Code
            </div>
            <div className="col-sm-6 col-md-6 col-lg-7 preview-value ">
              {/* <InlineInputEdit inputClassName="txtEditClassNameInput"
                                text={countryInfo[0].isoCountryCode}
                                labelClassName="txtEditClassName"
                                onFocusOut={(e) => countryFieldFocusOut(e, 'isoCountryCode')}
                                labelPlaceHolder='---'
                            /> */}
              <input
                type="text"
                className="form-control border-none pl-0 cursor-pointer txtEditFieldFocus boxShadow-None"
                value={countryInfo[0]?.isoCountryCode}
                onChange={(e: any) =>
                  countryFieldFocusOut(e.target.value, "isoCountryCode")
                }
              />
            </div>
          </div>
          <div className="col-sm-12 px-0 pt-4">
            <div className="col-sm-6 col-md-6 col-lg-5 preview-Key  " style={{lineHeight: '33px'}}>
              Region
            </div>
            <div className="col-sm-6 col-md-6 col-lg-7 preview-value ">
              {/* <InlineInputEdit inputClassName="txtEditClassNameInput"
                                text={countryInfo[0].region}
                                labelClassName="txtEditClassName"
                                onFocusOut={(e) => countryFieldFocusOut(e, 'region')}
                                labelPlaceHolder='---'
                            /> */}
              <input
                type="text"
                className="form-control border-none pl-0 cursor-pointer txtEditFieldFocus boxShadow-None"
                value={countryInfo[0]?.region}
                onChange={(e: any) =>
                  countryFieldFocusOut(e.target.value, "region")
                }
              />
            </div>
          </div>
          <div className="col-sm-12 px-0 pt-4">
            <div className="col-sm-6 col-md-6 col-lg-5 preview-Key  " style={{height: '5px'}}>
              Sub-Region
            </div>
            <div className="col-sm-6 col-md-6 col-lg-7 preview-value ">
              {/* <InlineInputEdit inputClassName="txtEditClassNameInput"
                                text={countryInfo[0].subRegion}
                                labelClassName="txtEditClassName"
                                onFocusOut={(e) => countryFieldFocusOut(e, 'subRegion')}
                                labelPlaceHolder='---'
                            /> */}
              <input
                type="text"
                className="form-control border-none pl-0 cursor-pointer txtEditFieldFocus boxShadow-None"
                value={countryInfo[0]?.subRegion}
                onChange={(e: any) =>
                  countryFieldFocusOut(e.target.value, "subRegion")
                }
              />
            </div>
          </div>

          <div className="col-sm-12 px-0 pt-5 pb-4">
            {footerPreview(countryInfo[0])}
          </div>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default PreviewCountry;
