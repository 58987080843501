import React from "react";
import {Link} from "react-router-dom";
import withBreadcrumbs from "react-router-breadcrumbs-hoc";

const PureBreadcrumbs = ({ breadcrumbs }) => (
  <div className="px-2 py-3 mb-0">
    {breadcrumbs.map(({ breadcrumb, match }, index) => (
      <div className="brdCmb-home mr-2" key={match.url}>
        <Link className= {(index === breadcrumbs.length - 1)?'disabled':""} to={match.url || ""}>{breadcrumb}</Link>
       {index < breadcrumbs.length - 1 && " / "}
      </div>
    ))}
  </div>
);

export default withBreadcrumbs()(PureBreadcrumbs);
