import { ButtonComponent } from "@syncfusion/ej2-react-buttons";
import axios from "axios";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import addIcon from "../../assets/add.png";
import {
  addMasterDataEndPoint,
  getEndPoint,
  getStatesByCountry,
} from "./utils";
import userIcon from "../../assets/userIcon.png";
import closeIcon from "../../assets/iconClose.png";
import { RootState } from "../../redux/store";
import {
  setCurrentContact,
  setMasterDataAddImage,
  setStatesAddrDataOne,
  setStatesAddrDataTwo,
  setStatesData,
} from "./contactReducer";
import { ContactType } from "./constants";
import notify from "../../services/toasterService";
import CountryDropdown from "../ContactsDetailsPanel/mobile-field-component";
import editIcon from "../../assets/EditRuleExcep.png";
import AddFileUploadScreen from "../accessMgmt/addScreen/fileUpload";
import { getStatesByCountryCode } from "../accessMgmt/utils";

const CustomerAddScreen = (props: any) => {
  const { setAddScreen } = props;
  useEffect(() => {
    getStatesList('USA');
    getStatesListAltOne('USA');
    getStatesListAltTwo('USA');
  },[]);
  const [showAltAddressInfo, setShowAltAddressInfo] = useState(false);
  const [showAltAddressInfoOne, setShowAltAddressInfoOne] = useState(false);
  const [selectedImage, setSelectedImage] = useState();
  const [checkBox, setCheckBox] = useState(true);

  const [myNameCust, setMynameCust] = useState("");
  const [myEmailCust, setMyEmailCust] = useState("");
  const [myPhoneCust, setMyPhoneCust] = useState("");
  const [myCustomerNumber, setMyCustomerNumber] = useState("");
  const [myAccount, setMyAccount] = useState("");

  const [isCustomerScreenDisabeled, setisCustomerScreenDisabeled] = useState(true);


  const [errors, setErrors] = useState({
    nameError: "",
    emailError: "",
    custNumberError: "",
    accNumberError: "",
    phoneError: "",
    altPhoneError: "",
    faxError: "",
    zipError: "",
    zipErrorOne: "",
    zipErrorTwo: "",
    languageError: "",
    currencyCodeError: "",
    timezoneError: "",
  });
  let nameError = "";
  let emailError = "";
  let custNumberError = "";
  let accNumberError = "";
  let phoneError = "";
  let altPhoneError = "";
  let faxError = "";
  let zipError = "";
  let zipErrorOne = "";
  let zipErrorTwo = "";
  let languageError = "";
  let currencyCodeError = "";
  let timezoneError = "";
  const currencyData = useSelector(
    (state: RootState) => state.contactReducer?.currencyData
  );
  const timezoneData = useSelector(
    (state: RootState) => state.contactReducer?.timezoneData
  );
  const languageData = useSelector(
    (state: RootState) => state.contactReducer?.laungageData
  );
  const paymentTermsData = useSelector(
    (state: RootState) => state.contactReducer?.paymentTermsData
  );
  const billingModeData = useSelector(
    (state: RootState) => state.contactReducer?.billingModeData
  );
  const customerTypeData = useSelector(
    (state: RootState) => state.contactReducer?.customerTypeData
  );
  const countryInfo = useSelector(
    (state: RootState) => state.contactReducer?.countryData
  );
  const countryCodeInfo = useSelector(
    (state: RootState) => state.contactReducer?.countryCodePhoneData
  );
  const statesInfo = useSelector(
    (state: RootState) => state.contactReducer?.statesData
  );
  const imageChange = (e: any) => {
    if (e.target.files && e.target.files.length > 0) {
      setSelectedImage(e.target.files[0]);
    }
  };
  const handleAltAdress = () => {
    setShowAltAddressInfo(true);
  };
  const handleAltAdressClose = () => {
    setShowAltAddressInfo(false);
  };

  const handleAltAdressOne = () => {
    setShowAltAddressInfoOne(true);
  };
  const handleAltAdressCloseOne = () => {
    setShowAltAddressInfoOne(false);
  };
  const dispatch = useDispatch();
  const [customerName, setCustomerName] = useState("");
  const [customerEmail, setCustomerEmail] = useState("");
  const [customerPhone, setCustomerPhone] = useState("");
  const [customerAltPhone, setCustomerAltPhone] = useState("");
  const [customerFax, setCustomerFax] = useState("");
  const [customerNumber, setCustomerNumber] = useState("");
  const [customerAccountNumber, setCustomerAccountNumber] = useState("");
  const [customerPaymentTerms, setCustomerPaymentTerms] = useState("");
  const [customerBillingMode, setCustomerBillingMode] = useState("");
  const [customerType, setCustomerType] = useState("");
  const [customerAddressStreet, setCustomerAddressStreet] = useState("");
  const [customerAddressCity, setCustomerAddressCity] = useState("");
  const [customerAddressState, setCustomerAddressState] = useState("");
  const [customerAddressZip, setCustomerAddressZip] = useState("");
  const [customerAddressCountry, setCustomerAddressCountry] = useState("");

  const [customerAltAddressStreetOne, setCustomerAltAddressStreetOne] =
    useState("");
  const [customerAltAddressCityOne, setCustomerAltAddressCityOne] =
    useState("");
  const [customerAltAddressStateOne, setCustomerAltAddressStateOne] =
    useState("");
  const [customerAltAddressZipOne, setCustomerAltAddressZipOne] = useState("");
  const [customerAltAddressCountryOne, setCustomerAltAddressCountryOne] =
    useState("");

  const [customerAltAddressStreetTwo, setCustomerAltAddressStreetTwo] =
    useState("");
  const [customerAltAddressCityTwo, setCustomerAltAddressCityTwo] =
    useState("");
  const [customerAltAddressStateTwo, setCustomerAltAddressStateTwo] =
    useState("");
  const [customerAltAddressZipTwo, setCustomerAltAddressZipTwo] = useState("");
  const [customerAltAddressCountryTwo, setCustomerAltAddressCountryTwo] =
    useState("");

  const [customerLanguage, setCustomerLanguage] = useState("en-US");
  const [customerCurrencyCode, setCustomerCurrencyCode] = useState("US Dollar");
  const [customerTimeZone, setCustomerTimeZone] = useState(
    "Eastern Standard Time"
  );
  const [customerPrepaid, setCustomerPrepaid] = useState(false);
  const [customerDefaultBillAddress, setCustomerDefaultBillAddress] =
    useState(false);
  const [customerTypeLinkToCustomer, setCustomerTypeLinkToCustomer] = useState<
    any[]
  >([]);

  const [customerCountryCodePhone, setCustomerCountryCodePhone] = useState("");
  const [customerCountryCodeAltPhone, setCustomerCountryCodeAltPhone] =
    useState("");
  const [customerCountryCodeFax, setCustomerCountryCodeFax] = useState("");

  const mobileWithFlag = useSelector(
    (state: RootState) => state.contactReducer?.selectedMobileWithCountryFlag
  );
  const altMobileWithFlag = useSelector(
    (state: RootState) => state.contactReducer?.selectedAltMobileWithCountryFlag
  );
  const faxWithFlag = useSelector(
    (state: RootState) => state.contactReducer?.selectedFaxWithCountryFlag
  );
  const phoneCountryCode = useSelector(
    (state: RootState) => state.contactReducer?.selectedCountryCodeForMobile
  );
  const AltPhoneCountryCode = useSelector(
    (state: RootState) => state.contactReducer?.selectedCountryCodeForAltMobile
  );
  const faxCountryCode = useSelector(
    (state: RootState) => state.contactReducer?.selectedCountryCodeForFax
  );
  const statesAddInfoOne = useSelector(
    (state: RootState) => state.contactReducer?.statesAddrDataOne
  );
  const statesAddrInfoTwo = useSelector(
    (state: RootState) => state.contactReducer?.statesAddrDataTwo
  );

  const handleCustomerName = (e: any) => {
    setCustomerName(e.target.value);
    validate();
  };
  const handleCustomerEmail = (e: any) => {
    //setCustomerEmail(e.target.value);
    //validate();

    setErrors({
      nameError,
      emailError,
      custNumberError,
      accNumberError,
      phoneError,
      altPhoneError,
      faxError,
      zipError,
      zipErrorOne,
      zipErrorTwo,
      languageError,
      currencyCodeError,
      timezoneError,
    });
    const reg =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    // if (e.target.value === "") {
    //   emailError = "Email is Required";
    // } else
     if (e.target.value && reg.test(e.target.value) === false) {
      emailError = "Email is Invalid ";
    }
    if (emailError) {
      setErrors({
        nameError,
        emailError,
        custNumberError,
        accNumberError,
        phoneError,
        altPhoneError,
        faxError,
        zipError,
        zipErrorOne,
        zipErrorTwo,
        languageError,
        currencyCodeError,
        timezoneError,
      });
      return false;
    } else {
      setCustomerEmail(e.target.value);
      return true;
    }
  };
  const handleCustomerPhone = (e: any) => {
    //setCustomerPhone(e.target.value);
    //validate();

    var phoneno = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
    if (e.target.value.match(phoneno)) {
      setCustomerPhone(e.target.value);
      phoneError = "";
      setErrors({
        nameError,
        emailError,
        custNumberError,
        accNumberError,
        phoneError,
        altPhoneError,
        faxError,
        zipError,
        zipErrorOne,
        zipErrorTwo,
        languageError,
        currencyCodeError,
        timezoneError,
      });
      return true;
    } else {
      phoneError = "Phone number is invalid";
    }
    if (phoneError) {
      setErrors({
        nameError,
        emailError,
        custNumberError,
        accNumberError,
        phoneError,
        altPhoneError,
        faxError,
        zipError,
        zipErrorOne,
        zipErrorTwo,
        languageError,
        currencyCodeError,
        timezoneError,
      });
      return false;
    }
  };
  const handleCustomerAltPhone = (e: any) => {
    var phoneno = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
    if (e.target.value.match(phoneno)) {
      setCustomerAltPhone(e.target.value);
      altPhoneError = "";
      setErrors({
        nameError,
        emailError,
        custNumberError,
        accNumberError,
        phoneError,
        altPhoneError,
        faxError,
        zipError,
        zipErrorOne,
        zipErrorTwo,
        languageError,
        currencyCodeError,
        timezoneError,
      });
      return true;
    } else {
      altPhoneError = "Phone number is invalid";
    }
    if (altPhoneError) {
      setErrors({
        nameError,
        emailError,
        custNumberError,
        accNumberError,
        phoneError,
        altPhoneError,
        faxError,
        zipError,
        zipErrorOne,
        zipErrorTwo,
        languageError,
        currencyCodeError,
        timezoneError,
      });
      return false;
    }
  };
  const handleCustomerFax = (e: any) => {
    setCustomerFax(e.target.value);
    validate();
    setErrors({
      nameError,
      emailError,
      custNumberError,
      accNumberError,
      phoneError,
      altPhoneError,
      faxError,
      zipError,
      zipErrorOne,
      zipErrorTwo,
      languageError,
      currencyCodeError,
      timezoneError,
    });
    var phoneno = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
    if (e.target.value.match(phoneno)) {
      setCustomerFax(e.target.value);
      faxError = "";
      setErrors({
        nameError,
        emailError,
        custNumberError,
        accNumberError,
        phoneError,
        altPhoneError,
        faxError,
        zipError,
        zipErrorOne,
        zipErrorTwo,
        languageError,
        currencyCodeError,
        timezoneError,
      });
      return true;
    } else {
      faxError = "Fax is invalid";
    }
    if (faxError) {
      setErrors({
        nameError,
        emailError,
        custNumberError,
        accNumberError,
        phoneError,
        altPhoneError,
        faxError,
        zipError,
        zipErrorOne,
        zipErrorTwo,
        languageError,
        currencyCodeError,
        timezoneError,
      });
      return false;
    }
  };
  const handleCustomerNumber = (e: any) => {
    setCustomerNumber(e.target.value);
    validate();
  };
  const handleCustomerAccountNumber = (e: any) => {
    setCustomerAccountNumber(e.target.value);
    validate();
  };
  const handleCustomerPaymentTerms = (e: any) => {
    setCustomerPaymentTerms(e.target.value);
  };
  const handleCustomerBillingMode = (e: any) => {
    setCustomerBillingMode(e.target.value);
  };

  const handleChangeCustomerCountryCodePhoneRC = (e: any) => {
    setCustomerCountryCodePhone(e.target.value);
  };

  const handleChangeCustCountryAltPhoneRC = (e: any) => {
    setCustomerCountryCodeAltPhone(e.target.value);
  };
  const handleChangeCustCountryCodeFaxRC = (e: any) => {
    setCustomerCountryCodeFax(e.target.value);
  };

//******************** */
const [emailErrorMsg, setEmailErrorMsg] = useState("");
const [myCustomerName, setMyCustomerName] = useState("");
const [myAccountNumber, setMyAccountNumber] = useState("");
const [customerInfoSelectFieldObj, setCustomerInfoSelectFieldObj] = useState<
any[]
>([
{
  language: "en-US",
  timeZone: "Eastern Standard Time",
  countryId: "United States of America",
  stateId: "",
  currencyCode: "US Dollar",
  customerType: "Select Customer Type",
},
]);

const [customerInfo, setCustomerInfo] = useState<any[]>([
{
  customerName: "",
  customerNumber: customerNumber,
  accountNumber: customerAccountNumber,
  email: "",
  phoneNumber: "",
  zipcode: "",
  addressLine1: "",
  addressLine2: "",
  alternatePhoneNumber: "",
  phoneCountryCode: phoneCountryCode,
  altPhoneCountryCode: AltPhoneCountryCode,
  faxCountryCode: faxCountryCode,
  fax: "",
  billingMode: customerBillingMode,
  billingAddress: customerDefaultBillAddress,
  prepaid: customerPrepaid,
},
]);

const handleCustomerTextFields = (event: any, key: any) => {
  if (key === "zipcode") {
      if (event.target.value.length <= 5) {
        setCustomerInfo([
          { ...customerInfo[0], [key]: event.target.value },
        ]);
        validateCustomers();
      }
    } else {
      setCustomerInfo([{ ...customerInfo[0], [key]: event.target.value }]);
      validateCustomers(); 
    }
  };

  const [customerInfoAlt, setCustomerInfoAlt] = useState<any[]>([
    {
      zipcode: "",
      addressLine1: "",
      addressLine2: "",
    },
    ]);
    
    const handleCustomerTextFieldsAlt = (event: any, key: any) => {
      if (key === "zipcode") {
          if (event.target.value.length <= 5) {
            setCustomerInfoAlt([
              { ...customerInfoAlt[0], [key]: event.target.value },
            ]);
            validateCustomers();
          }
        } else {
          setCustomerInfoAlt([{ ...customerInfoAlt[0], [key]: event.target.value }]);
          validateCustomers(); 
        }
      };
    
      const [customerInfoAltAddrTwo, setCustomerInfoAltAddrTwo] = useState<any[]>([
        {
          zipcode: "",
          addressLine1: "",
          addressLine2: "",
        },
        ]);
        
        const handleCustomerTextFieldsAltAddrTwo = (event: any, key: any) => {
          if (key === "zipcode") {
              if (event.target.value.length <= 5) {
                setCustomerInfoAltAddrTwo([
                  { ...customerInfoAltAddrTwo[0], [key]: event.target.value },
                ]);
                validateCustomers();
              }
            } else {
              setCustomerInfoAltAddrTwo([{ ...customerInfoAltAddrTwo[0], [key]: event.target.value }]);
              validateCustomers(); 
            }
          };

  const [statesList, setStateList] = useState<any>([]);

  const getStatesList = async (countryCode: any) => {
    const endPointUrl = getStatesByCountryCode(countryCode);
    await axios.get(endPointUrl).then(
      (res) => {
        setStateList(res.data);
        // dispatch(setStatesList(res.data));
      },
      (err) => {
        notify("Failed to fetch data", "error");
      }
    );
  };

  const handleCustomerSelectFields = (event: any, key: any) => {
   // const dataa = JSON.parse(event.target.value);
    if (key === "countryId") {
      const dataa = JSON.parse(event.target.value);
      getStatesList(dataa.isoCountryCode);
      setCustomerInfoSelectFieldObj([
        { ...customerInfoSelectFieldObj[0], [key]: dataa.countryName },
      ]);
      setCustomerInfo([{ ...customerInfo[0], [key]: dataa.id }]);
    }
    // setMobileUserSelectFields([{...mobileUserSelectFields[0], [key]: dataa}])
    if (key === "stateId") {
      setCustomerInfoSelectFieldObj([
        { ...customerInfoSelectFieldObj[0], [key]: event.target.value },
      ]);
      //setCustomerInfo([{ ...customerInfo[0], [key]: dataa.id }]);
    }
    if (key === "language") {
      setCustomerInfoSelectFieldObj([
        { ...customerInfoSelectFieldObj[0], [key]: event.target.value },
      ]);
      //setCustomerInfo([{ ...customerInfo[0], [key]: dataa.isoLongCode }]);
    }
    if (key === "timezone") {
      setCustomerInfoSelectFieldObj([
        { ...customerInfoSelectFieldObj[0], [key]: event.target.value },
      ]);
      //setCustomerInfo([{ ...customerInfo[0], [key]: dataa.timezoneName }]);
    }

    validateCustomers();
  };


  const [statesListAltOne, setStateListAltOne] = useState<any>([]);

  const getStatesListAltOne = async (countryCode: any) => {
    const endPointUrl = getStatesByCountryCode(countryCode);
    await axios.get(endPointUrl).then(
      (res) => {
        setStateListAltOne(res.data);
        // dispatch(setStatesList(res.data));
      },
      (err) => {
        notify("Failed to fetch data", "error");
      }
    );
  };
  
  const [customerInfoSelectFieldObjAlt, setCustomerInfoSelectFieldObjAlt] = useState<any[]>([
    {
  countryId: "United States of America",
  stateId: "",
  },
  ]);

  const handleCustomerSelectFieldsAlt = (event: any, key: any) => {
    // const dataa = JSON.parse(event.target.value);
     if (key === "countryId") {
       const dataa = JSON.parse(event.target.value);
       getStatesListAltOne(dataa.isoCountryCode);
       setCustomerInfoSelectFieldObjAlt([
         { ...customerInfoSelectFieldObjAlt[0], [key]: dataa.countryName },
       ]);
       setCustomerInfo([{ ...customerInfo[0], [key]: dataa.id }]);
     }
     // setMobileUserSelectFields([{...mobileUserSelectFields[0], [key]: dataa}])
     if (key === "stateId") {
      setCustomerInfoSelectFieldObjAlt([
         { ...customerInfoSelectFieldObjAlt[0], [key]: event.target.value },
       ]);
       //setCustomerInfo([{ ...customerInfo[0], [key]: dataa.id }]);
     } 
     validateCustomers();
   };


   const [statesListAltTwo, setStateListAltTwo] = useState<any>([]);

  const getStatesListAltTwo = async (countryCode: any) => {
    const endPointUrl = getStatesByCountryCode(countryCode);
    await axios.get(endPointUrl).then(
      (res) => {
        setStateListAltTwo(res.data);
        // dispatch(setStatesList(res.data));
      },
      (err) => {
        notify("Failed to fetch data", "error");
      }
    );
  };

   const [customerInfoSelectFieldObjAltAddrTwo, setCustomerInfoSelectFieldObjAltAddrTwo] = useState<any[]>([
    {
  countryId: "United States of America",
  stateId: "",
  },
  ]);

  const handleCustomerSelectFieldsAltAddrTwo = (event: any, key: any) => {
    // const dataa = JSON.parse(event.target.value);
     if (key === "countryId") {
       const dataa = JSON.parse(event.target.value);
       getStatesListAltTwo(dataa.isoCountryCode);
       setCustomerInfoSelectFieldObjAltAddrTwo([
         { ...customerInfoSelectFieldObjAltAddrTwo[0], [key]: dataa.countryName },
       ]);
       setCustomerInfo([{ ...customerInfo[0], [key]: dataa.id }]);
     }
     // setMobileUserSelectFields([{...mobileUserSelectFields[0], [key]: dataa}])
     if (key === "stateId") {
      setCustomerInfoSelectFieldObjAltAddrTwo([
         { ...customerInfoSelectFieldObjAltAddrTwo[0], [key]: event.target.value },
       ]);
       //setCustomerInfo([{ ...customerInfo[0], [key]: dataa.id }]);
     } 
     validateCustomers();
   };

  const validateCustomers = () =>{
    if (customerInfo[0].customerName === "") {
      nameError = "Required Field";
      setMyCustomerName("1px solid red");
    } else {
      setMyCustomerName("");
    }
    if (customerInfo[0].email !== "") {
      console.log(emailError)
      var regexFax = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    if (!regexFax.test(customerInfo[0].email)) {
      console.log(emailError)
      emailError = "Invalid Email";
      setEmailErrorMsg("Invalid Email")
    }      
     else {
      setEmailErrorMsg("");
    }
  }
    if (customerInfo[0].accountNumber === "") {
      accNumberError = "Required Field";
      setMyAccountNumber("1px solid red");
    } else {
      setMyAccountNumber("");
    }
    if (customerInfoSelectFieldObj[0].currencyCode === "") {
      currencyCodeError = "Required Field";
      //setMyUserName("1px solid red");
    } else {
      //setMyUserName("");
    }
    if (customerInfoSelectFieldObj[0].language === "") {
      languageError = "Required Field";
      //setMyUserName("1px solid red");
    } else {
      //setMyUserName("");
    }
    if (customerInfoSelectFieldObj[0].timeZone === "") {
      timezoneError = "Required Field";
      //setMyUserName("1px solid red");
    } else {
      //setMyUserName("");
    }
    if (
      //mobileUserInfo[0].firstname === "" ||
      customerInfo[0].customerName === "" 
     || customerInfoSelectFieldObj[0].timeZone === "" 
     || customerInfoSelectFieldObj[0].language === "" 
     || customerInfoSelectFieldObj[0].currencyCode === "" 
      // mobileUserInfo[0].password === ""
    ) {
      setErrors({
        nameError: "",
        emailError: "",
        custNumberError: "",
        accNumberError: "",
        phoneError: "",
        altPhoneError: "",
        faxError: "",
        zipError: "",
        zipErrorOne: "",
        zipErrorTwo: "",
        languageError: "",
        currencyCodeError: "",
        timezoneError: "",
      });
      setisCustomerScreenDisabeled(true);
      return false;
    } else {
      setisCustomerScreenDisabeled(false);
    }
    return true;
    }
//******************* */

  const handleCustomerType = (e: any) => {
    validate();
    const addCustomerTypeItem = JSON.parse(e.target.value);
    const CTInfo = [...customerTypeLinkToCustomer];
    if (
      !CTInfo.some(
        (person) => person.customerType === addCustomerTypeItem.customerType
      )
    ) {
      CTInfo.push(addCustomerTypeItem);
      setCustomerTypeLinkToCustomer(CTInfo);
      var mySelect = document.getElementById(
        "companyTypeLinkDropDown"
      ) as HTMLSelectElement;
      if (mySelect) {
        mySelect.selectedIndex = 0;
      }
    }
    var mySelect = document.getElementById(
      "companyTypeLinkDropDown"
    ) as HTMLSelectElement;
    if (mySelect) {
      mySelect.selectedIndex = 0;
    }
    //setCustomerType(e.target.value);
  };

  const removeLinkedCTData = (value: any) => {
    let linkedStoreData = [...customerTypeLinkToCustomer];
    let updatedStoreData = linkedStoreData.filter((item) => item.id !== value);
    var mySelect = document.getElementById(
      "companyTypeLinkDropDown"
    ) as HTMLSelectElement;
    if (mySelect) {
      mySelect.selectedIndex = 0;
    }
    setCustomerTypeLinkToCustomer(updatedStoreData);
  };
  const handleCustomerAddressStreet = (e: any) => {
    setCustomerAddressStreet(e.target.value);
  };
  const handleCustomerAddressCity = (e: any) => {
    setCustomerAddressCity(e.target.value);
  };
  const handleCustomerAddressState = (e: any) => {
    setCustomerAddressState(e.target.value);
  };
  const handleCustomerAddressZip = (e: any) => {
    setCustomerAddressZip(e.target.value);

    setErrors({
      nameError,
      emailError,
      custNumberError,
      accNumberError,
      phoneError,
      altPhoneError,
      faxError,
      zipError,
      zipErrorOne,
      zipErrorTwo,
      languageError,
      currencyCodeError,
      timezoneError,
    });
    var regexFax = "^[0-9]{5}(?:-[0-9]{4})?$";
    if (e.target.value.match(regexFax)) {
      setCustomerAddressZip(e.target.value);
      zipError = "";
      setErrors({
        nameError,
        emailError,
        custNumberError,
        accNumberError,
        phoneError,
        altPhoneError,
        faxError,
        zipError,
        zipErrorOne,
        zipErrorTwo,
        languageError,
        currencyCodeError,
        timezoneError,
      });
      return true;
    } else {
      zipError = "Zip code is invalid";
    }
    if (zipError) {
      setErrors({
        nameError,
        emailError,
        custNumberError,
        accNumberError,
        phoneError,
        altPhoneError,
        faxError,
        zipError,
        zipErrorOne,
        zipErrorTwo,
        languageError,
        currencyCodeError,
        timezoneError,
      });
      return false;
    }
  };
  const handleCustomerAddressCountry = (e: any) => {
    //setCustomerAddressCountry(e.target.value);
    const countryCode = JSON.parse(e.target.value);
    setCustomerAddressCountry(countryCode.countryName);
    const getEndpoint = getStatesByCountry(countryCode.isoCountryCode);
    handleGetStatesList(getEndpoint);
  };
  const handleGetStatesList = async (getEndpoint: string) => {
    const getresult = await axios.get(getEndpoint);
    let cData: any = getresult.data;
    dispatch(setStatesData(cData));
  };
  const handleCustomerLanguage = (e: any) => {
    setCustomerLanguage(e.target.value);
  };
  const handleCustomerCurrencyCode = (e: any) => {
    setCustomerCurrencyCode(e.target.value);
  };
  const handleCustomerTimeZone = (e: any) => {
    setCustomerTimeZone(e.target.value);
  };
  const handleSwitchCustomerPrepaid = (e: any) => {
    setCustomerPrepaid(e.target.checked);
  };
  const handleCustomerDefaultBillingAddr = (e: any) => {
    if (customerDefaultBillAddress) {
      setCheckBox(false);
    } else if (checkBox) {
      setCheckBox(e.target.value);
    } else {
      setCheckBox(true);
    }
  };
  const handleCustomerDefaultBillingAddrTwo = (e: any) => {
    setCheckBox(false);
    setCustomerDefaultBillAddress(e.target.checked);
  };
  console.log(customerDefaultBillAddress);
  const handleCustomerAltAddressStreetOne = (e: any) => {
    setCustomerAltAddressStreetOne(e.target.value);
  };
  const handleCustomerAltAddressCityOne = (e: any) => {
    setCustomerAltAddressCityOne(e.target.value);
  };
  const handleCustomerAltAddressStateOne = (e: any) => {
    setCustomerAltAddressStateOne(e.target.value);
  };
  const handleCustomerAltAddressZipOne = (e: any) => {
    setErrors({
      nameError,
      emailError,
      custNumberError,
      accNumberError,
      phoneError,
      altPhoneError,
      faxError,
      zipError,
      zipErrorOne,
      zipErrorTwo,
      languageError,
      currencyCodeError,
      timezoneError,
    });
    var regexFax = "^[0-9]{5}(?:-[0-9]{4})?$";
    if (e.target.value.match(regexFax)) {
      setCustomerAltAddressZipOne(e.target.value);
      zipErrorOne = "";
      setErrors({
        nameError,
        emailError,
        custNumberError,
        accNumberError,
        phoneError,
        altPhoneError,
        faxError,
        zipError,
        zipErrorOne,
        zipErrorTwo,
        languageError,
        currencyCodeError,
        timezoneError,
      });
      return true;
    } else {
      zipErrorOne = "Zip code is invalid";
    }
    if (zipErrorOne) {
      setErrors({
        nameError,
        emailError,
        custNumberError,
        accNumberError,
        phoneError,
        altPhoneError,
        faxError,
        zipError,
        zipErrorOne,
        zipErrorTwo,
        languageError,
        currencyCodeError,
        timezoneError,
      });
      return false;
    }
  };
  const handleCustomerAltAddressCountryOne = (e: any) => {
    //setCustomerAltAddressCountryOne(e.target.value);
    const countryCode = JSON.parse(e.target.value);
    setCustomerAltAddressCountryOne(countryCode.countryName);
    const getEndpoint = getStatesByCountry(countryCode.isoCountryCode);
    handleGetStatesListOne(getEndpoint);
  };
  const handleGetStatesListOne = async (getEndpoint: string) => {
    const getresult = await axios.get(getEndpoint);
    let cData: any = getresult.data;
    dispatch(setStatesAddrDataOne(cData));
  };

  const handleCustomerAltAddressStreetTwo = (e: any) => {
    setCustomerAltAddressStreetTwo(e.target.value);
  };
  const handleCustomerAltAddressCityTwo = (e: any) => {
    setCustomerAltAddressCityTwo(e.target.value);
  };
  const handleCustomerAltAddressStateTwo = (e: any) => {
    setCustomerAltAddressStateTwo(e.target.value);
  };
  const handleCustomerAltAddressZipTwo = (e: any) => {
    setErrors({
      nameError,
      emailError,
      custNumberError,
      accNumberError,
      phoneError,
      altPhoneError,
      faxError,
      zipError,
      zipErrorOne,
      zipErrorTwo,
      languageError,
      currencyCodeError,
      timezoneError,
    });
    var regexFax = "^[0-9]{5}(?:-[0-9]{4})?$";
    if (e.target.value.match(regexFax)) {
      setCustomerAltAddressZipTwo(e.target.value);
      zipErrorTwo = "";
      setErrors({
        nameError,
        emailError,
        custNumberError,
        accNumberError,
        phoneError,
        altPhoneError,
        faxError,
        zipError,
        zipErrorOne,
        zipErrorTwo,
        languageError,
        currencyCodeError,
        timezoneError,
      });
      return true;
    } else {
      zipErrorTwo = "Zip code is invalid";
    }
    if (zipErrorTwo) {
      setErrors({
        nameError,
        emailError,
        custNumberError,
        accNumberError,
        phoneError,
        altPhoneError,
        faxError,
        zipError,
        zipErrorOne,
        zipErrorTwo,
        languageError,
        currencyCodeError,
        timezoneError,
      });
      return false;
    }
  };
  const handleCustomerAltAddressCountryTwo = (e: any) => {
    //setCustomerAltAddressCountryTwo(e.target.value);
    const countryCode = JSON.parse(e.target.value);
    setCustomerAltAddressCountryTwo(countryCode.countryName);
    const getEndpoint = getStatesByCountry(countryCode.isoCountryCode);
    handleGetStatesListTwo(getEndpoint);
  };
  const handleGetStatesListTwo = async (getEndpoint: string) => {
    const getresult = await axios.get(getEndpoint);
    let cData: any = getresult.data;
    dispatch(setStatesAddrDataTwo(cData));
  };
  const customerAddedFinalCT: any[] = [];
  const body = {
    accountNumber: customerAccountNumber,
    active: true,
    address: [
      {
        addressLine1: "",
        addressLine2: "",
        alternateAddress: false,
        city: customerAddressCity,
        country: customerAddressCountry,
        deleted: false,
        state: customerAddressState,
        street: customerAddressStreet,
        zipcode: customerAddressZip,
        billingAddress: customerDefaultBillAddress,
      },
      {
        addressLine1: "",
        addressLine2: "",
        alternateAddress: true,
        city: customerAltAddressCityOne,
        country: customerAltAddressCountryOne,
        deleted: false,
        state: customerAltAddressStateOne,
        street: customerAltAddressStreetOne,
        zipcode: customerAltAddressZipOne,
        billingAddress: customerDefaultBillAddress,
      },
      {
        addressLine1: "",
        addressLine2: "",
        alternateAddress: true,
        city: customerAltAddressCityTwo,
        country: customerAltAddressCountryTwo,
        deleted: false,
        state: customerAltAddressStateTwo,
        street: customerAltAddressStreetTwo,
        zipcode: customerAltAddressZipTwo,
        billingAddress: customerDefaultBillAddress,
      },
    ],
    phoneCountryCode: phoneCountryCode,
    altPhoneCountryCode: AltPhoneCountryCode,
    faxCountryCode: faxCountryCode,
    alternatePhoneNumber: altMobileWithFlag?.toString().replace(/[^0-9]/g, ""),
    billingMode: customerBillingMode,
    clientId: 1,
    companyId: 33,
    currencyCode: customerCurrencyCode,
    customerName: customerName,
    customerNumber: customerNumber,
    customerType: customerAddedFinalCT,
    deleted: false,
    email: customerEmail,
    fax: faxWithFlag?.toString().replace(/[^0-9]/g, ""),
    language: customerLanguage,
    notes: "",
    paymentTerms: customerPaymentTerms,
    phoneNumber: mobileWithFlag?.toString().replace(/[^0-9]/g, ""),
    timezone: customerTimeZone,
    prepaid: customerPrepaid,
  };

  let tabName: any = ContactType.CUSTOMERS;
  const handleAddCustomer = () => {
    //if (validate()) {
      customerTypeLinkToCustomer.map((item) =>
        customerAddedFinalCT.push(item.customerType)
      );
      const endpoint = addMasterDataEndPoint(tabName);
      const getEndpoint = getEndPoint(tabName);
      console.log(customerInfo)
      handleMasterDataChange(endpoint, getEndpoint);
   // }
  };

  const handleMasterDataChange = async (
    endpoint: string,
    getEndpoint: string
  ) => {
    const reqbody = {
      accountNumber: customerInfo[0].accountNumber,
      active: true,
      address: [
        {
          addressLine1: "",
          addressLine2: "",
          alternateAddress: false,
          city: customerAddressCity,
          country: customerAddressCountry,
          deleted: false,
          state: customerAddressState,
          street: customerAddressStreet,
          zipcode: customerAddressZip,
          billingAddress: customerDefaultBillAddress,
        },
        {
          addressLine1: "",
          addressLine2: "",
          alternateAddress: true,
          city: customerAltAddressCityOne,
          country: customerAltAddressCountryOne,
          deleted: false,
          state: customerAltAddressStateOne,
          street: customerAltAddressStreetOne,
          zipcode: customerAltAddressZipOne,
          billingAddress: customerDefaultBillAddress,
        },
        {
          addressLine1: "",
          addressLine2: "",
          alternateAddress: true,
          city: customerAltAddressCityTwo,
          country: customerAltAddressCountryTwo,
          deleted: false,
          state: customerAltAddressStateTwo,
          street: customerAltAddressStreetTwo,
          zipcode: customerAltAddressZipTwo,
          billingAddress: customerDefaultBillAddress,
        },
      ],
      phoneCountryCode: phoneCountryCode,
      altPhoneCountryCode: AltPhoneCountryCode,
      faxCountryCode: faxCountryCode,
      alternatePhoneNumber: altMobileWithFlag?.toString().replace(/[^0-9]/g, ""),
      billingMode: customerInfo[0].billingMode,
      clientId: 1,
      companyId: 33,
      currencyCode: customerCurrencyCode,
      customerName: customerInfo[0].customerName,
      customerNumber: customerInfo[0].customerNumber,
      customerType: customerAddedFinalCT,
      deleted: false,
      email: customerInfo[0].email,
      fax: faxWithFlag?.toString().replace(/[^0-9]/g, ""),
      language: customerLanguage,
      notes: "",
      paymentTerms: customerPaymentTerms,
      phoneNumber: mobileWithFlag?.toString().replace(/[^0-9]/g, ""),
      timezone: customerTimeZone,
      prepaid: customerInfo[0].prepaid,
    };
    const result = await axios
      .post(endpoint, reqbody)
      .then((results: any) => {
        if (results && results.data) {
          setAddScreen(false);
          notify("Customer created successfully", "success");
        } else {
          notify("Failed to create Customer", "error");
        }
      })
      .catch((error: any) => {
        if (error.response) {
          notify(error.response?.data?.message, "error");
        }
      });
    const getresult = await axios.get(getEndpoint);
    dispatch(setCurrentContact(getresult.data));
  };

  const handleCancelCustomerPopup = () => {
    setAddScreen(false);
    dispatch(setMasterDataAddImage([]));
  };
  const validate = (field?: any, value?: any) => {
    if (customerName === "") {
      nameError = "Required Field";
      setMynameCust("1px solid red");
    } else {
      setMynameCust("");
    }
    if (customerLanguage === "") {
      languageError = "Language is required";
    }
    if (customerCurrencyCode === "") {
      currencyCodeError = "Currency code is required";
    }
    if (customerTimeZone === "") {
      timezoneError = "Timezone is required";
    }
    // if (customerNumber === "") {
    //   custNumberError = "Required Field";
    //   setMyCustomerNumber("1px solid red");
    // } else {
    //   setMyCustomerNumber("");
    // }
    if (customerAccountNumber === "") {
      accNumberError = "Required Field";
      setMyAccount("1px solid red");
    } else {
      setMyAccount("");
    }
    // if (customerPhone === "") {
    //   phoneError = "Required Field";
    //   setMyPhoneCust("1px solid red");
    // } else {
    //   setMyPhoneCust("");
    // }
    if (customerFax != "") {
      var phoneno = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
      if (customerFax && phoneno.test(customerFax) === false) {
        faxError = "Fax is Invalid";
      }
    }
    // if (customerEmail === "") {
    //   emailError = "Email is Required";
    //   setMyEmailCust("1px solid red");
    // } else {
    //   setMyEmailCust("");
    // }
    if (
      emailError ||
      nameError ||
      custNumberError ||
      accNumberError ||
      zipError ||
      zipErrorOne ||
      zipErrorTwo ||
      languageError ||
      currencyCodeError ||
      timezoneError
    ) {
      setErrors({
        nameError,
        emailError,
        custNumberError,
        accNumberError,
        phoneError,
        altPhoneError,
        faxError,
        zipError,
        zipErrorOne,
        zipErrorTwo,
        languageError,
        currencyCodeError,
        timezoneError,
      });
      return false;
    }
    return true;
  };

  const handlePopupActionAddCustomer = (selectedTabName: any) => {
    uploadedImageFile = [];
    setFileUploadAddScreen(true);
    setAddScreenTabName(selectedTabName);
  };

  let uploadedImageFile = useSelector(
    (state: RootState) => state.contactReducer.uploadedImage
  );

  const [addScreen, setFileUploadAddScreen] = useState(false);
  const [addScreenTabName, setAddScreenTabName] = useState(null);
  const [screenType, setScreenType] = useState("masterdataAdd");

  let [stepperindexvalue, setStepperIndexValue] = useState(1);
  
  const goToNextStep = (index: number) => {
    if (index === 1) {
      setStepperIndexValue(1);
    } else if (index === 2) {
      setStepperIndexValue(2);
    } else if (index === 3) {
      setStepperIndexValue(3);
    }
  };

  return (
    <div className="commodity-popup" style={{marginRight: '0'}}>
      <div className="StepperControl">
        <div className="col-6 pl-2 pt-0 mt-auto mb-auto">
          <div className="page-header--col col--steps">
            <ul className="list--steps pt-3 mb-0 pointer">
              <li
                className={` ${stepperindexvalue === 1 ? "active" : ""}`}
                onClick={(e) => goToNextStep(1)}
              >
                <strong className="steps--number">1</strong>
                <p className="steps--label"></p>
              </li>
              <li
                className={` ${stepperindexvalue === 2 ? "active" : ""}`}
                onClick={(e) => goToNextStep(2)}
              >
                <strong className="steps--number">2</strong>
                <p className="steps--label"></p>
              </li>
              <li
                className={` ${stepperindexvalue === 3 ? "active" : ""}`}
                onClick={(e) => goToNextStep(3)}
              >
                <strong className="steps--number">3</strong>
                <p className="steps--label"></p>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="row">
      {addScreen && (
        <AddFileUploadScreen
          addScreen={addScreen}
          setFileUploadAddScreen={setFileUploadAddScreen}
          tabName={addScreenTabName}
          screenType={screenType}
        />
      )}
        <div className="row d-flex justify-content-center">
          <div className="col-xl-2 col-lg-2 roundImage">
          {/* <input accept="image/*" type="file" onChange={imageChange} /> */}
          {/* <img src={userIcon} className="userAddIcon" />
          {selectedImage && (
            <div className="imagePreviewDiv">
              <img
                src={URL.createObjectURL(selectedImage)}
                className="imagePreview"
                height="100"
                width="100"
              />
            </div>
          )} */}
          {uploadedImageFile && uploadedImageFile.length > 0 ? (
            <div className="ImageContainer">
                <img
                  src={URL.createObjectURL(uploadedImageFile[0].file)}
                  className="userAddIcon"
                  height="140"
                  width="140"
                /></div>
              ) : (
                <img src={userIcon} className="userAddIcon" />
              )}
              <div className="middle">
              <img
                src={editIcon}
                alt="edit "
                width="18"
                height="18"
                className="m-3 pointer avatarEdit"
                onClick={() => handlePopupActionAddCustomer("fileUpload")}
                style={{position: 'sticky'}}
              /></div>
        </div>
        </div>
        </div>
        <div>
        {stepperindexvalue === 1 ? (
          <div>
        <div className="col-sm-12 row m-0 mt-3"> 
          <div className="col-sm-6">
            <div className="col-sm-12">
              <span className="lblClass" style={{float: 'left', marginTop:'8px'}}>
                Name<span className="requiredStar">*</span>
              </span>
              {customerInfo[0].customerName === "" ? (
                <span className="text-danger" style={{float: 'right', marginTop: '8px'}}>{errors.nameError}</span>
              ) : (
                ""
              )}
              <input
                type="text"
                className="formcontrol"
                id="txtCustomerName"
                onChange={(e) => handleCustomerTextFields(e, 'customerName')}
                value={customerInfo[0].customerName}
                style={{ border: myCustomerName }}
              />
            </div>
            <div className="col-sm-12">
              <span className="lblClass" style={{float: 'left', marginTop:'8px'}}>Email
                {/* <span className="requiredStar">*</span> */}
              </span>
              {emailErrorMsg !== '' ? (
                <span className="text-danger mt-3" style={{float: 'right'}}>{emailErrorMsg}</span>
              ) : (
                ""
              )}
              <input
                type="text"
                className="formcontrol"
                placeholder="someone@email.com"
                onChange={(e) => handleCustomerTextFields(e, 'email')}
                onKeyUp={(e) => handleCustomerTextFields(e, 'email')}
                onBlur={(e) => handleCustomerTextFields(e, 'email')}
                value={customerInfo[0].email}
                style={{ border: myEmailCust }}
              />
            </div>
              <div className="col-sm-12">
                <span className="lblClass" style={{float: 'left', marginTop:'8px'}}>Customer Number
                  {/* <span className="requiredStar">*</span> */}
                </span>
                <input
                  type="text"
                  className="formcontrol"
                  onChange={(e) => handleCustomerTextFields(e, 'customerNumber')}
                  value={customerInfo[0].customerNumber}
                  style={{ border: myCustomerNumber }}
                />
                {customerNumber === "" ? (
                  <span className="text-danger">{errors.custNumberError}</span>
                ) : (
                  ""
                )}
              </div>
              <div className="col-sm-12">
                <span className="lblClass" style={{float: 'left', marginTop:'8px'}}>
                  Account Number<span className="requiredStar">*</span>
                </span>
                <input
                  type="text"
                  className="formcontrol"
                  onChange={(e) => handleCustomerTextFields(e, 'accountNumber')}
                  value={customerInfo[0].accountNumber}
                  style={{ border: myAccountNumber }}
                />
                {customerInfo[0].accountNumber === "" ? (
                  <span className="text-danger">{errors.accNumberError}</span>
                ) : (
                  ""
                )}
              </div>          
          </div>
          <div className="col-sm-6">          
            <div className="col-sm-12">
              <span className="lblClass" style={{float: 'left', marginTop:'8px'}}>Address</span>
              <input
                type="text"
                className="form-control"
                placeholder="Street"
                onChange={(e) => handleCustomerTextFields(e, 'addressLine1')}
                value={customerInfo[0].addressLine1}
              />
            </div>
            
            <div className="col-sm-12 mt-3">
              <input
                type="text"
                className="form-control"
                placeholder="City"
                onChange={(e) => handleCustomerTextFields(e, 'addressLine2')}
                value={customerInfo[0].addressLine2}
              />
            </div>
            <div className="col-sm-12 mt-3">
              <select
                id="ddlCountry"
                className="form-control"
                //value={customerInfoSelectFieldObj[0].country}
                onChange={(e: any) => handleCustomerSelectFields(e, "countryId")}
              >
                {customerInfoSelectFieldObj[0].countryId !== "" ?
                <option value={customerInfoSelectFieldObj[0].countryId}>{customerInfoSelectFieldObj[0].countryId}</option>
                : <option value="">Select Country</option> }
                {countryInfo?.map((cn) => (
                  <option value={JSON.stringify(cn)}>{cn.countryName}</option>
                ))}
              </select>
            </div>
          
          <div className="col-sm-12 mt-3" style={{paddingLeft: '13px', paddingRight: '13px'}}>
            <div className="col-sm-8 p-1">
              <select
                id="ddlCountry"
                className="form-control"
                //value={customerInfoSelectFieldObj[0].stateId}
                onChange={(e: any) => handleCustomerSelectFields(e, "stateId")}
              >
                {customerInfoSelectFieldObj[0].stateId !== "" ?
                <option value={customerInfoSelectFieldObj[0].stateId}>{customerInfoSelectFieldObj[0].stateId}</option>
                : <option value="">Select State</option> }
                {statesList?.map((cn:any) => (
                  <option value={cn.stateName}>{cn.stateName}</option>
                ))}
              </select>
            </div>
            <div className="col-sm-4 p-1">
              <input
                type="text"
                className="form-control"
                placeholder="ZIP"
                onChange={(e) => handleCustomerTextFields(e, 'zipcode')}
                value={customerInfo[0].zipcode}
              />
              {zipError === "" ? (
                <span className="text-danger">{errors.zipError}</span>
              ) : (
                ""
              )}
            </div>
          </div>
          <div className="col-sm-12 p-0" style={{height: '200px', overflow: 'auto'}}>
          <div className="col-sm-12 altAddreesDiv" style={{ overflowY: "visible" }}>
            <div className="row ml-0 mr-0 mt-1rem">
                              
                <div className="col-sm-12 pl-2">
                  <span
                    className="lblClass cursor-pointer mt-0"
                    style={{ float: "left" }}
                  >
                    <input
                      checked={checkBox}
                      className="pr-2"
                      type="checkbox"
                      onChange={(e) => handleCustomerDefaultBillingAddr(e)}
                    />{" "}
                    <span className="defaultAdd" style={{marginTop: '8px'}}>
                      Default Billing Address
                    </span>
                  </span>
                </div>
              
                <div className="col-sm-12 pl-2">
                  {showAltAddressInfo ? (
                    <span
                      className="lblClass cursor-pointer"
                      style={{ float: "left", marginTop: '8px'}}
                      onClick={() => handleAltAdressClose()}
                    >
                      <img className="" src={closeIcon} />{" "}
                      <span>Alt Address</span>
                    </span>
                  ) : (
                    <span
                      className="lblClass cursor-pointer"
                      style={{ float: "left" }}
                      onClick={() => handleAltAdress()}
                    >
                      <img className="addIconImgAltAddress" src={addIcon} />{" "}
                      <span>Alt Address</span>
                    </span>
                  )}
                </div>              
            </div>

            {showAltAddressInfo ? (
              <div className="row ml-0 mr-0 mt-1rem">
                <div className="col-sm-12 p-0">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Street"
                    onChange={(e) => handleCustomerTextFieldsAlt(e, 'addressLine1')}
                    value={customerInfoAlt[0].addressLine1}
                  />
                  <div className="row ml-0 mr-0 mt-1rem">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="City"
                      onChange={(e) => handleCustomerTextFieldsAlt(e, 'addressLine2')}
                      value={customerInfoAlt[0].addressLine2}
                    />
                  </div>
                  <div className="row ml-0 mr-0 mt-1rem">
                    <select
                      id="ddlCountry"
                      className="form-control"
                      onChange={(e) => handleCustomerSelectFieldsAlt(e, "countryId")}
                    >
                      {customerInfoSelectFieldObjAlt[0].countryId !== "" ?
                      <option value={customerInfoSelectFieldObjAlt[0].countryId}>{customerInfoSelectFieldObjAlt[0].countryId}</option>
                      : <option value="">Select Country</option> }
                      {countryInfo?.map((cn) => (
                        <option value={JSON.stringify(cn)}>
                          {cn.countryName}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="row ml-0 mr-0 mt-1rem">
                    <div className="col-sm-8 pr-0 pl-0">
                      <select
                        id="ddlCountry"
                        className="form-control"
                        onChange={(e) => handleCustomerSelectFieldsAlt(e, "stateId")}
                      >
                        {customerInfoSelectFieldObjAlt[0].stateId !== "" ?
                        <option value={customerInfoSelectFieldObjAlt[0].stateId}>{customerInfoSelectFieldObjAlt[0].stateId}</option>
                        : <option value="">Select State</option> }
                        {statesListAltOne?.map((cn:any) => (
                          <option value={cn.stateName}>{cn.stateName}</option>
                        ))}
                      </select>
                    </div>
                    <div className="col-sm-4 pr-0">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="ZIP"
                        onChange={(e) => handleCustomerTextFieldsAlt(e, 'zipcode')}
                        value={customerInfoAlt[0].zipcode}
                      />
                      {zipErrorOne === "" ? (
                        <span className="text-danger">
                          {errors.zipErrorOne}
                        </span>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>
                <div className="row ml-0 mr-0 mt-1rem">                   
                    <div className="col-sm-12 pl-2">
                      <span
                        className="lblClass cursor-pointer"
                        style={{ float: "left" }}
                      >
                        <input
                          type="checkbox"
                          onChange={(e) =>
                            handleCustomerDefaultBillingAddrTwo(e)
                          }
                        />{" "}
                        <span className="defaultAdd" style={{marginTop: '8px'}}>
                          Default Billing Address
                        </span>
                      </span>
                    </div>
                  
                    <div className="col-sm-12 pl-2">
                      {showAltAddressInfoOne ? (
                        <span
                          className="lblClass cursor-pointer"
                          style={{ float: "left", marginTop: '8px' }}
                          onClick={() => handleAltAdressCloseOne()}
                        >
                          <img className="" src={closeIcon} />{" "}
                          <span>Alt Address</span>
                        </span>
                      ) : (
                        <span
                          className="lblClass cursor-pointer"
                          style={{ float: "left" }}
                          onClick={() => handleAltAdressOne()}
                        >
                          <img className="addIconImgAltAddress" src={addIcon} />{" "}
                          <span>Alt Address</span>
                        </span>
                      )}
                    </div>
                  
                </div>
              </div>
            ) : (
              <div></div>
            )}

            {showAltAddressInfoOne ? (
              <div className="row ml-0 mr-0 mt-1rem">
                <div className="col-sm-12 p-0">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Street"
                    onChange={(e) => handleCustomerTextFieldsAltAddrTwo(e, 'addressLine1')}
                    value={customerInfoAltAddrTwo[0].addressLine1}
                  />
                  <div className="row ml-0 mr-0 mt-1rem">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="City"
                      onChange={(e) => handleCustomerTextFieldsAltAddrTwo(e, 'addressLine2')}
                      value={customerInfoAltAddrTwo[0].addressLine2}
                    />
                  </div>
                  <div className="row ml-0 mr-0 mt-1rem">
                    <select
                      id="ddlCountry"
                      className="form-control"
                      onChange={(e) => handleCustomerSelectFieldsAltAddrTwo(e, 'countryId')}
                    >
                      {customerInfoSelectFieldObjAltAddrTwo[0].countryId !== "" ?
                      <option value={customerInfoSelectFieldObjAltAddrTwo[0].countryId}>{customerInfoSelectFieldObjAltAddrTwo[0].countryId}</option>
                      : <option value="">Select Country</option> }
                      {countryInfo?.map((cn) => (
                        <option value={JSON.stringify(cn)}>
                          {cn.countryName}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="row ml-0 mr-0 mt-1rem">
                    <div className="col-sm-8 pr-0 pl-0">
                      <select
                        id="ddlCountry"
                        className="form-control"
                        onChange={(e) => handleCustomerSelectFieldsAltAddrTwo(e, 'stateid')}
                      >
                        {customerInfoSelectFieldObjAltAddrTwo[0].stateId !== "" ?
                        <option value={customerInfoSelectFieldObjAltAddrTwo[0].stateId}>{customerInfoSelectFieldObjAltAddrTwo[0].stateId}</option>
                        : <option value="">Select State</option> }
                        {statesListAltTwo?.map((cn:any) => (
                          <option value={cn.stateName}>{cn.stateName}</option>
                        ))}
                      </select>
                    </div>
                    <div className="col-sm-4 pr-0">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="ZIP"
                        onChange={(e) => handleCustomerTextFieldsAltAddrTwo(e, 'zipcode')}
                        value={customerInfoAltAddrTwo[0].zipcode}
                      />
                      {zipErrorTwo === "" ? (
                        <span className="text-danger">
                          {errors.zipErrorTwo}
                        </span>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                  <div className="row ml-0 mr-0 mt-1rem">
                    <div className="row">
                      <div className="col-sm-12">
                        <span
                          className="lblClass cursor-pointer"
                          style={{ float: "left" }}
                        >
                          <input
                            className="pl-2"
                            type="checkbox"
                            onChange={(e) =>
                              handleCustomerDefaultBillingAddr(e)
                            }
                          />{" "}
                          <span className="defaultAdd" style={{marginTop: '8px'}}>
                            Default Billing Address
                          </span>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div></div>
            )}
          </div>
          </div>
          </div>        
        </div>
        <div className="col-sm-12 px-0">
                  <ButtonComponent
                    cssClass="eg-btn-primary float-right pt-3 pb-3 pl-5 pr-5"                    
                    onClick={() => goToNextStep(2)}
                    style={
                      customerInfo[0].customerName !== '' &&
                      customerInfo[0].accountNumber !== '' &&
                      emailErrorMsg === ""
                        ? {
                            opacity: "1",
                            pointerEvents: "all",
                            cursor: "pointer",
                            width: '168px',
                          }
                        : {
                            opacity: "0.5",
                            pointerEvents: "none",
                            backgroundColor: "#e8e8e8",
                            color: "#969696",
                          }
                    }
                  >
                    Next
                  </ButtonComponent>
                  <ButtonComponent
                    cssClass="eg-btn-primary-link e-flat mt-3"
                    onClick={() => handleCancelCustomerPopup()}
                  >
                    Cancel
                  </ButtonComponent>
                </div>
        </div>
        ) : (
          <div>
          {stepperindexvalue === 2 ? (
            <div>
              <div className="col-sm-12 row m-0 mt-3">
                <div className="col-sm-6">
                  <div className="col-sm-12">
                <span className="lblClass" style={{float: 'left'}}>Customer Type</span>
                <select
                  className="form-control"
                  id="companyTypeLinkDropDown"
                  // value={customerInfoSelectFieldObj[0].customerType}
                  onChange={(e: any) => handleCustomerSelectFields(e, "customerType")}
                >
                  <option value={customerInfoSelectFieldObj[0].customerType}>Select Customer Type</option>
                  {customerTypeData?.map((ct) => (
                    <option value={JSON.stringify(ct)}>
                      {ct.customerType}
                    </option>
                  ))}
                </select>
              </div>
              <div className="col-sm-12 linkedStoreDataDiv">
              <div className="col-sm-12 custTypeDivAdd">
                {customerTypeLinkToCustomer &&
                  customerTypeLinkToCustomer.map((item: any) => (
                    <div className="linkedStoreDiv">
                      <span title="remove" className="linkedStoreName">
                        {item.customerType}
                      </span>{" "}
                      <span
                        className="linkedStoreNameRemove"
                        onClick={(e) => removeLinkedCTData(item.id)}
                      >
                        X
                      </span>
                    </div>
                  ))}
              </div>
            </div>
            </div>
                <div className="col-sm-6 mt-2">
              <div className="col-sm-12">
                <span className="lblClass billingmode" style={{float: 'left'}}>
                  Default Billing Mode
                </span>
              </div>
              <div
                className="col-sm-12"
                onChange={(e) => handleCustomerBillingMode(e)}
              >
                {billingModeData?.map((bm) => (
                  <>
                    <input
                      type="radio"
                      name="billingType"
                      value={bm.billingMode}
                    />{" "}
                    <span> {bm.billingMode}</span>
                    <br />
                  </>
                ))}
              </div>
              <div className="col-sm-12">
              
                <span className="lblClass" style={{float: 'left', marginTop: '10px'}}>Prepaid</span>             
              
                {/* <SwitchComponent onChange={(e: any) => handleSwitchCustomerPrepaid(e)} /> */}
                <input
                  type="checkbox"
                  id="salvager"
                  className="toggle"
                  onChange={(e: any) => handleSwitchCustomerPrepaid(e)}
                />
                <label className="chkbx" htmlFor="salvager" style={{marginTop: '15px', left: '6px'}}></label>
              
              </div>
                </div>
                </div>
          <div className="col-sm-12 px-0 my-5">
                  <ButtonComponent
                    cssClass="eg-btn-primary float-right pt-4 pb-4 pl-5 pr-5"                    
                    onClick={() => goToNextStep(3)}
                    style={
                      customerTypeData?.length > 0
                        ? {
                            opacity: "1",
                            pointerEvents: "all",
                            cursor: "pointer",
                            width: '168px',
                          }
                        : {
                            opacity: "0.5",
                            pointerEvents: "none",
                            backgroundColor: "#e8e8e8",
                            color: "#969696",
                            
                          }
                          
                    }                   
                  >
                    Next
                  </ButtonComponent>
                  <ButtonComponent
                      cssClass="eg-btn-secondary float-right mr-4"
                      onClick={() => goToNextStep(1)}
                    >
                      Back
                    </ButtonComponent>
                  <ButtonComponent
                    cssClass="eg-btn-primary-link e-flat mt-3"
                    onClick={() => handleCancelCustomerPopup()}
                  >
                    Cancel
                  </ButtonComponent>
                </div>
          </div>          
          ) : (
            <div>
              <div className="col-sm-12 row m-0 mt-3">
                <div className="col-sm-6">
              
            <div className="col-sm-12" style={{ paddingRight: "7px" }}>
              <span className="lblClass" style={{float: 'left', marginTop:'8px'}}>Phone
                {/* <span className="requiredStar">*</span> */}
              </span>
            </div>
            <div className="col-sm-12">
              <CountryDropdown type="Phone" />
              {/* <div className="col-sm-4 pl-0 pr-0">
            <select className="form-control" onChange={(e: any) => handleChangeCustomerCountryCodePhoneRC(e)}>
                  <option>Select</option>
                  {countryCodeInfo?.map((ld) =>
                    <option value={ld?.mobileCountryCode}>{ld?.mobileCountryCode}</option>
                  )}
            </select>
            </div>
            <div className="col-sm-8 pl-0 pr-0">
            <input type="text" className="formcontrol" onChange={(e) => handleCustomerPhone(e)} style={{border:myPhoneCust}}/>
            {errors.phoneError?<span className="text-danger">{errors.phoneError}</span>:""}
            </div> */}
            </div>
          
            
            <div className="col-sm-12">
              <span className="lblClass" style={{float: 'left', marginTop:'8px'}}>Alt Phone</span>
            </div>
            <div className="col-sm-12">
              <CountryDropdown type="Alt Phone" />
              {/* <div className="col-sm-4 pl-0 pr-0">
            <select className="form-control" onChange={(e: any) => handleChangeCustCountryAltPhoneRC(e)}>
                  <option>Select</option>
                  {countryCodeInfo?.map((ld) =>
                    <option value={ld?.mobileCountryCode}>{ld?.mobileCountryCode}</option>
                  )}
            </select>
            </div>
            <div className="col-sm-8 pl-0 pr-0">
            <input type="text" className="form-control" onChange={(e) => handleCustomerAltPhone(e)} />
            {errors.altPhoneError?<span className="text-danger">{errors.altPhoneError}</span>:""}
            </div> */}
            </div>
            
            
            <div className="col-sm-12">
              <span className="lblClass" style={{float: 'left', marginTop:'8px'}}>Fax</span>
            </div>
            <div className="col-sm-12">
              <CountryDropdown type="Fax" />
              {/* <div className="col-sm-4 pl-0 pr-0">
            <select className="form-control" onChange={(e: any) => handleChangeCustCountryCodeFaxRC(e)}>
                  <option>Select</option>
                  {countryCodeInfo?.map((ld) =>
                    <option value={ld?.mobileCountryCode}>{ld?.mobileCountryCode}</option>
                  )}
            </select>
            </div>
            <div className="col-sm-8 pl-0 pr-0">
            <input type="text" className="form-control" onChange={(e) => handleCustomerFax(e)} />
            {errors.faxError?<span className="text-danger">{errors.faxError}</span>:""}
            </div> */}
            </div>
            
            <div>           
            {/* <div className="row ml-0 mr-0 mt-1rem">
              <div className="col-sm-4">
                <span className="lblClass">Payment Terms</span>
              </div>
              <div className="col-sm-8">
                <select className="form-control" onChange={(e) => handleCustomerPaymentTerms(e)}>
                  <option value="0">Select payment terms</option>
                  {paymentTermsData.map((pt) =>
                <option value={pt.paymentTerm}>{pt.paymentTerm}</option>
                )}
                </select>
              </div>
            </div> */}           
            </div>
            </div>
            <div className="col-sm-6">
           
            <div className="col-sm-12">
              <span className="lblClass" style={{float: 'left', marginTop:'8px'}}>
                Language<span className="requiredStar">*</span>
              </span>
              <select
                className="form-control"
                value={customerInfoSelectFieldObj[0].language}
                onChange={(e: any) => handleCustomerSelectFields(e, "language")}
              >
                {languageData?.map((ld) => (
                  <option value={ld?.isoLongCode}>{ld?.isoLongCode}</option>
                ))}
              </select>
              {customerLanguage === "" ? (
                <span className="text-danger">{errors.languageError}</span>
              ) : (
                ""
              )}
            </div>
            
            
            <div className="col-sm-12">
              <span className="lblClass" style={{float: 'left', marginTop:'8px'}}>
                Currency Code<span className="requiredStar">*</span>
              </span>
              <select
                className="form-control"
                value={customerInfoSelectFieldObj[0].currencyCode}
                onChange={(e: any) => handleCustomerSelectFields(e, "currencyCode")}
              >
                {currencyData?.map((cc) => (
                  <option value={cc.currency}>{cc.currency}</option>
                ))}
              </select>
              {customerCurrencyCode === "" ? (
                <span className="text-danger">{errors.languageError}</span>
              ) : (
                ""
              )}
            </div>
            
          
            <div className="col-sm-12">
              <span className="lblClass" style={{float: 'left', marginTop:'8px'}}>
                Time Zone<span className="requiredStar">*</span>
              </span>
              <select
                className="form-control"
                value={customerInfoSelectFieldObj[0].timeZone}
                onChange={(e: any) => handleCustomerSelectFields(e, "timeZone")}
              >
                {timezoneData?.map((tz) => (
                  <option value={tz.timezoneName}>
                    {tz.timezoneName}
                    {tz.utc}
                  </option>
                ))}
              </select>
              {customerTimeZone === "" ? (
                <span className="text-danger">{errors.languageError}</span>
              ) : (
                ""
              )}
            </div>
          
        </div>
        </div>
        
      
        <div className="col-sm-12 px-0" style={{marginTop: '7rem'}}>
        <ButtonComponent
          cssClass="eg-btn-primary float-right"
          onClick={() => handleAddCustomer()}
          style={
            isCustomerScreenDisabeled
              ? {
                  opacity: "0.5",
                  pointerEvents: "none",
                  backgroundColor: "#e8e8e8",
                  color: "#969696",
                  borderRadius: "4px",
                }
              : {
                  opacity: "1",
                  borderRadius: "4px",
                  pointerEvents: "all",
                  backgroundColor: "#636799",
                  color: "#ffffff",
                  cursor: "pointer",
                }
          }
        >
          ADD CUSTOMER
        </ButtonComponent>
        <ButtonComponent
          cssClass="eg-btn-secondary float-right mr-4"
          onClick={() => goToNextStep(2)}
        >
          Back
        </ButtonComponent>
        <ButtonComponent
          cssClass="eg-btn-primary-link e-flat mt-3"
          onClick={() => handleCancelCustomerPopup()}
        >
          Cancel
        </ButtonComponent>
      </div>
      </div>
          )}
      </div>
        )}
      </div>
    </div>
  );
};
export default CustomerAddScreen;
