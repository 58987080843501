import HttpService from '../http-service/http-service';
import { API_PATH, getApiBaseUrl } from '../../api-paths';
import { SearchCriteriaRequest } from '../../components/grid/gridConfig';

export interface Client {
    createdDate?: any;
    lastModifiedDate?: any;
    organizationId?: any;
    tenantId?: any;
    id: number;
    clientId: string;
    clientName: string;
}

export interface ARInvoiceGridView {
    carrierId: number;
    carrierName: string;
    cost: number;
    createdDate: string;
    credit: number;
    customerId: number;
    customerName: string;
    departmentName: string;
    description: string;
    extracted: boolean;
    glAccountName: string;
    id: string;
    invoiceNumber: number;
    lastModifiedDate: string;
    period: string;
    pickFromId: number;
    price: number;
    reclaimCenterId: string;
    reclaimCenterName: string;
    status: {};
    warehouseName: string;
}

export interface ARInvoiceResponse {
    result: ARInvoiceGridView[];
    count: number;
}

export default class ARInvoiceService {
    public async getFilteredItems(
        searchCriteria: SearchCriteriaRequest,
        param?: any
    ): Promise<ARInvoiceResponse> {
        const url = getApiBaseUrl() + API_PATH.AR_INVOICE_PATH;
        return HttpService.post(url, searchCriteria).then((response) => {
            return response.data;
        });
    }

    public async getFilteredCreditMemo(
        searchCriteria: SearchCriteriaRequest,
        param?: any
    ): Promise<ARInvoiceResponse> {
        const url = getApiBaseUrl() + API_PATH.CREDIT_MEMO_FILTER;
        return HttpService.post(url, searchCriteria).then((response) => {
            return response.data;
        });
    }

    public async getItem(id: number) {
        const url = getApiBaseUrl() + API_PATH.AR_INVOICE_DETAILS + id;
        return HttpService.get(url).then((response) => {
            return response.data;
        });
    }
}
