import {FC, useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useHistory} from 'react-router-dom';
import {DashboardLayoutComponent} from '@syncfusion/ej2-react-layouts';
import * as _ from "lodash";
import {TabComponent, TabItemDirective, TabItemsDirective,} from '@syncfusion/ej2-react-navigations';
import {
    bolAttachmentGridConfig,
    bolAttachmentsTabs,
    invoiceAttachmentGridConfig,
    invoiceAttachmentsTabs,
} from './config';
import ContentHeader from '../../common/ContentHeader/ContentHeader';
import {ButtonComponent} from '@syncfusion/ej2-react-buttons';
import {DropDownListComponent} from '@syncfusion/ej2-react-dropdowns';
import InvoiceDetailsTabControl from './InvoiceDetailsControl';
import Divider from '../../common/divider/Divider';
import InvoiceTable from './InvoiceTable';
import StatusButton from './StatusButton';
import StatusButtonBol from './StatusButtonBol';
import AttachmentTabControls from '../attachmentControls/AttachmentControls';
import InputCollection from '../../common/InputCollection.tsx/InputCollection';
import InputCollectionBol from '../../common/InputCollection.tsx/InputCollectionBol';
import Grid from '../../grid/Grid';
import NoCheckBoXGrid from '../../grid/fiscal-year-setup-grid/fiscal-year-setup-grid';
import SingleSelectGrid from '../../grid/SingleSelectGrid';
import LLModal from '../../common/llModal/LLModal';
import FixedItemDetailsForm from './FixedItemDetailsForm/FixedItemDetailsForm';
import ManualEntryForm from './ManualEntryForm/ManualEntryForm';
import {RootState} from '../../../redux/store';
import {addAttachmentGridConfig} from '../InvoiceDetails/config';
import {Internationalization} from '@syncfusion/ej2-base';
import {actionHistoryGridConfig, bolUrl, invoiceGridConfig, InvoiceScreen, invoiceView,} from '../config';
import './InvoiceDetails.scss';
import CreditMemos from '../CreditMemos/CreditMemos';
import {
    setArInvoiceSavedata,
    setBOLAttachementIconsStatus,
    setBOLDataByIddata,
    setBOLNumberForAdd,
    setCMIdForStatusUpdate,
    setCreditMemoSavedata,
    setFpiClearGridRow,
    setIsBOLDataClear,
    setIsInvoiceClear,
    setIsInvoiceSaved,
    setIsInvoiceUpdated,
    setIsManualEntryFormClear,
    setNewSavedInvoiceList,
    setScannedPalletsInfo,
    setSelectedArInvoiceTabIndex,
    setSelectedInvoiceScreen,
} from '../invoiceReducer';
import {
    addBOLEndPoint,
    addFixedPriceEndPoint,
    addManualEntryEndPoint,
    addManualEntryEndPointUpdate,
    addSalvageItemEndPoint,
    deleteBOLEndPoint,
    deleteInvoiceEndPoint,
    getAddToBolEndpoint,
    getARInvoicesListEndPointForBOL,
    getArInvoiceUpdateStatus,
    getBOLActionHistory,
    getBOLDataByIdEndPoint,
    getBOLListEndPointForInvoice,
    getBOLNumForADD,
    getBOLStatusUpdate,
    getFPIListEndPoint,
    getPalletsListEndPoint,
    getSaveCreditMemoEndpoint,
    getScannedPallets,
    getUpdateCreditMemoStatusEndpoint,
    SaveArInvoicesEndPoint,
    SaveArInvoicesNewEndPoint,
} from '../utils';
import axios from 'axios';
import BolDetailsTabControl from './BOLDetailsControl';
import CreditMemoTableDynamic from '../CreditMemos/CreditMemoTable/CreditMemoTableDynamic';
import notify from '../../../services/toasterService';
import StatusButtonCM from './StatusButtonCM';
import MiscSalvageItemForm from './MiscSalvageItemForm/MiscSalvageItemForm';
import FixedItemDetailsEditForm from './FixedItemDetailsForm/FixedItemDetailsEditForm';
import moment from 'moment';
import loader from '../../../assets/loader.gif';
import {setSelectedRecrdsExport} from '../../contactTabs/contactReducer';
import RouteLeavingGuard from './RouterPrompt';

interface IInvoiceDetails {
    backButtonText: string;
}

interface FPIDeatils {
    ComodityType: string;
    PricingType: string;
    //PricePerBoxBin: number;
}

const InvoiceDetails: FC<IInvoiceDetails> = ({
                                                 backButtonText = 'Back to invoices',
                                             }) => {
    const arInvoiceSavedata = useSelector(
        (store: RootState) => store.invoiceReducer.arInvoiceSaveData
    );
    const isInvoiceGetUpdated = useSelector(
        (store: RootState) => store.invoiceReducer.isInvoiceUpdated
    );
    const arInvoiceDefaultData = useSelector(
        (store: RootState) => store.invoiceReducer.arInvoiceDefaultData
    );
    const newSavedInvoiceList = useSelector(
        (store: RootState) => store.invoiceReducer.newSavedInvoiceList
    );
    const isInvoiceSaved = useSelector(
        (store: RootState) => store.invoiceReducer.isInvoiceSaved
    );
    const bolNumForNew = useSelector(
        (state: RootState) => state.invoiceReducer?.bolNumberForADD
    );
    const invoiceNumCM = useSelector(
        (state: RootState) => state.invoiceReducer?.invoiceNumberForCMView
    );
    const bolData = useSelector(
        (state: RootState) => state.invoiceReducer.bolDataById
    );
    let creditMemoInfo = useSelector(
        (state: RootState) => state.invoiceReducer.creditMemoSavedData
    );
    const customerIdForInvoicesBol = useSelector(
        (state: RootState) => state.invoiceReducer.customerIdForInvoiceInBol
    );
    const whIdForInvoicesBol = useSelector(
        (state: RootState) => state.invoiceReducer.warehouseIdForInvoiceInBol
    );
    const scannedPalInfo = useSelector(
        (state: RootState) => state.invoiceReducer.scannedPalletsInfo
    );

    const [selectedAttachmentTabIndex, setSelectedAttachmentTabIndex] =
        useState(0);
    const [
        selectedAttachmentTabIndexForBOL,
        setSelectedAttachmentTabIndexorBOL,
    ] = useState(0);
    const [creditMemoPrice, setCreditMemoPrice] = useState(0);
    const [isIndividualCMAdded, setIsIndividualCMAdded] = useState(false);
    const [FixedItemDetailsEditFormModel, setFixedItemDetailsEditFormModel] =
        useState(false);
    const [loaderStatus, setLoaderStatus] = useState(false);
    const [totalItemPrice, setTotalItemPrice] = useState(0);
    const [showErrorMessage, setShowErrorMessage] = useState(false);
    const [errorMessageText, setErrorMessageText] = useState('');
    const [bolDetailsData, setBolDetailsData] = useState<any>();
    const [invoiceDetailsData, setInvoiceDetailsData] = useState<any>();
    const [refreshGrid, setRefreshGrid] = useState(new Date().getTime());
    const [scannedPalletsItemsList, setScannedPalletsItemsList] = useState<any[]>([]);
    const [showErrorMessageList, setShowErrorMessageList] = useState<any[]>([]);
    const [whenState, updateWhenState] = useState(false);
    const InvoiceSavedataTotal = useSelector(
        (store: RootState) => store.invoiceReducer.arInvoiceSaveData
    );
    const changedCredit = useSelector(
        (store: RootState) => store.invoiceReducer.changedCredit
    );
    const lineleveltotalCM = useSelector(
        (store: RootState) => store.invoiceReducer.lineLevelCmTotal
    );
    const cmIdStatusUpdate = useSelector(
        (store: RootState) => store.invoiceReducer.cmIdForStatusUpdate
    );
    const [status, setStatus] = useState(() => {
        if (arInvoiceSavedata?.status) {
            if (arInvoiceSavedata?.status.status) {
                let st: string = arInvoiceSavedata?.status.status;
                st.replaceAll(' ', '');
                st.replaceAll(',', '');
                return st;
            } else {
                let st: string = arInvoiceSavedata?.status;
                st.replaceAll(' ', '');
                st.replaceAll(',', '');
                return st;
            }
        } else {
            return 'Draft';
        }
    });

    const [statusBOL, setStatusBOL] = useState(() => {
        if (bolData.status) {
            if (bolData.status.status) {
                let st: string = bolData.status.status;
                st.replaceAll(' ', '');
                st.replaceAll(',', '');
                return st;
            } else {
                let st: string = bolData.status;
                st.replaceAll(' ', '');
                st.replaceAll(',', '');
                return st;
            }
        } else {
            return 'Draft';
        }
    });
    const [statusCM, setStatusCM] = useState(() => {
        if (creditMemoInfo.status) {
            if (creditMemoInfo.status.status) {
                let st: string = creditMemoInfo.status.status;
                st.replaceAll(' ', '');
                st.replaceAll(',', '');
                return st;
            } else {
                let st: string = creditMemoInfo.status;
                st.replaceAll(' ', '');
                st.replaceAll(',', '');
                return st;
            }
        } else {
            return 'Draft';
        }
    });
    const [showInvoiceModal, setShowInvoiceModal] = useState(false);
    const [showActionHistoryModalBOL, setShowActionHistoryModalBOL] =
        useState(false);
    const [showAttachmentModal, setShowAttachmentModal] = useState(false);
    const [scanModeTextbox, setScanModeTextbox] = useState(false);
    const [isEditingAttachement, setIsEditingAttachment] = useState(false);
    const [isDeletingAttachement, setIsDeletingAttachement] = useState(false);
    const [showAttachmentModalBol, setShowAttachmentModalBol] = useState(false);
    const [confirmPopup, setConfirmPopup] = useState(false);
    const [forceUpdate, setForceUpdate] = useState(new Date().getTime());
    const [showAddToBolModal, setShowAddToBolModal] = useState(false);
    const [manualEntryNewRow, setManualEntryNewRow] = useState<any[]>([]);
    const [actionHistoryData, setActionHistoryData] = useState<any[]>([]);
    const [invoicesForBOLAdd, setInvoicesForBOLAdd] = useState<any[]>([]);

    const [isDirty, setIsDirty] = useState(false);
    //const [cMemoData, setCmemoData] = useState<any[]>([]);
    /////dynamic lists///////
    const selectedrowsList = useSelector(
        (state: RootState) => state.contactReducer.selectedRecordsForExport
    );
    const descCM = useSelector(
        (state: RootState) => state.invoiceReducer.descriptionForCM
    );
    const creditMemoSavedData = useSelector(
        (state: RootState) => state.invoiceReducer.creditMemoSavedData
    );
    const [selectedPalletsData, SetSlectedPalletsData] = useState<{ [key: string]: Object }[]>([]);
    const [palletsList, SetPalletsList] = useState<{ [key: string]: Object }[]>(
        []
    );
    const [selectingPalletsData, SetSelectingPalletsData] = useState<{ [key: string]: Object }[]>([]);
    const [PickedPalletsData, SetPickedPalletsData] = useState<{ [key: string]: Object }[]>([]);
    const [PalletsToShow, SetPalletsToShow] = useState<{ [key: string]: Object }[]>([]);
    const [PalletsToShowScanned, SetPalletsToShowScanned] = useState<{ [key: string]: Object }[]>([]);
    const [miscSalvage, setMiscSalvage] = useState<{ [key: string]: Object }[]>(
        []
    );
    const [selectedFPIData, SetSlectedFPIData] = useState<{ [key: string]: Object }[]>([]);

    const [FPIList, SetFPIList] = useState<{ [key: string]: Object }[]>([]);
    const [selectingFPIData, setSelectingFPIData] = useState<{
        [key: string]: Object;
    }>({});
    const [PickedFPIData, SetPickedFPIData] = useState<{ [key: string]: Object }[]>([]);
    const [calculateFPIData, setCalculateFPIData] = useState<{
        [key: string]: Object;
    }>({bins: 0, boxes: 0, items: 0, total: 0});

    // const [MiscList, SetMiscList] = useState<{ [key: string]: Object }[]>([]);
    const [PickedMiscData, SetPickedMiscData] = useState<{ [key: string]: Object }[]>([]);
    const [PickedMiscDataForEdit, setPickedMiscDataForEdit] = useState<any[]>(
        []
    );
    const [PickedManualEntryDataForEdit, setPickedManualEntryDataForEdit] =
        useState<any[]>([]);
    const [InvoiceTableData, SetInvoiceTableData] = useState<any>({
        pallets: 0,
        bins: 0,
        boxes: 0,
        items: 0,
        cost: 0,
        price: 0,
    });

    /////////////////////////
    /* BOL*/
    const [selectingInvoiceDataBOL, SetSelectingInvoiceDataBOL] = useState<{ [key: string]: Object }[]>([]);
    const [selectedInvoicesDataBOL, SetSelectedInvoicesDataBOL] = useState<{ [key: string]: Object }[]>([]);
    const [bolList, setBolList] = useState<any>([]);
    const [InvoicesShowBol, setInvoicesShowBol] = useState<{ [key: string]: Object }[]>([]);
    const [PickedInvoicesDataBOL, SetPickedInvoicesDataBOL] = useState<{ [key: string]: Object }[]>([]);
    const [ManualEntryList, SetManualEntryList] = useState<{ [key: string]: Object }[]>([]);
    const [PickedManualEntryData, SetPickedManualEntryData] = useState<{ [key: string]: Object }[]>([]);
    const [bolDataView, setBolDataView] = useState(false);
    const selectedInvoiceScreen = useSelector(
        (store: RootState) => store.invoiceReducer.selectedInvoiceScreen
    );
    const [selectedPallets, setSelectedPallets] = useState<any[]>([]);
    const [arInvoiceSavedPallets, setArInvoiceSavedPallets] = useState(
        arInvoiceSavedata?.palletsList
    );

    const [oldData, setOldData] = useState<any>();
    const [disableBolSaveButton, setDisableBolSaveButton] = useState(false);
    const [disableInvoiceSaveButton, setDisableInvoiceSaveButton] = useState(false);

    // const  baseURL = process.env.REACT_APP_MASTER_URL;
    // const  clientContext = process.env.REACT_APP_CLIENT_CONTEXT;
    function paramsToObject(entries: any) {
        const result: any = {};
        for (const [key, value] of entries) {
            result[key] = value;
        }
        return result;
    }

    const history = useHistory();
    const urlParams = new URLSearchParams(history.location.search);
    const paramEntries = urlParams.entries();
    const params = paramsToObject(paramEntries);
    const dispatch = useDispatch();
    //setOldData(arInvoiceSavedata);

    let neq: any;
    neq = _.isEqual(arInvoiceDefaultData, arInvoiceSavedata)
    console.log(_.isEqual(arInvoiceDefaultData, arInvoiceSavedata), "<<<dataaaaaaaaaaaa>>>")
    console.log(isInvoiceGetUpdated)
    useEffect(() => {
        if (selectedInvoiceScreen === InvoiceScreen.INVOICE && selectedArInvoiceTabIndex === 0 && isInvoiceGetUpdated) {
            if (!whenState) {
                updateWhenState(true)
            } else {
                updateWhenState(false);
            }
        }
    }, [(isInvoiceGetUpdated) || (arInvoiceSavedata && isInvoiceGetUpdated) || (arInvoiceSavedata && (selectedPalletsData || selectedFPIData))]);

    useEffect(() => {
        if (selectedInvoiceScreen === InvoiceScreen.INVOICE && selectedArInvoiceTabIndex === 0 && isInvoiceGetUpdated) {
            if (!whenState) {
                updateWhenState(true)
            } else {
                updateWhenState(false);
            }
        }
    }, [(isInvoiceGetUpdated || arInvoiceSavedata || selectedPalletsData || selectedFPIData)]);


    useEffect(() => {
        if (selectedInvoiceScreen === InvoiceScreen.INVOICE && selectedArInvoiceTabIndex === 1 && isInvoiceGetUpdated) {
            if (!whenState) {
                updateWhenState(true)
            } else {
                updateWhenState(false);
            }
        }
    }, [isInvoiceGetUpdated]);

    useEffect(() => {
        if (selectedInvoiceScreen === InvoiceScreen.BOL && isInvoiceGetUpdated) {
            if (!whenState) {
                updateWhenState(true)
            } else {
                updateWhenState(false);
            }
        }
    }, [isInvoiceGetUpdated]);

    //   useEffect(() => {
    //     if(!whenState) {
    //         updateWhenState(true)
    //     }
    //     else {
    //         updateWhenState(false);
    //     }
    //   }, [(arInvoiceSavedata && selectedFPIData)
    //   ]);

    useEffect(() => {
        if (selectedInvoiceScreen === InvoiceScreen.BOL && bolData) {
            SetSelectedInvoicesDataBOL(bolData.arInvoices);
            SetManualEntryList(bolData.manualEntries);
            if (bolData.bolNumber) {
                setBolDataView(true);
            } else {
                setBolDataView(false);
            }
        }
        //
        // const queryString = window.location.search;
        // const urlParams = new URLSearchParams(queryString);
        // const bolID = urlParams.get("bolId");
    }, [bolData]);
    // useEffect(() => {
    //   if (creditMemoInfo) {
    //     setCmemoData(creditMemoInfo);
    //   }
    // }, [creditMemoInfo]);
    const addPallets = (e: any) => {
        let selPalInfo: any[] = [...arInvoiceSavedPallets];
        //let selPalInfo: any[] = [];
        let items: any[] = [];
        if (scannedPalletsItemsList?.length > 0) {
            items = [...scannedPalletsItemsList];
            items?.map((item: any) => {
                const extitem = item;
                selectedPallets?.push(item);
            });
            selectedPallets?.forEach((obj) => {
                if (!selPalInfo?.some((o) => o.id === obj.id)) {
                    selPalInfo?.push({...obj});
                }
            });
            dispatch(setArInvoiceSavedata({...arInvoiceSavedata, ...{newpallets: selPalInfo}}))
            setArInvoiceSavedPallets([...selPalInfo])
            SetSlectedPalletsData([...selPalInfo]);
            setShowAttachmentModal(false);
            setScannedPalletsItemsList([]);
            dispatch(setScannedPalletsInfo([]));
            setShowErrorMessage(false);
            setShowErrorMessageList([]);
            dispatch(setIsInvoiceUpdated(true));
        } else {
            items = [...selectedrowsList];
            if (selectedrowsList?.length === 0) {
                notify('Please select Pallet', 'error');
                setShowAttachmentModal(false);
            } else {
                items.map((item: any) => {
                    const extitem = item;
                    selectedPallets?.push(item);
                });
                selectedPallets?.forEach((obj) => {
                    if (!selPalInfo?.some((o) => o.id === obj.id)) {
                        selPalInfo?.push({...obj});
                    }
                });
                SetSlectedPalletsData([...selPalInfo]);
                setArInvoiceSavedPallets([...selPalInfo])
                dispatch(setArInvoiceSavedata({...arInvoiceSavedata, ...{newpallets: selPalInfo}}))
                setShowAttachmentModal(false);
                setScannedPalletsItemsList([]);
                dispatch(setScannedPalletsInfo([]));
                dispatch(setSelectedRecrdsExport([]));
                dispatch(setIsInvoiceUpdated(true));
            }
        }

        SetPalletsToShow([]);
    };

    const selectedArInvoiceTabIndex = useSelector(
        (store: RootState) => store.invoiceReducer.selectedArInvoiceTabIndex
    );
    useEffect(() => {
        let updatedCredit = 0.0;
        selectedPalletsData.forEach((el: any) => {
            //console.log("elTest", el)
            updatedCredit =
                updatedCredit +
                (el.creditPrice === '' || el.creditPrice === 'NaN'
                    ? 0.0
                    : el.creditPrice);
            // console.log("elTest", updatedCredit)
        });
        selectedFPIData.forEach((el: any) => {
            updatedCredit =
                updatedCredit +
                (el.creditPrice === '' || el.creditPrice === 'NaN'
                    ? 0.0
                    : el.creditPrice);
        });
        if (
            creditMemoInfo.salvageItems != undefined &&
            creditMemoInfo.salvageItems.length != 0
        ) {
            creditMemoInfo.salvageItems.forEach((el: any) => {
                updatedCredit =
                    updatedCredit +
                    (el.creditPrice === '' || el.creditPrice === 'NaN'
                        ? 0
                        : el.creditPrice);
            });
        }
        console.log(
            'arInvoiceSavedata.salvageItems',
            arInvoiceSavedata.salvageItems
        );
        console.log('creditMemoInfo.salvageItems', creditMemoInfo.salvageItems);
        setCreditMemoPrice(updatedCredit);
        //arInvoiceSavedata.creditPrice
    }, [
        selectedPalletsData,
        selectedFPIData,
        creditMemoInfo.salvageItems,
        arInvoiceSavedata.creditPrice,
        arInvoiceSavedata.salvageItems,
    ]);
    const bolViewData = useSelector(
        (store: RootState) => store.invoiceReducer.bolDataById
    );
    const [addToBolId, setAddToBolId] = useState('');

    const handleAddManualItem = (data: any) => {
        if (
            isEditingAttachement &&
            PickedManualEntryData &&
            PickedManualEntryData.length > 0
        ) {
            Object.assign(data, {id: PickedManualEntryData[0]?.id});
            const endpoint = addManualEntryEndPointUpdate(data.id);
            handleEditManualEntryData(endpoint, data);
        } else {
            //  if(data.description === ""){
            //   notify("Description not empty", "error")
            //   SetManualEntryList([])
            //  }
            // else if(!data.pallet){
            //   notify("Enter Pallets not empty", "error")
            //   SetManualEntryList([])
            //  }
            if (!data.pallet) {
                notify('# of Pallets field is required.', 'error');
                SetManualEntryList([]);
            } else {
                // const options = {
                //   method: "POST",
                //   headers: {
                //     "Content-Type": "application/json",
                //   },
                //   body: JSON.stringify(data),
                // };
                //const endpoint = '';
                const endpoint = addManualEntryEndPoint();

                // fetch(endpoint, options)
                //   .then((data) => data.json())
                //   .then((result) => {
                //     if (!result) {
                //     } else {
                //       let manulData = [...ManualEntryList];
                //       manulData.push(result);
                //       SetManualEntryList(manulData);
                //       dispatch(setIsManualEntryFormClear(true));
                //       setShowAttachmentModalBol(false);
                //     }
                //   });
                handleAddManualEntryItem(endpoint, data);
            }
        }
        dispatch(setIsInvoiceUpdated(true));
    };
    const handleAddManualEntryItem = async (endpoint: any, options: any) => {
        const result = await axios
            .post(endpoint, options)
            .then((results: any) => {
                if (results && results.data) {
                    let manulData = [...ManualEntryList];
                    manulData.push(results.data);
                    SetManualEntryList(manulData);
                    dispatch(setIsManualEntryFormClear(true));
                    setShowAttachmentModalBol(false);
                } else {
                    notify('Failed', 'error');
                }
            })
            .catch((error: any) => {
                if (error.response) {
                    notify(error.response?.data?.message, 'error');
                }
            });
        dispatch(setIsInvoiceUpdated(true));
    };

    const handleEditManualEntryData = async (endpoint: string, data: any) => {
        const result = await axios
            .put(endpoint, data)
            .then((results: any) => {
                if (results && results.data) {
                    const updatedItems = ManualEntryList.map((el: any) => {
                        if (el.id === results.data.id) {
                            return {
                                ...el,
                                bin: results.data.bin,
                                box: results.data.box,
                                description: results.data.description,
                                pallet: results.data.pallet,
                                weight: results.data.weight,
                                id: results.data.id,
                            };
                        }
                        return el;
                    });
                    SetManualEntryList(updatedItems);
                    dispatch(setIsManualEntryFormClear(true));
                    setShowAttachmentModalBol(false);
                    SetPickedManualEntryData([]);
                    //handleGoBack();
                } else {
                    notify('Failed', 'error');
                }
            })
            .catch((error: any) => {
                if (error.response) {
                    notify(error.response?.data?.message, 'error');
                }
            });
        setIsEditingAttachment(false);
        dispatch(setIsInvoiceUpdated(true));
    };
    const handleTabSelection = (params: any) => {
        dispatch(setSelectedArInvoiceTabIndex(params?.selectedIndex));
        let routeToGoBack = '';
        if (selectedInvoiceScreen) {
            routeToGoBack =
                selectedInvoiceScreen === InvoiceScreen.INVOICE
                    ? `/${invoiceView}`
                    : `/${bolUrl}`;
        }
        history.push(`/invoices${routeToGoBack}`);
    };
    const manualEntriesData = useSelector(
        (state: RootState) => state.invoiceReducer.manualEntriesList
    );
    const arInvoicesInfo = useSelector(
        (state: RootState) => state.invoiceReducer.arInvoicesList
    );
    // useEffect(() => {
    //   if(manualEntriesData.length>0)
    //   setManualEntryNewRow(manualEntriesData)
    // },[manualEntriesData])

    useEffect(() => {
        if (arInvoiceSavedata?.fixedPrices) {
            SetSlectedFPIData([...arInvoiceSavedata?.fixedPrices]);
        }
    }, [arInvoiceSavedata]);

    useEffect(() => {
        if (creditMemoInfo?.fixedPrices) {
            SetSlectedFPIData([
                ...creditMemoInfo?.fixedPrices?.map((el: any) => {
                    return {
                        ...el,
                        barCode: el?.id,
                        //description: el?.commodityType?.description,
                        // price: el?.price ?'$'+el?.price.toFixed(2) : '',
                    };
                }),
            ]);
        }
    }, [creditMemoInfo]);
    useEffect(() => {
        //if(selectedArInvoiceTabIndex  === 0){
        if (arInvoiceSavedata.palletsList) {
            SetSlectedPalletsData(arInvoiceSavedata.palletsList);
        }
    }, [arInvoiceSavedata.palletsList]);
    useEffect(() => {
        //if(selectedArInvoiceTabIndex  === 0){
        if (creditMemoInfo?.palletsList) {
            SetSlectedPalletsData(creditMemoInfo?.palletsList);
            // SetSlectedPalletsData([
            //   ...arInvoiceSavedata.palletsList.map((el: any) => {
            //     return {
            //       price: el?.price ?'$'+el?.price.toFixed(2) : '',
            //     };
            //   }),
            // ]);
        }
    }, [creditMemoInfo]);

    const handleAttachmentTabSelection = (params: any) => {
        setSelectedAttachmentTabIndex(
            params?.selectedIndex ? params?.selectedIndex : 0
        );
        let attach = [];
        if (params.selectedIndex == 0) {
            attach = selectedPalletsData;
        } else if (params.selectedIndex == 1) {
            attach = selectedFPIData;
        } else {
            attach = salvageItemMapper(
                selectedArInvoiceTabIndex === 0
                    ? arInvoiceSavedata?.salvageItems
                    : creditMemoInfo?.salvageItems
            );
            attach = attach.map((el: any) => {
                // @ts-ignore
                const perAllcation = parseInt(el.price) / totalItemPrice;
                //const newMemoPrice = parseInt((perAllcation * perAllcation).toFixed(2));
                //const newMemoPrice = 0;
                console.log('newMemoPrice', el.creditPrice);
                const elObj = {
                    ...el,
                    creditMemo: perAllcation,
                    creditPrice: el.creditPrice,
                };
                return elObj;
            });
        }
        setUpdatedAttachData(attach);
    };
    const handleGridRowSelect = (params: any) => {
        if (selectedAttachmentTabIndex === 0) {
            SetPickedPalletsData(params);
        } else if (selectedAttachmentTabIndex === 1) {
            SetPickedFPIData(params);
        } else {
            SetPickedMiscData(params);
        }
    };
    const handleGridRowSelectBol = (params: any) => {
        if (selectedAttachmentTabIndexForBOL === 0) {
            SetPickedInvoicesDataBOL(params);
        } else {
            SetPickedManualEntryData(params);
        }
    };
    const handleAttachmentTabSelectionForBOL = (params: any) => {
        dispatch(setBOLAttachementIconsStatus(params?.selectedIndex));
        setSelectedAttachmentTabIndexorBOL(
            params?.selectedIndex ? params?.selectedIndex : 0
        );
    };
    //const handleGridRowSelect = () => {};

    const handleAddAttachment = () => {
        if (selectedAttachmentTabIndex === 0) {
            const endpoint = getPalletsListEndPoint();
            handleGetPalletsLists(endpoint);
            setShowAttachmentModal(true);
            setPickedMiscDataForEdit([]);
        }
        setShowAttachmentModal(true);
        // console.
        // PickedMiscDataForEdit
        setPickedMiscDataForEdit([]);
    };
    const handleAddAttachmentBol = () => {
        setPickedManualEntryDataForEdit([]);
        if (selectedAttachmentTabIndexForBOL === 0) {
            if (customerIdForInvoicesBol && whIdForInvoicesBol) {
                setShowAttachmentModalBol(true);
                const endpoint = getARInvoicesListEndPointForBOL(
                    customerIdForInvoicesBol, whIdForInvoicesBol);
                handleGetInvoicesForBOL(endpoint);
            } else {
                notify("Please select Pickup From and Customer details", 'error')
            }
        } else {
            setShowAttachmentModalBol(true);
        }


    };
    //let ARIvoiceDataBOL: any;
    const handleGetInvoicesForBOL = async (endpoint: string) => {
        const result = await axios
            .get(endpoint)
            .then((results: any) => {
                if (results && results?.data) {
                    //ARIvoiceDataBOL = results.data;
                    setInvoicesForBOLAdd(
                        results?.data?.map((item: any) => ({
                            invoiceNumber: item?.invoiceNumber,
                            description: item?.description,
                            customerName: item?.customerName,
                            period: item?.period,
                            createdDate: item?.createdDate,
                            cost: item?.cost === null ? 0 : item?.cost,
                            price: item?.price === null ? 0 : item?.price,
                            facility: item?.reclaimCenterName,
                            status:
                                item?.status === 'Pickup, Auto Approved'
                                    ? 'Pickup Auto'
                                    : item?.status === 'Ready for Salvager'
                                        ? 'Ready Slvg'
                                        : item?.status === 'Ready for Extract'
                                            ? 'Ready Extrct'
                                            : item?.status === 'Pickup, Manual Override'
                                                ? 'Pickup Mnul'
                                                : item?.status,
                            bins: item?.bins === null ? 0 : item?.bins,
                            boxes: item?.boxes === null ? 0 : item?.boxes,
                            pallets: item?.pallets === null ? 0 : item?.pallets,
                            items: item?.items === null ? 0 : item?.items,
                        }))
                    );
                    //   ...results.data,
                    //   invoiceNumber: data.invoiceNumber,
                    //   description: data.description,
                    //   customerId: data.customerId,
                    //   period: data.period,
                    //   cost: data.cost,
                    //   price: data.price,
                    //   created: data.createdDate,
                    //   facility: data.reclaimCenterName,
                    //   status: data.status.status === 'Pickup, Auto Approved' ? 'Pickup Auto' :
                    //   data.status.status === 'Ready for Salvager' ? 'Ready Slvg' :
                    //   data.status.status === 'Ready for Extract' ? 'Ready Extct' :
                    //   data.status.status === 'Pickup, Manual Override' ? 'Pickup Mnul' :
                    //   data.status.status,
                    // );
                } else {
                    notify('Failed to load Invoices', 'error');
                }
            })
            .catch((error: any) => {
                if (error.response) {
                    // notify(error.response?.data?.message, "error");
                }
            });
    };

    const handleEditAttachment = () => {
        if (selectedAttachmentTabIndexForBOL === 1) {
            setPickedManualEntryDataForEdit(PickedManualEntryData);
            if (ManualEntryList.length > 0) {
                setShowAttachmentModalBol(true);
                setIsEditingAttachment(true);
            }
        } else if (
            PickedFPIData &&
            selectedAttachmentTabIndex == 1 &&
            selectedFPIData.length > 0
        ) {
            setFixedItemDetailsEditFormModel(true);
            setIsEditingAttachment(true);
        } else {
            if (
                (PickedPalletsData &&
                    selectedAttachmentTabIndex == 0 &&
                    PickedPalletsData.length > 0) ||
                (PickedMiscData &&
                    selectedAttachmentTabIndex == 2 &&
                    PickedMiscData.length > 0)
            ) {
                setPickedMiscDataForEdit(PickedMiscData);
                setShowAttachmentModal(true);
                setIsEditingAttachment(true);
            }
        }
    };
    const handleDeleteAttachment = () => {
        if (selectedAttachmentTabIndex === 0) {
            const idsToDelete = PickedPalletsData.map((e) => e.id);
            const filteredPallets = selectedPalletsData.filter(
                (e) => !idsToDelete.includes(e.id)
            );
            SetSlectedPalletsData(filteredPallets);
            setSelectedPallets(filteredPallets);
            setArInvoiceSavedPallets(filteredPallets);
            dispatch(setIsInvoiceUpdated(true));
        } else if (selectedAttachmentTabIndex === 1) {
            const idsToDelete = PickedFPIData.map((e) => e.id);
            const filteredFPI = selectedFPIData.filter(
                (e) => !idsToDelete.includes(e.id)
            );
            SetSlectedFPIData(filteredFPI);
            dispatch(
                setArInvoiceSavedata({
                    ...arInvoiceSavedata,
                    fixedPrices: filteredFPI,
                })
            );
            dispatch(setIsInvoiceUpdated(true));
        } else {
            const idsToDelete = PickedMiscData.map((e) => e.barCode);
            const filteredMisc = arInvoiceSavedata?.salvageItems?.filter(
                (e: any) => !idsToDelete.includes(e.id)
            );
            setPickedMiscDataForEdit([]);
            SetPickedMiscData([]);
            setRefreshGrid(new Date().getTime());
            // SetMiscList(filteredMisc);
            dispatch(
                setArInvoiceSavedata({
                    ...arInvoiceSavedata,
                    fixedPrices: selectedFPIData,
                    salvageItems: filteredMisc,
                })
            );
            dispatch(setIsInvoiceUpdated(true));
        }
        dispatch(setIsInvoiceUpdated(true));
        setIsEditingAttachment(false);
    };
    const handleDeleteAttachmentBol = () => {
        if (selectedAttachmentTabIndexForBOL === 0) {
            const idsToDelete = PickedInvoicesDataBOL.map(
                (e) => e.invoiceNumber
            );
            const filteredPallets = selectedInvoicesDataBOL.filter(
                (e) => !idsToDelete.includes(e.invoiceNumber)
            );
            SetSelectedInvoicesDataBOL(filteredPallets);
        } else {
            const idsToDelete = PickedManualEntryData.map((e) => e.id);
            const filteredManuEntries = ManualEntryList.filter(
                (e) => !idsToDelete.includes(e.id)
            );
            SetManualEntryList(filteredManuEntries);
            SetPickedManualEntryData([]);
            setIsEditingAttachment(false);
        }
        dispatch(setIsInvoiceUpdated(true));
    };

    const handleAddInvoice = () => {
        setShowInvoiceModal(true);
    };
    const handleCancelNewPopup = () => {
        setShowInvoiceModal(false);
    };

    const handleAddToBOL = () => {
        setShowAddToBolModal(true);
        handleGetBolList();
    };
    const handleActionHistoryBOL = () => {
        let endpointHistory: any;
        if (bolData) {
            endpointHistory = getBOLActionHistory(bolData.id);
            handleGetActionHistoryBol(endpointHistory, bolData.id);
        } else {
            endpointHistory = getBOLActionHistory(bolNumForNew.id);
            handleGetActionHistoryBol(endpointHistory, bolNumForNew.id);
        }
    };
    const handleGetActionHistoryBol = async (e: any, bolId: any) => {
        const result = await axios.get(e, bolId);
        if (result && result.data) {
            setShowActionHistoryModalBOL(true);
            let info: any = result.data;
            setActionHistoryData(
                info.map((item: any) => ({
                    lastModifiedDate: moment(item.lastModifiedDate).format(
                        'MMM DD, YYYY'
                    ),
                    lastModifiedby: item.lastModifiedby,
                    status: item.status,
                }))
            );
        } else {
        }
    };

    const handleGoBack = () => {
        let routeToGoBack = '';
        if (selectedInvoiceScreen) {
            routeToGoBack =
                selectedInvoiceScreen === InvoiceScreen.INVOICE
                    ? `/${invoiceView}`
                    : `/${bolUrl}`;
        }
        history.push(`/invoices${routeToGoBack}`);
    };
    const data: any[] = [];

    const getStatusId = (isCreditMemo = false) => {
        let id = 1;
        switch (status) {
            case 'ReadyforSalvager':
                id = 2;
                break;
            case 'Cancelled':
                id = isCreditMemo ? 5 : 8;
                break;
            case 'ReadyforExtract':
                id = 3;
                break;
            case 'PickedManualOverride':
                id = 5;
                break;
            case 'Draft':
                id = 1;
                break;
            case 'InProcess':
                id = 2;
                break;
            case 'Approved':
                id = 3;
                break;
            case 'Rejected':
                id = 4;
                break;
            default:
                return 1;
        }
        return id;
    };

    const handleSaveCreditMemo = () => {
        console.log('selected salvageItems', creditMemoSavedData.salvageItems);
        // if(arInvoiceSavedata.creditPrice){
        //   const updateCreditMemoPayload = {
        //     credit: creditMemoPrice,
        //     fixedPrices: arInvoiceSavedata.fixedPrices,
        //     pallets: arInvoiceSavedata.pallets,
        //     salvageItems: arInvoiceSavedata.salvageItems,
        //     selectedInvoiceId: arInvoiceSavedata?.invoiceNumber,
        //   };
        //   const endpoint = getUpdateCreditMemoEndpoint();
        //   axios
        //     .put(endpoint, updateCreditMemoPayload)
        //     .then((result: any) => {
        //       if (result && result.data) {
        //         notify("Success", "success");
        //         handleGoBack();
        //       } else {
        //         notify("Failed", "error");
        //       }
        //     })
        //     .catch((error: any) => {
        //       if (error.response) {
        //         notify(error.response?.data?.message, "error");
        //       }
        //     });
        // }
        // else{
        const preparedFixedPriceItems = {};
        const preparedSalvageItem = {};
        const preparedPalletItems = {};
        //selectedFPIData.forEach((el) => {
        let saveDataObj = {};
        //selectedFPIData.forEach((el) => {
        selectedFPIData.forEach((el: any) => {
            if (el.creditPrice) {
                //@ts-ignore
                preparedFixedPriceItems[el.id] = el.creditPrice;
            }
        });
        // selectedPalletsData.forEach((el) => {
        selectedPalletsData.forEach((el: any) => {
            if (el.creditPrice) {
                // @ts-ignore
                preparedPalletItems[el.id] = el.creditPrice;
            }
        });
        if (selectedArInvoiceTabIndex === 0) {
            arInvoiceSavedata.salvageItems.forEach((el: any) => {
                if (el.creditPrice) {
                    // @ts-ignore
                    preparedSalvageItem[el.id] = el.creditPrice;
                }
            });
        } else {
            creditMemoInfo.salvageItems.forEach((el: any) => {
                if (el.creditPrice) {
                    // @ts-ignore
                    preparedSalvageItem[el.id] = el.creditPrice;
                }
            });
        }
        console.log('preparedFixedPriceItems', preparedFixedPriceItems);
        const saveCreditMemoPayload = {
            credit: creditMemoPrice,
            description: descCM ? descCM : creditMemoSavedData.description,
            fixedPrices: preparedFixedPriceItems,
            pallets: preparedPalletItems,
            salvageItems: preparedSalvageItem,
            selectedInvoiceId: creditMemoSavedData?.invoiceNumber,
        };
        //const endpoint = '';
        const endpoint = getSaveCreditMemoEndpoint();
        axios
            .post(endpoint, saveCreditMemoPayload)
            .then((result: any) => {
                if (result && result.data) {
                    notify('Success', 'success');
                    //handleGoBack();
                    // const endpointCMemo = getCreditMemoListEndPoint();
                    // handleActionGetCMList(endpointCMemo)
                    dispatch(setCMIdForStatusUpdate(result.data.id));
                    dispatch(
                        setCreditMemoSavedata(
                            result &&
                            result.data?.map((el: any) => {
                                return {
                                    ...el,
                                    creditMemoId: el.id,
                                };
                            })
                        )
                    );
                } else {
                    notify('Failed', 'error');
                }
            })
            .catch((error: any) => {
                if (error.response) {
                    notify(error.response?.data?.message, 'error');
                }
            });
        updateWhenState(false);
        // }
    };
    const handleActionGetCMList = async (endpoint: string) => {
        const result = await axios
            .get(endpoint)
            .then((results: any) => {
                if (results && results.data) {
                    dispatch(setCreditMemoSavedata(results.data.content));
                } else {
                    notify('Failed', 'error');
                }
            })
            .catch((error: any) => {
                if (error.response) {
                    notify(error.response?.data?.message, 'error');
                }
            });
    };
    const handleSaveInvoice = (
        fixedPriceItemIds: any[] = [],
        miscSalvageItemIds: any[] = []
    ) => {
        setDisableInvoiceSaveButton(true);
        setShowInvoiceModal(false);
        if (!invoiceDetailsData) return;
        const totalFixedPriceItemIds: any = [];
        const totalMiscSalvageItemIds: any = [];
        if (
            selectedFPIData &&
            selectedFPIData.length &&
            selectedFPIData.length > 0
        ) {
            totalFixedPriceItemIds.push(...selectedFPIData.map((el) => el.id));
        }
        if (
            fixedPriceItemIds &&
            fixedPriceItemIds.length &&
            fixedPriceItemIds.length > 0
        ) {
            totalFixedPriceItemIds.push(...fixedPriceItemIds);
        }
        if (
            arInvoiceSavedata?.salvageItems &&
            arInvoiceSavedata?.salvageItems.length &&
            arInvoiceSavedata?.salvageItems.length > 0
        ) {
            totalMiscSalvageItemIds.push(
                ...arInvoiceSavedata?.salvageItems?.map((e: any) => e.id)
            );
        }
        if (
            miscSalvageItemIds &&
            miscSalvageItemIds.length &&
            miscSalvageItemIds.length > 0
        ) {
            totalMiscSalvageItemIds.push(...miscSalvageItemIds);
        }
        const fromData = new FormData();
        const saveInvoiceRequestPayload = {
            ...arInvoiceSavedata,
            status: {
                id: getStatusId(),
                status: status,
            },
            // fixedPrices:
            //   arInvoiceSavedata.fixedPrices?.length === 0
            //     ? null
            //     : arInvoiceSavedata.fixedPrices,
            palletIds: [
                ...(selectedPalletsData || []).map((e) => e.id),
                // ...(selectingPalletsData || []).map((e) => e.id),
            ],
            salvageItemIds: totalMiscSalvageItemIds,
            fixedPriceIds: totalFixedPriceItemIds,
        };

        //console.log("filesare", arInvoiceSavedata.files);
        arInvoiceSavedata.files &&
        fromData.append('files', arInvoiceSavedata.files[0]);
        //delete saveInvoiceRequestPayload.files;
        delete saveInvoiceRequestPayload.newpallets;
        delete saveInvoiceRequestPayload.salvageItems;
        delete saveInvoiceRequestPayload.carrierName;
        delete saveInvoiceRequestPayload.reclaimCenterName;
        delete saveInvoiceRequestPayload.glAccountName;
        delete saveInvoiceRequestPayload.customerName;
        delete saveInvoiceRequestPayload.createdDate;
        delete saveInvoiceRequestPayload.created;
        delete saveInvoiceRequestPayload.facility;
        delete saveInvoiceRequestPayload.lastModifiedDate;
        delete saveInvoiceRequestPayload.palletsList;
        delete saveInvoiceRequestPayload.extractDate;
        delete saveInvoiceRequestPayload.invoiceDate;
        // delete saveInvoiceRequestPayload.salvageItemIds;
        saveInvoiceRequestPayload.bins = +InvoiceTableData.bins;
        saveInvoiceRequestPayload.boxes = +InvoiceTableData.boxes;
        saveInvoiceRequestPayload.cost = +InvoiceTableData.cost;
        saveInvoiceRequestPayload.price = +InvoiceTableData.price;
        saveInvoiceRequestPayload.items = InvoiceTableData.items;
        saveInvoiceRequestPayload.pallets = +InvoiceTableData.pallets;
        delete saveInvoiceRequestPayload.fixedPrices;

        if (saveInvoiceRequestPayload.reclaimCenterId === '' || !saveInvoiceRequestPayload?.reclaimCenterId) {
            notify('Required Origin', 'error');
            setDisableInvoiceSaveButton(false);
            return;
        }
        if (saveInvoiceRequestPayload.description === '' || !saveInvoiceRequestPayload?.description) {
            notify('Required Description', 'error');
            setDisableInvoiceSaveButton(false);
            return;
        }
        if (saveInvoiceRequestPayload.customerId === '' || !saveInvoiceRequestPayload?.customerId) {
            notify('Required Customer', 'error');
            setDisableInvoiceSaveButton(false);
            return;
        }
        if (saveInvoiceRequestPayload.glAccounts === '' || !saveInvoiceRequestPayload?.glAccounts) {
            notify('Required GL Account', 'error');
            setDisableInvoiceSaveButton(false);
            return;
        }
        if (saveInvoiceRequestPayload.departmentId === '' || !saveInvoiceRequestPayload?.departmentId) {
            notify('Required Department', 'error');
            setDisableInvoiceSaveButton(false);
            return;
        }
        if (saveInvoiceRequestPayload.pickFromId === '' || !saveInvoiceRequestPayload.pickFromId) {
            notify('Required Pickup from', 'error');
            setDisableInvoiceSaveButton(false);
            return;
        }
            //  else if (
            //   saveInvoiceRequestPayload.palletIds.length === 0 &&
            //   saveInvoiceRequestPayload.fixedPriceIds.length === 0 &&
            //   saveInvoiceRequestPayload.salvageItemIds.length === 0
            // ) {
            //   notify(
            //     "Add any of Pallet/FPI/Miscsalvage Item",
            //     "error"
            //   );
            // }
            //  else if (saveInvoiceRequestPayload.fixedPriceIds.length === 0) {
            //   notify("Add one Fixed pallet", "error");
            // }
            //  else if (saveInvoiceRequestPayload.salvageItemIds.length === 0) {
            //   notify("Add one Salvage", "error");
        // }
        else {
            fromData.append('data', JSON.stringify(saveInvoiceRequestPayload));

            const endpoint = SaveArInvoicesNewEndPoint(
                arInvoiceSavedata?.invoiceNumber
            );
            axios
                .put(endpoint, fromData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                })
                .then((result: any) => {
                    if (!result) {
                        notify('Something went wrong', 'error');
                        setDisableInvoiceSaveButton(false);
                    } else {
                        //alert("Save successful");
                        notify('Success', 'success');
                        // handleGoBack();
                        dispatch(setIsInvoiceSaved(true));
                        dispatch(
                            setNewSavedInvoiceList(result.data.invoiceNumber)
                        );
                        // axios.get(endpoint).then((result: any) => {
                        //   if (!result) {
                        //     //alert("An error occured while saving");
                        //   } else {
                        //alert("Save successful");
                        dispatch(setArInvoiceSavedata(result.data));
                        const endpoint = SaveArInvoicesEndPoint(
                            result.data.invoiceNumber
                        );
                        axios.get(endpoint).then(async (result: any) => {
                            if (!result) {
                            } else {
                                dispatch(setArInvoiceSavedata(result.data));
                                updateWhenState(false);
                                setDisableInvoiceSaveButton(false);
                                if (
                                    arInvoiceSavedata.invoiceNumber
                                        .charAt(0)
                                        .includes('C')
                                ) {
                                    let url = window.location.href;
                                    let params = url.replace('/Inv & CM/', '');

                                    history?.push(
                                        `/invoices/invoiceView/Details?ArInvoiceId=${result.data.invoiceNumber}`
                                    );
                                    // history?.push(
                                    //   `invoiceView/Details?ArInvoiceId=${result.data.invoiceNumber}`
                                    // );
                                } else {
                                    history?.push(
                                        `Details?ArInvoiceId=${result.data.invoiceNumber}`
                                    );
                                }
                            }
                        });
                        // if (result?.data?.invoiceNumber) {
                        //   history.push(
                        //     `invoiceView/Details?ArInvoiceId=${result?.data?.invoiceNumber}`
                        //   );
                        // }
                    }
                });
            //   }
            // }).catch((error:any)=>{
            //   if (error.response) {
            //     notify(error.response?.data?.message, 'error');
            //   }

            // });
        }
    };
    ////Update BOL status ///
    const updateBOLStatus = (txt: any) => {
        if (bolData) {
            const body = {
                id: txt._id,
                status: txt.properties.text,
                userName: '',
            };
            const endpoint = getBOLStatusUpdate(bolData.id);
            handleUpdateStatusBOL(endpoint, body);
        } else {
        }
        // const body = {
        //   id: value._id,
        //   status: value,
        // };
        // axios.put(endpoint, body).then((result: any) => {
        //   if (!result) {
        //   } else {
        //     let st: string = result?.data?.status?.status;
        //     if (st) {
        //       st.replaceAll(" ", "");
        //       st.replaceAll(",", "");
        //       setStatus(st);
        //       dispatch(
        //         setArInvoiceSavedata({
        //           ...arInvoiceSavedata,
        //           status: result?.data?.status,
        //         })
        //       );
        //     }
        //   }
        // });
    };
    const handleUpdateStatusBOL = async (endpoint: string, body: any) => {
        //const abc = (statusValBOL.txt).toString();
        const result = await axios
            .put(endpoint, body)
            .then((results: any) => {
                if (results && results.data) {
                    notify('Success', 'success');
                    //handleGoBack();
                    dispatch(setBOLDataByIddata(results.data));
                    let st: string = results?.data?.status?.status;
                    st.replaceAll(' ', '');
                    st.replaceAll(',', '');
                    setStatusBOL(st);
                } else {
                    notify('Failed', 'error');
                }
            })
            .catch((error: any) => {
                if (error.response) {
                    notify(error.response?.data?.message, 'error');
                }
            });
    };
    const updateArInvoiceStatus = (value: any, creditMemoId = '') => {
        const isCreditMemo = selectedArInvoiceTabIndex == 1 ? true : false;
        const endpoint = isCreditMemo
            ? getUpdateCreditMemoStatusEndpoint(
                cmIdStatusUpdate
                    ? cmIdStatusUpdate
                    : creditMemoInfo.creditMemoId
            )
            : getArInvoiceUpdateStatus(arInvoiceSavedata.invoiceNumber);
        const body = {
            id: value._id,
            status: value.properties.text,
            userName: '',
        };
        axios
            .put(endpoint, body)
            .then((result: any) => {
                if (!result) {
                } else {
                    let st: string = result?.data?.status?.status;
                    notify('Success', 'success');
                    //handleGoBack();
                    dispatch(setCMIdForStatusUpdate(''));
                    if (st) {
                        //console.log(">>>>>>> updateing the status: ", st);
                        st.replaceAll(' ', '');
                        st.replaceAll(',', '');
                        if (isCreditMemo) {
                            setStatusCM(st);
                        } else {
                            setStatus(st);
                            dispatch(setArInvoiceSavedata(result?.data));
                        }
                        //isCreditMemo ?   setStatusCM(st) : setStatus(st)
                        dispatch(
                            setArInvoiceSavedata({
                                ...arInvoiceSavedata,
                                status: result?.data?.status,
                            })
                        );
                        //dispatch(setArInvoiceSavedata(result?.data));
                        dispatch(
                            setCreditMemoSavedata({
                                ...creditMemoInfo,
                                status: result?.data?.status,
                            })
                        );
                        updateWhenState(false);
                    }
                }
            })
            .catch((error: any) => {
                if (error.response) {
                    notify(error.response?.data?.message, 'error');
                }
            });
    };

    /////fetch pallets list////////
    useEffect(() => {
        setSelectedPallets([]);
    }, []);
    useEffect(() => {
        // const endpoint = getPalletsListEndPoint();
        // handleGetPalletsLists(endpoint);
        // if (
        //   (showAttachmentModal && selectedAttachmentTabIndex == 0) ||
        //   !palletsList
        // ) {
        //   fetch(endpoint)
        //     .then((data) => data.json())
        //     .then((result) => {
        //       if (!result || result.length === 0) {
        //         //alert("Fetch list successful, but no list items are present");
        //       } else {
        //         SetPalletsList(result);
        //         SetPalletsToShow(result);
        //       }
        //     })
        //     .catch((error) => {
        //       // console.log("Catched error", error);
        //     });
        // }
        //setSelectedPallets([])
    }, [showAttachmentModal, selectedAttachmentTabIndex]);
    const handleGetPalletsLists = async (endpoint: string) => {
        setLoaderStatus(true);
        const result = await axios
            .get(endpoint)
            .then((results: any) => {
                if (results && results.data) {
                    setLoaderStatus(false);
                    SetPalletsList(results.data);
                    SetPalletsToShow(
                        results.data &&
                        results.data?.map((el: any) => {
                            return {
                                ...el,
                                barcode: el?.barcode ? el?.barcode : '',
                                bins: el?.bins ? el?.bins : 0,
                                boxes: el?.boxes ? el?.boxes : 0,
                                description: el?.description
                                    ? el?.description
                                    : '',
                                totalItemCost: el?.totalItemCost
                                    ? el?.totalItemCost
                                    : 0,
                                totalItemsCount: el?.totalItemsCount
                                    ? el?.totalItemsCount
                                    : 0,
                                rate: el?.rate ? el?.rate : 0,
                                price: el?.price ? el?.price : 0,
                            };
                        })
                    );
                } else {
                    setLoaderStatus(false);
                    notify('Failed', 'error');
                }
            })
            .catch((error: any) => {
                setLoaderStatus(false);
                if (error.response) {
                    notify(error.response?.data?.message, 'error');
                }
            });
    };
    ////update pallets list with selected ones///////
    // useEffect(() => {
    //   const include = selectedPalletsData.map((e) => e.id);
    //   const filterd = [...palletsList].filter((e) => !include.includes(e.id));
    //   SetPalletsToShow(filterd);
    //   //console.log('selectedPalletsData', selectedPalletsData)
    //   SetSelectingPalletsData([]);
    // }, [selectedPalletsData]);

    ////update invoices list with selected ones BOL///////
    // useEffect(() => {
    //   const include = selectedInvoicesDataBOL && selectedInvoicesDataBOL?.map((e) => e.invoiceNumber);
    //   //const filterd = palletsList.filter((e) => !include.includes(e.invoiceNumber));
    //   //SetInvoicesToShowBOL(filterd)
    //   SetSelectingInvoiceDataBOL([]);
    // }, [selectedInvoicesDataBOL]);

    ////update invoice table on pallets section//////
    useEffect(() => {
        let pallets = 0;
        let bins = 0;
        let boxes = 0;
        let items = 0;
        let cost: any = 0;
        let price: any = 0;
        selectedPalletsData.forEach((e) => {
            pallets = (selectedPalletsData || []).length;
            bins += parseInt(e.bins ? e.bins.toString() : '0');
            boxes += parseInt(e.boxes ? e.boxes.toString() : '0');
            items += parseInt(
                e.totalItemsCount ? e.totalItemsCount.toString() : '0'
            );
            cost += e.totalItemCost ? e.totalItemCost : '0';
            price += e.price ? e.price : '0';
        });

        selectedFPIData.forEach((e) => {
            bins += parseInt(e.binsCount ? e.binsCount?.toString() : '0');
            boxes += parseInt(e.boxesCount ? e.boxesCount?.toString() : '0');
            items += parseInt(e.itemsCount ? e.itemsCount?.toString() : '0');
            cost += e.price ? e.price : '0';
            price += e.price ? e.price : '0';
            if (e.binsCount > 0) {
                pallets += parseInt(e.binsCount ? e.binsCount?.toString() : '0');
            }
            if (e.boxesCount > 0) {
                pallets += 1;
            }
        });

        arInvoiceSavedata?.salvageItems?.forEach((e: any) => {
            bins += parseInt(e.binsCount ? e.binsCount?.toString() : '0');
            boxes += parseInt(e.boxesCount ? e.boxesCount?.toString() : '0');
            items += e.itemsCount * e.boxesCount;
            cost += e.itemCost * e.itemsCount * e.boxesCount;
            price += e.calculatePrice ? e.calculatePrice : '0';
        });
        pallets = pallets +
            arInvoiceSavedata?.salvageItems?.length;
        SetInvoiceTableData({pallets, bins, boxes, items, cost, price});
    }, [selectedPalletsData, selectedFPIData, arInvoiceSavedata?.salvageItems]);

    useEffect(() => {
        if (selectedArInvoiceTabIndex == 1) {
            // let total = 0;
            let totalPrice = 0;
            let totalCreditPrice = 0;
            selectedPalletsData.forEach((el: any) => {
                // if (el.creditMemo) {
                //   // @ts-ignore
                //   total = total + el.creditMemo;
                // }
                totalPrice = totalPrice + el.price;
                totalCreditPrice =
                    totalCreditPrice + el.creditPrice ? el.creditPrice : 0;
            });
            selectedFPIData.forEach((el: any) => {
                // if (el.creditMemo) {
                //   // @ts-ignore
                //   total = total + el.creditMemo;
                // }
                totalPrice = totalPrice + el.price;
                totalCreditPrice =
                    totalCreditPrice + el.creditPrice ? el.creditPrice : 0;
            });
            arInvoiceSavedata.salvageItems?.forEach((el: any) => {
                // if (el.creditMemo) {
                //   // @ts-ignore
                //   total = total + el.creditMemo;
                // }
                totalPrice = totalPrice + el.calculatePrice;
                totalCreditPrice =
                    totalCreditPrice + el.creditPrice ? el.creditPrice : 0;
            });
            // console.log(
            //   ">>>>>>>>>>>>>>>>>> aaaa: ",
            //   selectedPalletsData,
            //   selectedFPIData,
            //   arInvoiceSavedata?.salvageItems
            // );
            // setCreditMemoPrice(total);
            setTotalItemPrice(totalPrice);
        }
    }, [selectedPalletsData, selectedFPIData, creditMemoInfo?.salvageItems]);

    ///////fetch FPI list////////

    useEffect(() => {
        const endpoint = getFPIListEndPoint();
        handleGetFPIList(endpoint);
        // const endpoint = getFPIListEndPoint();
        // fetch(endpoint)
        //   .then((data) => data.json())
        //   .then((result) => {
        //     if (!result || result.length === 0) {
        //       //alert("Fetch list successful, but no list items are present");
        //     } else {
        //       //console.log("result", result);
        //       // SetFPIList(
        //       //   result &&
        //       //     result?.map((el: any) => {
        //       //       return {
        //       //         ...el,
        //       //         // price: el?.price ? "$" + el?.price.toFixed(2) : "",
        //       //       };
        //       //     })
        //       // );
        //       SetFPIList(result && result);
        //       //   if(FPIList){
        //       //   return FPIList.map((item: any) => ({
        //       //     description: item.description
        //       //   }));
        //       // }
        //     }
        //   })

        //   .catch((error) => {
        //     // console.log("Catched error", error);
        //   });
    }, []);
    const handleGetFPIList = async (endpoint: string) => {
        const result = await axios
            .get(endpoint)
            .then((results: any) => {
                if (results && results?.data) {
                    SetFPIList(results?.data && results?.data);
                } else {
                    notify('Failed', 'error');
                }
            })
            .catch((error: any) => {
                if (error.response) {
                    notify(error.response?.data?.message, 'error');
                }
            });
    };
    const handleSaveBOL = () => {
        setDisableBolSaveButton(true);
        setShowInvoiceModal(false);
        const manualEntryesIDsForAdd: any[] = [];
        const InvoiceIDsForAddToBOL: any[] = [];
        ManualEntryList.forEach((item) => {
            manualEntryesIDsForAdd.push(item.id);
        });
        selectedInvoicesDataBOL.forEach((item) => {
            InvoiceIDsForAddToBOL.push(item.invoiceNumber);
        });
        if (!bolDetailsData) {
            setDisableBolSaveButton(false);
            return;
        }
        const bolFinal = {
            ...bolDetailsData,
            invoiceIdList: InvoiceIDsForAddToBOL,
            maualEntryIdList: manualEntryesIDsForAdd,
            reclaimCenterId:
                bolDetailsData?.reclaimCenterId == undefined
                    ? bolNumForNew?.reclaimCenter?.id
                    : bolDetailsData?.reclaimCenterId,
        };
        const bolFinalUpdate = {
            ...bolDetailsData,
            bolId: bolData.id,
            // reclaimCenterId: bolData?.reclaimCenter?.id,
            // pickUpFromId: bolData?.pickUpFrom?.id,
            // customerId: bolData?.customer?.id,
            // primaryAddressId: bolData?.customer?.id,
            // period: bolData?.period?.id,
            // carrierId: bolData?.carrier?.id,
            invoiceIdList: InvoiceIDsForAddToBOL,
            maualEntryIdList: manualEntryesIDsForAdd,
        };
        //console.log("BOL", bolFinal);
        let endpoint: any;
        if (bolData) {
            //   if (!bolData.reclaimCenter.id) {
            //     notify("Origin not empty", "error");
            //   }
            //  else if (!bolData.pickUpFrom.id) {
            //     notify("Origin not empty", "error");
            //   }else if (!bolData.description) {
            //     notify("Description not empty", "error");
            //   }
            //   // else if(!bolFinal.customerId){
            //   //   notify('Customer not empty', 'error');
            //   // }
            //   // else if (bolData.customer.id && !bolData.billingTo) {
            //   //   notify("Billing not empty", "error");
            //   // }

            //   // else if(!bolFinal.billingTo){
            //   //   notify('Billing not empty', 'error');
            //   // }
            //   // else if(!bolFinal.carrierId){
            //   //   notify('Carrier not empty', 'error');
            //   // }
            //  if (
            //   bolData.arInvoices.length === 0 &&  bolFinal.invoiceIdList.length === 0 ||
            //   bolData.manualEntries.length === 0 && bolFinal.maualEntryIdList.length === 0
            // ) {
            //   notify("One Invoice or One Manual entry required", "error");
            // }
            // else{
            endpoint = addBOLEndPoint(bolData.id);
            handleADDBOLData(endpoint, bolFinalUpdate);
            //}
        } else {
            if (!bolFinal.reclaimCenterId) {
                notify('Required Origin', 'error');
                setDisableBolSaveButton(false);
            } else if (!bolFinal.description) {
                notify('Required Description', 'error');
                setDisableBolSaveButton(false);
            }
                // else if(!bolFinal.customerId){
                //   notify('Customer not empty', 'error');
            // }
            else if (bolFinal.customerId && !bolFinal.billingTo) {
                notify('Required Billing', 'error');
                setDisableBolSaveButton(false);
            } else if (!bolFinal.pickUpFromId) {
                notify('Required Pickup from', 'error');
                setDisableBolSaveButton(false);
            }
                // else if(!bolFinal.billingTo){
                //   notify('Billing not empty', 'error');
                // }
                // else if(!bolFinal.carrierId){
                //   notify('Carrier not empty', 'error');
            // }
            else if (
                bolFinal.invoiceIdList.length === 0 &&
                bolFinal.maualEntryIdList.length === 0
            ) {
                notify('Add any of Invoice or Manual entry Item', 'error');
                setDisableBolSaveButton(false);
            }
                //  else if (bolFinal.maualEntryIdList.length === 0) {
                //   notify("One Manual ntry required", "error");
            // }
            else {
                endpoint = addBOLEndPoint(bolFinal.bolId);
                handleADDBOLData(endpoint, bolFinal);
            }
        }
        updateWhenState(false);

        //const getEndpoint = getEndPoint(tabName);
        //handleMasterDataChange(endpoint, getEndpoint)
    };
    const handleDeleteBOL = () => {
        const endpoint = deleteBOLEndPoint(bolData.id);
        handleDeleteBOLData(endpoint);
    };

    const handleDeleteBOLData = async (endpoint: string) => {
        // const result = await axios
        //   .delete(endpoint)
        //   .then((results: any) => {
        //     if (results && results.data) {
        //       notify("BOL deleted successfully", "success");
        //       //handleGoBack();
        //     } else {
        //       notify("Failed", "error");
        //     }
        //   })
        //   .catch((error: any) => {
        //     if (error.response) {
        //       notify(error.response?.data?.message, "error");
        //     }
        //   });

        dispatch(setIsBOLDataClear(true));
        SetManualEntryList([]);
        SetSelectedInvoicesDataBOL([]);
    };

    const handleADDBOLData = async (endpoint: string, bolFinal: any) => {
        // const result = await axios.post(endpoint, bolFinal);
        // if (result && result.data) {
        //   console.log("result", result.data);
        //   handleGoBack();
        // } else {
        //   alert("Error");
        // }
        // const getresult = await axios.get(getEndpoint);
        // dispatch(setCurrentContact(getresult.data));
        const result = await axios
            .put(endpoint, bolFinal)
            .then((results: any) => {
                if (results && results.data) {
                    notify('Success', 'success');

                    //handleGoBack();
                    const endpoint = getBOLDataByIdEndPoint(results.data.bolId);
                    axios
                        .get(endpoint)
                        .then(async (resultBol: any) => {
                            setDisableBolSaveButton(false);
                            if (!resultBol) {
                            } else {
                                //if (!bolData) {
                                    dispatch(
                                        setBOLDataByIddata(resultBol.data)
                                    );
                               // }
                            }
                        })
                        .catch((error: any) => {
                            if (error.response) {
                                notify(error.response?.data?.message, 'error');
                                setDisableBolSaveButton(false);
                            }
                        });
                } else {
                    notify('Failed', 'error');
                    setDisableBolSaveButton(false);
                }
            })
            .catch((error: any) => {
                setDisableBolSaveButton(false);
                if (error.response) {
                    notify(error.response?.data?.message, 'error');
                }
            });
    };
    const handleCloseModal = () => {
        setSelectingFPIData({});
        setCalculateFPIData({bins: 0, boxes: 0, items: 0, total: 0});
    };
    const handleDeleteInvoice = () => {
        //  let newInvoice = arInvoiceSavedata.invoiceNumber.charAt(0).includes("C");
        //  if(newInvoice){

        //  }
        //  else{
        setShowInvoiceModal(false);
        const endpoint = deleteInvoiceEndPoint(arInvoiceSavedata.invoiceNumber);
        handleDeleteInvoiceData(endpoint);
        //}
    };
    const handleDeleteInvoiceData = async (endpoint: string) => {
        // const result = await axios
        //   .delete(endpoint)
        //   .then((results: any) => {
        //     if (results && results.data) {
        //       notify("Invoice deleted successfully", "success");
        //       handleGoBack();
        //     } else {
        //       notify("Failed", "error");
        //     }
        //   })
        //   .catch((error: any) => {
        //     if (error.response) {
        //       notify(error.response?.data?.message, "error");
        //     }
        //   });
        const defaultData = {...arInvoiceDefaultData};

        dispatch(
            setArInvoiceSavedata({
                ...arInvoiceSavedata,
                fixedPrices: [],
                salvageItems: [],
            })
        );

        dispatch(setIsInvoiceClear(true));
        setForceUpdate(new Date().getTime());
    };
    const NewInvoiceModalContent = () => {
        return (
            <>
                {selectedInvoiceScreen === InvoiceScreen.BOL ? (
                    <ContentHeader value="New BOL"/>
                ) : (
                    <ContentHeader value="New Invoice"/>
                )}
                <Divider/>
                <div
                    style={{
                        width: 300,
                        height: 100,
                        paddingTop: 40,
                        paddingBottom: 40,
                        textAlign: 'center',
                    }}
                >
                    <>
                        {selectedInvoiceScreen === InvoiceScreen.BOL ? (
                            <p>Save current BOL before proceeding?</p>
                        ) : arInvoiceSavedata &&
                        arInvoiceSavedata?.status?.status === 'Draft' &&
                        selectedArInvoiceTabIndex === 0 ? (
                            <p>Save current invoice before proceeding?</p>
                        ) : (
                            <p></p>
                        )}
                    </>
                </div>
                {selectedInvoiceScreen === InvoiceScreen.BOL ? (
                    <div
                        style={{
                            display: 'flex',
                            padding: 10,
                            justifyContent: 'end',
                        }}
                    >
                        <ButtonComponent
                            style={{marginRight: 10}}
                            cssClass="e-outline"
                            onClick={handleCancelNewPopup}
                        >
                            No
                        </ButtonComponent>
                        <ButtonComponent
                            cssClass="e-info"
                            onClick={() => handleSaveBOL()}
                        >
                            Yes
                        </ButtonComponent>
                    </div>
                ) : arInvoiceSavedata &&
                arInvoiceSavedata?.status?.status === 'Draft' ? (
                    <div
                        style={{
                            display: 'flex',
                            padding: 10,
                            justifyContent: 'end',
                        }}
                    >
                        <ButtonComponent
                            style={{marginRight: 10}}
                            cssClass="e-outline"
                            onClick={handleCancelNewPopup}
                        >
                            No
                        </ButtonComponent>
                        <ButtonComponent
                            cssClass="e-info"
                            onClick={() => handleSaveInvoice()}
                        >
                            Yes
                        </ButtonComponent>
                    </div>
                ) : (
                    ''
                )}
            </>
        );
    };

    const handleGetBolList = () => {
        const bolListEndPoint = getBOLListEndPointForInvoice(
            arInvoiceSavedata.invoiceNumber
        );
        //console.log(">>>>>>>>>>>>>>>>>>>>calling bol list");
        axios.get(bolListEndPoint).then((res: any) => {
            if (res) {
                //console.log(">>>>>>>>>>>> bol list data: ", res);
                setBolList(res.data);
            }
        });
    };

    const AddFixedPriceItem = (formData: any) => {
        if (formData.code === '') {
            notify('Plaese select Commodity', 'error');
        } else if (formData.bins === 0 && formData.boxes === 0) {
            notify('Please enter Bins or Boxes', 'error');
        } else {
            const body = {
                description: formData.description,
                commodityTypeId: formData.commodityTypeId,
                pricingType: formData.pricingType,
                itemsCount: formData?.items,
                boxesCount: formData?.boxes,
                binsCount: formData?.bins,
                pricePerBox: formData?.rate,
            };
            let endpoint = addFixedPriceEndPoint();
            let method: 'post' | 'put' = 'post';
            if (PickedFPIData.length > 0) {
                endpoint = isEditingAttachement
                    ? endpoint + `/${PickedFPIData[0].id}`
                    : endpoint;
                method = isEditingAttachement ? 'put' : 'post';
            }
            axios[method](endpoint, body).then((res: any) => {
                if (res) {
                    dispatch(setFpiClearGridRow(true));
                    let updatedData: any = [];
                    const data = selectedFPIData.filter(
                        (el) => el.id == res.data.id
                    );
                    if (data.length > 0) {
                        // selectedFPIDataCopy.map(obj => arr2.find(o => o.id === obj.id) || obj);
                        selectedFPIData.forEach((el) => {
                            if (el.id == res.data.id) {
                                updatedData.push({
                                    ...res.data,
                                    //description: res?.data?.commodityType?.description,
                                });
                            } else {
                                updatedData.push({
                                    ...el,
                                });
                            }
                        });

                        SetSlectedFPIData([...updatedData]);
                        dispatch(
                            setArInvoiceSavedata({
                                ...arInvoiceSavedata,
                                fixedPrices: [...updatedData],
                                palletsList: [...selectedPalletsData],
                                salvageItems: arInvoiceSavedata.salvageItems,
                            })
                        );
                    } else {
                        SetSlectedFPIData([
                            ...selectedFPIData,
                            {
                                ...res.data,
                                //description: res?.data?.commodityType?.description,
                            },
                        ]);

                        dispatch(
                            setArInvoiceSavedata({
                                ...arInvoiceSavedata,
                                fixedPrices: [
                                    ...selectedFPIData,
                                    {
                                        ...res.data,
                                    },
                                ],
                                palletsList: [...selectedPalletsData],
                                salvageItems: arInvoiceSavedata.salvageItems,
                            })
                        );
                    }

                    setShowAttachmentModal(false);
                    setFixedItemDetailsEditFormModel(false);
                    setCalculateFPIData({
                        bins: 0,
                        boxes: 0,
                        items: 0,
                        total: 0,
                    });
                    setSelectingFPIData({});
                    SetPickedFPIData([]);
                }
            });
            setSelectingFPIData({});
            setIsEditingAttachment(false);
        }
        dispatch(setIsInvoiceUpdated(true));
    };
    const AddMiscSalvageItem = (item: any) => {
        if (item.boxesCount === 0) {
            notify('Please enter # of Cases', 'error');
        } else if (item.itemsCount === 0) {
            notify('Please enter # of Items in case', 'error');
        } else if (item.itemCost === 0) {
            notify('Please enter Item Cost', 'error');
        } else if (item.costPercentage === 0) {
            notify('Required % of Cost', 'error');
        } else {
            const body = {...item};
            //let endpoint = ''
            let endpoint = addSalvageItemEndPoint();
            let method: 'post' | 'put' = 'post';
            if (PickedMiscData.length > 0) {
                let id = arInvoiceSavedata.salvageItems.find(
                    (el: any) => el.upc == PickedMiscData[0].upc
                )?.id;
                if (id) {
                    endpoint = isEditingAttachement
                        ? endpoint + `/${PickedMiscData[0].barCode}`
                        : endpoint;
                }
                method = isEditingAttachement ? 'put' : 'post';
            }
            setRefreshGrid(new Date().getTime());
            axios[method](endpoint, body).then((res: any) => {
                if (res) {
                    if (isEditingAttachement) {
                        let misId = PickedMiscData.map((e) => e.barCode);
                        if (
                            arInvoiceSavedata.salvageItems.some(
                                (m: any) => m.id === misId[0]
                            )
                        ) {
                            const updatedItems =
                                arInvoiceSavedata.salvageItems.map(
                                    (el: any) => {
                                        if (el.id === misId[0]) {
                                            return {
                                                ...el,
                                                upc: item.upc,
                                                barCode: item.upc,
                                                binsCount: item.binsCount,
                                                description: item.description,
                                                boxesCount: item.boxesCount,
                                                costPercentage:
                                                item.costPercentage,
                                                calculatePrice:
                                                item.calculatePrice,
                                                creditPrice: item.creditPrice,
                                                itemCost: item.itemCost,
                                                itemsCount: item.itemsCount,
                                                totalItemCost:
                                                item.totalItemCost,
                                                totalItemsCount:
                                                item.totalItemsCount,
                                            };
                                        }
                                        return el;
                                    }
                                );

                            dispatch(
                                setArInvoiceSavedata({
                                    ...arInvoiceSavedata,
                                    fixedPrices: [...selectedFPIData],
                                    palletsList: [...selectedPalletsData],
                                    salvageItems: updatedItems,
                                })
                            );
                        }
                    } else {
                        dispatch(
                            setArInvoiceSavedata({
                                ...arInvoiceSavedata,
                                fixedPrices: [...selectedFPIData],
                                palletsList: [...selectedPalletsData],
                                salvageItems: arInvoiceSavedata.salvageItems
                                    ? [
                                        ...arInvoiceSavedata.salvageItems,
                                        res.data,
                                    ]
                                    : [res.data],
                            })
                        );
                    }

                    // handleSaveInvoice([], [res.data.id]);
                    setShowAttachmentModal(false);
                    // SetPickedMiscData([]);
                }
            });
            setIsEditingAttachment(false);
        }
    };

    // const FixedItemDetailsModalContent = () => {
    //   return (

    //   );
    // };

    const callAddToBolApi = () => {
        const endpoint = getAddToBolEndpoint(arInvoiceSavedata.invoiceNumber);
        if (addToBolId) {
            const body = {
                bolId: addToBolId,
                invoiceIdList: [arInvoiceSavedata.invoiceNumber],
            };
            axios.put(endpoint, body).then((data) => {
                // console.log(">>>>>>>>>> add to bol result: ", data);
                setShowAddToBolModal(false);
            });
        }
    };
    const handleCreateBolInInvoice = async () => {
        const bolNumEndPoint = getBOLNumForADD();
        const bolNumResult = await axios
            .post(bolNumEndPoint)
            .catch((err) => err);
        SetSelectedInvoicesDataBOL(
            selectedInvoicesDataBOL
                ? [...selectedInvoicesDataBOL, arInvoiceSavedata]
                : [arInvoiceSavedata]
        );
        setShowAddToBolModal(false);
        dispatch(setBOLNumberForAdd(bolNumResult.data));
        dispatch(setSelectedInvoiceScreen(InvoiceScreen.BOL));
        dispatch(setBOLDataByIddata(''));
    };

    const AddToBOLModalContent = () => {
        return (
            <>
                <ContentHeader value="Add to BOL"/>
                <Divider/>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        padding: 20,
                    }}
                >
                    <span>Existing BOL</span>
                    <div
                        className="general-field"
                        style={{
                            width: '80%',
                            margin: '10px 0',
                        }}
                    >
                        <DropDownListComponent
                            className="select-control"
                            dataSource={bolList}
                            fields={{text: 'bolId', value: 'bolId'}}
                            value={addToBolId}
                            onChange={(event: any) => {
                                //console.log(">>>>>>>>>>>> value: ", event.value);
                                setAddToBolId(event.value);
                            }}
                        />
                    </div>
                    <p>Or</p>
                    <div
                        style={{
                            display: 'flex',
                            margin: 10,
                            justifyContent: 'center',
                        }}
                    >
                        <ButtonComponent
                            className="e-outline"
                            onClick={handleCreateBolInInvoice}
                        >
                            CREATE NEW BOL
                        </ButtonComponent>
                    </div>
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'space-around',
                            margin: 15,
                        }}
                    >
                        <ButtonComponent
                            style={{marginRight: 10}}
                            cssClass="e-outline"
                        >
                            CANCEL
                        </ButtonComponent>
                        <ButtonComponent
                            cssClass="e-info"
                            onClick={callAddToBolApi}
                        >
                            Add to BOL
                        </ButtonComponent>
                    </div>
                </div>
            </>
        );
    };
    const salvageItemMapper = (salvageItemData: any[]) => {
        if (salvageItemData) {
            return salvageItemData.map((item: any) => ({
                barCode: item.id,
                description: item.description,
                bins: item.binsCount,
                boxes: item.boxesCount,
                items: item.itemsCount,
                cost: item.itemCost,
                rate: item.costPercentage,
                totalItemCost: item.totalItemCost
                    ? item.totalItemCost
                    : item.boxesCount * item.itemCost * item.itemsCount,
                totalItemsCount: item.totalItemsCount
                    ? item.totalItemsCount
                    : item.itemsCount * item.boxesCount,
                price: item.calculatePrice,
                creditMemo: item.creditMemo ? item.creditMemo : 0,
                upc: item.upc,
                barcode: item.barcode,
                creditPrice: item.creditPrice,
            }));
        } else {
            return [];
        }
    };
    const [updatedAttachData, setUpdatedAttachData] =
        useState<any>(selectedPalletsData);

    useEffect(() => {
        console.log('InvoiceTableData', InvoiceTableData);
        let attachData = {};
        if (selectedAttachmentTabIndex === 0) {
            attachData = selectedPalletsData.map((el: any) => {
                // @ts-ignore
                if (el.creditPrice == '' || el.creditPrice == undefined) {
                    const elObj = {
                        ...el,
                        creditPrice: 0,
                    };
                    return elObj;
                } else {
                    const elObj = {...el};
                    return elObj;
                }
            });
        } else if (selectedAttachmentTabIndex == 1) {
            attachData = selectedFPIData.map((el: any) => {
                // @ts-ignore
                if (el.creditPrice == '' || el.creditPrice == undefined) {
                    const elObj = {
                        ...el,
                        creditPrice: 0,
                    };
                    return elObj;
                } else {
                    const elObj = {...el};
                    return elObj;
                }
            });
        } else {
            attachData = salvageItemMapper(
                selectedArInvoiceTabIndex === 0
                    ? arInvoiceSavedata?.salvageItems
                    : creditMemoInfo?.salvageItems
            );
        }
        setUpdatedAttachData(attachData);
    }, [
        selectedPalletsData,
        selectedFPIData,
        selectedAttachmentTabIndex,
        creditMemoInfo.salvageItems,
    ]);
    const computeShowAddToBolCondition = () => {
        let hide = true;
        let str = '';
        if (typeof arInvoiceSavedata?.status === 'string') {
            str = arInvoiceSavedata?.status;
        } else {
            str = arInvoiceSavedata?.status?.status;
        }
        str = str?.replaceAll(' ', '').replaceAll(',', '');
        if (str === 'PickupAutoApproved' || str === 'PickupManualOverride') {
            hide = false;
        }
        return hide;
    };
    // useEffect(() => {
    //   if (isIndividualCMAdded) {
    //     let total = 0;
    //     selectedPalletsData.forEach((el: any) => {
    //       if (el.creditMemo) {
    //         // @ts-ignore
    //         total = total + el.creditMemo;
    //       }
    //     });
    //     selectedFPIData.forEach((el: any) => {
    //       if (el.creditMemo) {
    //         // @ts-ignore
    //         total = total + el.creditMemo;
    //       }
    //     });
    //     arInvoiceSavedata.salvageItems?.forEach((el: any) => {
    //       if (el.creditMemo) {
    //         // @ts-ignore
    //         total = total + el.creditMemo;
    //       }
    //     });

    //     setCreditMemoPrice(total);
    //     setIsIndividualCMAdded(false);
    //   }
    // }, [selectedPalletsData, selectedFPIData, arInvoiceSavedata?.salvageItems]);

    let pevVal = '0';
    let [llamount, setllAmount] = useState(0);
    // const handleAttachemntDataChange = (
    //   id: string,
    //   input: string,
    //   value: string,
    //   list: any,
    //   attachmentTabIndex: number
    // ) => {
    //   let updatedValue = parseFloat(value);
    //   if (value === "NaN" || value === "") {
    //     updatedValue = 0;
    //   }
    //   if (value.length >= 2 && value.charAt(0) == "0") {
    //     updatedValue = parseFloat(value.substring(0));
    //   }
    //   //setChangedCredit(changedCredit - parseInt(pevVal))

    //   const idVals = id.split("-");
    //   pevVal = value;

    //   console.log("updatedValue", updatedValue);
    //   //setCreditMemoPrice(parseFloat(afterAdded));
    //   let cmamount = 0;
    //   const itemIndex = input.split("-")[1];
    //   list.map((item: any, i: any) => {
    //     let temp = document.getElementById(
    //       "creditmemoid-" + i
    //     ) as HTMLInputElement;
    //     //  if ( i == itemIndex) {
    //     //   cmamount = cmamount + parseInt(value);
    //     //  } else {
    //     //   cmamount = cmamount + ((item.price * changedCredit)/InvoiceSavedataTotal.price)
    //     //  }
    //     console.log("temp.value", temp.value);
    //     console.log("item.price", item.price);
    //     if ( parseInt(temp.value) === item.price || parseInt(temp.value) < item.price) {
    //       console.log("temp value", attachmentTabIndex);
    //       if (selectedArInvoiceTabIndex == 1) {
    //         if (attachmentTabIndex == 0) {
    //           // @ts-ignore
    //           console.log("element.price", list[itemIndex].price);
    //           console.log("arInvoiceSavedata.price", arInvoiceSavedata.price);
    //           list[itemIndex] = {
    //             ...list[itemIndex],
    //             creditMemo: parseInt(value),
    //             creditPrice: updatedValue,
    //           };
    //           SetSlectedPalletsData([...list]);
    //           console.log("selectedPalletsData>>> Edit", list);
    //           //arInvoiceDefaultData.palletsList = selectedPalletsData;
    //         } else if (attachmentTabIndex == 1) {
    //           // @ts-ignore

    //           // let tmp = JSON.parse(JSON.stringify(value))
    //           // list[itemIndex].creditMemo = tmp;
    //           list[itemIndex] = {
    //             ...list[itemIndex],
    //             creditMemo: parseInt(value),
    //             creditPrice: updatedValue,
    //           };
    //           SetSlectedFPIData([...list]);
    //           console.log(list);
    //           console.log("selectedPalletsData>>> Edit", list);
    //         } else if (attachmentTabIndex == 2) {
    //           // @ts-ignore
    //           const copySalvage = JSON.parse(
    //             JSON.stringify(creditMemoInfo.salvageItems)
    //           );
    //           copySalvage[itemIndex].creditMemo = updatedValue;
    //           copySalvage[itemIndex].creditPrice = updatedValue;

    //           creditMemoInfo = {
    //             ...creditMemoInfo,
    //             fixedPrices: selectedFPIData,
    //             salvageItems: copySalvage,
    //           };
    //           dispatch(
    //             setArInvoiceSavedata({
    //               ...arInvoiceSavedata,
    //               fixedPrices: selectedFPIData,
    //               salvageItems: copySalvage,
    //             })
    //           );

    //           dispatch(
    //             setCreditMemoSavedata({
    //               ...creditMemoInfo,
    //               fixedPrices: selectedFPIData,
    //               salvageItems: copySalvage,
    //             })
    //           );
    //           setMiscSalvage(creditMemoInfo);
    //           //setCreditMemoInfo
    //         }
    //         //set
    //         console.log("creditMemoInfo", creditMemoInfo);
    //         setIsIndividualCMAdded(true);
    //       }
    //     } else {
    //       notify("*Not more than price", "error");
    //       temp.value = "";
    //     }
    //   });
    //   /// return {...changedCredit, changedCredit: afterAdded}
    //   //console.log(">>>>>>>>>>>> list: ", list, arInvoiceSavedata.salvageItems);
    // };
    const handleAttachemntDataChange = (
        id: string,
        input: string,
        value: string,
        list: any,
        attachmentTabIndex: number
    ) => {
        let updatedValue = parseFloat(value);
        if (value === 'NaN' || value === '') {
            updatedValue = 0;
        }
        if (value.length >= 2 && value.charAt(0) == '0') {
            updatedValue = parseFloat(value.substring(0));
        }
        //setChangedCredit(changedCredit - parseInt(pevVal))
        const idVals = id.split('-');
        pevVal = value;
        //setCreditMemoPrice(parseFloat(afterAdded));
        let cmamount = 0;
        const itemIndex = input.split('-')[1];
        let updatedList = JSON.parse(JSON.stringify(list));
        const item = updatedList[itemIndex];
        if (updatedValue <= item.price) {
            if (selectedArInvoiceTabIndex == 1) {
                if (attachmentTabIndex == 0) {
                    // @ts-ignore
                    item['creditMemo'] = updatedValue;
                    item['creditPrice'] = updatedValue;
                    SetSlectedPalletsData([...updatedList]);
                    dispatch(setIsInvoiceUpdated(true));
                } else if (attachmentTabIndex == 1) {
                    // @ts-ignore
                    item['creditMemo'] = updatedValue;
                    item['creditPrice'] = updatedValue;
                    SetSlectedFPIData([...updatedList]);
                    dispatch(setIsInvoiceUpdated(true));
                } else if (attachmentTabIndex == 2) {
                    // @ts-ignore
                    const copySalvage = JSON.parse(
                        JSON.stringify(creditMemoInfo.salvageItems)
                    );
                    copySalvage[itemIndex].creditMemo = updatedValue;
                    copySalvage[itemIndex].creditPrice = updatedValue;

                    creditMemoInfo = {
                        ...creditMemoInfo,
                        palletsList: selectedPalletsData,
                        fixedPrices: selectedFPIData,
                        salvageItems: copySalvage,
                    };
                    dispatch(
                        setCreditMemoSavedata({
                            ...creditMemoInfo,
                            palletsList: selectedPalletsData,
                            fixedPrices: selectedFPIData,
                            salvageItems: copySalvage,
                        })
                    );
                    setMiscSalvage(creditMemoInfo);
                }
                setIsIndividualCMAdded(true);
                dispatch(setIsInvoiceUpdated(true));
            }
        } else {
            notify('*Not more than price', 'error');
            //temp.value = "";
            console.log('list', list);
        }
        list.map((item: any, i: any) => {
            let temp = document.getElementById(
                'creditmemoid-' + i
            ) as HTMLInputElement;
            //  if ( i == itemIndex) {
            //   cmamount = cmamount + parseInt(value);
            //  } else {
            //   cmamount = cmamount + ((item.price * changedCredit)/InvoiceSavedataTotal.price)
            //  }
        });
        /// return {...changedCredit, changedCredit: afterAdded}
        //console.log(">>>>>>>>>>>> list: ", list, arInvoiceSavedata.salvageItems);
    };
    console.log('AR >>>>>', arInvoiceSavedata);
    // const attachmentData =
    //   selectedAttachmentTabIndex === 0
    //     ? [...selectedPalletsData]
    //     : selectedAttachmentTabIndex === 1
    //     ? [...selectedFPIData]
    //     : salvageItemMapper(
    //         selectedArInvoiceTabIndex === 0
    //           ? arInvoiceSavedata?.salvageItems
    //           : creditMemoInfo?.salvageItems
    //       );
    // console.log(
    //   ">>>>>>>>>>>>> attachmentData: ",
    //   attachmentData,
    //   selectedFPIData,
    //   selectedPalletsData,
    //   arInvoiceSavedata.salvageItems
    // );

    const handleCreditMemoPriceChange = (updatedPrice: string) => {
        if (updatedPrice <= InvoiceTableData.price) {
            console.log('InvoiceTableData', InvoiceTableData.price);
            let crerditSum = 0.0;
            if (selectedArInvoiceTabIndex == 1) {
                const updatedSelectedPalletItems = selectedPalletsData.map(
                    (el: any) => {
                        // @ts-ignore
                        const perAllcation =
                            parseFloat(el.price) /
                            parseFloat(totalItemPrice.toString());
                        const newMemoPrice = parseFloat(
                            (parseFloat(updatedPrice) * perAllcation).toFixed(2)
                        );
                        crerditSum = crerditSum + newMemoPrice;
                        console.log('newMemoPrice', newMemoPrice);

                        const elObj = {
                            ...el,
                            creditMemo: newMemoPrice,
                            creditPrice: newMemoPrice,
                        };
                        return elObj;
                    }
                );
                console.log(
                    'updatedSelectedPalletItems',
                    updatedSelectedPalletItems
                );
                const updatedSelectedFPIItems = selectedFPIData.map(
                    (el: any) => {
                        // @ts-ignore

                        const perAllcation =
                            parseFloat(el.price) /
                            parseFloat(totalItemPrice.toString());
                        const newMemoPrice = parseFloat(
                            (parseFloat(updatedPrice) * perAllcation).toFixed(2)
                        );
                        crerditSum = crerditSum + newMemoPrice;
                        const elObj = {
                            ...el,
                            creditMemo: newMemoPrice,
                            creditPrice: newMemoPrice,
                        };
                        return elObj;
                    }
                );
                let copySalvage;
                if (creditMemoInfo && creditMemoInfo > 0) {
                    copySalvage = JSON.parse(
                        JSON.stringify(creditMemoInfo?.salvageItems)
                    );
                } else {
                    copySalvage = JSON.parse(
                        JSON.stringify(creditMemoInfo?.salvageItems)
                    );
                }

                // console.log("selectedArInvoiceTabIndex", selectedArInvoiceTabIndex)
                let updatedSalvageItems = copySalvage.map((el: any) => {
                    // @ts-ignore
                    const per =
                        parseFloat(el.calculatePrice) /
                        parseFloat(totalItemPrice.toString());
                    const newMemoPrice = parseFloat(
                        (parseFloat(updatedPrice) * per).toFixed(2)
                    );
                    crerditSum = crerditSum + newMemoPrice;
                    const elObj = {
                        ...el,
                        creditMemo: newMemoPrice,
                        creditPrice: newMemoPrice,
                    };
                    return elObj;
                });
                //let creditPriceExists = updatedSalvageItems[0].hasOwnProperty('creditPrice')
                if (crerditSum != parseFloat(updatedPrice)) {
                    let diff = parseFloat(
                        (parseFloat(updatedPrice) - crerditSum).toFixed(2)
                    );
                    console.log('diff', diff);
                    if (updatedSelectedPalletItems.length > 0) {
                        updatedSelectedPalletItems[0].creditPrice = parseFloat(
                            (
                                updatedSelectedPalletItems[0].creditPrice + diff
                            ).toFixed(2)
                        );
                    } else if (updatedSelectedFPIItems[0].creditPrice > 0) {
                        updatedSelectedFPIItems[0].creditPrice = parseFloat(
                            (
                                updatedSelectedFPIItems[0].creditPrice + diff
                            ).toFixed(2)
                        );
                    } else {
                        updatedSalvageItems[0].creditPrice = parseFloat(
                            (updatedSalvageItems[0].creditPrice + diff).toFixed(
                                2
                            )
                        );
                    }
                }

                // console.log(
                //   ">>>>>>>>>>> called updated: ",
                //   updatedSelectedPalletItems,
                //   updatedSelectedFPIItems,
                //   updatedSalvageItems
                // );
                // setUpdatedAttachData([...updatedSelectedPalletItems]);

                SetSlectedPalletsData(updatedSelectedPalletItems);
                SetSlectedFPIData(updatedSelectedFPIItems);
                setCreditMemoPrice(parseFloat(updatedPrice));
                dispatch(
                    setArInvoiceSavedata({
                        ...arInvoiceSavedata,
                        palletsList: updatedSelectedPalletItems,
                        fixedPrices: updatedSelectedFPIItems,
                        salvageItems: updatedSalvageItems,
                    })
                );

                dispatch(
                    setCreditMemoSavedata({
                        ...creditMemoInfo,
                        palletsList: updatedSelectedPalletItems,
                        fixedPrices: updatedSelectedFPIItems,
                        salvageItems: updatedSalvageItems,
                    })
                );
                setMiscSalvage(creditMemoInfo);
            }
        }
        dispatch(setIsInvoiceUpdated(true));
    };
    let intl = new Internationalization();
    let nFormatter = intl.getNumberFormat({
        skeleton: 'C3',
        currency: 'USD',
        minimumIntegerDigits: 0,
        minimumFractionDigits: 2,
    });
    const handleCancelButtonClick = () => {
    };
    const handleSwitchScanMode = (e: any) => {
        console.log(e.target.checked);
        if (e.target.checked) {
            setScanModeTextbox(true);
            // setShowErrorMessage(false)
        } else {
            if (scannedPalletsItemsList.length > 0) {
                setConfirmPopup(true);
                //setScannedPalletsItemsList([]);
            } else {
                setScanModeTextbox(false);
            }
        }
    };
    const handleConfirmPopupYes = () => {
        setScannedPalletsItemsList([]);
        setScanModeTextbox(false);
        setConfirmPopup(false);
        dispatch(setScannedPalletsInfo([]));
        setShowAttachmentModal(false);
        var ddlSelectCustType = document.getElementById(
            'generalTabActive'
        ) as HTMLInputElement;
        ddlSelectCustType.checked = false;
        setConfirmPopup(false);
    };
    const handleConfirmPopupNo = () => {
        var ddlSelectCustType = document.getElementById(
            'generalTabActive'
        ) as HTMLInputElement;
        ddlSelectCustType.checked = true;
        setConfirmPopup(false);
    };

    const handleCancelPalletPopup = () => {
        if (scanModeTextbox && scannedPalletsItemsList.length > 0) {
            setConfirmPopup(true);
        } else {
            setShowAttachmentModal(false);
        }
    };
    const handleChangeScannedItem = (e: any) => {
        if (e.keyCode === 13 && e.target.value) {
            setLoaderStatus(true);
            console.log(e.target.value);
            //setShowErrorMessage(false)
            const endPointScan = getScannedPallets(e.target.value);
            handleGetScannedResult(endPointScan, e.target.value);
        } else {
            setShowErrorMessage(false);
        }
    };
    const scannedItemData: any[] = [];
    const handleGetScannedResult = async (endpoint: string, scanId: any) => {
        const result = await axios
            .get(endpoint)
            .then((results: any) => {
                if (results && results?.data) {
                    console.log(results?.data);
                    var ddlSelectCustType = document.getElementById(
                        'txtScanItem'
                    ) as HTMLInputElement;
                    ddlSelectCustType.value = '';
                    let PLlist: any = [];
                    PLlist.push(results?.data);
                    //setShowErrorMessage(false)
                    setLoaderStatus(false);

                    if (
                        !scannedPalInfo.some((st) => st.id === results?.data.id)
                    ) {
                        const scanList = [
                            {...results?.data},
                            ...scannedPalletsItemsList,
                        ];
                        dispatch(setScannedPalletsInfo(scanList));
                        setScannedPalletsItemsList(scanList);
                    } else {
                        setShowErrorMessage(true);
                        //setErrorMessageText('Pallet(s) exist');
                        // let errorList: any = [];
                        // errorList.push('Pallet(s) exist')

                        const scanPalletErrorMsg = [
                            'Pallet(s) exist- ' + scanId,
                            ...showErrorMessageList,
                        ];
                        setShowErrorMessageList(scanPalletErrorMsg);
                        console.log(showErrorMessageList);
                    }
                } else {
                    notify('Failed', 'error');
                    setLoaderStatus(false);
                    var ddlSelectCustType = document.getElementById(
                        'txtScanItem'
                    ) as HTMLInputElement;
                    ddlSelectCustType.value = '';
                }
            })
            .catch((error: any) => {
                if (error.response) {
                    setLoaderStatus(false);
                    //notify(error.response?.data?.message, "error");
                    setShowErrorMessage(true);
                    const scanPalletErrorMsg = [
                        'Pallet not found for this id- ' + scanId,
                        ...showErrorMessageList,
                    ];
                    setShowErrorMessageList(scanPalletErrorMsg);
                    var ddlSelectCustType = document.getElementById(
                        'txtScanItem'
                    ) as HTMLInputElement;
                    ddlSelectCustType.value = '';
                }
            });
    };
    return (
        <DashboardLayoutComponent
            id="defaultLayout"
            cellSpacing={[5, 5]}
            allowResizing={true}
            columns={5}
        >
            <div
                className="invoices-details-container"
                data-row="0"
                data-col="0"
                data-sizex="5"
                data-sizey="1"
                key={forceUpdate}
            >
                {selectedInvoiceScreen === InvoiceScreen.INVOICE ? (
                    <>
                        {/* <TabComponent
              heightAdjustMode="Auto"
              selected={handleTabSelection}
              selectedItem={selectedArInvoiceTabIndex == 0 ? 0 : 1}
            >
              <TabItemsDirective>
                {invoiceDetailsTab.map((tab) => {
                  return <TabItemDirective key={tab} header={{ text: tab }} />;
                })}
              </TabItemsDirective>
            </TabComponent> */}

                        <div className="invoices-details-controls">
                            <ContentHeader
                                value={
                                    selectedArInvoiceTabIndex == 0
                                        ? !arInvoiceSavedata?.invoiceNumber
                                            ?.charAt(0)
                                            .includes('C')
                                            ? arInvoiceSavedata.invoiceNumber
                                            : 'New Invoice'
                                        : selectedArInvoiceTabIndex === 1
                                            ? invoiceNumCM
                                                ? invoiceNumCM + 'CM'
                                                : creditMemoInfo.invoiceNumber +
                                                'CM'
                                            : ''
                                }
                            />
                            <InvoiceDetailsTabControl
                                hideCancelButton={computeShowAddToBolCondition()}
                                handleAddInvoice={handleAddInvoice}
                                handleAddToBOL={handleAddToBOL}
                                handleDeleteInvoice={handleDeleteInvoice}
                                hideDeleteBtn={
                                    arInvoiceSavedata?.invoiceNumber
                                        ?.charAt(0)
                                        .includes('C')
                                        ? true
                                        : false
                                }
                                handleSave={
                                    selectedArInvoiceTabIndex == 1
                                        ? handleSaveCreditMemo
                                        : handleSaveInvoice
                                }
                                disableControls={
                                    (typeof arInvoiceSavedata.status ===
                                        'string' &&
                                        arInvoiceSavedata.status !== 'Draft') ||
                                    (arInvoiceSavedata.status?.status &&
                                        arInvoiceSavedata.status?.status !=
                                        'Draft') ||
                                    disableInvoiceSaveButton
                                        ? true
                                        : false
                                    //false
                                }
                            />
                        </div>
                        <Divider/>
                    </>
                ) : (
                    <>
                        {/* <TabComponent heightAdjustMode="Auto">
              <TabItemsDirective>
                {bolTabs.map((tab) => {
                  return <TabItemDirective key={tab} header={{ text: tab }} />;
                })}
              </TabItemsDirective>
            </TabComponent> */}

                        <div className="invoices-details-controls">
                            {!bolData ? (
                                <ContentHeader value={'New BOL'}/>
                            ) : (
                                <ContentHeader
                                    value={
                                        bolData.id
                                        // (bolData?.description ? bolData?.description : "") +
                                        // "(" +
                                        // bolData.id +
                                        // ")"
                                    }
                                />
                            )}
                            <hr/>
                            <BolDetailsTabControl
                                handleActionHistoryBOL={handleActionHistoryBOL}
                                handleAddInvoice={handleAddInvoice}
                                handleAddToBOL={handleAddToBOL}
                                handleSave={handleSaveBOL}
                                handleDeleteBOL={handleDeleteBOL}
                                hideDeleteBtnBOL={
                                    bolData?.id && bolData?.id ? false : true
                                }
                                hideAddBtnBOL={
                                    bolData?.id && bolData?.id ? false : true
                                }
                                disableControls={
                                    bolData?.status?.status === 'Picked' ||
                                    bolData?.status?.status === 'Cancelled' ||
                                    bolData?.status?.status === 'Released' ||
                                    disableBolSaveButton
                                        ? true
                                        : false
                                }
                            />
                        </div>
                    </>
                )}

                {selectedInvoiceScreen === InvoiceScreen.INVOICE ? (
                    <div className="InvoicesDiv">
                        <div className="secondary-details">
                            <>
                                {selectedArInvoiceTabIndex == 0 ? (
                                    <span
                                        className="backToInvSpan"
                                        onClick={handleGoBack}
                                    >{`< Back to Invoices`}</span>
                                ) : (
                                    <span
                                        className="backToInvSpan"
                                        onClick={handleGoBack}
                                    >{`< Back to Credit Memos
            `}</span>
                                )}
                            </>
                            {selectedArInvoiceTabIndex === 0 ? (
                                <StatusButton
                                    id={status}
                                    disable={
                                        !newSavedInvoiceList.some(
                                            (el) =>
                                                el.invoiceNumber ==
                                                arInvoiceSavedata.invoiceNumber
                                        ) ||
                                        (typeof arInvoiceSavedata.status ===
                                            'string' &&
                                            arInvoiceSavedata.status !=
                                            'Draft') ||
                                        (arInvoiceSavedata.status?.status &&
                                            arInvoiceSavedata.status?.status !=
                                            'Draft')
                                            ? false
                                            : true
                                    }
                                    handleChange={(value) =>
                                        updateArInvoiceStatus(value)
                                    }
                                />
                            ) : (
                                <StatusButtonCM
                                    id={statusCM}
                                    handleChange={(value) =>
                                        updateArInvoiceStatus(value)
                                    }
                                    disable={
                                        creditMemoInfo?.status?.status ===
                                        'Cancelled'
                                            ? true
                                            : false
                                    }
                                    // id={status}
                                    // handleChange={(value) => updateBOLStatus(value)}
                                />
                            )}
                        </div>
                        {selectedArInvoiceTabIndex == 0 ? (
                            <>
                                <InputCollection
                                    handleChange={(data) => {
                                        setInvoiceDetailsData(data);
                                        dispatch(setIsInvoiceSaved(false));
                                    }}
                                />
                                <InvoiceTable
                                    pallets={InvoiceTableData.pallets.toString()}
                                    bins={InvoiceTableData.bins.toString()}
                                    boxes={InvoiceTableData.boxes.toString()}
                                    items={InvoiceTableData.items.toString()}
                                    cost={InvoiceTableData.cost.toString()}
                                    price={InvoiceTableData.price.toString()}
                                />
                            </>
                        ) : (
                            <CreditMemos
                                creditMemoData={
                                    creditMemoInfo
                                        ? creditMemoInfo
                                        : arInvoiceSavedata
                                }
                                handleCreditMemoPriceChange={
                                    handleCreditMemoPriceChange
                                }
                                creditMemoPrice={creditMemoPrice}
                            />
                        )}
                        <div className="attachment-tabs">
                            <TabComponent
                                heightAdjustMode="Auto"
                                selected={handleAttachmentTabSelection}
                            >
                                <TabItemsDirective>
                                    {invoiceAttachmentsTabs.map((tab) => {
                                        return (
                                            <TabItemDirective
                                                key={tab}
                                                header={{text: tab}}
                                            />
                                        );
                                    })}
                                </TabItemsDirective>
                            </TabComponent>
                            {selectedArInvoiceTabIndex == 0 ? (
                                <AttachmentTabControls
                                    selectedArInvoiceTabIndex={
                                        selectedAttachmentTabIndex
                                    }
                                    handleAddAttachment={handleAddAttachment}
                                    // handleEditAttachment={handleAddAttachment}
                                    handleEditAttachment={handleEditAttachment}
                                    handleDeleteAttachment={
                                        handleDeleteAttachment
                                    }
                                    disableControls={
                                        (typeof arInvoiceSavedata.status ===
                                            'string' &&
                                            arInvoiceSavedata.status !=
                                            'Draft') ||
                                        (arInvoiceSavedata.status?.status &&
                                            arInvoiceSavedata.status?.status !=
                                            'Draft')
                                            ? true
                                            : false
                                    }
                                />
                            ) : (
                                ''
                            )}
                        </div>

                        {selectedArInvoiceTabIndex === 0 ? (
                            <>
                                <NoCheckBoXGrid
                                    config={
                                        invoiceAttachmentGridConfig[
                                            selectedAttachmentTabIndex
                                            ]
                                    }
                                    data={
                                        selectedAttachmentTabIndex === 0
                                            ? [...selectedPalletsData]
                                            : selectedAttachmentTabIndex === 1
                                                ? [...selectedFPIData]
                                                : salvageItemMapper(
                                                    selectedArInvoiceTabIndex ===
                                                    0
                                                        ? arInvoiceSavedata?.salvageItems
                                                        : creditMemoInfo?.salvageItems
                                                )
                                    }
                                    width={'100%'}
                                    onRowSelect={handleGridRowSelect}
                                    hideSpinner={true}
                                />
                            </>
                        ) : (
                            <div style={{display: 'flex'}}>
                                <NoCheckBoXGrid
                                    config={
                                        invoiceAttachmentGridConfig[
                                            selectedAttachmentTabIndex
                                            ]
                                    }
                                    data={
                                        selectedAttachmentTabIndex === 0
                                            ? [...selectedPalletsData]
                                            : selectedAttachmentTabIndex === 1
                                                ? [...selectedFPIData]
                                                : salvageItemMapper(
                                                    selectedArInvoiceTabIndex ===
                                                    0
                                                        ? arInvoiceSavedata?.salvageItems
                                                        : creditMemoInfo?.salvageItems
                                                )
                                    }
                                    width={'92%'}
                                    height={'500'}
                                    onRowSelect={handleGridRowSelect}
                                    hideSpinner={true}
                                    isFromInvoice={false}
                                />
                                <CreditMemoTableDynamic
                                    list={updatedAttachData}
                                    onDataChange={handleAttachemntDataChange}
                                    attachmentTabIndex={
                                        selectedAttachmentTabIndex
                                    }
                                />
                            </div>
                        )}
                        {/*
            {data.length === 0 && (
              <span
                className="add-attachment-item"
                onClick={() => setShowAttachmentModal(true)}
              >
                {selectedAttachmentTabIndex === 0
                  ? "Add pallets"
                  : selectedAttachmentTabIndex === 1
                  ? "Add fixed price item"
                  : "Add mixed-salvage item"}
              </span>
            )} */}
                    </div>
                ) : (
                    <>
                        <hr className="hrBOL"/>
                        <div className="secondary-details">
                            <span
                                className="backToInvSpan"
                                onClick={handleGoBack}
                            >{`< Back to Bols
            `}</span>
                            {/* <StatusButtonBol handleChange={(value) => setStatus(value)} /> */}
                            <StatusButtonBol
                                id={statusBOL}
                                disable={
                                    bolData?.status?.status === 'Picked' ||
                                    bolData?.status?.status === 'Cancelled'
                                        ? true
                                        : false
                                }
                                handleChange={(value) => updateBOLStatus(value)}
                                // id={status}
                                // handleChange={(value) => updateBOLStatus(value)}
                            />
                        </div>

                        <InputCollectionBol
                            handleChange={(data) => setBolDetailsData(data)}
                        />

                        <div className="attachment-tabs">
                            <TabComponent
                                heightAdjustMode="Auto"
                                selected={handleAttachmentTabSelectionForBOL}
                            >
                                <TabItemsDirective>
                                    {bolAttachmentsTabs.map((tab) => {
                                        return (
                                            <TabItemDirective
                                                key={tab}
                                                header={{text: tab}}
                                            />
                                        );
                                    })}
                                </TabItemsDirective>
                            </TabComponent>
                            <AttachmentTabControls
                                handleAddAttachment={handleAddAttachmentBol}
                                handleEditAttachment={handleEditAttachment}
                                handleDeleteAttachment={
                                    handleDeleteAttachmentBol
                                }
                                disableControls={
                                    bolData?.status?.status === 'Picked' ||
                                    bolData?.status?.status === 'Cancelled' ||
                                    bolData?.status?.status === 'Released'
                                        ? true
                                        : false
                                }
                                hideEditButton={
                                    selectedAttachmentTabIndexForBOL === 0 && bolData?.status?.status === 'Draft' ? true : false
                                }
                            />
                        </div>
                        {data.length === 0 && (
                            <NoCheckBoXGrid
                                config={
                                    bolAttachmentGridConfig[
                                        selectedAttachmentTabIndexForBOL
                                        ]
                                }
                                //data={data}
                                data={
                                    selectedAttachmentTabIndexForBOL === 0
                                        ? selectedInvoicesDataBOL
                                        : ManualEntryList
                                }
                                width={'100%'}
                                isFromInvoice={true}
                                onRowSelect={(event) =>
                                    handleGridRowSelectBol(event)
                                }
                            />
                        )}
                        {/* {data.length === 0 && (
          <span
            className="add-attachment-item"
            onClick={() => setShowAttachmentModalBol(true)}
          >
           {selectedAttachmentTabIndexForBOL === 0
              ? "Add Invoice"
              : "Add manual entry"}
          </span>
        )} */}
                    </>
                )}

                <LLModal
                    handleClose={() => {
                        setShowAttachmentModal(false);
                        handleCloseModal();
                    }}
                    show={showAttachmentModal}
                    width={selectedAttachmentTabIndex == 2 ? '60vw' : '90vw'}
                    padding={selectedAttachmentTabIndex == 2 ? 20 : undefined}
                >
                    {selectedAttachmentTabIndex == 0 && (
                        <>
                            {scanModeTextbox ? (
                                <div>
                                    {/* <div className="txtBoxesDiv" > */}
                                    <div className="col-sm-3">
                                        <input
                                            type="text"
                                            autoFocus
                                            className="form-control"
                                            id="txtScanItem"
                                            onKeyUp={(e) =>
                                                handleChangeScannedItem(e)
                                            }
                                        />
                                    </div>
                                    {/* <div className="col-sm-3">
              {showErrorMessage ?
              <div className="errorTextDiv"><div className="txtError"><p>{showErrorMessageList}</p></div></div>
              :''}
              </div> */}
                                    <div className="col-sm-3">
                                        {showErrorMessage ? (
                                            <div className="errorTextDiv">
                                                <div className="txtError">
                                                    {showErrorMessageList?.map(
                                                        (info, index) => (
                                                            <p>
                                                                {index + 1}){' '}
                                                                {info}
                                                            </p>
                                                        )
                                                    )}
                                                </div>
                                            </div>
                                        ) : (
                                            ''
                                        )}
                                    </div>
                                    {/* </div> */}
                                    <br/>
                                    <br/>
                                    <br/>
                                    <div className="attachment-pallet-grid">
                                        {/* <Grid
                config={addAttachmentGridConfig[selectedAttachmentTabIndex]}
                 //data={ scannedPalInfo.filter(value => JSON.stringify(value) !== '[]')}
                data={scannedPalletsItemsList}
                width={"100%"}
                onRowSelect={(e) => {
                  SetSelectingPalletsData(e);
                }}
                hideSpinner={true}
                isFromInvoice = {false}
                onRowDeselect={(e) => {
                  // console.log(e);
                  SetSelectingPalletsData(e);
                }}
              /> */}
                                        <NoCheckBoXGrid
                                            config={
                                                addAttachmentGridConfig[
                                                    selectedAttachmentTabIndex
                                                    ]
                                            }
                                            data={scannedPalletsItemsList}
                                            width={'100%'}
                                            hideSpinner={true}
                                            isFromActionHistory={true}
                                        />
                                        <div className="LoadImg">
                                            {loaderStatus ? (
                                                <img
                                                    className="loaderClass"
                                                    src={loader}
                                                />
                                            ) : (
                                                <></>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            ) : (
                                <div className="attachment-pallet-grid">
                                    <Grid
                                        config={
                                            addAttachmentGridConfig[
                                                selectedAttachmentTabIndex
                                                ]
                                        }
                                        data={PalletsToShow}
                                        width={'100%'}
                                        onRowSelect={(e) => {
                                            SetSelectingPalletsData(e);
                                        }}
                                        isFromInvoice={false}
                                        onRowDeselect={(e) => {
                                            // console.log(e);
                                            SetSelectingPalletsData(e);
                                        }}
                                    />
                                    <div className="LoadImg">
                                        {loaderStatus ? (
                                            <img
                                                className="loaderClass"
                                                src={loader}
                                            />
                                        ) : (
                                            <></>
                                        )}
                                    </div>
                                </div>
                            )}
                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'flex-end',
                                }}
                            >
                                {/* <ButtonComponent
                  style={{
                    color: "black",
                    margin: "20px",
                    padding: "10px 30px",
                  }}
                  className="eg-btn-secondary"
                >
                  SCAN BIN/BOX
                </ButtonComponent> */}

                                <input
                                    type="checkbox"
                                    // defaultChecked={false}
                                    id="generalTabActive"
                                    className="toggle"
                                    onChange={(e: any) =>
                                        handleSwitchScanMode(e)
                                    }
                                />
                                <label
                                    className="chkbx"
                                    htmlFor="generalTabActive"
                                >
                                    <span className="ActiveInactiveFont">
                                        Scan Mode
                                    </span>
                                </label>
                                <ButtonComponent
                                    style={{
                                        color: 'black',
                                        margin: '20px',
                                        padding: '10px 30px',
                                    }}
                                    className="eg-btn-secondary"
                                    onClick={handleCancelPalletPopup}
                                    //onClick={() => setShowAttachmentModal(false)}
                                >
                                    CANCEL
                                </ButtonComponent>
                                <ButtonComponent
                                    style={{
                                        color: 'white',
                                        backgroundColor: '#636799',
                                        margin: '20px',
                                        padding: '10px 20px',
                                    }}
                                    className="eg-btn-primary"
                                    // onClick={() => {
                                    //   SetSlectedPalletsData([
                                    //     ...selectedPalletsData,
                                    //     ...selectingPalletsData,
                                    //   ]);
                                    //   setShowAttachmentModal(false);
                                    // }}
                                    onClick={(e: any) => addPallets(e)}
                                >
                                    ADD PALLET
                                </ButtonComponent>
                            </div>
                        </>
                    )}
                    {selectedAttachmentTabIndex == 1 && (
                        <div className="fixedpriceItem-modal">
                            <div className="fixedpriceItem-modal-content">
                                <SingleSelectGrid
                                    config={
                                        addAttachmentGridConfig[
                                            selectedAttachmentTabIndex
                                            ]
                                    }
                                    data={FPIList}
                                    width={'100%'}
                                    onRowSelect={(e: any) => {
                                        setSelectingFPIData(e);
                                    }}
                                />

                                <FixedItemDetailsForm
                                    selectedFPIData={{...selectingFPIData}}
                                    setShowAttachmentModal={(d: any) => {
                                        setSelectingFPIData({});
                                        setShowAttachmentModal(d);
                                    }}
                                    AddFixedPriceItem={async (
                                        formValues: any
                                    ) => {
                                        setCalculateFPIData(formValues);
                                        await AddFixedPriceItem(formValues);
                                    }}
                                />
                            </div>
                        </div>
                    )}
                    {selectedAttachmentTabIndex == 2 && (
                        <div key={refreshGrid}>
                            <MiscSalvageItemForm
                                setData={AddMiscSalvageItem}
                                setShowAttachmentModal={(e: any) => {
                                    setShowAttachmentModal(e);
                                    setPickedMiscDataForEdit([]);
                                    // SetPickedMiscData([])
                                    setRefreshGrid(new Date().getTime());
                                }}
                                PickedMiscDataForEdit={PickedMiscDataForEdit}
                            />
                        </div>
                    )}
                </LLModal>

                <LLModal
                    handleClose={() => setFixedItemDetailsEditFormModel(false)}
                    show={FixedItemDetailsEditFormModel}
                    padding={20}
                    width="40%"
                >
                    <FixedItemDetailsEditForm
                        PickedFPIData={[...PickedFPIData]}
                        setShowAttachmentModal={(d: any) => {
                            setFixedItemDetailsEditFormModel(false);
                        }}
                        AddFixedPriceItem={async (formValues: any) => {
                            // setCalculateFPIData(formValues);
                            await AddFixedPriceItem(formValues);
                        }}
                    />
                </LLModal>

                <LLModal
                    handleClose={() => setShowInvoiceModal(false)}
                    show={showInvoiceModal}
                    padding={20}
                >
                    <NewInvoiceModalContent/>
                </LLModal>
                <LLModal
                    handleClose={() => setShowAddToBolModal(false)}
                    show={showAddToBolModal}
                    padding={20}
                    width="40%"
                >
                    <AddToBOLModalContent/>
                </LLModal>

                <RouteLeavingGuard
                    when={whenState}
                    navigate={(path: any) => {
                        history.push(path);
                    }}
                    shouldBlockNavigation={(location: any) => {
                        if (whenState) {
                            // if (location.pathname === 'signup') {
                            //     return true
                            //   }
                            return true;
                        }
                        return false;
                    }}
                    yes="Yes"
                    no="No"
                    onClickYes={handleSaveInvoice}
                    content={`Save current ${
                        selectedInvoiceScreen === InvoiceScreen.INVOICE ?
                            (selectedArInvoiceTabIndex === 0 ? 'Invoice' : 'Credit Memo')
                            : 'BOL'}
            before proceeding?`
                    }
                />
                {/* Bol Tab items add and view */}
                <LLModal
                    handleClose={() => setShowAttachmentModalBol(false)}
                    show={showAttachmentModalBol}
                >
                    <div className="attachment-pallet-grid">
                        {selectedAttachmentTabIndexForBOL == 0 ? (
                            <div key={refreshGrid}>
                                <Grid
                                    config={
                                        invoiceGridConfig[
                                            selectedAttachmentTabIndexForBOL
                                            ]
                                    }
                                    data={invoicesForBOLAdd}
                                    width={'100%'}
                                    onRowSelect={(e) => {
                                        SetSelectingInvoiceDataBOL(e);
                                    }}
                                    hideSpinner={true}
                                    onRowDeselect={(e) => {
                                        SetSelectingInvoiceDataBOL(e);
                                    }}
                                />
                                <div
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'flex-end',
                                    }}
                                >
                                    <ButtonComponent
                                        style={{
                                            color: 'black',
                                            margin: '20px',
                                            padding: '10px 30px',
                                        }}
                                        onClick={() => {
                                            // setPickedMiscDataForEdit([]);
                                            // SetPickedMiscData([])
                                            // setRefreshGrid(new Date().getTime());
                                            setShowAttachmentModalBol(false);
                                        }}
                                    >
                                        CANCEL
                                    </ButtonComponent>
                                    <ButtonComponent
                                        style={{
                                            color: 'white',
                                            backgroundColor: '#636799',
                                            margin: '20px',
                                            padding: '10px 30px',
                                            // margin: "5px",
                                        }}
                                        onClick={() => {
                                            SetSelectedInvoicesDataBOL([
                                                ...selectedInvoicesDataBOL,
                                                ...selectingInvoiceDataBOL,
                                            ]);
                                            setShowAttachmentModalBol(false);
                                            dispatch(setIsInvoiceUpdated(true));
                                        }}
                                    >
                                        ADD{' '}
                                    </ButtonComponent>
                                </div>
                            </div>
                        ) : (
                            <>
                                <ManualEntryForm
                                    data={ManualEntryList}
                                    setData={SetManualEntryList}
                                    setShowAttachmentModalBol={
                                        setShowAttachmentModalBol
                                    }
                                    handleAddManualItem={handleAddManualItem}
                                    PickedManualEntryDataForEdit={
                                        PickedManualEntryDataForEdit
                                    }
                                />
                                <ButtonComponent
                                    className="binCancelManualEntryPop"
                                    onClick={() =>
                                        setShowAttachmentModalBol(false)
                                    }
                                >
                                    CANCEL
                                </ButtonComponent>
                            </>
                        )}
                    </div>
                </LLModal>

                <LLModal
                    handleClose={() => setShowActionHistoryModalBOL(false)}
                    show={showActionHistoryModalBOL}
                >
                    <h3>Action History for BOL #{bolData.id}</h3>
                    <div className="History-grid">
                        <NoCheckBoXGrid
                            config={actionHistoryGridConfig}
                            data={actionHistoryData}
                            hideSpinner={true}
                            isFromActionHistory={true}
                        />
                    </div>
                    <ButtonComponent
                        className="btnCloseActionHistoryPopupBol e-control e-btn e-lib eg-btn-primary float-right btnBox"
                        onClick={() => setShowActionHistoryModalBOL(false)}
                    >
                        CLOSE
                    </ButtonComponent>
                </LLModal>
                <LLModal
                    handleClose={() => setConfirmPopup(false)}
                    show={confirmPopup}
                    width="25%"
                >
                    <h3>Are you sure want to continue?</h3>
                    <ButtonComponent
                        className="btnCloseActionHistoryPopupBol e-control e-btn e-lib btnNoConfirmPopup"
                        onClick={() => handleConfirmPopupNo()}
                    >
                        NO
                    </ButtonComponent>
                    <ButtonComponent
                        className="btnCloseActionHistoryPopupBol e-control e-btn e-lib eg-btn-primary float-right"
                        onClick={() => handleConfirmPopupYes()}
                    >
                        YES
                    </ButtonComponent>
                </LLModal>
            </div>
        </DashboardLayoutComponent>
    );
};
export default InvoiceDetails;

function updateCreditValue() {
    throw new Error('Function not implemented.');
}
