import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import Grid from "../../grid/Grid";
import { useEffect, useState } from "react";
import { generateGridData, timeZoneHeaders } from "../utils";
import LLAdminPreviewTab from "../previewTab/previewPane";
import loader from "../../../assets/loader.gif";

const TimeZone = () => {
  // const [loaderStatus, setLoaderStatus] = useState(true)
  /* Get TimeZone data from Grid Reducer */
  const selectedRowData = useSelector(
    (state: RootState) => state.gridReducer.selectedRowData
  );
  // console.log(selectedRowData, 'selectedRowData');

  /* Get TimeZone data from LL admin Reducer */
  const timeZoneData = useSelector(
    (state: RootState) => state.lladminReducer.timezoneData
  );
  // console.log(timeZoneData, 'timeZoneData');

  /* Defined Grid Hieght based on Data  */
  let gridHieght = "calc(100vh - 450px)";

  /* Excute code on page load */
  useEffect(() => {
    // Write some code
  });
  const getSelectedRecord = (e: any) => {
    //console.log(e)
  };
  const getDeSelectedRecord = (e: any) => {
    //  seletedDataToExport(e);
  };

  const loaderStatus = useSelector(
    (state: RootState) => state.lladminReducer.isLoaderShow
  );
  return (
    <div className="col-sm-12 col-md-12 col-lg-12 ">
      <div className="control-pane">
        <div className="control-section tab-control-section">
          <div className="col-sm-12 col-md-12 col-lg-12 ">
            <div className="col-sm-8 col-md-12 col-lg-8 pl-0 pr-4 child-data-section " style={{width: '75%'}}>
            <>
            {loaderStatus ? (
              <div className="LoadImg">
                <img className="loaderClass" src={loader} />
                </div> ) : 
              <Grid
                config={timeZoneHeaders}
                data={generateGridData("Time Zones", timeZoneData)}
                width={"100%"}
                height={gridHieght}
                onRowSelect={getSelectedRecord}
                onRowDeselect={getDeSelectedRecord}
              />  
            }      </>
      
            </div>
            {selectedRowData && selectedRowData.length > 0 ? (
              <div className="col-sm-4 col-md-12 col-lg-4 pl-4 child-data-section " style={{width: '25%'}}>
                <LLAdminPreviewTab previewData={selectedRowData} />
              </div>
            ) : (
              <div className="col-sm-4 col-md-12 col-lg-4 pl-4 child-data-section " style={{width: '25%'}}>
                <div className="childTab-preview-pane d-flex align-items-center">
                  <div className="col-sm-12 col-md-12 col-lg-12 d-flex justify-content-center">
                    <p className="preview-msg">
                      Select time zone to view details
                    </p>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TimeZone;
