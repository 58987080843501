//const  baseURL = process.env.REACT_APP_MASTER_URL;
//const baseURL = "https://dev0-revlog.limnlabs.com/api/revlog";
//const  baseURL = process.env.REACT_APP_URL;
//const  clientContext = process.env.REACT_APP_CLIENT_CONTEXT;
//const  baseURL = 'http://localhost:50008';
//const  clientContext = '';
// const clientContext = "/invoice";
//const baseURL = 'https://eagle.arkatiss.com';

import { environment } from "../../environment";

//const  baseURL = process.env.REACT_APP_URL;
//const  clientContext = process.env.REACT_APP_CLIENT_CONTEXT;

// const baseURL = process.env.REACT_APP_URL;
// const clientContext = process.env.REACT_APP_CLIENT_CONTEXT;
//const clientContext = "/CS0001";

const baseURL = environment.react_app_url;
const clientContext = environment.react_app_client_context;

export const getARInvoicesListEndPoint = () => {
  return baseURL + `${clientContext}/v1/invoice/arinvoices/list`;
};
export const getARInvoicesListEndPointForBOL = (custId: any, Whid: any) => {
  return (
    baseURL +
    `${clientContext}/v1/invoice/arinvoices/list/bol?customerid=${custId}&warehouseid=${Whid}`
  );
};
export const getARInvoicesListEndPointForCreditMemo = () => {
  return (
    baseURL +
    `${clientContext}/v1/invoice/arinvoices/list?selectiontype=creditmemo`
  );
};
export const getCreditMemoListEndPoint = () => {
  return baseURL + `${clientContext}/v1/invoices/creditmemo/list`;
};
export const getArInvoiceNumberEndPoint = () => {
  return baseURL + `${clientContext}/v1/invoice/arinvoices/items/creationv1`;
};
export const SaveArInvoicesEndPoint = (id: string) => {
  return baseURL + `${clientContext}/v1/invoice/arinvoices/items/${id}`;
};
export const getArInvoicesEndPointForCM = (id: string) => {
  return (
    baseURL + `${clientContext}/v1/invoice/arinvoices/items/${id}/creditmemo`
  );
};
export const handleCancelActionCall = () => {
  return baseURL + `${clientContext}/v1/invoice/arinvoices/list/updateStatus`;
};
export const handleCancelActionCallCMemo = () => {
  return baseURL + `${clientContext}/v1/invoices/creditmemo/list/updateStatus`;
};
export const handleCancelActionCallBOL = () => {
  return baseURL + `${clientContext}/v1/invoice/bol/list/updateStatus`;
};
export const SaveArInvoicesNewEndPoint = (id: string) => {
  return baseURL + `${clientContext}/v1/invoice/arinvoices/items/${id}/editv1`;
};
export const creditMemoById = (id: string) => {
  return baseURL + `${clientContext}/v1/invoices/creditmemo/${id}`;
};
export const getFPIListEndPoint = () => {
  return baseURL + `/revlog/v1/masterdata/commoditytype/list?forSelection=true`;
};
export const getArInvoicesExport = () => {
  return baseURL + `${clientContext}/v1/reports/printinvoicedetail`;
};
export const getInvoiceWithOutPricing = () => {
  return baseURL + `${clientContext}/v1/reports/printinvoicewithoutprice`;
};
export const getInvoiceWithDetails = () => {
  return baseURL + `${clientContext}/v1/reports/printinvoicewithdetail`;
};
export const getInvoiceWithClient = () => {
  return baseURL + `${clientContext}/v1/reports/printinvoicewithclient`;
};
export const getBOLPrint = () => {
  return baseURL + `${clientContext}/v1/reports/printbol`;
};
// export const getPalletsListEndPoint = () => {
//   return baseURL + `${clientContext}/v1/secondaryretail/list`;
// };

////input collection
export const getReclaimCenterListEndPoint = () => {
  return (
    baseURL + `${clientContext}/v1/masterdata/reclaimcenters/list?forSelection=true`
  );
};

export const addBOLEndPoint = (id: any) => {
  return baseURL + `${clientContext}/v1/invoice/bol/items/${id}`;
};
export const deleteBOLEndPoint = (id: any) => {
  return baseURL + `${clientContext}/v1/invoice/bol/items/${id}`;
};
export const deleteInvoiceEndPoint = (id: any) => {
  return baseURL + `${clientContext}/v1/invoice/arinvoices/items/${id}`;
};
export const addManualEntryEndPoint = () => {
  return baseURL + `${clientContext}/v1/invoice/manualitems/items`;
};
export const addManualEntryEndPointUpdate = (id: any) => {
  return baseURL + `${clientContext}/v1/invoice/manualitems/items/${id}`;
};
export const getManualItemsEndPoint = () => {
  return baseURL + `${clientContext}/v1/invoice/manualitems/list`;
};
export const getBOLListEndPoint = () => {
  return baseURL + `${clientContext}/v1/invoice/bol/list`;
};
export const getBOLListEndPointForInvoice = (id: any) => {
  return (
    baseURL + `${clientContext}/v1/invoice/bol/list/draft?invoicenumber=${id}`
  );
};
export const getBOLDataByIdEndPoint = (id: any) => {
  return baseURL + `${clientContext}/v1/invoice/bol/items/${id}`;
};
export const getArInvoiceDataByIdEndPoint = (id: any) => {
  return baseURL + `${clientContext}/v1/invoice/arinvoices/items/${id}`;
};

export const getCarrierListEndPoint = () => {
  return baseURL + `/revlog/v1/masterdata/carrier/list?forSelection=true`;
};
export const getPeriodListEndPoint = () => {
  return baseURL + `/revlog/v1/masterdata/fiscalyear/items/fiscalyearperiods`;
};
export const getGlAcoountListEndPoint = () => {
  return baseURL + `/revlog/v1/masterdata/glaccount/list?forSelection=true`;
};
export const getDepartmentListEndPoint = () => {
  return baseURL + `/revlog/v1/masterdata/departments/list?forSelection=true`;
};
export const getPickUPFromListEndPoint = () => {
  return baseURL + `/revlog/v1/masterdata/warehouse/list?forSelection=true`;
};
export const getCustomerListEndPoint = () => {
  return baseURL + `/revlog/v1/masterdata/customer/list?forSelection=true`;
};
export const addFixedPriceEndPoint = () => {
  return baseURL + `${clientContext}/v1/invoices/fixedprices`;
};
export const addSalvageItemEndPoint = () => {
  return baseURL + `${clientContext}/v1/invoices/salvageitems`;
};

export const getArInvoiceMockData = () => {
  return {
    status: {
      id: 1,
      status: "Draft",
    },
  };
};

export const getBOLNumForADD = () => {
  return baseURL + `${clientContext}/v1/invoice/bol/items`;
};
export const getSaveCreditMemoEndpoint = () => {
  return baseURL + `/CS0001/v1/invoices/creditmemo`;
};
export const getUpdateCreditMemoStatusEndpoint = (id: any) => {
  return (
    baseURL + `CS0001/v1/invoices/creditmemo${id ? `/${id}` : ""}/updateStatus`
  );
};
export const getPalletsListEndPoint = () => {
  return baseURL + "CS0001/v1/invoice/pallet/list";
};
export const getArInvoiceUpdateStatus = (id: any) => {
  return (
    baseURL +
    `${clientContext}/v1/invoice/arinvoices/items${
      id ? `/${id}` : ""
    }/updateStatus`
  );
};
export const getBOLActionHistory = (id: any) => {
  return baseURL + `${clientContext}/v1/invoice/bol/items/${id}/transactions`;
};
export const getBOLStatusList = () => {
  return baseURL + `${clientContext}/v1/masterdata/bolstatus/list`;
};
export const getBOLStatusUpdate = (id: any) => {
  return baseURL + `${clientContext}/v1/invoice/bol/items/${id}/updatestatus`;
};
export const getInvoiceActionHistoryEndpoint = () => {
  return baseURL + `${clientContext}/v1/invoice/actionHistory`;
};
export const getAddToBolEndpoint = (invoice: string) => {
  return (
    baseURL + `${clientContext}/v1/invoice/bol/items/${invoice}/addinvoice`
  );
};
export const getScannedPallets = (id: any) => {
  return baseURL + `${clientContext}/v1/invoice/pallet/items?id=${id}`;
};
