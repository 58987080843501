import { FC } from "react";
import "./CommonImageWrapper.scss";

interface ICommonImageWrapper {
  gradientColor: string;
  barColor: string;
  width: number;
  height: number;
  src: string;
  header?: string;
  content?: string;
  onClick?: () => void;
  customclassName: string;
  customImg: string;
}

const CommonImageWrapper: FC<ICommonImageWrapper> = ({
  gradientColor,
  barColor,
  width,
  height,
  src,
  header,
  content,
  onClick,
  customclassName,
  customImg,
}) => {
  return (
    <span
      style={{
        backgroundImage: `linear-gradient(137deg, rgba(255, 255, 255, 0.7), rgba(255, 255, 255, 0.9)), linear-gradient(
          142.56deg,${gradientColor} 25.67%, rgba(140, 77, 63, 0) 90.92%)`,
        marginRight: 20,
        width,
        height,
      }}
      className="image-wrapper"
      onClick={onClick}
    >
      <span
        //style={{ backgroundColor: `linear-gradient(0deg, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.2)), ${barColor}`, width: 8, height: "100%" }}
        style={{ backgroundColor: barColor, width: 8, height: "100%" }}
        className="image-bar"
      ></span>
      <span className="image-content">
        <p className={customclassName}>{header}</p>
        <p className="image-wrapper-body">{content}</p>
        <img
          className={customImg}
          src={src}
          alt="Default"
          width={"70%"}
          height={"60%"}
        />
      </span>
    </span>
  );
};

export default CommonImageWrapper;
