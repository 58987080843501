import { createSlice } from '@reduxjs/toolkit';

export interface ReportState {
    savedReportData: any[];
    paramsInfoData: any[];
    isLoaderShow: boolean;
}

const initialState: ReportState = {
    savedReportData: [],
    paramsInfoData: [],
    isLoaderShow: false,
};

export const ReportSlice = createSlice({
    name: 'Report',
    initialState,
    reducers: {
        setSavedReportData: (state, { payload }) => {
            state.savedReportData = payload;
        },
        setParamsPreviewData: (state, { payload }) => {
            state.paramsInfoData = payload;
        },
        setIsLoaderShow: (state, { payload }) => {
            state.isLoaderShow = payload;
        },
    },
});

// Action creators are generated for each case reducer function
export const { setSavedReportData, setParamsPreviewData, setIsLoaderShow } =
    ReportSlice.actions;

export default ReportSlice.reducer;
