//import { toast } from "react-toastify";
import toast from 'react-hot-toast';

const notify = (msg, type) => {
    //toast("Default Notification !");
    if (type === 'success') {
        toast(msg ? msg : '', {
            duration: 4000,
            position: 'top-center',
            // Styling
            style: {color: 'green', padding: '10px 25px 10px 10px'},
            className: '',
            // Custom Icon
            icon: '',
            // Change colors of success/error/loading icon
            iconTheme: {
                primary: '#000',
                secondary: '#fff',
            },
            // Aria
            ariaProps: {
                role: 'status',
                'aria-live': 'polite',
            },
        });
    } else if (type === 'error') {
        toast(msg ? msg : '', {
            duration: 5000,
            position: 'top-center',
            // Styling
            style: { color: 'red', padding: '10px 25px 10px 10px'},
            className: '',
            // Custom Icon
            icon: '',
            // Change colors of success/error/loading icon
            iconTheme: {
                primary: '#000',
                secondary: '#fff',
            },
            // Aria
            ariaProps: {
                role: 'status',
                'aria-live': 'polite',
            },
        });
    } else if (type === 'warning') {
        toast(msg ? msg : '', {
            duration: 4000,
            position: 'top-center',
            // Styling
            style: {padding: '10px 23px 10px 10px'},
            className: '',
            // Custom Icon
            icon: '',
            // Change colors of success/error/loading icon
            iconTheme: {
                primary: '#000',
                secondary: '#fff',
            },
            // Aria
            ariaProps: {
                role: 'status',
                'aria-live': 'polite',
            },
        });
    }
    else if (type === 'error-right') {
        toast(msg ? msg : '', {
            duration: 5000,
            position: 'top-right',
            // Styling
            style: { color: 'red', padding: '10px 25px 10px 10px'},
            className: '',
            // Custom Icon
            icon: '',
            // Change colors of success/error/loading icon
            iconTheme: {
                primary: '#000',
                secondary: '#fff',
            },
            // Aria
            ariaProps: {
                role: 'status',
                'aria-live': 'polite',
            },
        });
    }

    // toast.warn("Warning Notification !", {
    //   position: toast.POSITION.BOTTOM_LEFT
    // });

    // toast.info("Info Notification !", {
    //   position: toast.POSITION.BOTTOM_CENTER
    // });

    // toast("Custom Style Notification with css class!", {
    //   position: toast.POSITION.BOTTOM_RIGHT,
    //   className: 'foo-bar'
    // });
};
export default notify;

export const ScanModeNotify = async (type) => {
    let trashToastId;
    let binToastId;
    let nocredToastId;
    let auditToastId;
    if (type === 'trashmode') {
        toast('You are scanning in Trash Mode.', {
            duration: Infinity,
            position: 'top-center',
            // Styling
            style: {},
            className: 'trashmode',
            // Custom Icon
            icon: '',
            // Change colors of success/error/loading icon
            iconTheme: {
                primary: '#000',
                secondary: '#fff',
            },
            // Aria
            ariaProps: {
                role: 'status',
                'aria-live': 'polite',
            },
        });
    } else if (type === 'binmode') {
        toast('You are scanning in Bin Mode.', {
            duration: Infinity,
            position: 'top-center',
            // Styling
            style: {},
            className: 'binmode',
            //autoClose: "false",
            // Custom Icon
            icon: '',
            // Change colors of success/error/loading icon
            iconTheme: {
                primary: '#000',
                secondary: '#fff',
            },
            // Aria
            ariaProps: {
                role: 'status',
                'aria-live': 'polite',
            },
        });
    } else if (type === 'nocreditmode') {
        toast(
            'You are scanning in No Credit Mode. These scans will not be reported.',
            {
                duration: Infinity,
                position: 'top-center',
                // Styling
                style: {},
                className: 'nocreditmode',
                //autoClose: false,
                // Custom Icon
                icon: '',
                // Change colors of success/error/loading icon
                iconTheme: {
                    primary: '#000',
                    secondary: '#fff',
                },
                // Aria
                ariaProps: {
                    role: 'status',
                    'aria-live': 'polite',
                },
            }
        );
    } else if (type === 'auditmode') {
        toast(
            'You are scanning in audit Mode. These scans will not be reported.',
            {
                duration: Infinity,
                position: 'top-center',
                // Styling
                style: {},
                className: 'auditmode',
                //autoClose: false,
                // Custom Icon
                icon: '',
                // Change colors of success/error/loading icon
                iconTheme: {
                    primary: '#000',
                    secondary: '#fff',
                },
                // Aria
                ariaProps: {
                    role: 'status',
                    'aria-live': 'polite',
                },
            }
        );
    }
};
