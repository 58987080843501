import React from "react";

const Home = () => {
  return (
    <div>
      <p>Environment:{process.env.NODE_ENV}</p>
      <p>Version:{process.env.REACT_APP_VERSION}</p>
      <p>URL:{process.env.REACT_APP_MASTER_URL}</p>
      <p>{process.env.REACT_APP_CLIENT_CONTEXT}</p>
    </div>
  );
};

export default Home;
