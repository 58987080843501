import "./ContactsDetailsPanel.scss";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import updateIcon from "../../assets/updateForTabs.png";
import cancelIcon from "../../assets/cancelIcon.png";
import { useEffect, useState } from "react";
import axios from "axios";
import { getEndPoint, updateMasterDataEndPoint } from "../contactTabs/utils";
import { setCurrentContact, setUpdatedStoresORVendorData } from "../contactTabs/contactReducer";
import { ContactType } from "../contactTabs/constants";
import notify from "../../services/toasterService";
import "react-edit-text/dist/index.css";
import { setRowData, setStoresORVendorsRowData } from "../grid/gridReducer";

const NotesTabView = () => {
  const [rowInfo, setRowInfo] = useState<any[]>([]);
  const [warehouseInfo, setWarehouseInfo] = useState(false);
  const [showUpdateCancelIcons, setShowUpdateCancelIcons] = useState(false);
  const dispatch = useDispatch();
  const setTabHeading = useSelector(
    (state: RootState) => state.contactReducer?.tabHeading
  );
  const setRowDataInfo = useSelector(
    (state: RootState) => state.gridReducer?.selectedRowData
  );
  const setRowId = useSelector(
    (state: RootState) => state.gridReducer?.selectedRowDataId
  );
  const selStoreAndVendorRowData = useSelector(
    (state: RootState) => state.gridReducer?.selectedStoresOrVendorsRowData
  );

  useEffect(() => {
    if (
      setTabHeading.payload === ContactType.STORES ||
      setTabHeading.payload === ContactType.VENDORS
    ) {
      if (selStoreAndVendorRowData && selStoreAndVendorRowData?.length > 0) {
        setRowInfo(selStoreAndVendorRowData);
      }
    } else {
      if (setRowDataInfo && setRowDataInfo?.length > 0) {
        setRowInfo(setRowDataInfo);
      }
    }
  }, [setRowDataInfo, selStoreAndVendorRowData]);

  const _handleFocusNotes = (text: any) => {
    setShowUpdateCancelIcons(true);
    setRowInfo([{ ...rowInfo[0], notes: text.target.value }]);
  };
  const _handleFocusOutNotes = (text: any) => {
    setShowUpdateCancelIcons(true);
    setRowInfo([{ ...rowInfo[0], notes: text }]);
  };
  const handleSwitch = (e: any) => {
    setShowUpdateCancelIcons(true);
    setRowInfo([{ ...rowInfo[0], active: e.target.checked }]);
  };
  const handleMasterDataChange = async (
    endpoint: string,
    getEndpoint: string
  ) => {
    const rowInfoObject = Object.assign({}, ...rowInfo);
    // const result = await axios.put(endpoint, rowInfoObject);
    // if (result && result.data) {
    //   alert('Done');
    //   setRowInfo([])
    //   const getresult = await axios.get(getEndpoint);
    //   dispatch(setCurrentContact(getresult.data));
    // } else {
    //   alert('Error');
    // }
    // const result = await axios.put(endpoint, rowInfoObject);
    // if (result && result.data) {
    //   notify(`${setTabHeading.payload} updated successfully`, 'success');
    //   //toast.success('Success')
    // } else {
    //   notify(`Failed to update ${setTabHeading.payload}`, 'error');
    //   //toast.error('Error')
    // }
    const result = await axios
      .put(endpoint, rowInfoObject)
      .then((results: any) => {
        if (results && results.data) {
          notify("Success! Changes saved", "success");
        } else {
          notify(`Failed to update ${setTabHeading.payload}`, "error");
        }
      })
      .catch((error: any) => {
        if (error.response) {
          notify(error.response?.data?.message, "error");
        }
      });
    let getResult: any;
    if (setTabHeading.payload === ContactType.STORES) {
      getResult = await axios.get(getEndpoint);
      let storesArray: any = getResult.data;
      //dispatch(setCurrentContact(storesArray?.content));
      dispatch(setUpdatedStoresORVendorData(storesArray?.content))
      // setTimeout(() => {
      //   const dataa = getResult.data[0];
      //   dispatch(setStoresORVendorsRowData(dataa));
      // }, 10);
    } else if (setTabHeading.payload === ContactType.VENDORS) {
      getResult = await axios.get(getEndpoint);
      let vendorsArray: any = getResult.data;
      // dispatch(setCurrentContact(vendorsArray?.content));
      dispatch(setUpdatedStoresORVendorData(vendorsArray?.content))
      // setTimeout(() => {
      //   let dataa = getResult.data[0];
      //   dispatch(setStoresORVendorsRowData(dataa));
      // }, 10);
    } else {
      getResult = await axios.get(getEndpoint);
      dispatch(setCurrentContact(getResult.data));
      setTimeout(() => {
        const dataa = getResult.data[0];
        dispatch(setRowData(dataa));
      }, 10);
    }
  };
  const updateIDs: any[] = [];
  const handleUpdateNotes = () => {
    if (setTabHeading.payload === ContactType.WAREHOUSES) {
      const whArray = JSON.parse(JSON.stringify(rowInfo[0].reclaimCenters));
      whArray.filter((item: any) => {
        return updateIDs.push(item.id);
      });
      setRowInfo([{ ...rowInfo[0], reclaimCenterIds: updateIDs }]);
      setWarehouseInfo(true);
    } else if (setTabHeading.payload === ContactType.DEPARTMENTS) {
      const whArray = JSON.parse(JSON.stringify(rowInfo[0].glAccounts));
      whArray.filter((item: any) => {
        return updateIDs.push(item.id);
      });
      setRowInfo([{ ...rowInfo[0], glAccountIds: updateIDs }]);
      setWarehouseInfo(true);
    } else if (setTabHeading.payload === ContactType.GL_ACCOUNTS) {
      const whArray = JSON.parse(JSON.stringify(rowInfo[0].departments));
      whArray.filter((item: any) => {
        return updateIDs.push(item.id);
      });
      setRowInfo([{ ...rowInfo[0], departmentIds: updateIDs }]);
      setWarehouseInfo(true);
    } else if (setTabHeading.payload === ContactType.STORES) {
      const endpoint = updateMasterDataEndPoint(setTabHeading.payload, rowInfo);
      const getEndpoint = getEndPoint(setTabHeading.payload, 1, 1);
      handleMasterDataChange(endpoint, getEndpoint);
    } else if (setTabHeading.payload === ContactType.VENDORS) {
      const endpoint = updateMasterDataEndPoint(setTabHeading.payload, rowInfo);
      const getEndpoint = getEndPoint(setTabHeading.payload, 1, 1);
      handleMasterDataChange(endpoint, getEndpoint);
    } else {
      const endpoint = updateMasterDataEndPoint(setTabHeading.payload, rowInfo);
      const getEndpoint = getEndPoint(setTabHeading.payload);
      handleMasterDataChange(endpoint, getEndpoint);
    }
  };
  useEffect(() => {
    if (warehouseInfo) {
      const endpoint = updateMasterDataEndPoint(setTabHeading.payload, rowInfo);
      const getEndpoint = getEndPoint(setTabHeading.payload);
      handleMasterDataChange(endpoint, getEndpoint);
    }
  }, [warehouseInfo]);
  const handleCancel = () => {
    if (setTabHeading.payload === ContactType.STORES) {
      const getEndpoint = getEndPoint(setTabHeading.payload, 1, 1);
      handleCancelAction(getEndpoint);
    } else if (setTabHeading.payload === ContactType.VENDORS) {
      const getEndpoint = getEndPoint(setTabHeading.payload, 1, 1);
      handleCancelAction(getEndpoint);
    } else {
      const getEndpoint = getEndPoint(setTabHeading.payload);
      handleCancelAction(getEndpoint);
    }
  };
  const handleCancelAction = async (getEndpoint: string) => {
    if (setTabHeading.payload === ContactType.STORES) {
      const getresult = await axios.get(getEndpoint);
      let storesArray: any = getresult.data;
     // dispatch(setCurrentContact(storesArray?.content));
      dispatch(setUpdatedStoresORVendorData(storesArray?.content))
      
    } else if (setTabHeading.payload === ContactType.VENDORS) {
      const getresult = await axios.get(getEndpoint);
      let vendorsArray: any = getresult.data;
     // dispatch(setCurrentContact(vendorsArray?.content));
      dispatch(setUpdatedStoresORVendorData(vendorsArray?.content))
    } else {
      const getresult = await axios.get(getEndpoint);
      dispatch(setCurrentContact(getresult.data));
    }
    notify(`Information! Changes discarded`, "warning");
  };
  return (
    <div>
      {rowInfo && rowInfo?.length > 0 ? (
        <>
          {rowInfo?.map((info) => (
            <div>
              <div className="col-sm-12 paddingGeneralTab px-4 py-3">
                <div className="row">
                  {/* <EditTextarea
                  name='textarea1'
                  rows={8}
                  className="notes-preview"
                  value={info.notes}
                  onChange={_handleFocusNotes}
                /> */}
                  <div className="col-sm-3 col-md-3 col-lg-2 preview-Key">
                    <span className="storeNumHeading">Notes:</span>
                  </div>
                  <div className="col-sm-9 col-md-9 col-lg-10 preview-value ">
                    <textarea
                      className="notes-preview"
                      name=""
                      id=""
                      rows={8}
                      onChange={_handleFocusNotes}
                    >
                      {info.notes}
                    </textarea>
                  </div>
                </div>
              </div>
              {/*               <div className="row ml-0 p-15 height-350">
                <span className="col-sm-12 inlineInput-textarea">
                  <InlineInputEdit inputClassName="txtEditNotesClassName"
                    text={info.notes}
                    labelClassName="txtEditNotesClassName"
                    onFocus={_handleFocusNotes}
                    onFocusOut={_handleFocusOutNotes}
                    inputWidth={250}
                    inputHeight={250}
                  />
                </span> */}

              {/* <div className="row float-right mr-0">

                        <SwitchComponent checked={info.active} onChange={() => handleSwitch(info.active)}/><span className="switchText">Active</span>

                        </div> */}
            </div>

            /* </div> */
          ))}
        </>
      ) : (
        <div></div>
      )}

      <div className="col-sm-12 pt-1">
        <div className="col-sm-6 pl-1">
          {showUpdateCancelIcons ? (
            <>
              <img
                className="updateIconImg "
                onClick={handleUpdateNotes}
                src={updateIcon}
              />
              <img
                className="updateIconImg "
                onClick={handleCancel}
                src={cancelIcon}
              />
            </>
          ) : (
            ""
          )}
        </div>

        {rowInfo.map((info) => (
          <div className="col-sm-6 pl-0">
            {/* <SwitchComponent checked={info.active} onChange={(e: any) => handleSwitch(e)}/><span className="switchText switchTextTabs">{info.active ? 'Active' : 'Inactive'}</span> */}
            <input
              type="checkbox"
              defaultChecked={info.active}
              id="NotesTabActive"
              className="toggle"
              onChange={(e: any) => handleSwitch(e)}
            />
            <label className="chkbx" htmlFor="NotesTabActive" style={{marginLeft: '45px'}}>
            <span className="ActiveInactiveFont">
              {info.active ? "Active" : "Inactive"}
              </span>
            </label>
          </div>
        ))}
      </div>

      {/* <img className="addIconImg" src={updateIcon}/> */}
    </div>
  );
};
export default NotesTabView;
