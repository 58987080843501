import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import delIcon from '../../assets/deleteIcon.png';
import addIcon from '../../assets/addBig.png';
import printerStatusIcon from '../../assets/printer.png';
import scanIcon from '../../assets/scan.png';
import { RootState } from '../../redux/store';
import LLModal from '../common/llModal/LLModal';
import { ButtonComponent } from '@syncfusion/ej2-react-buttons';
import '../grid/Grid.scss';
import { pageOptions } from '../grid/gridConfig';
import {
    ColumnDirective,
    ColumnsDirective,
    FilterSettingsModel,
    GridComponent,
    SelectionSettingsModel,
} from '@syncfusion/ej2-react-grids';
import {
    getEndPoint,
    getItemsDataForBins,
    updateMasterDataEndPoint,
} from './utils';
import axios from 'axios';
import {
    setCurrentContact,
    setInventoryNameMasterData,
} from './contactReducer';
import { PrintManager } from '../printer/printermanager';
import { OutBoundBoxUtil } from '../scan/OutBoundBoxUtil';
import moment from 'moment';
import AddButtonSvg from '../../assets/AddButtonSVG.svg';
import PrintButtonSvg from '../../assets/PrintButtonSVG.svg';
import DeleteButtonSvg from '../../assets/DeleteButtonSVG.svg';
import saveIcon from '../../assets/saveIcon.png';
import { ContactType } from './constants';
import notify from '../../services/toasterService';

const FilterOptions: FilterSettingsModel = {
    columns: [],
    type: 'FilterBar',
    mode: 'Immediate',
    showFilterBarStatus: true,
    immediateModeDelay: 10,
    operators: {},
};
const InventoryCatalogViewScreen = (props: any) => {
    const [rowInfo, setRowInfo] = useState<any[]>([]);
    const [boxInfo, setBoxInfo] = useState<any[]>([]);
    const [boxNBinInfo, setBoxNBinInfo] = useState<any[]>([]);
    const [itemInfo, setItemInfo] = useState<any[]>([]);
    const [showAddBinorBoxModal, setShowAddBinorBoxModal] = useState(false);
    const [test, setTest] = useState(false);
    const [showAddItemModal, setShowAddItemModal] = useState(false);
    const [selectedInventoryId, setSelectedInventoryId] = useState();
    const { backPressInventoryCatalog } = props;
    const [price, setPrice] = useState('');
    const [pricingTypeSymbal, setPricingTypeSymbal] = useState('');

    const setRowData = useSelector(
        (state: RootState) => state.gridReducer?.selectedRowData
    );
    const setRowId = useSelector(
        (state: RootState) => state.gridReducer?.selectedRowDataId
    );
    const reclaimCentersData = useSelector(
        (state: RootState) => state.contactReducer?.reclaimCenters
    );
    const pricingTypeData = useSelector(
        (state: RootState) => state.contactReducer?.pricingTypeMasterData
    );
    const whLocationsInfo = useSelector(
        (state: RootState) => state.contactReducer?.WhareHouseLocationData
    );

    const dispatch = useDispatch();
    const printPalletLabel = () => {
        PrintManager.printLabel(
            OutBoundBoxUtil.getPalletPrintLabelRequest(rowInfo, '', ''),
            'pallet',
            '',
            ''
        );
    };
    const printBoxLabel = () => {
        const payload = itemInfo[0];
        const outboundBox = payload[0]?.outboundBox;
        PrintManager.printLabel(
            outboundBox,
            'box',
            outboundBox.closedBy,
            outboundBox.boxNumberOnPallet
        );
    };
    useEffect(() => {
        if (setRowData && setRowData?.length > 0) {
            dispatch(
                setInventoryNameMasterData(
                    setRowData[0]?.palletId +
                        ' ' +
                        setRowData[0]?.warehouseLocation
                )
            );
            setRowInfo(
                setRowData?.filter((item) =>
                    item?.id && setRowId ? item?.id === setRowId?.id : ''
                )
            );
            let boxList: any[] = [];
            boxList =
                setRowData && setRowData[0]?.boxList
                    ? setRowData[0]?.boxList
                    : [];

            if (setRowData && setRowData[0]?.binList.length > 0) {
                const rowIfoCopy = JSON.parse(JSON.stringify(setRowData));
                const bilListCopy = JSON.parse(
                    JSON.stringify(rowIfoCopy[0].binList)
                );
                boxList?.length > 0
                    ? boxList.concat(bilListCopy)
                    : (boxList = bilListCopy);
            }
            boxList = boxList?.map((ele: any) => ({
                ...ele,
                boxId: ele['outboundBoxId']
                    ? ele['outboundBoxId']
                    : ele['binId'],
            }));
            setBoxInfo(boxList);
            setSelectedInventoryId(setRowData && setRowData[0]?.id);

            setPrice(setRowData && setRowData[0]?.price);
            if (
                setRowData[0].pricingType === 'Fixed price' ||
                setRowData[0].pricingType === 'User Set'
            ) {
                setPricingTypeSymbal('$');
            } else {
                setPricingTypeSymbal('%');
            }
        }
    }, [setRowData, setRowId]);

    const handleChangePrice = (e: any) => {
        const rowIfoCopy = JSON.parse(JSON.stringify(rowInfo));
        if (e.target.value.includes('.') === true) {
            if (e.target.value.split('.')[1].length > 2) {
                rowIfoCopy[0]['rate'] = parseFloat(e.target.value).toFixed(2);
                setPrice(parseFloat(e.target.value).toFixed(2));
            } else {
                rowIfoCopy[0]['rate'] = e.target.value;
                setPrice(e.target.value);
            }
        } else {
            rowIfoCopy[0]['rate'] = e.target.value;
            setPrice(e.target.value);
        }
        setRowInfo(rowIfoCopy);
    };

    const handlePricingType = (e: any) => {
        if (
            e.target.value === 'Fixed price' ||
            e.target.value === 'User Set' ||
            e.target.value === '0'
        ) {
            setPricingTypeSymbal('$');
            setRowInfo([{ ...rowInfo[0], pricingType: e.target.value }]);
        } else {
            setPricingTypeSymbal('%');
            setRowInfo([{ ...rowInfo[0], pricingType: e.target.value }]);
        }
    };

    const selectionOptions: SelectionSettingsModel = {
        checkboxMode: 'ResetOnRowClick',
        mode: 'Row',
        type: 'Single',
    };

    let itemData: any;
    const handleGetSelectedRowData = async (endpoint: string) => {
        const result = await axios.get(endpoint);
        if (result && result.data) {
            setItemInfo([result.data]);
        } else {
            alert('Error');
        }
    };

    const handleChangeDescription = (e: any) => {};

    const handleSelectedRowBin = (event: any) => {
        let tabName: any = 'Items';
        const endpoint = getItemsDataForBins(
            tabName,
            selectedInventoryId,
            event.data.id
        );
        handleGetSelectedRowData(endpoint);
    };

    const formatDateToMonDDYY = (date: any) => {
        return moment(date).format('YYYY-MM-DD');
    };

    const handleSaveInventoryCatalog = async (e: any) => {
        const endpoint = updateMasterDataEndPoint(
            ContactType.INVENTORY_CATALOG,
            rowInfo
        );
        const requestBody = Object.assign({}, ...rowInfo);
        console.log(requestBody);
        delete requestBody?.boxList;
        delete requestBody?.client;
        delete requestBody?.createdBy;
        delete requestBody?.createdDate;
        delete requestBody?.lastModifiedBy;
        const result = await axios
            .put(endpoint, requestBody)
            .then((data: any) => {
                if (data && data.data) {
                    notify('Updated successfully', 'success');
                    backPressInventoryCatalog();
                    getInventory();
                } else {
                    notify('Error', 'error');
                }
            });
    };
    const getInventory = async () => {
        const getEndpoint = getEndPoint(ContactType.INVENTORY_CATALOG);
        const getresult = await axios.get(getEndpoint);
        dispatch(setCurrentContact(getresult.data));
    };
    return (
        <>
            {rowInfo &&
                rowInfo?.map((info) => (
                    <>
                        <hr style={{ marginBottom: '7px', marginTop: '0' }} />
                        <div className="row row ml-0 mr-0">
                            <div className="col-sm-4 p-0">
                                <span
                                    className="backToAllText"
                                    onClick={backPressInventoryCatalog}
                                >
                                    {'<'} Back to all inventory
                                </span>
                            </div>
                            <div className="col-sm-5"></div>
                            <div className="col-sm-3 textAlign-right pr-0">
                                <div className="ml-5 inventory-catalog-btn">
                                    <img
                                        alt=""
                                        className="cursor-pointer"
                                        src={saveIcon}
                                        style={{ float: 'right' }}
                                        onClick={(e) =>
                                            handleSaveInventoryCatalog(info.id)
                                        }
                                    />
                                </div>
                                {info.invoiceRaised ? (
                                    ''
                                ) : (
                                    <span className="notInvoicedFlagInventory">
                                        Not Invoiced
                                    </span>
                                )}
                            </div>
                        </div>
                        <br />
                        <div className="row ml-0 mr-0">
                            <div className="col-sm-3 p-0">
                                <div className="col-sm-12 p-0">
                                    <span
                                        className="lblClass"
                                        style={{ float: 'left' }}
                                    >
                                        Bar Code
                                    </span>
                                    <div className="col-sm-12">
                                        <span className="lblBarCodeInvent">
                                            {info.palletId}
                                        </span>
                                    </div>
                                </div>

                                <div className="col-sm-12 pl-0">
                                    <span
                                        className="lblClass"
                                        style={{ float: 'left' }}
                                    >
                                        Pallet Name
                                    </span>
                                    <input
                                        type="text"
                                        className="form-control"
                                        defaultValue={info?.warehouseLocation}
                                        readOnly={
                                            info.invoiceRaised && info.isclosed
                                        }
                                        onChange={(e) =>
                                            handleChangeDescription(e)
                                        }
                                    />
                                </div>
                            </div>
                            <div className="col-sm-3 p-0">
                                <div className="col-sm-12">
                                    <span
                                        className="lblClass"
                                        style={{ float: 'left' }}
                                    >
                                        Reclaim Center
                                    </span>
                                    <select
                                        className="form-control"
                                        value={info?.reclaimCenter}
                                        disabled={
                                            info.invoiceRaised && info.isclosed
                                        }
                                        id="ddlDisposition"
                                    >
                                        <option value={info?.reclaimCenter}>
                                            {info?.reclaimCenter}
                                        </option>
                                        {reclaimCentersData?.map((st) => (
                                            <option value={st.id}>
                                                {st.reclaimcenterName}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                                <div className="col-sm-12">
                                    <span
                                        className="lblClass"
                                        style={{ float: 'left' }}
                                    >
                                        WHSE Location
                                    </span>
                                    <select
                                        className="form-control"
                                        value={info?.warehouseLocation}
                                        disabled={
                                            info.invoiceRaised && info.isclosed
                                        }
                                        id="ddlDisposition"
                                    >
                                        <option value={info?.warehouseLocation}>
                                            {info?.warehouseLocation}
                                        </option>
                                        {whLocationsInfo?.map((rc) => (
                                            <option value={rc.id}>
                                                {rc.warehouseLocation}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                            </div>

                            <div className="col-sm-3 p-0">
                                <div className="col-sm-12">
                                    <span
                                        className="lblClass"
                                        style={{ float: 'left' }}
                                    >
                                        Pricing Type
                                    </span>
                                    <select
                                        className="form-control"
                                        value={info?.pricingType}
                                        id="ddlPricingType"
                                        disabled={
                                            info.invoiceRaised && info.isclosed
                                        }
                                        onChange={handlePricingType}
                                    >
                                        {/* <option value={info.pricingType}>
                                                    {info.pricingType}
                                        </option> */}
                                        {pricingTypeData?.map((pt) => (
                                            <option value={pt.pricingType}>
                                                {pt.pricingType}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                                <div className="col-sm-12">
                                    <span
                                        className="lblClass"
                                        style={{ float: 'left' }}
                                    >
                                        Rate ({pricingTypeSymbal})
                                    </span>
                                    <input
                                        type="number"
                                        className="form-control"
                                        value={info?.rate}
                                        onChange={(e) => handleChangePrice(e)}
                                        onBlur={(e) => handleChangePrice(e)}
                                        onKeyUp={(e) => handleChangePrice(e)}
                                        readOnly={
                                            info.invoiceRaised && info.isclosed
                                        }
                                    />
                                </div>
                            </div>
                            <div className="col-sm-3 p-0">
                                <div className="col-sm-12 pr-0">
                                    <span
                                        className="lblClass"
                                        style={{ float: 'left' }}
                                    >
                                        Open Date
                                    </span>
                                    <input
                                        type="text"
                                        className="form-control"
                                        readOnly={
                                            info.invoiceRaised && info.isclosed
                                        }
                                        defaultValue={moment(
                                            info.createdDate
                                        ).format('MMM DD, YYYY')}
                                    />
                                </div>
                                {info.isClosed ? (
                                    <div>
                                        <div className="col-sm-12 pr-0">
                                            <span
                                                className="lblClass"
                                                style={{ float: 'left' }}
                                            >
                                                Close Date
                                            </span>
                                            <input
                                                type="text"
                                                className="form-control"
                                                readOnly={
                                                    info.invoiceRaised &&
                                                    info.isclosed
                                                }
                                                defaultValue={moment(
                                                    info.closedOn
                                                ).format('MMM DD, YYYY')}
                                            />
                                        </div>
                                    </div>
                                ) : (
                                    ''
                                )}
                            </div>
                        </div>
                        <br />
                        {info.isClosed ? (
                            <div>
                                <div className="row ml-0 mr-0">
                                    <div className="col-sm-3"></div>
                                    <div className="col-sm-6">
                                        <div className="inventoryCatalog-table">
                                            <div className="inventoryCatalog-table-header">
                                                <span>BINS</span>
                                                <span>BOXES</span>
                                                <span>ITEMS</span>
                                                <span>COST</span>
                                                <span>PRICE</span>
                                            </div>
                                            <div className="inventoryCatalog-table-content">
                                                <span>{info.bins}</span>
                                                <span>{info.boxes}</span>
                                                <span>{info.items}</span>
                                                {info.cost !== undefined &&
                                                info.cost !== null &&
                                                info.cost !== '' ? (
                                                    <span>${info.cost}</span>
                                                ) : (
                                                    <></>
                                                )}
                                                {info.price !== undefined &&
                                                info.price !== null &&
                                                info.price !== '' ? (
                                                    <span>${info.price}</span>
                                                ) : (
                                                    <></>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-3"></div>
                                </div>

                                <br />
                                <div className="row ml-0 mr-0">
                                    <div className="col-sm-6 p-0">
                                        <div className="col-sm-12 pl-0">
                                            <div className="col-sm-9 inventoryBinsandBoxesHeading">
                                                <span>Bins & Boxes</span>
                                            </div>

                                            {!info.invoiceRaised &&
                                            !info.isclosed ? (
                                                <>
                                                    <div className="col-sm-1">
                                                        <img
                                                            src={
                                                                DeleteButtonSvg
                                                            }
                                                        />
                                                    </div>
                                                    <div className="col-sm-1">
                                                        <img
                                                            src={PrintButtonSvg}
                                                            className="cursor-pointer"
                                                            onClick={
                                                                printBoxLabel
                                                            }
                                                        />
                                                    </div>
                                                    <div className="col-sm-1">
                                                        <img
                                                            src={AddButtonSvg}
                                                            className="cursor-pointer"
                                                            onClick={() =>
                                                                setShowAddBinorBoxModal(
                                                                    true
                                                                )
                                                            }
                                                        />
                                                    </div>
                                                </>
                                            ) : (
                                                ''
                                            )}
                                        </div>
                                    </div>
                                    <div className="col-sm-6 p-0">
                                        <div className="col-sm-12 pr-0">
                                            <div className="col-sm-10 inventoryBinsandBoxesHeading">
                                                <span>Items</span>
                                            </div>
                                            {!info.invoiceRaised &&
                                            !info.isclosed ? (
                                                <>
                                                    <div className="col-sm-1">
                                                        <img
                                                            src={
                                                                DeleteButtonSvg
                                                            }
                                                        />
                                                    </div>
                                                    <div className="col-sm-1">
                                                        <img
                                                            src={AddButtonSvg}
                                                            className="cursor-pointer"
                                                            onClick={() =>
                                                                setShowAddItemModal(
                                                                    true
                                                                )
                                                            }
                                                        />
                                                    </div>
                                                </>
                                            ) : (
                                                ''
                                            )}
                                        </div>
                                    </div>
                                </div>
                                <div className="row mt-1rem">
                                    <div className="col-sm-6">
                                        <>
                                            {/* <Grid
          config={config['Box and Bins']}
          data={boxInfo}
          />  */}
                                            <GridComponent
                                                dataSource={boxInfo}
                                                height={434}
                                                allowPaging={
                                                    pageOptions.pageSize &&
                                                    boxInfo?.length >
                                                        pageOptions?.pageSize
                                                        ? true
                                                        : false
                                                }
                                                filterSettings={FilterOptions}
                                                allowFiltering={true}
                                                selectionSettings={
                                                    selectionOptions
                                                }
                                                pageSettings={pageOptions}
                                                rowSelected={(event) =>
                                                    handleSelectedRowBin(event)
                                                }
                                            >
                                                <ColumnsDirective>
                                                    <ColumnDirective
                                                        type="checkbox"
                                                        width="50"
                                                    />
                                                    {/* <ColumnDirective ><img src={closeIcon}/></ColumnDirective> */}
                                                    {/* <ColumnDirective field={closeIcon} headerText='' width='100'/> */}
                                                    <ColumnDirective
                                                        field="boxId"
                                                        headerText="BAR CODE"
                                                        width="100"
                                                    />
                                                    <ColumnDirective
                                                        field="description"
                                                        headerText="DESC"
                                                        width="100"
                                                    />
                                                    <ColumnDirective
                                                        field="type"
                                                        headerText="TYPE"
                                                        width="100"
                                                    />
                                                    <ColumnDirective
                                                        field="currentQuantity"
                                                        headerText="QUANTITY"
                                                        width="100"
                                                    />
                                                </ColumnsDirective>
                                            </GridComponent>
                                        </>
                                    </div>
                                    <div className="col-sm-6">
                                        <>
                                            <GridComponent
                                                dataSource={itemInfo[0]}
                                                height={434}
                                                allowPaging={
                                                    pageOptions.pageSize &&
                                                    itemInfo?.length >
                                                        pageOptions?.pageSize
                                                        ? true
                                                        : false
                                                }
                                                filterSettings={FilterOptions}
                                                allowFiltering={true}
                                                selectionSettings={
                                                    selectionOptions
                                                }
                                                pageSettings={pageOptions}
                                            >
                                                <ColumnsDirective>
                                                    <ColumnDirective
                                                        type="checkbox"
                                                        width="100"
                                                    />
                                                    {/* <ColumnDirective ><img src={closeIcon}/></ColumnDirective> */}
                                                    {/* <ColumnDirective field={closeIcon} headerText='' width='100'/> */}
                                                    <ColumnDirective
                                                        field="upc"
                                                        headerText="UPC"
                                                        width="100"
                                                    />
                                                    <ColumnDirective
                                                        field="description"
                                                        headerText="DESC"
                                                        width="100"
                                                    />
                                                    <ColumnDirective
                                                        field="quantity"
                                                        headerText="QTY"
                                                        width="100"
                                                    />
                                                </ColumnsDirective>
                                            </GridComponent>
                                        </>
                                    </div>
                                </div>
                            </div>
                        ) : (
                            ''
                        )}
                        <LLModal
                            handleClose={() => setShowAddBinorBoxModal(false)}
                            show={showAddBinorBoxModal}
                            padding={20}
                            width={740}
                        >
                            <div className="row ml-0 mr-0 mb-30 tabNameRow">
                                <span className="tabNameForAddScreen">
                                    Add Bin or Box
                                </span>
                            </div>
                            <div className="row pl-3 pr-5">
                                <div
                                    className="col-sm-6"
                                    style={{ textAlign: 'center' }}
                                >
                                    <span>Scan Outbound Bin/Box Label</span>
                                    <br />
                                    <img src={scanIcon} />
                                </div>
                                <div
                                    className="col-sm-6"
                                    style={{ textAlign: 'center' }}
                                >
                                    <span>Enter Outbound Bin/Box Details</span>
                                    <br />
                                    {/* <div className="row mt-1rem">
                                        <div className="col-sm-4">
                                            <span className="lblClass">Store #</span>
                                        </div>
                                        <div className="col-sm-8">
                                            <select className="form-control"></select>
                                        </div>
                                    </div> */}
                                    <div className="row mt-1rem">
                                        <div className="col-sm-4">
                                            <span className="lblClass">
                                                Bin or Box #
                                            </span>
                                        </div>
                                        <div className="col-sm-8">
                                            <input
                                                type="text"
                                                className="form-control"
                                            />
                                        </div>
                                    </div>
                                    {/* <div className="row mt-1rem">
                                        <div className="col-sm-4 pl-0">
                                            <span className="lblClass">Week Ending</span>
                                        </div>
                                        <div className="col-sm-8">
                                            <input type="text" className="form-control"/>
                                        </div>
                                    </div> */}
                                </div>
                            </div>
                            <div
                                className="row"
                                style={{ textAlign: 'center' }}
                            >
                                <div className="col-sm-3"></div>
                                <div className="col-sm-3">
                                    <ButtonComponent
                                        cssClass="p-15 btnCancelInPopups"
                                        onClick={() =>
                                            setShowAddBinorBoxModal(false)
                                        }
                                        style={{ width: '140px' }}
                                    >
                                        CANCEL
                                    </ButtonComponent>
                                </div>
                                <div className="col-sm-3">
                                    <ButtonComponent
                                        cssClass="p-15 btnAddInPopups"
                                        style={{ width: '140px' }}
                                    >
                                        ADD
                                    </ButtonComponent>
                                </div>
                                <div className="col-sm-3"></div>
                            </div>
                        </LLModal>
                        <LLModal
                            handleClose={() => setShowAddItemModal(false)}
                            show={showAddItemModal}
                            padding={20}
                            width={740}
                        >
                            <div className="row ml-0 mr-0 mb-30 tabNameRow">
                                <span className="tabNameForAddScreen">
                                    Add Item
                                </span>
                            </div>
                            <div className="row pl-3 pr-5">
                                <div
                                    className="col-sm-6"
                                    style={{ textAlign: 'center' }}
                                >
                                    <span>Scan Item UPC</span>
                                    <br />
                                    <img src={scanIcon} />
                                </div>
                                <div
                                    className="col-sm-6"
                                    style={{ textAlign: 'center' }}
                                >
                                    <span>Enter Item Quantity and UPC</span>
                                    <br />
                                    <div className="row mt-3rem">
                                        <div className="col-sm-4">
                                            <span className="lblClass">
                                                Quantity
                                            </span>
                                        </div>
                                        <div className="col-sm-8">
                                            <input
                                                type="number"
                                                className="form-control"
                                            />
                                        </div>
                                    </div>
                                    <div className="row mt-1rem">
                                        <div className="col-sm-4">
                                            <span className="lblClass">
                                                UPC
                                            </span>
                                        </div>
                                        <div className="col-sm-8">
                                            <input
                                                type="number"
                                                className="form-control"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div
                                className="row"
                                style={{ textAlign: 'center' }}
                            >
                                <div className="col-sm-3"></div>
                                <div className="col-sm-3">
                                    <ButtonComponent
                                        cssClass="p-15 btnCancelInPopups"
                                        onClick={() =>
                                            setShowAddItemModal(false)
                                        }
                                        style={{ width: '140px' }}
                                    >
                                        CANCEL
                                    </ButtonComponent>
                                </div>
                                <div className="col-sm-3">
                                    <ButtonComponent
                                        cssClass="p-15 btnAddInPopups"
                                        style={{ width: '140px' }}
                                    >
                                        ADD
                                    </ButtonComponent>
                                </div>
                                <div className="col-sm-3"></div>
                            </div>
                        </LLModal>
                    </>
                ))}
        </>
    );
};
export default InventoryCatalogViewScreen;
