import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import AsyncSelect from 'react-select/async';
import {
    TabComponent,
    TabItemDirective,
    TabItemsDirective,
} from '@syncfusion/ej2-react-navigations';
import { DateRangePickerComponent } from '@syncfusion/ej2-react-calendars';
import saveIcon from '../../assets/saveIcon.png';
import axios from 'axios';
import {
    setCurrentContact,
    setDispositionData,
    setRECodeForRuleExceptionView,
    setUpcList,
    setWHLocationsData,
} from './contactReducer';
import {
    getAllMasterData,
    getEndPoint,
    updateMasterDataEndPoint,
} from './utils';
import { ContactType } from './constants';
import notify from '../../services/toasterService';
import closeIcon from '../../icons/common/cancel.png';
import moment from 'moment';
import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns';
import { environment } from '../../environment';
import {
    casualDataAddPopupEndPoints,
    RuleExceptionEndPointsForFiltering,
} from '../settings/utils';
const RuleExceptionViewScreen = (props: any) => {
    const [rowInfo, setRowInfo] = useState<any[]>([]);
    const [existingRCList, setExistingRCList] = useState<any[]>([]);
    const [existingVendorList, setExistingVendorList] = useState<any[]>([]);
    let [existingUPCList, setExistingUPCList] = useState<any[]>([]);
    let [existingUPCInfo, setExistingUPCInfo] = useState<any[]>([]);
    const [existingClientGroupList, setExistingClientGroupList] = useState<
        any[]
    >([]);
    const [existingStoresList, setExistingStoresList] = useState<any[]>([]);
    const [startDateNew, setStartDateNew] = useState();
    const [endDateNew, setEndDateNew] = useState();
    const [trueFlagForUpdate, setTrueFlagForUpdate] = useState(false);
    const byupcInputFieldRef = React.createRef<HTMLInputElement>();
    const upcInputFieldRef = React.createRef<HTMLInputElement>();
    const [billCostByAllUPC, setBillCostByAllUPC] = useState<any>();
    const [vendorList, setVendorList] = useState<any[]>([]);
    const [upcListBasedonVendors, setUpcListBasedonVendors] = useState<any[]>(
        []
    );

    const [vendorIds, setVendorIds] = useState('');
    const [upcValue, setUpcValue] = useState('');
    const [clientGroupIds, setClientGroupIds] = useState('');
    const [storeName, setStoreName] = useState('');

    //let vendorList: any;
    let itemCatalogList: any;
    let storesList: any;
    let rowIfoCopyGlobal: any = [];
    let rowIfoCopyGlobalByUPC: any = [];
    let existingUPCInfoForUPC: any = [];
    let selectedStartDate: any;
    let selectedEndDate: any;
    let pageNo = environment.react_app_page;
    let pageSize = environment.react_app_pagesize;
    let dropDownListObject: any;
    let vendorIdsSelected = '';
    let clientGroupIdSelected = '';
    const dispatch = useDispatch();

    const setRowId = useSelector(
        (state: RootState) => state.gridReducer?.selectedRowDataId
    );
    const RCData = useSelector(
        (state: RootState) => state.contactReducer?.reclaimCenters
    );
    const VendorData = useSelector(
        (state: RootState) => state.contactReducer?.vendors
    );
    const CustomerData = useSelector(
        (state: RootState) => state.contactReducer?.customers
    );
    const ClientGroupData = useSelector(
        (state: RootState) => state.contactReducer?.clientGroupData
    );
    const DispositioData = useSelector(
        (state: RootState) => state.contactReducer?.dispositionData
    );
    const whseLocationData = useSelector(
        (state: RootState) => state.contactReducer?.WhareHouseLocationData
    );
    const ItemCatalogData = useSelector(
        (state: RootState) => state.contactReducer?.itemCatalogData
    );
    const upCList = useSelector(
        (state: RootState) => state.contactReducer?.upcData
    );
    const DebitReasonData = useSelector(
        (state: RootState) => state.contactReducer?.debitReason
    );
    const StoredData = useSelector(
        (state: RootState) => state.contactReducer?.stores
    );

    const { backPressRuleException } = props;

    const setRowData = useSelector(
        (state: RootState) => state.gridReducer?.selectedRowData
    );
    const headerText = [{ text: 'Rule Criteria' }, { text: 'Value Changes' }];

    useEffect(() => {
        const upcIdsCopy: any[] = [];
        if (setRowData && setRowData?.length > 0) {
            let setRowDataInfoCopy = JSON.parse(JSON.stringify(setRowData));
            getWhseLocations();
            getDispositions();
            if (
                setRowDataInfoCopy[0]?.startDate !== undefined &&
                setRowDataInfoCopy[0]?.startDate !== null
            ) {
                setRowDataInfoCopy[0].startDate = moment(
                    setRowDataInfoCopy[0]?.startDate
                ).format('MM/DD/YYYY');
                selectedStartDate = moment(
                    setRowDataInfoCopy[0]?.startDate
                ).format('MM/DD/YYYY');
            } else {
                setRowDataInfoCopy[0].startDate = '';
                selectedStartDate = '';
            }
            if (
                setRowDataInfoCopy[0]?.endDate !== undefined &&
                setRowDataInfoCopy[0]?.endDate !== null
            ) {
                setRowDataInfoCopy[0].endDate = moment(
                    setRowDataInfoCopy[0]?.endDate
                ).format('MM/DD/YYYY');
                selectedEndDate = moment(setRowDataInfoCopy[0]?.endDate).format(
                    'MM/DD/YYYY'
                );
            } else {
                setRowDataInfoCopy[0].endDate = '';
                selectedEndDate = '';
            }
            if (setRowDataInfoCopy[0]?.upc?.length > 0) {
                setRowDataInfoCopy[0]?.upc?.map((item: any) => {
                    if (
                        item?.upc !== undefined &&
                        item?.upc !== null &&
                        item?.upc !== null
                    ) {
                        upcIdsCopy.push({
                            id: item?.upc?.upcReference,
                            billCost: item?.billCost,
                        });
                    }
                });
                setRowDataInfoCopy[0]['upcIds'] = upcIdsCopy;
                setExistingUPCInfo([...upcIdsCopy]);
            }
            setBillCostByAllUPC(setRowData && setRowData[0]?.billCost);

            //  setSelectedRCInfo(setRowDataInfoCopy);
            setRowInfo(setRowDataInfoCopy);
            setExistingRCList(setRowData[0].reclaimCenter);
            setExistingVendorList(setRowData[0].vendor);
            setExistingUPCList(setRowData[0].upc);
            setExistingClientGroupList(setRowData[0].clientGroups);
            setExistingStoresList(setRowData[0].store);

            dispatch(
                setRECodeForRuleExceptionView(
                    setRowData?.map((info) =>
                        info?.ruleCode !== undefined && info?.ruleCode !== null
                            ? info?.ruleCode + '(' + info?.id + ')'
                            : '(' + info?.id + ')'
                    )
                )
            );
            const vendors = setRowDataInfoCopy[0].vendor.reduce(
                (s: any, el: any, idx: any, arr: any) => {
                    s += el.id;
                    if (idx < arr.length - 1) {
                        s += ',';
                    }
                    return s;
                },
                ''
            );
            setVendorIds(vendors);

            const clientGroups = setRowDataInfoCopy[0].clientGroup.reduce(
                (s: any, el: any, idx: any, arr: any) => {
                    s += el;
                    if (idx < arr.length - 1) {
                        s += ',';
                    }
                    return s;
                },
                ''
            );
            setClientGroupIds(clientGroups);
            clientGroupIdSelected = clientGroups;
            loadStoreOptionsByCustomerGroup();
        }
    }, [setRowData]);

    // vendorList = VendorData;
    itemCatalogList = ItemCatalogData;
    storesList = StoredData;
    //console.log('vendorList', vendorList);
    const getWhseLocations = async () => {
        const whseLocationEndPoint = getAllMasterData('WhseLocations');
        const whseLocationResult = await axios
            .get(whseLocationEndPoint)
            .catch((err) => err);
        dispatch(setWHLocationsData(whseLocationResult.data));
    };

    const getDispositions = async () => {
        const dispositionEndPoint = getAllMasterData('WhseLocations');
        const dispositionResult = await axios
            .get(dispositionEndPoint)
            .catch((err) => err);
        dispatch(setDispositionData(dispositionResult.data));
    };
    const handleRCInDefineRuleUpdate = (e: any) => {
        const addRCItem = JSON.parse(e.target.value);
        const rowInfoCopy = JSON.parse(JSON.stringify(rowInfo));
        // const abc = [...rowInfoCopy[0].reclaimCenter];
        // if(!abc.filter(person => person.reclaimcenterName === addRCItem.reclaimcenterName)){
        //   abc.push(addRCItem);
        //   setRowInfo(abc);
        //   }

        //const addRCItem = JSON.parse(e.target.value);
        const RCInfo = [...existingRCList];
        if (
            !RCInfo.some(
                (person) =>
                    person.reclaimcenterName === addRCItem.reclaimcenterName
            )
        ) {
            RCInfo.push(addRCItem);
            setExistingRCList(RCInfo);
            rowInfoCopy[0].reclaimCenter.push(addRCItem);
            setRowInfo(rowInfoCopy);
        }
        var ddlRC = document.getElementById('ddlRCList') as HTMLSelectElement;
        if (ddlRC) {
            ddlRC.selectedIndex = 0;
        }
    };

    const handleRemoveLinkedRCInDefineRuleUpdate = (rcId: any) => {
        let linkedRC = [...rowInfo];
        let updatedRCInfo = linkedRC.map((item) => ({
            ...item,
            reclaimCenter: item.reclaimCenter.filter(
                (itemData: any) => itemData.id !== rcId
            ),
        }));
        setRowInfo(updatedRCInfo);
        let linkedRCData = [...existingRCList];
        let updatedRCData = linkedRCData.filter((item) => item.id !== rcId);
        setExistingRCList(updatedRCData);
    };

    const handleVendorInDefineRuleUpdate = (e: any) => {
        const addVItem = e;
        const rowInfoCopy = JSON.parse(JSON.stringify(rowInfo));
        const VInfo = [...existingVendorList];
        if (!VInfo.some((person) => person.apVendorNumber === addVItem)) {
            VInfo.push(addVItem);
            setExistingVendorList(VInfo);

            rowInfoCopy[0].vendor.push(addVItem);
            setRowInfo(rowInfoCopy);
            const vendors = rowInfoCopy[0].vendor.reduce(
                (s: any, el: any, idx: any, arr: any) => {
                    s += el.id;
                    if (idx < arr.length - 1) {
                        s += ',';
                    }
                    return s;
                },
                ''
            );
            setVendorIds(vendors);
            vendorIdsSelected = vendors;
            loadUPCOptionsByVendor();
        }
        var ddlVendor = document.getElementById(
            'ddlVendorList'
        ) as HTMLSelectElement;
        if (ddlVendor) {
            ddlVendor.selectedIndex = 0;
        }
    };

    const handleRemoveLinkedVendorInDefineRuleUpdate = (vId: any) => {
        let linkedVendor = [...rowInfo];
        let updatedVData = linkedVendor.map((item) => ({
            ...item,
            vendor: item.vendor.filter((itemData: any) => itemData.id !== vId),
        }));
        setRowInfo(updatedVData);
        let linkedVendorData = [...existingVendorList];
        let updatedVendorData = linkedVendorData.filter(
            (item) => item.id !== vId
        );
        const vendors = updatedVendorData.reduce(
            (s: any, el: any, idx: any, arr: any) => {
                s += el.id;
                if (idx < arr.length - 1) {
                    s += ',';
                }
                return s;
            },
            ''
        );
        setVendorIds(vendors);
        setExistingVendorList(updatedVendorData);
    };

    const handleUPCInDefineRuleUpdate = (e: any) => {
        const addUPCItem = e;
        const rowInfoCopy = JSON.parse(JSON.stringify(rowInfo));
        const UPCInfo = [...existingUPCList];
        if (
            !UPCInfo.some(
                (person) => person.upc.upcReference === addUPCItem.upc
            )
        ) {
            UPCInfo.push({
                id: addUPCItem.id,
                billCost: null,
                upc: addUPCItem,
            });
            setExistingUPCList(UPCInfo);
            rowInfoCopy[0].upc?.push({
                id: addUPCItem.id,
                billCost: null,
                upc: addUPCItem,
            });
            rowInfoCopy[0].upcIds?.push({
                id: addUPCItem.id,
                billCost: null,
                upc: addUPCItem,
            });
            setRowInfo(rowInfoCopy);
            setExistingUPCInfo([
                ...existingUPCInfo,
                { id: addUPCItem.upc, billCost: null },
            ]);
        }
        var ddlUPC = document.getElementById('ddlUPCList') as HTMLSelectElement;
        if (ddlUPC) {
            ddlUPC.selectedIndex = 0;
        }
    };

    const handleRemoveLinkedUPCInDefineRuleUpdate = (UId: any) => {
        console.log(existingUPCList);
        let linkedUPCData = [...rowInfo];
        let updatedUPCData = linkedUPCData.map((item) => ({
            ...item,
            upc: item.upc.filter((itemData: any) => itemData.id !== UId),
        }));

        let linkedUData = [...existingUPCList];
        let updatedUData = linkedUData.filter((item) => item.id !== UId);
        console.log(updatedUData);
        let upcList: any = [];
        let upc: any = updatedUData.forEach((item) => {
            upcList.push({
                billCost: item.billCost,
                id: item.upc.upcReference,
            });
        });
        setRowInfo(updatedUPCData);
        setExistingUPCList(updatedUData);
        setExistingUPCInfo(upcList);
        console.log(existingUPCList);
    };

    const handleCustomerInDefineRuleUpdate = (e: any) => {
        const addVItem = e;
        const rowInfoCopy = JSON.parse(JSON.stringify(rowInfo));
        const VInfo = [...existingStoresList];
        if (!VInfo.some((person) => person.storeName === addVItem.storeName)) {
            VInfo.push(addVItem);
            setExistingStoresList(VInfo);
            rowInfoCopy[0].store.push(addVItem);
            setRowInfo(rowInfoCopy);
        }
        var ddlVendor = document.getElementById(
            'ddlStoreList'
        ) as HTMLSelectElement;
        if (ddlVendor) {
            ddlVendor.selectedIndex = 0;
        }
    };

    const handleRemoveLinkedCustomerInDefineRuleUpdate = (stId: any) => {
        let linkedRCData = [...rowInfo];
        let updatedRCData = linkedRCData.map((item) => ({
            ...item,
            store: item.store.filter((itemData: any) => itemData.id !== stId),
        }));
        setRowInfo(updatedRCData);
        let linkedVendorData = [...existingStoresList];
        let updatedVendorData = linkedVendorData.filter(
            (item) => item.id !== stId
        );
        setExistingStoresList(updatedVendorData);
    };

    const handleClientGroupInDefineRuleUpdate = (e: any) => {
        const addCGItem = JSON.parse(e.target.value);
        console.log(addCGItem);
        const rowInfoCopy = JSON.parse(JSON.stringify(rowInfo));
        const CGInfo = [...existingClientGroupList];
        if (
            !CGInfo.some(
                (person) =>
                    person?.clientGroupNumber === addCGItem?.clientGroupNumber
            )
        ) {
            CGInfo.push(addCGItem);
            setExistingClientGroupList(CGInfo);
            rowInfoCopy[0].clientGroup.push(addCGItem?.clientGroupNumber);
            setRowInfo(rowInfoCopy);
            const clientGroups = rowInfoCopy[0].clientGroup.join(',');
            setClientGroupIds(clientGroups);
            loadStoreOptionsByCustomerGroup();
        }
        var ddlVendor = document.getElementById(
            'ddlVendorList'
        ) as HTMLSelectElement;
        if (ddlVendor) {
            ddlVendor.selectedIndex = 0;
        }
    };

    const handleRemoveLinkedClientGroupInDefineRuleUpdate = (rcId: any) => {
        let linkedRCData = [...rowInfo];

        let updatedRCData = linkedRCData.map((item) => ({
            ...item,
            clientGroup: item.clientGroup.filter(
                (itemData: any) => itemData !== rcId.clientGroupNumber
            ),
        }));
        setRowInfo(updatedRCData);
        let linkedVendorData = [...existingClientGroupList];
        let updatedVendorData = linkedVendorData.filter(
            (item) => item.clientGroupNumber !== rcId.clientGroupNumber
        );

        const clientGroups = updatedVendorData.reduce(
            (s: any, el: any, idx: any, arr: any) => {
                s += el.clientGroupNumber;
                if (idx < arr.length - 1) {
                    s += ',';
                }
                return s;
            },
            ''
        );
        setClientGroupIds(clientGroups);
        setExistingClientGroupList(updatedVendorData);
    };

    const handleCodeInDefineRuleUpdate = (e: any) => {
        setRowInfo([{ ...rowInfo[0], ruleCode: e.target.value }]);
    };

    let startdateVal: any;
    let enddateVal: any;
    const handleChangeDateRangeUpdate = (e: any) => {
        console.log(e.target.value);
        // if(e?.target?.value !== undefined && e?.target?.value !== null && e?.target?.value !== '') {
        //   var selectedDates = e.target.value;
        //   const startDateSelected = new Date(selectedDates[0]);
        //   startdateVal = new Date(startDateSelected.toLocaleDateString());
        //   const endDateSelection = new Date(selectedDates[1]);
        //   enddateVal = new Date(endDateSelection.toLocaleDateString());
        //   const rowInfoCopy = JSON.parse(JSON.stringify(rowInfo));
        //   rowInfoCopy[0].startDate = JSON.parse(JSON.stringify(startdateVal));
        //   rowInfoCopy[0].endDate = JSON.parse(JSON.stringify(enddateVal));
        //   setRowInfo(rowInfoCopy);
        // }
        if (
            e?.target?.value !== undefined &&
            e?.target?.value !== null &&
            e?.target?.value !== ''
        ) {
            var selectedDates = e?.target?.value;
            const rowInfoCopy = JSON.parse(JSON.stringify(rowInfo));
            rowInfoCopy[0].startDate = moment(selectedDates[0]).format(
                'MM/DD/YYYY'
            );
            rowInfoCopy[0].endDate = moment(selectedDates[1]).format(
                'MM/DD/YYYY'
            );
            selectedEndDate = selectedDates[1];
            selectedStartDate = selectedDates[0];
            setRowInfo(rowInfoCopy);
        } else {
            const rowInfoCopy = JSON.parse(JSON.stringify(rowInfo));
            rowInfoCopy[0].startDate = '';
            rowInfoCopy[0].endDate = '';
            console.log(rowInfoCopy[0]);
            selectedEndDate = '';
            selectedStartDate = '';
            setRowInfo(rowInfoCopy);
        }
    };

    const handleDebitReasonUpdate = (e: any) => {
        setRowInfo([{ ...rowInfo[0], debitReasonCode: e.target.value }]);
    };
    const handleWhseLocation = (e: any) => {
        setRowInfo([{ ...rowInfo[0], warehouseLocation: e.target.value }]);
    };
    const handleDisposition = (e: any) => {
        setRowInfo([{ ...rowInfo[0], disposition: e.target.value }]);
    };
    const handleSetRowDatabyUpc = (e: any) => {
        if (rowIfoCopyGlobalByUPC && rowIfoCopyGlobalByUPC.length > 0) {
            setRowInfo(rowIfoCopyGlobalByUPC);
            setExistingUPCInfo(existingUPCInfoForUPC);
            console.log(rowInfo);
        } else {
            setRowInfo(rowInfo);
            setExistingUPCInfo(existingUPCInfo);
            console.log(existingUPCInfo);
        }
    };
    const handleChangeBillCostbyUPC = (e: any, UPCNum: any, idx: number) => {
        let id = UPCNum;
        const rowInfoCopy = JSON.parse(JSON.stringify(rowInfo));
        const UpcIdsCopy = rowInfoCopy[0].upcIds
            ? rowInfoCopy[0].upcIds
            : rowInfoCopy[0].upc;
        const val = e.target.value;
        console.log(rowInfoCopy);
        if (e.target.value.includes('.') === true) {
            const regexp = /^\d+\.\d{0,2}$/;
            if (!regexp.test(val)) {
                return;
            }
            if (e.target.value.split('.')[1].length > 2) {
                if (rowInfoCopy[0]?.upcIds) {
                    rowInfoCopy[0].upcIds[idx] = {
                        billCost: parseFloat(val).toFixed(2),
                        id: UPCNum,
                    };
                }
                if (rowInfoCopy[0]?.upc) {
                    rowInfoCopy[0].upc[idx] = {
                        billCost: parseFloat(val).toFixed(2),
                        id: UPCNum,
                        upc: rowInfoCopy[0].upc[idx].upc,
                    };
                }
                if (UpcIdsCopy) {
                    UpcIdsCopy[idx] = {
                        billCost: parseFloat(val).toFixed(2),
                        id: UPCNum,
                    };
                }
            } else {
                if (rowInfoCopy[0]?.upcIds) {
                    rowInfoCopy[0].upcIds[idx] = {
                        billCost: val,
                        id: UPCNum,
                    };
                }
                if (rowInfoCopy[0]?.upc) {
                    rowInfoCopy[0].upc[idx] = {
                        billCost: val,
                        id: UPCNum,
                        upc: rowInfoCopy[0].upc[idx].upc,
                    };
                }
                if (UpcIdsCopy) {
                    UpcIdsCopy[idx] = { billCost: val, id: UPCNum };
                }
            }
        } else {
            if (rowInfoCopy[0]?.upcIds) {
                rowInfoCopy[0].upcIds[idx] = {
                    billCost: parseInt(val),
                    id: UPCNum,
                };
            }
            if (UpcIdsCopy) {
                UpcIdsCopy[idx] = {
                    billCost: parseInt(val),
                    id: UPCNum,
                };
            }
            if (rowInfoCopy[0]?.upc) {
                rowInfoCopy[0].upc[idx] = {
                    billCost: parseInt(val),
                    id: UPCNum,
                    upc: rowInfoCopy[0].upc[idx].upc,
                };
            }
        }
        rowIfoCopyGlobalByUPC = rowInfoCopy;
        if (UpcIdsCopy) {
            existingUPCInfoForUPC = [...UpcIdsCopy];
        }
    };

    const handleChangeBillCostAllUPC = (e: any) => {
        const rowIfoCopy = JSON.parse(JSON.stringify(rowInfo));
        const val = e.target.value;
        if (e.target.value.includes('.') === true) {
            const regexp = /^\d+\.\d{0,2}$/;
            if (!regexp.test(val)) {
                return;
            }
            if (e.target.value.split('.')[1].length > 2) {
                rowIfoCopy[0].billCost = parseFloat(val).toFixed(2);
                //setBillCostByAllUPC(val);
                //upcInputFieldRef.current?.focus();
            } else {
                rowIfoCopy[0].billCost = parseFloat(val).toFixed(2);
                // setBillCostByAllUPC(val);
                // upcInputFieldRef.current?.focus();
            }
        } else {
            rowIfoCopy[0].billCost = parseFloat(val).toFixed(2);
            // setBillCostByAllUPC(parseInt(val));
            //upcInputFieldRef.current?.focus();
        }
        rowIfoCopyGlobal = rowIfoCopy;
        // dispatch(setRuleExceptionRowData(rowIfoCopy));
        // document.getElementById('txtBillCostAllUPC')?.focus();
        // upcInputFieldRef.current?.focus();
    };

    const handleSetRowData = (e: any) => {
        if (rowIfoCopyGlobal && rowIfoCopyGlobal.length > 0) {
            setRowInfo(rowIfoCopyGlobal);
        } else {
            setRowInfo(rowInfo);
        }
    };

    const handleMasterDataChange = async (
        endpoint: string,
        getEndpoint: string
    ) => {
        const rowInfoObject = Object.assign({}, ...rowInfo);
        rowInfoObject.startDate =
            rowInfoObject?.startDate !== undefined &&
            rowInfoObject?.startDate !== null &&
            rowInfoObject?.startDate !== ''
                ? moment(rowInfoObject?.startDate).format('YYYY-MM-DD')
                : null;
        rowInfoObject.endDate =
            rowInfoObject?.endDate !== undefined &&
            rowInfoObject?.endDate !== null &&
            rowInfoObject?.endDate !== ''
                ? moment(rowInfoObject?.endDate).format('YYYY-MM-DD')
                : null;
        console.log(existingUPCInfo);
        console.log(rowInfoObject);
        rowInfoObject.upcIds = existingUPCInfo;
        console.log(rowInfoObject);
        delete rowInfoObject?.client;
        delete rowInfoObject?.createdBy;
        delete rowInfoObject?.createdDate;
        delete rowInfoObject?.lastModifiedBy;
        delete rowInfoObject?.lastModifiedDate;
        delete rowInfoObject?.reclaimCenter;
        delete rowInfoObject?.store;
        delete rowInfoObject?.upc;
        delete rowInfoObject?.vendor;
        console.log(rowInfoObject);
        const result = await axios.put(endpoint, rowInfoObject);
        if (result && result.data) {
            notify('Updated successfully', 'success');
            backPressRuleException();
            const getresult = await axios.get(getEndpoint);
            dispatch(setCurrentContact(getresult.data));
        } else {
            notify('Error', 'error');
        }
    };

    let tabName: any = ContactType.RULE_EXCEPTIONS;
    const vendorIdsFinal: any[] = [];
    const RCIdsFinal: any[] = [];
    const UPCdsFinal: any[] = [];
    const CustIdsFinal: any[] = [];

    const handleUpdateRuleException = () => {
        const vendorArray = JSON.parse(JSON.stringify(rowInfo[0].vendor));
        vendorArray.forEach((item: any) => {
            vendorIdsFinal.push(item.id);
        });
        const RCArray = JSON.parse(JSON.stringify(rowInfo[0].reclaimCenter));
        RCArray.forEach((item: any) => {
            RCIdsFinal.push(item.id);
        });
        const Upcids = JSON.parse(JSON.stringify(rowInfo[0].upc));
        Upcids.forEach((item: any) => {
            UPCdsFinal.push(item.upc);
        });
        const Cistids = JSON.parse(JSON.stringify(rowInfo[0].store));
        Cistids.forEach((item: any) => {
            CustIdsFinal.push(item.id);
        });
        setRowInfo([
            {
                ...rowInfo[0],
                reclaimCenterIds: RCIdsFinal,
                vendorIds: vendorIdsFinal,
                upcIds: UPCdsFinal,
                storeIds: CustIdsFinal,
            },
        ]);
    };

    useEffect(() => {
        console.log(rowInfo);
        if (rowInfo[0]?.vendorIds && rowInfo[0]?.reclaimCenterIds) {
            const endpoint = updateMasterDataEndPoint(tabName, rowInfo);
            const getEndpoint = getEndPoint(tabName);
            handleMasterDataChange(endpoint, getEndpoint);
        }
    }, [rowInfo]);

    // const getVendorsList = async (txt?: any) => {
    //     const endPointURL = getEndPoint(
    //         ContactType.VENDORS,
    //         pageNo,
    //         pageSize,
    //         txt
    //     );
    //     await axios.get(endPointURL).then((res: any) => {
    //         setVendorList(res.data.content);
    //         console.log(vendorList);
    //         //alert(vendorList);
    //     });
    // };

    const handleChange = (value: any) => {
        handleVendorInDefineRuleUpdate(value);
    };

    const loadVendorOptions = (txt: any) => {
        const endPointURL = getEndPoint(
            ContactType.VENDORS,
            pageNo,
            pageSize,
            txt
        );
        return axios.get(endPointURL).then((res: any) => {
            // setVendorList(res.data.content);
            // console.log(vendorList);
            //alert(vendorList);
            // return res.data.content
            // const options: any = [];
            // res.data.content.forEach((vendor: any) => {
            //     options.push({
            //         label: vendor.apVendorName,
            //         value: vendor.apVendorNumber,
            //     });
            // });
            return res.data.content;
        });
    };

    const loadUPCOptionsByVendor = (txt?: any) => {
        vendorIdsSelected = vendorIdsSelected ? vendorIdsSelected : vendorIds;
        const upcEndPoint: any = RuleExceptionEndPointsForFiltering(
            'UPCs',
            pageNo,
            pageSize,
            vendorIdsSelected,
            vendorIdsSelected ? txt : 'a'
        );
        return axios.get(upcEndPoint).then((res: any) => {
            return res.data.content;
        });
    };

    const loadStoreOptionsByCustomerGroup = (txt?: any) => {
        clientGroupIdSelected = clientGroupIdSelected
            ? clientGroupIdSelected
            : clientGroupIds;
        const upcEndPoint: any = RuleExceptionEndPointsForFiltering(
            'Stores',
            pageNo,
            pageSize,
            clientGroupIdSelected,
            clientGroupIdSelected ? txt : 'zzz'
        );
        return axios.get(upcEndPoint).then((res: any) => {
            return res.data.content;
        });
    };

    // const getUpcListBasedonVendors = async (txt?: any) => {
    //     const upcEndPoint = casualDataAddPopupEndPoints(
    //         'UPCs',
    //         pageNo,
    //         pageSize,
    //         txt
    //     );
    //     const upcResult = await axios.get(upcEndPoint).catch((err) => err);
    //     setUpcListBasedonVendors(upcResult.data?.content);
    // };
    const defineRuleCriteriaContent = () => {
        return (
            <>
                {rowInfo &&
                    rowInfo.map((info) => (
                        <>
                            <div className="row mt-2rem">
                                <div className="col-sm-2">
                                    <span className="lblClassRule">
                                        Reclaim Center(s)
                                        <span className="requiredStar">*</span>
                                    </span>
                                </div>
                                <div
                                    className="col-sm-2"
                                    style={{ paddingLeft: '23px' }}
                                >
                                    <span className="lblClassRule">
                                        Vendor(s)
                                        <span className="requiredStar">*</span>
                                    </span>
                                </div>
                                <div
                                    className="col-sm-2"
                                    style={{ paddingLeft: '33px' }}
                                >
                                    <span className="lblClassRule">UPC(s)</span>
                                </div>
                                {/* <div className="col-sm-2">
                <span>Customer(s)</span>
              </div> */}
                                <div
                                    className="col-sm-2"
                                    style={{ paddingLeft: '43px' }}
                                >
                                    <span className="lblClassRule">
                                        Client Group(s)
                                    </span>
                                </div>
                                <div
                                    className="col-sm-2"
                                    style={{ paddingLeft: '53px' }}
                                >
                                    <span className="lblClassRule">
                                        Store(s)
                                    </span>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm-2 ml-1rem p-0">
                                    <select
                                        className="form-control"
                                        id="ddlRCList"
                                        onChange={(e: any) =>
                                            handleRCInDefineRuleUpdate(e)
                                        }
                                    >
                                        <option>Select</option>
                                        {RCData?.map((rc) => (
                                            <option value={JSON.stringify(rc)}>
                                                {rc.reclaimcenterName}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                                <div className="col-sm-2 ml-1rem p-0">
                                    <AsyncSelect
                                        //cacheOptions
                                        defaultOptions
                                        getOptionLabel={(e: any) =>
                                            e.apVendorName
                                        }
                                        getOptionValue={(e) => e.apVendorName}
                                        loadOptions={loadVendorOptions}
                                        onChange={
                                            handleVendorInDefineRuleUpdate
                                        }
                                    />
                                </div>
                                <div className="col-sm-2 ml-1rem p-0">
                                    <AsyncSelect
                                        //cacheOptions
                                        defaultOptions
                                        getOptionLabel={(e: any) => e.upc}
                                        getOptionValue={(e) => e.upc}
                                        loadOptions={loadUPCOptionsByVendor}
                                        onChange={handleUPCInDefineRuleUpdate}
                                    />
                                </div>
                                {/* <div className="col-sm-2">
                <select className="form-control" onChange={(e: any) => handleCustomerInDefineRuleUpdate(e)}>
                  <option>Select</option>
                  {CustomerData?.map((c) =>
                    <option value={JSON.stringify(c)}>{c.customerName}</option>
                  )}
                </select>
              </div> */}
                                <div className="col-sm-2 ml-1rem p-0">
                                    <select
                                        className="form-control"
                                        onChange={(e: any) =>
                                            handleClientGroupInDefineRuleUpdate(
                                                e
                                            )
                                        }
                                    >
                                        <option>Select</option>
                                        {ClientGroupData?.map((cg) => (
                                            <option value={JSON.stringify(cg)}>
                                                ({cg.clientGroupNumber})
                                                {cg.clientGroupName}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                                <div className="col-sm-2 ml-1rem p-0">
                                    <AsyncSelect
                                        //cacheOptions
                                        defaultOptions
                                        getOptionLabel={(e: any) =>
                                            e.storeNameAndMainframeCustomerNumber
                                        }
                                        getOptionValue={(e) =>
                                            e.storeNameAndMainframeCustomerNumber
                                        }
                                        loadOptions={
                                            loadStoreOptionsByCustomerGroup
                                        }
                                        onChange={
                                            handleCustomerInDefineRuleUpdate
                                        }
                                    />
                                    {/* <select
                                        className="form-control"
                                        id="ddlStoreList"
                                        onChange={(e: any) =>
                                            handleCustomerInDefineRuleUpdate(e)
                                        }
                                    >
                                        <option>Select</option>
                                        {storesList?.content &&
                                            storesList?.content.map(
                                                (c: any) => (
                                                    <option
                                                        value={JSON.stringify(
                                                            c
                                                        )}
                                                    >
                                                        {c.storeName}
                                                    </option>
                                                )
                                            )}
                                    </select> */}
                                </div>
                            </div>
                            <div className="row mt-3">
                                <div className="col-sm-2 RCListInRuleExceptionView">
                                    {existingRCList.map((item: any) => (
                                        <div className="row linkedStoreDiv ml-0 mr-0">
                                            <div className="col-sm-10 pl-1">
                                                <span
                                                    title="remove"
                                                    className="linkedStoreName pr-2"
                                                >
                                                    <u
                                                        style={{
                                                            lineHeight:
                                                                '18px !important',
                                                        }}
                                                    >
                                                        {item.reclaimcenterName}{' '}
                                                    </u>
                                                </span>
                                            </div>
                                            <div className="col-sm-2 p-0">
                                                <img
                                                    src={closeIcon}
                                                    alt="Close"
                                                    className="contactcloseIcon float-right"
                                                    onClick={() =>
                                                        handleRemoveLinkedRCInDefineRuleUpdate(
                                                            item.id
                                                        )
                                                    }
                                                />{' '}
                                            </div>
                                        </div>
                                    ))}
                                </div>
                                <div className="col-sm-2 RCListInRuleExceptionView">
                                    {existingVendorList.map((item: any) => (
                                        <div className="row linkedStoreDiv ml-0 mr-0">
                                            <div className="col-sm-10 pl-1">
                                                <span
                                                    title="remove"
                                                    className="linkedStoreName"
                                                >
                                                    <u>
                                                        ({item.apVendorNumber}){' '}
                                                        {item.apVendorName}
                                                    </u>
                                                </span>
                                            </div>
                                            {/* <span className="linkedStoreNameRemove" onClick={(e) => handleRemoveLinkedVendorInDefineRuleUpdate(item.id)} >X</span> */}
                                            <div className="col-sm-2 p-0">
                                                <img
                                                    src={closeIcon}
                                                    alt="Close"
                                                    className="contactcloseIcon float-right"
                                                    onClick={() =>
                                                        handleRemoveLinkedVendorInDefineRuleUpdate(
                                                            item.id
                                                        )
                                                    }
                                                    style={{ display: 'block' }}
                                                />
                                            </div>
                                        </div>
                                    ))}
                                </div>
                                <div className="col-sm-2 RCListInRuleExceptionView">
                                    {existingUPCList?.map((item: any) => (
                                        <div className="row linkedStoreDiv ml-0 mr-0">
                                            <div className="col-sm-10 pl-1">
                                                <span
                                                    title="remove"
                                                    className="linkedStoreName"
                                                >
                                                    {' '}
                                                    <u>
                                                        {item?.upc?.upcReference
                                                            ? item?.upc
                                                                  ?.upcReference
                                                            : item.upc.upc}
                                                    </u>
                                                </span>
                                            </div>
                                            {/* <span className="linkedStoreNameRemove" onClick={(e) => handleRemoveLinkedUPCInDefineRuleUpdate(item.id)}>X</span> */}
                                            <div className="col-sm-2 p-0">
                                                <img
                                                    src={closeIcon}
                                                    alt="Close"
                                                    className="contactcloseIcon float-right"
                                                    onClick={() =>
                                                        handleRemoveLinkedUPCInDefineRuleUpdate(
                                                            item.id
                                                        )
                                                    }
                                                />
                                            </div>
                                        </div>
                                    ))}
                                </div>
                                {/* <div className="col-sm-2 ">
                {info.customer && info.customer.map((item: any) =>
                  <div className="linkedStoreDiv"><span title="remove" className="linkedStoreName">{item.customerName}</span>  <span className="linkedStoreNameRemove" onClick={(e) => handleRemoveLinkedCustomerInDefineRuleUpdate(item.id)}>X</span></div>
                )}
              </div> */}
                                <div className="col-sm-2 RCListInRuleExceptionView">
                                    {existingClientGroupList &&
                                        existingClientGroupList.map(
                                            (item: any) => (
                                                <div className="row linkedStoreDiv ml-0 mr-0">
                                                    <div className="col-sm-10 pl-1">
                                                        <span
                                                            title="remove"
                                                            className="linkedStoreName"
                                                        >
                                                            <u>
                                                                {' '}
                                                                (
                                                                {
                                                                    item.clientGroupNumber
                                                                }
                                                                ){' '}
                                                                {
                                                                    item.clientGroupName
                                                                }{' '}
                                                            </u>
                                                        </span>
                                                    </div>
                                                    {/* <span className="linkedStoreNameRemove" onClick={(e) => handleRemoveLinkedClientGroupInDefineRuleUpdate(item)}>X</span> */}
                                                    <div className="col-sm-2 p-0">
                                                        <img
                                                            src={closeIcon}
                                                            alt="Close"
                                                            className="contactcloseIcon float-right"
                                                            onClick={() =>
                                                                handleRemoveLinkedClientGroupInDefineRuleUpdate(
                                                                    item
                                                                )
                                                            }
                                                        />
                                                    </div>
                                                </div>
                                            )
                                        )}
                                </div>
                                <div className="col-sm-2 RCListInRuleExceptionView">
                                    {existingStoresList.map((item: any) => (
                                        <div className="row linkedStoreDiv ml-0 mr-0">
                                            <div className="col-sm-10 pl-1">
                                                <span
                                                    title="remove"
                                                    className="linkedStoreName"
                                                >
                                                    <u>
                                                        {item.storeNameAndMainframeCustomerNumber
                                                            ? item.storeNameAndMainframeCustomerNumber
                                                            : '(' +
                                                              item.mainframeCustomerNumber +
                                                              ')' +
                                                              item.storeName}
                                                    </u>
                                                </span>
                                            </div>
                                            <div className="col-sm-2 p-0">
                                                <img
                                                    src={closeIcon}
                                                    alt="Close"
                                                    className="contactcloseIcon float-right"
                                                    onClick={() =>
                                                        handleRemoveLinkedCustomerInDefineRuleUpdate(
                                                            item.id
                                                        )
                                                    }
                                                />
                                            </div>
                                            {/* <span className="linkedStoreNameRemove" onClick={(e) => handleRemoveLinkedCustomerInDefineRuleUpdate(item.id)}>X</span> */}
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </>
                    ))}
            </>
        );
    };
    const defineValueChangesContent = () => {
        return (
            <>
                {rowInfo &&
                    rowInfo.map((info) => (
                        <>
                            <div className="row mt-2rem">
                                <div className="col-sm-8 p-0">
                                    <div className="col-sm-4">
                                        <span
                                            className="lblClass"
                                            style={{ float: 'left' }}
                                        >
                                            Disposition
                                            <span className="requiredStar">
                                                *
                                            </span>
                                        </span>
                                        <select
                                            className="form-control"
                                            id="ddlDisposition"
                                            onChange={(e: any) =>
                                                handleDisposition(e)
                                            }
                                        >
                                            <option value={info.disposition}>
                                                {info.disposition}
                                            </option>
                                            <option value="Select">
                                                Select
                                            </option>
                                            {DispositioData?.map((d) => (
                                                <option value={d.disposition}>
                                                    {d.disposition}
                                                </option>
                                            ))}
                                        </select>
                                    </div>

                                    <div className="col-sm-4">
                                        <span
                                            className="lblClass"
                                            style={{ float: 'left' }}
                                        >
                                            Whse Location
                                            <span className="requiredStar">
                                                *
                                            </span>
                                        </span>
                                        {/* <input type="text" className="form-control" id="txtWhseLocation" defaultValue={info.warehouseLocation} /> */}
                                        <select
                                            className="form-control"
                                            defaultValue={
                                                info.warehouseLocation
                                            }
                                            onChange={(e: any) =>
                                                handleWhseLocation(e)
                                            }
                                        >
                                            <option value="Select">
                                                Select
                                            </option>
                                            {whseLocationData?.map((d) => (
                                                <option
                                                    value={d.warehouseLocation}
                                                >
                                                    {d.warehouseLocation}
                                                </option>
                                            ))}
                                        </select>
                                    </div>

                                    <div className="col-sm-4">
                                        <span
                                            className="lblClass"
                                            style={{ float: 'left' }}
                                        >
                                            Debit Reason
                                        </span>
                                        <select
                                            className="form-control"
                                            defaultValue={info.debitReasonCode}
                                            onChange={(e: any) =>
                                                handleDebitReasonUpdate(e)
                                            }
                                        >
                                            <option value="Select">
                                                Select
                                            </option>
                                            {DebitReasonData?.map((d) => (
                                                <option
                                                    value={d.debitReasonCode}
                                                >
                                                    {d.debitReasonCode}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                                <div
                                    className="col-sm-4 pb-2"
                                    style={{ background: '#e8e8e873' }}
                                >
                                    <div className="col-sm-5 p-1">
                                        <span
                                            className="lblClass"
                                            style={{ float: 'left' }}
                                        >
                                            Bill Cost
                                        </span>
                                        <span
                                            className="lblClassAppliesTUPC mt-3"
                                            style={{ lineHeight: '18px' }}
                                        >
                                            *Applies to all UPCs
                                        </span>

                                        <input
                                            type="number"
                                            className="form-control"
                                            id="txtBillCostAllUPC"
                                            defaultValue={info.billCost}
                                            ref={upcInputFieldRef}
                                            // type="number"
                                            // className="form-control"
                                            // id="txtBillCostAllUPC"
                                            // value={billCostByAllUPC}
                                            onChange={(e: any) =>
                                                handleChangeBillCostAllUPC(e)
                                            }
                                            onBlur={(e: any) =>
                                                handleSetRowData(e)
                                            }
                                        />
                                    </div>

                                    <div className="col-sm-7 upcBillcostList">
                                        <span
                                            className="lblClass"
                                            style={{
                                                float: 'left',
                                                marginTop: '4%',
                                            }}
                                        >
                                            Bill Cost By UPC ($)
                                        </span>
                                        {info.upc.map(
                                            (item: any, i: number) => (
                                                <>
                                                    <div className="col-sm-7 p-1">
                                                        <input
                                                            type="number"
                                                            className="form-control"
                                                            defaultValue={parseInt(
                                                                item?.upc
                                                                    ?.upcReference
                                                                    ? item?.upc
                                                                          ?.upcReference
                                                                    : item?.upc
                                                                          ?.upc
                                                            )}
                                                            disabled
                                                        />
                                                    </div>
                                                    <div className="col-sm-5 p-1">
                                                        <input
                                                            // ref={byupcInputFieldRef}
                                                            type="number"
                                                            className="form-control"
                                                            defaultValue={
                                                                item?.billCost
                                                            }
                                                            onChange={(
                                                                e: any
                                                            ) =>
                                                                handleChangeBillCostbyUPC(
                                                                    e,
                                                                    item?.upc
                                                                        ?.upcReference
                                                                        ? item
                                                                              ?.upc
                                                                              ?.upcReference
                                                                        : item
                                                                              ?.upc
                                                                              ?.upc,
                                                                    i
                                                                )
                                                            }
                                                            onBlur={(e) =>
                                                                handleSetRowDatabyUpc(
                                                                    e
                                                                )
                                                            }
                                                        />
                                                    </div>
                                                </>
                                            )
                                        )}
                                    </div>
                                </div>
                            </div>
                        </>
                    ))}
            </>
        );
    };
    return (
        <>
            {rowInfo &&
                rowInfo.map((info) => (
                    <>
                        <hr className="mainheading-border" />
                        <div>
                            <div className="row">
                                <div className="col-sm-3">
                                    <span
                                        className="backToAllText"
                                        onClick={backPressRuleException}
                                    >
                                        {'<'} Back to all Rule Exceptions
                                    </span>
                                </div>
                                <div className="col-sm-8"></div>
                                <div className="col-sm-1 updateIconRuleExce">
                                    <img
                                        className="cursor-pointer updateIconRuleExce"
                                        src={saveIcon}
                                        onClick={handleUpdateRuleException}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-3">
                                <span
                                    className="lblClass"
                                    style={{ float: 'left' }}
                                >
                                    Code
                                </span>
                                <input
                                    type="text"
                                    className="form-control"
                                    value={info.ruleCode}
                                    onChange={(e: any) =>
                                        handleCodeInDefineRuleUpdate(e)
                                    }
                                />
                            </div>
                            <div className="col-sm-3">
                                <span
                                    className="lblClass"
                                    style={{ float: 'left' }}
                                >
                                    Date Range{' '}
                                </span>
                                <span>
                                    <DateRangePickerComponent
                                        startDate={info.startDate}
                                        endDate={info.endDate}
                                        onChange={(e: any) =>
                                            handleChangeDateRangeUpdate(e)
                                        }
                                        delayUpdate={true}
                                    />
                                </span>
                            </div>
                        </div>
                        <br />

                        <TabComponent id="tab-wizard">
                            <TabItemsDirective>
                                <TabItemDirective
                                    key=""
                                    header={headerText[0]}
                                    content={defineRuleCriteriaContent}
                                />
                                <TabItemDirective
                                    key=""
                                    header={headerText[1]}
                                    content={defineValueChangesContent}
                                />
                            </TabItemsDirective>
                        </TabComponent>
                    </>
                ))}
        </>
    );
};
export default RuleExceptionViewScreen;
