import { DashboardLayoutComponent } from "@syncfusion/ej2-react-layouts";
import { useDispatch } from "react-redux";
import ContentHeader from "../common/ContentHeader/ContentHeader";
import CommonImageWrapper from "../common/CommonImageWrapper/CommonImageWrapper";
import invoicebg from "../../assets/invoice_bg.png";
import bolbg from "../../assets/bol_bg.png";
import "./InvoicesHome.scss";
import { setARInvoicesList, setIsInvoiceUpdated, setSelectedInvoiceScreen } from "./invoiceReducer";
import { bolUrl, InvoiceScreen, invoiceView } from "./config";
import { getARInvoicesListEndPoint } from "./utils";
import axios from "axios";
import { useEffect } from "react";
import { setTabNameForRowData } from "../contactTabs/contactReducer";
import ARINVOICESVG from "../../assets/ARInvoiceImg.svg";
import BOLSSVG from "../../assets/BolsImg.svg";

const InvoicesHome = (props: any) => {
  const dispatch = useDispatch();
  const handleInvoiceScreenSelect = (screenType: string) => {
    dispatch(setTabNameForRowData("Invoice"));
    if (screenType === InvoiceScreen.INVOICE) {
      props.history.push(`${props.match.url}/${invoiceView}`);
      dispatch(setSelectedInvoiceScreen(InvoiceScreen.INVOICE));
    } else {
      props.history.push(`${props.match.url}/${bolUrl}`);
      dispatch(setSelectedInvoiceScreen(InvoiceScreen.BOL));
    }
  };
  const handleGetBOLAndInvoicesInfo = async () => {
    //     const BOLsEndPoint = getBOLListEndPoint();
    //     const bolResult = await axios.get(BOLsEndPoint).catch(err => err);
    //     dispatch(setBOLsList(bolResult.data));
    const ARInvoicesEndPoint = getARInvoicesListEndPoint();
    const ARResult = await axios.get(ARInvoicesEndPoint).catch((err) => err);
    dispatch(setARInvoicesList(ARResult.data));
  };
  useEffect(() => {
    handleGetBOLAndInvoicesInfo();
    dispatch(setIsInvoiceUpdated(false));
  });
  return (
    <DashboardLayoutComponent
      id="defaultLayout"
      cellSpacing={[5, 5]}
      allowResizing={true}
      columns={5}
    >
      <div className="invoices-home" data-row="0" data-col="0" data-sizex="5">
        <ContentHeader value="Invoices" />
        <div className="invoice-images">
          <CommonImageWrapper
            src={ARINVOICESVG}
            width={250}
            height={300}
            gradientColor={"#F2CA52"}
            customclassName="txtARInvoiceHead"
            customImg="imgOrg"
            barColor={"#F2CA52"}
            header="Invoice & Credit Memo"
            content="View and manage Invoice & Credit Memo"
            onClick={() => handleInvoiceScreenSelect(InvoiceScreen.INVOICE)}
          />
          <CommonImageWrapper
            src={BOLSSVG}
            width={250}
            height={300}
            gradientColor={"#8C4D3F"}
            barColor={"#8C4D3F"}
            customclassName="txtBOLHead"
            customImg="imgOrg"
            header="BOLs"
            content="View and manage BOLs"
            onClick={() => handleInvoiceScreenSelect(InvoiceScreen.BOL)}
          />
        </div>
      </div>
    </DashboardLayoutComponent>
  );
};

export default InvoicesHome;
