import { ButtonComponent } from '@syncfusion/ej2-react-buttons';
import { forwardRef, useImperativeHandle } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../redux/store';
import notify from '../../../services/toasterService';
import ContentHeader from '../../common/ContentHeader/ContentHeader';
import Divider from '../../common/divider/Divider';
import { setScanModeListReducer, setScanModeReducer } from '../scanReducer';

const BinModeWarning = forwardRef((props: any, ref) => {
    const { setIsBin, setAddScreen, setScanMode, updateSettings, resetFocusToUpc } =
        props;
        useImperativeHandle(ref, () => ({
            clearForm() {
                props.resetScanMode();
            },
        }));
    
    const dispatch = useDispatch();
    const getScanModeList = useSelector(
        (state: RootState) => state.scanReducer.scanModeList
    );
    const handleCancel = () => {
        //setScanMode('box');
        setAddScreen(false);
        resetFocusToUpc();
        updateSettings();
        //dispatch(setScanModeReducer('box'));
    };
    const handleSetBinMode = () => {
        notify('bin', 'binmode');
        setIsBin(true);
        setScanMode('bin');
        dispatch(setScanModeReducer('bin'));
        if(!getScanModeList?.payload?.includes('bin')) {
            const modesListString = (getScanModeList.payload ? getScanModeList.payload : '') + ',' + 'bin Mode';
            dispatch(setScanModeListReducer(modesListString.replace(/(^,)|(,$)/g, "")));
        }
        setAddScreen(false);
        resetFocusToUpc();
    };
    return (
        <div className="demo-css">
            <ContentHeader value="Bin Mode" />
            <Divider />
            <div className="col-sm-12 mb-5">
                <div className="text-center mt-5 mb-5">
                    <h5 className="binMode">
                        Please validate bin configurations before proceeding.
                    </h5>
                </div>
            </div>
            <div className="col-sm-offset-2 col-sm-8">
                <div className="col-sm-5 pl-3">
                    <ButtonComponent
                        cssClass="eg-btn-secondary mr-1"
                        onClick={handleCancel}
                    >
                        CANCEL
                    </ButtonComponent>
                </div>
                <div className="col-sm-5">
                    <ButtonComponent
                        cssClass="eg-btn-primary float-right btnBox"
                        onClick={handleSetBinMode}
                    >
                        OK
                    </ButtonComponent>
                </div>
            </div>
        </div>
    );
});

export default BinModeWarning;
