import React from "react";
import { toast } from "react-hot-toast";

const NetStatusNotification = (isOnline: boolean, message: string) => {
  if (isOnline) {
    toast.success(message, {
      duration: 4000,
      position: "top-center",
    });
  } else {
    toast.error(message, {
      duration: 4000,
      position: "top-center",
    });
  }

  console.log("NetworkStaus", message);
};
const networkStatus = () => {
  window.addEventListener("load", () => {
    // 1st, we set the correct status when the page loads
    navigator.onLine
      ? NetStatusNotification(true, "You are online")
      : NetStatusNotification(false, "You are offline");

    // now we listen for network status changes
    window.addEventListener("online", () => {
      NetStatusNotification(true, "You are online back !!");
    });

    window.addEventListener("offline", () => {
      NetStatusNotification(false, "Lost Network Connection !!");
    });
  });

  return <div></div>;
};

export default networkStatus;
