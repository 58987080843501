import {
  TabComponent,
  TabItemDirective,
  TabItemsDirective,
} from "@syncfusion/ej2-react-navigations";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { setRowData } from "../grid/gridReducer";
import { setSelectedRecrdsExport, setTabNameForRowData } from "../contactTabs/contactReducer";
import axios from "axios";
import notify from "../../services/toasterService";
import AccessRequest from "./accessrequest/accessrequest";
import RoleManagement from "./roles/roles";
import UserManagement from "./usermanagement/usermanagement";
import "./AccessMgmt.scss";
import { getAccessMgmtEndPoint } from "./utils";
import {
  setIsLoaderShow,
  setModulesList,
  setRolesData,
  setRolesList,
  setUserAccessData,
} from "./accessmgmtReducer";

const parentTab = [
  { text: "Users", id: "0" },
  { text: "Roles", id: "1" },
  { text: "Access Requests", id: "2" },
];

const AccessMgmt = () => {
  const dispatch = useDispatch();
  let selectedTab: string;

  const [userLoginData, SetUserLoginData] = useState<any>();

  useEffect(() => {
    dispatch(setRowData([]));
    dispatch(setSelectedRecrdsExport([]));
    dispatch(setTabNameForRowData(""));
    //userSignIn(selectedTab)
    getAccessManagementTab(selectedTab);
    getRolesAndModules("Roles");
    getRolesAndModules("All Modules");
  }, []);

  /**
   * On page this event will get you the selected tab details
   * @author Anudeep
   * @param e - event from Syncfusion tabs
   */

  const getTabEvent = (e: any) => {
    if (e !== null) {
      const selectedId = e.selectingID;
      // console.log(selectedId);
      parentTab.map((item) => {
        if (item.id === selectedId) {
          selectedTab = item.text;
          // userSignIn('signin');
          // getAccessManagementTab(selectedTab);
        }
      });
      // console.log(selectedTab, 'selectedTab');
    }
  };

  const userSignIn = async (TabName: any) => {
    const endPointURL = getAccessMgmtEndPoint("signin");
    const body = { email: "admin@limnlabs.com", password: "Test123" };
    await axios
      .post(endPointURL, body)
      .then((res: any) => {
        SetUserLoginData(res?.data?.AccessToken);
        sessionStorage.setItem("token", res?.data?.AccessToken?.access_token);
        const token = res?.data?.AccessToken?.access_token;
        if (token !== undefined && token !== null) {
          const base64Url = token.split(".")[1];
          const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
          const jsonPayload = decodeURIComponent(
            atob(base64)
              .split("")
              .map(function (c) {
                return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
              })
              .join("")
          );
          const decodedObj = JSON.parse(jsonPayload);
          console.log(decodedObj, "JWT Token");
        }

        getAccessManagementTab(selectedTab);
        getRolesAndModules("Roles");
        getRolesAndModules("All Modules");
      })
      .catch((err) => {
        console.log(err);
        notify(
          "Error! Unable to fetch data. Please try after sometime.",
          "error"
        );
      });
  };

  const TabChangeEvent = async (e: any) => {
    dispatch(setRowData([]));
    dispatch(setSelectedRecrdsExport([]));
    selectedTab = e.selectingItem.innerText;
    if (selectedTab === "Access Requests") {
      // Here don't need any WS call
    } else {
      await getAccessManagementTab(e.selectingItem.innerText);
    }
  };

  const getRolesAndModules = async (fieldName: any) => {
    // const token = UserService.getToken();
    // console.log(token)
    // const token = sessionStorage.getItem('token');
    const getEndPointUrl = getAccessMgmtEndPoint(fieldName);
    // await axios.post(getEndPointUrl, { }, { headers: { 'Authorization': `Bearer ${token}` }}).then((res: { data: any; }) => {
    await axios
      .post(getEndPointUrl, {}, {})
      .then((res: { data: any }) => {
        if (fieldName === "Roles") {
          dispatch(setRolesList(res.data));
        } else if ((fieldName = "All Modules")) {
          dispatch(setModulesList(res.data));
        } else {
          // dispatch(setArchiveReqData(res.data));
        }
      })
      .catch((err: any) => {
        console.log(err);
        notify(err, "error");
      });
  };
  /**
   * Get selected web services
   * @author Anudeep
   * @param TabName - Selected Tab name
   */
  const getAccessManagementTab = async (TabName: any) => {
    dispatch(setIsLoaderShow(true))
    // const token = UserService.getToken();
    // console.log(token);
    // const token = sessionStorage.getItem('token');
    const endPointURL = getAccessMgmtEndPoint(TabName);
    if (TabName === "Users") {
      // await axios.get(endPointURL, { headers: { 'Authorization': `Bearer ${token}` } }).then((res: any) => {
      await axios
        .get(endPointURL)
        .then((res: any) => {
          dispatch(setIsLoaderShow(false))
          dispatch(setUserAccessData(res.data));
          getRoles("Roles");
        })
        .catch((err) => {
          console.log(err);
          notify(
            "Error! Unable to fetch data. Please try after sometime.",
            "error"
          );
          dispatch(setIsLoaderShow(false))
        });
    } else if (TabName === "Roles") {
      getRoles(TabName);
    } else {
    }
  };

  const getRoles = async (fieldName: any) => {
    dispatch(setIsLoaderShow(true))
    // const token = sessionStorage.getItem('token');
    // const token = UserService.getToken();
    const getEndPointUrl = getAccessMgmtEndPoint(fieldName);
    // await axios.post(getEndPointUrl, {}, { headers: { 'Authorization': `Bearer ${token}` } }).then((res: { data: any; }) => {
    await axios
      .post(getEndPointUrl, {})
      .then((res: { data: any }) => {
        dispatch(setIsLoaderShow(false))
        // console.log(res.data);
        dispatch(setRolesData(res.data));
        dispatch(setRolesList(res.data));
      })
      .catch((err: any) => {
        dispatch(setIsLoaderShow(false))
        console.log(err);
        notify(
          "Error! Unable to fetch data. Please try after sometime.",
          "error"
        );
      });
  };

  return (
    <div className="col-sm-12 col-md-12 col-lg-12 parent-tab-width">
      <div className="control-pane">
        <div className="control-section tab-control-section">
          <TabComponent
            heightAdjustMode="Auto"
            cssClass="orientation-tab ll-custom-tab"
            selecting={TabChangeEvent}
            ref={(tab) => getTabEvent(tab)}
          >
            <TabItemsDirective>
              <TabItemDirective
                header={parentTab[0]}
                content={UserManagement}
              />

              <TabItemDirective
                header={parentTab[1]}
                content={RoleManagement}
              />

              <TabItemDirective header={parentTab[2]} content={AccessRequest} />
            </TabItemsDirective>
          </TabComponent>
        </div>
      </div>
    </div>
  );
};

export default AccessMgmt;
