import { forwardRef, useImperativeHandle, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../redux/store';
import ContentHeader from '../../common/ContentHeader/ContentHeader';
import Divider from '../../common/divider/Divider';
import { ReactComponent as Scan } from '../../../icons/Scan-qr.svg';
import ClosePalletAndOutBound from './closePalletAndOutBound';
import { ButtonComponent } from '@syncfusion/ej2-react-buttons';
import {
    authenticateSupervisor,
    getEndpoint,
    getListofValues,
    getLocalStorageItems,
    getPalletList,
    setLocalStorageItems,
} from '../utils';
import { EndPoints } from '../constants';
import {
    setIsMaxInboundBoxApproved,
    setIsMaxOutboundBoxApproved,
    setScanModeListReducer,
    setScanModeReducer,
    setScanSettings,
    setSubDispositionsByState,
} from '../scanReducer';
import notify from '../../../services/toasterService';
import { InputField } from '../../input-field/input-field';

interface IManager {
    show: boolean;
}

const ManagementApprovalModal = forwardRef((props: any, ref) => {
    const {
        setAddScreen,
        openPallets,
        setScanMode,
        updateSettings,
        resetFocusToUpc,
        unlabelledDisposition,
        unlabelledSubDisposition,
        updateUnlabeled,
        setIsisNOCredit,
    } = props;

    const [manageApproval, setManageApproval] = useState(true);
    const [reclaimCenter, setReclaimCenter] = useState('');
    const [scangroup, setScanGroup] = useState('');
    const [scanStation, setscanStation] = useState('');
    const [supervisorPassword, setSupervisorPassword] = useState('');
    const [pallets, setPallets] = useState<[{}]>([{}]);
    const [reclaimCentersList, setreclaimCentersList] = useState([]);
    const [scanGroupList, setScanGroupList] = useState([]);
    const [scanStationList, setScanStationList] = useState([]);
    const [scanStationListByGroup, setScanStationListByGroup] = useState('');
    const [isAuthenticated, setIsAuthenticated] = useState('');
    const [isOpenOutboundBox, setisOpenOutboundBox] = useState(false);
    const [outBoundBoxLabel, setOutBoundBoxLabel] = useState('');
    const [outBoundBoxError, setOutBoundBoxError] = useState('');
    const [supervisorPasswordError, setSupervisorPasswordError] =
        useState(false);
    const [errors, setErrors] = useState({
        reclaimCenterError: '',
        scanGroupError: '',
        scanStationError: '',
    });
    let reclaimCenterError = '';
    let scanGroupError = '';
    let scanStationError = '';
    let inboundBoxApproved = false;
    let outboundBoxApproved = false;
    const getIsMaxInboundBoxApproved = useSelector(
        (state: RootState) => state.scanReducer.isMaxInboundBoxApproved
    );
    const getIsMaxOutboundBoxApproved = useSelector(
        (state: RootState) => state.scanReducer.isMaxOutboundBoxApproved
    );
    useImperativeHandle(ref, () => ({
        clearForm() {
            console.log('clearing fields...');
            resetReclaimCenter();
            if (props?.resetScanMode) {
                props.resetScanMode(props.isFrom);
            }
        },
    }));
    const getScanSettings = useSelector(
        (state: RootState) => state.scanReducer.scanSettings
    );
    const getScanModeList = useSelector(
        (state: RootState) => state.scanReducer.scanModeList
    );
    const dispatch = useDispatch();
    const resetFrom = (e: any) => {
        if (e) {
            handleCancel();
        }
    };

    const handleOutBoundBox = (value: any) => {
        setOutBoundBoxLabel(value);
    };

    const handleCancel = () => {
        setAddScreen(false);
        if (resetFocusToUpc) {
            resetFocusToUpc();
        }
        reset();
        setManageApproval(true);
        setSupervisorPasswordError(false);
        setIsAuthenticated('');
        if (props.isFrom === 'nocredit' || props.isFrom === 'audit') {
            if (props.isFrom === 'audit') {
               //dispatch(setScanModeListReducer(''));
            }
            updateSettings();
            // setScanMode('box');
            // dispatch(setScanModeReducer('box'));
        }
        if (props.isFrom === 'scansettings') {
            resetReclaimCenter();
        } else {
            setAddScreen(false);
            if (resetFocusToUpc) {
                resetFocusToUpc();
            }
        }
    };
    const handleAuditMode = (val: any) => {
        if (val === 'audit_outbound') {
            const endpoint: any = getEndpoint(
                EndPoints.OUTBOUNDAUDIT,
                outBoundBoxLabel
            );
            const result = getListofValues(endpoint);
            result
                .then((response: any) => {
                    resetFocusToUpc();
                    if (response) {
                        props.outBoundBoxLabel(outBoundBoxLabel);
                        setAddScreen(false);
                        setScanMode(val);
                        dispatch(setScanModeReducer(val));
                        setManageApproval(true);
                        notify('audit', 'auditmode');
                        setOutBoundBoxError('');
                    }
                })
                .catch((error: any) => {
                    if (error.response.status === 404) {
                        setOutBoundBoxError(error.response.data.message);
                        //notify(error.response.data.message, "error");
                    }
                });
        } else {
            setAddScreen(false);
            resetFocusToUpc();
            setScanMode(val);
            // dispatch(setScanModeReducer(val));
            // setManageApproval(true);
            notify('audit', 'auditmode');
        }
    };
    const handleContinue = () => {
        if (supervisorPassword == '') {
            setSupervisorPasswordError(true);
            setIsAuthenticated('');
        } else {
            if (props.isFrom === 'pallets') {
                getSupervisorApproval()
                    .then((response: any) => {
                        console.log(response);
                        if (response.data.resultStatus) {
                            setIsAuthenticated('true');
                            handleGetPalletList();
                            setManageApproval(false);
                            setSupervisorPassword('');
                            setSupervisorPasswordError(false);
                            //props.openPallets();
                        } else {
                            setIsAuthenticated('false');
                            setSupervisorPasswordError(false);
                        }
                    })
                    .catch((error: any) => {
                        if (error.response) {
                            notify(error, 'error');
                        }
                    });
            }
            if (props.isFrom === 'nocredit') {
                getSupervisorApproval()
                    .then((response: any) => {
                        resetFocusToUpc();
                        if (response.data.resultStatus) {
                            setIsAuthenticated('true');
                            setIsisNOCredit(true);
                            setScanMode('nocredit');
                            setAddScreen(false);
                            setSupervisorPassword('');
                            setSupervisorPasswordError(false);
                            dispatch(setScanModeReducer('nocredit'));
                            notify('nocredit', 'nocreditmode');
                        } else {
                            setIsAuthenticated('false');
                            setSupervisorPasswordError(false);
                        }
                    })
                    .catch((error: any) => {
                        notify(error, 'error');
                    });
            }
            if (props.isFrom === 'audit') {
                getSupervisorApproval()
                    .then((response: any) => {
                        console.log(response);
                        if (response.data.resultStatus) {
                            setScanMode('audit');
                            setIsAuthenticated('true');
                            setManageApproval(false);
                            setisOpenOutboundBox(false);
                            setSupervisorPassword('');
                            setOutBoundBoxLabel('');
                            setSupervisorPasswordError(false);
                            dispatch(setScanModeReducer('audit'));
                            props.openAuditMode();
                        } else {
                            setIsAuthenticated('false');
                            setSupervisorPasswordError(false);
                        }
                    })
                    .catch((error: any) => {
                        //notify(error.response, "error");
                    });
            }
            if (props.isFrom === 'scansettings') {
                getSupervisorApproval()
                    .then((response: any) => {
                        console.log(response);
                        if (response.data.resultStatus) {
                            setIsAuthenticated('true');
                            setManageApproval(false);
                            getReclaimCentersList();
                            setSupervisorPassword('');
                            setSupervisorPasswordError(false);
                        } else {
                            setIsAuthenticated('false');
                            setSupervisorPasswordError(false);
                        }
                    })
                    .catch((error: any) => {
                        notify(error, 'error');
                    });
            }

            if (props.isFrom === 'underreview') {
                getSupervisorApproval()
                    .then((response: any) => {
                        console.log(response);
                        if (response.data.resultStatus) {
                            setIsAuthenticated('true');
                            setManageApproval(true);
                            setSupervisorPassword('');
                            setSupervisorPasswordError(false);
                            props.openInboundBoxAfterApproval(
                                props.underReviewObj
                            );
                        } else {
                            setIsAuthenticated('false');
                            setSupervisorPasswordError(false);
                        }
                    })
                    .catch((error: any) => {
                        notify(error, 'error');
                    });
            }

            if (
                props.isFrom === 'OutboundMaxQty' ||
                props.isFrom === 'unlabeled' ||
                props.isFrom === '13d' ||
                props.isFrom === 'NF'
            ) {
                getSupervisorApproval()
                    .then((response: any) => {
                        console.log(response);
                        if (response.data.resultStatus) {
                            setAddScreen(false);
                            if (props.isFromInboundMaxQty === true) {
                                dispatch(setIsMaxInboundBoxApproved(true));
                                inboundBoxApproved = true;
                            }
                            if (props.isFromOutboundMaxQty === true) {
                                dispatch(setIsMaxOutboundBoxApproved(true));
                                outboundBoxApproved = true;
                            }
                            if (resetFocusToUpc) {
                                resetFocusToUpc();
                            }
                            setSupervisorPassword('');
                            setSupervisorPasswordError(false);
                            if (props.isFrom === 'OutboundMaxQty') {
                                props.handleOutboundMaxScanQty(
                                    inboundBoxApproved,
                                    outboundBoxApproved
                                );
                            } else {
                                props.updateUnlabeled(
                                    unlabelledSubDisposition,
                                    unlabelledDisposition,
                                    props.isFrom,
                                    inboundBoxApproved,
                                    outboundBoxApproved
                                );
                            }
                        } else {
                            setIsAuthenticated('false');
                            if (resetFocusToUpc) {
                                resetFocusToUpc();
                            }
                            setSupervisorPassword('');
                            setSupervisorPasswordError(false);
                        }
                    })
                    .catch((error: any) => {
                        notify(error, 'error');
                    });
            }
            if (props.isFrom === 'InboundMaxQty') {
                getSupervisorApproval()
                    .then((response: any) => {
                        if (response.data.resultStatus) {
                            setAddScreen(false);
                            if (props.isFromInboundMaxQty === true) {
                                dispatch(setIsMaxInboundBoxApproved(true));
                                inboundBoxApproved = true;
                            }
                            resetFocusToUpc();
                            setSupervisorPassword('');
                            setSupervisorPasswordError(false);
                            props.handleInboundMaxScanQty(
                                inboundBoxApproved,
                                outboundBoxApproved
                            );
                        } else {
                            // setAddScreen(false);
                            resetFocusToUpc();
                            setSupervisorPassword('');
                            setIsAuthenticated('false');
                            setSupervisorPasswordError(false);
                        }
                    })
                    .catch((error: any) => {
                        notify(error, 'error');
                    });
            }
        }
    };

    const getSupervisorApproval = () => {
        let endPoint: any;
        const requestPayload = {
            scanUser: {
                supervisorPassword: supervisorPassword,
            },
        };
        endPoint = getEndpoint(EndPoints.ACCESSSUPERVISOR);
        const result = authenticateSupervisor(endPoint, requestPayload);
        return result;
    };
    const getReclaimCentersList = () => {
        const endpoint: any = getEndpoint(EndPoints.RECLAIMCENTERSLIST);
        const result = getListofValues(endpoint);
        result.then((data: any) => {
            // notify();
            console.log(data.data);
            setreclaimCentersList(data.data);
        });
    };

    const getSubdispositionsByState = () => {
        const reclaimCenterId = getLocalStorageItems('reclaimCenterId');
        const endpoint: any = getEndpoint(
            EndPoints.SUBDISPOSITIONS,
            reclaimCenterId
        );
        const result = getListofValues(endpoint);
        result.then((data: any) => {
            dispatch(setSubDispositionsByState(data.data));
        });
    };
    const handleSubmit = (e: any) => {
        // e.preventDefault();
        if (validate()) {
            const reclaimCenterName = reclaimCenter.split(':');
            let reclaimCenterState = '';
            reclaimCentersList.forEach((center: any) => {
                if (
                    center?.reclaimcenterName?.toLowerCase() ===
                    reclaimCenterName[0]?.toLowerCase()
                )
                    reclaimCenterState = center.address[0]?.state;
            });
            const scanSettingObj = {
                reclaimCenter: reclaimCenterName[0],
                scangroup: scangroup,
                scanStation: scanStationListByGroup,
                reclaimCenterState: reclaimCenterState,
            };
            setLocalStorageItems('scanSettings', scanSettingObj);
            setLocalStorageItems('reclaimCenterId', reclaimCenterName[1]);
            dispatch(setScanSettings(getLocalStorageItems('scanSettings')));
            updateSettings();
            resetReclaimCenter();
            setAddScreen(false);
            getSubdispositionsByState();
            resetFocusToUpc();
        }
    };

    const handleReclaimCenter = (e: any) => {
        setReclaimCenter(e.target.value);
        getScanGroupAndScanStationByReclaim(e.target.value);
    };

    const handleScanGroup = (e: any) => {
        setScanGroup(e);
        scanGroupList.filter((item: any) => {
            if (e === item.scanGroupCode) {
                setScanStationList(item.scanStation);
                console.log(scanStationList);
            }
        });
    };

    const validate = () => {
        if (reclaimCenter === '') {
            reclaimCenterError = 'Reclaim Center is required';
        }
        if (scangroup === '') {
            scanGroupError = 'Scan Group is required';
        }
        if (scanStationListByGroup === '') {
            scanStationError = 'Scan Station is required';
        }
        if (reclaimCenterError || scanGroupError || scanStationError) {
            setErrors({ reclaimCenterError, scanGroupError, scanStationError });
            return false;
        } else {
            return true;
        }
    };
    const resetReclaimCenter = () => {
        setReclaimCenter('');
        setScanGroup('');
        setscanStation('');
        setErrors({
            reclaimCenterError: '',
            scanGroupError: '',
            scanStationError: '',
        });
        setManageApproval(true);
        reset();
    };
    const handleGetPalletList = () => {
        const requestPayload = {
            scanGroup: getScanSettings.payload.scangroup,
            scanStation: getScanSettings.payload.scanStation,
            reclaimCenter: getScanSettings.payload.reclaimCenter,
        };
        const endpoint: any = getEndpoint(EndPoints.PALLETLIST);

        const result = getPalletList(endpoint, requestPayload);
        result.then((data: any) => {
            console.log(data.data.data);
            setPallets(data.data.data);
            // addToast('Closed OutboundBox Successfully', { appearance: 'success' });
        });
    };

    const reset = () => {
        setSupervisorPassword('');
        setOutBoundBoxError('');
    };

    const getScanGroupAndScanStationByReclaim = (reclaimCenterId: any) => {
        if (reclaimCenterId) {
            const rc = reclaimCenterId.split(':');
            const endpoint: any = getEndpoint(EndPoints.SCANSETTINGS, rc[1]);
            console.log(endpoint);
            const result = getListofValues(endpoint);
            result.then((data: any) => {
                setScanGroupList(data.data.scanGroups);
            });
        }
    };
    //prevent text
    /*check input format function */
    const formatInput = (e: any) => {
        // Prevent characters that are not numbers ("e", ".", "+" & "-") ✨
        let checkIfNum;
        if (e.key !== undefined) {
            // Check if it's a "e", ".", "+" or "-"
            checkIfNum =
                e.key === 'e' ||
                e.key === '.' ||
                e.key === '+' ||
                e.key === '-';
        } else if (e.keyCode !== undefined) {
            // Check if it's a "e" (69), "." (190), "+" (187) or "-" (189)
            checkIfNum =
                e.keyCode === 69 ||
                e.keyCode === 190 ||
                e.keyCode === 187 ||
                e.keyCode === 189;
        }
        return checkIfNum && e.preventDefault();
    };
    const submitAuditOutboundBox = () => {
        handleAuditMode('audit_outbound');
    };

    return (
        <>
            <ContentHeader value={props.heading} />
            <Divider />
            <div>
                {manageApproval === true ? (
                    <div className="col-sm-12 p5">
                        {props.isFrom === 'underreview' ? (
                            <div className="col-sm-12 store-under">
                                <b>Store:</b> {props.underReviewObj.storeNum}{' '}
                                <br />
                                <b>Reason:</b> {props.underReviewObj.reasonCode}
                            </div>
                        ) : (
                            ''
                        )}
                        <div className="col-sm-4">
                            <div className="text-center fb-15">
                                Scan Management Password
                            </div>
                            <Scan
                                width={83}
                                height={83}
                                className="scan-inbound m6"
                            />
                        </div>
                        <div className="col-sm-8">
                            <div className="text-center fb-15 pl-4 pwdtxt">
                                Enter Management Password
                            </div>
                            <div className="col-sm-3"></div>
                            <div className="col-sm-9 p5">
                                <InputField
                                    id="approval-password"
                                    type="password"
                                    placeholder="Password"
                                    className="form-control"
                                    onChange={setSupervisorPassword}
                                    onKeyDown={formatInput}
                                    initialValue={supervisorPassword}
                                    onEnter={handleContinue}
                                />
                                {isAuthenticated === 'false' ? (
                                    <span className="text-danger">
                                        Invalid Passcode
                                    </span>
                                ) : (
                                    ''
                                )}
                                {supervisorPasswordError ? (
                                    <span className="text-danger">
                                        Password Required
                                    </span>
                                ) : (
                                    ''
                                )}
                            </div>
                        </div>
                        <div className="col-sm-9 mt-2rem">
                            <ButtonComponent
                                disabled={supervisorPassword === ''}
                                cssClass="eg-btn-primary float-right btnBox"
                                onClick={(e) => handleContinue()}
                            >
                                Continue
                            </ButtonComponent>
                            <ButtonComponent
                                cssClass="eg-btn-secondary float-right mr-4"
                                onClick={() => handleCancel()}
                            >
                                CANCEL
                            </ButtonComponent>
                        </div>
                    </div>
                ) : (
                    <>
                        {props.isFrom === 'pallets' ? (
                            <ClosePalletAndOutBound
                                heading={''}
                                key1={{
                                    header: 'PALLET ID',
                                    field: 'palletId',
                                }}
                                key2={{
                                    header: 'WAREHOUSE LOCATION',
                                    field: 'warehouseLocation',
                                }}
                                value={pallets}
                                setAddScreen={setAddScreen}
                                isFrom={props.isFrom}
                                reset={resetFrom}
                                resetFocusToUpc={resetFocusToUpc}
                            />
                        ) : props.isFrom === 'scansettings' ? (
                            <>
                                {/* <form onSubmit={handleSubmit}> */}
                                <div className="col-sm-1"></div>
                                <div className="col-sm-8">
                                    <div className="form-group p5">
                                        <label className="control-label col-sm-4">
                                            Reclaim Center #
                                        </label>
                                        <div className="col-sm-8">
                                            <select
                                                className="form-control"
                                                id="txtStore"
                                                onChange={(e) =>
                                                    handleReclaimCenter(e)
                                                }
                                            >
                                                <option value="">Select</option>
                                                {reclaimCentersList?.filter((center:any) => center.active)
                                                .map(
                                                    (rc: any) => (
                                                        <option
                                                            value={
                                                                rc?.reclaimcenterName +
                                                                ':' +
                                                                rc?.id
                                                            }
                                                        >
                                                            {
                                                                rc?.reclaimcenterName
                                                            }
                                                        </option>
                                                    )
                                                )}
                                            </select>
                                            {reclaimCenter === '' ? (
                                                <span className="text-danger">
                                                    {errors.reclaimCenterError}
                                                </span>
                                            ) : (
                                                ''
                                            )}
                                        </div>
                                    </div>
                                    <div className="form-group p5">
                                        <label className="control-label col-sm-4">
                                            Scan Group #
                                        </label>
                                        <div className="col-sm-8">
                                            <select
                                                className="form-control"
                                                id="txtStore"
                                                onChange={(e: any) =>
                                                    handleScanGroup(
                                                        e.target.value
                                                    )
                                                }
                                            >
                                                <option value="">Select</option>
                                                {scanGroupList?.map(
                                                    (sg: any) => (
                                                        <option
                                                            value={
                                                                sg?.scanGroupCode
                                                            }
                                                        >
                                                            {sg?.scanGroupCode}
                                                        </option>
                                                    )
                                                )}
                                            </select>

                                            {scanStation === '' ? (
                                                <span className="text-danger">
                                                    {errors.scanGroupError}
                                                </span>
                                            ) : (
                                                ''
                                            )}
                                        </div>
                                    </div>
                                    <div className="form-group p2">
                                        <label className="control-label col-sm-4">
                                            Scan Station #
                                        </label>
                                        <div className="col-sm-8">
                                            <select
                                                className="form-control"
                                                id="txtStore"
                                                onChange={(e: any) =>
                                                    setScanStationListByGroup(
                                                        e.target.value
                                                    )
                                                }
                                            >
                                                <option value="">Select</option>
                                                {scanStationList?.map(
                                                    (ss: any) => (
                                                        <option value={ss}>
                                                            {ss}
                                                        </option>
                                                    )
                                                )}
                                            </select>
                                            {scanStation === '' ? (
                                                <span className="text-danger">
                                                    {errors.scanStationError}
                                                </span>
                                            ) : (
                                                ''
                                            )}
                                        </div>
                                    </div>
                                </div>
                                {/* <div className="">
            <ButtonComponent cssClass="p-15 btn btn-outline-primary" onClick={handleCancel}>
              CANCEL
            </ButtonComponent>
           <button className="btn btn-primary" type="submit">Ok</button>
          </div> */}
                                <div className="col-sm-7 ml-5 mt-2rem">
                                    <button
                                        className="eg-btn-primary float-right btnBox"
                                        onClick={(e) => handleSubmit(e)}
                                    >
                                        Continue
                                    </button>
                                    <button
                                        className="eg-btn-secondary float-right mr-4"
                                        onClick={() => handleCancel()}
                                    >
                                        CANCEL
                                    </button>
                                </div>
                                {/* </form> */}
                            </>
                        ) : (
                            <>
                                {props.isFrom === 'audit' &&
                                isOpenOutboundBox === false ? (
                                    <div className="col-sm-12 p5">
                                        <h5 className="text-center fb-15">
                                            Select Audit Mode
                                        </h5>
                                        <div className="col-sm-7 col-sm-offset-3">
                                            <div className="col-sm-5">
                                                <button
                                                    className="btn btn-primary inbound-box"
                                                    onClick={(e) =>
                                                        handleAuditMode(
                                                            'audit_inbound'
                                                        )
                                                    }
                                                >
                                                    Inbound
                                                </button>
                                            </div>
                                            <div className="col-sm-5">
                                                <button
                                                    className="btn btn-primary inbound-box"
                                                    onClick={(e) =>
                                                        setisOpenOutboundBox(
                                                            true
                                                        )
                                                    }
                                                >
                                                    Outbound
                                                </button>
                                            </div>
                                        </div>

                                        <ButtonComponent
                                            cssClass="eg-btn-secondary float-right mr-4 mt-5"
                                            onClick={() => handleCancel()}
                                        >
                                            CANCEL
                                        </ButtonComponent>
                                    </div>
                                ) : (
                                    <>
                                        <div>
                                            <div className="col-sm-12 p5">
                                                <div className="col-sm-4 p-0">
                                                    <div className="text-center fb-15 popupName">
                                                        Scan Outbound Box/Bin
                                                        Label
                                                    </div>
                                                    <Scan
                                                        width={83}
                                                        height={83}
                                                        className="scan-inbound m6"
                                                    />
                                                </div>
                                                <div className="col-sm-8">
                                                    <div className="text-center fb-15 inbound-box-heading popupNamae">
                                                        Enter Outbound Box/Bin
                                                        Details
                                                    </div>
                                                    <div className="form-group p5">
                                                        <label className="control-label col-sm-4">
                                                            Box / Bin #
                                                        </label>
                                                        <div className="col-sm-8">
                                                            <InputField
                                                                id="audit-outbound-box"
                                                                type="text"
                                                                className="form-control"
                                                                initialValue={
                                                                    outBoundBoxLabel
                                                                }
                                                                onChange={
                                                                    handleOutBoundBox
                                                                }
                                                                onEnter={
                                                                    submitAuditOutboundBox
                                                                }
                                                            />
                                                            {outBoundBoxError !==
                                                            '' ? (
                                                                <span className="text-danger">
                                                                    {
                                                                        outBoundBoxError
                                                                    }
                                                                </span>
                                                            ) : (
                                                                ''
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-sm-9 mt-2rem">
                                                <ButtonComponent
                                                    disabled={
                                                        outBoundBoxLabel === ''
                                                    }
                                                    cssClass="eg-btn-primary float-right btnBox"
                                                    onClick={(e) =>
                                                        handleAuditMode(
                                                            'audit_outbound'
                                                        )
                                                    }
                                                >
                                                    Open Box
                                                </ButtonComponent>
                                                <button
                                                    className="eg-btn-secondary float-right mr-4"
                                                    onClick={() =>
                                                        handleCancel()
                                                    }
                                                >
                                                    CANCEL
                                                </button>
                                            </div>
                                        </div>
                                    </>
                                )}
                            </>
                        )}
                    </>
                )}
            </div>
        </>
    );
});

export default ManagementApprovalModal;
