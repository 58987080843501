import axios from 'axios';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import { setCurrentContact, setPalletName } from './contactReducer';
import saveIcon from '../../assets/saveIcon.png';
import saveIconDisable from '../../assets/iconSave.png';
import {
    getEndPoint,
    getScanGroupByRC,
    updateMasterDataEndPoint,
} from './utils';
import { ContactType } from './constants';
import notify from '../../services/toasterService';

const PalletLocationViewScreen = (props: any) => {
    const [rowInfo, setRowInfo] = useState<any[]>([]);
    const [selectedImage, setSelectedImage] = useState();
    const [palletNamePL, setPalletNamePL] = useState('');
    const [reclaimCenterPL, setReclaimCenterPL] = useState('');
    const [scanGroupPL, setscanGroupPL] = useState('');
    const [positionPL, setPositionPL] = useState('');
    const [finalePrice, setFinalPrice] = useState('');
    const [binfinalePrice, binsetFinalPrice] = useState('');
    const [locationEnabledPL, setLocationEnabledPL] = useState(Boolean);
    const [pricingTypeSymbal, setPricingTypeSymbal] = useState('');
    const [scanGroupByRc, setScanGroupByRc] = useState<any[]>([]);
    const [newSGList, setNewSGList] = useState(false);
    const [btnStatus, setBtnStatus] = useState(true);
    const [binbtnStatus, binsetBtnStatus] = useState(true);
    const { backPressPallet } = props;
    const [errors, setErrors] = useState({
        palletNameError: '',
        whseLocError: '',
        rcError: '',
        scanGroupError: '',
        pricingTypeError: '',
        priceError: '',
        binpriceError: '',
        positionError: '',
        locationEnabldError: '',
    });
    let palletNameError = '';
    let whseLocError = '';
    let rcError = '';
    let scanGroupError = '';
    let pricingTypeError = '';
    let priceError = '';
    let binpriceError = '';
    let positionError = '';
    let locationEnabldError = '';
    const dispatch = useDispatch();
    const setRowData = useSelector(
        (state: RootState) => state.gridReducer?.selectedRowData
    );
    const setRowId = useSelector(
        (state: RootState) => state.gridReducer?.selectedRowDataId
    );
    const palletNameData = useSelector(
        (state: RootState) => state.contactReducer?.palletNameMasterData
    );
    const RCData = useSelector(
        (state: RootState) => state.contactReducer?.reclaimCenters
    );
    const sacnGroupData = useSelector(
        (state: RootState) => state.contactReducer?.scanGroupMasterData
    );
    const whLocationsInfo = useSelector(
        (state: RootState) => state.contactReducer?.WhareHouseLocationData
    );
    const pricingTypeData = useSelector(
        (state: RootState) => state.contactReducer?.pricingTypeMasterData
    );
    useEffect(() => {
        if (setRowData && setRowData?.length > 0) {
            setRowInfo(
                setRowData.filter((item) =>
                    item.id && setRowId ? item.id === setRowId.id : ''
                )
            );
            dispatch(setPalletName(setRowData[0].palletName));
            const getSgEndpoint = getScanGroupByRC(
                setRowData[0]?.reclaimCenter?.id
            );
            hadleGetScanGroupData(
                setRowData[0]?.reclaimCenter?.id,
                getSgEndpoint
            );
            if (
                setRowData[0].pricingType === 'Fixed price' ||
                setRowData[0].pricingType === 'User Set'
            ) {
                setPricingTypeSymbal('$');
            } else {
                setPricingTypeSymbal('%');
            }
        }
    }, [setRowData, setRowId]);

    const handleAddPLPalletName = (e: any) => {
        setRowInfo([{ ...rowInfo[0], palletName: e.target.value }]);
    };
    const handleAddPLRC = (e: any) => {
        const getEndpoint = getScanGroupByRC(e.target.value);
        hadleGetScanGroupData(e.target.value, getEndpoint);
        setRowInfo([{ ...rowInfo[0], reclaimCenterId: e.target.value }]);
    };
    const hadleGetScanGroupData = async (val: any, endpoint: string) => {
        const result = await axios.get(endpoint, val);
        if (result && result.data) {
            const SGinfo = JSON.parse(JSON.stringify(result.data));
            setScanGroupByRc(SGinfo);
            console.log('scanGroupByRc', scanGroupByRc);
            setNewSGList(true);
        } else {
            setNewSGList(false);
            setScanGroupByRc([]);
        }
    };
    const handleAddPLSacnGroup = (e: any) => {
        setRowInfo([{ ...rowInfo[0], scanGroupId: e.target.value }]);
        //setscanGroupPL(e.target.value);
    };
    const handleAddPLPosition = (e: any) => {
        setRowInfo([{ ...rowInfo[0], position: e.target.value }]);
        //setPositionPL(e.target.value);
    };
    const handleChangeHasPallet = (e: any) => {
        if (e.target.value === 'true') {
            setRowInfo([{ ...rowInfo[0], pallet: true }]);
        } else {
            setRowInfo([{ ...rowInfo[0], pallet: false }]);
        }
    };
    const handleChangeLocationEnabled = (e: any) => {
        if (e.target.value === 'true') {
            setRowInfo([{ ...rowInfo[0], locationEnabled: true }]);
        } else {
            setRowInfo([{ ...rowInfo[0], locationEnabled: false }]);
        }
    };
    const handleWhseLocation = (e: any) => {
        setRowInfo([{ ...rowInfo[0], whLocationId: e.target.value }]);
    };
    const handlePricingType = (e: any) => {
        if (
            e.target.value === 'Fixed price' ||
            e.target.value === 'User Set' ||
            e.target.value === '0'
        ) {
            setPricingTypeSymbal('$');
            setRowInfo([{ ...rowInfo[0], pricingType: e.target.value }]);
        } else {
            setPricingTypeSymbal('%');
            setRowInfo([{ ...rowInfo[0], pricingType: e.target.value }]);
        }
    };
    const handleChagePrice = (e: any) => {
        //setRowInfo([{ ...rowInfo[0], price: parseFloat(e.target.value) }])
        setBtnStatus(false);
        if (e.target.value === '') {
            priceError = 'Required ';
            setBtnStatus(false);
        }
        var Cname = document.getElementById(
            'ddlPricingType'
        ) as HTMLInputElement;
        if (Cname.value === 'Percent') {
            if (parseInt(e.target.value) > 100) {
                console.log(e.target.value);
                var Cname = document.getElementById(
                    'txtPrice'
                ) as HTMLInputElement;
                Cname.value = '';
                notify('Percent should not exceed 100%', 'error');
            }
        }
        // var regexFax = /^[0-9]*(?:\.\d{1,2})?$/;
        // if( !regexFax.test(e.target.value) ) {
        //   priceError = "Invalid ";
        //   setBtnStatus(false);
        // }
        // else{
        //   setFinalPrice(e.target.value);

        // }
        if (priceError) {
            setErrors({
                palletNameError,
                whseLocError,
                rcError,
                scanGroupError,
                pricingTypeError,
                priceError,
                binpriceError,
                positionError,
                locationEnabldError,
            });
            return false;
        } else {
            if (e.target.value.includes('.') === true) {
                if (e.target.value.split('.')[1].length > 2) {
                    setFinalPrice(parseFloat(e.target.value).toFixed(2));
                    setRowInfo([
                        {
                            ...rowInfo[0],
                            price: parseFloat(e.target.value).toFixed(2),
                        },
                    ]);
                    //  setPrice(parseFloat(e.target.value).toFixed(2))
                    setBtnStatus(true);
                } else {
                    setFinalPrice(e.target.value);
                    setRowInfo([{ ...rowInfo[0], price: e.target.value }]);
                    setBtnStatus(true);
                }
            } else {
                setFinalPrice(e.target.value);
                setRowInfo([{ ...rowInfo[0], price: e.target.value }]);
                setBtnStatus(true);
            }
            return true;
        }
    };
    const handleChagebinPrice = (e: any) => {
        //setRowInfo([{ ...rowInfo[0], price: parseFloat(e.target.value) }])
        binsetBtnStatus(false);
        if (e.target.value === '') {
            binpriceError = 'Required ';
            binsetBtnStatus(false);
        }
        var Cname = document.getElementById(
            'ddlPricingType'
        ) as HTMLInputElement;
        if (Cname.value === 'Percent') {
            if (parseInt(e.target.value) > 100) {
                console.log(e.target.value);
                var Cname = document.getElementById(
                    'bintxtPrice'
                ) as HTMLInputElement;
                Cname.value = '';
                notify('Percent should not exceed 100%', 'error');
            }
        }
        if (binpriceError) {
            setErrors({
                palletNameError,
                whseLocError,
                rcError,
                scanGroupError,
                pricingTypeError,
                priceError,
                binpriceError,
                positionError,
                locationEnabldError,
            });
            return false;
        } else {
            if (e.target.value.includes('.') === true) {
                if (e.target.value.split('.')[1].length > 2) {
                    binsetFinalPrice(parseFloat(e.target.value).toFixed(2));
                    setRowInfo([
                        {
                            ...rowInfo[0],
                            binPrice: parseFloat(e.target.value).toFixed(2),
                        },
                    ]);
                    //  setPrice(parseFloat(e.target.value).toFixed(2))
                    binsetBtnStatus(true);
                } else {
                    binsetFinalPrice(e.target.value);
                    setRowInfo([{ ...rowInfo[0], binPrice: e.target.value }]);
                    binsetBtnStatus(true);
                }
            } else {
                binsetFinalPrice(e.target.value);
                setRowInfo([{ ...rowInfo[0], binPrice: e.target.value }]);
                binsetBtnStatus(true);
            }
            return true;
        }
    };
    let tabName: any = ContactType.PALLET_LOCATIONS;
    let body: any;
    const handleUpdatePallet = () => {
        var paltName = document.getElementById(
            'txtPalletName'
        ) as HTMLInputElement;
        var idForUpdate = document.getElementById('txtId') as HTMLInputElement;
        var scanGrp = document.getElementById(
            'ddlScanGroup'
        ) as HTMLSelectElement;
        var pricingTyp = document.getElementById(
            'ddlPricingType'
        ) as HTMLSelectElement;
        var prce = document.getElementById('txtPrice') as HTMLInputElement;
        var binprce = document.getElementById(
            'bintxtPrice'
        ) as HTMLInputElement;
        var WHloc = document.getElementById('ddlWHLoc') as HTMLSelectElement;
        var positn = document.getElementById('txtPosition') as HTMLInputElement;
        var RCCentr = document.getElementById(
            'ddlRecliamCenter'
        ) as HTMLSelectElement;
        var locEnabled = document.getElementById(
            'radioLocationEnabled'
        ) as HTMLInputElement;
        body = {
            id: parseInt(idForUpdate.value),
            palletName: paltName.value,
            scanGroupId: parseInt(scanGrp.value),
            pricingType: pricingTyp.value,
            price: parseFloat(prce.value),
            binPrice: parseFloat(binprce.value),
            whLocationId: parseInt(WHloc.value),
            position: positn.value,
            reclaimCenterId: parseInt(RCCentr.value),
            locationEnabled: locEnabled.checked,
        };
        if (paltName.value === '') {
            notify('Name is required');
        } else if (scanGrp.value === '') {
            notify('Scan group is required');
        } else if (pricingTyp.value === '') {
            notify('Pricing type is required');
        } else if (prce.value === '') {
            notify('Required');
        } else if (
            pricingTyp.value === 'Percent' &&
            parseInt(prce.value) > 100
        ) {
            notify(`Percent should not exceed 100`, 'warning');
        } else if (WHloc.value === '') {
            notify('WH Location is required');
        } else if (positn.value === '') {
            notify('Position is required');
        } else if (RCCentr.value === '') {
            notify('Reclaim center is required');
        } else {
            const endpoint = updateMasterDataEndPoint(tabName, rowInfo);
            const getEndpoint = getEndPoint(tabName);
            handleMasterDataPalletUpdate(endpoint, getEndpoint);
        }
    };
    const handleMasterDataPalletUpdate = async (
        endpoint: string,
        getEndpoint: string
    ) => {
        //const rowInfoObject = Object.assign({}, ...rowInfo);
        //const result = await axios.put(endpoint, rowInfoObject);
        // if (result && result.data) {
        //   notify('Pallet Location updated successfully', 'success');
        //   backPressPallet();
        //   const getresult = await axios.get(getEndpoint);
        //   dispatch(setCurrentContact(getresult.data));
        // } else {
        //   notify('Failed to update Pallet Location', 'error');
        // }
        const result = await axios
            .put(endpoint, body)
            .then((results: any) => {
                if (results && results.data) {
                    notify('Updated successfully', 'success');
                    backPressPallet();
                } else {
                    notify('Failed to update', 'error');
                }
            })
            .catch((error: any) => {
                if (error.response) {
                    notify(error.response?.data?.message, 'error');
                }
            });
        const getresult = await axios.get(getEndpoint);
        dispatch(setCurrentContact(getresult.data));
    };
    return (
        <>
            {rowInfo &&
                rowInfo.map((info) => (
                    <>
                        <hr className="horizontalLineBarInPalletView" />

                        <div className="row">
                            <div className="col-sm-11 pr-0">
                                <span
                                    className="backToAllText"
                                    onClick={backPressPallet}
                                >
                                    {'<'} Back to all pallet locations
                                </span>
                            </div>
                            <div className="col-sm-1 pl-0 palletLocationSaveIcon">
                                {btnStatus ? (
                                    <img
                                        className="cursor-pointer btnPalletUpdate"
                                        src={saveIcon}
                                        onClick={() => handleUpdatePallet()}
                                        style={{ float: 'right' }}
                                    />
                                ) : (
                                    <img
                                        className="cursor-pointer btnPalletUpdate"
                                        src={saveIconDisable}
                                    />
                                )}
                            </div>
                        </div>
                        <div className="row mt-1rem">
                            <div className="col-sm-12 p-0">
                                <div className="col-sm-3 pl-0">
                                    <div className="col-sm-12">
                                        <span
                                            className="lblClass fontWeight-Bold"
                                            style={{ float: 'left' }}
                                        >
                                            Pallet Name
                                        </span>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="txtPalletName"
                                            value={info.palletName}
                                            onChange={(e) =>
                                                handleAddPLPalletName(e)
                                            }
                                        />
                                        <input
                                            type="hidden"
                                            className="form-control"
                                            id="txtId"
                                            value={info.id}
                                        />
                                    </div>
                                    <div className="col-sm-12">
                                        <span
                                            className="lblClass fontWeight-Bold"
                                            style={{ float: 'left' }}
                                        >
                                            Whse Location
                                        </span>
                                        <select
                                            className="form-control"
                                            id="ddlWHLoc"
                                            onChange={(e: any) =>
                                                handleWhseLocation(e)
                                            }
                                            defaultValue={info.whLocation.id}
                                        >
                                            {/* <option value={info.whLocation.id}>
                        {info.whLocation.warehouseLocation}
                      </option> */}
                                            {whLocationsInfo?.map((rc) => (
                                                <option value={rc.id}>
                                                    {rc.warehouseLocation}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                </div>

                                <div className="col-sm-3">
                                    <div className="col-sm-12">
                                        <span
                                            className="lblClass fontWeight-Bold"
                                            style={{ float: 'left' }}
                                        >
                                            Reclaim Center
                                        </span>
                                        <select
                                            className="form-control"
                                            id="ddlRecliamCenter"
                                            onChange={(e) => handleAddPLRC(e)}
                                            defaultValue={info.reclaimCenter.id}
                                        >
                                            {/* <option hidden>
                        {info.reclaimCenter.reclaimcenterName}
                      </option> */}
                                            {RCData?.map((rc) => (
                                                <option value={rc.id}>
                                                    {rc.reclaimcenterName}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                    <div className="col-sm-12">
                                        <span
                                            className="lblClass fontWeight-Bold"
                                            style={{ float: 'left' }}
                                        >
                                            Scan Group
                                        </span>
                                        {/* {newSGList? */}
                                        <select
                                            className="form-control"
                                            id="ddlScanGroup"
                                            onChange={(e: any) =>
                                                handleAddPLSacnGroup(e)
                                            }
                                        >
                                            <option value={info.scanGroup.id}>
                                                {info.scanGroup.scanGroupCode}{' '}
                                                {
                                                    info.scanGroup
                                                        .scanGroupDescription
                                                }
                                            </option>
                                            {scanGroupByRc?.map((sg) => (
                                                <option value={sg.id}>
                                                    {sg.scanGroupCode}{' '}
                                                    {sg.scanGroupDescription}
                                                </option>
                                            ))}
                                        </select>

                                        {/* :
<select className="form-control" id="ddlScanGroup" onChange={(e: any) => handleAddPLSacnGroup(e)}>
<option value={info.scanGroup.id}>{info.scanGroup.scanGroupCode} {info.scanGroup.scanGroupDescription}</option>
</select>
} */}
                                    </div>
                                </div>
                                <div className="col-sm-3">
                                    <div className="col-sm-12">
                                        <span
                                            className="lblClass fontWeight-Bold"
                                            style={{ float: 'left' }}
                                        >
                                            Pricing Type
                                        </span>
                                        <select
                                            className="form-control"
                                            id="ddlPricingType"
                                            onChange={handlePricingType}
                                            value={info.pricingType}
                                        >
                                            {/* <option value={info.pricingType}>{info.pricingType}</option> */}
                                            {pricingTypeData?.map((pt) => (
                                                <option value={pt.pricingType}>
                                                    {pt.pricingType}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                    <div className="col-sm-12">
                                        <div className="col-sm-6 p-1">
                                            <span
                                                className="lblClass fontWeight-Bold"
                                                style={{ float: 'left' }}
                                            >
                                                Box Rate{pricingTypeSymbal}
                                            </span>
                                            <input
                                                type="number"
                                                className="form-control"
                                                id="txtPrice"
                                                onChange={(e) =>
                                                    handleChagePrice(e)
                                                }
                                                value={info.price}
                                            />
                                            {finalePrice === '' ? (
                                                <span className="text-danger fontSize-11">
                                                    {errors.priceError}
                                                </span>
                                            ) : (
                                                ''
                                            )}
                                        </div>
                                        <div className="col-sm-6 p-1">
                                            <span
                                                className="lblClass fontWeight-Bold"
                                                style={{ float: 'left' }}
                                            >
                                                Bin Rate{pricingTypeSymbal}
                                            </span>
                                            <input
                                                type="number"
                                                className="form-control"
                                                id="bintxtPrice"
                                                onChange={(e) =>
                                                    handleChagebinPrice(e)
                                                }
                                                value={info.binPrice}
                                            />
                                            {binfinalePrice === '' ? (
                                                <span className="text-danger fontSize-11">
                                                    {errors.binpriceError}
                                                </span>
                                            ) : (
                                                ''
                                            )}
                                        </div>
                                    </div>
                                </div>

                                <div className="col-sm-3 pr-0">
                                    <div className="col-sm-12">
                                        <span
                                            className="lblClass fontWeight-Bold"
                                            style={{ float: 'left' }}
                                        >
                                            Position
                                        </span>
                                        <input
                                            type="number"
                                            id="txtPosition"
                                            className="form-control"
                                            value={info.position}
                                            onChange={(e) =>
                                                handleAddPLPosition(e)
                                            }
                                        />
                                    </div>

                                    <div className="col-sm-12">
                                        <span
                                            className="lblClass fontWeight-Bold "
                                            style={{
                                                float: 'left',
                                                marginLeft: '0',
                                            }}
                                        >
                                            Location Enabled?
                                        </span>
                                    </div>
                                    <div
                                        className="col-sm-12"
                                        onChange={(e) =>
                                            handleChangeLocationEnabled(e)
                                        }
                                    >
                                        <input
                                            type="radio"
                                            value="true"
                                            id="radioLocationEnabled"
                                            name="locatioEnabled"
                                            checked={info.locationEnabled}
                                        />{' '}
                                        <span className="lblRadioText lblRatePalletView">
                                            {' '}
                                            Yes
                                        </span>
                                        <input
                                            type="radio"
                                            value="false"
                                            id="radioLocationEnabled"
                                            name="locatioEnabled"
                                            checked={!info.locationEnabled}
                                        />{' '}
                                        <span className="lblRadioText lblRatePalletView">
                                            {' '}
                                            No
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                ))}
        </>
    );
};
export default PalletLocationViewScreen;
