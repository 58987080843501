import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { addMasterDataEndPoint, generateGridData, getAllMasterData } from "./utils";
import axios from "axios";
import { ContactType } from "./constants";
import ContactsDetailspanel from "../ContactsDetailsPanel/ContactsDetailsPanel";
import Grid from "../grid/Grid";
import { config } from "../grid/config";
import LLModal from "../common/llModal/LLModal";
import { ButtonComponent } from "@syncfusion/ej2-react-buttons";
import userIcon from "../../assets/userIcon.png";
import notify from "../../services/toasterService";
import {
  setTabHeading,
  setTabNameWHLocation,
  setWHLocCustomData,
} from "./contactReducer";
import { ReactComponent as Add } from "../../icons/AddNew.svg";

const UpcCustomView = () => {
  const [nameCustom, setNameCustom] = useState("");
  const [whLocCustom, setWhLocCustom] = useState("");
  const [voiceCustom, setVoiceCustom] = useState("");
  const [maxBoxCountCustom, setMaxBoxCountCustom] = useState("");
  const [tabNameForWHLocations, setTabNameForWHLocations] = useState("");
  const [showModelCustom, setShowModelCustom] = useState(Boolean);
  const [selectedImage, setSelectedImage] = useState();
  let tabNameCustom: any = ContactType.CustomWHLoc;
  const selectedRowData = useSelector(
    (state: RootState) => state.gridReducer.selectedRowData
  );
  const customData = useSelector(
    (state: RootState) => state.contactReducer?.WHCustomData
  );
  // console.log(customData);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setTabNameWHLocation(tabNameCustom));
    dispatch(setTabHeading(tabNameCustom));
  }, [tabNameCustom]);
  /* Custom WH Loaction Add functionality starts*/
  const handleChangeNameCustom = (e: any) => {
    setNameCustom(e.target.value);
  };
  const handleChangeWHLocCustom = (e: any) => {
    setWhLocCustom(e.target.value);
  };
  const handleChangeVoiceCustom = (e: any) => {
    setVoiceCustom(e.target.value);
  };
  const handleChangeBoxMaxCountCustom = (e: any) => {
    setMaxBoxCountCustom(e.target.value);
  };
  const handlePopupActionCustom = () => {
    setShowModelCustom(true);
  };
  const handleCloseCustomPopup = () => {
    setShowModelCustom(false);
  };
  const bodyCustom = {
    boxMaxCount: maxBoxCountCustom,
    disposition: nameCustom,
    dispositionVoiceText: voiceCustom,
    warehouseLocation: whLocCustom,
  };
  const handleAddWHLocCustom = () => {
    const endpoint = addMasterDataEndPoint(tabNameCustom);
    handleMasterDataChangeCustom(endpoint);
    setNameCustom("");
    setMaxBoxCountCustom("");
    setVoiceCustom("");
    setWhLocCustom("");
  };
  const handleMasterDataChangeCustom = async (endpoint: string) => {
    // const result = await axios.post(endpoint, bodyCustom);
    // if (result && result.data) {
    //   setShowModelCustom(false);
    //   notify("Warehouse Location created successfully", "success");
    //   handleGetWHLocCustom();
    // } else {
    //   notify("Failed to create Whse Location", "error");
    // }

    const result = await axios
      .post(endpoint, bodyCustom)
      .then((results: any) => {
        if (results && results.data) {
          setShowModelCustom(false);
          notify("Warehouse location is created successfully", "success");
          handleGetWHLocCustom();
        } else {
          notify("Failed to create Warehouse location is created", "error");
        }
      })
      .catch((error: any) => {
        if (error.response) {
          notify(error.response?.data?.message, "error");
        }
      });
    // const getresult = await axios.get(getEndpoint);
    // dispatch(setCurrentContact(getresult.data));
  };

  const handleGetWHLocCustom = async () => {
    const endpoint2 = getAllMasterData(tabNameCustom);
    // console.log(endpoint2);
    const getresult = await axios.get(endpoint2);
    // console.log(getresult.data);
    dispatch(setWHLocCustomData(getresult.data));
  };
  let gridHieght = "calc(100vh - 385px)";
  /* UCustom WH Loaction Add functionality ends*/
  return (
    <>
      <div className="float-right">
        <span>
          {/* <img
            className="ItemCatImages ml-1rem addPlusIcon upcDefAddImg"
            src={addIcon}
            onClick={handlePopupActionCustom}
          /> */}
          <ButtonComponent
            type="button"
            className="iconCopy float-right ml-1"
            onClick={handlePopupActionCustom}
            style={{ position: "relative", bottom: "4rem" }}
          >
            <Add />
          </ButtonComponent>
        </span>
      </div>
      <br />
      <div className="contacts-wrapper">
        <div className="masterDataGrid">
          <Grid
            config={config["Custom"]}
            width={"100%"}
            height={gridHieght}
            data={generateGridData(ContactType.CustomWHLoc,customData)}
          />
        </div>
        {selectedRowData && selectedRowData.length > 0 ? (
          <div className="detailsTab">
            <ContactsDetailspanel />
          </div>
        ) : (
          <>
            <div className="detailsTabEmpty d-flex align-items-center">
              <div className="col-sm-12 col-md-12 col-lg-12 d-flex justify-content-center">
                <p className="selectTextForEmpty">
                  Select Warehouse location to view details{" "}
                </p>
              </div>
            </div>
          </>
        )}
      </div>
      <LLModal
        handleClose={() => setShowModelCustom(false)}
        show={showModelCustom}
        width={800}
      >
        <div>
          <div className="row">
            <div className="row ml-0 mr-0 mb-30 modal-title-header">
              <span className="tabNameForAddScreen">
                Add Warehouse Location
              </span>
            </div>
            </div>
            <div className="row">
            <div className="col-sm-12 textAlign-center">
              <img src={userIcon} className="userAddIcon" />
              {selectedImage && (
                <div className="imagePreviewDiv">
                  <img
                    src={URL.createObjectURL(selectedImage)}
                    className="imagePreview"
                    height="100"
                    width="100"
                  />
                </div>
              )}
            </div>
            </div>
          
            <div className="col-sm-12 row m-0 mt-2">
            <div className="col-sm-6 mt-1">
            <div className="col-sm-12">
              <label>Name</label>
              <input
                type="text"
                className="form-control"
                value={nameCustom}
                onChange={(e) => handleChangeNameCustom(e)}
              />
            </div>
            <div className="col-sm-12 mt-1">
              <label>Warehouse Location</label>
              <input
                type="text"
                value={whLocCustom}
                className="form-control"
                onChange={(e) => handleChangeWHLocCustom(e)}
              />
            </div>
            </div>
          
            <div className="col-sm-6 mt-1">
            <div className="col-sm-12">
              <label>Voice</label>
              <input
                type="text"
                value={voiceCustom}
                className="form-control"
                onChange={(e) => handleChangeVoiceCustom(e)}
              />
            </div>
            <div className="col-sm-12 mt-1">
              <label>Max Boxes Per Pallet</label>
              <input
                type="text"
                value={maxBoxCountCustom}
                className="form-control"
                onChange={(e) => handleChangeBoxMaxCountCustom(e)}
              />
            </div>
            </div>
          </div>
          <div className="col-sm-12 px-0 mt-5">
            <ButtonComponent
              cssClass="eg-btn-primary float-right"
              onClick={handleAddWHLocCustom}
            >
              ADD WHSE LOCATION
            </ButtonComponent>
            <ButtonComponent
              cssClass="e-control e-btn e-lib eg-btn-primary-link e-flat mt-3"
              onClick={handleCloseCustomPopup}
            >
              CANCEL
            </ButtonComponent>
          </div>
        </div>
      </LLModal>
    </>
  );
};
export default UpcCustomView;
