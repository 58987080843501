import { FC, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { DropDownListComponent } from "@syncfusion/ej2-react-dropdowns";
import { RadioButtonComponent, SwitchComponent } from "@syncfusion/ej2-react-buttons";
import InvoiceTable from "../InvoiceDetails/InvoiceTable";
import axios from "axios";
import { getARInvoicesListEndPoint, SaveArInvoicesEndPoint } from "../utils";
import CreditMemoTable from "./CreditMemoTable/CreditMemoTable";
import LLAccordion from "../../common/llAccordion/LLAccordion";
import "./CreditMemos.scss";
import { setArInvoiceSavedata, setChangedCreditmemo, setDescriptionForCM, setIsInvoiceUpdated } from "../invoiceReducer";
import { RootState } from "../../../redux/store";
import { Internationalization } from "@syncfusion/ej2-base";
import notify from "../../../services/toasterService";
import { TextBoxComponent } from "@syncfusion/ej2-react-inputs";
import "../../common/InputCollection.tsx/InputCollection.scss";
import moment from "moment";
interface IProps {
  creditMemoData: any;
  handleCreditMemoPriceChange: (newPrice: string) => void;
  creditMemoPrice: number;
}

const CreditMemos: FC<IProps> = ({
  creditMemoData,
  handleCreditMemoPriceChange,
  creditMemoPrice,
}) => {
  let CMemoData: any;
  // call api to get list
  const [invoiceOptions, setInvoiceOptions] = useState(() => []);
  const [includeMessage, setIncludeMessage] = useState("false");
  const [creditamount, setCreditAmount] = useState<any>()

  let linelevelcreditamountTotal = useSelector(
    (store: RootState) => store.invoiceReducer.lineLevelCmTotal
  );


 
  const [showCreditPriceErrorMessage, setShowCreditPriceErrorMessage] =
    useState(false);
  
    const [selectedInvoice, setSelectedInvoice] = useState(
    creditMemoData.invoiceNumber ? creditMemoData.invoiceNumber : ""
  );
  const [isViewOnly, setIsViewOnly] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const creditMemoInfo = useSelector(
    (state: RootState) => state.invoiceReducer.creditMemoSavedData
  );
  const arInvoiceSavedata = useSelector(
    (store: RootState) => store.invoiceReducer.arInvoiceSaveData
  );
 
  const handleCreditMemoChange = (value: string) => {
    if(value === ""){
      value = "0"
    }
     let price =  value.replace(/[&\/\\#,+()$~%'":*?<>{}]/g, '');
     if (creditMemoInfo.price >= parseFloat(price) ) {
      setCreditAmount((price))
      dispatch(setChangedCreditmemo(price))
      //setChangedCreditmemo(price)
      handleCreditMemoPriceChange(value);
     } else {
       notify('*Credit price cannot be greater than the total price', 'error')
     }
   
  };
  
  let price =  arInvoiceSavedata?.creditPrice?.toString().replace(/[&\/\\#,+()$~%'":*?<>{}]/g, '');
  //dispatch(setChangedCreditmemo(price))
  const CovertArInvoiceGridData = (data: any) => {
    return {
      ...data,
      invoiceNumber: data.invoiceNumber,
      description: data.description,
      customerId: data.customerId,
      period: data.period,
      cost: data.cost,
      price: data.price,
      created: data.createdDate,
      facility: data.reclaimCenterName,
      status: data.status.status,
    };
  };
  const [rcName, setRCName] = useState('');
  const [period, setPeriod] = useState('');
  const [description, setDescription] = useState(creditMemoInfo?.description ? creditMemoInfo?.description : arInvoiceSavedata?.description);
  const [customer, setCustomer] = useState('');
  const [glAccount, setGlAccount] = useState('');
  const [pickupFrom, setPickupFrom] = useState('');
  const [trailerNumber, setTrailerNumber] = useState('');
  const [deliverTo, setDeliverTo] = useState('');
  const [sealnumber, setSealnumber] = useState('');
  const [notes, setNotes] = useState('');
  const [carrier, setCarrier] = useState('');
  const [doNotFreez, setDoNotFreez] = useState(Boolean);
  useEffect(() => {
    setCreditAmount(+creditMemoPrice.toFixed(2));
  }, [creditMemoPrice]);
  useEffect(() => {
    if(creditMemoData.length != 0) {
      setRCName(creditMemoData.reclaimCenterName);
      setPeriod(creditMemoData.period);
      setDescription(description);
      setCustomer(creditMemoData.customerName);
      setGlAccount(creditMemoData.glAccountName);
      setPickupFrom(creditMemoData.reclaimCenterName);
      setTrailerNumber(creditMemoData.trailerNumber);
      setSealnumber(creditMemoData.sealNumber);
      setNotes(creditMemoData.notes);
      setCarrier(creditMemoData.carrierName);
      setDoNotFreez(creditMemoData.doNotFreeze);
     
    }
    else{
      setRCName(creditMemoInfo.reclaimCenterName);
      setPeriod(creditMemoInfo.period);
      setDescription(description);
      setCustomer(creditMemoInfo.customerName);
      setGlAccount(creditMemoInfo.glAccountName);
      setPickupFrom(creditMemoInfo.reclaimCenterName);
      setTrailerNumber(creditMemoInfo.trailerNumber);
      setSealnumber(creditMemoInfo.sealNumber);
      setNotes(creditMemoInfo.notes);
      setCarrier(creditMemoInfo.carrierName);
      setDoNotFreez(creditMemoInfo.doNotFreeze);
    }
    // console.log(linelevelcreditamountTotal)
    if (linelevelcreditamountTotal !== undefined && linelevelcreditamountTotal !== null) {
      setCreditAmount(linelevelcreditamountTotal.toFixed(2))
    }
    // CMemoData = creditMemoData? creditMemoData : '' ;
    // CMemoData = creditMemoInfo.length > 0 ? creditMemoInfo : '' ;
    
  }, [linelevelcreditamountTotal]);
  let intl = new Internationalization();
  let nFormatter = intl.getNumberFormat({
    skeleton: "C3",
    currency: "USD",
    minimumIntegerDigits: 0,
    minimumFractionDigits: 2,
  });
  // useEffect(() => {
  //   const endpoint = getARInvoicesListEndPoint();
  //   fetch(endpoint)
  //     .then((data) => data.json())
  //     .then((result) => {
  //       if (!result || result.length === 0) {
  //         //alert("Fetch list successful, but no list items are present");
  //       } else {
  //         //alert("Fetch list successful");
  //         let arlist: any = [];
  //         result.forEach((element: any) => {
  //           arlist.push(CovertArInvoiceGridData(element));
  //         });
  //         setInvoiceOptions(arlist);
  //       }
  //     })
  //     .catch((error) => console.log(error));
  // }, []);
  useEffect(() => {
    const endpoint = getARInvoicesListEndPoint();
    arListEndPoint(endpoint);
},[]);
const arListEndPoint = async (endpoint: string) => {
  const result = await axios
    .get(endpoint)
    .then((results: any) => {
      if (results && results.data) {
        let arlist: any = [];
        results.data.forEach((element: any) => {
            arlist.push(CovertArInvoiceGridData(element));
          });
          setInvoiceOptions(arlist);
      } else {
        notify("Failed", "error");
      }
    })
    .catch((error: any) => {
      if (error.response) {
        notify(error.response?.data?.message, "error");
      }
    });
};
  // useEffect(() => {
  //   const endpoint = getARInvoicesListEndPoint();
  //   axios.get(endpoint).then((result: any) => {
  //     if (!result) {
  //     } else {
  //       let arlist: any = [];
  //         result.forEach((element: any) => {
  //           arlist.push(CovertArInvoiceGridData(element));
  //         });
  //         setInvoiceOptions(arlist);
  //     }
  //   });
  // }, []);
  // useEffect(() => {
  //   const endpoint = SaveArInvoicesEndPoint(selectedInvoice);
  //   axios.get(endpoint).then((result: any) => {
  //     if (!result) {
  //     } else {
  //       dispatch(setArInvoiceSavedata(result.data));
  //     }
  //   });
  // }, [selectedInvoice]);
  const handleInvoiceChange = (params: any) => {
    setSelectedInvoice(params.itemData.invoiceNumber);
  };
  const handleRadiobutton = (value: string) => {
    const val = value === "true" ? "true" : "false";
    dispatch(setIsInvoiceUpdated(true));
    setIncludeMessage(val);
  };
  const handleDescriptionChangeCM = (val: any) =>{
    setDescription(val)
    dispatch(setDescriptionForCM(val));
    dispatch(setIsInvoiceUpdated(true));
  }
  return (
    <>
       
      <div className="col-sm-12 row mt-4 p-0 ar-invoice-row">
      <div className="col-sm-4">
        
              <div className="col-sm-4">
                <span className=" settingslblClass fw-bold">Invoice #</span>
              </div>
            <div className="col-sm-8  m-0 p-0">
            <input disabled className="form-control" type="text"  value={creditMemoInfo?.invoiceNumber ? creditMemoInfo?.invoiceNumber : arInvoiceSavedata?.invoiceNumber} />

            </div> 
            </div>
            
      </div>
      <div className="col-sm-12 row mt-4 p-0 ar-invoice-row">
      <div className="col-sm-4">
        
              <div className="col-sm-4">
                <span className=" settingslblClass fw-bold">Origin</span>
              </div>
            <div className="col-sm-8  m-0 p-0">
            <input disabled className="form-control" type="text" value= {creditMemoInfo?.reclaimCenterName ? creditMemoInfo?.reclaimCenterName : arInvoiceSavedata?.reclaimCenterName}/>

            </div> 
            </div>
            <div className="col-sm-4">
        
              <div className="col-sm-4">
                <span className=" settingslblClass fw-bold">Period</span>
              </div>
            <div className="col-sm-8  m-0 p-0">
            <input disabled className="form-control" type="text" value={creditMemoInfo?.period ? creditMemoInfo?.period : arInvoiceSavedata?.period}/>

            </div> 
            </div>
            <div className="col-sm-4">
        
              <div className="col-sm-4">
                <span className=" settingslblClass fw-bold">Description</span>
              </div>
            <div className="col-sm-8  m-0 p-0">
            {creditMemoInfo?.status?.status === 'Draft' ||  arInvoiceSavedata?.status?.status === 'Draft' ?
           <input
           value={description}
           className="pl-1 form-control"
           onChange={(e: any) => handleDescriptionChangeCM(e.target.value)}
         />
        : <input disabled className="form-control" type="text" value={creditMemoInfo?.description ? creditMemoInfo?.description : arInvoiceSavedata?.description}/>

        }

            </div> 
            </div>
      </div>
      <div className="col-sm-12 row mt-4 p-0 ar-invoice-row">
      <div className="col-sm-4">
        
              <div className="col-sm-4">
                <span className=" settingslblClass fw-bold">Customer</span>
              </div>
            <div className="col-sm-8  m-0 p-0">
            <input disabled className="form-control" type="text" value={creditMemoInfo?.customerName ? creditMemoInfo?.customerName : arInvoiceSavedata?.customerName}/>

            </div> 
            </div>
            <div className="col-sm-4">
        
              <div className="col-sm-4">
                <span className=" settingslblClass fw-bold">Department</span>
              </div>
            <div className="col-sm-8  m-0 p-0">
            <input disabled className="form-control" type="text" value={creditMemoInfo?.departmentName ? creditMemoInfo?.departmentName : arInvoiceSavedata?.departmentName}/>

            </div> 
            </div>
            <div className="col-sm-4">
        
              <div className="col-sm-4">
                <span className=" settingslblClass fw-bold">GL Account</span>
              </div>
            <div className="col-sm-8  m-0 p-0">
            <input disabled className="form-control" type="text" value={creditMemoInfo?.glAccountName ? creditMemoInfo?.glAccountName : arInvoiceSavedata?.glAccountName}/>

            </div> 
            </div>
            </div>
            <div className="col-sm-12 row mt-4 p-0 ar-invoice-row">
      <div className="col-sm-4">
        
              <div className="col-sm-4">
                <span className=" settingslblClass fw-bold">Pickup from</span>
              </div>
            <div className="col-sm-8  m-0 p-0">
            <input disabled className="form-control" type="text" value={creditMemoInfo?.warehouseName ? creditMemoInfo?.warehouseName : arInvoiceSavedata?.warehouseName}/>

            </div> 
            </div>
            <div className="col-sm-4">
        
        <div className="col-sm-4">
          <span className=" settingslblClass fw-bold">Trailer number</span>
        </div>
      <div className="col-sm-8  m-0 p-0">
      <input disabled className="form-control" type="text" value={creditMemoInfo?.trailerNumber ? creditMemoInfo?.trailerNumber : arInvoiceSavedata?.trailerNumber}/>

      </div> 
      </div>
      <div className="col-sm-4">
        
        <div className="col-sm-4">
          <span className=" settingslblClass fw-bold">Credit Memo Date</span>
        </div>
      <div className="col-sm-8  m-0 p-0">
      <input disabled className="form-control" type="text" 
      value={moment(creditMemoInfo?.invoiceDate ? creditMemoInfo?.invoiceDate : new Date()).format('MMM DD, YYYY')}/>
      </div> 
      </div>
            </div>
            <div className="col-sm-12 row mt-4 p-0 ar-invoice-row">
      <div className="col-sm-4">
        
              <div className="col-sm-4">
                <span className=" settingslblClass fw-bold">Deliver To</span>
              </div>
            <div className="col-sm-8  m-0 p-0">
            <input disabled className="form-control" type="text"
             value={creditMemoInfo?.deliveryAddress !== null ? creditMemoInfo?.deliveryAddress ? creditMemoInfo?.deliveryAddress?.city+ ","+creditMemoInfo?.deliveryAddress?.state : "" :arInvoiceSavedata?.deliveryAddress !== null ? arInvoiceSavedata?.deliveryAddress?.city+","+arInvoiceSavedata?.deliveryAddress?.state : ""}/>

            </div> 
            </div>
            <div className="col-sm-4">
        
              <div className="col-sm-4">
                <span className=" settingslblClass fw-bold">Seal number</span>
              </div>
            <div className="col-sm-8  m-0 p-0">
            <input disabled className="form-control" type="text" value={creditMemoInfo?.sealNumber ? creditMemoInfo?.sealNumber : arInvoiceSavedata?.sealNumber}/>

            </div> 
            </div>
            <div className="col-sm-4">
        
              <div className="col-sm-4">
                <span className=" settingslblClass fw-bold">Closed Date</span>
              </div>
            <div className="col-sm-8  m-0 p-0">
            <input disabled className="form-control" type="text"
             value={creditMemoInfo?.extractDate === null ? creditMemoInfo?.extractDate : moment(creditMemoInfo?.extractDate).format('MMM DD, YYYY')  ? moment(creditMemoInfo?.extractDate).format('MMM DD, YYYY') : arInvoiceSavedata?.extractDate === null ? arInvoiceSavedata?.extractDate : moment(arInvoiceSavedata?.extractDate).format('MMM DD, YYYY') }/>

            </div> 
            </div>
            </div>
            <div className="col-sm-12 row mt-4 p-0 ar-invoice-row">
      <div className="col-sm-4">
        
              <div className="col-sm-4">
                <span className=" settingslblClass fw-bold">Carrier</span>
              </div>
            <div className="col-sm-8  m-0 p-0">
            <input disabled className="form-control" type="text" value={creditMemoInfo?.carrierName ? creditMemoInfo?.carrierName : arInvoiceSavedata?.carrierName}/>

            </div> 
            </div>
            <div className="col-sm-4">
        
              <div className="col-sm-4">
                <span className=" settingslblClass fw-bold">Notes</span>
              </div>
            <div className="col-sm-8  m-0 p-0">
            <input disabled className="form-control" type="text" value={creditMemoInfo?.notes ? creditMemoInfo?.notes : arInvoiceSavedata?.notes}/>

            </div> 
            </div>
            <div className="col-sm-4">
        
              <div className="col-sm-4">
                <span className=" settingslblClass fw-bold">Do Not Freeze Message</span>
              </div>
            <div className="col-sm-8 textAlign-left  m-0 p-0">
            <div className="radio-group-invoice donotFrzInvoice">
            <span className="ml-3">
              <RadioButtonComponent
                label="Include"
                name="invoice-details-message"
                value="true"
                checked={doNotFreez}
                change={(prams: any) => handleRadiobutton(prams.value)}
              />
            </span>
            <span className="ml-3">
              <RadioButtonComponent
                label="Do Not Include"
                name="invoice-details-message"
                value="false"
                checked={!doNotFreez}
                change={(prams: any) => handleRadiobutton(prams.value)}
              />
            </span>
          </div>
            </div> 
            </div>
            </div>
     <div className="input-container">
       
        
        
        </div>
      <div className="creditmemo-container">
       
        {/* <div className="col-sm-12">
        <div className="input-control">
          <p className="input-control-label fontSize-14">Invoice Number</p>
          <p className="input-control-value fontSize-14">
          {arInvoiceSavedata?.invoiceNumber ? arInvoiceSavedata?.invoiceNumber : arInvoiceSavedata?.invoiceNumber}
          </p>
        </div>
        </div>
        <div className="input-control">
          <p className="input-control-label fontSize-14">Origin</p>
          <p className="input-control-value fontSize-14">
          {arInvoiceSavedata?.reclaimCenterName ? arInvoiceSavedata?.reclaimCenterName : arInvoiceSavedata?.reclaimCenterName}
          </p>
        </div>
        <div className="input-control">
          <p className="input-control-label fontSize-14">Period</p>
          <p className="input-control-value fontSize-14">{arInvoiceSavedata?.period ? arInvoiceSavedata?.period : arInvoiceSavedata?.period}</p>
        </div>
        <div className="input-control">
          <p className="input-control-label fontSize-14">Description</p>
          <p className="input-control-value fontSize-14">{arInvoiceSavedata?.description ? arInvoiceSavedata?.description : arInvoiceSavedata?.description}</p>
        </div>
        <div className="input-control">
          <p className="input-control-label fontSize-14">Customer</p>
          <p className="input-control-value fontSize-14">{arInvoiceSavedata?.customerName ? arInvoiceSavedata?.customerName : arInvoiceSavedata?.customerName}</p>
        </div>
        <div className="input-control">
          <p className="input-control-label fontSize-14">Department</p>
          <p className="input-control-value fontSize-14">{arInvoiceSavedata?.departmentName ? arInvoiceSavedata?.departmentName : arInvoiceSavedata?.departmentName}</p>
        </div>
        <div className="input-control">
          <p className="input-control-label fontSize-14">GL Account</p>
          <p className="input-control-value fontSize-14">{arInvoiceSavedata?.glAccountName ? arInvoiceSavedata?.glAccountName : arInvoiceSavedata?.glAccountName}</p>
        </div>
     
          <div className="input-control">
            <p className="input-control-label fontSize-14">Pickup from</p>
            <p className="input-control-value fontSize-14">{arInvoiceSavedata?.reclaimCenterName ? arInvoiceSavedata?.reclaimCenterName : arInvoiceSavedata?.reclaimCenterName}</p>
          </div>
          <div className="input-control">
            <p className="input-control-label fontSize-14">Trailer number</p>
            <p className="input-control-value fontSize-14">
              {arInvoiceSavedata?.trailerNumber ? arInvoiceSavedata?.trailerNumber : arInvoiceSavedata?.trailerNumber}
            </p>
          </div> */}
          {/* <div className="input-control">
            <p className="input-control-label">Attachment</p>
            <p className="input-control-value">{creditMemoData.attachments}</p>
          </div> */}
          {/* <div className="input-control">
            <p className="input-control-label fontSize-14">Deliver To</p>
            <p className="input-control-value fontSize-14">
              {arInvoiceSavedata?.primaryAddressId ? arInvoiceSavedata?.primaryAddressId : arInvoiceSavedata?.primaryAddressId}
            </p>
          </div>
          <div className="input-control">
            <p className="input-control-label fontSize-14">Seal number</p>
            <p className="input-control-value fontSize-14">{arInvoiceSavedata?.sealNumber ? arInvoiceSavedata?.sealNumber : arInvoiceSavedata?.sealNumber}</p>
          </div>
          <div className="input-control">
            <p className="input-control-label fontSize-14">Notes</p>
            <p className="input-control-value fontSize-14">{arInvoiceSavedata?.notes ? arInvoiceSavedata?.notes : arInvoiceSavedata?.notes}</p>
          </div>
          <div className="input-control">
            <p className="input-control-label fontSize-14">Carrier</p>
            <p className="input-control-value fontSize-14">{arInvoiceSavedata?.carrierName ? arInvoiceSavedata?.carrierName : arInvoiceSavedata?.carrierName}</p>
          </div>
        
          <div className="col-sm-12 pl-0">
          <div className="col-sm-4 mt-1rem pl-0 extrctOracTextCMDiv">
          <div className="col-sm-3 pl-0">
          <p className="inpextrctOracTextCM">Extract to Oracle</p>
          </div>
          <div className="col-sm-5 pl-0">
            <SwitchComponent checked={arInvoiceSavedata?.extracted} disabled/>
          </div>
          </div>
          <div className="col-sm-4 extrctOracTextCMDiv doNotFrzDivCM">
          <div className="radio-group-invoice donotFrzInvoice"> */}
            {/* <span className="do-not-freeze-text donotFrzInvoiceTxt">Do Not Freeze Message</span> */}
            {/* <p className="input-control-label fontSize-14 donotFrzCMTxt">Do Not Freeze Message</p>
            <span>
              <RadioButtonComponent
                label="Include"
                name="invoice-details-message"
                value="true"
                checked={doNotFreez}
                change={(prams: any) => handleRadiobutton(prams.value)}
              />
            </span>
            <span>
              <RadioButtonComponent
                label="Do Not Include"
                name="invoice-details-message"
                value="false"
                checked={!doNotFreez}
                change={(prams: any) => handleRadiobutton(prams.value)}
              />
            </span>
          </div>
          </div>
          <div className="col-sm-4 mt-1rem extrctOracTextCMDiv invceDatCM">
          <div className="col-sm-4 pl-0">
          <p className="inpextrctOracTextCM">Credit Memo Date</p>
          </div>
          <div className="col-sm-5 pl-0">
           <p>{arInvoiceSavedata?.createdDate ? arInvoiceSavedata?.createdDate : arInvoiceSavedata?.createdDate}</p>
          </div>
          </div>
          </div> */}
          {/* <div className="input-control">
            <p className="input-control-label fontSize-14">Extract Date</p>
            <p className="input-control-value fontSize-14">
              {creditMemoInfo.extractDate ? creditMemoInfo?.extractDate : creditMemoData?.extractDate
                ? new Date(creditMemoInfo.extractDate ? creditMemoInfo?.extractDate : creditMemoData?.extractDate).toDateString()
                : new Date().toDateString()}
            </p>
          </div> */}
          {/* <div className="input-control">
            <p className="input-control-label fontSize-14">Picked Date</p>
            <p className="input-control-value fontSize-14">
              {creditMemoInfo.pickedDate ? creditMemoInfo?.pickedDate : creditMemoData?.pickedDate
                ? new Date(creditMemoInfo.pickedDate ? creditMemoInfo?.pickedDate : creditMemoData?.pickedDate).toDateString()
                : new Date().toDateString()}
            </p>
          </div> */}
          {/* <div className="radio-group-invoice">
            <span className="do-not-freeze-credit">Do not Freeze Message</span>
            <span>
              <RadioButtonComponent
                label="Include"
                name="invoice-details-message"
                value="true"
                checked = {doNotFreez}
                change={(prams: any) => handleRadiobutton(prams.value)}
              />
            </span>
            <span>
              <RadioButtonComponent
                label="Do Not Include"
                name="invoice-details-message"
                value="false"
                checked = {!doNotFreez}
                change={(prams: any) => handleRadiobutton(prams.value)}
              />
            </span>
          </div> */}
          {/* <div className="radio-group-invoice donotFrzInvoice">
          
            <p className="input-control-label fontSize-14 donotFrzCMTxt">Do Not Freeze Message</p>
            <span>
              <RadioButtonComponent
                label="Include"
                name="invoice-details-message"
                value="true"
                checked={doNotFreez}
                change={(prams: any) => handleRadiobutton(prams.value)}
              />
            </span>
            <span>
              <RadioButtonComponent
                label="Do Not Include"
                name="invoice-details-message"
                value="false"
                checked={!doNotFreez}
                change={(prams: any) => handleRadiobutton(prams.value)}
              />
            </span>
          </div> */}
        </div>

      <div className="creditmemo-tables-container">
        <InvoiceTable
          pallets={creditMemoInfo?.pallets ? creditMemoInfo?.pallets : creditMemoInfo?.pallets ? creditMemoInfo?.pallets ? creditMemoInfo?.pallets : creditMemoInfo?.pallets: 0}
          bins={creditMemoInfo?.bins ? creditMemoInfo?.bins : creditMemoInfo?.bins ? creditMemoInfo?.bins ? creditMemoInfo?.bins : arInvoiceSavedata?.bins: 0}
          boxes={creditMemoInfo?.boxes ? creditMemoInfo?.boxes : creditMemoInfo?.boxes ? creditMemoInfo?.boxes ? creditMemoInfo?.boxes : creditMemoInfo?.boxes: 0}
          items={creditMemoInfo?.items ? creditMemoInfo?.items : creditMemoInfo?.items ? creditMemoInfo?.items ? creditMemoInfo?.items : creditMemoInfo?.items: 0}
          cost={creditMemoInfo?.cost ? creditMemoInfo?.cost : creditMemoInfo?.cost ? creditMemoInfo?.cost ? creditMemoInfo?.cost : creditMemoInfo?.cost: 0}
          price={creditMemoInfo?.price ? creditMemoInfo?.price : creditMemoInfo?.price ? creditMemoInfo?.price ? creditMemoInfo?.price : creditMemoInfo?.price: 0}
        />
       
        <CreditMemoTable
          //value = {(creditamount !== undefined ? creditamount : (creditMemoPrice ? creditMemoPrice : arInvoiceSavedata.creditPrice))}
          value={creditamount}
          headerValue="CREDIT($)"
          onChange={handleCreditMemoChange}
          showError={showCreditPriceErrorMessage}
          isEditable={creditMemoInfo?.status?.status === 'Draft'}
          errorMessage="*Credit price cannot be greater than the total price"
         
        />
          {/* isEditable={arInvoiceSavedata?.status?.status === 'Draft' ? arInvoiceSavedata?.status?.status === 'Draft' : arInvoiceSavedata?.status?.status === 'Draft'} */}
        <CreditMemoTable
         //value = {`$${nFormatter(+creditMemoPrice ? arInvoiceSavedata?.price - +creditMemoPrice :  arInvoiceSavedata.price )}`}
         value = {`$${nFormatter(+creditMemoPrice ? creditMemoInfo?.price - +creditMemoPrice :  (creditMemoInfo?.revisedPrice? creditMemoInfo?.revisedPrice : 0) )}`}
          // value={creditMemoInfo.price ? creditMemoInfo?.price : creditMemoData?.price - +creditMemoPrice}
          color="#abcabc"
          headerValue="REVISED NET PRICE"
          onChange={() => {}}
          errorMessage=""
        />
      </div>
    </>
  );
};

export default CreditMemos;
