import { ButtonComponent } from "@syncfusion/ej2-react-buttons";
import axios from "axios";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import userIcon from "../../assets/userIcon.png";
import { RootState } from "../../redux/store";
import notify from "../../services/toasterService";
import { ContactType } from "./constants";
import { setCurrentContact } from "./contactReducer";
import { addMasterDataEndPoint, getEndPoint, getScanGroupByRC } from "./utils";

const PalletLocationAddScreen = (props: any) => {
  const [selectedImage, setSelectedImage] = useState();
  const [palletNamePL, setPalletNamePL] = useState("");
  const [reclaimCenterPL, setReclaimCenterPL] = useState("");
  const [whPL, setWHPL] = useState("");
  const [scanGroupPL, setscanGroupPL] = useState("");
  const [positionPL, setPositionPL] = useState("");
  const [hasPalletPL, setHasPalletPL] = useState(Boolean);
  const [locationEnabledPL, setLocationEnabledPL] = useState(Boolean);
  const [locationEnabledPL1, setLocationEnabledPL1] = useState(true);
  const [pricingTypeSymbal, setPricingTypeSymbal] = useState("$");
  const [pricingType, setPricingType] = useState("");
  const [price, setPrice] = useState("");
  const [binprice, setbinPrice] = useState("");
  const [selectRCText, setSelectRCText] = useState("Please select RC");
  const [scanGroupByRc, setScanGroupByRc] = useState<any[]>([]);
  const [showPriceDiv, setShowPriceDiv] = useState(true);

  const [myPalletName, setMyPalletName] = useState("");
  const [myWhseLocation, setMyWhseLocation] = useState("");
  const [myReclaimCenter, setMyReclaimCenter] = useState("");
  const [myScanGroup, setMyScanGroup] = useState("");
  const [myPricingType, setMyPricingType] = useState("");
  const [mybinRate, setbinMyRate] = useState("");
  const [myRate, setMyRate] = useState("");    
  const [myPosition, setMyPosition] = useState("");

  const [selPrice, setSelPrice] = useState<any>();
  const [selbinPrice, setbinSelPrice] = useState<any>();
  const { setAddScreen } = props;
  const [errors, setErrors] = useState({
    palletNameError: "",
    whseLocError: "",
    rcError: "",
    scanGroupError: "",
    pricingTypeError: "",
    priceError: "",
    binpriceError:"",
    positionError: "",
    locationEnabldError: "",
  });
  let palletNameError = "";
  let whseLocError = "";
  let rcError = "";
  let scanGroupError = "";
  let pricingTypeError = "";
  let priceError = "";
  let binpriceError = "";
  let positionError = "";
  let locationEnabldError = "";
  const dispatch = useDispatch();
  const imageChange = (e: any) => {
    if (e.target.files && e.target.files.length > 0) {
      setSelectedImage(e.target.files[0]);
    }
  };
  const palletNameData = useSelector(
    (state: RootState) => state.contactReducer?.palletNameMasterData
  );
  const RCData = useSelector(
    (state: RootState) => state.contactReducer?.reclaimCenters
  );
  const sacnGroupData = useSelector(
    (state: RootState) => state.contactReducer?.scanGroupMasterData
  );
  const pricingTypeData = useSelector(
    (state: RootState) => state.contactReducer?.pricingTypeMasterData
  );
  const whLocationsInfo = useSelector(
    (state: RootState) => state.contactReducer?.WhareHouseLocationData
  );
  const handleAddPLPalletName = (e: any) => {
    setPalletNamePL(e.target.value);
    validate();
  };
  const handleAddPLRC = (e: any) => {
    if (e.target.value === "0") {
      setSelectRCText("Please select RC");
    } else {
      const getEndpoint = getScanGroupByRC(e.target.value);
      hadleGetScanGroupData(e.target.value, getEndpoint);
      setReclaimCenterPL(e.target.value);
    }
    validate();
  };
  const hadleGetScanGroupData = async (val: any, endpoint: string) => {
    const result = await axios.get(endpoint, val);
    if (result && result.data) {
      let dataLength = JSON.parse(JSON.stringify(result.data));
      if (dataLength.length > 0) {
        setSelectRCText("Select");
        const SGinfo = JSON.parse(JSON.stringify(result.data));
        setScanGroupByRc(SGinfo);
      } else {
        setScanGroupByRc([]);
        setSelectRCText("Not avaliable");
      }
    } else {
    }
  };
  const handleWhseLocation = (e: any) => {
    setWHPL(e.target.value);
    validate();
  };
  const handleAddPLSacnGroup = (e: any) => {
    setscanGroupPL(e.target.value);
    validate();
  };
  const handleAddPLPosition = (e: any) => {
    setPositionPL(e.target.value);
    validate();
  };
  const handlePricingType = (e: any) => {
    setPricingType(e.target.value);
    validate();
    if (
      e.target.value === "Fixed price" ||
      e.target.value === "User Set" ||
      e.target.value === "0"
    ) {
      setShowPriceDiv(true);
    } else {
      setShowPriceDiv(false);
    }
  };

  const handleChagePrice = (e: any) => {
    if (e.target.value === "") {
      priceError = "Required ";
    }

    if (e.target.value.includes(".") === true) {
      if (e.target.value.split(".")[1].length > 2) {
        setSelPrice(parseFloat(e.target.value).toFixed(2));
        setPrice(parseFloat(e.target.value).toFixed(2));
      } else {
        setPrice(e.target.value);
        setSelPrice(e.target.value);
      }
    } else {
      setPrice(e.target.value);
      setSelPrice(e.target.value);
    }

    if (priceError) {
      setErrors({
        palletNameError,
        whseLocError,
        rcError,
        scanGroupError,
        pricingTypeError,
        priceError,
        binpriceError,
        positionError,
        locationEnabldError,
      });
      return false;
    } else {
      if (e.target.value.includes(".") === true) {
        if (e.target.value.split(".")[1].length > 2) {
          setPrice(parseFloat(e.target.value).toFixed(2));
          setSelPrice(parseFloat(e.target.value).toFixed(2));
        } else {
          setPrice(e.target.value);
          setSelPrice(e.target.value);
        }
      } else {
        setPrice(e.target.value);
        setSelPrice(e.target.value);
      }

      return true;
    }
  };
  const handleChagePriceBinRate = (e: any) => {
    if (e.target.value === "") {
      binpriceError = "Required ";
    }

    if (e.target.value.includes(".") === true) {
      if (e.target.value.split(".")[1].length > 2) {
        setbinSelPrice(parseFloat(e.target.value).toFixed(2));
        setbinPrice(parseFloat(e.target.value).toFixed(2));
      } else {
        setbinPrice(e.target.value);
        setbinSelPrice(e.target.value);
      }
    } else {
      setbinPrice(e.target.value);
      setbinSelPrice(e.target.value);
    }

    if (priceError) {
      setErrors({
        palletNameError,
        whseLocError,
        rcError,
        scanGroupError,
        pricingTypeError,
        priceError,
        binpriceError,
        positionError,
        locationEnabldError,
      });
      return false;
    } else {
      if (e.target.value.includes(".") === true) {
        if (e.target.value.split(".")[1].length > 2) {
          setbinPrice(parseFloat(e.target.value).toFixed(2));
          setbinSelPrice(parseFloat(e.target.value).toFixed(2));
        } else {
          setbinPrice(e.target.value);
          setbinSelPrice(e.target.value);
        }
      } else {
        setbinPrice(e.target.value);
        setbinSelPrice(e.target.value);
      }

      return true;
    }
  };

  const handleChagePricePercent = (e: any) => {
    if (e.target.value === "") {
      priceError = "Required ";
    }
    if (e.target.value > 100) {
      setPrice("");
      priceError = "Percent should not exceed 100%";
      e.target.value = "";
    } else {
      if (e.target.value.includes(".") === true) {
        if (e.target.value.split(".")[1].length > 2) {
          setPrice(parseFloat(e.target.value).toFixed(2));
        } else {
          setPrice(e.target.value);
        }
      } else {
        setPrice(e.target.value);
      }
    }
    if (priceError) {
      setErrors({
        palletNameError,
        whseLocError,
        rcError,
        scanGroupError,
        pricingTypeError,
        priceError,
        binpriceError,
        positionError,
        locationEnabldError,
      });
      return false;
    } else {
      if (e.target.value.includes(".") === true) {
        if (e.target.value.split(".")[1].length > 2) {
          setPrice(parseFloat(e.target.value).toFixed(2));
        } else {
          setPrice(e.target.value);
        }
      } else {
        setPrice(e.target.value);
      }
      return true;
    }
  };
  const handleChagePricePercentBinRate = (e: any) => {
    if (e.target.value === "") {
      binpriceError = "Required ";
    }
    if (e.target.value > 100) {
      setbinPrice("");
      binpriceError = "Percent should not exceed 100%";
      e.target.value = "";
    } else {
      if (e.target.value.includes(".") === true) {
        if (e.target.value.split(".")[1].length > 2) {
          setbinPrice(parseFloat(e.target.value).toFixed(2));
        } else {
          setbinPrice(e.target.value);
        }
      } else {
        setbinPrice(e.target.value);
      }
    }
    if (priceError) {
      setErrors({
        palletNameError,
        whseLocError,
        rcError,
        scanGroupError,
        pricingTypeError,
        priceError,
        binpriceError,
        positionError,
        locationEnabldError,
      });
      return false;
    } else {
      if (e.target.value.includes(".") === true) {
        if (e.target.value.split(".")[1].length > 2) {
          setbinPrice(parseFloat(e.target.value).toFixed(2));
        } else {
          setbinPrice(e.target.value);
        }
      } else {
        setbinPrice(e.target.value);
      }
      return true;
    }
  };
  const handleAddHasPallet = (e: any) => {
    if (e.target.value === "true") {
      setHasPalletPL(true);
    } else {
      setHasPalletPL(false);
    }
  };
  const handleAddLocationEnabled = (e: any) => {
    if (e.target.value === "true") {
      setLocationEnabledPL(true);
      setLocationEnabledPL1(false);
    } else {
      setLocationEnabledPL(false);
      setLocationEnabledPL1(false);
    }
    validate();
  };

  const body = {
    active: true,
    deleted: false,
    locationEnabled: locationEnabledPL,
    pallet: hasPalletPL,
    palletName: palletNamePL,
    position: positionPL,
    reclaimCenterId: reclaimCenterPL,
    scanGroupId: scanGroupPL,
    whLocationId: whPL,
    pricingType: pricingType,
    price: parseFloat(price),
    binPrice: parseFloat(binprice),
  };
  let tabName: any = ContactType.PALLET_LOCATIONS;
  const [convertedPrice, setConvertedPrice] = useState<any>();
  const handleAddPalletLocation = () => {
    if (validate()) {
      const endpoint = addMasterDataEndPoint(tabName);
      const getEndpoint = getEndPoint(tabName);
      handleMasterDataChange(endpoint, getEndpoint);
    }
  };
  const handleMasterDataChange = async (
    endpoint: string,
    getEndpoint: string
  ) => {
    const result = await axios
      .post(endpoint, body)
      .then((results: any) => {
        if (results && results.data) {
          setAddScreen(false);
          notify("Pallet Location created successfully", "success");
        } else {
          notify("Failed to create Pallet Location", "error");
        }
      })
      .catch((error: any) => {
        if (error.response) {
          notify(error.response?.data?.message, "error");
        }
      });
    const getresult = await axios.get(getEndpoint);
    dispatch(setCurrentContact(getresult.data));
  };
  const validate = (field?: any, value?: any) => {
    if (palletNamePL === "") {
      palletNameError = "Required Field";
      setMyPalletName("1px solid red");
    } else {
      setMyPalletName("");
    }
    if (whPL === "") {
      whseLocError = "Required Field";
      setMyWhseLocation("1px solid red");
    } else {
      setMyWhseLocation("");
    }
    if (reclaimCenterPL === "") {
      rcError = "Required Field";
      setMyReclaimCenter("1px solid red");
    } else {
      setMyReclaimCenter("");
    }
    if (scanGroupPL === "") {
      scanGroupError = "Required Field";
      setMyScanGroup("1px solid red");
    } else {
      setMyScanGroup("");
    }
    if (pricingType === "") {
      pricingTypeError = "Required Field";
      setMyPricingType("1px solid red");
    } else {
      setMyPricingType("");
    }
    if (price === "") {
      priceError = "Required";
      setMyRate("1px solid red");
    } else {
      setMyRate("");
    }
    if (binprice === "") {
      binpriceError = "Required";
      setbinMyRate("1px solid red");
    } else {
      setbinMyRate("");
    }
    if (positionPL === "") {
      positionError = "Required Field";
      setMyPosition("1px solid red");
    } else {
      setMyPosition("");
    }
    if (locationEnabledPL1) {
      locationEnabldError = "Required";
    }

    if (
      palletNameError ||
      whseLocError ||
      rcError ||
      scanGroupError ||
      pricingTypeError ||
      priceError ||
      positionError ||
      locationEnabldError
    ) {
      setErrors({
        palletNameError,
        whseLocError,
        rcError,
        scanGroupError,
        pricingTypeError,
        priceError,
        binpriceError,
        positionError,
        locationEnabldError,
      });
      return false;
    }
    return true;
  };
  const handleCancelPalletLocation = () => {
    setAddScreen(false);
  };
  return (
    <div className="palletpop" style={{marginRight: '0'}}>
      <div className="row">
        <div className="col-sm-12 textAlign-center">
          {/* <input accept="image/*" type="file" onChange={imageChange} /> */}
          <img src={userIcon} className="userAddIcon" />
          {selectedImage && (
            <div className="imagePreviewDiv">
              <img
                src={URL.createObjectURL(selectedImage)}
                className="imagePreview"
                height="100"
                width="100"
              />
            </div>
          )}
        </div>
        </div>

        <div className="col-sm-12 row m-0 mt-5">
          <div className="col-sm-6">
            <div className="col-sm-12">
              <span className="lblClass" style={{float: 'left', marginTop: '0'}}>
                Pallet Name<span className="requiredStar">*</span>
              </span>
              {palletNamePL === "" ? (
                <span className="text-danger" style={{float: 'right'}}>{errors.palletNameError}</span>
              ) : (
                ""
              )}
              <input
                type="text"
                className="formcontrol"
                onChange={(e) => handleAddPLPalletName(e)}
                style={{ border: myPalletName }}
              />
              
              {/* <select className="form-control" onChange={(e) => handleAddPLPalletName(e)}>
                <option>Select</option>
                {palletNameData?.map((pn) =>
                  <option value={pn.palletName}>{pn.palletName}</option>
                )}
              </select> */}
            </div> 
          
            <div className="col-sm-12">
              <span className="lblClass" style={{float: 'left', marginTop: '5px'}}>
                Whse Location<span className="requiredStar">*</span>
              </span>
              {whPL === "" ? (
                <span className="text-danger" style={{float: 'right', marginTop: '5px'}}>{errors.whseLocError}</span>
              ) : (
                ""
              )}
              <select
                className="formcontrol"
                onChange={(e) => handleWhseLocation(e)}
                style={{ border: myWhseLocation }}
              >
                <option hidden>Select</option>
                {whLocationsInfo?.map((rc) => (
                  <option value={rc.id}>{rc.warehouseLocation}</option>
                ))}
              </select>
            </div>
          
          
            <div className="col-sm-12">
              <span className="lblClass" style={{float: 'left', marginTop: '5px'}}>
                Reclaim Center<span className="requiredStar">*</span>
              </span>
              {reclaimCenterPL === "" ? (
                <span className="text-danger" style={{float: 'right', marginTop: '5px'}}>{errors.rcError}</span>
              ) : (
                ""
              )}
              <select
                className="formcontrol"
                onChange={(e) => handleAddPLRC(e)}
                style={{ border: myReclaimCenter }}
              >
                <option value="0" hidden>Select</option>
                {RCData?.map((rc) => (
                  <option value={rc.id}>{rc.reclaimcenterName}</option>
                ))}
              </select>
            </div>
          
            <div className="col-sm-12">
              <span className="lblClass" style={{float: 'left', marginTop: '5px'}}>
                Scan Group<span className="requiredStar">*</span>
              </span>
              {scanGroupPL === "" ? (
                <span className="text-danger" style={{float: 'right', marginTop: '5px'}}>{errors.scanGroupError}</span>
              ) : (
                ""
              )}
              <select
                className="formcontrol"
                onChange={(e) => handleAddPLSacnGroup(e)}
                style={{ border: myScanGroup }}
              >
                <option>{selectRCText}</option>
                {scanGroupByRc?.map((sg) => (
                  <option value={sg.id}>{sg.scanGroupCode}</option>
                ))}
              </select>
            </div>            
          </div>

          <div className="col-sm-6">
            <div className="col-sm-12 p-0">
            <div className="col-sm-6 p-1">
              <span className="lblClass" style={{float: 'left', marginTop: '0'}}>
                Pricing Type<span className="requiredStar">*</span>
              </span>
              {pricingType === "" ? (
                <span className="text-danger" style={{float: 'right', fontSize: '9px', marginTop: '3px'}}>{errors.pricingTypeError}</span>
              ) : (
                ""
              )}
              <select
                className="formcontrol"
                onChange={handlePricingType}
                style={{ border: myPricingType, padding: "0px" }}
              >
                <option value="0" hidden>Select</option>
                {pricingTypeData?.map((pt) => (
                  <option value={pt.pricingType}>{pt.pricingType}</option>
                ))}
              </select>
            </div>
            <div className="col-sm-6" style={{padding: '0px'}}>
            {showPriceDiv ? (
              <>
              <div className="col-sm-12 p-0">
                <div className="col-sm-6 p-1">
                  <span className="lblClass rate-class" style={{float: 'left', marginTop: '0'}}>
                   Box Rate ($)<span className="requiredStar">*</span>
                  </span>
                  <input
                    type="number"
                    className="formcontrol"
                    onChange={(e: any) => handleChagePrice(e)}
                    value={selPrice}
                    onBlur={(e) => handleChagePrice(e)}
                    onKeyUp={(e) => handleChagePrice(e)}
                    style={{ border: myRate }}
                  />
                  {price === "" ? (
                    <span className="text-danger">{errors.priceError}</span>
                  ) : (
                    ""
                  )}
                </div>
                <div className="col-sm-6 p-1">
                  <span className="lblClass rate-class" style={{float: 'left', marginTop: '0'}}>
                    Bin Rate ($)<span className="requiredStar">*</span>
                  </span>
                  <input
                    type="number"
                    className="formcontrol"
                    onChange={(e: any) => handleChagePriceBinRate(e)}
                    value={selbinPrice}
                    onBlur={(e) => handleChagePriceBinRate(e)}
                    onKeyUp={(e) => handleChagePriceBinRate(e)}
                    style={{ border: mybinRate }}
                  />
                  {binprice === "" ? (
                    <span className="text-danger">{errors.binpriceError}</span>
                  ) : (
                    ""
                  )}
                </div>
                </div>
              </>
            ) : (
              <>
              <div className="col-sm-12 p-0">
                <div className="col-sm-6 p-1">
                  <span className="lblClass rate-class" style={{float: 'left', marginTop: '0'}}>
                    Box Rate (%)<span className="requiredStar">*</span>
                  </span>
                  <input
                    type="number"
                    id="txtPercent"
                    className="form-control"
                    onChange={(e: any) => handleChagePricePercent(e)}
                    value={price}
                  />
                  {price === "" ? (
                    <span className="text-danger">{errors.priceError}</span>
                  ) : (
                    ""
                  )}
                </div>
                
                <div className="col-sm-6 p-1">
                  <span className="lblClass rate-class" style={{float: 'left', marginTop: '0'}}>
                    Bin Rate (%)<span className="requiredStar">*</span>
                  </span>
                  <input
                    type="number"
                    id="bintxtPercent"
                    className="form-control"
                    onChange={(e: any) => handleChagePricePercentBinRate(e)}
                    value={binprice}
                  />
                  {binprice === "" ? (
                    <span className="text-danger">{errors.binpriceError}</span>
                  ) : (
                    ""
                  )}
                </div>
                </div>
              </>
            )}      
            </div> 
            </div>   
            <div className="col-sm-12 p-0">
              <span className="lblClass" style={{float: 'left', marginTop: '8px'}}>
                Position<span className="requiredStar">*</span>
              </span>
              {positionPL === "" ? (
                <span className="text-danger" style={{float: 'right', marginTop: '5px'}}>{errors.positionError}</span>
              ) : (
                ""
              )}
              <input
                type="number"
                className="formcontrol"
                onChange={(e) => handleAddPLPosition(e)}
                style={{ border: myPosition }}
              />
            </div>
          
          {/* <div className="row ml-0 mr-0 mt-1rem">
            <div className="col-sm-4">
              <span className="lblClass">Has Pallet?</span>
            </div>
            <div className="col-sm-8" onChange={(e) => handleAddHasPallet(e)}>
              <div className="col-sm-3">
                <input type="radio" name="hasPallet" value="true" /> <span> Yes</span>
              </div>
              <div className="col-sm-3">
                <input type="radio" name="hasPallet" value="false" /><span> No</span>
              </div>
            </div>
          </div> */}
          
            <div className="col-sm-12 p-0">
              <span className="lblClass" style={{float: 'left', marginTop: '8px'}}>
                Location Enabled?<span className="requiredStar">*</span>
              </span>
            </div>
            <div
              className="col-sm-7 palletRatelbl p-0"
              onChange={(e) => handleAddLocationEnabled(e)}
            >
              <div className="col-sm-4 p-0">
                <input type="radio" name="locatioEnabled" value="true" />{" "}
                <span> Yes</span>
                <br />
                {locationEnabledPL1 ? (
                  <span className="text-danger">
                    {errors.locationEnabldError}
                  </span>
                ) : (
                  ""
                )}
              </div>
              <div className="col-sm-4 p-0">
                <input type="radio" name="locatioEnabled" value="false" />
                <span> No</span>
              </div>
            </div>
            </div>        
        </div>
        <div className="col-sm-12 px-0 mt-5">
        <ButtonComponent
          style={
            palletNamePL !== "" && pricingType !== "" && positionPL !== "" &&
            whPL !== "" && reclaimCenterPL !== "" && scanGroupPL !== ""
              ? {
                  opacity: "1",
                  pointerEvents: "all",
                  cursor: "pointer",
                }
              : {
                  opacity: "0.5",
                  pointerEvents: "none",
                  backgroundColor: "#e8e8e8",
                  color: "#969696",
                }
          }
          cssClass="eg-btn-primary float-right"
          onClick={() => handleAddPalletLocation()}
        >
          ADD PALLET LOCATION
        </ButtonComponent>
        <ButtonComponent
          cssClass="e-control e-btn e-lib eg-btn-primary-link e-flat mt-3"
          onClick={handleCancelPalletLocation}
        >
          CANCEL
        </ButtonComponent>
      </div>
    </div>
  );
};
export default PalletLocationAddScreen;
