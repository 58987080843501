import { createSlice } from "@reduxjs/toolkit";

export interface contactsState {
  selectedRowData: any[] | null;
  selectedRowDataId: any | null;
  selectedRowDataForTabs: any | null;
  gridConfig: any;
  selectedStoresOrVendorsRowData: any[] | null;
  gridRefInfo: any;
}

const initialState: contactsState = {
  selectedRowData: null,
  selectedRowDataId: null,
  selectedRowDataForTabs: null,
  gridConfig: null,
  selectedStoresOrVendorsRowData: null,
  gridRefInfo: null
};

export const gridSlice = createSlice({
  name: "gridReducer",
  initialState,
  reducers: {
    setRowData: (state, { payload }) => {
      state.selectedRowData = null;
      state.selectedRowData = payload;
    },

    setRowId: (state, { payload }) => {
      state.selectedRowDataId = null;
      state.selectedRowDataId = payload;
    },
    setRowDataForTabs: (state, { payload }) => {
      state.selectedRowDataForTabs = null;
      state.selectedRowDataForTabs = payload;
    },
    setGridConfigForTabs: (state, { payload }) => {
      state.gridConfig = null;
      state.gridConfig = payload;
    },
    setStoresORVendorsRowData: (state, { payload }) => {
      state.selectedStoresOrVendorsRowData = null;
      state.selectedStoresOrVendorsRowData = payload;
    },
    setGridRefInfo: (state, { payload }) => {
      state.gridRefInfo = null;
      state.gridRefInfo = payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setRowData,
  setRowId,
  setRowDataForTabs,
  setGridConfigForTabs,
  setStoresORVendorsRowData,
  setGridRefInfo
} = gridSlice.actions;

export default gridSlice.reducer;
