import "./ContactScreenWithTabs.scss";
import { DashboardLayoutComponent } from "@syncfusion/ej2-react-layouts";
import ContentHeader from "../common/ContentHeader/ContentHeader";
import CommonImageWrapper from "../common/CommonImageWrapper/CommonImageWrapper";
import inventory from "../../assets/inventorNew.png";
import items from "../../assets/itemsNew.png";
import partners from "../../assets/partnersNew.png";
import finance from "../../assets/FinanceNew.png";
import orgStruc from "../../assets/orgStrucNew.png";
import { setRowData } from "../grid/gridReducer";
import OrgStrucSvg from "../../assets/OrgStructureImg.svg";
import PartnerSvg from "../../assets/PartnersImg.svg";
import FinanceSvg from "../../assets/FinanceImg.svg";
import ItemsSvg from "../../assets/ItemsImg.svg";
import InventorySvg from "../../assets/InventoryImg.svg";
import {
  financeUrl,
  inventoryUrl,
  itemsUrl,
  MasterDataScreen,
  orgStructureUrl,
  partnersUrl,
} from "./constants";
import { useDispatch } from "react-redux";
import { setCurrentContact, setSelectedRecrdsExport } from "./contactReducer";

const MasterData = (props: any) => {
  const dispatch = useDispatch();
  const handleMasterDataScreenSelect = (screenType: string) => {
    dispatch(setRowData([]));
    dispatch(setSelectedRecrdsExport([]));
    dispatch(setCurrentContact([]));
    if (screenType === MasterDataScreen.PARTNERS) {
      props.history.push({
        pathname: `${props.match.url}/${partnersUrl}`,
        state: { value: "PartnersDefaultTab" },
      });
    } else if (screenType === MasterDataScreen.ORGSTRUCTURE) {
      // props.history.push(`${props.match.url}/${orgStructureUrl}`);
      props.history.push({
        pathname: `${props.match.url}/${orgStructureUrl}`,
        state: { value: "OrgStructureDefaultTab" },
      });
    } else if (screenType === MasterDataScreen.FINANCE) {
      props.history.push({
        pathname: `${props.match.url}/${financeUrl}`,
        state: { value: "FinanceDefaultTab" },
      });
    } else if (screenType === MasterDataScreen.ITEMS) {
      props.history.push({
        pathname: `${props.match.url}/${itemsUrl}`,
        state: { value: "ItemsDefaultTab" },
      });
    } else if (screenType === MasterDataScreen.INVENTORY) {
      props.history.push({
        pathname: `${props.match.url}/${inventoryUrl}`,
        state: { value: "InventoryDefaultTab" },
      });
    } else {
    }
  };
  return (
    <DashboardLayoutComponent
      id="defaultLayout"
      cellSpacing={[5, 5]}
      allowResizing={true}
      columns={5}
    >
      <div className="invoices-home" data-row="0" data-col="0" data-sizex="6">
        <ContentHeader value="Master Data" />
        <div className="invoice-images">
          <CommonImageWrapper
            src={OrgStrucSvg}
            width={250}
            height={300}
            gradientColor={"#F2CA52"}
            customclassName="txtOrgstructure"
            customImg="imgOrg"
            barColor={"#F2CA52"}
            header="Org Structure"
            content="View and manage internal organizational strucutre."
            onClick={() =>
              handleMasterDataScreenSelect(MasterDataScreen.ORGSTRUCTURE)
            }
          />
          <CommonImageWrapper
            src={PartnerSvg}
            width={250}
            height={300}
            gradientColor={"#8C4D3F"}
            barColor={"#8C4D3F"}
            customclassName="txtPartners"
            customImg="imgPart"
            header="Partners"
            content="Record contact information and settings of external strategic partners."
            onClick={() =>
              handleMasterDataScreenSelect(MasterDataScreen.PARTNERS)
            }
          />
          <CommonImageWrapper
            src={FinanceSvg}
            width={250}
            height={300}
            gradientColor={"#79C24F"}
            barColor={"#79C24F"}
            customclassName="txtFinance"
            customImg="imgFinance"
            header="Finance"
            content="Integrate with your financial systems through linking account numbers and departments."
            onClick={() =>
              handleMasterDataScreenSelect(MasterDataScreen.FINANCE)
            }
          />
          <CommonImageWrapper
            src={ItemsSvg}
            width={250}
            height={300}
            gradientColor={"#737AE6"}
            barColor={"#737AE6"}
            customclassName="txtIt"
            customImg="imgIt"
            header="Items"
            content="Manage your item catalog and attributes."
            onClick={() => handleMasterDataScreenSelect(MasterDataScreen.ITEMS)}
          />
          <CommonImageWrapper
            src={InventorySvg}
            width={250}
            height={300}
            gradientColor={"#636799"}
            barColor={"#636799"}
            customclassName="txtInventory"
            customImg="imgInventory"
            header="Inventory"
            content="Manage current and historical inventory, as well as floor locations."
            onClick={() =>
              handleMasterDataScreenSelect(MasterDataScreen.INVENTORY)
            }
          />
        </div>
      </div>
    </DashboardLayoutComponent>
  );
};

export default MasterData;
