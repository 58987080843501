import { useDispatch, useSelector } from "react-redux";
import { generateGridData, getTabData } from "./utils";
import { RootState } from "../../redux/store";
import { config } from "../grid/config";
// import Grid from "../grid/Grid";
import Grid from "../grid/fiscal-year-setup-grid/fiscal-year-setup-grid";

import { ContactType } from "./constants";
import axios from "axios";
import { setCurrentContact } from "./contactReducer";
import { useState } from "react";

const FiscalYearViewScreen = (props: any) => {
  const tab = ContactType.FISCAL_YEAR_SETUP;
  const dispatch = useDispatch();
  const [fiscalYearCal, setFiscalYearCal] = useState("");
  const currentContact = useSelector(
    (state: RootState) => state.contactReducer?.currentContact
  );
  const FiscYearData = useSelector(
    (state: RootState) => state.contactReducer?.fiscalYears
  );
  const tabName: any = ContactType.FISCAL_YEAR_SETUP;
  const handleChangeFiscalYearCalendar = (e: any) => {
    setFiscalYearCal(e.target.value);
    const endpoint = getTabData(tabName, e.target.value);
    handleMasterDataChange(endpoint, e);
  };
  const handleMasterDataChange = async (endpoint: string, e: any) => {
    const result = await axios.get(endpoint, e);
    if (result && result.data) {
      dispatch(setCurrentContact(result.data));
    } else {
    }
  };
  return (
    <>
      {/* <hr /> */}
      {/* <div className="row">
        <div className="col-sm-2">
          <select className="form-control" onChange={(e) => handleChangeFiscalYearCalendar(e)}>
            <option value=''>Select</option>
            {FiscYearData?.map((fy) =>
              <option value={fy.id}>{fy.calendarName}</option>
            )}
          </select>
        </div>
      </div>  <br /> */}
      {currentContact.length === 0 ? (
        <div className="fiscalYearNoData">
          <div className="emptyDivText">
            Select calendar from menu or configure a new calendar
          </div>
        </div>
      ) : (
        <Grid
          config={config[tab]}
          data={generateGridData(ContactType.FISCAL_YEAR_SETUP, currentContact)}
        />
      )}
      {/* <fiscalYearSetupGrid></fiscalYearSetupGrid> */}
    </>
  );
};
export default FiscalYearViewScreen;
