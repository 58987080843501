import { FC, useRef, useState } from 'react';
import {
    Column,
    ColumnDirective,
    ColumnsDirective,
    CommandColumn,
    CommandModel,
    Edit,
    Filter,
    FilterSettingsModel,
    Grid,
    GridComponent,
    Inject,
    Page,
    QueryCellInfoEventArgs,
    RowDataBoundEventArgs,
    SelectionSettingsModel,
    Sort,
} from '@syncfusion/ej2-react-grids';
import { getValue, Internationalization } from '@syncfusion/ej2-base';
import { useDispatch, useSelector } from 'react-redux';
import { setRowData, setRowId } from '../gridReducer';
import { getDefaultFilterOperator, pageOptions } from '../gridConfig';
import './fiscal-year-setup-grid.scss';
import { RootState } from '../../../redux/store';
import { getTabData } from '../../contactTabs/utils';
import axios from 'axios';
import { ContactType } from '../../contactTabs/constants';
import notify from '../../../services/toasterService';
import {
    setSelectedRCIdForBin,
    setSelectedRecrdsExport,
} from '../../contactTabs/contactReducer';
import { setSelectedIdForCancel } from '../../invoices/invoiceReducer';
import loader from '../../../assets/loader.gif';

const FilterOptions: FilterSettingsModel = {
    columns: [],
    type: 'FilterBar',
    mode: 'Immediate',
    showFilterBarStatus: true,
    immediateModeDelay: 10,
    operators: {},
    showFilterBarOperator: true,
    ignoreAccent: true,
};
const editSettings = {
    allowEditing: true,
    allowAdding: true,
    allowDeleting: true,
    allowEditOnDblClick: false,
};
const commands: CommandModel[] = [
    {
        buttonOption: {
            content: 'Active',
            cssClass: 'eg-btn-success',
        },
    },
];

interface IGridWrapper {
    config: any[];
    data: any[];
    width?: string;
    height?: string;
    onRowSelect?: (selectedRecords: any[]) => void;
    onRowDeselect?: (selectedRecords: any[]) => void;
    onPaging?: (pageno: number) => void;
    onDelete?: (selectedRecords: any[]) => void;
    pageConfig?: { pageNO: number; totalRecords: number; totalPages: number };
    pageNo?: number;
    hideSpinner?: boolean;
    isFromInvoice?: boolean;
    onCheckBox?: (rowId: number) => void;
    onFilter?: (text: string) => void;
    isFromActionHistory?: boolean;
}

// @ts-ignore
const FiscalYearSetupGrid: FC<IGridWrapper> = ({
    config,
    data,
    onRowSelect,
    onRowDeselect,
    onPaging,
    onDelete,
    onCheckBox,
    onFilter,
    isFromInvoice,
    width = '',
    height = '434',
    pageConfig = { pageNO: 0, totalRecords: 0, totalPages: 0 },
    pageNo = 1,
    hideSpinner,
    isFromActionHistory,
}) => {
    // @ts-ignore
    //let gridRef: Grid | null = useRef(null);
    // const gridRef = useRef<GridComponent | null>(null);
    // let dataArray = [];
    // let pageNo = pageConfig.pageNO;
    // dataArray.push(data);
    let gridRef: Grid | null = useRef(null);
    const dispatch = useDispatch();
    let intl = new Internationalization();
    //let pageNo = pageConfig.pageNO;
    let selectedRow: any[];
    const tabNameForRow = useSelector(
        (state: RootState) => state.contactReducer.tabNameForRowData
    );

    const customizeCell = (args: QueryCellInfoEventArgs) => {
        if (
            (args.column as Column).field === 'activeForGrid' &&
            args.data &&
            args.cell
        ) {
            if (getValue('activeForGrid', args.data) === 'Active') {
                args.cell.innerHTML = `<span class='btnActiveClass'>${getValue(
                    'activeForGrid',
                    args.data
                )}</span>`;
            } else if (
                getValue('activeForGridVendor', args.data) === 'Active'
            ) {
                args.cell.innerHTML = `<span class='btnActiveClass'>${getValue(
                    'activeForGridVendor',
                    args.data
                )}</span>`;
            } else if (getValue('activeForGrid', args.data) === 'Open') {
                args.cell.innerHTML = `<span class='btnActiveClass'>${getValue(
                    'activeForGrid',
                    args.data
                )}</span>`;
            } else if (getValue('activeForGrid', args.data) === 'Approved') {
                args.cell.innerHTML = `<span class='btnApprovedClass'>${getValue(
                    'activeForGrid',
                    args.data
                )}</span>`;
            } else if (getValue('activeForGrid', args.data) === 'Denied') {
                args.cell.innerHTML = `<span class='btnDeniedClass'>${getValue(
                    'activeForGrid',
                    args.data
                )}</span>`;
            } else if (
                getValue('activeForGridVendor', args.data) === 'InActive'
            ) {
                args.cell.innerHTML = `<span class='btnInActiveClass'>${getValue(
                    'activeForGridVendor',
                    args.data
                )}</span>`;
            } else {
                args.cell.innerHTML = `<span class='btnInActiveClass'>${getValue(
                    'activeForGrid',
                    args.data
                )}</span>`;
            }
        }
        if (
            (args.column as Column).field === 'locationEnabledForGrid' &&
            args.data &&
            args.cell
        ) {
            if (getValue('locationEnabledForGrid', args.data) === 'Yes') {
                args.cell.innerHTML = `<span class='btnActiveClass'>${getValue(
                    'locationEnabledForGrid',
                    args.data
                )}</span>`;
            } else {
                args.cell.innerHTML = `<span class='btnActiveNoClassPallet'>${getValue(
                    'locationEnabledForGrid',
                    args.data
                )}</span>`;
            }
        }
        if (
            (args.column as Column).field === 'activeForGridItemCatalog' &&
            args.data &&
            args.cell
        ) {
            if (getValue('activeForGridItemCatalog', args.data) === 'Yes') {
                args.cell.innerHTML = `<span class='btnActiveClass'>${getValue(
                    'activeForGridItemCatalog',
                    args.data
                )}</span>`;
            } else {
                args.cell.innerHTML = `<span class='btnActiveNoClassPallet'>${getValue(
                    'activeForGridItemCatalog',
                    args.data
                )}</span>`;
            }
            if (
                (args.column as Column).field === 'status' &&
                args.data &&
                args.cell
            ) {
                if (getValue('status', args.data) === 'Cancelled') {
                    args.cell.innerHTML = `<span class='btnInActiveClass'>${getValue(
                        'status',
                        args.data
                    )}</span>`;
                } else if (
                    getValue('status', args.data) === 'Ready for Salvager'
                ) {
                    args.cell.innerHTML = `<span class='btnActiveClass'>${getValue(
                        'status',
                        args.data
                    )}</span>`;
                } else if (getValue('status', args.data) === 'Draft') {
                    args.cell.innerHTML = `<span class='btnDraftClass'>${getValue(
                        'status',
                        args.data
                    )}</span>`;
                } else if (
                    getValue('status', args.data) === 'Ready for Extract'
                ) {
                    args.cell.innerHTML = `<span class='btnActiveClass'>${getValue(
                        'status',
                        args.data
                    )}</span>`;
                }
                // else if (
                //   getValue("status", args.data) === "Pickup, Manual Override"
                // ) {
                //   args.cell.innerHTML = `<span class='btnPickupClass'>${getValue(
                //     "status",
                //     args.data
                //   )}</span>`;
                // }
            }
        }

        // if ((args.column as Column).field === "delete" && args.data && args.cell) {
        //   if (getValue("delete", args.data) === "delete") {
        //     args.cell.innerHTML = `<span class='deleteItem'><p class='e-icons e-delete'></p></span>`;
        //   }
        // }

        if (
            (args.column as Column).field === 'status' &&
            args.data &&
            args.cell
        ) {
            if (getValue('status', args.data) === 'Cancelled') {
                args.cell.innerHTML = `<span class='btnCancelledClass'>${getValue(
                    'status',
                    args.data
                )}</span>`;
            } else if (getValue('status', args.data) === 'Ready Slvg') {
                args.cell.innerHTML = `<span class='btnReadySlvgClass'>${getValue(
                    'status',
                    args.data
                )}</span>`;
            } else if (getValue('status', args.data) === 'Draft') {
                args.cell.innerHTML = `<span class='btnDraftClass'>${getValue(
                    'status',
                    args.data
                )}</span>`;
            } else if (getValue('status', args.data) === 'Ready Extrct') {
                args.cell.innerHTML = `<span class='btnReadyExtctClass'>${getValue(
                    'status',
                    args.data
                )}</span>`;
            } else if (getValue('status', args.data) === 'Pickup Mnul') {
                args.cell.innerHTML = `<span class='btnPickupClass'>${getValue(
                    'status',
                    args.data
                )}</span>`;
            } else if (getValue('status', args.data) === 'Pickup Auto') {
                args.cell.innerHTML = `<span class='btnPickupClass'>${getValue(
                    'status',
                    args.data
                )}</span>`;
            } else if (getValue('status', args.data) === 'Pickup, Hold') {
                args.cell.innerHTML = `<span class='btnPickupClass'>${getValue(
                    'status',
                    args.data
                )}</span>`;
            } else if (getValue('status', args.data) === 'Final Close') {
                args.cell.innerHTML = `<span class='btnFinalCloseClass'>${getValue(
                    'status',
                    args.data
                )}</span>`;
            } else if (getValue('status', args.data) === 'Picked') {
                args.cell.innerHTML = `<span class='btnPickedClass'>${getValue(
                    'status',
                    args.data
                )}</span>`;
            } else if (getValue('status', args.data) === 'In Process') {
                args.cell.innerHTML = `<span class='btnInProcessCMClass'>${getValue(
                    'status',
                    args.data
                )}</span>`;
            } else if (getValue('status', args.data) === 'Approved') {
                args.cell.innerHTML = `<span class='btnApprovedCMClass'>${getValue(
                    'status',
                    args.data
                )}</span>`;
            } else if (getValue('status', args.data) === 'Rejected') {
                args.cell.innerHTML = `<span class='btnRejectedCMClass'>${getValue(
                    'status',
                    args.data
                )}</span>`;
            }
        }
        if (
            (args.column as Column).field === 'activeFlagBinException' &&
            args.data &&
            args.cell
        ) {
            if (getValue('activeFlagBinException', args.data) === 'Active') {
                args.cell.innerHTML = `<span class='btnActiveClass'>${getValue(
                    'activeFlagBinException',
                    args.data
                )}</span>`;
            } else {
                args.cell.innerHTML = `<span class='btnInActiveClass'>${getValue(
                    'activeFlagBinException',
                    args.data
                )}</span>`;
            }
        }
        if (
            (args.column as Column).field === 'authorizationFlagGrid' &&
            args.data &&
            args.cell
        ) {
            if (getValue('authorizationFlagGrid', args.data) === 'Yes') {
                args.cell.innerHTML = `<span class='btnActiveClass'>${getValue(
                    'authorizationFlagGrid',
                    args.data
                )}</span>`;
            } else {
                args.cell.innerHTML = `<span class='btnActiveNoClassPallet'>${getValue(
                    'authorizationFlagGrid',
                    args.data
                )}</span>`;
            }
        }

        if (
            (args.column as Column).field === 'cost' &&
            args.data &&
            args.cell
        ) {
            let nFormatter = intl.getNumberFormat({
                skeleton: 'C3',
                currency: 'USD',
                minimumIntegerDigits: 0,
                minimumFractionDigits: 2,
            });
            args.cell.innerHTML = `$${nFormatter(getValue('cost', args.data))}`;
        }

        if (
            (args.column as Column).field === 'price' &&
            args.data &&
            args.cell
        ) {
            let nFormatter = intl.getNumberFormat({
                skeleton: 'C3',
                currency: 'USD',
                minimumIntegerDigits: 0,
                minimumFractionDigits: 2,
            });

            args.cell.innerHTML = `$${nFormatter(
                getValue('price', args.data)
            )}`;
        }

        if (
            (args.column as Column).field === 'totalItemCost' &&
            args.data &&
            args.cell
        ) {
            let nFormatter = intl.getNumberFormat({
                skeleton: 'C3',
                currency: 'USD',
                minimumIntegerDigits: 0,
                minimumFractionDigits: 2,
            });

            args.cell.innerHTML = `$${nFormatter(
                getValue('totalItemCost', args.data)
            )}`;
        }

        if (
            (args.column as Column).field === 'totalItemsCount' &&
            args.data &&
            args.cell
        ) {
            let nFormatter = intl.getNumberFormat({
                skeleton: 'C3',
                currency: 'USD',
                minimumIntegerDigits: 0,
                minimumFractionDigits: 0,
            });

            args.cell.innerHTML = `${nFormatter(
                getValue('totalItemsCount', args.data)
            )}`;
        }

        if (
            (args.column as Column).field === 'rate' &&
            args.data &&
            args.cell
        ) {
            args.cell.innerHTML = `${getValue('rate', args.data)}%`;
        }

        if (
            (args.column as Column).field === 'pricePerBox' &&
            args.data &&
            args.cell
        ) {
            let nFormatter = intl.getNumberFormat({
                skeleton: 'C3',
                currency: 'USD',
                minimumIntegerDigits: 0,
                minimumFractionDigits: 2,
            });
            args.cell.innerHTML = `$${nFormatter(
                getValue('pricePerBox', args.data)
            )}`;
            // args.cell.innerHTML = `$${getValue("fixedRate", args.data)}`;
        }
    };

    const handleGetSelectedRowData = async (endpoint: string) => {
        //   //const result = await axios.get(endpoint);
        //   if (result && result?.data) {
        //     dispatch(setRowData([result?.data]));
        //     onRowSelect && onRowSelect([result?.data]);
        //   } else {
        //     alert("Error");
        //   }
        // };
        console.log('.....');
        const result = await axios
            .get(endpoint)
            .then((results: any) => {
                setLoaderStatus(false);
                if (results && results.data) {
                    console.log(results);
                    dispatch(setRowData([results?.data]));
                    //     onRowSelect && onRowSelect([result?.data]);
                } else {
                    notify('Failed ', 'error');
                }
            })
            .catch((error: any) => {
                setLoaderStatus(false);
                if (tabNameForRow === ContactType.BIN_EXCEPTIONS) {
                    dispatch(setRowData([{ NoRecord: '' }]));
                } else {
                    // if (error.response) {
                    //   notify(error.response?.data?.message, "error");
                    // }
                }
            });
    };

    const onDataBound = () => {
        if (gridRef) {
            gridRef.element.addEventListener('click', onClick.bind(this));
        }
    };

    const onClick = (args: any) => {
        if (args.target.closest('.deleteItem')) {
            var columnText = args.target.innerText;
            // alert("columnText" + " is clicked");
            //handleDelete();
        }
    };

    const handleDelete = (e: any) => {
        // alert("zdf");
        if (gridRef) {
            // alert(JSON.stringify(e.commandColumn));
            e.cancel = true;
            //if (e.commandColumn.type === "Delete") {
            onDelete && onDelete(selectedRow);

            // }
        }
        // console.log(e);
        //}
    };

    const rowDataBound = (args: RowDataBoundEventArgs) => {
        // console.log(args);
        if (args.row) {
            if (getValue('isDeleted', args.data) === 'Yes') {
                args.row.classList.add('trash-row');
            }
        }
    };

    const handleSelectedRow = (event: any) => {
        console.log(event, isFromInvoice);
        //setTimeout(() =>{
        console.log('event event', event);
        if (
            event.target.classList.contains('e-check') &&
            isFromInvoice === true
        ) {
            event.cancel = true;
            // const selectedrecords: object[] = gridRef.getSelectedRecords();
            // selectedRow = gridRef.getSelectedRecords();
            // dispatch(setSelectedRecrdsExport(selectedrecords));
            dispatch(setSelectedIdForCancel(event));
        } else {
            if (
                gridRef &&
                (event.target.classList.contains('e-selectionbackground') ||
                    event.target.classList.contains('e-check'))
            ) {
                const selectedrecords: object[] = gridRef.getSelectedRecords();
                selectedRow = gridRef.getSelectedRecords();
                console.log(selectedrecords);
                dispatch(setSelectedRecrdsExport(selectedrecords));
                console.log(tabNameForRow);
                if (
                    tabNameForRow === ContactType.INVENTORY_CATALOG ||
                    tabNameForRow === ContactType.ITEM_CATALOG ||
                    tabNameForRow === ContactType.RULE_EXCEPTIONS ||
                    tabNameForRow === ContactType.COMPANY_CODES ||
                    tabNameForRow === ContactType.STORES ||
                    tabNameForRow === ContactType.CARRIERS ||
                    tabNameForRow === ContactType.VENDORS ||
                    tabNameForRow === ContactType.CUSTOMERS ||
                    tabNameForRow === ContactType.RECLAIM_CENTERS ||
                    tabNameForRow === ContactType.WAREHOUSES ||
                    tabNameForRow === ContactType.DEPARTMENTS ||
                    tabNameForRow === ContactType.GL_ACCOUNTS ||
                    tabNameForRow === ContactType.COMMODITY_TYPES ||
                    tabNameForRow === ContactType.PALLET_LOCATIONS ||
                    tabNameForRow === ContactType.STORE_DISPOSITIONS ||
                    tabNameForRow === ContactType.CustomWHLoc ||
                    tabNameForRow === ContactType.BIN_EXCEPTIONS
                ) {
                    const endpoint = getTabData(tabNameForRow, event.data.id);
                    dispatch(setRowData([]));
                    handleGetSelectedRowData(endpoint);
                    dispatch(setSelectedRCIdForBin(event.data.reclaimCenterId));
                    dispatch(setRowId(event.data));
                } else {
                    if (selectedrecords.length !== 0) {
                        dispatch(setRowData(selectedrecords));
                        dispatch(setRowId(event.data));
                        onRowSelect && onRowSelect(selectedrecords);
                        onRowDeselect && onRowDeselect(selectedrecords);
                    }
                    return false;
                }
            } else if (gridRef) {
                const selectedrecords: object[] = gridRef.getSelectedRecords();
                selectedRow = gridRef.getSelectedRecords();
                dispatch(setSelectedRecrdsExport(selectedrecords));
                onRowDeselect && onRowDeselect(selectedrecords);
            }
        }
        //}, 2000)
    };

    const [loaderStatus, setLoaderStatus] = useState(true);

    const selectionOptions: SelectionSettingsModel = {
        checkboxMode: 'ResetOnRowClick',
        mode: 'Row',
        type: 'Single',
    };
    const handlePagination = async (tablename: string, pageNo: number) => {
        console.log(pageNo);
        // const endpoint = getEndPoint(tabNameForRow,pageNo,1000);
        // const result = await axios.get(endpoint).then((data)=>{
        //   if (data) {
        //     let storesArray:any =  data.data;
        //     gridRef.current?.hideSpinner()
        //     dispatch(setCurrentContact(storesArray.content));
        //   } else {
        //     alert("Error");
        //   }
        //});
        // gridRef && gridRef.hideSpinner()
        onPaging && onPaging(pageNo);
    };
    const onFirstGridCreated = () => {
        if (gridRef) {
            //gridRef.current.locale="en-US";
            //   createSpinner({
            //     // Specify the target for the spinner to show
            //     target: document.getElementById('container') as HTMLElement,
            //     width: '30px'
            // });
            // showSpinner(document.getElementById('container') as HTMLElement);
            !hideSpinner && gridRef.showSpinner();
        }
        //createSpinner() method is used to create spinner

        // showSpinner() will make the spinner visible
    };
    const onActionComplete = (args: any) => {
        if (
            tabNameForRow === ContactType.STORES ||
            tabNameForRow === ContactType.VENDORS ||
            tabNameForRow === ContactType.ITEM_CATALOG
        ) {
            if (gridRef) {
                // gridRef.current?.setGridPager(template)
                /** height of the each row */
                console.log(gridRef);
                // ContactScreenWithTabs.handleGridDataChange('','','')
                //dispatch(setGridConfigForTabs(gridRef));
                const totalcount = gridRef.pageSettings.totalRecordsCount;
                const totalpages =
                    (totalcount as number) /
                    (gridRef.pageSettings.pageSize as number);

                if (gridRef.pageSettings.currentPage === totalpages) {
                    pageNo++;
                    gridRef.showSpinner();
                    handlePagination(ContactType.STORES, pageNo);
                }
                // const rowHeight: number = gridRef.getRowHeight();
                // console.log(rowHeight)
                // /** Grid height */
                // const gridHeight: number = gridRef.height as number;
                // console.log(gridHeight)
                // /** initial page size */
                // const pageSize: number = gridRef.pageSettings.pageSize as number;
                // console.log(pageSize)
                // /** new page size is obtained here */
                // const pageResize: any = (gridHeight - (pageSize * rowHeight)) / rowHeight;
                // console.log(parseInt(pageResize))
                // gridRef.pageSettings.pageSize = pageSize + Math.round(pageResize);
                // console.log(pageSize)
                // (document.getElementById('container') as HTMLElement).style.display="none";
            }
        }
    };

    const actionCompleteByUser = (args: any) => {
        console.log(args);
        onFilter && onFilter(args);
    };

    console.log(gridRef);
    return (
        <>
            {/* <div id="container" className="custom-rolling"><div></div></div> */}
            <GridComponent
                className="fiscal-grid"
                id="FGrid"
                ref={(g) => (gridRef = g)}
                // enableStickyHeader={true}
                dataSource={data}
                queryCellInfo={customizeCell}
                width={width}
                rowHeight={48}
                height={height}
                created={onFirstGridCreated}
                onClick={onActionComplete}
                allowSorting={true}
                allowPaging={true}
                filterSettings={FilterOptions}
                allowFiltering={true}
                selectionSettings={selectionOptions}
                pageSettings={pageOptions}
                rowSelected={(event) => handleSelectedRow(event)}
                rowDeselected={(event) => handleSelectedRow(event)}
                editSettings={editSettings}
                commandClick={(e) => handleDelete(e)}
                actionComplete={actionCompleteByUser}
                rowDataBound={rowDataBound}
            >
                <ColumnsDirective>
                    {/* <ColumnDirective type="checkbox" width="50" /> */}
                    {config.map((col: any) => {
                        return (
                            <ColumnDirective
                                filter={getDefaultFilterOperator(col.dataType)}
                                key={col.field}
                                field={col.field}
                                headerText={col.header}
                                width={col.width ? col.width : '100'}
                                textAlign={col?.textAlign}
                                commands={col?.commands}
                            />
                        );
                    })}
                </ColumnsDirective>
                <Inject services={[Page, Sort, Filter, CommandColumn, Edit]} />
            </GridComponent>
            {/* {isFromActionHistory ? 
      '': <div className="LoadImg">
          {loaderStatus ? (
          <img className="loaderClass" src={loader} />
          ) : <></>}
          </div>
      } */}
        </>
    );
};

export default FiscalYearSetupGrid;
