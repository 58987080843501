import { ButtonComponent } from "@syncfusion/ej2-react-buttons";
import saveIcon from "../../../icons/common/save.png";
import closeIcon from "../../../icons/common/close.png";
import activeIcon from "../../../icons/common/active.png";
import inactiveIcon from "../../../icons/common/inactive.png";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getLlAdminEndPoint, updateLlAdminEndPoint } from "../utils";
import axios from "axios";
import { RootState } from "../../../redux/store";
import notify from "../../../services/toasterService";
import { setRowData } from "../../grid/gridReducer";
import { setMsgMgmtData } from "../lladminreducer";

const PreviewMsgMmt = () => {
  const dispatch = useDispatch();
  const [msgMgmtInfo, setMsgMgmtInfo] = useState<any[]>([]);

  const languageData = useSelector(
    (state: RootState) => state.lladminReducer.laungageData
  );

  /* Get selected data from Grid Reducer */
  const selRowData = useSelector(
    (state: RootState) => state.gridReducer.selectedRowData
  );

  useEffect(() => {
    if (selRowData && selRowData?.length > 0) {
        setMsgMgmtInfo(selRowData);
    }
    // Write for onload
  }, [selRowData]);

  const reclaimScanTextFieldFocusOut = (value: any, key: any) => {
    setMsgMgmtInfo([{ ...msgMgmtInfo[0], [key]: value }]);
  };

  const msgMgmtFocusOut = (event: any, key: any) => {
    setMsgMgmtInfo([{ ...msgMgmtInfo[0], [key]: event.target.value }]);
  };

  /**
   * Changing status for Preview selected tabs
   * @param flag - true/false
   */
  const changeStatusForRowData = (flag: any) => {
    if (flag === false) {
      setMsgMgmtInfo([
        { ...msgMgmtInfo[0], activeForGrid: "Inactive" },
      ]);
    } else {
      setMsgMgmtInfo([{ ...msgMgmtInfo[0], activeForGrid: "Active" }]);
    }
  };

  const clearPreview = () => {
    dispatch(setRowData([]));
  };

  /**
   * PUT web service methods for Location setting tabs
   * @param data - Tab Name
   */
  const updateLocationSettingTabApi = async (data: any) => {
    // console.log(data);
    const endPointURL = updateLlAdminEndPoint(data.tabApiName, data.id);
    let body = {
      id: data.id,
      messageType: data.type,
      languageKey: data.languageKey,
      messageText: data.text,
    };
    const status = data.activeForGrid.toLowerCase();
    if (status === "active") {
      Object.assign(body, { active: true });
    } else {
      Object.assign(body, { active: false });
    }
    await axios
      .put(endPointURL, body)
      .then((res) => {
        notify(data.tabApiName + " updated successfully", "success");
        getlAdminTabs(data.tabApiName);
      })
      .catch((err) => {
        console.log(err, data.tabApiName);
        notify("Failed to update " + data.tabApiName, "error");
      });
  };

  const getlAdminTabs = async (tabName: any) => {
    const endPointURL = getLlAdminEndPoint(tabName);
    await axios
      .get(endPointURL)
      .then((res) => {
          dispatch(setMsgMgmtData(res.data));
        // if (tabName === "Reclaim Scan") {
        //   dispatch(setReclaimMsgData\(res.data));
        // } else if (tabName === "Mobile") {
        //   dispatch(setMobileMsgData(res.data));
        // }
      })
      .catch((err) => {
        console.log(err, "Reclaim Scan tab");
        notify("Failed to update Reclaim Scan data", "error");
      });
  };

  const footerPreview = (data: any) => {
    return (
      <div className="col-sm-12 px-0 pt-5 ">
        <div className="col-sm-6 col-md-6 col-lg-6 pl-1.5rem pr-1 preview-Key ">
          <ButtonComponent
            cssClass="e-outline opt-btn-light float-left"
            onClick={() => updateLocationSettingTabApi(data)}
          >
            <img src={saveIcon} alt="Save" />
          </ButtonComponent>

          <ButtonComponent
            cssClass="e-outline opt-btn-light float-left ml-3"
            onClick={() => clearPreview()}
          >
            <img src={closeIcon} alt="Close" />
          </ButtonComponent>
        </div>
        <div className="col-sm-6 col-md-6 col-lg-6 preview-value pt-3">
          <p className="preview-status">
            {data?.activeForGrid === "Active" ||
            data?.activeForGrid === "active" ? (
              <span>
                <img
                  src={activeIcon}
                  alt="Status"
                  onClick={() => changeStatusForRowData(false)}
                />
                &nbsp; &nbsp; Active
              </span>
            ) : (
              <span>
                <img
                  src={inactiveIcon}
                  alt="Status"
                  onClick={() => changeStatusForRowData(true)}
                />
                &nbsp; &nbsp; Inactive
              </span>
            )}
          </p>
        </div>
      </div>
    );
  };

  return (
    <div>
      {msgMgmtInfo && msgMgmtInfo.length > 0 ? (
        <div className="col-sm-12 px-3 py-3 preview-details  ">
          <div className="col-sm-12 px-0 pt-4 ">
            <div className="col-sm-6 preview-Key">
              <span className="storeNumHeading" style={{lineHeight: '20px'}}>Message ID</span>
            </div>
            <div className="col-sm-6 preview-value">
            <span className="storeNumHeading">{msgMgmtInfo[0]?.id}</span>
            </div>
          </div>
          <div className="col-sm-12 px-0 pt-5">
            <div className="col-sm-6 preview-Key">
            <span className="storeNumHeading" style={{lineHeight: '30px'}}>Message Type</span>
            </div>
            <div className="col-sm-6 p-0 preview-value">
              <select
                className="form-control border-none storeNumber"
                value={msgMgmtInfo[0]?.type}
                onChange={(e) => msgMgmtFocusOut(e, "type")}
              >
                {/* <option value={msgMgmtInfo[0]?.type}>{msgMgmtInfo[0]?.type}</option> */}
                <option value="E">Error</option>
                <option value="I">Information</option>
                <option value="S">Success</option>
                <option value="W">Warning</option>
              </select>
            </div>
          </div>
          <div className="col-sm-12 px-0 pt-5">
            <div className="col-sm-6 preview-Key">
            <span className="storeNumHeading" style={{lineHeight: '30px'}}>Language</span>
            </div>
            <div className="col-sm-6 p-0 preview-value">
              <select
              id="ddlLaguage"
                className="form-control border-none storeNumber"
                value={msgMgmtInfo[0]?.languageKey}
                onChange={(e) => msgMgmtFocusOut(e, "languageKey")}
                >
                <option value={msgMgmtInfo[0]?.languageKey}>{msgMgmtInfo[0]?.languageKey}</option>
                {languageData?.map((ld) => (
                  <option value={ld?.isoLongCode}>{ld?.isoLongCode}</option>
                ))}
              </select>
            </div>
          </div>

          <div className="col-sm-12 p-0 mb-auto">
            <hr className="divider" />
          </div>

          <div className="col-sm-12 px-0">
            <div className="col-sm-4 preview-Key">
              Msg Text
            </div>
            <div className="col-sm-8 p-0 preview-value ">
              <textarea
                className="notes-preview p-3"
                id=""
                rows={8}
                value={msgMgmtInfo[0]?.text}
                onChange={(e) =>
                  reclaimScanTextFieldFocusOut(e.target.value, "text")
                }
                style={{marginLeft: '20px'}}
              ></textarea>
            </div>
          </div>

          <div className="col-sm-12 px-0 pt-5 pb-4">
            {footerPreview(msgMgmtInfo[0])}
          </div>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default PreviewMsgMmt;

