import { ButtonComponent } from "@syncfusion/ej2-react-buttons";
import axios from "axios";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import userIcon from "../../assets/userIcon.png";
import { RootState } from "../../redux/store";
import notify from "../../services/toasterService";
import { ContactType } from "./constants";
import { setCurrentContact, setMasterDataAddImage } from "./contactReducer";
import { addMasterDataEndPoint, getEndPoint } from "./utils";
import editIcon from "../../assets/EditRuleExcep.png";
import AddFileUploadScreen from "../accessMgmt/addScreen/fileUpload";

const GLAccountAddScreen = (props: any) => {
  const { setAddScreen } = props;
  const dispatch = useDispatch();
  const [selectedImage, setSelectedImage] = useState();
  const [glAccName, setGlAccName] = useState("");
  const [glAccNum, setGlAccNum] = useState("");
  const [glAccNotes, setGlAccNotes] = useState("");

  const [myName, setMyName] = useState("");
  const [myAccountNo, setMyAccountNo] = useState("");

  const [departmentsGLAccountsLink, setDepartmentsGLAccountsLink] = useState<
    any[]
  >([]);
  const [errors, setErrors] = useState({
    nameError: "",
    accNumber: "",
  });
  const departmentData = useSelector(
    (state: RootState) => state.contactReducer?.departmentsMasterData
  );
  const imageChange = (e: any) => {
    if (e.target.files && e.target.files.length > 0) {
      setSelectedImage(e.target.files[0]);
    }
  };
  const handleAddGLAccName = (e: any) => {
    setGlAccName(e.target.value);
    validate();
  };
  const handleAddAccountNum = (e: any) => {
    setGlAccNum(e.target.value);
    validate();
  };
  const handleAddGLAccNotes = (e: any) => {
    setGlAccNotes(e.target.value);
  };
  const handleAddDeptInGLAccount = (e: any) => {
    const addDeptItem = JSON.parse(e.target.value);
    const CTInfo = [...departmentsGLAccountsLink];
    if (
      !CTInfo.some(
        (person) => person.departmentName === addDeptItem.departmentName
      )
    ) {
      CTInfo.push(addDeptItem);
      setDepartmentsGLAccountsLink(CTInfo);
      var mySelect = document.getElementById(
        "deptLinkDropDown"
      ) as HTMLSelectElement;
      if (mySelect) {
        mySelect.selectedIndex = 0;
      }
    }
    var mySelect = document.getElementById(
      "deptLinkDropDown"
    ) as HTMLSelectElement;
    if (mySelect) {
      mySelect.selectedIndex = 0;
    }
  };
  const removeLinkedDeptData = (value: any) => {
    let linkedGLData = [...departmentsGLAccountsLink];
    let updatedStoreData = linkedGLData.filter((item) => item.id !== value);
    var mySelect = document.getElementById(
      "deptLinkDropDown"
    ) as HTMLSelectElement;
    if (mySelect) {
      mySelect.selectedIndex = 0;
    }
    setDepartmentsGLAccountsLink(updatedStoreData);
  };
  const glAddedFinalDepts: any[] = [];
  const body = {
    active: true,
    deleted: false,
    departmentIds: glAddedFinalDepts,
    glAccountName: glAccName,
    glAccountNumber: glAccNum,
    notes: glAccNotes,
  };
  let tabName: any = ContactType.GL_ACCOUNTS;
  const handleAddGLAccount = () => {
    if (validate()) {
      departmentsGLAccountsLink.map((item) => glAddedFinalDepts.push(item.id));
      const endpoint = addMasterDataEndPoint(tabName);
      const getEndpoint = getEndPoint(tabName);
      handleMasterDataChange(endpoint, getEndpoint);
    }
  };

  const handleMasterDataChange = async (
    endpoint: string,
    getEndpoint: string
  ) => {
    const result = await axios
      .post(endpoint, body)
      .then((results: any) => {
        if (results && results.data) {
          setAddScreen(false);
          notify("GL Account created successfully", "success");
        } else {
          notify("Failed to create GL Account", "error");
        }
      })
      .catch((error: any) => {
        if (error.response) {
          notify(error.response?.data?.message, "error");
        }
      });
    const getresult = await axios.get(getEndpoint);
    dispatch(setCurrentContact(getresult.data));
  };
  const handleCancleGLAccPopup = () => {
    setAddScreen(false);
    dispatch(setMasterDataAddImage([]));
  };
  const validate = (field?: any, value?: any) => {
    let nameError = "";
    let accNumber = "";

    if (glAccName === "") {
      nameError = "Required Field";
      setMyName("1px solid red");
    } else {
      setMyName("");
    }
    if (glAccNum === "") {
      accNumber = "Required Field";
      setMyAccountNo("1px solid red");
    } else {
      setMyAccountNo("");
    }
    if (accNumber || nameError) {
      setErrors({ nameError, accNumber });
      return false;
    }
    return true;
  };

  const handlePopupActionAddGLAccount = (selectedTabName: any) => {
    uploadedImageFile = [];
    setFileUploadAddScreen(true);
    setAddScreenTabName(selectedTabName);
  };

  let uploadedImageFile = useSelector(
    (state: RootState) => state.contactReducer.uploadedImage
  );

  const [addScreen, setFileUploadAddScreen] = useState(false);
  const [addScreenTabName, setAddScreenTabName] = useState(null);
  const [screenType, setScreenType] = useState("masterdataAdd");

  return (
    <div className="GLadd_popup" style={{marginRight: '0'}}>
      <div className="row">
      {addScreen && (
        <AddFileUploadScreen
          addScreen={addScreen}
          setFileUploadAddScreen={setFileUploadAddScreen}
          tabName={addScreenTabName}
          screenType={screenType}
        />
      )}
        <div className="row d-flex justify-content-center">
          <div className="col-xl-2 col-lg-2 roundImage">
          {/* <input accept="image/*" type="file" onChange={imageChange} /> */}
          {/* <img src={userIcon} className="userAddIcon" />
          {selectedImage && (
            <div className="imagePreviewDiv">
              <img
                src={URL.createObjectURL(selectedImage)}
                className="imagePreview"
                height="100"
                width="100"
              />
            </div>
          )} */}
          {uploadedImageFile && uploadedImageFile.length > 0 ? (
            <div className="ImageContainer">
                <img
                  src={URL.createObjectURL(uploadedImageFile[0].file)}
                  className="userAddIcon"
                  height="140"
                  width="140"
                /></div>
              ) : (
                <img src={userIcon} className="userAddIcon" />
              )}
              <div className="middle">
              <img
                src={editIcon}
                alt="edit "
                width="18"
                height="18"
                className="m-3 pointer avatarEdit"
                onClick={() => handlePopupActionAddGLAccount("fileUpload")}
                style={{position: 'sticky'}}
              /></div>
        </div>
        </div>

        <div className="col-sm-12 row m-0 mt-3">
        <div className="col-sm-6">
              <div className="col-sm-12 mt-3">
              <label className="label-title">Name<span className="requiredStar">*</span></label>
              {glAccName === "" ? (
                <span className="text-danger" style={{float: 'right'}}>{errors.nameError}</span>
              ) : (
                ""
              )}
              <input
                type="text"
                className="formcontrol"
                onChange={(e) => handleAddGLAccName(e)}
                style={{ border: myName }}
              />
            </div>
          
            <div className="col-sm-12 mt-3">
              <label className="label-title">Account #<span className="requiredStar">*</span></label>
              {glAccNum === "" ? (
                <span className="text-danger" style={{float: 'right'}}>{errors.accNumber}</span>
              ) : (
                ""
              )}
              <input
                type="text"
                className="formcontrol"
                onChange={(e) => handleAddAccountNum(e)}
                style={{ border: myAccountNo }}
              />
            </div>
            </div>
          {/* <div className="row ml-0 mr-0 mt-1rem">
            <div className="col-sm-4">
              <span className="lblClass">Notes</span>
            </div>
            <div className="col-sm-8">
              <textarea className="form-control" onChange={(e) => handleAddGLAccNotes(e)}></textarea>
            </div>
          </div> */}
          <div></div>
        
        <div className="col-sm-6">
            <div className="col-sm-12 mt-3">
              <label className="label-title">Link Departments</label>
              <select
                className="form-control"
                id="deptLinkDropDown"
                onChange={(e) => handleAddDeptInGLAccount(e)}
              >
                <option>Search Departments</option>
                {departmentData?.map((dept) => (
                  <option value={JSON.stringify(dept)}>
                    {dept.departmentName}
                  </option>
                ))}
              </select>
            </div>
            <div className="col-sm-12 linkedDeptAccAddInGLAccDiv">
              {departmentsGLAccountsLink &&
                departmentsGLAccountsLink.map((item: any) => (
                  <div className="linkedStoreDiv">
                    <span title="remove" className="linkedStoreName">
                      {item.departmentName}
                    </span>{" "}
                    <span
                      className="linkedStoreNameRemove"
                      onClick={(e) => removeLinkedDeptData(item.id)}
                    >
                      X
                    </span>
                  </div>
                ))}
            </div>
          
        </div>
        </div>
      </div>
      <div className="row mt-2rem mr-2">
        <ButtonComponent
          style={
            glAccName !== "" &&
            glAccNum !== "" 
              ? {
                  opacity: "1",
                  pointerEvents: "all",
                  cursor: "pointer",
                }
              : {
                  opacity: "0.5",
                  pointerEvents: "none",
                  backgroundColor: "#e8e8e8",
                  color: "#969696",
                }
          }
          cssClass="eg-btn-primary float-right"
          onClick={() => handleAddGLAccount()}
        >
          ADD GL ACCOUNT
        </ButtonComponent>
        <ButtonComponent
          cssClass="e-control e-btn e-lib eg-btn-primary-link e-flat mt-3"
          onClick={handleCancleGLAccPopup}
        >
          CANCEL
        </ButtonComponent>
      </div>
    </div>
  );
};
export default GLAccountAddScreen;
