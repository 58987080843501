import * as React from 'react';
import {forwardRef, useEffect, useRef, useState} from 'react';
import KeyboardWrapper from './key-board-wrapper';
import {useSelector} from 'react-redux';
import {RootState} from '../../redux/store';
import keyboardIcon from '../../icons/keyboard.png';
import './input-field.scss';

interface Props extends React.HTMLProps<HTMLElement> {
    className?: string;
    ariaLabel?: string;
    initialValue?: any;
    maxLength?: number;
    autoFocus?: boolean;

    onChange(value: any): void;

    onKeyDown?(event: any): void;

    onKeyUp?(value: any): void;

    onBlur?(): void;

    onEnter?(): void;

    onClose?(): void;

    selectTextOnFocus?: boolean;

    showKeyboardIcon?: boolean;

    resetFocus?(): void;
}

export const InputField = forwardRef(
    (props: Props, ref: React.Ref<HTMLInputElement>) => {
        const [input, setInput] = useState(props.initialValue);
        const keyboard = useRef<any>(null);
        const [keyboardVisibility, setKeyboardVisibility] = useState(false);
        const onChangeInput = (value: any): void => {
            keyboard?.current?.setInput(value);
            setInput(value);
            props.onChange(value);
        };

        const showOnlyCurrentFieldKeyboard = (event: any) => {
            document.querySelectorAll('.keyboardContainer')?.forEach((value) => {
                value.classList.add('hide-me');
            });
            setKeyboardVisibility(true);
            event?.target?.parentElement
                ?.querySelectorAll('.keyboardContainer')[0]
                ?.classList.remove('hide-me');
        };
        const onFocus = (event: React.FocusEvent<HTMLInputElement>) => {
            if (props.showKeyboardIcon) return;
            if (props.selectTextOnFocus) {
                event.target.select();
            }
            showOnlyCurrentFieldKeyboard(event);
        };
        const getEnableVirtualKeyboard = useSelector(
            (state: RootState) => state.scanReducer.enableTouchKeyboard
        );

        const openKeyboard = (event: any) => {
            showOnlyCurrentFieldKeyboard(event);
        };
        const touchModeEnabled = () => {
            return getEnableVirtualKeyboard.payload !== false;
        };
        useEffect(() => {
            setInput(props.initialValue);
            keyboard?.current?.setInput(props.initialValue);
        }, []);

        return (
            <div
                className={`input-field ${
                    props.showKeyboardIcon ? 'upc-input-field' : ''
                } `}
            >
                {touchModeEnabled() && props.showKeyboardIcon && (
                    <img
                        alt="keyboard"
                        className="keyboard-icon"
                        onClick={openKeyboard}
                        src={keyboardIcon}
                    />
                )}
                <input
                    id={props.id}
                    type={props.type}
                    className={props.className}
                    placeholder={props.placeholder}
                    aria-label={props.ariaLabel}
                    name={props.name}
                    value={props.initialValue}
                    maxLength={props.maxLength}
                    onChange={(e) => {
                        onChangeInput(e.target.value);
                    }}
                    onKeyDown={props.onKeyDown}
                    onKeyUp={props.onKeyUp}
                    autoFocus={props.autoFocus ? props.autoFocus : false}
                    onFocus={(e) => onFocus(e)}
                    onBlur={props.onBlur}
                    autoComplete="off"
                    ref={ref}
                />

                {touchModeEnabled() && keyboardVisibility && (
                    <KeyboardWrapper
                        keyboardRef={keyboard}
                        onChange={onChangeInput}
                        keyboardVisibility={keyboardVisibility}
                        setKeyboardVisibility={setKeyboardVisibility}
                        defaultValue={
                            props.selectTextOnFocus ? '' : props.initialValue
                        }
                        onEnter={props.onEnter}
                        onClose={props.onClose}
                        keyboardType={
                            props.type === 'text' ? 'alphanumeric' : 'numeric'
                        }
                    />
                )}
            </div>
        );
    });
