import { Column } from '@syncfusion/ej2-grids/src/grid/models/column';
import ServiceHandler from '../../../services/service-handler';
import {
    DataStateChangeEventArgs,
    PageSettingsModel,
} from '@syncfusion/ej2-react-grids';
import { RuleModel } from '@syncfusion/ej2-querybuilder/src/query-builder/query-builder-model';

export interface PageRequest {
    pageNumber: number;
    pageSize: number;
}

export function getPaginationFilter(
    state: DataStateChangeEventArgs
): PageRequest {
    let page: PageRequest = {
        pageNumber: 0,
        pageSize: 50,
    };
    if (state.take) {
        page.pageSize = state.take;
    }
    if (state.skip) {
        console.log('Inside' + state.skip);
        page.pageNumber = state.skip / page.pageSize;
        console.log(' page.pageNumber' + page.pageNumber);
    }
    return page;
}

export const joinVisibleColumns = (visibleColumns: Column[]): string => {
    const columnNames = visibleColumns.map((colum) => {
        return colum.field;
    });
    return columnNames.join(',');
};

export const getCriteriaRequest = (
    runReportFlag: boolean,
    reportName: string,
    reportDescription: string,
    selectedColumns: string,
    criteria: string
) => {
    return {
        runReportFlag: runReportFlag,
        reportName,
        reportDescription,
        selectedColumns,
        criteria,
    };
};

export const downloadPdf = (id?: number | undefined) => {
    if (id) {
        ServiceHandler.ReportsService.downloadPdf(id);
    }
};
export const downloadExcel = (id?: number | undefined) => {
    if (id) {
        ServiceHandler.ReportsService.downloadExcel(id);
    }
};

export const scanReportPageOptions: PageSettingsModel = {
    pageSize: 50,
    pageSizes: ['50', '100', '150', '200', '500', '1000', '5000'],
};

export const runPreviewRequest = (
    criteriaRule: RuleModel,
    state: DataStateChangeEventArgs
) => {
    const pageRequest = getPaginationFilter(state);
    return {
        page: {
            pageNumber: pageRequest.pageNumber,
            pageSize: pageRequest.pageSize,
        },
        condition: criteriaRule.condition,
        rules: criteriaRule.rules,
    };
};
