export const stringOperator = [
    { value: 'contains', key: 'Contains' },
    { value: 'startswith', key: 'StartsWith' },
    { value: 'endswith', key: 'EndsWith' },
    { value: 'equal', key: 'Equal' },
    { value: 'notequal', key: 'NotEqual' },
];
export const dateOperator = [
    { value: 'equal', key: 'Equal' },
    { value: 'greaterthan', key: 'GreaterThan' },
    { value: 'greaterthanorequal', key: 'GreaterThanOrEqual' },
    { value: 'lessthan', key: 'LessThan' },
    { value: 'lessthanorequal', key: 'LessThanOrEqual' },
    { value: 'notequal', key: 'NotEqual' },
];

export const numberOperator = [
    { value: 'equal', key: 'Equal' },
    { value: 'greaterthanorequal', key: 'GreaterThanOrEqual' },
    { value: 'greaterthan', key: 'GreaterThan' },
    { value: 'between', key: 'Between' },
    { value: 'lessthan', key: 'LessThan' },
    { value: 'notbetween', key: 'NotBetween' },
    { value: 'lessthanorequal', key: 'LessThanOrEqual' },
    { value: 'notequal', key: 'NotEqual' },
];
