import Axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';

export default class HttpService {
    private static axiosCustomInstance(): AxiosInstance {
        return Axios.create();
    }

    static get<T = any, R = AxiosResponse<T>>(
        apiUrl: string,
        config?: AxiosRequestConfig
    ): Promise<R> {
        return this.axiosCustomInstance().get(apiUrl, {
            ...config,
            headers: HttpService.getAuthHeader(),
        });
    }

    static post<T = any, R = AxiosResponse<T>>(
        apiUrl: string,
        data?: any,
        config?: AxiosRequestConfig
    ): Promise<R> {
        return this.axiosCustomInstance().post(apiUrl, data, {
            ...config,
            headers: HttpService.getAuthHeader(),
        });
    }

    static delete<T = any, R = AxiosResponse<T>>(
        apiUrl: string,
        config?: AxiosRequestConfig
    ): Promise<R> {
        return this.axiosCustomInstance().delete(apiUrl, {
            ...config,
            headers: HttpService.getAuthHeader(),
        });
    }

    static put<T = any, R = AxiosResponse<T>>(
        apiUrl: string,
        data?: any,
        config?: AxiosRequestConfig
    ): Promise<R> {
        return this.axiosCustomInstance().put(apiUrl, data, {
            ...config,
            headers: HttpService.getAuthHeader(),
        });
    }

    static buildQueryParams(params: any) {
        const qs: string[] = [];
        for (const key in params) {
            if (Object.prototype.hasOwnProperty.call(params, key)) {
                qs.push(
                    `${encodeURIComponent(key)}=${encodeURIComponent(
                        params[key]
                    )}`
                );
            }
        }
        return `?${qs.join('&')}`;
    }

    static getAuthHeader() {
        const token = localStorage.getItem('token');
        return { Authorization: 'Bearer ' + token };
    }
}
