import {
    dateOperator,
    numberOperator,
    stringOperator,
} from './query-builder-util';

export const ScanReportsGridConfig = [
    { field: 'upc', label: 'UPC', type: 'string', operators: stringOperator },
    {
        field: 'description',
        label: 'Description',
        type: 'string',
        operators: stringOperator,
    },
    {
        field: 'warehouseLocation',
        label: 'Warehouse Location',
        type: 'string',
        operators: stringOperator,
    },
    {
        field: 'scannedBy',
        label: 'Scanned By',
        type: 'number',
        operators: numberOperator,
    },
    {
        field: 'scannedOn',
        label: 'Scanned On',
        type: 'dateTime',
        format: 'MM-dd-yyyy hh:mm:ss a',
        operators: dateOperator,
    },
    {
        field: 'disposition',
        label: 'Disposition',
        type: 'string',
        operators: stringOperator,
    },
    { field: 'cost', label: 'Cost', type: 'number', operators: numberOperator },
    {
        field: 'extCost',
        label: 'Ext Cost',
        type: 'number',
        operators: numberOperator,
    },
    {
        field: 'quantity',
        label: 'Quantity',
        type: 'number',
        operators: numberOperator,
    },
    {
        field: 'debitReason',
        label: 'Debit Reason',
        type: 'string',
        operators: stringOperator,
    },
    {
        field: 'storeName',
        label: 'Store Name',
        type: 'string',
        operators: stringOperator,
    },
    {
        field: 'storeNumber',
        label: 'Store Number',
        type: 'string',
        operators: stringOperator,
    },
    {
        field: 'vendorNumber',
        label: 'Vendor Number',
        type: 'string',
        operators: stringOperator,
    },
    {
        field: 'vendorName',
        label: 'Vendor Name',
        type: 'string',
        operators: stringOperator,
    },
    {
        field: 'groupName',
        label: 'Group Name',
        type: 'string',
        operators: stringOperator,
    },
    {
        field: 'containerId',
        label: 'Container Id',
        type: 'string',
        operators: stringOperator,
    },
];

export const ScanReportsListGridConfig = [
    {
        field: 'reportName',
        label: 'Report Name',
        type: 'string',
        operators: stringOperator,
    },
    {
        field: 'reportDescription',
        label: 'Report Description',
        type: 'string',
        operators: stringOperator,
    },
    {
        field: 'runReportFlag',
        label: 'Requested to Run Report',
        type: 'boolean',
        operators: stringOperator,
    },
    {
        field: 'reportStatus',
        label: 'Report Status',
        type: 'string',
        operators: stringOperator,
    },
    {
        field: 'createdBy',
        label: 'Created By',
        type: 'string',
        operators: stringOperator,
    },
    {
        field: 'lastRunOn',
        label: 'Last Run On',
        type: 'dateTime',
        format: 'MM-dd-yyyy hh:mm:ss a',
        operators: dateOperator,
    },
];
