import {
    TabComponent,
    TabItemDirective,
    TabItemsDirective,
} from '@syncfusion/ej2-react-navigations';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { setRowData } from '../grid/gridReducer';
import ExcelService from '../../services/excel';
import { getReportsEndPoint } from './utils';
import { setSavedReportData } from './reportreducer';
import notify from '../../services/toasterService';
import SavedReport from './savedreport/savedreport';
import './reports.scss';
import { FinanceReportBuilder } from './finance-report-builder/finance-report-builder';
import ScanReportBuilder from './scan-report-builder/scan-report-builder';
import { setTabNameForRowData } from '../contactTabs/contactReducer';

const childTab = [
    { text: 'Saved Reports' },
    { text: 'Scan Report Builder' },
    { text: 'Finance Report Builder' },
];

const savedReport = () => {
    return <SavedReport />;
};

export const OldReports = () => {
    return (
        <Reports/>
    )
}

export const NewReports = () => {
    return (
        <Reports />
    )
} 

const Reports = () => {
    const [viewReportDetails, setViewReportDetails] = useState(false);
    const [selectedRowId, setSelectedRowId] = useState<number | undefined>(
        undefined
    );

    let TabData: any = [];
    let selectedTab: string;

    const scanReportBuilder = () => {
        return (
            <ScanReportBuilder
                selectedRowId={selectedRowId}
                setSelectedRowId={setSelectedRowId}
                viewReportDetails={viewReportDetails}
                setViewReportDetails={setViewReportDetails}
            />
        );
    };
    const financeReportBuilder = () => {
        return <FinanceReportBuilder />;
    };
    useEffect(() => {
        dispatch(setRowData([]));
        dispatch(setTabNameForRowData([]));
        selectedTab = 'Saved Reports';
        reportsTabApi(selectedTab);
    }, []);

    const dispatch = useDispatch();

    const TabChangeEvent = async (e: any) => {
        dispatch(setRowData([]));
        selectedTab = e.selectingItem.innerText;
        if (selectedTab === 'Saved Reports') {
            await reportsTabApi(e.selectingItem.innerText);
        } else if (selectedTab === 'Scan Report Builder') {
            setViewReportDetails(false);
            setSelectedRowId(undefined);
        }
    };

    /**
     * Reports tabs Get WS
     * @author Anudeep
     * @param TabName - Selected Tab name
     */
    const reportsTabApi = async (TabName: any) => {
        const endPointURL = getReportsEndPoint(TabName);
        await axios
            .get(endPointURL)
            .then((res: any) => {
                if (res.status === 200) {
                    res.data.forEach((element: any) => {
                        element.reportParameters.forEach(
                            (subEle: { wsData: any[] }) => {
                                subEle.wsData = [];
                            }
                        );
                    });
                    if (TabName === 'Saved Reports') {
                        // console.log(res.data);
                        const reportsData = res.data.sort((a: any, b: any) =>
                            a.name > b.name ? 1 : b.name > a.name ? -1 : 0
                        );
                        // console.log(reportsData);
                        dispatch(setSavedReportData(reportsData));
                    } else {
                    }
                    TabData = res.data;
                }
            })
            .catch((err) => {
                notify('Failed to fetch data', 'error');
            });
    };

    /**
     * Excel Export function using excel js file
     * @author Anudeep
     * @param - Selected tab name and Tab Data
     */
    const excelExport = () => {
        // console.log(selectedTab, TabData);
        ExcelService.exportAsExcel('', selectedTab, TabData);
    };

    return (
        <div>
            <div className="col-sm-12 col-md-12 col-lg-12  parent-tab-width">
                <div className="control-pane">
                    <div className="control-section tab-control-section">
                        {/* <p>Hi</p> */}
                        <TabComponent
                            heightAdjustMode="Auto"
                            cssClass="orientation-tab ll-custom-tab"
                            selecting={TabChangeEvent}
                        >
                            <TabItemsDirective>
                                <TabItemDirective
                                    id="saved-report"
                                    header={childTab[0]}
                                    content={savedReport}
                                />

                                <TabItemDirective
                                    id="scan-report-builder"
                                    header={childTab[1]}
                                    content={scanReportBuilder}
                                />

                                <TabItemDirective
                                    id="finance-report-builder"
                                    header={childTab[2]}
                                    content={financeReportBuilder}
                                />
                            </TabItemsDirective>
                        </TabComponent>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Reports;
