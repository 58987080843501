import Keycloak from 'keycloak-js';
import { environment } from '../environment.js';
//import store from "../redux/store.js";
//const _kc1 = new Keycloak('/keycloak.json');

let keycloakJSON = {
    realm: environment.keycloak_realm,
    url: environment.keycloak_auth_server_url,
    'ssl-required': 'external',
    clientId: environment.keycloak_clientId,
    'public-client': true,
    'confidential-port': 443,
    'enable-cors': true,
    'disable-trust-manager': true,
};

const _kc = new Keycloak(keycloakJSON);

/**
 * Initializes Keycloak instance and calls the provided callback function if successfully authenticated.
 *
 * @param onAuthenticatedCallback
 */
const initKeycloak = (onAuthenticatedCallback) => {
    _kc.init({
        onLoad: 'login-required',
        // silentCheckSsoRedirectUri: window.location.origin + '../../public/silent-check-sso.html',
        // pkceMethod: 'S256',
        checkLoginIframe: false,
    })
        .then((authenticated) => {
            if (!authenticated) {
                console.log('user is not authenticated..!');
            }
            onAuthenticatedCallback();
            localStorage.setItem('token', _kc.token);
            //store.dispatch(setAccessToken(_kc.token))
            // usergetLoggedInUser(_kc.token)
        })
        .catch(console.error);
};

const doLogin = _kc.login;

const doLogout = _kc.logout;

//const loggedInUserData = usergetLoggedInUser(_kc.token)
const getToken = () => _kc.token;
console.log(_kc.token);

const isLoggedIn = () => !!_kc.token;

const updateToken = (successCallback) =>
    _kc.updateToken(5).then(successCallback).catch(doLogin);

const getUsername = () => _kc.tokenParsed;

const hasRole = (roles) => roles.some((role) => _kc.hasRealmRole(role));

const UserService = {
    initKeycloak,
    doLogin,
    doLogout,
    isLoggedIn,
    getToken,
    updateToken,
    getUsername,
    hasRole,
    //loggedInUserData
};

export default UserService;
