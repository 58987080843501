import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import store from '../redux/store';

const EXCEL_TYPE =
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';

/* Format date into  US format */
const formatDateToUS = (date) => {
    let d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();
    if (month.length < 2) {
        month = '0' + month;
    }
    if (day.length < 2) {
        day = '0' + day;
    }

    return [month, day, year].join('-');
};

/* Export data into Excel with Current date in US format */
const exportAsExcel = (type, excelFileName, json) => {
    let convertedJson = JSON.parse(JSON.stringify(json));
    for (var i = 0; i < convertedJson.length; i++) {
        // var newDate= new Date(convertedJson[i].createdDate);
        // convertedJson[i].createdDate =newDate;
        var a = convertedJson[i];
        for (var key in a) {
            if (type === 'acm') {
                if (
                    key.toLocaleLowerCase() === 'createddate' ||
                    key.toLocaleLowerCase() === 'lastmodifieddate' ||
                    key.toLocaleLowerCase() === 'image' ||
                    key.toLocaleLowerCase() === 'statuscode' ||
                    key.toLocaleLowerCase() === 'tenantid' ||
                    key.toLocaleLowerCase() === 'stateid' ||
                    key.toLocaleLowerCase() === 'roleid' ||
                    key.toLocaleLowerCase() === 'password' ||
                    key.toLocaleLowerCase() === 'organizationid' ||
                    key.toLocaleLowerCase() === 'id' ||
                    key.toLocaleLowerCase() === 'federationlink' ||
                    key.toLocaleLowerCase() === 'countryid' ||
                    key.toLowerCase() === 'country' ||
                    key.toLowerCase() === 'tabname' ||
                    key.toLowerCase() === 'scanuser' ||
                    key.toLocaleLowerCase() === 'reclaimcenterid' ||
                    key.toLowerCase() === 'storeid' ||
                    key.toLowerCase() === 'countrycode' ||
                    key.toLowerCase() === 'userid'
                ) {
                    delete a[key];
                }
                if (key.toLowerCase() === 'enddate') {
                    if (
                        '01-01-1970' !== formatDateToUS(a[key]) &&
                        formatDateToUS(a[key]) !== '12-31-1969'
                    ) {
                        a[key] = formatDateToUS(a[key]);
                    } else {
                        a[key] = '';
                    }
                }

                if (key.toLowerCase() === 'activeforgrid') {
                    a['Status'] = a[key];
                    delete a[key];
                }
                if (key.toLowerCase() === 'store') {
                    const col = a['store'];
                    a['store'] = col?.storeName;
                }
            } else if (type === 'lladmin') {
                if (
                    key.toLocaleLowerCase() === 'tabname' ||
                    key.toLocaleLowerCase() === 'tabapiname' ||
                    key.toLocaleLowerCase() === 'tenantid' ||
                    key.toLocaleLowerCase() === 'active' ||
                    key.toLocaleLowerCase() === 'deleted' ||
                    key.toLocaleLowerCase() === 'id' ||
                    key.toLocaleLowerCase() === 'organizationid'
                ) {
                    delete a[key];
                }
                if (key.toLowerCase() === 'activeforgrid') {
                    a['Status'] = a[key];
                    delete a[key];
                }
            } else {
                if (key === 'createdDate' || key === 'lastModifiedDate') {
                    a[key] = formatDateToYMD(a[key]);
                }
                if (key.toLowerCase() === 'activeforgrid') {
                    a['Status'] = a[key];
                    delete a[key];
                }
            }

            
            if (a.hasOwnProperty(key)) {
                a[key.charAt(0).toUpperCase() + key.substring(1)] = a[key];
                delete a[key];
            }
        }
        convertedJson[i] = a;
    }

    const worksheet = XLSX.utils.json_to_sheet(convertedJson);
    const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
    const excelBuffer = XLSX.write(workbook, {
        bookType: 'xlsx',
        type: 'array',
    });
    const data = new Blob([excelBuffer], { type: EXCEL_TYPE });
    FileSaver.saveAs(
        data,
        excelFileName + ' ' + formatDateToUS(new Date()) + EXCEL_EXTENSION
    );
};

const ExcelService = {
    exportAsExcel,
    formatDateToUS,
};

const formatDateToYMD = (date) => {
    const d = new Date(date);
    let month = '' + (d.getMonth() + 1);
    let day = '' + d.getDate();
    const year = d.getFullYear().toString();
    if (month.length < 2) {
        month = '0' + month;
    }
    if (day.length < 2) {
        day = '0' + day;
    }
    const hours = d.getUTCHours();
    const minutes = d.getMinutes();
    const seconds = d.getSeconds();
    const time = [hours, minutes, seconds].join(':');
    const formateddate = [year, month, day].join('-');
    return formateddate + ' ' + time;
};

export default ExcelService;
