import {
  TabComponent,
  TabItemDirective,
  TabItemsDirective,
} from "@syncfusion/ej2-react-navigations";
import "./lladmin.scss";
import LocationSettings from "./locationsettings/locationsettings";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { setRowData } from "../grid/gridReducer";
import { setSelectedRecrdsExport, setTabNameForRowData } from "../contactTabs/contactReducer";
import { getLlAdminEndPoint } from "./utils";
import axios from "axios";
import { setClientsData, setIsLoaderShow, setLanguageData, setMsgMgmtData } from "./lladminreducer";
import ClientIdManagament from "./clientidmanagement/clientIdMgmt";
import notify from "../../services/toasterService";
import MessageManagement from "./messagemanagement/messageManagement";

const parentTab = [
  { text: "Location Settings" },
  { text: "Client ID Management" },
  { text: "Message Management" },
];

const LlAdmin = () => {
  const dispatch = useDispatch();
  let selectedTab: string;

  useEffect(() => {
    dispatch(setRowData([]));
    dispatch(setSelectedRecrdsExport([]));
    dispatch(setTabNameForRowData(""));
  });

  const TabChangeEvent = async (e: any) => {
    dispatch(setRowData([]));
    // console.log(e.selectingIndex, e.selectingItem.innerText);
    selectedTab = e.selectingItem.innerText;
    if (selectedTab === "Client ID Management") {
      await LlAdminTabApi(e.selectingItem.innerText);
    } else  if (selectedTab === "Message Management") { 
      await LlAdminTabApi(e.selectingItem.innerText);
    }
  };

  /**
   * LL Admin Tab Change event
   * @author Anudeep
   * @param TabName - Selected Tab name
   */
  const LlAdminTabApi = async (TabName: any) => {
    dispatch(setIsLoaderShow(true))
    const endPointURL = getLlAdminEndPoint(TabName);
    await axios
      .get(endPointURL)
      .then((res) => {
        dispatch(setIsLoaderShow(false))
        if (TabName === 'Client ID Management') {
          dispatch(setClientsData(res.data));
        } else if (TabName === 'Message Management') {
          dispatch(setMsgMgmtData(res.data));
            const endPointURL = getLlAdminEndPoint("Languages");
            axios.get(endPointURL).then((res) => {
              dispatch(setLanguageData(res.data));
            }).catch((err) => {
              dispatch(setIsLoaderShow(false));
              notify(err, "error");
            } );
        }
      })
      .catch((err) => {
        notify("Failed to fetch data", "error");
        dispatch(setIsLoaderShow(false));
      });
  };

  return (
    <div className="col-sm-12 col-md-12 col-lg-12 parent-tab-width">
      <div className="control-pane">
        <div className="control-section tab-control-section">
          <TabComponent
            heightAdjustMode="Auto"
            cssClass="orientation-tab ll-custom-tab"
            selecting={TabChangeEvent}
          >
            <TabItemsDirective>
              <TabItemDirective
                header={parentTab[0]}
                content={locationSettings}
              />

              <TabItemDirective
                header={parentTab[1]}
                content={clientIdManagement}
              />

              <TabItemDirective
                header={parentTab[2]}
                content={messageManagement}
              />
            </TabItemsDirective>
          </TabComponent>
        </div>
      </div>
    </div>
  );

  function locationSettings() {
    return (
      <div>
        <div className="col-sm-12 col-md-12 col-lg-12 ">
          <LocationSettings />
        </div>
      </div>
    );
  }

  function clientIdManagement() {
    return (
      <div>
        <div className="col-sm-12 col-md-12 col-lg-12 pt-2">
          <ClientIdManagament />
        </div>
      </div>
    );
  }

  function messageManagement() {
    return (
      <div>
        <div className="col-sm-12 col-md-12 col-lg-12 pt-2">
          <MessageManagement />
        </div>
      </div>
    );
  }
};

export default LlAdmin;


