import { Column, QueryCellInfoEventArgs } from '@syncfusion/ej2-react-grids';
import { getValue, Internationalization } from '@syncfusion/ej2-base';

export const customizeCell = (args: QueryCellInfoEventArgs) => {
    let intl = new Internationalization();
    if (
        (args.column as Column).field === 'activeForGrid' &&
        args.data &&
        args.cell
    ) {
        if (getValue('activeForGrid', args.data) === 'Active') {
            args.cell.innerHTML = `<span class='btnActiveClass'>${getValue(
                'activeForGrid',
                args.data
            )}</span>`;
        } else if (getValue('activeForGrid', args.data) === 'Open') {
            args.cell.innerHTML = `<span class='btnActiveClass'>${getValue(
                'activeForGrid',
                args.data
            )}</span>`;
        } else if (getValue('activeForGrid', args.data) === 'Approved') {
            args.cell.innerHTML = `<span class='btnApprovedClass'>${getValue(
                'activeForGrid',
                args.data
            )}</span>`;
        } else if (getValue('activeForGrid', args.data) === 'Denied') {
            args.cell.innerHTML = `<span class='btnDeniedClass'>${getValue(
                'activeForGrid',
                args.data
            )}</span>`;
        } else if (getValue('activeForGridVendor', args.data) === 'InActive') {
            args.cell.innerHTML = `<span class='btnInActiveClass'>${getValue(
                'activeForGridVendor',
                args.data
            )}</span>`;
        } else if (getValue('activeForGridStore', args.data) === 'InActive') {
            args.cell.innerHTML = `<span class='btnInActiveClass'>${getValue(
                'activeForGridStore',
                args.data
            )}</span>`;
        } else if (getValue('activeForGridRules', args.data) === 'InActive') {
            args.cell.innerHTML = `<span class='btnInActiveClass'>${getValue(
                'activeForGridRules',
                args.data
            )}</span>`;
        } else {
            args.cell.innerHTML = `<span class='btnInActiveClass'>${getValue(
                'activeForGrid',
                args.data
            )}</span>`;
        }
    }

    if (
        (args.column as Column).field === 'locationEnabledForGrid' &&
        args.data &&
        args.cell
    ) {
        if (getValue('locationEnabledForGrid', args.data) === 'Yes') {
            args.cell.innerHTML = `<span class='btnActiveClass'>${getValue(
                'locationEnabledForGrid',
                args.data
            )}</span>`;
        } else {
            args.cell.innerHTML = `<span class='btnActiveNoClassPallet'>${getValue(
                'locationEnabledForGrid',
                args.data
            )}</span>`;
        }

        if (
            (args.column as Column).field === 'authorizationFlagGrid' &&
            args.data &&
            args.cell
        ) {
            if (getValue('authorizationFlagGrid', args.data) === 'Yes') {
                args.cell.innerHTML = `<span class='btnYesClass'>${getValue(
                    'authorizationFlagGrid',
                    args.data
                )}</span>`;
            } else {
                args.cell.innerHTML = `<span class='btnNoClass'>${getValue(
                    'authorizationFlagGrid',
                    args.data
                )}</span>`;
            }
        }
    }
    if (
        (args.column as Column).field === 'invoiceRaisedForGrid' &&
        args.data &&
        args.cell
    ) {
        if (getValue('invoiceRaisedForGrid', args.data) === 'Yes') {
            args.cell.innerHTML = `<span class='btnActiveClass'>${getValue(
                'invoiceRaisedForGrid',
                args.data
            )}</span>`;
        } else {
            args.cell.innerHTML = `<span class='btnActiveNoClassPallet'>${getValue(
                'invoiceRaisedForGrid',
                args.data
            )}</span>`;
        }

        if (
            (args.column as Column).field === 'authorizationFlagGrid' &&
            args.data &&
            args.cell
        ) {
            if (getValue('authorizationFlagGrid', args.data) === 'Yes') {
                args.cell.innerHTML = `<span class='btnYesClass'>${getValue(
                    'authorizationFlagGrid',
                    args.data
                )}</span>`;
            } else {
                args.cell.innerHTML = `<span class='btnNoClass'>${getValue(
                    'authorizationFlagGrid',
                    args.data
                )}</span>`;
            }
        }
    }

    if (
        (args.column as Column).field === 'getActiveForGridItemCatalog' &&
        args.data &&
        args.cell
    ) {
        if (
            getValue('getActiveForGridItemCatalog', args.data) === 'Yes' ||
            getValue('getActiveForGridItemCatalog', args.data) === 'yes'
        ) {
            args.cell.innerHTML = `<span class='btnActiveClass'>${getValue(
                'getActiveForGridItemCatalog',
                args.data
            )}</span>`;
        } else {
            args.cell.innerHTML = `<span class='btnActiveNoClassPallet'>${getValue(
                'getActiveForGridItemCatalog',
                args.data
            )}</span>`;
        }
    }

    if (
        (args.column as Column).field === 'isActive' &&
        args.data &&
        args.cell
    ) {
        if (getValue('isActive', args.data) === 'Active') {
            args.cell.innerHTML = `<span class='btnActiveClass'>${getValue(
                'isActive',
                args.data
            )}</span>`;
        } else {
            args.cell.innerHTML = `<span class='btnInActiveClass'>${getValue(
                'isActive',
                args.data
            )}</span>`;
        }
    }
    if (
        (args.column as Column).field === 'isMobileScanEnabled' &&
        args.data &&
        args.cell
    ) {
        if (getValue('isMobileScanEnabled', args.data) === 'Active') {
            args.cell.innerHTML = `<span class='btnActiveClass'>${getValue(
                'isMobileScanEnabled',
                args.data
            )}</span>`;
        } else {
            args.cell.innerHTML = `<span class='btnInActiveClass'>${getValue(
                'isMobileScanEnabled',
                args.data
            )}</span>`;
        }
    }
    if (
        (args.column as Column).field === 'activeForGridVendor' &&
        args.data &&
        args.cell
    ) {
        if (getValue('activeForGridVendor', args.data) === 'Active') {
            args.cell.innerHTML = `<span class='btnActiveClass'>${getValue(
                'activeForGridVendor',
                args.data
            )}</span>`;
        } else {
            args.cell.innerHTML = `<span class='btnInActiveClass'>${getValue(
                'activeForGridVendor',
                args.data
            )}</span>`;
        }
    }

    if (
        (args.column as Column).field === 'activeForGridRules' &&
        args.data &&
        args.cell
    ) {
        if (getValue('activeForGridRules', args.data) === 'Active') {
            args.cell.innerHTML = `<span class='btnActiveClass'>${getValue(
                'activeForGridRules',
                args.data
            )}</span>`;
        } else {
            args.cell.innerHTML = `<span class='btnInActiveClass'>${getValue(
                'activeForGridRules',
                args.data
            )}</span>`;
        }
    }

    if (
        (args.column as Column).field === 'activeForFiscal' &&
        args.data &&
        args.cell
    ) {
        if (getValue('activeForFiscal', args.data) === 'Open') {
            args.cell.innerHTML = `<span class='btnActiveClass'>${getValue(
                'activeForFiscal',
                args.data
            )}</span>`;
        } else {
            args.cell.innerHTML = `<span class='btnActiveClass'>${getValue(
                'activeForFiscal',
                args.data
            )}</span>`;
        }
    }

    if ((args.column as Column).field === 'status' && args.data && args.cell) {
        if (getValue('status', args.data) === 'Cancelled') {
            args.cell.innerHTML = `<span class='btnCancelledClass'>${getValue(
                'status',
                args.data
            )}</span>`;
        } else if (getValue('status', args.data) === 'Ready Slvg') {
            args.cell.innerHTML = `<span class='btnReadySlvgClass'>${getValue(
                'status',
                args.data
            )}</span>`;
        } else if (getValue('status', args.data) === 'Draft') {
            args.cell.innerHTML = `<span class='btnDraftClass'>${getValue(
                'status',
                args.data
            )}</span>`;
        } else if (getValue('status', args.data) === 'Ready Extrct') {
            args.cell.innerHTML = `<span class='btnReadyExtctClass'>${getValue(
                'status',
                args.data
            )}</span>`;
        } else if (getValue('status', args.data) === 'Pickup Mnul') {
            args.cell.innerHTML = `<span class='btnPickupClass'>${getValue(
                'status',
                args.data
            )}</span>`;
        } else if (getValue('status', args.data) === 'Pickup Auto') {
            args.cell.innerHTML = `<span class='btnPickupClass'>${getValue(
                'status',
                args.data
            )}</span>`;
        } else if (getValue('status', args.data) === 'Pickup, Hold') {
            args.cell.innerHTML = `<span class='btnPickupClass'>${getValue(
                'status',
                args.data
            )}</span>`;
        } else if (getValue('status', args.data) === 'Final Close') {
            args.cell.innerHTML = `<span class='btnFinalCloseClass'>${getValue(
                'status',
                args.data
            )}</span>`;
        } else if (getValue('status', args.data) === 'Picked') {
            args.cell.innerHTML = `<span class='btnPickedClass'>${getValue(
                'status',
                args.data
            )}</span>`;
        } else if (getValue('status', args.data) === 'Released') {
            args.cell.innerHTML = `<span class='btnReleasedClass'>${getValue(
                'status',
                args.data
            )}</span>`;
        } else if (getValue('status', args.data) === 'In Process') {
            args.cell.innerHTML = `<span class='btnInProcessCMClass'>${getValue(
                'status',
                args.data
            )}</span>`;
        } else if (getValue('status', args.data) === 'Approved') {
            args.cell.innerHTML = `<span class='btnApprovedCMClass'>${getValue(
                'status',
                args.data
            )}</span>`;
        } else if (getValue('status', args.data) === 'Rejected') {
            args.cell.innerHTML = `<span class='btnRejectedCMClass'>${getValue(
                'status',
                args.data
            )}</span>`;
        }
    }

    if ((args.column as Column).field === 'status' && args.data && args.cell) {
        if (getValue('status', args.data) === 'Cancelled') {
            args.cell.innerHTML = `<span class='btnInActiveClass'>${getValue(
                'status',
                args.data
            )}</span>`;
        } else if (getValue('status', args.data) === 'Ready for Salvager') {
            args.cell.innerHTML = `<span class='btnActiveClass'>${getValue(
                'status',
                args.data
            )}</span>`;
        } else if (getValue('status', args.data) === 'Draft') {
            args.cell.innerHTML = `<span class='btnDraftClass'>${getValue(
                'status',
                args.data
            )}</span>`;
        } else if (getValue('status', args.data) === 'Ready for Extract') {
            args.cell.innerHTML = `<span class='btnDraftClass'>${getValue(
                'status',
                args.data
            )}</span>`;
        } else if (
            getValue('status', args.data) === 'Pickup, Manual Override'
        ) {
            args.cell.innerHTML = `<span class='btnPickupClass'>${getValue(
                'status',
                args.data
            )}</span>`;
        } else if (getValue('status', args.data) === 'Picked') {
            args.cell.innerHTML = `<span class='btnPickupClass'>${getValue(
                'status',
                args.data
            )}</span>`;
        }
    }
    if ((args.column as Column).field === 'cost' && args.data && args.cell) {
        let nFormatter = intl.getNumberFormat({
            skeleton: 'C3',
            currency: 'USD',
            minimumIntegerDigits: 0,
            minimumFractionDigits: 2,
        });

        args.cell.innerHTML = `$${nFormatter(getValue('cost', args.data))}`;
    }

    if ((args.column as Column).field === 'price' && args.data && args.cell) {
        let nFormatter = intl.getNumberFormat({
            skeleton: 'C3',
            currency: 'USD',
            minimumIntegerDigits: 0,
            minimumFractionDigits: 2,
        });
        args.cell.innerHTML = `$${nFormatter(getValue('price', args.data))}`;
    }
};
