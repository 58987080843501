import { environment } from "../../environment";

const baseURL = environment.react_app_url;
const clientContext = environment.react_app_client_context;

/**
 * Modal Mapping and Data binding
 */
export const generateGridData = (tabName: string, data: any) => {
  // console.log(data);
  switch (tabName) {
    case "Time Zones": {
      return data.map((row: any) => ({
        id: isEmpty(row.id),
        name: isEmpty(row.timezoneName),
        timeZone: isEmpty(row.timezone),
        isoCode: isEmpty(row.isoCode),
        country: isEmpty(row.country),
        activeForGrid: isEmpty(row.active) === true ? "Active" : "Inactive",
        active: isEmpty(row.active),
        tabName: "Time Zone",
        tabApiName: "Time Zones",
        utc: isEmpty(row.utc),
        deleted: isEmpty(row.deleted),
        description: isEmpty(row.description),
        organizationId: isEmpty(row.organizationId),
        tenantId: isEmpty(row.tenantId),
      }));
    }
    case "Languages": {
      return data.map((row: any) => ({
        id: isEmpty(row.id),
        language: isEmpty(row.language),
        description: isEmpty(row.languageDescription),
        isoCode: isEmpty(row.isoCode),
        isoShortCode: isEmpty(row.isoShortCode),
        isoLongCode: isEmpty(row.isoLongCode),
        country: isEmpty(row.country),
        activeForGrid: isEmpty(row.active) === true ? "Active" : "Inactive",
        active: isEmpty(row.active),
        tabName: "Language",
        tabApiName: "Languages",
        deleted: isEmpty(row.deleted),
        organizationId: isEmpty(row.organizationId),
        tenantId: isEmpty(row.tenantId),
      }));
    }
    case "Currency Codes": {
      return data.map((row: any) => ({
        id: isEmpty(row.id),
        currency: isEmpty(row.currency),
        isoCode: isEmpty(row.isoCode),
        currencyCode: isEmpty(row.primaryCurrencyCode),
        alternateCurrency: isEmpty(row.alternateCurrencyCode),
        currencyValidity: isEmpty(row.currencyValidity),
        activeForGrid: isEmpty(row.active === true) ? "Active" : "Inactive",
        active: isEmpty(row.active),
        tabName: "Currency Code",
        tabApiName: "Currency Codes",
        deleted: isEmpty(row.deleted),
        organizationId: isEmpty(row.organizationId),
        tenantId: isEmpty(row.tenantId),
      }));
    }
    case "Country Codes": {
      return data.map((row: any) => ({
        id: isEmpty(row.id),
        countryName: isEmpty(row.countryName),
        isoCountryCode: isEmpty(row.isoCountryCode),
        region: isEmpty(row.region),
        subRegion: isEmpty(row.subRegion),
        organizationId: isEmpty(row.organizationId),
        tenantId: isEmpty(row.tenantId),
        deleted: isEmpty(row.deleted),
        activeForGrid: isEmpty(row.active) === true ? "Active" : "Inactive",
        active: isEmpty(row.active),
        tabName: "Country Code",
        tabApiName: "Country Codes",
      }));
    }
    case "Client ID Management": {
      return data.map((row: any) => ({
        id: isEmpty(row.id),
        clientName: isEmpty(row.clientName),
        clientId: isEmpty(row.clientId),
        activeForGrid: "Active",
        tabName: "Client ID Management",
        tabApiName: "Client ID Management",
      }));
    }
    case "Reclaim Scan": {
      return data.map((row: any) => ({
        id: isEmpty(row.id),
        type: isEmpty(row.messageType),
        languageKey: isEmpty(row.languageKey),
        text: isEmpty(row.messageText),
        activeForGrid: isEmpty(row.active) === true ? "Active" : "Inactive",
        tabName: "Reclaim Scan",
        tabApiName: "Reclaim Scan",
      }));
    }
    case "Mobile": {
      return data.map((row: any) => ({
        id: isEmpty(row.id),
        type: isEmpty(row.messageType),
        languageKey: isEmpty(row.languageKey),
        text: isEmpty(row.messageText),
        activeForGrid: isEmpty(row.active === true) ? "Active" : "Inactive",
        tabName: "Mobile",
        tabApiName: "Mobile",
      }));
    }
    case "Message Management": {
      return data.map((row: any) => ({
        id: isEmpty(row.id),
        type: isEmpty(row.messageType),
        languageKey: isEmpty(row.languageKey),
        text: isEmpty(row.messageText),
        activeForGrid: isEmpty(row.active) === true ? "Active" : "Inactive",
        tabName: "Message Management",
        tabApiName: "Message Management",
        messageId: isEmpty(row?.messageId)
      }));
    }

    default:
      return [];
  }
};

const isEmpty = (val: any) => {
  if (val !== undefined && val !== null && val !== "") {
    return val;
  } else {
    return "";
  }
};

export const getLlAdminEndPoint = (tabName: any) => {
  switch (tabName) {
    case "Country Codes": {
      return baseURL + `${clientContext}/v1/masterdata/country/list`;
    }
    case "Currency Codes": {
      return baseURL + `${clientContext}/v1/masterdata/currency/list`;
    }
    case "Languages": {
      return baseURL + `${clientContext}/v1/masterdata/language/list`;
    }
    case "Time Zones": {
      return baseURL + `${clientContext}/v1/masterdata/timezone/list`;
    }
    case "Client ID Management": {
      return baseURL + `${clientContext}/v1/masterdata/administration/list`;
    }
    case "Message Management": {
      return baseURL + `${clientContext}/v1/masterdata/messageManagement/list`;
    }
    case "Reclaim Scan": {
      return (
        baseURL +
        `${clientContext}/v1/masterdata/messageManagement/list?isReclaim=true&isGlobal=true`
      );
    }
    case "Mobile": {
      return (
        baseURL +
        `${clientContext}/v1/masterdata/messageManagement/list?isReclaim=false&isGlobal=true`
      );
    }
    default:
      return "";
  }
};

export const updateLlAdminEndPoint = (tabName: any, id: any) => {
  switch (tabName) {
    case "Time Zones": {
      return baseURL + `${clientContext}/v1/masterdata/timezone/items/${id}`;
    }
    case "Currency Codes": {
      return baseURL + `${clientContext}/v1/masterdata/currency/items/${id}`;
    }
    case "Languages": {
      return baseURL + `${clientContext}/v1/masterdata/language/items/${id}`;
    }
    case "Country Codes": {
      return baseURL + `${clientContext}/v1/masterdata/country/items/${id}`;
    }
    case "Message Management": {
      return baseURL + `${clientContext}/v1/masterdata/messagemanagement/items/${id}?isReclaim=true&isGlobal=true`;
    }
    case "Reclaim Scan": {
      return (
        baseURL +
        `${clientContext}/v1/masterdata/messagemanagement/items/${id}?isReclaim=true&isGlobal=true`
      );
    }
    case "Mobile": {
      return (
        baseURL +
        `${clientContext}/v1/masterdata/messagemanagement/items/${id}?isReclaim=false&isGlobal=true`
      );
    }
    default:
      return "";
  }
};

export const addLlAdminEndPoint = (tabName: any) => {
  switch (tabName) {
    case "Time Zones": {
      return baseURL + `${clientContext}/v1/masterdata/timezone/items`;
    }
    case "Currency Codes": {
      return baseURL + `${clientContext}/v1/masterdata/currency/items`;
    }
    case "Languages": {
      return baseURL + `${clientContext}/v1/masterdata/language/items`;
    }
    case "Country Codes": {
      return baseURL + `${clientContext}/v1/masterdata/country/items`;
    }
    case "Reclaim Scan": {
      return (
        baseURL +
        `${clientContext}/v1/masterdata/messagemanagement/items?isReclaim=true&isGlobal=true`
      );
    }
    case "Mobile": {
      return (
        baseURL +
        `${clientContext}/v1/masterdata/messagemanagement/items?isReclaim=false&isGlobal=true`
      );
    }
    default:
      return "";
  }
};

export const timeZoneHeaders = [
  {
    field: "id",
    header: "ID",
    width: "80",
    textAlign: "Right",
    dataType: "number",
  },
  { field: "name", header: "NAME", width: "170", textAlign: "Left" },
  { field: "timeZone", header: "TIME ZONE", width: "120", textAlign: "Left" },
  { field: "isoCode", header: "ISO CODE", width: "120", textAlign: "Left" },
  { field: "country", header: "COUNTRY", width: "130", textAlign: "Left" },
  { field: "activeForGrid", header: "STATUS", dataType: "enum" },
];

export const languageHeaders = [
  {
    field: "id",
    header: "ID",
    width: "80",
    textAlign: "Right",
    dataType: "number",
  },
  { field: "language", header: "LANGUAGE", width: "80", textAlign: "Left" },
  {
    field: "description",
    header: "DESCRIPTION",
    width: "120",
    textAlign: "Left",
  },
  {
    field: "isoShortCode",
    header: "ISO SHORT CODE",
    width: "100",
    textAlign: "Left",
  },
  {
    field: "isoLongCode",
    header: "ISO LONG CODE",
    width: "100",
    textAlign: "Left",
  },
  { field: "activeForGrid", header: "STATUS", dataType: "enum" },
];

export const currencyCodeHeaders = [
  {
    field: "id",
    header: "ID",
    width: "80",
    textAlign: "Right",
    dataType: "number",
  },
  { field: "currency", header: "CURRENCY", width: "120", textAlign: "Left" },
  { field: "isoCode", header: "ISO CODE", width: "120", textAlign: "Left" },
  {
    field: "currencyCode",
    header: "CURRENCY CODE",
    width: "120",
    textAlign: "Left",
  },
  { field: "activeForGrid", header: "STATUS", dataType: "enum" },
];

export const countryCodeHeaders = [
  {
    field: "id",
    header: "ID",
    width: "80",
    textAlign: "Right",
    dataType: "number",
  },
  {
    field: "countryName",
    header: "COUNTRY NAME",
    width: "120",
    textAlign: "Left",
  },
  {
    field: "isoCountryCode",
    header: "ISO COUNTRY CODE",
    width: "150",
    textAlign: "Left",
  },
  { field: "region", header: "REGION", width: "120", textAlign: "Right" },
  {
    field: "subRegion",
    header: "SUB REGION",
    width: "120",
    textAlign: "Right",
  },
  { field: "activeForGrid", header: "STATUS", dataType: "enum" },
];

export const clientIdMgmtHeaders = [
  {
    field: "id",
    header: "ID",
    width: "80",
    textAlign: "Right",
    dataType: "number",
  },
  { field: "clientName", header: "NAME", width: "120", textAlign: "Left" },
  { field: "activeForGrid", header: "STATUS", dataType: "enum" },
];

export const reclaimCenterHeaders = [
  {
    field: "id",
    header: "Message ID",
    width: "100",
    textAlign: "Right",
    dataType: "number",
  },
  { field: "type", header: "TYPE", width: "80", textAlign: "Left" },
  {
    field: "languageKey",
    header: "LANGUAGE KEY",
    width: "120",
    textAlign: "Left",
  },
  { field: "text", header: "TEXT", width: "150", textAlign: "Left" },
  { field: "activeForGrid", header: "STATUS", dataType: "enum" },
];

export const messageMgmtHeaders = [
  {
    field: "id",
    header: "Message ID",
    width: "100",
    textAlign: "Right",
    dataType: "number",
  },
  { field: "type", header: "TYPE", width: "80", textAlign: "Left" },
  {
    field: "languageKey",
    header: "LANGUAGE KEY",
    width: "120",
    textAlign: "Left",
  },
  { field: "text", header: "TEXT", width: "150", textAlign: "Left" },
  { field: "activeForGrid", header: "STATUS", dataType: "enum" },
];
