import { FC, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../redux/store';
import LLModal from '../../common/llModal/LLModal';
import ManagementApprovalModal from './managementApproval';

interface IScanBox {
    heading: string;
    isSettings: boolean;
    key1: string;
    key2: string;
    key3: string;
    value1: string;
    value2: string;
    value3: string;
}

const ScanContentBox: FC<IScanBox> = ({
    heading,
    isSettings,
    key1,
    key2,
    key3,
    value1,
    value2,
    value3,
}) => {
    const [addScreen, setAddScreen] = useState(false);
    const setItemDetails = useSelector(
        (state: RootState) => state.scanReducer.itemDetails
    );
    const handleRenderModal = (type: any) => {
        console.log(type);
        setAddScreen(true);
    };

    return (
        <>
            <div className="col-sm-12 mt-1rem pl-1 scanned-content-box">
                <div className="row ml-0 mr-0 inboundBoxHeading">
                    <span className="inboundBoxHeadingText">{heading}</span>
                    {/* {isSettings ? (
            <span onClick={(e) => handleRenderModal("inbound")}>
              <img src={scanstation} className="imgsetting" />
            </span>
          ) : (
            ""
          )} */}
                    <LLModal
                        handleClose={() => setAddScreen(false)}
                        show={addScreen}
                        width={800}
                    >
                        <ManagementApprovalModal
                            isFrom="scansettings"
                            setAddScreen={setAddScreen}
                            heading="Change Scan Location"
                        />
                    </LLModal>
                </div>
                <div className="inboundBoxContent">
                    <div className="row customerGroupInboundDiv ml-0 mr-0 px-1 py-2">
                        <div className="col-sm-6 pl-0">
                            <span className="label-name float-left">
                                {key1}
                            </span>
                        </div>
                        <div className="col-sm-6 p-0">
                            <span className="label-name float-right item-description">
                                {value1}
                            </span>
                        </div>
                    </div>
                    <div className="row storeInboundDiv ml-0 mr-0 px-1 py-2">
                        <div className="col-sm-6 pl-0">
                            <span className="label-name float-left">
                                {key2}
                            </span>
                        </div>
                        <div className="col-sm-6 p-0">
                            <span className="label-name float-right">
                                {value2}
                            </span>
                        </div>
                    </div>
                    <div className="row customerGroupInboundDiv ml-0 mr-0 px-1 py-2">
                        <div className="col-sm-6 pl-0">
                            <span className="label-name float-left">
                                {key3} #
                            </span>
                        </div>
                        <div className="col-sm-6 p-0">
                            <span className="label-name float-right">
                                {value3}
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ScanContentBox;
