import { FC, useState, useEffect } from "react";
import { TextBoxComponent } from "@syncfusion/ej2-react-inputs";
import "./CreditMemoTable.scss";
import { Internationalization } from "@syncfusion/ej2-base";
import { RootState } from "../../../../redux/store";
import { useSelector } from "react-redux";

interface CreditList {
  list: any;
  attachmentTabIndex: number;
  onDataChange: (
    id: string,
    input: string,
    value: string,
    list: any,
    attachmentIndex: number
  ) => void;
}
let intl = new Internationalization();
let nFormatter = intl.getNumberFormat({
  skeleton: "C3",
  currency: "USD",
  minimumIntegerDigits: 0,
  minimumFractionDigits: 2,
});

const InvoiceTableDynamic: FC<CreditList> = ({
  list,
  onDataChange,
  attachmentTabIndex,
}) => {
  const handleCreditMemoDataChange = (id: string, input: string, value: string) => {
    const idString = id.split("-")
    const idValue = parseInt(idString[1])
    {list.map((element: any, i: number) => {
      
    })}
    if(parseFloat(value) > list[idValue].price ){
      value = list[idValue].creditPrice.toString()
    }
    onDataChange(id, input, value, list, attachmentTabIndex);
  };
  const arInvoiceSavedata = useSelector(
    (store: RootState) => store.invoiceReducer.arInvoiceSaveData
  );
  const changedCredit = useSelector(
    (store: RootState) => store.invoiceReducer.changedCredit
  );
  console.log("changedCredit", changedCredit)
  const [test, setTest] = useState(() => []);
  let creditMemoInfo = useSelector(
    (state: RootState) => state.invoiceReducer.creditMemoSavedData
  );
  useEffect(() => {
    console.log("list",list)
    });

  return (
    <div className="credit-memo-input-table">
      <div className="credit-memo-input-table-header">
        <span>CREDIT($)</span>
      </div>
      <div className="credit-memo-input-table-warpper">
        {list.map((element: any, i: number) => {
          return (
            <div key={i} className="credit-memo-input-table-item">
              {/* <div className="e-input-group"> */}
              {/* <p>{element.creditPrice}</p> */}
              <span>$</span>
              <input
                id={`creditmemoid-${i}`}
                name={`credit-${i}`}
                className="e-input"
                type="number"
                style={{ color: "red", borderBottom: "1px solid #c0b8b8" }}
                onChange={(e: any) => {
                  handleCreditMemoDataChange(
                    `credit-${i}`,
                    `creditmemoid-${i}`,
                    e.target.value
                  );
                }}
                value={element.creditPrice}
                disabled = {creditMemoInfo.status.status !== "Draft"}
              />              
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default InvoiceTableDynamic;
