import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../redux/store';
import { ReactComponent as Scan } from '../../../icons/Scan-qr.svg';
import ContentHeader from '../../common/ContentHeader/ContentHeader';
import Divider from '../../common/divider/Divider';
import { ButtonComponent } from '@syncfusion/ej2-react-buttons';
import React, {
    forwardRef,
    useEffect,
    useImperativeHandle,
    useState,
} from 'react';
import {
    getEndpoint,
    getListofValues,
    getLocalStorageItems,
    openInboundBox,
    setLocalStorageItems,
} from '../utils';
import { EndPoints } from '../constants';
import notify from '../../../services/toasterService';
import { setInboundBoxDetails, setScanModeListReducer, setScanModeReducer } from '../scanReducer';
import moment from 'moment';
import { InputField } from '../../input-field/input-field';
import toast from 'react-hot-toast';

export interface StoreDetails {
    id: number;
    storeName: string;
    mainframeCustomerNumber: string;
    storeNameAndMainframeCustomerNumber: string;
    underReview: boolean;
    reasonCode: string;
}

const OpenInboundBox = forwardRef((props: any, ref) => {
    const { setAddScreen, resetFocusToUpc, isFrom, setScanMode, updateSettings } = props;
    const [store, setStore] = useState('');
    const [box, setBox] = useState('');
    const [weekEnding, setWeekEnding] = useState('');
    const [validStore, setValidStore] = useState<boolean>(false);
    const [validBox, setValidBox] = useState<boolean>(false);
    const [validWeekEnd, setValidWeekEnd] = useState<boolean>(false);
    const [boxError, setBoxError] = useState<string>('');
    const [weekEndError, setWeekEndrror] = useState<string>('');
    const storeInputFieldRef = React.createRef<HTMLInputElement>();
    const setScanSettings = useSelector(
        (state: RootState) => state.scanReducer.scanSettings
    );
    const getScanModeList = useSelector(
        (state: RootState) => state.scanReducer.scanModeList
    );
    let interval: any;
    let barcode = '';
    let barcodeArray: any = [];
    const dispatch = useDispatch();
    useImperativeHandle(ref, () => ({
        clearForm() {
            if(props?.resetScanMode) {
                props.resetScanMode(props.isFrom);
            }
        },
    }));

    useEffect(() => {
        getWeekEnd();
        resetFocusToStore();
    }, []);
    const getWeekEnd = () => {
        const dt = new Date();
        const weekendDate = dt.getDate() + (7 - (dt.getDay() + 1));
        dt.setDate(weekendDate);
        const finalWeekendDate = dt.toISOString();
        setWeekEnding(moment(finalWeekendDate).format('MMDDYY'));
        setValidWeekEnd(true);
    };

    const resetFocusToStore = () => {
        storeInputFieldRef.current?.focus();
    };

    const handleCancel = () => {
        setAddScreen(false);
        resetFocusToUpc();
        reset();
        if(isFrom==='audit_inbound'){
        if(getScanModeList?.payload?.includes('audit')) {
            dispatch(setScanModeListReducer(""));
            dispatch(setScanModeReducer('box'));
            setScanMode('box');
            updateSettings();
        }
    }
    };

    function handleStoreFieldEnterKey() {
        if (barcode.length > 9 || barcode.length <= 15) {
            const val = handleBarcode(barcode);
            if (val.length === 15) {
                const store = val.substr(0, 5);
                const box = val.substr(5, 4);
                const weekendDate = val.substr(9, 6);
                setWeekEnding(weekendDate);
                onStoreChange(store);
                onBoxChange(box);
                validateStoreAndOpenBox(store, box, weekendDate);
                return;
            } else if (val.length === 11) {
                const store = val.substr(0, 7);
                const box = val.substr(7, 4);
                onStoreChange(store);
                onBoxChange(box);
                setWeekEnding('');
                getWeekEnd();
                validateStoreAndOpenBox(store, box, weekEnding);
                return;
            } else if (val.length === 9) {
                const store = val.substr(0, 5);
                const box = val.substr(5, 4);
                onStoreChange(store);
                onBoxChange(box);
                setWeekEnding('');
                getWeekEnd();
                validateStoreAndOpenBox(store, box, weekEnding);
                return;
            } else if (val.length === 10) {
                const store = val.substr(0, 6);
                const box = val.substr(6, 4);
                onStoreChange(store);
                onBoxChange(box);
                setWeekEnding('');
                getWeekEnd();
                validateStoreAndOpenBox(store, box, weekEnding);
                return;
            } else if (val.length === 11) {
                const store = val.substr(0, 7);
                const box = val.substr(7, 4);
                onStoreChange(store);
                onBoxChange(box);
                setWeekEnding('');
                getWeekEnd();
                validateStoreAndOpenBox(store, box, weekEnding);
                return;
            } else if (val.length === 12) {
                const store = val.substr(0, 8);
                const box = val.substr(8, 4);
                onStoreChange(store);
                onBoxChange(box);
                setWeekEnding('');
                getWeekEnd();
                validateStoreAndOpenBox(store, box, weekEnding);
                return;
            }
            barcode = '';
            return;
        }
    }

    const handleStoreBarcode = (e: any) => {
        barcode = e.target.value;
        if (e.keyCode === 13) {
            handleStoreFieldEnterKey();
        }
    };

    function handleBarcode(scanned_barcode: any) {
        return scanned_barcode;
    }

    const validToOpenBox = () => {
        return validBox && validStore && validWeekEnd;
    };

    const validateStoreAndOpenBox = (
        selectedStore: any,
        selectedBox: any,
        selectedWeekEnding: any
    ) => {
        const endpoint: any = getEndpoint(EndPoints.STORELIST, selectedStore);
        const result = getListofValues(endpoint);
        result
            .then((data: any) => {
                const storesList: StoreDetails[] = data.data.content;
                const storeDetails = storesList?.find(
                    (s) =>
                        Number(selectedStore) ===
                        Number(s.mainframeCustomerNumber)
                );
                if (storeDetails) {
                    setStore(storeDetails.mainframeCustomerNumber);
                    if (
                        storeDetails.underReview &&
                        isFrom !== 'audit_inbound' && storeDetails.mainframeCustomerNumber !=='0000015' 
                    ) {
                        setAddScreen(false);
                        props.openUnderReview(
                            storeDetails.mainframeCustomerNumber,
                            storeDetails.reasonCode,
                            selectedBox,
                            selectedWeekEnding
                        );
                    } else {
                        const payload = {
                            scanGroup: setScanSettings.payload.scangroup
                                ? setScanSettings.payload.scangroup
                                : '',
                            scanStation: setScanSettings.payload.scanStation
                                ? setScanSettings.payload.scanStation
                                : '',
                            reclaimCenter: setScanSettings.payload.reclaimCenter
                                ? setScanSettings.payload.reclaimCenter
                                : '',
                            storeId: storeDetails.mainframeCustomerNumber,
                            inboundBoxId: selectedBox,
                            weekendDate: selectedWeekEnding,
                        };
                        const endpoint: any = getEndpoint(
                            EndPoints.OPENINBOUND
                        );
                        const result = openInboundBox(endpoint, payload);
                        result
                            .then((data) => {
                                setLocalStorageItems('inboundBox', data.data);
                                resetFocusToUpc();
                                if (isFrom === 'audit_inbound') {
                                    setAddScreen(false);
                                    resetFocusToUpc();
                                    //setScanMode(val);
                                    dispatch(setScanModeReducer(isFrom));
                                    //setManageApproval(true);
                                    // notify('audit', 'auditmode');
                                    toast(
                                        'You are scanning in Inbound Audit Mode. These scans will not be reported.',
                                        {
                                            duration: 5000,
                                            position: 'top-center',
                                            // Styling
                                            style: {},
                                            className: 'auditmode',
                                            // Custom Icon
                                            icon: '',
                                            // Change colors of success/error/loading icon
                                            iconTheme: {
                                                primary: '#000',
                                                secondary: '#fff',
                                            },
                                            // Aria
                                            ariaProps: {
                                                role: 'status',
                                                'aria-live': 'polite',
                                            },
                                        }
                                    );
                                    document.body.style.backgroundColor =
                                        '#e3e4fa';
                                } else {
                                    notify(
                                        'Opened Inbound Box Successfully',
                                        'success'
                                    );
                                }
                                dispatch(
                                    setInboundBoxDetails(
                                        getLocalStorageItems('inboundBox')
                                    )
                                );
                                reset();
                                setAddScreen(false);
                            })
                            .catch((error) => {
                                if (error && error?.response)
                                    notify(
                                        error.response.data.message.messageText,
                                        'error-right'
                                    );
                                setAddScreen(false);
                                resetFocusToUpc();
                            });
                        reset();
                    }
                } else {
                    notify('Cannot find store.','error-right');
                }
            })
            .catch((error: any) => {
                notify(error.message, 'error-right');
            });
    };
    const openBox = () => {
        if (validToOpenBox()) {
            validateStoreAndOpenBox(store, box, weekEnding);
        }
    };
    const reset = () => {
        setBox('');
        setStore('');
        setValidBox(false);
        setValidStore(false);
        setBoxError('');
    };

    const onBoxChange = (value: any) => {
        setBox(value);
        if (!value) {
            setValidBox(false);
            return;
        }
        if (value.length === 4) {
            setValidBox(true);
            setBoxError('');
        } else if (value.length > 4) {
            setBoxError('Box Id length should be 4 digits.');
            setValidBox(true);
            //Client want this way
            setBox(value.substring(0, 4));
        } else {
            setBoxError('');
            setValidBox(false);
        }
    };
    const onWeekEndChange = (value: any) => {
        setWeekEnding(value);
        if (!value) {
            setValidWeekEnd(false);
            return;
        }
        if (value.length === 6) {
            setValidWeekEnd(true);
            setWeekEndrror('');
        } else if (value.length > 6) {
            setWeekEndrror('Week Ending length should be 6 digits.');
            setValidWeekEnd(true);
            // //Client want this way
             setWeekEnding(value.substring(0, 6));
        } else {
            setValidWeekEnd(false);
        }
    }
    const onStoreChange = (value: any) => {
        setStore(value);
        if (value && value.length > 0) {
            setValidStore(true);
        }
    };
    return (
        <>
            <ContentHeader value="Open Inbound Box" />
            <Divider />
            <div className="col-sm-12 p5">
                <div className="col-sm-4">
                    <div className="text-center fb-15 popupName">
                        Scan Inbound Box Label
                    </div>
                    <Scan width={83} height={83} className="scan-inbound m6" />
                </div>
                <div className="col-sm-8">
                    <div className="text-center fb-15 inbound-box-heading popupName">
                        Enter Inbound Box Details
                    </div>
                    <div className="form-group p5">
                        <label className="control-label col-sm-4">
                            <b className="requiredStar">*</b> Store #
                        </label>
                        <div className="col-sm-8 ss">
                            <InputField
                                id="store-number"
                                type="number"
                                className="form-control"
                                onChange={onStoreChange}
                                onKeyUp={handleStoreBarcode}
                                initialValue={store}
                                ref={storeInputFieldRef}
                                autoFocus={true}
                                onEnter={handleStoreFieldEnterKey}
                            />
                        </div>
                    </div>
                    <div className="form-group p5">
                        <label className="control-label col-sm-4">
                            <b className="requiredStar">*</b> 4 Digit Box #
                        </label>
                        <div className="col-sm-8">
                            <InputField
                                id="box-number"
                                type="number"
                                className="form-control"
                                onChange={onBoxChange}
                                initialValue={box}
                                maxLength={2}
                            />
                            {boxError ? (
                                <span className="text-danger">{boxError}</span>
                            ) : (
                                ''
                            )}
                        </div>
                    </div>
                    <div className="form-group p2">
                        <label className="control-label col-sm-4">
                            Week Ending #
                        </label>
                        <div className="col-sm-8">
                            <InputField
                                type="number"
                                className="form-control"
                                onChange={onWeekEndChange}
                                initialValue={weekEnding}
                                maxLength={6}
                            />
                            {weekEndError ? (
                                <span className="text-danger">{weekEndError}</span>
                            ) : (
                                ''
                            )}
                        </div>
                    </div>
                </div>
                <div className="col-sm-9 mt-2rem">
                    <ButtonComponent
                        cssClass="eg-btn-primary float-right btnBox"
                        onClick={openBox}
                        disabled={!validToOpenBox()}
                    >
                        Open Box
                    </ButtonComponent>
                    <button
                        className="eg-btn-secondary float-right mr-4"
                        onClick={() => handleCancel()}
                    >
                        CANCEL
                    </button>
                </div>
            </div>
        </>
    );
});
export default OpenInboundBox;
