import React, { FC } from "react";
import "./ContentHeader.scss";

interface IContentHeaderProps {
  value: string;
}

const ContentHeader: FC<IContentHeaderProps> = ({ value }) => {
  return <span className="content-header">{value}</span>;
};

export default ContentHeader;
