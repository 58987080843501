import { useCallback, useEffect, useState } from "react";
import {
  ButtonComponent,
  RadioButtonComponent,
} from "@syncfusion/ej2-react-buttons";
import { TextBoxComponent } from "@syncfusion/ej2-react-inputs";
import { AutoCompleteComponent, ComboBoxComponent, DropDownListComponent } from "@syncfusion/ej2-react-dropdowns";
import ContentHeader from "../../../common/ContentHeader/ContentHeader";
import Divider from "../../../common/divider/Divider";
import "./MiscSalvageItemForm.scss";
import { getUpcDetailsById } from "../../../accessMgmt/utils";
import axios from "axios";
import NumberFormat from "react-number-format";
import notify from "../../../../services/toasterService";
import AsyncSelect from 'react-select/async';
import { setIsInvoiceUpdated } from "../../invoiceReducer";
import { useDispatch } from "react-redux";

interface FPIDeatils {
  price: number;
  upc: string;
  description: string;
  barCode: string;
  cost: string;
}

const MiscSalvageItemForm = (props: any) => {
  const [calculatedPrice, setCalculatedPrice] = useState("0");
  const [txtBox, setTxtBox] = useState(false);
  const [txtBin, setTxtBin] = useState(false);
  const [MiscItemDetails, setMiscItemDetails] = useState({
    upc: "",
    description: "",
    itemCost: "0",
    noOfCases: "0",
    noOfItems: "0",
    costPercentage: "",
    fixedPercentage: "0",
    //price:""
  });
  const autoCompleteFields = {
    value: "upc",
    text: "upc",
  };
  const [percent, setPercent] = useState(0);
  const [ShowCustom, setShowCustom] = useState(false);
  const [bins, setbins] = useState(0);
  const [boxes, setboxes] = useState(0);
  const [items, setitems] = useState(0);
  const [itemCost, setitemCost] = useState(0);
  const [dataSource, setDataSource] = useState([]);
  // const [totalPrice, setTotalPrice] = useState("");
  const dispatch = useDispatch();
  let dropDownListObject:any;
  let isStop=true;
  const handleChangeBin = (val: any) => {
    if (val > 0) {
      setTxtBox(true);
    } else {
      setTxtBox(false);
    }
  };
  const handleChangeBox = (val: any) => {
    if (val > 0) {
      setTxtBin(true);
    } else {
      setTxtBin(false);
    }
  };

  useEffect(() => {
    console.log(MiscItemDetails);
    if (
      MiscItemDetails.noOfCases.length > 0 &&
      MiscItemDetails.noOfItems.length > 0 &&
      MiscItemDetails.itemCost.length > 0 &&
      MiscItemDetails.costPercentage.length > 0
    ) {
      const { noOfCases, noOfItems, itemCost, costPercentage } =
        MiscItemDetails;
      const totalCost =
        ((+noOfCases * +noOfItems * +itemCost) / 100) * +costPercentage;

      setCalculatedPrice(totalCost.toFixed(2) + "");
      const ddlSelectCustType = document.getElementById(
        'async-upc'
    ) as HTMLSelectElement;
      ddlSelectCustType.value = MiscItemDetails.upc;
    }
    // // setTxtBox(false);
    // // setTxtBin(false);
    // let bin = bins;
    // let box = boxes;
    // let item = items;
    // // x = itemCost * (item);
    // console.log(itemCost, box, item, percent);
    // x = (+MiscItemDetails.cost * +box * +item) / 100;
    // x = x * percent;
    // setMiscItemDetails({ ...MiscItemDetails, price: x });
  }, [MiscItemDetails]);

  useEffect(()=>{
    const ddlSelectCustType = document.getElementById(
      'async-upc'
  ) as HTMLSelectElement;
  if (ddlSelectCustType && MiscItemDetails.upc==='' ) {
      ddlSelectCustType.focus();
      dropDownListObject.showPopup();
  } 
  },[MiscItemDetails])

  useEffect(() => {
    if (props.PickedMiscDataForEdit?.length > 0) {
      console.log(">>>>> Misc", props.PickedMiscDataForEdit);
      setMiscItemDetails({
        upc: props.PickedMiscDataForEdit[0].upc,
        description: props.PickedMiscDataForEdit[0].description,
        itemCost: props.PickedMiscDataForEdit[0].cost + "",
        noOfCases: props.PickedMiscDataForEdit[0].boxes + "",
        noOfItems: props.PickedMiscDataForEdit[0].items + "",
        costPercentage: props.PickedMiscDataForEdit[0].rate + "",
        //price: props.PickedMiscDataForEdit[0].price + "",
        fixedPercentage: "Y"
      });    
      // // setbins(parseInt(props.PickedMiscDataForEdit[0]?.bins))
      // setboxes(parseInt(props.PickedMiscDataForEdit[0]?.boxes));
      // setitems(parseInt(props.PickedMiscDataForEdit[0]?.items));
      // setitemCost(parseInt(props.PickedMiscDataForEdit[0]?.itemCost));
    } else {
      setMiscItemDetails({
        upc: "",
        description: "",
        itemCost: "0",
        noOfCases: "0",
        noOfItems: "0",
        costPercentage: "",
        fixedPercentage: "Y",
        //price:""
      });
      setCalculatedPrice("0");
    }
  }, [props.PickedMiscDataForEdit]);

  const onChangeUpcDetails = (e:any) => {
    if (e && e[0].upc) {
      setMiscItemDetails({
        ...MiscItemDetails,
        upc: e[0]["upc"],
        description:
          e[0]["description"] ||
          MiscItemDetails["description"],
        itemCost:
          (e[0]["unitCost"] || MiscItemDetails["itemCost"]) +
          "",
      });
    } else {
      setMiscItemDetails({
        ...MiscItemDetails,
        upc: "",
        description: "",
        itemCost: "0",
      });
    }
  }
  const getUPCDetails = async (element:any) => {
    const endPointURL = getUpcDetailsById();
   await axios
      .get(endPointURL, { params: { upc: element, size: 500 } })
      .then((res: any) => {
        if (res.status === 200) {
          if(res.data['content'].length === 1) {
            onChangeUpcDetails(res.data['content']);
          }
         else if(res.data["content"].length > 1) {
          setDataSource(res.data["content"]);
          }          
           else {
            const ddlSelectCustType = document.getElementById(
              'async-upc'
          ) as HTMLSelectElement;
          setDataSource([]);
          ddlSelectCustType.value ='';
            MiscItemDetails.upc = '';
          }
        }
      })
      .catch((err) => {});
  }

  const handleUPCDetails = (event: any) => {
    if(dropDownListObject) {
    dropDownListObject?.filterInput?.addEventListener("keydown", (e:any) =>{
      if (e.key == "Enter" && isStop) {
    if (e && e.target.value.length >= 4) {
      getUPCDetails(e.target.value);
      isStop = false;
    } 
  }
});
    } else {
const ddlSelectCustType = document.getElementById(
  'async-upc'
) as HTMLSelectElement;
ddlSelectCustType?.addEventListener("keydown", (e:any) =>{
  if (e.key == "Enter" && isStop) {
if (e && e.target.value.length >= 4) {
  getUPCDetails(e.target.value);
  isStop = false;
} 
}
})
    }
}
const handleCustomPercentage= (e: any) => {
 
  if(parseInt(e.target.value) > 100){
    console.log(e.target.value)
    var Cname = document.getElementById("txtCustomPerc") as HTMLInputElement;
    Cname.value = '';
    notify('not more than 100', 'error')
  }
  else{
    setMiscItemDetails({
      ...MiscItemDetails,
      costPercentage: e.target.value,
    })
  }
 
}
  const validateValue = (event: any) => {
    const val = event.value.split(".");
    var itemCostVal = val[0];
    var cost = val[1];
    if (cost) {
      var decPoint = cost.toString().length;
      if (decPoint < 3) {
        var cost = val[1];
        setMiscItemDetails({
          ...MiscItemDetails,
          itemCost: event.value,
        });
      } else {
        if (decPoint > 2) {
          const subStringVal = event.value
            .split(".")
            .substr(event.value.indexOf("."), 3);
          const totalVal = itemCostVal + subStringVal;
          setMiscItemDetails({
            ...MiscItemDetails,
            itemCost: totalVal,
          });
        }
        // setMiscItemDetails({
        // ...MiscItemDetails,
        // itemCost: itemCostVal ,
        // })
      }
    } else {
      setMiscItemDetails({
        ...MiscItemDetails,
        itemCost: itemCostVal,
      });
    }
  };
  const handleItemCost = (e: any) => {
    const val = e.value.split(".")
    var itemCostVal = val[0];
    var cost = val[1];
    if(cost){
    var decPoint = cost.toString().length
    if(decPoint < 3){
    var cost = val[1];
    setMiscItemDetails({
    ...MiscItemDetails,
    itemCost: e.value,
    })
    } else {
    setMiscItemDetails({
    ...MiscItemDetails,
    itemCost: itemCostVal,
    })
    }
    } else {
    setMiscItemDetails({
    ...MiscItemDetails,
    itemCost: itemCostVal ,
    })
    }
    }
  return (
    <div>
      <ContentHeader value="Add Misc-Salvage Item" />
      <Divider />
      <div className="miscSalvage-form">
        <div className="miscSalvage-form-column">
          <div className="miscSalvage-form-field ">
            <p>UPC</p>
            {/* <AutoCompleteComponent
              fields={{ value: "upc" }}
              dataSource={dataSource}
              filtering={handleUPCDetails.bind(this)}
              value={MiscItemDetails.upc}
              change={(e: any) => {
                if (e && e?.itemData) {
                  setMiscItemDetails({
                    ...MiscItemDetails,
                    upc: e.itemData["upc"],
                    description:
                      e.itemData["description"] ||
                      MiscItemDetails["description"],
                    itemCost:
                      (e.itemData["unitCost"] || MiscItemDetails["itemCost"]) +
                      "",
                  });
                } else {
                  setMiscItemDetails({
                    ...MiscItemDetails,
                    upc: "",
                    description: "",
                    itemCost: "0",
                  });
                }
              }}
            /> */}
             <ComboBoxComponent
             id="async-upc"
                allowFiltering={true}
                autofill={true} 
                dataSource={dataSource}
                ref={(scope) => { dropDownListObject = scope; }}
                filtering={handleUPCDetails.bind(this)}
                fields={autoCompleteFields}
                change={(e: any) => {
                  if (e && e?.itemData) {
                    setMiscItemDetails({
                      ...MiscItemDetails,
                      upc: e.itemData["upc"],
                      description:
                        e.itemData["description"] ||
                        MiscItemDetails["description"],
                      itemCost:
                        (e.itemData["unitCost"] || MiscItemDetails["itemCost"]) +
                        "",
                    });
                  } else {
                    setMiscItemDetails({
                      ...MiscItemDetails,
                      upc: "",
                      description: "",
                      itemCost: "0",
                    });
                  }
                }}
              />
              {/* <AsyncSelect
              id="async-upc"
                                        //cacheOptions
                                        defaultOptions
                                        autoFocus={true}
                                        defaultMenuIsOpen
                                        getOptionLabel={(e: any) =>
                                            e.upc
                                        }
                                         getOptionValue={(e) => e.upc}
                                        loadOptions={handleUPCDetails}
                                        onChange={
                                            (e: any) => {
                  if (e && e?.itemData) {
                    setMiscItemDetails({
                      ...MiscItemDetails,
                      upc: e.itemData["upc"],
                      description:
                        e.itemData["description"] ||
                        MiscItemDetails["description"],
                      itemCost:
                        (e.itemData["unitCost"] || MiscItemDetails["itemCost"]) +
                        "",
                    });
                  } else {
                    setMiscItemDetails({
                      ...MiscItemDetails,
                      upc: "",
                      description: "",
                      itemCost: "0",
                    });
                  }
                }
                                        }
                                    /> */}
          </div>
          <div className="miscSalvage-form-field">
            <p>Description</p>
            <TextBoxComponent
              style={{ fontSize: "15px", textAlign: "center", fontWeight: 500 }}
              value={MiscItemDetails.description}
              onChange={(e: any) =>
                setMiscItemDetails({
                  ...MiscItemDetails,
                  description: e.target.value,
                })
              }
            />
          </div>

          <div className="miscSalvage-form-field">
            <p>
              # of Cases<b className="requiredStar">*</b>
            </p>
            <TextBoxComponent
              type={"number"}
              style={{ fontSize: "15px", textAlign: "center", fontWeight: 500 }}
              value={MiscItemDetails.noOfCases}
              onChange={(e: any) =>
                setMiscItemDetails({
                  ...MiscItemDetails,
                  noOfCases: e.target.value,
                })
              }
            />
          </div>

          <div className="miscSalvage-form-field">
            <p>
              # of Items in Case<b className="requiredStar">*</b>
            </p>
            <TextBoxComponent
              type={"number"}
              style={{ fontSize: "15px", textAlign: "center", fontWeight: 500 }}
              value={MiscItemDetails.noOfItems}
              onChange={(e: any) =>
                setMiscItemDetails({
                  ...MiscItemDetails,
                  noOfItems: e.target.value,
                })
              }
            />
          </div>
        </div>
        <div className="miscSalvage-form-column">
          <div className="miscSalvage-form-field">
            <p>
              Item cost $<b className="requiredStar">*</b>
            </p>
            <TextBoxComponent
              type={"number"}
              htmlAttributes={{
                max: "2",
                min: "2",
                // maxLength: "2",
                // minLength: "2",
              }}
              style={{ fontSize: "15px", textAlign: "center", fontWeight: 500 }}
              value={MiscItemDetails.itemCost}
               onChange={(event: any) => handleItemCost(event)}
              // onChange={(e: any) =>
              //   setMiscItemDetails({
              //     ...MiscItemDetails,
              //     itemCost: e.target.value,
              //   })
              // }
            />
          </div>
          <div className="miscSalvage-radio">
            <p className="percOfCost">
              % of cost<b className="requiredStar">*</b>
            </p>
            <div className="miscSalvage-form-radiobuttons">
              <RadioButtonComponent
                label="Out of code (30%)"
                name="codePercentage"
                value="30"
                // ={MiscItemDetails.costPercentage}
                checked={MiscItemDetails.costPercentage === "30"}
                change={(e: any) =>
                  setMiscItemDetails({
                    ...MiscItemDetails,
                    costPercentage: e.value,
                    fixedPercentage: "Y",
                  })
                }
              />
              <RadioButtonComponent
                label="In code (40%)"
                name="codePercentage"
                value="40"
                checked={MiscItemDetails.costPercentage === "40"}
                change={(e: any) =>
                  setMiscItemDetails({
                    ...MiscItemDetails,
                    costPercentage: e.value,
                    fixedPercentage: "Y",
                  })
                }
              />
              <div className="miscSalvage-form-radiobuttons-withinput">
                <RadioButtonComponent
                  label="Custom (%)"
                  name="codePercentage"
                  value="C"
                  checked={MiscItemDetails && MiscItemDetails.costPercentage !== '30' && MiscItemDetails.costPercentage !== '40'}
                  change={(e: any) =>
                    setMiscItemDetails({
                      ...MiscItemDetails,
                      fixedPercentage: "N",
                    })
                  }
                />
                {/* {MiscItemDetails.costPercentage !== '30' && MiscItemDetails.costPercentage !== '40' && ( */}
                  <span className="radiobuttons-input radio-input misCustomInput">
                    <input type="number" id="txtCustomPerc"
                        defaultValue={MiscItemDetails && MiscItemDetails.costPercentage !== '30' && MiscItemDetails.costPercentage !== '40' ?MiscItemDetails.costPercentage && MiscItemDetails.costPercentage : 0}  style={{width :"30%",marginLeft: "1rem"}}
                        onKeyUp={(e: any) => handleCustomPercentage(e)}/>
                    {/* <TextBoxComponent
                    id="txtCustomPerc"
                      htmlAttributes={{
                        max: "3",
                        min: "3",
                        maxLength: "3",
                        minLength: "3",
                      }}
                      style={{
                        fontSize: "15px",
                        textAlign: "center",
                        fontWeight: 500,
                      }}
                      value={percent.toString()}
                      onFocus={(e: any) => handleCustomPercentage(e)
                        // setMiscItemDetails({
                        //   ...MiscItemDetails,
                        //   costPercentage: e.target.value,
                        // })
                      }
                    /> */}
                  </span>
                {/* )} */}
              </div>
            </div>
          </div>
          <div>
            <span className="cal-price-text">Calculated price</span>
            <span className="miscSalvage-cal-price">
              {calculatedPrice.length > 0 && (
                <NumberFormat
                  decimalScale={2}
                  fixedDecimalScale={true}
                  decimalSeparator="."
                  displayType={"text"}
                  thousandSeparator={true}
                  prefix={"$"}
                  value={calculatedPrice}
                />
               )}
            </span>
          </div>
        </div>
      </div>
      <div
        className="col-sm-12"
        style={{
          display: "flex",
          marginLeft: "-2rem",
          justifyContent: "flex-end",
        }}
      >
        <ButtonComponent
          style={{ color: "black", margin: "20px", padding: "10px 30px" }}
          onClick={() => props.setShowAttachmentModal(false)}
        >
          CANCEL
        </ButtonComponent>
        <ButtonComponent
          style={{
            color: "white",
            backgroundColor: "#636799",
            margin: "20px",
            padding: "10px 30px",
          }}
          onClick={() => {
            props.setData({
              itemCost: +MiscItemDetails.itemCost,
              itemsCount: +MiscItemDetails.noOfItems,
              //binsCount: +MiscItemDetails.noOfCases,
              boxesCount: +MiscItemDetails.noOfCases,
              costPercentage: +MiscItemDetails.costPercentage,
              calculatePrice: +calculatedPrice,
              upc: MiscItemDetails.upc,
              description: MiscItemDetails.description,
            });
            dispatch(setIsInvoiceUpdated(true));
          }}
        >
          ADD ITEM
        </ButtonComponent>
      </div>
    </div>
  );
};

export default MiscSalvageItemForm;
