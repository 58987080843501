import HttpService from '../http-service/http-service';
import { API_PATH, getApiBaseUrl } from '../../api-paths';

export interface PrinterSettingsResponse {
    boxLabelPrinterName: string;
    palletLabelPrinterName: string;
}

export default class PrinterService {
    public async getPrinterSettings(
        reclaimCenterId: string,
        scanCode: string
    ): Promise<PrinterSettingsResponse> {
        const url =
            getApiBaseUrl() +
            API_PATH.PRINTER_SETTINGS +
            reclaimCenterId +
            '/' +
            scanCode;
        return HttpService.get<PrinterSettingsResponse>(url).then(
            (response) => {
                return response.data;
            }
        );
    }
}
