import { ButtonComponent } from "@syncfusion/ej2-react-buttons";
import axios from "axios";
import { useState } from "react";
// import { addMasterDataEndPoint, getEndPoint } from './utils';
import { useDispatch } from "react-redux";
import notify from "../../../services/toasterService";
import { setCountryData } from "../lladminreducer";
import userAvatar from "../../../icons/common/user-avatar.png";
import { addLlAdminEndPoint, getLlAdminEndPoint } from "../utils";

const AddCountryCode = (props: any) => {
  const { setAddScreen } = props;

  const dispatch = useDispatch();
  const [country, setCountry] = useState("");
  const [region, setRegion] = useState("");
  const [isoCountryCode, setIsoCountryCode] = useState("");
  const [subRegion, setSubRegion] = useState("");
  const [countryDisabled, setcountryDisabled] = useState(true);
  const [myCountryName, setMyCountryName] = useState("");
  const [myIsoCountry, setMyIsoCountry] = useState("");
  const [myRegion, setMyRegion] = useState("");
  const [mySubRegion, setMySubRegion] = useState("");

  const [errors, setErrors] = useState({
    countryError: "",
    regionError: "",
    isoCountryCodeError: "",
    subRegionError: "",
  });

  const handleCountry = (e: any) => {
    setCountry(e.target.value);
    validate();
  };

  const handleIsoCountryCode = (e: any) => {
    setRegion(e.target.value);
    validate();
  };

  const handleRegion = (e: any) => {
    setIsoCountryCode(e.target.value);
    validate();
  };

  const handleSubRegion = (e: any) => {
    setSubRegion(e.target.value);
    validate();
  };

  /* Create Object to insert the form */
  const body = {
    countryName: country,
    isoCountryCode: isoCountryCode,
    region: region,
    subRegion: subRegion,
    deleted: false,
  };

  const handleAddCOuntry = () => {
    if (validate()) {
      const addEndPoint = addLlAdminEndPoint("Country Codes");
      const getEndPoint = getLlAdminEndPoint("Country Codes");
      addCountryWS(addEndPoint, getEndPoint);
    }
  };

  const addCountryWS = async (addEndPoint: string, getEndPoint: string) => {
    const result = await axios.post(addEndPoint, body);
    if (result && result.data) {
      setAddScreen(false);
      notify("Country code added successfully", "success");
      await axios.get(getEndPoint).then(
        (res) => {
          dispatch(setCountryData(res.data));
        },
        (err) => {
          notify("Failed to fetch data", "error");
        }
      );
    } else {
      notify("Failed to create country code", "error");
    }
  };

  const handlePopUpClose = () => {
    setAddScreen(false);
  };

  const validate = (field?: any, value?: any) => {
    let countryError = "";
    let regionError = "";
    let isoCountryCodeError = "";
    let subRegionError = "";

    setErrors({
      countryError: "",
      regionError: "",
      isoCountryCodeError: "",
      subRegionError: "",
    });

    if (country === "") {
      countryError = "Required Field";
      setMyCountryName("1px solid red");
    } else {
      setMyCountryName("");
    }

    if (region === "") {
      regionError = "Required Field";
      setMyIsoCountry("1px solid red");
    } else {
      setMyIsoCountry("");
    }

    if (isoCountryCode === "") {
      isoCountryCodeError = "Required Field";
      setMyRegion("1px solid red");
    } else {
      setMyRegion("");
    }

    if (region === "") {
      subRegionError = "Required Field";
      setMySubRegion("1px solid red");
    } else {
      setMySubRegion("");
    }

    if (countryError || regionError || isoCountryCodeError || subRegionError) {
      setErrors({
        countryError,
        regionError,
        isoCountryCodeError,
        subRegionError,
      });
      setcountryDisabled(true);
      return false;
    } else {
      setcountryDisabled(false);
    }
    return true;
  };

  return (
    <div>
      <div className="col-sm-12 p-0 d-flex justify-content-center">
        <img src={userAvatar} />
      </div>

      <div className="col-sm-12 row m-0 mt-5">
        <div className="col-sm-6">
          <div className="col-sm-12 mt-3">
            <label className="label-title">Country Name</label>
            {country === "" ? (
              <span className="text-danger" style={{ float: "right" }}>
                {errors.countryError}
              </span>
            ) : (
              ""
            )}
            <input
              type="text"
              className="formcontrol"
              onChange={(e) => handleCountry(e)}
              onBlur={(e) => handleCountry(e)}
              onKeyUp={(e) => handleCountry(e)}
              style={{ border: myCountryName }}
            />
          </div>

          <div className="col-sm-12 mt-3">
            <label className="label-title">ISO Country Code</label>
            {isoCountryCode === "" ? (
              <span className="text-danger" style={{ float: "right" }}>
                {errors.isoCountryCodeError}
              </span>
            ) : (
              ""
            )}
            <input
              type="text"
              className="formcontrol"
              onChange={(e) => handleIsoCountryCode(e)}
              onBlur={(e) => handleIsoCountryCode(e)}
              onKeyUp={(e) => handleIsoCountryCode(e)}
              style={{ border: myIsoCountry }}
            />
          </div>
        </div>

        <div className="col-sm-6">
          <div className="col-sm-12 mt-3">
            <label className="label-title">Region</label>
            {region === "" ? (
              <span className="text-danger" style={{ float: "right" }}>
                {errors.regionError}
              </span>
            ) : (
              ""
            )}
            <input
              type="text"
              className="formcontrol"
              onChange={(e) => handleRegion(e)}
              onBlur={(e) => handleRegion(e)}
              onKeyUp={(e) => handleRegion(e)}
              style={{ border: myRegion }}
            />
          </div>

          <div className="col-sm-12 mt-3">
            <label className="label-title">Sub-Region</label>
            {subRegion === "" ? (
              <span className="text-danger" style={{ float: "right" }}>
                {errors.subRegionError}
              </span>
            ) : (
              ""
            )}
            <input
              type="text"
              className="formcontrol"
              onChange={(e) => handleSubRegion(e)}
              onBlur={(e) => handleSubRegion(e)}
              onKeyUp={(e) => handleSubRegion(e)}
              style={{ border: mySubRegion }}
            />
          </div>
        </div>
      </div>

      <div className="col-sm-12 px-0 my-5" style={{ width: "94%" }}>
        <ButtonComponent
          cssClass="eg-btn-primary float-right"
          disabled={countryDisabled}
          onClick={() => handleAddCOuntry()}
        >
          Add Country
        </ButtonComponent>
        <ButtonComponent
          cssClass="eg-btn-primary-link e-flat mt-3"
          onClick={() => handlePopUpClose()}
        >
          Cancel
        </ButtonComponent>
      </div>
    </div>
  );
};
export default AddCountryCode;
