import React, { FC } from "react";
import "./CustomDropDown.scss";

interface IProps {
  data: any[];
  valueField: string;
  textField: string;
  onChange: (value: string | number) => void;
  selectedOption?: string;
}

const CustomDropdown: FC<IProps> = ({
  data,
  valueField,
  textField,
  onChange,
  selectedOption,
}) => {
  const displayableList = selectedOption
    ? data
    : [{ [valueField]: null, [textField]: "" }, ...data];
  return (
    <select
      className="ll-custom-dropdown form-control"
      onChange={(event: any) => {
        onChange(event.target.value);
      }}
    >
      {displayableList.map((el) => (
        <option
          value={el[valueField]}
          selected={
            selectedOption && el[textField] == selectedOption ? true : false
          }
        >
          {el[textField]}
        </option>
      ))}
    </select>
  );
};

export default CustomDropdown;
