import { ButtonComponent } from "@syncfusion/ej2-react-buttons";
import axios from "axios";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { setCurrentContact, setMasterDataAddImage, setStatesData } from "./contactReducer";
import {
  addMasterDataEndPoint,
  getEndPoint,
  getStatesByCountry,
} from "./utils";
import userIcon from "../../assets/userIcon.png";
import { ContactType } from "./constants";
import notify from "../../services/toasterService";
import CountryDropdown from "../ContactsDetailsPanel/mobile-field-component";
import AddFileUploadScreen from "../accessMgmt/addScreen/fileUpload";
import editIcon from "../../assets/EditRuleExcep.png";
import { getStatesByCountryCode } from "../accessMgmt/utils";

const WarehouseAddScreen = (props: any) => {
  const { setAddScreen } = props;
  useEffect(() => {
    getStatesList('USA');
  },[]);
  const [selectedImage, setSelectedImage] = useState();
  const imageChange = (e: any) => {
    if (e.target.files && e.target.files.length > 0) {
      setSelectedImage(e.target.files[0]);
    }
  };
  const currencyData = useSelector(
    (state: RootState) => state.contactReducer?.currencyData
  );
  const timezoneData = useSelector(
    (state: RootState) => state.contactReducer?.timezoneData
  );
  const languageData = useSelector(
    (state: RootState) => state.contactReducer?.laungageData
  );
  const storeData = useSelector(
    (state: RootState) => state.contactReducer?.stores
  );
  const countryInfo = useSelector(
    (state: RootState) => state.contactReducer?.countryData
  );
  const reclaimCentersData = useSelector(
    (state: RootState) => state.contactReducer?.reclaimCenters
  );
  const countryCodeInfo = useSelector(
    (state: RootState) => state.contactReducer?.countryCodePhoneData
  );
  const statesInfo = useSelector(
    (state: RootState) => state.contactReducer?.statesData
  );

  const mobileWithFlag = useSelector(
    (state: RootState) => state.contactReducer?.selectedMobileWithCountryFlag
  );
  const altMobileWithFlag = useSelector(
    (state: RootState) => state.contactReducer?.selectedAltMobileWithCountryFlag
  );
  const faxWithFlag = useSelector(
    (state: RootState) => state.contactReducer?.selectedFaxWithCountryFlag
  );
  const phoneCountryCode = useSelector(
    (state: RootState) => state.contactReducer?.selectedCountryCodeForMobile
  );
  const AltPhoneCountryCode = useSelector(
    (state: RootState) => state.contactReducer?.selectedCountryCodeForAltMobile
  );
  const faxCountryCode = useSelector(
    (state: RootState) => state.contactReducer?.selectedCountryCodeForFax
  );
  // const handleGetMastersData = async (
  //   ) => {
  //   const reclaimCenterEndPoint = getEndPoint(ContactType.RECLAIM_CENTERS);
  //   const reclaimCenterResult = await axios.get(reclaimCenterEndPoint);
  //   dispatch(setReclaimCenters(reclaimCenterResult.data));
  //   };
  //   useEffect(() => {
  //       handleGetMastersData();
  //   });

  const dispatch = useDispatch();
  const [warehouseName, setWarehouseName] = useState("");
  const [warehouseEmail, setWarehouseEmail] = useState("");
  const [warehousePhone, setWarehousePhone] = useState("");
  const [warehouseAltPhone, setWarehouseAltPhone] = useState("");
  const [warehouseFax, setWarehouseFax] = useState("");
  const [warehouseAddressStreet, setWarehouseAddressStreet] = useState("");
  const [warehouseAddressCity, setWarehouseAddressCity] = useState("");
  const [warehouseAddressState, setWarehouseAddressState] = useState("");
  const [warehouseAddressZip, setWarehouseAddressZip] = useState("");
  const [warehouseAddressCountry, setWarehouseAddressCountry] = useState("");
  const [warehouseLinkStores, setWarehouseLinkStores] = useState<any[]>([]);
  const [warehouseLanguage, setWarehouseLanguage] = useState("en-US");
  const [warehouseCurrencyCode, setWarehouseCurrencyCode] =
    useState("US Dollar");
  const [warehouseTimeZone, setWarehouseTimeZone] = useState(
    "Eastern Standard Time"
  );
  const [wareHouseCountryCodePhone, setWareHouseCountryCodePhone] =
    useState("");
  const [wareHouseCountryCodeAltPhone, setWareHouseCountryCodeAltPhone] =
    useState("");
  const [wareHouseCountryCodeFax, setWareHouseCountryCodeFax] = useState("");
  //const [warehouseFinalAddedStores, setWarehouseFinalAddedStores] =  useState<any[]>([]);

  //******************** */
  const [emailErrorMsg, setEmailErrorMsg] = useState("");
  const [myWareHouseName, setMyWareHouseName] = useState('')
  const [isWHLScreenDisabeled, setIsWHLScreenDisabeled] = useState(true);
  const [wareHouseInfoSelectFieldObj, setwareHouseInfoSelectFieldObj] = useState<
    any[]
  >([
    {
      language: "en-US",
      timeZone: "Eastern Standard Time",
      countryId: "United States of America",
      stateId: "",
      currencyCode: "US Dollar"
    },
  ]);

  const [wareHouseInfo, setwareHouseInfo] = useState<any[]>([
    {
      warehouseName: "",
      email: "",
      phoneNumber: "",
      zipcode: "",
      addressLine1: "",
      addressLine2: "",
      alternatePhoneNumber: "",
    phoneCountryCode: phoneCountryCode,
    altPhoneCountryCode: AltPhoneCountryCode,
    faxCountryCode: faxCountryCode,
    fax: "",
    },
  ]);

  const handleWareHouseTextFields = (event: any, key: any) => {
    if (key === "zipcode") {
        if (event.target.value.length <= 5) {
          setwareHouseInfo([
            { ...wareHouseInfo[0], [key]: event.target.value },
          ]);
          validateWHL();
        }
      } else {
        setwareHouseInfo([{ ...wareHouseInfo[0], [key]: event.target.value }]);
        validateWHL();
      }
    };

    const [statesList, setStateList] = useState<any>([]);

    const getStatesList = async (countryCode: any) => {
    const endPointUrl = getStatesByCountryCode(countryCode);
    await axios.get(endPointUrl).then(
      (res) => {
        setStateList(res.data);
        // dispatch(setStatesList(res.data));
      },
      (err) => {
        notify("Failed to fetch data", "error");
      }
    );
    };
  
    const handleWareHouseSelectFields = (event: any, key: any) => {
      //const dataa = JSON.parse(event.target.value);
      if (key === "countryId") {
        const dataa = JSON.parse(event.target.value);
        getStatesList(dataa.isoCountryCode);
        setwareHouseInfoSelectFieldObj([
          { ...wareHouseInfoSelectFieldObj[0], [key]: dataa.countryName },
        ]);
        setwareHouseInfo([{ ...wareHouseInfo[0], [key]: dataa.id }]);
      }
      // setMobileUserSelectFields([{...mobileUserSelectFields[0], [key]: dataa}])
      if (key === "stateId") {
        setwareHouseInfoSelectFieldObj([
          { ...wareHouseInfoSelectFieldObj[0], [key]: event.target.value },
        ]);
        //setwareHouseInfo([{ ...wareHouseInfo[0], [key]: dataa.id }]);
      }
      if (key === "language") {
        setwareHouseInfoSelectFieldObj([
          { ...wareHouseInfoSelectFieldObj[0], [key]: event.target.value },
        ]);
        //setwareHouseInfo([{ ...wareHouseInfo[0], [key]: dataa.isoLongCode }]);
      }
      if (key === "timezone") {
        setwareHouseInfoSelectFieldObj([
          { ...wareHouseInfoSelectFieldObj[0], [key]: event.target.value },
        ]);
        //setwareHouseInfo([{ ...wareHouseInfo[0], [key]: dataa.timezoneName }]);
      }
  
      validateWHL();
    };

    const validateWHL = () =>{
      if (wareHouseInfo[0].warehouseName === "") {
        nameError = "Required Field";
        setMyWareHouseName("1px solid red");
      } else {
        setMyWareHouseName("");
      }
      if (wareHouseInfo[0].email !== "") {
        console.log(emailError)
        var regexFax = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
      if (!regexFax.test(wareHouseInfo[0].email)) {
        console.log(emailError)
        emailError = "Invalid Email";
        setEmailErrorMsg("Invalid Email")
      }      
       else {
        setEmailErrorMsg("");
      }
    }
      if (wareHouseInfoSelectFieldObj[0].currencyCode === "") {
        currencyCodeError = "Required Field";
        //setMyUserName("1px solid red");
      } else {
        //setMyUserName("");
      }
      if (wareHouseInfoSelectFieldObj[0].language === "") {
        languageError = "Required Field";
        //setMyUserName("1px solid red");
      } else {
        //setMyUserName("");
      }
      if (wareHouseInfoSelectFieldObj[0].timeZone === "") {
        timezoneError = "Required Field";
        //setMyUserName("1px solid red");
      } else {
        //setMyUserName("");
      }
      if (
        //mobileUserInfo[0].firstname === "" ||
        wareHouseInfo[0].warehouseName === "" 
       || wareHouseInfoSelectFieldObj[0].timeZone === "" 
       || wareHouseInfoSelectFieldObj[0].language === "" 
       || wareHouseInfoSelectFieldObj[0].currencyCode === "" 
        // mobileUserInfo[0].password === ""
      ) {
        setErrors({
          nameError: "",
          emailError: "",
          phoneNumberError: "",
          altPhoneError: "",
          zipError: "",
          faxError: "",
          languageError: "",
          currencyCodeError: "",
          timezoneError: "",
          streetError: "",
          cityError: "",
          stateError: "",
          countryError: "",
        });
        setIsWHLScreenDisabeled(true);
        return false;
      } else {
        setIsWHLScreenDisabeled(false);
      }
      return true;
      }
  //******************* */

  const [myName, setMyName] = useState("");
  const [myEmail, setMyEmail] = useState("");
  const [myPhone, setMyPhone] = useState("");
  const [myStreet, setMyStreet] = useState("");
  const [myCity, setMyCity] = useState("");
  const [myZip, setMyzip] = useState("");

  const [errors, setErrors] = useState({
    nameError: "",
    emailError: "",
    phoneNumberError: "",
    altPhoneError: "",
    zipError: "",
    faxError: "",
    languageError: "",
    currencyCodeError: "",
    timezoneError: "",
    streetError: "",
    cityError: "",
    stateError: "",
    countryError: "",
  });
  let nameError = "";
  let emailError = "";
  let phoneNumberError = "";
  let altPhoneError = "";
  let zipError = "";
  let faxError = "";
  let languageError = "";
  let currencyCodeError = "";
  let timezoneError = "";
  let streetError = "";
  let cityError = "";
  let stateError = "";
  let countryError = "";

  const handleWarehouseName = (e: any) => {
    setWarehouseName(e.target.value);
    validate();
  };
  const handleWarehouseEmail = (e: any) => {
    // setWarehouseEmail(e.target.value);
    //validate();
    setErrors({
      emailError,
      nameError,
      phoneNumberError,
      altPhoneError,
      zipError,
      faxError,
      languageError,
      currencyCodeError,
      timezoneError,
      streetError,
      cityError,
      stateError,
      countryError,
    });
    const reg =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    // if (e.target.value === "") {
    //   emailError = "Email is Required";
    // } 
    if (e.target.value && reg.test(e.target.value) === false) {
      emailError = "Email is Invalid ";
    }
    if (emailError) {
      setErrors({
        emailError,
        nameError,
        phoneNumberError,
        altPhoneError,
        zipError,
        faxError,
        languageError,
        currencyCodeError,
        timezoneError,
        streetError,
        cityError,
        stateError,
        countryError,
      });
      return false;
    } else {
      setWarehouseEmail(e.target.value);
      return true;
    }
  };
  const handleWarehousePhone = (e: any) => {
    validate();
    setErrors({
      emailError,
      nameError,
      phoneNumberError,
      altPhoneError,
      zipError,
      faxError,
      languageError,
      currencyCodeError,
      timezoneError,
      streetError,
      cityError,
      stateError,
      countryError,
    });
    var phoneno = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
    if (e.target.value.match(phoneno)) {
      setWarehousePhone(e.target.value);
      phoneNumberError = "";
      setErrors({
        emailError,
        nameError,
        phoneNumberError,
        altPhoneError,
        zipError,
        faxError,
        languageError,
        currencyCodeError,
        timezoneError,
        streetError,
        cityError,
        stateError,
        countryError,
      });
      return true;
    } else {
      phoneNumberError = "Phone number is invalid";
    }
    if (phoneNumberError) {
      setErrors({
        emailError,
        nameError,
        phoneNumberError,
        altPhoneError,
        zipError,
        faxError,
        languageError,
        currencyCodeError,
        timezoneError,
        streetError,
        cityError,
        stateError,
        countryError,
      });
      return false;
    }
  };
  const handleWarehouseAltPhone = (e: any) => {
    validate();
    setErrors({
      emailError,
      nameError,
      phoneNumberError,
      altPhoneError,
      zipError,
      faxError,
      languageError,
      currencyCodeError,
      timezoneError,
      streetError,
      cityError,
      stateError,
      countryError,
    });
    var phoneno = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
    if (e.target.value.match(phoneno)) {
      setWarehouseAltPhone(e.target.value);
      altPhoneError = "";
      setErrors({
        emailError,
        nameError,
        phoneNumberError,
        altPhoneError,
        zipError,
        faxError,
        languageError,
        currencyCodeError,
        timezoneError,
        streetError,
        cityError,
        stateError,
        countryError,
      });
      return true;
    } else {
      altPhoneError = "Phone number is invalid";
    }
    if (altPhoneError) {
      setErrors({
        emailError,
        nameError,
        phoneNumberError,
        altPhoneError,
        zipError,
        faxError,
        languageError,
        currencyCodeError,
        timezoneError,
        streetError,
        cityError,
        stateError,
        countryError,
      });
      return false;
    }
  };
  const handleWarehouseFax = (e: any) => {
    validate();
    setErrors({
      emailError,
      nameError,
      phoneNumberError,
      altPhoneError,
      zipError,
      faxError,
      languageError,
      currencyCodeError,
      timezoneError,
      streetError,
      cityError,
      stateError,
      countryError,
    });
    var phoneno = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
    if (e.target.value.match(phoneno)) {
      setWarehouseFax(e.target.value);
      faxError = "";
      setErrors({
        emailError,
        nameError,
        phoneNumberError,
        altPhoneError,
        zipError,
        faxError,
        languageError,
        currencyCodeError,
        timezoneError,
        streetError,
        cityError,
        stateError,
        countryError,
      });
      return true;
    } else {
      faxError = "Fax is invalid";
    }
    if (faxError) {
      setErrors({
        emailError,
        nameError,
        phoneNumberError,
        altPhoneError,
        zipError,
        faxError,
        languageError,
        currencyCodeError,
        timezoneError,
        streetError,
        cityError,
        stateError,
        countryError,
      });
      return false;
    }
  };
  const handleWarehouseAddressStreet = (e: any) => {
    setWarehouseAddressStreet(e.target.value);
    validate();
  };
  const handleWarehouseAddressCity = (e: any) => {
    setWarehouseAddressCity(e.target.value);
    validate();
  };
  const handleWarehouseAddressState = (e: any) => {
    setWarehouseAddressState(e.target.value);
    validate();
  };
  const handleWarehouseAddressZip = (e: any) => {
    setErrors({
      emailError,
      nameError,
      phoneNumberError,
      altPhoneError,
      zipError,
      faxError,
      languageError,
      currencyCodeError,
      timezoneError,
      streetError,
      cityError,
      stateError,
      countryError,
    });
    var regexFax = "^[0-9]{5}(?:-[0-9]{4})?$";
    if (e.target.value.length <= 5) {
      setWarehouseAddressZip(e.target.value);
    }
    if (e.target.value.match(regexFax)) {
      setWarehouseAddressZip(e.target.value);
      zipError = "";
      setErrors({
        emailError,
        nameError,
        phoneNumberError,
        altPhoneError,
        zipError,
        faxError,
        languageError,
        currencyCodeError,
        timezoneError,
        streetError,
        cityError,
        stateError,
        countryError,
      });
      return true;
    } else {
      zipError = "Zip code is invalid";
    }
    if (zipError) {
      setErrors({
        emailError,
        nameError,
        phoneNumberError,
        altPhoneError,
        zipError,
        faxError,
        languageError,
        currencyCodeError,
        timezoneError,
        streetError,
        cityError,
        stateError,
        countryError,
      });
      return false;
    }
    validate();
  };
  const handleWarehouseAddressCountry = (e: any) => {
    //setWarehouseAddressCountry(e.target.value);
    validate();
    const countryCode = JSON.parse(e.target.value);
    setWarehouseAddressCountry(countryCode.countryName);
    const getEndpoint = getStatesByCountry(countryCode.isoCountryCode);
    handleGetStatesList(getEndpoint);
  };
  const handleGetStatesList = async (getEndpoint: string) => {
    const getresult = await axios.get(getEndpoint);
    let cData: any = getresult.data;
    dispatch(setStatesData(cData));
  };
  const handleWarehouseLinkStores = (e: any) => {
    const addStoreItem = JSON.parse(e.target.value);
    const storeInfo = [...warehouseLinkStores];
    if (!storeInfo.some((st) => st.id === addStoreItem.id)) {
      storeInfo.push(addStoreItem);
      setWarehouseLinkStores(storeInfo);
      var mySelect = document.getElementById(
        "wareHouseLinkDropDown"
      ) as HTMLSelectElement;
      if (mySelect) {
        mySelect.selectedIndex = 0;
      }
    }
    var mySelect = document.getElementById(
      "wareHouseLinkDropDown"
    ) as HTMLSelectElement;
    if (mySelect) {
      mySelect.selectedIndex = 0;
    }
  };
  const handleWarehouseLanguage = (e: any) => {
    setWarehouseLanguage(e.target.value);
    validate();
  };
  const handleWarehouseCurrencyCode = (e: any) => {
    setWarehouseCurrencyCode(e.target.value);
    validate();
  };
  const handleWarehouseTimeZone = (e: any) => {
    setWarehouseTimeZone(e.target.value);
    validate();
  };

  const handleChangeCountryCodePhoneWH = (e: any) => {
    setWareHouseCountryCodePhone(e.target.value);
  };
  const handleChangeCountryCodeAltPhoneWH = (e: any) => {
    setWareHouseCountryCodeAltPhone(e.target.value);
  };
  const handleChangeCountryCodeFaxWH = (e: any) => {
    setWareHouseCountryCodeFax(e.target.value);
  };
  const warehouseFinalAddedStores: any[] = [];
  const body = {
    address: [
      {
        addressLine1: "16 Elim Street",
        addressLine2: "Street12",
        alternateAddress: false,
        city: warehouseAddressCity,
        country: warehouseAddressCountry,
        deleted: false,
        state: warehouseAddressState,
        street: warehouseAddressStreet,
        zipcode: warehouseAddressZip,
      },
    ],
    phoneCountryCode: phoneCountryCode,
    altPhoneCountryCode: AltPhoneCountryCode,
    faxCountryCode: faxCountryCode,
    alternatePhoneNumber: altMobileWithFlag?.toString().replace(/[^0-9]/g, ""),
    clientId: 1,
    companyId: 1,
    currencyCode: warehouseCurrencyCode,
    deleted: false,
    email: warehouseEmail,
    fax: faxWithFlag?.toString().replace(/[^0-9]/g, ""),
    language: warehouseLanguage,
    notes: "",
    phoneNumber: mobileWithFlag?.toString().replace(/[^0-9]/g, ""),
    reclaimCenterIds: warehouseFinalAddedStores,
    timezone: warehouseTimeZone,
    warehouseName: warehouseName,
  };
  let tabName: any = ContactType.WAREHOUSES;
  const handleAddWarehouse = () => {
    var phone = document.getElementById("txtPhone") as HTMLInputElement;
    var countryCodephone = document.getElementById(
      "txtCountryCodePhone"
    ) as HTMLSelectElement;
    var altPhone = document.getElementById("txtAltPhone") as HTMLInputElement;
    var countryCodeAltPhone = document.getElementById(
      "txtCountryCodeAltPhone"
    ) as HTMLSelectElement;
    var fax = document.getElementById("txtFax") as HTMLInputElement;
    var countryCodeFax = document.getElementById(
      "txtCountryCodeFax"
    ) as HTMLSelectElement;
    // if(phone.value !== '' && countryCodephone.value === ''){
    //   notify('Please select Country code for Phone number', 'warning');
    // }
    // else if(altPhone.value !== '' && countryCodeAltPhone.value === ''){
    //   notify('Please select Country code for Alt Phone number', 'warning');
    // }
    // else if(fax.value !== '' && countryCodeFax.value === ''){
    //   notify('Please select Country code for Fax', 'warning');
    // }
    // else{
    //if (validate()) {
      warehouseLinkStores.map((item) =>
        warehouseFinalAddedStores.push(item.id)
      );
      // console.log("warehouseFinalLinkStores", body)
      //setWarehouseFinalAddedStores(warehouseFinalAddedStores);
      const endpoint = addMasterDataEndPoint(tabName);
      const getEndpoint = getEndPoint(tabName);
      console.log(wareHouseInfo)
      console.log(wareHouseInfoSelectFieldObj)
      handleMasterDataChange(endpoint, getEndpoint);
   // }
    // }
  };

  const handleMasterDataChange = async (
    endpoint: string,
    getEndpoint: string
  ) => {
    const reqBody = {
      address: [
        {
          addressLine1: wareHouseInfo[0].addressLine1,
          addressLine2: wareHouseInfo[0].addressLine2,
          alternateAddress: false,
          city: wareHouseInfo[0].addressLine2,
          country: wareHouseInfoSelectFieldObj[0].countryId,
          deleted: false,
          state: wareHouseInfoSelectFieldObj[0].stateId,
          street: wareHouseInfo[0].addressLine1,
          zipcode: warehouseAddressZip,
        },
      ],
      phoneCountryCode: phoneCountryCode,
      altPhoneCountryCode: AltPhoneCountryCode,
      faxCountryCode: faxCountryCode,
      alternatePhoneNumber: altMobileWithFlag?.toString().replace(/[^0-9]/g, ""),
      clientId: 1,
      companyId: 1,
      currencyCode: wareHouseInfoSelectFieldObj[0].currencyCode,
      deleted: false,
      email: wareHouseInfo[0].email,
      fax: faxWithFlag?.toString().replace(/[^0-9]/g, ""),
      language: wareHouseInfoSelectFieldObj[0].language,
      notes: "",
      phoneNumber: mobileWithFlag?.toString().replace(/[^0-9]/g, ""),
      reclaimCenterIds: warehouseFinalAddedStores,
      timezone: wareHouseInfoSelectFieldObj[0].timeZone,
      warehouseName: wareHouseInfo[0].warehouseName,
    };
    const result = await axios
      .post(endpoint, reqBody)
      .then((results: any) => {
        if (results && results.data) {
          setAddScreen(false);
          notify("Warehouse created successfully", "success");
        } else {
          notify("Failed to create Warehouse", "error");
        }
      })
      .catch((error: any) => {
        if (error.response) {
          notify(error.response?.data?.message, "error");
        }
      });
    const getresult = await axios.get(getEndpoint);
    dispatch(setCurrentContact(getresult.data));
  };
  const handleCancelWarehousePopup = () => {
    setAddScreen(false);
    dispatch(setMasterDataAddImage([]));
  };

  const removeLinkStoreData = (value: any) => {
    let linkedStoreData = [...warehouseLinkStores];
    let updatedStoreData = linkedStoreData.filter((item) => item.id !== value);
    var mySelect = document.getElementById(
      "wareHouseLinkDropDown"
    ) as HTMLSelectElement;
    if (mySelect) {
      mySelect.selectedIndex = 0;
    }
    setWarehouseLinkStores(updatedStoreData);
  };
  const validate = (field?: any, value?: any) => {
    if (warehouseLanguage === "") {
      languageError = "Language is required";
    }
    if (warehouseCurrencyCode === "") {
      currencyCodeError = "Currency code is required";
    }
    if (warehouseTimeZone === "") {
      timezoneError = "Timezone is required";
    }
    if (warehouseName === "") {
      nameError = "Required Field";
      setMyName("1px solid red");
    } else {
      setMyName("");
    }
    const reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{1,})+$/;
    // if (warehouseEmail === "") {
    //   emailError = "Required Field";
    //   setMyEmail("1px solid red");
    // } 
    if (warehouseEmail && reg.test(warehouseEmail) === false) {
      emailError = "Email is Invalid ";
    } else {
      setMyEmail("");
    }
    const phoneReg =
      /^[(]{0,1}[0-9]{3}[)]{0,1}[-\s\.]{0,1}[0-9]{3}[-\s\.]{0,1}[0-9]{4}$/;
    // if (warehousePhone === "") {
    //   phoneNumberError = "Required Field";
    //   setMyPhone("1px solid red");
    // } else
     if (warehousePhone && phoneReg.test(warehousePhone) === false) {
      phoneNumberError = "Phone Number is Invalid";
    } else {
      setMyPhone("");
    }
    if (warehouseAltPhone && phoneReg.test(warehouseAltPhone) === false) {
      altPhoneError = "Phone Number is Invalid";
    }
    if (warehouseFax && phoneReg.test(warehouseFax) === false) {
      faxError = "Fax is Invalid";
    }
    // if (warehouseAddressStreet === "") {
    //   streetError = "Required Field";
    //   setMyStreet("1px solid red");
    // } else {
    //   setMyStreet("");
    // }
    // if (warehouseAddressCity === "") {
    //   cityError = "Required Field";
    //   setMyCity("1px solid red");
    // } else {
    //   setMyCity("");
    // }
    // if (warehouseAddressState === "") {
    //   stateError = "Required Field";
    // }
    // if (warehouseAddressCountry === "") {
    //   countryError = "Required Field";
    // }
    // if (warehouseAddressZip === "") {
    //   zipError = "Required Field";
    //   setMyzip("1px solid red");
    // } else {
    //   setMyzip("");
    // }

    //   const phoneReg =/^[(]{0,1}[0-9]{3}[)]{0,1}[-\s\.]{0,1}[0-9]{3}[-\s\.]{0,1}[0-9]{4}$/;
    //   if(companyCodePhone==="") {
    //     phoneNumberError = "Phone is Required";
    //   }
    //   else if(companyCodePhone && phoneReg.test(companyCodePhone) === false){
    //     phoneNumberError = "Phone Number is Invalid ";
    // }
    if (
      emailError ||
      nameError ||
      zipError ||
      languageError ||
      currencyCodeError ||
      timezoneError ||
      streetError ||
      cityError ||
      stateError ||
      countryError
    ) {
      setErrors({
        emailError,
        nameError,
        phoneNumberError,
        altPhoneError,
        zipError,
        faxError,
        languageError,
        currencyCodeError,
        timezoneError,
        streetError,
        cityError,
        stateError,
        countryError,
      });
      return false;
    } else {
      return true;
    }
  };

  let uploadedImageFile = useSelector(
    (state: RootState) => state.contactReducer.uploadedImage
  );

  const [addScreen, setFileUploadAddScreen] = useState(false);
  const [addScreenTabName, setAddScreenTabName] = useState(null);
  const [screenType, setScreenType] = useState("masterdataAdd");

  const handlePopupActionAddWareHouse = (selectedTabName: any) => {
    uploadedImageFile = [];
    setFileUploadAddScreen(true);
    setAddScreenTabName(selectedTabName);
  };

  let [stepperindexvalue, setStepperIndexValue] = useState(1);
  const goToNextStep = (index: number) => {
    if (index === 1) {
      setStepperIndexValue(1);
    } else if (index === 2) {
      setStepperIndexValue(2);
    }
  };
  
  return (
    <div className="commodity-popup" style={{marginRight: '0'}}>
      <div className="StepperControl">
        <div className="col-6 pl-2 pt-0 mt-auto mb-auto">
          <div className="page-header--col col--steps">
            <ul className="list--steps pt-3 mb-0 pointer">
              <li
                className={` ${stepperindexvalue === 1 ? "active" : ""}`}
                onClick={(e) => goToNextStep(1)}
              >
                <strong className="steps--number">1</strong>
                <p className="steps--label"></p>
              </li>
              <li
                className={` ${stepperindexvalue === 2 ? "active" : ""}`}
                onClick={(e) => goToNextStep(2)}
              >
                <strong className="steps--number">2</strong>
                <p className="steps--label"></p>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="row">
      {addScreen && (
        <AddFileUploadScreen
          addScreen={addScreen}
          setFileUploadAddScreen={setFileUploadAddScreen}
          tabName={addScreenTabName}
          screenType={screenType}
        />
      )}
        <div className="row d-flex justify-content-center">
          <div className="col-xl-2 col-lg-2 roundImage">
          {/* <input accept="image/*" type="file" onChange={imageChange} /> */}
          {/* <img src={userIcon} className="userAddIcon" />
          {selectedImage && (
            <div className="imagePreviewDiv">
              <img
                src={URL.createObjectURL(selectedImage)}
                className="imagePreview"
                height="100"
                width="100"
              />
            </div>
          )} */}
          {uploadedImageFile && uploadedImageFile.length > 0 ? (
            <div className="ImageContainer">
                <img
                  src={URL.createObjectURL(uploadedImageFile[0].file)}
                  className="userAddIcon"
                  height="140"
                  width="140"
                /></div>
              ) : (
                <img src={userIcon} className="userAddIcon" />
              )}
              <div className="middle">
              <img
                src={editIcon}
                alt="edit "
                width="18"
                height="18"
                className="m-3 pointer avatarEdit"
                onClick={() => handlePopupActionAddWareHouse("fileUpload")}
                style={{position: 'sticky'}}
              /></div>
        </div>
        </div>
        </div>
        <div>
        {stepperindexvalue === 1 ? (
          <div>
        <div className="col-sm-12 row m-0 mt-3">
          <div className="col-sm-6">
            <div className="col-sm-12">
              <span className="lblClass" style={{float: 'left', marginTop: '0'}}>
                Name<span className="requiredStar">*</span>
              </span>
              {wareHouseInfo[0].warehouseName === "" ? (
                <span className="text-danger" style={{float: 'right'}}>{errors.nameError}</span>
              ) : (
                ""
              )}
              <input
                type="text"
                className="formcontrol"
                onChange={(e) => handleWareHouseTextFields(e, 'warehouseName')}
                value={wareHouseInfo[0].warehouseName}
                style={{ border: myWareHouseName }}
              />
            </div>         
            <div className="col-sm-12 mt-3">
              <span className="lblClass" style={{float: 'left', marginTop: '0'}}>Email
                {/* <span className="requiredStar">*</span> */}
              </span>
              {emailErrorMsg !== '' ? (
                <span className="text-danger" style={{float: 'right'}}>{emailErrorMsg}</span>
              ) : (
                ""
              )} 
              <input
                type="text"
                className="formcontrol"
                placeholder="someone@email.com"
                onChange={(e) => handleWareHouseTextFields(e, 'email')}
                onBlur={(e) => handleWareHouseTextFields(e, 'email')}
                onKeyUp={(e) => handleWareHouseTextFields(e, 'email')}
                value={wareHouseInfo[0].email}
                style={{ border: myEmail }}
              />
            </div>          
          </div>

          <div className="col-sm-6">
            <div className="col-sm-12">
              <span className="lblClass" style={{float: 'left', marginTop: '0'}}>Address
                {/* <span className="requiredStar">*</span> */}
              </span>            
              <input
                type="text"
                className="formcontrol"
                placeholder="Street"
                onChange={(e) => handleWareHouseTextFields(e, 'addressLine1')}
                value={wareHouseInfo[0].addressLine1}
                style={{ border: myStreet }}
              />
              {streetError === "" ? (
                <span className="text-danger">{errors.streetError}</span>
              ) : (
                ""
              )}
            </div>            
            <div className="col-sm-12 mt-3">
              <input
                type="text"
                className="formcontrol"
                placeholder="City"
                onChange={(e) => handleWareHouseTextFields(e, 'addressLine2')}
                value={wareHouseInfo[0].addressLine2}
                style={{ border: myCity }}
              />
              {cityError === "" ? (
                <span className="text-danger">{errors.cityError}</span>
              ) : (
                ""
              )}
            </div>
          
          
            
            <div className="col-sm-12 mt-3">
              <select
                id="ddlCountry"
                className="form-control"
                //value={wareHouseInfoSelectFieldObj[0].country}
                onChange={(e: any) => handleWareHouseSelectFields(e, "countryId")}
              >
                {wareHouseInfoSelectFieldObj[0].countryId !== "" ?
                <option value={wareHouseInfoSelectFieldObj[0].countryId}>{wareHouseInfoSelectFieldObj[0].countryId}</option>
                : <option value="">Select Country</option> }
                {countryInfo?.map((cn) => (
                  <option value={JSON.stringify(cn)}>{cn.countryName}</option>
                ))}
              </select>
              {warehouseAddressCountry === "" ? (
                <span className="text-danger">{errors.countryError}</span>
              ) : (
                ""
              )}
            </div>
          
          
            
            <div className="col-sm-8 pr-0 mt-3">
              <select
                id="ddlCountry"
                className="form-control"
               // value={wareHouseInfoSelectFieldObj[0].stateId}
                onChange={(e: any) => handleWareHouseSelectFields(e, "stateId")}
              >
                {wareHouseInfoSelectFieldObj[0].stateId !== "" ?
                <option value={wareHouseInfoSelectFieldObj[0].stateId}>{wareHouseInfoSelectFieldObj[0].stateId}</option>
                : <option value="">Select State</option> }
                {statesList?.map((cn:any) => (
                  <option value={cn.stateName}>{cn.stateName}</option>
                ))}
              </select>
              {warehouseAddressState === "" ? (
                <span className="text-danger">{errors.stateError}</span>
              ) : (
                ""
              )}
            </div>
            <div className="col-sm-4 mt-3">
              <input
                type="number"
                className="formcontrol"
                placeholder="ZIP"
                onChange={(e) => handleWareHouseTextFields(e, 'zipcode')}
                value={wareHouseInfo[0].zipcode}
                style={{ border: myZip }}
              />
              {errors.zipError ? (
                <span className="text-danger">{errors.zipError}</span>
              ) : (
                ""
              )}
            </div>
          

          {/* <div className="row ml-0 mr-0 mt-1rem">
            <div className="col-sm-4">
              <span className="lblClass">Link Reclaim Centers</span>
            </div>
            <div className="col-sm-8">
              <select className="form-control" name="wareHouseLinkDropDown" id="wareHouseLinkDropDown" onChange={(e) => handleWarehouseLinkStores(e)}>
                <option>Search Reclaim Centers</option>
                {reclaimCentersData?.map((st) =>
                  <option value={JSON.stringify(st)}>{st.reclaimcenterName}</option>
                )}
              </select>
            </div>
            <div className="linkedStoreDataDiv row">
              <div className="col-sm-4">
              </div>
              <div className="col-sm-7 linkedRCDiv">
                {warehouseLinkStores && warehouseLinkStores.map((item: any) =>
                  <div className="linkedStoreDiv"><span title="remove" className="linkedStoreName">{item.reclaimcenterName}</span>  <span className="linkedStoreNameRemove" onClick={(e) => removeLinkStoreData(item.id)}>X</span></div>
                )}
              </div>
            </div>
          </div> */}
        </div>
        <div className="col-sm-12 px-0" style={{marginTop: '7rem'}}>
                  <ButtonComponent
                    cssClass="eg-btn-primary float-right pt-3 pb-3 pl-5 pr-5"
                    style={
                      wareHouseInfo[0].warehouseName !== "" &&
                      emailErrorMsg === ""
                        ? {
                            opacity: "1",
                            pointerEvents: "all",
                            cursor: "pointer",
                          }
                        : {
                            opacity: "0.5",
                            pointerEvents: "none",
                            backgroundColor: "#e8e8e8",
                            color: "#969696",
                          }
                    }                    
                    onClick={() => goToNextStep(2)}
                  >
                    Next
                  </ButtonComponent>
                  <ButtonComponent
                    cssClass="eg-btn-primary-link e-flat mt-3"
                    onClick={() => handleCancelWarehousePopup()}
                  >
                    Cancel
                  </ButtonComponent>
                </div>
        </div>
        </div>
        ) : ( 
          <div>
            <div className="col-sm-12 row m-0 mt-2">
              <div className="col-sm-6">
            <div className="col-sm-12">
              <span className="lblClass" style={{float: 'left'}}>Phone
                {/* <span className="requiredStar">*</span> */}
              </span>
              </div>
            <div className="col-sm-12">
              <CountryDropdown type="Phone" />
              {/* <div className="col-sm-4 pl-0 pr-0">
            <select className="form-control" id="txtCountryCodePhone" onChange={(e: any)=> handleChangeCountryCodePhoneWH(e)}>
                  <option value=''>Select</option>
                  {countryCodeInfo?.map((ld) =>
                    <option value={ld?.mobileCountryCode}>{ld?.mobileCountryCode}</option>
                  )}
            </select>
            </div>
            <div className="col-sm-8 pl-0 pr-0">
            <input type="text" id="txtPhone" className="formcontrol" onChange={(e) => handleWarehousePhone(e)} style={{border:myPhone}}/>
            {errors.phoneNumberError?<span className="text-danger">{errors.phoneNumberError}</span>:""}
            </div> */}
            </div>
          
          
            <div className="col-sm-12">
              <span className="lblClass" style={{float: 'left', marginTop: '8px'}}>Alt Phone</span>
            </div>
            <div className="col-sm-12">
              <CountryDropdown type="Alt Phone" />
              {/* <div className="col-sm-4 pl-0 pr-0">
            <select className="form-control" id="txtCountryCodeAltPhone" onChange={(e: any)=>handleChangeCountryCodeAltPhoneWH(e)}>
                  <option value=''>Select</option>
                  {countryCodeInfo?.map((ld) =>
                    <option value={ld?.mobileCountryCode}>{ld?.mobileCountryCode}</option>
                  )}
            </select>
            </div>
            <div className="col-sm-8 pl-0 pr-0">
            <input type="text" id="txtAltPhone" className="form-control" onChange={(e) => handleWarehouseAltPhone(e)} />
            {errors.altPhoneError?<span className="text-danger">{errors.altPhoneError}</span>:""}
            </div> */}
            </div>
         
          
            <div className="col-sm-12">
              <span className="lblClass" style={{float: 'left', marginTop: '8px'}}>Fax</span>
            </div>
            <div className="col-sm-12">
              <CountryDropdown type="Fax" />
              {/* <div className="col-sm-4 pl-0 pr-0">
            <select className="form-control" id="txtCountryCodeFax" onChange={(e: any)=>handleChangeCountryCodeFaxWH(e)}>
                  <option value=''>Select</option>
                  {countryCodeInfo?.map((ld) =>
                    <option value={ld?.mobileCountryCode}>{ld?.mobileCountryCode}</option>
                  )}
            </select>
            </div>
            <div className="col-sm-8 pl-0 pr-0">
            <input type="text" id="txtFax" className="form-control" onChange={(e) => handleWarehouseFax(e)} />
            {errors.faxError?<span className="text-danger">{errors.faxError}</span>:""}
            </div> */}
            </div>          
          </div>
          
          <div className="col-sm-6">
          
            <div className="col-sm-12">
              <span className="lblClass" style={{float: 'left'}}>
                Language<span className="requiredStar">*</span>
              </span>            
              <select
                className="form-control"
                value={wareHouseInfoSelectFieldObj[0].language}
                onChange={(e: any) => handleWareHouseSelectFields(e, "language")}
              >
                {languageData?.map((ld) => (
                  <option value={ld?.isoLongCode}>{ld?.isoLongCode}</option>
                ))}
              </select>
              {warehouseLanguage === "" ? (
                <span className="text-danger">{errors.languageError}</span>
              ) : (
                ""
              )}
            </div>
          
          
            <div className="col-sm-12">
              <span className="lblClass" style={{float: 'left', marginTop: '8px'}}>
                Currency Code<span className="requiredStar">*</span>
              </span>
              <select
                className="form-control"
                value={wareHouseInfoSelectFieldObj[0].currencyCode}
                onChange={(e: any) => handleWareHouseSelectFields(e, "currencyCode")}
              >
                {currencyData?.map((cc) => (
                  <option value={cc.currency}>{cc.currency}</option>
                ))}
              </select>
              {warehouseCurrencyCode === "" ? (
                <span className="text-danger">{errors.currencyCodeError}</span>
              ) : (
                ""
              )}
            </div>
          
          
            <div className="col-sm-12">
              <span className="lblClass" style={{float: 'left', marginTop: '8px'}}>
                Time Zone<span className="requiredStar">*</span>
              </span>
              <select
                className="form-control"
                value={wareHouseInfoSelectFieldObj[0].timeZone}
                onChange={(e: any) => handleWareHouseSelectFields(e, "timeZone")}
              >
                {timezoneData?.map((tz) => (
                  <option value={tz.timezoneName}>
                    {tz.timezoneName}
                    {tz.utc}
                  </option>
                ))}
              </select>
              {warehouseTimeZone === "" ? (
                <span className="text-danger">{errors.timezoneError}</span>
              ) : (
                ""
              )}
            </div>
          
          </div>
          </div>
        
      

          <div className="col-sm-12 px-0" style={{marginTop: '7rem'}}>
        <ButtonComponent
          cssClass="eg-btn-primary float-right"
          onClick={() => handleAddWarehouse()}
          style={
            isWHLScreenDisabeled
              ? {
                  opacity: "0.5",
                  pointerEvents: "none",
                  backgroundColor: "#e8e8e8",
                  color: "#969696",
                  borderRadius: "4px",
                }
              : {
                  opacity: "1",
                  borderRadius: "4px",
                  pointerEvents: "all",
                  backgroundColor: "#636799",
                  color: "#ffffff",
                  cursor: "pointer",
                }
          }
        >
          ADD WAREHOUSE
        </ButtonComponent>
        <ButtonComponent
                      cssClass="eg-btn-secondary float-right mr-4"
                      onClick={() => goToNextStep(1)}
                    >
                      Back
                    </ButtonComponent>
        <ButtonComponent
                    cssClass="eg-btn-primary-link e-flat mt-3"
                    onClick={() => handleCancelWarehousePopup()}
                  >
                    Cancel
                  </ButtonComponent>
      </div>
      </div>
        )}
      </div>
    </div>
  );
};
export default WarehouseAddScreen;
