import HttpService from '../http-service/http-service';
import { API_PATH, getApiBaseUrl } from '../../api-paths';
import { SearchCriteriaRequest } from '../../components/grid/gridConfig';

export interface Client {
    createdDate?: any;
    lastModifiedDate?: any;
    organizationId?: any;
    tenantId?: any;
    id: number;
    clientId: string;
    clientName: string;
}

export interface Stores {
    createdDate: Date;
    lastModifiedDate: Date;
    organizationId: string;
    tenantId: string;
    id: number;
    upcReference: string;
    description: string;
    pack: number;
    unitFactor: number;
    disposition: string;
    vendorNumber: string;
    itemCode: string;
    itemIdType: string;
    storeCost: number;
    programType: string;
    programName: string;
    clientCustomerNumber: number;
    retail?: any;
    cost: number;
    crossQuantityRequiredFlag: string;
    size: string;
    batchStartTime: Date;
    client: Client;
    debitReasonCode?: any;
    hazardousFlag: string;
    subDisposition?: any;
    maStateDisposition?: any;
    flStateDisposition?: any;
    txStateDisposition?: any;
    caStateDisposition?: any;
    ruleExceptionList?: any;
    warehouseLocation?: any;
    storeDisposition?: any;
    vendorName?: any;
    vendorId?: any;
    active: boolean;
    ruleActive: boolean;
    deleted: boolean;
}

export interface StoresGridView {
    id: number;
    active: boolean;
    addressLine1: string;
    addressLine2: string;
    city: string;
    clientGroupName: string;
    clientGroupNumber: string;
    clientId: string;
    country: string;
    deleted: string;
    mainframeCustomerNumber: string;
    state: string;
    storeName: string;
    storeNumber: string;
    street: string;
    underReview: boolean;
    addressInfo: string;
    clientGroupInfo: string;
    isActive: string;
    isMobileScanEnabled: string;
}

export interface StoreResponse {
    result: StoresGridView[];
    count: number;
}

export default class StoresService {
    public async getFilteredItems(
        searchCriteria: SearchCriteriaRequest
    ): Promise<StoreResponse> {
        const url = getApiBaseUrl() + API_PATH.STORES_PATH;
        return HttpService.post<StoreResponse>(url, searchCriteria).then(
            (response) => {
                return response.data;
            }
        );
    }

    public async getItem(id: number): Promise<Stores> {
        const url = getApiBaseUrl() + API_PATH.STORES_ITEM + id;
        return HttpService.get<Stores>(url).then((response) => {
            return response.data;
        });
    }

    public async updateItem(id: number, rowInfoObject: any): Promise<Stores> {
        const url = getApiBaseUrl() + API_PATH.STORES_PATH + id;
        return HttpService.put<Stores>(url, rowInfoObject).then((response) => {
            return response.data;
        });
    }
}
