import { ButtonComponent } from "@syncfusion/ej2-react-buttons";
import saveIcon from "../../../icons/common/save.png";
import closeIcon from "../../../icons/common/close.png";
import activeIcon from "../../../icons/common/active.png";
import inactiveIcon from "../../../icons/common/inactive.png";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setRowData } from "../../grid/gridReducer";
import { RootState } from "../../../redux/store";
import notify from "../../../services/toasterService";
import axios from "axios";
import { getAccessMgmtEndPoint, updateAccessReqEndPoint } from "../utils";
import { setRolesData } from "../accessmgmtReducer";
import addModuleIcon from "../../../icons/common/addmodule.png";
import closeModuleIcon from "../../../icons/common/closemodule.png";
import closeDialog from "../../../icons/common/close-dialog.png";
import {
  CheckBoxSelection,
  Inject,
  MultiSelectComponent,
} from "@syncfusion/ej2-react-dropdowns";

const PreviewRole = () => {
  const dispatch = useDispatch();
  const [roleInfo, setRoleInfo] = useState<any[]>([]);
  const [showAdd, setShowAdd] = useState(true);
  const [removeSelModules, setRemoveSelModules] = useState<any[]>([]);
  const [modules, setModules] = useState<any[]>([]);
  const [listModules, setListModules] = useState<any[]>([]);

  let moduleMappingId: any[] = [];

  let [selectedModules, setSelectedModules] = useState<any[]>([]);
  /* Get selected data from Grid Reducer */
  const selRowData = useSelector(
    (state: RootState) => state.gridReducer.selectedRowData
  );

  const modulesDataList = useSelector(
    (state: RootState) => state.accessMgmtReducer.modulesList
  );
  let checkFields: Object = { text: "modulename", value: "moduleMenuId" };

  useEffect(() => {
    if (selRowData && selRowData?.length > 0) {
      console.log(selRowData);
      getModulesBasedOnId(selRowData[0].id);
      setRoleInfo(selRowData);
      setRemoveSelModules([]);
      setModules([]);
    }
    // Write for onload
  }, [selRowData]);

  const getModulesBasedOnId = async (id: any) => {
    setSelectedModules([]);
    // selectedModules = [];
    const endPointURL = getAccessMgmtEndPoint("Modules By Id");
    const body = { id: id };
    await axios
      .post(endPointURL, body)
      .then((res) => {
        let dataaa: any;
        dataaa = res.data;
        setSelectedModules(dataaa);

        // getRoles('Roles');
      })
      .catch((err) => {
        console.log(err, "Roles");
        notify("Failed to get modules", "error");
      });
  };

  const rowInfoFieldFocusOut = (value: any, key: any) => {
    setRoleInfo([{ ...roleInfo[0], [key]: value }]);
  };
  /**
   * Changing status for Preview selected tabs
   * @param flag - true/false
   */
  const changeStatusForRowData = (flag: any) => {
    if (flag === false) {
      setRoleInfo([{ ...roleInfo[0], activeForGrid: "Inactive" }]);
    } else {
      setRoleInfo([{ ...roleInfo[0], activeForGrid: "Active" }]);
    }
  };

  const clearPreview = () => {
    dispatch(setRowData([]));
  };

  const changeIcon = () => {
    const toggleImage = !showAdd;
    setShowAdd(toggleImage);
  };

  const removeRoles = (id: any, idx: any) => {
    moduleMappingId.push(id);
    const modId = id;
    // moduleMappingId = [...moduleMappingId];
    //  setRemoveSelModules(moduleMappingId);
    const modInfo = [...removeSelModules];
    if (!modInfo.some((rc) => rc === modId)) {
      modInfo.push(modId);
      setRemoveSelModules(modInfo);
    }
    let roleInfoCopy = [...selectedModules];
    // const list = roleInfoCopy[0]?.roleModuleMapping.filter((item: { id: any; }) =>  item.id !== id);
    let updatedModuleList = roleInfoCopy.filter(
      (item: any) => item.moduleMenuMappingId !== id
    );
    selectedModules = [];

    setSelectedModules(updatedModuleList);
  };
  const handleModules = (e: any) => {
    // console.log(e.value);
    // const selectedModulesId = e.value;
    // let selectedModules: any[] = [];
    // if (selectedModulesId && selectedModulesId.length > 0 ) {
    //     selectedModulesId.map((item: any) => {
    //         modulesDataList.map((subItem: any) => {
    //             if (subItem?.moduleId === item) {
    //                 selectedModules.push(subItem);
    //             }
    //         })
    //     })
    // }
    // // console.log(selectedModules);
    // setRoleInfo([{ ...roleInfo[0], roleModuleMapping: selectedModules }])
    //
    // console.log(roleInfo);
    setModules([]);
    console.log(e.value);
    setModules(e.value);
  };

  /**
   * PUT web service methods for Location setting tabs
   * @param data - Tab Name
   */
  const updateRolesTabApi = async (data: any) => {
    const addModuleData: any[] = [];
    const roleModuleMapping: any[] = [];
    const roleModuleMenuMapping: any[] = [];
    // const token = sessionStorage.getItem('token');
    // const token = UserService.getToken();
    const endPointURL = updateAccessReqEndPoint("Roles");
    let body = {
      id: data?.id,
      description: data?.description,
      roleName: data?.roleName,
      status: data?.activeForGrid,
    };
    Object.assign(body, { removeModules: removeSelModules });
    if (modules.length > 0) {
      modules.map((parentid) => {
        modulesDataList.map((item: any) => {
          if (item.moduleMenuId === parentid) {
            const obj1 = { moduleId: item.moduleId, roleId: data.id };
            const obj2 = {
              moduleId: item.moduleId,
              moduleMenuId: item.moduleMenuId,
            };
            roleModuleMapping.push(obj1);
            roleModuleMenuMapping.push(obj2);
          }
        });
        // const moduleObj = { roleId: data?.id, moduleId: item };
        // addModuleData.push(moduleObj);
      });
      const uniqueroleModuleMapping = roleModuleMapping.filter(
        (ele, ind) =>
          ind ===
          roleModuleMapping.findIndex((elem) => elem.moduleId === ele.moduleId)
      );
      Object.assign(body, { roleModuleMapping: uniqueroleModuleMapping });
      Object.assign(body, { roleModuleMenuMapping: roleModuleMenuMapping });
    } else {
      Object.assign(body, { roleModuleMapping: [] });
      Object.assign(body, { roleModuleMenuMapping: [] });
    }
    // await axios.post(endPointURL, body, { headers: { 'Authorization': `Bearer ${token}` } }).then(
    await axios
      .post(endPointURL, body)
      .then((res) => {
        notify("Role updated successfully", "success");
        getRoles("Roles");
      })
      .catch((err) => {
        console.log(err, "Roles");
        notify("Failed to update role", "error");
      });
  };

  const getRoles = async (fieldName: any) => {
    // const token = sessionStorage.getItem('token');
    // const token = UserService.getToken();
    const getEndPointUrl = getAccessMgmtEndPoint(fieldName);
    // await axios.post(getEndPointUrl, {}, { headers: { 'Authorization': `Bearer ${token}` } }).then((res: { data: any; }) => {
    await axios
      .post(getEndPointUrl, {})
      .then((res: { data: any }) => {
        console.log(res.data);
        dispatch(setRolesData(res.data));
        setTimeout(() => {
          setRoleInfo(res.data[0]);
          dispatch(setRowData(roleInfo));
        }, 5);
      })
      .catch((err: any) => {
        console.log(err);
        notify(
          "Error! Unable to fetch data. Please try after sometime.",
          "error"
        );
      });
  };

  const footerPreview = (data: any) => {
    return (
      <div className="col-sm-12 px-0 pt-5 ">
        <div className="col-sm-6 col-md-6 col-lg-6 pl-1.5rem pr-1 preview-Key  ">
          <ButtonComponent
            cssClass="e-outline opt-btn-light float-left"
            onClick={() => updateRolesTabApi(data)}
          >
            <img src={saveIcon} alt="Save" />
          </ButtonComponent>

          <ButtonComponent
            cssClass="e-outline opt-btn-light float-left ml-3"
            onClick={() => clearPreview()}
          >
            <img src={closeIcon} alt="Close" />
          </ButtonComponent>
        </div>
        <div className="col-sm-6 col-md-6 col-lg-6 preview-value pt-3">
          <p className="preview-status">
            {data?.activeForGrid === "Active" ||
            data?.activeForGrid === "active" ? (
              <span>
                <img
                  src={activeIcon}
                  alt="Status"
                  onClick={() => changeStatusForRowData(false)}
                />
                &nbsp; &nbsp; Active
              </span>
            ) : (
              <span>
                <img
                  src={inactiveIcon}
                  alt="Status"
                  onClick={() => changeStatusForRowData(true)}
                />
                &nbsp; &nbsp; Inactive
              </span>
            )}
          </p>
        </div>
      </div>
    );
  };

  return (
    <div>
      {roleInfo && roleInfo.length > 0 ? (
        <div className="col-sm-12 px-3 py-3 role-preview-details  ">
          <div className="col-sm-12 px-0 pt-4">
            <div className="col-sm-6 col-md-6 col-lg-4 preview-Key" style={{lineHeight: '30px'}}>
              <span>
              Name</span>
            </div>
            <div className="col-sm-6 col-md-6 col-lg-8 p-0 preview-value ">
              {/* <InlineInputEdit
                inputClassName="txtEditClassNameInput"
                text={roleInfo[0]?.roleName}
                labelClassName="txtEditClassName"
                onFocusOut={(e) => rowInfoFieldFocusOut(e, "roleName")}
                labelPlaceHolder="---"
              /> */}
              <input
                type="text"
                className="form-control boxShadow-None border-none cursor-pointer boxShadow-None pl-0"
                value={roleInfo[0]?.roleName}
                onChange={(e: any) =>
                  rowInfoFieldFocusOut(e.target, "roleName")
                }
                onBlur={(e: any) => rowInfoFieldFocusOut(e.target, "roleName")}
                onKeyUp={(e: any) => rowInfoFieldFocusOut(e.target, "roleName")}
              />
            </div>
          </div>

          <div className="col-sm-12 p-0 mb-auto">
            <hr className="divider mt-3 mb-2" />
          </div>

          <div className="col-sm-12 px-0 pt-3">
            <div className="col-sm-6 col-md-6 col-lg-6 pr-0 module-access-key  ">
              Module Access
            </div>
            <div className="col-sm-6 col-md-6 col-lg-6 preview-value pr-0">
              {showAdd && showAdd === true ? (
                <img
                  src={addModuleIcon}
                  alt="Add"
                  className="float-right"
                  onClick={changeIcon}
                />
              ) : (
                <img
                  src={closeModuleIcon}
                  alt="Close"
                  className="float-right"
                  onClick={changeIcon}
                />
              )}
            </div>
          </div>

          {showAdd && showAdd === true ? (
            ""
          ) : (
            <div className="col-sm-12 px-0 pt-4">
              <div id="ll-autocomplete" className="control-pane ">
                <div className="control-section col-sm-12 p-0">
                  <div id="multigroup" className="control-styles">
                    <MultiSelectComponent
                      className="form-control"
                      id="checkbox"
                      dataSource={modulesDataList}
                      fields={checkFields}
                      placeholder="Select Modules"
                      mode="CheckBox"
                      showSelectAll={true}
                      showDropDownIcon={true}
                      filterBarPlaceholder="Search Modules"
                      popupHeight="350px"
                      change={handleModules.bind(this)}
                    >
                      <Inject services={[CheckBoxSelection]} />
                    </MultiSelectComponent>
                  </div>
                </div>
              </div>
            </div>
          )}

          {selectedModules && selectedModules?.length > 0 ? (
            <div className="col-sm-12 px-0 pt-4">
              {selectedModules.map((item: any, idx: any) => (
                <div className="col-sm-12 linkedStoreDiv">
                  <div className="col-sm-10">                  
                    <span className="module-access-value">
                      {item?.modulename}
                    </span>
                    </div>
                    <div className="col-sm-2 p-1" style={{cursor: 'pointer'}}>
                    <span>
                      <img
                        src={closeDialog}
                        alt="Close"
                        className="closeIcon float-right"
                        onClick={() =>
                          removeRoles(item?.moduleMenuMappingId, idx)
                        }
                      />
                    </span>
                 </div>
                </div>
              ))}
            </div>
          ) : (
            ""
          )}

          <div className="col-sm-12 p-0 mb-auto">
            <hr className="divider" />
          </div>

          <div className="col-sm-12 px-0 pt-2 pb-2 mb-auto">
            <div className="col-sm-12 p-0">
              <div className="col-sm-6 col-md-6 col-lg-5 preview-Key  ">
                Description
              </div>
              <div className="col-sm-6 col-md-6 col-lg-7 pl-0 pr-0 preview-value ">
                <textarea
                  className="access-description-preview"
                  name=""
                  id=""
                  rows={5}
                  onChange={(e) => rowInfoFieldFocusOut(e.target.value, "text")}
                >
                  {roleInfo[0]?.description}
                </textarea>
              </div>
            </div>
          </div>

          <div className="col-sm-12 px-0 pt-2 pb-4">
            {footerPreview(roleInfo[0])}
          </div>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default PreviewRole;
