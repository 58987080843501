import "./addScreen.scss";
// import "@syncfusion/ej2-base/styles/material.css";
 import "@syncfusion/ej2-buttons/styles/material.css";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import AddMessage from "../messageManagementSettings/addMessageMgmt";
import SettingsModal from "../../common/settingsModal/settingsModal";
import AddCasualDataRule from "../casualdatasettings/addCasualDataRule";

const AddSettingsScreen = (props: any) => {
  const dispatch = useDispatch();
  const [tabNameFinal, setTabNameFinal] = useState("");
  const { addScreen, setAddScreen, tabName } = props;
  useEffect(() => {
    const res = tabName?.charAt(tabName.length - 1);
    if (res === "s") {
      setTabNameFinal(tabName?.slice(0, -1));
    } else {
      setTabNameFinal(tabName);
    }
  });

  console.log(tabNameFinal);

  return (
    <>
      <SettingsModal
        handleClose={() => setAddScreen(false)}
        show={addScreen}
        width={800}
      >
        <div className="row ml-0 mr-0 mb-30 modal-title-header">
          {tabName === "Casual Data" ? (
            <span className="tabNameForAddScreen">Add Causal Data Rule</span>
          ) : tabName === "Reclaim Scan" || tabName === "Mobile" ? (
            <span className="tabNameForAddScreen">Add Message</span>
          ) : (
            ""
          )}
        </div>

        {tabName === "Casual Data" ? (
          <AddCasualDataRule setAddScreen={setAddScreen} />
        ) : tabName === "Reclaim Scan" || tabName === "Mobile" ? (
          <AddMessage setAddScreen={setAddScreen} setTabName={tabName} />
        ) : (
          ""
        )}
      </SettingsModal>
    </>
  );
};

export default AddSettingsScreen;
